import { Box, Card, CardContent, Typography } from '@mui/material';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ReportStatistic } from '../../../../generated/graphql';
import { ReportStatisticConfig } from '../../types/report-statistic-config';
import { ReportGroupConfiguration } from '../../types/report-types';

declare type CustomTooltipProps<
  Value extends ValueType,
  Name extends NameType,
> = TooltipProps<Value, Name> & {
  reportGroupConfiguration: ReportGroupConfiguration | undefined;
  reportStatisticsConfig: ReportStatisticConfig[];
};

const CustomTooltip = ({
  active,
  payload,
  reportGroupConfiguration,
  reportStatisticsConfig,
}: CustomTooltipProps<ValueType, NameType>) => {
  const formatTooltipLabel = ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    const formattedStartDate = !isNil(startDate)
      ? dayjs.utc(startDate).format('MM/DD/YY')
      : '';
    const formattedEndDate = !isNil(endDate)
      ? dayjs.utc(endDate).format('MM/DD/YY')
      : '';

    if (isEmpty(formattedStartDate) && isEmpty(formattedEndDate)) {
      return '';
    }

    // This happens when the aggregation period is DAY. In this case, it doesn't make sense to return a date range.
    if (dayjs(formattedStartDate).isSame(formattedEndDate, 'day')) {
      return formattedStartDate;
    }

    return `${formattedStartDate} - ${formattedEndDate}`;
  };
  if (Boolean(active) && !isNil(payload) && !isNil(payload[0])) {
    const bucket = payload[0]?.payload;
    return (
      <Card>
        <CardContent>
          <Typography>
            {formatTooltipLabel({
              startDate: bucket?.startDate ?? null,
              endDate: bucket?.endDate ?? null,
            })}
          </Typography>
          {reportStatisticsConfig.map((statistic) => {
            if (
              !isNil(reportGroupConfiguration) &&
              reportGroupConfiguration.reportStatistics.includes(
                statistic.configKey,
              )
            ) {
              return (
                <Typography color={statistic.color} key={statistic.key}>
                  {statistic.name}:{' '}
                  {statistic.configKey !== ReportStatistic.NumberOfOrders &&
                  statistic.configKey !== ReportStatistic.NumberOfPackages
                    ? currency(bucket[statistic.key], {
                        separator: ',',
                      }).format()
                    : bucket[statistic.key]}
                </Typography>
              );
            }
            return <Box key={statistic.key} />;
          })}
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default CustomTooltip;
