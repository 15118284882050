import { ReportStatistic } from '../../../generated/graphql';
import { HeadCell } from '../utils';
import { ReportStatisticConfig } from './report-statistic-config';

export const REVENUE_REPORT_STATISTICS: ReportStatisticConfig[] = [
  {
    key: 'totalRevenue',
    name: 'Total Revenue',
    color: '#007bff',
    configKey: ReportStatistic.TotalRevenue,
  },
  {
    key: 'freightRevenue',
    name: 'Freight Revenue',
    color: '#dc3545',
    configKey: ReportStatistic.FreightRevenue,
  },
  {
    key: 'customChargeRevenue',
    name: 'Custom Charge Revenue',
    color: '#28a745',
    configKey: ReportStatistic.CustomChargeRevenue,
  },
  {
    key: 'fuelRevenue',
    name: 'Fuel Revenue',
    color: '#ff9900',
    configKey: ReportStatistic.FuelRevenue,
  },
];

export const REVENUE_REPORT_STATISTICS_BY_STOP: ReportStatisticConfig[] = [
  {
    key: 'totalRevenue',
    name: 'Total Revenue',
    color: '#007bff',
    configKey: ReportStatistic.TotalRevenue,
  },
  {
    key: 'pickupRevenue',
    name: 'Pickup Revenue',
    color: '#dc3545',
    configKey: ReportStatistic.PickupRevenue,
  },
  {
    key: 'deliveryRevenue',
    name: 'Delivery Revenue',
    color: '#28a745',
    configKey: ReportStatistic.DeliveryRevenue,
  },
  // {
  //   key: 'recoveryRevenue',
  //   name: 'Recovery Revenue',
  //   color: '#ff9900',
  //   configKey: ReportStatistic.RecoveryRevenue,
  // },
  {
    key: 'transferRevenue',
    name: 'Transfer Revenue',
    color: '#c965ff',
    configKey: ReportStatistic.TransferRevenue,
  },
];

export const ORDER_PACKAGE_COUNT_REPORT_STATISTICS: ReportStatisticConfig[] = [
  {
    key: 'numberOfOrders',
    name: 'Number of Orders',
    color: '#3b5998',
    configKey: ReportStatistic.NumberOfOrders,
  },
  {
    key: 'numberOfPackages',
    name: 'Number of Packages',
    color: '#17a2b8',
    configKey: ReportStatistic.NumberOfPackages,
  },
];

export const ORDER_HEAD_CELLS: readonly HeadCell[] = [
  {
    key: 'startDate',
    numeric: false,
    label: 'Start Date',
  },
  {
    key: 'totalRevenue',
    numeric: true,
    label: 'Total Revenue',
  },
  {
    key: 'freightRevenue',
    numeric: true,
    label: 'Freight Revenue',
  },
  {
    key: 'customChargeRevenue',
    numeric: true,
    label: 'Custom Charge Revenue',
  },
  {
    key: 'fuelRevenue',
    numeric: true,
    label: 'Fuel Revenue',
  },
  {
    key: 'totalWeight',
    numeric: true,
    label: 'Total Weight',
  },
  {
    key: 'numberOfOrders',
    numeric: true,
    label: '# Orders',
  },
  {
    key: 'numberOfPackages',
    numeric: true,
    label: '# Packages',
  },
];

export const ORDER_CSV_HEADERS = [...ORDER_HEAD_CELLS];
ORDER_CSV_HEADERS.splice(1, 0, {
  key: 'endDate',
  numeric: false,
  label: 'End Date',
});

export const DRIVER_HEAD_CELLS: readonly HeadCell[] = [
  {
    key: 'driverName',
    numeric: false,
    label: 'Driver Name',
  },
  {
    key: 'driverType',
    numeric: false,
    label: 'Driver Type',
  },
  {
    key: 'totalRevenue',
    numeric: true,
    label: 'Total Revenue',
  },
  {
    key: 'freightRevenue',
    numeric: true,
    label: 'Freight Revenue',
  },
  {
    key: 'customChargeRevenue',
    numeric: true,
    label: 'Custom Charge Revenue',
  },
  {
    key: 'fuelRevenue',
    numeric: true,
    label: 'Fuel Revenue',
  },
  {
    key: 'totalWeight',
    numeric: true,
    label: 'Total Weight',
  },
  {
    key: 'numberOfOrders',
    numeric: true,
    label: '# Orders',
  },
  {
    key: 'numberOfPackages',
    numeric: true,
    label: '# Packages',
  },
];

export const DRIVER_CSV_HEADERS = [...DRIVER_HEAD_CELLS];

export const CUSTOMER_HEAD_CELLS: readonly HeadCell[] = [
  {
    key: 'displayName',
    numeric: false,
    label: 'Company',
  },
  {
    key: 'totalRevenue',
    numeric: true,
    label: 'Total Revenue',
  },
  {
    key: 'freightRevenue',
    numeric: true,
    label: 'Freight Revenue',
  },
  {
    key: 'customChargeRevenue',
    numeric: true,
    label: 'Custom Charge Revenue',
  },
  {
    key: 'fuelRevenue',
    numeric: true,
    label: 'Fuel Revenue',
  },
  {
    key: 'totalWeight',
    numeric: true,
    label: 'Total Weight',
  },
  {
    key: 'numberOfOrders',
    numeric: true,
    label: '# Orders',
  },
  {
    key: 'numberOfPackages',
    numeric: true,
    label: '# Packages',
  },
];

export const CUSTOMER_CSV_HEADERS = [...CUSTOMER_HEAD_CELLS];

export const SERVICE_LEVEL_HEAD_CELLS: readonly HeadCell[] = [
  {
    key: 'name',
    numeric: false,
    label: 'Service Level',
  },
  {
    key: 'totalRevenue',
    numeric: true,
    label: 'Total Revenue',
  },
  {
    key: 'freightRevenue',
    numeric: true,
    label: 'Freight Revenue',
  },
  {
    key: 'customChargeRevenue',
    numeric: true,
    label: 'Custom Charge Revenue',
  },
  {
    key: 'fuelRevenue',
    numeric: true,
    label: 'Fuel Revenue',
  },
  {
    key: 'totalWeight',
    numeric: true,
    label: 'Total Weight',
  },
  {
    key: 'numberOfOrders',
    numeric: true,
    label: '# Orders',
  },
  {
    key: 'numberOfPackages',
    numeric: true,
    label: '# Packages',
  },
];

export const SERVICE_LEVEL_CSV_HEADERS = [...SERVICE_LEVEL_HEAD_CELLS];

export const STATION_REPORT_HEAD_CELLS: readonly HeadCell[] = [
  {
    key: 'stationName',
    numeric: false,
    label: 'Station',
  },
  {
    key: 'totalRevenue',
    numeric: true,
    label: 'Total Revenue',
  },
  {
    key: 'freightRevenue',
    numeric: true,
    label: 'Freight Revenue',
  },
  {
    key: 'customChargeRevenue',
    numeric: true,
    label: 'Custom Charge Revenue',
  },
  {
    key: 'fuelRevenue',
    numeric: true,
    label: 'Fuel Revenue',
  },
  {
    key: 'totalWeight',
    numeric: true,
    label: 'Total Weight',
  },
  {
    key: 'numberOfOrders',
    numeric: true,
    label: '# Orders',
  },
  {
    key: 'numberOfPackages',
    numeric: true,
    label: '# Packages',
  },
];

export const STATION_REPORT_CSV_HEADERS = [...STATION_REPORT_HEAD_CELLS];

export const TERMINAL_HEAD_CELLS: readonly HeadCell[] = [
  {
    key: 'terminalName',
    numeric: false,
    label: 'Terminal',
  },
  {
    key: 'totalRevenue',
    numeric: true,
    label: 'Total Revenue',
  },
  {
    key: 'pickupRevenue',
    numeric: true,
    label: 'Pickup Revenue',
  },
  {
    key: 'deliveryRevenue',
    numeric: true,
    label: 'Delivery Revenue',
  },
  // {
  //   key: 'recoveryRevenue',
  //   numeric: true,
  //   label: 'Recovery Revenue',
  // },
  {
    key: 'transferRevenue',
    numeric: true,
    label: 'Transfer Revenue',
  },
  // {
  //   key: 'freightRevenue',
  //   numeric: true,
  //   label: 'Freight Revenue',
  // },
  // {
  //   key: 'customChargeRevenue',
  //   numeric: true,
  //   label: 'Custom Charge Revenue',
  // },
  // {
  //   key: 'fuelRevenue',
  //   numeric: true,
  //   label: 'Fuel Revenue',
  // },
  {
    key: 'totalWeight',
    numeric: true,
    label: 'Total Weight',
  },
  {
    key: 'numberOfOrders',
    numeric: true,
    label: '# Orders',
  },
  {
    key: 'numberOfPackages',
    numeric: true,
    label: '# Packages',
  },
];

export const TERMINAL_CSV_HEADERS = [...TERMINAL_HEAD_CELLS];
