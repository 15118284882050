import { Button, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

type RescheduleAppointmentDialogProps = {
  handleAppointmentReschedule: () => void;
  loading: boolean;
  pickupOrDelivery?: string | undefined;
  onBack: () => void;
};

export const ConfirmRescheduleAppointment: FunctionComponent<
  RescheduleAppointmentDialogProps
> = ({ handleAppointmentReschedule, loading, pickupOrDelivery, onBack }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Typography fontWeight="500">
        If you request to reschedule this appointment, your{' '}
        {pickupOrDelivery ?? 'order'} will be delayed 3-5 business days.
      </Typography>
      <Button
        color="error"
        onClick={handleAppointmentReschedule}
        disabled={loading}
        variant="contained"
      >
        Confirm Reschedule Request
      </Button>
      <Button onClick={onBack} disabled={loading} variant="text">
        Back
      </Button>
    </Stack>
  );
};
