import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  TextField,
  Stack,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import PhoneInputMask from '../../../../common/components/phone/phone-input-mask';
import {
  useCreateWarehouseEmployeeMutation,
  WarehouseEmployeesDocument,
} from '../../../../generated/graphql';
import { WarehouseEmployeeFormValues } from './forms/types';
import useCreateWarehouseEmployeeForm from './forms/use-create-warehouse-employee-form';

interface CreateWarehouseEmployeeModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
}

const CreateWarehouseEmployeeModal = ({
  open,
  setOpen,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
}: CreateWarehouseEmployeeModalProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useCreateWarehouseEmployeeForm();

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const [createWarehouseEmployee, { loading: createWarehouseEmployeeLoading }] =
    useCreateWarehouseEmployeeMutation({
      refetchQueries: [WarehouseEmployeesDocument],
    });

  const onSubmit: SubmitHandler<WarehouseEmployeeFormValues> = async (data) => {
    const { firstName, lastName, emailAddress, phoneNumber, password, active } =
      data;
    try {
      const response = await createWarehouseEmployee({
        variables: {
          createWarehouseEmployeeInput: {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            password,
            active,
          },
        },
      });
      const createdWarehouseEmployee =
        response?.data?.createWarehouseEmployee?.warehouseEmployee;
      if (isNil(createdWarehouseEmployee)) {
        setShowErrorMessage(true);
      } else {
        setSuccessSnackbarVisible(true);
        handleClose();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create Warehouse Employee</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>First Name</InputLabel>
                  <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    required
                    error={!isNil(errors.firstName)}
                    helperText={errors?.firstName?.message}
                    sx={{ width: '100%' }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Last Name</InputLabel>
                  <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    required
                    error={!isNil(errors.lastName)}
                    helperText={errors?.lastName?.message}
                    sx={{ width: '100%' }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="emailAddress"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    required
                    error={!isNil(errors.emailAddress)}
                    helperText={errors?.emailAddress?.message}
                    sx={{ width: '100%' }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Phone Number</InputLabel>
                  <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    required
                    error={!isNil(errors.phoneNumber)}
                    helperText={errors?.phoneNumber?.message}
                    sx={{ width: '100%' }}
                    InputProps={{
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      inputComponent: PhoneInputMask as any,
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Password</InputLabel>
                  <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    required
                    error={!isNil(errors.password)}
                    helperText={errors?.password?.message}
                    sx={{ width: '100%' }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Controller
              control={control}
              name="active"
              defaultValue={false}
              render={({ field }) => (
                <FormControl fullWidth sx={{ paddingLeft: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    }
                    label="Active"
                  />
                </FormControl>
              )}
            />
          </Grid>
          {showErrorMessage && (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography sx={{ color: 'red' }}>
                Error creating warehouse employee. Is the phone number unique?
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={createWarehouseEmployeeLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={createWarehouseEmployeeLoading}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWarehouseEmployeeModal;
