import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { capitalCase, sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import React, { useMemo, useState } from 'react';
import { defaultStyles, JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import { isPalletAdmin } from '../../../../../../../../utils';
import useMe from '../../../../../../../common/react-hooks/use-me';
import {
  OrderEventFragment,
  OrderEventType,
  OrderSource,
  Segment,
} from '../../../../../../../generated/graphql';
import useVersionHistoryStore from '../../../../../version-history-store';
import {
  defaultBOLName,
  defaultSecondaryRefName,
  formatOrderEventActor,
  getSnapshotDifferences,
  renderOrderEventMessage,
  SnapshotDifference,
  SnapshotDifferenceData,
} from '../order-audit-log-utils';
import { OrderEvent } from '../types';

dayjs.extend(utc);
dayjs.extend(timezone);

type OrderType = OrderEventFragment['order'];

const SCAN_ORDER_EVENT_TYPES = [
  OrderEventType.OrderScanned,
  OrderEventType.PieceScanned,
];

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 15px;
  padding-bottom: 15px !important;
`);

const TableCellCustom = styled(TableCell)(`
  fontSize: 12px;
`);

export const mapOrderSourceToString = (order: OrderType): string => {
  if (isNil(order)) return '';
  switch (order?.source) {
    case OrderSource.DocumentScanning:
      return 'Doc scan';
    case OrderSource.ManuallyEntered:
      return 'Manual entry';
    case OrderSource.Edi:
      return 'EDI';
    case OrderSource.Api:
      return 'API';
    case OrderSource.Consolidation:
      return 'Billing review consolidation';
    case OrderSource.FromTemplate:
    case OrderSource.IsTemplate:
      return `Recurring order (${order.name})`;
    case OrderSource.CsvImport:
      return 'CSV import';
    case OrderSource.Migrated:
      return 'TMS migration';
    case OrderSource.Rebilled:
      return 'Rebilled';
    case OrderSource.Quote:
      return 'Quote';
    default:
      return exhaustive(order.source);
  }
};

const StorageOrderAuditLogCard = ({
  eventType,
  selectedVersionUuid,
  orderEvent,
  previousOrderEvent,
}: {
  eventType: OrderEventType;
  selectedVersionUuid?: string | undefined;
  orderEvent: OrderEvent;
  previousOrderEvent: OrderEvent | null | undefined;
}) => {
  const { segment, email } = useMe();
  const [showJsonDialog, setShowJsonDialog] = useState<boolean>(false);
  const [logSearchInput, showEdits] = useVersionHistoryStore(
    (state) => [state.logSearchInput, state.showEdits],
    shallow,
  );
  const previousOrderEventSnapshot = previousOrderEvent?.snapshot;
  const snapshotDifferences: SnapshotDifferenceData | null = useMemo(
    () =>
      !isNil(previousOrderEventSnapshot) && !isNil(orderEvent.snapshot)
        ? getSnapshotDifferences(
            previousOrderEventSnapshot,
            orderEvent.snapshot,
          )
        : null,
    [previousOrderEventSnapshot, orderEvent.snapshot],
  );

  const getNewValueString = (diff: SnapshotDifference) => {
    if (isNil(diff.value)) return '';
    const newValueString =
      diff.noCase === true ? diff.value : capitalCase(diff.value.toString());
    return newValueString;
  };

  const getNewFieldString = (diff: SnapshotDifference) => {
    let newValueString =
      diff.noCase === true ? diff.field : capitalCase(diff.field);
    if (diff.field === defaultBOLName) {
      newValueString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';
    }
    if (diff.field === defaultSecondaryRefName) {
      newValueString =
        segment === Segment.Cartage ? 'Ref Number' : defaultSecondaryRefName;
    }
    return newValueString;
  };

  const shouldShow = useMemo(() => {
    if (isEmpty(logSearchInput)) return true;
    const searchInput = logSearchInput.toLowerCase();
    if (eventType === OrderEventType.OrderSnapshot) {
      if (isNil(snapshotDifferences)) return false;
      return (
        snapshotDifferences?.order.some(
          (difference) =>
            difference.field.toLowerCase().includes(searchInput) ||
            difference.value?.toString().toLowerCase().includes(searchInput),
        ) ||
        snapshotDifferences?.shipments.some((shipmentDiff) => {
          return (
            shipmentDiff.differences.some(
              (difference) =>
                difference.field.toLowerCase().includes(searchInput) ||
                difference.value
                  ?.toString()
                  .toLowerCase()
                  ?.includes(searchInput),
            ) || shipmentDiff.stopType?.toLowerCase().includes(searchInput)
          );
        })
      );
    }
    return (
      capitalCase(orderEvent.eventType).toLowerCase().includes(searchInput) ||
      orderEvent.message.toLowerCase().includes(searchInput)
    );
  }, [
    eventType,
    logSearchInput,
    orderEvent.eventType,
    orderEvent.message,
    snapshotDifferences,
  ]);

  const isInternalUser = isPalletAdmin(email ?? '');

  if (
    orderEvent.eventType === OrderEventType.OrderSnapshot &&
    previousOrderEvent?.ignoreSnapshot === true
  ) {
    return null;
  }
  const formattedTime = SCAN_ORDER_EVENT_TYPES.includes(eventType)
    ? dayjs(orderEvent.createdAt).format('MM/DD/YY h:mm:ssa')
    : dayjs(orderEvent.createdAt).format('MM/DD/YY h:mma');

  return (
    <Card
      variant="outlined"
      sx={{
        background:
          selectedVersionUuid === orderEvent.uuid
            ? 'rgba(37, 48, 82, 0.08)'
            : undefined,
        display: shouldShow === true ? undefined : 'none',
      }}
    >
      <CardContentSpecialPadding>
        <Stack direction="column" spacing={1}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                {formattedTime}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ float: 'right' }}
                color="text.secondary"
                variant="caption"
              >
                {formatOrderEventActor(orderEvent)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="body2">
                    {eventType === OrderEventType.OrderSnapshot
                      ? 'Order Edited'
                      : capitalCase(eventType)}
                  </Typography>
                </Grid>
                <Grid item>
                  {isInternalUser && !isNil(orderEvent.snapshot) && (
                    <>
                      <Dialog
                        onClose={() => {
                          setShowJsonDialog(false);
                        }}
                        open={showJsonDialog}
                      >
                        <DialogTitle>Raw JSON Snapshot Data</DialogTitle>
                        <JsonView
                          data={JSON.parse(orderEvent.snapshot)}
                          shouldInitiallyExpand={(level, value, field) =>
                            level === 0 || field === 'shipments'
                          }
                          style={defaultStyles}
                        />
                      </Dialog>
                      <Button
                        onClick={() => {
                          setShowJsonDialog(true);
                        }}
                        size="small"
                      >
                        View JSON
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {renderOrderEventMessage(orderEvent)}
              </Typography>
            </Grid>
            {eventType === OrderEventType.OrderSnapshot && (
              <Grid item xs={12}>
                {!isNil(snapshotDifferences) ? (
                  <Table
                    size="small"
                    sx={{ display: showEdits ? undefined : 'none' }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Entity</TableCellCustom>
                        <TableCellCustom>Action</TableCellCustom>
                        <TableCellCustom>Field</TableCellCustom>
                        <TableCellCustom>New Value</TableCellCustom>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {snapshotDifferences.order.map((diff) => (
                        <TableRow key={diff.field}>
                          <TableCellCustom>Order</TableCellCustom>
                          <TableCellCustom>
                            {sentenceCase(diff.method)}
                          </TableCellCustom>
                          <TableCellCustom>
                            {getNewFieldString(diff)}
                          </TableCellCustom>
                          <TableCellCustom>
                            {getNewValueString(diff)}
                          </TableCellCustom>
                        </TableRow>
                      ))}
                      {snapshotDifferences.shipments.map((shipment) => {
                        return shipment.differences.map((shipmentDiff) => {
                          return (
                            <TableRow key={shipmentDiff.field}>
                              <TableCellCustom>
                                {sentenceCase(shipment.stopType ?? 'Shipment')}
                              </TableCellCustom>
                              <TableCellCustom>
                                {sentenceCase(shipmentDiff.method)}{' '}
                              </TableCellCustom>
                              <TableCellCustom>
                                {shipmentDiff.field}
                              </TableCellCustom>
                              <TableCellCustom>
                                {getNewValueString(shipmentDiff)}
                              </TableCellCustom>
                            </TableRow>
                          );
                        });
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant="caption">
                    {!isNil(orderEvent.order)
                      ? mapOrderSourceToString(orderEvent.order)
                      : ''}
                  </Typography>
                )}
              </Grid>
            )}
            {eventType === OrderEventType.OrderCreated && (
              <Grid item xs={12}>
                <Typography variant="caption">
                  {!isNil(orderEvent.order)
                    ? mapOrderSourceToString(orderEvent.order)
                    : ''}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Stack>
      </CardContentSpecialPadding>
    </Card>
  );
};

export default React.memo(StorageOrderAuditLogCard);
