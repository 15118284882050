import { Checkbox, Stack, Typography } from '@mui/material';
import React from 'react';

const RouteShowCompleteStopsCheckboxComponent = ({
  showCompleteStops,
  onChange,
}: {
  showCompleteStops: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography sx={{ fontSize: 14 }}>Show complete stops</Typography>
      <Checkbox
        defaultChecked={showCompleteStops}
        size="small"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.checked)
        }
      />
    </Stack>
  );
};

export const RouteShowCompleteStopsCheckbox = React.memo(
  RouteShowCompleteStopsCheckboxComponent,
);
