import { ArrowRightAlt } from '@mui/icons-material';
import {
  Box,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import { TariffType, TariffZoneType } from '../../../../generated/graphql';
import { TariffModalMode } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';
import styles from './styles';

const TariffGroupGridHeader = () => {
  const [loading, setLoading] = useState(true);

  const [tariffType, zoneType, locationZonesData, xRangeValues, modalMode] =
    useTariffGroupStore(
      (state) => [
        state.tariffType,
        state.zoneType,
        state.locationZonesData,
        state.xRangeValues,
        state.modalMode,
      ],
      shallow,
    );

  useEffect(() => {
    if (!loading) {
      return;
    }
    if (
      modalMode === TariffModalMode.CREATE ||
      ((zoneType === TariffZoneType.Miles || locationZonesData.length > 0) &&
        !isNil(xRangeValues))
    ) {
      setLoading(false);
    }
  }, [locationZonesData.length, xRangeValues, modalMode, loading, zoneType]);

  if (loading) {
    return null;
  }

  const getTariffTypeRangeCopy = (type: TariffType) => {
    switch (type) {
      case TariffType.PerHundredPounds:
        return 'Weight Range';
      case TariffType.PerPiece:
        return 'Piece Range';
      case TariffType.PerCubicFoot:
        return 'Volume Range';
      case TariffType.NoUnits:
        return '';
      default:
        return exhaustive(type);
    }
  };

  return (
    <TableHead aria-label="tariff-table">
      <TableRow>
        {![TariffType.NoUnits, TariffType.PerCubicFoot].includes(
          tariffType,
        ) && (
          <TableCell align="center">
            <Typography>{getTariffTypeRangeCopy(tariffType)}</Typography>
          </TableCell>
        )}
        {zoneType === TariffZoneType.Universal && <TableCell align="center" />}
        {zoneType === TariffZoneType.Location &&
          locationZonesData.map((zone) => (
            <TableCell key={zone.uuid} align="center">
              <Typography>{zone.name}</Typography>
              {zone?.isLhLaneActive === false && (
                <Typography sx={{ fontSize: '10px' }}>(Archived)</Typography>
              )}
            </TableCell>
          ))}
        {zoneType === TariffZoneType.Miles &&
          xRangeValues.map((value, i) => {
            if (i === xRangeValues.length - 1) {
              return (
                <TableCell key={value} align="center">
                  <Typography>{xRangeValues[i - 1] ?? 0}+ mi</Typography>
                </TableCell>
              );
            }
            return (
              <TableCell key={value} align="center">
                <Box sx={styles.center}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{xRangeValues[i - 1] ?? 0}</Typography>
                    <ArrowRightAlt />
                    <Typography>{value} mi</Typography>
                  </Stack>
                </Box>
              </TableCell>
            );
          })}
        {tariffType !== TariffType.NoUnits && <TableCell align="center" />}
      </TableRow>
    </TableHead>
  );
};

export default TariffGroupGridHeader;
