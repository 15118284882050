import { Chip } from '@mui/material';
import { green, lightBlue, orange, grey } from '@mui/material/colors';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { RouteInfoFragment, RouteStatus } from '../../../../generated/graphql';

const RouteStatusChip = ({
  routeInfo,
  minified,
}: {
  routeInfo: RouteInfoFragment;
  minified?: boolean;
}) => {
  let color;
  let minifiedColor;
  let backgroundColor;
  let fontWeight;
  let label = 'Unknown';
  switch (routeInfo.status) {
    case RouteStatus.NotStarted:
      label = 'NOT STARTED';
      minifiedColor = grey['500'];
      backgroundColor = grey['50'];
      fontWeight = 400;
      break;
    case RouteStatus.InProgress:
      color = lightBlue['900'];
      minifiedColor = lightBlue['500'];
      backgroundColor = lightBlue['50'];
      label = 'IN PROG.';
      break;
    case RouteStatus.Complete:
      color = green['900'];
      minifiedColor = green['600'];
      backgroundColor = '#BBF7D0';
      label = 'DONE';
      break;
    case RouteStatus.Incomplete:
      color = orange['900'];
      minifiedColor = orange['500'];
      backgroundColor = orange['50'];
      label = 'INCOMP.';
      break;
    default:
      return exhaustive(routeInfo.status);
  }

  label += ` ${
    routeInfo.totalStopsCompleted +
    routeInfo.totalStopsCancelled +
    routeInfo.totalStopsFailed
  }/${routeInfo.totalStops}`;

  if (minified === true) {
    return (
      <Chip
        size="small"
        sx={{ height: 8, width: 8, backgroundColor: minifiedColor }}
        label=""
      />
    );
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      sx={{
        height: 18,
        width: 'fit-content',
        fontSize: '12px',
        fontWeight: fontWeight ?? '500',
        borderColor: color,
        color,
        backgroundColor,
      }}
      label={label}
    />
  );
};

export default RouteStatusChip;
