import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil, toUpper } from 'lodash';
import pluralize from 'pluralize';
import { Fragment, ReactNode, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import DateField from '../../../../common/components/date-field';
import { MarkOrderAsReadyToInvoiceDialog } from '../../../../common/components/modals/mark-order-as-ready-to-invoice-dialog';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  convertOrdersToStopsForMarkReadyToInvoiceDialog,
  isNilOrEmptyString,
} from '../../../../common/utils/utils';
import {
  OrderWithPaperworkDocument,
  OutstandingOrderFragmentFragment,
  PermissionResource,
  StandardOrderFragmentFragment,
  StopUpdateInput,
  useMarkOrderAsCompleteForceMutation,
  useUpdateLegMutation,
  useUpdatePaperworkCompletedForStopsMutation,
  useUpdateStopMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import OrderDocuments from '../../../orders/components/order-form/components/overview/documents';
import CannotCompleteOrderModal from '../../../orders/components/order-form/components/overview/modals/cannot-complete-order-modal';
import { calculateWaitTime } from '../../../orders/components/order-form/components/overview/stops/utils';
import Comments from '../../../orders/components/order-form/components/right-sidebar/comments';
import PrintMenu from '../../../orders/components/order-form/components/right-sidebar/print-menu';
import { StopType } from '../../../orders/components/order-form/forms/stop-type';
import {
  OrderFormValues,
  StopValues,
} from '../../../orders/components/order-form/forms/types';
import useSaveOrderBrm from '../../../orders/components/order-form/forms/use-save-order-brm';
import { getCannotCompleteOrder } from '../../../orders/components/order-form/forms/utils';
import OrderAuditLogList from '../../../orders/components/standard/components/version-history/components/order-audit-log/order-audit-log-list';
import useBillingReviewStore from '../../billing-review-store';
import EditTextField from './edit-text-field';
import OutstandingOrderDetails from './outstanding-order-details';
import OutstandingOrderStatusBanner from './outstanding-order-status-banner';
import Packages from './packages';

const useStyles = () => {
  return {
    boxFlexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxFlexCol: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    packageItem: {
      borderLeft: '2px solid lightgray',
      paddingLeft: 1,
      paddingRight: 1,
      textAlign: 'center',
    },
    step: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.MuiStepIcon-text': {
        display: 'none',
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.Mui-completed': {
        fontWeight: 'normal',
      },
    },
  };
};

const CardHeaderNoPadding = styled(CardHeader)(`
  padding-bottom: 0;
`);

type StopFieldProps = {
  name: string;
  value: ReactNode;
};

// TODO: Adapt this for all fields in the page.
const StopField = ({ name, value }: StopFieldProps) => {
  return (
    <Stack
      gap={0.5}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography fontWeight="bold">{name}</Typography>
      {typeof value === 'string' ? <Typography>{value}</Typography> : value}
    </Stack>
  );
};

enum OrderTab {
  Overview = 'Overview',
  Notes = 'Notes',
  Documents = 'Documents',
  AuditLog = 'Audit Log',
}

type OutstandingOrderOverviewProps = {
  isConsolidated: boolean;
  isStatusIncomplete: boolean;
  cannotFinalizeBecausePaperworkIncomplete: boolean;
  currentOrder:
    | StandardOrderFragmentFragment
    | OutstandingOrderFragmentFragment
    | null;
  saveOrderLoading: boolean;
};

const OutstandingOrderOverview = ({
  isConsolidated,
  isStatusIncomplete,
  cannotFinalizeBecausePaperworkIncomplete,
  currentOrder,
  saveOrderLoading,
}: OutstandingOrderOverviewProps) => {
  const theme = useTheme();
  const styles = useStyles();
  const { saveOrder } = useSaveOrderBrm();
  const [markOrderAsCompleteForce] = useMarkOrderAsCompleteForceMutation();
  const [updatePaperworkCompleteForStops] =
    useUpdatePaperworkCompletedForStopsMutation({
      refetchQueries: [OrderWithPaperworkDocument],
    });

  const [openCollapse, setOpenCollapse] = useState(false);
  const [showPrintMenu, setShowPrintMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState<OrderTab>(OrderTab.Overview);
  const printButtonRef = useRef<HTMLButtonElement>(null);
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );
  const ffShowProrateConsolidationFlow = useFeatureFlag(
    FeatureFlag.FF_SHOW_PRORATE_CONSOLIDATION_FLOW,
  );
  const [updateStop] = useUpdateStopMutation();
  const [updateLeg] = useUpdateLegMutation();

  const [setOpenedOrderUuid] = useGlobalStore((state) => [
    state.setCurrentOrderUuid,
  ]);
  const [outstandingOrdersInPage, openedOutstandingOrderUuid] =
    useBillingReviewStore(
      (state) => [
        state.outstandingOrdersInPage,
        state.openedOutstandingOrderUuid,
      ],
      shallow,
    );

  const [isEditingOrderDetails, setIsEditingOrderDetails] = useState(false);

  const { watch, setValue } = useFormContext<OrderFormValues>();
  const stops: StopValues[] = watch('stops') ?? [];
  const hawb = watch('shipperBillOfLadingNumber');
  const serviceUuid = watch('serviceUuid');

  const [cannotCompleteOrderModalOpen, setCannotCompleteOrderModalOpen] =
    useState(false);
  const [cannotCompleteOrderModalMessage, setCannotCompleteOrderModalMessage] =
    useState<string | undefined>();
  const [showMarkAsReadyToInvoiceDialog, setShowMarkAsReadyToInvoiceDialog] =
    useState(false);

  const currentOutstandingOrder = useMemo(() => {
    return outstandingOrdersInPage?.find(
      (o) => o.uuid === openedOutstandingOrderUuid,
    );
  }, [outstandingOrdersInPage, openedOutstandingOrderUuid]);

  const onMarkReadyToInvoice = async () => {
    const cannotCompleteOrderMessage = getCannotCompleteOrder({
      stops,
      terminalsEnabled,
      ffRequireTransferAddress,
    });
    if (!isNil(cannotCompleteOrderMessage)) {
      setCannotCompleteOrderModalMessage(cannotCompleteOrderMessage);
      setCannotCompleteOrderModalOpen(true);
      return;
    }

    const everyStopCompleted =
      currentOrder?.shipments.every(
        (s) => !isNil(s.legs?.at(0)?.endStop.completedAt),
      ) ?? false;
    if (!everyStopCompleted) {
      setShowMarkAsReadyToInvoiceDialog(true);
      return;
    }
    if (!isNil(openedOutstandingOrderUuid)) {
      await markOrderAsCompleteForce({
        variables: {
          markOrderAsCompleteForceInput: {
            uuid: openedOutstandingOrderUuid,
          },
        },
      });
      await saveOrder({});
    }
  };

  const onMarkPaperworkComplete = async () => {
    if (!isNil(stops) && !isNil(openedOutstandingOrderUuid)) {
      const res = await updatePaperworkCompleteForStops({
        variables: {
          updatePaperworkCompleteInput: {
            uuid: openedOutstandingOrderUuid,
            paperworkCompleted: true,
          },
        },
      });
      if (!isNil(res.errors)) {
        setCannotCompleteOrderModalMessage(
          'Failed to mark paperwork as complete',
        );
        setCannotCompleteOrderModalOpen(true);
        return;
      }
      stops.forEach((s, idx) =>
        setValue(`stops.${idx}.paperworkMarkedComplete`, true),
      );
      await saveOrder({});
    }
  };

  let incompleteBanner = null;
  if (isStatusIncomplete) {
    incompleteBanner = (
      <OutstandingOrderStatusBanner
        text="This order is not complete"
        textIsBold
        buttonLabel="Mark complete"
        onClick={onMarkReadyToInvoice}
      />
    );
  } else if (cannotFinalizeBecausePaperworkIncomplete) {
    incompleteBanner = (
      <OutstandingOrderStatusBanner
        text="This order's paperwork is not complete"
        textIsBold
        buttonLabel="Mark paperwork complete"
        onClick={onMarkPaperworkComplete}
      />
    );
  }

  let ordersProratedWithBanner = null;
  if (
    ffShowProrateConsolidationFlow &&
    !isEmpty(currentOrder?.ordersProratedWith)
  ) {
    ordersProratedWithBanner = (
      <OutstandingOrderStatusBanner
        text={`This order has been consolidated with ${currentOrder?.ordersProratedWith?.length ?? 0} other ${pluralize('order', currentOrder?.ordersProratedWith?.length ?? 0)}. Weight-based charges have been pro-rated by weight and set as flat rates.`}
        dropdownLabel="VIEW CONSOLIDATED ORDERS"
        dropdownMenuItems={currentOrder?.ordersProratedWith?.map((order) => ({
          label: order.standardOrderFields?.shipperBillOfLadingNumber ?? '',
          onClick: () => {
            setOpenedOrderUuid(order.uuid);
          },
        }))}
      />
    );
  }

  return (
    <Card
      variant="outlined"
      style={{ height: '100%', maxHeight: '100%', overflow: 'scroll' }}
    >
      {!isNil(openedOutstandingOrderUuid) && (
        <MarkOrderAsReadyToInvoiceDialog
          handleClose={() => {
            setShowMarkAsReadyToInvoiceDialog(false);
          }}
          open={showMarkAsReadyToInvoiceDialog}
          stops={convertOrdersToStopsForMarkReadyToInvoiceDialog(currentOrder)}
          orderUuid={openedOutstandingOrderUuid}
          setShowMarkedAsReadyError={() => null}
          setShowMarkedAsReadySuccess={async () => {
            await saveOrder({ forceRefresh: true });
          }}
          updateStopCompletedAtInState={(idx, stopValue) => {
            setValue(`stops.${idx}.completedAt`, stopValue);
          }}
          updateStopSigneeInState={(idx, stopValue) => {
            setValue(`stops.${idx}.proofOfDeliverySignee`, stopValue);
          }}
        />
      )}
      <CannotCompleteOrderModal
        open={cannotCompleteOrderModalOpen}
        setOpen={setCannotCompleteOrderModalOpen}
        message={cannotCompleteOrderModalMessage}
        setMessage={setCannotCompleteOrderModalMessage}
      />
      <PrintMenu
        showPrintMenu={showPrintMenu}
        onClose={() => {
          setShowPrintMenu(false);
        }}
        buttonRef={printButtonRef.current}
        isEditMode
      />
      <Tabs
        value={selectedTab}
        onChange={(_, val) => setSelectedTab(val)}
        variant="fullWidth"
      >
        <Tab label={OrderTab.Overview} value={OrderTab.Overview} />
        <Tab label={OrderTab.Notes} value={OrderTab.Notes} />
        <Tab label={OrderTab.Documents} value={OrderTab.Documents} />
        <Tab label={OrderTab.AuditLog} value={OrderTab.AuditLog} />
      </Tabs>
      {selectedTab === OrderTab.Overview && (
        <CardHeaderNoPadding
          title={
            <Box sx={{ ...styles.boxFlexRow, alignItems: 'flex-start' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                  Order Details
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    disabled={
                      (saveOrderLoading === true ||
                        isNilOrEmptyString(serviceUuid) ||
                        isNilOrEmptyString(hawb)) &&
                      isEditingOrderDetails
                    }
                    onClick={async () => {
                      setIsEditingOrderDetails(!isEditingOrderDetails);
                    }}
                  >
                    {!isEditingOrderDetails ? (
                      <ModeEditIcon sx={{ fontSize: '20px' }} />
                    ) : (
                      <CheckIcon sx={{ fontSize: '20px' }} />
                    )}
                  </IconButton>
                  {saveOrderLoading === true && isEditingOrderDetails && (
                    <CircularProgress size={20} />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <Button
                  size="small"
                  ref={printButtonRef}
                  variant="outlined"
                  onClick={() => setShowPrintMenu(!showPrintMenu)}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{ height: '30px' }}
                >
                  Print
                </Button>
                {!isConsolidated ? (
                  <Button
                    onClick={async () => {
                      await saveOrder({});
                      setOpenedOrderUuid(openedOutstandingOrderUuid);
                    }}
                    endIcon={<OpenInNewIcon />}
                  >
                    View
                  </Button>
                ) : (
                  <Box sx={styles.boxFlexCol}>
                    <Box sx={styles.boxFlexRow}>
                      <Button
                        onClick={() => setOpenCollapse(!openCollapse)}
                        endIcon={
                          openCollapse ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )
                        }
                        sx={{ py: 0 }}
                      >
                        View consolidated orders
                      </Button>
                    </Box>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                      <Box sx={styles.boxFlexCol}>
                        {currentOutstandingOrder?.consolidatedOrders?.map(
                          (o) => (
                            <Button
                              key={o.uuid}
                              onClick={async () => {
                                setOpenedOrderUuid(o.uuid);
                              }}
                              endIcon={<OpenInNewIcon />}
                              sx={{ pt: 0 }}
                            >
                              Open{' '}
                              {o.standardOrderFields.shipperBillOfLadingNumber}
                            </Button>
                          ),
                        )}
                      </Box>
                    </Collapse>
                  </Box>
                )}
              </Box>
            </Box>
          }
        />
      )}
      <CardContent>
        {selectedTab === OrderTab.Overview && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <OutstandingOrderDetails
              currentOrder={currentOrder}
              isConsolidated={isConsolidated}
              isEditingOrderDetails={isEditingOrderDetails}
              incompleteBanner={incompleteBanner}
              ordersProratedWithBanner={ordersProratedWithBanner}
            />
            <Divider />
            <Grid container>
              <Packages saveOrderLoading={saveOrderLoading} />
            </Grid>
            <Divider />
            <Grid container>
              {stops.map((stop, i) => {
                const idx = stops.findIndex(
                  (itrStop) => itrStop.uuid === stop.uuid,
                );
                const { waitTimeHours, waitTimeMinutes } = calculateWaitTime({
                  arrivedAt: stop.arrivedAt,
                  completedAt: stop.completedAt,
                });

                const addressText = !isNil(stop?.address) && (
                  <>
                    <Grid item xs={12}>
                      <Typography fontWeight="bold">Address</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: '10px' }}>
                      <Typography>{stop?.address?.name}</Typography>
                      <Typography>
                        {transformAddressToFullAddressString(stop?.address)}
                      </Typography>
                    </Grid>
                  </>
                );

                if (
                  stop.stopType === StopType.PartnerCarrierDropoff ||
                  stop.stopType === StopType.PartnerCarrierPickup ||
                  stop.stopType === StopType.None
                ) {
                  return (
                    <Grid key={stop.uuid} item xs={12} width="100%">
                      <Box p="5px" bgcolor={theme.palette.grey[100]}>
                        <Typography fontSize="16px">
                          {stop.stopType === StopType.None
                            ? `${i === 0 ? 'NO INBOUND' : 'NO OUTBOUND'}`
                            : toUpper(stop.stopType)}
                        </Typography>
                      </Box>
                      <Box p="5px">{addressText}</Box>
                    </Grid>
                  );
                }

                return (
                  <Fragment key={stop.uuid}>
                    <Grid
                      item
                      xs={12}
                      bgcolor={theme.palette.grey[100]}
                      width="100%"
                      p="5px"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography fontSize="16px">
                          {toUpper(stop.stopType)}
                        </Typography>
                        <DateField
                          date={stop.completedAt ?? null}
                          hideHHMM
                          setDate={(date) => {
                            setValue(`stops.${idx}.completedAt`, date);
                            setValue(`stops.${idx}.arrivedAt`, date);
                          }}
                          editable={canWriteOrders}
                          closeEditingOnChange={openedOutstandingOrderUuid}
                          onInstantiateDate={() => {
                            setValue(`stops.${idx}.arrivedAt`, new Date());
                            setValue(`stops.${idx}.completedAt`, new Date());
                          }}
                          onFinishEditing={(date) => {
                            if (!isNil(stop.uuid)) {
                              updateStop({
                                variables: {
                                  updateStopInput: {
                                    stopUpdateInput: {
                                      uuid: stop.uuid,
                                      completedAt: date,
                                      arrivedAt: date,
                                    },
                                  },
                                },
                              });
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid container p={1} gap={1}>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        gap={1}
                      >
                        <StopField
                          name="Arrival"
                          value={
                            <DateField
                              date={stop.arrivedAt ?? null}
                              hideMMDDYYY
                              setDate={(date) => {
                                setValue(`stops.${idx}.arrivedAt`, date);
                              }}
                              editable={canWriteOrders}
                              closeEditingOnChange={openedOutstandingOrderUuid}
                              onInstantiateDate={() => {
                                setValue(`stops.${idx}.arrivedAt`, new Date());
                              }}
                              onFinishEditing={(date) => {
                                let stopUpdateInput: StopUpdateInput = {
                                  uuid: stop.uuid,
                                  arrivedAt: date,
                                };
                                if (dayjs(date).isAfter(stop.completedAt)) {
                                  setValue(`stops.${idx}.completedAt`, date);
                                  stopUpdateInput = {
                                    ...stopUpdateInput,
                                    completedAt: date,
                                  };
                                }
                                if (!isNil(stop.uuid)) {
                                  updateStop({
                                    variables: {
                                      updateStopInput: {
                                        stopUpdateInput,
                                      },
                                    },
                                  });
                                }
                              }}
                            />
                          }
                        />
                        <StopField
                          name="Completed"
                          value={
                            <DateField
                              date={stop.completedAt ?? null}
                              hideMMDDYYY
                              setDate={(date) => {
                                setValue(`stops.${idx}.completedAt`, date);
                              }}
                              editable={canWriteOrders}
                              closeEditingOnChange={openedOutstandingOrderUuid}
                              onInstantiateDate={() => {
                                setValue(
                                  `stops.${idx}.completedAt`,
                                  new Date(),
                                );
                              }}
                              onFinishEditing={(date) => {
                                let stopUpdateInput: StopUpdateInput = {
                                  uuid: stop.uuid,
                                  completedAt: date,
                                };
                                if (dayjs(date).isBefore(stop.arrivedAt)) {
                                  setValue(`stops.${idx}.arrivedAt`, date);
                                  stopUpdateInput = {
                                    ...stopUpdateInput,
                                    arrivedAt: date,
                                  };
                                }
                                if (!isNil(stop.uuid)) {
                                  updateStop({
                                    variables: {
                                      updateStopInput: {
                                        stopUpdateInput,
                                      },
                                    },
                                  });
                                }
                              }}
                            />
                          }
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        gap={1}
                      >
                        <StopField
                          name="Appt. Start"
                          value={
                            <DateField
                              date={stop.appointmentTime ?? null}
                              hideMMDDYYY
                              setDate={(date) => {
                                setValue(`stops.${idx}.appointmentTime`, date);
                              }}
                              editable={canWriteOrders}
                              closeEditingOnChange={openedOutstandingOrderUuid}
                              onInstantiateDate={() => {
                                setValue(
                                  `stops.${idx}.appointmentTime`,
                                  new Date(),
                                );
                              }}
                              onFinishEditing={(date) => {
                                if (!isNil(stop.uuid)) {
                                  updateStop({
                                    variables: {
                                      updateStopInput: {
                                        stopUpdateInput: {
                                          uuid: stop.uuid,
                                          appointmentTime: date,
                                        },
                                      },
                                    },
                                  });
                                }
                              }}
                            />
                          }
                        />
                        <StopField
                          name="Appt. End"
                          value={
                            <DateField
                              date={stop.endAppointmentTime ?? null}
                              hideMMDDYYY
                              setDate={(date) => {
                                setValue(
                                  `stops.${idx}.endAppointmentTime`,
                                  date,
                                );
                              }}
                              editable={canWriteOrders}
                              closeEditingOnChange={openedOutstandingOrderUuid}
                              onInstantiateDate={() => {
                                setValue(
                                  `stops.${idx}.endAppointmentTime`,
                                  new Date(),
                                );
                              }}
                              onFinishEditing={(date) => {
                                if (!isNil(stop.uuid)) {
                                  updateStop({
                                    variables: {
                                      updateStopInput: {
                                        stopUpdateInput: {
                                          uuid: stop.uuid,
                                          endAppointmentTime: date,
                                        },
                                      },
                                    },
                                  });
                                }
                              }}
                            />
                          }
                        />
                      </Stack>
                      <StopField
                        name="POD"
                        value={
                          <EditTextField
                            fieldValue={stop.proofOfDeliverySignee}
                            setFieldValue={(name) => {
                              setValue(
                                `stops.${idx}.proofOfDeliverySignee`,
                                name,
                              );
                            }}
                            onFinishEditing={(name) => {
                              if (!isNil(stop.uuid)) {
                                updateStop({
                                  variables: {
                                    updateStopInput: {
                                      stopUpdateInput: {
                                        uuid: stop.uuid,
                                        proofOfDeliverySignee: name,
                                      },
                                    },
                                  },
                                });
                              }
                            }}
                          />
                        }
                      />
                      <StopField
                        name="Wait"
                        value={
                          !isNil(waitTimeHours) && !isNil(waitTimeMinutes)
                            ? `${
                                waitTimeHours > 0 ? `${waitTimeHours}h, ` : ''
                              }${waitTimeMinutes}m`
                            : '-'
                        }
                      />
                      <StopField
                        name="Driver"
                        value={
                          !isNilOrEmptyString(stop?.driverName)
                            ? stop?.driverName
                            : '-'
                        }
                      />
                      <StopField
                        name="Vehicle"
                        value={
                          !isNilOrEmptyString(stop?.equipmentNames)
                            ? stop?.equipmentNames
                            : '-'
                        }
                      />
                      <StopField
                        name="Mileage"
                        value={
                          <EditTextField
                            endAdornment="mi"
                            isNumber
                            fieldValue={stop?.miles?.toString()}
                            setFieldValue={(value) => {
                              let parsedValue: number | null =
                                parseFloat(value);
                              if (Number.isNaN(parsedValue)) {
                                parsedValue = null;
                              }
                              setValue(`stops.${idx}.miles`, parsedValue);
                            }}
                            onFinishEditing={(value) => {
                              if (!isNil(stop.uuid)) {
                                let parsedValue: number | null = parseInt(
                                  value,
                                  10,
                                );
                                if (Number.isNaN(parsedValue)) {
                                  parsedValue = null;
                                }
                                updateLeg({
                                  variables: {
                                    updateLegInput: {
                                      legUpdateInput: {
                                        uuid: stop.legUuid,
                                        miles: parsedValue,
                                      },
                                    },
                                  },
                                });
                              }
                            }}
                          />
                        }
                      />
                      {addressText}
                      <StopField
                        name="Driver Notes"
                        value={stop?.notes ?? '-'}
                      />
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </Box>
        )}
        {selectedTab === OrderTab.Documents && (
          <OrderDocuments isEditMode showHeader={false} />
        )}
        {selectedTab === OrderTab.Notes && (
          <Comments isEditMode showHeader={false} />
        )}
        {selectedTab === OrderTab.AuditLog && (
          <OrderAuditLogList orderUuid={openedOutstandingOrderUuid ?? null} />
        )}
      </CardContent>
    </Card>
  );
};

export default OutstandingOrderOverview;
