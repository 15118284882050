import {
  CircularProgress,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import {
  FindInvoicesSort,
  FindInvoicesSortFields,
  SortDirection,
  useInvoicesForContactLazyQuery,
} from '../../../../generated/graphql';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import useInvoicesStore from '../../invoices-store';
import { InvoiceBalanceStatus } from '../../types/types';
import { SingleColumnInvoiceTableSortLabel } from '../../utils';
import ContactInvoiceListRow from './contact-invoice-list-row';

const DEFAULT_INVOICES_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50];

interface InvoiceListProps {
  contactUuid: string | undefined;
}

const InvoiceList = ({ contactUuid }: InvoiceListProps) => {
  const { companyConfiguration } = useMe();
  const { height } = useWindowDimensions();
  const { fetchInvoiceTotalsByUuids } = useInvoiceTotals();
  const [shouldRefreshInvoiceList, setShouldRefreshInvoiceList] =
    useInvoicesStore(
      (state) => [
        state.shouldRefreshInvoiceList,
        state.setShouldRefreshInvoiceList,
      ],
      shallow,
    );
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_INVOICES_PAGE_SIZE,
  );
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<FindInvoicesSort>({
    sortBy: FindInvoicesSortFields.InvoiceNameOrJournalNumber,
    sortDirection: SortDirection.Asc,
  });
  const [invoiceBalanceStatus, setInvoiceBalanceStatus] =
    useState<InvoiceBalanceStatus>(InvoiceBalanceStatus.OPEN);
  const ffInvoicesOpenClosedFilter = useFeatureFlag(
    FeatureFlag.FF_INVOICES_OPEN_CLOSED_FILTER,
  );

  const [getInvoices, { data: invoicesData, loading }] =
    useInvoicesForContactLazyQuery();

  const fetchInvoices = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    let hasZeroBalance;
    if (ffInvoicesOpenClosedFilter) {
      if (invoiceBalanceStatus === InvoiceBalanceStatus.CLOSED) {
        hasZeroBalance = true;
      }
      if (invoiceBalanceStatus === InvoiceBalanceStatus.OPEN) {
        hasZeroBalance = false;
      }
    }
    await getInvoices({
      variables: {
        billingPartyContactUuid: contactUuid,
        first,
        after,
        last,
        before,
        sorts: [sort],
        hasZeroBalance,
      },
    });
  };

  const prev = async () => {
    await fetchInvoices({
      last: rowsPerPage,
      before: invoicesData?.invoices.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchInvoices({
      first: rowsPerPage,
      after: invoicesData?.invoices.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchInvoices({ first: rowsPerPage });
    setPage(0);
  };

  useEffect(() => {
    const uuids = invoicesData?.invoices.edges.map(
      ({ node: invoice }) => invoice.uuid,
    );
    if (!isNil(uuids)) {
      fetchInvoiceTotalsByUuids(uuids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesData]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUuid, rowsPerPage, invoiceBalanceStatus]);

  useEffect(() => {
    if (shouldRefreshInvoiceList) {
      refresh();
      setShouldRefreshInvoiceList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshInvoiceList]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {ffInvoicesOpenClosedFilter && (
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel id="invoice-status-label">Invoices</InputLabel>
              <Select
                labelId="invoice-status-label"
                label="Invoices"
                onChange={(e) => {
                  setInvoiceBalanceStatus(
                    e.target.value as InvoiceBalanceStatus,
                  );
                }}
                value={invoiceBalanceStatus}
                required
                size="small"
                sx={{ backgroundColor: 'white' }}
              >
                {Object.values(InvoiceBalanceStatus).map((balanceStatus) => (
                  <MenuItem
                    key={balanceStatus}
                    id={balanceStatus}
                    value={balanceStatus}
                  >
                    {sentenceCase(balanceStatus)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TablePagination
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            labelRowsPerPage="Show"
            component="div"
            count={invoicesData?.invoices.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next();
              } else {
                prev();
              }
              setPage(newPage);
            }}
            backIconButtonProps={{
              disabled: loading || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                loading ||
                invoicesData?.invoices.totalCount === 0 ||
                page + 1 ===
                  Math.ceil(
                    (invoicesData?.invoices.totalCount ?? 0) / rowsPerPage,
                  ),
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(+e.target.value);
            }}
            style={{ marginLeft: 'auto' }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TableContainer sx={{ overflowY: 'scroll', height: height - 325 }}>
          <Table stickyHeader aria-label="invoice-preview-table" size="small">
            <TableHead>
              <TableRow>
                <TableCell width={70}>
                  {loading && <CircularProgress size={18} />}
                </TableCell>
                <TableCell sx={{ width: '250px' }}>
                  {SingleColumnInvoiceTableSortLabel({
                    label:
                      companyConfiguration?.useJournalNumberForInvoice === true
                        ? 'Journal #'
                        : 'Name',
                    sortBy: FindInvoicesSortFields.InvoiceNameOrJournalNumber,
                    currentSort: sort,
                    setSort,
                    onClick: () => {
                      setShouldRefreshInvoiceList(true);
                    },
                  })}
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Total charges</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>
                  {SingleColumnInvoiceTableSortLabel({
                    label: 'Date',
                    sortBy: FindInvoicesSortFields.Date,
                    currentSort: sort,
                    setSort,
                    onClick: () => {
                      setShouldRefreshInvoiceList(true);
                    },
                  })}
                </TableCell>
                <TableCell>
                  {SingleColumnInvoiceTableSortLabel({
                    label: 'Created at',
                    sortBy: FindInvoicesSortFields.CreatedAt,
                    currentSort: sort,
                    setSort,
                    onClick: () => {
                      setShouldRefreshInvoiceList(true);
                    },
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesData?.invoices.edges.map(({ node: invoice }) => {
                return (
                  <ContactInvoiceListRow key={invoice.uuid} invoice={invoice} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default React.memo(InvoiceList);
