import { Dialog, Typography, Button, Stack } from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import {
  OrderForDocumentAttachmentsDownloadFragment,
  RouteFragment,
} from '../../../../../generated/graphql';
import useRouteActions from '../../../hooks/use-route-actions';

const ConfirmUnsignedPodsDownloadModal = ({
  open,
  setOpen,
  routes,
  ordersWithoutUnsignedPods,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  routes: RouteFragment[];
  ordersWithoutUnsignedPods: OrderForDocumentAttachmentsDownloadFragment[];
}) => {
  const { downloadUnsignedPodDocumentsForRoutes } = useRouteActions();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Stack direction="column" sx={{ p: 2, width: '400px' }} spacing={2}>
        <Typography variant="h6">
          Found {ordersWithoutUnsignedPods.length} orders missing unsigned PODs
        </Typography>
        <Typography variant="caption">
          {ordersWithoutUnsignedPods
            .map((order) => order.standardOrderFields.shipperBillOfLadingNumber)
            .join(', ')}
        </Typography>
        <Typography>Do you still want to download?</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              downloadUnsignedPodDocumentsForRoutes(routes, true);
              setOpen(false);
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(ConfirmUnsignedPodsDownloadModal);
