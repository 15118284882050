import {
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
  TariffZoneGroupFragment,
  TariffZoneGroupsDocument,
  useCreateTariffZoneGroupMutation,
  useUpdateTariffZoneGroupMutation,
} from '../../../../generated/graphql';
import CreateOrEdit from '../../enums/create-or-edit';
import { styles } from '../styles';

const CreateOrEditZoneGroupModal = ({
  open,
  onClose,
  createOrEdit,
  tariffZoneGroup,
}: {
  open: boolean;
  onClose: () => void;
  createOrEdit: CreateOrEdit;
  tariffZoneGroup: TariffZoneGroupFragment | undefined;
}) => {
  const [name, setName] = useState(tariffZoneGroup?.name ?? '');
  const [description, setDescription] = useState(
    tariffZoneGroup?.description ?? '',
  );
  const [errorMessage, setErrorMessage] = useState('');

  const [createTariffZoneGroup, { loading: creating }] =
    useCreateTariffZoneGroupMutation({
      refetchQueries: [TariffZoneGroupsDocument],
      onError: ({ message }) => setErrorMessage(message),
    });
  const [updateTariffZoneGroup, { loading: updating }] =
    useUpdateTariffZoneGroupMutation({
      refetchQueries: [{ query: TariffZoneGroupsDocument, variables: {} }],
      onError: ({ message }) => setErrorMessage(message),
    });

  const validateInputs = () => {
    if (isEmpty(name)) {
      setErrorMessage('Please enter a name');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (createOrEdit === CreateOrEdit.Create) {
      await createTariffZoneGroup({
        variables: {
          tariffZoneGroupCreateInput: {
            name,
            description,
          },
        },
        onCompleted: () => {
          onClose();
          setErrorMessage('');
        },
      });
    } else {
      if (!tariffZoneGroup) {
        return;
      }
      await updateTariffZoneGroup({
        variables: {
          tariffZoneGroupUpdateInput: {
            id: tariffZoneGroup.id,
            name,
            description,
          },
        },
        onCompleted: () => {
          onClose();
          setErrorMessage('');
        },
      });
    }
    onClose();
  };

  const handleOnClose = () => {
    onClose();
    setName('');
    setDescription('');
    setErrorMessage('');
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h5">{createOrEdit} a Zone Group</Typography>
        {!isEmpty(errorMessage) && (
          <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
        )}

        <TextField
          size="small"
          value={name}
          label="Name"
          onChange={(e) => {
            setName(e.target.value);
            setErrorMessage('');
          }}
          sx={{ width: '300px' }}
        />
        <TextField
          size="small"
          label="Description"
          value={description ?? ''}
          onChange={(e) => {
            setDescription(e.target.value);
            setErrorMessage('');
          }}
          sx={{ width: '300px' }}
        />

        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button
            variant="text"
            onClick={onClose}
            disabled={creating || updating}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={creating || updating}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default CreateOrEditZoneGroupModal;
