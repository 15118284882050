import { isEmpty } from 'lodash';
import { Resolver } from 'react-hook-form';
import {
  BaseItemFormValueErrors,
  BaseItemFormValues,
  CreateItemFormValues,
  UpdateItemFormValues,
} from './types';

// const SECONDARY_UNIT_OF_MEASURE_VALUES: Partial<keyof BaseItemFormValues>[] = [
//   'secondaryUnitOfMeasure',
//   'primaryUnitsPerSecondaryUOM',
// ];
//
// const STORAGE_UNIT_VALUE_KEYS: Partial<keyof BaseItemFormValues>[] = [
//   'storageUnitConfigConsolidationUnitUOM',
//   'length',
//   'width',
//   'height',
//   'weight',
//   'tie',
//   'high',
//   'primaryUnitsPerConsolidationUnit',
// ];

const checkFormValuesForErrors = (formValues: BaseItemFormValues) => {
  const errors: BaseItemFormValueErrors = {};
  if (isEmpty(formValues.warehouse)) {
    errors.warehouse = {
      type: 'required',
      message: 'Warehouse is required',
    };
  }
  if (isEmpty(formValues.sku)) {
    errors.sku = {
      type: 'required',
      message: 'SKU is required',
    };
  }
  if (isEmpty(formValues.description)) {
    errors.description = {
      type: 'required',
      message: 'Description is required',
    };
  }
  if (isEmpty(formValues.primaryUnitOfMeasure)) {
    errors.primaryUnitOfMeasure = {
      type: 'required',
      message: 'Primary UOM is required',
    };
  }
  //
  // // For secondary UOM values
  // const secondaryUoMValues = SECONDARY_UNIT_OF_MEASURE_VALUES.map((key) => ({
  //   key,
  //   value: formValues[key],
  // }));
  //
  // const [nonEmptySecondaryUoMValues, emptySecondaryUoMValues] = partition(
  //   secondaryUoMValues,
  //   (secondaryUoMValue) =>
  //     !isNil(secondaryUoMValue.value) || !isEmpty(secondaryUoMValue.value),
  // );
  //
  // if (!isEmpty(nonEmptySecondaryUoMValues)) {
  //   emptySecondaryUoMValues.forEach((emptySecondaryUoMValue) => {
  //     errors[emptySecondaryUoMValue.key] = {
  //       type: 'required',
  //       message: `${startCase(emptySecondaryUoMValue.key)} is required`,
  //     };
  //   });
  // }
  //
  // // For container values, ensure that all required values are set if one of the values is set.
  // const storageUnitValues = STORAGE_UNIT_VALUE_KEYS.map((key) => ({
  //   key,
  //   value: formValues[key],
  // }));
  //
  // const [nonEmptyStorageUnitValues, emptyStorageUnitValues] = partition(
  //   storageUnitValues,
  //   (storageUnitValue) =>
  //     !isNil(storageUnitValue.value) || !isEmpty(storageUnitValue.value),
  // );
  //
  // if (!isEmpty(nonEmptyStorageUnitValues)) {
  //   emptyStorageUnitValues.forEach((emptyStorageUnitValue) => {
  //     errors[emptyStorageUnitValue.key] = {
  //       type: 'required',
  //       message: `${startCase(emptyStorageUnitValue.key)} is required`,
  //     };
  //   });
  // }

  return errors;
};

export const createItemFormResolver: Resolver<CreateItemFormValues> = async (
  formValues: CreateItemFormValues,
) => {
  const errors = checkFormValuesForErrors(formValues);

  return {
    values: formValues,
    errors,
  };
};

export const updateItemFormResolver: Resolver<UpdateItemFormValues> = async (
  formValues,
) => {
  const errors = checkFormValuesForErrors(formValues);
  return {
    values: formValues,
    errors,
  };
};
