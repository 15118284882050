import { Box, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import { BillingMethod } from '../../../../../../../common/types';
import { OrderFormValues } from '../../../forms/types';

const RateField = ({
  namePrefix,
  disabled,
}: {
  namePrefix: `stops.${number}` | `orderChargesShipment`;
  disabled?: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const settlementPercentageRate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.settlementPercentageRate`,
  });
  const settlementFlatRate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.settlementFlatRate`,
  });

  const billingMethod =
    useWatch({
      control,
      name: `${namePrefix}.freightCharge.settlementBillingMethod`,
    }) ?? BillingMethod.Percentage;

  const rate = useMemo(() => {
    switch (billingMethod) {
      case BillingMethod.FlatRate:
        return settlementFlatRate;

      case BillingMethod.Percentage:
        return settlementPercentageRate;

      default:
        return exhaustive(billingMethod);
    }
  }, [billingMethod, settlementFlatRate, settlementPercentageRate]);

  const [rateInput, setRateInput] = useState(rate?.toString() ?? '');

  useEffect(() => {
    setRateInput(rate?.toString() ?? '');
  }, [billingMethod, rate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <TextField
        size="small"
        value={rateInput}
        onChange={(e) => {
          setRateInput(e.target.value);
          const parsedFloat = parseFloat(e.target.value);
          if (billingMethod === BillingMethod.FlatRate) {
            if (!Number.isNaN(parsedFloat)) {
              setValue(
                `${namePrefix}.freightCharge.settlementFlatRate`,
                parsedFloat,
              );
            } else {
              setValue(`${namePrefix}.freightCharge.settlementFlatRate`, 0);
            }
          }
          if (billingMethod === BillingMethod.Percentage) {
            if (!Number.isNaN(parsedFloat)) {
              setValue(
                `${namePrefix}.freightCharge.settlementPercentageRate`,
                parsedFloat,
              );
            } else {
              setValue(
                `${namePrefix}.freightCharge.settlementPercentageRate`,
                0,
              );
            }
          }
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export const DriverSettlementFreightChargeRateField = React.memo(RateField);
