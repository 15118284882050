import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNaN, isNil, parseInt } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import {
  DocumentType,
  DocumentUpdateInput,
  useOrderLazyQuery,
  useUpdateDocumentsMutation,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import useSpecifyBolNumberPagesForm, {
  SpecifyBolNumberPagesFormValues,
} from '../hooks/use-specify-bol-number-pages-form';
import { FetchCompanyDocumentsParams } from '../types/company-documents';
import { DOCUMENT_PAGE_SIZE } from './bulk-change-document-type';
import { BillOfLadingType } from './specify-bill-of-lading-form';

const useStyles = () => ({
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
  },
});

interface SpecifyBolNumberPagesModalProps {
  open: boolean;

  onClose: () => void;

  orderUuid: string | undefined;

  setShowMatchOrderSuccessMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  setShowMatchOrderErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;

  fetchCompanyDocuments: (params: FetchCompanyDocumentsParams) => void;
}

const SpecifyBolNumberPagesModal = ({
  open,
  onClose,
  orderUuid,
  setShowMatchOrderSuccessMessage,
  setShowMatchOrderErrorMessage,
  fetchCompanyDocuments,
}: SpecifyBolNumberPagesModalProps) => {
  const styles = useStyles();
  const { selectedDocumentUuids, setSelectedDocumentUuids } =
    useContext(EndOfDayContext);
  const [billOfLadingType, setBillOfLadingType] =
    useState<BillOfLadingType>('Single');

  const [pageNumber, setPageNumber] = useState<string>('');
  const [pageNumberError, setPageNumberError] = useState<string>('');

  const [updateDocuments, { loading: updateDocumentsLoading }] =
    useUpdateDocumentsMutation();

  const [getOrder, { data: orderData }] = useOrderLazyQuery();

  useEffect(() => {
    if (!isNil(orderUuid)) {
      getOrder({ variables: { uuid: orderUuid } });
    }
  }, [orderUuid, getOrder]);

  const validPageNumbers = useMemo(() => {
    return selectedDocumentUuids.map((uuid, idx) => idx + 1);
  }, [selectedDocumentUuids]);

  const {
    formState: { errors },
    control,
    handleSubmit,
    setError,
  } = useSpecifyBolNumberPagesForm();

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    if (!isNil(newAlignment)) {
      setBillOfLadingType(newAlignment as BillOfLadingType);
    }
  };

  const handleClose = () => {
    setPageNumber('');
    setPageNumberError('');
    onClose();
  };

  const handleMatchSinglePage = async () => {
    const shipmentUuid = orderData?.order?.shipments?.at(0)?.uuid;
    if (isNil(shipmentUuid) || isEmpty(shipmentUuid)) {
      setPageNumberError('Could not find shipment');
      return;
    }

    const parsedPageNumber = parseInt(pageNumber);
    if (
      isEmpty(pageNumber) ||
      isNaN(parsedPageNumber) ||
      parsedPageNumber < 1 ||
      !validPageNumbers.includes(parsedPageNumber)
    ) {
      setPageNumberError('Please enter a valid page number');
      return;
    }
    const bolDocumentUuid = selectedDocumentUuids?.at(parsedPageNumber - 1);
    if (isNil(bolDocumentUuid)) {
      return;
    }

    setPageNumberError('');
    try {
      const documentUpdateInputs: DocumentUpdateInput[] =
        selectedDocumentUuids.map((uuid) => {
          return {
            uuid,
            orderUuid,
            shipmentUuid,
            type:
              bolDocumentUuid === uuid
                ? DocumentType.ProofOfDeliveryScanned
                : DocumentType.Other,
          };
        });
      // Update the BOL document to set the type to bill of lading.
      await updateDocuments({
        variables: {
          updateDocumentsInput: {
            documentUpdateInputs,
          },
        },
      });
      fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });
      setShowMatchOrderSuccessMessage(true);
      setSelectedDocumentUuids([]);
      setPageNumberError('');
      handleClose();
    } catch (e) {
      setShowMatchOrderErrorMessage(true);
    }
  };

  const handleMatchMultiplePages: SubmitHandler<
    SpecifyBolNumberPagesFormValues
  > = async (data) => {
    const startPageNumber = parseInt(data.startPage);
    const endPageNumber = parseInt(data.endPage);

    if (!validPageNumbers.includes(startPageNumber)) {
      setError('startPage', { message: 'Please enter a valid start page' });
      return;
    }
    if (!validPageNumbers.includes(endPageNumber)) {
      setError('endPage', { message: 'Please enter a valid end page' });
      return;
    }
    const shipmentUuid = orderData?.order?.shipments?.at(0)?.uuid;
    if (isNil(shipmentUuid) || isEmpty(shipmentUuid)) {
      setError('startPage', { message: 'Could not find shipment' });
      return;
    }

    try {
      const startIdx = startPageNumber - 1;
      const endIdx = endPageNumber - 1;
      const bolDocumentUuids = selectedDocumentUuids?.slice(
        startIdx,
        endIdx + 1,
      );
      if (isNil(bolDocumentUuids)) {
        return;
      }

      const documentUpdateInputs: DocumentUpdateInput[] =
        selectedDocumentUuids.map((uuid) => {
          return {
            uuid,
            orderUuid,
            shipmentUuid,
            type: bolDocumentUuids.includes(uuid)
              ? DocumentType.ProofOfDeliveryScanned
              : DocumentType.Other,
          };
        });

      await updateDocuments({
        variables: {
          updateDocumentsInput: {
            documentUpdateInputs,
          },
        },
      });
      fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });

      setShowMatchOrderSuccessMessage(true);
      setSelectedDocumentUuids([]);
      setPageNumberError('');
      handleClose();
    } catch (e) {
      setShowMatchOrderErrorMessage(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{ minWidth: 650 }}
    >
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid
            item
            xs={11}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ marginLeft: 4 }}>Specify Proof of Delivery Pages</div>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={styles.centeredRow}>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={billOfLadingType}
              onChange={handleTypeChange}
            >
              {['Single', 'Multiple'].map((type) => {
                return (
                  <ToggleButton
                    key={type}
                    value={type}
                    onClick={() =>
                      setBillOfLadingType(type as BillOfLadingType)
                    }
                  >
                    {sentenceCase(type)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sx={styles.centeredRow}>
            {billOfLadingType === 'Single' ? (
              <FormControl>
                <TextField
                  type="number"
                  size="small"
                  onChange={(event) => {
                    setPageNumber(event.target.value);
                    setPageNumberError('');
                  }}
                  required
                  label="Page Number"
                  value={pageNumber}
                  error={!isEmpty(pageNumberError)}
                />
                {!isEmpty(pageNumberError) && (
                  <FormHelperText sx={{ color: 'red' }}>
                    {pageNumberError}
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <Grid container sx={{ ...styles.centeredRow, gap: 2 }}>
                <Grid item xs={3}>
                  <Controller
                    name="startPage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        size="small"
                        label="Start Page"
                        onChange={onChange}
                        value={value}
                        required
                        error={!isNil(errors.startPage)}
                        helperText={errors?.startPage?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="endPage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        size="small"
                        label="End Page"
                        onChange={onChange}
                        value={value}
                        required
                        error={!isNil(errors.endPage)}
                        helperText={errors?.endPage?.message}
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={
            billOfLadingType === 'Single'
              ? handleMatchSinglePage
              : handleSubmit(handleMatchMultiplePages)
          }
          disabled={updateDocumentsLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SpecifyBolNumberPagesModal;
