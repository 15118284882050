import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isNil } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import useLocalStorageState, {
  LocalStorageState,
} from 'use-local-storage-state';

const truckingCompanies = [
  'Pioneer Truck Lines',
  'Golden Gate Logistics',
  'Blue Horizon Freight',
  'Rapid Route Logistics',
  'Ironclad Trucking',
  'Evergreen Transport Co.',
  'Apex Freight Solutions',
  'Titan Haulage',
  'Trailblazer Transport',
  'North Star Carriers',
  'Velocity Freightways',
  'Prime Path Logistics',
];

export const useTruckloadShipmentCarrierDemoFieldValue = (): LocalStorageState<
  string | null
> => {
  const [searchParams] = useSearchParams();
  const pageOrderUuid = searchParams.get('orderUuid') ?? 'new';
  return useLocalStorageState<string | null>(
    `order-${pageOrderUuid}-truckload-carrier`,
    {
      defaultValue: null,
      serializer: {
        parse: (value) => value,
        stringify: (value) => value as string,
      },
    },
  );
};

export const TruckloadShipmentCarrierDemoField = () => {
  const [carrier, setCarrier] = useTruckloadShipmentCarrierDemoFieldValue();
  return (
    <FormControl>
      <InputLabel id="order-truckload-carrier-label">Carrier</InputLabel>
      <Select<string>
        id="stop-type-select"
        labelId="order-truckload-carrier-label"
        label="Carrier"
        value={carrier !== 'null' && !isNil(carrier) ? carrier : ''}
        onChange={(e) => {
          setCarrier(e.target.value);
        }}
        size="small"
        sx={{
          width: '196px',
        }}
      >
        {truckingCompanies.map((company) => (
          <MenuItem key={company} value={company}>
            {company}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
