import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useAppSelector } from '../../../../../redux/hooks';
import { selectStandardOrderValuesById } from '../../../redux/standard/standard-orders-values-slice';

interface RejectEdiOrderModalProps {
  open: boolean;
  onClose: () => void;
  orderUuid: string | undefined;
  handleRejectOrder: (orderUuid: string) => void;
  // handleDiscardChanges?: () => void;
}

const RejectEdiOrderModal: React.FC<RejectEdiOrderModalProps> = ({
  open,
  onClose,
  orderUuid,
  handleRejectOrder,
}: RejectEdiOrderModalProps) => {
  const order = useAppSelector((state) =>
    selectStandardOrderValuesById(state, orderUuid ?? ''),
  );

  if (isNil(order) || isNil(orderUuid)) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          textAlign: 'left',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <WarningAmberIcon color="error" sx={{ mr: '10px' }} fontSize="medium" />{' '}
        Are you sure you want to reject this order?
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Typography>
              Rejecting
              <b> {order.name}</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Typography>This operation is irreversible.</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleRejectOrder(orderUuid);
            onClose();
          }}
          disabled={false}
        >
          {`Yes I'm sure`}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectEdiOrderModal;
