import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { useDebounce } from 'use-debounce';
import { shallow } from 'zustand/shallow';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../../common/feature-flags';
import { Option } from '../../../../common/filters/types';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import {
  EmailSendStatus,
  InvoiceStatus,
  PermissionResource,
  useInvoiceUuidsForBatchPostLazyQuery,
} from '../../../../generated/graphql';
import { OFFSET_HEIGHT_ABOVE_ROUTES } from '../../../dispatch/map/constants';
import useInvoicesStore from '../../invoices-store';
import { InvoiceStatusTab } from '../../types/types';
import {
  LOCAL_CUSTOMER_OPTION_LABEL,
  LOCAL_CUSTOMER_OPTION_VALUE,
  LOCAL_DATE_OPTION_END_DATE_ISO_STRING,
  LOCAL_DATE_OPTION_FILTER_TYPE,
  LOCAL_DATE_OPTION_START_DATE_ISO_STRING,
  LOCAL_DATE_OPTION_VALUE,
  LOCAL_STATUS_OPTION,
} from './constants';
import DownloadInvoiceModal from './download/download-invoice-modal';
import ExportToQuickbooks from './export-to-quickbooks';
import ExportToSageBusinessWorks from './export-to-sage-businessworks';
import Invoice from './invoice-details';
import InvoiceList from './invoice-list';
import InvoicesContextMenu from './invoices-context-menu';
import PostAndSendModal from './post-and-send/post-and-send-modal';
import ExportToQuickbooksDesktop from './quickbooks-desktop/export-to-quickbooks-desktop';
import ShowPostWithoutSendingModal from './show-post-without-sending-modal';

export type InvoicesProps = {
  setShowSendAccountingReportsSuccessMessage: Dispatch<SetStateAction<boolean>>;
  setShowSendAccountingReportsErrorMessage: Dispatch<SetStateAction<boolean>>;
  showCustomerFilter?: boolean;
};

const Invoices = ({
  setShowSendAccountingReportsSuccessMessage,
  setShowSendAccountingReportsErrorMessage,
  showCustomerFilter = true,
}: InvoicesProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteInvoices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Invoices,
  );
  const invoiceUuid = searchParams.get('invoiceUuid');
  const invoiceName = searchParams.get('invoiceName');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ffNewCsvInvoiceButtonEnabled = useFeatureFlag(
    FeatureFlag.FF_NEW_CSV_INVOICE_BUTTON_ENABLED,
  );
  const { companyData } = useMe();
  const { quickbooksDesktopEnabled } = useMe();
  const { height } = useWindowDimensions();
  const [selectedInvoiceUuids, selectInvoiceUuid] = useInvoicesStore(
    (state) => [state.selectedInvoiceUuids, state.selectInvoiceUuid],
    shallow,
  );
  const setShouldRefreshInvoiceList = useInvoicesStore(
    (state) => state.setShouldRefreshInvoiceList,
  );
  const deselectAllInvoiceUuids = useInvoicesStore(
    (state) => state.deselectAllInvoiceUuids,
  );
  const [downloadErrorSnackbarVisible, setDownloadErrorSnackbarVisible] =
    useState(false);
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showPostAndSendModal, setShowPostAndSendModal] =
    useState<boolean>(false);
  const [openedInvoiceUuid, setOpenedInvoiceUuid] = useState<
    string | undefined
  >();
  const [showInvoiceDetails, setShowInvoiceDetails] = useState<boolean>(false);
  const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [dateOption, setDateOption] = useState<DateOption>();
  const [emailSendStatus, setEmailSendStatus] = useState<
    EmailSendStatus | 'All'
  >('All');
  const [isSyncedToQBD, setIsSyncedToQBD] = useState<boolean | undefined>();
  const [showSuccessfulPaymentCreation, setShowSuccessfulPaymentCreation] =
    useState(false);
  const theme = useTheme();
  const [showPostWithoutSendingModal, setShowPostWithoutSendingModal] =
    useState(false);
  const [showSuccessfulPostMessage, setShowSuccessfulPostMessage] =
    useState(false);
  const [showFailedPostMessage, setShowFailedPostMessage] = useState(false);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] =
    useState(false);

  const [getInvoices] = useInvoiceUuidsForBatchPostLazyQuery();

  // when we open the page check if there is an invoiceUuid provided
  useEffect(() => {
    if (typeof invoiceUuid === 'string' && typeof invoiceName === 'string') {
      // Opening invoice from different page
      setOpenedInvoiceUuid(invoiceUuid);
      // Reset filters
      setSearchText(invoiceName);
      setCustomerOption(undefined);
      setStatusTab(InvoiceStatusTab.ALL);
      setDateOption(initialDateOption);
      setEmailSendStatus('All');
      setIsSyncedToQBD(undefined);
      navigate('/accounting', { replace: true });
    } else if (!isNil(invoiceUuid)) {
      // Opening invoice from invoices page
      setOpenedInvoiceUuid(invoiceUuid);
      setShowInvoiceDetails(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceUuid, invoiceName]);

  useEffect(() => {
    if (typeof invoiceUuid === 'string' && typeof invoiceName === 'string') {
      // Need to reset filters if invoice opened from another tab
      return;
    }
    // Get all local storage values to preserve the filters
    const localDateOptionFilterType = localStorage.getItem(
      LOCAL_DATE_OPTION_FILTER_TYPE,
    );
    const localDateOptionValue = localStorage.getItem(LOCAL_DATE_OPTION_VALUE);
    const localDateOptionStartDateIsoString = localStorage.getItem(
      LOCAL_DATE_OPTION_START_DATE_ISO_STRING,
    );
    const localDateOptionEndDateIsoString = localStorage.getItem(
      LOCAL_DATE_OPTION_END_DATE_ISO_STRING,
    );
    if (
      !isNil(localDateOptionFilterType) &&
      Object.values(DatePickerFilterType)
        .map((filterType) => String(filterType))
        .includes(localDateOptionFilterType)
    ) {
      setDateOption({
        filterType: localDateOptionFilterType as DatePickerFilterType,
        value:
          !isNil(localDateOptionValue) &&
          !Number.isNaN(parseFloat(localDateOptionValue))
            ? parseFloat(localDateOptionValue)
            : undefined,
        startDate:
          !isNil(localDateOptionStartDateIsoString) &&
          dayjs(localDateOptionStartDateIsoString).isValid() &&
          localDateOptionFilterType !== DatePickerFilterType.AllSelect
            ? dayjs(localDateOptionStartDateIsoString).toDate()
            : undefined,
        endDate:
          !isNil(localDateOptionEndDateIsoString) &&
          dayjs(localDateOptionEndDateIsoString).isValid() &&
          localDateOptionFilterType !== DatePickerFilterType.AllSelect
            ? dayjs(localDateOptionEndDateIsoString).toDate()
            : undefined,
      });
    } else {
      setDateOption(initialDateOption);
    }

    const localCustomerOptionLabel = localStorage.getItem(
      LOCAL_CUSTOMER_OPTION_LABEL,
    );
    const localCustomerOptionValue = localStorage.getItem(
      LOCAL_CUSTOMER_OPTION_VALUE,
    );
    if (!isNil(localCustomerOptionLabel) && !isNil(localCustomerOptionValue)) {
      setCustomerOption({
        value: localCustomerOptionValue,
        label: localCustomerOptionLabel,
      });
    }

    const localStatusOption = localStorage.getItem(LOCAL_STATUS_OPTION);
    if (!isNil(localStatusOption)) {
      setStatusTab(localStatusOption as InvoiceStatusTab);
    } else {
      setStatusTab(InvoiceStatusTab.NOT_POSTED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(dateOption)) {
      localStorage.setItem(
        LOCAL_DATE_OPTION_FILTER_TYPE,
        dateOption.filterType,
      );
      if (!isNil(dateOption.value)) {
        localStorage.setItem(LOCAL_DATE_OPTION_VALUE, String(dateOption.value));
      }
      if (!isNil(dateOption.startDate)) {
        localStorage.setItem(
          LOCAL_DATE_OPTION_START_DATE_ISO_STRING,
          dateOption.startDate.toISOString(),
        );
      }
      if (!isNil(dateOption.endDate)) {
        localStorage.setItem(
          LOCAL_DATE_OPTION_END_DATE_ISO_STRING,
          dateOption.endDate.toISOString(),
        );
      }
    }
  }, [dateOption]);

  useEffect(() => {
    if (
      !isNil(customerOption) &&
      !isNil(customerOption.label) &&
      !isNil(customerOption.value)
    ) {
      localStorage.setItem(LOCAL_CUSTOMER_OPTION_LABEL, customerOption.label);
      localStorage.setItem(LOCAL_CUSTOMER_OPTION_VALUE, customerOption.value);
    } else {
      localStorage.removeItem(LOCAL_CUSTOMER_OPTION_LABEL);
      localStorage.removeItem(LOCAL_CUSTOMER_OPTION_VALUE);
    }
  }, [customerOption]);

  useEffect(() => {
    if (!isNil(statusTab)) {
      localStorage.setItem(LOCAL_STATUS_OPTION, statusTab);
    }
  }, [statusTab]);

  const getInvoiceUuidsForBatchPost = async (shouldGetAll: boolean) => {
    let statuses: InvoiceStatus[] = [];

    if (statusTab === InvoiceStatusTab.NOT_POSTED) {
      statuses = [InvoiceStatus.NotFinalized];
    } else if (statusTab === InvoiceStatusTab.POSTED) {
      statuses = []; // The button is disabled for the posted tab, but in the worst case avoid selecting any invoices.
    } else if (shouldGetAll || statusTab === InvoiceStatusTab.ALL) {
      statuses = [InvoiceStatus.NotFinalized]; // Avoid resending any posted invoice.
    }
    const res = await getInvoices({
      variables: {
        statuses,
        billingPartyContactUuid: customerOption?.value,
        invoiceStartDate: dateOption?.startDate,
        invoiceEndDate: dateOption?.endDate,
        emailSendStatus:
          emailSendStatus === 'All' ? undefined : emailSendStatus,
        isSyncedToQuickbooksDesktop: isSyncedToQBD,
        first: 5000,
        groupByContact: true,
      },
    });

    res.data?.invoices.edges.map(({ node: invoice }) =>
      selectInvoiceUuid(
        invoice.uuid,
        invoice.status === InvoiceStatus.NotFinalized,
      ),
    );
  };

  const invoiceDetailsOpen = !isNil(openedInvoiceUuid) || showInvoiceDetails;

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSuccessfulPostMessage(false)}
        open={showSuccessfulPostMessage}
      >
        <Alert>Successfully posted invoices</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowFailedPostMessage(false)}
        open={showFailedPostMessage}
      >
        <Alert>Failed to post invoices</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSuccessfulPaymentCreation(false)}
        open={showSuccessfulPaymentCreation}
      >
        <Alert>Successfully created payment</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={downloadErrorSnackbarVisible}
      >
        <Alert
          severity="error"
          onClose={() => setDownloadErrorSnackbarVisible(false)}
        >
          Error downloading invoice
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        sx={{
          paddingLeft: 1,
          paddingRight: 2,
          paddingTop: '5px',
          height: '100%',
        }}
      >
        <Grid item xs={6}>
          <Stack direction="row" spacing={2} alignItems="center">
            <DateDropdownPicker
              filterTitle="Invoice Date"
              dateOption={dateOption ?? initialDateOption}
              setDateOption={setDateOption}
              defaultFilterType={DatePickerFilterType.WeekPaginate}
            />
            {showCustomerFilter && (
              <CustomerFilterButton
                cacheId="INVOICES"
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) => {
                  setCustomerOption(option);
                }}
              />
            )}
            <TextField
              size="small"
              label="Search Invoices"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              sx={{ width: '200px' }}
            />
            {invoicesLoading && <CircularProgress size={20} />}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ float: 'right' }}
          >
            {statusTab === InvoiceStatusTab.POSTED &&
              quickbooksDesktopEnabled && <ExportToQuickbooksDesktop />}
            {statusTab === InvoiceStatusTab.POSTED &&
              companyData?.useSageBusinessWorks === true && (
                <ExportToSageBusinessWorks />
              )}
            {ffNewCsvInvoiceButtonEnabled && (
              <ExportToQuickbooks selectedInvoiceUuids={selectedInvoiceUuids} />
            )}
            <Button
              color="info"
              variant="contained"
              onClick={() => {
                if (selectedInvoiceUuids.length === 0) {
                  getInvoiceUuidsForBatchPost(
                    statusTab !== InvoiceStatusTab.NOT_POSTED,
                  );
                }
                setShowDownloadModal(true);
              }}
            >
              Batch Download{' '}
              {selectedInvoiceUuids.length > 0
                ? `(${selectedInvoiceUuids.length})`
                : 'All'}
            </Button>
            <Button
              disabled={
                !canWriteInvoices ||
                (statusTab === InvoiceStatusTab.POSTED &&
                  isEmpty(selectedInvoiceUuids))
              }
              variant="contained"
              onClick={() => {
                if (isEmpty(selectedInvoiceUuids)) {
                  getInvoiceUuidsForBatchPost(false);
                }
                setShowPostAndSendModal(true);
              }}
            >
              Post / Send{' '}
              {selectedInvoiceUuids.length > 0
                ? `(${selectedInvoiceUuids.length})`
                : `All`}
            </Button>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            <InvoicesContextMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              selectedInvoiceUuids={selectedInvoiceUuids}
              onClickPostWithoutSending={() => {
                if (selectedInvoiceUuids.length === 0) {
                  getInvoiceUuidsForBatchPost(false);
                }
                setShowPostWithoutSendingModal(true);
              }}
              setShowSendAccountingReportsSuccessMessage={
                setShowSendAccountingReportsSuccessMessage
              }
              setShowSendAccountingReportsErrorMessage={
                setShowSendAccountingReportsErrorMessage
              }
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ zIndex: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            sx={{
              overflowY: 'hidden',
              height: height - OFFSET_HEIGHT_ABOVE_ROUTES,
            }}
          >
            <Grid
              item
              xs={12}
              md={invoiceDetailsOpen ? 5 : 12}
              sx={{
                borderRight: 1,
                borderRightColor: theme.palette.borderColor.main,
                height: '100%',
                transition: '0.15s',
              }}
            >
              <InvoiceList
                openedInvoiceUuid={openedInvoiceUuid}
                setOpenedInvoiceUuid={setOpenedInvoiceUuid}
                showInvoiceDetails={showInvoiceDetails}
                setShowInvoiceDetails={setShowInvoiceDetails}
                searchText={debouncedSearchText}
                contactUuid={customerOption?.value}
                startDate={dateOption?.startDate}
                endDate={dateOption?.endDate}
                emailSendStatus={emailSendStatus}
                setEmailSendStatus={setEmailSendStatus}
                isSyncedToQBD={isSyncedToQBD}
                setIsSyncedToQBD={setIsSyncedToQBD}
                setInvoicesLoading={setInvoicesLoading}
                statusTab={statusTab}
                setStatusTab={setStatusTab}
                isHeaderCheckboxSelected={isHeaderCheckboxSelected}
                setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
              />
            </Grid>
            <Fade in={invoiceDetailsOpen}>
              <Grid item xs={12} md={7}>
                {invoiceDetailsOpen && !isNil(openedInvoiceUuid) && (
                  <Invoice
                    invoiceUuid={openedInvoiceUuid}
                    setOpenedInvoiceUuid={setOpenedInvoiceUuid}
                    setShowInvoiceDetails={setShowInvoiceDetails}
                    setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
                  />
                )}
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
      <DownloadInvoiceModal
        isOpen={showDownloadModal}
        setIsOpen={setShowDownloadModal}
        invoiceUuids={selectedInvoiceUuids}
        contactUuid={undefined}
        setDownloadErrorSnackbarVisible={setDownloadErrorSnackbarVisible}
      />
      <PostAndSendModal
        isOpen={showPostAndSendModal}
        setIsOpen={setShowPostAndSendModal}
        invoiceUuids={selectedInvoiceUuids}
        onPostAndSend={() => {
          deselectAllInvoiceUuids();
          setShouldRefreshInvoiceList(true);
        }}
        setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
      />
      <ShowPostWithoutSendingModal
        open={showPostWithoutSendingModal}
        setOpen={setShowPostWithoutSendingModal}
        selectedUnfinalizedInvoiceUuids={selectedInvoiceUuids}
      />
    </Stack>
  );
};

export default Invoices;
