import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import apolloClient from '../../../apollo-client';
import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  PickupOrDelivery,
  RouteDocument,
  RouteQuery,
  RouteQueryVariables,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import { upsertAddress } from '../../addresses/redux/addresses-values-slice';
import { upsertOneStandardShipmentValues } from '../../shipments/redux/standard-shipments-values-slice';
import {
  StopValues,
  upsertOneStopValues,
} from '../../stops/redux/stop-values-slice';
import { upsertOneDriverValues } from './driver-values-slice';
import { upsertOneRouteSlotValues } from './route-slots-values-slice';
import { upsertOneRouteValues } from './routes-values-slice';

type InitExistingRouteValuesArg = {
  routeUuid: string;
};

export const initExistingRouteValues = createAsyncThunk<
  string,
  InitExistingRouteValuesArg,
  { state: RootState }
>('routeValues/addRouteValues', async (arg, thunkAPI) => {
  if (isNil(arg.routeUuid) || arg.routeUuid.length === 0) {
    return '';
  }
  const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
    query: MeDocument,
    fetchPolicy: 'cache-first',
  });
  const response = await apolloClient.query<RouteQuery, RouteQueryVariables>({
    query: RouteDocument,
    variables: { uuid: arg.routeUuid },
  });
  const stops: StopValues[] = [];
  const driverUuids = await Promise.all(
    response.data.route.drivers.map((driver) => {
      thunkAPI.dispatch(
        upsertOneDriverValues({
          uuid: driver.uuid,
          firstName: driver.firstName,
          lastName: driver.lastName,
        }),
      );
      return driver.uuid;
    }),
  );
  await Promise.all(
    response.data.route.slots.map((slot) => {
      const filteredStops = slot.stops.filter(
        (stop) => !isNil(stop.shipment?.order),
      );
      const responseStops: StopValues[] = filteredStops.map((stop) => {
        const { shipment } = stop;
        const shipmentUuid = shipment?.uuid;
        thunkAPI.dispatch(
          upsertAddress({
            ...stop.address,
            isLocal: false,
          }),
        );
        if (!isNil(shipmentUuid)) {
          thunkAPI.dispatch(
            upsertOneStandardShipmentValues({
              shouldUseDimensionalWeight: undefined,
              uuid: shipmentUuid,
              airportInfoUuid: undefined,
              orderUuid: shipment?.order?.uuid ?? '',
              preBillingStatus: undefined,
              billingStatus: undefined,
              firstLegUuid: '',
              packageUuids: shipment?.order?.packages.map((pkg) => pkg.uuid),
              isLocal: false,
              customChargeIds: undefined,
              freightChargeId: undefined,
              name: undefined,
              cargoDescription: undefined,
              cargoPieces: undefined,
              weight: undefined,
              deliveryDate: undefined,
              deadlineDate: undefined,
              deadlineType: undefined,
              dimFactor:
                companyData.data.me?.company?.configuration?.defaultDimFactor ??
                250,
              shipmentType: shipment?.shipmentType,
              type: undefined,
              fieldsUuid: '',
              serviceUuid: shipment?.order?.service?.uuid,
              pickupOrDelivery: stop.stopType as PickupOrDelivery | undefined,
              numberOfFailures: undefined,
              clientReferenceNumber: undefined,
              totalChargesAmount: undefined,
              shipperAddressUuid: undefined,
              shipmentSubscriberUuids: undefined,
              hasSignedPOD: shipment?.hasSignedPOD ?? false,
            }),
          );
        }
        return {
          ...stop,
          isSpecial: stop.isSpecial ?? undefined,
          standardStopType: stop.standardStopType ?? undefined,
          addressUuid: stop.address.uuid,
          billOfLadingNumber:
            stop.shipment?.order?.standardOrderFields.shipperBillOfLadingNumber,
          name: stop.shipment?.order?.name,
          shipmentUuid: stop.shipment?.uuid,
          orderUuid: stop.shipment?.order?.uuid,
          refusedBy: stop.shipment?.order?.refusedBy,
          stopType: stop.stopType ?? null,
          inboundMethod: stop.inboundMethod,
          outboundMethod: stop.outboundMethod,
        };
      });
      stops.push(...responseStops);
      thunkAPI.dispatch(
        upsertOneRouteSlotValues({
          uuid: slot.uuid,
          stopUuids: slot.stops.map((stop) => stop.uuid),
          placeUuids: [],
          isLocal: false,
        }),
      );
      return slot.uuid;
    }),
  );
  await Promise.all(
    stops.map((stop) => {
      thunkAPI.dispatch(upsertOneStopValues(stop));
      return stop.uuid;
    }),
  );

  await thunkAPI.dispatch(
    upsertOneRouteValues({
      uuid: response.data.route.uuid,
      name: response.data.route.name,
      routeSlotUuids: response.data.route.slots.map((slot) => slot.uuid),
      driverUuids,
      equipmentUuids: response.data.route.equipments.map(
        (equipment) => equipment.uuid,
      ),
    }),
  );

  return arg.routeUuid;
});

export default initExistingRouteValues;
