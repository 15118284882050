import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Stack, SxProps } from '@mui/material';
import { isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TerminalFragment,
  useTerminalLazyQuery,
} from '../../../../generated/graphql';
import TerminalInfo from './terminal-info';

const styles = {
  container: {
    bgcolor: 'background.paper',
    // boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // gap: 'px',
    p: 4,
  } as SxProps,
};

type EditTerminalProps = {
  uuid: string;
};

const EditTerminal: FunctionComponent<EditTerminalProps> = ({ uuid }) => {
  const navigate = useNavigate();

  const [terminalQuery] = useTerminalLazyQuery();
  const [selectedTerminal, setSelectedTerminal] = useState<
    TerminalFragment | undefined
  >(undefined);

  const reloadTerminal = () => {
    if (!isNil(uuid)) {
      terminalQuery({
        variables: {
          uuid,
        },
      }).then((response) => {
        setSelectedTerminal(response.data?.terminal);
      });
    }
  };

  useEffect(() => {
    if (!isNil(uuid)) {
      terminalQuery({
        variables: {
          uuid,
        },
      }).then((response) => {
        setSelectedTerminal(response.data?.terminal);
      });
    }
  }, [terminalQuery, uuid]);

  const goBack = async () => {
    navigate(-1);
  };

  return (
    <Stack sx={styles.container}>
      <Button onClick={goBack} variant="outlined" color="primary">
        <ArrowBackIcon sx={{ mr: '10px' }} />
        Back to setup
      </Button>
      <TerminalInfo
        uuid={uuid}
        selectedTerminal={selectedTerminal}
        reloadTerminal={reloadTerminal}
      />
    </Stack>
  );
};

export default EditTerminal;
