import { Box, Tab, Tabs } from '@mui/material';
import { isNaN, isNil } from 'lodash';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import InventoryView from './inventory-view';
import PicklistsTable from './picklists-table';

interface InventoryPageProps {
  isCustomerPortal?: boolean;
}
const InventoryPage = ({ isCustomerPortal = false }: InventoryPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState<number>(() => {
    const tab = searchParams.get('tab');
    if (!isNil(tab)) {
      const parsed = parseInt(tab, 10);
      if (!isNaN(parsed)) {
        return parsed;
      }
    }
    return 0;
  });
  const tabs = [
    {
      label: 'Inventory',
      component: <InventoryView isCustomerPortal={isCustomerPortal} />,
    },
    {
      label: 'Pick tickets',
      component: <PicklistsTable isCustomerPortal={isCustomerPortal} />,
    },
  ];
  return (
    <Box height="100%" overflow="auto">
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={tabIndex}
        onChange={(e, newIndex) => {
          setTabIndex(newIndex);
          setSearchParams((sp) => {
            const newParams = new URLSearchParams(sp);
            newParams.set('tab', newIndex.toString());
            return newParams;
          });
        }}
        aria-label="inventory page tabs"
        sx={{ pl: 1 }}
      >
        {tabs.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel
          key={t.label}
          selectedValue={tabIndex}
          panelValue={idx}
          sx={{ p: 2 }}
        >
          {t.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default InventoryPage;
