import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { shallow } from 'zustand/shallow';
import { getValueWithinBounds } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';
import styles from './styles';

enum AmountInputType {
  Minimum = 'MINIMUM',
  Maximum = 'MAXIMUM',
}

const TariffZoneRangeModal = ({
  zoneRangeModalOpen,
  setZoneRangeModalOpen,
}: {
  zoneRangeModalOpen: boolean;
  setZoneRangeModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [
    minMaxAmountValues,
    settlementPercentageRateValues,
    locationZonesData,
    setMinMaxAmountValue,
    setSettlementPercentageRateValue,
  ] = useTariffGroupStore(
    (state) => [
      state.minMaxAmountValues,
      state.settlementPercentageRateValues,
      state.locationZonesData,
      state.setMinMaxAmountValue,
      state.setSettlementPercentageRateValue,
    ],
    shallow,
  );
  const updateMinMaxAmount = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
    rate: number | undefined,
  ) => {
    const minMaxAmount = { ...minMaxAmountValues[rowIndex] };
    if (!isNil(minMaxAmount)) {
      if (event.target.name === AmountInputType.Minimum) {
        minMaxAmount.min = rate;
      } else {
        minMaxAmount.max = rate;
      }
    }
    setMinMaxAmountValue(rowIndex, minMaxAmount);
  };

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    const rate = !Number.isNaN(parsed)
      ? getValueWithinBounds(currency(parsed, { precision: 2 }).value)
      : undefined;
    updateMinMaxAmount(event, rowIndex, rate);
  };

  const updateSettlementPercentageRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    const newSettlementRate = !Number.isNaN(parsed) ? parsed : null;
    setSettlementPercentageRateValue(rowIndex, newSettlementRate);
  };

  return (
    <Modal
      open={zoneRangeModalOpen}
      onClose={() => {
        setZoneRangeModalOpen(false);
      }}
    >
      <Box sx={styles.modal}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Edit Zone Ranges</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setZoneRangeModalOpen(false);
                }}
              >
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <TableContainer sx={{ maxHeight: '55vh' }}>
                <Table stickyHeader>
                  <TableHead aria-label="tariff-table">
                    <TableRow>
                      <TableCell align="center">
                        <Typography>Zone Range</Typography>
                      </TableCell>
                      <TableCell colSpan={3} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationZonesData.map((zone, rowIndex) => (
                      <TableRow key={zone.uuid}>
                        <TableCell>
                          {zone.name}
                          {zone.isLhLaneActive === false && (
                            <Typography sx={{ fontSize: '10px' }}>
                              (Archived)
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={AmountInputType.Minimum}
                            size="small"
                            label="Minimum Amount"
                            onBlur={(event) => {
                              validateAndUpdateRate(event, rowIndex);
                            }}
                            onChange={(event) =>
                              updateMinMaxAmount(
                                event,
                                rowIndex,
                                parseFloat(event.target.value),
                              )
                            }
                            value={minMaxAmountValues[rowIndex]?.min}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={AmountInputType.Maximum}
                            size="small"
                            label="Maximum Amount"
                            onBlur={(event) => {
                              validateAndUpdateRate(event, rowIndex);
                            }}
                            onChange={(event) => {
                              updateMinMaxAmount(
                                event,
                                rowIndex,
                                parseFloat(event.target.value),
                              );
                            }}
                            value={minMaxAmountValues[rowIndex]?.max}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            label="Settlement Rate"
                            onBlur={(event) => {
                              updateSettlementPercentageRate(event, rowIndex);
                            }}
                            onChange={(event) => {
                              updateSettlementPercentageRate(event, rowIndex);
                            }}
                            value={
                              settlementPercentageRateValues[
                                rowIndex
                              ]?.amount.toNumber() ?? ''
                            }
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffZoneRangeModal;
