import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { getSettlementEligibleTotal } from 'shared/billing';
import {
  InvoiceShipmentWithChargesFragment,
  StopDriverMapSummaryFragment,
} from '../../../../generated/graphql';
import { InvoiceShipmentFragmentCustomCharge } from '../../../invoices/types/types';
import { getSettlementRateString } from '../../utils';

const CustomChargeRow = ({
  customCharge,
}: {
  customCharge: InvoiceShipmentFragmentCustomCharge;
}) => {
  const customChargeEligibleTotal = useMemo(() => {
    return getSettlementEligibleTotal(
      customCharge.settlementFlatRate,
      customCharge.settlementPercentageRate,
      customCharge?.total,
    );
  }, [customCharge]);

  return (
    <TableRow>
      <TableCell>
        {!isEmpty(customCharge?.name)
          ? customCharge?.name
          : customCharge?.accessorialTemplate?.name}
      </TableCell>
      <TableCell>${customCharge?.total}</TableCell>
      <TableCell>
        {getSettlementRateString(
          customCharge?.settlementFlatRate,
          customCharge.settlementPercentageRate,
        )}
      </TableCell>
      <TableCell>${customChargeEligibleTotal}</TableCell>
    </TableRow>
  );
};

const SettlementChargesTable = ({
  stopDriverMap,
  shipment,
}: {
  stopDriverMap: StopDriverMapSummaryFragment;
  shipment: InvoiceShipmentWithChargesFragment;
}) => {
  const { charges, shipmentCharges } = shipment;

  const freightCharge = charges.find(
    (charge) => charge.__typename === 'FreightChargeEntity',
  );

  const freightChargeEligibleTotal = useMemo(() => {
    if (!isNil(freightCharge)) {
      return getSettlementEligibleTotal(
        freightCharge.settlementFlatRate,
        freightCharge.settlementPercentageRate,
        shipmentCharges.totalFreightCharge,
      );
    }
    return 0;
  }, [freightCharge, shipmentCharges.totalFreightCharge]);

  const freightChargeRow =
    !isNil(charges) && !isEmpty(freightCharge) ? (
      <TableRow>
        <TableCell>Freight Charge</TableCell>
        <TableCell>${shipmentCharges.totalFreightCharge}</TableCell>
        <TableCell>
          {getSettlementRateString(
            freightCharge?.settlementFlatRate,
            freightCharge?.settlementPercentageRate,
          )}
        </TableCell>
        <TableCell>${freightChargeEligibleTotal}</TableCell>
      </TableRow>
    ) : null;

  const fuelCharge =
    freightCharge?.__typename === 'FreightChargeEntity'
      ? freightCharge?.fuelCharge
      : undefined;

  const fuelChargeEligibleTotal = useMemo(() => {
    return getSettlementEligibleTotal(
      fuelCharge?.settlementFlatRate,
      fuelCharge?.settlementPercentageRate,
      shipmentCharges.totalFuelCharge,
    );
  }, [fuelCharge, shipmentCharges.totalFuelCharge]);

  const fuelChargeRow = !isNil(fuelCharge) ? (
    <TableRow>
      <TableCell>Fuel Charge</TableCell>
      <TableCell>${shipmentCharges.totalFuelCharge}</TableCell>
      <TableCell>
        {getSettlementRateString(
          fuelCharge?.settlementFlatRate,
          fuelCharge?.settlementPercentageRate,
        )}
      </TableCell>
      <TableCell>${fuelChargeEligibleTotal}</TableCell>
    </TableRow>
  ) : null;

  // eslint-disable-next-line ignore-use-effect/exhaustive-deps
  const customCharges: InvoiceShipmentFragmentCustomCharge[] = (charges.filter(
    (charge) => charge.__typename === 'CustomChargeEntity',
  ) ?? []) as InvoiceShipmentFragmentCustomCharge[];

  const customChargeRows =
    !isNil(customCharges) && !isEmpty(customCharges)
      ? customCharges.map((customCharge) => {
          return (
            <CustomChargeRow
              key={customCharge.uuid}
              customCharge={customCharge}
            />
          );
        })
      : null;

  const customChargesTotal = useMemo(
    () =>
      customCharges.reduce(
        (curr, prev) =>
          curr +
          getSettlementEligibleTotal(
            prev.settlementFlatRate,
            prev.settlementPercentageRate,
            prev?.total,
          ),
        0,
      ),
    [customCharges],
  );

  const deductionTotal = useMemo(() => {
    return getSettlementEligibleTotal(
      stopDriverMap.stop?.settlementDeductionFlatRate,
      stopDriverMap.stop?.settlementDeductionPercentageRate,
      freightChargeEligibleTotal + fuelChargeEligibleTotal + customChargesTotal,
    );
  }, [
    stopDriverMap,
    freightChargeEligibleTotal,
    fuelChargeEligibleTotal,
    customChargesTotal,
  ]);

  const deductionsRow = (
    <TableRow>
      <TableCell colSpan={2}>
        {!isEmpty(stopDriverMap.stop?.settlementDeductionName)
          ? `Deduction: ${stopDriverMap.stop?.settlementDeductionName}`
          : 'Deductions'}
      </TableCell>
      <TableCell>
        {getSettlementRateString(
          stopDriverMap.stop?.settlementDeductionFlatRate,
          stopDriverMap.stop?.settlementDeductionPercentageRate,
        )}
      </TableCell>
      <TableCell>-{currency(deductionTotal).format()}</TableCell>
    </TableRow>
  );

  const totalChargesRow = (
    <TableRow sx={{ '& td': { border: 0 } }}>
      <TableCell colSpan={3}>
        <strong>Total Eligible Amount</strong>
      </TableCell>
      <TableCell>
        {currency(
          stopDriverMap.driverSettlementTotalAmounts
            .totalEligibleAmountForStop ?? 0,
        ).format()}
      </TableCell>
    </TableRow>
  );

  const driverPayoutRow = (
    <TableRow sx={{ '& td': { border: 0 } }}>
      <TableCell colSpan={2}>
        <strong>Driver Payout</strong>
      </TableCell>
      <TableCell>
        {stopDriverMap.revenuePercentageRate ?? 0}% of{' '}
        {currency(
          stopDriverMap.driverSettlementTotalAmounts
            .totalEligibleAmountForStop ?? 0,
        ).format()}
      </TableCell>
      <TableCell>
        {currency(
          stopDriverMap.driverSettlementTotalAmounts.totalDriverPayout ?? 0,
        ).format()}
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell>Charge Type</TableCell>
          <TableCell>Total</TableCell>
          <TableCell>Eligible Amount</TableCell>
          <TableCell>Eligible Total</TableCell>
        </TableHead>
        <TableBody>
          {freightChargeRow}
          {fuelChargeRow}
          {customChargeRows}
          {deductionsRow}
          {totalChargesRow}
          {driverPayoutRow}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettlementChargesTable;
