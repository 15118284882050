import { Build, CheckCircle, LocalShipping } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useSamsaraOAuthTokenQuery,
} from '../../generated/graphql';

const useStyles = () => {
  return {
    box: {
      height: '70vh', // 100% doesn't seem to work here
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};

const SamsaraSettingsTab = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsSamsara } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsSamsara,
  );

  const styles = useStyles();
  const navigate = useNavigate();
  const { data, loading } = useSamsaraOAuthTokenQuery();
  if (loading) {
    return (
      <Box sx={styles.box}>
        <CircularProgress />
      </Box>
    );
  }
  if (!isNil(data?.samsaraOAuthToken?.uuid)) {
    return (
      <Box sx={styles.box}>
        <CheckCircle
          sx={{
            color: 'green',
            fontSize: '40px',
            mb: '20px',
          }}
        />
        <Typography sx={{ fontSize: '30px' }}>
          Samsara integration complete
        </Typography>
        <Button
          onClick={() => {
            navigate('/samsara');
          }}
          startIcon={<Build />}
          sx={{ mt: 5 }}
          variant="contained"
          disabled={!canWriteSettingsSamsara}
        >
          Repair
        </Button>
      </Box>
    );
  }
  return (
    <Box sx={styles.box}>
      <LocalShipping
        sx={{
          fontSize: '40px',
          mb: '20px',
        }}
      />
      <Button
        onClick={() => {
          navigate('/samsara');
        }}
        variant="contained"
        disabled={!canWriteSettingsSamsara}
      >
        Click here to set up your Samsara integration
      </Button>
    </Box>
  );
};

export default SamsaraSettingsTab;
