// eslint-disable-next-line no-restricted-imports
import { Button, Card, Chip, Fade, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { transformAddressToFullAddressString } from 'shared/copy';
import { shallow } from 'zustand/shallow';
import {
  SameDayDispatchCellHeader,
  SameDayDispatchRouteFragment,
  SameDayDispatchRouteSlotFragment,
  StopStatus,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { getCellHeader } from './daily-control-center-stop-item';

const useStyles = () => ({
  card: {
    minHeight: 200,
    minWidth: 150,
    maxWidth: 200,
    padding: 2,
    paddingTop: 1,
  },
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  centeredColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBold: {
    fontWeight: 700,
  },
  textSmall: {
    fontSize: 14,
    fontWeight: 400,
  },
});

interface DriverRouteStopCardProps {
  route: SameDayDispatchRouteFragment;
  routeSlotUuid: string;
  stop: SameDayDispatchRouteSlotFragment['stops'][0];
  sameDayDispatchCellHeader: SameDayDispatchCellHeader;
}

const DriverRouteStopCard = ({
  route,
  routeSlotUuid,
  stop,
  sameDayDispatchCellHeader,
}: DriverRouteStopCardProps) => {
  const styles = useStyles();
  const shipment = stop?.leg?.shipment;
  const navigate = useNavigate();
  const [setCurrentRoute, setCurrentRouteSlotUuid] = useGlobalStore(
    (state) => [state.setCurrentRoute, state.setCurrentRouteSlotUuid],
    shallow,
  );

  // Cut off the hyphenated count at the end of ORDER-NUMBER
  const orderName = shipment?.order?.name ?? 'Order';
  const cellHeader = getCellHeader({
    billOfLadingNumber:
      shipment?.order?.standardOrderFields.shipperBillOfLadingNumber,
    orderName,
    stopType: stop.stopType,
    sameDayDispatchCellHeader,
    secondaryRefNumber: shipment?.order?.secondaryRefNumber,
    service: shipment?.order?.service,
  });
  const [isHovering, setIsHovering] = useState(false);

  const stopStatusChip = useMemo(() => {
    if (stop.status === StopStatus.Completed) {
      return <Chip label="Completed" color="success" />;
    }
    if (stop.status === StopStatus.Arrived) {
      return <Chip label="Arrived" color="primary" />;
    }
    if (stop.status === StopStatus.Failed) {
      return <Chip label="Attempt" color="error" />;
    }
    if (stop.status === StopStatus.NotArrived) {
      return <Chip label="Not Arrived" color="info" />;
    }
    return <Chip label="In Progress" color="info" />;
  }, [stop]);

  const stopAddress = stop?.address;
  const stopNotes = stop?.notes;

  return (
    <Card
      sx={styles.card}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sx={styles.centeredRow}>
          <Typography sx={styles.textBold}>{cellHeader}</Typography>
        </Grid>
        <Grid item xs={12} sx={styles.centeredRow}>
          {stopStatusChip}
        </Grid>
        {!isNil(stopAddress) && (
          <>
            <Grid item xs={12} sx={styles.centeredRow}>
              <Typography sx={styles.textBold}>Address</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.centeredRow}>
              <Typography sx={styles.textSmall}>
                {transformAddressToFullAddressString(stopAddress, true)}
              </Typography>
            </Grid>
          </>
        )}
        {!isNil(stopNotes) && (
          <>
            <Grid item xs={12} sx={styles.centeredRow}>
              <Typography sx={styles.textBold}>Reason</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.centeredRow}>
              <Typography sx={styles.textSmall}>{stopNotes}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={6} sx={styles.centeredColumn}>
          <Typography sx={styles.textBold}>Arrived</Typography>
          <Typography sx={styles.textSmall}>
            {!isNil(stop?.arrivedAt)
              ? dayjs(stop?.arrivedAt).format('hh:mma')
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={styles.centeredColumn}>
          <Typography sx={styles.textBold}>Completed</Typography>
          <Typography sx={styles.textSmall}>
            {!isNil(stop?.completedAt)
              ? dayjs(stop?.completedAt).format('hh:mma')
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={styles.centeredRow}>
          <Fade in={isHovering}>
            <Button
              variant="contained"
              onClick={() => {
                setCurrentRouteSlotUuid(routeSlotUuid);
                setCurrentRoute(route);
                navigate(`/orders/?orderUuid=${shipment?.order?.uuid ?? ''}`);
              }}
            >
              View order
            </Button>
          </Fade>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DriverRouteStopCard;
