import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { objectKeys } from 'tsafe';
import GeneralLedgerCodeAutocomplete from '../../../../common/components/general-ledger-code-autocomplete';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  AccessorialType,
  CreateAccessorialInput,
  UpdateAccessorialInput,
  useAccessorialLazyQuery,
  useCreateAccessorialMutation,
  useFuelProfilesQuery,
  useUpdateAccessorialMutation,
} from '../../../../generated/graphql';
import { muiStyles } from '../accessorial.styles';
import AccessorialPrices from './accessorial-prices/accessorial-prices';
import {
  ALL_TERMINALS,
  FormMode,
  getBackUrl,
  NO_FUEL_PROFILE,
  QuantityBasedAccessorialFormErrors,
  QuantityBasedAccessorialFormFieldName,
  QuantityBasedAccessorialFormValues,
  QuantityBasedAccessorialTextField,
  validateAllFields,
  validateField,
} from './common';

const useFormValues = (
  uuid: string | undefined,
): [
  QuantityBasedAccessorialFormValues,
  React.Dispatch<React.SetStateAction<QuantityBasedAccessorialFormValues>>,
] => {
  const [formValues, setFormValues] =
    useState<QuantityBasedAccessorialFormValues>({
      name: null,
      rate: 0.0,
      maximumCharge: null,
      minimumCharge: null,
      percentForSettlement: 100,
      terminalUuid: ALL_TERMINALS,
      fuelProfileUuid: NO_FUEL_PROFILE,
      code: null,
      ediCode: null,
      invoiceDisplayName: null,
      generalLedgerCodeId: null,
    });
  const [accessorialQuery] = useAccessorialLazyQuery();

  useEffect(() => {
    if (uuid !== undefined) {
      accessorialQuery({ variables: { uuid: uuid ?? '' } }).then((response) => {
        const accessorial = response.data?.accessorial;

        if (!isNil(accessorial)) {
          const isUnitBased =
            accessorial.__typename === 'UnitBasedAccessorialEntity';
          const isSkidBased =
            accessorial.__typename === 'SkidBasedAccessorialEntity';
          let rate;
          let minimumCharge;
          let maximumCharge;
          if (isUnitBased) {
            rate = accessorial.ratePerUnit;
            maximumCharge = accessorial.maximumCharge;
            minimumCharge = accessorial.minimumCharge;
          } else if (isSkidBased) {
            rate = accessorial.ratePerSkid;
            maximumCharge = accessorial.maximumCharge;
            minimumCharge = accessorial.minimumCharge;
          }
          const {
            name,
            code,
            ediCode,
            percentForSettlement,
            terminal,
            fuelProfile,
          } = accessorial;
          const terminalUuid = terminal?.uuid;
          const fuelProfileUuid = fuelProfile?.uuid;
          setFormValues({
            name,
            rate: rate ?? null,
            maximumCharge: maximumCharge ?? null,
            minimumCharge: minimumCharge ?? null,
            percentForSettlement: percentForSettlement ?? null,
            terminalUuid: !isEmpty(terminalUuid) ? terminalUuid : ALL_TERMINALS,
            fuelProfileUuid: !isEmpty(fuelProfileUuid)
              ? fuelProfileUuid
              : NO_FUEL_PROFILE,
            code: code ?? null,
            ediCode: ediCode ?? null,
            invoiceDisplayName: accessorial.invoiceDisplayName ?? null,
            generalLedgerCodeId: accessorial.generalLedgerCode?.id ?? null,
          });
        }
      });
    }
  }, [accessorialQuery, uuid]);

  return [formValues, setFormValues];
};

const makeCreateInput = (
  formValues: QuantityBasedAccessorialFormValues,
  accessorialType: AccessorialType.Unit | AccessorialType.Skid,
  contactUuid: string | undefined,
  templateUuid: string | undefined,
  isAuthoCodeRequired: boolean,
  // eslint-disable-next-line consistent-return
): { variables: { input: CreateAccessorialInput } } => {
  // This should never happen since these must be validated beforehand.
  if (formValues.rate === null || formValues.name === null) {
    throw new Error('Name or rate must be defined');
  }

  // eslint-disable-next-line default-case
  switch (accessorialType) {
    case AccessorialType.Unit:
      return {
        variables: {
          input: {
            accessorialCreateInput: {
              unitBasedAccessorialCreateInput: {
                name: formValues.name,
                minimumCharge: formValues.minimumCharge,
                maximumCharge: formValues.maximumCharge,
                ratePerUnit: formValues.rate,
              },
              matchingGlobalAccessorial: templateUuid,
              contactUuid,
              percentForSettlement: formValues.percentForSettlement,
              terminalUuid:
                formValues.terminalUuid !== ALL_TERMINALS
                  ? formValues.terminalUuid
                  : null,
              code: formValues.code,
              ediCode: formValues.ediCode,
              fuelProfileUuid:
                formValues.fuelProfileUuid !== NO_FUEL_PROFILE
                  ? formValues.fuelProfileUuid
                  : null,
              isAuthoCodeRequired,
              generalLedgerCodeId: formValues.generalLedgerCodeId,
              invoiceDisplayName: formValues.invoiceDisplayName,
            },
          },
        },
      };
    case AccessorialType.Skid:
      return {
        variables: {
          input: {
            accessorialCreateInput: {
              skidBasedAccessorialCreateInput: {
                name: formValues.name,
                minimumCharge: formValues.minimumCharge,
                maximumCharge: formValues.maximumCharge,
                ratePerSkid: formValues.rate,
              },
              matchingGlobalAccessorial: templateUuid,
              contactUuid,
              percentForSettlement: formValues.percentForSettlement,
              terminalUuid:
                formValues.terminalUuid !== ALL_TERMINALS
                  ? formValues.terminalUuid
                  : null,
              code: formValues.code,
              ediCode: formValues.ediCode,
              fuelProfileUuid:
                formValues.fuelProfileUuid !== NO_FUEL_PROFILE
                  ? formValues.fuelProfileUuid
                  : null,
              isAuthoCodeRequired,
              generalLedgerCodeId: formValues.generalLedgerCodeId,
              invoiceDisplayName: formValues.invoiceDisplayName,
            },
          },
        },
      };
  }
};

const makeUpdateInput = (
  formValues: QuantityBasedAccessorialFormValues,
  accessorialType: AccessorialType.Unit | AccessorialType.Skid,
  uuid: string,
  isAuthoCodeRequired: boolean,
): {
  variables: {
    input: UpdateAccessorialInput;
  };
  // eslint-disable-next-line consistent-return
} => {
  // This should never happen since these must be validated beforehand.
  if (formValues.rate === null || formValues.name === null) {
    throw new Error('Name or rate is null after validation');
  }
  // eslint-disable-next-line default-case
  switch (accessorialType) {
    case AccessorialType.Unit:
      return {
        variables: {
          input: {
            accessorialUpdateInput: {
              unitBasedAccessorialUpdateInput: {
                name: formValues.name,
                minimumCharge: formValues.minimumCharge,
                maximumCharge: formValues.maximumCharge,
                ratePerUnit: formValues.rate,
                uuid,
              },
              percentForSettlement: formValues.percentForSettlement,
              terminalUuid:
                formValues.terminalUuid !== ALL_TERMINALS
                  ? formValues.terminalUuid
                  : null,
              code: formValues.code,
              ediCode: formValues.ediCode,
              fuelProfileUuid:
                formValues.fuelProfileUuid !== NO_FUEL_PROFILE
                  ? formValues.fuelProfileUuid
                  : null,
              isAuthoCodeRequired,
              generalLedgerCodeId: formValues.generalLedgerCodeId,
              invoiceDisplayName: formValues.invoiceDisplayName,
            },
          },
        },
      };
    case AccessorialType.Skid:
      return {
        variables: {
          input: {
            accessorialUpdateInput: {
              skidBasedAccessorialUpdateInput: {
                name: formValues.name,
                minimumCharge: formValues.minimumCharge,
                maximumCharge: formValues.maximumCharge,
                ratePerSkid: formValues.rate,
                uuid,
              },
              percentForSettlement: formValues.percentForSettlement,
              terminalUuid:
                formValues.terminalUuid !== ALL_TERMINALS
                  ? formValues.terminalUuid
                  : null,
              code: formValues.code,
              ediCode: formValues.ediCode,
              fuelProfileUuid:
                formValues.fuelProfileUuid !== NO_FUEL_PROFILE
                  ? formValues.fuelProfileUuid
                  : null,
              isAuthoCodeRequired,
              generalLedgerCodeId: formValues.generalLedgerCodeId,
              invoiceDisplayName: formValues.invoiceDisplayName,
            },
          },
        },
      };
  }
};

interface QuantityBasedAccessorialFormProps {
  mode: FormMode;
  uuid: string | undefined;
  contactUuid: string | undefined;
  accessorialType: AccessorialType.Unit | AccessorialType.Skid;
  templateUuid?: string | undefined;
  isAuthoCodeRequired: boolean;
}

const QuantityBasedAccessorialForm = ({
  mode,
  uuid,
  contactUuid,
  accessorialType,
  templateUuid,
  isAuthoCodeRequired,
}: QuantityBasedAccessorialFormProps) => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] =
    useState<QuantityBasedAccessorialFormErrors>({});
  const [formValues, setFormValues] = useFormValues(
    templateUuid !== undefined ? templateUuid : uuid,
  );

  const { terminalsEnabled, terminals, terminalsLoading } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();
  const [createAccessorialMutation] = useCreateAccessorialMutation({});
  const [updateAccessorialMutation] = useUpdateAccessorialMutation({});
  // const [removeAccessorialMutation] = useRemoveAccessorialMutation({});

  const onChange = (
    field: QuantityBasedAccessorialFormFieldName,
    value: string | number | boolean | null,
  ) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const validateFieldsAndSetErrors = (
    field: QuantityBasedAccessorialFormFieldName,
  ) => {
    const validationResult = validateField(field, formValues[field]);
    if (!validationResult.valid) {
      setFormErrors({ ...formErrors, [field]: validationResult.explanation });
    } else {
      setFormErrors({ ...formErrors, [field]: undefined });
    }
  };

  const onSave = async () => {
    const invalidFields = validateAllFields(formValues);
    const invalidFieldNames = objectKeys(invalidFields);
    if (invalidFieldNames.length === 0) {
      if (mode === FormMode.CREATE) {
        const input = makeCreateInput(
          formValues,
          accessorialType,
          contactUuid,
          templateUuid,
          isAuthoCodeRequired,
        );
        // TODO: handle the errors from this don't just assume it passes
        await createAccessorialMutation(input);
        navigate(getBackUrl(contactUuid));
      } else if (mode === FormMode.EDIT) {
        // This should never happen
        if (uuid === null) {
          throw new Error('uuid is not defined but in edit mode');
        }
        const input = makeUpdateInput(
          formValues,
          accessorialType,
          uuid as string,
          isAuthoCodeRequired,
        );
        // TODO: handle the errors from this don't just assume it passes
        await updateAccessorialMutation(input);
        navigate(getBackUrl(contactUuid));
      }
    } else {
      invalidFieldNames.forEach((field) => {
        const validationResultExplanation =
          invalidFields[field]?.explanation ?? '';
        setFormErrors({
          ...formErrors,
          [field]: validationResultExplanation,
        });
      });
    }
  };

  return (
    <Box sx={muiStyles.pageContainer}>
      <Box sx={muiStyles.buttonRow}>
        {(mode === FormMode.CREATE || mode === FormMode.EDIT) && (
          <Button onClick={onSave} variant="contained">
            Save
          </Button>
        )}
      </Box>
      <Box sx={muiStyles.pageContent}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.name}
              error={formErrors?.name}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="name"
              label="Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              value={formValues.percentForSettlement}
              error={formErrors?.percentForSettlement}
              name="percentForSettlement"
              type="number"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              label="Settlement Rate"
              isPercent
              fullWidth
            />
          </Grid>
          {mode === FormMode.CREATE && (
            <Grid item xs={3}>
              <QuantityBasedAccessorialTextField
                mode={mode}
                value={formValues.rate}
                error={formErrors?.rate}
                name="rate"
                type="number"
                onBlur={validateFieldsAndSetErrors}
                onChange={onChange}
                label={
                  accessorialType === AccessorialType.Skid
                    ? 'Rate per skid'
                    : 'Rate per unit'
                }
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              value={formValues.minimumCharge}
              error={formErrors?.minimumCharge}
              name="minimumCharge"
              type="number"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              label="Minimum charge"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              value={formValues.maximumCharge}
              error={formErrors?.maximumCharge}
              name="maximumCharge"
              type="number"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              label="Maximum charge"
              fullWidth
            />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.terminalUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.terminalUuid) &&
                    !isEmpty(formValues.terminalUuid)
                  }
                  id="select-terminal-label"
                >
                  Terminal
                </InputLabel>
                <Select
                  labelId="select-terminal-label"
                  id="select-terminal"
                  label="Terminal"
                  value={formValues.terminalUuid}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        terminalUuid: event.target.value,
                      });
                    }
                  }}
                  disabled={terminalsLoading}
                  size="small"
                  fullWidth
                >
                  <MenuItem key={ALL_TERMINALS} value={ALL_TERMINALS}>
                    {ALL_TERMINALS}
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.terminalUuid) && (
                  <FormHelperText error>
                    {formErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {mode === FormMode.CREATE && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.fuelProfileUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.fuelProfileUuid) &&
                    !isEmpty(formValues.fuelProfileUuid)
                  }
                  id="select-fuel-profile-label"
                >
                  Fuel Profile
                </InputLabel>
                <Select
                  labelId="select-fuel-profile-label"
                  id="select-fuel-profile"
                  label="Fuel Profile"
                  value={formValues.fuelProfileUuid}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        fuelProfileUuid: event.target.value,
                      });
                    }
                  }}
                  disabled={fuelProfilesLoading}
                  size="small"
                  fullWidth
                >
                  <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                    {NO_FUEL_PROFILE}
                  </MenuItem>
                  {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.fuelProfileUuid) && (
                  <FormHelperText error>
                    {formErrors.fuelProfileUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.code}
              error={formErrors?.code}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="code"
              label="Accessorial Code"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.ediCode}
              error={formErrors?.ediCode}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="ediCode"
              label="EDI Code"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <QuantityBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.invoiceDisplayName}
              error={formErrors?.invoiceDisplayName}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="invoiceDisplayName"
              label="Display on invoices as"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <GeneralLedgerCodeAutocomplete
              value={formValues.generalLedgerCodeId}
              formError={formErrors.generalLedgerCodeId ?? null}
              setValue={(newValue: string | null) => {
                setFormValues({
                  ...formValues,
                  generalLedgerCodeId: newValue,
                });
              }}
              disabled={!isNil(contactUuid)}
              size="small"
            />
          </Grid>
          {!isNil(uuid) && (
            <Grid item xs={12}>
              <AccessorialPrices
                accessorialUuid={uuid}
                accessorialType={accessorialType}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default QuantityBasedAccessorialForm;
