import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { DateOption } from '../../../../../common/components/date-dropdown-picker';

export const formatDateOption = (dateOption: DateOption) => {
  if (isNil(dateOption.startDate) && isNil(dateOption.endDate)) {
    return 'All';
  }
  return `${!isNil(dateOption.startDate) ? dayjs(dateOption.startDate).format('MM/DD') : 'All'} - ${
    !isNil(dateOption.endDate)
      ? dayjs(dateOption.endDate).format('MM/DD/YY')
      : 'All'
  }`;
};
