import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import {
  TariffGroupType,
  TariffZoneType,
  useTariffZoneGroupsQuery,
} from '../../../../../generated/graphql';
import useTariffGroupActions from '../hooks/use-tariff-group-actions';
import { TariffModalMode } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';

const ZoneGroupField = () => {
  const [
    modalMode,
    tariffZoneGroupId,
    setTariffZoneGroupId,
    tariffGroupType,
    zoneType,
  ] = useTariffGroupStore(
    (state) => [
      state.modalMode,
      state.tariffZoneGroupId,
      state.setTariffZoneGroupId,
      state.tariffGroupType,
      state.zoneType,
    ],
    shallow,
  );

  const { refetchAndSetLocationZones } = useTariffGroupActions();
  const { data: zoneGroupsData, loading: zoneGroupsLoading } =
    useTariffZoneGroupsQuery({
      variables: {
        findTariffZoneGroupsInput: {
          isArchived: false,
        },
      },
    });

  const handleRemoveZoneGroup = useCallback(() => {
    setTariffZoneGroupId(null);

    // pass null here because we want to reset to the zones from
    // info that we have in the store.
    refetchAndSetLocationZones({ variables: null });
  }, [refetchAndSetLocationZones, setTariffZoneGroupId]);

  const tariffCanHaveZoneGroup = useMemo(() => {
    // Zone groups are only applicable for ordinary, zone-based tariffs
    // (They're also applicable for zone-based point-to-point tariffs, but
    // these cannot currently be created in the UI to begin with, so that's
    // not yet a concern).
    if (tariffGroupType !== TariffGroupType.Ordinary) {
      return false;
    }
    // Zone groups are only applicable for zone-based tariffs
    if (zoneType !== TariffZoneType.Location) {
      return false;
    }
    return true;
  }, [tariffGroupType, zoneType]);

  const canEditZoneGroup = useMemo(() => {
    if (!tariffCanHaveZoneGroup) {
      return false;
    }
    // No editing during loading
    if (zoneGroupsLoading) {
      return false;
    }
    // Cannot be edited after creation
    if (modalMode !== TariffModalMode.CREATE) {
      return false;
    }

    return true;
  }, [modalMode, zoneGroupsLoading, tariffCanHaveZoneGroup]);

  useEffect(() => {
    if (!tariffCanHaveZoneGroup && !isNil(tariffZoneGroupId)) {
      handleRemoveZoneGroup();
    }
  }, [tariffCanHaveZoneGroup, handleRemoveZoneGroup, tariffZoneGroupId]);

  const sortedTariffZoneGroups = useMemo(
    () =>
      zoneGroupsData?.tariffZoneGroups.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
    [zoneGroupsData],
  );

  return (
    <TextField
      label="Zone Group"
      id="select-zone-group"
      value={tariffZoneGroupId ?? ''}
      onChange={(event) => {
        if (typeof event.target.value === 'string') {
          setTariffZoneGroupId(event.target.value);
        }
      }}
      size="small"
      disabled={!canEditZoneGroup}
      fullWidth
      select
      InputProps={{
        endAdornment: canEditZoneGroup ? (
          <InputAdornment position="end" sx={{ marginRight: '20px' }}>
            <IconButton onClick={handleRemoveZoneGroup}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    >
      {sortedTariffZoneGroups?.map((zoneGroup) => (
        <MenuItem key={zoneGroup.id} value={zoneGroup.id}>
          {zoneGroup.name ?? 'Unnamed'}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default memo(ZoneGroupField);
