import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MAX_INTEGER, safeDivide, safeMultiply } from 'shared/math';
import { objectKeys } from 'tsafe';
import GeneralLedgerCodeAutocomplete from '../../../../common/components/general-ledger-code-autocomplete';
import {
  validateNonNegativeNumber,
  validateString,
  ValidationResponse,
} from '../../../../common/form/formValidators';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  AccessorialType,
  CreateAccessorialInput,
  UpdateAccessorialInput,
  useAccessorialLazyQuery,
  useCreateAccessorialMutation,
  useUpdateAccessorialMutation,
} from '../../../../generated/graphql';
import { muiStyles } from '../accessorial.styles';
import AccessorialPrices from './accessorial-prices/accessorial-prices';
import {
  AccessorialRange,
  ALL_TERMINALS,
  FormMode,
  getBackUrl,
  NO_FUEL_PROFILE,
  WeightBasedAccessorialFormErrors,
  WeightBasedAccessorialFormFieldName,
  WeightBasedAccessorialFormValues,
  WeightBasedAccessorialTextField,
} from './common';
import RangeBasedAccessorialRateEditor from './range-based-accessorial-rate-editor';
import useAccessorialEditorStore from './use-accessorial-editor-store';

const useFormValues = (
  uuid: string | undefined,
): [
  WeightBasedAccessorialFormValues,
  React.Dispatch<React.SetStateAction<WeightBasedAccessorialFormValues>>,
] => {
  const [formValues, setFormValues] =
    useState<WeightBasedAccessorialFormValues>({
      name: null,
      ranges: [
        {
          uuid: null,
          lessThanOrEqualToValue: MAX_INTEGER,
          rate: null,
        },
      ],
      maximumCharge: null,
      minimumCharge: null,
      percentForSettlement: 100,
      terminalUuid: ALL_TERMINALS,
      fuelProfileUuid: NO_FUEL_PROFILE,
      code: null,
      ediCode: null,
      invoiceDisplayName: null,
      generalLedgerCodeId: null,
    });
  const [accessorialQuery] = useAccessorialLazyQuery();

  useEffect(() => {
    if (uuid !== undefined) {
      accessorialQuery({ variables: { uuid: uuid ?? '' } }).then((response) => {
        const accessorial = response.data?.accessorial;

        if (!isNil(accessorial)) {
          const isWeightBased =
            accessorial.__typename === 'WeightBasedAccessorialEntity';
          let minimumCharge;
          let maximumCharge;
          if (isWeightBased) {
            maximumCharge = accessorial.maximumCharge;
            minimumCharge = accessorial.minimumCharge;
          }
          const {
            name,
            code,
            ediCode,
            percentForSettlement,
            terminal,
            fuelProfile,
            invoiceDisplayName,
          } = accessorial;
          const terminalUuid = terminal?.uuid;
          const fuelProfileUuid = fuelProfile?.uuid;
          const ranges =
            isWeightBased &&
            !isNil(accessorial.accessorialRanges) &&
            accessorial.accessorialRanges.length > 0
              ? accessorial.accessorialRanges
                  .sort(
                    (a, b) =>
                      (a.lessThanOrEqualToValue ?? 0) -
                      (b.lessThanOrEqualToValue ?? 0),
                  )
                  .map((range) => ({
                    ...range,
                    rate: safeDivide(range.rateUsdCents ?? 0, 100, 10),
                  }))
              : [
                  {
                    lessThanOrEqualToValue: MAX_INTEGER,
                    rate: isWeightBased
                      ? accessorial.ratePerHundredWeight
                      : null,
                    uuid: null,
                  },
                ];
          setFormValues({
            name,
            ranges,
            maximumCharge: maximumCharge ?? null,
            minimumCharge: minimumCharge ?? null,
            percentForSettlement: percentForSettlement ?? null,
            terminalUuid: !isEmpty(terminalUuid) ? terminalUuid : ALL_TERMINALS,
            fuelProfileUuid: !isEmpty(fuelProfileUuid)
              ? fuelProfileUuid
              : NO_FUEL_PROFILE,
            code: code ?? null,
            ediCode: ediCode ?? null,
            invoiceDisplayName: invoiceDisplayName ?? null,
            generalLedgerCodeId: accessorial.generalLedgerCode?.id ?? null,
          });
        }
      });
    }
  }, [accessorialQuery, uuid]);

  return [formValues, setFormValues];
};

const makeCreateInput = (
  formValues: WeightBasedAccessorialFormValues,
  accessorialType: AccessorialType.Weight,
  contactUuid: string | undefined,
  templateUuid: string | undefined,
  isAuthoCodeRequired: boolean,
  // eslint-disable-next-line consistent-return
): { variables: { input: CreateAccessorialInput } } => {
  // eslint-disable-next-line default-case
  switch (accessorialType) {
    case AccessorialType.Weight:
      return {
        variables: {
          input: {
            accessorialCreateInput: {
              weightBasedAccessorialCreateInput: {
                name: formValues.name ?? '',
                minimumCharge: formValues.minimumCharge,
                maximumCharge: formValues.maximumCharge,
                ratePerHundredWeight:
                  formValues.ranges.find(
                    (range) => range.lessThanOrEqualToValue === MAX_INTEGER,
                  )?.rate ?? 0,
                accessorialRangeCreateInputs: formValues.ranges.map(
                  (range) => ({
                    lessThanOrEqualToValue: range.lessThanOrEqualToValue ?? 0,
                    rateUsdCents: safeMultiply(range.rate ?? 0, 100),
                  }),
                ),
              },
              matchingGlobalAccessorial: templateUuid,
              contactUuid,
              percentForSettlement: formValues.percentForSettlement,
              terminalUuid:
                formValues.terminalUuid !== ALL_TERMINALS
                  ? formValues.terminalUuid
                  : null,
              code: formValues.code,
              ediCode: formValues.ediCode,
              fuelProfileUuid:
                formValues.fuelProfileUuid !== NO_FUEL_PROFILE
                  ? formValues.fuelProfileUuid
                  : null,
              isAuthoCodeRequired,
              generalLedgerCodeId: formValues.generalLedgerCodeId,
              invoiceDisplayName: formValues.invoiceDisplayName,
            },
          },
        },
      };
  }
};

const makeUpdateInput = (
  formValues: WeightBasedAccessorialFormValues,
  accessorialType: AccessorialType.Weight,
  uuid: string,
  isAuthoCodeRequired: boolean,
): {
  variables: {
    input: UpdateAccessorialInput;
  };
  // eslint-disable-next-line consistent-return
} => {
  // eslint-disable-next-line default-case
  switch (accessorialType) {
    case AccessorialType.Weight:
      return {
        variables: {
          input: {
            accessorialUpdateInput: {
              weightBasedAccessorialUpdateInput: {
                name: formValues.name,
                minimumCharge: formValues.minimumCharge,
                maximumCharge: formValues.maximumCharge,
                ratePerHundredWeight:
                  formValues.ranges.find(
                    (range) => range.lessThanOrEqualToValue === MAX_INTEGER,
                  )?.rate ?? 0,
                accessorialRangeUpdateInputs: null,
                uuid,
              },
              percentForSettlement: formValues.percentForSettlement,
              terminalUuid:
                formValues.terminalUuid !== ALL_TERMINALS
                  ? formValues.terminalUuid
                  : null,
              code: formValues.code,
              ediCode: formValues.ediCode,
              fuelProfileUuid:
                formValues.fuelProfileUuid !== NO_FUEL_PROFILE
                  ? formValues.fuelProfileUuid
                  : null,
              isAuthoCodeRequired,
              generalLedgerCodeId: formValues.generalLedgerCodeId,
              invoiceDisplayName: formValues.invoiceDisplayName,
            },
          },
        },
      };
  }
};

interface RangeBasedAccessorialFormProps {
  mode: FormMode;
  uuid: string | undefined;
  contactUuid: string | undefined;
  accessorialType: AccessorialType.Weight;
  templateUuid?: string | undefined;
  isAuthoCodeRequired: boolean;
}

const RangeBasedAccessorialForm = ({
  mode,
  uuid,
  contactUuid,
  accessorialType,
  templateUuid,
  isAuthoCodeRequired,
}: RangeBasedAccessorialFormProps) => {
  const navigate = useNavigate();
  const setShouldSaveDateRangeConfig = useAccessorialEditorStore(
    (state) => state.setShouldSaveDateRangeConfig,
  );

  const [formErrors, setFormErrors] =
    useState<WeightBasedAccessorialFormErrors>({});
  const [formValues, setFormValues] = useFormValues(
    templateUuid !== undefined ? templateUuid : uuid,
  );
  const { terminalsEnabled, terminals, terminalsLoading } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [createAccessorial] = useCreateAccessorialMutation();
  const [updateAccessorial] = useUpdateAccessorialMutation();

  const onChange = (
    field: WeightBasedAccessorialFormFieldName,
    value: string | number | boolean | AccessorialRange[] | null,
  ) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const validateRange = (
    range: AccessorialRange,
    idx: number,
  ): { valid: boolean; explanation: string } => {
    if (isNil(range.lessThanOrEqualToValue) || isNil(range.rate)) {
      return { valid: false, explanation: `Range ${idx + 1} is invalid` };
    }
    return { valid: true, explanation: '' };
  };

  const validateField = (
    field: WeightBasedAccessorialFormFieldName,
    value: string | number | boolean | AccessorialRange[] | null | undefined,
  ): { valid: boolean; explanation: string }[] => {
    if (
      field === 'minimumCharge' ||
      field === 'maximumCharge' ||
      field === 'percentForSettlement'
    ) {
      return [validateNonNegativeNumber(value as number, false)];
    }
    if (field === 'name') {
      return [validateString(value as string, true)];
    }
    if (field === 'ranges') {
      return formValues.ranges.map((range, idx) => validateRange(range, idx));
    }
    return [{ valid: true, explanation: '' }];
  };

  const validateFieldsAndSetErrors = (
    field: WeightBasedAccessorialFormFieldName,
  ) => {
    const validationResult = validateField(field, formValues[field]);
    if (!validationResult.every((res) => res.valid)) {
      setFormErrors({
        ...formErrors,
        [field]: validationResult.map((res) => res.explanation),
      });
    } else {
      setFormErrors({ ...formErrors, [field]: undefined });
    }
  };

  const validateAllFields = () => {
    const invalidFields: {
      [k in WeightBasedAccessorialFormFieldName]?: ValidationResponse[];
    } = {};
    objectKeys(formValues).forEach((fieldName) => {
      const result = validateField(fieldName, formValues[fieldName]);
      if (!result.every((res) => res.valid)) {
        invalidFields[fieldName] = result;
      }
    });
    return invalidFields;
  };

  const onSave = async () => {
    const invalidFields = validateAllFields();
    const invalidFieldNames = objectKeys(invalidFields);
    if (invalidFieldNames.length === 0) {
      if (mode === FormMode.CREATE) {
        const input = makeCreateInput(
          formValues,
          accessorialType,
          contactUuid,
          templateUuid,
          isAuthoCodeRequired,
        );
        // TODO: handle the errors from this don't just assume it passes
        await createAccessorial(input);
        navigate(getBackUrl(contactUuid));
      } else if (mode === FormMode.EDIT) {
        // This should never happen
        if (uuid === null) {
          throw new Error('uuid is not defined but in edit mode');
        }
        const input = makeUpdateInput(
          formValues,
          accessorialType,
          uuid as string,
          isAuthoCodeRequired,
        );

        setShouldSaveDateRangeConfig(true);
        // TODO: handle the errors from this don't just assume it passes
        await updateAccessorial(input);
        navigate(getBackUrl(contactUuid));
      }
    } else {
      invalidFieldNames.forEach((field) => {
        const validationResultExplanation =
          invalidFields[field]
            ?.filter((invalidField) => !invalidField.valid)
            .map((invalidField) => invalidField.explanation)
            .join(', ') ?? '';
        setFormErrors({
          ...formErrors,
          [field]: validationResultExplanation,
        });
      });
    }
  };

  return (
    <Box sx={muiStyles.pageContainer} gap={12}>
      <Box sx={muiStyles.buttonRow}>
        {(mode === FormMode.CREATE || mode === FormMode.EDIT) && (
          <Button onClick={onSave} variant="contained">
            Save
          </Button>
        )}
      </Box>
      <Box sx={muiStyles.pageContent}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.name}
              error={formErrors?.name?.join(', ')}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="name"
              label="Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              value={formValues.percentForSettlement}
              error={formErrors?.percentForSettlement?.join(', ')}
              name="percentForSettlement"
              type="number"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              label="Settlement Rate"
              isPercent
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              value={formValues.minimumCharge}
              error={formErrors?.minimumCharge?.join(', ')}
              name="minimumCharge"
              type="number"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              label="Minimum charge"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              value={formValues.maximumCharge}
              error={formErrors?.maximumCharge?.join(', ')}
              name="maximumCharge"
              type="number"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              label="Maximum charge"
              fullWidth
            />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.terminalUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.terminalUuid) &&
                    !isEmpty(formValues.terminalUuid)
                  }
                  id="select-terminal-label"
                >
                  Terminal
                </InputLabel>
                <Select
                  labelId="select-terminal-label"
                  id="select-terminal"
                  label="Terminal"
                  value={formValues.terminalUuid}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        terminalUuid: event.target.value,
                      });
                    }
                  }}
                  disabled={terminalsLoading}
                  size="small"
                >
                  <MenuItem key={ALL_TERMINALS} value={ALL_TERMINALS}>
                    {ALL_TERMINALS}
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.terminalUuid) && (
                  <FormHelperText error>
                    {formErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.code}
              error={formErrors?.code?.join(', ')}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="code"
              label="Accessorial Code"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.ediCode}
              error={formErrors?.ediCode?.join(', ')}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="ediCode"
              label="EDI Code"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <WeightBasedAccessorialTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={formValues.invoiceDisplayName}
              error={formErrors?.invoiceDisplayName?.join(', ')}
              type="text"
              onBlur={validateFieldsAndSetErrors}
              onChange={onChange}
              name="invoiceDisplayName"
              label="Display on invoices as"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <GeneralLedgerCodeAutocomplete
              value={formValues.generalLedgerCodeId ?? null}
              formError={formErrors.generalLedgerCodeId?.join(', ') ?? null}
              setValue={(newValue: string | null) => {
                setFormValues({
                  ...formValues,
                  generalLedgerCodeId: newValue,
                });
              }}
              disabled={!isNil(contactUuid)}
              size="small"
            />
          </Grid>
          {mode === FormMode.CREATE && (
            <Grid item xs={12}>
              <RangeBasedAccessorialRateEditor
                accessorialType={accessorialType}
                mode={mode}
                formValues={formValues}
                onChangeFormValues={(newData) => {
                  setFormValues(newData);
                }}
                formErrors={formErrors}
                validateFieldsAndSetErrors={validateFieldsAndSetErrors}
              />
            </Grid>
          )}

          {!isNil(uuid) && (
            <Grid item xs={12}>
              <AccessorialPrices
                accessorialUuid={uuid}
                accessorialType={AccessorialType.Weight}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default RangeBasedAccessorialForm;
