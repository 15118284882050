import {
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import CenteredCircularProgress from '../../../../../../common/components/centered-circular-progress';
import CustomerFilterButton from '../../../../../../common/components/customer-filter-button';
import { DemoTariffGroupTypeFilterButton } from '../../../../../../common/components/demo/demo-tariff-group-type-filter-button';
import ServiceFilterButton from '../../../../../../common/components/service-filter-button';
import { Option } from '../../../../../../common/filters/types';
import {
  TariffGroupType,
  useTariffGroupsLazyQuery,
} from '../../../../../../generated/graphql';
import { DemoTariffTableRow } from './demo-tariff-table-row';

const DEFAULT_INVOICES_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50];

export const DemoAllTariffsTable = () => {
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [serviceOption, setServiceOption] = useState<
    Option | null | undefined
  >();

  const [tariffGroupTypeOption, setTariffGroupTypeOption] = useState<
    TariffGroupType | null | undefined
  >();

  const [
    getTariffGroups,
    { data: tariffGroupsData, loading: tariffGroupsLoading },
  ] = useTariffGroupsLazyQuery();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_INVOICES_PAGE_SIZE,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 200);

  const handleCustomerChange = (option: Option | undefined) => {
    setCustomerOption(option);
  };
  const handleServiceChange = (option?: Option | null) => {
    setServiceOption(option);
  };
  const handleTariffGroupTypeChange = (
    option: TariffGroupType | null | undefined,
  ) => {
    setTariffGroupTypeOption(option);
    setPage(0);
  };

  const fetchTariffGroups = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    await getTariffGroups({
      variables: {
        first,
        after,
        last,
        before,
        searchText: searchText.trim(),
        serviceUuid: serviceOption?.value,
        contactUuid: customerOption?.value,
        tariffGroupType: tariffGroupTypeOption,
        excludeDefault: true,
      },
    });
  };

  const prev = async () => {
    await fetchTariffGroups({
      last: rowsPerPage,
      before:
        tariffGroupsData?.tariffGroupsPaginated.pageInfo.startCursor ??
        undefined,
    });
  };
  const next = async () => {
    await fetchTariffGroups({
      first: rowsPerPage,
      after:
        tariffGroupsData?.tariffGroupsPaginated.pageInfo.endCursor ?? undefined,
    });
  };

  useEffect(() => {
    fetchTariffGroups({ first: rowsPerPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerOption,
    serviceOption,
    tariffGroupTypeOption,
    rowsPerPage,
    debouncedSearchText,
  ]);

  const tariffGroupsTableComponent = tariffGroupsLoading ? (
    <CenteredCircularProgress />
  ) : (
    <TableContainer sx={{ overflowY: 'scroll' }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell width={250}>Name</TableCell>
            <TableCell>Customers applied to</TableCell>
            <TableCell>Tariff type</TableCell>
            <TableCell>Rate type</TableCell>
            <TableCell>Zone type</TableCell>
            <TableCell>Unit type</TableCell>
            <TableCell>Start / end</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tariffGroupsData?.tariffGroupsPaginated.edges.map(
            ({ node: tariffGroup }) => (
              <DemoTariffTableRow
                key={tariffGroup.uuid}
                tariffGroup={tariffGroup}
              />
            ),
          )}
        </TableBody>
      </Table>
      <Stack alignItems="center" padding="20px">
        {!tariffGroupsLoading &&
          isEmpty(tariffGroupsData?.tariffGroupsPaginated.edges) && (
            <Typography>No tariffs found</Typography>
          )}
      </Stack>
    </TableContainer>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ mb: 1 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1}>
            <TextField
              size="small"
              sx={{ width: '250px' }}
              label="Search Tariffs"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                endAdornment: tariffGroupsLoading && (
                  <CircularProgress size={15} />
                ),
              }}
            />
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={handleCustomerChange}
            />
            <ServiceFilterButton
              selectedOption={serviceOption}
              handleChange={handleServiceChange}
            />
            <DemoTariffGroupTypeFilterButton
              selectedOption={tariffGroupTypeOption}
              handleChange={handleTariffGroupTypeChange}
            />
          </Stack>
          <TablePagination
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            labelRowsPerPage="Show"
            component="div"
            count={tariffGroupsData?.tariffGroupsPaginated.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next();
              } else {
                prev();
              }
              setPage(newPage);
            }}
            backIconButtonProps={{
              disabled: tariffGroupsLoading || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                tariffGroupsLoading ||
                tariffGroupsData?.tariffGroupsPaginated.totalCount === 0 ||
                page + 1 ===
                  Math.ceil(
                    (tariffGroupsData?.tariffGroupsPaginated.totalCount ?? 0) /
                      rowsPerPage,
                  ),
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(+e.target.value);
            }}
          />
        </Stack>
        {tariffGroupsTableComponent}
      </Grid>
    </Grid>
  );
};
