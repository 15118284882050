import {
  Checkbox,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { EntityId } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import ClearTextFieldButton from '../../../../../common/components/clear-text-field-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import { SortType } from '../../../../../common/components/sort-component';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import { isProduction } from '../../../../../environment-variables';
import {
  Segment,
  useEdiAndApiOrdersForReviewLazyQuery,
} from '../../../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { selectStandardOrdersByIds } from '../../../redux/standard/standard-orders-values-slice';
import { initExistingStandardOrderValues } from '../../../redux/standard/standard-orders-values-thunks';
import ReviewEdiOrdersTableRow from './review-edi-orders-table-row';

const useStyles = () => ({
  headerRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  checkBoxTableCell: {
    width: '40px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
});

const DEFAULT_PAGE_SIZE = 10;
// const ROW_PER_PAGE_OPTIONS = [10, 25, 50];
const ReviewEdiOrdersTable = ({
  selectedOrderUuids,
  setSelectedOrderUuids,
  setLoadingOrders,
  orderUuidsWithPendingChanges,
  setOrderUuidsWithPendingChanges,
}: {
  selectedOrderUuids: string[];
  setSelectedOrderUuids: Dispatch<SetStateAction<string[]>>;
  setLoadingOrders: Dispatch<SetStateAction<boolean>>;
  orderUuidsWithPendingChanges: string[];
  setOrderUuidsWithPendingChanges: Dispatch<SetStateAction<string[]>>;
}) => {
  const [searchParams] = useSearchParams();
  const { segment } = useMe();
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(DEFAULT_PAGE_SIZE);
  const [createdAtSortState, setCreatedAtSortState] = useState<SortType>(
    SortType.Descending,
  );
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [originTerminalOption, setOriginTerminalOption] = useState<
    Option | undefined
  >();
  const [destinationTerminalOption, setDestinationTerminalOption] = useState<
    Option | undefined
  >();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const [page, setPage] = useState<number>(0);
  const [
    getEdiAndApiOrdersForReview,
    { data: ediAndApiOrdersForReviewData, loading },
  ] = useEdiAndApiOrdersForReviewLazyQuery();

  const dispatch = useAppDispatch();

  const orderUuid = searchParams.get('orderUuid');

  const handleCreatedDateSort = () => {
    if (createdAtSortState === SortType.Ascending) {
      setCreatedAtSortState(SortType.Descending);
    } else if (createdAtSortState === SortType.Descending) {
      setCreatedAtSortState(SortType.Ascending);
    }
  };

  const selectedOrderValues = useAppSelector((state) =>
    selectStandardOrdersByIds(state, selectedOrderUuids as EntityId[]),
  );

  const styles = useStyles();
  const fetchEdiAndApiOrdersForReview = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    if (!isEmpty(searchText)) {
      // ignore all filters if searching
      await getEdiAndApiOrdersForReview({
        variables: {
          searchText: searchText.trim(),
          first,
          after,
          last,
          before,
          sortByCreatedAtDateAsc: createdAtSortState === SortType.Ascending,
        },
      });
    } else {
      await getEdiAndApiOrdersForReview({
        variables: {
          first,
          after,
          last,
          before,
          contactUuid: customerOption?.value,
          originTerminalUuid: originTerminalOption?.value,
          destinationTerminalUuid: destinationTerminalOption?.value,
          // createdAtDateFilters: dateFilters,
          sortByCreatedAtDateAsc: createdAtSortState === SortType.Ascending,
        },
      });
    }
  };

  const prev = async () => {
    await fetchEdiAndApiOrdersForReview({
      last: rowsPerPage,
      before:
        ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview.pageInfo
          .startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchEdiAndApiOrdersForReview({
      first: rowsPerPage,
      after:
        ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview.pageInfo
          .endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchEdiAndApiOrdersForReview({ first: rowsPerPage });
    setPage(0);
  };

  const handleCustomerChange = (option: Option | undefined) => {
    setCustomerOption(option);
  };
  const handleOriginChange = (option: Option | null | undefined) => {
    setOriginTerminalOption(option ?? undefined);
  };
  const handleDestinationChange = (option: Option | null | undefined) => {
    setDestinationTerminalOption(option ?? undefined);
  };

  const selectAllCheckboxChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setSelectedOrderUuids(
        ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview.edges.map(
          (e) => e.node.uuid,
        ) ?? [],
      );
    } else if (orderUuidsWithPendingChanges.length === 0) {
      setSelectedOrderUuids([]);
    }
  };

  useEffect(() => {
    // when we select, make sure we tell redux about newly selected orders
    const updateRedux = async (uuidsNotInRedux: string[]) => {
      setLoadingOrders(true);
      await Promise.all(
        uuidsNotInRedux.map((uuid) =>
          dispatch(
            initExistingStandardOrderValues({
              orderUuid: uuid,
            }),
          ),
        ),
      );
      setLoadingOrders(false);
    };
    // get uuids that are not in redux:
    const uuidsNotInRedux = selectedOrderUuids.filter(
      (uuid) => !selectedOrderValues.map((val) => val.uuid).includes(uuid),
    );
    updateRedux(uuidsNotInRedux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderUuids]);

  useEffect(() => {
    refresh();
    setOrderUuidsWithPendingChanges([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchText,
    createdAtSortState,
    rowsPerPage,
    customerOption?.value,
    originTerminalOption?.value,
    destinationTerminalOption?.value,
  ]);

  useEffect(() => {
    if (typeof orderUuid === 'string' && !isEmpty(orderUuid)) {
      setSelectedOrderUuids([...selectedOrderUuids, orderUuid]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUuid]);

  const inputFieldString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  const ordersToReview =
    ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview.edges.map(
      ({ node: order }) => order,
    );

  // QA would like to keep source column for testing purposes
  const shouldShowSource = !isProduction();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ ml: '10px', flexWrap: 'wrap' }}
            >
              <CustomerFilterButton
                selectedOption={customerOption}
                handleChange={handleCustomerChange}
              />
              {terminalsEnabled && (
                <TerminalFilterButton
                  selectedOption={originTerminalOption}
                  handleChange={handleOriginChange}
                  prefixText="Orig"
                  displayCode
                  includeInactiveTerminals={false}
                />
              )}
              {terminalsEnabled && (
                <TerminalFilterButton
                  selectedOption={destinationTerminalOption}
                  handleChange={handleDestinationChange}
                  prefixText="Dest"
                  displayCode
                  includeInactiveTerminals={false}
                />
              )}
              <TextField
                size="small"
                label={`Search ${inputFieldString}`}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading === true ? (
                        <CircularProgress size={15} />
                      ) : (
                        <ClearTextFieldButton
                          searchText={searchText}
                          handleClearSearchText={() => {
                            setSearchText('');
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{ minWidth: '200px' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ float: 'right', mr: '5px' }}
            >
              <TablePagination
                rowsPerPageOptions={[]}
                labelRowsPerPage="Show"
                component="div"
                count={
                  ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview
                    .totalCount ?? 0
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage: number) => {
                  if (newPage > page) {
                    next();
                  } else {
                    prev();
                  }
                  setPage(newPage);
                }}
                backIconButtonProps={{
                  disabled: loading === true || page === 0,
                }}
                nextIconButtonProps={{
                  disabled:
                    loading === true ||
                    ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview
                      .totalCount === 0 ||
                    page + 1 ===
                      Math.ceil(
                        (ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview
                          .totalCount ?? 0) / rowsPerPage,
                      ),
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(+e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table
            padding="none"
            stickyHeader
            aria-label="freight-snap-preview-table"
            sx={{ overflowY: 'scroll', tableLayout: 'fixed' }}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell sx={styles.headerRow}></TableCell> */}
                <TableCell sx={styles.checkBoxTableCell}>
                  <Checkbox
                    checked={
                      selectedOrderUuids.length ===
                      ediAndApiOrdersForReviewData?.ediAndApiOrdersForReview
                        .edges.length
                    }
                    onChange={selectAllCheckboxChanged}
                  />
                </TableCell>
                <TableCell sx={styles.headerRow}>Order</TableCell>
                <TableCell sx={styles.headerRow}>{inputFieldString}</TableCell>
                {shouldShowSource && (
                  <TableCell sx={styles.headerRow}>Source</TableCell>
                )}
                <TableCell sx={styles.headerRow}>Customer</TableCell>
                <TableCell sx={styles.headerRow}>
                  <TableSortLabel
                    active
                    disabled
                    direction={
                      createdAtSortState === SortType.Descending
                        ? 'desc'
                        : 'asc'
                    }
                    hideSortIcon={false}
                    onClick={handleCreatedDateSort}
                  >
                    Received At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersToReview?.map((order) => (
                <ReviewEdiOrdersTableRow
                  key={order.uuid}
                  ediOrder={order}
                  rowIsSelected={selectedOrderUuids.includes(order.uuid)}
                  setRowAsSelected={() => {
                    setSelectedOrderUuids([...selectedOrderUuids, order.uuid]);
                  }}
                  unselectRow={() => {
                    setSelectedOrderUuids(
                      selectedOrderUuids.filter((uuid) => uuid !== order.uuid),
                    );
                  }}
                  hasPendingChanges={orderUuidsWithPendingChanges.includes(
                    order.uuid,
                  )}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ReviewEdiOrdersTable;
