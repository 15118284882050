import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
} from '@mui/material';
import { filter, get, isArray, isEmpty, isNil } from 'lodash';
import React from 'react';
import {
  FieldErrors,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { isNilOrEmptyString } from '../../../../../common/utils/utils';
import { OrderFormValues } from '../forms/types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from './constants';

const PackagesErrorSection = ({
  errors,
}: {
  errors: FieldErrors<OrderFormValues>;
}) => {
  const { control } = useFormContext<OrderFormValues>();
  const packages = useWatch({ control, name: 'packages' });
  const packagesArrayErrors = errors.packages?.message;
  const hasPackageQuantityErrors =
    packages?.some(
      (_, idx) => !isNilOrEmptyString(get(errors, `packages.${idx}.quantity`)),
    ) ?? false;
  const errorsList = filter(
    [
      !isNilOrEmptyString(packagesArrayErrors) && (
        <Typography>{packagesArrayErrors}</Typography>
      ),
      hasPackageQuantityErrors && (
        <Typography>A package is missing a quantity</Typography>
      ),
      !isNilOrEmptyString(errors.packages?.root?.message) && (
        <Typography>{errors.packages?.root?.message}</Typography>
      ),
    ],
    (error) => Boolean(error) && !isNilOrEmptyString(error),
  );
  if (isEmpty(errorsList)) {
    return null;
  }
  return (
    <List>
      <Typography sx={{ fontWeight: 'bold' }}>Packages</Typography>
      {errorsList}
    </List>
  );
};

const ChargeErrorSection = ({
  errors,
}: {
  errors: FieldErrors<OrderFormValues>;
}) => {
  let inboundStopChargesErrors =
    errors.stops?.[INBOUND_STOP_IDX]?.customCharges;
  let outboundStopChargesErrors =
    errors.stops?.[OUTBOUND_STOP_IDX]?.customCharges;

  if (!isNil(inboundStopChargesErrors) && !isArray(inboundStopChargesErrors)) {
    inboundStopChargesErrors = [inboundStopChargesErrors];
  }
  if (
    !isNil(outboundStopChargesErrors) &&
    !isArray(outboundStopChargesErrors)
  ) {
    outboundStopChargesErrors = [outboundStopChargesErrors];
  }
  const errorsList = filter(
    [
      ...(inboundStopChargesErrors?.map?.((e) => (
        <Typography key={e?.message}>{e?.message}</Typography>
      )) ?? []),
      ...(outboundStopChargesErrors?.map?.((e) => (
        <Typography key={e?.message}>{e?.message}</Typography>
      )) ?? []),
    ],
    (error) => Boolean(error) && !isNilOrEmptyString(error),
  );
  if (isEmpty(errorsList)) {
    return null;
  }
  return (
    <List>
      <Typography sx={{ fontWeight: 'bold' }}>Charges</Typography>
      {errorsList}
    </List>
  );
};

const StopErrorSection = ({
  idx,
  label,
  errors,
}: {
  idx: number;
  label: string;
  errors: FieldErrors<OrderFormValues>;
}) => {
  const addressError = get(errors, `stops.${idx}.address`);
  const recoveryLocationError = get(errors, `stops.${idx}.airportInfoUuid`);
  const serviceDateError = get(errors, `stops.${idx}.serviceDate`);
  const terminalError = get(errors, `stops.${idx}.terminalUuid`);
  const errorsList = filter(
    [
      !isNil(recoveryLocationError) && (
        <Typography>Missing recovery location</Typography>
      ),
      !isNil(terminalError) && <Typography>Missing terminal</Typography>,
      !isNil(addressError) && isNil(recoveryLocationError) && (
        <Typography>Missing address</Typography>
      ),
      !isNil(serviceDateError) && (
        <Typography>{serviceDateError.message}</Typography>
      ),
    ],
    (error) => Boolean(error) && !isNilOrEmptyString(error),
  );
  if (isEmpty(errorsList)) {
    return null;
  }
  return (
    <List>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      {errorsList}
    </List>
  );
};

type ErrorDialogProps = {
  handleClose: () => void;
  open: boolean;
  type: 'order' | 'quote';
  additionalErrors?: string[];
};

const ErrorDialog = ({
  handleClose,
  open,
  type,
  additionalErrors,
}: ErrorDialogProps) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const hawbError = errors.shipperBillOfLadingNumber;
  const serviceError = get(errors, `serviceUuid`);
  const lineHaulLaneError = get(errors, `lineHaulLaneUuid`);
  const fulfillmentTypeError = errors.fulfillmentType;
  const emergencyResponseNumberError = errors.emergencyResponseNumber;
  const repeatEveryError = get(
    errors,
    'recurringOrderFrequency.repeatIntervalWeeks',
  );
  const mismatchedDriverError = get(errors, 'stops.0.driverUuid');
  const orderChargesError = get(errors, 'orderChargesShipment.customCharges');
  let orderChargesErrorMessage: string;
  if (typeof orderChargesError?.message === 'string') {
    orderChargesErrorMessage = orderChargesError.message;
  } else {
    orderChargesErrorMessage =
      'Unexpected error in Order Charges; please try again and contact Pallet support if the issue persists.';
    console.error(
      `Unexpected error in Order Charges: ${JSON.stringify(orderChargesError)}`,
    );
  }

  const errorsList = (
    <List>
      {(additionalErrors ?? []).map((err) => (
        // eslint-disable-next-line react/jsx-key
        <Typography>{err}</Typography>
      ))}
      {!isNil(repeatEveryError) && (
        <Typography>Missing weekly repeat interval</Typography>
      )}
      {!isNil(hawbError) && <Typography>Missing HAWB</Typography>}
      {!isNil(serviceError) && <Typography>Missing service level</Typography>}
      {!isNil(lineHaulLaneError) && (
        <Typography>Missing line haul lane</Typography>
      )}
      {!isNil(fulfillmentTypeError) && (
        <Typography>Missing fulfillment type</Typography>
      )}
      <StopErrorSection idx={0} label="Inbound Stop" errors={errors} />
      <StopErrorSection idx={1} label="Outbound Stop" errors={errors} />
      {!isNil(emergencyResponseNumberError) && (
        <Typography>Invalid emergency response number</Typography>
      )}
      {!isNil(orderChargesError) && (
        <Typography>{orderChargesErrorMessage}</Typography>
      )}
      <PackagesErrorSection errors={errors} />
      <ChargeErrorSection errors={errors} />
      {!isNil(mismatchedDriverError) && (
        <Typography>
          Drivers must match when stops are fulfilled on the same route
        </Typography>
      )}
    </List>
  );
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ErrorIcon color="error" sx={{ mr: 1 }} />
          Errors in saving this {type === 'order' ? 'order' : 'quote'}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {errorsList}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ErrorDialog);
