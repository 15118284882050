import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Settings as SettingsIcon,
  Speed as SpeedIcon,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { useCsvOrderMappingQuery } from '../../../../generated/graphql';
import useCsvImportConfigurationForm, {
  DEFAULT_CSV_IMPORT_CONFIGURATION_FORM_DATA,
} from '../form/use-csv-import-configuration-form';
import TestMapping from './test-mapping';
import ViewAndUpdateFieldMappings from './view-and-update-field-mappings';

const ConfigureCsvOrderMapping = ({
  isEditMode,
  selectedMappingUuid,
  locallyCreatedMapping,
  companyUuid,
  handleSuccessfulCreate,
}: {
  isEditMode: boolean;
  selectedMappingUuid: string;
  locallyCreatedMapping: { name: string; uuid: string } | null;
  companyUuid: string;
  handleSuccessfulCreate: (uuid: string) => void;
}) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const { form, repopulateForm, handleSaveForm } =
    useCsvImportConfigurationForm({
      isEditMode,
      companyUuid,
      onSuccessfulCreate: handleSuccessfulCreate,
    });
  const {
    reset: resetForm,
    control,
    formState: { isLoading: isLoadingForm },
  } = form;

  const { data, loading } = useCsvOrderMappingQuery({
    variables: {
      input: {
        uuid: selectedMappingUuid,
      },
    },
  });

  // TODO: BKO-1254 - move this out of the component
  // so it occurs where the changing of the selected mapping uuid occurs
  // instead of relying on the useEffect hook
  useEffect(() => {
    if (!isNil(data) && isEditMode) {
      repopulateForm(data.csvOrderMapping);
    } else if (!isNil(locallyCreatedMapping) && !isEditMode) {
      resetForm({
        ...DEFAULT_CSV_IMPORT_CONFIGURATION_FORM_DATA,
        name: locallyCreatedMapping?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isEditMode, locallyCreatedMapping]);

  // blank out the form when the selected mapping uuid changes so the old form data
  // isn't present while the new data is loading
  useEffect(() => {
    resetForm(DEFAULT_CSV_IMPORT_CONFIGURATION_FORM_DATA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMappingUuid]);

  const [currentTab, setCurrentTab] = useState(0);

  const theme = useTheme();
  useEffect(() => {
    setCurrentTab(0);
  }, [selectedMappingUuid]);

  const mappingName = useWatch({ control, name: 'name' });

  if (loading || isLoadingForm) {
    return (
      <Stack
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: theme.palette.concreteGrey[10],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  const selectedMapping = data?.csvOrderMapping;

  if (isNil(selectedMapping) && isEditMode) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <Stack
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: theme.palette.concreteGrey[10],
        }}
      >
        {/* Header */}

        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
            backgroundColor: 'white',
          }}
          direction="row"
        >
          {isEditingName ? (
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: '1.5rem', fontWeight: 500 },
                  }}
                  autoFocus
                  onBlur={() => {
                    field.onBlur();
                    setIsEditingName(false);
                  }}
                />
              )}
            />
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h5" fontWeight="500">
                {mappingName}
              </Typography>
              <IconButton size="small" onClick={() => setIsEditingName(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleSaveForm}
            >
              Save
            </Button>
            <IconButton color="error">
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          sx={{ backgroundColor: 'white' }}
        >
          <Tab
            icon={<SettingsIcon sx={{ fontSize: 20 }} />}
            iconPosition="start"
            label="Configure"
          />
          <Tab
            icon={<SpeedIcon sx={{ fontSize: 20 }} />}
            iconPosition="start"
            label="Test"
          />
        </Tabs>
        <Divider />
        <Stack sx={{ overflowY: 'scroll', flex: 1 }}>
          {currentTab === 0 ? <ViewAndUpdateFieldMappings /> : <TestMapping />}
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default ConfigureCsvOrderMapping;
