import { useMemo } from 'react';
import { buildUserPermissionsList } from 'shared/roles';
import { useUserRolesQuery } from '../../generated/graphql';
import { useUserPermissionsContext } from '../contexts/user-permissions';
import type { UserPermissionWithAccessLevel } from '../utils/roles';

type UserRoles = {
  userPermissions: UserPermissionWithAccessLevel[];
  loading: boolean;
  refetchUserRoles: () => void;
};

// If a UserPermissionsContext is available, returns the context's value. Otherwise fetches the user's roles
// and builds the permissions.
const useUserRoles = (): UserRoles => {
  const {
    data: userRolesData,
    loading,
    refetch: refetchUserRoles,
  } = useUserRolesQuery({
    fetchPolicy: 'cache-first',
  });

  const userPermissionsContext = useUserPermissionsContext();

  const userPermissions = useMemo(
    () =>
      userPermissionsContext ??
      buildUserPermissionsList(userRolesData?.userRoles ?? []),
    [userPermissionsContext, userRolesData],
  );

  return {
    userPermissions,
    loading,
    refetchUserRoles,
  };
};

export default useUserRoles;
