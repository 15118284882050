import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { isEmpty } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import CenteredCircularProgress from '../../../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useOrderTableFieldHeaders from '../../../../../common/react-hooks/orders/use-order-table-field-headers';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import { OrderTableField } from '../../../../../generated/graphql';
import AvailableFieldRow from '../../../../orders/components/standard/components/available-field-row';
import CurrentFieldRow from '../../../../orders/components/standard/components/current-field-row';
import {
  deprecatedOrderFields,
  terminalOnlyOrderFields,
} from '../../../../orders/components/utils';

const ALL_ORDER_TABLE_FIELDS = Object.values(OrderTableField);

type ConfigureOrderTableHeadersProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  initialFields: OrderTableField[];
  onSaveOrderTableColumns: (fields: OrderTableField[]) => Promise<void>;
};

const ConfigureOrderTableHeaders = ({
  open,
  setOpen,
  initialFields,
  onSaveOrderTableColumns,
}: ConfigureOrderTableHeadersProps) => {
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );
  const ffHidePaperworkCompleteColumn = useFeatureFlag(
    FeatureFlag.FF_HIDE_PAPERWORK_COMPLETE_COLUMN,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const { orderTableFieldHeaders, orderTableFieldHeadersLoading } =
    useOrderTableFieldHeaders();

  const filterEligibleOrderTableFields = useCallback(
    (field: OrderTableField) => {
      return (
        (terminalsEnabled || !terminalOnlyOrderFields.includes(field)) &&
        !deprecatedOrderFields.includes(field)
      );
    },
    [terminalsEnabled],
  );

  const [currentlyShowingFields, setCurrentlyShowingFields] = useState(() =>
    initialFields.filter(filterEligibleOrderTableFields),
  );

  useEffect(() => {
    setCurrentlyShowingFields(
      initialFields.filter(filterEligibleOrderTableFields),
    );
  }, [initialFields, filterEligibleOrderTableFields]);

  const [availableFieldsToShow, setAvailableFieldsToShow] = useState(() =>
    ALL_ORDER_TABLE_FIELDS.filter(
      (orderTableField) =>
        !initialFields.includes(orderTableField) &&
        filterEligibleOrderTableFields(orderTableField),
    ),
  );

  const disableSave = isEmpty(currentlyShowingFields);

  const onSave = () => {
    if (disableSave) {
      return;
    }
    onSaveOrderTableColumns(currentlyShowingFields);
    setOpen(false);
  };

  const configureOrderColumnsBody =
    orderTableFieldHeadersLoading === true ? (
      <CenteredCircularProgress />
    ) : (
      <Stack direction="row" spacing={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Available fields</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {availableFieldsToShow
                  .sort((a, b) => a.localeCompare(b))
                  .map((field) => {
                    if (
                      ffHidePaperworkCompleteColumn &&
                      field === OrderTableField.PaperworkComplete
                    ) {
                      return null;
                    }
                    if (
                      !ffInboundOutboundPaperworkColumns &&
                      (field === OrderTableField.InboundPaperwork ||
                        field === OrderTableField.OutboundPaperwork)
                    ) {
                      return null;
                    }
                    return (
                      <AvailableFieldRow
                        key={field}
                        field={field}
                        orderTableFieldHeaders={orderTableFieldHeaders}
                        setShowCurrentlyShowingFields={
                          setCurrentlyShowingFields
                        }
                        setShowAvailableFieldsToShow={setAvailableFieldsToShow}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Displayed fields</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {currentlyShowingFields.map((field, idx) => {
                  if (
                    ffHidePaperworkCompleteColumn &&
                    field === OrderTableField.PaperworkComplete
                  ) {
                    return null;
                  }
                  if (
                    !ffInboundOutboundPaperworkColumns &&
                    (field === OrderTableField.InboundPaperwork ||
                      field === OrderTableField.OutboundPaperwork)
                  ) {
                    return null;
                  }
                  return (
                    <CurrentFieldRow
                      key={field}
                      field={field}
                      orderTableFieldHeaders={orderTableFieldHeaders}
                      setShowCurrentlyShowingFields={setCurrentlyShowingFields}
                      setShowAvailableFieldsToShow={setAvailableFieldsToShow}
                      idx={idx}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    );

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <Stack justifyContent="center" p={2} alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
          <DialogTitle>Edit Table Fields</DialogTitle>
          <Tooltip title={disableSave && 'No fields selected'}>
            {/* span required so the tooltip has an anchor when the button is disabled */}
            <span>
              <Button
                variant="contained"
                disabled={orderTableFieldHeadersLoading || disableSave}
                onClick={onSave}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </Stack>
        {configureOrderColumnsBody}
      </Stack>
    </Dialog>
  );
};

export default ConfigureOrderTableHeaders;
