import { ExpandMore } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { isNil, values } from 'lodash';
import { useEffect, useState } from 'react';
import { titleCase } from '../../../domains/daily-control-center/utils';
import { TariffGroupType } from '../../../generated/graphql';
import useStyles from '../general-styles';

const CACHE_PREFIX = 'TARIFF_GROUP_TYPE_FILTER';

interface TariffGroupTypeFilterButtonProps {
  cacheId?: string;
  selectedOption: TariffGroupType | null | undefined;
  handleChange: (option: TariffGroupType | null | undefined) => void;
  isSmall?: boolean;
}

export const DemoTariffGroupTypeFilterButton = ({
  cacheId,
  selectedOption,
  handleChange,
  isSmall = false,
}: TariffGroupTypeFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedValue)) {
        handleChange(cachedValue as TariffGroupType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (option: TariffGroupType | null | undefined) => {
    if (!isNil(cacheId)) {
      if (!isNil(option)) {
        localStorage.setItem(`${CACHE_PREFIX}_VALUE_${cacheId}`, option);
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      }
    }
    handleChange(option);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        variant="outlined"
        sx={[isSmall ? styles.filterButtonSmall : styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterValue}>Tariff Type:</Typography>
          <Typography sx={styles.filterValue}>
            {!isNil(selectedOption) ? titleCase(selectedOption) : 'All'}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="tariff-group-type-menu"
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuItem
          key="all"
          onClick={() => {
            onChange(undefined);
            handleClose();
          }}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            pl: '10px',
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography sx={styles.menuText}>All</Typography>
          </Stack>
        </MenuItem>
        {values(TariffGroupType).map((tariffGroupType) => {
          return (
            <MenuItem
              key={tariffGroupType}
              value={tariffGroupType}
              onClick={() => {
                onChange(tariffGroupType);
                handleClose();
              }}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Typography sx={styles.menuText}>
                {tariffGroupType === TariffGroupType.LineHaul
                  ? 'LTL'
                  : titleCase(tariffGroupType)}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
