/* eslint-disable no-case-declarations */
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '@mui/material';
import type { ColDef, ICellRendererParams, IRowNode } from 'ag-grid-community';
import dayjs, { Dayjs } from 'dayjs';
import { clamp, isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import { safeDivide } from 'shared/math';
import { exhaustive } from 'shared/switch';
import { COLORS } from '../../common/constants';
import {
  chooseForegroundColor,
  lightenHexColor,
} from '../../common/utils/colors';
import { formatAppointmentRange } from '../../common/utils/prettyPrintUtils';
import { getDriverName, getStringOrEmpty } from '../../common/utils/utils';
import {
  DispatchTableColorField,
  DispatchTableColorFragment,
  FulfillmentType,
  StopType,
  RecurringRunHeaderFragment,
  RouteFragment,
  StopDetailsStatus,
  StopFragment,
  StopOnRouteBySearchTextFragment,
  StopOnRouteFragment,
  StopStatus,
} from '../../generated/graphql';
// eslint-disable-next-line import/no-cycle
import StopDetailsStatusIcon from './routes/components/stop-details-status-icon';
import { MAX_CARD_WIDTH, MIN_CARD_WIDTH } from './types/dimensions';
import { RouteSortType, statusOrder } from './types/routes';
import type { StopsTableElement } from './types/stops';

export type RowNodeWithRequiredData<TData> = Omit<IRowNode, 'data'> & {
  data: TData;
};

export const isStopFragment = (
  element: StopsTableElement | undefined,
): element is StopFragment => element?.__typename === 'CachedStopEntity';

export const isStopFragmentNode = (
  node: IRowNode<StopsTableElement> | undefined,
): node is RowNodeWithRequiredData<StopFragment> => isStopFragment(node?.data);

export const isRecurringRunHeaderFragment = (
  element: StopsTableElement | undefined,
): element is RecurringRunHeaderFragment =>
  element?.__typename === 'RecurringRunHeaderEntity';

export const isRecurringRunHeaderFragmentNode = (
  node: IRowNode<StopsTableElement> | undefined,
): node is RowNodeWithRequiredData<RecurringRunHeaderFragment> =>
  isRecurringRunHeaderFragment(node?.data);

/** Returns all the StopFragments and all the RecurringRunHeaderFragment found in nodes. */
export const getStopsAndRecurringRunHeadersFromNodes = (
  nodes: IRowNode<StopsTableElement>[],
): {
  stops: StopFragment[];
  recurringRunHeaders: RecurringRunHeaderFragment[];
} => {
  const stops: StopFragment[] = [];
  const recurringRunHeaders: RecurringRunHeaderFragment[] = [];

  for (const { data } of nodes) {
    if (isStopFragment(data)) {
      stops.push(data);
    } else if (isRecurringRunHeaderFragment(data)) {
      recurringRunHeaders.push(data);
    }
  }

  return { stops, recurringRunHeaders };
};

export const getDriverIdentifier = (
  driver:
    | {
        driverReferenceNumber?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
      }
    | null
    | undefined,
  useDriverReferenceNumber: boolean | null | undefined,
  includeName = true,
) => {
  const driverName = `${driver?.firstName ?? ''} ${driver?.lastName ?? ''}`;
  return useDriverReferenceNumber === true &&
    !isEmpty(driver?.driverReferenceNumber)
    ? `${driver?.driverReferenceNumber}${includeName ? `, ${driverName}` : ''}`
    : driverName;
};

export const convertPercentageToCardWidth = (percentage: number) => {
  // Convert percentage to an integer ratio (e.g., 50% becomes 50)
  const ratio = Math.floor(
    (percentage * (MAX_CARD_WIDTH - MIN_CARD_WIDTH)) / 100,
  );
  return clamp(ratio + MIN_CARD_WIDTH, MIN_CARD_WIDTH, MAX_CARD_WIDTH);
};

export const convertCardWidthToPercentage = (cardWidth: number) => {
  // Calculate the percentage based on the provided card width
  const percentage =
    ((cardWidth - MIN_CARD_WIDTH) / (MAX_CARD_WIDTH - MIN_CARD_WIDTH)) * 100;

  // Ensure the percentage is within the valid range (0% to 100%)
  return clamp(percentage, 0, 100);
};

// Returns the color to display using the position of elements in dispatchTableColors as the precedence.
export const getDispatchTableColorForStop = ({
  isSpecial,
  serviceUuid,
  deadlineDate,
  appointmentDate,
  appointmentStartTime,
  appointmentEndTime,
  dispatchTableColors,
}: {
  isSpecial: boolean;
  serviceUuid: string | null | undefined;
  deadlineDate: string | null | undefined;
  appointmentDate: string | null | undefined;
  appointmentStartTime: string | null | undefined;
  appointmentEndTime: string | null | undefined;
  dispatchTableColors: DispatchTableColorFragment[];
}): string | undefined => {
  const deadlineDay = dayjs(deadlineDate);
  const isDeadlineToday =
    deadlineDay.isAfter(dayjs().startOf('day')) &&
    deadlineDay.isBefore(dayjs().endOf('day'));
  const isDeadlineTomorrow =
    deadlineDay.isAfter(dayjs().add(1, 'day').startOf('day')) &&
    deadlineDay.isBefore(dayjs().add(1, 'day').endOf('day'));
  const isDeadlineOverdue = deadlineDay.isBefore(dayjs().startOf('day'));
  const appointmentStartDateTime = !isNil(appointmentDate)
    ? dayjs(appointmentDate)
        .set(
          'hour',
          isNil(appointmentStartTime) ? 0 : dayjs(appointmentStartTime).hour(),
        )
        .set(
          'minute',
          isNil(appointmentStartTime)
            ? 0
            : dayjs(appointmentStartTime).minute(),
        )
    : undefined;
  const appointmentEndDateTime = !isNil(appointmentDate)
    ? dayjs(appointmentDate)
        .set(
          'hour',
          isNil(appointmentEndTime) ? 23 : dayjs(appointmentEndTime).hour(),
        )
        .set(
          'minute',
          isNil(appointmentEndTime) ? 59 : dayjs(appointmentEndTime).minute(),
        )
    : undefined;
  const appointmentStartWithinMinutes = !isNil(appointmentStartDateTime)
    ? appointmentStartDateTime.diff(dayjs(), 'minute')
    : undefined;
  const appointmentEndWithinMinutes = !isNil(appointmentEndDateTime)
    ? appointmentEndDateTime.diff(dayjs(), 'minute')
    : undefined;

  return dispatchTableColors.find((dispatchTableColor) => {
    if (!dispatchTableColor.active) {
      return false;
    }
    for (const field of dispatchTableColor.fields) {
      switch (field) {
        case DispatchTableColorField.AppointmentPassed:
          if (
            isNil(appointmentEndDateTime) ||
            appointmentEndDateTime.isAfter(dayjs())
          ) {
            return false;
          }
          break;
        case DispatchTableColorField.AppointmentStartWithin:
          if (
            isNil(appointmentStartWithinMinutes) ||
            isNil(dispatchTableColor.appointmentStartWithinMinutes) ||
            appointmentStartWithinMinutes < 0 ||
            appointmentStartWithinMinutes >
              dispatchTableColor.appointmentStartWithinMinutes
          ) {
            return false;
          }
          break;
        case DispatchTableColorField.AppointmentEndWithin:
          if (
            isNil(appointmentEndWithinMinutes) ||
            isNil(dispatchTableColor.appointmentEndWithinMinutes) ||
            appointmentEndWithinMinutes < 0 ||
            appointmentEndWithinMinutes >
              dispatchTableColor.appointmentEndWithinMinutes
          ) {
            return false;
          }
          break;
        case DispatchTableColorField.DeadlinePassed:
          if (!isDeadlineOverdue) {
            return false;
          }
          break;
        case DispatchTableColorField.DeadlineToday:
          if (!isDeadlineToday) {
            return false;
          }
          break;
        case DispatchTableColorField.DeadlineTomorrow:
          if (!isDeadlineTomorrow) {
            return false;
          }
          break;
        case DispatchTableColorField.HasAppointmentDate:
          if (dispatchTableColor.hasAppointmentDate === true) {
            if (isNil(appointmentDate)) {
              return false;
            }
          } else if (!isNil(appointmentDate)) {
            return false;
          }
          break;
        case DispatchTableColorField.IsSpecial:
          if (dispatchTableColor.isSpecial === false) {
            if (isSpecial) {
              return false;
            }
          } else if (!isSpecial) {
            return false;
          }
          break;
        case DispatchTableColorField.Service:
          if (
            isNil(serviceUuid) ||
            isNil(dispatchTableColor.service) ||
            dispatchTableColor.service.uuid !== serviceUuid
          ) {
            return false;
          }
          break;
        default:
          return false;
      }
    }
    return true;
  })?.color;
};

export const searchRoutes = (
  routes: RouteFragment[] | undefined,
  text: string,
) => {
  const searchText = text.toLowerCase().trim();
  return routes?.filter((route: RouteFragment) => {
    const orderInRoute = route.slots.some((slot) => {
      const stop = slot?.stops[0];
      const order = stop?.shipment?.order;
      return (
        order?.name.toLowerCase().includes(searchText) === true ||
        order?.standardOrderFields.shipperBillOfLadingNumber
          ?.toLowerCase()
          .includes(searchText) === true ||
        order?.standardOrderFields.masterAirwayBillOfLadingNumber
          ?.toLowerCase()
          .includes(searchText) === true ||
        stop?.address.city?.toLowerCase().includes(searchText) === true
      );
    });
    const driverName = `${route.drivers[0]?.firstName ?? ''} ${
      route.drivers[0]?.lastName ?? ''
    }`;
    const vehicleName = route.equipments[0]?.name;
    return (
      route.name?.toLowerCase().includes(searchText) === true ||
      driverName.toLowerCase().includes(searchText) ||
      vehicleName?.toLowerCase().includes(searchText) === true ||
      orderInRoute
    );
  });
};

export const sortRoutes = (
  routes: RouteFragment[] | undefined,
  sortType: RouteSortType,
  sortAsc: boolean,
) => {
  return routes?.slice().sort((a, b) => {
    switch (sortType) {
      case RouteSortType.CREATED_TIME:
        if (sortAsc) {
          return a.createdAt.localeCompare(b.createdAt);
        }
        return b.createdAt.localeCompare(a.createdAt);
      case RouteSortType.NAME:
        if (sortAsc) {
          return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
      case RouteSortType.DRIVER:
        const aDriver = a.drivers[0];
        const bDriver = b.drivers[0];
        const aName = getDriverName(aDriver);
        const bName = getDriverName(bDriver);
        let comparison: number | undefined;
        if (
          !isNil(aDriver?.driverReferenceNumber) &&
          !isNil(bDriver?.driverReferenceNumber)
        ) {
          comparison = aDriver?.driverReferenceNumber?.localeCompare(
            bDriver?.driverReferenceNumber ?? '',
          );
        } else {
          comparison = aName.localeCompare(bName);
        }
        return sortAsc ? comparison : -1 * (comparison ?? 0);
      case RouteSortType.STATUS:
        if (sortAsc) {
          return (
            statusOrder[a.routeInfo.status] - statusOrder[b.routeInfo.status]
          );
        }
        return (
          statusOrder[b.routeInfo.status] - statusOrder[a.routeInfo.status]
        );
      case RouteSortType.NUMBER_STOPS_COMPLETE:
        if (sortAsc) {
          return (
            a.routeInfo.totalStopsCompleted - b.routeInfo.totalStopsCompleted
          );
        }
        return (
          b.routeInfo.totalStopsCompleted - a.routeInfo.totalStopsCompleted
        );
      case RouteSortType.NUMBER_STOPS_INCOMPLETE:
        const aStopsIncomplete =
          a.routeInfo.totalStopsNotArrived + a.routeInfo.totalStopsArrived;
        const bStopsIncomplete =
          b.routeInfo.totalStopsNotArrived + b.routeInfo.totalStopsArrived;
        if (aStopsIncomplete === bStopsIncomplete) {
          return b.routeInfo.totalStops - a.routeInfo.totalStops;
        }
        if (sortAsc) {
          return aStopsIncomplete - bStopsIncomplete;
        }
        return bStopsIncomplete - aStopsIncomplete;
      default:
        return 0;
    }
  });
};

export const reorderRoute = (
  route: RouteFragment,
  startIndex: number,
  endIndex: number,
  swap?: boolean,
): RouteFragment => {
  const newSlots = route.slots.map((slot) => ({ ...slot }));

  if (swap === true) {
    const start = newSlots[startIndex];
    const end = newSlots[endIndex];
    if (!isNil(start) && !isNil(end)) {
      newSlots[startIndex] = end;
      newSlots[endIndex] = start;
    }
  } else {
    const [removed] = newSlots.splice(startIndex, 1);
    if (!isNil(removed)) {
      newSlots.splice(endIndex, 0, removed);
    }
  }

  const newRoute = { ...route };
  newRoute.slots = newSlots;
  return newRoute;
};

export const reverseRoute = (route: RouteFragment): RouteFragment => {
  const newSlots = Array.from(route.slots);
  const newRoute = { ...route };
  newRoute.slots = newSlots.reverse();
  return newRoute;
};

// Covers the Bay Area from SF to San Jose when no bounds can be calculated
const DEFAULT_BOUNDS = {
  bottomRightX: -121.8728955,
  bottomRightY: 37.77689155,
  topLeftX: -122.469451343932,
  topLeftY: 37.2707095,
};

// Takes the coordinates of the farthest marker to the left, to the right, to the top, and to the bottom,
// And returns an object representing the bounds for the map.
export const calculateBoundsFromSlotsToShowAllMarkers = (
  stops: (StopOnRouteFragment | undefined)[],
) => {
  let isModified = false;
  let topLeftX = Number.POSITIVE_INFINITY;
  let bottomRightX = Number.NEGATIVE_INFINITY;
  let topLeftY = Number.POSITIVE_INFINITY;
  let bottomRightY = Number.NEGATIVE_INFINITY;
  stops
    .filter(
      (stop) =>
        !isNil(stop?.address.longitude) && !isNil(stop?.address.latitude),
    )
    .forEach((stop) => {
      topLeftX = Math.min(topLeftX, stop?.address?.longitude ?? 0);
      bottomRightX = Math.max(bottomRightX, stop?.address?.longitude ?? 0);
      topLeftY = Math.min(topLeftY, stop?.address?.latitude ?? 0);
      bottomRightY = Math.max(bottomRightY, stop?.address?.latitude ?? 0);
      isModified = true;
    });
  if (isModified) {
    return { topLeftX, topLeftY, bottomRightX, bottomRightY };
  }
  return DEFAULT_BOUNDS;
};

export const getRouteTotalTimeString = (
  totalTimeInSeconds: number | undefined | null,
) => {
  const totalTimeInMinutes = safeDivide(totalTimeInSeconds ?? 0, 60);
  const totalTimeInHours = safeDivide(totalTimeInMinutes, 60);
  const minutes = Math.floor(totalTimeInMinutes % 60);
  return `${Math.floor(totalTimeInHours)}:${minutes < 10 ? 0 : ''}${minutes}`;
};

export const getEtaOrCompletedTimeString = (
  stop: StopOnRouteFragment | StopOnRouteBySearchTextFragment,
) => {
  let etaOrCompletedTimeString = 'No ETA';
  if (!isNil(stop.completedAt)) {
    etaOrCompletedTimeString = `${dayjs(stop.completedAt).format('HH:mm')}`;
  } else if (
    (stop.status === StopStatus.NotArrived ||
      stop.status === StopStatus.Arrived) &&
    !isNil(stop.cachedEta?.arrivalTime)
  ) {
    etaOrCompletedTimeString = `${dayjs(stop.cachedEta?.arrivalTime).format(
      'HH:mm',
    )}`;
  }

  return etaOrCompletedTimeString;
};

/**
 * Gets indices of same-day stops where the inbound is after the linked outbound
 * @param route
 */
export const getLinkedStopIndicesOutOfOrder = (route: RouteFragment) => {
  const courierOutboundUuids: { [uuid: string]: number } = {};
  const pairs: [number, number][] = [];
  for (let i = 0; i < route.slots.length; i += 1) {
    const stop = route.slots[i]?.stops[0];
    const order = stop?.shipment?.order;
    if (!isNil(order) && order.fulfillmentType === FulfillmentType.Dedicated) {
      if (
        stop?.stopType === StopType.Delivery ||
        stop?.stopType === StopType.Transfer
      ) {
        courierOutboundUuids[order.uuid] = i;
      } else if (order.uuid in courierOutboundUuids) {
        pairs.push([courierOutboundUuids[order.uuid]! + 1, i + 1]);
      }
    }
  }
  return pairs;
};

export const getStopsWithoutAddressesWarningText = (indices: number[]) => {
  return `${pluralize('Address', indices.length)} not found for ${pluralize('stop', indices.length)} ${indices.map((i) => i + 1).join(', ')}`;
};

const MANIFEST_CSV_HEADERS = [
  'Route Name',
  'Manifest ID',
  'Manifest Date',
  'Drivers',
  'Helpers',
  'Name',
  'Line 1',
  'City',
  'State',
  'Zipcode',
  'HAWB',
  'MAWB',
  'Shipment Type',
  'Pieces',
  'Weight',
  'Description',
  'Service Name',
  'Appointment Time',
  'Customer',
  'Special Instructions',
];

const OVERVIEW_CSV_HEADERS = [
  'Route',
  'Driver',
  'Helper(s)',
  'Vehicle',
  'Start Time',
  'Hours',
  'Preload',
  'Notes',
];

export const convertRoutesToManifestCsv = (
  routes: RouteFragment[],
  id: number,
) => {
  const rows: (string | number | undefined)[][] = [];
  routes.forEach((route) => {
    route.slots?.forEach((slot) => {
      const stop = slot.stops[0];
      const order = stop?.shipment?.order;
      const helpers = route.helpers.map((helper) =>
        getDriverName(helper).replace(',', ''),
      );
      const drivers = route.drivers.map((driver) =>
        getDriverName(driver).replace(',', ''),
      );

      rows.push([
        route.name,
        id,
        new Date().toDateString(),
        getStringOrEmpty(drivers.join('; ')),
        getStringOrEmpty(helpers.join('; ')),
        getStringOrEmpty(stop?.address.name),
        getStringOrEmpty(stop?.address.line1),
        getStringOrEmpty(stop?.address.city),
        getStringOrEmpty(stop?.address.state),
        getStringOrEmpty(stop?.address.zip),
        getStringOrEmpty(order?.standardOrderFields.shipperBillOfLadingNumber),
        getStringOrEmpty(
          order?.standardOrderFields.masterAirwayBillOfLadingNumber,
        ),
        getStringOrEmpty(stop?.stopType),
        order?.packages?.reduce(
          (prev, curr) => prev + (curr.quantity ?? 0),
          0,
        ) ?? '',
        order?.packages?.reduce((prev, curr) => prev + (curr.weight ?? 0), 0) ??
          '',
        order?.packages?.map((pkg) => pkg.description).join('; '),
        order?.service?.name ?? 'NA',
        formatAppointmentRange(
          stop?.shipment?.standardShipmentFields?.deadlineDate ?? undefined,
          stop?.appointmentTime,
          stop?.endAppointmentTime,
        ) ?? 'NA',
        getStringOrEmpty(order?.billingPartyContact?.displayName),
        getStringOrEmpty(stop?.specialInstructions),
      ]);
    });
  });
  return [MANIFEST_CSV_HEADERS, ...rows];
};

export const convertRoutesToOverviewCsv = (routes: RouteFragment[]) => {
  const rows: (string | number | undefined)[][] = [];
  routes.forEach((route) => {
    const helpers = route.helpers.map((helper) => getDriverName(helper));
    rows.push([
      route.name,
      getDriverName(route.drivers[0]),
      getStringOrEmpty(helpers.join('; ')),
      getStringOrEmpty(route.equipments[0]?.name),
      getStringOrEmpty(
        !isNil(route.defaultStartTime)
          ? dayjs(route.defaultStartTime).format('HH:mm')
          : '',
      ),
      getStringOrEmpty(getRouteTotalTimeString(route.totalTimeInSeconds)),
      '',
      '',
    ]);
  });
  return [OVERVIEW_CSV_HEADERS, ...rows];
};

export const getStopDetailsStatusIcon = (
  {
    stopStatus,
    stopDetails,
    orderRefused,
  }: {
    stopStatus: StopStatus;
    stopDetails:
      | { stopDetailsStatus: StopDetailsStatus; message?: string | null }
      | null
      | undefined;
    orderRefused: boolean;
  },
  color?: string,
) => {
  if (orderRefused) {
    return (
      <CancelIcon
        color="error"
        sx={{ fontSize: '13px', ml: '2px' }}
        htmlColor={color}
      />
    );
  }
  switch (stopStatus) {
    case StopStatus.NotArrived:
      if (isNil(stopDetails)) return null;
      return (
        <StopDetailsStatusIcon
          status={stopDetails.stopDetailsStatus}
          message={stopDetails.message}
          color={color}
        />
      );
    case StopStatus.Arrived:
      return (
        <CheckIcon
          color={isNil(color) ? 'info' : undefined}
          htmlColor={color}
          sx={{ fontSize: '13px', ml: '2px' }}
        />
      );
    case StopStatus.Cancelled:
    case StopStatus.Failed:
      return (
        <WarningIcon
          color={isNil(color) ? 'warning' : undefined}
          sx={{ fontSize: '13px', ml: '2px' }}
          htmlColor={color}
        />
      );
    case StopStatus.Completed:
      return (
        <CheckCircleIcon
          color={isNil(color) ? 'success' : undefined}
          sx={{ fontSize: '13px', ml: '2px' }}
          htmlColor={color}
        />
      );
    default:
      return exhaustive(stopStatus);
  }
};

export const otherColumns = ({
  dispatchTableColors,
}: {
  dispatchTableColors: DispatchTableColorFragment[];
}): ColDef[] => {
  return [
    {
      field: '',
      pinned: 'left',
      lockPinned: true,
      resizable: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      checkboxSelection: true,
      maxWidth: 40,
    },
    {
      field: 'pord',
      hide: false,
      headerName: '',
      pinned: 'left',
      lockPinned: true,
      autoHeight: true,
      width: 20,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cellStyle: ({ data }: ICellRendererParams<StopFragment>) => {
        if (isNil(data)) {
          return null;
        }
        const { stop, order } = data;
        const color = getDispatchTableColorForStop({
          isSpecial: stop.isSpecial === true,
          serviceUuid: order?.serviceUuid,
          deadlineDate: stop.deadlineDate,
          appointmentDate: stop.deliveryDate,
          appointmentStartTime: stop.appointmentTime,
          appointmentEndTime: stop.endAppointmentTime,
          dispatchTableColors,
        });
        return { background: color, paddingLeft: 5 };
      },
      cellRenderer: ({ data }: ICellRendererParams<StopFragment>) => {
        if (isNil(data)) {
          return null;
        }
        const { stop, order } = data;
        const color = getDispatchTableColorForStop({
          isSpecial: stop.isSpecial === true,
          serviceUuid: order?.serviceUuid,
          deadlineDate: stop.deadlineDate,
          appointmentDate: stop.deliveryDate,
          appointmentStartTime: stop.appointmentTime,
          appointmentEndTime: stop.endAppointmentTime,
          dispatchTableColors: dispatchTableColors ?? [],
        });
        return (
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              color: !isNil(color) ? chooseForegroundColor(color) : 'black',
            }}
          >
            {!isNil(stop?.stopType) ? stop?.stopType[0] : null}
          </Typography>
        );
      },
    },
  ];
};

export const getRouteColor = (
  routes: RouteFragment[],
  routeUuid: string,
  lighten = false,
) => {
  if (lighten) {
    return lightenHexColor(
      COLORS[routes.findIndex((r) => r.uuid === routeUuid) % COLORS.length] ??
        'black',
      0.2,
    );
  }
  return (
    COLORS[routes.findIndex((r) => r.uuid === routeUuid) % COLORS.length] ??
    'black'
  );
};

export const isPointInSquare = (
  corner1Lat: number,
  corner1Long: number,
  corner2Lat: number,
  corner2Long: number,
  point1Lat: number,
  point1Long: number,
) => {
  // Determine the minimum and maximum latitude and longitude to establish the bounds of the square
  const minLat = Math.min(corner1Lat, corner2Lat);
  const maxLat = Math.max(corner1Lat, corner2Lat);
  const minLon = Math.min(corner1Long, corner2Long);
  const maxLong = Math.max(corner1Long, corner2Long);

  // Check if the point falls within the bounds of the square
  return (
    point1Lat >= minLat &&
    point1Lat <= maxLat &&
    point1Long >= minLon &&
    point1Long <= maxLong
  );
};

// If the date is in the current year, return the date in the format "MM/DD". Otherwise, return the date in the format "MM/DD/YY".
export const formattedDateAbbreviated = (date: Dayjs) => {
  return date.year() === dayjs().year()
    ? date.format('MM/DD')
    : date.format('MM/DD/YY');
};

export const getPlanningDate = ({
  planningDate,
  planningDateExpiration,
}: {
  planningDate: string | null;
  planningDateExpiration: string | null;
}): Dayjs => {
  if (isNil(planningDate)) {
    return dayjs();
  }
  if (isNil(planningDateExpiration)) {
    return dayjs(planningDate);
  }
  const expiration = dayjs(planningDateExpiration);
  if (expiration.isAfter(dayjs())) {
    return dayjs(planningDate);
  }
  return dayjs();
};

export const getStopDataFromFragment = (
  stop: StopOnRouteFragment | StopFragment,
) => {
  if (stop.__typename === 'StandardStopEntity') {
    return {
      stopUuid: stop.uuid,
      stopStatus: stop.status,
      stopType: stop.stopType,
      specialInstructions: stop.specialInstructions,
      address: stop.address,
      contactPerson: stop.contactPerson,
      order: stop.shipment?.order,
      serviceUuid: stop.shipment?.order?.service?.uuid,
      serviceName: stop.shipment?.order?.service?.name,
      shipperBillOfLadingNumber:
        stop.shipment?.order?.standardOrderFields.shipperBillOfLadingNumber,
      masterAirwayBillOfLadingNumber:
        stop.shipment?.order?.standardOrderFields
          .masterAirwayBillOfLadingNumber,
    };
  }
  const stopData = stop.stop;
  const { order } = stop;
  return {
    stopUuid: stopData.uuid,
    stopStatus: stopData.status,
    stopType: stopData.stopType,
    specialInstructions: stopData.specialInstructions,
    address: stopData.address,
    order,
    serviceUuid: order?.serviceUuid,
    serviceName: order?.serviceName,
    shipperBillOfLadingNumber: order?.shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber: order?.masterAirwayBillOfLadingNumber,
  };
};
