// eslint-disable-next-line no-restricted-imports
import { Button, Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import useDocuments from '../../../common/react-hooks/use-documents';
import { CustomDriverFormFragment } from '../../../generated/graphql';
import { DocViewerDocument } from '../types/doc-viewer-document';
import GeneratedCustomFormPdf from './generated-custom-form-pdf';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  maxWidth: '100%',
  maxHeight: '100%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CustomFormDocumentViewerModal = ({
  showDocViewerModal,
  setShowDocViewerModal,
  doc,
  driverFormTemplate,
}: {
  showDocViewerModal: boolean;
  setShowDocViewerModal: Dispatch<SetStateAction<boolean>>;
  doc: DocViewerDocument | undefined;
  driverFormTemplate: CustomDriverFormFragment;
}) => {
  const { getDocumentTypeCopy, loading: documentsLoading } = useDocuments();

  if (documentsLoading) {
    return null;
  }

  return (
    <Modal
      keepMounted
      open={showDocViewerModal}
      onClose={() => {
        setShowDocViewerModal(false);
      }}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={[style, { width: '60vw', height: '90vh', top: '50%' }]}>
        {!isNil(doc) ? (
          <Stack spacing={2} direction="column">
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '16px', color: 'gray' }}>
                  {getDocumentTypeCopy({
                    documentType: doc.docType,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button sx={{ float: 'right' }}>
                  <PDFDownloadLink
                    document={
                      <GeneratedCustomFormPdf
                        signatureDocument={doc}
                        driverFormTemplate={driverFormTemplate}
                      />
                    }
                    fileName={
                      driverFormTemplate.title +
                      doc.fileName.substring(0, doc.fileName.length - 4)
                    }
                  >
                    {({ loading }) =>
                      loading === true ? 'Loading...' : 'Download'
                    }
                  </PDFDownloadLink>
                </Button>
              </Grid>
            </Grid>
            <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
              <GeneratedCustomFormPdf
                signatureDocument={doc}
                driverFormTemplate={driverFormTemplate}
              />
            </PDFViewer>
          </Stack>
        ) : (
          'Loading...'
        )}
      </Box>
    </Modal>
  );
};

export default CustomFormDocumentViewerModal;
