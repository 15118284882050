import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { PartialDeep } from 'type-fest';
import { DeepStringified } from '../../../common/types';
import type { RootState } from '../../../redux/store';
import { InvoiceFormField } from './invoice-form-types';

export type InvoiceErrors = DeepStringified<
  PartialDeep<Omit<InvoiceFormField, 'uuid'>> & { uuid: string }
>;

const invoiceErrorsAdapter = createEntityAdapter<InvoiceErrors>({
  selectId: (invoiceErrors) => invoiceErrors.uuid,
});

export const invoiceErrorsSlice = createSlice({
  name: 'invoiceErrors',
  initialState: invoiceErrorsAdapter.getInitialState,
  reducers: {
    addOneInvoiceErrors: invoiceErrorsAdapter.addOne,
    removeAllInvoicerErrors: invoiceErrorsAdapter.removeAll,
    updateOneInvoiceErrors: invoiceErrorsAdapter.updateOne,
    upsertOneInvoiceErrors: invoiceErrorsAdapter.upsertOne,
  },
});

export const { selectById: selectInvoiceErrorsById } =
  invoiceErrorsAdapter.getSelectors(
    (state: RootState) => state.invoiceErrorFormValues,
  );

export const {
  addOneInvoiceErrors,
  updateOneInvoiceErrors,
  upsertOneInvoiceErrors,
} = invoiceErrorsSlice.actions;

const invoiceErrorsReducer = invoiceErrorsSlice.reducer;

export default invoiceErrorsReducer;
