import { Box, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import { BillingMethod } from '../../../../../../../common/types';
import { CustomChargeValues, OrderFormValues } from '../../../forms/types';

const RateField = ({
  customChargePathPrefix,
  disabled,
}: {
  customChargePathPrefix:
    | `stops.${number}.customCharges.${number}`
    | `orderChargesShipment.customCharges.${number}`;
  disabled?: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const settlementPercentageRate: CustomChargeValues['settlementPercentageRate'] =
    useWatch({
      control,
      name: `${customChargePathPrefix}.settlementPercentageRate`,
    });
  const settlementFlatRate: CustomChargeValues['settlementFlatRate'] = useWatch(
    {
      control,
      name: `${customChargePathPrefix}.settlementFlatRate`,
    },
  );
  const billingMethod =
    useWatch({
      control,
      name: `${customChargePathPrefix}.settlementBillingMethod`,
    }) ?? BillingMethod.Percentage;

  const rate = useMemo(() => {
    switch (billingMethod) {
      case BillingMethod.FlatRate:
        return settlementFlatRate;

      case BillingMethod.Percentage:
        return settlementPercentageRate;

      default:
        return exhaustive(billingMethod);
    }
  }, [billingMethod, settlementFlatRate, settlementPercentageRate]);

  const [rateInput, setRateInput] = useState(rate?.toString() ?? '');

  useEffect(() => {
    setRateInput(rate?.toString() ?? '');
  }, [billingMethod, rate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <TextField
        size="small"
        value={rateInput}
        onChange={(e) => {
          setRateInput(e.target.value);
          const parsedFloat = parseFloat(e.target.value);
          if (billingMethod === BillingMethod.FlatRate) {
            if (!Number.isNaN(parsedFloat)) {
              setValue(
                `${customChargePathPrefix}.settlementFlatRate`,
                parsedFloat,
              );
            } else {
              setValue(`${customChargePathPrefix}.settlementFlatRate`, null);
            }
          }
          if (billingMethod === BillingMethod.Percentage) {
            if (!Number.isNaN(parsedFloat)) {
              setValue(
                `${customChargePathPrefix}.settlementPercentageRate`,
                parsedFloat,
              );
            } else {
              setValue(`${customChargePathPrefix}.settlementPercentageRate`, 0);
            }
          }
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export const DriverSettlementCustomChargeRateField = React.memo(RateField);
