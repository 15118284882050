import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';

const CACHE_PREFIX = 'REPORT_GROUPING_OPTION';

export enum ReportGroupingType {
  CONTACT = 'Contact',
  TERMINAL = 'Terminal',
}

interface ReportGroupingSelectionProps {
  cacheId?: string;
  selectedOption?: ReportGroupingType;
  labelText: string;
  handleChange: (reportGroupingType: ReportGroupingType) => void;
}

const ReportGroupingSelection = ({
  cacheId,
  labelText,
  selectedOption = ReportGroupingType.CONTACT,
  handleChange,
}: ReportGroupingSelectionProps) => {
  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedType = localStorage.getItem(`${CACHE_PREFIX}_${cacheId}`);
      if (!isNil(cachedType)) {
        handleChange(cachedType as ReportGroupingType);
      }
    }
  }, []);

  const onChange = (reportGroupingType: ReportGroupingType) => {
    if (!isNil(cacheId)) {
      if (!isNil(reportGroupingType)) {
        localStorage.setItem(
          `${CACHE_PREFIX}_${cacheId}`,
          reportGroupingType.toString(),
        );
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_${cacheId}`);
      }
    }
    handleChange(reportGroupingType);
  };

  return (
    <Box>
      <FormControl>
        <InputLabel id="report-grouping-selection-label">
          {labelText}
        </InputLabel>
        <Select
          labelId="report-grouping-selection-label"
          value={selectedOption}
          label={labelText}
          onChange={(e) => {
            onChange(e.target.value as ReportGroupingType);
          }}
          size="small"
          sx={{ backgroundColor: 'white' }}
        >
          {Object.values(ReportGroupingType).map((groupingType) => (
            <MenuItem key={groupingType} id={groupingType} value={groupingType}>
              {sentenceCase(groupingType)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ReportGroupingSelection;
