import { Error as ErrorIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import {
  EquipmentsDocument,
  EquipmentType,
  MeasurementSystem,
  useCreateEquipmentMutation,
  useDeleteEquipmentMutation,
  useEquipmentLazyQuery,
  useUpdateEquipmentMutation,
  useVehicleTypesMinimalQuery,
} from '../../../generated/graphql';
import EquipmentPageMode from '../enums/equipment-page-mode';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from './common/archive-action-component';
import { COULD_NOT_FIND_IN_SAMSARA_TOOLTIP } from './strings';

const availableEquipmentTypes = Object.values(EquipmentType);

const THIS_FIELD_IS_REQUIRED = 'This field is required';

const styles = {
  flexRowBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    my: 1.5,
  } as CSSProperties,
  notes: { mx: 10, width: '100%' } as SxProps,
  licenses: { mx: 10 } as CSSProperties,
  licenseField: { mx: 2 } as CSSProperties,
  licenseButton: { mx: 2, my: 2 } as CSSProperties,
  addEquipmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    p: 1,
    gap: 1,
  } as SxProps,
  card: {
    width: '100%',
    p: 5,
  } as SxProps,
};

interface EquipmentLicenseData {
  localUuid: string;
  uuid: string;
  number: string;
  licenseType: string;
  licensingBody: string;
  issueDate: Date;
  expiryDate: Date;
}

type FormData = {
  name: string | null;
  type: EquipmentType | null;
  vehicleTypeUuid: string | null;
  capacity: number | null;
  measurementSystem: MeasurementSystem;
  weight: number | null;
  length: number | null;
  width: number | null;
  height: number | null;
  notes: string | null;
  hasSamsaraVehicleId: boolean | null;
  hasSamsaraOAuthToken: boolean | null;
  isActive: boolean | null;
};

type FormErrors = {
  [key in keyof FormData]: string | null;
};

type EquipmentProps = {
  equipmentUuid: string | null;
};

const Equipment: FunctionComponent<EquipmentProps> = ({ equipmentUuid }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Determine if we are in CREATE/EDIT/VIEW mode
  const edit = searchParams.get('edit');
  const hasUuid = !isNil(equipmentUuid);

  // TODO (Aki) This may not be how we want to implement `view` mode in the future
  const pageMode: EquipmentPageMode = (() => {
    if (hasUuid) {
      return edit === 'true' ? EquipmentPageMode.EDIT : EquipmentPageMode.VIEW;
    }
    return EquipmentPageMode.CREATE;
  })();

  const [formData, setFormData] = useState<FormData>({
    name: null,
    type: null,
    vehicleTypeUuid: null,
    capacity: null,
    measurementSystem: MeasurementSystem.Imperial,
    weight: null,
    length: null,
    width: null,
    height: null,
    notes: null,
    hasSamsaraOAuthToken: null,
    hasSamsaraVehicleId: null,
    isActive: null,
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({
    name: null,
    type: null,
    vehicleTypeUuid: null,
    capacity: null,
    measurementSystem: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    notes: null,
    hasSamsaraOAuthToken: null,
    hasSamsaraVehicleId: null,
    isActive: null,
  });

  const [equipmentLicenseData, setEquipmentLicenseData] = useState<
    EquipmentLicenseData[]
  >([]);

  const requiredFields: (keyof FormData)[] = ['name', 'type'];

  const [equipmentQuery] = useEquipmentLazyQuery();
  const { data: vehicleTypesData } = useVehicleTypesMinimalQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (hasUuid) {
      equipmentQuery({
        variables: {
          uuid: equipmentUuid,
        },
      }).then((resp) => {
        if (!isNil(resp) && !isNil(resp.data)) {
          const equipmentData = resp.data.equipment;
          setFormData({
            name: equipmentData.name ?? '',
            type: equipmentData.type ?? null,
            vehicleTypeUuid: equipmentData.vehicleType?.uuid ?? null,
            capacity: equipmentData.capacity ?? null,
            weight: equipmentData.weight ?? null,
            measurementSystem:
              equipmentData.measurementSystem ?? MeasurementSystem.Imperial,
            length: equipmentData.length ?? null,
            width: equipmentData.width ?? null,
            height: equipmentData.height ?? null,
            notes: equipmentData.notes ?? null,
            hasSamsaraOAuthToken: !isNil(resp.data.samsaraOAuthToken),
            hasSamsaraVehicleId: !isNil(equipmentData.samsaraVehicleId),
            isActive: equipmentData.isActive,
          });
          /*
            Assign localUuids to each list item, so they can be edited + saved locally.
            Allows us to identify, then edit/delete licenses that are created locally but do not yet have a database UUID assigned to them.
            Generated here, directly after API call, so that they remain stable between re-renders.
          */
          setEquipmentLicenseData(
            equipmentData.equipmentLicenses.map((equipmentLicense) => {
              return { ...equipmentLicense, localUuid: v4() };
            }),
          );
        }
      });
    } else {
      setEquipmentLicenseData([]);
    }
  }, [equipmentQuery, equipmentUuid, hasUuid]);

  const [createEquipment] = useCreateEquipmentMutation({
    refetchQueries: [EquipmentsDocument],
  });

  const [deleteEquipment] = useDeleteEquipmentMutation({
    refetchQueries: [EquipmentsDocument],
  });

  const [updateEquipment] = useUpdateEquipmentMutation({
    refetchQueries: [EquipmentsDocument],
  });

  const hasFieldError = (): boolean => {
    let hasError = false;

    const newErrors = { ...formErrors };

    // Verify existence of Name, Type
    if (!isNil(formData)) {
      requiredFields.forEach((requiredField) => {
        if (
          isNil(formData[requiredField]) ||
          formData[requiredField]?.toString().length === 0
        ) {
          newErrors[requiredField] = THIS_FIELD_IS_REQUIRED;
          hasError = true;
        } else {
          newErrors[requiredField] = null;
        }
      });
    }

    setFormErrors(newErrors);
    return hasError;
  };

  const generateEquipmentLicenseCreateInputs = () => {
    return equipmentLicenseData.map((equipmentLicenseFormEntry) => {
      return {
        number: equipmentLicenseFormEntry.number,
        licenseType: equipmentLicenseFormEntry.licenseType,
        licensingBody: equipmentLicenseFormEntry.licensingBody,
        issueDate: equipmentLicenseFormEntry.issueDate,
        expiryDate: equipmentLicenseFormEntry.expiryDate,
      };
    });
  };

  /**
   * onClick handler for creating a new Equipment
   */
  const handleCreateEquipment = async () => {
    if (hasFieldError()) {
      return;
    }

    const equipmentLicenseCreateInputs = generateEquipmentLicenseCreateInputs();

    const { name, type, vehicleTypeUuid } = formData;

    // Should never happen since we check required fields above
    if (isNil(name) || isNil(type)) {
      throw new Error('required field was not entered');
    }

    await createEquipment({
      variables: {
        input: {
          equipmentCreateInput: {
            name,
            type,
            vehicleTypeUuid,
            measurementSystem: formData.measurementSystem,
            capacity: formData.capacity,
            weight: formData.weight,
            length: formData.length,
            width: formData.width,
            height: formData.height,
            notes: formData.notes,
            equipmentLicenseCreateInputs,
          },
        },
      },
    });
    navigate(-1);
  };

  const generateEquipmentLicenseArrayUpdateInputs = () => {
    return equipmentLicenseData.map((equipmentLicenseFormEntry) => {
      if (equipmentLicenseFormEntry.uuid.length > 0) {
        // if the form entry comes with a db-generated UUID, we know we must update an existing license
        return {
          equipmentLicenseUpdateInput: {
            uuid: equipmentLicenseFormEntry.uuid,
            number: equipmentLicenseFormEntry.number,
            licenseType: equipmentLicenseFormEntry.licenseType,
            licensingBody: equipmentLicenseFormEntry.licensingBody,
            issueDate: equipmentLicenseFormEntry.issueDate,
            expiryDate: equipmentLicenseFormEntry.expiryDate,
          },
        };
      }
      // if the uuid field is empty, it means this item was not fetched from the database, and must be created
      return {
        equipmentLicenseCreateInput: {
          number: equipmentLicenseFormEntry.number,
          licenseType: equipmentLicenseFormEntry.licenseType,
          licensingBody: equipmentLicenseFormEntry.licensingBody,
          issueDate: equipmentLicenseFormEntry.issueDate,
          expiryDate: equipmentLicenseFormEntry.expiryDate,
        },
      };
    });
  };

  /**
   * onClick handler for saving changes to an existing equipment
   */
  const handleUpdateEquipment = async () => {
    if (hasFieldError()) {
      return;
    }

    const equipmentLicenseArrayUpdateInputs =
      generateEquipmentLicenseArrayUpdateInputs();

    const { name, type, vehicleTypeUuid } = formData;

    // Should never happen since we check required fields above
    if (isNil(name) || isNil(type)) {
      throw new Error('required field was not entered');
    }

    if (hasUuid) {
      await updateEquipment({
        variables: {
          input: {
            equipmentUpdateInput: {
              uuid: equipmentUuid,
              name,
              type,
              measurementSystem: formData.measurementSystem,
              capacity: formData.capacity,
              weight: formData.weight,
              length: formData.length,
              width: formData.width,
              height: formData.height,
              notes: formData.notes,
              equipmentLicenseArrayUpdateInputs,
              vehicleTypeUuid,
            },
          },
        },
      });
      // TODO (Aki) Add error handling here for failure case
      navigate(-1);
    }
  };

  const handleDeleteEquipment = async () => {
    if (typeof equipmentUuid === 'string') {
      await deleteEquipment({
        variables: {
          uuid: equipmentUuid,
        },
      });
    }
    navigate(-1);
  };

  const handleArchiveEquipment = async () => {
    if (hasUuid) {
      await updateEquipment({
        variables: {
          input: {
            equipmentUpdateInput: {
              uuid: equipmentUuid,
              isActive: false,
            },
          },
        },
      });
      // TODO (Aki) Add error handling here for failure case
      navigate(-1);
    }
  };
  const handleUnarchiveEquipment = async () => {
    if (hasUuid) {
      await updateEquipment({
        variables: {
          input: {
            equipmentUpdateInput: {
              uuid: equipmentUuid,
              isActive: true,
            },
          },
        },
      });
      navigate(-1);
    }
  };

  const changeSelectedEquipmentTypeHandler = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setFormData({ ...formData, type: value as EquipmentType });
  };

  return (
    <Box sx={styles.addEquipmentContainer}>
      <Stack justifyContent="space-between" direction="row">
        <Button
          onClick={() => navigate(-1)}
          sx={{ marginBottom: '16px' }}
          variant="contained"
        >
          Back
        </Button>
        {pageMode === EquipmentPageMode.CREATE && (
          <Button
            onClick={handleCreateEquipment}
            sx={{ marginBottom: '16px' }}
            variant="contained"
          >
            Save
          </Button>
        )}
        {pageMode === EquipmentPageMode.EDIT && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ marginBottom: '16px' }}
          >
            <Button
              onClick={handleDeleteEquipment}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
            <Button onClick={handleUpdateEquipment} variant="contained">
              Save Changes
            </Button>
          </Stack>
        )}
      </Stack>
      <Card sx={styles.card}>
        <Box sx={styles.flexRowBox}>
          {pageMode === EquipmentPageMode.CREATE && (
            <Typography variant="h6">Add Equipment</Typography>
          )}
          {pageMode === EquipmentPageMode.VIEW && (
            <Typography variant="h6">View Equipment</Typography>
          )}
          {pageMode === EquipmentPageMode.EDIT && (
            <Typography variant="h6">Edit Equipment</Typography>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid sx={styles.flexRowBox} item xs={3}>
            <TextField
              disabled={pageMode === EquipmentPageMode.VIEW}
              onChange={(event) => {
                setFormData({ ...formData, name: event.target.value });
              }}
              label="Name"
              error={!isNil(formErrors.name)}
              helperText={formErrors.name}
              required
              value={formData.name ?? ''}
            />
            {formData.hasSamsaraOAuthToken === true &&
              formData.hasSamsaraVehicleId === false && (
                <Tooltip title={COULD_NOT_FIND_IN_SAMSARA_TOOLTIP}>
                  <ErrorIcon color="warning" sx={{ ml: '10px' }} />
                </Tooltip>
              )}
          </Grid>
          <Grid sx={styles.flexRowBox} item xs={3}>
            <FormControl
              disabled={pageMode === EquipmentPageMode.VIEW}
              error={!isNil(formErrors.type)}
              sx={{ width: 200 }}
              required
            >
              <InputLabel
                shrink={formData.type !== null}
                id="select-equipment-type-label"
              >
                Equipment Type
              </InputLabel>
              <Select
                labelId="select-equipment-type-label"
                id="select-equipment-type"
                label="equipmentType"
                onChange={changeSelectedEquipmentTypeHandler}
                value={formData.type ?? ''}
                error={!isNil(formErrors.type)}
              >
                {availableEquipmentTypes.map((equipmentType) => (
                  <MenuItem key={equipmentType} value={equipmentType}>
                    {equipmentType}
                  </MenuItem>
                ))}
              </Select>
              {!isNil(formErrors.type) && (
                <FormHelperText error>{formErrors.type}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid sx={styles.flexRowBox} item xs={3}>
            <FormControl
              disabled={pageMode === EquipmentPageMode.VIEW}
              error={!isNil(formErrors.vehicleTypeUuid)}
              sx={{ width: 200 }}
            >
              <InputLabel shrink={!isEmpty(formData.vehicleTypeUuid)}>
                Vehicle Type
              </InputLabel>
              <Select
                notched={!isEmpty(formData.vehicleTypeUuid)}
                label="Vehicle Type"
                value={formData.vehicleTypeUuid}
                required
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    vehicleTypeUuid: event.target.value,
                  });
                }}
              >
                {vehicleTypesData?.vehicleTypes.map((vehicleType) => (
                  <MenuItem key={vehicleType.uuid} value={vehicleType.uuid}>
                    {vehicleType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      {pageMode === EquipmentPageMode.EDIT && (
        <Card sx={styles.card}>
          <ArchiveActionComponent
            entityType={ArchiveableEntity.EQUIPMENT}
            isActive={formData.isActive ?? true}
            handleArchive={handleArchiveEquipment}
            handleUnarchive={handleUnarchiveEquipment}
          />
        </Card>
      )}
    </Box>
  );
};

export default Equipment;
