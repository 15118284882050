import { Stack, Typography, useTheme } from '@mui/material';

interface RequiredWrapperProps {
  children: React.ReactNode;
  isRequired?: boolean;
}
const RequiredLabel = ({
  children,
  isRequired = true,
}: RequiredWrapperProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" gap="4px">
      {children}
      {isRequired && (
        <Typography color={theme.palette.error.main}>*</Typography>
      )}
    </Stack>
  );
};

export default RequiredLabel;
