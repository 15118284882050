import { Stack } from '@mui/material';
import FieldMappingsCard from './field-mappings-card';
import NonFieldMappingRules from './non-field-mapping-rules';

const ViewAndUpdateFieldMappings = () => {
  return (
    <Stack sx={{ p: 2, gap: 2 }}>
      <NonFieldMappingRules />
      <FieldMappingsCard />
    </Stack>
  );
};

export default ViewAndUpdateFieldMappings;
