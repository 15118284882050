import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { safeAdd } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import { ORDER_PAGE_FINALIZE_CHARGES_BUTTON_TEST_ID } from '../../../../../../../constants';
import useUserRoles from '../../../../../../common/react-hooks/use-user-roles';
import {
  OrderDetailedStatus,
  OrderStatus,
  PermissionResource,
  useFinalizeOrderMutation,
  useOrderWithPaperworkQuery,
  useUnfinalizeOrderMutation,
} from '../../../../../../generated/graphql';
import useOrderFormStore from '../../../../order-form-store';
import { PackageValues, StopValues } from '../../forms/types';
import {
  calculateLineHaulShipmentTotalCharge,
  calculateOrderTotalCharge,
  calculateTotalCharge,
  useCanFinalizeCharges,
} from '../../forms/utils';
import { useShouldShowOrderChargesSection } from '../../hooks/use-expected-order-components';
import { OnSubmitParams, OrderFormFieldValues } from '../../types';
import { OrderFormCard } from '../order-form-card';
import { OrderFormCardTitle } from '../order-form-card-title';
import LineHaulCharge from './components/line-haul-charge/line-haul-charge';
import OrderCharges from './components/order-charges/order-charges';
import OrderFormChargesContext from './components/order-form-charges-context';
import RateOrderContainer from './components/rate-order-container';
import StopCharges from './components/stop-charges/stop-charges';

const Charges = ({
  inBillingReview = false,
  onSubmit,
  showTitle = true,
}: {
  inBillingReview?: boolean;
  onSubmit?: (params: OnSubmitParams) => Promise<boolean>;
  showTitle?: boolean;
}) => {
  const [isRatingAccessorials, setIsRatingAccessorials] =
    useState<boolean>(false);
  const [isSwitchingAccessorial, setIsSwitchingAccessorial] =
    useState<boolean>(false);

  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const theme = useTheme();
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const uuid = useWatch({ control, name: 'uuid' });
  const stops = useWatch({ control, name: 'stops' });

  const isUsingLineHaul = useWatch({ control, name: 'isUsingLineHaul' });

  const { showSection: showOrderChargesSection } =
    useShouldShowOrderChargesSection();

  const lineHaulShipment = useWatch({ control, name: 'lineHaulShipment' });
  const orderChargesShipment = useWatch({
    control,
    name: 'orderChargesShipment',
  });
  const status = useWatch({ control, name: 'status' });
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const packages: PackageValues[] =
    useWatch({ control, name: 'packages' }) ?? [];

  const { data: paperworkCompleteData } = useOrderWithPaperworkQuery(
    isNilOrEmptyString(uuid) ? { skip: true } : { variables: { uuid } },
  );
  const isPaperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;

  const {
    userHasPermissionToFinalizeCharges,
    isOrderFinalizable,
    reasonChargesNotFinalizable,
  } = useCanFinalizeCharges({ detailedStatus, packages, isPaperworkComplete });

  const [finalizeOrder] = useFinalizeOrderMutation();
  const [unfinalizeOrder] = useUnfinalizeOrderMutation();
  const chargesFinalized =
    detailedStatus === OrderDetailedStatus.InvoicePosted ||
    detailedStatus === OrderDetailedStatus.ChargesFinalized ||
    detailedStatus === OrderDetailedStatus.OnInvoice;
  const [isOrderPageRating, setIsOrderPageRating, setJustFinalizedCharges] =
    useOrderFormStore(
      (state) => [
        state.isOrderPageRating,
        state.setIsOrderPageRating,
        state.setJustFinalizedCharges,
      ],
      shallow,
    );

  const onInvoice = (stops ?? []).some(
    (st: StopValues) => !isNil(st.invoiceUuid),
  );

  const stopChargesTotal = (stops ?? []).reduce((prev, curr) => {
    if (curr?.hideFromBilling) {
      return prev;
    }
    const freightChargeTotal = curr?.freightCharge?.totalCharge ?? 0;
    const fuelChargeTotal = curr?.freightCharge?.fuelCharge?.totalCharge ?? 0;
    return safeAdd(
      prev,
      calculateTotalCharge({
        freightChargeTotal,
        fuelChargeTotal,
        customCharges: curr?.customCharges ?? [],
      }),
    );
  }, 0);

  const lineHaulChargeTotal = isUsingLineHaul
    ? calculateLineHaulShipmentTotalCharge({
        freightChargeTotal: lineHaulShipment?.freightCharge?.totalCharge ?? 0,
        fuelChargeTotal:
          lineHaulShipment?.freightCharge?.fuelCharge?.totalCharge ?? 0,
      })
    : 0;
  const orderChargesTotal = calculateTotalCharge({
    freightChargeTotal: orderChargesShipment?.freightCharge?.totalCharge ?? 0,
    fuelChargeTotal:
      orderChargesShipment?.freightCharge?.fuelCharge?.totalCharge ?? 0,
    customCharges: orderChargesShipment?.customCharges ?? [],
  });

  const totalCharge = calculateOrderTotalCharge({
    stopChargesTotal,
    lineHaulChargeTotal,
    orderChargesTotal,
  });

  useEffect(() => {
    setValue('totalCharge', totalCharge);
  }, [totalCharge, setValue]);

  const totalChargeStr = isNil(totalCharge)
    ? '-'
    : currency(totalCharge).format();

  const onUnfinalizeCharges = async () => {
    setIsOrderPageRating(true);
    try {
      const res = await unfinalizeOrder({
        variables: {
          uuid,
        },
      });
      const { data } = res;
      if (!isNil(data)) {
        setValue('detailedStatus', data.unfinalizeOrder.detailedStatusV2);
        setValue('status', data.unfinalizeOrder.status);
        stops?.forEach((stop, idx) => {
          const newShipmentStatus = data.unfinalizeOrder.shipments.find(
            (s) => s.uuid === stop.shipmentUuid,
          )?.status;
          if (!isNil(newShipmentStatus))
            setValue(`stops.${idx}.shipmentStatus`, newShipmentStatus);
        });
      }
    } finally {
      setIsOrderPageRating(false);
    }
  };

  const onFinalizeCharges = async () => {
    if (!isOrderFinalizable) {
      return;
    }
    setIsOrderPageRating(true);
    try {
      let success = true;
      if (!isNil(onSubmit)) {
        success = await onSubmit({
          refetchOrderAfterSave: true,
          noRedirect: true,
        });
      }
      if (!success) {
        return;
      }
      const res = await finalizeOrder({
        variables: {
          uuid,
        },
      });
      const { data } = res;
      if (!isNil(data)) {
        setValue('detailedStatus', data.finalizeOrder.detailedStatusV2);
        setValue('status', data.finalizeOrder.status);
        stops?.forEach((stop, idx) => {
          const newShipmentStatus = data.finalizeOrder.shipments.find(
            (s) => s.uuid === stop.shipmentUuid,
          )?.status;
          if (!isNil(newShipmentStatus))
            setValue(`stops.${idx}.shipmentStatus`, newShipmentStatus);
        });
        setJustFinalizedCharges(true);
      }
    } finally {
      setIsOrderPageRating(false);
    }
  };

  const FinalizeChargesComponent = userHasPermissionToFinalizeCharges &&
    !chargesFinalized &&
    (status === OrderStatus.Delivered || status === OrderStatus.HasIssue) && (
      <Tooltip
        title={reasonChargesNotFinalizable}
        disableHoverListener={isNil(reasonChargesNotFinalizable)}
        placement="top"
      >
        <Box sx={{ ml: 'auto' }}>
          <Button
            onClick={onFinalizeCharges}
            disabled={
              !canWriteOrders ||
              !isOrderFinalizable ||
              isOrderPageRating === true
            }
            data-testid={ORDER_PAGE_FINALIZE_CHARGES_BUTTON_TEST_ID}
            startIcon={
              isOrderPageRating === true && <CircularProgress size={20} />
            }
          >
            Finalize charges
          </Button>
        </Box>
      </Tooltip>
    );

  const UnfinalizeChargesComponent = (status === OrderStatus.Finalized ||
    status === OrderStatus.Invoiced ||
    chargesFinalized) && (
    <Tooltip
      title="Remove from invoice to unfinalize charges"
      disableHoverListener={!onInvoice}
      placement="top"
    >
      <Box sx={{ ml: 'auto' }}>
        <Button
          onClick={onUnfinalizeCharges}
          disabled={
            !canWriteOrders ||
            onInvoice ||
            !isOrderFinalizable ||
            !userHasPermissionToFinalizeCharges ||
            isOrderPageRating === true
          }
          startIcon={
            isOrderPageRating === true && <CircularProgress size={20} />
          }
        >
          {!(userHasPermissionToFinalizeCharges && isOrderFinalizable)
            ? 'Charges finalized'
            : 'Unfinalize charges'}
        </Button>
      </Box>
    </Tooltip>
  );

  const memoizedOrderFormChargesContext = useMemo(
    () => ({
      isRatingAccessorials,
      setIsRatingAccessorials,
      isSwitchingAccessorial,
      setIsSwitchingAccessorial,
    }),
    [
      isRatingAccessorials,
      setIsRatingAccessorials,
      isSwitchingAccessorial,
      setIsSwitchingAccessorial,
    ],
  );

  return (
    <OrderFormChargesContext.Provider value={memoizedOrderFormChargesContext}>
      <OrderFormCard>
        <RateOrderContainer />
        {showTitle && <OrderFormCardTitle title="Charges" />}
        {inBillingReview !== true && (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            {FinalizeChargesComponent}
            {UnfinalizeChargesComponent}
          </Box>
        )}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell width="150">Quantity</TableCell>
                <TableCell colSpan={2}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stops?.map((stop, idx) => (
                <StopCharges
                  key={stop.uuid}
                  idx={idx}
                  inBillingReview={inBillingReview}
                />
              ))}
              {isUsingLineHaul && <LineHaulCharge />}
              {showOrderChargesSection && (
                <OrderCharges inBillingReview={inBillingReview} />
              )}
              <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                <TableCell data-testid="order-total-header">
                  Order Total
                </TableCell>{' '}
                <TableCell />
                <TableCell />
                <TableCell data-testid="order-total">
                  {totalChargeStr}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </OrderFormCard>
    </OrderFormChargesContext.Provider>
  );
};

export default Charges;
