import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Typography,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import React from 'react';
import { LocationZoneData } from '../store/tariff-group-controller';
import { AmountInputType } from '../types';

interface TariffZoneOverageRowProps {
  zone: LocationZoneData;
  rowIndex: number;
  mileOverageRate: number | null | undefined;
  mileOverageFlatRate: number | null | undefined;
  mileOverageApplicableAbove: number | null | undefined;
  pieceOverageRate: number | null | undefined;
  pieceOverageFlatRate: number | null | undefined;
  pieceOverageApplicableAbove: number | null | undefined;
  weightOverageRate: number | null | undefined;
  weightOverageFlatRate: number | null | undefined;
  weightOverageApplicableAbove: number | null | undefined;
  updateMileOverageRate: (rowIndex: number, mileOverageRate: number) => void;
  updateMileOverageFlatRate: (
    rowIndex: number,
    mileOverageFlatRate: number,
  ) => void;
  updateMileOverageApplicableAbove: (
    rowIndex: number,
    mileOverageApplicableAbove: number,
  ) => void;
  updatePieceOverageRate: (rowIndex: number, pieceOverageRate: number) => void;
  updatePieceOverageFlatRate: (
    rowIndex: number,
    pieceOverageFlatRate: number,
  ) => void;
  updatePieceOverageApplicableAbove: (
    rowIndex: number,
    pieceOverageApplicableAbove: number,
  ) => void;
  updateWeightOverageRate: (
    rowIndex: number,
    weightOverageRate: number,
  ) => void;
  updateWeightOverageFlatRate: (
    rowIndex: number,
    weightOverageFlatRate: number,
  ) => void;
  updateWeightOverageApplicableAbove: (
    rowIndex: number,
    weightOverageApplicableAbove: number,
  ) => void;
  validateAndUpdateRate: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => void;
}

const TariffZoneOverageRow = ({
  zone,
  rowIndex,
  mileOverageRate,
  mileOverageFlatRate,
  mileOverageApplicableAbove,
  pieceOverageRate,
  pieceOverageFlatRate,
  pieceOverageApplicableAbove,
  weightOverageRate,
  weightOverageFlatRate,
  weightOverageApplicableAbove,
  updateMileOverageRate,
  updateMileOverageFlatRate,
  updateMileOverageApplicableAbove,
  updatePieceOverageRate,
  updatePieceOverageFlatRate,
  updatePieceOverageApplicableAbove,
  updateWeightOverageRate,
  updateWeightOverageFlatRate,
  updateWeightOverageApplicableAbove,
  validateAndUpdateRate,
}: TariffZoneOverageRowProps) => {
  return (
    <Grid
      container
      key={zone.uuid}
      mt={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item mr={5}>
        <Typography variant="subtitle1">
          {zone.name}
          {zone.isLhLaneActive === false && (
            <Typography
              component="span"
              sx={{ fontSize: '10px', display: 'block' }}
            >
              (Archived)
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" spacing={4}>
          <Stack direction="row" flex={1} spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.MileOverage}
              size="small"
              label="Mile Overage Rate"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updateMileOverageRate(rowIndex, parseFloat(event.target.value));
              }}
              value={mileOverageRate ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.MileOverageFlatRate}
              size="small"
              label="Mile Ovg. Flat Rate"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updateMileOverageFlatRate(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={mileOverageFlatRate ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.MileOverageApplicableAbove}
              size="small"
              label="Applicable Above (Optional)"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updateMileOverageApplicableAbove(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={mileOverageApplicableAbove ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">miles</InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" flex={1} spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.PieceOverage}
              size="small"
              label="Piece Count Overage Rate"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updatePieceOverageRate(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={pieceOverageRate ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.PieceOverageFlatRate}
              size="small"
              label="Piece Count Ovg. Flat Rate"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updatePieceOverageFlatRate(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={pieceOverageFlatRate ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.PieceOverageApplicableAbove}
              size="small"
              label="Applicable Above (Optional)"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updatePieceOverageApplicableAbove(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={pieceOverageApplicableAbove ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pieces</InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction="row" flex={1} spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.WeightOverage}
              size="small"
              label="Weight Overage Rate"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updateWeightOverageRate(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={weightOverageRate ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.WeightOverageFlatRate}
              size="small"
              label="Weight Ovg. Flat Rate"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updateWeightOverageFlatRate(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={weightOverageFlatRate ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              InputLabelProps={{ shrink: true }}
              name={AmountInputType.WeightOverageApplicableAbove}
              size="small"
              label="Applicable Above (Optional)"
              onBlur={(event) => validateAndUpdateRate(event, rowIndex)}
              onChange={(event) => {
                updateWeightOverageApplicableAbove(
                  rowIndex,
                  parseFloat(event.target.value),
                );
              }}
              value={weightOverageApplicableAbove ?? null}
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">pounds</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default React.memo(TariffZoneOverageRow);
