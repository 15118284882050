import { HighlightOff } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import DocumentUploadModal from '../../../../common/components/document-upload-modal';
import { DocumentsViewerModal } from '../../../../common/components/documents-viewer-modal';
import useDocuments from '../../../../common/react-hooks/use-documents';
import {
  DocumentType,
  StorageOrderDocumentFragment,
} from '../../../../generated/graphql';
import { DocViewerDocument } from '../../../end-of-day/types/doc-viewer-document';

const useStyles = () => {
  const theme = useTheme();
  return {
    borderRightDivider: {
      borderRight: 1,
    } as SxProps,
    borderColor: theme.palette.borderColor.main,
    unassignedColor: theme.palette.unassignedColor.main,
    routeCardContainer: {
      display: 'flex',
      height: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardHover: {
      '&:hover': {
        background: 'rgba(37, 48, 82, 0.08)',
        transition: '0.15s',
        cursor: 'pointer',
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        fontSize: 9,
        height: 15,
        minWidth: 15,
      },
    } as SxProps,
  };
};

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
`);

interface StorageOrderDocumentsViewerProps {
  documents: StorageOrderDocumentFragment[];
  onUploadDocument: (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => void;
  onDeleteDocument: (uuid: string) => void;
  getAwsUrl: (
    fileName: string,
    fileType: string,
  ) => Promise<string | undefined>;
}
const StorageOrderDocumentsViewer = ({
  documents,
  onUploadDocument,
  onDeleteDocument,
  getAwsUrl,
}: StorageOrderDocumentsViewerProps) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const uploadDropdownOpen = Boolean(anchorEl);
  const [selectedDocIndex, setSelectedDocIndex] = useState<number | undefined>(
    undefined,
  );
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [hoveredDoc, setHoveredDoc] = useState<string>();
  const [selectedDocTypeUpload, setSelectedDocTypeUpload] =
    useState<DocumentType>();

  const { getDocumentTypeCopy, loading } = useDocuments();

  const handleOpenModal = (index: number) => {
    setSelectedDocIndex(index);
  };

  const handleCloseUploadDropdown = () => {
    setAnchorEl(null);
  };

  const convertStorageOrderDocumentToDocViewerDocument = (
    storageOrderDocument: StorageOrderDocumentFragment,
  ): DocViewerDocument => {
    return {
      fileType: storageOrderDocument.fileType,
      fileName: storageOrderDocument.fileName,
      uuid: storageOrderDocument.uuid,
      preSignedGetUrl: storageOrderDocument.preSignedGetUrl,
      pageNumber: undefined,
      docType: storageOrderDocument.type,
      driverFormTemplateUuid: undefined,
      notes: undefined,
    };
  };

  const docViewerDocuments = documents.map(
    convertStorageOrderDocumentToDocViewerDocument,
  );

  if (loading) {
    return null;
  }

  return (
    <Stack rowGap={2}>
      <Grid container>
        <Stack rowGap={2}>
          <Button
            variant="contained"
            size="medium"
            id="upload-dropdown-button"
            aria-controls={
              uploadDropdownOpen ? 'upload-dropdown-menu' : undefined
            }
            aria-haspopup="true"
            aria-expanded={uploadDropdownOpen ? 'true' : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Upload
          </Button>
        </Stack>
        <Menu
          id="upload-dropdown-menu"
          anchorEl={anchorEl}
          open={uploadDropdownOpen}
          onClose={handleCloseUploadDropdown}
          MenuListProps={{
            'aria-labelledby': 'upload-dropdown-button',
          }}
        >
          <MenuItem
            onClick={() => {
              setShowUploadModal(true);
              setSelectedDocTypeUpload(DocumentType.EndOfDayDocument);
              handleCloseUploadDropdown();
            }}
          >
            {getDocumentTypeCopy({
              documentType: DocumentType.EndOfDayDocument,
            })}
          </MenuItem>
          <Divider />
          {Object.values(DocumentType)
            .filter(
              (documentType) =>
                documentType !== DocumentType.EndOfDayDocument &&
                documentType !== DocumentType.CustomDriverFormSignature,
            )
            .map((documentType) => (
              <MenuItem
                key={documentType}
                onClick={() => {
                  setShowUploadModal(true);
                  setSelectedDocTypeUpload(documentType);
                  handleCloseUploadDropdown();
                }}
              >
                {getDocumentTypeCopy({
                  documentType,
                })}
              </MenuItem>
            ))}
        </Menu>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        {!isEmpty(documents) ? (
          documents.map((doc, index) => {
            return (
              <Grid item xs={4} key={doc.uuid}>
                <Card
                  variant="outlined"
                  sx={[styles.cardHover, { height: '180px' }]}
                  onClick={() => handleOpenModal(index)}
                  onMouseEnter={() => setHoveredDoc(doc.uuid)}
                  onMouseLeave={() => setHoveredDoc(undefined)}
                >
                  <CardContentSpecialPadding>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontSize: '10px',
                            color: 'gray',
                            marginBottom: '5px',
                          }}
                        >
                          {getDocumentTypeCopy({
                            documentType: doc.type,
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {!isNil(onDeleteDocument) && (
                          <Fade in={doc.uuid === hoveredDoc} timeout={0}>
                            <IconButton
                              sx={{
                                float: 'right',
                                padding: '2px',
                                transform: 'translate(5px, -5px)',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeleteDocument(doc.uuid);
                              }}
                            >
                              <HighlightOff />
                            </IconButton>
                          </Fade>
                        )}
                      </Grid>
                    </Grid>
                    {doc.fileType === 'application/pdf' ? (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div style={{ width: '100%' }}>
                          <Viewer
                            fileUrl={doc.preSignedGetUrl}
                            defaultScale={0.5}
                          />
                        </div>
                      </Worker>
                    ) : (
                      <object data={doc.preSignedGetUrl} width="100%">
                        {' '}
                      </object>
                    )}
                  </CardContentSpecialPadding>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              sx={{
                fontSize: '13px',
                color: 'gray',
                marginBottom: '5px',
              }}
            >
              No Documents Uploaded
            </Typography>
          </Grid>
        )}
      </Grid>
      <DocumentsViewerModal
        documents={docViewerDocuments}
        selectedIndex={selectedDocIndex}
        setSelectedIndex={setSelectedDocIndex}
      />
      <DocumentUploadModal
        isOpen={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        documentUploadType={selectedDocTypeUpload ?? DocumentType.Other}
        handleNewDocument={onUploadDocument}
        getAwsUrl={getAwsUrl}
      />
    </Stack>
  );
};

export default StorageOrderDocumentsViewer;
