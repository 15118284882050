import { useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import AppointmentDecisionView from '../domains/appointments/components/appointment-decision-view';

/**
 * This page is intentionally left unauthenticated. It is used to confirm an appointment, and a user will
 * generally navigate to it from their smartphone (which will not be logged into the Cashew platform).
 *
 * The internal component also calls a resolver that is left unauthenticated.
 *
 * @constructor
 */
const ConfirmAppointmentPage = () => {
  const { appointmentUuid } = useParams();

  if (isNotNilOrEmptyString(appointmentUuid)) {
    return <AppointmentDecisionView stopUuid={appointmentUuid} />;
  }

  return 'Page not found.';
};

export default ConfirmAppointmentPage;
