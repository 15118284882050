import {
  Box,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactsDocument,
  PermissionResource,
  useMasterAccountsQuery,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  updateOneContactValues,
  selectContactMasterAccountUuid,
} from '../redux/contact-values-slice';

const ContactPaymentsSettings = ({ contactUuid }: { contactUuid: string }) => {
  const { userPermissions } = useUserRoles();
  const { data: masterAccountsData } = useMasterAccountsQuery();
  const masterAccounts =
    masterAccountsData?.masterAccounts?.masterAccounts ?? [];
  const dispatch = useAppDispatch();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const masterAccountUuid = useAppSelector((state) =>
    selectContactMasterAccountUuid(state, contactUuid),
  );
  const [updateContact, { loading: isSaving }] =
    useUpdateCustomerContactMutation({
      refetchQueries: [ContactsDocument],
    });

  const handleMasterAccountChange = async (
    newMasterAccountUuid: string | undefined,
  ) => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: {
          masterAccountUuid: newMasterAccountUuid,
        },
      }),
    );

    await updateContact({
      variables: {
        input: {
          customerContactUpdateInput: {
            uuid: contactUuid,
            masterAccountUuid: newMasterAccountUuid ?? null,
          },
        },
      },
    });
  };

  return (
    <Box>
      <Stack direction="column" spacing={3} sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h6">Payments</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{ fontSize: '12px', color: 'gray', float: 'right' }}
            >
              {isSaving ? 'Saving...' : 'Saved'}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Master account
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                size="small"
                value={masterAccountUuid ?? ''}
                required
                onChange={(event) => {
                  if (event.target.value === '') {
                    handleMasterAccountChange(undefined);
                  } else {
                    handleMasterAccountChange(event.target.value);
                  }
                }}
                disabled={!canWriteContacts}
              >
                <MenuItem key="" value="">
                  None
                </MenuItem>
                {masterAccounts.map((masterAccount) => (
                  <MenuItem key={masterAccount.uuid} value={masterAccount.uuid}>
                    {masterAccount.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ContactPaymentsSettings;
