import { DayOfWeekAll } from '../../../generated/graphql';

/** All days of the week, starting from Monday. */
export const daysOfWeek = [
  DayOfWeekAll.Monday,
  DayOfWeekAll.Tuesday,
  DayOfWeekAll.Wednesday,
  DayOfWeekAll.Thursday,
  DayOfWeekAll.Friday,
  DayOfWeekAll.Saturday,
  DayOfWeekAll.Sunday,
];

/** Mapping from DayOfWeekAll to the day abbreviated to two letters. */
export const daysOfWeekAbbreviated = {
  [DayOfWeekAll.Monday]: 'Mo',
  [DayOfWeekAll.Tuesday]: 'Tu',
  [DayOfWeekAll.Wednesday]: 'We',
  [DayOfWeekAll.Thursday]: 'Th',
  [DayOfWeekAll.Friday]: 'Fr',
  [DayOfWeekAll.Saturday]: 'Sa',
  [DayOfWeekAll.Sunday]: 'Su',
};
