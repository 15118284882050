import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty, isNil, range, sum, toUpper } from 'lodash';
import React, { useMemo } from 'react';
import { PackageValues } from 'shared/types';
import {
  formatWeightUnits,
  transformAddressToFullAddressStringSeparateLines,
} from '../../../common/utils/prettyPrintUtils';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import { PackageFragment, WeightUnits } from '../../../generated/graphql';
import { StopType } from '../../orders/components/order-form/forms/stop-type';
import {
  UniquePackagePiece,
  AddressType,
  LabelType,
  getShipperText,
  getRoutingText,
} from '../utils';

interface ContainerLabelDocumentProps {
  companyName: string;
  contact: string;
  tagNumber: string;
  mawb: string;
  hawb: string;
  shipperAddress?: AddressType;
  consigneeAddress?: AddressType;
  packages: PackageValues[] | PackageFragment[];
  inboundStopType?: StopType;
  outboundStopType?: StopType;
  recoveryAddress?: AddressType;
  pcdCarrier?: string;
  pcpCarrier?: string;
  defaultWeightUnits?: WeightUnits;
  outboundFlightAirline?: string;
  outboundFlightOriginCode?: string;
  outboundFlightDestinationCode?: string;
  dates: string;
  widthDimension: number;
  heightDimension: number;
  rotate: boolean;
}

const GeneratedContainerLabelPdf = ({
  companyName,
  contact,
  tagNumber,
  mawb,
  hawb,
  shipperAddress,
  consigneeAddress,
  packages,
  inboundStopType,
  outboundStopType,
  recoveryAddress,
  pcdCarrier,
  pcpCarrier,
  defaultWeightUnits,
  outboundFlightAirline,
  outboundFlightOriginCode,
  outboundFlightDestinationCode,
  dates,
  widthDimension,
  heightDimension,
  rotate,
}: ContainerLabelDocumentProps) => {
  const styles = StyleSheet.create({
    page: {
      padding: 4,
      fontFamily: 'Roboto',
      fontSize: '10px',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: rotate ? 'rotate(90deg)' : undefined,
    },
    headerTextBold: {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    headerText: {
      fontSize: '12px',
    },
    outerCell: {
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      padding: '3px',
      borderWidth: 1,
      borderColor: 'black',
      width: `${(rotate ? heightDimension / widthDimension : 1) * 100}%`,
      height: `${(rotate ? widthDimension / heightDimension : 1) * 100}%`,
    },
    cell: {
      borderTopWidth: 1,
      borderColor: 'gray',
      width: '100%',
      display: 'flex',
      padding: '3px',
    },
    contactTransferText: {
      fontSize: '10px',
      maxWidth: '100px',
    },
    airportInformationCell: {
      width: '100%',
      padding: 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    orderInformationCell: {
      display: 'flex',
      padding: '3px',
      flexDirection: 'row',
      fontSize: '12px',
    },
    packageCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    packageText: {
      fontSize: '12px',
    },
    headerFooter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
    },
  });

  const shipperText = getShipperText(
    LabelType.ContainerLabel,
    inboundStopType,
    shipperAddress,
    recoveryAddress,
    pcdCarrier,
  );

  const routingText = getRoutingText(
    outboundStopType,
    outboundFlightAirline,
    pcpCarrier,
  );

  const totalNumberOfPieces = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.quantity ?? 1));
  }, [packages]);

  const totalWeight = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.weight ?? 0));
  }, [packages]);

  let count = 0;

  const uniquePackagePieces: UniquePackagePiece[] = [];
  let pieceNumber = 1;
  packages.forEach((pkg) => {
    range(pkg?.quantity ?? 0).forEach(() => {
      uniquePackagePieces.push({
        pkg: {
          ...pkg,
          quantity: pkg.quantity ?? 0,
        },
        pieceNumber,
      });
      pieceNumber += 1;
    });
  });

  return (
    <Document>
      {uniquePackagePieces?.map((uniquePackagePiece) => {
        const { pkg } = uniquePackagePiece;
        count += 1;
        return (
          <Page
            key={pkg.uuid}
            size={{ width: widthDimension * 72, height: heightDimension * 72 }} // convert from pt to inc
            orientation="portrait"
            style={styles.page}
          >
            <View style={styles.outerCell}>
              <View style={styles.headerFooter}>
                <Text>Date: {dates}</Text>
              </View>
              <View style={styles.headerFooter}>
                <Text style={styles.headerTextBold}>
                  {companyName.toUpperCase()}
                </Text>
              </View>
              <View
                style={{
                  ...styles.headerFooter,
                  justifyContent: 'flex-end',
                }}
              >
                <Text style={styles.headerText}>{consigneeAddress?.name}</Text>
              </View>
              <View
                style={{
                  ...styles.headerFooter,
                  marginBottom: '5px',
                  marginTop: '5px',
                }}
              >
                <Text style={styles.contactTransferText}>
                  {contact.toUpperCase()}
                </Text>
                {!isNilOrEmptyString(routingText) && (
                  <Text style={styles.contactTransferText}>
                    {routingText?.toUpperCase()}
                  </Text>
                )}
              </View>
              <View style={[styles.cell]}>
                <View style={[styles.headerFooter]}>
                  <Text>
                    <Text style={{ fontWeight: 'bold' }}>MAWB#: </Text>
                    {mawb ?? '-'}
                  </Text>
                  <Text>
                    <Text style={{ fontWeight: 'bold' }}>HAWB#: </Text>
                    {hawb ?? '-'}
                  </Text>
                </View>
              </View>
              <View style={[styles.cell]}>
                {!isNil(shipperText) && (
                  <View style={[styles.orderInformationCell]}>
                    <Text style={{ width: '20%', fontWeight: 'bold' }}>
                      SHIP:
                    </Text>
                    <Text>{shipperText}</Text>
                  </View>
                )}
                {!isEmpty(outboundFlightOriginCode) && (
                  <View style={[styles.orderInformationCell]}>
                    <Text style={{ width: '20%', fontWeight: 'bold' }}>
                      ORIG:
                    </Text>
                    <Text>{outboundFlightOriginCode?.toUpperCase()}</Text>
                  </View>
                )}
                {!isEmpty(outboundFlightDestinationCode) && (
                  <View style={[styles.orderInformationCell]}>
                    <Text style={{ width: '20%', fontWeight: 'bold' }}>
                      DEST:
                    </Text>
                    <Text>{outboundFlightDestinationCode?.toUpperCase()}</Text>
                  </View>
                )}
                <View style={[styles.orderInformationCell]}>
                  <Text style={{ width: '20%', fontWeight: 'bold' }}>
                    CONS:
                  </Text>
                  <Text>
                    {!isNil(consigneeAddress)
                      ? transformAddressToFullAddressStringSeparateLines(
                          consigneeAddress,
                        )
                      : '-'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.headerFooter,
                  borderTopWidth: 1,
                  borderColor: 'black',
                }}
              >
                <Text style={styles.packageText}>
                  <Text style={{ fontWeight: 'bold' }}>Order: </Text>
                  {tagNumber ?? '-'}
                </Text>
                <Text style={styles.packageText}>
                  {`${count}/${totalNumberOfPieces} ${
                    pkg.type ?? 'PCS'
                  }, ${totalWeight} ${toUpper(
                    formatWeightUnits(defaultWeightUnits),
                  )}`}
                </Text>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default GeneratedContainerLabelPdf;
