import { isNil } from 'lodash';
import {
  AgingReportBucketFragment,
  AgingReportDataFragment,
  InvoiceForAgingReportFragment,
} from '../../../../../generated/graphql';

export enum AgingReportType {
  Detailed = 'Detailed',
  Summary = 'Summary',
}

export const getAgingReportColumnHeader = ({
  bucket,
  connector = '-',
}: {
  bucket: AgingReportBucketFragment;
  connector?: string;
}) => {
  const { greaterThanOrEqualToDays, lessThanOrEqualToDays } = bucket;
  if (isNil(greaterThanOrEqualToDays) && isNil(lessThanOrEqualToDays)) {
    return 'Balance';
  }
  if (isNil(greaterThanOrEqualToDays) || greaterThanOrEqualToDays === 0) {
    return 'Current';
  }
  if (isNil(lessThanOrEqualToDays)) {
    return `Over ${greaterThanOrEqualToDays - 1}`;
  }
  return `${bucket.greaterThanOrEqualToDays}${connector}${bucket.lessThanOrEqualToDays}`;
};

export const calculateAgingReportTotals = ({
  bucketIdx,
  agingReportData,
}: {
  bucketIdx: number;
  agingReportData: AgingReportDataFragment[];
}) => {
  return agingReportData.reduce((acc, curr) => {
    const bucket = curr.openInvoiceValueBuckets[bucketIdx];
    return acc + (bucket?.openInvoiceValue ?? 0);
  }, 0);
};

export const calculateInvoiceOpenBalanceForBucket = ({
  bucket,
  invoice,
  startDays,
}: {
  bucket: AgingReportBucketFragment;
  invoice: InvoiceForAgingReportFragment;
  startDays: number;
}) => {
  const { greaterThanOrEqualToDays, lessThanOrEqualToDays } = bucket;
  const age = Math.floor(invoice.age);

  if (isNil(greaterThanOrEqualToDays) && isNil(lessThanOrEqualToDays)) {
    return invoice.totalOpenAmount;
  }
  if (isNil(greaterThanOrEqualToDays)) {
    return age <= (lessThanOrEqualToDays ?? 0) + startDays
      ? invoice.totalOpenAmount
      : 0;
  }
  if (isNil(lessThanOrEqualToDays)) {
    return age >= greaterThanOrEqualToDays + startDays
      ? invoice.totalOpenAmount
      : 0;
  }
  return age >= greaterThanOrEqualToDays + startDays &&
    age <= lessThanOrEqualToDays + startDays
    ? invoice.totalOpenAmount
    : 0;
};
