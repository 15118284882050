import { Checkbox, Stack, Typography } from '@mui/material';
import { shallow } from 'zustand/shallow';
import useDispatchStore from '../../dispatch-store';

export const AllRouteSelector = ({ isMapView }: { isMapView: boolean }) => {
  const [
    routes,
    allRoutesSelected,
    setSelectedMapRouteUuids,
    selectAllRoutes,
    deselectAllRoutes,
  ] = useDispatchStore(
    (state) => [
      state.routes,
      isMapView
        ? state.selectedMapRouteUuids.length === state.routes.length
        : state.selectedRouteUuids.length === state.routes.length,
      state.setSelectedMapRouteUuids,
      state.selectAllRoutes,
      state.deselectAllRoutes,
    ],
    shallow,
  );
  return (
    <Stack direction="row" alignItems="center">
      <Checkbox
        size="small"
        sx={{ color: 'black' }}
        checked={allRoutesSelected}
        onChange={(e) => {
          if (isMapView) {
            setSelectedMapRouteUuids(
              e.target.checked ? routes.map((r) => r.uuid) : [],
            );
          } else if (e.target.checked) {
            selectAllRoutes();
          } else {
            deselectAllRoutes();
          }
        }}
      />
      <Typography variant="subtitle2" sx={{ color: 'black' }}>
        Select All Routes
      </Typography>
    </Stack>
  );
};
