import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, TableCell, TableRow } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import {
  OrderTableField,
  OrderTableFieldHeaderFragment,
} from '../../../../../generated/graphql';
import { getOrderTableFieldHeader } from '../../utils';

const AvailableFieldRow = ({
  field,
  orderTableFieldHeaders,
  setShowCurrentlyShowingFields,
  setShowAvailableFieldsToShow,
}: {
  field: OrderTableField;
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
  setShowCurrentlyShowingFields: Dispatch<SetStateAction<OrderTableField[]>>;
  setShowAvailableFieldsToShow: Dispatch<SetStateAction<OrderTableField[]>>;
}) => {
  return (
    <TableRow>
      <TableCell>
        {getOrderTableFieldHeader({
          orderTableFieldHeaders,
          orderTableField: field,
        })}
      </TableCell>
      <TableCell>
        <Button
          onClick={() => {
            setShowCurrentlyShowingFields((prevState) => [...prevState, field]);
            setShowAvailableFieldsToShow((prevState) =>
              prevState.filter((itrField) => itrField !== field),
            );
          }}
        >
          <ArrowForwardIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AvailableFieldRow;
