import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Box,
  Tooltip,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import React, { useState } from 'react';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useIsMac } from '../../../common/react-hooks/use-is-mac';
import UnifiedSearchDialog from '../../../domains/orders/components/unified-search/unified-search-dialog';

const NavbarSearch = () => {
  const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(false);
  const isMac = useIsMac();
  const { palette } = useTheme();

  useHotkeys(['Control+k', 'Meta+k'], async (e) => {
    e.preventDefault();
    setSearchDialogOpen(true);
  });
  const ffNewDashboardUi = useFeatureFlag(FeatureFlag.FF_NEW_DASHBOARD_UI);

  return (
    <Box>
      <Tooltip
        title={
          ffNewDashboardUi ? null : (
            <Stack>
              <Typography variant="caption">
                Search and jump to a page
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  border: 1,
                  borderRadius: 1,
                  px: 0.5,
                  width: 'fit-content',
                }}
              >
                {isMac ? '⌘+k' : 'ctrl+k'}
              </Typography>
            </Stack>
          )
        }
      >
        {ffNewDashboardUi ? (
          <Button
            size="small"
            startIcon={<SearchIcon />}
            sx={{
              fontWeight: 'b',
              borderRadius: 1,
              color: 'white',
              opacity: searchDialogOpen ? 1 : 0.8,
              backgroundColor: palette.airfreightBlue[60],
              width: '100%',
              '&:hover': {
                opacity: 1,
                backgroundColor: palette.airfreightBlue[60],
                transition: '0.25s',
              },
            }}
            onClick={() => {
              setSearchDialogOpen(true);
            }}
          >
            Search or {isMac ? '⌘+k' : 'ctrl+k'}
          </Button>
        ) : (
          <Button
            sx={{ color: 'white' }}
            startIcon={<SearchIcon />}
            onClick={() => {
              setSearchDialogOpen(true);
            }}
          >
            Search
          </Button>
        )}
      </Tooltip>
      {searchDialogOpen && (
        <UnifiedSearchDialog
          open={searchDialogOpen}
          setOpen={setSearchDialogOpen}
        />
      )}
    </Box>
  );
};

export default NavbarSearch;
