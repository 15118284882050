import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { TABLE_CONFIG_PILL_HEIGHT } from './table-configuration-pills';

const ViewChangedActionButton = styled(Button)<{ primary?: boolean }>(
  ({ primary = false }) => ({
    background: 'white',
    border: `1px solid ${theme.palette.borderColor.main}`,
    color: primary ? theme.palette.primary.main : theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    borderRadius: '6px',
    ':hover': {
      // Hack to fix the right border being cut off for the first button
      zIndex: 1,
    },
  }),
);

type ViewChangedButtonsNewProps = {
  resetView: () => void;
  saveView: () => void;
  showCreateViewModal: (viewIsFromScratch: boolean) => void;
  currentSavedViewUuid: string | null;
  shouldAllowSavedViews: boolean;
};

const ViewChangedButtonsNew = ({
  resetView,
  saveView,
  showCreateViewModal,
  currentSavedViewUuid,
  shouldAllowSavedViews,
}: ViewChangedButtonsNewProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleResetView = () => {
    resetView();
    handleCloseMenu();
  };

  const saveCurrentView = () => {
    saveView();
    handleCloseMenu();
  };

  const createNewView = () => {
    showCreateViewModal(false);
    handleCloseMenu();
  };

  const resetButton = (
    <ViewChangedActionButton onClick={handleResetView}>
      Reset
    </ViewChangedActionButton>
  );

  const saveCurrentViewButton = (
    <ViewChangedActionButton onClick={saveCurrentView} primary>
      Save view
    </ViewChangedActionButton>
  );

  const saveAsNewViewButton = (
    <ViewChangedActionButton onClick={createNewView} primary>
      Save as new view...
    </ViewChangedActionButton>
  );

  let buttonsContent = null;

  if (shouldAllowSavedViews && !isNil(currentSavedViewUuid)) {
    // Custom saved view
    buttonsContent = (
      <>
        {resetButton}
        <ButtonGroup>
          {saveCurrentViewButton}
          <ViewChangedActionButton onClick={openMenu} primary sx={{ p: 0 }}>
            <ArrowDropDownIcon fontSize="small" />
          </ViewChangedActionButton>
        </ButtonGroup>
        <Menu
          anchorEl={menuAnchorEl}
          onClose={handleCloseMenu}
          open={Boolean(menuAnchorEl)}
          sx={{ maxWidth: '300px' }}
        >
          <MenuList sx={{ p: 0 }}>
            <MenuItem onClick={createNewView}>Save as new view...</MenuItem>
          </MenuList>
        </Menu>
      </>
    );
  } else {
    // Default view
    buttonsContent = (
      <>
        {resetButton}
        {saveAsNewViewButton}
      </>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      height={TABLE_CONFIG_PILL_HEIGHT}
    >
      {buttonsContent}
    </Stack>
  );
};

export default React.memo(ViewChangedButtonsNew);
