import { OperationVariables, QueryResult } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { DownloadType } from '../../../common/components/download-type-selection';
import { useMeasureExecutionTime } from '../../../common/react-hooks/use-measure-execution-time';
import useInvoicesStore from '../../invoices/invoices-store';

/* Used for expected cases of empty reports where we want to show the user an error but not flag it as an unexpected error in Datadog */
export class EmptyReportError extends Error {}

/* Util that throws an error if a request fails or returns an empty response. This does not cover the case where the request succeeds but returns no data. */
export const throwIfFetchFails = <T, S extends OperationVariables>(
  reportQueryResult: QueryResult<T, S>,
) => {
  if (!isNil(reportQueryResult.error)) {
    throw new Error(reportQueryResult.error.message);
  }
  if (reportQueryResult.data == null) {
    throw new Error('Failed to fetch report data');
  }
};

interface DownloadReportParams<T> {
  /* Name of the report */
  name: string;
  rumLabel: string;
  filters?: object;
  downloadType: DownloadType;
  downloadReport: () => Promise<T>;
  /* Called when fn finishes, regardless of success or failure */
  onComplete?: (res?: T) => void;
}

/* Common util to handle downloading reports
  - Wraps useMeasureExecution, allowing for report download durations to be logged
  - Abstracts completeDownload logic for success and failure banners in the invoices context
  - Logs datadog RUM errors when reports fail to download (true failures, not empty reports) */
export const useDownloadReport = <T>({
  name,
  rumLabel,
  filters,
  downloadType,
  downloadReport,
  onComplete,
}: DownloadReportParams<T>) => {
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const exec = useCallback(async () => {
    const completeDownload = createFileDownload();
    try {
      const res = await downloadReport();
      completeDownload({
        message: `Downloaded ${name} report`,
      });
      return res;
    } catch (e) {
      const message =
        e instanceof Error
          ? e.message
          : `There was an error downloading the ${name} report`;
      completeDownload({
        alertSeverity: 'error',
        message,
      });
      if (e instanceof EmptyReportError) {
        // Do not log error for expected empty reports
        return null;
      }
      datadogRum.addError(e, {
        reportName: name,
        isReportFailure: true,
        error: e,
        downloadType,
        filters,
      });
    } finally {
      onComplete?.();
    }
    return null;
  }, [
    createFileDownload,
    downloadReport,
    downloadType,
    filters,
    name,
    onComplete,
  ]);

  return useMeasureExecutionTime({
    fn: exec,
    rumLabel,
    logData: {
      ...filters,
      downloadType,
    },
  });
};
