import { Text, View } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import { isNilOrEmptyString } from '../../../common/utils/utils';

export type AddressToDisplay =
  | {
      name?: string;
      line1?: string;
      line2?: string | null;
      city?: string;
      state?: string;
      zip?: string;
    }
  | null
  | undefined;

export type DisplayAddressProps = {
  fontSize?: string | undefined;
  address?: AddressToDisplay;
  gap?: number;
};

/**
 * Address display component to be used in PDF generation.
 *
 * @param props
 * @constructor
 */
const DisplayAddress = (props: DisplayAddressProps | null | undefined) => {
  if (isNil(props)) {
    return null;
  }
  const { address, fontSize, gap } = props;
  const { name, line1, line2, city, state, zip } = address ?? {};

  return (
    <View style={{ display: 'flex', flexDirection: 'column', gap }}>
      {!isNilOrEmptyString(name) && <Text style={{ fontSize }}>{name}</Text>}
      <Text style={{ fontSize }}>{line1 ?? ''}</Text>
      {!isNilOrEmptyString(line2) && <Text style={{ fontSize }}>{line2}</Text>}
      <Text style={{ fontSize }}>{`${city ?? ''}, ${state ?? ''} ${
        zip ?? ''
      }`}</Text>
    </View>
  );
};

export const getAddressStringToDisplay = (
  address: AddressToDisplay | null | undefined,
) => {
  if (isNil(address)) {
    return '';
  }
  const { name, line1, line2, city, state, zip } = address ?? {};
  return `${name ?? ' '}\n${line1 ?? ' '} ${line2 ?? ''}\n${city ?? ''}, ${
    state ?? ''
  } ${zip ?? ''}`;
};

export default DisplayAddress;
