import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil, orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { DocumentsViewerModal } from '../../../common/components/documents-viewer-modal';
import useDocuments from '../../../common/react-hooks/use-documents';
import { DocumentType } from '../../../generated/graphql';
import {
  downloadImage,
  getDocTypesPrioritizeHasDocuments,
} from '../end-of-day-utils';
import { DocViewerDocument } from '../types/doc-viewer-document';
import CustomFormDocumentViewer from './custom-form-document-viewer';
import DocumentPreviewCard from './document-preview-card';
import MultipleDocumentUploadModal from './multiple-document-upload-modal';

type DocumentsCardsProps = {
  docs: DocViewerDocument[];
  onUploadDocument?: (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => void;
  onDeleteDocument?: (uuid: string) => void;
  getAwsUrl?: (
    fileName: string,
    fileType: string,
  ) => Promise<string | undefined>;
  fetchShipmentDocuments?: () => void;
  displayDocTypes?: DocumentType[]; // what doc types should be shown + order of them
  hideEmptyDocTypes?: boolean;
  showAllDocumentsInOneSection?: boolean;
  colSize?: number;
  spacing?: number;
  cardHeight?: string;
  showDownloadAll?: boolean;

  canModifyDocuments?: boolean;
  hidePreviews?: boolean;

  uploadDisabled?: boolean;
  onSave?: (
    documentType: DocumentType,
    existingDocument: DocViewerDocument,
    existingDocumentIndex: number,
  ) => void;
};

const DocumentsCards = ({
  docs: unsortedDocs,
  onUploadDocument,
  onDeleteDocument,
  getAwsUrl,
  fetchShipmentDocuments,
  displayDocTypes,
  hideEmptyDocTypes = false,
  showAllDocumentsInOneSection = false,
  colSize = 2,
  spacing = 2,
  cardHeight = '180px',
  canModifyDocuments,
  showDownloadAll,
  hidePreviews,
  uploadDisabled,
  onSave,
}: DocumentsCardsProps) => {
  const theme = useTheme();
  const [selectedDocIndex, setSelectedDocIndex] = useState<number | undefined>(
    undefined,
  );
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [hoveredDoc, setHoveredDoc] = useState<string>();

  const { getDocumentTypeCopy, loading } = useDocuments();
  const docTypes = useMemo(() => {
    if (!isNil(displayDocTypes) && !isEmpty(displayDocTypes)) {
      return displayDocTypes;
    }
    return getDocTypesPrioritizeHasDocuments(unsortedDocs, hideEmptyDocTypes);
  }, [displayDocTypes, unsortedDocs, hideEmptyDocTypes]);

  const docs = useMemo(
    () =>
      orderBy(
        unsortedDocs,
        [
          (doc) => docTypes.indexOf(doc.docType),
          (doc) => (isNil(doc.pageNumber) ? Infinity : doc.pageNumber),
        ],
        ['asc', 'asc'],
      ),
    [unsortedDocs, docTypes],
  );

  const handleOpenModal = (docUuid: string) => {
    setSelectedDocIndex(docs.findIndex((doc) => doc.uuid === docUuid));
  };

  const renderDocumentPreviewCard = (doc: DocViewerDocument) => {
    if (
      doc.docType === DocumentType.CustomDriverFormSignature &&
      !isNil(doc.driverFormTemplateUuid)
    ) {
      return (
        <CustomFormDocumentViewer
          key={doc.uuid}
          doc={doc}
          driverFormTemplateUuid={doc.driverFormTemplateUuid}
          colSize={colSize}
        />
      );
    }
    return (
      <DocumentPreviewCard
        key={doc.uuid}
        doc={doc}
        onDeleteDocument={onDeleteDocument}
        handleOpenModal={() => handleOpenModal(doc.uuid)}
        canModifyDocuments={canModifyDocuments}
        cardHeight={cardHeight}
        colSize={colSize}
        hoveredDoc={hoveredDoc}
        setHoveredDoc={setHoveredDoc}
      />
    );
  };

  if (loading) {
    return null;
  }

  return (
    <Stack rowGap={spacing}>
      {!isNil(onUploadDocument) && !isNil(getAwsUrl) && (
        <Grid container>
          {canModifyDocuments === true && (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Button
                variant="contained"
                size="medium"
                id="upload-dropdown-button"
                aria-haspopup="true"
                onClick={() => setShowUploadModal(true)}
                disabled={uploadDisabled === true}
              >
                Upload
              </Button>
              {showDownloadAll === true && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.redColor.main }}
                  onClick={() => {
                    docs.forEach((doc) => {
                      // Need to treat pre-signed GET URLs differently
                      downloadImage(doc.preSignedGetUrl, doc.fileName);
                    });
                  }}
                >
                  Download all
                </Button>
              )}
            </Box>
          )}
          {hideEmptyDocTypes && isEmpty(docs) && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: '13px',
                  color: 'gray',
                  marginTop: '10px',
                }}
              >
                No Documents Uploaded
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {showAllDocumentsInOneSection && hidePreviews !== true && (
        <Grid container spacing={spacing} alignItems="center">
          {!isEmpty(docs) ? (
            docs.map((doc) => renderDocumentPreviewCard(doc))
          ) : (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                  color: 'gray',
                }}
              >
                No Documents Uploaded
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {!showAllDocumentsInOneSection &&
        hidePreviews !== true &&
        docTypes.map((documentType, index) => {
          const docsWithDocType = docs.filter(
            (doc) => doc.docType === documentType,
          );

          return (
            <Grid
              container
              spacing={spacing}
              alignItems="center"
              key={documentType}
            >
              <Grid item xs={12} md={6}>
                <Typography>{getDocumentTypeCopy({ documentType })}</Typography>
              </Grid>
              {!isEmpty(docsWithDocType) && (
                <Grid item xs={12} md={6} sx={{ paddingRight: 2 }}>
                  <Button
                    size="small"
                    sx={{ float: 'right' }}
                    onClick={() => {
                      docsWithDocType.forEach((doc) => {
                        // Need to treat pre-signed GET URLs differently
                        downloadImage(doc.preSignedGetUrl, doc.fileName);
                      });
                    }}
                  >
                    Download All
                  </Button>
                </Grid>
              )}
              {!isEmpty(docsWithDocType) ? (
                docsWithDocType.map((doc) => renderDocumentPreviewCard(doc))
              ) : (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: 'gray',
                      marginBottom: '5px',
                    }}
                  >
                    No Documents Uploaded
                  </Typography>
                </Grid>
              )}
              {index < docTypes.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          );
        })}

      <DocumentsViewerModal
        documents={docs}
        selectedIndex={selectedDocIndex}
        setSelectedIndex={setSelectedDocIndex}
      />
      {!isNil(onUploadDocument) && !isNil(getAwsUrl) && (
        <MultipleDocumentUploadModal
          key={showUploadModal.toString()}
          isOpen={showUploadModal}
          onClose={() => setShowUploadModal(false)}
          getAwsUrl={getAwsUrl}
          handleNewDocument={onUploadDocument}
          fetchShipmentDocuments={fetchShipmentDocuments}
          existingDocuments={docs}
          onDeleteDocument={onDeleteDocument}
          onSave={onSave}
        />
      )}
    </Stack>
  );
};

export default DocumentsCards;
