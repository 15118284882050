import { Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import { useMountEffect } from 'primereact/hooks';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useDispatchStore from '../dispatch-store';
import { CreateDispatchViewModal } from './components/create-dispatch-view-modal';
import { EditDispatchViewModal } from './components/edit-dispatch-view-modal';
import { useDispatchViews } from './use-dispatch-views';

const DispatchTabs = React.memo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const ffDispatchViews = useFeatureFlag(FeatureFlag.FF_DISPATCH_VIEWS);
  const [
    showMap,
    setShowMap,
    setSelectedStopUuids,
    selectedViewUuid,
    setSelectedViewUuid,
  ] = useDispatchStore(
    (state) => [
      state.showMap,
      state.setShowMap,
      state.setSelectedStopUuids,
      state.selectedViewUuid,
      state.setSelectedViewUuid,
    ],
    shallow,
  );
  const [showCreateDispatchViewModal, setShowCreateDispatchViewModal] =
    useState(false);
  const [showEditDispatchViewModal, setShowEditDispatchViewModal] =
    useState(false);
  const { dispatchViews, refetchDispatchViews } = useDispatchViews();

  const selectTabByValue = (value: string) => {
    if (value === 'routes') {
      setShowMap(false);
      setSelectedViewUuid(undefined);
    } else if (value === 'map') {
      setShowMap(true);
      setSelectedViewUuid(undefined);
    } else {
      setSelectedViewUuid(value);
    }
  };

  const onTabChanged = (e: React.SyntheticEvent, newValue: string) => {
    selectTabByValue(newValue);
    setSelectedStopUuids([]);
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('tab', newValue);
      return newParams;
    });
  };

  const currentTabValue = !isNil(selectedViewUuid)
    ? selectedViewUuid
    : showMap
      ? 'map'
      : 'routes';

  useMountEffect(() => {
    const tab = searchParams.get('tab');
    if (!isNil(tab)) {
      selectTabByValue(tab);
    }
  });

  const selectedDispatchView = dispatchViews?.find(
    (view) => view.id === selectedViewUuid,
  );

  return (
    <Stack direction="row" alignItems="center">
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={currentTabValue}
        onChange={onTabChanged}
      >
        <Tab value="routes" label="Routes" />
        <Tab value="map" label="Map" />
        {ffDispatchViews &&
          dispatchViews?.map((view) => (
            <Tab
              key={view.id}
              value={view.id}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  {view.name}
                  {selectedViewUuid === view.id && (
                    <IconButton
                      size="small"
                      component="span"
                      sx={{ p: 0.5 }}
                      onClick={() => {
                        setShowEditDispatchViewModal(true);
                      }}
                    >
                      <EditIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  )}
                </Stack>
              }
            />
          ))}
      </Tabs>
      {ffDispatchViews && (
        <Tooltip title="New view">
          <IconButton
            size="small"
            onClick={() => {
              setShowCreateDispatchViewModal(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {showCreateDispatchViewModal && (
        <CreateDispatchViewModal
          open={showCreateDispatchViewModal}
          onClose={() => {
            setShowCreateDispatchViewModal(false);
          }}
        />
      )}
      {showEditDispatchViewModal && !isNil(selectedDispatchView) && (
        <EditDispatchViewModal
          open={showEditDispatchViewModal}
          dispatchView={selectedDispatchView}
          refetchDispatchViews={refetchDispatchViews}
          onClose={() => {
            setShowEditDispatchViewModal(false);
          }}
        />
      )}
    </Stack>
  );
});

export { DispatchTabs };
