import { Alert, Snackbar } from '@mui/material';
import { shallow } from 'zustand/shallow';
import OrderTableReportAlerts from '../../../domains/orders/components/order-table-report-alerts';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import GlobalErrorSnackbar from './global-error-snackbar';
import GlobalSuccessSnackbar from './global-success-snackbar';

/**
 * A container component that encapsulates alerts for various operations that are shared
 * among multiple pages/components. It is initialized in the DashboardLayout component and will be
 * available across said pages/components.
 */
const GlobalSnackbars = () => {
  const [
    showAcceptedCsvOrdersSuccessMessage,
    setShowAcceptedCsvOrdersSuccessMessage,
    showRejectedCsvOrdersSuccessMessage,
    setShowRejectedCsvOrdersSuccessMessage,
  ] = useGlobalStore(
    (state) => [
      state.showAcceptedCsvOrdersSuccessMessage,
      state.setShowAcceptedCsvOrdersSuccessMessage,
      state.showRejectedCsvOrdersSuccessMessage,
      state.setShowRejectedCsvOrdersSuccessMessage,
    ],
    shallow,
  );

  return (
    <>
      <OrderTableReportAlerts />
      <GlobalSuccessSnackbar />
      <GlobalErrorSnackbar />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowAcceptedCsvOrdersSuccessMessage(false)}
        open={showAcceptedCsvOrdersSuccessMessage}
      >
        <Alert>Successfully confirmed CSV orders</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowRejectedCsvOrdersSuccessMessage(false)}
        open={showRejectedCsvOrdersSuccessMessage}
      >
        <Alert>Successfully rejected CSV orders</Alert>
      </Snackbar>
    </>
  );
};

export default GlobalSnackbars;
