import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  HeaderFileMappingType,
  QuickbooksHeaderFileMappingsDocument,
  useCreateQuickbooksHeaderFileMappingMutation,
  useUpdateQuickbooksHeaderFileMappingMutation,
} from '../../../generated/graphql';

export enum CreateOrEditQBHeader {
  Create = 'Create',
  Edit = 'Edit',
}

const CreateOrEditQuickbooksHeader = ({
  open,
  setOpen,
  createOrEdit,
  numberOfMappings,
  uuid,
  headerName,
  headerType,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  createOrEdit: CreateOrEditQBHeader;
  numberOfMappings: number;
  uuid: string | undefined;
  headerName: string;
  headerType: HeaderFileMappingType | null;
}) => {
  const [name, setName] = useState('');
  const [type, setType] = useState<HeaderFileMappingType | null>(null);
  const [createQuickbooksHeader] = useCreateQuickbooksHeaderFileMappingMutation(
    { refetchQueries: [QuickbooksHeaderFileMappingsDocument] },
  );
  const [updateQuickbooksHeader] = useUpdateQuickbooksHeaderFileMappingMutation(
    { refetchQueries: [QuickbooksHeaderFileMappingsDocument] },
  );

  useEffect(() => {
    setType(headerType);
    setName(headerName);
  }, [uuid, headerName, headerType]);

  const onSave = async () => {
    if (createOrEdit === CreateOrEditQBHeader.Create && !isNil(type)) {
      await createQuickbooksHeader({
        variables: {
          createQuickbooksHeaderFileMappingInput: {
            quickbooksHeaderFileMappingCreateInput: {
              name,
              headerType: type,
              cellNumber: numberOfMappings,
            },
          },
        },
      });
    }
    if (
      createOrEdit === CreateOrEditQBHeader.Edit &&
      !isNil(type) &&
      !isNil(uuid)
    ) {
      await updateQuickbooksHeader({
        variables: {
          updateQuickbooksHeaderFileMappingInput: {
            quickbooksHeaderFileMappingUpdateInput: {
              name,
              headerType: type,
              uuid,
            },
          },
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '20px',
          textAlign: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <Typography variant="h5">{createOrEdit} header</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Name</Typography>
          <TextField
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Type</Typography>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value as HeaderFileMappingType)}
            size="small"
            sx={{ width: '200px' }}
          >
            {Object.values(HeaderFileMappingType).map((itrType) => (
              <MenuItem value={itrType} key={type}>
                {sentenceCase(itrType)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          sx={{ ml: 'auto', mr: 'auto' }}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
};

export default CreateOrEditQuickbooksHeader;
