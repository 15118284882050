import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import styled from 'styled-components';
import { ORDER_PAGE_MARK_AS_COMPLETE_MODAL_BUTTON_TEST_ID } from '../../../../constants';
import { getMarkAsTestIds } from '../../../../utils';
import { StopType } from '../../../domains/orders/components/order-form/forms/stop-type';
import {
  PickupOrDelivery,
  StopStatus,
  useCompleteStopV2Mutation,
  useMarkOrderAsCompleteForceMutation,
} from '../../../generated/graphql';
import {
  MarkOrderAsReadyToInvoiceFormValues,
  useMarkOrderAsReadyToInvoiceForm,
} from '../../form/stops/use-mark-order-as-ready-to-invoice-form';
import useMe from '../../react-hooks/use-me';
import { DatePickerInput } from '../forms/date-picker-input';

const StyledDatePicker = styled(DatePicker)`
  '.rmdp-container.completed-at-date-picker-container' {
    width: 100%;
  }
`;

type MarkOrderAsReadyToInvoiceDialogProps = {
  handleClose: () => void;
  open: boolean;
  stops: {
    uuid: string;
    routeDate: Date | undefined;
    pickupOrDelivery: PickupOrDelivery | undefined;
    addressName: string | undefined;
    status: StopStatus;
    completedAt: Date | undefined;
    stopType?: StopType;
  }[];
  orderUuid: string;
  saveOrder?: () => Promise<boolean>;
  setShowMarkedAsReadySuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMarkedAsReadyError: React.Dispatch<React.SetStateAction<boolean>>;
  updateStopCompletedAtInState?: (idx: number, stopValue: Date) => void;
  updateStopSigneeInState?: (idx: number, stopValue: string) => void;
  markAsRefused?: boolean;
};

export const MarkOrderAsReadyToInvoiceDialog = ({
  handleClose,
  open,
  stops,
  orderUuid,
  saveOrder,
  setShowMarkedAsReadyError,
  setShowMarkedAsReadySuccess,
  updateStopCompletedAtInState,
  updateStopSigneeInState,
  markAsRefused,
}: MarkOrderAsReadyToInvoiceDialogProps) => {
  const theme = useTheme();
  const { companyConfiguration } = useMe();
  const requirePODPhotoAndName = companyConfiguration?.requirePODPhotoAndName;
  const requirePODPhotoAndNameForPickups =
    companyConfiguration?.requirePODPhotoAndNameForPickups ??
    requirePODPhotoAndName;
  const [
    markOrderAsCompleteForce,
    { loading: markOrderAsCompleteForceLoading },
  ] = useMarkOrderAsCompleteForceMutation();
  const [completeStop, { loading: completeStopLoading }] =
    useCompleteStopV2Mutation();

  const requirePODPhotoAndNameOverall =
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true ||
      stops.every((stop) => stop.stopType === StopType.Delivery));

  const {
    form: {
      control,
      reset,
      formState: { errors },
      handleSubmit,
      watch,
      setError,
    },
    fieldArray,
  } = useMarkOrderAsReadyToInvoiceForm({
    context: {
      requirePODPhotoAndName: requirePODPhotoAndNameOverall,
    },
  });
  const stopsArr = watch('stops');

  useEffect(() => {
    if (!open) {
      return;
    }
    reset();
    stops.forEach((s) => {
      fieldArray.append({
        addressName: s.addressName,
        podSigneeName: undefined,
        pickupOrDelivery: s.stopType as string as PickupOrDelivery,
        completedDate: s.completedAt ?? s.routeDate,
        uuid: s.uuid,
        status: s.status,
        stopType: s.stopType,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit: SubmitHandler<MarkOrderAsReadyToInvoiceFormValues> = async (
    data,
  ) => {
    try {
      let isValid = true;
      const { refusedBy, refusedDate } = data;

      if (markAsRefused === true) {
        if (isEmpty(refusedBy)) {
          setError(`refusedBy`, {
            message: 'Refused by is required.',
          });
          isValid = false;
        }
        if (isNil(refusedDate)) {
          setError('refusedDate', {
            message: 'Refused date is required.',
          });
        }
      } else {
        stopsArr?.forEach((stop, index) => {
          const podSigneeNameAndDateRequired =
            (requirePODPhotoAndName === true &&
              stop.stopType === StopType.Delivery) ||
            (requirePODPhotoAndNameForPickups === true &&
              stop.stopType === StopType.Pickup);
          if (
            isNil(stop.podSigneeName) &&
            stop.status !== StopStatus.Completed &&
            podSigneeNameAndDateRequired
          ) {
            setError(`stops.${index}.podSigneeName`, {
              message: 'POD signee name is required.',
            });
            isValid = false;
          }
          if (
            isNil(stop.completedDate) &&
            stop.status !== StopStatus.Completed &&
            podSigneeNameAndDateRequired
          ) {
            setError(`stops.${index}.completedDate`, {
              message: 'Completion date is required.',
            });
            isValid = false;
          }
        });
      }

      if (!isValid) {
        return;
      }

      const saveOrderSuccess = !isNil(saveOrder) ? await saveOrder() : true;

      if (saveOrderSuccess) {
        await Promise.all(
          data.stops?.map(async (stop, idx) => {
            const podSigneeName = stop.podSigneeName ?? refusedBy;
            const completedDate = stop.completedDate ?? refusedDate;
            if (!isNil(updateStopCompletedAtInState) && !isNil(completedDate)) {
              updateStopCompletedAtInState(idx, completedDate);
            }
            if (!isNil(updateStopSigneeInState) && !isNil(podSigneeName)) {
              updateStopSigneeInState(idx, podSigneeName);
            }
            // then complete the stop
            await completeStop({
              variables: {
                completeStopInput: {
                  stopUuid: stop.uuid,
                  sentFromDriverMobileApplication: false,
                  proofOfDeliverySignee: podSigneeName,
                  completedAt: completedDate,
                },
              },
            });
          }),
        );

        const res = await markOrderAsCompleteForce({
          variables: {
            markOrderAsCompleteForceInput: {
              uuid: orderUuid,
              refuseOrderInput: !isNil(refusedDate)
                ? {
                    uuid: orderUuid,
                    refusedBy: refusedBy ?? '',
                    refusedDate,
                  }
                : null,
            },
          },
        });
        if (!isNil(res.data?.markOrderAsCompleteForce)) {
          setShowMarkedAsReadySuccess(true);
        } else {
          setShowMarkedAsReadyError(true);
        }
        handleClose();
      } else {
        setShowMarkedAsReadyError(true);
      }
    } catch (e) {
      const message = `Error saving stops: ${e}`;
      console.error(message);
    }
  };

  // Controlled inputs
  const watchFieldArray = watch('stops');
  const controlledFields = fieldArray.fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: { maxHeight: 525, maxWidth: 650, minHeight: 350 },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Mark order as {markAsRefused === true ? 'refused' : 'complete'}
        </Box>
      </DialogTitle>
      <DialogContent>
        {markAsRefused !== true &&
          controlledFields.map((item, index) => {
            const routeDate = stops[index]?.routeDate;

            const showPodSigneeNameAndDateForm =
              (requirePODPhotoAndName === true &&
                item.stopType === StopType.Delivery) ||
              (requirePODPhotoAndNameForPickups === true &&
                item.stopType === StopType.Pickup) ||
              item.stopType === StopType.PartnerCarrierPickup ||
              item.stopType === StopType.PartnerCarrierDropoff;

            if (!showPodSigneeNameAndDateForm) {
              return null;
            }
            const {
              markAsCompleteSigneeInputTestId,
              markAsCompleteCompletedAtInputTestId,
            } = getMarkAsTestIds({
              stopType: item.stopType ?? StopType.None,
            });

            return (
              <Stack
                key={item.id}
                spacing={2}
                sx={{ marginTop: '5px', marginBottom: '25px' }}
              >
                <Typography>
                  {item.stopType === StopType.PartnerCarrierPickup ||
                  item.stopType === StopType.PartnerCarrierDropoff ? (
                    <>
                      Complete {sentenceCase(item?.stopType ?? '')}
                      <b>{item.addressName}</b> (optional)
                    </>
                  ) : (
                    <>
                      Complete {sentenceCase(item?.stopType ?? '')} to{' '}
                      <b>{item.addressName}</b>
                    </>
                  )}
                </Typography>
                <Controller
                  name={`stops.${index}.podSigneeName`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      name={`stops.[${index}].podSigneeName`}
                      size="small"
                      label="Full Signee Name"
                      value={value}
                      required={
                        item.stopType !== StopType.PartnerCarrierPickup &&
                        item.stopType !== StopType.PartnerCarrierDropoff
                      }
                      onChange={onChange}
                      error={!isNil(errors.stops?.[index]?.podSigneeName)}
                      helperText={errors.stops?.[index]?.podSigneeName?.message}
                      sx={{ width: '50%' }}
                      inputProps={{
                        'data-testid': markAsCompleteSigneeInputTestId,
                      }}
                    />
                  )}
                />
                <Controller
                  name={`stops.${index}.completedDate`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Stack direction="row" spacing={2}>
                      <StyledDatePicker
                        title="Date Completed"
                        containerClassName="completed-at-date-picker-container"
                        className="completed-at-date-picker"
                        onChange={(newDate) => {
                          onChange(
                            (newDate as DateObject)?.isValid
                              ? (newDate as DateObject).toDate().toISOString()
                              : '',
                          );
                        }}
                        value={value}
                        containerStyle={{ width: '35%' }}
                        plugins={[
                          <TimePicker
                            key="time-picker"
                            position="right"
                            hideSeconds
                            format="HH:mm"
                          />,
                        ]}
                        calendarPosition="right"
                        hideOnScroll
                        style={{
                          width: '100%',
                          fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                          fontSize: '1rem',
                          color: 'rgba(0, 0, 0, 0.87)',
                          borderColor: !isNil(
                            errors.stops?.[index]?.completedDate,
                          )
                            ? '#B00020'
                            : 'rgba(0, 0, 0, 0.23)',
                        }}
                        render={
                          <DatePickerInput
                            placeholder="Completed Date"
                            dataTestId={markAsCompleteCompletedAtInputTestId}
                          />
                        }
                        name="Date Completed"
                        format="MM/DD/YYYY HH:mm"
                      />
                      {!isNil(errors.stops?.[index]?.completedDate) && (
                        <FormHelperText sx={{ color: '#B00020' }}>
                          {errors.stops?.[index]?.completedDate?.message}
                        </FormHelperText>
                      )}
                      {!isNil(routeDate) && typeof value !== 'object' && (
                        <Button
                          onClick={() => {
                            onChange(dayjs(routeDate).startOf('day').toDate());
                          }}
                        >
                          Use Route Date
                        </Button>
                      )}
                    </Stack>
                  )}
                />
              </Stack>
            );
          })}
        {markAsRefused === true && (
          <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
            <Controller
              name="refusedBy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="refusedBy"
                  size="small"
                  label="Refused by"
                  value={value}
                  required
                  onChange={onChange}
                  error={!isNil(errors.refusedBy)}
                  helperText={errors.refusedBy?.message}
                  sx={{ width: '50%' }}
                />
              )}
            />
            <Stack>
              <Controller
                name="refusedDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledDatePicker
                    title="Date Refused"
                    onChange={(newDate) => {
                      onChange(
                        (newDate as DateObject)?.isValid
                          ? (newDate as DateObject).toDate().toISOString()
                          : '',
                      );
                    }}
                    value={value}
                    plugins={[
                      <TimePicker
                        key="time-picker"
                        position="right"
                        hideSeconds
                        format="HH:mm"
                      />,
                    ]}
                    containerStyle={{ width: '35%' }}
                    calendarPosition="right"
                    hideOnScroll
                    style={{
                      width: '100%',
                      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                      fontSize: '1rem',
                      color: 'rgba(0, 0, 0, 0.87)',
                      borderColor: !isNil(errors.refusedDate)
                        ? '#B00020'
                        : 'rgba(0, 0, 0, 0.23)',
                    }}
                    render={<DatePickerInput placeholder="Refused Date" />}
                    name="Date Refused"
                    format="MM/DD/YYYY HH:mm"
                  />
                )}
              />
              {!isNil(errors.refusedDate) && (
                <FormHelperText sx={{ color: '#B00020' }}>
                  {errors.refusedDate?.message}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions
        sx={{ p: theme.spacing(2), justifyContent: 'space-between' }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={markOrderAsCompleteForceLoading || completeStopLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          data-testid={ORDER_PAGE_MARK_AS_COMPLETE_MODAL_BUTTON_TEST_ID}
        >
          Mark order as {markAsRefused === true ? 'refused' : 'complete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
