import { Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil, filterNotNilOrEmpty } from 'shared/array';
import { shallow } from 'zustand/shallow';
import { getStopMarkAsTestIds } from '../../../../../../../../utils';
import { MarkOrderAsReadyToInvoiceDialog } from '../../../../../../../common/components/modals/mark-order-as-ready-to-invoice-dialog';
import MarkStopAsArrivedDialog from '../../../../../../../common/components/modals/mark-stop-as-arrived-dialog';
import { MarkStopAsAttemptedDialog } from '../../../../../../../common/components/modals/mark-stop-as-attempted.dialog';
import { MarkStopAsCompletedDialog } from '../../../../../../../common/components/modals/mark-stop-as-completed-dialog';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import {
  AppointmentCallStatus,
  LegDocument,
  LegQuery,
  StopStatus,
  useBulkChangeCallStatusMutation,
  useDeleteSlotsFromRouteMutation,
  useMarkAttemptedStopAsCompleteMutation,
  useMarkStopAsFailedV2Mutation,
  useMarkStopAsIncompleteMutation,
  useDeleteStopAttemptMutation,
} from '../../../../../../../generated/graphql';
import useGlobalStore from '../../../../../../../layouts/dashboard/global-store';
import useOrderFormStore from '../../../../../order-form-store';
import ConfirmMarkIncompleteModal from '../../../../standard/components/standard-order/modals/confirm-mark-incomplete-modal';
import { StopType } from '../../../forms/stop-type';
import { OrderFormValues } from '../../../forms/types';
import {
  getCannotCompleteOrder,
  getPickupOrDelivery,
} from '../../../forms/utils';
import { useLoadOrderForm } from '../../../hooks/use-load-order-form';
import {
  stopCanBeCompleted,
  stopCanBeMarkedAsArrived,
  stopCanBeMarkedIncomplete,
} from '../../../utils';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../../constants';
import { formatStopAttemptTime } from './utils';

type StopMarkAsMenuProps = {
  buttonRef: HTMLButtonElement | null;
  setShowContextMenu: Dispatch<SetStateAction<boolean>>;
  showContextMenu: boolean;
  idx: number;
  legData?: LegQuery;
  saveOrder: () => Promise<boolean>;
  setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
};

const StopMarkAsMenu = ({
  buttonRef,
  setShowContextMenu,
  showContextMenu,
  idx,
  legData,
  saveOrder,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: StopMarkAsMenuProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );

  const { fetchData } = useLoadOrderForm();
  const { control } = useFormContext<OrderFormValues>();
  const [showMarkStopAsIncompleteModal, setShowMarkStopAsIncompleteModal] =
    useState(false);
  const orderUuid = useWatch({
    control,
    name: 'uuid',
  });
  const [showMarkStopAsCompletedDialog, setShowMarkStopAsCompletedDialog] =
    useState(false);
  const [showMarkStopAsArrivedDialog, setShowMarkStopAsArrivedDialog] =
    useState(false);
  const [showMarkAsReadyToInvoiceDialog, setShowMarkAsReadyToInvoiceDialog] =
    useState(false);
  const [showMarkStopAsFailedDialog, setShowMarkStopAsFailedDialog] =
    useState(false);

  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore((state) => [
    state.setSuccessMessage,
    state.setShowSuccessMessage,
    state.setErrorMessage,
    state.setShowErrorMessage,
  ]);

  const [
    setShowMarkStopAsArrivedSuccessMessage,
    setShowMarkStopAsArrivedErrorMessage,
  ] = useOrderFormStore(
    (state) => [
      state.setShowMarkStopAsArrivedSuccessMessage,
      state.setShowMarkStopAsArrivedErrorMessage,
    ],
    shallow,
  );

  const ref = useRef<HTMLUListElement>(null);
  const shipperBillOfLadingNumber = useWatch({
    control,
    name: 'shipperBillOfLadingNumber',
  });
  const stopValues = useWatch({ control, name: `stops.${idx}` });
  const otherStopValues = useWatch({
    control,
    name: `stops.${idx === INBOUND_STOP_IDX ? OUTBOUND_STOP_IDX : INBOUND_STOP_IDX}`,
  });
  const stops = useWatch({
    control,
    name: 'stops',
  });
  const [updateOrderCallStatus] = useBulkChangeCallStatusMutation();
  const [markAsFailed] = useMarkStopAsFailedV2Mutation({
    refetchQueries: [LegDocument],
  });
  const [markStopAsIncomplete] = useMarkStopAsIncompleteMutation();
  const [removeSlotsFromRoute] = useDeleteSlotsFromRouteMutation();
  const [deleteStopAttempt, { loading: deleteStopAttemptLoading }] =
    useDeleteStopAttemptMutation({
      refetchQueries: [LegDocument],
    });
  const [markAttemptedStopAsComplete] =
    useMarkAttemptedStopAsCompleteMutation();

  const { companyConfiguration, companyTimezone } = useMe();
  const requirePODPhotoAndName = companyConfiguration?.requirePODPhotoAndName;
  const requirePODPhotoAndNameForPickups =
    companyConfiguration?.requirePODPhotoAndNameForPickups ??
    requirePODPhotoAndName;

  const requirePODPhotoAndNameOverall =
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true ||
      (stops?.every((stop) => stop.stopType === StopType.Delivery) ?? false) ===
        true);

  const { stopMarkAsMenuMarkCompletedOptionTestId } = getStopMarkAsTestIds({
    stopIdx: idx,
  });

  const handleMarkStopAsFailed = async (notes: string) => {
    if (!isNil(stopValues)) {
      const res = await markAsFailed({
        variables: {
          markStopAsFailedV2Input: {
            notes,
            uuid: stopValues?.uuid,
          },
        },
      });
      if (!isNil(res.errors)) {
        setErrorMessage('Error marking stop as attempted');
        setShowErrorMessage(true);
        return;
      }
      fetchData({ uuid: orderUuid });
    }
    setShowMarkStopAsFailedDialog(false);
  };

  const handleDeleteStopAttempt = async (uuid: string) => {
    try {
      const response = await deleteStopAttempt({
        variables: {
          deleteStopAttemptInput: {
            uuid,
          },
        },
      });
      if (
        response?.data?.deleteStopAttempt?.__typename ===
        'DeleteStopAttemptSuccessOutput'
      ) {
        // Refetch the order form to ensure that statuses and other relevant data is accurately displayed
        fetchData({ uuid: orderUuid });
        setSuccessMessage('Successfully removed stop attempt');
        setShowSuccessMessage(true);
      } else {
        setErrorMessage('Error removing stop attempt');
        setShowErrorMessage(true);
      }
    } catch (e) {
      setErrorMessage('Error removing stop attempt');
      setShowErrorMessage(true);
    }
  };

  const updateCallStatus = async (callStatus: AppointmentCallStatus) => {
    if (!isNil(stopValues)) {
      const res = await updateOrderCallStatus({
        variables: {
          changeCallStatusArgs: {
            uuids: [stopValues?.uuid],
            callStatus,
          },
        },
      });
      if (!isNil(res.errors)) {
        setErrorMessage('Error updating call status');
        return;
      }
      fetchData({ uuid: orderUuid });
    }
  };

  const unassignStop = async () => {
    const routeSlotUuid = stopValues?.routeSlotUuid;
    const routeUuid = stopValues?.routeUuid;
    if (!isNil(routeSlotUuid) && !isNil(routeUuid)) {
      const res = await removeSlotsFromRoute({
        variables: {
          deleteSlotsFromRouteInput: {
            routeSlotUuids: [routeSlotUuid],
            routeUuid,
          },
        },
      });
      if (!isNil(res.errors)) {
        setErrorMessage('Error unassigning stop');
        return;
      }
      if (
        !isNil(res.data?.deleteSlotsFromRoute) &&
        (res.data?.deleteSlotsFromRoute ?? 0) > 0 &&
        !isNil(stopValues)
      ) {
        const markIncompleteRes = await markStopAsIncomplete({
          variables: {
            uuid: stopValues?.uuid,
          },
        });
        if (!isNil(markIncompleteRes.errors)) {
          setErrorMessage('Error unassigning stop');
          return;
        }
      }
    }
    fetchData({ uuid: orderUuid });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isNil(ref.current) &&
        buttonRef?.contains(event.target as Node) === false
      ) {
        setShowContextMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canBeUnassigned = !isNil(stopValues?.routeUuid);
  const canBeMarkedAsAttempted = !isNil(stopValues?.routeUuid);
  const canBeMarkedAsArrived = stopCanBeMarkedAsArrived({
    stopType: stopValues?.stopType,
    routeUuid: stopValues?.routeUuid,
    stopStatus: stopValues?.status as StopStatus,
  });
  const canBeCompleted = stopCanBeCompleted({
    stopType: stopValues?.stopType,
    shipperBillOfLadingNumber,
    stopStatus: stopValues?.status as StopStatus,
  });
  const canBeIncompleted = stopCanBeMarkedIncomplete({
    stopStatus: stopValues?.status as StopStatus,
  });

  const lastAttemptedStop = useMemo(
    () =>
      legData?.leg?.previousStopAttempts
        ?.slice()
        ?.filter((a) => !isNil(a.completedAt))
        ?.sort((a, b) => a.completedAt.localeCompare(b.completedAt))[0],
    [legData],
  );

  const stopAttemptMenuItemObjects = filterNotNilOrEmpty(
    legData?.leg?.previousStopAttempts ?? [],
  ).map((attempt) => {
    const copy = `Remove attempt at ${formatStopAttemptTime({
      completedAt: attempt.completedAt,
      companyTimezone,
    })}`;
    return (
      <MenuItem
        key={attempt.uuid}
        onClick={() => handleDeleteStopAttempt(attempt.uuid)}
        disabled={deleteStopAttemptLoading}
      >
        {copy}
      </MenuItem>
    );
  });

  let markCompleteTooltip = '';
  if (!canBeCompleted) {
    if (stopValues?.status === StopStatus.Completed) {
      markCompleteTooltip = 'Stop is already completed';
    } else if (isNil(shipperBillOfLadingNumber)) {
      // TODO: Use HAWB instead of shipper bill of lading number
      markCompleteTooltip =
        'Shipper bill of lading number must be filled in and saved';
    } else {
      markCompleteTooltip =
        'Pickup / delivery must be assigned to a route to be completed';
    }
  }

  let markIncompleteTooltip = '';
  if (!canBeIncompleted) {
    if (stopValues?.status !== StopStatus.Completed) {
      markIncompleteTooltip = 'Stop must be completed to be marked incomplete';
    }
  }

  const getMarkAsCompleteTooltipText = () => {
    if (stopValues?.status === StopStatus.Completed)
      return 'Order has been completed';
    if (lastAttemptedStop?.canMarkAttemptComplete !== true)
      return 'This attempt cannot be marked as complete because the order has been assigned to a route';
    return '';
  };

  return (
    <>
      <MarkStopAsAttemptedDialog
        handleClose={() => {
          setShowMarkStopAsFailedDialog(false);
          fetchData({ uuid: orderUuid });
          setShowContextMenu(false);
        }}
        open={showMarkStopAsFailedDialog}
        handleMarkStopAsFailed={handleMarkStopAsFailed}
      />
      {!isNil(stopValues) && (
        <MarkStopAsCompletedDialog
          handleClose={(refetch: boolean) => {
            setShowMarkStopAsCompletedDialog(false);
            if (refetch) {
              fetchData({ uuid: orderUuid });
            }
            setShowContextMenu(false);
          }}
          open={showMarkStopAsCompletedDialog}
          stopUuids={[stopValues?.uuid]}
          routeDate={stopValues?.routeDate ?? undefined}
          saveOrder={saveOrder}
          setShowMarkStopAsCompletedSuccessMessage={() => null}
          setShowMarkStopAsCompletedErrorMessage={() => null}
          allStopsAreDeliveries={stopValues.stopType === StopType.Delivery}
          allStopsArePartnerStops={
            stopValues.stopType === StopType.PartnerCarrierPickup ||
            stopValues.stopType === StopType.PartnerCarrierDropoff
          }
          idx={idx}
        />
      )}
      {!isNil(stopValues) && (
        <MarkStopAsArrivedDialog
          handleClose={(refetch: boolean) => {
            setShowMarkStopAsArrivedDialog(false);
            if (refetch) {
              fetchData({ uuid: orderUuid });
            }
            setShowContextMenu(false);
          }}
          open={showMarkStopAsArrivedDialog}
          stopUuids={[stopValues?.uuid]}
          routeDate={stopValues?.routeDate ?? undefined}
          saveOrder={saveOrder}
          setShowMarkStopAsArrivedSuccessMessage={
            setShowMarkStopAsArrivedSuccessMessage
          }
          setShowMarkStopAsArrivedErrorMessage={
            setShowMarkStopAsArrivedErrorMessage
          }
        />
      )}
      {!isNil(stopValues) && !isNil(orderUuid) && (
        <MarkOrderAsReadyToInvoiceDialog
          handleClose={() => {
            setShowMarkAsReadyToInvoiceDialog(false);
          }}
          open={showMarkAsReadyToInvoiceDialog}
          saveOrder={saveOrder}
          stops={filterNotNil(
            (stops ?? []).map((stop) => {
              if (
                stop.stopType !== StopType.Pickup &&
                stop.stopType !== StopType.Delivery
              ) {
                return null;
              }
              return {
                uuid: stop.uuid,
                pickupOrDelivery: getPickupOrDelivery(stop.stopType),
                addressName: stop.address?.name ?? '',
                routeDate: stop.routeDate ?? undefined,
                status: stop.status as StopStatus,
                completedAt: stop.completedAt ?? undefined,
                stopType: stop.stopType,
              };
            }),
          )}
          orderUuid={orderUuid}
          setShowMarkedAsReadyError={() => null}
          setShowMarkedAsReadySuccess={() => {
            fetchData({ uuid: orderUuid });
          }}
        />
      )}
      {!isNil(stopValues) && !isNil(orderUuid) && (
        <ConfirmMarkIncompleteModal
          saveOrder={saveOrder}
          onClose={async () => {
            setShowMarkStopAsIncompleteModal(false);
            fetchData({ uuid: orderUuid });
            setShowContextMenu(false);
          }}
          open={showMarkStopAsIncompleteModal}
          stopUuid={stopValues?.uuid}
        />
      )}
      <Menu
        anchorEl={buttonRef}
        open={showContextMenu}
        onClose={() => setShowContextMenu(false)}
      >
        <Tooltip
          title={
            canBeUnassigned
              ? ''
              : 'Stop must be assigned to a route to be unassigned from route'
          }
        >
          <span>
            <MenuItem disabled={!canBeUnassigned} onClick={unassignStop}>
              Unassign from route
            </MenuItem>
          </span>
        </Tooltip>
        {stopAttemptMenuItemObjects}
        <Divider />
        <Tooltip
          title={
            canBeMarkedAsAttempted
              ? ''
              : 'Stop must be assigned to a route to be marked as attempted'
          }
        >
          <span>
            <MenuItem
              disabled={!canBeMarkedAsAttempted}
              onClick={() => setShowMarkStopAsFailedDialog(true)}
            >
              Mark as attempted
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={
            // eslint-disable-next-line no-nested-ternary
            canBeMarkedAsArrived
              ? ''
              : isNil(stopValues?.routeUuid)
                ? 'Stop must be assigned to a route to be marked as arrived'
                : 'Cannot mark a completed stop as arrived'
          }
        >
          <span>
            <MenuItem
              disabled={!canBeMarkedAsArrived}
              onClick={() => setShowMarkStopAsArrivedDialog(true)}
            >
              Mark as arrived
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={markCompleteTooltip}>
          <span>
            <MenuItem
              disabled={!canBeCompleted}
              data-testid={stopMarkAsMenuMarkCompletedOptionTestId}
              onClick={() => {
                const otherStopCompleted =
                  otherStopValues?.stopType === StopType.None ||
                  otherStopValues?.status === StopStatus.Completed;

                // If the other stop is completed, the order would be also completed, so we need to check all stops.
                // Otherwise, we only need to check the current stop.
                const cannotCompleteOrderMessage = getCannotCompleteOrder({
                  stops: otherStopCompleted ? stops : [stopValues],
                  terminalsEnabled,
                  ffRequireTransferAddress,
                });
                if (!isNil(cannotCompleteOrderMessage)) {
                  setCannotCompleteOrderModalMessage(
                    cannotCompleteOrderMessage,
                  );
                  setCannotCompleteOrderModalOpen(true);
                  return;
                }

                if (
                  requirePODPhotoAndNameOverall &&
                  (stops ?? []).some(
                    (s, i) =>
                      idx !== i &&
                      !isNil(s.completedAt) &&
                      isNil(s.proofOfDeliverySignee) &&
                      (s.stopType === StopType.Pickup ||
                        s.stopType === StopType.Delivery),
                  )
                ) {
                  setShowMarkAsReadyToInvoiceDialog(true);
                } else {
                  setShowMarkStopAsCompletedDialog(true);
                }
              }}
            >
              Mark completed
            </MenuItem>
          </span>
        </Tooltip>
        {lastAttemptedStop && (
          <Tooltip title={getMarkAsCompleteTooltipText()}>
            <span>
              <MenuItem
                disabled={lastAttemptedStop?.canMarkAttemptComplete !== true}
                onClick={async () => {
                  await markAttemptedStopAsComplete({
                    variables: {
                      stopUuid: lastAttemptedStop?.uuid,
                    },
                  });
                  fetchData({ uuid: orderUuid });
                }}
              >
                Mark last attempt as complete
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <Tooltip title={canBeIncompleted ? '' : markIncompleteTooltip}>
          <span>
            <MenuItem
              disabled={!canBeIncompleted}
              onClick={() => {
                setShowMarkStopAsIncompleteModal(true);
              }}
            >
              Mark incomplete
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        <MenuItem
          onClick={() => {
            updateCallStatus(AppointmentCallStatus.Confirmed);
            setShowContextMenu(false);
          }}
        >
          Confirm appointment
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateCallStatus(AppointmentCallStatus.Rejected);
            setShowContextMenu(false);
          }}
        >
          Request appointment reschedule
        </MenuItem>
      </Menu>
    </>
  );
};

export default StopMarkAsMenu;
