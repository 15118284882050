import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { forwardRef } from 'react';

const MapLegendRow = forwardRef(
  (
    {
      color,
      driverName,
      isSelected = false,
      preferRouteName,
      routeName,
    }: {
      color: string;
      driverName: string | undefined;
      preferRouteName: boolean;
      isSelected: boolean;
      routeName: string;
    },
    ref,
  ) => {
    const label = isNil(driverName) || preferRouteName ? routeName : driverName;
    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '2px',
          paddingRight: '10px',
          position: 'relative',
          margin: '2px',
        }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: color,
            position: 'absolute',
            marginLeft: '5px',
          }}
        />
        <Typography
          color="black"
          sx={{
            fontWeight: isSelected ? 'bold' : 'normal',
            wordWrap: 'break-word',
            marginLeft: '20px',
            fontSize: '14px',
          }}
        >
          {label}
        </Typography>
      </Box>
    );
  },
);

export default MapLegendRow;
