import * as yup from 'yup';
import {
  addressSchema,
  addressSchemaWithAllOptionalFields,
} from './address-schema';
import { contactPersonSchema } from './contact-person-schema';
import { customChargeSchema } from './custom-charge-schema';
import { documentSchema } from './document-schema';
import { freightChargeSchema } from './freight-charge-schema';
import { fuelChargeSchema } from './fuel-charge-schema';
import { lineHaulShipmentSchema } from './line-haul-shipment-schema';
import { orderChargesShipmentSchema } from './order-charges-shipment-schema';
import { orderCommentSchema } from './order-comment-schema';
import { packageSchema } from './package-schema';
import { recurringOrderFrequencySchema } from './recurring-order-frequency-schema';
import {
  getOrderSchema,
  stopDriverMapSchema,
  stopSchema,
} from './use-order-form';

export enum PageMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum Units {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export type RecurringOrderFrequencyValues = yup.InferType<
  typeof recurringOrderFrequencySchema
>;

export type CustomChargeValues = yup.InferType<typeof customChargeSchema>;

export type FuelChargeValues = yup.InferType<typeof fuelChargeSchema>;

export type FreightChargeValues = yup.InferType<typeof freightChargeSchema>;

export type DocumentValues = yup.InferType<typeof documentSchema>;

export type OrderFormValues = yup.InferType<ReturnType<typeof getOrderSchema>>;

export type StopValues = yup.InferType<typeof stopSchema>;

export type PackageValues = yup.InferType<typeof packageSchema>;

export type ContactPersonValues = yup.InferType<typeof contactPersonSchema>;

export type AddressValues = yup.InferType<typeof addressSchema>;

export type AddressOptionalValues = yup.InferType<
  typeof addressSchemaWithAllOptionalFields
>;

export type OrderCommentValues = yup.InferType<typeof orderCommentSchema>;

export type StopDriverMapValues = yup.InferType<typeof stopDriverMapSchema>;

export type LineHaulShipmentValues = yup.InferType<
  typeof lineHaulShipmentSchema
>;

export type OrderChargesShipmentValues = yup.InferType<
  typeof orderChargesShipmentSchema
>;
