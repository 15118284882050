import { useNavigate } from 'react-router-dom';

/**
 * Make the onClick event open in a new tab if a meta / ctrl key is pressed
 */
export const useAllowOpenInNewTab = () => {
  const navigate = useNavigate();

  const allowOpenInNewTab = (event: React.MouseEvent, url: string) => {
    if (event.ctrlKey === true || event.metaKey === true) {
      window.open(url, '_blank')?.focus();
      event.stopPropagation();
    } else if (event.altKey === true) {
      window.open(url, '_blank');
      event.stopPropagation();
    } else {
      navigate(url);
    }
  };

  return { allowOpenInNewTab };
};
