import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Stack, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import TimePickerComponent from '../../../../common/components/time-picker-component';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import {
  RouteFragment,
  RoutesDocument,
  useUpdateRouteMutation,
} from '../../../../generated/graphql';
import useFetchRoutes from '../../hooks/use-fetch-routes';
import useRouteActions from '../../hooks/use-route-actions';

const RouteStartTimeField = ({
  route,
  baseDate,
  forceEdit = false,
}: {
  route: RouteFragment;
  baseDate: Dayjs | undefined;
  forceEdit?: boolean;
}) => {
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const { fetchRoute } = useFetchRoutes();
  const { calculateRouteEta } = useRouteActions();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string | null | undefined>();
  const [updateRoute] = useUpdateRouteMutation({
    refetchQueries: [RoutesDocument],
  });

  useEffect(() => {
    setShowInput(forceEdit);
  }, [forceEdit]);

  useEffect(() => {
    if (!isNil(route.defaultStartTime)) {
      setStartTime(route.defaultStartTime);
    } else {
      setStartTime(undefined);
    }
  }, [route.defaultStartTime]);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <AccessTimeIcon color="primary" sx={{ fontSize: 17 }} />
      {(!showInput || route.locked) && (
        <Typography variant="caption">
          {!isNil(route.defaultStartTime)
            ? dayjs(route.defaultStartTime).format('HH:mm')
            : 'No time'}
        </Typography>
      )}
      {showInput && !route.locked && (
        <TimePickerComponent
          hideClearable
          minimized
          placeholder="Start Time"
          appointmentTime={startTime}
          updateAppointmentTime={(
            appointmentTime: Dayjs | null | undefined,
          ) => {
            if (!isNil(appointmentTime)) {
              const newDate = baseDate ?? dayjs();
              setStartTime(
                newDate
                  .hour(appointmentTime.hour())
                  .minute(appointmentTime.minute())
                  .second(appointmentTime.second())
                  .toISOString(),
              );
            } else {
              setStartTime(null);
            }
          }}
          onFocus={() => {
            sendDispatchUserLocationEvent({
              action: DispatchMultiplayerAction.EDITING,
              routeUuid: route.uuid,
            });
          }}
          onBlur={() => {
            updateRoute({
              variables: {
                updateRouteInput: {
                  routeUpdateInput: {
                    uuid: route.uuid,
                    defaultStartTime: isNil(startTime) ? null : startTime,
                  },
                },
              },
            }).then(() => {
              calculateRouteEta(route.uuid);
              fetchRoute(route.uuid);
            });
          }}
        />
      )}
    </Stack>
  );
};

export default memo(RouteStartTimeField);
