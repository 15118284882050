import { Check } from '@mui/icons-material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { memo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import useDispatchStore from '../../dispatch-store';
import { RouteSortType } from '../../types/routes';

const RouteSortMenuButton = () => {
  const [sortMenuRef, setSortMenuRef] = useState<HTMLElement | null>(null);
  const [sortType, sortAsc, setSortType, setSortAsc] = useDispatchStore(
    (state) => [
      state.sortType,
      state.sortAsc,
      state.setSortType,
      state.setSortAsc,
    ],
    shallow,
  );

  const handleClickSortType = (type: RouteSortType) => {
    if (type === sortType) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(false);
      setSortType(type);
    }
  };

  return (
    <>
      <Tooltip title="Sorts">
        <IconButton
          onClick={(e) => {
            setSortMenuRef(e.currentTarget);
          }}
        >
          <SwapVertIcon />
        </IconButton>
      </Tooltip>
      {!isNil(sortMenuRef) && (
        <Menu
          anchorEl={sortMenuRef}
          open={!isNil(sortMenuRef)}
          onClose={() => {
            setSortMenuRef(null);
          }}
        >
          <MenuList dense sx={{ width: 200 }}>
            {Object.values(RouteSortType).map((type) => (
              <MenuItem
                key={type}
                onClick={() => {
                  handleClickSortType(type as RouteSortType);
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack direction="row" alignItems="center">
                    <Check
                      sx={{
                        visibility: type === sortType ? undefined : 'hidden',
                        fontSize: '14px',
                        ml: 0,
                        mr: '6px',
                      }}
                    />
                    {type}
                  </Stack>
                  {type === sortType && sortAsc ? (
                    <ArrowUpwardIcon
                      sx={{
                        fontSize: '14px',
                        visibility: type === sortType ? undefined : 'hidden',
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        fontSize: '14px',
                        visibility: type === sortType ? undefined : 'hidden',
                      }}
                    />
                  )}
                </Stack>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default memo(RouteSortMenuButton);
