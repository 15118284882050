import { minutes } from '@buge/ts-units/time';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil, lowerCase, snakeCase } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { plainTimeEndOfDay, plainTimeStartOfDay } from 'shared/plain-date-time';
import { PlainTimeInputField } from '../../../common/components/plain-time-input-field';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  FulfillmentType,
  PlainTimeInput,
  ServiceFragment,
  ServicesDocument,
  SpecialDayOfWeek,
  StopType,
  UpsertServiceAvailabilityDateInput,
  useCreateServiceMutation,
  useUpdateServiceMutation,
} from '../../../generated/graphql';
import { titleCase } from '../../daily-control-center/utils';
import { StopType as OrderFormStopType } from '../../orders/components/order-form/forms/stop-type';
import {
  StopMethod,
  stopTypeOptions,
} from '../../orders/components/order-form/forms/use-order-form';
import CreateOrEdit from '../enums/create-or-edit';
import { DefaultFulfillmentTypeSelection } from './services/default-fulfillment-type-selector';
import { styles } from './styles';

const dayCategories: SpecialDayOfWeek[] = [
  SpecialDayOfWeek.Weekday,
  SpecialDayOfWeek.Saturday,
  SpecialDayOfWeek.Sunday,
  SpecialDayOfWeek.Holiday,
];

const DefaultStopTypeSelection = ({
  method,
  defaultStopType,
  setDefaultStopType,
  defaultIsLineHaul,
}: {
  method: StopMethod;
  defaultStopType: StopType | null;
  setDefaultStopType: Dispatch<SetStateAction<StopType | null>>;
  defaultIsLineHaul: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography sx={{ fontWeight: 'bold' }}>
        Default {lowerCase(method)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'right',
        }}
      >
        <RadioGroup
          value={defaultStopType}
          onChange={(e) => {
            setDefaultStopType(e.target.value as StopType);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {stopTypeOptions(method).map((option) => {
              const orderFormStopType =
                Object.keys(OrderFormStopType)[
                  Object.values(OrderFormStopType).indexOf(option)
                ];
              // eslint-disable-next-line react/jsx-no-useless-fragment
              if (isNil(orderFormStopType)) return <></>;
              const stopTypeOption =
                StopType[orderFormStopType as keyof typeof StopType];
              const noneStopTypeDisabled = defaultIsLineHaul;
              if (stopTypeOption === StopType.None && noneStopTypeDisabled) {
                return (
                  <Tooltip
                    key={stopTypeOption}
                    title="Deselect line haul to set stop to None"
                    placement="top"
                  >
                    <FormControlLabel
                      disabled
                      checked={stopTypeOption === defaultStopType}
                      value={stopTypeOption}
                      control={<Radio />}
                      label={sentenceCase(stopTypeOption)}
                    />
                  </Tooltip>
                );
              }
              return (
                <FormControlLabel
                  key={stopTypeOption}
                  checked={stopTypeOption === defaultStopType}
                  value={stopTypeOption}
                  control={<Radio />}
                  label={sentenceCase(stopTypeOption)}
                />
              );
            })}
          </Box>
        </RadioGroup>
        <Button onClick={() => setDefaultStopType(null)}>Clear</Button>
      </Box>
    </Box>
  );
};

// By default, all dates and all times are selected.
const defaultServiceAvailabilityDates: PartialUpsertServiceAvailabilityDateInput[] =
  dayCategories.map((dayCategory) => ({
    dayCategory,
    start: null,
    end: null,
    enabled: true,
  }));

type PartialUpsertServiceAvailabilityDateInput = Omit<
  UpsertServiceAvailabilityDateInput,
  'start' | 'end'
> & {
  start: PlainTimeInput | null;
  end: PlainTimeInput | null;
};

const CreateOrEditService = ({
  createOrEdit,
  open,
  setOpen,
  selectedService,
}: {
  createOrEdit: CreateOrEdit;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedService: ServiceFragment | null;
}) => {
  const [name, setName] = useState('');
  const [timeInMinutes, setTimeInMinutes] = useState<number | null>(null);
  const [shouldUseInRouteOptimization, setShouldUseInRouteOptimization] =
    useState(false);
  const [shouldMarkOrdersAsSpecial, setShouldMarkOrdersAsSpecial] =
    useState(false);
  const [defaultStartNow, setDefaultStartNow] = useState(false);
  const [defaultApptStart, setDefaultApptStart] = useState<Date | null>(null);
  const [defaultApptEnd, setDefaultApptEnd] = useState<Date | null>(null);
  const [defaultInboundStopType, setDefaultInboundStopType] =
    useState<StopType | null>(null);
  const [defaultOutboundStopType, setDefaultOutboundStopType] =
    useState<StopType | null>(null);
  const [defaultPickupPaddingMinutes, setDefaultPickupPaddingMinutes] =
    useState<number | null>(null);
  const [defaultDeliveryPaddingMinutes, setDefaultDeliveryPaddingMinutes] =
    useState<number | null>(null);
  const [defaultTotalDurationMinutes, setDefaultTotalDurationMinutes] =
    useState<number | null>(null);
  const [defaultIsLineHaul, setDefaultIsLineHaul] = useState<boolean>(false);
  const [serviceAvailabilityDates, setServiceAvailabilityDates] = useState<
    PartialUpsertServiceAvailabilityDateInput[]
  >(defaultServiceAvailabilityDates);
  const [defaultFulfillmentType, setDefaultFulfillmentType] =
    useState<FulfillmentType | null>(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [createService, { loading: creating }] = useCreateServiceMutation({
    onError: ({ message }) => {
      setErrorMessage(message);
    },
    refetchQueries: [ServicesDocument],
  });
  const [updateService, { loading: updating }] = useUpdateServiceMutation({
    onError: ({ message }) => {
      setErrorMessage(message);
    },
    refetchQueries: [ServicesDocument],
  });
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { companyConfiguration, companyTimezone } = useMe();
  useEffect(() => {
    dayjs.tz.setDefault(companyTimezone);
  }, [companyTimezone]);
  const ffCourierv1 = useFeatureFlag(FeatureFlag.FF_COURIER_V1);

  useEffect(() => {
    if (!isNil(selectedService) && open) {
      setName(sentenceCase(selectedService.name));
      setTimeInMinutes(selectedService.timeInMinutes ?? null);
      setShouldUseInRouteOptimization(
        selectedService.useApptInRouteOptimization,
      );
      setShouldMarkOrdersAsSpecial(selectedService.markOrdersAsSpecial);
      setDefaultStartNow(selectedService.defaultStartNow);
      setDefaultApptStart(selectedService.defaultAppointmentStartTime);
      setDefaultApptEnd(selectedService.defaultAppointmentEndTime);
      setDefaultInboundStopType(selectedService.defaultInboundStopType ?? null);
      setDefaultOutboundStopType(
        selectedService.defaultOutboundStopType ?? null,
      );
      setDefaultFulfillmentType(selectedService.defaultFulfillmentType ?? null);
      setDefaultPickupPaddingMinutes(
        selectedService.defaultPickupPadding?.in(minutes).amount ?? null,
      );
      setDefaultDeliveryPaddingMinutes(
        selectedService.defaultDeliveryPadding?.in(minutes).amount ?? null,
      );
      setDefaultTotalDurationMinutes(
        selectedService.defaultTotalDuration?.in(minutes).amount ?? null,
      );
      setDefaultIsLineHaul(selectedService.defaultIsLineHaul);
      setServiceAvailabilityDates(
        selectedService.serviceAvailabilityDates.map(
          // This omits the __typename field.
          ({ dayCategory, start, end, enabled }) => ({
            dayCategory,
            start: {
              hour: start.hour,
              minute: start.minute,
            },
            end: {
              hour: end.hour,
              minute: end.minute,
            },
            enabled,
          }),
        ),
      );
    }
  }, [selectedService, open]);

  const validateInputs = () => {
    if (isEmpty(name)) {
      setErrorMessage('Please enter a name');
      return false;
    }
    if (!isNil(timeInMinutes) && Number.isNaN(timeInMinutes)) {
      setErrorMessage('Invalid duration');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (createOrEdit === CreateOrEdit.Create) {
      await createService({
        variables: {
          input: {
            serviceCreateInput: {
              name,
              timeInMinutes,
              useApptInRouteOptimization: shouldUseInRouteOptimization,
              markOrdersAsSpecial: shouldMarkOrdersAsSpecial,
              defaultStartNow,
              defaultAppointmentStartTime:
                !isNil(defaultApptStart) && !isNil(defaultApptEnd)
                  ? defaultApptStart
                  : null,
              defaultAppointmentEndTime:
                !isNil(defaultApptStart) && !isNil(defaultApptEnd)
                  ? defaultApptEnd
                  : null,
              defaultInboundStopType: defaultInboundStopType ?? null,
              defaultOutboundStopType: defaultOutboundStopType ?? null,
              defaultFulfillmentType: defaultFulfillmentType ?? null,
              defaultPickupPadding: isNil(defaultPickupPaddingMinutes)
                ? null
                : minutes(defaultPickupPaddingMinutes),
              defaultDeliveryPadding: isNil(defaultDeliveryPaddingMinutes)
                ? null
                : minutes(defaultDeliveryPaddingMinutes),
              defaultTotalDuration: isNil(defaultTotalDurationMinutes)
                ? null
                : minutes(defaultTotalDurationMinutes),
              defaultIsLineHaul,
              serviceAvailabilityDates: serviceAvailabilityDates.map(
                ({ start, end, ...rest }) => ({
                  ...rest,
                  start: start ?? plainTimeStartOfDay(),
                  end: end ?? plainTimeEndOfDay(),
                }),
              ),
            },
          },
        },
        onCompleted: () => {
          setOpen(false);
          setErrorMessage('');
        },
      });
    }
    if (createOrEdit === CreateOrEdit.Edit && !isNil(selectedService)) {
      await updateService({
        variables: {
          input: {
            serviceUpdateInput: {
              uuid: selectedService.uuid,
              name: snakeCase(name.toUpperCase()),
              timeInMinutes,
              useApptInRouteOptimization: shouldUseInRouteOptimization,
              markOrdersAsSpecial: shouldMarkOrdersAsSpecial,
              defaultStartNow,
              defaultAppointmentStartTime:
                !isNil(defaultApptStart) && !isNil(defaultApptEnd)
                  ? defaultApptStart
                  : null,
              defaultAppointmentEndTime:
                !isNil(defaultApptStart) && !isNil(defaultApptEnd)
                  ? defaultApptEnd
                  : null,
              defaultInboundStopType: defaultInboundStopType ?? null,
              defaultOutboundStopType: defaultOutboundStopType ?? null,
              defaultFulfillmentType: defaultFulfillmentType ?? null,
              defaultPickupPadding: isNil(defaultPickupPaddingMinutes)
                ? null
                : minutes(defaultPickupPaddingMinutes),
              defaultDeliveryPadding: isNil(defaultDeliveryPaddingMinutes)
                ? null
                : minutes(defaultDeliveryPaddingMinutes),
              defaultTotalDuration: isNil(defaultTotalDurationMinutes)
                ? null
                : minutes(defaultTotalDurationMinutes),
              defaultIsLineHaul,
              serviceAvailabilityDates: filterNotNil(
                serviceAvailabilityDates.map(
                  ({ dayCategory, start, end, enabled }) =>
                    enabled || !isNil(start) || !isNil(end)
                      ? {
                          dayCategory,
                          start: start ?? plainTimeStartOfDay(),
                          end: end ?? plainTimeEndOfDay(),
                          enabled,
                        }
                      : null,
                ),
              ),
            },
          },
        },
        onCompleted: () => {
          setOpen(false);
          setErrorMessage('');
        },
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h5">{createOrEdit} a service</Typography>
        {!isEmpty(errorMessage) && (
          <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>Name</Typography>
          <TextField
            size="small"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrorMessage('');
            }}
            sx={{ width: '300px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            Time at each stop (minutes){' '}
            <Tooltip
              title={
                <Typography fontSize={14}>
                  How long each stop is expected to take, for ETA calculations
                  and route planning
                </Typography>
              }
            >
              <HelpOutlineIcon sx={{ fontSize: '18px' }} />
            </Tooltip>
          </Typography>
          <TextField
            size="small"
            value={timeInMinutes ?? ''}
            onChange={(e) => {
              setTimeInMinutes(
                e.target.value === '' ? null : parseInt(e.target.value, 10),
              );
              setErrorMessage('');
            }}
            sx={{ width: '300px' }}
            type="number"
          />
        </Box>
        {ffCourierv1 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              Total service duration (minutes){' '}
              <Tooltip
                title={
                  <Typography fontSize={14}>
                    The expected time from pickup start to delivery end
                  </Typography>
                }
              >
                <HelpOutlineIcon sx={{ fontSize: '18px' }} />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              value={defaultTotalDurationMinutes ?? ''}
              onChange={(e) => {
                setDefaultTotalDurationMinutes(parseInt(e.target.value, 10));
                setErrorMessage('');
              }}
              sx={{ width: '300px' }}
              type="number"
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>
            Use appointment in route optimization
          </Typography>
          <Checkbox
            checked={shouldUseInRouteOptimization}
            onChange={(e) => setShouldUseInRouteOptimization(e.target.checked)}
          />
        </Box>
        {ffCourierv1 && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Default start time to current time{' '}
                <Tooltip
                  title={
                    <Typography fontSize={14}>
                      If enabled, the pickup start time will be set to the
                      current time when creating an order
                    </Typography>
                  }
                >
                  <HelpOutlineIcon sx={{ fontSize: '18px' }} />
                </Tooltip>
              </Typography>
              <Checkbox
                checked={defaultStartNow}
                onChange={(e) => setDefaultStartNow(e.target.checked)}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Default pickup time padding (minutes){' '}
                <Tooltip
                  title={
                    <Typography fontSize={14}>
                      The expected time from pickup start to pickup end
                    </Typography>
                  }
                >
                  <HelpOutlineIcon sx={{ fontSize: '18px' }} />
                </Tooltip>
              </Typography>
              <TextField
                size="small"
                value={defaultPickupPaddingMinutes ?? ''}
                onChange={(e) => {
                  setDefaultPickupPaddingMinutes(parseInt(e.target.value, 10));
                  setErrorMessage('');
                }}
                sx={{ width: '300px' }}
                type="number"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Default delivery time padding (minutes){' '}
                <Tooltip
                  title={
                    <Typography fontSize={14}>
                      The delivery time from delivery start to delivery end
                    </Typography>
                  }
                >
                  <HelpOutlineIcon sx={{ fontSize: '18px' }} />
                </Tooltip>
              </Typography>
              <TextField
                size="small"
                value={defaultDeliveryPaddingMinutes ?? ''}
                onChange={(e) => {
                  setDefaultDeliveryPaddingMinutes(
                    parseInt(e.target.value, 10),
                  );
                  setErrorMessage('');
                }}
                sx={{ width: '300px' }}
                type="number"
              />
            </Box>
          </>
        )}
        <DefaultStopTypeSelection
          method={StopMethod.Inbound}
          defaultStopType={defaultInboundStopType}
          setDefaultStopType={setDefaultInboundStopType}
          defaultIsLineHaul={defaultIsLineHaul}
        />
        <DefaultStopTypeSelection
          method={StopMethod.Outbound}
          defaultStopType={defaultOutboundStopType}
          setDefaultStopType={setDefaultOutboundStopType}
          defaultIsLineHaul={defaultIsLineHaul}
        />
        {terminalsEnabled && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              Mark orders as line haul
            </Typography>
            <Checkbox
              checked={defaultIsLineHaul}
              onChange={(e) => {
                setDefaultIsLineHaul(e.target.checked);
                if (e.target.checked) {
                  if (defaultInboundStopType === StopType.None) {
                    setDefaultInboundStopType(null);
                  }
                  if (defaultOutboundStopType === StopType.None) {
                    setDefaultOutboundStopType(null);
                  }
                }
              }}
            />
          </Box>
        )}
        {companyConfiguration?.fulfillmentTypeEnabled === true && (
          <DefaultFulfillmentTypeSelection
            defaultFulfillmentType={defaultFulfillmentType}
            setDefaultFulfillmentType={setDefaultFulfillmentType}
          />
        )}
        <Box>
          <Typography fontWeight="700" mb={2}>
            Availability
          </Typography>
          <Stack gap={1}>
            <Stack direction="row" gap={2} pb={1}>
              <Typography style={{ width: '130px' }}>Day</Typography>
              <Typography>Available time</Typography>
            </Stack>
            {dayCategories.map((dayCategory) => (
              <Stack key={dayCategory} direction="row" gap={2}>
                <Stack direction="row" style={{ width: '130px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={serviceAvailabilityDates.some(
                          (date) =>
                            date.dayCategory === dayCategory && date.enabled,
                        )}
                        onChange={({ target }) => {
                          setServiceAvailabilityDates(
                            (prevServiceAvailabilityDates) => {
                              const currentElement =
                                prevServiceAvailabilityDates.find(
                                  (date) => date.dayCategory === dayCategory,
                                );
                              if (isNil(currentElement)) {
                                return [
                                  ...prevServiceAvailabilityDates,
                                  {
                                    dayCategory,
                                    start: null,
                                    end: null,
                                    enabled: target.checked,
                                  },
                                ];
                              }
                              return prevServiceAvailabilityDates.map((date) =>
                                date.dayCategory === dayCategory
                                  ? {
                                      ...currentElement,
                                      enabled: target.checked,
                                    }
                                  : date,
                              );
                            },
                          );
                        }}
                      />
                    }
                    label={`${titleCase(dayCategory)}s`}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <PlainTimeInputField
                    width="75px"
                    placeholder="HH:MM"
                    time={
                      serviceAvailabilityDates.find(
                        (date) => date.dayCategory === dayCategory,
                      )?.start
                    }
                    onChange={(newTime) => {
                      setServiceAvailabilityDates(
                        (prevServiceAvailabilityDates) => {
                          const currentElement =
                            prevServiceAvailabilityDates.find(
                              (date) => date.dayCategory === dayCategory,
                            );
                          if (isNil(currentElement)) {
                            return [
                              ...prevServiceAvailabilityDates,
                              {
                                dayCategory,
                                start: newTime,
                                end: plainTimeEndOfDay(),
                                enabled: true,
                              },
                            ];
                          }
                          return prevServiceAvailabilityDates.map((date) =>
                            date.dayCategory === dayCategory
                              ? {
                                  ...date,
                                  start: newTime,
                                }
                              : date,
                          );
                        },
                      );
                    }}
                  />
                  <Typography>–</Typography>
                  <PlainTimeInputField
                    width="75px"
                    placeholder="HH:MM"
                    time={
                      serviceAvailabilityDates.find(
                        (date) => date.dayCategory === dayCategory,
                      )?.end
                    }
                    onChange={(newTime) => {
                      setServiceAvailabilityDates(
                        (prevServiceAvailabilityDates) => {
                          const currentElement =
                            prevServiceAvailabilityDates.find(
                              (date) => date.dayCategory === dayCategory,
                            );
                          if (isNil(currentElement)) {
                            return [
                              ...prevServiceAvailabilityDates,
                              {
                                dayCategory,
                                start: plainTimeStartOfDay(),
                                end: newTime,
                                enabled: true,
                              },
                            ];
                          }
                          return prevServiceAvailabilityDates.map((date) =>
                            date.dayCategory === dayCategory
                              ? {
                                  ...date,
                                  end: newTime,
                                }
                              : date,
                          );
                        },
                      );
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button
            variant="text"
            onClick={() => setOpen(false)}
            disabled={creating || updating}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={creating || updating}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default CreateOrEditService;
