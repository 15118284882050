import { FormControl, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ShallowContactQuery } from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { OrderFormValues } from '../forms/types';
import { useOrderFormContact } from '../hooks/use-order-form-contact';

type ContactStation = Omit<
  ShallowContactQuery['contact']['contactStations'][number],
  'person'
>;

type ContactStationsFieldProps = {
  disabled: boolean;
};

/*
 * In addition to this component, contactStationId is syncronized with the selected billing party.
 * The onChange in OrderFormContactAutocompleteComponent sets the contactStationId to null
 * when the contactUuid changes
 */
const ContactStationsFieldComponent: FunctionComponent<
  ContactStationsFieldProps
> = ({ disabled }) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const { contact, loading } = useOrderFormContact();

  const billingPartyContactStationId = useWatch({
    control,
    name: 'contactStationId',
  });

  const contactStations = contact?.contactStations;
  const autocompleteOptions = useMemo<ContactStation[]>(
    () =>
      contactStations
        ?.filter((cs) => !cs.isArchived)
        .map((cs) => ({
          id: cs.id,
          name: cs.name,
          isArchived: cs.isArchived,
        })) ?? [],
    [contactStations],
  );

  return (
    <FormControl sx={{ width: '190px' }}>
      <AutocompleteFuzzy
        matchSortOptions={{ keys: ['name'] }}
        disabled={disabled || loading}
        value={
          !isNil(billingPartyContactStationId)
            ? autocompleteOptions.find(
                (cs) => cs.id === billingPartyContactStationId,
              )
            : null
        }
        options={autocompleteOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Station"
            autoComplete="off"
          />
        )}
        onChange={(_, contactStation) => {
          setValue('contactStationId', contactStation?.id ?? null);
        }}
      />
    </FormControl>
  );
};

export const ContactStationsField = React.memo(ContactStationsFieldComponent);
