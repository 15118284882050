import {
  Alert,
  Button,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { exhaustive } from 'shared/switch';
import ErrorBanner from '../../../../../common/components/error-banner';
import GeneralLedgerCodeAutocomplete from '../../../../../common/components/general-ledger-code-autocomplete';
import {
  GeneralLedgerCodeFragment,
  GeneralLedgerConfigurationDocument,
  GeneralLedgerConfigurationUpdateInput,
  useGeneralLedgerConfigurationQuery,
  useUpdateGeneralLedgerConfigurationMutation,
} from '../../../../../generated/graphql';
import CreateOrEdit from '../../../enums/create-or-edit';
import AddOrEditGeneralLedgerCodeModal from './add-or-edit-gl-code-modal';
import DeleteGLCodeModal from './delete-gl-code-modal';
import GeneralLedgerCodesTableRow from './gl-codes-table-row';

enum PresetChargeType {
  PICKUP = 'Pickup freight',
  DELIVERY = 'Delivery freight',
  RECOVERY = 'Recovery freight',
  TRANSFER = 'Transfer freight',
  ORDER_CHARGE = 'Order charge freight',
  LINE_HAUL = 'Line haul freight',
  FUEL = 'Fuel',
  ACCESSORIAL_DEFAULT = 'Accessorial default',
}

const PRESET_CHARGE_TYPES_ORDERED = [
  PresetChargeType.PICKUP,
  PresetChargeType.DELIVERY,
  PresetChargeType.RECOVERY,
  PresetChargeType.TRANSFER,
  PresetChargeType.ORDER_CHARGE,
  PresetChargeType.LINE_HAUL,
  PresetChargeType.FUEL,
  PresetChargeType.ACCESSORIAL_DEFAULT,
];

const GeneralLedger = () => {
  const [addOrEditGlCodeModalOpen, setAddOrEditGlCodeModalOpen] =
    useState(false);
  const [deleteGlCodeModalOpen, setDeleteGlCodeModalOpen] = useState(false);
  const [currentGlCode, setCurrentGlCode] =
    useState<GeneralLedgerCodeFragment>();
  const { data: generalLedgerConfiguration, loading: configurationLoading } =
    useGeneralLedgerConfigurationQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const [updateGeneralLedgerConfiguration, { loading: isSavingConfiguration }] =
    useUpdateGeneralLedgerConfigurationMutation({
      refetchQueries: [GeneralLedgerConfigurationDocument],
      // this is a graphql error as opposed to the bottom which is a backend error.
      onError: ({ message }) => setErrorMessage(message),
      onCompleted: ({
        updateGeneralLedgerConfiguration:
          updateGeneralLedgerConfigurationResult,
      }) => {
        // if there's an error there will be a message to surface.
        if (
          updateGeneralLedgerConfigurationResult.__typename ===
          'MutationErrorOutput'
        ) {
          setErrorMessage(updateGeneralLedgerConfigurationResult.message);
        }
      },
    });
  if (configurationLoading) {
    return <CircularProgress />;
  }

  const configuration = generalLedgerConfiguration?.generalLedgerConfiguration;
  if (isNil(configuration)) {
    return (
      <Stack padding={2}>
        <ErrorBanner errorMessage="Something went wrong, please contact support" />
      </Stack>
    );
  }

  // call the update mutation whenever the select changes.
  const handleChargeCodeChange = (
    chargeType: PresetChargeType,
    newCodeId: string | null,
  ): void => {
    let input: GeneralLedgerConfigurationUpdateInput = {
      id: configuration.id,
    };
    switch (chargeType) {
      case PresetChargeType.DELIVERY:
        input = {
          ...input,
          deliveryChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.PICKUP:
        input = {
          ...input,
          pickupChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.TRANSFER:
        input = {
          ...input,
          transferChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.RECOVERY:
        input = {
          ...input,
          recoveryChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.LINE_HAUL:
        input = {
          ...input,
          lineHaulChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.ORDER_CHARGE:
        input = {
          ...input,
          orderChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.FUEL:
        input = {
          ...input,
          fuelChargeCodeId: newCodeId,
        };
        break;
      case PresetChargeType.ACCESSORIAL_DEFAULT:
        input = {
          ...input,
          accessorialFallbackCodeId: newCodeId,
        };
        break;
      default:
        exhaustive(chargeType);
    }

    updateGeneralLedgerConfiguration({
      variables: {
        input,
      },
    });
  };

  const getChargeTypeCodeFromConfiguration = (chargeType: PresetChargeType) => {
    switch (chargeType) {
      case PresetChargeType.DELIVERY:
        return configuration.deliveryChargeCodeId;
      case PresetChargeType.PICKUP:
        return configuration.pickupChargeCodeId;
      case PresetChargeType.TRANSFER:
        return configuration.transferChargeCodeId;
      case PresetChargeType.RECOVERY:
        return configuration.recoveryChargeCodeId;
      case PresetChargeType.LINE_HAUL:
        return configuration.lineHaulChargeCodeId;
      case PresetChargeType.ORDER_CHARGE:
        return configuration.orderChargeCodeId;
      case PresetChargeType.FUEL:
        return configuration.fuelChargeCodeId;
      case PresetChargeType.ACCESSORIAL_DEFAULT:
        return configuration.accessorialFallbackCodeId;
      default:
        return exhaustive(chargeType);
    }
  };

  const handleClickAddGlCode = () => {
    setCurrentGlCode(undefined);
    setAddOrEditGlCodeModalOpen(true);
  };
  const handleClickEditGlCode = (glCode: GeneralLedgerCodeFragment) => {
    setCurrentGlCode(glCode);
    setAddOrEditGlCodeModalOpen(true);
  };
  const handleClickDeleteGlCode = (glCode: GeneralLedgerCodeFragment) => {
    setCurrentGlCode(glCode);
    setDeleteGlCodeModalOpen(true);
  };

  return (
    <Stack spacing={5} sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h6">General charge mappings</Typography>
            <Typography variant="caption" color={theme.palette.grey[600]}>
              {
                'Map other charges from Company > Billing > Credit types and Company > Accessorials.'
              }
            </Typography>
          </Stack>
          <Typography sx={{ fontSize: '12px', color: 'gray', float: 'right' }}>
            {isSavingConfiguration ? 'Saving...' : 'Saved'}
          </Typography>
        </Stack>

        <Grid container columnGap={5} rowGap={2}>
          {PRESET_CHARGE_TYPES_ORDERED.map((chargeType: PresetChargeType) => (
            <Grid item xs={12} sm={5} key={chargeType}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ display: 'flex' }}
                width="100%"
              >
                <Typography sx={{ minWidth: 150 }}>{chargeType}</Typography>
                <Stack sx={{ flex: 1 }}>
                  <GeneralLedgerCodeAutocomplete
                    value={
                      getChargeTypeCodeFromConfiguration(chargeType) ?? null
                    }
                    formError={null}
                    setValue={(newValue: string | null) => {
                      handleChargeCodeChange(chargeType, newValue);
                    }}
                    disabled={false}
                    size="medium"
                    showTextFieldLabel={false}
                    showEvenIfEmpty
                  />
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Stack>
        <Typography variant="h6">Codes</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Mapped charge(s)</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickAddGlCode}
                  >
                    Add code
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              {configuration.generalLedgerCodes.map((glCode) => (
                <GeneralLedgerCodesTableRow
                  key={glCode.id}
                  row={glCode}
                  onClickEdit={() => handleClickEditGlCode(glCode)}
                  onClickDelete={() => handleClickDeleteGlCode(glCode)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <AddOrEditGeneralLedgerCodeModal
        open={addOrEditGlCodeModalOpen}
        setOpen={(open: boolean) => {
          setAddOrEditGlCodeModalOpen(open);
        }}
        configurationId={configuration.id}
        createOrEdit={
          !isNil(currentGlCode) ? CreateOrEdit.Edit : CreateOrEdit.Create
        }
        glCode={currentGlCode}
      />
      <DeleteGLCodeModal
        open={deleteGlCodeModalOpen}
        setOpen={(open: boolean) => {
          setDeleteGlCodeModalOpen(open);
        }}
        glCode={currentGlCode}
      />
      {!isEmpty(errorMessage) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={4000}
          onClose={() => setErrorMessage('')}
          open={!isEmpty(errorMessage)}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
};

export default GeneralLedger;
