import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DashboardDrawerItem } from '../types';
import { SubpageListRow } from './subpage-list-row';

const SubpageList = ({ drawerItem }: { drawerItem: DashboardDrawerItem }) => {
  const { pathname } = useLocation();
  return (
    <Stack>
      {drawerItem.tabs.map((tab) => {
        const { url } = tab;
        const selected = pathname === url;
        return (
          <SubpageListRow key={url} tab={tab} url={url} selected={selected} />
        );
      })}
    </Stack>
  );
};

export default SubpageList;
