import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { filterNotNil } from 'shared/array';
import { ContactPersonEntity } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type ContactPersonFormField = Partial<
  Omit<ContactPersonEntity, '__typename'>
> & {
  uuid: string;
};

type ContactPersonValuesStoreState = ContactPersonFormField;

/**
 * An object which declares all the possible fields in a contact person, so we can iterate over them.
 *
 * The actual value of each field is meaningless
 */
export const CONTACT_PERSON_SCHEMA: {
  [k in keyof Required<ContactPersonFormField>]: null;
} = {
  createdAt: null,
  email: null,
  firstName: null,
  lastName: null,
  notes: null,
  phone: null,
  updatedAt: null,
  uuid: null,
};

const contactPersonsValuesAdapter =
  createEntityAdapter<ContactPersonValuesStoreState>({
    selectId: (contactPerson) => contactPerson.uuid,
  });

export const ContactPersonsValuesSlice = createSlice({
  name: 'ContactPersonsValues',
  initialState: contactPersonsValuesAdapter.getInitialState(),
  reducers: {
    addContactPerson: contactPersonsValuesAdapter.addOne,
    updateContactPerson: contactPersonsValuesAdapter.updateOne,
    upsertOneContactPerson: contactPersonsValuesAdapter.upsertOne,
    removeOneContactPerson: contactPersonsValuesAdapter.removeOne,
    updateContactPersons: contactPersonsValuesAdapter.updateMany,
    setAllContactPersons: contactPersonsValuesAdapter.setAll,
  },
});

export const {
  selectEntities: selectContactPersonEntities,
  selectById: selectContactPersonById,
  // Pass in a selector that returns the posts slice of state
} = contactPersonsValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormContactPersonsValues,
);

export const selectContactPersonsByIds = createSelector(
  selectContactPersonEntities,
  (state: RootState, ids: string[]) => ids,
  (entities, ids) => filterNotNil(ids.map((id) => entities[id])),
);

export const {
  addContactPerson,
  setAllContactPersons,
  removeOneContactPerson,
  updateContactPerson,
  upsertOneContactPerson,
} = ContactPersonsValuesSlice.actions;

export default ContactPersonsValuesSlice.reducer;
