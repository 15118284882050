import { isNil } from 'lodash';
import React from 'react';
import { Marker } from 'react-map-gl';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import { darkenHexColor } from '../../../../common/utils/colors';
import { RouteFragment } from '../../../../generated/graphql';
import TruckIcon from '../../../daily-control-center/map/components/truck-icon';
import useDispatchStore from '../../dispatch-store';
import { useMapRouteColor } from '../../hooks/use-map-route-color';
import { useRouteCurrentDriverLocation } from '../../hooks/use-route-current-driver-location';
import { MapRoutePathType } from '../../types/routes';
import Line from './line';
import StopMarker from './stop-marker';

const MapRoutePathComponent = ({ route }: { route: RouteFragment }) => {
  const mapRouteColor = useMapRouteColor({ routeUuid: route.uuid });
  const [mapRoutePathType, routePaths] = useDispatchStore(
    (state) => [state.mapRoutePathType, state.routePaths],
    shallow,
  );
  const { routeCurrentDriverLocationData } = useRouteCurrentDriverLocation({
    routeUuid: route.uuid,
  });

  const routePathCoordinates = routePaths.find(
    (routePath) => routePath?.route?.uuid === route.uuid,
  )?.coordinates;

  const straightLineCoordinates = filterNotNil(
    route.slots.map((slot) => {
      const address = slot.stops[0]?.address;
      const latitude = address?.latitude;
      const longitude = address?.longitude;
      if (!isNil(latitude) && !isNil(longitude)) {
        return [longitude, latitude];
      }
      return null;
    }),
  );

  const coordinates: number[][] =
    mapRoutePathType === MapRoutePathType.PATH
      ? routePathCoordinates ?? []
      : straightLineCoordinates;

  return (
    <>
      {mapRoutePathType !== MapRoutePathType.NONE && (
        <Line
          key={route.uuid}
          id={route.uuid}
          coordinates={coordinates}
          color={darkenHexColor(mapRouteColor, 0.25)}
        />
      )}
      {!isNil(routeCurrentDriverLocationData) && (
        <Marker
          longitude={routeCurrentDriverLocationData.longitude}
          latitude={routeCurrentDriverLocationData.latitude}
          anchor="bottom"
        >
          <TruckIcon color={mapRouteColor} />
        </Marker>
      )}
      {route.slots.map((slot, idx) => {
        const stop = slot.stops[0];

        if (!isNil(stop)) {
          return (
            <StopMarker
              key={stop.uuid}
              color={mapRouteColor}
              stop={stop}
              ordinal={idx + 1}
            />
          );
        }
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
      })}
    </>
  );
};

export const MapRoutePath = React.memo(MapRoutePathComponent);
