import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@mui/material';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

/**
 * - set minimum width of all cells & their header’s to fit at least  M... (using the letter M as a basis because it’s the widest letter), this should come out to be around 64px
 * - headers and their cell columns should be the same width
 * - use 8px padding for cells & headers (it’s 22px rn)
 * - overflow text ellipses (since we seem to be okay with no wrapping :relaxed:)
 * - please use spacing variables not arbitrary pixels! we want to abide by an 8px grid — ask @Elliot or @Ashank if you don't know what to use
 * - always left-align headers and cell content...
 *   ...except for numerical values, right-align! (weight, costs, counts etc...)
 * - vertical-align top everything — no more middle alignment please
 * - use same font size within the whole table
 */
const minCellWidth = '64px';
const cellPadding = '8px'; // 8px padding as per 8px grid system
const fontSize = '1rem'; // Standard font size for consistency

const Table = styled(MuiTable)({
  minWidth: minCellWidth,
});

const TableCell = styled(MuiTableCell)({
  minWidth: minCellWidth,
  padding: cellPadding,
  fontSize,
  textAlign: 'left',
  verticalAlign: 'top',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const TableCellNumeric = styled(TableCell)({
  textAlign: 'right',
});

const TableButtonCell = styled(TableCell)({
  textAlign: 'center',
  padding: 0,
});

const TableButton = styled(Button)({
  padding: cellPadding, // Apply padding to the button instead
  borderRadius: 0,
  width: 'fit-content', // Ensure button width fits content
  minWidth: '64px', // Set minimum width if needed
});

const TableHeadCell = styled(TableCell)({
  fontWeight: 'bold',
});

const TableHeadButtonCell = styled(TableHeadCell)({
  textAlign: 'center',
  padding: 0,
});

const TableRow = styled(MuiTableRow)({});

const TableHead = styled(MuiTableHead)({});

const TableBody = styled(MuiTableBody)({});

export {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableHeadCell,
  TableCellNumeric,
  TableButtonCell,
  TableHeadButtonCell,
  TableButton,
};
