import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AccessorialType } from '../../../../../generated/graphql';
import {
  AccessorialRange,
  FormMode,
  WeightBasedAccessorialFormErrors,
} from '../common';
import { AccessorialDateRangeConfigFormValues } from '../forms/use-accessorial-date-range-config-form';
import RangeBasedAccessorialRateEditor from '../range-based-accessorial-rate-editor';

const WeightBasedAccessorialRateEditor = () => {
  const [formErrors] = useState<WeightBasedAccessorialFormErrors>({});
  const { watch, setValue } =
    useFormContext<AccessorialDateRangeConfigFormValues>();
  const ranges = watch('ranges');

  return (
    <RangeBasedAccessorialRateEditor
      accessorialType={AccessorialType.Weight}
      mode={FormMode.EDIT}
      formValues={{ ranges }}
      onChangeFormValues={(newData: { ranges: AccessorialRange[] }) => {
        setValue('ranges', newData.ranges);
      }}
      formErrors={formErrors}
      validateFieldsAndSetErrors={() => {}}
    />
  );
};

export default WeightBasedAccessorialRateEditor;
