import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Stack, SxProps } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import useMe from '../../../../common/react-hooks/use-me';
import {
  LineHaulManifestForPrintFragment,
  useLineHaulManifestsForPrintLazyQuery,
} from '../../../../generated/graphql';
import GeneratedLineHaulManifestPdf from '../../../generated-documents/components/generated-line-haul-manifest-pdf';
import useLineHaulDispatchStore from '../../store/line-haul-dispatch-store';

const styles = {
  manifestModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  } as SxProps,
};

const PrintLineHaulManifestsModal = ({
  open,
  setOpen,
  manifestUuids,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  manifestUuids: string[];
}) => {
  const { companyName } = useMe();
  const [getManifestsForPrint] = useLineHaulManifestsForPrintLazyQuery();

  const [planningDate] = useLineHaulDispatchStore(
    (state) => [state.planningDate],
    shallow,
  );

  const [manifests, setManifests] = useState<
    LineHaulManifestForPrintFragment[] | null
  >(null);

  useEffect(() => {
    if (!isNil(planningDate)) {
      getManifestsForPrint({
        variables: {
          date: planningDate?.toDate(),
          uuids: manifestUuids,
        },
      }).then((res) => {
        const result = res.data?.lineHaulManifests.lineHaulManifests;
        if (!isNil(result)) {
          const sortedResult = result.sort((a, b) => {
            const indexA = manifestUuids.indexOf(a.uuid);
            const indexB = manifestUuids.indexOf(b.uuid);
            return indexA - indexB;
          });
          setManifests(sortedResult);
        }
      });
    }
  }, [manifestUuids, planningDate]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setManifests(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.manifestModal}>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          sx={{ padding: 0, float: 'right', mb: '10px', ml: 'auto' }}
        >
          <CloseIcon />
        </IconButton>
        {!isNil(manifests) && !isNil(companyName) && (
          <>
            <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
              <GeneratedLineHaulManifestPdf
                manifests={manifests}
                companyName={companyName}
                // timeZone={companyTimezone ?? 'America/Los_Angeles'}
              />
            </PDFViewer>
            <Stack
              sx={{ paddingTop: 2 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Button variant="contained">
                {
                  // consider making the filename include today's date
                }
                <PDFDownloadLink
                  document={
                    <GeneratedLineHaulManifestPdf
                      manifests={manifests}
                      companyName={companyName}
                    />
                  }
                  fileName={`manifest${
                    manifests.length === 1 ? `-${manifests[0]?.departDate}` : ''
                  }.pdf`}
                >
                  {({ loading }) =>
                    loading === true ? 'Loading...' : 'Download'
                  }
                </PDFDownloadLink>
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default PrintLineHaulManifestsModal;
