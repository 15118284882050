import { Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import PalletRouterLink from '../../../pallet-ui/links/router-link/pallet-router-link';
import useGlobalStore from '../global-store';
import { DashboardTabs } from '../types';

const SubpageListRow = React.memo(
  ({
    tab,
    url,
    selected,
  }: {
    tab: DashboardTabs;
    url: string;
    selected: boolean;
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const setOpenedOrderUuid = useGlobalStore(
      (state) => state.setCurrentOrderUuid,
    );

    const { palette } = useTheme();
    const backgroundColor = isHovered
      ? palette.airfreightBlue[60]
      : selected
        ? palette.airfreightBlue[70]
        : undefined;

    return (
      <PalletRouterLink
        to={url}
        key={url}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        underline={false}
      >
        <Stack
          direction="row"
          sx={{
            borderRadius: 1,
            backgroundColor,
            paddingLeft: '24px',
            height: '28px',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Typography
              variant="caption"
              sx={{
                cursor: 'pointer',
                color: palette.concreteGrey[10],
                opacity: selected ? 1 : 0.8,
                fontSize: '14px',
              }}
              onClick={() => {
                setOpenedOrderUuid(undefined);
              }}
            >
              {tab.name}
            </Typography>
          </Stack>
        </Stack>
      </PalletRouterLink>
    );
  },
);

export { SubpageListRow };
