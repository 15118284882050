import {
  TableCell,
  TableContainer,
  Checkbox,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Link,
  Button,
  Fade,
  SxProps,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { RecurringOrderTemplateFragment } from '../../../generated/graphql';
import {
  getFrequencyInformation,
  getTemplateStatus,
} from './recurring-order-template-utils';

const styles = {
  accessorialButton: {
    mx: 1.5,
  } as SxProps,
  disabledCell: {
    opacity: 0.7,
  },
};

type RecurringOrderTableProps = {
  selectedTemplateUuids: string[];
  setSelectedTemplateUuids: (uuids: string[]) => void;
  recurringOrderTemplatesData: Array<
    RecurringOrderTemplateFragment & { disabled?: boolean }
  >;
  loadingCreateOrders: boolean;
  searchQuery?: string | undefined;
  canWriteCompanyRecurringOrders: boolean;
  hideEdit: boolean;
  hideCheckBox: boolean;
};

const RecurringOrderTable = ({
  selectedTemplateUuids,
  setSelectedTemplateUuids,
  recurringOrderTemplatesData,
  loadingCreateOrders,
  searchQuery,
  canWriteCompanyRecurringOrders,
  hideEdit,
  hideCheckBox,
}: RecurringOrderTableProps) => {
  const [
    hoveredRecurringOrderTemplateUuid,
    setHoveredRecurringOrderTemplateUuid,
  ] = useState<string>();

  const filteredData =
    isNil(searchQuery) || searchQuery === ''
      ? recurringOrderTemplatesData
      : recurringOrderTemplatesData.filter((template) =>
          template.name.toLowerCase().includes(searchQuery.toLowerCase()),
        );

  const hasEnabledTemplate =
    !loadingCreateOrders &&
    filteredData.some(
      (template) => isNil(template.disabled) || !template.disabled,
    );

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: '16px' }} size="small">
        <TableHead>
          <TableRow>
            {!hideCheckBox && canWriteCompanyRecurringOrders && (
              <TableCell width={50}>
                <Checkbox
                  checked={recurringOrderTemplatesData.every((template) =>
                    selectedTemplateUuids.includes(template.uuid),
                  )}
                  disabled={loadingCreateOrders || !hasEnabledTemplate}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedTemplateUuids(
                        recurringOrderTemplatesData
                          .filter((template) => template.disabled !== true)
                          .map((template) => template.uuid) ?? [],
                      );
                    } else {
                      setSelectedTemplateUuids([]);
                    }
                    e.stopPropagation();
                  }}
                />
              </TableCell>
            )}
            <TableCell>Name</TableCell>
            <TableCell>HAWB</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Frequency</TableCell>
            {!hideEdit && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((template) => (
            <TableRow
              onMouseEnter={() => {
                setHoveredRecurringOrderTemplateUuid(template.uuid);
              }}
              onMouseLeave={() => {
                setHoveredRecurringOrderTemplateUuid(undefined);
              }}
              key={template.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {!hideCheckBox && canWriteCompanyRecurringOrders && (
                <TableCell>
                  <Checkbox
                    checked={selectedTemplateUuids.includes(template.uuid)}
                    disabled={template.disabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTemplateUuids([
                          ...selectedTemplateUuids,
                          template.uuid,
                        ]);
                      } else {
                        setSelectedTemplateUuids(
                          selectedTemplateUuids.filter(
                            (uuid) => uuid !== template.uuid,
                          ),
                        );
                      }
                      e.stopPropagation();
                    }}
                  />
                </TableCell>
              )}
              <TableCell
                sx={
                  template.disabled === true ? styles.disabledCell : undefined
                }
              >
                {template.name}
              </TableCell>
              <TableCell
                sx={
                  template.disabled === true ? styles.disabledCell : undefined
                }
              >
                {template.standardOrderFields.shipperBillOfLadingNumber}
              </TableCell>
              <TableCell
                sx={
                  template.disabled === true ? styles.disabledCell : undefined
                }
              >
                {template.billingPartyContact?.displayName ?? 'N/A'}
              </TableCell>
              <TableCell
                sx={
                  template.disabled === true ? styles.disabledCell : undefined
                }
              >
                {getTemplateStatus(
                  template.recurringOrderFrequency ?? undefined,
                )}
              </TableCell>
              <TableCell
                sx={
                  template.disabled === true ? styles.disabledCell : undefined
                }
              >
                {getFrequencyInformation(
                  template.recurringOrderFrequency ?? undefined,
                )}
              </TableCell>
              {!hideEdit && (
                <TableCell align="right">
                  <Fade
                    in={hoveredRecurringOrderTemplateUuid === template.uuid}
                  >
                    <Button
                      sx={styles.accessorialButton}
                      variant="contained"
                      disabled={
                        template.disabled === true ||
                        !canWriteCompanyRecurringOrders
                      }
                    >
                      <Link
                        href={`/management/recurring-orders/${template.uuid}?edit=true`}
                        sx={{ color: 'white', textDecoration: 'none' }}
                      >
                        Edit
                      </Link>
                    </Button>
                  </Fade>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecurringOrderTable;
