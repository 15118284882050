import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../../generated/graphql';
import { OrderFormValues } from '../forms/types';

const MINIMIZED_FONT_SIZE = 12;

const MawbComponent = ({
  excludeFormLabel,
  editingDisabled,
  isMinimized = false,
}: {
  excludeFormLabel?: boolean;
  editingDisabled: boolean;
  isMinimized?: boolean;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<OrderFormValues>();
  const { useAllCaps } = useMe();
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  return (
    <Controller
      name="masterAirwayBillOfLadingNumber"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <TextField
            sx={{ width: '100%' }}
            label={excludeFormLabel === true ? undefined : 'MAWB'}
            disabled={editingDisabled || !canWriteOrders}
            size="small"
            value={value ?? ''}
            inputProps={{
              style: {
                ...(isMinimized
                  ? {
                      fontSize: MINIMIZED_FONT_SIZE,
                      height: 10,
                    }
                  : {}),
                ...(useAllCaps ? { textTransform: 'uppercase' } : {}),
              },
            }}
            onChange={onChange}
            error={!isNil(errors.masterAirwayBillOfLadingNumber)}
          />
          {!isNil(errors.masterAirwayBillOfLadingNumber) && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              {errors.masterAirwayBillOfLadingNumber?.message?.toString() ?? ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default React.memo(MawbComponent);
