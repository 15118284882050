/* eslint-disable no-param-reassign */
import { isNil } from 'lodash';
import { findWithIndex } from 'shared/array';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  OrderStatus,
  OutstandingOrderFragmentFragment,
} from '../../generated/graphql';
import { OrderFormValues } from '../orders/components/order-form/forms/types';
import { InvoiceShipment, OrderToSendToBilling } from './types/types';

export type BillingReviewOrder = OutstandingOrderFragmentFragment & {
  queriedProratedOrdersWith: OutstandingOrderFragmentFragment[] | null;
};

type BillingReviewState = {
  searchedOrders: BillingReviewOrder[];
  outstandingOrdersInPage: BillingReviewOrder[];
  outstandingOrderUuidsInReview: string[];
  openedOutstandingOrderUuid: string | undefined;
  orderUuidsToSave: string[];
  selectedOriginTerminalUuid: string | undefined;
  selectedDestinationTerminalUuid: string | undefined;
  outstandingOrderUuidInForm: string | undefined;
  initialFormData: OrderFormValues | undefined;
  openedOrderDataLoading: boolean;
  ordersListDataLoading: boolean;
  isSelectingOrdersToSendInReviewModal: boolean;
  selectedShipmentsToSend: InvoiceShipment[];
  selectedOrdersToSendShipments: OrderToSendToBilling[];
  consolidateOrderFlowIsOpen: boolean;
  isSubmitting: boolean;
  paginationInfo: {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    startCursor: string | null;
    endCursor: string | null;
    lastPaginationArgs: {
      first?: number | null | undefined;
      after?: string | null | undefined;
      last?: number | null | undefined;
      before?: string | null | undefined;
    };
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
type BillingReviewActions = {
  setOutstandingOrdersInPage: (
    orders: OutstandingOrderFragmentFragment[] | undefined,
  ) => void;
  setOutstandingOrder: (order: OutstandingOrderFragmentFragment) => void;
  setOrderStatusByUuid: (uuid: string | undefined, status: OrderStatus) => void;
  setOutstandingOrderUuidsInReview: (uuids: string[] | undefined) => void;
  setOpenedOutstandingOrderUuid: (uuid: string | undefined) => void;
  setSearchedOrder: (order: OutstandingOrderFragmentFragment) => void;
  setSearchedOrders: (
    orders: OutstandingOrderFragmentFragment[] | undefined,
  ) => void;
  removeSearchedOrdersByUuids: (uuids: string[]) => void;
  setSearchedOrderStatus: (orderUuid: string, status: OrderStatus) => void;
  setConsolidateOrderFlowIsOpen: (open: boolean) => void;
  setIsSelectingOrdersToSendInReviewModal: (isSelecting: boolean) => void;
  addShipmentsToSend: (shipments: InvoiceShipment[]) => void;
  removeOrdersToSend: (orderUuids: string[]) => void;
  clearShipmentsToSend: () => void;
  addOrdersToSendShipments: (orders: OrderToSendToBilling[]) => void;
  removeOrdersToSendShipments: (orderUuids: string[]) => void;
  clearOrdersToSendShipments: () => void;
  addOrderUuidToSave: (uuid: string) => void;
  removeOrderUuidToSave: (uuid: string) => void;
  clearOrderUuidsToSave: () => void;
  setSelectedOriginTerminalUuid: (terminalUuid: string | undefined) => void;
  setSelectedDestinationTerminalUuid: (
    terminalUuid: string | undefined,
  ) => void;
  setOutstandingOrderUuidInForm: (uuid: string | undefined) => void;
  setQueriedProratedOrderOnOutstandingOrder: (
    queriedProratedOrder: OutstandingOrderFragmentFragment,
    outstandingOrderUuid: string,
  ) => void;
  setQueriedProratedOrderOnSearchedOrder: (
    queriedProratedOrder: OutstandingOrderFragmentFragment,
    searchedOrderUuid: string,
  ) => void;
  getInitialFormData: () => OrderFormValues | undefined;
  setInitialFormData: (data: OrderFormValues) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setHasNextPage: (hasNextPage: boolean) => void;
  setHasPrevPage: (hasPrevPage: boolean) => void;
  setStartCursor: (startCursor: string | null) => void;
  setEndCursor: (endCursor: string | null) => void;
  setPaginationInfo: (paginationInfo: {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    startCursor: string | null;
    endCursor: string | null;
    lastPaginationArgs: {
      first?: number | null | undefined;
      after?: string | null | undefined;
      last?: number | null | undefined;
      before?: string | null | undefined;
    };
  }) => void;
  setOpenedOrderDataLoading: (loading: boolean) => void;
  setOrdersListDataLoading: (loading: boolean) => void;
  resetStore: () => void;
};

const initialState: BillingReviewState = {
  searchedOrders: [],
  outstandingOrdersInPage: [],
  outstandingOrderUuidsInReview: [],
  openedOutstandingOrderUuid: undefined,
  orderUuidsToSave: [],
  selectedOriginTerminalUuid: undefined,
  selectedDestinationTerminalUuid: undefined,
  outstandingOrderUuidInForm: undefined,
  initialFormData: undefined,
  openedOrderDataLoading: false,
  ordersListDataLoading: false,
  isSelectingOrdersToSendInReviewModal: false,
  selectedShipmentsToSend: [],
  selectedOrdersToSendShipments: [],
  consolidateOrderFlowIsOpen: false,
  isSubmitting: false,
  paginationInfo: {
    hasNextPage: false,
    hasPrevPage: false,
    startCursor: null,
    endCursor: null,
    lastPaginationArgs: {},
  },
};

const useBillingReviewStore = create(
  immer<BillingReviewState & BillingReviewActions>((set, get) => ({
    ...initialState,
    setOutstandingOrdersInPage: (
      orders: OutstandingOrderFragmentFragment[] | undefined,
    ) =>
      set((state) => {
        state.outstandingOrdersInPage =
          orders?.map((o) => ({
            ...o,
            queriedProratedOrdersWith: null,
          })) ?? [];
      }),
    setSearchedOrders: (
      orders: OutstandingOrderFragmentFragment[] | undefined,
    ) =>
      set((state) => {
        state.searchedOrders =
          orders?.map((o) => ({
            ...o,
            queriedProratedOrdersWith: null,
          })) ?? [];
      }),
    setSearchedOrder: (order: OutstandingOrderFragmentFragment) =>
      set((state) => {
        const { searchedOrders } = state;
        if (!isNil(searchedOrders)) {
          const orderIndex = searchedOrders.findIndex(
            (o) => o.uuid === order.uuid,
          );
          if (orderIndex >= 0) {
            searchedOrders[orderIndex] = {
              ...order,
              queriedProratedOrdersWith: null,
            };
          } else {
            searchedOrders.push({
              ...order,
              queriedProratedOrdersWith: null,
            });
          }
        }
      }),
    setSearchedOrderStatus: (orderUuid: string, status: OrderStatus) =>
      set((state) => {
        const { searchedOrders } = state;
        const order = searchedOrders.find((o) => o.uuid === orderUuid);
        if (!isNil(order)) {
          order.status = status;
        }
      }),
    removeSearchedOrdersByUuids: (uuids: string[]) =>
      set((state) => {
        state.searchedOrders = state.searchedOrders.filter(
          (o) => !uuids.includes(o.uuid),
        );
      }),
    setOutstandingOrderUuidsInReview: (uuids: string[] | undefined) =>
      set((state) => {
        state.outstandingOrderUuidsInReview = uuids ?? [];
      }),
    setOutstandingOrder: async (order: OutstandingOrderFragmentFragment) => {
      set((state) => {
        const { outstandingOrdersInPage } = state;
        if (!isNil(outstandingOrdersInPage)) {
          const orderIndex = outstandingOrdersInPage.findIndex(
            (o) => o.uuid === order.uuid,
          );
          if (orderIndex >= 0) {
            outstandingOrdersInPage[orderIndex] = {
              ...order,
              queriedProratedOrdersWith: null,
            };
          }
        }
      });
    },
    setQueriedProratedOrderOnOutstandingOrder: (
      queriedProratedOrder: OutstandingOrderFragmentFragment,
      outstandingOrderUuid: string,
    ) => {
      set((state) => {
        const { outstandingOrdersInPage } = state;
        const outstandingOrder = outstandingOrdersInPage.find(
          (o) => o.uuid === outstandingOrderUuid,
        );
        if (!isNil(outstandingOrder)) {
          if (isNil(outstandingOrder.queriedProratedOrdersWith)) {
            outstandingOrder.queriedProratedOrdersWith = [queriedProratedOrder];
          } else {
            const idx = outstandingOrder.queriedProratedOrdersWith.findIndex(
              (o) => o.uuid === queriedProratedOrder.uuid,
            );
            if (idx >= 0) {
              outstandingOrder.queriedProratedOrdersWith[idx] =
                queriedProratedOrder;
            } else {
              outstandingOrder.queriedProratedOrdersWith.push(
                queriedProratedOrder,
              );
            }
          }
        }
      });
    },
    setQueriedProratedOrderOnSearchedOrder: (
      queriedProratedOrder: OutstandingOrderFragmentFragment,
      searchedOrderUuid: string,
    ) => {
      set((state) => {
        const { searchedOrders } = state;
        const searchedOrder = searchedOrders.find(
          (o) => o.uuid === searchedOrderUuid,
        );
        if (!isNil(searchedOrder)) {
          if (isNil(searchedOrder.queriedProratedOrdersWith)) {
            searchedOrder.queriedProratedOrdersWith = [queriedProratedOrder];
          } else {
            const idx = searchedOrder.queriedProratedOrdersWith.findIndex(
              (o) => o.uuid === queriedProratedOrder.uuid,
            );
            if (idx >= 0) {
              searchedOrder.queriedProratedOrdersWith[idx] =
                queriedProratedOrder;
            } else {
              searchedOrder.queriedProratedOrdersWith.push(
                queriedProratedOrder,
              );
            }
          }
        }
      });
    },
    // this isn't great
    setOrderStatusByUuid: async (
      uuid: string | undefined,
      status: OrderStatus,
    ) => {
      set((state) => {
        const { outstandingOrdersInPage, searchedOrders } = state;

        if (!isNil(uuid)) {
          const outstandingOrder = findWithIndex(
            outstandingOrdersInPage,
            (o) => o.uuid === uuid,
          );
          if (!isNil(outstandingOrder)) {
            outstandingOrder.value.status = status;
          }

          const outstandingOrderContainingProratedOrder = findWithIndex(
            outstandingOrdersInPage,
            (o) =>
              o.queriedProratedOrdersWith?.some((p) => p?.uuid === uuid) ??
              false,
          );
          if (!isNil(outstandingOrderContainingProratedOrder)) {
            const proratedOrder = findWithIndex(
              outstandingOrderContainingProratedOrder.value
                .queriedProratedOrdersWith ?? [],
              (o) => o.uuid === uuid,
            );
            if (!isNil(proratedOrder)) {
              proratedOrder.value.status = status;
            }
          }

          const searchedOrder = findWithIndex(
            searchedOrders,
            (o) => o.uuid === uuid,
          );
          if (!isNil(searchedOrder)) {
            searchedOrder.value.status = status;
          }

          const searchedProratedOrder = findWithIndex(
            searchedOrders.flatMap((o) => o.queriedProratedOrdersWith ?? []),
            (o) => o.uuid === uuid,
          );
          if (!isNil(searchedProratedOrder)) {
            searchedProratedOrder.value.status = status;
          }

          if (status !== OrderStatus.Finalized) {
            state.removeOrdersToSend([uuid]);
            state.removeOrdersToSendShipments([uuid]);
          }
        }
      });
    },
    setOpenedOutstandingOrderUuid: (uuid: string | undefined) =>
      set((state) => {
        state.openedOutstandingOrderUuid = uuid;
      }),
    setConsolidateOrderFlowIsOpen: (open: boolean) =>
      set((state) => {
        state.consolidateOrderFlowIsOpen = open;
      }),
    setIsSelectingOrdersToSendInReviewModal: (isSelecting: boolean) =>
      set((state) => {
        state.isSelectingOrdersToSendInReviewModal = isSelecting;
      }),
    addShipmentsToSend: (shipments: InvoiceShipment[]) =>
      set((state) => {
        state.selectedShipmentsToSend =
          state.selectedShipmentsToSend.concat(shipments);
      }),
    removeOrdersToSend: (orderUuids: string[]) =>
      set((state) => {
        state.selectedShipmentsToSend = state.selectedShipmentsToSend.filter(
          (s) => !orderUuids.includes(s.orderUuid),
        );
      }),
    clearShipmentsToSend: () =>
      set((state) => {
        state.selectedShipmentsToSend = [];
      }),
    addOrdersToSendShipments: (orders: OrderToSendToBilling[]) =>
      set((state) => {
        state.selectedOrdersToSendShipments =
          state.selectedOrdersToSendShipments.concat(orders);
      }),
    removeOrdersToSendShipments: (orderUuids: string[]) =>
      set((state) => {
        state.selectedOrdersToSendShipments =
          state.selectedOrdersToSendShipments.filter(
            (order) => !orderUuids.includes(order.uuid),
          );
      }),
    clearOrdersToSendShipments: () =>
      set((state) => {
        state.selectedOrdersToSendShipments = [];
      }),
    addOrderUuidToSave: (uuid: string) =>
      set((state) => {
        state.orderUuidsToSave = [...state.orderUuidsToSave, uuid];
      }),
    removeOrderUuidToSave: (uuid: string) =>
      set((state) => {
        state.orderUuidsToSave = state.orderUuidsToSave.filter(
          (uuidToSave) => uuidToSave !== uuid,
        );
      }),
    clearOrderUuidsToSave: () =>
      set((state) => {
        state.orderUuidsToSave = [];
      }),
    setSelectedOriginTerminalUuid: (terminalUuid: string | undefined) =>
      set((state) => {
        state.selectedOriginTerminalUuid = terminalUuid;
      }),
    setSelectedDestinationTerminalUuid: (terminalUuid: string | undefined) =>
      set((state) => {
        state.selectedDestinationTerminalUuid = terminalUuid;
      }),
    setOutstandingOrderUuidInForm: (uuid: string | undefined) =>
      set((state) => {
        state.outstandingOrderUuidInForm = uuid;
      }),
    getInitialFormData: () => {
      const { initialFormData } = get();
      return initialFormData;
    },
    setInitialFormData: (data: OrderFormValues) => {
      set((state) => {
        state.initialFormData = data;
      });
    },
    setOpenedOrderDataLoading: (loaded: boolean) =>
      set((state) => {
        state.openedOrderDataLoading = loaded;
      }),
    setOrdersListDataLoading: (loaded: boolean) =>
      set((state) => {
        state.ordersListDataLoading = loaded;
      }),
    setIsSubmitting: (isSubmitting: boolean) =>
      set((state) => {
        state.isSubmitting = isSubmitting;
      }),
    setHasNextPage: (hasNextPage: boolean) =>
      set((state) => {
        state.paginationInfo.hasNextPage = hasNextPage;
      }),
    setHasPrevPage: (hasPrevPage: boolean) =>
      set((state) => {
        state.paginationInfo.hasPrevPage = hasPrevPage;
      }),
    setStartCursor: (startCursor: string | null) =>
      set((state) => {
        state.paginationInfo.startCursor = startCursor;
      }),
    setEndCursor: (endCursor: string | null) =>
      set((state) => {
        state.paginationInfo.endCursor = endCursor;
      }),
    setPaginationInfo: (paginationInfo: {
      hasNextPage: boolean;
      hasPrevPage: boolean;
      startCursor: string | null;
      endCursor: string | null;
      lastPaginationArgs: {
        first?: number | null | undefined;
        after?: string | null | undefined;
        last?: number | null | undefined;
        before?: string | null | undefined;
      };
    }) =>
      set((state) => {
        state.paginationInfo = paginationInfo;
      }),
    resetStore: () =>
      set((state) => {
        const newState = { ...state };
        return Object.assign(newState, initialState);
      }),
  })),
);

export default useBillingReviewStore;
