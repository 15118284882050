import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import useBusinessDivisions from '../../../common/react-hooks/use-business-divisions';
import useContacts from '../../../common/react-hooks/use-contacts';
import useDrivers from '../../../common/react-hooks/use-drivers';
import useLineHaulLanes from '../../../common/react-hooks/use-line-haul-lanes';
import useServices from '../../../common/react-hooks/use-services';
import useTags from '../../../common/react-hooks/use-tags';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  FilterOperator,
  FormattedOrderFragment,
  OrderSource,
  PickupOrDelivery,
  StandardStopType,
} from '../../../generated/graphql';
import {
  BooleanFilter,
  DateSelectFilter,
  MultiSelectFilter,
  SingleSelectFilter,
  TextFilter,
} from '../../ag-grid/filter-components';
import {
  InboundMethodOptions,
  OrderFilterField,
  OrderStatusFilterOption,
  OutboundMethodOptions,
} from '../components/enums/order-filters';
import { Order } from '../types';

/** Do not use this directly, @see useOrdersTableFilterColumns instead */
const RAW_ORDERS_TABLE_FILTER_COLUMNS_WITHOUT_OPTIONS: ColDef<FormattedOrderFragment>[] =
  [
    // Hidden columns used for filters.
    {
      field: OrderFilterField.ACTIVE_TERMINAL,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: OrderFilterField.ADDRESS_TYPE,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: Object.values(StandardStopType)
          .filter((val) => val !== StandardStopType.Dummy)
          .map((val) => {
            return { actualValue: val, displayValue: sentenceCase(val) };
          }),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        shouldShowSearch: false,
      },
    },
    {
      field: OrderFilterField.APPOINTMENT_REQUIRED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.APPOINTMENT_SCHEDULED,
      headerName: 'Appointment Confirmed',
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.ASSIGNED_TO_A_ROUTE,
      headerName: 'Assigned to a Route',
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.BUSINESS_DIVISION,
      headerName: 'Business Division',
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          params.value.displayName,
      },
    },
    {
      field: OrderFilterField.CHARGES_FINALIZED,
      headerName: 'Charges Finalized',
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.CITY,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
    {
      field: OrderFilterField.CUSTOMER,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          params.value.displayName,
      },
    },
    {
      field: OrderFilterField.DRIVER,
      hide: true,
      filter: MultiSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideCombinationMode: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          `${params.value.firstName} ${params.value.lastName}`,
      },
    },
    {
      field: OrderFilterField.INBOUND_DRIVER,
      hide: true,
      filter: MultiSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        defaultFieldLevelFilterOperator: FilterOperator.Or,
        hideCombinationMode: true,
        hideFilterType: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          `${params.value.firstName} ${params.value.lastName}`,
      },
    },
    {
      field: OrderFilterField.OUTBOUND_DRIVER,
      hide: true,
      filter: MultiSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        defaultFieldLevelFilterOperator: FilterOperator.Or,
        hideCombinationMode: true,
        hideFilterType: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          `${params.value.firstName} ${params.value.lastName}`,
      },
    },
    {
      field: OrderFilterField.DATE_ATTEMPTED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.DATE_COMPLETED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.DATE_CREATED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.DATE_RECEIVED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        supressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.INBOUND_CITY,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
    {
      field: OrderFilterField.OUTBOUND_CITY,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
    {
      field: OrderFilterField.DATE_SCHEDULED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.DEADLINE_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.EXPECTED_INBOUND_ARRIVAL_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.HAS_CONTACT_INFORMATION,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.INBOUND_SERVICE_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_CANCELLED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_REFUSED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_COLLECT_ON_DELIVERY,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_HAZMAT,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_IN_BOND,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_PICKED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_OSD,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.IS_SPECIAL,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.LINE_HAUL_LANE,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          sentenceCase(params.value.displayValue),
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.lineHaulLane?.name ?? 'N/A';
      },
    },
    {
      field: OrderFilterField.ON_HAND,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.ON_INVOICE,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.ORDER_SOURCE,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: Object.values(OrderSource).map((val) => {
          return { actualValue: val, displayValue: sentenceCase(val) };
        }),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        shouldShowSearch: false,
      },
    },
    {
      field: OrderFilterField.ORDER_STATUS,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: Object.values(OrderStatusFilterOption).map((val) => {
          return { actualValue: val, displayValue: sentenceCase(val) };
        }),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.INBOUND_NOT_ARRIVED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.INVOICE_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.REQUIRES_ROUTING,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.REQUIRES_RECOVERY,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.SERVICE_LEVEL,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          sentenceCase(params.value.displayValue),
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.service?.name;
      },
    },
    {
      field: OrderFilterField.ORDER_TYPE,
      hide: true,
      headerName: 'Order type',
      filter: MultiSelectFilter,
      filterParams: {
        values: [PickupOrDelivery.Delivery, PickupOrDelivery.Pickup].map(
          (val) => {
            return { actualValue: val, displayValue: sentenceCase(val) };
          },
        ),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        shouldShowSearch: false,
      },
    },
    {
      field: OrderFilterField.INBOUND_METHOD,
      hide: true,
      headerName: 'Inbound Method',
      filter: SingleSelectFilter,
      filterParams: {
        values: Object.values(InboundMethodOptions).map((val) => {
          return {
            actualValue: val,
            displayValue: sentenceCase(val.toString()),
          };
        }),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        shouldShowSearch: false,
      },
    },
    {
      field: OrderFilterField.OUTBOUND_METHOD,
      hide: true,
      headerName: 'Outbound Method',
      filter: SingleSelectFilter,
      filterParams: {
        values: Object.values(OutboundMethodOptions).map((val) => {
          return {
            actualValue: val,
            displayValue: sentenceCase(val.toString()),
          };
        }),
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        shouldShowSearch: false,
      },
    },
    {
      field: OrderFilterField.PAPERWORK_COMPLETED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.PRIMARY_APPOINTMENT_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.TRANSFER_PENDING,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.TAG,
      hide: true,
      filter: MultiSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        hideCombinationMode: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: OrderFilterField.TERMINAL,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<Order>) => params.value.uuid,
        valueFormatter: (params: ValueFormatterParams<Order>) =>
          sentenceCase(params.value.displayValue),
      },
    },
    {
      field: OrderFilterField.ZIP_CODE,
      hide: true,
      filter: TextFilter,
      filterParams: {},
    },
    {
      field: OrderFilterField.SERVICE_DATE,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().toDate(),
          endDate: getCurrentTimeDefaultTimezone().toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: OrderFilterField.HAWB,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['equals'],
      },
    },
    {
      field: OrderFilterField.MAWB,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['equals'],
      },
    },
    {
      field: OrderFilterField.UN_NUMBER,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
    {
      field: OrderFilterField.IT_NUMBER,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
    {
      field: OrderFilterField.ORDER_NAME,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
    {
      field: OrderFilterField.OUTBOUND_TERMINAL,
      hide: true,
      filter: TextFilter,
      filterParams: {
        maxNumConditions: 1,
        numberOfInputs: 0,
        buttons: ['apply'],
        filterOptions: ['contains'],
      },
    },
  ].sort((a, b) => a.field.toString().localeCompare(b.field.toString()));

export const useOrdersTableFilterColumns = () => {
  const { tags } = useTags();
  const { businessDivisions } = useBusinessDivisions();
  const { drivers } = useDrivers();
  const { services } = useServices();
  const { lanes } = useLineHaulLanes({
    includeInactiveTerminals: true,
  });
  const { terminals } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { contacts } = useContacts();
  const filterColumnsWithOptions = useMemo(() => {
    return RAW_ORDERS_TABLE_FILTER_COLUMNS_WITHOUT_OPTIONS.map((columnDef) => {
      if (columnDef.field === OrderFilterField.CUSTOMER) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values:
              contacts.map((contact) => ({
                actualValue: contact.uuid,
                displayValue: `${contact.displayName}${
                  !isNil(contact.contactReferenceNumber)
                    ? ` (${contact.contactReferenceNumber})`
                    : ''
                }`,
              })) ?? [],
          },
        };
      }
      if (columnDef.field === OrderFilterField.BUSINESS_DIVISION) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values:
              businessDivisions?.map((businessDivision) => ({
                actualValue: businessDivision.uuid,
                displayValue: businessDivision.name,
              })) ?? [],
          },
        };
      }
      if (
        columnDef.field === OrderFilterField.DRIVER ||
        columnDef.field === OrderFilterField.INBOUND_DRIVER ||
        columnDef.field === OrderFilterField.OUTBOUND_DRIVER
      ) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values: drivers.map((driver) => ({
              actualValue: driver.uuid,
              displayValue: `${driver.firstName} ${driver.lastName}`,
            })),
          },
        };
      }
      if (columnDef.field === OrderFilterField.SERVICE_LEVEL) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values:
              services.map((service) => ({
                actualValue: service.uuid,
                displayValue: service.name,
              })) ?? [],
          },
        };
      }
      if (columnDef.field === OrderFilterField.TAG) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values:
              tags.map((tag) => ({
                actualValue: tag.uuid,
                displayValue: tag.value,
              })) ?? [],
          },
        };
      }
      if (
        columnDef.field === OrderFilterField.TERMINAL ||
        columnDef.field === OrderFilterField.ACTIVE_TERMINAL
      ) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values: terminals.map((terminal) => ({
              actualValue: terminal.uuid,
              displayValue: terminal.code,
            })),
          },
        };
      }
      if (columnDef.field === OrderFilterField.LINE_HAUL_LANE) {
        return {
          ...columnDef,
          filterParams: {
            ...columnDef.filterParams,
            values:
              lanes?.map((lane) => ({
                actualValue: lane.uuid,
                displayValue: `${lane.name} ${
                  lane.isActive ? '' : '(archived)'
                }`,
              })) ?? [],
          },
        };
      }
      return columnDef;
    });
  }, [tags, businessDivisions, drivers, services, lanes, contacts, terminals]);
  return filterColumnsWithOptions;
};
