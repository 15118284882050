import { useCallback } from 'react';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import { OrderTableField } from '../../../../generated/graphql';
import {
  terminalOnlyOrderFields,
  deprecatedOrderFields,
} from '../../../orders/components/utils';

/**
 * Filter out fields that shouldn't be shown based on feature flags or company configuration.
 */
const useFilterEligibleOrderTableFields = () => {
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );
  const ffHidePaperworkCompleteColumn = useFeatureFlag(
    FeatureFlag.FF_HIDE_PAPERWORK_COMPLETE_COLUMN,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const filterEligibleOrderTableFields = useCallback(
    (field: OrderTableField) => {
      if (
        ffHidePaperworkCompleteColumn &&
        field === OrderTableField.PaperworkComplete
      ) {
        return false;
      }
      if (
        !ffInboundOutboundPaperworkColumns &&
        (field === OrderTableField.InboundPaperwork ||
          field === OrderTableField.OutboundPaperwork)
      ) {
        return false;
      }
      if (!terminalsEnabled && terminalOnlyOrderFields.includes(field)) {
        return false;
      }
      if (deprecatedOrderFields.includes(field)) {
        return false;
      }
      return true;
    },
    [
      terminalsEnabled,
      ffHidePaperworkCompleteColumn,
      ffInboundOutboundPaperworkColumns,
    ],
  );
  return filterEligibleOrderTableFields;
};

export { useFilterEligibleOrderTableFields };
