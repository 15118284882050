import { Box, FormControl, TextField, Typography, styled } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import useDimensionsInputRefs from '../../../domains/orders/hooks/use-dimensions-input-refs';
import { convertToCentimeters, convertToInches } from '../../utils/utils';

const DimensionsTextField = styled(TextField)`
  width: 75px;
  & .MuiOutlinedInput-root fieldset {
    border: none;
  }
`;
const DimensionsTextFieldLabel = styled(Box)`
  background-color: white;
  padding: 0 4px;
`;

const DimensionsInputContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  border-color: #bfbebd;
`;

interface DimensionsInputProps {
  disabled?: boolean;
  useCentimeters: boolean;
  length: number | null | undefined;
  width: number | null | undefined;
  height: number | null | undefined;
  onChangeLength: (length: number | null | undefined) => void;
  onChangeWidth: (width: number | null | undefined) => void;
  onChangeHeight: (height: number | null | undefined) => void;
  showLabel?: boolean;
  packageLengthTestId?: string;
  packageWidthTestId?: string;
  packageHeightTestId?: string;
}

const DimensionsInput = ({
  disabled,
  useCentimeters,
  length,
  width,
  height,
  onChangeLength,
  onChangeWidth,
  onChangeHeight,
  packageLengthTestId,
  packageWidthTestId,
  packageHeightTestId,
  showLabel = false,
}: DimensionsInputProps) => {
  const dimensionsInputRefs = useDimensionsInputRefs();

  const [lengthInput, setLengthInput] = useState(String(length ?? ''));
  const [widthInput, setWidthInput] = useState(String(width ?? ''));
  const [heightInput, setHeightInput] = useState(String(height ?? ''));

  useEffect(() => {
    setLengthInput(String(length ?? ''));
    setWidthInput(String(width ?? ''));
    setHeightInput(String(height ?? ''));
  }, [length, width, height]);

  useEffect(() => {
    if (useCentimeters === true) {
      const centimetersLength = convertToCentimeters(length ?? 0);
      const centimetersWidth = convertToCentimeters(width ?? 0);
      const centimetersHeight = convertToCentimeters(height ?? 0);
      onChangeLength(centimetersLength);
      onChangeWidth(centimetersWidth);
      onChangeHeight(centimetersHeight);
      setLengthInput(centimetersLength?.toFixed(2) ?? '');
      setWidthInput(centimetersWidth?.toFixed(2) ?? '');
      setHeightInput(centimetersHeight?.toFixed(2) ?? '');
    }
    if (useCentimeters === false) {
      const inchesLength = convertToInches(length ?? 0);
      const inchesWidth = convertToInches(width ?? 0);
      const inchesHeight = convertToInches(height ?? 0);
      onChangeLength(inchesLength);
      onChangeWidth(inchesWidth);
      onChangeHeight(inchesHeight);
      setLengthInput(inchesLength?.toFixed(2) ?? '');
      setWidthInput(inchesWidth?.toFixed(2) ?? '');
      setHeightInput(inchesHeight?.toFixed(2) ?? '');
    }
  }, [useCentimeters]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onInputChange: (value: string) => void,
    onValueChange: (value: number | null) => void,
  ) => {
    const newValue = e.target.value;
    const parsedFloat = parseFloat(newValue);
    onInputChange(newValue);
    if (!Number.isNaN(parsedFloat)) {
      onValueChange(parsedFloat);
    } else if (newValue === '') {
      onValueChange(null);
    }
  };

  return (
    <DimensionsInputContainer>
      <FormControl>
        <DimensionsTextField
          label={
            showLabel ? (
              <DimensionsTextFieldLabel>Length</DimensionsTextFieldLabel>
            ) : undefined
          }
          disabled={disabled}
          size="small"
          inputRef={(el) => {
            dimensionsInputRefs.current[0] = el;
          }}
          value={lengthInput}
          onChange={(e) => handleInputChange(e, setLengthInput, onChangeLength)}
          inputProps={{ 'data-testid': packageLengthTestId }}
        />
      </FormControl>
      <Typography>⨉</Typography>
      <FormControl>
        <DimensionsTextField
          label={
            showLabel ? (
              <DimensionsTextFieldLabel>Width</DimensionsTextFieldLabel>
            ) : undefined
          }
          disabled={disabled}
          size="small"
          inputRef={(el) => {
            dimensionsInputRefs.current[1] = el;
          }}
          value={widthInput}
          onChange={(e) => handleInputChange(e, setWidthInput, onChangeWidth)}
          inputProps={{ 'data-testid': packageWidthTestId }}
        />
      </FormControl>
      <Typography>⨉</Typography>
      <FormControl>
        <DimensionsTextField
          label={
            showLabel ? (
              <DimensionsTextFieldLabel>Height</DimensionsTextFieldLabel>
            ) : undefined
          }
          disabled={disabled}
          size="small"
          inputRef={(el) => {
            dimensionsInputRefs.current[2] = el;
          }}
          value={heightInput}
          onChange={(e) => handleInputChange(e, setHeightInput, onChangeHeight)}
          inputProps={{ 'data-testid': packageHeightTestId }}
        />
      </FormControl>
    </DimensionsInputContainer>
  );
};

export default DimensionsInput;
