import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Stack,
  Button,
  SxProps,
  Snackbar,
  Alert,
  Fade,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  MasterAccountFragment,
  PermissionResource,
  useMasterAccountsQuery,
} from '../../../../../generated/graphql';
import CreateMasterAccountModal from './create-master-account-modal';
import UpdateMasterAccountModal from './update-master-account-modal';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  warehouseEmployeeButton: {
    mx: 1.5,
  } as SxProps,
};

const MasterAccountsTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const { data: masterAccountsData } = useMasterAccountsQuery();
  const [createMasterAccountModalOpen, setCreateMasterAccountModalOpen] =
    useState(false);
  const [updateMasterAccountModalOpen, setUpdateMasterAccountModalOpen] =
    useState(false);
  const [hoveredMasterAccountUuid, setHoveredMasterAccountUuid] =
    useState<string>();
  const [selectedMasterAccount, setSelectedMasterAccount] = useState<
    MasterAccountFragment | undefined
  >();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const sortedMasterAccounts = useMemo(() => {
    return (masterAccountsData?.masterAccounts?.masterAccounts ?? []).sort(
      (a, b) => a.name.localeCompare(b.name),
    );
  }, [masterAccountsData]);

  return (
    <Stack
      direction="column"
      alignItems="flex-end"
      gap={2}
      sx={styles.stackView}
    >
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
        onClose={() => setSuccessSnackbarVisible(false)}
      >
        <Alert severity="success">Saved master account</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
        onClose={() => setErrorSnackbarVisible(false)}
      >
        <Alert severity="error">Error saving master account</Alert>
      </Snackbar>
      <CreateMasterAccountModal
        open={createMasterAccountModalOpen}
        setOpen={() => setCreateMasterAccountModalOpen(false)}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
      />
      <UpdateMasterAccountModal
        open={updateMasterAccountModalOpen}
        setOpen={() => setUpdateMasterAccountModalOpen(false)}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
        selectedMasterAccount={selectedMasterAccount}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                onClick={() => setCreateMasterAccountModalOpen(true)}
                disabled={!canWriteCompanyBilling}
              >
                Add master account
              </Button>
            </TableCell>
          </TableHead>
          {sortedMasterAccounts.map((masterAccount) => {
            return (
              <TableRow
                key={masterAccount.uuid}
                onMouseEnter={() => {
                  setHoveredMasterAccountUuid(masterAccount.uuid);
                }}
                onMouseLeave={() => {
                  setHoveredMasterAccountUuid(undefined);
                }}
              >
                <TableCell>{masterAccount.name}</TableCell>
                <TableCell align="right">
                  <Fade in={hoveredMasterAccountUuid === masterAccount.uuid}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedMasterAccount(masterAccount);
                        setUpdateMasterAccountModalOpen(true);
                      }}
                      disabled={!canWriteCompanyBilling}
                    >
                      Edit
                    </Button>
                  </Fade>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default MasterAccountsTable;
