// eslint-disable-next-line no-restricted-imports
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { memo } from 'react';
import useDispatchStore from '../../dispatch-store';

const RouteStopRefNumberRow = ({
  orderUuid,
  shipperBillOfLadingNumber,
  masterAirwayBillOfLadingNumber,
  refNumbers,
}: {
  orderUuid: string | undefined;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  refNumbers: string[] | null | undefined;
}) => {
  const firstRefNumber = refNumbers?.[0];

  const setOpenedOrderUuid = useDispatchStore(
    (state) => state.setOpenedOrderUuid,
  );

  const numberOfIdentifiers =
    (!isNil(firstRefNumber) ? 1 : 0) +
    (!isNil(masterAirwayBillOfLadingNumber) ? 1 : 0) +
    (!isNil(shipperBillOfLadingNumber) ? 1 : 0);

  return (
    <Grid container>
      <Grid
        item
        xs={
          // eslint-disable-next-line no-nested-ternary
          numberOfIdentifiers === 3
            ? 4
            : numberOfIdentifiers === 2 && isNil(firstRefNumber)
              ? 0
              : 6
        }
      >
        <Typography variant="caption">{firstRefNumber}</Typography>
      </Grid>
      <Grid
        item
        xs={
          // eslint-disable-next-line no-nested-ternary
          numberOfIdentifiers === 3
            ? 4
            : numberOfIdentifiers === 2 &&
                !isNil(masterAirwayBillOfLadingNumber)
              ? 6
              : 0
        }
      >
        <Box
          sx={{
            display: numberOfIdentifiers === 3 ? 'flex' : undefined,
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {masterAirwayBillOfLadingNumber}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={numberOfIdentifiers <= 2 ? 6 : 4} sx={{ float: 'right' }}>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{
              fontSize: '12px',
              py: 0,
              px: 1,
            }}
            onClick={() => {
              setOpenedOrderUuid(orderUuid);
            }}
          >
            {shipperBillOfLadingNumber}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(RouteStopRefNumberRow);
