import { Box } from '@mui/material';

export const QuickbooksErrorPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Something went wrong
    </Box>
  );
};

export const QuickbooksSuccessPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <img src="/icon.png" width={128} height={128} alt="icon" />
      OAuth complete! (you can close this tab)
    </Box>
  );
};
