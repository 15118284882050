import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import * as EmailValidator from 'email-validator';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactEmailType,
  PermissionResource,
  useContactEmailsQuery,
  useCreateContactEmailMutation,
  useDeleteContactEmailMutation,
} from '../../../generated/graphql';

const InvoiceContactEmails = ({ contactUuid }: { contactUuid: string }) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const [email, setEmail] = useState<string>('');
  const [createContactEmail, { loading: createContactEmailLoading }] =
    useCreateContactEmailMutation();
  const [deleteContactEmail, { loading: deleteContactEmailLoading }] =
    useDeleteContactEmailMutation();
  const [hoveredRow, setHoveredRow] = useState<string>();
  const { data: contactEmailsData, refetch: refetchContactEmails } =
    useContactEmailsQuery({
      variables: { contactUuid },
    });

  const handleAddEmail = async () => {
    await createContactEmail({
      variables: {
        createContactEmailInput: {
          contactEmailCreateInput: {
            contactUuid,
            email,
            type: ContactEmailType.Invoice,
          },
        },
      },
    });
    await refetchContactEmails();
    setEmail('');
  };

  const handleDeleteEmail = async ({
    contactEmailUuid,
  }: {
    contactEmailUuid: string;
  }) => {
    await deleteContactEmail({
      variables: {
        deleteContactEmailInput: {
          uuid: contactEmailUuid,
        },
      },
    });
    await refetchContactEmails();
  };

  const invoiceEmails = (contactEmailsData?.contactEmails ?? []).filter(
    (contactEmail) => contactEmail.type === ContactEmailType.Invoice,
  );

  const cannotAddEmail =
    !EmailValidator.validate(email) ||
    invoiceEmails.some(
      (e) => e.email.trim().toLowerCase() === email.trim().toLowerCase(),
    ) === true;

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Email Recipients</Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          size="small"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: '250px' }}
          disabled={!canWriteContacts}
        />
        <Button
          disabled={
            cannotAddEmail || createContactEmailLoading || !canWriteContacts
          }
          onClick={handleAddEmail}
          variant="contained"
        >
          Add email
        </Button>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceEmails.map((contactEmail) => {
                return (
                  <TableRow
                    key={contactEmail.uuid}
                    onMouseEnter={() => setHoveredRow(contactEmail.uuid)}
                    onMouseLeave={() => setHoveredRow(undefined)}
                  >
                    <TableCell>{contactEmail.email}</TableCell>
                    <TableCell
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Fade in={hoveredRow === contactEmail.uuid}>
                        <IconButton
                          disabled={
                            deleteContactEmailLoading || !canWriteContacts
                          }
                          onClick={async () => {
                            await handleDeleteEmail({
                              contactEmailUuid: contactEmail.uuid,
                            });
                          }}
                        >
                          <CloseIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Fade>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default InvoiceContactEmails;
