import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Stack, SxProps } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { CSVLink } from 'react-csv';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import { RouteFragment, Segment } from '../../../../../generated/graphql';
import GeneratedManifestPdf from '../../../../generated-documents/components/generated-manifest-pdf-v3';
import { convertRoutesToManifestCsv } from '../../../utils';

const styles = {
  manifestModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  } as SxProps,
};

const PrintManifestModal = ({
  open,
  setOpen,
  routes,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  routes: RouteFragment[];
}) => {
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const { segment, defaultWeightUnits, companyTimezone } = useMe();
  const id = Math.floor(1000 + Math.random() * 9000);

  const generatedPdf = React.useMemo(
    () => (
      <GeneratedManifestPdf
        routes={routes.filter((route) => route.slots.length > 0)}
        segment={segment}
        defaultWeightUnits={defaultWeightUnits}
        timeZone={companyTimezone ?? 'America/Los_Angeles'}
        displayPackageSpecName={ffPackageSpecs}
      />
    ),
    [routes, segment, defaultWeightUnits, companyTimezone, ffPackageSpecs],
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.manifestModal}>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          sx={{ padding: 0, float: 'right', mb: '10px', ml: 'auto' }}
        >
          <CloseIcon />
        </IconButton>
        {!isNil(routes) && (
          <>
            <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
              {generatedPdf}
            </PDFViewer>
            <Stack
              sx={{ paddingTop: 2 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Button variant="contained">
                {
                  // consider making the filename include today's date
                }
                <PDFDownloadLink
                  document={generatedPdf}
                  fileName={`manifest${
                    routes.length === 1 ? `-${routes[0]?.name}` : ''
                  }.pdf`}
                >
                  {({ loading }) =>
                    loading === true ? 'Loading...' : 'Download'
                  }
                </PDFDownloadLink>
              </Button>
              {segment === Segment.Cartage && (
                <CSVLink
                  data={convertRoutesToManifestCsv(routes, id)}
                  filename={`manifest-${id}.csv`}
                >
                  <Button variant="contained">Download CSV</Button>
                </CSVLink>
              )}
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default React.memo(PrintManifestModal);
