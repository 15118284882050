import { Box, SxProps } from '@mui/material';
import type {
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

type OrderFormCardProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    sx?: SxProps;
  }>;

export const OrderFormCard: FunctionComponent<OrderFormCardProps> = ({
  children,
  ...props
}) => (
  <Box p={2} bgcolor="white" {...props}>
    {children}
  </Box>
);
