import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useQuickbooksDesktopData from '../../../common/react-hooks/use-quickbooks-desktop-data';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  ContactType,
  PermissionResource,
  ShallowContactDocument,
  useShallowContactLazyQuery,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';
import QuickbooksDesktopMappingAutocomplete from '../../settings/components/quickbooks-desktop-mapping-autocomplete';

const QuickbooksDesktopContactMapping = ({
  contactUuid,
}: {
  contactUuid: string;
}) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsQuickbooksDesktop } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsQuickbooksDesktop,
  );
  const [updateCustomerContact] = useUpdateCustomerContactMutation({
    refetchQueries: [ShallowContactDocument],
  });
  const {
    quickbooksCustomers,
    quickbooksCustomersLoading,
    refetchQuickbooksCustomers,
    quickbooksAccounts,
    quickbooksAccountsLoading,
    refetchQuickbooksAccounts,
  } = useQuickbooksDesktopData();
  const [getContact, { data: contactData, loading: contactLoading }] =
    useShallowContactLazyQuery();
  const [customerMapping, setCustomerMapping] = useState<string>();
  const [accountMapping, setAccountMapping] = useState<string>();
  const [showSuccessfulSave, setShowSuccessfulSave] = useState(false);
  const [showUnsuccessfulSave, setShowUnsuccessfulSave] = useState(false);

  const quickbooksLoading =
    quickbooksCustomersLoading || quickbooksAccountsLoading;

  useEffect(() => {
    if (!isNil(quickbooksCustomers?.error)) {
      refetchQuickbooksCustomers();
    }
    if (!isNil(quickbooksAccounts?.error)) {
      refetchQuickbooksAccounts();
    }
  }, []);

  useEffect(() => {
    getContact({ variables: { uuid: contactUuid } });
  }, [contactUuid]);

  useEffect(() => {
    setCustomerMapping(contactData?.contact.quickbooksDesktopContactId ?? '');
    setAccountMapping(
      contactData?.contact.quickbooksDesktopAccountsReceivableId ?? '',
    );
  }, [contactData?.contact.quickbooksDesktopContactId]);

  const onSave = async () => {
    if (contactData?.contact.type === ContactType.Customer) {
      const updateContactRes = await updateCustomerContact({
        variables: {
          input: {
            customerContactUpdateInput: {
              uuid: contactUuid,
              quickbooksDesktopContactId: customerMapping ?? null,
              quickbooksDesktopAccountsReceivableId: accountMapping ?? null,
            },
          },
        },
      });
      if (!isNil(updateContactRes.data?.updateCustomerContact.uuid)) {
        setShowSuccessfulSave(true);
      } else {
        setShowUnsuccessfulSave(true);
      }
    }
  };

  if (contactData?.contact.type === ContactType.ThirdParty) {
    return <Box>Quickbooks is not available for third party contacts</Box>;
  }

  if (contactLoading || quickbooksLoading === true) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  if (
    !isNilOrEmptyString(quickbooksCustomers?.error) ||
    !isNilOrEmptyString(quickbooksAccounts?.error)
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          textAlign: 'center',
        }}
      >
        {!isNilOrEmptyString(quickbooksCustomers?.error) && (
          <Typography>
            Error fetching Quickbooks customers: {quickbooksCustomers?.error}
          </Typography>
        )}
        {!isNilOrEmptyString(quickbooksAccounts?.error) && (
          <Typography>
            Error fetching Quickbooks accounts: {quickbooksAccounts?.error}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSuccessfulSave(false)}
        open={showSuccessfulSave}
      >
        <Alert>Successfully saved Quickbooks Desktop mappings</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowUnsuccessfulSave(false)}
        open={showUnsuccessfulSave}
      >
        <Alert severity="error">Failed to save</Alert>
      </Snackbar>
      <Stack direction="column" spacing={2}>
        <QuickbooksDesktopMappingAutocomplete
          label="Customer name mapping"
          currentMappingId={customerMapping}
          onChange={(value) => {
            setCustomerMapping(value);
          }}
          quickbooksData={quickbooksCustomers}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
        <QuickbooksDesktopMappingAutocomplete
          label="Account mapping"
          currentMappingId={accountMapping}
          onChange={(value) => {
            setAccountMapping(value);
          }}
          quickbooksData={quickbooksAccounts}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
        <Button sx={{ width: '100px' }} variant="contained" onClick={onSave}>
          Save
        </Button>
      </Stack>
      <Typography variant="caption">
        Not seeing the latest data from Quickbooks Desktop? Try refreshing the
        page.
      </Typography>
    </Box>
  );
};

export default QuickbooksDesktopContactMapping;
