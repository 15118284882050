import { Checkbox, Stack, Typography } from '@mui/material';
import { getPermissionsFlags } from 'shared/roles';
import { OrderCommentValues } from '../../../../domains/orders/components/order-form/forms/types';
import { PermissionResource } from '../../../../generated/graphql';
import useUserRoles from '../../../react-hooks/use-user-roles';

export type OrderCommentShowOnInvoiceCheckboxProps = {
  orderComment: OrderCommentValues;
  disabled: boolean;
  onCheckShowOnInvoice: (commentUuid: string, value: boolean) => void;
};

const OrderCommentShowOnInvoiceCheckbox = ({
  disabled,
  orderComment,
  onCheckShowOnInvoice,
}: OrderCommentShowOnInvoiceCheckboxProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteInvoices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Invoices,
  );

  if (!canWriteInvoices) return null;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography>Show note on invoice</Typography>
      <Checkbox
        checked={orderComment?.showOnInvoice ?? false}
        onChange={(e) =>
          onCheckShowOnInvoice(orderComment?.uuid ?? '', e.target.checked)
        }
        disabled={disabled}
        size="small"
      />
    </Stack>
  );
};

export default OrderCommentShowOnInvoiceCheckbox;
