/* eslint-disable react/destructuring-assignment */
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import React, { ForwardedRef, useImperativeHandle, useRef } from 'react';
import { FilterViewPage } from '../../../generated/graphql';
import useOnColumnResized from './use-on-column-resized';
import useOnColumnVisible from './use-on-column-visible';
import useOnGridReady from './use-on-grid-ready';
import useOnToolPanelVisibleChanged from './use-on-tool-panel-visible-changed';

/**
 * We override the forwardRef function from the React module to include the
 * type definition for the forwardRef function. We need to include the type
 * definition for the forwardRef function in order to prevent any type errors
 * when using the AgGridReact component + ref.
 * Note: The forwarded ref type has been narrowed down to RefObject to
 * simplify how we use the ref in our implementation.
 */
declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: ForwardedRef<T>) => ReactNode,
  ): (props: P & RefAttributes<T>) => ReactNode;
}

/**
 * PalletAgGridReactRequiredProps is a type that extends AgGridReactProps and
 * includes required props for our AgGrid implementation. We require
 * these props to be passed in order to prevent any regressions or
 * unexpected behavior.
 */

// Disallow changing class name to maintain table styles and overrides
type OverrideDisallowedAgGridProps = 'className';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface PalletAgGridReactRequiredProps<TData = any>
  extends Omit<AgGridReactProps<TData>, OverrideDisallowedAgGridProps> {
  pageType: FilterViewPage;
  cacheBlockSize: AgGridReactProps['cacheBlockSize'];
  columnDefs: AgGridReactProps['columnDefs'];
  defaultColDef: AgGridReactProps['defaultColDef'];
  getRowId: AgGridReactProps['getRowId'];
  headerHeight: AgGridReactProps['headerHeight'];
  onCellClicked: AgGridReactProps['onCellClicked'];
  onColumnMoved: AgGridReactProps['onColumnMoved'];
  onFilterChanged: AgGridReactProps['onFilterChanged'];
  onGridReady: AgGridReactProps['onGridReady'];
  onRowSelected: AgGridReactProps['onRowSelected'];
  onSelectionChanged: AgGridReactProps['onSelectionChanged'];
  onSortChanged: AgGridReactProps['onSortChanged'];
  pagination: AgGridReactProps['pagination'];
  rowHeight: AgGridReactProps['rowHeight'];
  rowModelType: AgGridReactProps['rowModelType'];
  rowMultiSelectWithClick: AgGridReactProps['rowMultiSelectWithClick'];
  rowSelection: AgGridReactProps['rowSelection'];
  sideBar: AgGridReactProps['sideBar'];
  suppressCellFocus: AgGridReactProps['suppressCellFocus'];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PalletAgGridReact = <TData = any,>(
  props: PalletAgGridReactRequiredProps<TData>,
  ref: ForwardedRef<AgGridReact<TData>>,
) => {
  /**
   * Expose the AgGridReact instance to the parent component.
   * This allows the parent component to access the AgGridReact instance
   * via the ref prop.
   */
  const gridRef = useRef<AgGridReact>(null);
  useImperativeHandle<AgGridReact<TData> | null, AgGridReact<TData> | null>(
    ref,
    () => gridRef.current,
  );

  /**
   * Add additional functionality to passed in props.
   */
  const onToolPanelVisibleChanged = useOnToolPanelVisibleChanged(
    props.onToolPanelVisibleChanged,
    gridRef,
  );
  const onColumnVisible = useOnColumnVisible(props.onColumnVisible);
  const onColumnResized = useOnColumnResized(
    props.onColumnResized,
    props.pageType,
  );
  const onGridReady = useOnGridReady(props.onGridReady, props.pageType);

  return (
    <AgGridReact
      {...props}
      className="ag-theme-material ag-compact"
      ref={gridRef}
      onToolPanelVisibleChanged={onToolPanelVisibleChanged}
      onColumnVisible={onColumnVisible}
      onColumnResized={onColumnResized}
      onGridReady={onGridReady}
    />
  );
};

export default React.forwardRef(PalletAgGridReact);
