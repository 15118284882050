import { isNil } from 'lodash';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { useInvoiceEmailSendStatusLazyQuery } from '../../../generated/graphql';
import useInvoicesStore from '../invoices-store';

const useInvoiceEmailStatuses = () => {
  const mainAbortController = useRef(new AbortController());
  const [queueProcessing, setQueueProcessing] = useState<boolean>(false);
  const [queue, setQueue] = useState<string[][]>([]);
  const [invoiceEmailSendStatusesCache] = useInvoicesStore(
    (state) => [state.invoiceEmailSendStatusesCache],
    shallow,
  );
  const [upsertInvoiceEmailSendStatus] = useInvoicesStore(
    (state) => [state.upsertInvoiceEmailSendStatus],
    shallow,
  );

  const [getInvoiceEmailSendStatus] = useInvoiceEmailSendStatusLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const fetchAndCacheInvoiceEmailSendStatuses = useCallback(
    async (uuids: string[], controller?: MutableRefObject<AbortController>) => {
      const res = await getInvoiceEmailSendStatus({
        variables: { uuids },
        context: !isNil(controller)
          ? {
              fetchOptions: {
                signal: controller.current.signal,
              },
            }
          : undefined,
      });
      res.data?.invoicesByUuids.forEach(({ uuid, emailSendStatus }) => {
        upsertInvoiceEmailSendStatus(uuid, emailSendStatus);
      });
    },
    [getInvoiceEmailSendStatus, upsertInvoiceEmailSendStatus],
  );

  const fetchInvoiceEmailSendStatusesByUuids = useCallback(
    async (uuids: string[], addToQueue?: boolean) => {
      if (addToQueue === true) {
        setQueue([...queue, ...[uuids]]);
      } else {
        mainAbortController.current.abort();
        mainAbortController.current = new AbortController();
        fetchAndCacheInvoiceEmailSendStatuses(uuids, mainAbortController);
      }
    },
    [queue, fetchAndCacheInvoiceEmailSendStatuses],
  );

  useEffect(() => {
    if (queue.length > 0) {
      const uuids = queue.shift();
      if (!isNil(uuids) && queueProcessing === false) {
        setQueueProcessing(true);
        fetchAndCacheInvoiceEmailSendStatuses(uuids).then(() =>
          setQueueProcessing(false),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, queueProcessing]);

  return {
    invoiceEmailSendStatuses: invoiceEmailSendStatusesCache,
    fetchInvoiceEmailSendStatusesByUuids,
  };
};

export default useInvoiceEmailStatuses;
