import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import useStyles from './general-styles';

const CACHE_PREFIX = 'SINGLE_SELECT_FILTER';

interface SingleSelectFilterButtonProps<T> {
  cacheId?: string;
  option: T | undefined;
  handleChange: (newOption: T | undefined) => void;
  options: T[];
  renderOption?: (option: T) => string;
  filterTitle?: string;
  enableDefaultFilter?: boolean;
  defaultFilterTitle?: string;
}

const SingleSelectFilterButton = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  DefaultOptionsType extends string,
>({
  cacheId,
  option,
  handleChange,
  options,
  renderOption,
  filterTitle,
  enableDefaultFilter,
  defaultFilterTitle,
}: SingleSelectFilterButtonProps<DefaultOptionsType>) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      handleChange(
        !isNil(cachedValue) ? (cachedValue as DefaultOptionsType) : undefined,
      );
    }
  }, []);

  const onChange = (newOption: DefaultOptionsType | undefined) => {
    if (!isNil(cacheId)) {
      if (!isNil(newOption)) {
        localStorage.setItem(`${CACHE_PREFIX}_VALUE_${cacheId}`, newOption);
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      }
    }
    handleChange(newOption);
  };

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
          e.stopPropagation();
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton, { whiteSpace: 'nowrap' }]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{filterTitle ?? ''}</Typography>
          <Typography sx={styles.filterValue}>
            {!isNil(option) ? sentenceCase(option) : defaultFilterTitle}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          {enableDefaultFilter === true && (
            <MenuItem
              key="default"
              onClick={(e) => {
                onChange(undefined);
                e.stopPropagation();
              }}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  data-testid="boolean-and-input-filter-all-option-checkmark"
                  sx={{
                    visibility: isNil(option) ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>
                  {defaultFilterTitle}
                </Typography>
              </Stack>
            </MenuItem>
          )}
          {options.map((opt) => (
            <MenuItem
              key={opt}
              onClick={(e) => {
                onChange(opt);
                e.stopPropagation();
              }}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  data-testid="boolean-and-input-filter-all-option-checkmark"
                  sx={{
                    visibility: option === opt ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>
                  {!isNil(renderOption) ? renderOption(opt) : sentenceCase(opt)}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SingleSelectFilterButton;
