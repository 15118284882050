import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { getPermissionsFlags } from 'shared/roles';
import ButtonLink from '../../../../common/components/buttons/button-link';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  VehicleTypeStatus,
  useVehicleTypesMinimalQuery,
} from '../../../../generated/graphql';
import VehicleTypeRow from './vehicle-type-row';

const VehicleTypes = () => {
  const { userPermissions } = useUserRoles();
  const { canRead, canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyEquipment,
  );
  const { data, loading } = useVehicleTypesMinimalQuery({
    fetchPolicy: 'cache-and-network',
  });

  const vehicleTypesArchivedLast = data?.vehicleTypes.slice().sort((a, b) => {
    if (a.status === VehicleTypeStatus.Archived) {
      return b.status === VehicleTypeStatus.Archived ? 0 : 1;
    }
    if (b.status === VehicleTypeStatus.Archived) {
      return -1;
    }
    return 0;
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">
              <ButtonLink
                href="/management/vehicle-types/add"
                variant="contained"
                disabled={!canWrite}
              >
                Add vehicle type
              </ButtonLink>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <CircularProgress />
          ) : (
            vehicleTypesArchivedLast?.map(({ uuid, name, status }) => (
              <VehicleTypeRow
                key={uuid}
                name={name}
                status={status}
                openButton={
                  canRead || canWrite ? (
                    <ButtonLink
                      variant="contained"
                      href={`/management/vehicle-types/${uuid}`}
                    >
                      {canWrite ? 'Edit' : 'View'}
                    </ButtonLink>
                  ) : null
                }
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VehicleTypes;
