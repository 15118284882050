import { StyleSheet } from '@react-pdf/renderer';
import { safeDivide } from 'shared/math';
import { GENERATED_REVENUE_REPORT_COLS, TEXT_PADDING } from './constants';

const NUM_REVENUE_COLUMNS = GENERATED_REVENUE_REPORT_COLS.length;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterLabelText: {
    fontWeight: 'bold',
    width: '75px',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  reportRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '8px',
    width: '100%',
    textAlign: 'center',
  },
  reportHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    marginTop: '10px',
  },
  reportRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    textAlign: 'left',
  },
  reportRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '88%',
    textAlign: 'right',
  },
  customerCol: {
    width: '50%',
    paddingRight: TEXT_PADDING,
  },
  contactRefNumCol: {
    width: '25%',
    paddingRight: TEXT_PADDING,
  },
  countCol: {
    width: '25%',
  },
  revenueCol: {
    width: `${safeDivide(100, NUM_REVENUE_COLUMNS)}%`,
  },
});

export default styles;
