import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormHelperText,
  IconButton,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil, values } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import {
  StorageOrderDocument,
  UnitOfMeasure,
  useCreateStorageUnitMutation,
} from '../../../../../generated/graphql';
import theme from '../../../../../theme';
import useCreateStorageUnitForm, {
  CreateStorageUnitFormValues,
} from '../../../forms/storage-units/use-create-storage-unit-form';

interface StorageUnitCreateRowProps {
  storageOrderUuid: string;

  setCreateStorageUnitSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;

  setCreateStorageUnitErrorMessageVisible: Dispatch<SetStateAction<boolean>>;

  setIsCreatingStorageUnit: Dispatch<SetStateAction<boolean>>;

  onSelectStorageUnit: (newStorageUnitUuid: string | null) => void;
}
const StorageUnitCreateRow = ({
  storageOrderUuid,
  setCreateStorageUnitSuccessMessageVisible,
  setCreateStorageUnitErrorMessageVisible,
  setIsCreatingStorageUnit,
  onSelectStorageUnit,
}: StorageUnitCreateRowProps) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useCreateStorageUnitForm();

  const [createStorageUnit, { loading: createStorageUnitLoading }] =
    useCreateStorageUnitMutation({ refetchQueries: [StorageOrderDocument] });

  useEffect(() => {
    reset({
      unitOfMeasure: UnitOfMeasure.Pallet,
    });
  }, [storageOrderUuid]);

  const onSubmit: SubmitHandler<CreateStorageUnitFormValues> = async (data) => {
    const { name, unitOfMeasure } = data;
    try {
      const createStorageUnitResponse = await createStorageUnit({
        variables: {
          createStorageUnitInput: {
            storageOrderUuid,
            name,
            unitOfMeasure,
          },
        },
      });
      setCreateStorageUnitSuccessMessageVisible(true);
      onSelectStorageUnit(
        createStorageUnitResponse?.data?.createStorageUnit?.storageUnit.uuid ??
          null,
      );
      setIsCreatingStorageUnit(false);
    } catch (e) {
      setCreateStorageUnitErrorMessageVisible(true);
    }
  };

  const handleCancel = () => {
    reset();
    setIsCreatingStorageUnit(false);
  };

  return (
    <TableRow>
      <TableCell>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <TextField
                name="name"
                size="small"
                value={value}
                required
                onChange={onChange}
                error={!isNil(errors.name)}
                sx={{ width: '100%' }}
                autoFocus
              />
              {!isNil(errors.name) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.name?.message ?? ''}
                </FormHelperText>
              )}
            </>
          )}
        />
      </TableCell>
      <TableCell sx={{ minWidth: theme.spacing(15) }}>
        <Controller
          name="unitOfMeasure"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <Select
                size="small"
                value={value}
                onChange={onChange}
                error={!isNil(errors.unitOfMeasure)}
                required
                native
              >
                {values(UnitOfMeasure).map((unitOfMeasure) => {
                  return (
                    <option key={unitOfMeasure} value={unitOfMeasure}>
                      {sentenceCase(unitOfMeasure)}
                    </option>
                  );
                })}
              </Select>
              {!isNil(errors.unitOfMeasure) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.unitOfMeasure?.message ?? ''}
                </FormHelperText>
              )}
            </>
          )}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={handleSubmit(onSubmit)}
          disabled={createStorageUnitLoading}
          color="primary"
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={handleCancel}
          disabled={createStorageUnitLoading}
          color="primary"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default StorageUnitCreateRow;
