import { isNil } from 'lodash';
import React from 'react';
import { CustomComponentProps } from 'react-multi-date-picker';
import { filterNotNilOrEmpty } from 'shared/array';
import { normalizeHourAndMinute } from 'shared/plain-date-time';

export const fixDatetimeInput = (datetimeStr: string) => {
  const datetimeStrTokens = filterNotNilOrEmpty(datetimeStr.split(' '));
  if (datetimeStrTokens.length !== 2) {
    return datetimeStr;
  }
  const [dateStr, timeStr] = datetimeStrTokens;
  if (isNil(timeStr)) {
    return datetimeStr;
  }
  const maybeNormalizedTime = normalizeHourAndMinute(timeStr);
  if (isNil(maybeNormalizedTime)) {
    return datetimeStr;
  }
  return `${dateStr} ${maybeNormalizedTime}`;
};

const DatePickerInput = ({
  placeholder,
  value,
  handleValueChange,
  openCalendar,
  dataTestId,
}: CustomComponentProps & { placeholder: string; dataTestId?: string }) => {
  return (
    <input
      data-testid={dataTestId ?? ''}
      value={value}
      placeholder={placeholder}
      className="rmdp-input"
      onFocus={openCalendar}
      onChange={(e) => {
        e.target.value = fixDatetimeInput(e.target.value);
        handleValueChange?.(e);
      }}
    />
  );
};

export { DatePickerInput };
