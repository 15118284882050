import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  Link,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { safeDivide } from 'shared/math';
import useMe from '../../../../common/react-hooks/use-me';
import { transformDateToDateString } from '../../../../common/utils/prettyPrintUtils';
import {
  InvoiceSource,
  InvoiceStatus,
  InvoiceForContactFragment,
} from '../../../../generated/graphql';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import InvoiceOrdersList from '../invoices/invoice-orders-list';
import InvoicePaymentsList from '../invoices/invoice-payments-list';

interface ContactInvoiceListRowProps {
  invoice: InvoiceForContactFragment;
}

const ContactInvoiceListRow = ({ invoice }: ContactInvoiceListRowProps) => {
  const { companyConfiguration } = useMe();
  const { invoiceTotals, invoiceBalances } = useInvoiceTotals();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const invoiceStatus =
    invoice.status === InvoiceStatus.NotFinalized ? 'Not finalized' : 'Posted';
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: isExpanded ? '#F7F7F7' : undefined,
        }}
      >
        <TableCell width={70}>
          <IconButton size="small" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link
            sx={{
              cursor: 'pointer',
              maxWidth: '100px',
            }}
            href={`/accounting/?invoiceUuid=${invoice.uuid}&invoiceName=${invoice.name}`}
            target="_blank"
            underline="hover"
          >
            <Stack alignItems="center" spacing={1} direction="row">
              {companyConfiguration?.useJournalNumberForInvoice === true
                ? invoice.journalNumber
                : invoice.name}
            </Stack>
          </Link>
        </TableCell>
        <TableCell>{invoiceStatus}</TableCell>
        <TableCell>
          {!isNil(invoiceTotals[invoice.uuid]) ? (
            invoiceTotals[invoice.uuid]
          ) : (
            <Skeleton />
          )}
        </TableCell>
        <TableCell>
          {!isNil(invoiceBalances[invoice.uuid]) ? (
            currency(
              safeDivide(invoiceBalances[invoice.uuid] ?? 0, 100),
            ).format()
          ) : (
            <Skeleton />
          )}
        </TableCell>
        <TableCell>{transformDateToDateString(invoice.date)}</TableCell>
        <TableCell>{transformDateToDateString(invoice.createdAt)}</TableCell>
      </TableRow>
      <TableRow sx={{ paddingY: 0 }}>
        <TableCell sx={{ paddingY: 0 }} colSpan={10}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <ButtonGroup
              disableElevation
              variant="outlined"
              size="small"
              sx={{ paddingY: 1 }}
            >
              <Button
                variant={tabIndex === 0 ? 'contained' : 'outlined'}
                onClick={() => setTabIndex(0)}
              >
                Orders
              </Button>
              <Button
                variant={tabIndex === 1 ? 'contained' : 'outlined'}
                onClick={() => setTabIndex(1)}
              >
                Payments
              </Button>
            </ButtonGroup>
            <Box sx={{ paddingLeft: 2 }}>
              {tabIndex === 0 && (
                <InvoiceOrdersList
                  invoiceUuid={invoice.uuid}
                  contactUuid={invoice.billToContact.uuid}
                  disableInvoiceEditing={
                    invoice.status !== InvoiceStatus.NotFinalized ||
                    invoice.source === InvoiceSource.Migrated
                  }
                  inCustomersTab
                />
              )}
              {tabIndex === 1 && (
                <InvoicePaymentsList
                  invoiceUuid={invoice.uuid}
                  inCustomersTab
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContactInvoiceListRow;
