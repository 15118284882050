import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Modal,
  Typography,
  TextField,
  Stack,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import useDrivers from '../../../../../../common/react-hooks/use-drivers';
import useMe from '../../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../../common/react-hooks/use-terminals';
import { DriverOption } from '../../../../../../common/types';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { getDriverIdentifier } from '../../../../../dispatch/utils';
import useStyles from '../../../styles';

const AddDriverForDriverSettlementModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (driverUuid: string) => void;
}) => {
  const styles = useStyles();
  const { companyConfiguration } = useMe();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getDriverName, drivers } = useDrivers();
  const [selectedDriverOption, setSelectedDriverOption] =
    useState<DriverOption>();

  const onClose = () => {
    setSelectedDriverOption(undefined);
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ ...styles.modal, ...{ height: '20vh', width: '30vw' } }}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Typography variant="h6">Add Driver</Typography>
          </Grid>
          <Grid item xs={12}>
            <AutocompleteFuzzy
              size="small"
              sx={{
                width: '100%',
              }}
              value={selectedDriverOption}
              options={
                drivers
                  ?.filter((driver) => !isNil(driver))
                  ?.sort((a, b) =>
                    getDriverIdentifier(
                      a,
                      companyConfiguration?.useDriverNumberForDispatch,
                    )?.localeCompare(
                      getDriverIdentifier(
                        b,
                        companyConfiguration?.useDriverNumberForDispatch,
                      ),
                    ),
                  )
                  .map((driver) => ({
                    value: driver.uuid,
                    label: `${
                      !isEmpty(driver.driverReferenceNumber)
                        ? `${driver.driverReferenceNumber} - `
                        : ''
                    }${getDriverName(driver.uuid)}`,
                    terminal:
                      driver.terminal?.name ??
                      (terminalsEnabled ? 'All terminals' : undefined),
                  })) ?? []
              }
              matchSortOptions={{ keys: ['label', 'terminal'] }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  variant="standard"
                  size="small"
                />
              )}
              renderOption={(props, option) => {
                return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props} key={option.value}>
                    <Stack>
                      <Typography sx={{ fontSize: '14px' }}>
                        {option.label}
                      </Typography>
                      {!isNil(option.terminal) && (
                        <Typography variant="caption" color="text.secondary">
                          {option.terminal}
                        </Typography>
                      )}
                    </Stack>
                  </li>
                );
              }}
              onChange={(event, option) => {
                setSelectedDriverOption(option ?? undefined);
                event.stopPropagation();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ float: 'right' }}>
              <Button
                variant="contained"
                onClick={() => {
                  const driverUuid = selectedDriverOption?.value;
                  if (!isNil(driverUuid)) {
                    onConfirm(driverUuid);
                  }
                }}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddDriverForDriverSettlementModal;
