import { isNil } from 'lodash';
import * as yup from 'yup';
import { OrderFormFieldsFragment } from '../../../../../generated/graphql';
import { packageSchema } from './package-schema';

export const getPackagesSchema = (
  orderFormFields?: OrderFormFieldsFragment,
) => {
  const basePackagesSchema = yup.array().of(packageSchema);

  const packagesSchema =
    orderFormFields?.onePackageRequired === true
      ? basePackagesSchema
          .min(1, 'At least one package is required')
          .required('This field is required')
      : basePackagesSchema.nullable();

  return packagesSchema.test(
    'at-least-one-weight',
    'At least one package must have a weight',
    (packages) => {
      // if at least one package weight is not required, then skip validation
      if (orderFormFields?.onePackageWeightRequired !== true) {
        return true;
      }
      if (isNil(packages)) {
        return false;
      }
      return packages.some((pkg) => !isNil(pkg.weight));
    },
  );
};
