import { MenuItem, TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { ReactNode, memo } from 'react';
import { shallow } from 'zustand/shallow';
import { useAvailableTariffTypes } from '../../../../../common/react-hooks/use-available-tariff-types';
import { TariffGroupType } from '../../../../../generated/graphql';
import useTariffGroupActions from '../hooks/use-tariff-group-actions';
import useTariffGroupStore, {
  convertTariffGroupTypeToTariffZoneLocationType,
} from '../store/tariff-group-state-store';

const TariffGroupTypeField = () => {
  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const { refetchAndSetLocationZones } = useTariffGroupActions();
  const [
    useZoneGroups,
    tariffGroupType,
    zoneType,
    setTariffGroupType,
    setTariffZoneGroupId,
  ] = useTariffGroupStore(
    (state) => [
      state.useZoneGroups,
      state.tariffGroupType,
      state.zoneType,
      state.setTariffGroupType,
      state.setTariffZoneGroupId,
    ],
    shallow,
  );
  const handleChangeTariffGroupType = async (
    newTariffGroupType: TariffGroupType,
  ) => {
    if (newTariffGroupType !== TariffGroupType.Ordinary || !useZoneGroups) {
      refetchAndSetLocationZones({
        variables: {
          tariffZoneType: zoneType,
          tariffZoneLocationType:
            convertTariffGroupTypeToTariffZoneLocationType(newTariffGroupType),
        },
      });
      setTariffZoneGroupId(null);
    }
    setTariffGroupType(newTariffGroupType);
  };

  const menuItems: ReactNode[] = [];
  if (ordinaryTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.Ordinary}>Default</MenuItem>,
    );
  }
  if (transferTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.Transfer}>
        {sentenceCase(TariffGroupType.Transfer)}
      </MenuItem>,
    );
  }
  if (lineHaulTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.LineHaul}>
        {sentenceCase(TariffGroupType.LineHaul)}
      </MenuItem>,
    );
  }
  if (pointToPointTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.PointToPoint}>
        {sentenceCase(TariffGroupType.PointToPoint)}
      </MenuItem>,
    );
  }

  return (
    <TextField
      label="Tariff Group Type"
      value={tariffGroupType}
      onChange={(e) =>
        handleChangeTariffGroupType(e.target.value as TariffGroupType)
      }
      size="small"
      fullWidth
      select
    >
      {menuItems}
    </TextField>
  );
};

export default memo(TariffGroupTypeField);
