import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useLocalStorageState, {
  LocalStorageState,
} from 'use-local-storage-state';
import { v4 } from 'uuid';
import { StopType } from '../forms/stop-type';
import { OrderFormFieldValues } from '../types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from './constants';

export enum DemoShipmentType {
  LTL = 'LTL',
  Truckload = 'Truckload',
}

export const useShipmentTypeDemoFieldValue =
  (): LocalStorageState<DemoShipmentType> => {
    const [searchParams] = useSearchParams();
    const pageOrderUuid = searchParams.get('orderUuid') ?? 'new';
    return useLocalStorageState<DemoShipmentType>(
      `order-${pageOrderUuid}-shipment-type`,
      {
        defaultValue: DemoShipmentType.LTL,
        serializer: {
          parse: (value) =>
            Object.values(DemoShipmentType).includes(value as never)
              ? (value as DemoShipmentType)
              : DemoShipmentType.LTL,
          stringify: (value) => value as string,
        },
      },
    );
  };

export const ShipmentTypeDemoField = () => {
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const [shipmentType, setShipmentType] = useShipmentTypeDemoFieldValue();
  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });
  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });
  const inboundAddress = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.address`,
  });
  const outboundAddress = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.address`,
  });

  return (
    <FormControl>
      <InputLabel id="order-shipment-type-label">Shipment Type</InputLabel>
      <Select<DemoShipmentType>
        id="stop-type-select"
        labelId="order-shipment-type-label"
        label="Shipment Type"
        value={shipmentType}
        onChange={(e) => {
          setShipmentType(e.target.value as DemoShipmentType);
          if (e.target.value === DemoShipmentType.Truckload) {
            if (inboundStopType !== StopType.Pickup) {
              setValue(`stops.${INBOUND_STOP_IDX}.stopType`, StopType.Pickup);
              if (!isNil(inboundAddress)) {
                // Provide a new uuid in case the order is saved.
                setValue(`stops.${INBOUND_STOP_IDX}.address`, {
                  ...inboundAddress,
                  uuid: v4(),
                });
              }
            }
            if (outboundStopType !== StopType.Delivery) {
              setValue(
                `stops.${OUTBOUND_STOP_IDX}.stopType`,
                StopType.Delivery,
              );
              if (!isNil(outboundAddress)) {
                // Provide a new uuid in case the order is saved.
                setValue(`stops.${OUTBOUND_STOP_IDX}.address`, {
                  ...outboundAddress,
                  uuid: v4(),
                });
              }
            }
          }
        }}
        size="small"
        sx={{
          width: '150px',
        }}
      >
        <MenuItem value={DemoShipmentType.LTL}>LTL</MenuItem>
        <MenuItem value={DemoShipmentType.Truckload}>Truckload</MenuItem>
      </Select>
    </FormControl>
  );
};
