import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import useContacts from '../../../../../../../common/react-hooks/use-contacts';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useStableIdentity from '../../../../../../../common/react-hooks/use-stable-identity';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import { downloadBlob } from '../../../../../../../common/utils/file';
import {
  OrderDocumentType,
  useNameOfRouteLazyQuery,
} from '../../../../../../../generated/graphql';
import GeneratedOrderPodReportPdf from '../../../../../../generated-documents/components/generated-order-pod-report-pdf';
import {
  convertOrderFormValuesToPodReportData,
  getPodReportBlob,
} from '../../../../../../generated-documents/utils';
import { DocumentAttachments } from '../../../../../../invoice-old/components/download-documents';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../../../../order-form/components/constants';
import { StopValues } from '../../../../order-form/forms/types';
import EmailOrderDocuments from './email-order-documents-component';

export const INCLUDED_DOCUMENTS = [
  DocumentAttachments.IncludeAll,
  DocumentAttachments.IncludeUnsignedPoDs,
  DocumentAttachments.NoAttachments,
];

const PrintPodReportModal = ({
  isOpen,
  setIsOpen,
  isEditMode,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
}) => {
  const { segment, companyData } = useMe();
  const { getServiceName } = useServices();
  const { getContactName } = useContacts();
  const { getTerminalCode, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();
  const orderStable = useStableIdentity(order);
  const stopRouteUuids =
    order.stops?.map((stop: StopValues) => stop.routeUuid) ?? [];
  const inboundUuid = stopRouteUuids.at(INBOUND_STOP_IDX);
  const outboundUuid = stopRouteUuids.at(OUTBOUND_STOP_IDX);

  const [getRouteName] = useNameOfRouteLazyQuery();

  const [showCharges, setShowCharges] = useState(true);
  const [attachmentDocuments, setAttachmentDocuments] =
    useState<DocumentAttachments>(DocumentAttachments.NoAttachments);
  const [fileName, setFileName] = useState<string>('');
  const [generatedPdf, setGeneratedPdf] = useState<JSX.Element | undefined>();

  useEffect(() => {
    const generatePODReportPDF = async () => {
      let routeNameInbound: string | undefined;
      let routeNameOutbound: string | undefined;
      if (isEditMode) {
        const inboundPromise = !isNil(inboundUuid)
          ? getRouteName({ variables: { uuid: inboundUuid } })
          : Promise.resolve(undefined);
        const outboundPromise = !isNil(outboundUuid)
          ? getRouteName({ variables: { uuid: outboundUuid } })
          : Promise.resolve(undefined);

        const [inboundResult, outboundResult] = await Promise.all([
          inboundPromise,
          outboundPromise,
        ]);

        routeNameInbound = inboundResult?.data?.route.name;
        routeNameOutbound = outboundResult?.data?.route.name;
      }
      const orderForPodReport = convertOrderFormValuesToPodReportData({
        order: orderStable,
        getTerminalCode,
        getContactName,
        getServiceName,
        companyName: companyData?.name,
        companyAddress: companyData?.defaultAddress,
        companyPhone: companyData?.phone,
        companyInvoiceEmail: companyData?.invoiceEmail,
        terminalsEnabled,
        routeNameInbound,
        routeNameOutbound,
      });
      setFileName(
        `order-pod-report-${orderForPodReport.shipperBillOfLadingNumber}.pdf`,
      );
      setGeneratedPdf(
        <GeneratedOrderPodReportPdf
          order={orderForPodReport}
          segment={segment}
          showCharges={showCharges}
        />,
      );
    };
    if (isOpen) {
      generatePODReportPDF();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderStable,
    getTerminalCode,
    getContactName,
    getServiceName,
    companyData?.name,
    companyData?.defaultAddress,
    companyData?.phone,
    companyData?.invoiceEmail,
    terminalsEnabled,
    inboundUuid,
    outboundUuid,
    isOpen,
    isEditMode,
    showCharges,
  ]);

  const [generatedBlob, setGeneratedBlob] = useState<Blob | undefined>();

  useEffect(() => {
    const getBlob = async () => {
      if (!isNil(generatedPdf)) {
        const stopDocuments =
          order.stops?.flatMap((stop: StopValues) => stop.documents) ?? [];
        const newBlob = await getPodReportBlob(
          generatedPdf,
          stopDocuments,
          attachmentDocuments,
        );
        setGeneratedBlob(newBlob);
      }
    };
    if (isOpen && !isNil(generatedPdf)) {
      getBlob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedPdf, attachmentDocuments, isOpen]);

  const close = () => {
    // setShowCharges(true);
    // setAttachmentDocuments(DocumentAttachments.NoAttachments);
    setIsOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={close}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        PoD Report
      </DialogTitle>
      <DialogContent sx={{ pt: '10px !important' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!isNil(generatedPdf) && (
              <PDFViewer style={{ width: '100%', height: '60vh' }} showToolbar>
                {generatedPdf}
              </PDFViewer>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl sx={{ width: '40%' }}>
              <InputLabel id="documents-label" shrink>
                Attachment Documents
              </InputLabel>
              <Select
                labelId="documents-label"
                displayEmpty
                onChange={(e) => {
                  setAttachmentDocuments(e.target.value as DocumentAttachments);
                }}
                value={attachmentDocuments}
                required
                notched
                size="small"
                sx={{ backgroundColor: 'white' }}
                label="Attachment Documents"
              >
                {INCLUDED_DOCUMENTS.map((docType) => (
                  <MenuItem key={docType} id={docType} value={docType}>
                    {docType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Checkbox
              checked={showCharges}
              onChange={(e) => setShowCharges(e.target.checked)}
            />
            <Typography>Show charges</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (isNil(generatedBlob)) return;
                downloadBlob(generatedBlob, fileName);
              }}
            >
              Download
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {!isNil(generatedPdf) && (
              <EmailOrderDocuments
                documentType={OrderDocumentType.PodReport}
                isQuote={false}
                generatedPdf={generatedPdf}
                generatedBlob={generatedBlob}
                isOpen={isOpen}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PrintPodReportModal;
