import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { useOrderFormEditAccess } from '../../../../domains/orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderCommentValues } from '../../../../domains/orders/components/order-form/forms/types';
import OrderCommentShowOnInvoiceCheckbox from './order-comment-show-on-invoice-checkbox';

type CommentsMenuProps = {
  orderComment: OrderCommentValues;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  onCheckShowOnInvoice?: (commentUuid: string, value: boolean) => Promise<void>;
  onDeleteComment: (commentUuid: string) => Promise<void>;
};

const CommentsMenu = ({
  orderComment,
  setIsEditing,
  onCheckShowOnInvoice,
  onDeleteComment,
}: CommentsMenuProps) => {
  const theme = useTheme();
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);

  const { disabledIfInvoicePosted } = useOrderFormEditAccess();

  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{ marginTop: '-4px', '&:hover': { background: 'transparent' } }}
        onClick={(e) => {
          setDropdownMenuRef(e.currentTarget);
          e.stopPropagation();
        }}
      >
        <MoreVertIcon sx={{ fontSize: '20px' }} />
      </IconButton>
      <Menu
        anchorEl={dropdownMenuRef}
        open={dropdownMenuOpen}
        onClose={closeDropdownMenu}
      >
        <MenuItem onClick={() => setIsEditing(true)}>
          <Typography>Edit note</Typography>
        </MenuItem>
        {!isNil(onCheckShowOnInvoice) && (
          <MenuItem>
            <OrderCommentShowOnInvoiceCheckbox
              orderComment={orderComment}
              disabled={disabledIfInvoicePosted}
              onCheckShowOnInvoice={onCheckShowOnInvoice}
            />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            if (isNil(orderComment) || isNilOrEmptyString(orderComment.uuid)) {
              return;
            }
            onDeleteComment(orderComment.uuid);
          }}
        >
          <Typography sx={{ color: theme.palette.secondary.main }}>
            Delete note
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CommentsMenu;
