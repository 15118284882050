import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  SxProps,
  Theme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useState, memo, useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import { MarkStopAsCompletedDialog } from '../../../../common/components/modals/mark-stop-as-completed-dialog';
import {
  areAllStopsDeliveries,
  areAllStopsPartnerCarrierStops,
} from '../../../../common/utils/stops';
import useDispatchStore from '../../dispatch-store';
import useAssignStopsRouteActions from '../../hooks/use-assign-stops-route-actions';
import useFetchRoutes from '../../hooks/use-fetch-routes';
import { useUnassignStopsRouteActions } from '../../hooks/use-unassign-stops-route-actions';
import ServiceDateModal from './modals/service-date-modal';
import RouteSelectionDropdown from './route-selection-dropdown';

const RouteMenuDropdownStyle: SxProps<Theme> = {
  width: '300px',
  ':hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
};

const RoutesBulkEditStopsMenu = () => {
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);
  const [showMarkStopAsCompletedDialog, setShowMarkStopAsCompletedDialog] =
    useState<boolean>(false);
  const [showEditServiceDateDialog, setShowEditServiceDateDialog] =
    useState<boolean>(false);
  const [planningDate, selectedStops, setSelectedStopsForBulkAction] =
    useDispatchStore(
      (state) => [
        state.planningDate,
        state.selectedStopsForBulkAction,
        state.setSelectedStopsForBulkAction,
      ],
      shallow,
    );
  const { fetchRoutes } = useFetchRoutes();
  const [assignMenuRef, setAssignMenuRef] = useState<HTMLElement | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const selectedStopUuids = selectedStops.map((s) => s.uuid);
  const [routes, setErrorMessage] = useDispatchStore(
    (state) => [state.routes, state.setErrorMessage],
    shallow,
  );
  const { reassignStop } = useAssignStopsRouteActions();
  const { unassignStop } = useUnassignStopsRouteActions();

  const reassignStopsToRoute = async (routeUuid: string) => {
    if (isNil(routeUuid)) return;
    setIsSaving(true);
    const responses = await Promise.all(
      selectedStopUuids.map((stopUuid) => {
        let oldSlotUuid: string | undefined;
        for (const route of routes) {
          for (const slot of route.slots) {
            if (slot.stops[0]?.uuid === stopUuid) {
              oldSlotUuid = slot.uuid;
              break;
            }
          }
          if (!isNil(oldSlotUuid)) break;
        }
        if (!isNil(oldSlotUuid)) {
          return reassignStop({
            slotUuid: oldSlotUuid,
            routeUuid,
          });
        }
        return Promise.resolve();
      }),
    );
    if (responses.some((res) => isNil(res))) {
      setErrorMessage(
        `Failed to reassign ${responses.filter((res) => res === false).length} stop(s).`,
      );
    } else {
      setDropdownMenuRef(null);
      setAssignMenuRef(null);
    }
    setIsSaving(false);
  };

  const unassignSelectedStops = () => {
    selectedStopUuids.forEach((stopUuid) => {
      const route = routes.find((r) =>
        r.slots.some((slot) => slot.stops[0]?.uuid === stopUuid),
      );
      unassignStop({
        routeUuid: route?.uuid,
        slotUuid: route?.slots.find((slot) => slot.stops[0]?.uuid === stopUuid)
          ?.uuid,
        stopUuid,
        emitMultiplayerEvent: true,
      });
    });
    setSelectedStopsForBulkAction([]);
  };

  const allStopsAreDeliveries = useMemo(
    () => areAllStopsDeliveries(selectedStops),
    [selectedStops],
  );
  const allStopsArePartnerStops = useMemo(
    () => areAllStopsPartnerCarrierStops(selectedStops),
    [selectedStops],
  );

  return (
    <>
      {!isEmpty(selectedStopUuids) && (
        <>
          <Button
            color="info"
            onClick={(e) => {
              setDropdownMenuRef(e.currentTarget);
            }}
            endIcon={<ArrowDropDownIcon />}
          >
            Edit stops ({selectedStopUuids.length})
          </Button>
          <Menu
            anchorEl={dropdownMenuRef}
            open={dropdownMenuOpen}
            onClose={() => {
              setDropdownMenuRef(null);
            }}
          >
            <MenuList dense sx={{ py: 0 }}>
              <MenuItem
                onClick={() => {
                  setShowMarkStopAsCompletedDialog(true);
                  setDropdownMenuRef(null);
                }}
              >
                Mark as complete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowEditServiceDateDialog(true);
                  setDropdownMenuRef(null);
                }}
              >
                Edit service date
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setAssignMenuRef(e.currentTarget);
                }}
              >
                Assign stops
              </MenuItem>
              <MenuItem onClick={unassignSelectedStops}>
                Unassign stops
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setSelectedStopsForBulkAction([]);
                  setDropdownMenuRef(null);
                }}
              >
                Clear selection
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu
            open={!isNil(assignMenuRef)}
            anchorEl={assignMenuRef}
            onClose={() => setAssignMenuRef(null)}
          >
            <MenuItem sx={RouteMenuDropdownStyle}>
              <RouteSelectionDropdown
                onSelectRoute={(route) => {
                  reassignStopsToRoute(route.uuid);
                }}
                routes={routes}
                disabled={isSaving}
              />
            </MenuItem>
          </Menu>
        </>
      )}
      {showEditServiceDateDialog && (
        <ServiceDateModal
          open={showEditServiceDateDialog}
          setOpen={setShowEditServiceDateDialog}
          stopUuids={selectedStopUuids}
          onConfirm={() => {
            fetchRoutes({
              uuids: filterNotNil(
                selectedStops.map((s) => s.routeSlot?.route?.uuid),
              ),
            });
            setShowEditServiceDateDialog(false);
            setSelectedStopsForBulkAction([]);
          }}
          isAssigned
        />
      )}
      {showMarkStopAsCompletedDialog && (
        <MarkStopAsCompletedDialog
          handleClose={() => {
            fetchRoutes({
              uuids: filterNotNil(
                selectedStops.map((s) => s.routeSlot?.route?.uuid),
              ),
            });
            setShowMarkStopAsCompletedDialog(false);
            setSelectedStopsForBulkAction([]);
          }}
          open={showMarkStopAsCompletedDialog}
          stopUuids={selectedStopUuids}
          routeDate={planningDate?.toDate()}
          setShowMarkStopAsCompletedSuccessMessage={() => null}
          setShowMarkStopAsCompletedErrorMessage={() => null}
          allStopsAreDeliveries={allStopsAreDeliveries}
          allStopsArePartnerStops={allStopsArePartnerStops}
        />
      )}
    </>
  );
};

export default memo(RoutesBulkEditStopsMenu);
