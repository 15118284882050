import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { LegFormField } from 'shared/types';
import { PartialDeep } from 'type-fest';
import { DeepStringified } from '../../../common/types';
import type { RootState } from '../../../redux/store';

export type LegFormFieldError = DeepStringified<
  PartialDeep<Omit<LegFormField, 'uuid'>> & { uuid: string }
>;

type LegErrorsStoreState = LegFormFieldError;

const legsErrorsAdapter = createEntityAdapter<LegErrorsStoreState>({
  selectId: (leg) => leg.uuid,
});

export const legsErrorsSlice = createSlice({
  name: 'legsErrors',
  initialState: legsErrorsAdapter.getInitialState(),
  reducers: {
    addLegErrors: legsErrorsAdapter.addOne,
    updateOneLegErrors: legsErrorsAdapter.updateOne,
    upsertOneLegErrors: legsErrorsAdapter.upsertOne,
    deleteLegErrors: legsErrorsAdapter.removeOne,
    updateLegErrors: legsErrorsAdapter.updateMany,
  },
});

export const { updateOneLegErrors, upsertOneLegErrors } =
  legsErrorsSlice.actions;

export const { selectById: selectLegErrorsById } =
  legsErrorsAdapter.getSelectors(
    (state: RootState) => state.orderFormLegsErrors,
  );

export default legsErrorsSlice.reducer;
