import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-enterprise';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import { FilterOperator } from '../../generated/graphql';
import { OrderFilterDatePicker } from '../orders/components/enums/order-filter-date-picker';
import {
  AGGridFilterType,
  BooleanFilterOption,
  DateFilterOption,
  DeprecatedOrderStatusFilterOption,
  FilterModel,
  MultiSelectFilterValue,
  OrderFilterField,
  SingleSelectFilterOption,
} from '../orders/components/enums/order-filters';
import {
  DispatchDateFilterOption,
  DispatchFilterType,
  DispatchRelativeDateFilterOption,
} from './dispatch-stops/types';

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IExtendedFilterParams<TData = any, TContext = any>
  extends IFilterParams<TData, TContext> {
  // Add your additional properties or methods here
  shouldShowSearch?: boolean;
  values?: MultiSelectFilterValue[];
  defaultSelectedValues?: MultiSelectFilterValue[];
  defaultFieldLevelFilterOperator?: FilterOperator | null;
  hideFilterType?: boolean;
  hideCombinationMode?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  inDispatchTable?: boolean;
}

const useStyles = () => {
  const theme = useTheme();
  return {
    filterTypeButtonWrapper: {
      marginLeft: '50px',
    },
    filterButtonContainer: {
      paddingLeft: '10px',
      width: '100%',
      paddingTop: '5px',
      paddingBottom: '5px',
    },
    filterOptionButton: {
      backgroundColor: 'white',
      width: '100%',
      color: 'black',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: theme.palette.hoverColor,
      },
    },
  };
};

const FilterOperatorSelector = ({
  setFilterOperator,
  filterOperator,
}: {
  filterOperator: FilterOperator;
  setFilterOperator: (filterOperator: FilterOperator) => void;
}) => {
  const styles = useStyles();

  return (
    <Box sx={styles.filterTypeButtonWrapper}>
      <Typography variant="body1">Filter type</Typography>
      <ButtonGroup variant="outlined" size="small" disableElevation>
        {Object.values(FilterOperator).map((operator) => (
          <Button
            key={operator}
            variant={operator === filterOperator ? 'contained' : 'outlined'}
            onClick={() => {
              setFilterOperator(operator);
            }}
          >
            {operator}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export const SingleSelectFilter = forwardRef(
  (
    {
      colDef,
      hideFilterType,
      api,
      shouldShowSearch,
      filterChangedCallback,
    }: Omit<IExtendedFilterParams, 'values'>,
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState(
      SingleSelectFilterOption.ANY as string,
    );
    const [filterOperator, setFilterOperator] = useState<FilterOperator>(
      FilterOperator.And,
    );
    const [searchText, setSearchText] = useState('');
    const styles = useStyles();

    const filteredValues: MultiSelectFilterValue[] = api
      .getColumnDef(colDef.field as string)
      ?.filterParams?.values?.filter((val: MultiSelectFilterValue) => {
        return val.displayValue
          ?.toLowerCase()
          .includes(searchText.toLowerCase());
      });

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(_params: IDoesFilterPassParams) {
          return true;
        },

        isFilterActive() {
          return (
            !isEmpty(filterValue) &&
            filterValue !== SingleSelectFilterOption.ANY
          );
        },

        getModel() {
          if (
            !isEmpty(filterValue) &&
            filterValue !== SingleSelectFilterOption.ANY
          ) {
            let getDisplayValue = api
              .getColumnDef(colDef.field as string)
              ?.filterParams.values.find(
                (val: MultiSelectFilterValue) =>
                  val.actualValue === filterValue,
              );
            // Backwards compatibility for deprecated order status filter since the values are not in the filterParams
            if (
              colDef.field === OrderFilterField.ORDER_STATUS &&
              Object.values(DeprecatedOrderStatusFilterOption).includes(
                filterValue as DeprecatedOrderStatusFilterOption,
              )
            ) {
              getDisplayValue = {
                displayValue: sentenceCase(filterValue),
              };
            }
            return {
              value:
                filterValue === SingleSelectFilterOption.ANY ? '' : filterValue,
              displayValue: getDisplayValue?.displayValue,
              filterType: AGGridFilterType.SINGLE_SELECT,
              filterOperator,
            };
          }
          return null;
        },

        setModel(model: FilterModel | null) {
          if (isNil(model)) {
            setFilterValue(SingleSelectFilterOption.ANY);
          } else {
            setFilterValue(model.value);
            setFilterOperator(model.filterOperator);
          }
        },
      };
    });

    const onSelectionChange = (filterOption: string) => {
      setFilterValue(filterOption);
    };

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    };

    useEffect(() => {
      filterChangedCallback();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, filterOperator]);

    return (
      <div className="ag-set-filter-list">
        {shouldShowSearch !== false && (
          <div className="ag-mini-filter ag-labeled ag-label-align-left">
            <input
              style={{ width: '100%' }}
              className="ag-theme-material"
              type="text"
              value={searchText}
              onChange={onSearchChange}
              placeholder="Search"
            />
          </div>
        )}
        {(isNil(hideFilterType) || hideFilterType === false) && (
          <FilterOperatorSelector
            filterOperator={filterOperator}
            setFilterOperator={setFilterOperator}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            sx={styles.filterOptionButton}
            onClick={() => onSelectionChange(SingleSelectFilterOption.ANY)}
          >
            <Box sx={styles.filterButtonContainer}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  sx={{
                    visibility:
                      filterValue === SingleSelectFilterOption.ANY
                        ? undefined
                        : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography variant="body2">
                  {SingleSelectFilterOption.ANY}
                </Typography>
              </Stack>
            </Box>
          </Button>

          {filteredValues?.map((val) => {
            return (
              <Button
                key={val.actualValue}
                sx={styles.filterOptionButton}
                onClick={() => onSelectionChange(val.actualValue)}
              >
                <Box sx={styles.filterButtonContainer}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Check
                      sx={{
                        visibility:
                          filterValue === val.actualValue
                            ? undefined
                            : 'hidden',
                        fontSize: '14px',
                        ml: 0,
                        mr: '6px',
                      }}
                    />
                    <Typography variant="body2">{val.displayValue}</Typography>
                  </Stack>
                </Box>
              </Button>
            );
          })}
        </div>
      </div>
    );
  },
);

export const MultiSelectFilter = forwardRef(
  (
    {
      values,
      defaultSelectedValues,
      defaultFieldLevelFilterOperator,
      hideFilterType,
      hideCombinationMode,
      shouldShowSearch,
      filterChangedCallback,
    }: IExtendedFilterParams,
    ref,
  ) => {
    const [fieldLevelFilterOperator, setFieldLevelFilterOperator] =
      useState<FilterOperator>(
        defaultFieldLevelFilterOperator ?? FilterOperator.And,
      );
    const [filterOperator, setFilterOperator] = useState<FilterOperator>(
      FilterOperator.And,
    );
    const [filterValues, setFilterValues] = useState<MultiSelectFilterValue[]>(
      defaultSelectedValues ?? [],
    );
    const [searchText, setSearchText] = useState('');
    const styles = useStyles();

    const filteredValues = values?.filter((val) => {
      return val.displayValue?.toLowerCase().includes(searchText.toLowerCase());
    });

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(_params: IDoesFilterPassParams) {
          return true;
        },

        isFilterActive() {
          return !isEmpty(filterValues);
        },

        getModel() {
          if (!isEmpty(filterValues)) {
            return {
              values: filterValues,
              fieldLevelFilterOperator,
              filterOperator,
              filterType: AGGridFilterType.MULTI_SELECT,
            };
          }
          return null;
        },

        setModel(model: FilterModel | null) {
          if (isNil(model)) {
            setFilterValues([]);
          } else {
            setFieldLevelFilterOperator(model.fieldLevelFilterOperator);
            setFilterOperator(model.filterOperator);
            setFilterValues(model.values);
          }
        },
      };
    });
    const onSelectionChange = (
      filterOption: MultiSelectFilterValue | SingleSelectFilterOption,
    ) => {
      if (filterOption === SingleSelectFilterOption.ANY) {
        setFilterValues([]);
      } else if (
        !isNil(
          filterValues.find(
            (filterValue) =>
              filterValue.actualValue === filterOption.actualValue,
          ),
        )
      ) {
        setFilterValues(
          filterValues.filter(
            (filterValue) =>
              filterValue.actualValue !== filterOption.actualValue,
          ),
        );
      } else {
        setFilterValues(filterValues.concat(filterOption));
      }
    };

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    };

    useEffect(() => {
      filterChangedCallback();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, fieldLevelFilterOperator, filterOperator]);

    return (
      <div className="ag-set-filter-list">
        {shouldShowSearch !== false && (
          <div className="ag-mini-filter ag-labeled ag-label-align-left">
            <input
              style={{ width: '100%' }}
              className="ag-theme-material"
              type="text"
              value={searchText}
              onChange={onSearchChange}
              placeholder="Search"
            />
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {(isNil(hideFilterType) || hideFilterType === false) && (
              <FilterOperatorSelector
                filterOperator={filterOperator}
                setFilterOperator={setFilterOperator}
              />
            )}
            {(isNil(hideCombinationMode) || hideCombinationMode === false) && (
              <Box>
                <Typography variant="body1">Combination mode</Typography>
                <ButtonGroup variant="outlined" size="small" disableElevation>
                  {Object.values(FilterOperator).map((operator) => (
                    <Button
                      key={operator}
                      variant={
                        operator === fieldLevelFilterOperator
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() => {
                        setFieldLevelFilterOperator(operator);
                      }}
                    >
                      {operator === FilterOperator.And
                        ? 'Match all'
                        : 'Match one'}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Button
              sx={styles.filterOptionButton}
              onClick={() => onSelectionChange(SingleSelectFilterOption.ANY)}
            >
              <Box sx={styles.filterButtonContainer}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Check
                    sx={{
                      visibility: isEmpty(filterValues) ? undefined : 'hidden',
                      fontSize: '14px',
                      ml: 0,
                      mr: '6px',
                    }}
                  />
                  <Typography variant="body2">
                    {SingleSelectFilterOption.ANY}
                  </Typography>
                </Stack>
              </Box>
            </Button>
            {filteredValues?.map((val) => {
              return (
                <Button
                  key={val.actualValue}
                  sx={styles.filterOptionButton}
                  onClick={() => onSelectionChange(val)}
                >
                  <Box sx={styles.filterButtonContainer}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Check
                        sx={{
                          visibility: !isNil(
                            filterValues?.find(
                              (filterValue) =>
                                filterValue.actualValue === val.actualValue,
                            ),
                          )
                            ? undefined
                            : 'hidden',
                          fontSize: '14px',
                          ml: 0,
                          mr: '6px',
                        }}
                      />
                      <Typography variant="body2">
                        {val.displayValue}
                      </Typography>
                    </Stack>
                  </Box>
                </Button>
              );
            })}
          </Box>
        </div>
      </div>
    );
  },
);

const CommonDateFilterOptionButton = ({
  value,
  filterValue,
  onSelectionChange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  value: DateFilterOption;
  filterValue: DispatchDateFilterOption;
  onSelectionChange: (value: DispatchDateFilterOption) => void;
  startDate: Date;
  endDate: Date;
  setStartDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
}) => {
  const styles = useStyles();

  if (value === DateFilterOption.DATE_RANGE) {
    return (
      <Button
        key={value}
        sx={styles.filterOptionButton}
        onClick={() =>
          onSelectionChange({
            filterType: DispatchFilterType.FIXED,
            fixedDateOption: DateFilterOption.DATE_RANGE,
            relativeDateOption: undefined,
          })
        }
      >
        <Box sx={styles.filterButtonContainer}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Check
              sx={{
                visibility:
                  filterValue.fixedDateOption === DateFilterOption.DATE_RANGE
                    ? undefined
                    : 'hidden',
                fontSize: '14px',
                ml: 0,
                mr: '6px',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="body2"> Date Range</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <OrderFilterDatePicker
                  handleChange={(date) => {
                    setStartDate(date ?? new Date());
                  }}
                  name="Start"
                  value={startDate}
                  allowNoLimit={false}
                />
                <Typography sx={{ fontSize: '14px', mx: '5px' }}>to</Typography>
                <OrderFilterDatePicker
                  handleChange={(date) => {
                    setEndDate(date ?? new Date());
                  }}
                  name="End"
                  value={endDate}
                  allowNoLimit={false}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Button>
    );
  }

  return (
    <Button
      key={value}
      sx={styles.filterOptionButton}
      onClick={() =>
        onSelectionChange({
          filterType: DispatchFilterType.FIXED,
          fixedDateOption: value,
          relativeDateOption: undefined,
        })
      }
    >
      <Box sx={styles.filterButtonContainer}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility:
                filterValue.fixedDateOption === value ? undefined : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography variant="body2">{value}</Typography>
        </Stack>
      </Box>
    </Button>
  );
};

const DispatchDateFilterOptionButton = ({
  value,
  filterValue,
  onSelectionChange,
}: {
  value: DispatchRelativeDateFilterOption;
  filterValue: DispatchDateFilterOption;
  onSelectionChange: (value: DispatchDateFilterOption) => void;
}) => {
  const styles = useStyles();

  return (
    <Button
      key={value}
      sx={styles.filterOptionButton}
      onClick={() =>
        onSelectionChange({
          filterType: DispatchFilterType.RELATIVE,
          fixedDateOption: undefined,
          relativeDateOption: value,
        })
      }
    >
      <Box sx={styles.filterButtonContainer}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility:
                filterValue.relativeDateOption === value ? undefined : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography variant="body2">{value}</Typography>
        </Stack>
      </Box>
    </Button>
  );
};

export const DateSelectFilter = forwardRef(
  (
    {
      hideFilterType,
      filterChangedCallback,
      inDispatchTable,
    }: Omit<IExtendedFilterParams, 'values'>,
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState<DispatchDateFilterOption>({
      filterType: DispatchFilterType.FIXED,
      fixedDateOption: DateFilterOption.ANY_DAY,
      relativeDateOption: undefined,
    });

    const [filterOperator, setFilterOperator] = useState<FilterOperator>(
      FilterOperator.And,
    );

    const [startDate, setStartDate] = useState(
      getCurrentTimeDefaultTimezone().toDate(),
    );

    const [endDate, setEndDate] = useState(
      getCurrentTimeDefaultTimezone().toDate(),
    );

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(_params: IDoesFilterPassParams) {
          return true;
        },

        isFilterActive() {
          return (
            !isEmpty(filterValue) &&
            filterValue.fixedDateOption !== DateFilterOption.ANY_DAY
          );
        },

        // this example isn't using getModel() and setModel(),
        // so safe to just leave these empty. don't do this in your code!!!
        getModel() {
          if (
            !isEmpty(filterValue) &&
            filterValue.fixedDateOption !== DateFilterOption.ANY_DAY
          ) {
            if (filterValue.fixedDateOption === DateFilterOption.DATE_RANGE) {
              return {
                value: filterValue,
                filterOperator,
                filterType: AGGridFilterType.DATE,
                startDate,
                endDate,
              };
            }
            return {
              value: filterValue,
              filterOperator,
              filterType: AGGridFilterType.DATE,
              startDate: null,
              endDate: null,
            };
          }
          return null;
        },

        // we always expect this to be passed in as null since we call this to delete the filter
        setModel(model: FilterModel | null) {
          if (isNil(model)) {
            setFilterValue({
              filterType: DispatchFilterType.FIXED,
              fixedDateOption: DateFilterOption.ANY_DAY,
              relativeDateOption: undefined,
            });
          } else {
            setFilterValue(model.value);
            setStartDate(model.startDate);
            setEndDate(model.endDate);
            setFilterOperator(model.filterOperator);
          }
        },
      };
    });

    const onSelectionChange = (
      selectedFilterOption: DispatchDateFilterOption,
    ) => {
      setFilterValue(selectedFilterOption);
    };

    useEffect(() => {
      filterChangedCallback();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, startDate, endDate, filterOperator]);

    const dateFilterOptions = Object.values(DateFilterOption).map((val) => (
      <CommonDateFilterOptionButton
        key={val}
        value={val}
        filterValue={filterValue}
        onSelectionChange={onSelectionChange}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    ));

    if (inDispatchTable === true) {
      dateFilterOptions.push(
        ...Object.values(DispatchRelativeDateFilterOption).map((val) => (
          <DispatchDateFilterOptionButton
            key={val}
            value={val}
            filterValue={filterValue}
            onSelectionChange={onSelectionChange}
          />
        )),
      );
    }

    return (
      <div className="ag-set-filter-list">
        {(isNil(hideFilterType) || hideFilterType === false) && (
          <FilterOperatorSelector
            filterOperator={filterOperator}
            setFilterOperator={setFilterOperator}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {dateFilterOptions}
        </div>
      </div>
    );
  },
);

export const BooleanFilter = forwardRef(
  (
    {
      hideFilterType,
      filterChangedCallback,
    }: Omit<IExtendedFilterParams, 'values'>,
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState<string | null>(null);
    const styles = useStyles();
    const [filterOperator, setFilterOperator] = useState<FilterOperator>(
      FilterOperator.And,
    );
    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(_params: IDoesFilterPassParams) {
          return true;
        },

        isFilterActive() {
          return !isNil(filterValue);
        },

        getModel() {
          if (!isNil(filterValue)) {
            return {
              value: filterValue,
              filterType: AGGridFilterType.BOOLEAN,
              filterOperator,
            };
          }
          return null;
        },

        setModel(model: FilterModel | null) {
          if (isNil(model)) {
            setFilterValue(null);
          } else {
            setFilterValue(model.value);
            setFilterOperator(model.filterOperator);
          }
        },
      };
    });

    const onSelectionChange = (selectedFilterOption: string) => {
      setFilterValue(selectedFilterOption);
    };

    useEffect(() => {
      filterChangedCallback();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, filterOperator]);

    return (
      <div className="ag-set-filter-list">
        {(isNil(hideFilterType) || hideFilterType === false) && (
          <FilterOperatorSelector
            filterOperator={filterOperator}
            setFilterOperator={setFilterOperator}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {Object.values(BooleanFilterOption).map((val) => {
            return (
              <Button
                key={val}
                sx={styles.filterOptionButton}
                onClick={() => onSelectionChange(val)}
              >
                <Box sx={styles.filterButtonContainer}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Check
                      sx={{
                        visibility: filterValue === val ? undefined : 'hidden',
                        fontSize: '14px',
                        ml: 0,
                        mr: '6px',
                      }}
                    />
                    <Typography variant="body2">{val}</Typography>
                  </Stack>
                </Box>
              </Button>
            );
          })}
        </div>
      </div>
    );
  },
);

export const TextFilter = forwardRef(
  (
    {
      hideFilterType,
      filterChangedCallback,
    }: Omit<IExtendedFilterParams, 'values'>,
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState('');
    const [searchText, setSearchText] = useState('');
    const [filterOperator, setFilterOperator] = useState<FilterOperator>(
      FilterOperator.And,
    );

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(_params: IDoesFilterPassParams) {
          return true;
        },

        isFilterActive() {
          return !isEmpty(filterValue);
        },

        getModel() {
          if (!isEmpty(filterValue))
            return {
              value: filterValue,
              filterType: AGGridFilterType.TEXT,
              filterOperator,
            };
          return null;
        },

        setModel(model: FilterModel | null) {
          if (isNil(model)) {
            setFilterValue('');
          } else {
            setFilterValue(model.value);
            setFilterOperator(model.filterOperator);
          }
        },
      };
    });

    const onClickSearch = (_event: React.MouseEvent<HTMLElement>) => {
      setFilterValue(searchText);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        setFilterValue(searchText);
      }
    };
    const onClickClear = (_event: React.MouseEvent<HTMLElement>) => {
      setSearchText('');
      setFilterValue('');
    };
    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    };

    useEffect(() => {
      filterChangedCallback();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, filterOperator]);

    return (
      <div className="ag-set-filter-list">
        {(isNil(hideFilterType) || hideFilterType === false) && (
          <FilterOperatorSelector
            filterOperator={filterOperator}
            setFilterOperator={setFilterOperator}
          />
        )}
        <div className="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
          <input
            style={{ width: '100%' }}
            className="ag-theme-material"
            type="text"
            value={searchText}
            onKeyDown={handleKeyDown}
            onChange={onSearchChange}
            placeholder="Search"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: '10px',
          }}
        >
          <Button variant="contained" onClick={onClickSearch}>
            Search
          </Button>
          <Button sx={{}} onClick={onClickClear}>
            Clear
          </Button>
        </div>
      </div>
    );
  },
);
