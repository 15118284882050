import { Tab, TabProps } from '@mui/material';
import theme from '../../../../../theme';

export const TAB_MAX_WIDTH = '150px';

const OrderTableTab = ({ label, sx, ...rest }: TabProps) => {
  return (
    <Tab
      {...rest}
      sx={{
        padding: 1,
        maxWidth: TAB_MAX_WIDTH,
        borderBottom: '2px solid transparent',
        '&[aria-selected="true"]': {
          color: theme.palette.primary.main,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          opacity: 1,
        },
        ...sx,
      }}
      label={
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {label}
        </span>
      }
    />
  );
};

export default OrderTableTab;
