import { Chip } from '@mui/material';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { EdiTransactionStatus } from '../../../../../../generated/graphql';

export type EdiTransactionStatusChipProps = {
  status: EdiTransactionStatus;
};
const EdiTransactionStatusChip = ({
  status,
}: EdiTransactionStatusChipProps) => {
  switch (status) {
    case EdiTransactionStatus.Success:
      return <Chip size="small" label="Sent" color="success" />;
    case EdiTransactionStatus.Failed:
      return <Chip size="small" label="Failed" color="error" />;
    case EdiTransactionStatus.Ignored:
      return <Chip size="small" label="Ignored" />;
    default:
      return exhaustive(status);
  }
};

export default EdiTransactionStatusChip;
