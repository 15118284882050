import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { addDays } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import GeneralDatePicker from '../../../../common/components/date-picker';
import {
  FuelBillingMethod,
  FuelProfileDocument,
  FuelProfileFragment,
  FuelProfilesDocument,
  FuelProfileType,
  useCreateFuelProfileDateRangeMutation,
  useCreateFuelProfileMutation,
  useFuelProfileLazyQuery,
  useRemoveFuelProfileMutation,
  useUpdateFuelProfileMutation,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import CreateOrEdit from '../../enums/create-or-edit';
import FuelProfileDateRangeRow, {
  DateRange,
} from './fuel-profile-date-range-row';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '20px',
    p: 2,
    pb: 0,
    width: '100%',
  } as SxProps,
};

const FuelProfileModal = ({
  createOrEdit,
  open,
  setOpen,
  selectedFuelProfile,
  setSelectedFuelProfile,
  setUpdateFuelProfileSuccessMessageVisible,
  setUpdateFuelProfileErrorMessageVisible,
}: {
  createOrEdit: CreateOrEdit;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedFuelProfile: FuelProfileFragment | undefined;
  setSelectedFuelProfile: Dispatch<
    SetStateAction<FuelProfileFragment | undefined>
  >;
  setUpdateFuelProfileSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;
  setUpdateFuelProfileErrorMessageVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const [
    showOverlappingDateRangeErrorMessage,
    setShowOverlappingDateRangeErrorMessage,
  ] = useState('');

  const [createFuelProfile] = useCreateFuelProfileMutation({
    refetchQueries: [FuelProfilesDocument],
  });

  const [updateFuelProfile] = useUpdateFuelProfileMutation({
    refetchQueries: [FuelProfilesDocument],
  });

  const [removeFuelProfile] = useRemoveFuelProfileMutation({
    refetchQueries: [FuelProfilesDocument],
  });

  const [
    getFuelProfile,
    { data: fuelProfileData, refetch: refetchFuelProfile },
  ] = useFuelProfileLazyQuery();

  useEffect(() => {
    const uuid = selectedFuelProfile?.uuid;
    if (!isNil(uuid)) {
      getFuelProfile({ variables: { findFuelProfileInput: { uuid } } });
    }
  }, [selectedFuelProfile, getFuelProfile]);

  // Used to modify existing fuel profiles.
  const [dateRanges, setDateRanges] = useState<DateRange[]>([]);

  // Start date and expiration date are only used while initially creating fuel profiles.
  const [startDate, setStartDate] = useState<Dayjs | undefined>(dayjs());
  const [expirationDate, setExpirationDate] = useState<Dayjs | undefined>(
    dayjs().add(1, 'week'),
  );
  const [name, setName] = useState('');
  const [billingType, setBillingType] = useState(FuelBillingMethod.FlatRate);
  const [type, setType] = useState(FuelProfileType.ContactSpecific);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fuelProfile = fuelProfileData?.fuelProfile?.fuelProfile;
    if (!isNil(fuelProfile)) {
      setBillingType(fuelProfile.billingType);
      setName(fuelProfile?.name ?? '');

      if (createOrEdit === CreateOrEdit.Edit) {
        const newDateRanges: DateRange[] = fuelProfile.fuelProfileDateRanges
          .sort(
            (a, b) =>
              new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
          )
          .map((fuelProfileDateRange) => ({
            uuid: fuelProfileDateRange.uuid,
            startDate: fuelProfileDateRange.startDate,
            expirationDate: fuelProfileDateRange.expirationDate,
            surchargeRate: fuelProfileDateRange.surchargeRate,
            flatRateUsdCents: fuelProfileDateRange.flatRateUsdCents,
          }));
        setDateRanges(newDateRanges);
      }
    }
  }, [fuelProfileData, createOrEdit]);

  const validateInputs = useCallback(() => {
    if (isNil(startDate)) {
      setErrorMessage('Please enter a start date.');
      return false;
    }
    if (isNil(expirationDate)) {
      setErrorMessage('Please enter an expiration date.');
      return false;
    }
    if (dayjs(startDate).isAfter(expirationDate)) {
      setErrorMessage('Start date cannot come after expiration date.');
      return false;
    }
    return true;
  }, [startDate, expirationDate]);

  const [createFuelProfileDateRange] = useCreateFuelProfileDateRangeMutation({
    refetchQueries: [FuelProfileDocument],
  });

  const handleClose = () => {
    setOpen(false);
    setSelectedFuelProfile(undefined);
  };

  const handleCreateDateRange = async () => {
    try {
      if (!isNil(selectedFuelProfile)) {
        const lastFuelProfileDateRange = dateRanges[dateRanges.length - 1];
        const lastDate = new Date(lastFuelProfileDateRange?.expirationDate);
        const resp = await createFuelProfileDateRange({
          variables: {
            createFuelProfileDateRangeInput: {
              startDate: addDays(lastDate, 1),
              expirationDate: addDays(lastDate, 8),
              fuelProfileUuid: selectedFuelProfile.uuid,
              surchargeRate: null,
              flatRateUsdCents: null,
            },
          },
        });
        const response = resp.data?.createFuelProfileDateRange;
        if (response?.success === true) {
          setShowOverlappingDateRangeErrorMessage('');
          setUpdateFuelProfileSuccessMessageVisible(true);
          await refetchFuelProfile({
            findFuelProfileInput: { uuid: selectedFuelProfile.uuid },
          });
        } else if (response?.success === false && !isEmpty(response?.error)) {
          setShowOverlappingDateRangeErrorMessage(response?.error ?? '');
        } else {
          setUpdateFuelProfileErrorMessageVisible(true);
        }
      }
    } catch (e) {
      setUpdateFuelProfileErrorMessageVisible(true);
    }
  };
  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    try {
      if (createOrEdit === CreateOrEdit.Create) {
        const res = await createFuelProfile({
          variables: {
            input: {
              startDate: startDate?.toDate(),
              expirationDate: expirationDate?.toDate(),
              surchargeRate: 0,
              flatRateUsdCents: null,
              billingType,
              type,
              name,
            },
          },
        });
        if (isNil(res.data?.createFuelProfile?.uuid)) {
          setErrorMessage(
            `This fuel profile's start and expiration date overlap with another fuel profile.`,
          );
        } else {
          setSelectedFuelProfile(undefined);
          setOpen(false);
        }
      }
      if (createOrEdit === CreateOrEdit.Edit && !isNil(selectedFuelProfile)) {
        const res = await updateFuelProfile({
          variables: {
            input: {
              uuid: selectedFuelProfile.uuid,
              startDate: startDate?.toDate(),
              expirationDate: expirationDate?.toDate(),
              name,
            },
          },
        });
        if (isNil(res.data?.updateFuelProfile?.uuid)) {
          setErrorMessage(
            `This fuel profile's start and expiration date overlap with another fuel profile.`,
          );
        } else {
          handleClose();
        }
      }
    } catch (err) {
      setErrorMessage(
        'An error occured when submitting the form, please try again.',
      );
    }
  };

  // currently hidden because this may cause bad stuff to happen. ideal solution would be to have some sort of archive/put out of use functionality
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelete = async () => {
    if (!isNil(selectedFuelProfile)) {
      const res = await removeFuelProfile({
        variables: {
          uuid: selectedFuelProfile.uuid,
        },
      });

      if (!isNil(res.errors)) {
        setErrorMessage(
          'An error occured when trying to delete the fuel profile, please try again.',
        );
      } else {
        handleClose();
      }
    }
  };

  const hasSingleDateRange = useMemo(() => {
    return dateRanges.length === 1;
  }, [dateRanges]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <Box sx={styles.modalInnerContainer}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{createOrEdit} Fuel Profile</Typography>
        </Stack>
        <Stack direction="column" gap={theme.spacing(2)} sx={{ width: '100%' }}>
          <TextField
            label="Name"
            placeholder="Name"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrorMessage('');
            }}
          />
          {createOrEdit === CreateOrEdit.Create && (
            <Select
              size="small"
              value={billingType}
              onChange={(e) =>
                setBillingType(e.target.value as FuelBillingMethod)
              }
            >
              {Object.values(FuelBillingMethod)
                .filter(
                  (billingMethod) =>
                    billingMethod !== FuelBillingMethod.AutoCalculate,
                )
                .map((fuelBillingMethod) => (
                  <MenuItem key={fuelBillingMethod} value={fuelBillingMethod}>
                    {sentenceCase(fuelBillingMethod)}
                  </MenuItem>
                ))}
            </Select>
          )}
          {createOrEdit === CreateOrEdit.Create && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Fuel profile type
                </FormLabel>
                <RadioGroup
                  name="controlled-radio-buttons-group-fuel-profile"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value as FuelProfileType);
                  }}
                >
                  <FormControlLabel
                    value={FuelProfileType.ContactSpecific}
                    control={<Radio />}
                    label="Contact specific"
                  />
                  <FormControlLabel
                    value={FuelProfileType.Global}
                    control={<Radio />}
                    label="Global"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          )}
          {createOrEdit === CreateOrEdit.Create && (
            <Stack direction="row" gap={1} alignItems="center">
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Typography>Start date</Typography>
                <GeneralDatePicker
                  text=""
                  dateNil={startDate}
                  setDateNil={setStartDate}
                />
              </Box>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <Typography>Expiration date</Typography>
                <GeneralDatePicker
                  text=""
                  dateNil={expirationDate}
                  setDateNil={setExpirationDate}
                />
              </Box>
            </Stack>
          )}
          {createOrEdit === CreateOrEdit.Edit &&
            !isNil(selectedFuelProfile) && (
              <>
                {dateRanges.map((dateRange) => {
                  return (
                    <FuelProfileDateRangeRow
                      key={dateRange.uuid}
                      fuelProfileUuid={selectedFuelProfile.uuid}
                      dateRange={dateRange}
                      billingType={billingType}
                      refetchFuelProfile={refetchFuelProfile}
                      setUpdateFuelProfileSuccessMessageVisible={
                        setUpdateFuelProfileSuccessMessageVisible
                      }
                      setUpdateFuelProfileErrorMessageVisible={
                        setUpdateFuelProfileErrorMessageVisible
                      }
                      isDeletable={!hasSingleDateRange}
                      setShowOverlappingDateRangeErrorMessage={
                        setShowOverlappingDateRangeErrorMessage
                      }
                    />
                  );
                })}
                <Button onClick={handleCreateDateRange}>Add Date Range</Button>
              </>
            )}
        </Stack>
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
        {!isEmpty(showOverlappingDateRangeErrorMessage) && (
          <Typography sx={{ color: 'red' }}>
            {showOverlappingDateRangeErrorMessage}
          </Typography>
        )}
        <DialogActions>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default FuelProfileModal;
