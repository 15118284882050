import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FilterViewPage } from '../../../generated/graphql';
import { EditSavedViewMenu } from './edit-saved-view-menu';

type EditSavedViewTabButtonAndMenuProps = {
  isViewShared: boolean;
  isUserViewCreator: boolean;
  pageType: FilterViewPage;
  handleClickRename: () => void;
  handleSetIsViewShared: (isShared: boolean) => void;
  handleClickDelete: () => void;
};

export const EditSavedViewTabButtonAndMenu = ({
  isViewShared,
  isUserViewCreator,
  pageType,
  handleClickRename,
  handleSetIsViewShared,
  handleClickDelete,
}: EditSavedViewTabButtonAndMenuProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  // Open the menu the first time its rendered
  useEffect(() => {
    if (!isNil(buttonRef)) {
      setMenuAnchorEl(buttonRef.current);
    }
  }, [buttonRef]);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton
        component="span"
        sx={{
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
          },
        }}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <EditIcon sx={{ fontSize: '16px' }} />
      </IconButton>
      {/*
        Note that this is not rendered as part of the icon button.
        Otherwise, onClick events that would normally close the menu will
        propagate to the icon button and reopen the menu.
      */}
      <EditSavedViewMenu
        anchorEl={menuAnchorEl}
        isViewShared={isViewShared}
        isUserViewCreator={isUserViewCreator}
        pageType={pageType}
        handleCloseMenu={handleCloseMenu}
        handleClickRename={handleClickRename}
        handleSetIsViewShared={handleSetIsViewShared}
        handleClickDelete={handleClickDelete}
      />
    </>
  );
};
