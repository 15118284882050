import { Stack } from '@mui/material';
import { memo } from 'react';
import {
  DetailedLineHaulManifestFragment,
  ShallowLineHaulSegmentFragment,
} from '../../../../generated/graphql';
import ManifestDriverField from '../manifest-driver-field';
import ManifestLoadSummary from '../manifest-load-summary';
import ManifestVehicleField from '../manifest-vehicle-field';
import OpenedManifestOrdersTable from './opened-manifest-orders-table/opened-manifest-orders-table';
import OpenedManifestStatusPicker from './opened-manifest-status-picker';

const OpenedManifestDetails = ({
  manifest,
  unmanifestedSegment,
}: {
  manifest: DetailedLineHaulManifestFragment | undefined;
  unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
}) => {
  return (
    <Stack minHeight={0}>
      <OpenedManifestStatusPicker manifest={manifest} />
      <Stack
        direction="row"
        p={1}
        pl={2}
        pr={2}
        justifyContent="space-between"
        gap={1}
      >
        <Stack width="100%" direction="row" gap={2}>
          <ManifestDriverField
            manifest={manifest}
            unmanifestedSegment={unmanifestedSegment}
          />
          <ManifestVehicleField
            manifest={manifest}
            unmanifestedSegment={unmanifestedSegment}
          />
        </Stack>
      </Stack>
      <Stack p={1} pl={2} pr={2}>
        <ManifestLoadSummary manifest={manifest} />
      </Stack>
      <OpenedManifestOrdersTable
        manifest={manifest}
        unmanifestedSegment={unmanifestedSegment}
      />
    </Stack>
  );
};

export default memo(OpenedManifestDetails);
