import {
  CircularProgress,
  Stack,
  Step,
  Stepper,
  Typography,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { isNil, sum } from 'lodash';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  useGetLineHaulProgressQuery,
  useLineHaulLaneQuery,
} from '../../../../../../../generated/graphql';
import { OrderFormValues } from '../../../forms/types';
import LineHaulTerminalStepLabel from './line-haul-terminal-step-label';

const QontoConnector = styled(StepConnector)(() => ({
  height: 'fit-content',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
    bottom: 0,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: '#784af4',
      borderStyle: 'solid',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 0,
    borderStyle: 'dashed',
  },
}));

const LineHaulLaneProgress = ({ isEditMode }: { isEditMode: boolean }) => {
  const { control } = useFormContext<OrderFormValues>();

  const lineHaulLaneUuid = useWatch({ control, name: 'lineHaulLaneUuid' });
  const orderUuid = useWatch({ control, name: 'uuid' });
  const packages = useWatch({ control, name: 'packages' });
  const expectedPieceCount = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.quantity));
  }, [packages]);

  const { data: laneData, loading: laneLoading } = useLineHaulLaneQuery({
    variables: {
      uuid: lineHaulLaneUuid ?? '',
    },
    skip: isNil(lineHaulLaneUuid),
  });
  const { data: progressData, loading: progressLoading } =
    useGetLineHaulProgressQuery({
      fetchPolicy: 'cache-first',
      variables: {
        getLineHaulProgressInput: {
          uuid: orderUuid,
        },
      },
    });

  if (laneLoading) {
    return (
      <Stack direction="column" justifyContent="center" p="20px" gap="20px">
        <CircularProgress />
      </Stack>
    );
  }

  if (!isNil(laneData) && isEditMode) {
    return (
      <Stack direction="column" justifyContent="center" gap={2}>
        <Typography variant="h6" fontSize="16px">
          Line haul summary
        </Typography>
        {progressLoading && <CircularProgress />}
        <Stepper
          sx={{ width: '100%', p: '0 !important' }}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {progressData?.getLineHaulProgress.terminalStatuses.map((status) => {
            return (
              <Step
                key={status.terminal.uuid}
                completed={
                  !isNil(status.manifestDepartingFromThisTerminal) ||
                  !isNil(status.markedArrivedAt) ||
                  (isNil(status.manifestDepartingFromThisTerminal) &&
                    status.arrivalScans.length >= (packages?.length ?? 0))
                }
              >
                <LineHaulTerminalStepLabel
                  status={status}
                  expectedPieceCount={expectedPieceCount}
                />
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    );
  }

  return null;
};

export default LineHaulLaneProgress;
