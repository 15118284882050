import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { ContactEntity } from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type ContactValues = Partial<
  Omit<
    ContactEntity,
    | '__typename'
    | 'addresses'
    | 'contactPersons'
    | 'contactStations'
    | 'defaultAddress'
    | 'defaultContactPerson'
    | 'defaultInvoiceDocumentsRequired'
    | 'defaultInvoiceDownloadDocuments'
    | 'defaultInvoiceType'
    | 'defaultInvoiceTransmissionMethod'
    | 'defaultServicePickupsDeliveries'
    | 'defaultService'
    | 'masterAccount'
    | 'dimFactor'
    | 'referenceNumberLabels'
  >
> & {
  addressUuids: string[];
  contactPersonUuids: string[];
  defaultAddressUuid: string | undefined | null;
  defaultContactPersonUuid: string | undefined | null;
  defaultFuelSurcharge?: number | null;
  defaultUseTariff?: boolean | null;
  defaultDimFactor?: number | null;
  uuid: string;
  serviceUuids: string[] | undefined | null;
  defaultInboundEdiServiceUuid: string | undefined | null;
  defaultOutboundEdiServiceUuid: string | undefined | null;
  defaultInboundOutboundEdiServiceUuid: string | undefined | null;
  referenceNumberLabels: string[];
  defaultServiceUuid: string | undefined | null;
  masterAccountUuid: string | undefined | null;
  businessDivisionUuid?: string | null;
  isPrepaidOnly: boolean;
  useCustomPackageSpecs: boolean;
  packageSpecIds: string[];
  useCustomVehicleTypes: boolean;
  vehicleTypeUuids: string[];
  defaultOrdinaryTariffChainId: string | null;
  defaultLineHaulTariffChainId: string | null;
  defaultTransferTariffChainId: string | null;
  defaultPointToPointTariffChainId: string | null;
};

const contactValuesAdapter = createEntityAdapter<ContactValues>({
  selectId: (contact) => contact.uuid,
});

export const contactValuesSlice = createSlice({
  name: 'contactValues',
  initialState: contactValuesAdapter.getInitialState,
  reducers: {
    addManyContactValues: contactValuesAdapter.addMany,
    addOneContactValues: contactValuesAdapter.addOne,
    updateOneContactValues: contactValuesAdapter.updateOne,
    upsertOneContactValues: contactValuesAdapter.upsertOne,
  },
});

const contactValuesReducer = contactValuesSlice.reducer;

export default contactValuesReducer;

export const {
  selectById: selectContactValuesById,
  selectEntities: selectContactValuesByIds,
  selectAll: selectContacts,
} = contactValuesAdapter.getSelectors(
  (state: RootState) => state.contactValues,
);

export const selectContactDefaultAddressUuid = createSelector(
  selectContactValuesById,
  (values) => {
    return values?.defaultAddressUuid;
  },
);

export const selectContactMasterAccountUuid = createSelector(
  selectContactValuesById,
  (values) => {
    return values?.masterAccountUuid;
  },
);

export const selectContactDefaultContactPersonUuid = createSelector(
  selectContactValuesById,
  (values) => {
    return values?.defaultContactPersonUuid;
  },
);

export const selectContactAddressUuids = createSelector(
  selectContactValuesById,
  (values) => {
    return values?.addressUuids;
  },
);

export const selectContactPersonUuids = createSelector(
  selectContactValuesById,
  (values) => {
    return values?.contactPersonUuids;
  },
);

export const {
  addOneContactValues,
  addManyContactValues,
  updateOneContactValues,
  upsertOneContactValues,
} = contactValuesSlice.actions;
