import {
  Box,
  Button,
  Fade,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  DriverSignableFormFragment,
  PermissionResource,
  useCustomDriverSignableFormsQuery,
} from '../../../generated/graphql';
import CreateOrEdit from '../enums/create-or-edit';
import CustomDriverFormsModal from './custom-driver-forms-modal';

export const CustomDriverFormsTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyCustomForms } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyCustomForms,
  );

  const [open, setOpen] = useState(false);
  const [hoveredCustomFormUuid, setHoveredCustomFormUuid] = useState<string>();
  const [selectedCustomForm, setSelectedCustomForm] = useState<
    DriverSignableFormFragment | undefined
  >(undefined);
  const [mode, setMode] = useState<CreateOrEdit>(CreateOrEdit.Create);
  const { data: customForms } = useCustomDriverSignableFormsQuery();

  return (
    <>
      <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell align="right">
                  {' '}
                  <Button
                    onClick={() => {
                      setMode(CreateOrEdit.Create);
                      setOpen(true);
                    }}
                    variant="contained"
                    disabled={!canWriteCompanyCustomForms}
                  >
                    Create Custom Form
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customForms?.customDriverForms?.map((customDriverForm) => {
                return (
                  <TableRow
                    onMouseEnter={() => {
                      setHoveredCustomFormUuid(customDriverForm.uuid);
                    }}
                    onMouseLeave={() => {
                      setHoveredCustomFormUuid(undefined);
                    }}
                    key={customDriverForm.uuid}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Box>
                        <Typography variant="body1">
                          {customDriverForm.title}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant="body1">
                          {dayjs(customDriverForm.createdAt).format(
                            'MM/DD/YYYY',
                          )}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Fade
                        in={hoveredCustomFormUuid === customDriverForm.uuid}
                      >
                        <Button
                          onClick={() => {
                            setSelectedCustomForm(
                              customDriverForm as DriverSignableFormFragment,
                            );
                            setMode(CreateOrEdit.Edit);
                            setOpen(true);
                          }}
                          variant="contained"
                          disabled={!canWriteCompanyCustomForms}
                        >
                          Edit
                        </Button>
                      </Fade>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <CustomDriverFormsModal
        selectedCustomDriverForm={selectedCustomForm}
        open={open}
        setOpen={setOpen}
        createOrEdit={mode}
      />
    </>
  );
};

export default CustomDriverFormsTable;
