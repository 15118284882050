import { CircularProgress } from '@mui/material';
import { CSSProperties, FunctionComponent } from 'react';

type CenteredCircularProgressProps = {
  size?: number;
  style?: CSSProperties;
};

const CenteredCircularProgress: FunctionComponent<
  CenteredCircularProgressProps
> = ({ size, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <CircularProgress size={size} />
    </div>
  );
};

export default CenteredCircularProgress;
