import { Menu, MenuItem, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LabelType } from '../../../../../generated-documents/utils';
import PrintArrivalNoticeModal from '../../../standard/components/standard-order/modals/print-arrival-notice-modal';
import PrintOrderAirWaybillModal from '../../../standard/components/standard-order/modals/print-order-air-waybill-modal';
import PrintOrderCoverSheetModalNew from '../../../standard/components/standard-order/modals/print-order-cover-sheet-modal-new';
import PrintOrderDeliveryReceiptModal from '../../../standard/components/standard-order/modals/print-order-delivery-receipt-modal';
import PrintPodReportModal from '../../../standard/components/standard-order/modals/print-pod-report-modal';
import PrintQuoteAirWaybillModal from '../../../standard/components/standard-order/modals/print-quote-air-waybill-modal';
import PrintQuoteAuthoSheetModal from '../../../standard/components/standard-order/modals/print-quote-autho-sheet-modal';
import DownloadInventoryLabelsModal from './components/download-inventory-labels-modal';
import PrintBol from './components/print-bol';

const PrintMenu = ({
  showPrintMenu,
  onClose,
  buttonRef,
  isEditMode,
  isFromQuotesPage = false,
}: {
  showPrintMenu: boolean;
  onClose: () => void;
  buttonRef: HTMLButtonElement | null;
  isEditMode: boolean;
  isFromQuotesPage?: boolean;
}) => {
  const { control } = useFormContext();
  const orderUuid = useWatch({ control, name: 'uuid' });
  const packages = useWatch({ control, name: 'packages' });

  const [showPrintArrivalNoticeModal, setShowPrintArrivalNoticeModal] =
    useState(false);
  const [showPrintCoverSheetModal, setShowPrintCoverSheetModal] =
    useState(false);
  const [showDownloadPodReportModal, setShowDownloadPodReportModal] =
    useState(false);
  const [selectedLabelType, setSelectedLabelType] = useState<
    LabelType | undefined
  >(undefined);

  const [showPrintDeliveryReceiptModal, setShowPrintDeliveryReceiptModal] =
    useState(false);
  const [showPrintAuthoSheetModal, setShowPrintAuthoSheetModal] =
    useState(false);
  const [showPrintQuoteAirWaybillModal, setShowPrintQuoteAirWaybillModal] =
    useState(false);
  const [showPrintOrderAirWaybillModal, setShowPrintOrderAirWaybillModal] =
    useState(false);

  type LabelPrintOption = {
    type: LabelType;
    visibilityCondition?: boolean;
  };

  const labelOptions: LabelPrintOption[] = [
    { type: LabelType.OutboundLabel },
    { type: LabelType.ContainerLabel },
    { type: LabelType.LotLabel },
  ];

  return (
    <>
      {showPrintCoverSheetModal && (
        <PrintOrderCoverSheetModalNew
          orderUuid={orderUuid}
          isOpen={showPrintCoverSheetModal}
          setIsOpen={setShowPrintCoverSheetModal}
          isEditMode={isEditMode}
        />
      )}

      <PrintPodReportModal
        isOpen={showDownloadPodReportModal}
        setIsOpen={setShowDownloadPodReportModal}
        isEditMode={isEditMode}
      />

      {!isNil(orderUuid) && (
        <PrintArrivalNoticeModal
          isOpen={showPrintArrivalNoticeModal}
          setIsOpen={setShowPrintArrivalNoticeModal}
          isQuote={isFromQuotesPage}
        />
      )}
      {!isNil(selectedLabelType) && (
        <DownloadInventoryLabelsModal
          open={!isNil(selectedLabelType)}
          onClose={() => setSelectedLabelType(undefined)}
          labelType={selectedLabelType}
        />
      )}
      <PrintOrderDeliveryReceiptModal
        isOpen={showPrintDeliveryReceiptModal}
        setIsOpen={setShowPrintDeliveryReceiptModal}
      />
      <PrintQuoteAuthoSheetModal
        isOpen={showPrintAuthoSheetModal}
        setIsOpen={setShowPrintAuthoSheetModal}
      />
      <PrintQuoteAirWaybillModal
        isOpen={showPrintQuoteAirWaybillModal}
        setIsOpen={setShowPrintQuoteAirWaybillModal}
      />
      <PrintOrderAirWaybillModal
        isOpen={showPrintOrderAirWaybillModal}
        setIsOpen={setShowPrintOrderAirWaybillModal}
      />
      <Menu anchorEl={buttonRef} open={showPrintMenu} onClose={onClose}>
        {!isFromQuotesPage && (
          <MenuItem
            key="arrival-notice"
            onClick={() => {
              setShowPrintArrivalNoticeModal(true);
            }}
          >
            Arrival notice
          </MenuItem>
        )}
        {isEditMode && <PrintBol />}
        {isFromQuotesPage && (
          <MenuItem
            key="autho-sheet"
            onClick={() => {
              setShowPrintAuthoSheetModal(true);
            }}
          >
            Autho sheet
          </MenuItem>
        )}
        {isFromQuotesPage ? (
          <MenuItem
            key="air-waybill"
            onClick={() => {
              setShowPrintQuoteAirWaybillModal(true);
            }}
          >
            Air waybill
          </MenuItem>
        ) : (
          <MenuItem
            key="air-waybill"
            onClick={() => {
              setShowPrintOrderAirWaybillModal(true);
            }}
          >
            Air waybill
          </MenuItem>
        )}
        <MenuItem
          key="cover-sheet"
          onClick={() => {
            setShowPrintCoverSheetModal(true);
          }}
        >
          Cover sheet
        </MenuItem>
        {isEditMode && (
          <MenuItem
            key="pod-report"
            onClick={() => {
              setShowDownloadPodReportModal(true);
            }}
          >
            PoD report
          </MenuItem>
        )}
        <MenuItem
          key="delivery-receipt"
          onClick={() => {
            setShowPrintDeliveryReceiptModal(true);
          }}
        >
          Delivery Receipt
        </MenuItem>
        {labelOptions
          .filter((opt) => opt.visibilityCondition !== false)
          .map((option) => (
            <Tooltip
              key={option.type}
              title={
                !isNil(packages) && packages.length > 0
                  ? ''
                  : 'Add packages to print labels'
              }
              placement="left"
            >
              <span>
                <MenuItem
                  disabled={isNil(packages) || packages.length === 0}
                  onClick={() => {
                    setSelectedLabelType(option.type);
                  }}
                >
                  {option.type}
                </MenuItem>
              </span>
            </Tooltip>
          ))}
      </Menu>
    </>
  );
};

export default PrintMenu;
