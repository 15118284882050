import AssessmentIcon from '@mui/icons-material/Assessment';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import OrdersAuditModal from '../../audit-report/audit-modal';
import useAuditReportStore from '../../audit-report/audit-report-store';

interface ReportsContextMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ReportsContextMenu = ({ anchorEl, onClose }: ReportsContextMenuProps) => {
  const [showOrderAuditModal, setShowOrderAuditModal] =
    useState<boolean>(false);
  const currentBucket = useAuditReportStore((state) => state.currentBucket);

  useEffect(() => {
    if (showOrderAuditModal === false && !isNil(currentBucket)) {
      setShowOrderAuditModal(true);
    }
  }, [currentBucket]);

  return (
    <>
      <Menu
        id="orders-context-menu-more-options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        sx={{ top: '10px' }}
      >
        <MenuItem
          onClick={() => {
            setShowOrderAuditModal(true);
            onClose();
          }}
        >
          <ListItemIcon>
            <AssessmentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View audit report</ListItemText>
        </MenuItem>
      </Menu>
      <OrdersAuditModal
        isOpen={showOrderAuditModal}
        setIsOpen={setShowOrderAuditModal}
      />
    </>
  );
};

export default ReportsContextMenu;
