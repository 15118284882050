import React, { Dispatch, SetStateAction } from 'react';
import ReactMapGL, { ViewStateChangeEvent } from 'react-map-gl';
import useLocalStorageState from 'use-local-storage-state';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { StopFragment } from '../../../generated/graphql';
import StopMarker from '../map/components/stop-marker';
import { pinTextForStopType } from '../map/util';

const GenerateRoutesPreviewStopsMap = ({
  stops,
  setSelectedStopUuids,
}: {
  stops: StopFragment[];
  setSelectedStopUuids: Dispatch<SetStateAction<string[]>>;
}) => {
  const ffDispatchTrackParity = useFeatureFlag(
    FeatureFlag.FF_DISPATCH_TRACK_PARITY,
  );
  const [viewState, setViewState] = useLocalStorageState(
    'route_map_initial_view_state',
    {
      defaultValue: {
        longitude: 0,
        latitude: 0,
        zoom: 0,
      },
    },
  );

  const handleMapMove = (e: ViewStateChangeEvent) => {
    setViewState({ ...viewState, ...e.viewState });
  };

  return (
    <ReactMapGL
      latitude={viewState.latitude}
      longitude={viewState.longitude}
      zoom={viewState.zoom}
      onMove={handleMapMove}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
      doubleClickZoom={false}
      boxZoom={!ffDispatchTrackParity}
    >
      {stops?.map((stop) => (
        <StopMarker
          key={stop.stop.uuid}
          color="#000000"
          stop={stop}
          ordinal={0}
          text={pinTextForStopType(stop.stop.stopType)}
          onClick={() => {
            setSelectedStopUuids((prevState) => {
              if (!prevState.some((s) => s === stop.stop.uuid)) {
                return [...prevState, stop.stop.uuid];
              }
              return prevState;
            });
          }}
        />
      ))}
    </ReactMapGL>
  );
};

export default React.memo(GenerateRoutesPreviewStopsMap);
