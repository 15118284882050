import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Tooltip,
} from '@mui/material';
import { debounce, isNil } from 'lodash';
import React, { memo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import TagsDropdownField from '../../../../common/components/tags-dropdown-field';
import { Size } from '../../../../common/types';
import useDispatchStore from '../../dispatch-store';
import { RouteStopTypeFilter } from './route-stop-type-filter';

const RouteFilterMenuButton = ({
  showSearchTextField,
}: {
  showSearchTextField?: boolean;
}) => {
  const [filterMenuRef, setFilterMenuRef] = useState<HTMLElement | null>(null);
  const [
    routeSearchText,
    setRouteSearchText,
    filteredTagUuids,
    setFilteredTagUuids,
  ] = useDispatchStore(
    (state) => [
      state.routeSearchText,
      state.setRouteSearchText,
      state.filteredTagUuids,
      state.setFilteredTagUuids,
    ],
    shallow,
  );

  const updateSearchText = debounce((value: string) => {
    setRouteSearchText(value);
  }, 300);

  return (
    <>
      <Tooltip title="Filters">
        <IconButton
          onClick={(e) => {
            setFilterMenuRef(e.currentTarget);
          }}
        >
          <FilterAltIcon />
        </IconButton>
      </Tooltip>
      {!isNil(filterMenuRef) && (
        <Menu
          anchorEl={filterMenuRef}
          open={!isNil(filterMenuRef)}
          onClose={() => {
            setFilterMenuRef(null);
          }}
        >
          <MenuList dense sx={{ width: 200 }}>
            {showSearchTextField === true && (
              <MenuItem>
                <TextField
                  className="dispatch-search-input"
                  variant="standard"
                  size="small"
                  placeholder="Search routes"
                  autoComplete="off"
                  sx={{ minWidth: 75, mb: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={routeSearchText}
                  onChange={(e) => {
                    updateSearchText(e.target.value);
                  }}
                />
              </MenuItem>
            )}
            <MenuItem>
              <RouteStopTypeFilter />
            </MenuItem>
            <MenuItem>
              <TagsDropdownField
                size={Size.sm}
                tagUuids={filteredTagUuids}
                onChange={(tagUuids) => {
                  setFilteredTagUuids(tagUuids);
                }}
              />
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default memo(RouteFilterMenuButton);
