import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { safeDivide } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import {
  LINE_HAUL_FUEL_CHARGE_NEW_RATE_AUTO_APPLIED_MESSAGE_TEST_ID,
  LINE_HAUL_FUEL_CHARGE_TOTAL_TEST_ID,
} from '../../../../../../../../../constants';
import { getLineHaulFuelChargeTestIds } from '../../../../../../../../../utils';
import {
  FuelBillingMethod,
  FuelProfileDateRangeFragment,
  LineHaulFuelChargeFuelProfileDateRangeFragment,
  TariffFragment,
  useFuelProfilesLazyQuery,
  useLineHaulFuelChargeFuelProfileDateRangeLazyQuery,
  useTariffLazyQuery,
} from '../../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';
import { OrderFormFieldValues } from '../../../../types';
import { getOutboundStop } from '../../../../utils';
import { buildNewFuelProfileAppliedMessage } from '../../utils';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from '../description-component';
import FuelChargeRateInput from '../fuel-charges/fuel-charge-rate-input';

const LineHaulFuelChargeRow = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.has('orderUuid');

  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const stopValues = useWatch({ control, name: 'stops' }) ?? [];

  const freightChargeKey = `lineHaulShipment.freightCharge`;

  const [tariff, setTariff] = useState<TariffFragment | undefined>(undefined);
  const fuelChargeKey = 'lineHaulShipment.freightCharge.fuelCharge';
  const fuelCharge = useWatch({ control, name: fuelChargeKey });
  const fuelChargeUuid = fuelCharge?.uuid;
  const billingMethod: FuelBillingMethod | undefined = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.fuelCharge.billingMethod`,
  });
  const setFuelBillingMethod = (newBillingMethod: FuelBillingMethod) =>
    setValue(
      `lineHaulShipment.freightCharge.fuelCharge.billingMethod`,
      newBillingMethod,
    );

  const totalCharge = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.fuelCharge.totalCharge`,
  });

  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { shouldRateOrder, chargesFinalized } = useShouldRateOrder({
    detailedStatus,
  });

  const outboundStop = getOutboundStop(stopValues);
  const contactUuid = useWatch({ control, name: 'contactUuid' });
  const type = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.fuelCharge.billingMethod`,
  });

  const dateForFuelCharge: Date | null | undefined = useWatch({
    control,
    name: `lineHaulShipment.dateForFuelCharge`,
  });

  const tariffUuid: string | null | undefined = useWatch({
    control,
    name: `${freightChargeKey}.tariffUuid`,
  });

  const [getLineHaulFuelChargeFuelProfileDateRange] =
    useLineHaulFuelChargeFuelProfileDateRangeLazyQuery();

  const surchargeRateDollars = useWatch({
    control,
    name: `${fuelChargeKey}.surchargeRate`,
  });
  const savedSurchargeRate = useRef(surchargeRateDollars);

  const flatRateDollars = useWatch({
    control,
    name: `${fuelChargeKey}.flatRateDollars`,
  });

  const [surchargeRateInput, setSurchargeRateInput] = useState(
    surchargeRateDollars?.toString() ?? '',
  );
  const [flatRateInput, setFlatRateInput] = useState(
    flatRateDollars?.toString() ?? '',
  );

  // Set the surcharge rate upon any order change in the billing review modal
  useEffect(() => {
    if (!isNil(fuelChargeUuid)) {
      setSurchargeRateInput(surchargeRateDollars?.toString() ?? '');
      setFlatRateInput(flatRateDollars?.toString() ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuelChargeUuid]);

  const [fuelProfileDateRangeToUse, setFuelProfileDateRangeToUse] = useState<
    | FuelProfileDateRangeFragment
    | LineHaulFuelChargeFuelProfileDateRangeFragment
    | undefined
  >();
  const [getFuelProfiles, { data: fuelProfileData }] =
    useFuelProfilesLazyQuery();
  const [getTariffByUuid] = useTariffLazyQuery();

  const fetchTariff = async () => {
    if (!isNil(tariffUuid) && !isEmpty(tariffUuid)) {
      const res = await getTariffByUuid({
        variables: {
          uuid: tariffUuid,
        },
      });
      setTariff(res.data?.tariff);
    }
  };

  useEffect(() => {
    fetchTariff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffUuid]);

  useEffect(() => {
    getFuelProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeBillingMethod = (event: SelectChangeEvent<string>) => {
    setValue(
      `${fuelChargeKey}.billingMethod`,
      event.target.value as FuelBillingMethod,
    );
  };

  useEffect(() => {
    if (!isEmpty(fuelProfileData?.fuelProfiles)) {
      setFuelBillingMethod(FuelBillingMethod.AutoCalculate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuelProfileData]);

  const fetchLineHaulFuelChargeFuelProfileDateRange = async () => {
    const response = await getLineHaulFuelChargeFuelProfileDateRange({
      variables: {
        lineHaulFuelChargeFuelProfileDateRangeInput: {
          contactUuid,
          tariffGroupFuelProfileUuid: tariff?.tariffGroup?.fuelProfile?.uuid,
          lineHaulCompletedDate: dateForFuelCharge,
        },
      },
    });

    const fuelProfileDateRange =
      response?.data?.lineHaulFuelChargeFuelProfileDateRange
        ?.fuelProfileDateRange;

    if (!isNil(fuelProfileDateRange)) {
      setFuelProfileDateRangeToUse(fuelProfileDateRange);
    }

    return fuelProfileDateRange;
  };

  useEffect(() => {
    //  If the charges are finalized and/or the order is on an unposted invoice, we do not want to reselect a fuel profile.
    const shouldSelectFuelProfile = shouldRateOrder && !chargesFinalized;
    if (
      billingMethod === FuelBillingMethod.AutoCalculate &&
      shouldSelectFuelProfile
    ) {
      fetchLineHaulFuelChargeFuelProfileDateRange();
    } else {
      setFuelBillingMethod(billingMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fuelProfileData?.fuelProfiles.length,
    billingMethod,
    tariff,
    contactUuid,
    dateForFuelCharge,
    shouldRateOrder,
    chargesFinalized,
  ]);

  useEffect(() => {
    const shouldSelectFuelProfile = shouldRateOrder && !chargesFinalized;
    if (
      billingMethod === FuelBillingMethod.AutoCalculate &&
      shouldSelectFuelProfile
    ) {
      const surchargeRate = fuelProfileDateRangeToUse?.surchargeRate;
      const flatRate = fuelProfileDateRangeToUse?.flatRateUsdCents;
      setFuelBillingMethod(FuelBillingMethod.AutoCalculate);

      setValue(`${fuelChargeKey}.surchargeRate`, surchargeRate ?? null);
      setSurchargeRateInput(!isNil(surchargeRate) ? String(surchargeRate) : '');
      setValue(
        `${fuelChargeKey}.flatRateDollars`,
        !isNil(flatRate) ? safeDivide(flatRate, 100) : null,
      );
      setFlatRateInput(
        !isNil(flatRate) ? String(safeDivide(flatRate, 100)) : '',
      );
    } else {
      setFuelBillingMethod(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fuelProfileDateRangeToUse?.uuid,
    billingMethod,
    type,
    shouldRateOrder,
    chargesFinalized,
    outboundStop?.status,
  ]);

  const {
    lineHaulFuelChargeTypeSelectTestId,
    lineHaulFuelChargeRateInputTestId,
  } = getLineHaulFuelChargeTestIds();

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  return (
    <TableRow>
      <TableCell>
        <FormControl sx={MT_IF_HAS_DESCRIPTION_STYLES}>
          <InputLabel>Fuel</InputLabel>
          <Select
            data-testid={lineHaulFuelChargeTypeSelectTestId}
            inputProps={{ 'aria-label': 'Fuel charge select' }}
            size="small"
            value={billingMethod}
            required
            sx={{ width: '200px' }}
            onChange={onChangeBillingMethod}
            label="Fuel"
            disabled={disabledIfFinalizedOrLater}
          >
            {Object.values(FuelBillingMethod).map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DescriptionComponent
          keyString={fuelChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
      <TableCell>
        <FuelChargeRateInput
          billingMethod={billingMethod}
          surchargeRateInput={surchargeRateInput}
          setSurchargeRateInput={setSurchargeRateInput}
          flatRateInput={flatRateInput}
          setFlatRateInput={setFlatRateInput}
          disabled={disabledIfFinalizedOrLater}
          setSurchargeRateInForm={(rate: number) => {
            setValue(`${fuelChargeKey}.surchargeRate`, rate);
          }}
          setFlatRateDollarsInForm={(rate: number) => {
            setValue(`${fuelChargeKey}.flatRateDollars`, rate);
          }}
          fuelChargeRateInputTestId={lineHaulFuelChargeRateInputTestId}
        />
        {isEditMode &&
          billingMethod === FuelBillingMethod.AutoCalculate &&
          !isNil(savedSurchargeRate.current) &&
          !isNilOrEmptyString(surchargeRateInput) &&
          savedSurchargeRate.current !== parseFloat(surchargeRateInput) && (
            <Typography
              data-test-id={
                LINE_HAUL_FUEL_CHARGE_NEW_RATE_AUTO_APPLIED_MESSAGE_TEST_ID
              }
              sx={{ color: theme.palette.primary.main, fontSize: '14px' }}
            >
              {buildNewFuelProfileAppliedMessage({
                originalSurchargeRate: !isNil(savedSurchargeRate?.current)
                  ? savedSurchargeRate.current.toString()
                  : '-',
              })}
            </Typography>
          )}
      </TableCell>
      <TableCell>1</TableCell>
      <TableCell>
        <Typography data-testid={LINE_HAUL_FUEL_CHARGE_TOTAL_TEST_ID}>
          {!isNil(totalCharge) ? currency(totalCharge).format() : '-'}{' '}
        </Typography>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default React.memo(LineHaulFuelChargeRow);
