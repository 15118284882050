import { TextField, Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

const GeneralDatePicker = ({
  date,
  dateNil,
  setDate,
  setDateNil,
  text,
  color,
  isLarge,
  width,
}: {
  date?: Dayjs;
  dateNil?: Dayjs | undefined;
  setDate?: (newPlanningDate: Dayjs) => void;
  setDateNil?: Dispatch<SetStateAction<Dayjs | undefined>>;
  text?: string;
  color?: string;
  isLarge?: boolean;
  width?: string | number;
}) => {
  // Disable any typing into the date picker
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <DatePicker
          onChange={(newDate) => {
            // TODO: Have a visual indicator to the user when the date they entered is invalid.
            if (newDate !== null) {
              if (!isNil(setDate)) {
                setDate(newDate);
              } else if (!isNil(setDateNil)) {
                setDateNil(newDate);
              }
            }
          }}
          label={text}
          renderInput={(params) => (
            <TextField
              aria-label="date-picker"
              sx={{
                width: width ?? 200,
                height: '40px',
                backgroundColor: color,
              }}
              size={isLarge === true ? 'medium' : 'small'}
              onKeyDown={onKeyDown}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
          value={!isNil(date) ? date : dateNil ?? null}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default GeneralDatePicker;
