// eslint-disable-next-line no-restricted-imports
import { Grid, useTheme } from '@mui/material';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import DocumentThumbnail from './document-thumbnail';
import { UploadDocument } from './pdf-document-thumbnail';

const BorderedDiv = styled.div<{ color: string }>`
  border: 2px dashed ${({ color }) => color};
  border-radius: 5px;
`;

interface DocumentThumbnailsProps {
  thumbnailDocuments: UploadDocument[];
  updateDocumentAttributes: (
    attribute: UploadDocument['attributes'],
    documentIndex: number,
    pageIndex?: number,
  ) => void;
}

const DocumentThumbnails = ({
  thumbnailDocuments,
  updateDocumentAttributes,
}: DocumentThumbnailsProps) => {
  const theme = useTheme();
  return (
    <Grid gap={1} container position="relative">
      {thumbnailDocuments.map((document, documentIndex) => (
        <Fragment key={`${document.file.name}`}>
          <BorderedDiv color="transparent">
            <DocumentThumbnail
              key={document.file.name}
              documentIndex={documentIndex}
              document={document}
              updateDocumentAttributes={updateDocumentAttributes}
            />
          </BorderedDiv>
          {document.attributes.documentType === 'MULTIPLE_PAGES' &&
            document.attributes.pages?.map((page, pageIndex) => (
              <BorderedDiv
                color={theme.palette.paleOrange.main}
                key={`${document.file.name}-${page.file.name}`}
              >
                <DocumentThumbnail
                  key={page.file.name}
                  documentIndex={documentIndex}
                  pageIndex={pageIndex}
                  document={page}
                  updateDocumentAttributes={updateDocumentAttributes}
                />
              </BorderedDiv>
            ))}
        </Fragment>
      ))}
    </Grid>
  );
};

export default DocumentThumbnails;
