import { isEmpty, parseInt } from 'lodash';
import { Resolver, useForm } from 'react-hook-form';

export type SpecifyBolNumberPagesFormValues = {
  startPage: string;

  endPage: string;
};

const resolver: Resolver<SpecifyBolNumberPagesFormValues> = async (
  formValues,
) => {
  const errors: {
    [property in keyof SpecifyBolNumberPagesFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  if (isEmpty(formValues.startPage)) {
    errors.startPage = {
      type: 'required',
      message: 'Start page is required',
    };
  }

  if (isEmpty(formValues.endPage)) {
    errors.endPage = {
      type: 'required',
      message: 'End page is required',
    };
  }

  const startPageNumber = parseInt(formValues.startPage);
  const endPageNumber = parseInt(formValues.endPage);

  if (startPageNumber < 0) {
    errors.startPage = {
      type: 'required',
      message: 'Start page cannot be negative',
    };
  }

  if (endPageNumber < 0) {
    errors.endPage = {
      type: 'required',
      message: 'End page cannot be negative',
    };
  }

  if (startPageNumber > endPageNumber) {
    errors.startPage = {
      type: 'required',
      message: 'Start page cannot be greater than end page',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useSpecifyBolNumberPagesForm = () => {
  return useForm({ resolver });
};

export default useSpecifyBolNumberPagesForm;
