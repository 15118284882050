import { Add } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { Button, Grid, TextField, useTheme } from '@mui/material';
import {
  ColDef,
  FilterChangedEvent,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SideBarDef,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { isNil } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import styled from 'styled-components';
import apolloClient from '../../../../apollo-client';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import { DateOption } from '../../../../common/components/date-dropdown-picker';
import { Option } from '../../../../common/filters/types';
import {
  FilterOperator,
  FilterViewPage,
  FindStorageOrderStorageUnitsFragment,
  StorageOrderFragment,
  StorageOrdersDocument,
  StorageOrdersQuery,
  StorageOrdersQueryVariables,
  StringFilterType,
  useWmsContactsQuery,
} from '../../../../generated/graphql';
import {
  DateFilterOption,
  FilterModel,
  convertDateFilterOptionToDateFilters,
} from '../../../orders/components/enums/order-filters';
import SelectedFilterButtonForToolbar from '../../../orders/components/selected-filter-button-for-toolbar';
import StorageUnitLabelsModal from '../../../storage-orders/components/common/modals/storage-unit-labels-modal';
import WarehouseSelector from '../../../storage-orders/components/common/warehouse-selector';
import UploadStorageOrdersFromCsvModal from '../../../storage-orders/components/upload-storage-orders-from-csv-modal';
import { StorageOrdersFilterField } from '../../../storage-orders/utils';
import useFilterStore from '../../filter-store';

const StorageOrdersFilterRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  mt: 10px;
  p: 7px;
  width: 100%;
`;

const useStyles = () => {
  const theme = useTheme();
  return {
    mainColor: {
      color: theme.palette.primary.main,
    },
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
      padding: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    totalText: {
      ml: '4px',
      mb: '2px',
      color: 'black',
      fontSize: '15px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
    createButtonRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 2,
    },
  };
};

interface State {
  searchText: string;
  currentCursor: string | null | undefined;
  customFilterModelJson: FilterModel;
  currentFilterViewUuid: string | null;
  currentFilterViewName: string | null;
  customerOption: Option | undefined;
  warehouseOption: Option | undefined;
  dateOption: DateOption | undefined;
}

const getFetchStorageOrdersQueryVariables = ({
  filterModel,
  customerOption,
  warehouseOption,
}: {
  filterModel: FilterModel;
  customerOption?: Option;
  warehouseOption?: Option;
}): StorageOrdersQueryVariables => {
  let result: StorageOrdersQueryVariables = {
    findStorageOrdersInput: {
      contactUuidFilter:
        customerOption?.value != null
          ? {
              filterType: StringFilterType.Equals,
              value: customerOption.value,
              filterOperator: FilterOperator.And,
            }
          : undefined,
      warehouseUuidFilter:
        warehouseOption?.value != null
          ? {
              filterType: StringFilterType.Equals,
              value: warehouseOption.value,
              filterOperator: FilterOperator.And,
            }
          : undefined,
    },
  };
  const addFilterFromFilterModelToResult = (
    currentResult: StorageOrdersQueryVariables,
    filterField: StorageOrdersFilterField,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    filterModelValue: any,
  ): StorageOrdersQueryVariables => {
    switch (filterField) {
      case StorageOrdersFilterField.ARRIVED_AT: {
        const { value } = filterModelValue;
        if (isNil(value)) {
          return {
            ...currentResult,
          };
        }
        const currentFindStorageOrdersInput =
          currentResult.findStorageOrdersInput;
        return {
          findStorageOrdersInput: {
            ...currentFindStorageOrdersInput,
            arrivedAtDateFilters: convertDateFilterOptionToDateFilters({
              dateFilterOption: filterModelValue.value as DateFilterOption,
              startDate: filterModelValue.startDate as Date,
              endDate: filterModelValue.endDate as Date,
            }),
          },
        };
      }
      case StorageOrdersFilterField.EXPECTED_AT: {
        const { value } = filterModelValue;
        if (isNil(value)) {
          return {
            ...currentResult,
          };
        }
        const currentFindStorageOrdersInput =
          currentResult.findStorageOrdersInput;
        return {
          findStorageOrdersInput: {
            ...currentFindStorageOrdersInput,
            expectedAtDateFilters: convertDateFilterOptionToDateFilters({
              dateFilterOption: filterModelValue.value as DateFilterOption,
              startDate: filterModelValue.startDate as Date,
              endDate: filterModelValue.endDate as Date,
            }),
          },
        };
      }
      default:
        return { ...currentResult };
    }
  };

  Object.keys(filterModel).forEach((key) => {
    const value = filterModel[key];
    result = addFilterFromFilterModelToResult(
      result,
      key as StorageOrdersFilterField,
      value,
    );
  });

  return result;
};

interface StorageOrdersTableProps {
  columnDefinitions: ColDef<StorageOrderFragment>[];
  pageSize: number;

  pageType: FilterViewPage;
  shouldRememberFilters?: boolean;

  storageUnitLabelsModalOpen: boolean;

  setStorageUnitLabelsModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedStorageOrderForLabels: StorageOrderFragment | undefined;

  selectedStorageUnitsForLabels: FindStorageOrderStorageUnitsFragment[];
}

const StorageOrdersTableWithFiltersAgGrid = ({
  columnDefinitions,
  pageSize,
  pageType,
  shouldRememberFilters = false,
  storageUnitLabelsModalOpen,
  setStorageUnitLabelsModalOpen,
  selectedStorageOrderForLabels,
  selectedStorageUnitsForLabels,
}: StorageOrdersTableProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const gridRef = useRef<AgGridReact<StorageOrderFragment>>(null);
  const toolPanelVisibleRef = useRef(false);

  const { data: wmsContactsData } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    rememberedFilters,
    setRememberedFilters,
    rememberedSearch,
    setRememberedSearch,
  } = useFilterStore((state) => {
    return {
      rememberedSearch: state.search,
      rememberedFilters: state.filters,
      setRememberedFilters: state.setFilters,
      setRememberedSearch: state.setSearch,
    };
  });

  const [state, setState, stateRef] = useStateRef<State>({
    searchText:
      shouldRememberFilters === true ? (rememberedSearch[pageType] ?? '') : '',
    currentCursor: null,
    customFilterModelJson: {},
    currentFilterViewName: null,
    currentFilterViewUuid: null,
    customerOption: undefined,
    warehouseOption: undefined,
    dateOption: undefined,
  });
  const [searching, setSearching] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] =
    useState<ColDef<StorageOrderFragment>[]>(columnDefinitions);
  const [
    isUploadStorageOrdersFromCsvModalOpen,
    setIsUploadStorageOrdersFromCsvModalOpen,
  ] = useState(false);

  const createServerSideDatasource = () => {
    return {
      getRows(params: IServerSideGetRowsParams<StorageOrderFragment>) {
        let variables = getFetchStorageOrdersQueryVariables({
          filterModel: params.request.filterModel,
          customerOption: stateRef.current.customerOption,
          warehouseOption: stateRef.current.warehouseOption,
        });
        const { findStorageOrdersInput } = variables;
        const newFindStorageOrdersInput = {
          ...findStorageOrdersInput,
          first: pageSize,
          after: stateRef.current.currentCursor,
          searchText: stateRef.current.searchText?.trim(),
        };
        variables = { findStorageOrdersInput: newFindStorageOrdersInput };
        apolloClient
          .query<StorageOrdersQuery, StorageOrdersQueryVariables>({
            query: StorageOrdersDocument,
            variables,
          })
          .then((res) => res.data)
          .then((data) => {
            setState((prevState) => {
              return {
                ...prevState,
                currentCursor:
                  data?.storageOrders.storageOrderConnection.pageInfo.endCursor,
              };
            });
            params.success({
              rowData:
                data?.storageOrders.storageOrderConnection.edges.map(
                  (edge) => edge.node,
                ) ?? [],
              rowCount:
                data?.storageOrders.storageOrderConnection.totalCount ??
                undefined,
            });
          });
      },
    };
  };

  // call this if we want to refresh the grid (filters change, etc.)
  const refreshGrid = (refreshServerSide = true) => {
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.showNoRowsOverlay();
      if (refreshServerSide) {
        gridRef.current?.api.refreshServerSide({ purge: true });
      }
      gridRef.current?.api.paginationGoToFirstPage();
      gridRef.current?.api.hideOverlay();
    }
  };

  /**
   * Restores any remembered filters.
   */
  const handleRememberedFilters = (params: GridReadyEvent) => {
    const rememberedFilter = rememberedFilters[pageType];
    if (!isNil(rememberedFilter)) {
      params.api.setFilterModel(JSON.parse(rememberedFilter));
    }
  };

  useEffect(() => {
    setColumnDefs(columnDefinitions);
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.sizeColumnsToFit();
    }
  }, [columnDefinitions]);

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.sizeColumnsToFit();
    params.api.closeToolPanel();
    if (shouldRememberFilters === true) {
      handleRememberedFilters(params);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeFilterToolPanel = () => {
    const filterToolPanel =
      gridRef.current?.api.getToolPanelInstance('filters');
    filterToolPanel?.collapseFilters();
    // reset filters to all before closing.
    filterToolPanel?.refresh();
    filterToolPanel?.setFilterLayout(columnDefs);
    gridRef.current?.api.closeToolPanel();
  };

  const defaultColDef: ColDef<StorageOrderFragment> = useMemo(() => {
    return {
      flex: 1,
      autoHeight: true,
      wrapHeaderText: true,
      wrapText: true,
      resizable: false,
      suppressMenu: true,
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: false,
          },
        },
      ],
      defaultToolPanel: 'filters',
      position: 'left',
    };
  }, []);

  // This closes the toolpanel if we click outside the filter toolpanel since AG Grid doesn't have an API for this
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as Element).closest(
        '.ag-filter-toolpanel',
      );

      const isDateRangePickerClicked = (event.target as Element).closest(
        '.rmdp-wrapper',
      );

      if (
        gridApi &&
        !isToolPanelClicked &&
        !isDateRangePickerClicked &&
        toolPanelVisibleRef.current
      ) {
        closeFilterToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [closeFilterToolPanel]);

  const destroyFilter = useCallback((field: StorageOrdersFilterField) => {
    gridRef.current?.api.destroyFilter(field);
  }, []);

  const handleDeleteFilter = async (field: StorageOrdersFilterField) => {
    // reset the filter
    destroyFilter(field);

    // collapse that filter in the panel.
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.collapseFilters([field]);
  };
  const handleClickSelectedFilter = async (field: StorageOrdersFilterField) => {
    // open the toolpanel and show that filter
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.expandFilters([field]);
    toolPanel?.setFilterLayout([
      columnDefs.find((colDef) => colDef.field === field) ?? {},
    ]);
    gridRef.current?.api.openToolPanel('filters');
  };

  // callback which AG Grid calls when a filter changes.
  const handleFilterChanged = (_event: FilterChangedEvent) => {
    const filterModel = gridRef.current?.api?.getFilterModel();

    if (shouldRememberFilters === true) {
      setRememberedFilters(JSON.stringify(filterModel ?? {}), pageType);
    }

    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
  };

  const handleSearch = () => {
    setSearching(true);
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
    refreshGrid(true);
    setSearching(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TextField
          size="small"
          label="Search receipts"
          InputProps={{ style: { backgroundColor: 'white' } }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          value={stateRef.current.searchText}
          onChange={(e) => {
            setState((prevState: State) => {
              if (shouldRememberFilters) {
                setRememberedSearch(e.target.value, pageType);
              }
              return {
                ...prevState,
                searchText: e.target.value,
                currentCursor: null,
              };
            });
          }}
          sx={{ width: '400px' }}
        />
        <Button variant="contained" disabled={searching} onClick={handleSearch}>
          Search
        </Button>
      </Grid>
      <Grid item xs={6} sx={styles.createButtonRow}>
        <Button
          variant="contained"
          onClick={() => {
            navigate('/warehouse/storage-orders/create');
          }}
        >
          Create receipt
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setIsUploadStorageOrdersFromCsvModalOpen(true);
          }}
        >
          Upload packing list
        </Button>
      </Grid>
      <StorageOrdersFilterRow item xs={12}>
        <WarehouseSelector
          value={state.warehouseOption ?? null}
          onChange={(option) => {
            setState((prevState) => ({
              ...prevState,
              warehouseOption: option ?? undefined,
            }));
            handleSearch();
          }}
          cacheId="receipts"
        />
        <CustomerFilterButton
          cacheId="storage-orders"
          contactsOverride={wmsContactsData?.wmsContacts}
          isSmall
          selectedOption={state.customerOption}
          handleChange={(option) => {
            setState((prevState) => ({
              ...prevState,
              customerOption: option,
            }));
            handleSearch();
          }}
        />
        <Button
          onClick={(_e) => {
            gridRef.current?.api.openToolPanel('filters');
          }}
          size="small"
          startIcon={<Add />}
          sx={styles.filterButton}
          variant="outlined"
        >
          Filter
        </Button>
        {Object.keys(gridRef.current?.api?.getFilterModel() ?? {}).map(
          (key) => {
            return (
              <SelectedFilterButtonForToolbar
                key={key}
                prependText={undefined}
                filterModel={gridRef.current?.api?.getFilterModel()}
                keyName={key}
                handleDelete={() =>
                  handleDeleteFilter(key as StorageOrdersFilterField)
                }
                handleSelect={() =>
                  handleClickSelectedFilter(key as StorageOrdersFilterField)
                }
              />
            );
          },
        )}
      </StorageOrdersFilterRow>
      <Grid item xs={12}>
        <div
          className="ag-theme-material ag-non-compact"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact<StorageOrderFragment>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            pagination
            paginationPageSize={pageSize}
            cacheBlockSize={pageSize}
            domLayout="autoHeight"
            animateRows
            sideBar={sideBar}
            onFilterChanged={handleFilterChanged}
            ref={gridRef}
            onToolPanelVisibleChanged={() => {
              toolPanelVisibleRef.current = !toolPanelVisibleRef.current;
            }}
            onRowClicked={(e) =>
              navigate(`/warehouse/storage-orders/${e.data?.uuid}`)
            }
            rowStyle={{ cursor: 'pointer' }}
          />
        </div>
      </Grid>
      {!isNil(selectedStorageOrderForLabels) && (
        <StorageUnitLabelsModal
          open={storageUnitLabelsModalOpen}
          onClose={() => setStorageUnitLabelsModalOpen(false)}
          storageOrder={selectedStorageOrderForLabels}
          storageUnits={selectedStorageUnitsForLabels}
        />
      )}
      <UploadStorageOrdersFromCsvModal
        open={isUploadStorageOrdersFromCsvModalOpen}
        onClose={() => setIsUploadStorageOrdersFromCsvModalOpen(false)}
      />
    </Grid>
  );
};

export default StorageOrdersTableWithFiltersAgGrid;
