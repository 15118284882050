import {
  Alert,
  AlertTitle,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CsvError, parse } from 'csv-parse/browser/esm';
import { isNil } from 'lodash';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import {
  TariffLocationCreateInput,
  TariffZoneLocationType,
  TariffZoneType,
  TariffZonesForGroupDocument,
  useCreateTariffZoneMutation,
  useTariffZonesForGroupQuery,
} from '../../../../generated/graphql';
import TariffZoneModal from '../../../tariff-zones/components/tariff-zone-modal';

type TariffZoneBulkCreateInput = {
  zone: string;
  city: string;
  zipcode: string;
};

const BULK_UPLOAD_TEMPLATE_HEADERS = [['Zone', 'City', 'Zipcode']];

const ZonesTable = ({ tariffZoneGroupId }: { tariffZoneGroupId: string }) => {
  const [csvUploadError, setCsvUploadError] = useState<boolean>(false);

  const [tariffZoneModalOpen, setTariffZoneModalOpen] =
    useState<boolean>(false);
  const [selectedTariffZoneUuid, setSelectedTariffZoneUuid] =
    useState<string>();
  const { data: tariffZonesData, loading: tariffZonesLoading } =
    useTariffZonesForGroupQuery({
      fetchPolicy: 'network-only',
      variables: {
        tariffZoneGroupId,
      },
    });

  const sortedTariffZones = useMemo(
    () =>
      tariffZonesData?.tariffZoneGroup.tariffZones.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
    [tariffZonesData],
  );

  const [createTariffZone] = useCreateTariffZoneMutation({
    refetchQueries: [TariffZonesForGroupDocument],
  });

  const saveZones = async (result: TariffZoneBulkCreateInput[]) => {
    const zoneGroups = result.reduce(
      (acc, cur) => {
        const record = acc[cur.zone];
        if (isNil(record)) {
          acc[cur.zone] = [cur];
        } else {
          record.push(cur);
        }
        return acc;
      },
      {} as Record<string, TariffZoneBulkCreateInput[]>,
    );

    const locationGroups: TariffLocationCreateInput[][] = Object.values(
      zoneGroups,
    ).map((data) => {
      return data.map((location) => ({
        city: location.city,
        zipcode: location.zipcode,
      }));
    });

    if (!isNil(zoneGroups)) {
      await Promise.all(
        locationGroups.map((locations, index) => {
          const group: TariffZoneBulkCreateInput[] | undefined =
            Object.values(zoneGroups)[index];
          if (!isNil(group)) {
            return createTariffZone({
              variables: {
                tariffZoneCreateInput: {
                  locations,
                  name: group[0]?.zone ?? '',
                  type: TariffZoneType.Location,
                  tariffZoneLocationType: TariffZoneLocationType.Zipcode,
                  tariffZoneGroupId,
                },
              },
            });
          }
          return null;
        }),
      );
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const csv = reader.result?.toString(); // Get the CSV data as a string
        if (csv !== undefined) {
          parse(
            csv,
            {
              delimiter: ',',
              columns: ['zone', 'city', 'zipcode'],
              fromLine: 2,
            },
            (
              error: CsvError | undefined,
              result: TariffZoneBulkCreateInput[],
            ) => {
              if (!isNil(error)) {
                setCsvUploadError(true);
              } else {
                setCsvUploadError(false);
                saveZones(result);
              }
            },
          );
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h6">Tariff Zones</Typography>
      </Grid>
      <Grid item xs={8}>
        <Stack direction="row" sx={{ float: 'right' }} spacing={2}>
          {csvUploadError && (
            <Alert severity="error">
              <AlertTitle>CSV Parse Error</AlertTitle>
              Please check the csv format (zone,city,zipcode).
            </Alert>
          )}

          <CSVLink
            data={BULK_UPLOAD_TEMPLATE_HEADERS}
            filename="tariff-zone-upload.csv"
          >
            <Button variant="outlined">Download CSV Template</Button>
          </CSVLink>
          <Button component="label" variant="outlined" sx={{ float: 'right' }}>
            Bulk Upload from CSV
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
              multiple
            />
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setTariffZoneModalOpen(true);
              setSelectedTariffZoneUuid(undefined);
            }}
          >
            Add Zone
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tariffZonesLoading ? (
                <CenteredCircularProgress />
              ) : (
                sortedTariffZones?.map((tariffZone) => (
                  <TableRow key={tariffZone.uuid}>
                    <TableCell>{tariffZone.name}</TableCell>
                    <TableCell>
                      <Button
                        sx={{ float: 'right' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          setTariffZoneModalOpen(true);
                          setSelectedTariffZoneUuid(tariffZone.uuid);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TariffZoneModal
        tariffZoneUuid={selectedTariffZoneUuid}
        open={tariffZoneModalOpen}
        setOpen={setTariffZoneModalOpen}
        tariffZoneGroupId={tariffZoneGroupId}
      />
    </Grid>
  );
};

export default ZonesTable;
