import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { Option } from '../../../../common/filters/types';
import {
  MeQuery,
  PaymentForCheckRegisterReportFragment,
} from '../../../../generated/graphql';

const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  invoicesTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '8px',
    width: '100%',
    textAlign: 'center',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  invoicesTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    textAlign: 'left',
  },
  invoicesTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '10%',
    textAlign: 'right',
  },
  checkRefNumCol: {
    width: '15%',
    paddingRight: TEXT_PADDING,
  },
  dateCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  terminalCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  accountRefNumCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  contactCol: {
    width: '35%',
    paddingRight: TEXT_PADDING,
  },
  contactColLong: {
    width: '45%',
    paddingRight: TEXT_PADDING,
  },
  commentCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  amountCol: {
    width: '100%',
    paddingRight: TEXT_PADDING,
  },
});

const ChecksTable = ({
  payments,
  showTerminal,
}: {
  showTerminal: boolean;
  payments: PaymentForCheckRegisterReportFragment[];
}) => {
  return (
    <View style={styles.invoicesTable}>
      <View
        style={[
          styles.invoicesTableRow,
          styles.invoicesTableHeader,
          styles.contactBlockHeader,
        ]}
      >
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.checkRefNumCol}>Payment ref no</Text>
          <Text style={styles.dateCol}>Date</Text>
          {showTerminal && <Text style={styles.terminalCol}>Terminal</Text>}
          <Text style={styles.accountRefNumCol}>Account ID</Text>
          <Text
            style={showTerminal ? styles.contactCol : styles.contactColLong}
          >
            Customer
          </Text>
          <Text style={styles.commentCol}>Comments</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.amountCol}>Amount</Text>
        </View>
      </View>
      {payments.map((payment) => {
        return (
          <View
            style={styles.invoicesTableRow}
            wrap={false}
            key={payment.paymentUuid}
          >
            <View style={styles.invoicesTableRowLeftSection}>
              <Text style={styles.checkRefNumCol}>
                {payment.referenceNumber}
              </Text>
              <Text style={styles.dateCol}>
                {dayjs(payment.paymentDate).format('MM/DD/YY')}
              </Text>
              {showTerminal && (
                <Text style={styles.terminalCol}>
                  {payment.terminalCodes?.join(', ')}
                </Text>
              )}
              <Text style={styles.accountRefNumCol}>
                {payment.contactReferenceNumber}
              </Text>
              <Text
                style={showTerminal ? styles.contactCol : styles.contactColLong}
              >
                {payment.contactDisplayName}
              </Text>
              <Text style={styles.commentCol}>{payment.comment}</Text>
            </View>
            <View style={styles.invoicesTableRowRightSection}>
              <Text style={styles.amountCol}>
                {currency(payment.amountInCents ?? 0, {
                  fromCents: true,
                }).format()}
              </Text>
            </View>
          </View>
        );
      })}
      <View style={[styles.invoicesTableRow, { borderTop: '1px solid black' }]}>
        <View
          style={[
            styles.invoicesTableRowLeftSection,
            { justifyContent: 'flex-end' },
          ]}
        >
          <Text style={{ fontWeight: 'bold' }}>Total</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.amountCol}>
            {currency(
              sumBy(
                payments,
                (payment) =>
                  currency(payment.amountInCents ?? 0, { fromCents: true })
                    .value ?? 0,
              ),
            ).format()}
          </Text>
        </View>
      </View>
    </View>
  );
};

export interface GeneratedCheckRegisterReportProps {
  companyData: MeQuery | undefined;
  dateString: string;
  payments: PaymentForCheckRegisterReportFragment[];
  customerOption?: Option | null | undefined;
  terminalOption?: Option | null | undefined;
  terminalsEnabled: boolean;
}

const GeneratedCheckRegisterReport = ({
  companyData,
  dateString,
  payments,
  customerOption,
  terminalOption,
  terminalsEnabled,
}: GeneratedCheckRegisterReportProps) => {
  return (
    <Document title="Check Register Report">
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>Check Register Report</Text>
        </View>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>Date</Text>
              <Text style={{ marginLeft: '10px' }}>{dateString}</Text>
            </View>
          </View>
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>Contact</Text>
              <Text style={{ marginLeft: '10px' }}>
                {customerOption?.label ?? 'All'}
              </Text>
            </View>
          </View>
          {terminalsEnabled && (
            <View style={styles.rowWithSpaceBetween}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '10px',
                }}
              >
                <Text style={{ fontWeight: 'bold' }}>Terminal</Text>
                <Text style={{ marginLeft: '10px' }}>
                  {terminalOption?.label ?? 'All'}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.contactBlock}>
            <ChecksTable payments={payments} showTerminal={terminalsEnabled} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedCheckRegisterReport;
