import DeleteIcon from '@mui/icons-material/Delete';
import {
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { v4 } from 'uuid';
import { getStopAddressComponentsTestIds } from '../../../utils';
import { AddressFormField } from '../../domains/addresses/redux/addresses-values-slice';
import { StopType } from '../../domains/orders/components/order-form/forms/stop-type';
import {
  AddressWithContactFragment,
  ContactPersonFragment,
  useRemoveAddressMutation,
  useTemplateAddressesLazyQuery,
} from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import { isNilOrEmptyString } from '../utils/utils';
import AutofillComponent from './autofill-component';

export type FormAddressWithAssociatedContact = AddressFormField & {
  associatedContact?: ContactPersonFragment | null;
};

export type OptionStructure = {
  label: string;
  value: string;
  address: FormAddressWithAssociatedContact;
  fromMapbox: boolean;
  name?: string;
};

const DEBOUNCE_WAIT_TIME_MS = 250;

const convertAddressFragmentToOption = (
  address: AddressWithContactFragment | AddressFormField,
) => {
  return {
    label: `${address.name} ${transformAddressToFullAddressString({
      ...address,
      line1: address.line1 ?? null,
      city: address.city ?? null,
      zip: address.zip ?? null,
      name: address.name,
    })}`,
    address: {
      ...address,
      isLocal: false,
      uuid: address.uuid,
    },
    fromMapbox: false,
    value: address.uuid,
    name: address.name,
  };
};

const AddressAutocompleteForm = ({
  currentAddress,
  handleChange,
  width,
  disabled = false,
  hideLine2 = false,
  newStyling,
  showError,
  useAllCaps = false,
  shouldRefreshAddresses,
  forceEditMode,
  stopIdx,
  optional,
  stopType,
}: {
  currentAddress: FormAddressWithAssociatedContact | null | undefined;
  handleChange: (
    isAutofillChange: boolean,
    newAddress: FormAddressWithAssociatedContact | undefined,
  ) => void;
  disabled?: boolean;
  width?: string;
  hideLine2?: boolean;
  newStyling?: boolean;
  showError?: boolean;
  useAllCaps?: boolean;
  shouldRefreshAddresses?: boolean;
  forceEditMode?: boolean;
  stopIdx?: number;
  optional?: boolean;
  stopType?: StopType;
}) => {
  const [searchTemplateAddresses] = useTemplateAddressesLazyQuery();
  const [removeAddress] = useRemoveAddressMutation();
  const [nameInputValue, setNameInputValue] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [debouncedNameInputValue] = useDebounce(
    nameInputValue,
    DEBOUNCE_WAIT_TIME_MS,
  );
  const [addressOptions, setAddressOptions] = useState<OptionStructure[]>([]);
  const [currentOption, setCurrentOption] = useState<OptionStructure | null>(
    null,
  );
  const [foundAddresses, setFoundAddresses] = useState<
    AddressWithContactFragment[]
  >([]);

  const search = async () => {
    const res = await searchTemplateAddresses({
      variables: { searchText: debouncedNameInputValue, first: 10 },
    });

    setFoundAddresses(res.data?.templateAddresses ?? []);
  };

  useEffect(() => {
    if (!isNil(showError) && showError === true) {
      setError('Field is required');
    }
  }, [showError]);

  useEffect(() => {
    if (!isEmpty(debouncedNameInputValue)) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNameInputValue, shouldRefreshAddresses]);

  useEffect(() => {
    if (!isNil(currentAddress)) {
      setNameInputValue(currentAddress.name ?? '');
      setCurrentOption(convertAddressFragmentToOption(currentAddress));
    }
  }, [currentAddress]);

  useEffect(() => {
    const addressLabelAndValues = foundAddresses.map((address) =>
      convertAddressFragmentToOption({ ...address }),
    );
    setAddressOptions(addressLabelAndValues);
  }, [foundAddresses]);

  const updateAddressName = useDebouncedCallback(
    ({
      option,
      isAutofillChange,
    }: {
      option: string | OptionStructure | null;
      isAutofillChange: boolean;
    }) => {
      if (isNil(option)) {
        handleChange(isAutofillChange, undefined);
      }
      if (typeof option === 'string' && !isNil(currentAddress)) {
        const updatedAddress = {
          ...currentAddress,
          ...{ name: option, isLocal: false },
        };
        handleChange(isAutofillChange, updatedAddress);
      } else if (!isNil(option) && typeof option !== 'string') {
        const currentAddressUuid = currentAddress?.uuid ?? '';
        const uuid = currentAddressUuid.length > 0 ? currentAddressUuid : v4();
        handleChange(isAutofillChange, {
          ...currentAddress,
          ...option.address,
          ...{ uuid, isLocal: false },
        });
      }
    },
    DEBOUNCE_WAIT_TIME_MS,
  );

  const updateAddressFields = ({
    newAddress,
    isAutofillChange,
  }: {
    newAddress: AddressFormField;
    isAutofillChange: boolean;
  }) => {
    // The order here matters. We prioritize the uuid being what was passed in through the current address,
    // then the name held in the input, then the new address information (line 1/2, zipcode, city, state)
    // finally whatever is remaining is the current address information
    const updatedAddress = !isNil(currentAddress)
      ? {
          ...currentAddress,
          ...newAddress,
          name: nameInputValue,
          uuid: currentAddress?.uuid,
        }
      : { ...newAddress, name: nameInputValue };

    handleChange(isAutofillChange, updatedAddress);
  };

  const deleteAddressSuggestion = async (uuid: string) => {
    await removeAddress({ variables: { uuid } });
    setFoundAddresses(
      foundAddresses.filter((address) => address.uuid !== uuid),
    );
  };

  const validate = () => {
    if (isNilOrEmptyString(nameInputValue) && optional !== true) {
      setError('Field is required.');
    } else {
      setError(undefined);
    }
  };

  const { addressNameTestId } = getStopAddressComponentsTestIds({
    stopIdx: stopIdx ?? 0,
  });

  if (newStyling === true) {
    return (
      <Grid
        item
        md={12}
        sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
      >
        <Grid direction="row" md={12} sx={{ display: 'flex', gap: '15px' }}>
          <Grid item md={8.5} flexGrow={1}>
            <AutocompleteFuzzy
              matchSortOptions={{ keys: ['label'] }}
              disabled={disabled}
              value={currentOption ?? null}
              freeSolo
              onChange={(e, option) =>
                updateAddressName({ option, isAutofillChange: true })
              }
              options={addressOptions}
              isOptionEqualToValue={(option, value) =>
                option.address.uuid === value.address.uuid
              }
              inputValue={nameInputValue}
              onBlur={validate}
              renderOption={(props, option) => {
                return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...props} key={option.value}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <Stack direction="column">
                          <Typography>{option.name}</Typography>
                          <Typography sx={{ fontSize: '14px' }}>
                            {transformAddressToFullAddressString({
                              ...option.address,
                              line1: option.address.line1 ?? null,
                              city: option.address.city ?? null,
                              zip: option.address.zip ?? null,
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Tooltip title="Delete suggestion">
                          <IconButton
                            sx={{ float: 'right' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteAddressSuggestion(option.value);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  data-cy="address-autocomplete-stop"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  required={optional !== true}
                  error={!isNil(error)}
                  helperText={error}
                  onChange={(e) => {
                    const input = e.target.value;
                    updateAddressName({
                      option: input,
                      isAutofillChange: false,
                    });
                    setNameInputValue(input);
                  }}
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    ...(useAllCaps
                      ? { style: { textTransform: 'uppercase' } }
                      : {}),
                    'data-testid': addressNameTestId,
                  }}
                  label="Name"
                  onBlur={validate}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          {(stopType === StopType.Recovery ||
            stopType === StopType.Transfer) && (
            <Grid item md={3}>
              <Tooltip title="3-letter IATA code for the airport associated with this address. Printable labels will use this code if no final destination airport code is provided.">
                <TextField
                  required={false}
                  error={!isNil(error)}
                  onChange={(e) => {
                    updateAddressFields({
                      newAddress: {
                        ...currentAddress,
                        iataCode: e.target.value,
                        isLocal: currentAddress?.isLocal ?? false,
                        uuid: currentAddress?.uuid ?? v4(),
                      },
                      isAutofillChange: false,
                    });
                  }}
                  size="small"
                  label="Airport code"
                  onBlur={validate}
                  sx={{
                    width: '100%',
                  }}
                  value={currentAddress?.iataCode ?? ''}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <AutofillComponent
          freeSolo
          disabled={disabled}
          handleChange={({
            address,
            isAutofillChange,
          }: {
            address: AddressFormField;
            isAutofillChange: boolean;
          }) => updateAddressFields({ newAddress: address, isAutofillChange })}
          error={error}
          currentOption={currentOption}
          nameInputValue={nameInputValue}
          hideLine2={hideLine2}
          newStyling={newStyling}
          useAllCaps={useAllCaps}
          forceEditMode={forceEditMode}
          stopIdx={stopIdx}
          required={optional !== true}
        />
      </Grid>
    );
  }
  return (
    <FormControl
      sx={{
        width: width ?? '100%',
      }}
      fullWidth
      size="small"
    >
      <Typography sx={{ textAlign: 'center' }}>Address Name</Typography>
      <AutocompleteFuzzy
        disabled={disabled}
        value={currentOption ?? null}
        freeSolo
        filterOptions={(options) => options}
        sx={{ mb: '20px' }}
        onChange={(e, option) =>
          updateAddressName({ option, isAutofillChange: true })
        }
        matchSortOptions={{ keys: ['label'] }}
        options={addressOptions}
        isOptionEqualToValue={(option, value) =>
          option.address.uuid === value.address.uuid
        }
        inputValue={nameInputValue}
        onBlur={validate}
        renderOption={(props, option) => {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.value}>
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <Stack direction="column">
                    <Typography>{option.name}</Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      {transformAddressToFullAddressString({
                        ...option.address,
                        line1: option.address.line1 ?? null,
                        city: option.address.city ?? null,
                        zip: option.address.zip ?? null,
                      })}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip title="Delete suggestion">
                    <IconButton
                      sx={{ float: 'right' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteAddressSuggestion(option.value);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            data-cy="address-autocomplete-stop"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            required
            error={!isNil(error)}
            helperText={error}
            onChange={(e) => {
              const input = useAllCaps
                ? e.target.value.toUpperCase()
                : e.target.value;
              updateAddressName({
                option: input,
                isAutofillChange: false,
              });
              setNameInputValue(input);
            }}
            size="small"
            inputProps={{
              ...params.inputProps,
              'data-testid': addressNameTestId,
            }}
            placeholder="Name"
            onBlur={validate}
            sx={{
              backgroundColor: 'white',
              width: '100%',
              '& .MuiFormHelperText-root': {
                backgroundColor: '#F7F7F7',
                margin: 0,
                paddingLeft: '14px',
                paddingTop: '4px',
              },
            }}
          />
        )}
      />
      <AutofillComponent
        freeSolo
        disabled={disabled}
        handleChange={({
          address,
          isAutofillChange,
        }: {
          address: AddressFormField;
          isAutofillChange: boolean;
        }) => updateAddressFields({ newAddress: address, isAutofillChange })}
        error={error}
        currentOption={currentOption}
        nameInputValue={nameInputValue}
        hideLine2={hideLine2}
        useAllCaps={useAllCaps}
        stopIdx={stopIdx}
        required={optional !== true}
      />
    </FormControl>
  );
};

export default AddressAutocompleteForm;
