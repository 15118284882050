// eslint-disable-next-line no-restricted-imports
import { Button, Card, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { PlusIcon } from 'primereact/icons/plus';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../../../../common/components/tab-panel/tab-panel';
import {
  AccessorialType,
  SpecialAccessorialType,
  useAccessorialDateRangeConfigsQuery,
} from '../../../../../generated/graphql';
import AccessorialPriceEditor from './accessorial-price-editor';
import AddAccessorialPriceModal from './add-accessorial-price-modal';

interface AccessorialPricesProps {
  accessorialUuid: string;
  accessorialType: AccessorialType;
  specialAccessorialType?: SpecialAccessorialType | null;
}

const AccessorialPrices = ({
  accessorialUuid,
  accessorialType,
  specialAccessorialType,
}: AccessorialPricesProps) => {
  const {
    loading: accessorialDateRangeConfigsLoading,
    data: accessorialDateRangeConfigsData,
    refetch: refetchAccessorialDateRangeConfigs,
  } = useAccessorialDateRangeConfigsQuery({
    variables: {
      findAccessorialDateRangeConfigsInput: { accessorialUuid },
    },
  });

  const [showAddAccessorialPriceModal, setShowAddAccessorialPriceModal] =
    useState<boolean>(false);

  const [
    selectedAccessorialDateRangeConfigUuid,
    setSelectedAccessorialDateRangeConfigUuid,
  ] = useState<string | undefined>(undefined);

  const accessorialDateRangeConfigs =
    accessorialDateRangeConfigsData?.accessorialDateRangeConfigs
      ?.accessorialDateRangeConfigs ?? [];

  useEffect(() => {
    setSelectedAccessorialDateRangeConfigUuid(
      accessorialDateRangeConfigs[0]?.uuid,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialDateRangeConfigs.length]);

  return (
    <Card sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Tabs
            value={selectedAccessorialDateRangeConfigUuid}
            onChange={(e, newValue) => {
              setSelectedAccessorialDateRangeConfigUuid(newValue);
            }}
            aria-label="basic tabs example"
          >
            {accessorialDateRangeConfigs.map((accessorialDateRangeConfig) => (
              <Tab
                key={accessorialDateRangeConfig.uuid}
                value={accessorialDateRangeConfig.uuid}
                label={
                  accessorialDateRangeConfigs.length === 1 &&
                  isNil(accessorialDateRangeConfig.endDate)
                    ? 'Current Price'
                    : `${dayjs(accessorialDateRangeConfig.startDate).format(
                        'MM/DD/YYYY',
                      )} -
                      ${
                        isNil(accessorialDateRangeConfig.endDate)
                          ? 'Now'
                          : dayjs(accessorialDateRangeConfig.endDate).format(
                              'MM/DD/YYYY',
                            )
                      }`
                }
              />
            ))}
          </Tabs>
          <Button
            disabled={accessorialDateRangeConfigsLoading}
            startIcon={<PlusIcon />}
            onClick={() => {
              setShowAddAccessorialPriceModal(true);
            }}
          >
            Add new price
          </Button>
        </Grid>
        <Grid item xs={12}>
          {accessorialDateRangeConfigsLoading ? (
            <CircularProgress />
          ) : (
            accessorialDateRangeConfigs.map((accessorialDateRangeConfig) => {
              return (
                <TabPanel
                  key={accessorialDateRangeConfig.uuid}
                  panelValue={accessorialDateRangeConfig.uuid}
                  selectedValue={selectedAccessorialDateRangeConfigUuid}
                >
                  <AccessorialPriceEditor
                    accessorialUuid={accessorialUuid}
                    accessorialDateRangeConfig={accessorialDateRangeConfig}
                    accessorialDateRangeConfigLength={
                      accessorialDateRangeConfigs.length
                    }
                    refetchAccessorialDateRangeConfigs={async () => {
                      await refetchAccessorialDateRangeConfigs();
                    }}
                    accessorialType={accessorialType}
                    specialAccessorialType={specialAccessorialType}
                  />
                </TabPanel>
              );
            })
          )}
        </Grid>
      </Grid>
      <AddAccessorialPriceModal
        isOpen={showAddAccessorialPriceModal}
        setIsOpen={setShowAddAccessorialPriceModal}
        accessorialUuid={accessorialUuid}
        accessorialType={accessorialType}
        refetchAccessorialDateRangeConfigs={async () => {
          await refetchAccessorialDateRangeConfigs();
        }}
        accessorialDateRangeConfigs={accessorialDateRangeConfigs}
      />
    </Card>
  );
};

export default React.memo(AccessorialPrices);
