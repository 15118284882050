import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import ContactAutocompleteComponent from '../../../../common/components/contact-autocomplete-component';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  p: 2,
};

const BillingPartyContactModal = ({
  open,
  setIsOpen,
  isFromQuotesPage,
  isRecurringTemplate,
  isFromRecurringRuns,
}: {
  open: boolean;
  setIsOpen: (show: boolean) => void;
  isFromQuotesPage?: boolean;
  isRecurringTemplate?: boolean;
  isFromRecurringRuns?: boolean;
}) => {
  const navigate = useNavigate();
  const [billingPartyContactUuid, setBillingPartyContactUuid] = useState<
    string | undefined
  >(undefined);

  const goToOrderCreate = () => {
    if (isNilOrEmptyString(billingPartyContactUuid)) {
      return;
    }
    setIsOpen(false);

    let basePath: string;
    if (isFromQuotesPage === true) {
      basePath = '/order-entry/quotes';
    } else if (isFromRecurringRuns === true) {
      basePath = '/management/recurring-runs';
    } else if (isRecurringTemplate === true) {
      basePath = '/management/recurring-orders';
    } else {
      basePath = '/order-entry';
    }
    navigate(`${basePath}/add?contactUuid=${billingPartyContactUuid}`);

    setBillingPartyContactUuid(undefined);
  };

  const createButtonCopy = `Create ${
    // eslint-disable-next-line no-nested-ternary
    isFromQuotesPage === true
      ? 'quote'
      : isRecurringTemplate === true
        ? 'template'
        : 'order'
  }`;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => setIsOpen(false)}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6" sx={{ mb: '20px' }}>
          {isFromQuotesPage === true
            ? 'Create a new quote'
            : `Create a new order${
                isRecurringTemplate === true ? ' template' : ''
              }`}
        </Typography>
        <Typography>Search for a customer</Typography>
        <ContactAutocompleteComponent
          billingPartyContactUuid={billingPartyContactUuid}
          onChange={(value: string | undefined) =>
            setBillingPartyContactUuid(value)
          }
          onPressEnter={goToOrderCreate}
          open={open}
        />
        <DialogActions>
          <Tooltip
            title={
              <Stack justifyContent="center" alignItems="center">
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Press
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 10,
                      backgroundColor: 'black',
                      borderRadius: 1,
                      pl: 0.2,
                      width: 27,
                    }}
                  >
                    Enter
                  </Typography>
                </Stack>
              </Stack>
            }
          >
            <Button
              data-cy="billing-party-contact-modal-create-order-button"
              variant="contained"
              sx={{ mt: 1 }}
              onClick={goToOrderCreate}
            >
              {createButtonCopy}
            </Button>
          </Tooltip>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BillingPartyContactModal;
