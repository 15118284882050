import { isNil } from 'lodash';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useUpdateAccessorialDateRangeConfigMutation } from '../../../../../generated/graphql';
import { AccessorialRateTextField } from '../common';
import useAccessorialEditorStore from '../use-accessorial-editor-store';

const AccessorialMatrixRateCell = ({
  uuid,
  accessorialDateRangeConfigUuid,
  initialRate,
}: {
  uuid: string;
  accessorialDateRangeConfigUuid: string;
  initialRate: number;
}) => {
  const [rateInput, setRateInput] = useState<number | undefined>(initialRate);
  const [
    setShowUpdateAccessorialDateRangeSuccessMessage,
    setShowUpdateAccessorialDateRangeErrorMessage,
  ] = useAccessorialEditorStore(
    (state) => [
      state.setShowUpdateAccessorialDateRangeSuccessMessage,
      state.setShowUpdateAccessorialDateRangeErrorMessage,
    ],
    shallow,
  );

  const [updateAccessorialDateRangeConfig] =
    useUpdateAccessorialDateRangeConfigMutation();

  const onUpdate = async () => {
    const rate = !Number.isNaN(rateInput) ? rateInput : 0;
    setRateInput(rate);
    const res = await updateAccessorialDateRangeConfig({
      variables: {
        updateAccessorialDateRangeConfigInput: {
          uuid: accessorialDateRangeConfigUuid,
          specialAccessorialMatrixItemUpdateInputs: [
            {
              uuid,
              rate,
            },
          ],
        },
      },
    });
    if (!isNil(res.errors)) {
      setShowUpdateAccessorialDateRangeErrorMessage(true);
    } else {
      setShowUpdateAccessorialDateRangeSuccessMessage(true);
    }
  };

  return (
    <AccessorialRateTextField
      value={rateInput}
      type="number"
      onBlur={onUpdate}
      onChange={(e) => {
        const parsedFloat = parseFloat(e.target.value);
        setRateInput(parsedFloat);
      }}
      isCurrency
      name="rate"
      label="Rate"
      error={undefined}
    />
  );
};

export default AccessorialMatrixRateCell;
