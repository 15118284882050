// ORDER PAGE TEST IDs
export const ORDER_PAGE_CONTAINER_TEST_ID = 'order-page-container';

export const ORDER_PAGE_SAVE_BUTTON_TEST_ID = 'order-page-save-button';

export const ORDER_PAGE_TERMINAL_SELECTOR_BUTTON = 'orders-terminal-button';

export const ORDER_PAGE_SAVE_BUTTON_LOADING_SPINNER_TEST_ID =
  'order-page-save-button-loading-spinner';
export const ORDER_PAGE_SAVE_AND_EXIT_BUTTON_TEST_ID =
  'order-page-save-and-exit-button';

export const ORDER_PAGE_MARK_AS_MODAL_BUTTON_TEST_ID =
  'order-page-mark-as-modal-button';

export const ORDER_PAGE_MARK_AS_COMPLETE_BUTTON_TEST_ID =
  'order-page-mark-as-complete-button';

export const ORDER_PAGE_MARK_AS_COMPLETE_MODAL_SIGNEE_INPUT_TEST_ID =
  'order-page-mark-as-complete-modal-signee-input';

export const ORDER_PAGE_MARK_AS_COMPLETE_MODAL_COMPLETED_AT_INPUT_TEST_ID =
  'order-page-mark-as-complete-modal-completed-at-input';

export const ORDER_PAGE_MARK_AS_COMPLETE_MODAL_BUTTON_TEST_ID =
  'order-page-mark-as-complete-modal-button';

export const ORDER_PAGE_COMPLETED_STOP_CHIP_TEST_ID =
  'order-page-completed-stop-chip';

export const ORDER_PAGE_SAVE_AND_EXIT_BUTTON_LOADING_SPINNER_TEST_ID =
  'order-page-save-and-exit-button-loading-spinner';
export const FREIGHT_CHARGE_NEW_TARIFF_AUTO_APPLIED_MESSAGE_TEST_ID =
  'freight-charge-new-tariff-auto-applied';

export const FREIGHT_CHARGE_RATE_SKELETON_TEST_ID =
  'freight-charge-rate-skeleton';
export const FREIGHT_CHARGE_TOTAL_TEST_ID = 'freight-charge-total';
export const FREIGHT_CHARGE_TYPE_SELECT_TEST_ID = 'freight-charge-type-select';

export const FUEL_CHARGE_TYPE_SELECT_TEST_ID = 'fuel-charge-type-select';

export const FUEL_CHARGE_RATE_INPUT_TEST_ID = 'fuel-charge-rate-input';
export const FUEL_CHARGE_TOTAL_TEST_ID = 'fuel-charge-total';

export const FUEL_CHARGE_NEW_RATE_AUTO_APPLIED_MESSAGE_TEST_ID =
  'fuel-charge-new-rate-auto-applied';

export const CUSTOM_CHARGE_TOTAL_TEST_ID = 'custom-charge-total';

export const STOP_CHARGE_TOTAL_TEST_ID = 'stop-charge-total';

export const LINE_HAUL_FREIGHT_CHARGE_TOTAL_TEST_ID =
  'line-haul-freight-charge-total';

export const LINE_HAUL_FUEL_CHARGE_TYPE_SELECT_TEST_ID =
  'line-haul-fuel-charge-type-select';

export const LINE_HAUL_FUEL_CHARGE_RATE_INPUT_TEST_ID =
  'line-haul-fuel-charge-rate-input';
export const LINE_HAUL_FUEL_CHARGE_TOTAL_TEST_ID =
  'line-haul-fuel-charge-total';

export const LINE_HAUL_FUEL_CHARGE_NEW_RATE_AUTO_APPLIED_MESSAGE_TEST_ID =
  'line-haul-fuel-charge-new-rate-auto-applied';

export const LINE_HAUL_CHARGE_TOTAL_TEST_ID = 'line-haul-charge-total';

export const ORDER_TOTAL_TEST_ID = 'order-total';

export const INVOICE_ORDER_TABS_TEST_ID_PREFIX = 'invoice-order-tabs';

export const ORDERS_PAGE_OPEN_ORDER_BUTTON_TEST_ID_PREFIX =
  'orders-page-open-order-button';

export const ORDER_PAGE_REDIRECT_ANNOUNCEMENT_TEXT_TEST_ID =
  'order-page-redirect-announcement-text';

export const ORDER_PAGE_REDIRECT_ANNOUNCEMENT_CLOSE_BUTTON_TEST_ID =
  'order-page-redirect-announcement-close-button';

export const ORDER_PAGE_STOP_TYPE_RADIO_BUTTON_TEST_ID_PREFIX =
  'order-page-stop-type-radio-button';

export const ORDER_PAGE_HAWB_TEXT_FIELD_TEST_ID = 'order-page-hawb-text-field';

export const ORDER_PAGE_ADDRESS_NAME_TEST_ID_PREFIX = 'order-page-address-name';
export const ORDER_PAGE_ADDRESS_LINE_1_TEST_ID_PREFIX =
  'order-page-address-line-1';

export const ORDER_PAGE_ADDRESS_LINE_2_TEST_ID_PREFIX =
  'order-page-address-line-2';

export const ORDER_PAGE_ADDRESS_CITY_TEST_ID_PREFIX = 'order-page-address-city';

export const ORDER_PAGE_ADDRESS_STATE_TEST_ID_PREFIX =
  'order-page-address-state';

export const ORDER_PAGE_ADDRESS_ZIP_TEST_ID_PREFIX = 'order-page-address-zip';

export const ORDER_PAGE_TERMINAL_SELECT_TEST_ID_PREFIX =
  'order-page-terminal-select';

export const ORDER_PAGE_TERMINAL_SELECT_INPUT_TEST_ID_PREFIX =
  'order-page-terminal-select-input';

export const ORDER_PAGE_CITY_AND_STATE_OPTIONS_TABLE_TEST_ID =
  'order-page-city-and-state-options-popover';

export const ORDER_PAGE_STOP_CARD_TEST_ID_PREFIX = 'order-page-stop-card';

export const ORDER_PAGE_SERVICE_SELECT_TEST_ID = 'order-page-service-select';

export const ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID =
  'order-page-service-select-input';

export const ORDER_PAGE_STOP_SERVICE_DATE_TEST_ID_PREFIX =
  'order-page-stop-service-date';

export const ORDER_PAGE_STOP_SERVICE_DATE_INPUT_TEST_ID_PREFIX =
  'order-page-stop-service-date-input';

export const ORDER_PAGE_PACKAGE_QUANTITY_INPUT_TEST_ID_PREFIX =
  'order-page-package-quantity-input';
export const ORDER_PAGE_PACKAGE_WEIGHT_INPUT_TEST_ID_PREFIX =
  'order-page-package-weight-input';

export const ORDER_PAGE_PACKAGE_LENGTH_INPUT_TEST_ID_PREFIX =
  'order-page-package-length-input';

export const ORDER_PAGE_PACKAGE_WIDTH_INPUT_TEST_ID_PREFIX =
  'order-page-package-width-input';

export const ORDER_PAGE_PACKAGE_HEIGHT_INPUT_TEST_ID_PREFIX =
  'order-page-package-height-input';

export const ORDER_PAGE_STOP_MARK_AS_MENU_BUTTON_TEST_ID_PREFIX =
  'order-page-stop-mark-as-menu-button';

export const ORDER_PAGE_STOP_MARK_AS_MENU_MARK_COMPLETED_OPTION_TEST_ID_PREFIX =
  'order-page-stop-mark-as-menu-mark-completed-option';

export const ORDER_PAGE_STOP_MARK_COMPLETED_DIALOG_POD_SIGNEE_NAME_INPUT_TEST_ID_PREFIX =
  'order-page-stop-mark-completed-dialog-pod-signee-name-input';

export const ORDER_PAGE_STOP_MARK_COMPLETED_DIALOG_MARK_COMPLETED_BUTTON_TEST_ID_PREFIX =
  'order-page-stop-mark-completed-dialog-mark-completed-button';

export const ORDER_PAGE_FINALIZE_CHARGES_BUTTON_TEST_ID =
  'order-page-finalize-charges-button';

export const ORDER_PAGE_ADD_CUSTOM_CHARGE_BUTTON_TEST_ID_PREFIX =
  'order-page-add-custom-charge-button';

export const ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_SELECT_TEST_ID_PREFIX =
  'order-page-custom-charge-accessorial-select';

export const ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_SELECT_INPUT_TEST_ID_PREFIX =
  'order-page-custom-charge-accessorial-select-input';

export const ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_ZONE_SELECT_TEST_ID_PREFIX =
  'order-page-custom-charge-accessorial-zone-select';

export const ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_ZONE_SELECT_INPUT_TEST_ID_PREFIX =
  'order-page-custom-charge-accessorial-zone-select-input';

export const ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_CHARGE_GROUP_SELECT_TEST_ID_PREFIX =
  'order-page-custom-charge-accessorial-charge-group-select';

export const ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_CHARGE_GROUP_SELECT_INPUT_TEST_ID_PREFIX =
  'order-page-custom-charge-accessorial-charge-group-select-input';

export const ORDER_PAGE_CUSTOM_CHARGE_RATE_INPUT_TEST_ID_PREFIX =
  'order-page-custom-charge-rate-input';

export const ORDER_PAGE_CUSTOM_CHARGE_QUANTITY_INPUT_TEST_ID_PREFIX =
  'order-page-custom-charge-quantity-input';

export const ORDER_PAGE_CUSTOM_CHARGE_NAME_INPUT_TEST_ID_PREFIX =
  'order-page-custom-charge-name-input';

export const ORDER_PAGE_TRANSPORT_ON_LINE_HAUL_CHECKBOX_TEST_ID =
  'order-page-transport-on-line-haul-checkbox';

export const ORDER_PAGE_LINE_HAUL_SELECT_TEST_ID =
  'order-page-line-haul-select';
export const ORDER_PAGE_LINE_HAUL_SELECT_INPUT_TEST_ID =
  'order-page-line-haul-select-input';

export const ORDER_PAGE_STOP_ADD_DESTINATION_BUTTON_TEST_ID_PREFIX =
  'order-page-stop-add-destination';

export const ORDER_PAGE_STOP_AIRPORT_INFO_SELECT_TEST_ID_PREFIX =
  'order-page-airport-info-select';

export const ORDER_PAGE_STOP_AIRPORT_INFO_SELECT_INPUT_TEST_ID_PREFIX =
  'order-page-airport-info-select-input';

// DISPATCH PAGE TEST IDs
export const DISPATCH_PAGE_CREATE_ROUTE_BUTTON_TEST_ID =
  'dispatch-page-create-route-button';

export const DISPATCH_PAGE_CREATE_ROUTE_CONFIRM_BUTTON_TEST_ID =
  'dispatch-page-create-route-confirm-button';

export const DISPATCH_PAGE_ROUTE_CARD_TEST_ID_PREFIX =
  'dispatch-page-route-card';
