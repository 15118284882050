import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CustomerFilterButton from '../../../common/components/customer-filter-button';
import { Option } from '../../../common/filters/types';
import { useItemInventorySummaryLazyQuery } from '../../../generated/graphql';
import styles from '../../invoices/styles';
import WarehouseSelector from '../../storage-orders/components/common/warehouse-selector';
import { downloadItemInventorySummaryXlsx } from '../utils';

interface DownloadItemInventorySummaryProps {
  onClose: () => void;
}

const DownloadItemInventorySummary = ({
  onClose,
}: DownloadItemInventorySummaryProps) => {
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [warehouseOption, setWarehouseOption] = useState<Option | null>(null);

  const [fetchItemsForReport] = useItemInventorySummaryLazyQuery();

  const handleDownload = async () => {
    const res = await fetchItemsForReport({
      variables: {
        contactUuid: customerOption?.value,
        warehouseUuid: warehouseOption?.value,
      },
    });
    if (res.data == null) return null;
    return downloadItemInventorySummaryXlsx(res.data.items.edges);
  };

  return (
    <Box
      sx={[
        styles.modal,
        {
          height: 'fit-content',
          width: '400px',
        },
      ]}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Download item inventory summary
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton sx={{ float: 'right' }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ height: '100%', mb: 2 }}>
          <Stack direction="column" spacing={1}>
            <Stack direction="column" gap={1}>
              <CustomerFilterButton
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) =>
                  setCustomerOption(option)
                }
              />
              <WarehouseSelector
                value={warehouseOption}
                onChange={setWarehouseOption}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end" alignContent="center">
            <Button
              onClick={handleDownload}
              sx={{ width: 100 }}
              variant="contained"
              color="info"
            >
              Download
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadItemInventorySummary;
