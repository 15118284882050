import {
  Button,
  Fade,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { ReadonlyDeep } from 'type-fest';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  DriverPayType,
  DriverType,
  PermissionResource,
  useDriversTableQuery,
} from '../../../../generated/graphql';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from '../common/active-archived-tab-panel';
import DriverQualificationsModal from './driver-qualifications-modal';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  driverButton: {
    mx: 1.5,
  } as SxProps,
};

// Driver pay types
export type DriverPayTypeDisplay = {
  driverPayTypeName: DriverPayType;
  displayName: string;
};

export const DRIVER_PAY_TYPES: ReadonlyArray<
  ReadonlyDeep<DriverPayTypeDisplay>
> = [
  {
    driverPayTypeName: DriverPayType.PerMile,
    displayName: 'Per Mile',
  },
  {
    driverPayTypeName: DriverPayType.PercentGrossRevenue,
    displayName: 'Percent of Gross Revenue',
  },
];

// Driver types
export type DriverTypeDisplay = {
  driverTypeName: DriverType;
  displayName: string;
};

export const DRIVER_TYPES: ReadonlyArray<ReadonlyDeep<DriverTypeDisplay>> = [
  {
    driverTypeName: DriverType.CompanyDriver,
    displayName: 'Company Driver',
  },
  {
    driverTypeName: DriverType.OwnerOperator,
    displayName: 'Owner/Operator',
  },
  {
    driverTypeName: DriverType.Contractor,
    displayName: 'Contractor',
  },
];

const DriversTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyDrivers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyDrivers,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  const navigate = useNavigate();
  const { data: driversData } = useDriversTableQuery();
  const [hoveredDriverUuid, setHoveredDriverUuid] = useState<string>();
  const [driverQualificationsModalOpen, setDriverQualificationsModalOpen] =
    useState(false);

  const sortedDrivers = driversData?.drivers
    .slice()
    .filter((d) =>
      isActiveOrArchived === ActiveOrArchivedTab.ACTIVE ? d.active : !d.active,
    )
    .sort((a, b) => {
      const aName = `${a.firstName} ${a.lastName}`;
      const bName = `${b.firstName} ${b.lastName}`;
      return aName.localeCompare(bName);
    });

  return (
    <Stack direction="column" sx={styles.stackView}>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <ActiveArchivedButtonGroup
          activeTab={isActiveOrArchived}
          setTab={setIsActiveOrArchived}
        />
        <Button
          variant="outlined"
          onClick={() => setDriverQualificationsModalOpen(true)}
        >
          Edit qualifications
        </Button>
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Reference #</TableCell>
              <TableCell>Terminal</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Pay Type</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => navigate('/management/drivers/add')}
                  variant="contained"
                  disabled={!canWriteCompanyDrivers}
                >
                  {ffDemoLoadManagement ? 'Add Driver/Agent' : 'Add Driver'}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDrivers?.map((driver) => (
              <TableRow
                onMouseEnter={() => {
                  setHoveredDriverUuid(driver.uuid);
                }}
                onMouseLeave={() => {
                  setHoveredDriverUuid(undefined);
                }}
                key={driver.uuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                >{`${driver.firstName}  ${driver.lastName}`}</TableCell>
                <TableCell>{driver.driverReferenceNumber}</TableCell>
                <TableCell>
                  {driver.terminal?.name ??
                    (terminalsEnabled ? 'All' : undefined)}
                </TableCell>
                <TableCell>
                  {
                    DRIVER_TYPES.find(
                      (possibleDriverType) =>
                        possibleDriverType.driverTypeName === driver.driverType,
                    )?.displayName
                  }
                </TableCell>
                <TableCell>
                  {
                    DRIVER_PAY_TYPES.find(
                      (possibleDriverPayType) =>
                        possibleDriverPayType.driverPayTypeName ===
                        driver.driverPayType,
                    )?.displayName
                  }
                </TableCell>
                <TableCell align="right">
                  <Fade in={hoveredDriverUuid === driver.uuid}>
                    <ButtonLink
                      href={`/management/drivers/${driver.uuid}`}
                      sx={styles.driverButton}
                      variant="contained"
                      disabled={!canWriteCompanyDrivers}
                    >
                      Edit
                    </ButtonLink>
                  </Fade>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DriverQualificationsModal
        open={driverQualificationsModalOpen}
        onClose={() => setDriverQualificationsModalOpen(false)}
      />
    </Stack>
  );
};

export default DriversTable;
