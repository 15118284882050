import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Stack,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  DialogContent,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import useDocuments from '../../../../../common/react-hooks/use-documents';
import { FormattedOrderFragment } from '../../../../../generated/graphql';
import { downloadImage } from '../../../../end-of-day/end-of-day-utils';

const PhotosModalNew = ({
  open,
  setOpen,
  order,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  order: FormattedOrderFragment | undefined;
}) => {
  const photos = order?.photosField?.orderTablePhotos ?? [];
  const { getDocumentTypeCopy, loading } = useDocuments();

  if (loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: '20px',
          gap: '20px',
        }}
      >
        <DialogTitle>Photos</DialogTitle>
        <DialogContent
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {photos.map((doc) => (
              <Stack spacing={2} direction="column" key={doc.fileName}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: '16px', color: 'gray' }}>
                      {doc.fileName} -{' '}
                      {getDocumentTypeCopy({
                        documentType: doc.type,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      sx={{ float: 'right' }}
                      onClick={() => {
                        downloadImage(doc.preSignedGetUrl, doc?.fileName);
                      }}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
                <Box sx={{ height: '80vh', overflowY: 'scroll' }}>
                  <object
                    data={doc.preSignedGetUrl}
                    width="100%"
                    style={{ height: '99%', objectFit: 'contain' }}
                  >
                    {' '}
                  </object>
                </Box>
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PhotosModalNew;
