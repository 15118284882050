enum ContactsPageTabs {
  DETAILS = 'DETAILS',
  STATIONS = 'STATIONS',
  PEOPLE = 'PEOPLE',
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  BILLING = 'BILLING',
  THIRD_PARTY = 'THIRD_PARTY',
  NOTIFICATIONS = 'NOTIFICATIONS',
  WAREHOUSE = 'WAREHOUSE',
}

export default ContactsPageTabs;
