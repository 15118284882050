import { PalletProductOffering } from '../../generated/graphql';
import { DefaultPalletPage } from '../../layouts/dashboard/constants';

export const getPageToOpenOnFirstLoad = (
  purchasedProductOfferings: PalletProductOffering[],
): DefaultPalletPage => {
  if (purchasedProductOfferings.includes(PalletProductOffering.Tms)) {
    return DefaultPalletPage.ORDERS;
  }
  if (
    purchasedProductOfferings.includes(PalletProductOffering.EmailIngestion)
  ) {
    return DefaultPalletPage.EMAIL_INGESTION;
  }
  return DefaultPalletPage.ORDERS;
};
