import { useSearchParams } from 'react-router-dom';
import SignDigitalDocumentView from '../domains/sign-digital-document/components/sign-digital-document';

/**
 * This page is intentionally left unauthenticated. It is used to show an unsigned delivery receipt.
 * generally navigate to it from their smartphone (which will not be logged into the Cashew platform).
 *
 * The internal component also calls a resolver that is left unauthenticated.
 *
 * @constructor
 */
export const SignDigitalDocumentPage = () => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get('orderUuid');
  const documentUuid = searchParams.get('documentUuid');

  return (
    <SignDigitalDocumentView
      orderUuid={orderUuid}
      documentUuid={documentUuid}
    />
  );
};
