import { useChannel } from '@ably-labs/react-hooks';
import { memo } from 'react';
import {
  AssignStopEventPayload,
  DispatchEvent,
  NewOrderEventPayload,
  RouteCreateEventPayload,
  RouteEditEventPayload,
  UnassignStopEventPayload,
} from 'shared/types';
import useFetchRoutes from '../../../domains/dispatch/hooks/use-fetch-routes';
import useRouteActions from '../../../domains/dispatch/hooks/use-route-actions';

const DispatchMultiplayerTableComponent = ({
  companyUuid,
  handleAssignStop,
  handleUnassignStop,
  handleNewOrder,
}: {
  companyUuid: string;
  handleAssignStop: (payload: AssignStopEventPayload) => void;
  handleUnassignStop: (payload: UnassignStopEventPayload) => void;
  handleNewOrder: (payload: NewOrderEventPayload) => void;
}) => {
  const { deleteRoute } = useRouteActions();
  const { fetchRoute, fetchRoutes } = useFetchRoutes();

  useChannel(`${companyUuid}:dispatch:stops`, async (message) => {
    try {
      if (message.name === DispatchEvent.ASSIGN_STOP) {
        const payload = JSON.parse(message.data) as AssignStopEventPayload;
        handleAssignStop(payload);
      } else if (message.name === DispatchEvent.UNASSIGN_STOP) {
        const payload = JSON.parse(message.data) as UnassignStopEventPayload;
        await handleUnassignStop(payload);
      } else if (message.name === DispatchEvent.NEW_ORDER) {
        const payload = JSON.parse(message.data) as NewOrderEventPayload;
        handleNewOrder(payload);
      } else if (message.name === DispatchEvent.CREATE_ROUTE) {
        const payload = JSON.parse(message.data) as RouteCreateEventPayload;
        fetchRoutes({ uuids: payload.routeUuids });
      } else if (message.name === DispatchEvent.EDIT_ROUTE) {
        const payload = JSON.parse(message.data) as RouteEditEventPayload;
        fetchRoute(payload.routeUuid);
      } else if (message.name === DispatchEvent.DELETE_ROUTE) {
        const payload = JSON.parse(message.data) as RouteEditEventPayload;
        deleteRoute(payload.routeUuid);
      }
    } catch (e) {
      console.error(
        `Error in dispatch multiplayer table component: ${JSON.stringify(e)}`,
      );
    }
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default memo(DispatchMultiplayerTableComponent);
