import { ExpandMore } from '@mui/icons-material';
import { Box, Button, Menu, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../common/feature-flags';
import useContacts from '../../../common/react-hooks/use-contacts';
import useDrivers from '../../../common/react-hooks/use-drivers';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import {
  OrderStatus,
  PickupOrDelivery,
  ReportAggregationPeriod,
  ReportRevenueType,
  ReportType,
} from '../../../generated/graphql';
import useReport from '../hooks/use-report';
import { getFilterLabelValue } from '../report-group-configuration-converter';
import useReportsStore from '../reports-store';
import useStyles from '../reports-styles';
import {
  convertReportFilterTypeToTitle,
  Option,
  ReportFilterType,
} from '../types/report-filters';
import ReportFilterAutocompleteMenuList from './report-filter-autocomplete-menu-list';
import ReportFilterAutocompleteMenuListMultiselect from './report-filter-autocomplete-menu-list-multiselect';
import ReportFilterDateRangeMenuList from './report-filter-date-range-menu-list';
import ReportFilterDateRangeMenuListV2 from './report-filter-date-range-menu-list-v2';
import ReportFilterMultiSelectMenuList from './report-filter-multi-select-menu-list';
import ReportFilterSingleSelectMenuList from './report-filter-single-select-menu-list';
import { getDriverNameLabel } from './utils';

type ReportFilterButtonProps = {
  type: ReportFilterType;
};

const ReportFilterButton = ({ type }: ReportFilterButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const styles = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const useNewReportDateRangeMenu = useFeatureFlag(
    FeatureFlag.FF_USE_NEW_REPORT_DATE_RANGE_MENU,
  );
  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);

  const { updateReportGroupConfiguration, loadReport } = useReport();
  const [businessDivisions, terminals] = useReportsStore(
    (state) => [state.businessDivisions, state.terminals],
    shallow,
  );
  const { contacts } = useContacts();
  const { drivers, getDriverName } = useDrivers();
  const [currentReportIndex, setCurrentReportGroupConfiguration] =
    useReportsStore(
      (state) => [
        state.currentReportIndex,
        state.setCurrentReportGroupConfiguration,
      ],
      shallow,
    );
  const reportGroupConfiguration = useReportsStore(
    (state) => state.reportGroupConfigurations[state.currentReportIndex],
    shallow,
  );

  const closeMenuAndUpdate = () => {
    updateReportGroupConfiguration(currentReportIndex);
    loadReport(currentReportIndex);
    setMenuAnchorEl(null);
  };

  const handleReportTypeChange = (option: string) => {
    if (!isNil(reportGroupConfiguration)) {
      setCurrentReportGroupConfiguration({
        ...reportGroupConfiguration,
        defaultReportType: option as ReportType,
      });
    }
  };

  const handleReportRevenueTypeChange = (option: string) => {
    if (!isNil(reportGroupConfiguration)) {
      setCurrentReportGroupConfiguration({
        ...reportGroupConfiguration,
        reportRevenueType: option as ReportRevenueType,
      });
    }
  };

  const handleAggregationPeriodChange = (option: string) => {
    if (!isNil(reportGroupConfiguration)) {
      setCurrentReportGroupConfiguration({
        ...reportGroupConfiguration,
        reportAggregationPeriod: option as ReportAggregationPeriod,
      });
    }
  };

  const handleStopTypeChange = (option: string) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      if (data.stopTypes.includes(option as PickupOrDelivery)) {
        data.stopTypes = data.stopTypes.filter((value) => value !== option);
      } else {
        data.stopTypes = [...data.stopTypes, option as PickupOrDelivery];
      }
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleOrderStatusChange = (option: string) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      if (data.orderStatuses.includes(option as OrderStatus)) {
        data.orderStatuses = data.orderStatuses.filter(
          (value) => value !== option,
        );
      } else {
        data.orderStatuses = [...data.orderStatuses, option as OrderStatus];
      }
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleContactChange = (option: Option | undefined) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      const contact = contacts?.find((c) => c.uuid === option?.value);
      data.contact =
        !isNil(option) && !isNil(contact)
          ? {
              uuid: contact.uuid,
              displayName: contact.displayName,
            }
          : undefined;
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleContactsMultiselectChange = (options: Option[]) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      data.contacts = filterNotNil(
        options.map((contact) => {
          if (isNil(contact.value) || isNil(contact.label)) return null;
          return {
            uuid: contact.value,
            displayName: contact.label,
          };
        }),
      );
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleBusinessDivisionChange = (option: Option | undefined) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      const businessDivision = businessDivisions?.find(
        (b) => b.uuid === option?.value,
      );
      data.businessDivision =
        !isNil(option) && !isNil(businessDivision)
          ? {
              uuid: businessDivision.uuid,
              name: businessDivision.name,
            }
          : undefined;
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleDriverChange = (option: Option | undefined) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      const driver = drivers?.find((d) => d.uuid === option?.value);
      data.driver =
        !isNil(option) && !isNil(driver)
          ? {
              uuid: driver.uuid,
              firstName: driver.firstName,
              lastName: driver.lastName,
            }
          : undefined;
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleDriversMultiselectChange = (options: Option[]) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      data.drivers = filterNotNil(
        options.map((driverOption) => {
          const driver = drivers?.find((d) => d.uuid === driverOption?.value);
          if (
            isNil(driverOption.value) ||
            isNil(driverOption.label) ||
            isNil(driver)
          )
            return null;
          return {
            uuid: driver.uuid,
            firstName: driver.firstName,
            lastName: driver.lastName,
          };
        }),
      );
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleTerminalChange = (option: Option | undefined) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      const terminal = terminals?.find((d) => d.uuid === option?.value);
      data.terminal =
        !isNil(option) && !isNil(terminal)
          ? {
              uuid: terminal.uuid,
              code: terminal.code,
            }
          : undefined;
      setCurrentReportGroupConfiguration(data);
    }
  };

  const filterValue = getFilterLabelValue(type, reportGroupConfiguration);

  const dateRangeReportFilter = useNewReportDateRangeMenu ? (
    <ReportFilterDateRangeMenuListV2 />
  ) : (
    <ReportFilterDateRangeMenuList />
  );

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        ref={buttonRef}
        size="small"
        sx={styles.filterButton}
        variant="outlined"
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>
            {convertReportFilterTypeToTitle(type)}:
          </Typography>
          <Typography sx={styles.filterValue}>{filterValue}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id={`${type}-menu`}
        onClose={() => closeMenuAndUpdate()}
        open={Boolean(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        {type === ReportFilterType.REPORT_TYPE && (
          <ReportFilterSingleSelectMenuList
            selectedOption={reportGroupConfiguration?.defaultReportType}
            options={[
              ReportType.Order,
              ...(ffUseStations ? [ReportType.Station] : []),
              ReportType.Driver,
              ReportType.Customer,
              ReportType.ServiceLevel,
              // ReportType.Terminal,
            ]}
            handleChange={handleReportTypeChange}
          />
        )}
        {type === ReportFilterType.REVENUE_TYPE && (
          <ReportFilterSingleSelectMenuList
            selectedOption={reportGroupConfiguration?.reportRevenueType}
            options={[ReportRevenueType.Earned, ReportRevenueType.Projected]}
            handleChange={handleReportRevenueTypeChange}
          />
        )}
        {type === ReportFilterType.SERVICE_DATE_RANGE && dateRangeReportFilter}
        {type === ReportFilterType.AGGREGATION_PERIOD && (
          <ReportFilterSingleSelectMenuList
            selectedOption={reportGroupConfiguration?.reportAggregationPeriod}
            options={[
              ReportAggregationPeriod.Day,
              ReportAggregationPeriod.Week,
              ReportAggregationPeriod.Month,
              ReportAggregationPeriod.Year,
            ]}
            handleChange={handleAggregationPeriodChange}
          />
        )}
        {type === ReportFilterType.STOP_TYPE && (
          <ReportFilterMultiSelectMenuList
            selectedOptions={reportGroupConfiguration?.stopTypes ?? []}
            options={Object.values(PickupOrDelivery)}
            handleChange={handleStopTypeChange}
          />
        )}
        {type === ReportFilterType.ORDER_STATUS && (
          <ReportFilterMultiSelectMenuList
            selectedOptions={reportGroupConfiguration?.orderStatuses ?? []}
            options={Object.values(OrderStatus)}
            handleChange={handleOrderStatusChange}
          />
        )}
        {type === ReportFilterType.CONTACT && (
          <ReportFilterAutocompleteMenuList
            allowAll={
              reportGroupConfiguration?.defaultReportType !== ReportType.Station
            }
            selectedOption={
              !isNil(reportGroupConfiguration?.contact)
                ? {
                    value: reportGroupConfiguration?.contact?.uuid,
                    label: reportGroupConfiguration?.contact?.displayName,
                  }
                : undefined
            }
            options={
              contacts?.map((contact) => ({
                value: contact.uuid,
                label: contact.displayName,
              })) ?? []
            }
            handleChange={handleContactChange}
          />
        )}
        {type === ReportFilterType.CONTACTS && (
          <ReportFilterAutocompleteMenuListMultiselect
            selectedOptions={
              reportGroupConfiguration?.contacts?.map((contact) => ({
                value: contact?.uuid,
                label: contact?.displayName,
              })) ?? []
            }
            options={
              contacts?.map((contact) => ({
                value: contact.uuid,
                label: contact.displayName,
              })) ?? []
            }
            handleChange={handleContactsMultiselectChange}
          />
        )}
        {type === ReportFilterType.DRIVERS && (
          <ReportFilterAutocompleteMenuListMultiselect
            selectedOptions={
              reportGroupConfiguration?.drivers?.map((driver) => ({
                value: driver.uuid,
                label: getDriverNameLabel(driver),
              })) ?? []
            }
            options={
              drivers?.map((driver) => ({
                value: driver.uuid,
                label: getDriverName(driver.uuid),
              })) ?? []
            }
            handleChange={handleDriversMultiselectChange}
          />
        )}
        {type === ReportFilterType.BUSINESS_DIVISION && (
          <ReportFilterAutocompleteMenuList
            selectedOption={
              !isNil(reportGroupConfiguration?.businessDivision)
                ? {
                    value: reportGroupConfiguration?.businessDivision?.uuid,
                    label: reportGroupConfiguration?.businessDivision?.name,
                  }
                : undefined
            }
            options={
              businessDivisions?.map((businessDivision) => ({
                value: businessDivision.uuid,
                label: businessDivision.name,
              })) ?? []
            }
            handleChange={handleBusinessDivisionChange}
          />
        )}
        {type === ReportFilterType.DRIVER && (
          <ReportFilterAutocompleteMenuList
            selectedOption={
              !isNil(reportGroupConfiguration?.driver)
                ? {
                    value: reportGroupConfiguration?.driver?.uuid,
                    label: getDriverNameLabel(reportGroupConfiguration?.driver),
                  }
                : undefined
            }
            options={
              drivers?.map((data) => ({
                value: data.uuid,
                label: getDriverName(data.uuid),
              })) ?? []
            }
            handleChange={handleDriverChange}
          />
        )}
        {type === ReportFilterType.TERMINAL && (
          <ReportFilterAutocompleteMenuList
            selectedOption={
              !isNil(reportGroupConfiguration?.terminal)
                ? {
                    value: reportGroupConfiguration?.terminal?.uuid,
                    label: reportGroupConfiguration?.terminal?.code,
                  }
                : undefined
            }
            options={
              terminals?.map((data) => ({
                value: data.uuid,
                label: data.code,
              })) ?? []
            }
            handleChange={handleTerminalChange}
          />
        )}
      </Menu>
    </Box>
  );
};

export default ReportFilterButton;
