import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { memo, useState } from 'react';
import { exhaustive } from 'shared/switch';
import {
  DetailedLineHaulManifestFragment,
  LineHaulManifestStatus,
} from '../../../../generated/graphql';
import useLineHaulDispatchActions from '../../hooks/use-line-haul-dispatch-actions';
import ManifestStatusChip from '../manifest-status-chip';

const OpenedManifestStatusPicker = ({
  manifest,
}: {
  manifest: DetailedLineHaulManifestFragment | undefined;
}) => {
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);

  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };

  const { arriveManifest, departManifest, markManifestAsBeingPlanned } =
    useLineHaulDispatchActions();

  if (isNil(manifest)) {
    return null;
  }
  const handleStatusTransition = (newStatus: LineHaulManifestStatus) => {
    switch (newStatus) {
      case LineHaulManifestStatus.Departed: {
        departManifest({ uuid: manifest.uuid, refreshGridAfter: true });
        break;
      }
      case LineHaulManifestStatus.Arrived: {
        arriveManifest({ uuid: manifest.uuid });
        break;
      }
      case LineHaulManifestStatus.Planning: {
        markManifestAsBeingPlanned({ uuid: manifest.uuid });
        break;
      }
      default:
        exhaustive(newStatus);
    }
  };

  // let statusesToShow: LineHaulManifestStatus[] = [];

  // if (manifest.status === LineHaulManifestStatus.Planning)
  //   statusesToShow = [LineHaulManifestStatus.Departed];

  // if (manifest.status === LineHaulManifestStatus.Departed)
  //   statusesToShow = [LineHaulManifestStatus.Arrived];

  // if (manifest.status === LineHaulManifestStatus.Arrived)
  //   statusesToShow = [LineHaulManifestStatus.Departed];

  return (
    <Box>
      <Stack
        pl={2}
        pr={2}
        pt={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">Status</Typography>
        <Button
          onClick={(e) => {
            setDropdownMenuRef(e.currentTarget);
          }}
          endIcon={<ArrowDropDownIcon />}
        >
          <ManifestStatusChip status={manifest.status} />
        </Button>
      </Stack>
      <Menu
        anchorEl={dropdownMenuRef}
        open={dropdownMenuOpen}
        onClose={closeDropdownMenu}
      >
        <MenuList>
          {Object.values(LineHaulManifestStatus).map((status) => {
            return (
              <MenuItem
                // disabled={locked}
                onClick={() => {
                  closeDropdownMenu();
                  handleStatusTransition(status);
                }}
              >
                <ManifestStatusChip status={status} />
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default memo(OpenedManifestStatusPicker);
