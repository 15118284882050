import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';

const DeleteTruckloadModal = ({
  open,
  setOpen,
  manifestUuids,
}: //   onDeleteRoute,
{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  manifestUuids: string[];
  //   onDeleteRoute: () => void;
}) => {
  const { stopSelectingManifests, deleteMultipleManifests, fetchManifests } =
    useLineHaulDispatchActions();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    await deleteMultipleManifests({
      manifestUuids,
    });
    stopSelectingManifests();
    await fetchManifests();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant="h6">
          Are you sure you want to delete these truckloads?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          This will unassign all orders from these line haul segments
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleConfirm}>
          Yes, delete truckloads
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeleteTruckloadModal);
