import { Box, Stack, SxProps, Tab, Tabs, Theme } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import useGlobalStore from '../../layouts/dashboard/global-store';
import { useAppDispatch } from '../../redux/hooks';
import { OrderDialog } from '../orders/components/order-dialog';
import { initExistingStandardOrderValues } from '../orders/redux/standard/standard-orders-values-thunks';
import SettlementBills from './components/driver-settlement-bills/settlement-bills';
import DriverSettlementReports from './components/driver-settlement-stops/driver-settlement-reports';
import DriverSettlementStops from './components/driver-settlement-stops/driver-settlement-stops';

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white',
  },
  iconButton: {
    position: 'fixed',
    left: 35,
    top: 35,
    color: (theme) => theme.palette.grey[500],
    zIndex: 9999,
  },
};

enum DriverSettlementTab {
  UNSETTLED_STOPS,
  SETTLEMENTS,
  REPORTS,
}

const DriverSettlementPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState<DriverSettlementTab>(
    DriverSettlementTab.UNSETTLED_STOPS,
  );
  const [openedOrderUuid, setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.currentOrderUuid, state.setCurrentOrderUuid],
    shallow,
  );
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('tab', currentTab.toString());
      return newParams;
    });
  }, [currentTab, setSearchParams]);

  useEffect(() => {
    if (typeof tab === 'string' && !isEmpty(tab)) {
      const tabIndex = parseInt(tab, 10);
      if (tabIndex === DriverSettlementTab.SETTLEMENTS) {
        setCurrentTab(DriverSettlementTab.SETTLEMENTS);
      } else if (tabIndex === DriverSettlementTab.UNSETTLED_STOPS) {
        setCurrentTab(DriverSettlementTab.UNSETTLED_STOPS);
      } else if (tabIndex === DriverSettlementTab.REPORTS) {
        setCurrentTab(DriverSettlementTab.REPORTS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNil(openedOrderUuid)) {
      dispatch(initExistingStandardOrderValues({ orderUuid: openedOrderUuid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOrderUuid]);

  return (
    <Box sx={styles.container}>
      <Stack direction="column" spacing={1} height="100%">
        <Box padding="15px 15px 0px 15px">
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={currentTab}
            onChange={(_, newIndex) => setCurrentTab(newIndex)}
            aria-label="driver settlement page tabs"
            sx={{ marginTop: '-10px' }}
          >
            <Tab label="Stops" />
            <Tab label="Settlements" />
            <Tab label="Reports" />
          </Tabs>
        </Box>
        <TabPanel
          selectedValue={currentTab}
          panelValue={DriverSettlementTab.UNSETTLED_STOPS}
        >
          <DriverSettlementStops
            shouldRefreshFromUpperComponent={shouldRefresh}
          />
        </TabPanel>
        <TabPanel
          selectedValue={currentTab}
          panelValue={DriverSettlementTab.SETTLEMENTS}
        >
          <SettlementBills shouldRefreshFromUpperComponent={shouldRefresh} />
        </TabPanel>
        <TabPanel
          selectedValue={currentTab}
          panelValue={DriverSettlementTab.REPORTS}
        >
          <DriverSettlementReports />
        </TabPanel>
      </Stack>
      {!isNil(openedOrderUuid) && (
        <OrderDialog
          open
          onClose={() => {
            setOpenedOrderUuid(undefined);
            setShouldRefresh(true);
          }}
          orderUuid={openedOrderUuid}
        />
      )}
    </Box>
  );
};

export default DriverSettlementPage;
