import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { ReactZoomPanPinchRef, TransformComponent } from 'react-zoom-pan-pinch';

const useStyles = () => ({
  button: {
    borderRadius: '4px',
    padding: '4px 10px 4px 10px',
    flexShrink: 0,
    // flexBasis: 1,
  },
});

/**
 *
 * @param zoomFns
 * @param originalUrl - URL to the original PDF file
 * @param url - URL to the image of the current page
 * @constructor
 */
const DocumentComponent = ({
  zoomFns,
  originalUrl,
  url,
  documentToggle,
  loading,
}: {
  zoomFns: ReactZoomPanPinchRef;
  url: string | undefined;
  originalUrl: string | undefined;
  documentToggle?: JSX.Element | null;
  loading: boolean;
}) => {
  const { zoomIn, zoomOut, resetTransform } = zoomFns;
  useEffect(() => {
    resetTransform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const styles = useStyles();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          '@container (max-width: 600px)': {
            flexDirection: 'column',
          },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => window.open(originalUrl, '_blank')}
            sx={styles.button}
          >
            View original
          </Button>
        </Box>
        {documentToggle}

        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 1, flexShrink: 0 }}
        >
          <IconButton onClick={() => zoomIn()}>
            <ZoomIn />
          </IconButton>
          <IconButton onClick={() => zoomOut()}>
            <ZoomOut />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        }}
      />
      {loading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      <TransformComponent>
        <img
          src={url}
          alt=""
          style={{
            width: '100%',
            maxHeight: '100%',
          }}
        />
      </TransformComponent>
    </>
  );
};

export default DocumentComponent;
