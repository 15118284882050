import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FunctionComponent, ReactNode } from 'react';
import { plainTimeToHourAndMinute } from 'shared/plain-date-time';
import type { AllSchedulingZoneFieldsFragment } from '../../../generated/graphql';
import { daysOfWeek, daysOfWeekAbbreviated } from './days-of-week';

type SchedulingZoneOverviewRowProps = {
  zone: AllSchedulingZoneFieldsFragment;
  onEdit: () => void;
};

export const SchedulingZoneOverviewRow: FunctionComponent<
  SchedulingZoneOverviewRowProps
> = ({ zone, onEdit }) => {
  return (
    <Stack direction="row" gap="20px">
      <Box
        sx={{
          width: '260px',
          minWidth: '260px',
        }}
      >
        <Typography variant="h4" sx={{ fontSize: '15px', fontWeight: 500 }}>
          {zone.name}
        </Typography>
        <Typography
          fontSize="14px"
          color="text-secondary"
          display="block"
          mb={1}
        >
          {daysOfWeek.reduce<ReactNode[]>((previousValue, day) => {
            const availability = zone.availabilities.find(
              (a) => a.dayOfWeek === day,
            );
            if (isNil(availability)) {
              return previousValue;
            }
            return [
              ...previousValue,
              ...(previousValue.length > 0
                ? [<span key={`${day}-sep`}>, </span>]
                : []),
              <span
                key={day}
                style={{
                  textWrap: 'nowrap',
                }}
              >
                {daysOfWeekAbbreviated[day]}{' '}
                {plainTimeToHourAndMinute(availability.start)}–
                {plainTimeToHourAndMinute(availability.end)}
              </span>,
            ];
          }, [])}
        </Typography>
        <Button variant="outlined" onClick={onEdit} size="small">
          Edit
        </Button>
      </Box>
      <Stack direction="row" flexGrow={1} gap={1} flexWrap="wrap">
        {zone.locations.length === 0 && (
          <Typography variant="caption" display="block">
            No locations
          </Typography>
        )}
        {zone.locations.map(({ id, zipcode, city }) => (
          <Chip
            key={id}
            label={
              <span>
                <span style={{ fontWeight: 700, paddingRight: '2px' }}>
                  {zipcode}
                </span>{' '}
                {city}
              </span>
            }
            sx={{
              borderRadius: '4px',
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
