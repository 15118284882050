import {
  Typography,
  TableRow,
  TableCell,
  Collapse,
  Box,
  useTheme,
  Checkbox,
  IconButton,
} from '@mui/material';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronUpIcon } from 'primereact/icons/chevronup';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { NotifiedDriver } from '../../../types/routes';

const TextDriverRow = ({
  notifiedDriver,
  isSelected,
  setSelectedRouteUuids,
}: {
  notifiedDriver: NotifiedDriver;
  isSelected: boolean;
  setSelectedRouteUuids: Dispatch<SetStateAction<string[]>>;
}) => {
  const [isMessageContentsOpen, setIsMessageContentsOpen] = useState(false);
  const theme = useTheme();
  const textDriversTableCellStyle = {
    width: '225px',
    borderBottom: isMessageContentsOpen ? 'none' : '',
  };
  return (
    <Box>
      <TableRow>
        <TableCell>
          <Checkbox
            size="medium"
            checked={isSelected}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRouteUuids((prevState) => [
                  ...prevState,
                  notifiedDriver.routeUuid,
                ]);
              } else {
                setSelectedRouteUuids((prevState) =>
                  prevState.filter(
                    (routeUuid) => routeUuid !== notifiedDriver.routeUuid,
                  ),
                );
              }
            }}
          />
        </TableCell>
        <TableCell sx={textDriversTableCellStyle}>
          {notifiedDriver.driverName}
        </TableCell>
        <TableCell sx={textDriversTableCellStyle}>
          {notifiedDriver.routeName}
        </TableCell>
        <TableCell sx={textDriversTableCellStyle}>
          {notifiedDriver.messageSummary}
        </TableCell>
        <TableCell
          sx={{
            width: '175px',
            borderBottom: isMessageContentsOpen ? 'none' : '',
          }}
        >
          <IconButton
            onClick={() => setIsMessageContentsOpen(!isMessageContentsOpen)}
          >
            {isMessageContentsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <Collapse
        sx={{
          borderBottom: '1px solid',
          borderColor: theme.palette.borderColor.main,
        }}
        in={isMessageContentsOpen}
        timeout="auto"
        unmountOnExit
      >
        <Box
          sx={{
            marginTop: '5px',
            marginBottom: '20px',
            marginLeft: '16px',
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Message contents
          </Typography>
          {notifiedDriver.messageContents.split('\n').map((line) => (
            <Typography variant="body2">{line}</Typography>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default TextDriverRow;
