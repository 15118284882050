import InfoIcon from '@mui/icons-material/Info';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import { AppointmentWindowType } from '../../../generated/graphql';
import useGenerateRoutesStore from './use-generate-routes-store';

const getAppointmentWindowTypeTooltipText = (type: AppointmentWindowType) => {
  switch (type) {
    case AppointmentWindowType.Strict:
      return 'Must deliver within the given appointment window - may make it harder to generate a solution';
    case AppointmentWindowType.Soft:
      return 'Permits deliveries outside of the appointment window, but an increasing penalty is applied the further from the window you arrive';
    case AppointmentWindowType.SoftStart:
      return 'Permits early deliveries';
    case AppointmentWindowType.SoftEnd:
      return 'Permits late deliveries';
    default:
      return exhaustive(type);
  }
};

const GeneralConstraintSettings = () => {
  const [appointmentWindowType, setAppointmentWindowType] =
    useGenerateRoutesStore(
      (state) => [state.appointmentWindowType, state.setAppointmentWindowType],
      shallow,
    );
  return (
    <Stack spacing={1}>
      <FormControl>
        <FormLabel id="appointment-window-radio-group-label">
          Appointment window
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="appointment-window-radio-group-label"
          name="appointment-window-radio-group"
          value={appointmentWindowType ?? ''}
          onChange={(e) => {
            setAppointmentWindowType(e.target.value as AppointmentWindowType);
          }}
        >
          {Object.values(AppointmentWindowType).map((type) => (
            <FormControlLabel
              key={type}
              value={type}
              control={<Radio />}
              label={
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body2">{sentenceCase(type)}</Typography>
                  <Tooltip title={getAppointmentWindowTypeTooltipText(type)}>
                    <InfoIcon color="info" sx={{ fontSize: 13 }} />
                  </Tooltip>
                </Stack>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default React.memo(GeneralConstraintSettings);
