import { createTheme } from '@mui/material';
import { backdropClasses } from '@mui/material/Backdrop';
import {
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

/** We only want to allow non-integer spacing for small values. */
const VALID_SPACING_VALUES = [
  0, 0.25, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 8, 10, 15, 50,
] as const;

type SpacingArgument = (typeof VALID_SPACING_VALUES)[number];

/** MUI default spacing scaling factor */
const SCALING_FACTOR_PX = 8;

/**
 * By default, MUI allows any `number` as a spacing value
 * We want to restrict it to `VALID_SPACING_VALUES`. To do this at compile-time,
 * we redeclare the MUI Theme type to use our `SpacingArgument` (see below).
 *
 * This overrides the types for `theme.spacing()`, but overriding them for
 * `spacing` props would require redeclaring `ResponsiveStyleValue` for every
 * component, so we leave those as-is and throw a runtime error if the value is invalid.
 */
const spacing = (abs: SpacingArgument) => {
  // TODO(Elliot): reintroduce this once we've fixed all existing invalid values
  // if (isDevelopment()) {
  //   if (!VALID_SPACING_VALUES.includes(abs)) {
  //     throw new Error(`Invalid spacing value: ${abs}`);
  //   }
  // }
  return `${abs * SCALING_FACTOR_PX}px`;
};

/**
 * Required for TypeScript
 * https://mui.com/material-ui/customization/theming/#custom-variables
 */
declare module '@mui/material/styles' {
  interface Theme {
    // Override Spacing type to use new SpacingArgument type
    // Copied from @mui/system/createTheme/createSpacing
    spacing: {
      (): string;
      (abs: SpacingArgument): string;
      (topBottom: SpacingArgument, rightLeft: SpacingArgument): string;
      (
        top: SpacingArgument,
        rightLeft: SpacingArgument,
        bottom: SpacingArgument,
      ): string;
      (
        top: SpacingArgument,
        right: SpacingArgument,
        bottom: SpacingArgument,
        left: SpacingArgument,
      ): string;
    };
  }

  interface ThemeOptions {
    spacing: (abs: SpacingArgument) => string;
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  export interface SimpleColorScale {
    10?: string;
    20?: string;
    30?: string;
    40?: string;
    50?: string;
    60?: string;
    70?: string;
    80?: string;
    90?: string;
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  interface PaletteOptions {
    unassignedColor: SimplePaletteColorOptions;
    selectColor: SimplePaletteColorOptions;
    blueLink: SimplePaletteColorOptions;
    borderColor: SimplePaletteColorOptions;
    redColor: SimplePaletteColorOptions;
    hoverColor: SimplePaletteColorOptions;
    paleRed: SimplePaletteColorOptions;
    paleGreen: SimplePaletteColorOptions;
    paleOrange: SimplePaletteColorOptions;
    paleBlue: SimplePaletteColorOptions;
    blueBanner: SimplePaletteColorOptions;
    redBanner: SimplePaletteColorOptions;
    concreteGrey: SimpleColorScale;
    airfreightBlue: SimpleColorScale;
    signalOrange: SimpleColorScale;
    successGreen: SimpleColorScale;
    warningYellow: SimpleColorScale;
    errorRed: SimpleColorScale;
    greyWhiteAlpha: SimpleColorScale;
    greyBlackAlpha: SimpleColorScale;
  }

  interface Palette {
    unassignedColor: Palette['primary'];
    selectColor: Palette['primary'];
    blueLink: Palette['primary'];
    borderColor: Palette['primary'];
    redColor: Palette['primary'];
    hoverColor: Palette['primary'];
    paleRed: Palette['primary'];
    paleGreen: Palette['primary'];
    paleOrange: Palette['primary'];
    paleBlue: Palette['primary'];
    blueBanner: Palette['primary'];
    redBanner: Palette['primary'];
    concreteGrey: SimpleColorScale;
    airfreightBlue: SimpleColorScale;
    signalOrange: SimpleColorScale;
    successGreen: SimpleColorScale;
    warningYellow: SimpleColorScale;
    errorRed: SimpleColorScale;
    greyWhiteAlpha: SimpleColorScale;
    greyBlackAlpha: SimpleColorScale;
  }
}

export const inputBorderColor = 'hsl(0 0% 0% / 0.23)';

export const optionFocusedBackgroundColor = 'hsl(0 0% 0% / 4%)';

export const optionSelectedBackgroundColor = 'hsl(24 81.5% 44.51% / 8%)';

export const optionFocusedSelectedBackgroundColor =
  'hsl(24 81.5% 44.51% / 12%)';

const palette: PaletteOptions = {
  primary: {
    main: '#CE5F15',
    light: '#FAEFE8',
  },
  secondary: {
    main: '#CE3115',
  },
  error: {
    main: '#d32f2f',
  },
  background: {
    // Light gray card background
    default: '#F7F7F7',
  },
  selectColor: {
    main: '#00CED1',
  },
  unassignedColor: {
    main: '#838582',
  },
  blueLink: {
    main: '#1D388F',
  },
  borderColor: {
    main: '#e0e0e0',
    dark: '#C7C7C7',
  },
  redColor: {
    main: '#CE3115',
    dark: '#661405',
  },
  hoverColor: {
    main: '#e5e5e5',
  },
  paleRed: {
    main: '#FF6969',
  },
  paleGreen: {
    main: '#22A699',
  },
  paleBlue: {
    main: '#A6D0DD',
  },
  blueBanner: {
    main: '#E6F6FD',
    light: '#2196F3',
    dark: '#014361',
  },
  redBanner: {
    main: '#5F2120',
    light: '#FDEDED',
    dark: '#FF5252',
  },
  paleOrange: {
    main: '#F49D1A',
  },
  text: {
    primary: 'hsl(0 0% 6%)',
    disabled: 'rgba(0,0,0,0.5)',
  },
  // NEW COLORS, avoid using the above colors
  concreteGrey: {
    10: '#FDFDFD',
    20: '#F6F6F7',
    30: '#DCDDE1',
    // 40: '#B6B8C1',
    50: '#888D9D',
    // 60: '#5A6178',
    // 70: '#373B49',
    // 80: '#1C1F26',
    // 90: '#0A0B0D',
  },
  airfreightBlue: {
    // 10: '#F4F6FC',
    // 20: '#EFF2FB',
    // 30: '#D9E0F6',
    // 40: '#B4C2EE',
    // 50: '#7796E4',
    60: '#4972C7',
    70: '#30599B',
    // 80: '#273D6A',
    90: '#141F36',
  },
  signalOrange: {
    // 10: '#F4DCD1',
    // 20: '#FDCFBE',
    // 30: '#E8B49B',
    // 40: '#E09A78',
    // 50: '#D87F54',
    // 60: '#CE632F',
    // 70: '#AE542B',
    // 80: '#914621',
    // 90: '#74381B',
  },
  successGreen: {
    // 10: '#CCFCEC',
    // 20: '#9FFFDC',
    // 30: '#00F79D',
    // 40: '#00D688',
    // 50: '#00AE6F',
    // 60: '#008454',
    // 70: '#00613E',
    // 80: '#00452C',
    // 90: '#003320',
  },
  warningYellow: {
    // 10: '#F9EBCA',
    // 20: '#F4DFA7',
    // 30: '#ECC667',
    // 40: '#E4AC21',
    // 50: '#CB9819',
    // 60: '#B18516',
    // 70: '#987213',
    // 80: '#806010',
    // 90: '#684E0D',
  },
  errorRed: {
    // 10: '#FDF1EE',
    // 20: '#FADFDA',
    // 30: '#F6BDB3',
    // 40: '#F19B8B',
    // 50: '#EC7661',
    // 60: '#E54A2E',
    // 70: '#C53218',
    // 80: '#9C2813',
    // 90: '#741E0E',
  },
  greyWhiteAlpha: {
    // 10: '#FDFDFDF7',
    // 20: '#FDFDFDD8',
    // 30: '#FDFDFDBA',
    // 40: '#FDFDFD9C',
    // 50: '#FDFDFD7D',
    // 60: '#FDFDFD5E',
    // 70: '#FDFDFD40',
    // 80: '#FDFDFD21',
    // 90: '#FDFDFD03',
  },
  greyBlackAlpha: {
    // 10: '#0A0B0D0D',
    // 20: '#0A0B0D1C',
    // 30: '#0A0B0D39',
    // 40: '#0A0B0D55',
    // 50: '#0A0B0D71',
    // 60: '#0A0B0D8E',
    // 70: '#0A0B0DAA',
    // 80: '#0A0B0DC6',
    // 90: '#0A0B0DE3',
  },
} as const;

const typography: TypographyOptions = {
  button: {
    textTransform: 'none',
  },
};

const components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-sizeSmall': {
          padding: '0 31px 0 0 !important',
        },
      },
      hasClearIcon: {
        '& .MuiInputBase-sizeSmall.MuiOutlinedInput-root': {
          paddingRight: '55px !important',
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        lineHeight: '22px',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        '&:last-child': {
          paddingBottom: '4px',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '5px',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        // Floating label when the input field is empty and not focused.
        ':has(.MuiInputBase-sizeSmall) .MuiFormLabel-root.MuiInputLabel-animated:not(.MuiInputLabel-shrink)':
          {
            transform: 'translate(10px, 7px)',
          },
        '.MuiFormLabel-root.MuiInputLabel-animated:not(.MuiInputLabel-shrink)':
          {
            opacity: '0.65',
          },
      },
    },
  },
  // Radio input options labels.
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: '-7px',
      },
      label: {
        fontSize: '14px',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: '2px 2px 0 2px',
      },
    },
  },
  // Top-level labels on form fields.
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        color: palette.text?.primary,
      },
      asterisk: {
        color: palette.redColor.main,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      sizeSmall: {
        // Textarea wrapper.
        '&.MuiInputBase-multiline': {
          padding: 0,
        },
        '& .MuiInputAdornment-root': {
          marginLeft: 0,
        },
        '&.MuiInputBase-adornedEnd:not(.MuiAutocomplete-inputRoot)': {
          paddingRight: 0,
          '& .MuiIconButton-root': {
            padding: '4px',
            marginRight: 0,
          },
          '& .MuiTypography-root': {
            padding: '4px',
          },
        },
      },
      inputSizeSmall: {
        '&.MuiInputBase-input': {
          fontSize: '15.25px',
          paddingTop: '6px !important',
          paddingBottom: '6px !important',
          paddingLeft: '7px !important',
          height: '22px',
          lineHeight: '22px',
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: '5px',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        '&.MuiInputBase-inputSizeSmall': {
          padding: '6px 31px 6px 10px !important',
          minHeight: '22px',
        },
      },
    },
  },
  // Hack to fix modals blocking the entire screen if the transition fails
  // https://github.com/mui/material-ui/issues/32286#issuecomment-1820685334
  MuiModal: {
    styleOverrides: {
      root: {
        [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]: {
          pointerEvents: 'none',
        },
      },
    },
  },
};

/**
 * Default theme options: https://mui.com/material-ui/customization/default-theme/
 */
export const getTheme = (ffNewFont: boolean) => {
  return createTheme({
    palette,
    typography: {
      ...typography,
      fontFamily: ffNewFont ? 'var(--pallet-font-family)' : undefined,
    },
    spacing,
    components,
  });
};

const theme = getTheme(false);

export default theme;
