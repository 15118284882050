import { forwardRef, RefCallback } from 'react';
import { IMaskInput } from 'react-imask';

interface PhoneInputMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

/**
 * Text input with a phone number mask
 *
 * Implementation borrowed from https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
 */
const PhoneInputMask = forwardRef<HTMLElement, PhoneInputMaskProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  },
);

export default PhoneInputMask;
