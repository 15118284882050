import { Chip, TextField, useTheme } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { DocumentType } from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDocuments from '../react-hooks/use-documents';
import { Option } from '../types';

interface DocumentTypeSelectorProps {
  value: DocumentType[];
  label?: string;
  disabled?: boolean;
  onChange: (value: DocumentType[]) => void;
  onBlur?: () => void;
}
const DocumentTypeSelector = ({
  value,
  label,
  disabled,
  onChange,
  onBlur,
}: DocumentTypeSelectorProps) => {
  const theme = useTheme();
  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  const options = useMemo(() => {
    return (
      documentTypesForSelection?.map((documentType) => ({
        label: getDocumentTypeCopy({ documentType }),
        value: documentType,
      })) ?? []
    );
  }, [documentTypesForSelection, getDocumentTypeCopy]);

  const selectedOptions = useMemo(() => {
    return value.map((documentType) => ({
      label:
        getDocumentTypeCopy?.({ documentType }) ?? sentenceCase(documentType),
      value: documentType,
    }));
  }, [getDocumentTypeCopy, value]);

  const handleChange = (newValue: Option<DocumentType>[]) => {
    onChange(newValue.map((option) => option.value));
  };
  if (loading) return null;
  return (
    <AutocompleteFuzzy
      options={options}
      value={selectedOptions}
      onChange={(_, newValue) => {
        handleChange(newValue);
      }}
      limitTags={1} // limit so that selector does not grow beyond one line
      size="small"
      matchSortOptions={{ keys: ['label'] }}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      disabled={disabled}
      multiple
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          label={label}
          size="small"
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        />
      )}
      onBlur={onBlur}
      renderTags={(tagsValue, getTagProps) => {
        if (tagsValue.length === 1) {
          const firstTag = tagsValue[0];
          if (!isNil(firstTag)) {
            return (
              <Chip
                {...getTagProps({ index: 0 })}
                label={firstTag.label}
                key={firstTag.value}
                size="small"
              />
            );
          }
        }
        return (
          <Chip label={`${tagsValue.length} documents selected`} size="small" />
        );
      }}
    />
  );
};
export default DocumentTypeSelector;
