import { Button, Menu, MenuItem } from '@mui/material';
import { isNil } from 'lodash';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import useMe from '../../../common/react-hooks/use-me';
import useTerminals from '../../../common/react-hooks/use-terminals';
import useDispatchStore from '../../../domains/dispatch/dispatch-store';
import useRouteActions from '../../../domains/dispatch/hooks/use-route-actions';
import { MeDocument, useUpdateUserMutation } from '../../../generated/graphql';
import useGlobalStore from '../global-store';

const NavbarTerminalSelector = ({ color }: { color?: string }) => {
  const { pathname } = useLocation();
  const { user, refetchMeData } = useMe();
  const [updateUser] = useUpdateUserMutation({ refetchQueries: [MeDocument] });
  const { clearRoutes } = useRouteActions();
  const abortController = useRef<AbortController>(new AbortController());
  const [setRoutesLoading] = useDispatchStore(
    (state) => [state.setRoutesLoading],
    shallow,
  );
  const [selectedTerminalUuid, setSelectedTerminalUuid] = useGlobalStore(
    (state) => [state.selectedTerminalUuid, state.setSelectedTerminalUuid],
    shallow,
  );
  const [terminalAnchorEl, setTerminalAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const { terminals, getTerminalName } = useTerminals({
    includeInactiveTerminals: false,
  });

  const isOnDispatchPage = pathname === '/dispatch';

  const handleSelectTerminal = (terminalUuid: string | undefined) => {
    if (selectedTerminalUuid !== terminalUuid && isOnDispatchPage) {
      clearRoutes();
      setRoutesLoading(true);
    }
    setSelectedTerminalUuid(terminalUuid);
    setTerminalAnchorEl(null);
  };

  useEffect(() => {
    if (!isNil(user)) {
      abortController.current.abort();

      const newAbortController = new AbortController();
      abortController.current = newAbortController;

      const update = async () => {
        try {
          await updateUser({
            variables: {
              updateUserInput: {
                uuid: user.uuid,
                terminalUuid: selectedTerminalUuid ?? null,
              },
            },
            context: !isNil(abortController)
              ? {
                  fetchOptions: {
                    signal: abortController.current.signal,
                  },
                }
              : undefined,
          });
          await refetchMeData();
        } catch (error) {
          if (error instanceof Error) {
            if (error.name !== 'ApolloError') {
              console.error(
                'Unknown error while updating user or aborting update user call',
                error,
              );
            }
          } else {
            console.error('Error updating user', error);
          }
        }
      };

      update();
    }

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerminalUuid]);

  return (
    <>
      <Button
        sx={{ color: color ?? 'white' }}
        endIcon={<ChevronDownIcon />}
        onClick={(e) => {
          setTerminalAnchorEl(e.currentTarget);
        }}
      >
        {!isNil(selectedTerminalUuid)
          ? getTerminalName(selectedTerminalUuid)
          : 'All Terminals'}
      </Button>
      <Menu
        id="terminal-menu"
        anchorEl={terminalAnchorEl}
        open={!isNil(terminalAnchorEl)}
        onClose={() => {
          setTerminalAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {terminals
          ?.filter((terminal) => !isNil(terminal))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((terminal) => (
            <MenuItem
              key={terminal.uuid}
              value={terminal.uuid}
              selected={selectedTerminalUuid === terminal.uuid}
              onClick={() => handleSelectTerminal(terminal.uuid)}
            >
              {terminal.name}
            </MenuItem>
          ))}
        <MenuItem
          key="all"
          value="all"
          selected={selectedTerminalUuid === undefined}
          onClick={() => handleSelectTerminal(undefined)}
        >
          All Terminals
        </MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(NavbarTerminalSelector);
