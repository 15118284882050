import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { isNil } from 'lodash';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../../common/react-hooks/use-logout';
import useMe from '../../../common/react-hooks/use-me';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import { EnvironmentVariables } from '../../../environment-variables';

const FOOTER_HEIGHT = '64px';

const CompanyLogo = styled('img')({
  aspectRatio: 1,
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '4px',
  objectFit: 'contain',
});

type SettingsTab = {
  pathname: string;
  tab: string;
  label: string;
};

const SETTINGS_TABS: SettingsTab[] = [
  {
    pathname: '/settings',
    tab: 'roles',
    label: 'Roles & permissions',
  },
  { pathname: '/settings', tab: 'general', label: 'Company settings' },
];

const UserMenu = () => {
  const logout = useLogout();
  const { user, companyConfiguration, companyName } = useMe();
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Stack justifyContent="flex-start" height={FOOTER_HEIGHT} padding="0">
        <Stack
          direction="row"
          alignItems="center"
          height="100%"
          padding="12px 8px"
          gap="8px"
        >
          {!isNil(companyConfiguration?.invoicePdfLogoUrl) && (
            <CompanyLogo
              alt="Company logo"
              src={companyConfiguration?.invoicePdfLogoUrl}
            />
          )}
          <Stack flex={1} minWidth="0px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="white"
                noWrap
                fontWeight={600}
              >
                {!isNilOrEmptyString(companyName)
                  ? capitalCase(companyName)
                  : 'No company'}
              </Typography>
              <IconButton
                onClick={(e) => {
                  setUserAnchorEl(e.currentTarget);
                }}
              >
                <ChevronDownIcon color="white" width="16px" height="16px" />
              </IconButton>
            </Stack>
            <Typography variant="caption" color="white" noWrap>
              {user?.email}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Menu
        id="user-menu"
        anchorEl={userAnchorEl}
        open={!isNil(userAnchorEl)}
        onClose={() => {
          setUserAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList dense sx={{ py: 0 }}>
          {SETTINGS_TABS.map(({ pathname, tab, label }) => (
            <MenuItem
              key={tab}
              onClick={() => {
                navigate(`${pathname}?tab=${tab}`);
                setUserAnchorEl(null);
              }}
              sx={{
                fontSize: 16,
              }}
            >
              {label}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            onClick={() => {
              logout();
            }}
            sx={{
              color: theme.palette.redColor.main,
              fontSize: 16,
            }}
          >
            Logout
          </MenuItem>
          <Divider />
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default',
              },
              fontSize: 12,
              color: theme.palette.text.primary,
            }}
          >
            App Version:{' '}
            {EnvironmentVariables.VITE_RENDER_GIT_COMMIT?.slice(0, 5)}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export { UserMenu };
