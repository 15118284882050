import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import { CsvError, parse } from 'csv-parse/browser/esm';
import { isNil } from 'lodash';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  CsvRouteNameInput,
  PermissionResource,
  RouteNamesDocument,
  useBulkUploadRouteNamesMutation,
  useRouteNamesQuery,
} from '../../../generated/graphql';
import RouteNameLocationsModal from './route-name-locations-modal';
import RouteNameRow from './route-name-row';

const BULK_UPLOAD_TEMPLATE_HEADERS = [['Name', 'City', 'Zipcode']];

const RouteNames = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyRouteNames } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRouteNames,
  );

  const { data: routeNamesData } = useRouteNamesQuery();
  const [csvUploadError, setCsvUploadError] = useState<boolean>(false);
  const [bulkUploadRouteNames] = useBulkUploadRouteNamesMutation({
    refetchQueries: [RouteNamesDocument],
  });
  const [modalUuid, setModalUuid] = useState<string | null>(null);

  const handleSaveCsvUpload = async (upload: CsvRouteNameInput[]) => {
    const res = await bulkUploadRouteNames({
      variables: { bulkCsvRouteNameInput: { csvRouteNameInputs: upload } },
    });
    if (!isNil(res.errors)) {
      setCsvUploadError(true);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const csv = reader.result?.toString(); // Get the CSV data as a string
        if (csv !== undefined) {
          parse(
            csv,
            {
              delimiter: ',',
              columns: ['routeName', 'city', 'zipcode'],
              fromLine: 2,
            },
            (error: CsvError | undefined, result: CsvRouteNameInput[]) => {
              if (!isNil(error)) {
                setCsvUploadError(true);
              } else {
                setCsvUploadError(false);
                handleSaveCsvUpload(result);
              }
            },
          );
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Box>
      {!isNil(modalUuid) && (
        <RouteNameLocationsModal
          uuid={modalUuid}
          name={
            routeNamesData?.routeNames.find(
              (routeName) => routeName.uuid === modalUuid,
            )?.name ?? ''
          }
          setOpen={setModalUuid}
          open={!isNil(modalUuid)}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setCsvUploadError(false)}
        open={csvUploadError}
      >
        <Alert severity="error">
          Error uploading CSV. Please check format (route name, city, zip) and
          ensure no city-zip combinations are duplicated.
        </Alert>
      </Snackbar>
      <Grid container spacing="2" rowSpacing={5}>
        <Grid item xs={12}>
          <Grid container sx={{ px: 1 }}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Stack direction="row" spacing={1} justifyContent="right">
                <CSVLink
                  data={BULK_UPLOAD_TEMPLATE_HEADERS}
                  filename="route-name-upload.csv"
                >
                  <Button variant="outlined">Download CSV Template</Button>
                </CSVLink>
                <Button
                  component="label"
                  variant="contained"
                  sx={{ float: 'right' }}
                  disabled={!canWriteCompanyRouteNames}
                >
                  Bulk Upload from CSV
                  <input
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileUpload}
                    multiple
                  />
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routeNamesData?.routeNames.map((routeName) => (
                      <RouteNameRow
                        key={routeName.uuid}
                        uuid={routeName.uuid}
                        name={routeName.name}
                        setModalUuid={setModalUuid}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RouteNames;
