import { useThirdPartyBrokersQuery } from '../../generated/graphql';

const useThirdPartyBrokers = () => {
  const { data: thirdPartyBrokersData, refetch: refetchThirdPartyBrokers } =
    useThirdPartyBrokersQuery({
      fetchPolicy: 'cache-first',
    });

  const getThirdPartyBrokerName = (uuid: string | null | undefined) => {
    return thirdPartyBrokersData?.thirdPartyBrokers.find(
      (thirdPartyBroker) => thirdPartyBroker.uuid === uuid,
    )?.name;
  };

  return {
    thirdPartyBrokers: thirdPartyBrokersData?.thirdPartyBrokers ?? [],
    getThirdPartyBrokerName,
    refetchThirdPartyBrokers,
  };
};

export default useThirdPartyBrokers;
