import useCustomerPortalStore from '../../domains/customer-portal/use-customer-portal-store';
import { useWarehousesQuery } from '../../generated/graphql';

const useWarehouses = () => {
  const customerPortalCompany = useCustomerPortalStore(
    ({ company }) => company,
  );
  const { data: warehousesData, refetch } = useWarehousesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      warehousesInput: {
        companyUuidFilter: customerPortalCompany?.uuid,
      },
    },
  });

  return {
    refetch,
    warehouses: warehousesData?.warehouses,
  };
};

export default useWarehouses;
