import {
  Box,
  Dialog,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
  FormLabel,
  FormControl,
  Button,
  Checkbox,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { v4 } from 'uuid';
import { useUpdateTemplateAndRecordAddressesMutation } from '../../../../../../generated/graphql';
import { AddressFormField } from '../../../../../addresses/redux/addresses-values-slice';
import { ContactPersonValues } from '../../forms/types';

const EditAddressDialog = ({
  open,
  setOpen,
  address,
  contactPerson,
  specialInstructions,
  internalNotes,
  setShouldRefreshAddresses,
  setNewAddress,
  setNewContact,
  setSpecialInstructions,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  address: AddressFormField;
  contactPerson?: ContactPersonValues;
  specialInstructions: string;
  internalNotes: string;
  setShouldRefreshAddresses: Dispatch<SetStateAction<boolean>>;
  setNewAddress: (newAddress: AddressFormField) => void;
  setNewContact: (newContact: ContactPersonValues) => void;
  setSpecialInstructions: (newSpecialInstructions: string) => void;
}) => {
  const [addressInputValue, setAddressInputValue] =
    useState<AddressFormField>(address);
  const [contactPersonInputValue, setContactPersonInputValue] =
    useState<ContactPersonValues>(contactPerson ?? null);
  const [specialInstructionsInputValue, setSpecialInstructionsInputValue] =
    useState(specialInstructions);
  const [internalNotesInputValue, setInternalNotesInputValue] =
    useState(internalNotes);
  const [updateAddress] = useUpdateTemplateAndRecordAddressesMutation();
  const [
    saveContactAndSpecialInstructions,
    setSaveContactAndSpecialInstructions,
  ] = useState(true);

  const handleUpdateAddress = async () => {
    await updateAddress({
      variables: {
        updateAddressInput: {
          contactPersonUpsertInput:
            !isNil(contactPersonInputValue) && saveContactAndSpecialInstructions
              ? {
                  uuid: contactPersonInputValue.uuid,
                  firstName: contactPersonInputValue.firstName,
                  lastName: contactPersonInputValue.lastName,
                  email: contactPersonInputValue.email,
                  phone: contactPersonInputValue.phone,
                }
              : undefined,
          addressUpdateInput: {
            uuid: addressInputValue.uuid,
            name: addressInputValue.name,
            line1: addressInputValue.line1,
            line2: addressInputValue.line2,
            city: addressInputValue.city,
            state: addressInputValue.state,
            zip: addressInputValue.zip,
            specialInstructions: saveContactAndSpecialInstructions
              ? specialInstructionsInputValue
              : address.specialInstructions,
            internalNotes: internalNotesInputValue,
            iataCode: addressInputValue.iataCode,
          },
        },
      },
    });
    setOpen(false);
    setShouldRefreshAddresses(true);
    if (saveContactAndSpecialInstructions) {
      setSpecialInstructions(specialInstructionsInputValue);
    }
    setNewAddress({
      uuid: addressInputValue.uuid,
      name: addressInputValue.name,
      line1: addressInputValue.line1,
      line2: addressInputValue.line2,
      city: addressInputValue.city,
      state: addressInputValue.state,
      zip: addressInputValue.zip,
      isLocal: false,
      specialInstructions: saveContactAndSpecialInstructions
        ? specialInstructionsInputValue
        : undefined,
      internalNotes: internalNotesInputValue,
      iataCode: addressInputValue.iataCode,
    });
    if (!isNil(contactPersonInputValue) && saveContactAndSpecialInstructions) {
      setNewContact(contactPersonInputValue);
    }
  };

  const ContactComponent = (
    <>
      <Grid item md={12}>
        <FormLabel>Contact Info</FormLabel>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid item md={2.5}>
          <FormControl>
            <TextField
              label="First Name"
              size="small"
              value={contactPersonInputValue?.firstName}
              onChange={(e) => {
                const input = e.target.value;
                if (isNil(contactPersonInputValue)) {
                  setContactPersonInputValue({
                    uuid: v4(),
                    firstName: input,
                    lastName: undefined,
                    email: undefined,
                    phone: undefined,
                  });
                } else {
                  setContactPersonInputValue({
                    ...contactPersonInputValue,
                    firstName: input,
                  });
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl>
            <TextField
              label="Last Name"
              size="small"
              value={contactPersonInputValue?.lastName}
              onChange={(e) => {
                const input = e.target.value;
                if (isNil(contactPersonInputValue)) {
                  setContactPersonInputValue({
                    uuid: v4(),
                    firstName: undefined,
                    lastName: input,
                    email: undefined,
                    phone: undefined,
                  });
                } else {
                  setContactPersonInputValue({
                    ...contactPersonInputValue,
                    lastName: input,
                  });
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl>
            <TextField
              label="Phone"
              size="small"
              value={contactPersonInputValue?.phone}
              onChange={(e) => {
                const input = e.target.value;
                if (isNil(contactPersonInputValue)) {
                  setContactPersonInputValue({
                    uuid: v4(),
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined,
                    phone: input,
                  });
                } else {
                  setContactPersonInputValue({
                    ...contactPersonInputValue,
                    phone: input,
                  });
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={2.5}>
          <FormControl>
            <TextField
              label="Email"
              size="small"
              value={contactPersonInputValue?.email}
              onChange={(e) => {
                const input = e.target.value;
                if (isNil(contactPersonInputValue)) {
                  setContactPersonInputValue({
                    uuid: v4(),
                    firstName: undefined,
                    lastName: undefined,
                    email: input,
                    phone: undefined,
                  });
                } else {
                  setContactPersonInputValue({
                    ...contactPersonInputValue,
                    email: input,
                  });
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DialogTitle>Edit saved address</DialogTitle>
        <Grid
          item
          md={12}
          sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
        >
          <Grid item md={12}>
            <FormLabel>Address Info</FormLabel>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              name="address"
              size="small"
              placeholder="Name"
              type="text"
              onChange={(e) => {
                setAddressInputValue({
                  ...addressInputValue,
                  name: e.target.value,
                });
              }}
              sx={{ width: '100%' }}
              value={addressInputValue.name}
            />
            <TextField
              name="address"
              size="small"
              placeholder="Airport code"
              type="text"
              onChange={(e) => {
                setAddressInputValue({
                  ...addressInputValue,
                  iataCode: e.target.value,
                });
              }}
              sx={{ width: '100%' }}
              value={addressInputValue.iataCode}
            />
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid item md={8.5}>
              <TextField
                name="address"
                size="small"
                placeholder="Address line 1"
                type="text"
                onChange={(e) => {
                  setAddressInputValue({
                    ...addressInputValue,
                    line1: e.target.value,
                  });
                }}
                sx={{ width: '100%' }}
                value={addressInputValue.line1}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                placeholder="Apt, suite, etc."
                value={addressInputValue.line2}
                onChange={(e) => {
                  setAddressInputValue({
                    ...addressInputValue,
                    line2: e.target.value,
                  });
                }}
                sx={{ width: '100%' }}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid item md={3.5}>
              <TextField
                placeholder="City"
                value={addressInputValue.city}
                onChange={(e) => {
                  setAddressInputValue({
                    ...addressInputValue,
                    city: e.target.value,
                  });
                }}
                size="small"
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item md={3.5}>
              <TextField
                value={addressInputValue.state}
                placeholder="State"
                onChange={(e) => {
                  setAddressInputValue({
                    ...addressInputValue,
                    state: e.target.value.toUpperCase(),
                  });
                }}
                inputProps={{
                  maxLength: 2,
                }}
                size="small"
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item md={3.5}>
              <TextField
                value={addressInputValue?.zip}
                placeholder="Zipcode"
                onChange={(e) =>
                  setAddressInputValue({
                    ...addressInputValue,
                    zip: e.target.value,
                  })
                }
                size="small"
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          {ContactComponent}
          <Grid item md={12}>
            <TextField
              label="Special Instructions"
              size="small"
              value={specialInstructionsInputValue}
              onChange={(e) => {
                const input = e.target.value;
                setSpecialInstructionsInputValue(input);
              }}
              multiline
              rows={3}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Internal notes"
              size="small"
              value={internalNotesInputValue}
              onChange={(e) => {
                const input = e.target.value;
                setInternalNotesInputValue(input);
              }}
              multiline
              rows={3}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item md={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={saveContactAndSpecialInstructions}
                onChange={(e) =>
                  setSaveContactAndSpecialInstructions(e.target.checked)
                }
              />
              <Typography>
                Save contact and special instructions with address
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            sx={{ mt: '10px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdateAddress()}
            variant="contained"
            sx={{ mt: '10px' }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EditAddressDialog;
