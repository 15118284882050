import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';

export type OSDFormValues = {
  isOSD: boolean;
  markedOsdAt?: Date;
  osdReason?: string;
};
const useOSDForm = () => {
  const schema = joi.object({
    isOSD: joi.boolean(),
    markedOsdAt: joi.date(),
    osdReason: joi.string().optional(),
  });
  return useForm<OSDFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useOSDForm;
