import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types';
import { Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { safeDivide } from 'shared/math';
import {
  convertNumberStringToFloat,
  isNilOrEmptyString,
} from '../../../../common/utils/utils';
import {
  FuelBillingMethod,
  FuelProfileDateRangeEntity,
  FuelProfileQuery,
  FuelProfilesDocument,
  useDeleteFuelProfileDateRangeMutation,
  useUpdateFuelProfileDateRangeMutation,
} from '../../../../generated/graphql';

interface FuelProfileDateRangeRowProps {
  fuelProfileUuid: string;
  dateRange: DateRange;
  billingType: FuelBillingMethod;
  refetchFuelProfile: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<FuelProfileQuery>>;

  setUpdateFuelProfileSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;
  setUpdateFuelProfileErrorMessageVisible: Dispatch<SetStateAction<boolean>>;

  isDeletable: boolean;

  setShowOverlappingDateRangeErrorMessage: Dispatch<SetStateAction<string>>;
}

export type DateRange = Pick<
  FuelProfileDateRangeEntity,
  'uuid' | 'startDate' | 'expirationDate' | 'flatRateUsdCents' | 'surchargeRate'
>;

const FuelProfileDateRangeRow = ({
  fuelProfileUuid,
  dateRange,
  billingType,
  refetchFuelProfile,
  setUpdateFuelProfileSuccessMessageVisible,
  setUpdateFuelProfileErrorMessageVisible,
  isDeletable,
  setShowOverlappingDateRangeErrorMessage,
}: FuelProfileDateRangeRowProps) => {
  const [flatRate, setFlatRate] = useState<string | undefined>();
  const [surchargeRate, setSurchargeRate] = useState<string | undefined>('');
  const [flatRateInput, setFlatRateInput] = useState<string | undefined>();
  const [surchargeRateInput, setSurchargeRateInput] = useState<
    string | undefined
  >('');

  useEffect(() => {
    const surchargeRateString = dateRange.surchargeRate?.toString();
    const flatRateString = !isNil(dateRange.flatRateUsdCents)
      ? currency(safeDivide(dateRange.flatRateUsdCents, 100)).toString()
      : undefined;
    setSurchargeRate(surchargeRateString);
    setFlatRate(flatRateString);
    setSurchargeRateInput(surchargeRateString);
    setFlatRateInput(flatRateString);
  }, [dateRange]);

  const [
    deleteFuelProfileDateRange,
    { loading: deleteFuelProfileDateRangeLoading },
  ] = useDeleteFuelProfileDateRangeMutation({
    refetchQueries: [FuelProfilesDocument],
  });
  const [
    updateFuelProfileDateRange,
    { loading: updateFuelProfileDateRangeLoading },
  ] = useUpdateFuelProfileDateRangeMutation({
    refetchQueries: [FuelProfilesDocument],
  });

  const handleDelete = async () => {
    try {
      const resp = await deleteFuelProfileDateRange({
        variables: {
          deleteFuelProfileDateRangeInput: {
            uuid: dateRange.uuid,
          },
        },
      });
      if (resp.data?.deleteFuelProfileDateRange.success === true) {
        setUpdateFuelProfileSuccessMessageVisible(true);
        await refetchFuelProfile({
          findFuelProfileInput: { uuid: fuelProfileUuid },
        });
      } else {
        setUpdateFuelProfileErrorMessageVisible(true);
      }
    } catch (e) {
      setUpdateFuelProfileErrorMessageVisible(true);
    }
  };

  const handleUpdate = async ({
    newStartDate,
    newExpirationDate,
    newRate,
  }: {
    newStartDate?: Date;
    newExpirationDate?: Date;
    newRate?: number | null;
  }) => {
    try {
      const resp = await updateFuelProfileDateRange({
        variables: {
          updateFuelProfileDateRangeInput: {
            uuid: dateRange.uuid,
            startDate: newStartDate,
            expirationDate: newExpirationDate,
            surchargeRate:
              billingType === FuelBillingMethod.Percentage ? newRate : null,
            flatRateUsdCents:
              billingType === FuelBillingMethod.FlatRate && !isNil(newRate)
                ? currency(newRate).intValue
                : null,
          },
        },
      });
      const response = resp.data?.updateFuelProfileDateRange;
      if (response?.success === true) {
        setShowOverlappingDateRangeErrorMessage('');
        setUpdateFuelProfileSuccessMessageVisible(true);
        await refetchFuelProfile({
          findFuelProfileInput: { uuid: fuelProfileUuid },
        });
      } else if (response?.success === false && !isEmpty(response?.error)) {
        setShowOverlappingDateRangeErrorMessage(response?.error ?? '');
      } else {
        setUpdateFuelProfileErrorMessageVisible(true);
      }
    } catch (e) {
      setUpdateFuelProfileErrorMessageVisible(true);
    }
  };

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Stack spacing={1}>
        <Typography>Start date</Typography>
        <DatePicker
          onChange={(newDate) => {
            if (!isNil(newDate)) {
              handleUpdate({ newStartDate: newDate });
            }
          }}
          renderInput={(props) => <TextField size="small" {...props} />}
          value={dateRange.startDate}
          disabled={updateFuelProfileDateRangeLoading}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography>Expiration date</Typography>
        <DatePicker
          onChange={(newDate) => {
            if (!isNil(newDate)) {
              handleUpdate({ newExpirationDate: newDate });
            }
          }}
          renderInput={(props) => <TextField size="small" {...props} />}
          value={dateRange.expirationDate}
          disabled={updateFuelProfileDateRangeLoading}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography>Rate</Typography>
        {billingType === FuelBillingMethod.FlatRate && (
          <TextField
            label="Flat rate"
            type="number"
            size="small"
            fullWidth
            value={flatRateInput}
            error={isNilOrEmptyString(flatRateInput)}
            helperText={isNilOrEmptyString(flatRateInput) ? 'Required' : ''}
            onChange={(e) => {
              setFlatRateInput(e.target.value);
            }}
            onBlur={(e) => {
              const parsedInput = convertNumberStringToFloat(e.target.value);
              if (!isNil(parsedInput)) {
                handleUpdate({ newRate: parsedInput });
                const newFlatRate = parsedInput.toFixed(2);
                setFlatRate(newFlatRate);
                setFlatRateInput(newFlatRate);
              } else {
                setFlatRateInput(flatRate);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
        {billingType === FuelBillingMethod.Percentage && (
          <TextField
            label="Surcharge rate"
            type="number"
            size="small"
            fullWidth
            value={surchargeRateInput}
            error={isNilOrEmptyString(surchargeRateInput)}
            helperText={
              isNilOrEmptyString(surchargeRateInput) ? 'Required' : ''
            }
            onChange={(e) => {
              setSurchargeRateInput(e.target.value);
            }}
            onBlur={(e) => {
              const parsedInput = convertNumberStringToFloat(e.target.value);
              if (!isNil(parsedInput)) {
                handleUpdate({ newRate: parsedInput });
                const newSurchargeRate = parsedInput.toFixed(2);
                setSurchargeRate(newSurchargeRate);
                setSurchargeRateInput(newSurchargeRate);
              } else {
                setSurchargeRateInput(surchargeRate);
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        )}
      </Stack>
      <Box
        sx={{
          marginTop: 3,
        }}
      >
        <Tooltip arrow title="Delete">
          <IconButton
            onClick={handleDelete}
            disabled={
              deleteFuelProfileDateRangeLoading === true || !isDeletable
            }
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default FuelProfileDateRangeRow;
