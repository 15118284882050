import CloseIcon from '@mui/icons-material/Close';
import {
  FormHelperText,
  IconButton,
  Link,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import useWarehouses from '../../../../../common/react-hooks/use-warehouses';
import useWindowDimensions from '../../../../../common/react-hooks/use-window-dimensions';
import {
  FindItemGroupsFragment,
  PicklistsFragment,
} from '../../../../../generated/graphql';
import { PicklistFormValues } from '../../../forms/storage-units/use-picklist-form';

const MIN_QTY_WIDTH = 100;

interface PickListPickedTableProps {
  isCustomerPortal?: boolean;
  initialPicklist?: PicklistsFragment | null;
}
const PickListPickedTable = ({
  isCustomerPortal = false,
  initialPicklist,
}: PickListPickedTableProps) => {
  const { height } = useWindowDimensions();
  const { warehouses } = useWarehouses();

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<PicklistFormValues>();
  const pickedItemGroups = watch('pickedItemGroups');

  const handleRemovePicklistItemGroup = (itemGroup: FindItemGroupsFragment) => {
    setValue(
      'pickedItemGroups',
      pickedItemGroups.filter(
        (picklistItemGroup) => picklistItemGroup.uuid !== itemGroup.uuid,
      ),
    );
    // if the itemGroup is currently saved in the picklist, add it to the cache
    if (
      !(
        initialPicklist?.picklistItemGroups?.some(
          (picklistItemGroup) =>
            picklistItemGroup.itemGroup.uuid === itemGroup.uuid,
        ) ?? true
      )
    ) {
      return;
    }
    const itemGroupsRemovedFromPicklist = getValues(
      'itemGroupsRemovedFromPicklist',
    );
    const removedItemGroup = itemGroupsRemovedFromPicklist.find(
      ({ uuid }) => uuid === itemGroup.uuid,
    );
    // if it is not in the cache, add it
    if (isNil(removedItemGroup)) {
      itemGroupsRemovedFromPicklist.push({
        uuid: itemGroup.uuid,
        quantity: itemGroup.quantity,
      });
    } else {
      // otherwise update the quantity
      removedItemGroup.quantity += itemGroup.quantity;
      setValue('itemGroupsRemovedFromPicklist', itemGroupsRemovedFromPicklist);
    }
  };

  return (
    <TableContainer
      component={Paper}
      style={{ height: height - 350, overflowY: 'scroll', marginTop: 58 }}
    >
      <Table>
        <TableHead>
          <TableCell>Item SKU</TableCell>
          <TableCell>Item description</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Customer</TableCell>
          <TableCell>Container name</TableCell>
          <TableCell sx={{ minWidth: MIN_QTY_WIDTH }}>Qty</TableCell>
          <TableCell>PO number</TableCell>
          <TableCell>Lot number</TableCell>
          {(warehouses?.length ?? 0) > 1 && <TableCell>Warehouse</TableCell>}
          <TableCell />
        </TableHead>
        {pickedItemGroups.map((pickedItemGroup, i) => (
          <TableRow key={pickedItemGroup.uuid}>
            <TableCell>{pickedItemGroup.item.sku}</TableCell>
            <TableCell>{pickedItemGroup.item.description}</TableCell>
            <TableCell>
              {pickedItemGroup.storageUnit?.warehouseLocation?.name ?? '-'}
            </TableCell>
            <TableCell>
              {pickedItemGroup.storageOrder.contact.displayName}
            </TableCell>
            <TableCell>
              {isCustomerPortal || pickedItemGroup.storageOrder == null ? (
                pickedItemGroup.storageUnit?.name ?? '-'
              ) : (
                <Link
                  target="_blank"
                  href={`/warehouse/storage-orders/${pickedItemGroup.storageOrder.uuid}`}
                >
                  {pickedItemGroup.storageUnit?.name ?? '-'}
                </Link>
              )}
            </TableCell>
            <TableCell sx={{ minWidth: MIN_QTY_WIDTH }}>
              <Controller
                name={`pickedItemGroups.${i}.quantity`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <TextField
                      size="small"
                      type="number"
                      value={value}
                      onChange={onChange}
                    />
                    {!isNil(errors.pickedItemGroups?.[i]?.quantity) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.pickedItemGroups?.[i]?.quantity?.message ?? ''}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </TableCell>
            <TableCell>
              {pickedItemGroup.storageOrder.purchaseOrderNumber ?? '-'}
            </TableCell>
            <TableCell>
              {pickedItemGroup.storageOrder.lotNumber ?? '-'}
            </TableCell>
            {(warehouses?.length ?? 0) > 1 && (
              <TableCell>
                {pickedItemGroup.storageOrder.warehouse.name ?? '-'}
              </TableCell>
            )}
            <TableCell>
              <Tooltip arrow title="Remove from picklist">
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => handleRemovePicklistItemGroup(pickedItemGroup)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell />
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};

export default PickListPickedTable;
