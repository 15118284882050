import { PermissionResource } from '../../generated/graphql';

export const GENERAL_PERMISSION_RESOURCES = [PermissionResource.Master];

export const ORDERS_PERMISSION_RESOURCES = [
  PermissionResource.Orders,
  PermissionResource.DeleteOrders,
  PermissionResource.ScannedOrders,
  PermissionResource.EdiApiOrders,
  PermissionResource.EmailOrders,
  PermissionResource.Quote,
];

export const DISPATCH_PERMISSION_RESOURCES = [
  PermissionResource.Routes,
  PermissionResource.DailyControlCenter,
  PermissionResource.LineHaulDispatch,
  PermissionResource.Appointments,
  PermissionResource.EndOfDay,
];

export const ACCOUNTING_PERMISSION_RESOURCES = [
  PermissionResource.BillingReview,
  PermissionResource.SendFinalizedToBilling,
  PermissionResource.FinalizeChargesOrders,
  PermissionResource.FinalizeChargesOrdersPaperworkIncomplete,
  PermissionResource.Invoices,
  PermissionResource.Customers,
  PermissionResource.DriverSettlements,
  PermissionResource.AccountingReports,
];

export const SAVED_VIEWS_PERMISSION_RESOURCES = [PermissionResource.SavedViews];

export const REPORTS_PERMISSION_RESOURCES = [PermissionResource.Reports];

export const CONTACTS_PERMISSION_RESOURCES = [PermissionResource.Contacts];

export const COMPANY_PERMISSION_RESOURCES = [
  PermissionResource.CompanyDrivers,
  PermissionResource.CompanyWarehouseEmployees,
  PermissionResource.CompanyEquipment,
  PermissionResource.CompanyAccessorials,
  PermissionResource.CompanyTariffs,
  PermissionResource.CompanyBilling,
  PermissionResource.CompanyScheduling,
  PermissionResource.CompanyServices,
  PermissionResource.CompanyRouteNames,
  PermissionResource.CompanyUsers,
  PermissionResource.CompanyRecoveryTerminals,
  PermissionResource.CompanyThirdPartyBrokers,
  PermissionResource.CompanyCustomForms,
  PermissionResource.CompanyFuelProfiles,
  PermissionResource.CompanyBusinessDivisions,
  PermissionResource.CompanyRecurringOrders,
  PermissionResource.CompanyTerminals,
  PermissionResource.CompanyHoldReasons,
  PermissionResource.CompanyLineHaul,
];

export const SETTINGS_PERMISSION_RESOURCES = [
  PermissionResource.SettingsAutoApplyAccessorialRules,
  PermissionResource.SettingsTags,
  PermissionResource.SettingsSamsara,
  PermissionResource.SettingsQuickbooks,
  PermissionResource.SettingsDispatch,
  PermissionResource.SettingsRoles,
  PermissionResource.SettingsOrders,
  PermissionResource.SettingsQuickbooksOnline,
  PermissionResource.SettingsQuickbooksDesktop,
  PermissionResource.SettingsGeneral,
];

export const WAREHOUSE_PERMISSION_RESOURCES = [PermissionResource.Warehouses];

export const PERMISSIONS_DEPENDENT_ON_BILLING_REVIEW_WRITE = [
  PermissionResource.FinalizeChargesOrders,
  PermissionResource.FinalizeChargesOrdersPaperworkIncomplete,
];

export const PERMISSIONS_DEPENDENT_ON_FINALIZE_CHARGES_WRITE = [
  PermissionResource.FinalizeChargesOrdersPaperworkIncomplete,
];
