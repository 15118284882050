/* eslint-disable no-param-reassign */
import { Types } from 'ably';
import { v4 } from 'uuid';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DispatchUserLocationPayload } from './types/dispatch';
import PresenceMessage = Types.PresenceMessage;

// eslint-disable-next-line @typescript-eslint/ban-types
type MultiplayerState = {
  dispatchConnectionId: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dispatchUserPresenceData: PresenceMessage<DispatchUserLocationPayload>[];
  updateDispatchUserLocation:
    | ((userLocationPayload: DispatchUserLocationPayload) => void)
    | undefined;
};

type MultiplayerActions = {
  getDispatchMyLocation: () => // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  PresenceMessage<DispatchUserLocationPayload> | undefined;
  setUpdateDispatchUserLocation: (
    func: (userLocationPayload: DispatchUserLocationPayload) => void,
  ) => void;
  setDispatchUserPresenceData: (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    presenceData: PresenceMessage<DispatchUserLocationPayload>[],
  ) => void;
};

const useMultiplayerStore = create(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  immer<MultiplayerState & MultiplayerActions>((set, get) => ({
    dispatchConnectionId: v4(),
    dispatchUserPresenceData: [],
    updateDispatchUserLocation: undefined,
    getDispatchMyLocation: () => {
      const { dispatchConnectionId, dispatchUserPresenceData } = get();
      return dispatchUserPresenceData.find(
        (userLocation) =>
          userLocation.data.connectionId === dispatchConnectionId,
      );
    },
    setUpdateDispatchUserLocation: (
      func: (userLocationPayload: DispatchUserLocationPayload) => void,
    ) =>
      set((state) => {
        state.updateDispatchUserLocation = func;
      }),
    setDispatchUserPresenceData: (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      presenceData: PresenceMessage<DispatchUserLocationPayload>[],
    ) =>
      set((state) => {
        state.dispatchUserPresenceData = presenceData;
      }),
  })),
);

export default useMultiplayerStore;
