import { Check } from '@mui/icons-material';
import {
  Checkbox,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useStyles from '../reports-styles';
import { Option } from '../types/report-filters';

const ReportFilterAutocompleteMenuListMultiselect = ({
  selectedOptions,
  options,
  handleChange,
}: {
  selectedOptions: Option[];
  options: Option[];
  handleChange: (options: Option[]) => void;
}) => {
  const styles = useStyles();
  const isAllSelected = selectedOptions.length === 0;

  return (
    <MenuList
      dense
      sx={{
        p: 0,
      }}
    >
      <MenuItem
        key="all"
        onClick={() => handleChange([])}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility: isAllSelected ? undefined : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <Typography sx={styles.menuText}>All</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        key="custom"
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility: !isAllSelected ? undefined : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <AutocompleteFuzzy
            size="small"
            multiple
            disableCloseOnSelect
            sx={{ backgroundColor: 'white', width: '200px' }}
            value={selectedOptions}
            options={options.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            matchSortOptions={{ keys: ['label'] }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                size="small"
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option.label}
              </li>
            )}
            onChange={(_, selected) => {
              handleChange(selected);
            }}
          />
        </Stack>
      </MenuItem>
    </MenuList>
  );
};

export default ReportFilterAutocompleteMenuListMultiselect;
