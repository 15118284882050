import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { exhaustive } from 'shared/switch';
import DateDropdownPicker, {
  DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import { DownloadType } from '../../../../../common/components/download-type-selection';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import {
  CustomerReportBucketFragment,
  FilterOperator,
  InvoiceStatus,
  useIncomeAnalysisReportLazyQuery,
} from '../../../../../generated/graphql';
import {
  throwIfFetchFails,
  useDownloadReport,
} from '../../../../reports/hooks/use-download-report';
import styles from '../../../styles';
import { InvoiceStatusTab } from '../../../types/types';
import { downloadIncomeAnalysisReport } from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import ReportDateFilterTypeButton, {
  ReportDateFilterType,
} from './components/report-date-filter-type-button';

interface DownloadIncomeAnalysisReportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DownloadIncomeAnalysisReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadIncomeAnalysisReportModalProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [reportDateFilterType, setReportDateFilterType] =
    useState<ReportDateFilterType>(ReportDateFilterType.INVOICE_DATE);
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();

  const { companyData } = useMe();

  const [getIncomeAnalysisReport] = useIncomeAnalysisReportLazyQuery();
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>(
    InvoiceStatusTab.ALL,
  );

  const downloadPDF = async (
    incomeAnalysisReportData: CustomerReportBucketFragment[],
  ) => {
    await downloadIncomeAnalysisReport({
      companyData,
      startDate: dateOption.startDate ?? null,
      endDate: dateOption.endDate ?? null,
      reportDateFilterType,
      status: statusTab,
      incomeAnalysisReportData,
      terminalOption,
      terminalsEnabled,
    });

    setDateOption(accountingReportDefaultDateOption);
    setTerminalOption(undefined);
    setStatusTab(InvoiceStatusTab.ALL);
  };

  const incomeAnalysisReportInput = useMemo(() => {
    let statuses = [
      InvoiceStatus.NotFinalized,
      InvoiceStatus.ReadyToInvoice,
      InvoiceStatus.Invoiced,
    ];
    if (statusTab === InvoiceStatusTab.NOT_POSTED) {
      statuses = [InvoiceStatus.NotFinalized];
    } else if (statusTab === InvoiceStatusTab.POSTED) {
      statuses = [InvoiceStatus.ReadyToInvoice, InvoiceStatus.Invoiced];
    }
    let invoiceDateFilters;
    let serviceDateFilter;
    switch (reportDateFilterType) {
      case ReportDateFilterType.SERVICE_DATE:
        serviceDateFilter = {
          gte: dateOption.startDate,
          lte: dateOption.endDate,
        };
        break;
      case ReportDateFilterType.INVOICE_DATE:
        invoiceDateFilters = {
          filterOperator: FilterOperator.And,
          startFilterValue: dateOption.startDate,
          endFilterValue: dateOption.endDate,
        };
        break;
      default:
        exhaustive(reportDateFilterType);
    }

    return {
      invoiceStatuses: statuses,
      invoiceDateFilters,
      serviceDateFilter,
      terminalUuid: terminalOption?.value ?? undefined,
      includeContactsWithNoShipments: false,
      excludeMigratedOrders: true,
    };
  }, [dateOption, reportDateFilterType, statusTab, terminalOption]);

  const startDownload = async () => {
    setIsOpen(false);
    const res = await getIncomeAnalysisReport({
      variables: {
        args: incomeAnalysisReportInput,
      },
    });
    throwIfFetchFails(res);

    await downloadPDF(res.data?.incomeAnalysisReport ?? []);
  };

  const startDownloadWithMeasurement = useDownloadReport({
    name: 'Income analysis',
    rumLabel: 'download-income-analysis-report',
    filters: incomeAnalysisReportInput,
    downloadType: DownloadType.PDF,
    downloadReport: startDownload,
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '600px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11} display="flex" flexDirection="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Income Analysis Report
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <ReportDateFilterTypeButton
              value={reportDateFilterType}
              onChange={setReportDateFilterType}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle={reportDateFilterType}
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <FormControl sx={{ width: '35%' }}>
              <InputLabel id="invoice-status-label">Invoice status</InputLabel>
              <Select
                labelId="invoice-status-label"
                onChange={(e) => {
                  setStatusTab(e.target.value as InvoiceStatusTab);
                }}
                label="Invoice status"
                value={statusTab}
                required
                size="small"
                sx={{ backgroundColor: 'white' }}
              >
                {Object.values(InvoiceStatusTab).map((status) => (
                  <MenuItem key={status} id={status} value={status}>
                    {sentenceCase(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
              <TerminalFilterButton
                prefixText="Terminal"
                selectedOption={terminalOption}
                handleChange={(option: Option | null | undefined) => {
                  setTerminalOption(option ?? undefined);
                }}
                displayCode
                includeInactiveTerminals={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadIncomeAnalysisReportModal;
