import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardContent,
  Collapse,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from '@mui/material';
import * as React from 'react';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  padding-left:15px;
  &:last-child {
    padding-bottom: 10px;
  }
`);

type EndOfDayStopCardProps = {
  onClose: () => void;
  addressString: string;
  timeString: string;
  specialInstructions?: string | null;
  driverNotes?: string | null;
};

const EndOfDayStopDetailsCard = ({
  onClose,
  addressString,
  timeString,
  specialInstructions,
  driverNotes,
}: EndOfDayStopCardProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card variant="outlined">
      <CardContentSpecialPadding>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 15 }} gutterBottom>
              Address
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 13 }}
              color="text.secondary"
              gutterBottom
            >
              {addressString}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography sx={{ fontSize: 15 }} gutterBottom>
              Appointment Time
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 13 }}
              color="text.secondary"
              gutterBottom
            >
              {timeString}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={{ float: 'right', padding: '2px' }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <ExpandMore
              sx={{ float: 'right', padding: '2px' }}
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Grid>
      </CardContentSpecialPadding>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContentSpecialPadding>
          <Typography sx={{ fontSize: 15 }} gutterBottom>
            Special Instructions
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: 13 }}
            color="text.secondary"
            gutterBottom
          >
            {specialInstructions ?? '-'}
          </Typography>
          <Typography sx={{ fontSize: 15 }} gutterBottom>
            Driver Notes
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: 13 }}
            color="text.secondary"
          >
            {driverNotes ?? '-'}
          </Typography>
        </CardContentSpecialPadding>
      </Collapse>
    </Card>
  );
};

export default EndOfDayStopDetailsCard;
