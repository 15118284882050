import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  AppointmentFragment,
  GetAppointmentsDocument,
  PermissionResource,
  useCallStopsMutation,
} from '../../../generated/graphql';
import AppointmentsModal from './appointment-modal';
import CallPreviews from './call-previews';

type CallAppointmentsButtonProps = {
  rowData: AppointmentFragment[];
  selectedUuids: string[];
};

const CallAppointmentsButtonAndModal = ({
  rowData,
  selectedUuids,
}: CallAppointmentsButtonProps) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteAppointments } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Appointments,
  );
  const [callStops, { loading }] = useCallStopsMutation({
    refetchQueries: [GetAppointmentsDocument],
  });

  const onCallAppointments = () =>
    callStops({
      variables: { callStopsInput: { uuids: selectedUuids } },
    });

  return (
    <AppointmentsModal
      type="call"
      isModalOpenButtonDisabled={selectedUuids.length === 0}
      isSendButtonDisabled={loading || !canWriteAppointments}
      count={selectedUuids.length}
      appointmentsView={
        <CallPreviews rowData={rowData} selectedUuids={selectedUuids} />
      }
      onSendMessages={onCallAppointments}
    />
  );
};

export default CallAppointmentsButtonAndModal;
