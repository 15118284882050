import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { ORDER_TABLE_FIELD_DEFAULT_VALUE } from '../../constants';

const NotesComponent = ({
  notes,
  buttonSx,
}: {
  notes: string;
  buttonSx?: IconButtonProps['sx'];
}) => {
  const formattedNotes = notes.trim();
  // eslint-disable-next-line no-nested-ternary
  return isEmpty(formattedNotes) ? null : formattedNotes ===
    ORDER_TABLE_FIELD_DEFAULT_VALUE ? (
    <Typography>{ORDER_TABLE_FIELD_DEFAULT_VALUE}</Typography>
  ) : (
    <Tooltip title={notes} placement="right-start">
      <IconButton color="info" size="small" sx={buttonSx}>
        <TextSnippetIcon />
      </IconButton>
    </Tooltip>
  );
};

export default NotesComponent;
