import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './general-styles';

const CACHE_PREFIX = 'BOOLEAN_AND_INPUT_FILTER';

interface BooleanFilterButtonProps {
  cacheId?: string;
  value: boolean | undefined;
  handleChange: (value: boolean | undefined) => void;
  filterTitle?: string;
  isNotSelectedOptionTitle?: string;
  isTrueOptionTitle?: string;
  isFalseOptionTitle?: string;
}

const BooleanFilterButton = ({
  cacheId,
  value,
  handleChange,
  filterTitle,
  isNotSelectedOptionTitle = 'Any',
  isTrueOptionTitle = 'Yes',
  isFalseOptionTitle = 'No',
}: BooleanFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedValue)) {
        handleChange(value);
      }
    }
  }, []);

  const onChange = (newValue: boolean | undefined) => {
    if (!isNil(cacheId)) {
      if (!isNil(newValue)) {
        localStorage.setItem(
          `${CACHE_PREFIX}_VALUE_${cacheId}`,
          newValue.toString(),
        );
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      }
    }
    handleChange(newValue);
  };

  const valueTitle = useMemo(() => {
    if (isNil(value)) {
      return isNotSelectedOptionTitle;
    }
    if (value) {
      return isTrueOptionTitle ?? 'Yes';
    }
    return isFalseOptionTitle ?? 'No';
  }, [isFalseOptionTitle, isNotSelectedOptionTitle, isTrueOptionTitle, value]);

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{filterTitle ?? ''}</Typography>
          <Typography sx={styles.filterValue}>{valueTitle}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="boolean-and-input-filter-menu"
        data-testid="boolean-and-input-filter-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            onClick={() => onChange(undefined)}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: isNil(value) ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>
                {!isNil(isNotSelectedOptionTitle) && isNotSelectedOptionTitle}
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="all"
            onClick={() => onChange(true)}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: value === true ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>
                {!isNil(isTrueOptionTitle) && isTrueOptionTitle}
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="all"
            onClick={() => onChange(false)}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: value === false ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>
                {!isNil(isFalseOptionTitle) && isFalseOptionTitle}
              </Typography>
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default BooleanFilterButton;
