import type { UserPermissionWithAccessLevel } from '../../../common/utils/roles';
import { AccessLevel, PermissionResource } from '../../../generated/graphql';

// Full permissions for demo.
export const demoPermissions: UserPermissionWithAccessLevel[] = Object.values(
  PermissionResource,
).flatMap((permissionResource) =>
  Object.values(AccessLevel).map((accessLevel) => ({
    permissionResource,
    accessLevel,
  })),
);
