// eslint-disable-next-line import/prefer-default-export
export enum SettlementsDownloadType {
  SEPARATE_FILES = 'Separate files',
  ONE_FILE_INCLUDE_HEADERS = 'One file',
  ONE_FILE_NO_HEADERS = 'One file (no headers)',
}

export enum SettlementBillStatusTab {
  UNFINALIZED = 0,
  FINALIZED = 1,
  ALL = 2,
}
