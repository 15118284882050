import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  RateType,
  TariffGroupType,
  TariffType,
  TariffZoneLocationType,
  TariffZoneType,
} from '../../../../generated/graphql';
import useTariffGroupActions from './hooks/use-tariff-group-actions';
import TariffOverageRateModal from './overage-modals/tariff-overage-rates-modal';
import TariffZoneOverageRateModal from './overage-modals/tariff-zone-overage-rates-modal';
import useTariffGroupStore from './store/tariff-group-state-store';

enum RadioGroupType {
  TariffZone = 'TARIFF',
  Tariff = 'UNIT',
  Rate = 'RATE',
  UseActualWeight = 'USE_ACTUAL_WEIGHT',
}

const TariffConfigSection = ({
  disableTariffTypeChange,
}: {
  disableTariffTypeChange: boolean;
}) => {
  const ffDemoSterlingTariffs = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_TARIFFS,
  );
  const [overageRateModalOpen, setOverageRateModalOpen] =
    useState<boolean>(false);

  const { refetchAndSetLocationZones } = useTariffGroupActions();
  const [
    tariffGroupType,
    useZoneGroups,
    zoneType,
    rateType,
    tariffType,
    useActualWeight,
    useGlobalOverageRates,
    yRangeValues,
  ] = useTariffGroupStore(
    (state) => [
      state.tariffGroupType,
      state.useZoneGroups,
      state.zoneType,
      state.rateType,
      state.tariffType,
      state.useActualWeight,
      state.useGlobalOverageRates,
      state.yRangeValues,
      state.rateMatrix,
    ],
    shallow,
  );
  const [
    setZoneType,
    setRateType,
    setTariffType,
    setUseActualWeight,
    setYRangeValues,
    deleteRowFromRateMatrix,
  ] = useTariffGroupStore(
    (state) => [
      state.setZoneType,
      state.setRateType,
      state.setTariffType,
      state.setUseActualWeight,
      state.setYRangeValues,
      state.deleteRowFromRateMatrix,
    ],
    shallow,
  );

  const handleChangeTariffZoneType = async (
    newTariffZoneType: TariffZoneType,
  ) => {
    if (tariffGroupType !== TariffGroupType.Ordinary || !useZoneGroups) {
      await refetchAndSetLocationZones({
        variables: {
          tariffZoneType: newTariffZoneType,
          tariffZoneLocationType:
            tariffGroupType === TariffGroupType.Transfer
              ? TariffZoneLocationType.Terminal
              : tariffGroupType === TariffGroupType.LineHaul
                ? TariffZoneLocationType.LineHaulLane
                : TariffZoneLocationType.Zipcode,
        },
      });
    }
    setZoneType(newTariffZoneType);
  };

  const handleChange = (e: SelectChangeEvent) => {
    switch (e.target.name) {
      case RadioGroupType.TariffZone: {
        handleChangeTariffZoneType(e.target.value as TariffZoneType);
        break;
      }
      case RadioGroupType.Tariff: {
        setTariffType(e.target.value as TariffType);
        if (e.target.value === TariffType.NoUnits) {
          // Delete all rows except the first one and set the lessThanOrEqualValue to undefined
          // Unfortunately, even for flat rate tariffs, we still represent them as a range under the hood
          for (let i = 1; i < yRangeValues.length; i += 1) {
            deleteRowFromRateMatrix(i);
          }
          setYRangeValues([undefined]);

          setRateType(RateType.Flat);
        }
        break;
      }
      case RadioGroupType.Rate: {
        setRateType(e.target.value as RateType);
        break;
      }
      case RadioGroupType.UseActualWeight: {
        setUseActualWeight(e.target.value === 'true');
        break;
      }
      default: {
        break;
      }
    }
  };

  const showGlobalOverageRates = overageRateModalOpen && useGlobalOverageRates;

  return (
    <Stack spacing={2}>
      <FormControl disabled={disableTariffTypeChange}>
        <FormLabel id="primary-tariff-type-radio-buttons-group-label">
          Tariff Type
        </FormLabel>
        <RadioGroup
          onChange={handleChange}
          aria-labelledby="primary-tariff-type-radio-buttons-group-label"
          value={zoneType}
          name={RadioGroupType.TariffZone}
        >
          <FormControlLabel
            value={TariffZoneType.Universal}
            control={<Radio size="small" />}
            label="Universal"
          />
          <FormControlLabel
            value={TariffZoneType.Location}
            control={<Radio size="small" />}
            label="Zone Based"
          />
          <FormControlLabel
            value={TariffZoneType.Miles}
            control={<Radio size="small" />}
            label="Mile Range"
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="secondary-tariff-type-radio-buttons-group-label">
          Unit Type
        </FormLabel>
        <RadioGroup
          onChange={handleChange}
          aria-labelledby="secondary-tariff-type-radio-buttons-group-label"
          value={tariffType}
          name={RadioGroupType.Tariff}
        >
          <FormControlLabel
            value={TariffType.PerHundredPounds}
            control={<Radio size="small" />}
            label="Per Hundred Pounds"
          />
          <FormControlLabel
            value={TariffType.PerPiece}
            control={<Radio size="small" />}
            label="Per Piece"
          />
          {(zoneType === TariffZoneType.Miles || ffDemoSterlingTariffs) && (
            <FormControlLabel
              value={TariffType.PerCubicFoot}
              control={<Radio size="small" />}
              label="Per Cubic Foot"
            />
          )}
          {ffDemoSterlingTariffs && (
            <FormControlLabel
              value={TariffType.PerCubicFoot}
              control={<Radio size="small" />}
              label="Freight Class"
            />
          )}
          <FormControlLabel
            value={TariffType.NoUnits}
            control={<Radio size="small" />}
            label="No Units (Flat Rate)"
          />
        </RadioGroup>
      </FormControl>
      {tariffType !== TariffType.NoUnits && (
        <FormControl>
          <FormLabel id="rate-type-radio-buttons-group-label">
            Rate Type
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            aria-labelledby="rate-type-radio-buttons-group-label"
            value={rateType}
            name={RadioGroupType.Rate}
          >
            <FormControlLabel
              value={RateType.QuantityBased}
              control={<Radio size="small" />}
              label="Multiplier"
            />
            <FormControlLabel
              value={RateType.Flat}
              control={<Radio size="small" />}
              label="Flat Rate"
            />
          </RadioGroup>
        </FormControl>
      )}
      <FormControl>
        <FormLabel id="secondary-tariff-type-radio-buttons-group-label">
          Always use actual weight to calculate rate
        </FormLabel>
        <RadioGroup
          onChange={handleChange}
          aria-labelledby="secondary-tariff-type-radio-buttons-group-label"
          value={useActualWeight}
          name={RadioGroupType.UseActualWeight}
        >
          <FormControlLabel
            value
            control={<Radio size="small" />}
            label="Yes"
          />
          <FormControlLabel
            value={false}
            control={<Radio size="small" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
      <Button variant="contained" onClick={() => setOverageRateModalOpen(true)}>
        Edit Overage Rates
      </Button>
      {showGlobalOverageRates ? (
        <TariffOverageRateModal
          overageRateModalOpen={overageRateModalOpen}
          setOverageRateModalOpen={setOverageRateModalOpen}
        />
      ) : (
        <TariffZoneOverageRateModal
          overageRateModalOpen={overageRateModalOpen}
          setOverageRateModalOpen={setOverageRateModalOpen}
        />
      )}
    </Stack>
  );
};

export default TariffConfigSection;
