import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  SettlementDeductionTypesDocument,
  useCreateSettlementDeductionTypeMutation,
  useRemoveSettlementDeductionTypeMutation,
  useSettlementDeductionTypesQuery,
} from '../../../../generated/graphql';
import SettlementDeductionTypeRow from './settlement-deduction-type-row';

const SettlementDeductionTypes = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const { data: settlementDeductionTypesData } =
    useSettlementDeductionTypesQuery();
  const [createSettlementDeductionType] =
    useCreateSettlementDeductionTypeMutation({
      refetchQueries: [SettlementDeductionTypesDocument],
    });
  const [deleteSettlementDeductionType] =
    useRemoveSettlementDeductionTypeMutation({
      refetchQueries: [SettlementDeductionTypesDocument],
    });

  const addSettlementDeductionType = async () => {
    await createSettlementDeductionType({
      variables: {
        settlementDeductionTypeCreateInput: {
          name: 'New Settlement Deduction Type',
        },
      },
    });
  };

  return (
    <Stack direction="column" alignItems="flex-end">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Name</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell align="right" sx={{ width: '300px' }}>
                <Button
                  onClick={addSettlementDeductionType}
                  sx={{ marginBottom: '16px' }}
                  variant="contained"
                  disabled={!canWriteCompanyBilling}
                >
                  Add Settlement Deduction Type
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settlementDeductionTypesData?.settlementDeductionTypes.map(
              (settlementDeductionType) => (
                <SettlementDeductionTypeRow
                  key={settlementDeductionType.uuid}
                  settlementDeductionType={settlementDeductionType}
                  onDelete={async () => {
                    await deleteSettlementDeductionType({
                      variables: {
                        uuid: settlementDeductionType.uuid,
                      },
                    });
                  }}
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default SettlementDeductionTypes;
