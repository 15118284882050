import { Box, Button, Card, Stack, styled } from '@mui/material';
import { isNil } from 'lodash';
import { ChangeEvent } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactLogosDocument,
  DocumentType,
  PermissionResource,
  useContactLogosQuery,
  useCreateContactDocumentMutation,
  useGenerateContactPreSignedPutUrlMutation,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';

type ContactImageType = 'logo' | 'logoType';

const ImagePreview = styled('img')`
  max-height: 200px;
  height: auto;
  width: auto;
  object-fit: contain;
`;
interface WarehousePanelProps {
  contactUuid: string;
}
const WarehousePanel = ({ contactUuid }: WarehousePanelProps) => {
  const { userPermissions } = useUserRoles();

  const { canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Warehouses,
  );

  const { data: contactLogosData } = useContactLogosQuery({
    variables: { contactUuid },
  });

  const [generateContactPreSignedPutUrl] =
    useGenerateContactPreSignedPutUrlMutation();
  const [createContactDocument] = useCreateContactDocumentMutation();
  const [updateContact] = useUpdateCustomerContactMutation({
    refetchQueries: [ContactLogosDocument],
  });

  const handleUpload = async (
    imageType: ContactImageType,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = e.target.files?.[0];
    if (isNil(file)) return;
    const fileName = file.name;
    const fileType = file.type;
    const presignedUrlData = await generateContactPreSignedPutUrl({
      variables: {
        generateContactPreSignedPutUrlInput: {
          contactUuid,
          fileName,
          fileType,
        },
      },
    });
    const presignedUrl =
      presignedUrlData.data?.generateContactPreSignedPutUrl.url;
    if (isNil(presignedUrl)) return;
    const awsRes = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    });
    if (!awsRes.ok) return;
    const { data: createContactDocumentData } = await createContactDocument({
      variables: {
        createContactDocumentInput: {
          contactUuid,
          documentType: DocumentType.Other,
          fileName,
          fileType,
          name: imageType,
        },
      },
    });
    const documentUuid = createContactDocumentData?.createContactDocument.uuid;
    if (isNil(documentUuid)) return;
    await updateContact({
      variables: {
        input: {
          customerContactUpdateInput: {
            uuid: contactUuid,
            logoDocumentUuid: imageType === 'logo' ? documentUuid : null,
            logoTypeDocumentUuid:
              imageType === 'logoType' ? documentUuid : null,
          },
        },
      },
    });
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: 2,
          py: 2,
          width: '50%',
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          Packing slip logotype:
          <Button
            component="label"
            variant="contained"
            size="small"
            disabled={!canWrite}
          >
            Upload file
            <input
              disabled={!canWrite}
              type="file"
              accept=".jpg, .jpeg, .png"
              hidden
              onChange={(e) => handleUpload('logoType', e)}
              multiple
            />
          </Button>
        </Stack>
        <ImagePreview
          src={contactLogosData?.contact.logoType?.preSignedGetUrl}
        />
        <Stack direction="row" alignItems="center" gap={2}>
          Packing slip logo:
          <Button
            component="label"
            variant="contained"
            size="small"
            disabled={!canWrite}
          >
            Upload file
            <input
              disabled={!canWrite}
              type="file"
              accept=".jpg, .jpeg, .png"
              hidden
              onChange={(e) => handleUpload('logo', e)}
              multiple
            />
          </Button>
        </Stack>
        <ImagePreview src={contactLogosData?.contact.logo?.preSignedGetUrl} />
      </Card>
    </Box>
  );
};

export default WarehousePanel;
