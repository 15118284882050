import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { SyntheticEvent, useState } from 'react';
import { ConfirmationButton } from '../../../../common/components/buttons/confirmation-button';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { isMutationErrorOutput } from '../../../../common/utils/utils';
import {
  DispatchViewsDocument,
  UpdateDispatchViewMutationVariables,
  useUpdateDispatchViewMutation,
  useRemoveDispatchViewMutation,
} from '../../../../generated/graphql';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import useDispatchStore from '../../dispatch-store';
import { RouteShowCompleteStopsCheckbox } from '../../routes/components/route-show-complete-stops-checkbox';
import { AppointmentTimeFormatDisplayField } from '../../settings/appointment-time-format-display-field';
import DispatchColorSettings from '../../settings/dispatch-color-settings';
import { RouteCardWidthSlider } from '../../settings/route-card-width-slider';
import { StopsToDisplaySlider } from '../../settings/stops-to-display-slider';
import { DispatchSettingsTab } from '../../types/routes';
import { DispatchView } from '../use-dispatch-views';
import { RouteStopColumnsEditor } from './route-stop-columns-editor';

const EditDispatchViewModal = ({
  open,
  dispatchView,
  refetchDispatchViews,
  onClose,
}: {
  open: boolean;
  dispatchView: DispatchView;
  refetchDispatchViews: () => void;
  onClose: () => void;
}) => {
  const ffUseNewDispatchPage = useFeatureFlag(FeatureFlag.FF_NEW_DISPATCH_PAGE);
  const [currentTab, setCurrentTab] = useState<DispatchSettingsTab>(
    DispatchSettingsTab.ROUTES,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [removeDispatchView, { loading: loadingDelete }] =
    useRemoveDispatchViewMutation({
      refetchQueries: [DispatchViewsDocument],
      onError: ({ message }) => setErrorMessage(message),
    });
  const [updateDispatchView] = useUpdateDispatchViewMutation({
    refetchQueries: [DispatchViewsDocument],
    onError: ({ message }) => setErrorMessage(message),
  });
  const [editingName, setEditingName] = useState<boolean>(false);
  const [name, setName] = useState<string>(dispatchView.name);
  const [useMinimizedAppointmentTime, setUseMinimizedAppointmentTime] =
    useState<boolean>(dispatchView.useMinimizedAppointmentTime);
  const [numberOfStops, setNumberOfStops] = useState(
    dispatchView.numberOfStops,
  );
  const [routeCardWidth, setRouteCardWidth] = useState(
    dispatchView.routeCardWidth,
  );
  const [showCompleteStops, setShowCompleteStops] = useState(
    dispatchView.showCompleteStops,
  );
  const setSelectedViewUuid = useDispatchStore(
    (state) => state.setSelectedViewUuid,
  );

  const onUpdateSettings = async (
    input: Omit<UpdateDispatchViewMutationVariables['input'], 'id'>,
  ) => {
    if (input.name === '') {
      setErrorMessage('Please enter a name');
      return;
    }
    const res = await updateDispatchView({
      variables: {
        input: {
          id: dispatchView.id,
          name: input.name,
          routeCardStopTableFields: input.routeCardStopTableFields,
          useMinimizedAppointmentTime: input.useMinimizedAppointmentTime,
          numberOfStops: input.numberOfStops,
          routeCardWidth: input.routeCardWidth,
          showCompleteStops: input.showCompleteStops,
        },
      },
    });

    if (!isNil(res.errors?.[0])) {
      setErrorMessage(res.errors?.[0]?.message);
    } else if (isMutationErrorOutput(res.data?.updateDispatchView)) {
      setErrorMessage(res.data?.updateDispatchView.message);
    }
  };

  const onSaveName = () => {
    setEditingName(false);
    onUpdateSettings({
      name,
    });
  };

  return (
    <PalletModal
      open={open}
      onClose={onClose}
      title={
        editingName ? (
          <TextField
            variant="standard"
            size="small"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required
            onBlur={onSaveName}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSaveName();
              }
            }}
          />
        ) : (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="h6">{name}</Typography>
            <IconButton
              onClick={() => {
                setEditingName(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Stack>
        )
      }
      pinnedElements={{
        bottomLeft: [
          <ConfirmationButton
            key="delete"
            variant="contained"
            color="error"
            confirmationDescription="Are you sure you want to delete this view?"
            onConfirm={async () => {
              await removeDispatchView({
                variables: {
                  id: dispatchView.id,
                },
              });
              setSelectedViewUuid(undefined);
              onClose();
            }}
            disabled={loadingDelete}
          >
            Delete
          </ConfirmationButton>,
        ],
      }}
    >
      <Stack spacing={1} width="800px" sx={{ minHeight: '400px' }}>
        {!isNil(errorMessage) && (
          <Alert severity="error" onClose={() => setErrorMessage(undefined)}>
            {errorMessage}
          </Alert>
        )}
        <Tabs
          sx={{ mt: -1 }}
          orientation="horizontal"
          variant="scrollable"
          value={currentTab}
          onChange={(event: SyntheticEvent, newTab: DispatchSettingsTab) => {
            setCurrentTab(newTab);
          }}
        >
          {Object.values(DispatchSettingsTab).map((tab) => {
            if (!ffUseNewDispatchPage && tab === DispatchSettingsTab.ROUTES)
              return null;
            return <Tab label={tab} value={tab} key={tab} />;
          })}
        </Tabs>
        {ffUseNewDispatchPage && (
          <TabPanel
            panelValue={DispatchSettingsTab.ROUTES}
            selectedValue={currentTab}
          >
            <RouteStopColumnsEditor
              routeCardStopTableFields={dispatchView.routeCardStopTableFields}
              onChange={async (newFields) => {
                await onUpdateSettings({
                  routeCardStopTableFields: newFields,
                });
              }}
            />
          </TabPanel>
        )}
        <TabPanel
          panelValue={DispatchSettingsTab.DISPLAY}
          selectedValue={currentTab}
        >
          <Stack spacing={1}>
            <AppointmentTimeFormatDisplayField
              useMinimizedAppointmentTime={useMinimizedAppointmentTime}
              onChange={(value) => {
                setUseMinimizedAppointmentTime(value);
                onUpdateSettings({
                  useMinimizedAppointmentTime: value,
                });
              }}
            />
            <StopsToDisplaySlider
              numberOfStops={numberOfStops}
              onChange={(value) => {
                setNumberOfStops(value);
              }}
              onBlur={() => {
                onUpdateSettings({
                  numberOfStops,
                });
              }}
            />
            <RouteCardWidthSlider
              routeCardWidth={routeCardWidth}
              onChange={(value) => {
                setRouteCardWidth(value);
              }}
              onBlur={() => {
                onUpdateSettings({
                  routeCardWidth,
                });
              }}
            />
            <RouteShowCompleteStopsCheckbox
              showCompleteStops={showCompleteStops}
              onChange={(value) => {
                setShowCompleteStops(value);
                onUpdateSettings({
                  showCompleteStops: value,
                });
              }}
            />
          </Stack>
        </TabPanel>
        {currentTab === DispatchSettingsTab.COLORS && (
          <TabPanel
            panelValue={DispatchSettingsTab.COLORS}
            selectedValue={currentTab}
          >
            <DispatchColorSettings
              initialColors={dispatchView.dispatchTableColors}
              loading={false}
              onSaved={async () => {
                await refetchDispatchViews();
              }}
              dispatchViewId={dispatchView.id}
            />
          </TabPanel>
        )}
      </Stack>
    </PalletModal>
  );
};

export { EditDispatchViewModal };
