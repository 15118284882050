import { isEmpty, isNil } from 'lodash';

export const grabFileType = (fileName: string): string | undefined => {
  if (isEmpty(fileName)) {
    return undefined;
  }
  const splitArr = fileName.split('.');
  if (splitArr.length > 1) {
    const type = splitArr[splitArr.length - 1];
    return !isNil(type) && !isEmpty(type) ? type : undefined;
  }
  return undefined;
};

export async function downloadBlob(blob: Blob, fileName: string) {
  try {
    const fileURL = window.URL.createObjectURL(blob);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = fileName;
    document.body.appendChild(alink);
    alink.click();
    document.body.removeChild(alink);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching or downloading the file:', error);
  }
}

export async function downloadFile({ url }: { url: string }) {
  // Fetch the CSV file from the public URL
  const response = await fetch(url);

  // Check if the fetch was successful
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  // Get the CSV data as text
  const csvData = await response.text();

  // Create a Blob from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  downloadBlob(blob, 'order-report.csv');
}
