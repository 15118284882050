import { AgGridReactProps } from 'ag-grid-react';
import { FilterViewPage } from '../../../generated/graphql';
import invokeAll from './invoke-all';
import useSaveColumnWidth from './use-save-column-widths';

const useOnGridReady = (
  onGridReady: AgGridReactProps['onGridReady'],
  pageType: FilterViewPage,
) => {
  const { onGridReadyWidthSave } = useSaveColumnWidth({ pageType });
  return invokeAll(onGridReadyWidthSave, onGridReady);
};

export default useOnGridReady;
