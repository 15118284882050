import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { exhaustive } from 'shared/switch';
import CSVDownloadButton from '../../../../../common/components/buttons/csv-download-button';
import DateDropdownPicker, {
  DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import useMe from '../../../../../common/react-hooks/use-me';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import {
  AccountsReceivableCustomerFilter,
  FilterOperator,
  useAccountsReceivableReportLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import {
  convertAccountsReceivableDataToCSV,
  downloadAccountsReceivableReport,
} from '../../../utils';
import { BalanceCalculationType } from '../../accounting-reports/constants';
import { formatDateOption } from './utils';

interface DownloadAccountsReceivableReportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const accountsReceivableCustomerFilterLabel = (
  filter: AccountsReceivableCustomerFilter,
) => {
  switch (filter) {
    case AccountsReceivableCustomerFilter.All:
      return 'Include all customers';
    case AccountsReceivableCustomerFilter.NonzeroBalance:
      return 'Include customers with open balance';
    default:
      return exhaustive(filter);
  }
};

const DownloadAccountsReceivableReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadAccountsReceivableReportModalProps) => {
  const { companyData } = useMe();
  const [dateOption, setDateOption] = useState<DateOption>(initialDateOption);
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const [balanceCalculationType, setBalanceCalculationType] =
    useState<BalanceCalculationType>(BalanceCalculationType.Current);
  const [customerFilter, setCustomerFilter] =
    useState<AccountsReceivableCustomerFilter>(
      AccountsReceivableCustomerFilter.NonzeroBalance,
    );

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const [getAccountsReceivableReport] = useAccountsReceivableReportLazyQuery();

  useEffect(() => {
    if (
      dateOption.filterType === DatePickerFilterType.AllSelect &&
      balanceCalculationType !== BalanceCalculationType.Current
    ) {
      setBalanceCalculationType(BalanceCalculationType.Current);
    }
  }, [dateOption, balanceCalculationType]);

  const accountsReceivableReportInput = useMemo(
    () => ({
      dateFilter: {
        filterOperator: FilterOperator.And,
        startFilterValue: dateOption.startDate,
        endFilterValue: dateOption.endDate,
      },
      calculateBalanceAsOfEndDate:
        balanceCalculationType === BalanceCalculationType.AsOfEndDate,
      customerFilter,
    }),
    [dateOption, balanceCalculationType, customerFilter],
  );

  const getReportData = async () =>
    getAccountsReceivableReport({
      variables: {
        args: accountsReceivableReportInput,
      },
    });

  const resetFilters = () => {
    setBalanceCalculationType(BalanceCalculationType.Current);
    setDateOption(initialDateOption);
  };

  const downloadPDF = async () => {
    const completeDownload = createFileDownload();
    setIsOpen(false);

    const res = await getReportData();

    const reportData = res.data?.accountsReceivableReport;
    if (isNil(reportData)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'Error downloading report',
      });
      resetFilters();
      return;
    }

    await downloadAccountsReceivableReport({
      companyData,
      data: reportData,
      dateOption,
      balanceCalculationType,
    });
    completeDownload();
    resetFilters();
  };

  const downloadCSV = async () => {
    const completeDownload = createFileDownload();
    const res = await getReportData();

    const reportData = res.data?.accountsReceivableReport;
    if (isNil(reportData)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'Error downloading report',
      });
      return null;
    }
    const csvData = convertAccountsReceivableDataToCSV(reportData);
    completeDownload();
    return csvData;
  };

  const downloadPDFWithMeasurement = useMeasureExecutionTime({
    fn: downloadPDF,
    rumLabel: 'download-accounts-receivable-report',
    logData: {
      ...accountsReceivableReportInput,
      downloadType,
    },
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '500px' }]}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Accounts Receivable Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <DateDropdownPicker
              filterTitle="Date Range"
              dateOption={dateOption}
              setDateOption={setDateOption}
              showFilters={[
                DatePickerFilterType.AllSelect,
                DatePickerFilterType.Range,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              value={balanceCalculationType}
              onChange={(e) => {
                setBalanceCalculationType(
                  e.target.value as BalanceCalculationType,
                );
              }}
            >
              {Object.values(BalanceCalculationType).map((balanceType) => (
                <FormControlLabel
                  key={balanceType}
                  value={balanceType}
                  control={<Radio />}
                  label={balanceType}
                  disabled={
                    balanceType === BalanceCalculationType.AsOfEndDate &&
                    dateOption.filterType === DatePickerFilterType.AllSelect
                  }
                />
              ))}
            </RadioGroup>
          </Grid>{' '}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              value={customerFilter}
              onChange={(e) => {
                setCustomerFilter(
                  e.target.value as AccountsReceivableCustomerFilter,
                );
              }}
            >
              {Object.values([
                AccountsReceivableCustomerFilter.NonzeroBalance,
                AccountsReceivableCustomerFilter.All,
              ]).map((filter) => (
                <FormControlLabel
                  key={filter}
                  value={filter}
                  control={<Radio />}
                  label={accountsReceivableCustomerFilterLabel(filter)}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="ACCOUNTS_RECEIVABLE_REPORT_FILE_TYPE"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              {downloadType === DownloadType.PDF ? (
                <Button
                  sx={{ width: 100 }}
                  variant="contained"
                  color="info"
                  onClick={downloadPDFWithMeasurement}
                >
                  Download
                </Button>
              ) : (
                <CSVDownloadButton
                  getData={downloadCSV}
                  onDownloadComplete={() => {
                    resetFilters();
                  }}
                  buttonProps={{
                    sx: { width: 100 },
                    variant: 'contained',
                    color: 'info',
                    disabled: false,
                  }}
                  filename={`accounts-receivable-report-${formatDateOption(dateOption)}.csv`}
                  label="Download"
                  reportType="accounts-receivable"
                  dataForTimingLog={accountsReceivableReportInput}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadAccountsReceivableReportModal;
