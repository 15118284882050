import {
  Box,
  Button,
  Chip,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase, sentenceCase } from 'change-case';
import { isNil, noop, truncate } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { safeAdd } from 'shared/math';
import { calculateDimensionalWeight } from 'shared/weight';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useServices from '../../../../common/react-hooks/use-services';
import { formatDateWithTime } from '../../../../common/utils/prettyPrintUtils';
import {
  FulfillmentType,
  OutstandingOrderFragmentFragment,
  StandardOrderFragmentFragment,
  useShallowContactLazyQuery,
} from '../../../../generated/graphql';
import HawbComponent from '../../../orders/components/order-form/components/hawb-component';
import MawbComponent from '../../../orders/components/order-form/components/mawb-component';
import ReferenceNumbers from '../../../orders/components/order-form/components/reference-numbers';
import ServiceAutocomplete from '../../../orders/components/order-form/components/service/components/service-autocomplete';
import {
  OrderFormValues,
  PackageValues,
} from '../../../orders/components/order-form/forms/types';
import useSaveOrderBrm from '../../../orders/components/order-form/forms/use-save-order-brm';
import { SelectFulfillmentType } from './select-fulfillment-type';

const ContactStation = ({
  currentOrder,
}: {
  currentOrder:
    | StandardOrderFragmentFragment
    | OutstandingOrderFragmentFragment
    | null;
}) => {
  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);

  if (!ffUseStations) return null;

  return (
    <>
      <Grid item xs={3}>
        <Typography fontWeight="bold">Station</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          {truncate(
            capitalCase(currentOrder?.billingPartyContactStation?.name ?? ''),
            { length: 37 },
          )}
        </Typography>
      </Grid>
    </>
  );
};

const ConsolNumberComponent = ({
  currentOrder,
}: {
  currentOrder:
    | StandardOrderFragmentFragment
    | OutstandingOrderFragmentFragment
    | null;
}) => {
  const [text, setText] = useState('CONSOL');
  const [isEditable, setIsEditable] = useState(false);
  const { setValue } = useFormContext();
  const { saveOrder: saveCurrentOrder } = useSaveOrderBrm();

  useEffect(() => {
    const shipperBillOfLadingNumber =
      currentOrder?.standardOrderFields.shipperBillOfLadingNumber;
    if (!isNil(shipperBillOfLadingNumber)) {
      setText(shipperBillOfLadingNumber);
    }
  }, [currentOrder?.standardOrderFields.shipperBillOfLadingNumber]);

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleBlur = () => {
    setValue('shipperBillOfLadingNumber', text);
    if (!isNil(currentOrder)) {
      saveCurrentOrder({});
    }
    setIsEditable(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedText = e.target.value;
    setText(updatedText);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          sx={{ textAlign: 'right' }}
          size="small"
          onChange={handleChange}
          onBlur={handleBlur}
          value={text}
          disabled={!isEditable}
        />
        {!isEditable && (
          <Button
            variant="contained"
            onClick={handleEditClick}
            sx={{
              marginLeft: '15px',
            }}
          >
            Edit
          </Button>
        )}
      </Box>
    </Box>
  );
};

const OutstandingOrderDetails = ({
  isEditingOrderDetails,
  currentOrder,
  isConsolidated,
  incompleteBanner,
  ordersProratedWithBanner,
}: {
  isEditingOrderDetails: boolean;
  currentOrder:
    | StandardOrderFragmentFragment
    | OutstandingOrderFragmentFragment
    | null;
  isConsolidated: boolean;
  incompleteBanner: ReactNode;
  ordersProratedWithBanner: ReactNode;
}) => {
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );
  const { getServiceName } = useServices();
  const { companyConfiguration } = useMe();
  const { watch } = useFormContext<OrderFormValues>();
  const [getContact, { data: contactData }] = useShallowContactLazyQuery();
  const packages: PackageValues[] = watch('packages') ?? [];
  const masterAirwayBillOfLadingNumber: string | null | undefined = watch(
    'masterAirwayBillOfLadingNumber',
  );
  const shipperBillOfLadingNumber: string | null | undefined = watch(
    'shipperBillOfLadingNumber',
  );
  const serviceUuid: string | null | undefined = watch('serviceUuid');
  const totalSkids: number | null | undefined = watch('totalSkids');
  const refNumbers: string[] | null | undefined = watch('refNumbers');
  const fulfillmentType = watch('fulfillmentType');

  const showFulfillmentDropdown =
    companyConfiguration?.fulfillmentTypeEnabled === true;

  const regularWeight = packages.reduce((acc, pkg) => {
    return safeAdd(acc, pkg?.weight ?? 0);
  }, 0);
  const dimWeight = packages.reduce((acc, pkg) => {
    return safeAdd(
      acc,
      calculateDimensionalWeight({
        ...pkg,
        dimFactor: currentOrder?.standardOrderFields?.dimFactor ?? 0,
        length: pkg.length ?? null,
        width: pkg.width ?? null,
        height: pkg.height ?? null,
      }),
    );
  }, 0);

  const contactUuid = currentOrder?.billingPartyContact.uuid;
  useEffect(() => {
    if (!isNil(contactUuid) && isEditingOrderDetails) {
      getContact({
        variables: {
          uuid: contactUuid,
        },
      });
    }
  }, [contactUuid, getContact, isEditingOrderDetails]);

  const hawbFieldName = ffDemoLoadManagement ? 'Auth' : 'HAWB';

  return !isConsolidated ? (
    <Grid container spacing={1}>
      {incompleteBanner !== null && (
        <Grid item xs={12}>
          {incompleteBanner}
        </Grid>
      )}
      {ordersProratedWithBanner !== null && (
        <Grid item xs={12}>
          {ordersProratedWithBanner}
        </Grid>
      )}
      <Grid item xs={3}>
        <Typography fontWeight="bold">Contact</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          {truncate(
            capitalCase(currentOrder?.billingPartyContact.displayName ?? ''),
            { length: 37 },
          )}
        </Typography>
      </Grid>
      <ContactStation currentOrder={currentOrder} />
      <Grid item xs={2}>
        <Typography fontWeight="bold">Name</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{currentOrder?.name}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight="bold">Service</Typography>
      </Grid>
      <Grid item xs={4}>
        {isEditingOrderDetails ? (
          <ServiceAutocomplete
            contact={contactData?.contact}
            deriveLaneFromTerminals={noop}
            isEditMode
            isMinimized
          />
        ) : (
          <Typography>{sentenceCase(getServiceName(serviceUuid))}</Typography>
        )}
      </Grid>
      {showFulfillmentDropdown && (
        <>
          <Grid item xs={4}>
            <Typography fontWeight="bold">Fulfillment type</Typography>
          </Grid>
          <Grid item xs={8}>
            {isEditingOrderDetails ? (
              <SelectFulfillmentType />
            ) : (
              <Typography>
                {fulfillmentType === FulfillmentType.MultiTrip
                  ? 'Separate routes'
                  : 'Same route'}
              </Typography>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <Typography fontWeight="bold">{hawbFieldName}</Typography>
      </Grid>
      <Grid item xs={4}>
        {isEditingOrderDetails ? (
          <HawbComponent excludeFormLabel editingDisabled={false} isMinimized />
        ) : (
          <Typography>{shipperBillOfLadingNumber}</Typography>
        )}
      </Grid>
      {ffDemoLoadManagement ? (
        <Grid item xs={6} />
      ) : (
        <>
          <Grid item xs={2}>
            <Typography fontWeight="bold">MAWB</Typography>
          </Grid>
          <Grid item xs={4}>
            {isEditingOrderDetails ? (
              <MawbComponent
                excludeFormLabel
                editingDisabled={false}
                isMinimized
              />
            ) : (
              <Typography>{masterAirwayBillOfLadingNumber}</Typography>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <Typography fontWeight="bold">Ref Nums</Typography>
      </Grid>
      <Grid item xs={9}>
        {isEditingOrderDetails ? (
          <Stack
            direction="row"
            columnGap="10px"
            rowGap={1}
            flexWrap="wrap"
            alignItems="end"
          >
            <ReferenceNumbers excludeFormLabel isMinimized />
          </Stack>
        ) : (
          <Typography>
            {!isNil(refNumbers) ? refNumbers.join(', ') : ''}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight="bold">Weight</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>{regularWeight?.toFixed() ?? '0'} lbs</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight="bold">Dim weight</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>{dimWeight ?? '0'} lbs</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight="bold">Total skids</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>{totalSkids ?? '0'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Chip
          label={
            currentOrder?.paperwork.paperworkComplete === true
              ? 'Paperwork complete'
              : 'Paperwork not complete'
          }
        />
      </Grid>
      {!isNil(currentOrder?.dateMarkedOnHand) && (
        <>
          <Grid item xs={4}>
            <Typography fontWeight="bold">Marked on hand</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {formatDateWithTime(currentOrder?.dateMarkedOnHand)}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Typography fontWeight="bold">{hawbFieldName}</Typography>
      </Grid>
      <Grid item xs={10}>
        <ConsolNumberComponent currentOrder={currentOrder} />
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight="bold">Contact</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          {truncate(
            capitalCase(currentOrder?.billingPartyContact.displayName ?? ''),
            { length: 25 },
          )}
        </Typography>
      </Grid>
      <ContactStation currentOrder={currentOrder} />
      <Grid item xs={2}>
        <Typography fontWeight="bold">Name</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{currentOrder?.name}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography fontWeight="bold">Service</Typography>
      </Grid>
      <Grid item xs={3}>
        {isEditingOrderDetails ? (
          <ServiceAutocomplete
            contact={contactData?.contact}
            deriveLaneFromTerminals={noop}
            isEditMode
          />
        ) : (
          <Typography>{sentenceCase(getServiceName(serviceUuid))}</Typography>
        )}
      </Grid>
      {companyConfiguration?.fulfillmentTypeEnabled === true && (
        <>
          <Grid item xs={3}>
            <Typography fontWeight="bold">Fulfillment type</Typography>
          </Grid>
          <Grid item xs={9}>
            {isEditingOrderDetails ? (
              <SelectFulfillmentType />
            ) : (
              <Typography>
                {isNil(fulfillmentType) ? null : sentenceCase(fulfillmentType)}
              </Typography>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <Typography fontWeight="bold">Weight</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{regularWeight?.toFixed() ?? '0'}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography fontWeight="bold">Dim weight</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{dimWeight ?? '0'}</Typography>
      </Grid>
    </Grid>
  );
};

export default OutstandingOrderDetails;
