import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack, SxProps, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  LineHaulLanesDocument,
  useLineHaulLaneQuery,
  useUpdateLineHaulLaneMutation,
} from '../../../../generated/graphql';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from '../common/archive-action-component';
import LineHaulEditor from './line-haul-editor';

const styles = {
  container: {
    bgcolor: 'background.paper',
    // boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '40px',
    p: 4,
  } as SxProps,
};

type ViewLineHaulLaneProps = {
  uuid: string;
};

const ViewLineHaulLane: FunctionComponent<ViewLineHaulLaneProps> = ({
  uuid,
}) => {
  const navigate = useNavigate();
  const { terminals } = useTerminals({
    includeInactiveTerminals: false,
  });

  const { data: lane } = useLineHaulLaneQuery({
    variables: {
      uuid,
    },
  });

  const [errorMessage, setErrorMessage] = useState('');

  const [originTerminalUuid, setOriginTerminalUuid] = useState<
    string | undefined
  >(undefined);
  const [destinationTerminalUuid, setDestinationTerminalUuid] = useState<
    string | undefined
  >(undefined);

  const [intermediateTerminalUuids, setIntermediateTerminalUuids] = useState<
    string[] | undefined
  >(undefined);

  const [updateLineHaulLane] = useUpdateLineHaulLaneMutation({
    refetchQueries: [LineHaulLanesDocument],
  });

  useEffect(() => {
    if (!isNil(lane)) {
      setOriginTerminalUuid(lane.lineHaulLane.originTerminal.uuid);
      setDestinationTerminalUuid(lane.lineHaulLane.destinationTerminal.uuid);
      setIntermediateTerminalUuids(
        lane.lineHaulLane.segments
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((s) => s.endTerminal.uuid)
          .slice(0, lane.lineHaulLane.segments.length - 1),
      );
    }
  }, [lane]);

  if (isNil(lane) || isNil(terminals)) {
    return null;
  }

  const goBack = async () => {
    navigate(-1);
  };

  const handleArchiveOrUnarchive = async ({
    shouldArchive,
  }: {
    shouldArchive: boolean;
  }) => {
    if (isNil(lane)) {
      return;
    }
    try {
      const result = await updateLineHaulLane({
        variables: {
          updateLineHaulLaneInput: {
            lineHaulLaneUpdateInput: {
              uuid: lane.lineHaulLane.uuid,
              isActive: !shouldArchive,
            },
          },
        },
      });

      if (!isNil(result.data?.updateLineHaulLane.errorMessage)) {
        setErrorMessage(errorMessage);
      } else {
        navigate(-1);
      }
    } catch (err) {
      setErrorMessage('An unknown error occurred, please contact support');
    }
  };

  return (
    <Stack sx={styles.container}>
      <Button onClick={goBack} variant="outlined" color="primary">
        <ArrowBackIcon sx={{ mr: '10px' }} />
        Back to setup
      </Button>
      <Typography variant="h6">
        {terminals.find((t) => t.uuid === originTerminalUuid)?.code} -{' '}
        {terminals.find((t) => t.uuid === destinationTerminalUuid)?.code}
      </Typography>
      <LineHaulEditor
        originTerminalUuid={originTerminalUuid}
        destinationTerminalUuid={destinationTerminalUuid}
        terminals={terminals}
        intermediateTerminalUuids={intermediateTerminalUuids}
        setIntermediateTerminalUuids={setIntermediateTerminalUuids}
        isCreateMode={false}
      />
      {lane.lineHaulLane.isActive && (
        <Box sx={{ border: 'solid 0.5px black', p: 1, borderRadius: '10px' }}>
          <ArchiveActionComponent
            entityType={ArchiveableEntity.LINE_HAUL_LANE}
            isActive={lane.lineHaulLane.isActive}
            handleArchive={() => {
              handleArchiveOrUnarchive({ shouldArchive: true });
            }}
            handleUnarchive={() => {}}
          />
        </Box>
      )}
    </Stack>
  );
};

export default ViewLineHaulLane;
