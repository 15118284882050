import { Dialog, DialogProps, Paper, PaperProps } from '@mui/material';
import { FunctionComponent } from 'react';
import Draggable from 'react-draggable';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import OrderPage, { OrderPageProps } from './order-form/order-page';

const componentsProps: DialogProps['componentsProps'] = {
  root: {
    style: {
      pointerEvents: 'none',
    },
  },
  backdrop: {
    style: {
      display: 'none',
    },
  },
};

const maxWidthPx = 1950;

const PaperComponent = (props: PaperProps) => {
  const { width } = useWindowDimensions();

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
      bounds="parent"
      disabled={width <= 1920}
    >
      <Paper
        {...props}
        style={{
          marginLeft: '32px',
          marginRight: '32px',
          width: '100%',
          maxWidth: `${maxWidthPx}px`,
          height: 'calc(100% - 64px)',
          // When a wide screen, offset 32 px from the left (the left margin).
          // The 900 value is the max width divided by 2.
          left: width > 1920 ? -width / 2 + 32 + maxWidthPx / 2 : undefined,
          pointerEvents: 'auto',
        }}
      />
    </Draggable>
  );
};

type OrderDialogProps = Omit<OrderPageProps, 'modalMode' | 'modalOnClose'> & {
  open: boolean;
  onClose: () => void;
};

export const OrderDialog: FunctionComponent<OrderDialogProps> = ({
  open,
  onClose,
  ...orderPageProps
}) => (
  <Dialog
    open={open}
    onClose={(_event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }}
    PaperComponent={PaperComponent}
    componentsProps={componentsProps}
    aria-labelledby="draggable-dialog-title"
  >
    <OrderPage
      {...orderPageProps}
      modalMode
      modalOnClose={onClose}
      draggableId="draggable-dialog-title"
    />
  </Dialog>
);
