import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import { AccessorialStoreState } from 'shared/types';
import type { RootState } from '../../../redux/store';

export type StandardAccessorialFields = {
  __typename: 'StandardAccessorialEntity';
  uuid: string;
  name: string;
  rate: number;
};

export type WeightBasedAccessorialFields = {
  __typename: 'WeightBasedAccessorialEntity';
  maximumCharge: number | null | undefined;
  minimumCharge: number | null | undefined;
  name: string;
  ratePerHundredWeight: number;
  uuid: string;
};

export type WaitTimeAccessorialFields = {
  __typename: 'WaitTimeAccessorialEntity';
  maximumCharge: number | null | undefined;
  minimumCharge: number | null | undefined;
  name: string;
  rate: number;
  uuid: string;
};

export type ZoneBasedAccessorialFields = {
  __typename: 'ZoneBasedAccessorialEntity';
  uuid: string;
  name: string;
  chargeGroupIds: string[];
  matrixItemIds: string[];
  zoneIds: string[];
};

export type SpecialAccessorialFields = {
  __typename: 'SpecialAccessorialEntity';
  uuid: string;
  name: string;
  chargeGroupIds: string[];
  matrixItemIds: string[];
};

const accessorialsValuesAdapter = createEntityAdapter<AccessorialStoreState>({
  selectId: (accessorial) => accessorial.uuid,
});

export const accessorialsSlice = createSlice({
  name: 'accessorialsValues',
  initialState: accessorialsValuesAdapter.getInitialState(),
  reducers: {
    addOneAccessorial: accessorialsValuesAdapter.addOne,
    updateAccessorial: accessorialsValuesAdapter.updateOne,
    deleteAccessorial: accessorialsValuesAdapter.removeOne,
    updateAccessorials: accessorialsValuesAdapter.updateMany,
    setAllAccessorials: accessorialsValuesAdapter.setAll,
    upsertAccessorial: accessorialsValuesAdapter.upsertOne,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectAccessorialById,
  selectAll: selectAllAccessorials,
  // Pass in a selector that returns the posts slice of state
} = accessorialsValuesAdapter.getSelectors(
  (state: RootState) => state.accessorials,
);

const accessorialsSelector = (state: RootState) => state.accessorials;

export const selectAccessorialsByIds = createSelector(
  accessorialsSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<AccessorialStoreState>, entityIds: EntityId[]) => {
    const entityResults: AccessorialStoreState[] = [];
    entityIds.forEach((id) => {
      const match = accessorialsValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match !== undefined) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addOneAccessorial,
  updateAccessorial,
  deleteAccessorial,
  setAllAccessorials,
  upsertAccessorial,
} = accessorialsSlice.actions;

export default accessorialsSlice.reducer;
