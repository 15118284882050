import { Button, TableCell, TableRow, useTheme } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { v4 } from 'uuid';
import { FeatureFlag } from '../../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../../../common/react-hooks/use-me';
import {
  CustomChargeBillingMethod,
  useAccessorialsForCustomChargeRowQuery,
} from '../../../../../../../../generated/graphql';
import { useAutoApplyAccessorials } from '../../../../../../../shipments/hooks/use-auto-apply-accessorials';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import { CustomChargeValues } from '../../../../forms/types';
import { calculateTotalCharge } from '../../../../forms/utils';
import { useShouldShowOrderChargesSection } from '../../../../hooks/use-expected-order-components';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';
import { OrderFormFieldValues } from '../../../../types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../../../constants';
import { CUSTOM_CHARGE_KEY } from '../custom-charge/labels';
import { QuickAddAccessorialsRow } from '../quick-add-accessorials-row';
import OrderChargesCustomChargeRow from './order-charges-custom-charge-row';
import OrderChargesFreightChargeRow from './order-charges-freight-charge-row';
import OrderChargesFuelChargeRow from './order-charges-fuel-charge-row';

const OrderCharges = ({ inBillingReview }: { inBillingReview: boolean }) => {
  const { companyConfiguration } = useMe();

  const { allowAddCharges: allowAddOrderCharges } =
    useShouldShowOrderChargesSection();

  const defaultChargeToAccessorial =
    companyConfiguration?.defaultToAccessorial === true;

  const [newlyAddedCharge, setNewlyAddedCharge] = useState(false);
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const contactUuid = useWatch({ control, name: 'contactUuid' });
  const serviceUuid = useWatch({ control, name: `serviceUuid` });

  const ffAccessorialQuickAdd = useFeatureFlag(
    FeatureFlag.FF_ACCESSORIAL_QUICK_ADD,
  );
  const { autoApplyAccessorials, loading: autoApplyAccessorialsLoading } =
    useAutoApplyAccessorials({
      contactUuid,
    });
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const isHazmat = useWatch({ control, name: 'hazmat' });
  const isInBond = useWatch({ control, name: 'inBond' });
  const { shouldRateOrder } = useShouldRateOrder({
    detailedStatus,
  });

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  const freightCharge = useWatch({
    control,
    name: `orderChargesShipment.freightCharge`,
  });
  const freightChargeTotal = freightCharge?.totalCharge;

  const fuelCharge = freightCharge?.fuelCharge;
  const fuelChargeTotal = fuelCharge?.totalCharge;

  const customCharges = filterNotNil(
    useWatch({ control, name: `orderChargesShipment.customCharges` }) ?? [],
  );
  const totalCharge = calculateTotalCharge({
    freightChargeTotal,
    fuelChargeTotal,
    customCharges,
  });
  useEffect(() => {
    setValue(`orderChargesShipment.totalCharge`, totalCharge);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCharge]);

  const theme = useTheme();
  const deleteCharge = (uuid: string | undefined) => {
    if (!isNil(uuid)) {
      setValue(
        `orderChargesShipment.customCharges`,
        customCharges.filter((customCharge) => customCharge.uuid !== uuid),
      );
    }
  };

  const { data: accessorialsForCustomChargeData } =
    useAccessorialsForCustomChargeRowQuery({
      fetchPolicy: 'cache-first',
      variables: {
        billingPartyContactUuid: contactUuid,
      },
    });

  const accessorialsForCustomCharge =
    accessorialsForCustomChargeData?.accessorialsByBillingContact ?? [];
  const quickAddAccessorials = accessorialsForCustomCharge.filter(
    (acc) => acc.enableQuickAdd,
  );

  const onAddCharge = (accessorialOption?: {
    // The value is either the UUID of the accessorial or CUSTOM_CHARGE_KEY.
    value: string;
    label: string;
  }) => {
    let accessorialUuid: string | null = null;
    const isAccessorial =
      !isNil(accessorialOption) &&
      accessorialOption.value !== CUSTOM_CHARGE_KEY;
    if (isAccessorial) {
      accessorialUuid = accessorialOption.value;
    }
    const newCustomCharge: CustomChargeValues = {
      deductionTotal: 0,
      quantity: 1,
      rate: 0,
      totalCharge: 0,
      accessorialUuid,
      specialAccessorialMatrixItemUuid: null,
      zoneBasedAccessorialMatrixItemUuid: null,
      fuelSurchargePercentageRate: 0,
      billingMethod:
        isAccessorial || defaultChargeToAccessorial
          ? CustomChargeBillingMethod.Accessorial
          : CustomChargeBillingMethod.AdHoc,
      uuid: v4(),
      isAutoApplied: false,
      isLocal: true,
      name: accessorialOption?.label ?? '',
      accessorialName: accessorialOption?.label ?? null,
      zoneUuid: null,
      chargeGroupUuid: null,
      accessorialRangeUuid: null,
      postedFuelSurchargeRate: null,
      description: null,
      authoCode: null,
      settlementPercentageRate: null,
      settlementFlatRate: null,
      settlementBillingMethod: null,
      useAccessorialRate: true,
    };

    if (defaultChargeToAccessorial) {
      setNewlyAddedCharge(true);
    }

    setValue(`orderChargesShipment.customCharges`, [
      ...customCharges,
      newCustomCharge,
    ]);
  };

  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });
  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });

  const orderChargesShipment = useWatch({
    control,
    name: `orderChargesShipment`,
  });

  useEffect(() => {
    if (autoApplyAccessorialsLoading || !shouldRateOrder) {
      return;
    }
    autoApplyAccessorials({ stopIdx: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoApplyAccessorialsLoading,
    contactUuid,
    inboundStopType,
    outboundStopType,
    serviceUuid,
    isHazmat,
    isInBond,
  ]);

  return (
    <>
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell colSpan={5}>ORDER CHARGES</TableCell>
      </TableRow>
      {ffAccessorialQuickAdd && quickAddAccessorials.length > 0 && (
        <QuickAddAccessorialsRow
          quickAddAccessorials={quickAddAccessorials}
          onAddCharge={onAddCharge}
        />
      )}
      {!isNil(freightCharge) && (
        <OrderChargesFreightChargeRow
          key={orderChargesShipment?.freightCharge?.uuid}
          inBillingReview={inBillingReview}
        />
      )}
      {!isNil(fuelCharge) && (
        <OrderChargesFuelChargeRow inBillingReview={inBillingReview} />
      )}
      {customCharges.map((_, customChargeIdx) => (
        <OrderChargesCustomChargeRow
          // because of the way we access custom charges inside custom-charge-row (using the index syntax),
          // we have to key on that index to avoid staleness when the array changes size
          // eslint-disable-next-line react/no-array-index-key
          key={customChargeIdx}
          customChargeIdx={customChargeIdx}
          deleteCharge={deleteCharge}
          inBillingReview={inBillingReview}
          newlyAddedCharge={newlyAddedCharge}
          setNewlyAddedCharge={setNewlyAddedCharge}
        />
      ))}
      <TableRow>
        <TableCell colSpan={5}>
          <Button
            onClick={() => onAddCharge()}
            disabled={disabledIfFinalizedOrLater || !allowAddOrderCharges}
          >
            + Add charge
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          {isNil(totalCharge) ? '-' : currency(totalCharge).format()}
        </TableCell>
        <TableCell />
      </TableRow>
    </>
  );
};

export default memo(OrderCharges);
