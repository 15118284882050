import { Warning } from '@mui/icons-material';
import { Stack } from '@mui/material';

const LineHaulPartialPiecesWarning = ({
  totalScans,
  totalPieces,
}: {
  totalScans: number;
  totalPieces: number;
}) => {
  if (totalScans > 0 && totalScans < totalPieces) {
    return (
      <Stack direction="row" alignItems="center">
        <Warning fontSize="small" color="warning" />
        {totalScans} of {totalPieces} pcs
      </Stack>
    );
  }
  return null;
};

export default LineHaulPartialPiecesWarning;
