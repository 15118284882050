import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { safeMultiply, MAX_INTEGER } from 'shared/math';
import { shallow } from 'zustand/shallow';
import {
  AccessorialDateRangeConfigFragment,
  AccessorialType,
  SpecialAccessorialType,
  useDeleteAccessorialDateRangeConfigMutation,
  useFuelProfilesQuery,
  useUpdateAccessorialDateRangeConfigMutation,
} from '../../../../../generated/graphql';
import { AccessorialRateTextField, NO_FUEL_PROFILE } from '../common';
import useAccessorialDateRangeConfigForm, {
  AccessorialDateRangeConfigFormValues,
  getAccessorialRangesForForm,
} from '../forms/use-accessorial-date-range-config-form';
import useAccessorialEditorStore from '../use-accessorial-editor-store';
import SpecialAccessorialRateMatrixEditor from './special-accessorial-rate-matrix-editor';
import WeightBasedAccessorialRateEditor from './weight-based-accessorial-rate-editor';

interface SingleRateAccessorialPriceEditorProps {
  accessorialUuid: string;
  accessorialDateRangeConfig: AccessorialDateRangeConfigFragment;
  accessorialDateRangeConfigLength: number;
  refetchAccessorialDateRangeConfigs: () => Promise<void>;
  accessorialType: AccessorialType;
  specialAccessorialType?: SpecialAccessorialType | null;
}
const AccessorialPriceEditor = ({
  accessorialUuid,
  accessorialDateRangeConfig,
  accessorialDateRangeConfigLength,
  refetchAccessorialDateRangeConfigs,
  accessorialType,
  specialAccessorialType,
}: SingleRateAccessorialPriceEditorProps) => {
  const form = useAccessorialDateRangeConfigForm();
  const confirm = useConfirm();

  const fuelProfileUuidValue = form.getValues('fuelProfileUuid');

  const [
    setShowUpdateAccessorialDateRangeSuccessMessage,
    setShowUpdateAccessorialDateRangeErrorMessage,
    shouldSaveDateRangeConfig,
    setShouldSaveDateRangeConfig,
  ] = useAccessorialEditorStore(
    (state) => [
      state.setShowUpdateAccessorialDateRangeSuccessMessage,
      state.setShowUpdateAccessorialDateRangeErrorMessage,
      state.shouldSaveDateRangeConfig,
      state.setShouldSaveDateRangeConfig,
    ],
    shallow,
  );

  const [
    updateAccessorialDateRangeConfig,
    { loading: updateAccessorialDateRangeConfigLoading },
  ] = useUpdateAccessorialDateRangeConfigMutation();
  const [deleteAccessorialDateRangeConfig] =
    useDeleteAccessorialDateRangeConfigMutation();

  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();

  useEffect(() => {
    form.reset({
      startDate: accessorialDateRangeConfig.startDate,
      endDate: accessorialDateRangeConfig.endDate,
      percentForSettlement: accessorialDateRangeConfig.percentForSettlement,
      rate: accessorialDateRangeConfig.rate,
      fuelProfileUuid:
        accessorialDateRangeConfig?.fuelProfile?.uuid ?? NO_FUEL_PROFILE,
      ranges: getAccessorialRangesForForm(accessorialDateRangeConfig),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialDateRangeConfig]);

  const onSubmit: SubmitHandler<AccessorialDateRangeConfigFormValues> = async (
    data,
  ) => {
    const {
      startDate,
      endDate,
      percentForSettlement,
      rate,
      fuelProfileUuid,
      ranges,
    } = data;

    const lastRange = ranges[ranges.length - 1];
    if (!isNil(lastRange)) {
      lastRange.lessThanOrEqualToValue = MAX_INTEGER;
    }

    if (
      accessorialType === AccessorialType.Weight &&
      ranges.some((r) => isNil(r.lessThanOrEqualToValue))
    ) {
      setShowUpdateAccessorialDateRangeErrorMessage(true);
      return;
    }

    const res = await updateAccessorialDateRangeConfig({
      variables: {
        updateAccessorialDateRangeConfigInput: {
          uuid: accessorialDateRangeConfig.uuid,
          accessorialUuid,
          startDate,
          endDate: endDate ?? undefined,
          percentForSettlement: !isNil(percentForSettlement)
            ? percentForSettlement
            : undefined,
          rateUsd: !isNil(rate) ? rate : undefined,
          fuelProfileUuid:
            !isNil(fuelProfileUuid) && fuelProfileUuid !== NO_FUEL_PROFILE
              ? fuelProfileUuid
              : null,
          accessorialRangeUpdateInputs: ranges.map((r) => ({
            uuid: r.uuid,
            lessThanOrEqualToValue: r.lessThanOrEqualToValue ?? MAX_INTEGER,
            rateUsdCents: safeMultiply(r.rate ?? 0, 100),
          })),
        },
      },
    });
    await refetchAccessorialDateRangeConfigs();
    if (!isNil(res.errors)) {
      setShowUpdateAccessorialDateRangeErrorMessage(true);
    } else {
      setShowUpdateAccessorialDateRangeSuccessMessage(true);
    }
  };

  const onDelete = () => {
    confirm({
      title: 'Are you sure you want to delete this price?',
      description: (
        <Typography>
          Any orders currently using this price will no longer use this price.
          This action cannot be undone.
        </Typography>
      ),
      cancellationText: `Cancel`,
      confirmationText: `Confirm`,
    }).then(async () => {
      await deleteAccessorialDateRangeConfig({
        variables: {
          deleteAccessorialDateRangeConfigInput: {
            uuid: accessorialDateRangeConfig.uuid,
          },
        },
      });
      await refetchAccessorialDateRangeConfigs();
    });
  };

  useEffect(() => {
    if (shouldSaveDateRangeConfig) {
      onSubmit(form.getValues());
      setShouldSaveDateRangeConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSaveDateRangeConfig]);

  let label = 'Rate';
  if (accessorialType === AccessorialType.Skid) {
    label = 'Rate per skid';
  } else if (accessorialType === AccessorialType.Unit) {
    label = 'Rate per unit';
  }

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <InputLabel
                  shrink={!isEmpty(fuelProfileUuidValue)}
                  id="select-fuel-profile-label"
                >
                  Fuel Profile
                </InputLabel>
                <Select
                  sx={{ minWidth: '200px', width: 'fit-content' }}
                  labelId="select-fuel-profile-label"
                  id="select-fuel-profile"
                  name="fuelProfileUuid"
                  label="Fuel Profile"
                  size="small"
                  value={value}
                  onChange={onChange}
                  onBlur={form.handleSubmit(onSubmit)}
                  disabled={
                    fuelProfilesLoading ||
                    updateAccessorialDateRangeConfigLoading
                  }
                >
                  <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                    {NO_FUEL_PROFILE}
                  </MenuItem>
                  {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="fuelProfileUuid"
            control={form.control}
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12}>
          {(accessorialType === AccessorialType.Standard ||
            accessorialType === AccessorialType.Skid ||
            accessorialType === AccessorialType.Unit ||
            accessorialType === AccessorialType.WaitTime) && (
            <Controller
              render={({ field: { onChange, value } }) => (
                <AccessorialRateTextField
                  value={value}
                  error={form.formState.errors.rate?.message}
                  name="rate"
                  label={label}
                  type="number"
                  onBlur={form.handleSubmit(onSubmit)}
                  onChange={(e) => {
                    const parsedFloat = parseFloat(e.target.value);
                    onChange(parsedFloat);
                  }}
                  isCurrency
                  disabled={updateAccessorialDateRangeConfigLoading}
                />
              )}
              name="rate"
              control={form.control}
            />
          )}
          {accessorialType === AccessorialType.Special && (
            <SpecialAccessorialRateMatrixEditor
              accessorialDateRangeConfigUuid={accessorialDateRangeConfig.uuid}
              specialAccessorialType={specialAccessorialType}
            />
          )}
          {accessorialType === AccessorialType.Weight && (
            <WeightBasedAccessorialRateEditor />
          )}
        </Grid>

        {accessorialDateRangeConfigLength > 1 && (
          <Grid item xs={12}>
            <Button color="error" startIcon={<DeleteIcon />} onClick={onDelete}>
              DELETE PRICE
            </Button>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default React.memo(AccessorialPriceEditor);
