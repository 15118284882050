import { isNil } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import {
  ScannedOrderResultStatus,
  useScannedOrderResultQuery,
  useUpdateScannedOrderResultStatusMutation,
} from '../../../../../generated/graphql';
import useOrderFormStore from '../../../order-form-store';
import useIngestedEmailsStore from '../../email-ingestion/ingested-emails-store';

const useDocScan = ({
  orderUuid,
  scannedOrderResultUuid,
  inInboundEmailReview,
}: {
  orderUuid: string | undefined | null;
  scannedOrderResultUuid: string | undefined | null;
  inInboundEmailReview?: boolean;
}) => {
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [setIsSaving] = useOrderFormStore(
    (state) => [state.setIsSavingDocScan],
    shallow,
  );
  const { data: scannedOrderResultData, loading: scannedOrderResultLoading } =
    useScannedOrderResultQuery(
      isNil(scannedOrderResultUuid)
        ? { skip: true }
        : {
            variables: {
              uuid: scannedOrderResultUuid,
            },
          },
    );
  const [updateScannedOrderResultStatus] =
    useUpdateScannedOrderResultStatusMutation();
  const updateSelectedOrderAfterReview = useIngestedEmailsStore(
    (state) => state.updateSelectedOrderAfterReview,
  );

  const handleSetOrderUuid = (uuid: string | undefined) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      if (isNil(uuid)) {
        newParams.delete('orderUuid');
      } else {
        newParams.set('orderUuid', uuid);
      }
      return newParams;
    });
  };
  const approveScannedOrderResult = async () => {
    setIsSaving(true);
    if (!isNil(scannedOrderResultUuid)) {
      await updateScannedOrderResultStatus({
        variables: {
          updateScannedOrderResultStatusInput: {
            scannedOrderResultUuid,
            orderUuid,
            status: ScannedOrderResultStatus.Approved,
          },
        },
      });
    }
    if (inInboundEmailReview === true) {
      await updateSelectedOrderAfterReview(handleSetOrderUuid);
      return;
    }
    const nextOrderUuid =
      scannedOrderResultData?.scannedOrderResult?.nextScannedOrderResultInReview
        ?.order?.uuid;
    if (!isNil(nextOrderUuid)) {
      navigate(`/orders?orderUuid=${nextOrderUuid}&isDocumentScan=true`);
    } else if (
      scannedOrderResultData?.scannedOrderResult?.status ===
      ScannedOrderResultStatus.Duplicate
    ) {
      navigate('/orders/scans?tab=DUPLICATES');
    } else {
      navigate('/orders/scans');
    }
    setIsSaving(false);
  };

  const rejectScannedOrderResult = async () => {
    setIsSaving(true);
    if (!isNil(scannedOrderResultUuid)) {
      await updateScannedOrderResultStatus({
        variables: {
          updateScannedOrderResultStatusInput: {
            scannedOrderResultUuid,
            status: ScannedOrderResultStatus.Rejected,
          },
        },
      });
    }
    if (inInboundEmailReview === true) {
      await updateSelectedOrderAfterReview(handleSetOrderUuid);
      setIsSaving(false);
      return;
    }
    const nextOrder =
      scannedOrderResultData?.scannedOrderResult?.nextScannedOrderResultInReview
        ?.order?.uuid;
    if (!isNil(nextOrder)) {
      navigate(`/orders/?orderUuid=${nextOrder}&isDocumentScan=true`);
    } else if (
      scannedOrderResultData?.scannedOrderResult?.status ===
      ScannedOrderResultStatus.Duplicate
    ) {
      navigate('/orders/scans?tab=DUPLICATES');
    } else {
      navigate('/orders/scans');
    }
    setIsSaving(false);
  };

  return {
    loading: scannedOrderResultLoading,
    scannedOrderResult: scannedOrderResultData?.scannedOrderResult,
    approveScannedOrderResult,
    rejectScannedOrderResult,
  };
};

export default useDocScan;
