import { Stack, Typography } from '@mui/material';
import theme from '../../../theme';
import { DashboardDrawerItem } from '../types';
import SubpageList from './subpage-list';

const SidebarDrawerItemGroup = ({
  drawerItem,
}: {
  drawerItem: DashboardDrawerItem;
}) => {
  return (
    <Stack
      direction="column"
      sx={{
        color: theme.palette.concreteGrey[50],
        paddingLeft: 1,
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Stack>{drawerItem.icon}</Stack>
        <Stack>
          <Typography
            sx={{ fontWeight: 'bold', letterSpacing: '1px' }}
            variant="caption"
          >
            {drawerItem.name.toUpperCase()}
          </Typography>
        </Stack>
      </Stack>
      <SubpageList drawerItem={drawerItem} />
    </Stack>
  );
};

export { SidebarDrawerItemGroup };
