import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { MAX_INTEGER } from 'shared/math';
import { shallow } from 'zustand/shallow';
import { RangeInput } from '../../common/range-input';
import { getValueWithinBounds } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';
import styles from '../styles';
import { AmountInputType } from '../types';

const TariffMileRangeOverageRateModal = ({
  overageRateModalOpen,
  setOverageRateModalOpen,
}: {
  overageRateModalOpen: boolean;
  setOverageRateModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [zoneBasedOverageRates, xRangeValues] = useTariffGroupStore(
    (state) => [state.zoneBasedOverageRates, state.xRangeValues],
    shallow,
  );

  const [setZoneBasedOverageRates, setUseGlobalOverageRates] =
    useTariffGroupStore(
      (state) => [
        state.setZoneBasedOverageRates,
        state.setUseGlobalOverageRates,
      ],
      shallow,
    );

  const [statefulZoneBasedOverageRates, setStatefulZoneBasedOverageRates] =
    useState(zoneBasedOverageRates);

  useEffect(() => {
    setStatefulZoneBasedOverageRates(zoneBasedOverageRates);
  }, [zoneBasedOverageRates]);

  const updateMileOverageRate = (rowIndex: number, rate: number | null) => {
    setStatefulZoneBasedOverageRates((prev) => {
      const newState = prev.map((item, index) =>
        index === rowIndex ? { ...item, mileOverageRate: rate } : item,
      );
      return newState;
    });
  };

  const updateMileOverageApplicableAbove = (
    rowIndex: number,
    applicableAboveAmount: number | null,
  ) => {
    setStatefulZoneBasedOverageRates((prev) => {
      const newState = prev.map((item, index) =>
        index === rowIndex
          ? { ...item, mileOverageApplicableAbove: applicableAboveAmount }
          : item,
      );
      return newState;
    });
  };

  const updatePieceOverageRate = (rowIndex: number, rate: number | null) => {
    setStatefulZoneBasedOverageRates((prev) => {
      const newState = prev.map((item, index) =>
        index === rowIndex ? { ...item, pieceOverageRate: rate } : item,
      );
      return newState;
    });
  };

  const updatePieceOverageApplicableAbove = (
    rowIndex: number,
    applicableAboveAmount: number | null,
  ) => {
    setStatefulZoneBasedOverageRates((prev) => {
      const newState = prev.map((item, index) =>
        index === rowIndex
          ? { ...item, pieceOverageApplicableAbove: applicableAboveAmount }
          : item,
      );
      return newState;
    });
  };

  const updateWeightOverageRate = (rowIndex: number, rate: number | null) => {
    setStatefulZoneBasedOverageRates((prev) => {
      const newState = prev.map((item, index) =>
        index === rowIndex ? { ...item, weightOverageRate: rate } : item,
      );
      return newState;
    });
  };

  const updateWeightOverageApplicableAbove = (
    rowIndex: number,
    applicableAboveAmount: number | null,
  ) => {
    setStatefulZoneBasedOverageRates((prev) => {
      const newState = prev.map((item, index) =>
        index === rowIndex
          ? { ...item, weightOverageApplicableAbove: applicableAboveAmount }
          : item,
      );
      return newState;
    });
  };

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    let rate = null;
    if (!Number.isNaN(parsed)) {
      rate = getValueWithinBounds(currency(parsed, { precision: 2 }).value);
    }
    if (event.target.name === AmountInputType.MileOverage) {
      updateMileOverageRate(rowIndex, rate);
    } else if (
      event.target.name === AmountInputType.MileOverageApplicableAbove
    ) {
      updateMileOverageApplicableAbove(rowIndex, rate);
    } else if (event.target.name === AmountInputType.PieceOverage) {
      updatePieceOverageRate(rowIndex, rate);
    } else if (
      event.target.name === AmountInputType.PieceOverageApplicableAbove
    ) {
      updatePieceOverageApplicableAbove(rowIndex, rate);
    } else if (event.target.name === AmountInputType.WeightOverage) {
      updateWeightOverageRate(rowIndex, rate);
    } else if (
      event.target.name === AmountInputType.WeightOverageApplicableAbove
    ) {
      updateWeightOverageApplicableAbove(rowIndex, rate);
    }
  };

  const onSave = () => {
    setZoneBasedOverageRates(statefulZoneBasedOverageRates);
    setOverageRateModalOpen(false);
  };

  return (
    <Modal
      open={overageRateModalOpen}
      onClose={() => setOverageRateModalOpen(false)}
    >
      <Box sx={{ ...styles.modal, width: '80vw' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Edit Overages</Typography>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => setUseGlobalOverageRates(true)}
                >
                  Set Global Overage Rates
                </Button>
                <Button variant="contained" onClick={onSave}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack
              spacing={2}
              sx={{ width: '100%', maxHeight: '55vh', overflowY: 'auto' }}
            >
              <TableContainer sx={{ maxHeight: '55vh' }}>
                <Table stickyHeader>
                  <TableHead aria-label="tariff-table">
                    <TableRow>
                      <TableCell align="center">
                        <Typography>Mile Range</Typography>
                      </TableCell>
                      <TableCell colSpan={6} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {xRangeValues.map((value, rangeIndex) => {
                      const prevValue =
                        Number.isNaN(xRangeValues[rangeIndex - 1]) ||
                        xRangeValues[rangeIndex - 1] === MAX_INTEGER ||
                        isNil(xRangeValues[rangeIndex - 1])
                          ? undefined
                          : xRangeValues[rangeIndex - 1];

                      const displayValue =
                        value === MAX_INTEGER || isNil(value) ? '?' : value;

                      return (
                        <>
                          <TableRow>
                            <TableCell>
                              <Box sx={styles.center}>
                                <RangeInput
                                  rangeIndex={rangeIndex}
                                  prevValue={prevValue}
                                  rangeLength={xRangeValues.length}
                                  displayValue={displayValue}
                                  editable={false}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={4}>
                                <Stack direction="row" flex={1} spacing={2}>
                                  <TextField
                                    sx={{ flex: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                    name={AmountInputType.MileOverage}
                                    size="small"
                                    label="Mile Overage Rate"
                                    onBlur={(event) =>
                                      validateAndUpdateRate(event, rangeIndex)
                                    }
                                    onChange={(event) => {
                                      updateMileOverageRate(
                                        rangeIndex,
                                        parseFloat(event.target.value),
                                      );
                                    }}
                                    value={
                                      statefulZoneBasedOverageRates[rangeIndex]
                                        ?.mileOverageRate ?? null
                                    }
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLTextAreaElement).blur()
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <TextField
                                    sx={{ flex: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                    name={
                                      AmountInputType.MileOverageApplicableAbove
                                    }
                                    size="small"
                                    label="Applicable Above (Optional)"
                                    onBlur={(event) =>
                                      validateAndUpdateRate(event, rangeIndex)
                                    }
                                    onChange={(event) => {
                                      updateMileOverageApplicableAbove(
                                        rangeIndex,
                                        parseFloat(event.target.value),
                                      );
                                    }}
                                    value={
                                      statefulZoneBasedOverageRates[rangeIndex]
                                        ?.mileOverageApplicableAbove ?? null
                                    }
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLTextAreaElement).blur()
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          miles
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Stack>
                                <Stack direction="row" flex={1} spacing={2}>
                                  <TextField
                                    sx={{ flex: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                    name={AmountInputType.PieceOverage}
                                    size="small"
                                    label="Pieces Overage Rate"
                                    onBlur={(event) =>
                                      validateAndUpdateRate(event, rangeIndex)
                                    }
                                    onChange={(event) => {
                                      updatePieceOverageRate(
                                        rangeIndex,
                                        parseFloat(event.target.value),
                                      );
                                    }}
                                    value={
                                      statefulZoneBasedOverageRates[rangeIndex]
                                        ?.pieceOverageRate ?? null
                                    }
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLTextAreaElement).blur()
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <TextField
                                    sx={{ flex: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                    name={
                                      AmountInputType.PieceOverageApplicableAbove
                                    }
                                    size="small"
                                    label="Applicable Above (Optional)"
                                    onBlur={(event) =>
                                      validateAndUpdateRate(event, rangeIndex)
                                    }
                                    onChange={(event) => {
                                      updatePieceOverageApplicableAbove(
                                        rangeIndex,
                                        parseFloat(event.target.value),
                                      );
                                    }}
                                    value={
                                      statefulZoneBasedOverageRates[rangeIndex]
                                        ?.pieceOverageApplicableAbove ?? null
                                    }
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLTextAreaElement).blur()
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          pieces
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Stack>
                                <Stack direction="row" flex={1} spacing={2}>
                                  <TextField
                                    sx={{ flex: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                    name={AmountInputType.WeightOverage}
                                    size="small"
                                    label="Weight Overage Rate"
                                    onBlur={(event) =>
                                      validateAndUpdateRate(event, rangeIndex)
                                    }
                                    onChange={(event) => {
                                      updateWeightOverageRate(
                                        rangeIndex,
                                        parseFloat(event.target.value),
                                      );
                                    }}
                                    value={
                                      statefulZoneBasedOverageRates[rangeIndex]
                                        ?.weightOverageRate ?? null
                                    }
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLTextAreaElement).blur()
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <TextField
                                    sx={{ flex: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                    name={
                                      AmountInputType.WeightOverageApplicableAbove
                                    }
                                    size="small"
                                    label="Applicable Above (Optional)"
                                    onBlur={(event) =>
                                      validateAndUpdateRate(event, rangeIndex)
                                    }
                                    onChange={(event) => {
                                      updateWeightOverageApplicableAbove(
                                        rangeIndex,
                                        parseFloat(event.target.value),
                                      );
                                    }}
                                    value={
                                      statefulZoneBasedOverageRates[rangeIndex]
                                        ?.weightOverageApplicableAbove ?? null
                                    }
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLTextAreaElement).blur()
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          pounds
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Stack>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          <Divider />
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffMileRangeOverageRateModal;
