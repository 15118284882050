import { MenuItem, Select } from '@mui/material';
import { sentenceCase } from 'change-case';
import type { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { FulfillmentType } from '../../../../generated/graphql';
import { OrderFormValues } from '../../../orders/components/order-form/forms/types';

export const SelectFulfillmentType: FunctionComponent = () => {
  const { watch, setValue } = useFormContext<OrderFormValues>();
  const fulfillmentType = watch('fulfillmentType');

  return (
    <Select<FulfillmentType>
      size="small"
      value={fulfillmentType ?? ''}
      onChange={({ target }) => {
        const { value } = target;
        setValue('fulfillmentType', value as FulfillmentType);
      }}
      sx={{
        width: '98px',
      }}
      inputProps={{
        sx: {
          fontSize: '12px !important',
        },
      }}
    >
      {Object.values(FulfillmentType).map((type) => (
        <MenuItem
          key={type}
          value={type}
          sx={{
            fontSize: 12,
          }}
        >
          {sentenceCase(type)}
        </MenuItem>
      ))}
    </Select>
  );
};
