import { WarningAmber, PanToolOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Skeleton, Stack, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import { SegmentedLineHaulOrder } from '../types';

const SegmentCell = ({
  segmentedOrder,
}: {
  segmentedOrder: SegmentedLineHaulOrder | undefined;
}) => {
  if (isNil(segmentedOrder)) {
    return (
      <Box width="100px">
        <Skeleton />
      </Box>
    );
  }

  // if upcoming segment !== this segment and ordinal of segment > 0
  // if ordinal = 0 and has not completed inbound

  let showArriviedIcon = false;
  let tooltipText = null;
  if (
    segmentedOrder.segment.ordinal === 0 &&
    !isNil(segmentedOrder.order.dateMarkedReceivedAtOrigin)
  ) {
    tooltipText = `The order has been recieved at ${segmentedOrder.segment.startTerminal.code}`;
    showArriviedIcon = true;
  } else if (
    segmentedOrder.segment.startTerminal.uuid ===
    segmentedOrder.lastCompletedLineHaulSegment?.endTerminal.uuid
  ) {
    tooltipText = `The order has been recieved at ${segmentedOrder.segment.startTerminal.code}`;
    showArriviedIcon = true;
  } else if (
    segmentedOrder.segment.ordinal === 0 &&
    !isNil(segmentedOrder.hasNoneOrCompletedInboundStop) &&
    segmentedOrder.hasNoneOrCompletedInboundStop
  ) {
    tooltipText = `The order is not on-hand at ${segmentedOrder.segment.startTerminal.code}`;
  } else if (
    segmentedOrder.segment.ordinal > 0 &&
    segmentedOrder.upcomingLineHaulSegment?.uuid !== segmentedOrder.segment.uuid
  ) {
    tooltipText = `The order has not arrived at ${segmentedOrder.segment.startTerminal.code}`;
  } else if (
    segmentedOrder.segment.ordinal > 0 &&
    segmentedOrder.upcomingLineHaulSegment?.uuid === segmentedOrder.segment.uuid
  ) {
    tooltipText = `The order has been recieved at ${segmentedOrder.segment.startTerminal.code}`;
    showArriviedIcon = true;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
    >
      {`${segmentedOrder?.segment.startTerminal.code} → ${segmentedOrder?.segment.endTerminal.code}`}
      {isNil(segmentedOrder?.hasNoneOrCompletedInboundStop) ? (
        <CircularProgress size={10} />
      ) : (
        !isNil(tooltipText) && (
          <Tooltip
            // disableHoverListener={nextSegmentMatchesOpenedManifest}
            title={tooltipText}
          >
            <div>
              {showArriviedIcon ? (
                <PanToolOutlined color="success" sx={{ fontSize: 14 }} />
              ) : (
                <WarningAmber color="warning" sx={{ fontSize: 14 }} />
              )}
            </div>
          </Tooltip>
        )
      )}
    </Stack>
  );
};

export default SegmentCell;
