import { ArrowRightAlt } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { MAX_INTEGER } from 'shared/math';
import { AccessorialType } from '../../../../generated/graphql';
import {
  AccessorialRange,
  FormMode,
  WeightBasedAccessorialFormErrors,
  WeightBasedAccessorialFormFieldName,
  WeightBasedAccessorialFormValues,
  WeightBasedAccessorialTextField,
} from './common';

const getPreviousAccessorialRangeValue = (
  ranges: AccessorialRange[],
  idx: number,
) => {
  return Number.isNaN(ranges[idx - 1]?.lessThanOrEqualToValue) ||
    ranges[idx - 1]?.lessThanOrEqualToValue === MAX_INTEGER ||
    isNil(ranges[idx - 1]?.lessThanOrEqualToValue)
    ? undefined
    : ranges[idx - 1]?.lessThanOrEqualToValue;
};

const RangeBasedAccessorialRateEditor = ({
  accessorialType,
  mode,
  formValues,
  onChangeFormValues,
  formErrors,
  validateFieldsAndSetErrors,
}: {
  accessorialType: AccessorialType;
  mode: FormMode;
  formValues: WeightBasedAccessorialFormValues;
  onChangeFormValues: (newData: WeightBasedAccessorialFormValues) => void;
  formErrors: WeightBasedAccessorialFormErrors;
  validateFieldsAndSetErrors: (
    field: WeightBasedAccessorialFormFieldName,
  ) => void;
}) => {
  return (
    <TableContainer sx={{ maxHeight: '80vh', overflowX: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Weight range</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {formValues.ranges.map((range, idx) => {
          const prevValue = getPreviousAccessorialRangeValue(
            formValues.ranges,
            idx,
          );

          return (
            <TableRow key={range.uuid}>
              <TableCell>
                {idx < formValues.ranges.length - 1 ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        idx > 0 && !isNil(prevValue)
                          ? prevValue + 1
                          : idx === 0
                            ? '1'
                            : '?'
                      }
                    </Typography>
                    <ArrowRightAlt />
                    <WeightBasedAccessorialTextField
                      mode={mode}
                      value={range.lessThanOrEqualToValue}
                      error={
                        !isNil(formErrors?.ranges)
                          ? formErrors?.ranges[idx]
                          : undefined
                      }
                      name="ranges"
                      type="number"
                      isWeight
                      onBlur={() => {}}
                      onChange={(name, value) => {
                        const newRanges = [...formValues.ranges];
                        const newUpperLimit = parseFloat(
                          value?.toString() ?? '',
                        );
                        newRanges[idx] = {
                          ...range,
                          lessThanOrEqualToValue: !Number.isNaN(newUpperLimit)
                            ? newUpperLimit
                            : null,
                        };
                        onChangeFormValues({
                          ...formValues,
                          ranges: newRanges,
                        });
                      }}
                      label=""
                    />
                  </Stack>
                ) : (
                  <Typography>
                    {idx > 0 && !isNil(prevValue) ? prevValue + 1 : 1}+ lbs
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <WeightBasedAccessorialTextField
                  mode={mode}
                  value={range.rate}
                  error={
                    !isNil(formErrors?.ranges)
                      ? formErrors?.ranges[idx]
                      : undefined
                  }
                  name="ranges"
                  type="number"
                  isCurrency
                  onBlur={validateFieldsAndSetErrors}
                  onChange={(name, value) => {
                    const currentFormVals = { ...formValues };
                    const newRate = parseFloat(value?.toString() ?? '');
                    currentFormVals.ranges[idx] = {
                      ...range,
                      rate: !Number.isNaN(newRate) ? newRate : null,
                    };
                    onChangeFormValues(currentFormVals);
                  }}
                  label={
                    accessorialType === AccessorialType.Weight
                      ? 'Rate per pound'
                      : 'Rate'
                  }
                />
              </TableCell>
              <TableCell>
                <IconButton
                  color="error"
                  onClick={() => {
                    const newRanges = [...formValues.ranges];
                    newRanges.splice(idx, 1);
                    onChangeFormValues({
                      ...formValues,
                      ranges: newRanges,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <Button
                onClick={() => {
                  const newRanges = [...formValues.ranges];
                  newRanges.splice(newRanges.length - 1 ?? 0, 0, {
                    uuid: null,
                    lessThanOrEqualToValue: null,
                    rate: null,
                  });
                  onChangeFormValues({
                    ...formValues,
                    ranges: newRanges,
                  });
                }}
              >
                + Add Range
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default RangeBasedAccessorialRateEditor;
