import { Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const AppointmentDecisionCompleteView = () => {
  const [searchParams] = useSearchParams();
  const accepted = searchParams.get('accepted') === 'true';
  const styles = {
    boxView: {
      pt: 4,
      px: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
    },
  };

  return (
    <Box sx={styles.boxView}>
      {accepted ? (
        <Typography variant="h1" fontSize="large">
          Appointment accepted
        </Typography>
      ) : (
        <Typography fontWeight={500}>
          Request to reschedule appointment sent.
        </Typography>
      )}
      <Typography fontWeight={500}>You may now close this page.</Typography>
    </Box>
  );
};

export default AppointmentDecisionCompleteView;
