import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil, truncate } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { Option } from '../filters/types';
import useStyles from './general-styles';

export const getMultiselectFilterLabel = (
  selectedOptions: Option[] | null | undefined,
  displayNoneOption: boolean,
) => {
  let filterValue = 'All';
  if (!isNil(selectedOptions)) {
    if (selectedOptions?.length > 0) {
      if (selectedOptions.length === 1) {
        filterValue = truncate(selectedOptions[0]?.label ?? '', { length: 20 });
      } else {
        filterValue = `${selectedOptions.length} selected`;
      }
    } else if (displayNoneOption) {
      filterValue = 'None';
    }
  }
  return filterValue;
};

export const multiselectCacheKey = (cachePrefix: string, cacheId: string) =>
  `${cachePrefix}_MULTISELECT_${cacheId}`;

const MultiselectFilterButton = ({
  selectedOptions,
  options,
  handleChange,
  prefixText,
  displayNoneOption = false,
  isSmall = false,
  additionalMenuItems = [],
  filterLabel,
  cachePrefix,
  cacheId,
  disableButton = false,
}: {
  selectedOptions: Option[] | null | undefined;
  options: Option[];
  handleChange: (options: Option[] | null | undefined) => void;
  prefixText: string;
  displayNoneOption?: boolean;
  isSmall?: boolean;
  additionalMenuItems?: { element: JSX.Element; checked: boolean }[];
  filterLabel?: string;
  cachePrefix?: string;
  cacheId?: string;
  disableButton?: boolean;
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const styles = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const cacheKey = multiselectCacheKey(cachePrefix ?? '', cacheId ?? '');

  useEffect(() => {
    if (!isNil(cachePrefix) && !isNil(cacheId) && !isNil(handleChange)) {
      const cachedSelections = localStorage.getItem(cacheKey);
      if (!isNil(cachedSelections)) {
        const cachedOptions = JSON.parse(cachedSelections);
        handleChange(cachedOptions.options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hiding the "None" option and passing in an empty values array is an invalid state
  if (
    !isNil(selectedOptions) &&
    selectedOptions.length === 0 &&
    !displayNoneOption
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  // "All" is represented by null selectedOptions and "None" is represented by an empty array
  const allSelected =
    isNil(selectedOptions) ||
    (!displayNoneOption && selectedOptions?.length === 0);
  const noneSelected = displayNoneOption && selectedOptions?.length === 0;
  const additionalMenuItemsChecked = additionalMenuItems.some(
    (item) => item.checked,
  );

  const onChange = (newOptions: Option[] | null | undefined) => {
    if (!isNil(cachePrefix) && !isNil(cacheId)) {
      if (isNil(newOptions)) {
        localStorage.removeItem(cacheKey);
      } else {
        localStorage.setItem(cacheKey, JSON.stringify({ options: newOptions }));
      }
    }
    handleChange(newOptions);
  };

  const onClose = () => {
    setMenuAnchorEl(null);
  };

  const filterLabelText =
    filterLabel ??
    getMultiselectFilterLabel(selectedOptions, displayNoneOption);

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        ref={buttonRef}
        size="small"
        variant="outlined"
        sx={[isSmall ? styles.filterButtonSmall : styles.filterButton]}
        disabled={disableButton}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{prefixText}:</Typography>
          <Typography sx={styles.filterValue}>{filterLabelText}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        onClose={() => onClose()}
        open={Boolean(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            onClick={() => onChange(null)}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    allSelected && !additionalMenuItemsChecked
                      ? undefined
                      : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    !allSelected && !noneSelected && !additionalMenuItemsChecked
                      ? undefined
                      : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                multiple
                disableCloseOnSelect
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={selectedOptions ?? []}
                options={options.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: 0 }}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                onChange={(_, selected) => {
                  if (selected.length === 0) {
                    onChange(null); // Default to "All" if no options are selected
                  } else {
                    onChange(selected);
                  }
                }}
              />
            </Stack>
          </MenuItem>
          {displayNoneOption && (
            <MenuItem
              key="none"
              onClick={() => onChange([])}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  sx={{
                    visibility:
                      noneSelected && !additionalMenuItemsChecked
                        ? undefined
                        : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>None</Typography>
              </Stack>
            </MenuItem>
          )}
          {additionalMenuItems.map(({ element }) => element)}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default MultiselectFilterButton;
