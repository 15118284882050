/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface Company {
  uuid: string;
  name: string;
}
interface CustomerPortalStoreState {
  company: Company | null;
}
interface CustomerPortalStoreActions {
  setCompany: (companyUuid: Company | null) => void;
}

const useCustomerPortalStore = create(
  immer<CustomerPortalStoreState & CustomerPortalStoreActions>((set) => ({
    company: null,
    setCompany: (company) => {
      set((state) => {
        state.company = company;
      });
    },
  })),
);

export default useCustomerPortalStore;
