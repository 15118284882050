import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  ShallowTerminalFragment,
  useTerminalsLazyQuery,
} from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import useMe from './use-me';

const useTerminals = ({
  includeInactiveTerminals = false,
}: {
  includeInactiveTerminals?: boolean;
}) => {
  const { companyConfiguration } = useMe();
  const terminals = useGlobalStore((state) => state.terminals);
  const setTerminals = useGlobalStore((state) => state.setTerminals);
  const [getTerminals, { loading, refetch }] = useTerminalsLazyQuery({
    fetchPolicy: 'cache-first',
    variables: {
      getTerminalsInput: {
        excludeNotActive: false,
      },
    },
  });

  const getTerminal = useCallback(
    (uuid: string): ShallowTerminalFragment | undefined => {
      return terminals.find((terminal) => terminal.uuid === uuid);
    },
    [terminals],
  );

  const getTerminalName = useCallback(
    (uuid: string | null | undefined): string => {
      if (isNil(uuid)) {
        return '';
      }
      return terminals.find((terminal) => terminal.uuid === uuid)?.name ?? '';
    },
    [terminals],
  );

  const getTerminalCode = useCallback(
    (uuid: string | null | undefined): string => {
      if (isNil(uuid)) {
        return '';
      }
      return terminals.find((terminal) => terminal.uuid === uuid)?.code ?? '';
    },
    [terminals],
  );

  const loadTerminals = async () => {
    const res = await getTerminals();
    const terminalsData = res.data?.terminals;
    if (!isNil(terminalsData)) {
      setTerminals(
        terminalsData.slice().sort((a, b) => a?.name?.localeCompare(b?.name)),
      );
    }
  };

  const refetchTerminals = async () => {
    await refetch();
    loadTerminals();
  };

  const filteredTerminals = useMemo(() => {
    return includeInactiveTerminals
      ? terminals
      : terminals.filter((t) => t.isActive);
  }, [terminals, includeInactiveTerminals]);

  return {
    terminals: filteredTerminals,
    loadTerminals,
    terminalsLoading: loading,
    refetchTerminals,
    terminalsEnabled: companyConfiguration?.terminalsEnabled === true,
    getTerminal,
    getTerminalName,
    getTerminalCode,
  };
};

export default useTerminals;
