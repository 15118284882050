import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import type { ParseCsvOrdersOrderUpdateFragment } from '../../../../../../generated/graphql';

type OrderUpdatesTableProps = {
  orders: ParseCsvOrdersOrderUpdateFragment[];
};

export const OrderUpdatesTable: FunctionComponent<OrderUpdatesTableProps> = ({
  orders,
}) => {
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const [expandedOrderUuids, setExpandedOrderUuids] = useState<string[]>([]);

  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableCell sx={{ width: '34px' }} />
        <TableCell
          sx={{
            width: '160px',
          }}
        >
          Contact
        </TableCell>
        <TableCell>HAWB#</TableCell>
      </TableHead>
      {orders.map((order) => (
        <Fragment key={order.shipperBillOfLadingNumber}>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell sx={{ width: '34px' }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setExpandedOrderUuids((prevUuids) => {
                    if (expandedOrderUuids.includes(order.uuid)) {
                      return prevUuids.filter((uuid) => uuid !== order.uuid);
                    }
                    return [...prevUuids, order.uuid];
                  });
                }}
              >
                {expandedOrderUuids.includes(order.uuid) ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              sx={{
                width: '160px',
              }}
            >
              {order.billingPartyContact?.displayName ?? '-'}
            </TableCell>
            <TableCell>{order.shipperBillOfLadingNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse
                in={expandedOrderUuids.includes(order.uuid)}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Packages
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {ffPackageSpecs && <TableCell>Type</TableCell>}
                        <TableCell>Description</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Length</TableCell>
                        <TableCell>Width</TableCell>
                        <TableCell>Height</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.packages.map((pkg) => {
                        return (
                          <TableRow key={pkg.uuid}>
                            {ffPackageSpecs && (
                              <TableCell>
                                {pkg.packageSpec?.name ?? '-'}
                              </TableCell>
                            )}
                            <TableCell>{pkg.description ?? '-'}</TableCell>
                            <TableCell>{pkg.quantity ?? '-'}</TableCell>
                            <TableCell>{pkg.weight ?? '-'}</TableCell>
                            <TableCell>{pkg.length ?? '-'}</TableCell>
                            <TableCell>{pkg.width ?? '-'}</TableCell>
                            <TableCell>{pkg.height ?? '-'}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </Fragment>
      ))}
    </Table>
  );
};
