import { isNil } from 'lodash';
import { startTransition, useEffect, useState } from 'react';
import { ROUTE_STOPS_COLUMN_WIDTHS_LOCAL_STORAGE_KEY } from '../../../common/local-storage/keys';
import { useDispatchViewSettings } from './use-dispatch-view-settings';

export type ColumnWidths = Array<{ width: number | undefined; colId: string }>;

export type RouteCardsColumnWidths = {
  columnWidths: ColumnWidths | undefined;
  setColumnWidths: (columnWidths: ColumnWidths) => void;
};

export const useRouteCardsColumnWidths = () => {
  const { routeCardStopTableColumnWidths } = useDispatchViewSettings();
  const [columnWidths, setColumnWidths] = useState<ColumnWidths | undefined>(
    undefined,
  );
  useEffect(() => {
    const localStorageRouteStopsColumnWidths = localStorage.getItem(
      ROUTE_STOPS_COLUMN_WIDTHS_LOCAL_STORAGE_KEY,
    );
    if (
      !isNil(localStorageRouteStopsColumnWidths) ||
      !isNil(routeCardStopTableColumnWidths)
    ) {
      startTransition(() => {
        setColumnWidths(
          (routeCardStopTableColumnWidths as ColumnWidths) ??
            (JSON.parse(
              localStorageRouteStopsColumnWidths ?? '{}',
            ) as ColumnWidths),
        );
      });
    }
  }, [setColumnWidths, routeCardStopTableColumnWidths]);

  return {
    columnWidths,
    setColumnWidths,
  };
};
