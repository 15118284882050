/* eslint-disable import/prefer-default-export */

export const LOCAL_DATE_OPTION_FILTER_TYPE = 'LOCAL_DATE_OPTION_FILTER_TYPE';
export const LOCAL_DATE_OPTION_VALUE = 'LOCAL_DATE_OPTION_VALUE';
export const LOCAL_DATE_OPTION_START_DATE_ISO_STRING =
  'LOCAL_DATE_OPTION_START_DATE_ISO_STRING';
export const LOCAL_DATE_OPTION_END_DATE_ISO_STRING =
  'LOCAL_DATE_OPTION_END_DATE_ISO_STRING';
export const LOCAL_CUSTOMER_OPTION_LABEL = 'LOCAL_CUSTOMER_OPTION_LABEL';
export const LOCAL_CUSTOMER_OPTION_VALUE = 'LOCAL_CUSTOMER_OPTION_VALUE';
export const LOCAL_STATUS_OPTION = 'LOCAL_STATUS_OPTION';
