import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

type ZoneBasedAccessorialChargeGroupFields = {
  uuid: string;
  name: string;
};

type AccessorialChargeGroupStoreState = ZoneBasedAccessorialChargeGroupFields;

const accessorialsChargeGroupsAdapter =
  createEntityAdapter<AccessorialChargeGroupStoreState>({
    selectId: (chargeGroup) => chargeGroup.uuid,
  });

export const zoneBasedAccessorialChargeGroupsSlice = createSlice({
  name: 'accessorialChargeGroups',
  initialState: accessorialsChargeGroupsAdapter.getInitialState(),
  reducers: {
    addOneAccessorialChargeGroup: accessorialsChargeGroupsAdapter.addOne,
    updateAccessorialChargeGroup: accessorialsChargeGroupsAdapter.updateOne,
    deleteAccessorialChargeGroup: accessorialsChargeGroupsAdapter.removeOne,
    updateAccessorialChargeGroups: accessorialsChargeGroupsAdapter.updateMany,
    setAllAccessorialChargeGroups: accessorialsChargeGroupsAdapter.setAll,
    upsertManyZoneBasedAccessorialChargeGroups:
      accessorialsChargeGroupsAdapter.upsertMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectZoneBasedAccessorialChargeGroupById,
  // Pass in a selector that returns the posts slice of state
} = accessorialsChargeGroupsAdapter.getSelectors(
  (state: RootState) => state.zoneBasedAccessorialChargeGroups,
);

const accessorialChargeGroupsSelector = (state: RootState) =>
  state.zoneBasedAccessorialChargeGroups;

export const selectZoneBasedAccessorialChargeGroupsByIds = createSelector(
  accessorialChargeGroupsSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (
    state: EntityState<AccessorialChargeGroupStoreState>,
    entityIds: EntityId[],
  ) => {
    const entityResults: AccessorialChargeGroupStoreState[] = [];
    entityIds.forEach((id) => {
      const match = accessorialsChargeGroupsAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addOneAccessorialChargeGroup,
  updateAccessorialChargeGroup,
  deleteAccessorialChargeGroup,
  setAllAccessorialChargeGroups,
  upsertManyZoneBasedAccessorialChargeGroups,
} = zoneBasedAccessorialChargeGroupsSlice.actions;

export default zoneBasedAccessorialChargeGroupsSlice.reducer;
