import { css } from 'styled-components';

export const styles = css`
  .background {
    background-color: white;
  }
`;

export const muiStyles = {
  pageContainer: {
    width: '95%',
    margin: '0 auto',
  },
  pageContent: {
    backgroundColor: 'white',
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 3,
  },
  backButton: {
    borderColor: '#000',
    backgroundColor: '#FFF',
    color: '#000',
  },
  buttonRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: '22px',
    button: {
      marginLeft: '10px',
    },
  },
  formContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '22px',
    padding: '0 12px',
  },
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
  },
};
