import { isNil } from 'lodash';
import { useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import useMe from '../../../common/react-hooks/use-me';
import {
  useRoutesWithDriverMobilePhoneCoordinatesQuery,
  useRoutesSamsaraVehicleLocationsQuery,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';

const useRouteCurrentDriverLocation = ({
  routeUuid,
}: {
  routeUuid: string;
}) => {
  const { companyConfiguration } = useMe();
  const [routeUuids] = useDispatchStore(
    (state) => [state.routes.map((r) => r.uuid)],
    shallow,
  );
  const { data: driverMobilePhoneCoordinatesData } =
    useRoutesWithDriverMobilePhoneCoordinatesQuery({
      variables: {
        routeUuids,
      },
    });
  const { data: samsaraCoordinatesData } =
    useRoutesSamsaraVehicleLocationsQuery({
      variables: {
        routeUuids,
      },
    });

  const routesLocationData = useMemo(
    () =>
      filterNotNil(
        routeUuids.map((rUuid) => {
          const driverMobileLocation =
            driverMobilePhoneCoordinatesData?.routes.find(
              (r) => r.uuid === rUuid,
            );
          const samsaraLocation =
            samsaraCoordinatesData?.routesSamsaraVehicleLocations.routeCoordinates.find(
              (location) => location.routeUuid === rUuid,
            );
          if (
            !isNil(driverMobileLocation) &&
            !isNil(driverMobileLocation.currentDriverMobilePhoneLocation) &&
            companyConfiguration?.getDriverLocationDataFromMobile === true
          ) {
            return {
              routeUuid: driverMobileLocation.uuid,
              ...driverMobileLocation.currentDriverMobilePhoneLocation,
            };
          }
          return !isNil(samsaraLocation)
            ? {
                routeUuid: samsaraLocation.routeUuid,
                latitude: samsaraLocation.latitude,
                longitude: samsaraLocation.longitude,
              }
            : null;
        }),
      ),
    [
      samsaraCoordinatesData,
      driverMobilePhoneCoordinatesData,
      companyConfiguration,
      routeUuids,
    ],
  );

  return {
    routeCurrentDriverLocationData: routesLocationData.find(
      (r) => r.routeUuid === routeUuid,
    ),
  };
};

export { useRouteCurrentDriverLocation };
