import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormHelperText,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import {
  ContactItemsItemFragment,
  StorageOrderDocument,
  useUpsertItemGroupMutation,
} from '../../../../../generated/graphql';
import useAssignInventoryItemsForm, {
  AssignInventoryItemFormValues,
} from '../../../forms/inventory-items/use-assign-inventory-items-form';

interface AssignInventoryItemsRowProps {
  storageOrderUuid: string;
  storageUnitUuid: string;

  item: ContactItemsItemFragment;

  onComplete: () => void;

  setUpdateStorageUnitSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;

  setUpdateStorageUnitErrorMessageVisible: Dispatch<SetStateAction<boolean>>;
}

const AssignInventoryItemsRow = ({
  storageOrderUuid,
  storageUnitUuid,
  item,
  onComplete,
  setUpdateStorageUnitSuccessMessageVisible,
  setUpdateStorageUnitErrorMessageVisible,
}: AssignInventoryItemsRowProps) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useAssignInventoryItemsForm();

  const [upsertItemGroup, { loading: upsertItemGroupLoading }] =
    useUpsertItemGroupMutation({
      refetchQueries: [StorageOrderDocument],
      awaitRefetchQueries: true,
    });

  const onSubmit: SubmitHandler<AssignInventoryItemFormValues> = async (
    data,
  ) => {
    const { quantity } = data;
    try {
      await upsertItemGroup({
        variables: {
          upsertItemGroupInput: {
            storageOrderUuid,
            itemUuid: item.uuid,
            storageUnitUuid,
            unitOfMeasure: item.primaryUOM.unitOfMeasure,
            incrementQuantityBy: quantity,
          },
        },
      });
      setUpdateStorageUnitSuccessMessageVisible(true);
      onComplete();
    } catch (e) {
      setUpdateStorageUnitErrorMessageVisible(true);
    }
  };

  const handleCancel = () => {
    reset();
    onComplete();
  };

  return (
    <TableRow>
      <TableCell>{item.sku}</TableCell>
      <TableCell>{item.description ?? '-'}</TableCell>
      <TableCell>{sentenceCase(item.primaryUOM.unitOfMeasure)}</TableCell>
      <TableCell>
        <Controller
          name="quantity"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <TextField
                name="quantity"
                type="number"
                size="small"
                value={value}
                required
                onChange={onChange}
                error={!isNil(errors.quantity)}
                sx={{ width: '100%' }}
              />
              {!isNil(errors.quantity) && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.quantity?.message?.toString() ?? ''}
                </FormHelperText>
              )}
            </>
          )}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <IconButton
            onClick={handleSubmit(onSubmit)}
            disabled={upsertItemGroupLoading}
            color="primary"
          >
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={handleCancel}
            disabled={upsertItemGroupLoading}
            color="primary"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default AssignInventoryItemsRow;
