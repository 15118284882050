import { TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { InvoiceEdiTransactionFragment } from '../../../../../../generated/graphql';
import EdiTransactionStatusChip from './edi-transaction-status-chip';

export type EdiInvoiceRowProps = {
  ediTransaction: InvoiceEdiTransactionFragment;
};
const EdiInvoiceRow = ({ ediTransaction }: EdiInvoiceRowProps) => {
  return (
    <TableRow>
      <TableCell>
        {dayjs(ediTransaction.createdAt).format('MM/DD/YY hh:mm a')}{' '}
      </TableCell>
      <TableCell>
        <EdiTransactionStatusChip status={ediTransaction.status} />
      </TableCell>
      <TableCell>{ediTransaction.order?.name ?? '-'}</TableCell>
      <TableCell>
        {ediTransaction.order?.standardOrderFields?.shipperBillOfLadingNumber ??
          '-'}
      </TableCell>
      <TableCell>{ediTransaction.order?.secondaryRefNumber ?? '-'}</TableCell>
    </TableRow>
  );
};

export default EdiInvoiceRow;
