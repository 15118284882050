import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  ControllerRenderProps,
  FieldErrors,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import useContacts from '../../../../../common/react-hooks/use-contacts';
import { useContactForAutocompleteLazyQuery } from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { OrderFormValues } from '../forms/types';

export type ContactAutocompleteFieldValues = FieldValues & {
  contactUuid: string;
};

type ContactAutocompleteComponentProps<
  T extends ContactAutocompleteFieldValues,
  TName extends FieldPath<T>,
> = {
  contactUuid: string | null;
  autoFocus?: boolean;
  disabled: boolean;
  field: ControllerRenderProps<T, TName>;
  errors: FieldErrors;
};

function OrderFormContactAutocompleteComponent<
  T extends ContactAutocompleteFieldValues,
  TName extends FieldPath<T>,
>({
  contactUuid,
  autoFocus,
  disabled,
  field,
  errors,
}: ContactAutocompleteComponentProps<T, TName>) {
  const theme = useTheme();
  const { setValue } = useFormContext<OrderFormValues>();
  const [getContact] = useContactForAutocompleteLazyQuery();
  const { contacts, getContactName } = useContacts();
  const [contactsToUse, setContactsToUse] = useState(contacts);
  const [isPrepaidOnly, setIsPrepaidOnly] = useState<boolean>(false);

  const contactOptions = useMemo(() => {
    return (contactsToUse ?? [])
      .map((contact) => ({
        value: contact.uuid,
        label: contact.displayName,
      }))
      .slice()
      .sort((a, b) => a.label?.localeCompare(b.label));
  }, [contactsToUse]);

  useEffect(() => {
    const fetchContact = async () => {
      if (isNil(contactUuid)) return;
      // this is for the case where the existing uuid corresponds to an archived contact
      const { data: contactData } = await getContact({
        variables: {
          uuid: contactUuid,
        },
      });

      setIsPrepaidOnly(
        contactData?.contact.__typename === 'CustomerContactEntity' &&
          contactData.contact.isPrepaidOnly,
      );

      if (
        isNil(contactUuid) ||
        contacts.length === 0 ||
        !isNil(contacts.find((c) => c.uuid === contactUuid))
      ) {
        setContactsToUse(contacts);
        return;
      }

      if (contactData?.contact.isActive === false) {
        setContactsToUse([...contacts, contactData.contact]);
      }
    };
    fetchContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts.length, contactUuid]);

  return (
    <FormControl
      sx={{
        flexGrow: 1,
        maxWidth: '380px',
      }}
    >
      <AutocompleteFuzzy
        {...field}
        autoHighlight
        size="small"
        value={
          !isNil(contactUuid)
            ? {
                value: contactUuid,
                label: getContactName(contactUuid) ?? '',
              }
            : null
        }
        matchSortOptions={{ keys: ['label'] }}
        options={contactOptions}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            label={
              <>
                Customer
                {isPrepaidOnly && (
                  <Typography
                    component="span"
                    color={theme.palette.paleGreen.main}
                  >
                    {' '}
                    (Prepaid)
                  </Typography>
                )}
              </>
            }
            sx={{ width: '100%' }}
            error={!isNil(errors.contactUuid)}
          />
        )}
        onChange={(_event, option) => {
          field.onChange(option?.value ?? '');
          setValue('contactStationId', null);
        }}
        disabled={disabled}
      />
      {!isNil(errors.contactUuid?.message) && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          {errors.contactUuid?.message?.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default OrderFormContactAutocompleteComponent;
