import { Box, Button, TableCell, TableRow } from '@mui/material';
import { Fragment, FunctionComponent } from 'react';
import { AccessorialsForCustomChargeRowQuery } from '../../../../../../../generated/graphql';

const styles = {
  container: {
    backgroundColor: 'rgb(255, 243, 224, 0.5)',
    borderRadius: '8px',
    padding: '8px 16px',
    width: 'fit-content',
    // marginBottom: '12px',
  },
  header: {
    display: 'inline-block',
    lineHeight: '17px',
    color: 'rgb(0, 0, 0, 0.65)',
  },
  accessorialButton: {
    display: 'inline-block',
    fontWeight: 500,
    color: 'rgb(0, 0, 0, 0.65)',
    padding: 0,
    lineHeight: '17px',
  },
};

type QuickAddAccessorialsRowProps = {
  // A non-empty array of accessorials to display.
  quickAddAccessorials: AccessorialsForCustomChargeRowQuery['accessorialsByBillingContact'];
  onAddCharge: (accessorialOption: { value: string; label: string }) => void;
};

export const QuickAddAccessorialsRow: FunctionComponent<
  QuickAddAccessorialsRowProps
> = ({ quickAddAccessorials, onAddCharge }) => (
  <TableRow>
    <TableCell colSpan={5}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>Click to add:</Box>{' '}
        {quickAddAccessorials.map(({ uuid, name }, index) => (
          <Fragment key={uuid}>
            <Button
              variant="text"
              sx={styles.accessorialButton}
              onClick={() =>
                onAddCharge({
                  value: uuid,
                  label: name,
                })
              }
            >
              {name}
            </Button>
            {index < quickAddAccessorials.length - 1 && ', '}
          </Fragment>
        ))}
      </Box>
    </TableCell>
  </TableRow>
);
