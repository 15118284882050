export enum OrdersTab {
  Incomplete = 'Incomplete',
  NotScheduledOrders = 'Not scheduled',
  Scheduled = 'Scheduled',
  OutForDelivery = 'Delivery today',
  Complete = 'Complete',
  AttemptedOrders = 'Attempted',
  OnHold = 'On hold',
  AllOrders = 'All',
}
