import { IconButton, Tooltip } from '@mui/material';
import { ColumnApi } from 'ag-grid-community';
import { isNil } from 'lodash';
import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import WidthArrows from '../../common/icons/width-arrows.svg?react';
import { ORDERS_TABLE_COLUMN_WIDTHS_LOCAL_STORAGE_KEY } from '../../common/local-storage/keys';

interface ResetGridColumnWidthsButtonProps {
  columnApi: ColumnApi;
}

const ResetGridColumnWidthsButton = ({
  columnApi,
}: ResetGridColumnWidthsButtonProps) => {
  const [, setColumnWidths] = useLocalStorageState<
    { width: number | undefined; colId: string }[] | undefined
  >(ORDERS_TABLE_COLUMN_WIDTHS_LOCAL_STORAGE_KEY, {
    defaultValue: [],
  });
  const autoSizeAllColumns = () => {
    columnApi.autoSizeAllColumns();

    const columnState = columnApi.getColumnState();
    if (isNil(columnState)) return;
    setColumnWidths(
      columnState.map((column) => ({
        width: column.width,
        colId: column.colId,
      })),
    );
  };

  return (
    <Tooltip title="Resize columns" placement="top" arrow>
      <IconButton onClick={autoSizeAllColumns} size="small">
        <WidthArrows fill="#757575" />
      </IconButton>
    </Tooltip>
  );
};

export default ResetGridColumnWidthsButton;
