import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { filterNotNil } from 'shared/array';
import {
  AddressEntity,
  ContactPersonFragment,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type AddressFormField = Partial<
  Omit<AddressEntity, 'type' | '__typename' | 'associatedContact'>
> & {
  isLocal: boolean;
  isFreeForm?: boolean;
  uuid: string;
  associatedContact?: ContactPersonFragment | null;
};

type AddressValuesStoreState = AddressFormField;

type AddressSchema = { [k in keyof Required<AddressFormField>]: boolean };

/**
 * An object which declares all the possible fields in an address, so we can iterate over them.
 *
 * The actual value of each field is meaningless
 */
export const ADDRESS_SCHEMA: { [k in keyof Required<AddressFormField>]: null } =
  {
    city: null,
    country: null,
    createdAt: null,
    driverInstructions: null,
    latitude: null,
    line1: null,
    line2: null,
    longitude: null,
    name: null,
    receivingHoursEnd: null,
    receivingHoursStart: null,
    state: null,
    updatedAt: null,
    uuid: null,
    zip: null,
    isLocal: null,
    isFreeForm: null,
    preventCoordRecompute: null,
    specialInstructions: null,
    associatedContact: null,
    internalNotes: null,
    formattedAddress: null,
    iataCode: null,
  };

export const addressChargeFields: Partial<AddressSchema> = {
  city: true,
  line1: true,
  line2: false,
  zip: false,
};

const addressesValuesAdapter = createEntityAdapter<AddressValuesStoreState>({
  selectId: (address) => address.uuid,
});

export const addressesValuesSlice = createSlice({
  name: 'addressesValues',
  initialState: addressesValuesAdapter.getInitialState(),
  reducers: {
    addAddress: addressesValuesAdapter.addOne,
    updateAddress: addressesValuesAdapter.updateOne,
    removeOneAddress: addressesValuesAdapter.removeOne,
    setAllAddresses: addressesValuesAdapter.setAll,
    updateAddresses: addressesValuesAdapter.updateMany,
    upsertAddress: addressesValuesAdapter.upsertOne,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectAddressById,
  selectEntities: selectAddressEntities,
  selectIds: selectAddressIds,
  selectAll: selectAddresses,
  // Pass in a selector that returns the posts slice of state
} = addressesValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormAddressesValues,
);

export const selectAddressesByIds = createSelector(
  selectAddressEntities,
  (state: RootState, ids: string[]) => ids,
  (entities, ids) => filterNotNil(ids.map((id) => entities[id])),
);

export const selectAddressNamesByIds = createSelector(
  selectAddressEntities,
  (state: RootState, ids: string[]) => ids,
  (entities, ids) => ids.map((id) => entities[id]?.name),
);

export const selectAddressZipcode = createSelector(
  selectAddressById,
  (values: AddressFormField | undefined) => {
    return values?.zip;
  },
);

export const selectAddressName = createSelector(
  selectAddressById,
  (values: AddressFormField | undefined) => {
    return values?.name;
  },
);

export const selectAddressCity = createSelector(
  selectAddressById,
  (values: AddressFormField | undefined) => {
    return values?.city;
  },
);

export const {
  addAddress,
  removeOneAddress,
  setAllAddresses,
  updateAddress,
  upsertAddress,
} = addressesValuesSlice.actions;

export default addressesValuesSlice.reducer;
