import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface CannotCompleteOrderModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;

  message?: string;

  setMessage: Dispatch<SetStateAction<string | undefined>>;
}

const CannotCompleteOrderModal = ({
  open,
  setOpen,
  message,
  setMessage,
}: CannotCompleteOrderModalProps) => {
  const handleClose = () => {
    setOpen(false);
    setMessage('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cannot complete order</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CannotCompleteOrderModal;
