/* eslint-disable  @typescript-eslint/no-explicit-any */
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/naming-convention
import _, { isEmpty, isNil } from 'lodash';
import { ReactNode } from 'react';
import {
  EdiTransactionCode,
  OrderEventSourceType,
  OrderEventTargetType,
  OrderEventType,
} from '../../../../../../generated/graphql';
import PalletRouterLink from '../../../../../../pallet-ui/links/router-link/pallet-router-link';
import PicklistTableForAuditLog from '../../../../../inventory/components/picklist-table-for-audit-log';
import { OrderEvent } from './types';

interface SnapshotField {
  path: string;
  field: string;
  dataPath?: string[] | string;
  doNotShowUpdateResult?: boolean;
  isDayTime?: boolean;
  isDay?: boolean;
  isTime?: boolean;
  noCase?: boolean;
}

export const defaultBOLName = 'Shipper Bill Of Lading Number';
export const defaultSecondaryRefName = 'Secondary Ref Number';

const orderSnapshotFields: SnapshotField[] = [
  {
    path: 'billingPartyContact.displayName',
    field: 'Contact',
    noCase: true,
  },
  {
    path: 'detailedStatus',
    field: 'Order Status',
  },
  {
    path: 'name',
    field: 'Order Name',
    noCase: true,
  },
  {
    path: 'warehouse.name', // going from defined to other defined
    field: 'Warehouse',
  },
  {
    path: 'standardOrderFields.shipperBillOfLadingNumber',
    field: defaultBOLName,
    noCase: true,
  },
  {
    path: 'standardOrderFields.masterAirwayBillOfLadingNumber',
    field: 'MAWB',
    noCase: true,
  },
  {
    path: 'secondaryRefNumber',
    field: defaultSecondaryRefName,
    noCase: true,
  },
  {
    path: 'piecesPicked',
    field: 'Pieces Picked',
  },
  {
    path: 'receivedDate',
    field: 'Received Date',
    isDay: true,
  },
  {
    path: 'notes',
    field: 'Notes',
    noCase: true,
  },
];

const shipmentSnapshotFields: SnapshotField[] = [
  {
    path: 'fields.pickupOrDelivery',
    field: 'Stop Type',
  },
  {
    path: 'legs[0].endStop.status',
    field: 'Stop Status',
  },
  {
    path: 'fields.service',
    field: 'Service',
    dataPath: 'fields.service.name',
  },
  {
    path: 'fields.service.name',
    field: 'Service',
    dataPath: 'fields.service.name',
  },
  {
    path: 'fields.inboundMethod',
    field: 'Inbound Method',
  },
  {
    path: 'legs[0].endStop.inboundMethod',
    field: 'Inbound Method',
  },
  {
    path: 'airportInfo.terminal',
    field: 'Airport Terminal',
    noCase: true,
  },
  {
    path: 'legs[0].endStop.terminal.name',
    field: 'Terminal',
    noCase: true,
  },
  {
    path: 'legs[0].endStop.expectedOutboundDate',
    field: 'Expected Outbound Arrival Date',
    isDay: true,
  },
  {
    path: 'legs[0].endStop.expectedInboundArrivalDate',
    field: 'Expected Inbound Arrival Date',
    isDay: true,
  },
  {
    path: 'fields.outboundMethod',
    field: 'Outbound Method',
  },
  {
    path: 'legs[0].endStop.outboundMethod',
    field: 'Outbound Method',
  },
  {
    path: 'legs[0].endStop.destinationAirport',
    field: 'Destination Airport',
    noCase: true,
  },
  {
    path: 'legs[0].endStop.incomingCarrier',
    field: 'Incoming Carrier',
    noCase: true,
  },
  {
    path: 'fields.deadlineType',
    field: 'Deadline Type',
  },
  {
    path: 'fields.deadlineDate',
    field: 'Deadline Date',
    isDay: true,
  },
  {
    path: 'fields.deliveryDate',
    field: 'Appointment Date',
    isDay: true,
  },
  {
    path: 'legs[0].endStop.appointmentTime',
    field: 'Start Appointment',
    isTime: true,
  },
  {
    path: 'legs[0].endStop.endAppointmentTime',
    field: 'End Appointment',
    isTime: true,
  },
  {
    path: 'legs[0].endStop.proofOfDeliverySignee',
    field: 'POD Name',
  },
  {
    path: 'legs[0].endStop.completedAt',
    field: 'Completed At',
    isDayTime: true,
  },
  {
    path: 'legs[0].endStop.contactPerson',
    field: 'Contact Person',
    dataPath: [
      'legs[0].endStop.contactPerson.firstName',
      'legs[0].endStop.contactPerson.lastName',
      'legs[0].endStop.contactPerson.phone',
    ],
    noCase: true,
  },
  {
    path: 'legs[0].endStop.specialInstructions',
    field: 'Special Instructions',
    noCase: true,
  },
];

export enum SnapshotMethod {
  ADD = 'ADD',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export interface SnapshotDifference {
  method: SnapshotMethod;
  field: string;
  value?: string | number;
  noCase?: boolean;
}

export interface ShipmentSnapshotDifferenceData {
  uuid: string;
  stopType: string | undefined;
  differences: SnapshotDifference[];
}

export interface SnapshotDifferenceData {
  order: SnapshotDifference[];
  shipments: ShipmentSnapshotDifferenceData[];
}

const isObject = (v: any) => !isNil(v) && typeof v === 'object';

const getDifferenceMap = (a: any, b: any): object | boolean => {
  if (isEmpty(a) && isEmpty(b)) {
    return true;
  }
  return Object.assign(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...Array.from(new Set([...Object.keys(a), ...Object.keys(b)]), (k) => ({
      [k]:
        isObject(a[k]) && isObject(b[k])
          ? getDifferenceMap(a[k], b[k])
          : a[k] === b[k],
    })),
  );
};

const addSnapshotDifference = (
  differences: SnapshotDifference[],
  method: SnapshotMethod,
  field: string,
  value: string,
  snapshotField?: SnapshotField,
  ignoreCasing?: boolean,
) => {
  differences.push({
    method,
    field,
    value,
    noCase:
      snapshotField?.isTime === true ||
      snapshotField?.isDay === true ||
      snapshotField?.isDayTime === true ||
      snapshotField?.noCase === true ||
      ignoreCasing === true,
  });
};

const getStringOrEmpty = (string: string) => {
  if (string === 'null') {
    return '';
  }
  return string;
};

const parsePackageDifferences = (
  differences: SnapshotDifference[],
  previousSnapshotData: any,
  snapshotData: any,
  differenceMap: any,
) => {
  const packageValueString = snapshotData?.packages
    ?.map(
      (pkg: any) =>
        `${getStringOrEmpty(pkg?.quantity)}@${getStringOrEmpty(
          pkg?.weight,
        )}lbs, ${getStringOrEmpty(pkg?.length)}x${getStringOrEmpty(
          pkg?.width,
        )}x${getStringOrEmpty(pkg?.height)}`,
    )
    .join('\n');
  if (snapshotData?.packages?.length > previousSnapshotData?.packages?.length) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.ADD,
      'Package(s)',
      packageValueString,
      undefined,
      true,
    );
  } else if (
    snapshotData?.packages?.length < previousSnapshotData?.packages?.length
  ) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.DELETE,
      'Package(s)',
      packageValueString,
      undefined,
      true,
    );
  } else if (
    Object.values(differenceMap?.packages ?? {})?.some(
      (pkg: any) => pkg !== false && Object.values(pkg).includes(false),
    ) === true
  ) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.UPDATE,
      'Package(s)',
      packageValueString,
      undefined,
      true,
    );
  }
};

const parseTagsDifferences = (
  differences: SnapshotDifference[],
  previousSnapshotData: any,
  snapshotData: any,
  differenceMap: any,
) => {
  const tagValueString = snapshotData?.tags
    ?.map((tag: any) => tag?.value)
    .join(', ');
  if (snapshotData?.tags?.length > previousSnapshotData?.tags?.length) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.ADD,
      'Tag(s)',
      tagValueString,
      undefined,
      true,
    );
  } else if (snapshotData?.tags?.length < previousSnapshotData?.tags?.length) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.DELETE,
      'Tag(s)',
      tagValueString,
      undefined,
      true,
    );
  } else if (
    Object.values(differenceMap?.tags ?? {})?.some(
      (tag: any) => tag !== false && Object.values(tag).includes(false),
    ) === true
  ) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.UPDATE,
      'Tag(s)',
      tagValueString,
      undefined,
      true,
    );
  }
};

const parseShipmentDifferences = (
  differences: SnapshotDifference[],
  previousSnapshotData: any,
  snapshotData: any,
) => {
  if (
    snapshotData?.shipments?.length > previousSnapshotData?.shipments?.length
  ) {
    const previousShipmentUuid = previousSnapshotData?.shipments[0]?.uuid;
    const addedShipment = snapshotData?.shipments.find(
      (shipment: any) => shipment.uuid !== previousShipmentUuid,
    );
    addSnapshotDifference(
      differences,
      SnapshotMethod.ADD,
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      `${sentenceCase(addedShipment?.fields?.pickupOrDelivery ?? '')} Shipment`,
      '',
    );
  } else if (
    snapshotData?.shipments?.length < previousSnapshotData?.shipments?.length
  ) {
    const currentShipmentUuid = snapshotData?.shipments[0]?.uuid;
    const deletedShipment = previousSnapshotData?.shipments.find(
      (shipment: any) => shipment.uuid !== currentShipmentUuid,
    );
    addSnapshotDifference(
      differences,
      SnapshotMethod.DELETE,
      `${sentenceCase(
        // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
        deletedShipment?.fields?.pickupOrDelivery ?? '',
      )} Shipment`,
      '',
    );
  }
};

const parseDocumentDifferences = (
  differences: SnapshotDifference[],
  previousDocuments: any,
  documents: any,
) => {
  if (isNil(previousDocuments) || isNil(documents)) return;
  if (documents?.length > previousDocuments?.length) {
    addSnapshotDifference(differences, SnapshotMethod.ADD, 'Document(s)', '');
  } else if (documents?.length < previousDocuments?.length) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.DELETE,
      'Document(s)',
      '',
    );
  }
};

const parseFreightChargeDifferences = (
  differences: SnapshotDifference[],
  previousFreightCharge: any,
  freightCharge: any,
  shipmentDifferenceMap: any,
) => {
  if (
    Object.values(shipmentDifferenceMap?.freightCharge ?? {})?.some(
      (charges: any) =>
        (charges !== false && Object.values(charges).includes(false)) ||
        charges === false,
    ) === true
  ) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.UPDATE,
      'Freight Charge(s)',
      `Rate: $${freightCharge?.rate}`,
      undefined,
      true,
    );
  }
};

const isSameCustomCharges = (
  previousCustomCharges: any,
  customCharges: any,
) => {
  if (isNil(previousCustomCharges) || isNil(customCharges)) return true;
  const customChargesString = customCharges
    ?.sort(
      (a: any, b: any) =>
        // eslint-disable-next-line
        a.name?.localeCompare(b.name) ||
        // eslint-disable-next-line
        a.description?.localeCompare(b.description) ||
        // eslint-disable-next-line
        a.billingMethod?.localeCompare(b.billingMethod) ||
        // eslint-disable-next-line
        a.rate - b.rate ||
        // eslint-disable-next-line
        a.quantity - b.quantity,
    )
    .map(
      (customCharge: any) =>
        `${customCharge.name},${customCharge.description},${customCharge.billingMethod},${customCharge.rate},${customCharge.quantity}`,
    )
    .join('|');

  const previousCustomChargesString = previousCustomCharges
    ?.sort(
      (a: any, b: any) =>
        // eslint-disable-next-line
        a.name?.localeCompare(b.name) ||
        // eslint-disable-next-line
        a.description?.localeCompare(b.description) ||
        // eslint-disable-next-line
        a.billingMethod?.localeCompare(b.billingMethod) ||
        // eslint-disable-next-line
        a.rate - b.rate ||
        // eslint-disable-next-line
        a.quantity - b.quantity,
    )
    .map(
      (customCharge: any) =>
        `${customCharge.name},${customCharge.description},${customCharge.billingMethod},${customCharge.rate},${customCharge.quantity}`,
    )
    .join('|');

  return customChargesString === previousCustomChargesString;
};

const parseCustomChargeDifferences = (
  differences: SnapshotDifference[],
  previousCustomCharges: any,
  customCharges: any,
  shipmentDifferenceMap: any,
) => {
  const customChargeValueString = customCharges
    .map((customCharge: any) => customCharge?.accessorialTemplate?.name)
    .join(', ');
  if (customCharges?.length > previousCustomCharges?.length) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.ADD,
      'Custom Charge(s)',
      customChargeValueString,
      undefined,
      true,
    );
  } else if (customCharges?.length < previousCustomCharges?.length) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.DELETE,
      'Custom Charge(s)',
      customChargeValueString,
      undefined,
      true,
    );
  } else if (
    Object.values(shipmentDifferenceMap?.customCharges ?? {})?.some(
      (charges: any) =>
        charges !== false && Object.values(charges).includes(false),
    ) === true &&
    !isSameCustomCharges(previousCustomCharges, customCharges)
  ) {
    addSnapshotDifference(
      differences,
      SnapshotMethod.UPDATE,
      'Custom Charge(s)',
      customChargeValueString,
      undefined,
      true,
    );
  }
};

const getNewValue = (snapshotField: SnapshotField, data: any) => {
  let newValue: string =
    snapshotField.doNotShowUpdateResult === true
      ? ''
      : _.get(data, snapshotField.path);
  if (!isNil(snapshotField.dataPath)) {
    if (typeof snapshotField.dataPath !== 'string') {
      newValue = snapshotField.dataPath
        .map((path) => _.get(data, path))
        .join(' ');
    } else {
      newValue = _.get(data, snapshotField.dataPath);
    }
  }

  if (snapshotField.isTime === true) {
    return newValue !== 'null' ? dayjs(newValue).format('HH:mm') : '';
  }
  if (snapshotField.isDay === true) {
    return newValue !== 'null' ? dayjs(newValue).format('MM/DD') : '';
  }
  if (snapshotField.isDayTime === true) {
    return newValue !== 'null'
      ? dayjs(newValue).format('MM/DD/YY hh:mm a')
      : '';
  }

  return newValue;
};

const parseDifferences = (
  differenceMap: any,
  previousSnapshotData: any,
  snapshotData: any,
): SnapshotDifferenceData => {
  if (
    isNil(differenceMap) ||
    isNil(previousSnapshotData) ||
    isNil(snapshotData)
  ) {
    return { order: [], shipments: [] };
  }
  const orderDifferences: SnapshotDifference[] = [];
  orderSnapshotFields.forEach((snapshotField) => {
    const unchanged = _.get(differenceMap, snapshotField.path);
    if (unchanged === false) {
      addSnapshotDifference(
        orderDifferences,
        SnapshotMethod.UPDATE,
        snapshotField.field,
        getNewValue(snapshotField, snapshotData),
        snapshotField,
      );
    }
  });
  // warehouse edge case of if it goes from undefined to defined
  if (differenceMap.warehouse === false) {
    addSnapshotDifference(
      orderDifferences,
      SnapshotMethod.UPDATE,
      'Warehouse',
      snapshotData.warehouse?.name,
      undefined,
      true,
    );
  }

  parsePackageDifferences(
    orderDifferences,
    previousSnapshotData,
    snapshotData,
    differenceMap,
  );
  parseTagsDifferences(
    orderDifferences,
    previousSnapshotData,
    snapshotData,
    differenceMap,
  );
  parseShipmentDifferences(
    orderDifferences,
    previousSnapshotData,
    snapshotData,
  );

  const shipmentDifferences: ShipmentSnapshotDifferenceData[] = [];
  snapshotData.shipments.forEach((shipment: any, i: number) => {
    const shipmentDifferenceData: ShipmentSnapshotDifferenceData = {
      uuid: shipment?.uuid,
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      stopType: shipment?.fields?.pickupOrDelivery,
      differences: [],
    };

    parseDocumentDifferences(
      shipmentDifferenceData.differences,
      previousSnapshotData.shipments?.[i]?.documents,
      shipment?.documents,
    );
    parseFreightChargeDifferences(
      shipmentDifferenceData.differences,
      previousSnapshotData.shipments?.[i]?.freightCharge,
      shipment?.freightCharge,
      differenceMap.shipments?.[i],
    );
    parseCustomChargeDifferences(
      shipmentDifferenceData.differences,
      previousSnapshotData.shipments?.[i]?.customCharges,
      shipment?.customCharges,
      differenceMap.shipments?.[i],
    );

    shipmentSnapshotFields.forEach((snapshotField) => {
      const unchanged = _.get(differenceMap.shipments?.[i], snapshotField.path);
      if (unchanged === false) {
        const newValue: string = getNewValue(
          snapshotField,
          snapshotData.shipments?.[i],
        );
        addSnapshotDifference(
          shipmentDifferenceData.differences,
          isNil(newValue) ? SnapshotMethod.DELETE : SnapshotMethod.UPDATE,
          snapshotField.field,
          newValue ?? '',
          snapshotField,
          snapshotField.noCase,
        );
      }
    });
    shipmentDifferences.push(shipmentDifferenceData);
  });

  return { order: orderDifferences, shipments: shipmentDifferences };
};

export const getSnapshotDifferences = (
  previous: string,
  current: string,
): SnapshotDifferenceData | null => {
  let previousSnapshotData;
  let currentSnapshotData;
  try {
    previousSnapshotData = JSON.parse(previous.replaceAll('null', '"null"'));
    currentSnapshotData = JSON.parse(current.replaceAll('null', '"null"'));
  } catch (e) {
    console.error('Error parsing snapshot data', e);
  }
  if (!isNil(previousSnapshotData) && !isNil(currentSnapshotData)) {
    const differenceMap = getDifferenceMap(
      previousSnapshotData,
      currentSnapshotData,
    );
    return parseDifferences(
      differenceMap,
      previousSnapshotData,
      currentSnapshotData,
    );
  }
  return null;
};

const formatTextMessage = ({
  message,
  name,
  phone,
}: {
  message: string;
  name: string | null | undefined;
  phone: string | null | undefined;
}): string => {
  const isNameEmpty = isNil(name) || isEmpty(name);
  const isPhoneEmpty = isNil(phone) || isEmpty(phone);
  if (isNameEmpty && isPhoneEmpty) {
    return message;
  }
  if (isNameEmpty) {
    return `Sent to ${phone}: ${message} `;
  }
  if (isPhoneEmpty) {
    return `Sent to ${name}: ${message} `;
  }
  return `Sent to ${name} / ${phone}: ${message} `;
};

const formatName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) => {
  if (
    (isNil(firstName) && isNil(lastName)) ||
    (isEmpty(firstName) && isEmpty(lastName))
  ) {
    return '';
  }

  return `${firstName ?? ''} ${lastName ?? ''}`.trim();
};

const mapEDITransactionCodeToEvent = (code: EdiTransactionCode | undefined) => {
  // eslint-disable-next-line default-case
  switch (code) {
    case EdiTransactionCode.MotorCarrierLoadTender:
      return 'Received Load Tender (204)';
    case EdiTransactionCode.MotorCarrierBillOfLading:
      return 'Received Bill of Lading (211)';
    case EdiTransactionCode.ResponseToALoadTender:
      return 'Sent Response to Load Tender (990)';
    case EdiTransactionCode.TransportationCarrierShipmentStatusMessage:
      return 'Sent Shipment Status Update (214)';
    case EdiTransactionCode.MotorCarrierFreightDetailsAndInvoice:
      return 'Sent Invoice (210)';
    case undefined:
    default:
      return 'Unknown Transaction';
  }
};

export const formatOrderEventActor = (orderEvent: OrderEvent): string => {
  switch (orderEvent.sourceType) {
    case OrderEventSourceType.System:
      return 'Automated';
    case OrderEventSourceType.Driver: {
      const driver = orderEvent.sourceDriver;
      return !isNil(driver)
        ? `Driver: ${driver.firstName} ${driver.lastName}`
        : '';
    }
    case OrderEventSourceType.EdiTransaction:
      return 'EDI';
    case OrderEventSourceType.User: {
      return orderEvent.sourceUser?.email ?? '';
    }
    case OrderEventSourceType.WarehouseEmployee: {
      if (isNil(orderEvent.sourceWarehouseEmployee))
        return 'Warehouse employee';
      return `${orderEvent.sourceWarehouseEmployee.firstName} ${orderEvent.sourceWarehouseEmployee.lastName}`;
    }
    case OrderEventSourceType.Picklist:
      return `Picklist ${orderEvent.sourcePicklist?.outboundReferenceNumber ?? ''}`;
    default:
      return '';
  }
};

export const renderOrderEventMessage = (
  orderEvent: OrderEvent,
): string | ReactNode => {
  switch (orderEvent.eventType) {
    case OrderEventType.StopAppointmentSchedulingOptionsTextMessage:
    case OrderEventType.TextMessage: {
      switch (orderEvent.targetType) {
        case OrderEventTargetType.ContactPerson: {
          const name = formatName(
            orderEvent.targetContactPerson?.firstName,
            orderEvent.targetContactPerson?.lastName,
          );
          const phone = orderEvent.targetContactPerson?.phone;
          return formatTextMessage({
            message: orderEvent.message,
            name,
            phone,
          });
        }
        case OrderEventTargetType.Document: {
          return '';
        }
        case OrderEventTargetType.Driver: {
          const name = formatName(
            orderEvent.targetDriver?.firstName,
            orderEvent.targetDriver?.lastName,
          );
          const phone = orderEvent.targetDriver?.phoneNumber;
          return formatTextMessage({
            message: orderEvent.message,
            name,
            phone,
          });
        }
        case OrderEventTargetType.ShipmentSubscriber: {
          const phone = orderEvent.targetShipmentSubscriber?.phoneNumber;
          return formatTextMessage({
            message: orderEvent.message,
            name: null,
            phone,
          });
        }
        case OrderEventTargetType.Order:
        case OrderEventTargetType.Invoice:
        case OrderEventTargetType.ShipmentAndInvoice:
        case OrderEventTargetType.Stop:
        case OrderEventTargetType.StopAndRoute:
        case OrderEventTargetType.StorageOrder:
        default:
          return orderEvent.message;
      }
    }
    case OrderEventType.Edi:
      return mapEDITransactionCodeToEvent(
        orderEvent.sourceEdiTransaction?.code,
      );
    case OrderEventType.DocumentUploaded:
      return orderEvent.targetDocument?.fileName ?? '';
    case OrderEventType.QuoteApproved: {
      const quote = orderEvent.order?.quote;
      if (isNil(quote)) return '';
      const { uuid, number } = quote;
      return (
        <PalletRouterLink to={`/orders/quotes/${uuid}`}>
          {number}
        </PalletRouterLink>
      );
    }
    case OrderEventType.ShipmentAddedToInvoice:
      return orderEvent.targetInvoice?.name ?? '';
    case OrderEventType.StopAddedToRoute:
      return orderEvent.targetRoute?.name ?? '';
    case OrderEventType.StopRemovedFromRoute:
      return orderEvent.targetRoute?.name ?? '';
    case OrderEventType.OrderPicked: {
      const driver = orderEvent.sourceDriver;
      return !isNil(driver)
        ? `Driver: ${driver.firstName} ${driver.lastName}`
        : '';
    }
    case OrderEventType.StorageOrderInventoryDeduction:
      if (isNil(orderEvent.sourcePicklist)) return '';
      return (
        <PicklistTableForAuditLog
          picklistItemGroups={orderEvent.sourcePicklist.picklistItemGroups}
        />
      );
    case OrderEventType.NextStopPendingNotificationEmail:
    case OrderEventType.StopCompletedNotificationEmail:
    case OrderEventType.OrderCreatedNotificationEmail:
      return !isNil(orderEvent.targetEmail)
        ? `Email sent to ${orderEvent.targetEmail}`
        : '';
    case OrderEventType.NextStopPendingNotificationText:
    case OrderEventType.StopCompletedNotificationText:
    case OrderEventType.OrderCreatedNotificationText:
      return !isNil(orderEvent.targetPhoneNumber)
        ? `Text sent to ${orderEvent.targetPhoneNumber}`
        : '';
    case OrderEventType.OrderScanned:
    case OrderEventType.PieceScanned:
    case OrderEventType.OrderPiecesLoadedUpdated:
    case OrderEventType.OrderUpdateEmailSent:
      return orderEvent.message;
    case OrderEventType.ApiOrderApproved:
    case OrderEventType.EdiOrderApproved:
    case OrderEventType.OrderHasIssue:
    case OrderEventType.OrderLoaded:
    case OrderEventType.OrderMarkedNotOsd:
    case OrderEventType.OrderMarkedNotReceivedAtOrigin:
    case OrderEventType.OrderNotReadyToInvoice:
    case OrderEventType.OrderReceivedAtOrigin:
    case OrderEventType.OrderUnloaded:
    case OrderEventType.StopPaperworkMarkedComplete:
    case OrderEventType.StopPaperworkMarkedIncomplete:
    case OrderEventType.StopAppointmentConfirmed:
    case OrderEventType.StopAppointmentUnconfirmed:
    case OrderEventType.StopArrived:
    case OrderEventType.StopAttempted:
    case OrderEventType.StopCompleted:
    case OrderEventType.Call:
    case OrderEventType.StopAppointmentSchedulingOptionsCall:
    case OrderEventType.OrderSnapshot:
    case OrderEventType.OrderOnHand:
    case OrderEventType.OrderMarkedNotOnHand:
    case OrderEventType.OrderMarkedNotCompleted:
    case OrderEventType.OrderMarkedOnHold:
    case OrderEventType.OrderHoldRemoved:
    case OrderEventType.OrderMarkedOsd:
    case OrderEventType.OrderPieceCountUpdated:
    case OrderEventType.OrderPiecesPickedUpdated:
    case OrderEventType.OrderCancelled:
    case OrderEventType.OrderCreated:
    case OrderEventType.OrderUpdated:
    case OrderEventType.DocumentScanningOrderApproved:
    case OrderEventType.OrderFinalized:
      return '';
    default:
      return '';
  }
};
