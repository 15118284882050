import { Dialog } from '@mui/material';
import { Dispatch, memo, SetStateAction } from 'react';
import CreateMultipleRoutes from './create-multiple-routes';

const CreateMultipleRoutesModal = ({
  open,
  setOpen,
  stopUuidsToAssign,
  onCreate,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  stopUuidsToAssign?: string[];
  onCreate?: () => void;
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <CreateMultipleRoutes
        open={open}
        stopUuidsToAssign={stopUuidsToAssign}
        onCreate={() => {
          onCreate?.();
          setOpen(false);
        }}
      />
    </Dialog>
  );
};

export default memo(CreateMultipleRoutesModal);
