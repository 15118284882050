import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { PartialDeep } from 'type-fest';
import { DeepStringified } from '../../../common/types';
import type { RootState } from '../../../redux/store';
import { StopValues } from './stop-values-slice';

type StopErrors = DeepStringified<
  PartialDeep<Omit<StopValues, 'uuid'>> & { uuid: string }
>;

const stopErrorsAdapter = createEntityAdapter<StopErrors>({
  selectId: (stopErrors) => stopErrors.uuid,
});

export const stopErrorsSlice = createSlice({
  name: 'stopErrors',
  initialState: stopErrorsAdapter.getInitialState(),
  reducers: {
    upsertOneStopErrors: stopErrorsAdapter.upsertOne,
  },
});

export const { upsertOneStopErrors } = stopErrorsSlice.actions;

export const { selectById: selectStopErrorsById } =
  stopErrorsAdapter.getSelectors((state: RootState) => state.stopErrors);

const stopErrorsReducer = stopErrorsSlice.reducer;

export default stopErrorsReducer;
