import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactType,
  PermissionResource,
  ShallowContactDocument,
  useAccessorialsQuery,
  useBulkUpdateAccessorialMappingsMutation,
  useShallowContactLazyQuery,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';
import AccessorialMapping from './accessorial-mapping';

const ContactQuickbooksFileMappings = ({
  contactUuid,
}: {
  contactUuid: string;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const { data: accessorialData } = useAccessorialsQuery({
    variables: { contactUuid },
  });
  const [updateCustomerContact] = useUpdateCustomerContactMutation({
    refetchQueries: [ShallowContactDocument],
  });
  const [updateAccessorials] = useBulkUpdateAccessorialMappingsMutation({
    refetchQueries: [],
  });
  const [getContact, { data: contactData }] = useShallowContactLazyQuery();
  const [customerMapping, setCustomerMapping] = useState('');
  const [freightChargeMapping, setFreightChargeMapping] = useState('');
  const [fuelChargeMapping, setFuelChargeMapping] = useState('');
  const [pickupChargeMapping, setPickupChargeMapping] = useState('');
  const [accessorialsToUpdate, setAccessorialsToUpdate] = useState<{
    [key: string]: string;
  }>({});
  const [showSuccessfulSave, setShowSuccessfulSave] = useState(false);
  const [showUnsuccessfulSave, setShowUnsuccessfulSave] = useState(false);

  useEffect(() => {
    getContact({ variables: { uuid: contactUuid } });
  }, [contactUuid]);

  useEffect(() => {
    if (!isNil(contactData)) {
      setFreightChargeMapping(
        contactData.contact.quickbooksFileMappingFreightChargeName ?? '',
      );
      setFuelChargeMapping(
        contactData.contact.quickbooksFileMappingFuelChargeName ?? '',
      );
      setCustomerMapping(
        contactData.contact.quickbooksFileMappingCustomerName ?? '',
      );
      setPickupChargeMapping(
        contactData.contact.quickbooksFileMappingPickupChargeName ?? '',
      );
    }
  }, [contactData?.contact.uuid]);

  useEffect(() => {
    const accessorialState: {
      [key: string]: string;
    } = {};
    accessorialData?.accessorials.forEach((accessorial) => {
      accessorialState[accessorial.uuid] =
        accessorial.quickbooksFileMappingChargeName ?? '';
    });
    setAccessorialsToUpdate(accessorialState);
  }, [accessorialData?.accessorials.length]);

  const onSave = async () => {
    if (contactData?.contact.type === ContactType.Customer) {
      const updateContactRes = await updateCustomerContact({
        variables: {
          input: {
            customerContactUpdateInput: {
              uuid: contactUuid,
              quickbooksFileMappingCustomerName: customerMapping,
              quickbooksFileMappingFreightChargeName: freightChargeMapping,
              quickbooksFileMappingFuelChargeName: fuelChargeMapping,
              quickbooksFileMappingPickupChargeName: pickupChargeMapping,
            },
          },
        },
      });
      const updateAccessorialRes = await updateAccessorials({
        variables: {
          bulkAccessorialUpdateInput: {
            accessorialToMappingInputs: Object.keys(accessorialsToUpdate).map(
              (accessorialUuid) => ({
                uuid: accessorialUuid,
                mapping: accessorialsToUpdate[accessorialUuid] ?? '',
              }),
            ),
          },
        },
      });
      if (
        !isNil(updateContactRes.data?.updateCustomerContact.uuid) &&
        !isNil(updateAccessorialRes.data?.bulkUpdateAccessorialMappings.length)
      ) {
        setShowSuccessfulSave(true);
      } else {
        setShowUnsuccessfulSave(true);
      }
    }
  };

  if (contactData?.contact.type === ContactType.ThirdParty) {
    return <Box>Quickbooks is not available for third party contacts</Box>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSuccessfulSave(false)}
        open={showSuccessfulSave}
      >
        <Alert>Successfully saved Quickbooks mappings</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowUnsuccessfulSave(false)}
        open={showUnsuccessfulSave}
      >
        <Alert severity="error">Failed to save</Alert>
      </Snackbar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Customer name mapping</Typography>
        <TextField
          value={customerMapping}
          onChange={(e) => setCustomerMapping(e.target.value)}
          size="small"
          disabled={!canWriteContacts}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Freight charge mapping</Typography>
        <TextField
          value={freightChargeMapping}
          onChange={(e) => setFreightChargeMapping(e.target.value)}
          size="small"
          disabled={!canWriteContacts}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Fuel charge mapping</Typography>
        <TextField
          value={fuelChargeMapping}
          onChange={(e) => setFuelChargeMapping(e.target.value)}
          size="small"
          disabled={!canWriteContacts}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography>Pickup charge mapping</Typography>
        <TextField
          value={pickupChargeMapping}
          onChange={(e) => setPickupChargeMapping(e.target.value)}
          size="small"
          disabled={!canWriteContacts}
        />
      </Box>
      {accessorialData?.accessorials.map((accessorial) => (
        <AccessorialMapping
          uuid={accessorial.uuid}
          mapping={accessorialsToUpdate[accessorial.uuid] ?? ''}
          name={accessorial.name}
          setAccessorialsToUpdate={setAccessorialsToUpdate}
          disabled={!canWriteContacts}
        />
      ))}
      <Button
        sx={{ ml: 'auto', mr: 'auto' }}
        variant="contained"
        onClick={onSave}
        disabled={!canWriteContacts}
      >
        Save
      </Button>
    </Box>
  );
};

export default ContactQuickbooksFileMappings;
