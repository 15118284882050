import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import React, { SetStateAction, useState, Dispatch } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { ORDERS_PAGE_OPEN_ORDER_BUTTON_TEST_ID_PREFIX } from '../../../constants';
import useGlobalStore from '../../layouts/dashboard/global-store';
import OrderRowContextMenu from '../orders/components/standard/components/standard-order/order-row-context-menu';

interface OpenOrderButtonProps {
  params: ICellRendererParams | { data: { uuid: string | undefined } };
  openInCurrentPage?: boolean;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  buttonLabel?: string;
  includeContextMenu?: boolean;

  addPageEntryToBrowserHistory?: boolean;
}

const OpenOrderButton = ({
  params,
  openInCurrentPage,
  setSuccessMessage,
  buttonLabel,
  includeContextMenu,
  addPageEntryToBrowserHistory,
}: OpenOrderButtonProps) => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [setOpenedOrderUuid, setOrderUuidToRefetch] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid, state.setOrderUuidToRefetch],
    shallow,
  );

  const onClose = () => {
    setShowContextMenu(false);
  };

  const onAction = () => {
    setOpenedOrderUuid(undefined);
    setOrderUuidToRefetch(params.data.uuid);
  };

  const open = (event: React.MouseEvent) => {
    const url = `/orders/?orderUuid=${params.data.uuid}`;
    if (event.ctrlKey === true || event.metaKey === true) {
      window.open(url, '_blank')?.focus();
      event.stopPropagation();
      return;
    }
    if (event.altKey === true) {
      window.open(url, '_blank');
      event.stopPropagation();
      return;
    }
    if (openInCurrentPage === true) {
      setOpenedOrderUuid(params.data.uuid);
      setSearchParams(
        (sp) => {
          const newParams = new URLSearchParams(sp);
          newParams.set('orderUuid', params.data.uuid);
          return newParams;
        },
        {
          replace: addPageEntryToBrowserHistory !== true,
        },
      );
    } else {
      navigate(url);
    }
  };

  return (
    <Stack direction="row">
      <Button
        variant="contained"
        onClick={open}
        sx={{
          height: 'fit-content',
          padding: 0,
          borderRadius: 1,
        }}
        data-testid={`${ORDERS_PAGE_OPEN_ORDER_BUTTON_TEST_ID_PREFIX}-${params.data.uuid}`}
      >
        {!isNil(buttonLabel) ? buttonLabel : 'Open'}
      </Button>
      {includeContextMenu !== false && (
        <IconButton
          sx={{
            padding: 0,
            mr: '10px',
            height: 'fit-content',
          }}
          size="small"
          onClick={(e) => {
            setButtonRef(e.currentTarget);
            setShowContextMenu(true);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      {showContextMenu && (
        <OrderRowContextMenu
          orderUuid={params.data.uuid}
          buttonRef={buttonRef}
          showContextMenu={showContextMenu}
          onClose={onClose}
          onAction={onAction}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </Stack>
  );
};

export default OpenOrderButton;
