import {
  Accordion,
  AccordionDetails,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import SettlementsFilterButton, {
  Option,
} from '../../../../common/components/settlements-filter-button';
import useMe from '../../../../common/react-hooks/use-me';
import {
  Segment,
  StopDriverMapSummaryFragment,
} from '../../../../generated/graphql';
import AccordionSummary from '../../../invoices/components/accordion-summary';
import StopDriverMapsTableRow from './stop-driver-maps-table-row';

interface SettlementPreviewCardProps {
  driverUuid: string;
  stopDriverMaps: StopDriverMapSummaryFragment[];
  addToOpenSettlements?: boolean;
  updateDriverUuidToSettlementUuidMap: (
    driverUuid: string,
    settlementUuid: string | undefined,
  ) => void;
}

const SettlementPreviewCard = ({
  driverUuid,
  stopDriverMaps,
  addToOpenSettlements,
  updateDriverUuidToSettlementUuidMap,
}: SettlementPreviewCardProps) => {
  const { segment } = useMe();
  const theme = useTheme();
  const [settlementBillOption, setSettlementBillOption] = useState<Option>();

  const onSettlementBillOptionChange = (option: Option | undefined | null) => {
    setSettlementBillOption(option ?? undefined);
    const settlementUuid = option?.value;
    updateDriverUuidToSettlementUuidMap(driverUuid, settlementUuid);
  };

  const bolFieldString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';
  const secondaryRefNumberString =
    segment === Segment.Cartage ? 'Ref Number' : 'Secondary Ref Number';

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{ border: '1px solid', borderColor: theme.palette.borderColor.main }}
    >
      <AccordionSummary>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ ml: 1 }}
            >
              <Stack>
                <Typography>
                  {stopDriverMaps[0]?.driver?.firstName ?? ''}{' '}
                  {stopDriverMaps[0]?.driver?.lastName ?? ''}
                </Typography>
                <Typography color="text.secondary" variant="caption">
                  {capitalCase(stopDriverMaps[0]?.driver?.driverType ?? '')}
                </Typography>
              </Stack>
              <SettlementsFilterButton
                filterDefaultTitle="Create New"
                prefix="Add to "
                driverUuid={driverUuid}
                selectedOption={settlementBillOption}
                handleChange={onSettlementBillOptionChange}
                addToOpenSettlements={addToOpenSettlements}
              />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack sx={{ float: 'right' }}>
              <Typography color="text.secondary" variant="caption">
                Stops: {stopDriverMaps.length}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
          <TableContainer sx={{ overflowY: 'scroll' }}>
            <Table aria-label="stop-driver-maps-table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: '160px' }}>Completed At</TableCell>
                  <TableCell sx={{ minWidth: '130px' }}>Driver</TableCell>
                  <TableCell sx={{ minWidth: '130px' }}>Customer</TableCell>
                  <TableCell sx={{ minWidth: '100px' }}>Stop Type</TableCell>
                  <TableCell>{bolFieldString}</TableCell>
                  <TableCell>{secondaryRefNumberString}</TableCell>
                  <TableCell>Consignee</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stopDriverMaps.map((stopDriverMap) => (
                  <StopDriverMapsTableRow
                    key={stopDriverMap.uuid}
                    stopDriverMap={stopDriverMap}
                    showDriverType={false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(SettlementPreviewCard);
