import { Button } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import React from 'react';
import { useAllowOpenInNewTab } from '../../../common/react-hooks/use-allow-open-in-new-tab';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../../customer-portal/constants';

const OpenCustomerPortalOrderButton = ({
  params,
}: {
  params: ICellRendererParams;
}) => {
  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  if (isNil(params) || isNil(params.data)) {
    return null;
  }

  const open = (event: React.MouseEvent) => {
    const url = `${CUSTOMER_PORTAL_ORDERS_PATH}/${params.data.uuid}`;
    allowOpenInNewTab(event, url);
    event.stopPropagation();
  };

  return (
    <Button onClick={open} size="small" variant="outlined">
      View
    </Button>
  );
};

export default OpenCustomerPortalOrderButton;
