import React, { createContext } from 'react';

type OrderFormChargesContextProps = {
  isRatingAccessorials: boolean;
  setIsRatingAccessorials: React.Dispatch<React.SetStateAction<boolean>>;
  isSwitchingAccessorial: boolean;
  setIsSwitchingAccessorial: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Shared context for order form rating.
 * A note on accessorial custom charges: while there could be multiple, the structure defined below maintains a single piece of state
 * that is set when an accessorial is being rated or when its being switched. But, this is intentional given the structure of the new
 * rating APIs which rates the entire order in one call. As a result of this, invoking re-rating for an order will recalculate charges for all accessorial
 * custom charges, which justifies maintaining a single loading state for them.
 */
export default createContext<OrderFormChargesContextProps>({
  isRatingAccessorials: false,
  setIsRatingAccessorials: () => {},
  isSwitchingAccessorial: false,
  setIsSwitchingAccessorial: () => {},
});
