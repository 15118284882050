import * as yup from 'yup';

import { DocumentType } from '../../../../../generated/graphql';

export const documentSchema = yup
  .object({
    uuid: yup.string().defined(),
    fileName: yup.string().optional().required(),
    bucket: yup.string().optional().nullable(),
    region: yup.string().optional().nullable(),
    key: yup.string().optional().nullable(),
    preSignedGetUrl: yup.string().optional().required(),
    fileType: yup.string().optional().required(),
    name: yup.string().optional().nullable(),
    type: yup
      .mixed<DocumentType>()
      .oneOf(Object.values(DocumentType))
      .required(),
    notes: yup.string().optional().nullable(),
    driverFormTemplateUuid: yup.string().optional().nullable(),
  })
  .optional()
  .nullable();
