import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { capitalCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isEmpty, isNil, sumBy } from 'lodash';
import { filterNotNil } from 'shared/array';
import { Segment, ShipmentType } from '../../../generated/graphql';
import { dummyStopTypes } from '../../orders/lib/stop-types';
import { ArrivalNoticeData, ArrivalNoticeShipment } from '../utils';
import DisplayAddress from './display-address';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Roboto',
    fontSize: '11px',
    flexDirection: 'column',
  },
  titleBold: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  subHeaderTextSize: {
    fontSize: '10px',
  },
  subHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  rowWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
    display: 'flex',
    padding: '5px',
  },
});

const ChargesTable = ({ shipment }: { shipment: ArrivalNoticeShipment }) => {
  return (
    <View>
      <View style={styles.rowWithPadding}>
        <Text
          style={{
            fontSize: '11px',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          {shipment.shipmentType === ShipmentType.Regular
            ? `${capitalCase(shipment.stopType)} Charges`
            : capitalCase(shipment.shipmentType)}
        </Text>
      </View>
      <View style={styles.rowWithPadding}>
        <View style={{ width: '80%' }}>
          {shipment.shipmentType !== ShipmentType.OrderCharges && (
            <>
              <Text style={styles.subHeaderText}>Freight</Text>
              <Text style={styles.subHeaderText}>Fuel</Text>
            </>
          )}
          {shipment.customCharges?.map((charge) => (
            // eslint-disable-next-line react/jsx-key
            <Text style={styles.subHeaderText}>{charge.name}</Text>
          ))}
        </View>
        <View style={{ width: '10%' }}>
          {shipment.shipmentType !== ShipmentType.OrderCharges && (
            <>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {currency(shipment.totalFreightChargesAmount ?? 0).format()}
              </Text>
              <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
                {currency(shipment.totalFuelChargesAmount ?? 0).format()}
              </Text>
            </>
          )}
          {shipment.customCharges?.map((charge) => (
            // eslint-disable-next-line react/jsx-key
            <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
              {currency(charge.total).format()}
            </Text>
          ))}
        </View>
      </View>
      <View style={styles.rowWithPadding}>
        <View style={{ width: '90%', borderBottomWidth: 1 }} />
      </View>
      <View style={styles.rowWithPadding}>
        <View style={{ width: '80%' }}>
          <Text style={styles.subHeaderText}>Total Charges</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={[styles.subHeaderTextSize, { textAlign: 'right' }]}>
            {currency(shipment.totalChargesAmount).format()}
          </Text>
        </View>
      </View>
    </View>
  );
};

const GeneratedArrivalNoticePdf = ({
  segment,
  data,
  showCharges,
  showExternalNotes,
}: {
  segment: Segment | undefined;
  data: ArrivalNoticeData;
  showCharges: boolean;
  showExternalNotes: boolean;
}) => {
  const normalShipments = filterNotNil([
    data.inboundShipment,
    data.outboundShipment,
  ]).filter((shipment) => !dummyStopTypes.includes(shipment.stopType));
  const miscChargesShipments = filterNotNil([
    data.lineHaulShipment,
    data.orderChargesShipment,
  ]);

  return (
    <Document title="Arrival Notice">
      <Page size="LETTER" style={styles.page}>
        <View>
          <View>
            <Text style={{ ...{ fontWeight: 700 }, ...styles.titleBold }}>
              Arrival Notice
            </Text>
          </View>
          <View
            style={[
              {
                flexDirection: 'row',
                marginTop: '20px',
              },
              styles.cell,
            ]}
          >
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{ ...{ fontWeight: 700 }, ...styles.titleBold }}>
                {data.companyName}
              </Text>
              <DisplayAddress address={data.companyAddress} />
              <Text style={styles.subHeaderTextSize}>{data.companyPhone}</Text>
            </View>
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <Text>{dayjs().format('M/D/YYYY hh:MM')}</Text>
              <View style={{ marginRight: '3%' }}>
                <Text style={{ fontWeight: 700 }}>Print Date:</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
        >
          <View style={styles.rowWithPadding}>
            <Text style={styles.headerText}>{data.contactDisplayName}</Text>
          </View>
          <View style={styles.rowWithPadding}>
            <Text style={styles.subHeaderText}>Broker(s): </Text>
            <Text style={styles.subHeaderTextSize}>
              {data.thirdPartyBrokerName}
            </Text>
          </View>
          <View style={styles.rowWithPadding}>
            <View
              style={{ width: '40%', display: 'flex', flexDirection: 'column' }}
            >
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>Order #</Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>{data.orderName}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>
                    {segment === Segment.Cartage ? 'HAWB #' : 'Pro #'}
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {data.shipperBillOfLadingNumber}
                  </Text>
                </View>
              </View>
              {segment === Segment.Cartage && (
                <View style={styles.row}>
                  <View style={{ width: '40%' }}>
                    <Text style={styles.subHeaderText}>MAWB #</Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={{ fontSize: '10px' }}>
                      {data.masterAirwayBillOfLadingNumber}
                    </Text>
                  </View>
                </View>
              )}
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>IT/TE #</Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>{data.itTeNumber}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                display: 'flex',
              }}
            >
              <View style={styles.row}>
                <View style={{ width: '10%' }}>
                  <Text style={styles.subHeaderText}>Orig</Text>
                </View>
                <View style={{ width: '20%' }}>
                  <Text style={{ fontSize: '10px' }}>{data.originCode}</Text>
                </View>
                <View style={{ width: '15%' }}>
                  <Text style={styles.subHeaderText}>Dest</Text>
                </View>
                <View style={{ width: '20%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {data.destinationCode}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>Service Level: </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>{data.serviceName}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '30%',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              <View style={styles.rowWithPadding}>
                <View style={{ width: '25%' }}>
                  <Text style={styles.subHeaderText}>Weight:</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {sumBy(data.packages, 'weight')}
                  </Text>
                </View>
              </View>
              <View style={styles.rowWithPadding}>
                <View style={{ width: '25%' }}>
                  <Text style={styles.subHeaderText}>Pieces:</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {sumBy(data.packages, 'quantity')}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}
        >
          <View
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={styles.rowWithPadding}>
              <Text style={styles.headerText}>
                {data.inboundShipment?.stopType}
              </Text>
            </View>
            <hr />
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Address:</Text>
              </View>
              <View style={{ width: '80%', display: 'flex' }}>
                {!isNil(data.inboundShipment) && (
                  <DisplayAddress
                    fontSize="10px"
                    address={data.inboundShipment.address}
                  />
                )}
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Phone:</Text>
              </View>
              <View style={{ width: '80%', display: 'flex' }}>
                <Text style={styles.subHeaderTextSize}>
                  {data.inboundShipment?.contactPhone}
                </Text>
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Contact:</Text>
              </View>
              <View style={{ width: '80%', display: 'flex' }}>
                <Text style={styles.subHeaderTextSize}>
                  {data.inboundShipment?.contactPersonName}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={styles.rowWithPadding}>
              <Text style={styles.headerText}>
                {data.outboundShipment?.stopType}
              </Text>
            </View>
            <hr />
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Address:</Text>
              </View>
              <View style={{ width: '80%', display: 'flex' }}>
                {!isNil(data.outboundShipment) && (
                  <DisplayAddress
                    fontSize="10px"
                    address={data.outboundShipment.address}
                  />
                )}
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Phone:</Text>
              </View>
              <View style={{ width: '80%', display: 'flex' }}>
                <Text style={styles.subHeaderTextSize}>
                  {data.outboundShipment?.contactPhone}
                </Text>
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Contact:</Text>
              </View>
              <View style={{ width: '80%', display: 'flex' }}>
                <Text style={styles.subHeaderTextSize}>
                  {data.outboundShipment?.contactPersonName}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}
        >
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={styles.rowWithPadding}>
              <View style={{ width: '30%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>
                  Released by US Customs ______
                </Text>
              </View>
              <View style={{ width: '40%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>
                  Steamship Release ______
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}
        >
          <View
            style={{
              borderRightWidth: 1,
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={styles.rowWithPadding}>
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Description of Goods</Text>
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '100%', display: 'flex' }}>
                {data.packages.map((pkg) => (
                  // eslint-disable-next-line react/jsx-key
                  <Text style={styles.subHeaderTextSize}>
                    {pkg.quantity} {pkg.type} - {pkg.description}
                  </Text>
                ))}
              </View>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ width: '25%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>Hazmat:</Text>
              </View>
              <View style={{ width: '20%', display: 'flex' }}>
                <Text style={styles.subHeaderTextSize}>
                  {data.hazmat === true ? 'Yes' : 'No'}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '10px',
            }}
          >
            <View style={styles.rowWithPadding}>
              <Text style={styles.subHeaderText}>Pieces / Dimensions</Text>
            </View>
            <View style={styles.rowWithPadding}>
              <View style={{ display: 'flex' }}>
                {data.packages.map((pkg) => (
                  // eslint-disable-next-line react/jsx-key
                  <Text style={styles.subHeaderTextSize}>
                    {pkg.length ?? '-'}L x {pkg.width ?? '-'}W x{' '}
                    {pkg.height ?? '-'}H, {pkg.weight ?? '-'} lbs
                  </Text>
                ))}
              </View>
            </View>
          </View>
        </View>
        {showCharges && (
          <View
            style={[
              styles.cell,
              { flexDirection: 'column', borderTopWidth: 0 },
            ]}
          >
            <View style={styles.rowWithPadding}>
              <View
                style={{ width: !isEmpty(normalShipments) ? '50%' : undefined }}
              >
                {normalShipments
                  .filter((shipment) => !shipment.hideFromBilling)
                  .map((shipment) => (
                    // eslint-disable-next-line react/jsx-key
                    <ChargesTable shipment={shipment} />
                  ))}
              </View>
              <View style={{ width: '50%' }}>
                {miscChargesShipments
                  .filter((shipment) => !shipment.hideFromBilling)
                  .map((shipment) => (
                    // eslint-disable-next-line react/jsx-key
                    <ChargesTable shipment={shipment} />
                  ))}
              </View>
            </View>
            <View
              style={[
                styles.rowWithPadding,
                { marginTop: '10px', flexDirection: 'row-reverse' },
              ]}
            >
              <View style={{ width: '10%' }}>
                <Text
                  style={[styles.subHeaderTextSize, { textAlign: 'right' }]}
                >
                  {currency(
                    sumBy(normalShipments, 'totalChargesAmount') +
                      sumBy(miscChargesShipments, 'totalChargesAmount'),
                  ).format()}
                </Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text style={styles.subHeaderText}>
                  Total Charges for Order
                </Text>
              </View>
            </View>
          </View>
        )}
        {showExternalNotes && (
          <View
            style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}
          >
            <View style={{ gap: '5px' }}>
              <View style={{ width: '100%', display: 'flex' }}>
                <Text style={styles.subHeaderText}>External Notes</Text>
              </View>
              <View style={{ width: '100%', display: 'flex' }}>
                <Text style={styles.subHeaderTextSize}>
                  {data.externalNotes}
                </Text>
              </View>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default GeneratedArrivalNoticePdf;
