import { Box, CircularProgress, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import GenericDocument from '../../icons/generic-document.svg?react';

const useStyles = () => ({
  fileList: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '50px',
    width: '100%',
    alignItems: 'center',
    paddingLeft: '10%',
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5%',
  },
});

export type ImageInformation = {
  src: string | ArrayBuffer;
  name: string;
  type: string;
};

type ImageProps = {
  image: ImageInformation;
  index: number;
  setImages: (images: ImageInformation[]) => void;
  images: ImageInformation[];
  files: File[];
  setFiles: (files: File[]) => void;
};

// Rendering individual images
const Image = ({
  image,
  index,
  setImages,
  images,
  files,
  setFiles,
}: ImageProps) => {
  const styles = useStyles();
  const clickDelete = () => {
    const imagesClone = cloneDeep(images).filter((_, idx) => idx !== index);
    const filesClone = cloneDeep(files).filter((_, idx) => {
      return idx !== index;
    });
    setFiles(filesClone);
    setImages(imagesClone);
  };

  return (
    <Box sx={styles.imageBox}>
      <GenericDocument style={{ width: '50px' }} />
      <Typography sx={{ width: '30%', wordWrap: 'break-word' }}>
        {image.name}
      </Typography>
      <Typography
        onClick={clickDelete}
        style={{
          position: 'relative',
          cursor: 'pointer',
          color: '#959595',
          verticalAlign: 'text-top',
        }}
      >
        X
      </Typography>
    </Box>
  );
};

export type ImageGridProps = {
  images: ImageInformation[];
  setImages: (images: ImageInformation[]) => void;
  files: File[];
  setFiles: (files: File[]) => void;
  shouldShowLoader: boolean;
};

export const ImageGrid = ({
  images,
  setImages,
  files,
  setFiles,
  shouldShowLoader,
}: ImageGridProps) => {
  const styles = useStyles();
  // Circular spinner has weird CSS since for it sometimes appears in the middle of the list bc of how React renders lists
  return (
    <Box sx={styles.fileList}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center' }}>
          {files.length === 0 ? (
            <>No files uploaded yet</>
          ) : (
            <>Your {files.length} files uploaded</>
          )}
        </Typography>
      </Box>
      {shouldShowLoader && (
        <CircularProgress sx={{ position: 'absolute', top: '10%' }} />
      )}
      {images.map((image, index) => {
        return (
          <Image
            image={image}
            key={`${image.name}-image`}
            index={index}
            setImages={setImages}
            images={images}
            files={files}
            setFiles={setFiles}
          />
        );
      })}
    </Box>
  );
};
