// eslint-disable-next-line no-restricted-imports
import { SxProps, Box, Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { transformTimeToTimeString } from '../../../common/utils/prettyPrintUtils';
import { AppointmentFragment } from '../../../generated/graphql';

const styles = {
  splitContainer: {
    display: 'flex',
    p: 2,
  } as SxProps,
  splitView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
  appointmentSelectView: {
    my: 1,
    width: '90%',
    border: '1px solid grey',
    borderRadius: '10px',
    height: '50vh',
    overflow: 'scroll',
  } as SxProps,
  gridCallView: {
    p: 2,
    fontSize: '12px',
  } as SxProps,
  gridCallViewGreen: {
    p: 2,
    backgroundColor: '#E2FAE5',
    borderRadius: '10px',
    fontSize: '12px',
  } as SxProps,
  appointmentItem: {
    width: '100%',
  } as SxProps,
  boldTitle: {
    fontWeight: 'bold',
  } as SxProps,
  callPreviewView: {
    my: 1,
    p: 4,
    background: '#F7F7F7',
    borderRadius: '10px',
    padding: '10px',
    width: '90%',
    height: '50vh',
  } as SxProps,
  callWrapper: {
    m: 2,
  } as SxProps,
};

type CallPreviewsProps = {
  rowData: AppointmentFragment[];
  selectedUuids: string[];
};

const CallPreviews = ({ rowData, selectedUuids }: CallPreviewsProps) => {
  const appointments = rowData.filter((row) =>
    selectedUuids.includes(row.uuid),
  );
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const [callPreview, setCallPreview] = useState('');

  useEffect(() => {
    const appointment = appointments.find((appt) => appt.uuid === selectedUuid);
    if (!isNil(appointment)) {
      setCallPreview(appointment.formattedCallMessage ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid]);

  useEffect(() => {
    const firstAppointment = appointments[0];
    if (!isNil(firstAppointment)) {
      setSelectedUuid(firstAppointment.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments.length]);

  return (
    <Box sx={styles.splitContainer}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={styles.splitView}>
            <Typography variant="h6">Appointments to Schedule</Typography>
            <Box sx={styles.appointmentSelectView}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Box sx={styles.gridCallView}>
                    <Typography sx={styles.boldTitle}>
                      Consignee Name
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box sx={styles.gridCallView}>
                    <Typography sx={styles.boldTitle}>Appointment</Typography>
                  </Box>
                </Grid>
              </Grid>
              {appointments.map((appointment) => {
                let windowText;
                if (
                  !isNil(appointment.appointmentTime) &&
                  isNil(appointment.endAppointmentTime)
                ) {
                  windowText = `${transformTimeToTimeString(
                    appointment.appointmentTime,
                  )}`;
                }
                if (
                  isNil(appointment.appointmentTime) &&
                  !isNil(appointment.endAppointmentTime)
                ) {
                  windowText = `${transformTimeToTimeString(
                    appointment.endAppointmentTime,
                  )}`;
                }
                if (
                  !isNil(appointment.appointmentTime) &&
                  !isNil(appointment.endAppointmentTime)
                ) {
                  windowText = `${transformTimeToTimeString(
                    appointment.appointmentTime,
                  )} to ${transformTimeToTimeString(
                    appointment.endAppointmentTime,
                  )}`;
                }
                // If the preview is not selected, return with the option to select
                return (
                  <Grid
                    container
                    spacing={2}
                    onClick={() => setSelectedUuid(appointment.uuid)}
                    key={appointment.uuid}
                  >
                    <Grid item xs={7}>
                      <Box
                        sx={{
                          ...styles.gridCallView,
                          backgroundColor:
                            appointment.uuid === selectedUuid
                              ? '#E2FAE5'
                              : undefined,
                          marginLeft: '10px',
                          marginRight: '-40px',
                        }}
                      >
                        <Typography sx={{ fontSize: '13px' }}>
                          {appointment.contactPerson?.phone ?? 'No phone'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box
                        sx={{
                          ...styles.gridCallView,
                          ml: 0,
                          backgroundColor:
                            appointment.uuid === selectedUuid
                              ? '#E2FAE5'
                              : undefined,
                          marginLeft: 0,
                          marginRight: '10px',
                        }}
                      >
                        <Typography sx={{ fontSize: '13px' }}>
                          {windowText}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={styles.splitView}>
            <Typography variant="h6">Call Preview</Typography>
            <Box sx={styles.callPreviewView}>
              <Box sx={styles.callWrapper}>
                <Typography>{callPreview}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallPreviews;
