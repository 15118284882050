import { SortModelItem, ColumnState } from 'ag-grid-community';
import { sentenceCase } from 'change-case';
import { isEqual, isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import {
  DispatchTableField,
  FilterOperator,
  OrderSortV2,
  GetOrderTableFieldValuesQueryVariables,
  OrderTableField,
  PickupOrDelivery,
  SortOrder,
  OrderStatus,
} from '../../generated/graphql';
import {
  AGGridFilterType,
  FilterModel,
  OrderFilterField,
  OrderStatusFilterOption,
} from '../orders/components/enums/order-filters';
import { StopFilterField } from './dispatch-stops/constants';
import {
  DispatchSortV2,
  DispatchTableFilterModel,
} from './dispatch-stops/types';
import { OrderTableFilterModel } from './orders/types';

const countChangesBetweenModels = <
  T extends FilterModel | SortModelAsObject<OrderSortV2 | DispatchSortV2>,
>(
  originalObject: T,
  changedObject: T,
) => {
  let changes = 0;

  // Check for deleted keys in the changed object
  Object.keys(originalObject).forEach((key) => {
    if (!(key in changedObject)) {
      changes += 1;
    } else if (
      typeof originalObject[key] === 'object' &&
      typeof changedObject[key] === 'object'
    ) {
      if (!isEqual(originalObject[key], changedObject[key])) {
        changes += 1;
      }
    } else if (originalObject[key] !== changedObject[key]) {
      changes += 1;
    }
  });
  // Check for added keys in the changed object
  Object.keys(changedObject).forEach((key) => {
    if (!(key in originalObject)) {
      changes += 1;
    }
  });

  return changes;
};

export const countChangesBetweenFilterModels = (
  original: FilterModel | undefined,
  changed: FilterModel | undefined,
): number => {
  if (isNil(original) || isNil(changed)) {
    return 0;
  }
  return countChangesBetweenModels(original, changed);
};

type SortModelAsObject<T extends OrderSortV2 | DispatchSortV2> = {
  [key: string]: Omit<T, 'fieldName'>;
};

export const countChangesBetweenSortModels = <
  T extends OrderSortV2 | DispatchSortV2,
>(
  original: T[] | undefined,
  changed: T[] | undefined,
): number => {
  if (isNil(original) || isNil(changed)) {
    return 0;
  }

  const originalAsObject: SortModelAsObject<T> = Object.fromEntries(
    original.map(({ fieldName, ...rest }) => [fieldName, rest]),
  );
  const changedAsObject: SortModelAsObject<T> = Object.fromEntries(
    changed.map(({ fieldName, ...rest }) => [fieldName, rest]),
  );

  return countChangesBetweenModels(originalAsObject, changedAsObject);
};

export const changesBetweenTableFields = <
  T extends OrderTableField | DispatchTableField,
>(
  original: T[] | null,
  changed: T[] | null,
): number => {
  if (isNil(original) || isNil(changed)) {
    return 0;
  }

  let changes = 0;
  for (let i = 0; i < Math.max(original.length, changed.length); i += 1) {
    if (original[i] !== changed[i]) {
      changes += 1;
    }
  }

  return changes;
};

/** Used for updating AG Grid column sort state via applySortModel() */
export type ColumnSortState<
  TField extends OrderTableField | DispatchTableField,
> = {
  colId: TField;
  sort: 'asc' | 'desc';
  sortIndex: number;
};

/** Convert AG Grid sort directions to SortOrder enum values for OrderSortV2 */
export const getSortOrder = (sort: 'asc' | 'desc') => {
  switch (sort) {
    case 'asc':
      return SortOrder.Asc;
    case 'desc':
      return SortOrder.Desc;
    default:
      return exhaustive(sort);
  }
};

export const convertDefaultOrderStatusFilterTabToOrderTableFilterModel = (
  statusFilter:
    | OrderStatusFilterOption.INCOMPLETE
    | OrderStatusFilterOption.ATTEMPTED
    | OrderStatusFilterOption.ON_HOLD
    | OrderStatusFilterOption.COMPLETED,
  ffEnableNewTableFunctions: boolean,
): OrderTableFilterModel => {
  if (ffEnableNewTableFunctions) {
    const andFilters: OrderTableFilterModel[] = [];

    switch (statusFilter) {
      case OrderStatusFilterOption.INCOMPLETE:
        andFilters.push({
          ORDER_STATUS: {
            in: [OrderStatus.Created, OrderStatus.InProgress],
          },
        });
        break;
      case OrderStatusFilterOption.ATTEMPTED:
        andFilters.push({
          ORDER_STATUS: {
            in: [OrderStatus.Created, OrderStatus.InProgress],
          },
        });
        andFilters.push({ DATE_ATTEMPTED: { isNotBlank: true } });
        break;
      case OrderStatusFilterOption.ON_HOLD:
        andFilters.push({
          ORDER_STATUS: { eq: OrderStatus.OnHold },
        });
        break;
      case OrderStatusFilterOption.COMPLETED:
        andFilters.push({
          ORDER_STATUS: {
            in: [
              OrderStatus.Delivered,
              OrderStatus.HasIssue,
              OrderStatus.Invoiced,
              OrderStatus.Finalized,
            ],
          },
        });
        break;
      default:
        return exhaustive(statusFilter);
    }

    return { and: andFilters };
  }

  return {
    ORDER_STATUS: {
      value: statusFilter,
      displayValue: sentenceCase(statusFilter),
      filterType: AGGridFilterType.SINGLE_SELECT,
      filterOperator: FilterOperator.And,
    },
  };
};

/** @deprecated */
export const convertDefaultOrderStatusFilterTabToLegacyFilterModelJson = (
  statusFilter: OrderStatusFilterOption,
): FilterModel => {
  const filterModel: FilterModel = {};

  filterModel[OrderFilterField.ORDER_STATUS] = {
    value: statusFilter,
    displayValue: sentenceCase(statusFilter),
    filterType: AGGridFilterType.SINGLE_SELECT,
    filterOperator: FilterOperator.And,
  };

  return filterModel;
};

/** @deprecated */
export const convertStopTypeFilterTabToLegacyFilterModelJson = (
  stopType: PickupOrDelivery,
): FilterModel => {
  const filterModel: FilterModel = {};

  filterModel[StopFilterField.STOP_TYPE] = {
    value: stopType,
    displayValue: sentenceCase(stopType),
    filterType: AGGridFilterType.SINGLE_SELECT,
    filterOperator: FilterOperator.And,
  };

  return filterModel;
};

export const convertStopTypeFilterTabToDispatchTableFilterModel = (
  stopType: PickupOrDelivery,
): FilterModel => {
  const filterModel: DispatchTableFilterModel = {};

  filterModel.STOP_TYPE = {
    value: stopType,
    displayValue: sentenceCase(stopType),
    filterType: AGGridFilterType.SINGLE_SELECT,
    filterOperator: FilterOperator.And,
  };

  return filterModel;
};

export interface DefaultFilterTab<T> {
  value: T;
  label: string;
  filterModel?: FilterModel;
}

export interface DefaultOrdersFilterTab<T> {
  value: T;
  label: string;
  filtersToApply: Partial<
    GetOrderTableFieldValuesQueryVariables['getOrderTableFieldValuesInput']
  >;
  filterModel?: FilterModel;
}

export const mergeModels = (
  oldModel: FilterModel,
  newModel: FilterModel,
): FilterModel => {
  if (Object.keys(oldModel).length === 0) {
    return newModel;
  }
  const mergedObject: FilterModel = {};
  /* eslint-disable no-restricted-syntax */
  for (const key of Object.keys(oldModel)) {
    // eslint-disable-next-line no-prototype-builtins
    if (newModel.hasOwnProperty(key)) {
      mergedObject[key] = newModel[key];
    }
  }
  return mergedObject;
};

export const singleLineTruncatedCellProps = {
  cellClass: 'ag-cell-truncate',
};

export const getSortModelFromColumnState = (
  columnState: ColumnState[] | undefined,
): SortModelItem[] => {
  if (isNil(columnState)) return [];
  return filterNotNil(
    columnState
      .sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
      .map((col) =>
        isNil(col.sort) ? null : { colId: col.colId, sort: col.sort },
      ),
  );
};
