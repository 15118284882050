import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import { useDeleteSlotsFromRouteMutation } from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import useFetchRoutes from './use-fetch-routes';
import useRouteActions from './use-route-actions';

const useUnassignStopsRouteActions = () => {
  const [
    getRouteByUuid,
    setLastUnassignedData,
    removeSlotsFromRoute,
    lastUnassignedData,
  ] = useDispatchStore(
    (state) => [
      state.getRouteByUuid,
      state.setLastUnassignedData,
      state.removeSlotsFromRoute,
      state.lastUnassignedData,
    ],
    shallow,
  );
  const { updateStopOrder } = useRouteActions();
  const { fetchRoute } = useFetchRoutes();
  const [removeRouteSlot] = useDeleteSlotsFromRouteMutation();

  const unassignStop = async ({
    routeUuid,
    slotUuid,
    stopUuid,
    refetch = true,
    emitMultiplayerEvent = false,
  }: {
    routeUuid: string | undefined;
    slotUuid: string | undefined;
    stopUuid: string | undefined;
    refetch?: boolean;
    emitMultiplayerEvent?: boolean;
  }) => {
    if (isNil(routeUuid) || isNil(slotUuid) || isNil(stopUuid)) return false;
    const route = getRouteByUuid(routeUuid);
    const index = route?.slots.findIndex((slot) => slot.uuid === slotUuid);
    const res = await removeRouteSlot({
      variables: {
        deleteSlotsFromRouteInput: {
          routeSlotUuids: [slotUuid],
          routeUuid,
          emitMultiplayerEvent,
        },
      },
    });
    if (refetch) {
      fetchRoute(routeUuid);
    }
    if (!isNil(res.errors) || isNil(res.data?.deleteSlotsFromRoute)) {
      return false;
    }

    if (!isNil(stopUuid)) {
      setLastUnassignedData({
        routeUuid,
        stopUuid,
        index,
      });
    }
    removeSlotsFromRoute(routeUuid, [slotUuid]);
    return true;
  };

  const undoUnassignStop = async () => {
    if (!isNil(lastUnassignedData)) {
      const route = getRouteByUuid(lastUnassignedData.routeUuid);
      if (!isNil(route)) {
        const res = await updateStopOrder({
          routeUuid: lastUnassignedData.routeUuid,
          endIndex: lastUnassignedData.index ?? route.slots.length,
          stopUuid: lastUnassignedData.stopUuid,
        });
        if (!res) {
          // retry
          await fetchRoute(lastUnassignedData.routeUuid);
          await updateStopOrder({
            routeUuid: lastUnassignedData.routeUuid,
            endIndex: lastUnassignedData.index ?? route.slots.length,
            stopUuid: lastUnassignedData.stopUuid,
          });
        }
      }
      await fetchRoute(lastUnassignedData.routeUuid);
      setLastUnassignedData(undefined);
    }
  };

  return {
    unassignStop,
    undoUnassignStop,
  };
};

export { useUnassignStopsRouteActions };
