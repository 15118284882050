import {
  Accordion,
  AccordionDetails,
  Box,
  Chip,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DocumentTypeSelector from '../../../../common/components/document-type-selector';
import useDocuments from '../../../../common/react-hooks/use-documents';
import {
  ContactForInvoiceFragment,
  ContactsDocument,
  DocumentType,
  InvoiceTransmissionMethod,
  InvoiceType,
  useUpdateCustomerContactMutation,
} from '../../../../generated/graphql';
import AccordionSummary from '../accordion-summary';

const ContactSettingsCard = ({
  contact,
  setIsSaving,
}: {
  contact: ContactForInvoiceFragment;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const [
    defaultInvoiceTransmissionMethod,
    setDefaultInvoiceTransmissionMethod,
  ] = useState<InvoiceTransmissionMethod>();
  const [defaultInvoiceType, setDefaultInvoiceType] = useState<InvoiceType>();
  const [defaultInvoiceDocumentsRequired, setDefaultInvoiceDocumentsRequired] =
    useState<DocumentType[]>([]);
  const [invoiceDocumentAttachments, setInvoiceDocumentAttachments] = useState<
    DocumentType[]
  >([]);
  const [updateCustomerContact] = useUpdateCustomerContactMutation({
    refetchQueries: [ContactsDocument],
  });
  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  useEffect(() => {
    if (contact.__typename === 'CustomerContactEntity' && !loading) {
      setDefaultInvoiceTransmissionMethod(
        contact.defaultInvoiceTransmissionMethod,
      );
      setDefaultInvoiceType(contact.defaultInvoiceType);
      setDefaultInvoiceDocumentsRequired(
        contact.defaultInvoiceDocumentsRequired,
      );
      setInvoiceDocumentAttachments(contact.defaultInvoiceDownloadDocuments);
    }
  }, [documentTypesForSelection, contact, loading]);

  const update = async ({
    newInvoiceTransmissionMethod,
    newInvoiceType,
    newInvoiceDocumentsRequired,
    newInvoiceDownloadDocuments,
  }: {
    newInvoiceTransmissionMethod?: InvoiceTransmissionMethod | undefined;
    newInvoiceType?: InvoiceType | undefined;
    newInvoiceDocumentsRequired?: DocumentType[] | undefined;
    newInvoiceDownloadDocuments?: DocumentType[] | undefined;
  }) => {
    setIsSaving(true);
    await updateCustomerContact({
      variables: {
        input: {
          customerContactUpdateInput: {
            uuid: contact.uuid,
            defaultInvoiceTransmissionMethod: newInvoiceTransmissionMethod,
            defaultInvoiceType: newInvoiceType,
            defaultInvoiceDocumentsRequired: newInvoiceDocumentsRequired,
            defaultInvoiceDownloadDocuments: newInvoiceDownloadDocuments,
          },
        },
      },
    });
  };

  if (loading) {
    return null;
  }

  return (
    <Accordion
      elevation={0}
      sx={{ border: '1px solid', borderColor: theme.palette.borderColor.main }}
    >
      <AccordionSummary>
        <Typography sx={{ marginLeft: '10px' }}>
          {contact.displayName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="column"
          sx={{ width: '100%', marginTop: '10px' }}
          spacing={2}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="transmission-method-label">
                    Transmission Method
                  </InputLabel>
                  <Select
                    labelId="transmission-method-label"
                    onChange={(e) => {
                      setDefaultInvoiceTransmissionMethod(
                        e.target.value as InvoiceTransmissionMethod,
                      );
                      update({
                        newInvoiceTransmissionMethod: e.target
                          .value as InvoiceTransmissionMethod,
                      });
                    }}
                    value={defaultInvoiceTransmissionMethod ?? ''}
                    required
                    size="small"
                    label="Transmission Method"
                  >
                    <MenuItem
                      id={InvoiceTransmissionMethod.None}
                      value={InvoiceTransmissionMethod.None}
                    >
                      {sentenceCase(InvoiceTransmissionMethod.None)}
                    </MenuItem>
                    <MenuItem
                      id={InvoiceTransmissionMethod.Download}
                      value={InvoiceTransmissionMethod.Download}
                    >
                      {sentenceCase(InvoiceTransmissionMethod.Download)}
                    </MenuItem>
                    <MenuItem
                      id={InvoiceTransmissionMethod.Email}
                      value={InvoiceTransmissionMethod.Email}
                    >
                      {sentenceCase(InvoiceTransmissionMethod.Email)}
                    </MenuItem>
                    <MenuItem
                      id={InvoiceTransmissionMethod.EmailAndDownload}
                      value={InvoiceTransmissionMethod.EmailAndDownload}
                    >
                      {sentenceCase(InvoiceTransmissionMethod.EmailAndDownload)}
                    </MenuItem>
                    {!isNil(contact.stediPartnerId) && (
                      <MenuItem
                        id={InvoiceTransmissionMethod.Edi}
                        value={InvoiceTransmissionMethod.Edi}
                      >
                        {sentenceCase(InvoiceTransmissionMethod.Edi)}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="invoice-type-label">Invoice Type</InputLabel>
                  <Select
                    labelId="invoice-type-label"
                    onChange={(e) => {
                      setDefaultInvoiceType(e.target.value as InvoiceType);
                      update({
                        newInvoiceType: e.target.value as InvoiceType,
                      });
                    }}
                    label="Invoice Type"
                    value={defaultInvoiceType ?? ''}
                    required
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                  >
                    {Object.values(InvoiceType).map((type) => (
                      <MenuItem key={type} id={type} value={type}>
                        {sentenceCase(type)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="documents-required-label" shrink>
                    Required Documents
                  </InputLabel>
                  <Select
                    labelId="documents-required-label"
                    multiple
                    displayEmpty
                    onChange={(e) => {
                      setDefaultInvoiceDocumentsRequired(
                        e.target.value as DocumentType[],
                      );
                      update({
                        newInvoiceDocumentsRequired: e.target
                          .value as DocumentType[],
                      });
                    }}
                    value={defaultInvoiceDocumentsRequired}
                    required
                    notched
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                    label="Required Documents"
                    renderValue={(selected) =>
                      selected.length === 0 ? (
                        <Box>None</Box>
                      ) : (
                        <Box>
                          {selected.map((value) => (
                            <Chip key={value} label={sentenceCase(value)} />
                          ))}
                        </Box>
                      )
                    }
                  >
                    {documentTypesForSelection.map((docType) => (
                      <MenuItem key={docType} id={docType} value={docType}>
                        {getDocumentTypeCopy({
                          documentType: docType,
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                  <DocumentTypeSelector
                    value={invoiceDocumentAttachments}
                    label="Documents in Invoice"
                    onChange={(newValue) => {
                      setInvoiceDocumentAttachments(newValue);
                      update({
                        newInvoiceDownloadDocuments: newValue,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ContactSettingsCard);
