import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { shallow } from 'zustand/shallow';
import useMultiplayerStore from '../../common/multiplayer/multiplayer-store';
import {
  chooseForegroundColor,
  stringToColor,
} from '../../common/utils/colors';

const DispatchAvatarPresenceGroup = () => {
  const [myPresenceData, dispatchUserPresenceData] = useMultiplayerStore(
    (state) => [
      state.dispatchUserPresenceData.find(
        (userPresenceData) =>
          userPresenceData.data.connectionId === state.dispatchConnectionId,
      ),
      state.dispatchUserPresenceData.filter(
        (userPresenceData) =>
          userPresenceData.data.connectionId !== state.dispatchConnectionId,
      ),
    ],
    shallow,
  );

  return (
    <AvatarGroup
      max={5}
      sx={{
        '& .MuiAvatar-root': { width: 26, height: 26, fontSize: 10 },
      }}
    >
      {!isNil(myPresenceData) && (
        <Tooltip title={myPresenceData.data.name}>
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: 10,
              bgcolor: stringToColor(myPresenceData.data.connectionId),
              color: chooseForegroundColor(
                stringToColor(myPresenceData.data.connectionId),
              ),
            }}
          >
            You
          </Avatar>
        </Tooltip>
      )}
      {dispatchUserPresenceData.map((userPresenceData) => {
        const avatarColor = stringToColor(userPresenceData.data.connectionId);
        return (
          <Tooltip
            key={userPresenceData.data.connectionId}
            title={userPresenceData.data.name}
          >
            <Avatar
              sx={{
                width: 26,
                height: 26,
                fontSize: 13,
                bgcolor: avatarColor,
                color: chooseForegroundColor(avatarColor),
              }}
            >
              {userPresenceData.data.name.toUpperCase()[0]}
            </Avatar>
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
};

export default DispatchAvatarPresenceGroup;
