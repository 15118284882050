import { Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { ServiceLevelAutocomplete } from '../../../../../../../common/components/service-level-autocomplete';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import { ShallowContactFragment } from '../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { useShouldRateOrder } from '../../../hooks/use-should-rate-order';
import { OrderFormFieldValues } from '../../../types';

const ServiceAutocomplete = ({
  contact,
  label,
  deriveLaneFromTerminals,
  isEditMode,
  isMinimized = false,
}: {
  contact: ShallowContactFragment | undefined;
  label?: string;
  deriveLaneFromTerminals: () => void;
  isEditMode: boolean;
  isMinimized?: boolean;
}) => {
  const { control, setValue, clearErrors } =
    useFormContext<OrderFormFieldValues>();
  const { errors, touchedFields } = useFormState({ control });
  const { companyConfiguration } = useMe();
  const { services } = useServices();
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { shouldRateOrder } = useShouldRateOrder({ detailedStatus });

  const serviceUuid = useWatch({ control, name: `serviceUuid` });

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  useEffect(() => {
    if (!isNil(serviceUuid) && shouldRateOrder && !isEditMode) {
      const service = services.find((s) => s.uuid === serviceUuid);
      if (!isNil(service)) {
        if (service.defaultIsLineHaul === true) {
          setValue('isUsingLineHaul', true);
          deriveLaneFromTerminals();
        }
        if (
          !isNil(service.defaultFulfillmentType) &&
          companyConfiguration?.fulfillmentTypeEnabled === true
        ) {
          setValue('fulfillmentType', service.defaultFulfillmentType);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceUuid]);

  useEffect(() => {
    if (!isNil(contact?.defaultService) && isNil(serviceUuid)) {
      setValue('serviceUuid', contact?.defaultService.uuid);
    }
  }, [contact?.defaultService, serviceUuid, setValue]);

  return (
    <>
      <ServiceLevelAutocomplete
        label={label}
        contact={contact}
        contactUuid={undefined}
        isMinimized={isMinimized}
        selectedServiceUuid={serviceUuid ?? null}
        onChange={(service: ShallowContactFragment['services'][0] | null) => {
          clearErrors();
          setValue('serviceUuid', service?.uuid, { shouldTouch: true });
        }}
        required
        disabled={disabledIfFinalizedOrLater}
        error={!isNil(errors.serviceUuid)}
      />
      {isNil(serviceUuid) && touchedFields?.serviceUuid === true && (
        <Typography variant="caption" color="error">
          Service level is required
        </Typography>
      )}
    </>
  );
};

export default React.memo(ServiceAutocomplete);
