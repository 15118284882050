import { useState } from 'react';
import {
  FilterViewPage,
  SavedFilterViewFragment,
} from '../../../../../generated/graphql';
import { EditSavedViewMenu } from '../../../../saved-filter-views/components/edit-saved-view-menu';
import EditSavedViewModal from '../../../../saved-filter-views/components/edit-saved-view-modal';

interface ViewTabOverflowMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  view: SavedFilterViewFragment;
  userUuid: string;
  pageType: FilterViewPage;
  handleSetIsViewShared: (isShared: boolean) => void;
  handleDeleteSavedView: () => void;
  handleEditSavedViewName: (newName: string) => void;
}

const ViewTabOverflowMenu = ({
  anchorEl,
  onClose,
  view,
  userUuid,
  pageType,
  handleSetIsViewShared,
  handleDeleteSavedView,
  handleEditSavedViewName,
}: ViewTabOverflowMenuProps) => {
  const [showEditSavedViewModal, setShowEditSavedViewModal] = useState(false);
  return (
    <>
      <EditSavedViewMenu
        anchorEl={anchorEl}
        isViewShared={view.isShared}
        isUserViewCreator={view.user.uuid === userUuid}
        pageType={pageType}
        shouldStopPropagation
        handleCloseMenu={onClose}
        handleClickRename={() => {
          setShowEditSavedViewModal(true);
        }}
        handleSetIsViewShared={handleSetIsViewShared}
        handleClickDelete={handleDeleteSavedView}
      />
      {showEditSavedViewModal && (
        <EditSavedViewModal
          open={showEditSavedViewModal}
          setOpen={setShowEditSavedViewModal}
          currentName={view.displayName ?? undefined}
          editSavedView={handleEditSavedViewName}
          shouldStopPropagation
        />
      )}
    </>
  );
};

export default ViewTabOverflowMenu;
