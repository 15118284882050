import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { useContactsSimpleQuery } from '../../generated/graphql';
import useStyles from '../orders/components/styles';
import useAuditReportStore from './audit-report-store';
import OrdersAuditReport from './components/audit-report';

interface AuditReportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const AuditModal = ({ isOpen, setIsOpen }: AuditReportModalProps) => {
  const styles = useStyles();
  const currentBucket = useAuditReportStore((state) => state.currentBucket);
  const setCurrentBucket = useAuditReportStore(
    (state) => state.setCurrentBucket,
  );
  const { data: contactsData } = useContactsSimpleQuery({
    fetchPolicy: 'cache-first',
  });

  const onClose = () => {
    setCurrentBucket(undefined);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={[styles.modal, { width: '95vw', height: '95vh' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            {!isNil(currentBucket) && (
              <Button
                onClick={() => {
                  setCurrentBucket(undefined);
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Order Audit</Typography>
            {!isNil(currentBucket) && (
              <Typography variant="caption" color="text.secondary">
                <span style={{ fontWeight: 'bold' }}>
                  {!isNil(contactsData) &&
                    !isNil(currentBucket?.contactUuid) &&
                    `${
                      contactsData.contacts.find(
                        (contact) =>
                          contact.uuid === currentBucket?.contactUuid,
                      )?.displayName
                    } `}
                </span>
                {!isNil(currentBucket?.contactUuid) ? 'orders' : 'Orders'} for{' '}
                {dayjs(currentBucket.startDate).utc().format('MM/DD')} -{' '}
                {dayjs(currentBucket.endDate).utc().format('MM/DD/YYYY')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <IconButton onClick={onClose} sx={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <OrdersAuditReport />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default React.memo(AuditModal);
