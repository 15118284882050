import { Box, Typography, Fade, Button } from '@mui/material';
import { useState } from 'react';

export type DownloadableReport = {
  label: string;
  onClick: () => void;
  hidden?: boolean;
};

const ReportRow = ({ report }: { report: DownloadableReport }) => {
  const [isHovering, setIsHovering] = useState(false);

  if (report.hidden === true) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
        pt: 0.25,
        pb: 0.25,
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={report.onClick}
    >
      <Typography> {report.label} </Typography>
      <Fade in={isHovering}>
        <Button variant="contained" onClick={report.onClick}>
          Download
        </Button>
      </Fade>
    </Box>
  );
};

export default ReportRow;
