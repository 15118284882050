// eslint-disable-next-line no-restricted-imports
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import EdiMessagesTable from './edi-messages-table';
import FreightSnapTransactionsTable from './freight-snap/freight-snap-transactions-table';
import ReviewEdiOrders from './review-edi-orders/review-edi-orders';

export enum InboundMessagesTabs {
  EDI_MESSAGE_HISTORY,
  REVIEW_ORDERS,

  FREIGHT_SNAP_TRANSACTIONS,
}

const InboundMessagesPage = () => {
  const ffShowFreightSnapTable = useFeatureFlag(
    FeatureFlag.FF_SHOW_FREIGHT_SNAP_TABLE,
  );
  const { companyConfiguration } = useMe();
  const [currentTab, setCurrentTab] = useState<InboundMessagesTabs>();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  useEffect(() => {
    if (
      !isNil(companyConfiguration) &&
      companyConfiguration.requireReviewForEdiOrderCreation
    ) {
      setCurrentTab(InboundMessagesTabs.REVIEW_ORDERS);
    } else {
      setCurrentTab(InboundMessagesTabs.EDI_MESSAGE_HISTORY);
    }
  }, [companyConfiguration]);

  useEffect(() => {
    if (typeof tab === 'string') {
      const tabIndex = parseInt(tab, 10);
      if (tabIndex === InboundMessagesTabs.REVIEW_ORDERS) {
        setCurrentTab(InboundMessagesTabs.REVIEW_ORDERS);
      } else if (tabIndex === InboundMessagesTabs.EDI_MESSAGE_HISTORY) {
        setCurrentTab(InboundMessagesTabs.EDI_MESSAGE_HISTORY);
      }
    }
  }, [tab]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'white',
      }}
    >
      <Grid
        container
        sx={{ display: 'flex', height: '100%', overflowY: 'scroll' }}
        alignItems="stretch"
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Stack direction="column" spacing={1} height="100%">
            <Grid
              container
              spacing={0}
              sx={{ padding: '15px', paddingBottom: '0px' }}
            >
              <Grid item xs={12}>
                <Tabs
                  orientation="horizontal"
                  variant="scrollable"
                  value={currentTab}
                  onChange={(e, newIndex) => {
                    setCurrentTab(newIndex);
                  }}
                  aria-label="invoice page tabs"
                  sx={{
                    marginTop: '-10px',
                  }}
                >
                  {!isNil(companyConfiguration) &&
                    companyConfiguration.requireReviewForEdiOrderCreation && (
                      <Tab
                        value={InboundMessagesTabs.REVIEW_ORDERS}
                        label="Review outstanding EDI/API orders"
                      />
                    )}
                  <Tab
                    value={InboundMessagesTabs.EDI_MESSAGE_HISTORY}
                    label="EDI message history"
                  />

                  {ffShowFreightSnapTable && (
                    <Tab
                      value={InboundMessagesTabs.FREIGHT_SNAP_TRANSACTIONS}
                      label="Freight Snap"
                    />
                  )}
                </Tabs>
              </Grid>
            </Grid>
            {currentTab === InboundMessagesTabs.EDI_MESSAGE_HISTORY && (
              <TabPanel
                selectedValue={currentTab}
                panelValue={InboundMessagesTabs.EDI_MESSAGE_HISTORY}
              >
                <EdiMessagesTable />
              </TabPanel>
            )}
            {!isNil(companyConfiguration) &&
              companyConfiguration.requireReviewForEdiOrderCreation &&
              currentTab === InboundMessagesTabs.REVIEW_ORDERS && (
                <TabPanel
                  selectedValue={currentTab}
                  panelValue={InboundMessagesTabs.REVIEW_ORDERS}
                >
                  <ReviewEdiOrders />
                </TabPanel>
              )}
            {currentTab === InboundMessagesTabs.FREIGHT_SNAP_TRANSACTIONS &&
              ffShowFreightSnapTable && (
                <TabPanel
                  selectedValue={currentTab}
                  panelValue={InboundMessagesTabs.FREIGHT_SNAP_TRANSACTIONS}
                >
                  <FreightSnapTransactionsTable />
                </TabPanel>
              )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InboundMessagesPage;
