import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isNil, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import {
  PicklistsFragment,
  useAssignOutboundReferenceNumbersToStorageUnitsMutation,
  useUpsertPicklistMutation,
} from '../../../../../generated/graphql';
import usePicklistForm, {
  PicklistFormValues,
} from '../../../forms/storage-units/use-picklist-form';
import PickListPickedTable from '../pick-list/pick-list-picked-table';
import PickListItemGroupsTable from '../pick-list/pick-list-storage-units-table';

interface PickListModalProps {
  isCustomerPortal?: boolean;
  initialPicklist?: PicklistsFragment | null;
  open: boolean;
  onClose: () => void;
  onUpsertPicklist: (picklist: PicklistsFragment) => void;
}

const PickListModal = ({
  isCustomerPortal = false,
  initialPicklist,
  open,
  onClose,
  onUpsertPicklist,
}: PickListModalProps) => {
  const isEditing = !isNil(initialPicklist);
  const [
    createPicklistSuccessSnackbarVisible,
    setCreatePicklistSuccessSnackbarVisible,
  ] = useState(false);

  const [
    createPicklistErrorSnackbarVisible,
    setCreatePicklistErrorSnackbarVisible,
  ] = useState(false);

  const [
    assignOutboundReferenceNumbersToStorageUnits,
    { loading: assignOutboundReferenceNumbersToStorageUnitsLoading },
  ] = useAssignOutboundReferenceNumbersToStorageUnitsMutation();
  const [
    upsertPicklist,
    { data: upsertPicklistData, loading: upsertPicklistLoading },
  ] = useUpsertPicklistMutation();

  const form = usePicklistForm();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = form;
  const resetFormToEmptyState = () => {
    reset({
      outboundReferenceNumber: '',
      pickedItemGroups: [],
      date: new Date(),
      itemGroupsRemovedFromPicklist: [],
    });
  };
  useEffect(() => {
    if (!isNil(initialPicklist) && open) {
      reset({
        date: initialPicklist.date,
        outboundReferenceNumber:
          initialPicklist.outboundReferenceNumber ?? undefined,
        pickedItemGroups: filterNotNil(
          initialPicklist.picklistItemGroups.map(
            ({ itemGroup: { uuid, item, storageUnit }, pickedQuantity }) => {
              if (storageUnit?.storageOrder == null) return null;
              return {
                uuid,
                item,
                storageUnit,
                quantity: pickedQuantity,
                storageOrder: storageUnit.storageOrder,
              };
            },
          ),
        ),
        itemGroupsRemovedFromPicklist: [],
      });
    } else {
      resetFormToEmptyState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    resetFormToEmptyState();
    onClose();
  };

  const onSubmit: SubmitHandler<PicklistFormValues> = async (data) => {
    const uuids = uniq(
      filterNotNil(
        data.pickedItemGroups.map((itemGroup) => itemGroup.storageUnit?.uuid),
      ),
    );
    const { outboundReferenceNumber, date, pickedItemGroups } = data;
    try {
      await assignOutboundReferenceNumbersToStorageUnits({
        variables: {
          assignOutboundReferenceNumbersToStorageUnitsInput: {
            uuids,
            outboundReferenceNumber,
          },
        },
      });
      const res = await upsertPicklist({
        variables: {
          upsertPicklistInput: {
            id: initialPicklist?.id,
            outboundReferenceNumber,
            date,
            itemGroups: pickedItemGroups.map((itemGroup) => ({
              itemGroupUuid: itemGroup.uuid,
              quantity: itemGroup.quantity,
            })),
          },
        },
      });
      if (!isNil(res.data?.upsertPicklist.error)) {
        setCreatePicklistErrorSnackbarVisible(true);
      } else {
        setCreatePicklistSuccessSnackbarVisible(true);
        const picklist = res.data?.upsertPicklist.picklist;
        if (!isNil(picklist)) {
          await onUpsertPicklist(picklist);
        }
        handleClose();
      }
    } catch (e) {
      setCreatePicklistErrorSnackbarVisible(true);
    }
  };

  const pickItemGroupsComponent = (
    <Grid container spacing={1}>
      <Grid item xs={4} mt={1}>
        <InputLabel>
          <Stack direction="row" gap={1} alignItems="center">
            Outbound ref #
            <Tooltip title="The reference # of the order that the units are being picked for">
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </InputLabel>
        <Stack direction="row" gap={1}>
          <Controller
            name="outboundReferenceNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack>
                <TextField
                  name="name"
                  size="small"
                  value={value}
                  required
                  onChange={onChange}
                  error={!isNil(errors.outboundReferenceNumber)}
                  placeholder='e.g. "ORD-1234"'
                  sx={{ minWidth: 250 }}
                />
                {!isNil(errors.outboundReferenceNumber) && (
                  <FormHelperText sx={{ color: '#D32F2F' }}>
                    {errors.outboundReferenceNumber?.message ?? ''}
                  </FormHelperText>
                )}
              </Stack>
            )}
          />
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack>
                <DatePicker
                  onChange={(newDate) => onChange(newDate?.toString())}
                  renderInput={(props) => <TextField size="small" {...props} />}
                  value={value}
                />
                {!isNil(errors.date) && (
                  <FormHelperText sx={{ color: '#D32F2F' }}>
                    {errors.date?.message ?? ''}
                  </FormHelperText>
                )}
              </Stack>
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={6}>
        <PickListItemGroupsTable />
      </Grid>
      <Grid item xs={6}>
        <PickListPickedTable
          isCustomerPortal={isCustomerPortal}
          initialPicklist={initialPicklist}
        />
      </Grid>
    </Grid>
  );

  return (
    <FormProvider {...form}>
      <Dialog fullWidth maxWidth={false} open={open}>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={createPicklistSuccessSnackbarVisible}
          onClose={() => setCreatePicklistSuccessSnackbarVisible(false)}
        >
          <Alert severity="success">
            Successfully {isEditing ? 'updated' : 'created'} picklist
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={createPicklistErrorSnackbarVisible}
          onClose={() => setCreatePicklistErrorSnackbarVisible(false)}
        >
          <Alert severity="error">
            {upsertPicklistData?.upsertPicklist.error ??
              `Error ${isEditing ? 'updating' : 'creating'} picklist`}
          </Alert>
        </Snackbar>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            {isEditing ? 'Edit pick ticket' : 'Create pick ticket'}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>{pickItemGroupsComponent}</DialogContent>
        <DialogActions>
          <Stack direction="row" gap={1} justifyContent="center">
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{ mr: 1 }}
              disabled={
                assignOutboundReferenceNumbersToStorageUnitsLoading ||
                upsertPicklistLoading
              }
            >
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default PickListModal;
