import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  SelectChangeEvent,
  Checkbox,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { SameDayDispatchRoutesQuery } from '../../../generated/graphql';

const SelectMultipleRoutes = ({
  routeUuidsToShow,
  setRouteUuidsToShow,
  routesData,
}: {
  routeUuidsToShow: string[];
  setRouteUuidsToShow: Dispatch<SetStateAction<string[]>>;
  routesData: SameDayDispatchRoutesQuery | undefined;
}) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setRouteUuidsToShow(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel>Filter by route</InputLabel>
      <Select
        multiple
        size="small"
        value={routeUuidsToShow}
        onChange={handleChange}
        input={<OutlinedInput label="Filter by route" />}
        renderValue={(selected) =>
          routesData?.routes
            .filter((route) => selected.includes(route.uuid))
            .map((route) => route.name)
            .join(', ')
        }
      >
        {routesData?.routes.map((route) => (
          <MenuItem key={route.uuid} value={route.uuid}>
            <Checkbox checked={routeUuidsToShow.indexOf(route.uuid) > -1} />
            <ListItemText primary={route.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMultipleRoutes;
