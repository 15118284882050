import { useDispatchTableColorsQuery } from '../../generated/graphql';

const useDispatchTableColors = () => {
  const {
    data: dispatchTableColorsData,
    loading,
    refetch: refetchDispatchTableColors,
  } = useDispatchTableColorsQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    loadingDispatchTableColors: loading,
    dispatchTableColors: dispatchTableColorsData?.dispatchTableColors,
    refetchDispatchTableColors,
  };
};

export default useDispatchTableColors;
