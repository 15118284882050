import { isEmpty, isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import {
  AGGridFilterType,
  DateFilterOption,
  FilterModel,
  MultiSelectFilterValue,
} from './enums/order-filters';
import FilterButtonForToolbar from './filter-button-for-toolbar';

export interface SelectedFilterButtonForToolbarProps {
  filterModel: FilterModel | undefined;
  prependText: string | undefined;
  keyName: string;
  filterName?: string;
  handleDelete: () => void;
  handleSelect: () => void;
  currentDefaultTabFilterModel?: FilterModel;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
const SelectedFilterButtonForToolbar = ({
  filterModel,
  prependText,
  keyName,
  filterName,
  handleDelete,
  handleSelect,
  currentDefaultTabFilterModel,
}: SelectedFilterButtonForToolbarProps) => {
  if (isNil(filterModel) || Object.keys(filterModel).length === 0) {
    return null;
  }
  const currFilter = filterModel[keyName];
  const defaultFilter = !isNil(currentDefaultTabFilterModel)
    ? currentDefaultTabFilterModel[keyName]
    : null;

  const filterType = currFilter.filterType as AGGridFilterType;

  switch (filterType) {
    case AGGridFilterType.BOOLEAN: {
      return (
        <FilterButtonForToolbar
          prependText={prependText}
          isDefaultFilter={!isNil(defaultFilter)}
          field={keyName}
          filterName={filterName}
          filterType={AGGridFilterType.BOOLEAN}
          filterValues={[currFilter.value]}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      );
    }
    case AGGridFilterType.SINGLE_SELECT: {
      if (isEmpty(currFilter.value)) {
        return null;
      }
      return (
        <FilterButtonForToolbar
          prependText={prependText}
          isDefaultFilter={!isNil(defaultFilter)}
          field={keyName}
          filterName={filterName}
          filterValues={[currFilter.displayValue]}
          filterType={AGGridFilterType.SINGLE_SELECT}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      );
    }
    case AGGridFilterType.MULTI_SELECT: {
      if (isEmpty(currFilter.values)) {
        return null;
      }
      const op = !isNil(currFilter.fieldLevelFilterOperator)
        ? currFilter.fieldLevelFilterOperator
        : currFilter.filterOperator;
      return (
        <FilterButtonForToolbar
          prependText={prependText}
          isDefaultFilter={!isNil(defaultFilter)}
          field={keyName}
          filterName={filterName}
          filterValues={currFilter.values.map(
            (filterValue: MultiSelectFilterValue) => filterValue.displayValue,
          )}
          filterType={AGGridFilterType.MULTI_SELECT}
          delimiter={` ${op?.toLowerCase()} `}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      );
    }
    case AGGridFilterType.DATE: {
      if (currFilter.value === DateFilterOption.ANY_DAY) {
        return null;
      }
      return (
        <FilterButtonForToolbar
          prependText={prependText}
          isDefaultFilter={!isNil(defaultFilter)}
          field={keyName}
          filterName={filterName}
          filterType={AGGridFilterType.DATE}
          filterValues={[currFilter.value]}
          dateRangeStart={currFilter.startDate ?? null}
          dateRangeEnd={currFilter.endDate ?? null}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      );
    }
    case AGGridFilterType.SET:
      return (
        <FilterButtonForToolbar
          prependText={prependText}
          isDefaultFilter={!isNil(defaultFilter)}
          field={keyName}
          filterName={filterName}
          filterType={AGGridFilterType.SET}
          filterValues={[...currFilter.values]}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      );
    case AGGridFilterType.TEXT:
      if (isEmpty(currFilter.value)) {
        return null;
      }
      return (
        <FilterButtonForToolbar
          prependText={prependText}
          isDefaultFilter={!isNil(defaultFilter)}
          field={keyName}
          filterName={filterName}
          filterType={AGGridFilterType.TEXT}
          filterValues={[currFilter.value]}
          handleDelete={handleDelete}
          handleSelect={handleSelect}
        />
      );
    default:
      return exhaustive(filterType);
  }
};

export default SelectedFilterButtonForToolbar;
