import { useMemo } from 'react';
import { TariffGroupType, useTariffChainsQuery } from '../../generated/graphql';

export const useTariffChains = () => {
  const { data: tariffChainsData, loading } = useTariffChainsQuery({
    variables: {
      findTariffChainsInput: {
        isArchived: false,
      },
    },
  });

  const ordinaryTariffChains = useMemo(() => {
    return tariffChainsData?.tariffChains?.filter(
      (tariffChain) => tariffChain.tariffGroupType === TariffGroupType.Ordinary,
    );
  }, [tariffChainsData]);

  const lineHaulTariffChains = useMemo(() => {
    return tariffChainsData?.tariffChains?.filter(
      (tariffChain) => tariffChain.tariffGroupType === TariffGroupType.LineHaul,
    );
  }, [tariffChainsData]);

  const transferTariffChains = useMemo(() => {
    return tariffChainsData?.tariffChains?.filter(
      (tariffChain) => tariffChain.tariffGroupType === TariffGroupType.Transfer,
    );
  }, [tariffChainsData]);

  const pointToPointTariffChains = useMemo(() => {
    return tariffChainsData?.tariffChains?.filter(
      (tariffChain) =>
        tariffChain.tariffGroupType === TariffGroupType.PointToPoint,
    );
  }, [tariffChainsData]);

  return {
    ordinaryTariffChains,
    lineHaulTariffChains,
    transferTariffChains,
    pointToPointTariffChains,
    loading,
  };
};
