import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { BillingMethod } from '../../../../common/types';
import {
  PermissionResource,
  SettlementDeductionTypeFragment,
  useUpdateSettlementDeductionTypeMutation,
} from '../../../../generated/graphql';

interface SettlementDeductionTypeRowProps {
  settlementDeductionType: SettlementDeductionTypeFragment;
  onDelete: () => void;
}

const SettlementDeductionTypeRow = ({
  settlementDeductionType,
  onDelete,
}: SettlementDeductionTypeRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const [updateSettlementDeductionType] =
    useUpdateSettlementDeductionTypeMutation();
  const [isHovering, setIsHovering] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [nameInput, setNameInput] = useState(settlementDeductionType.name);
  const [rateInput, setRateInput] = useState('');
  const [billingMethod, setBillingMethod] = useState<BillingMethod>(
    BillingMethod.Percentage,
  );

  useEffect(() => {
    if (!isNil(settlementDeductionType.flatRate)) {
      setRateInput(settlementDeductionType.flatRate.toString());
      setBillingMethod(BillingMethod.FlatRate);
    } else {
      setRateInput(
        !isNil(settlementDeductionType.percentageRate)
          ? settlementDeductionType.percentageRate.toString()
          : '',
      );
      setBillingMethod(BillingMethod.Percentage);
    }
  }, [settlementDeductionType]);

  const update = async ({
    newBillingMethod,
  }: {
    newBillingMethod?: BillingMethod | undefined;
  }) => {
    // eslint-disable-next-line no-param-reassign
    newBillingMethod = newBillingMethod ?? billingMethod;
    if (!isEmpty(nameInput)) {
      await updateSettlementDeductionType({
        variables: {
          settlementDeductionTypeUpdateInput: {
            uuid: settlementDeductionType.uuid,
            name: nameInput,
            percentageRate:
              newBillingMethod === BillingMethod.Percentage
                ? parseFloat(rateInput ?? 0)
                : null,
            flatRate:
              newBillingMethod === BillingMethod.FlatRate
                ? parseFloat(rateInput ?? 0)
                : null,
          },
        },
      });
    }
  };

  return (
    <TableRow
      hover
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          {showInput ? (
            <TextField
              size="small"
              label="Name"
              value={nameInput}
              required
              onChange={(e) => setNameInput(e.target.value)}
              onBlur={() => {
                update({});
                setShowInput(false);
              }}
              disabled={!canWriteCompanyBilling}
            />
          ) : (
            <>
              <Typography>{nameInput}</Typography>
              {isHovering && (
                <IconButton
                  onClick={() => {
                    setShowInput(true);
                  }}
                  disabled={!canWriteCompanyBilling}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          <FormControl>
            <TextField
              disabled={!canWriteCompanyBilling}
              size="small"
              type="number"
              value={rateInput}
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              onChange={(event) => {
                setRateInput(event.target.value);
              }}
              onBlur={() => {
                update({});
              }}
              InputProps={
                billingMethod === BillingMethod.Percentage
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }
              }
            />
          </FormControl>
          <Select
            disabled={!canWriteCompanyBilling}
            size="small"
            value={billingMethod}
            required
            onChange={(event) => {
              const newBillingMethod = event.target.value as BillingMethod;
              setBillingMethod(newBillingMethod);
              update({ newBillingMethod });
            }}
          >
            <MenuItem value={BillingMethod.Percentage}>
              {sentenceCase(BillingMethod.Percentage)}
            </MenuItem>
            <MenuItem value={BillingMethod.FlatRate}>
              {sentenceCase(BillingMethod.FlatRate)}
            </MenuItem>
          </Select>
        </Stack>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={onDelete} disabled={!canWriteCompanyBilling}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default SettlementDeductionTypeRow;
