import {
  Box,
  Button,
  Dialog,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import useTags from '../../common/react-hooks/use-tags';
import { TagsDocument, useDeleteTagMutation } from '../../generated/graphql';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    p: 4,
  } as SxProps,
};

const DeleteTagModal = ({
  isOpen,
  setIsOpen,
  uuid,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  uuid: string;
}) => {
  const theme = useTheme();
  const { refetchTagsData } = useTags();
  const [deleteTag] = useDeleteTagMutation({
    refetchQueries: [TagsDocument],
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h5">
          Are you sure you want to delete this tag?
        </Typography>
        <Typography variant="h6">
          This will delete the tag from all orders.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button variant="contained" onClick={() => setIsOpen(false)}>
            No
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.redColor.main }}
            onClick={async () => {
              await deleteTag({ variables: { uuid } });
              refetchTagsData();
              setIsOpen(false);
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteTagModal;
