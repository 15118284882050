import { StatsigProvider } from '@statsig/react-bindings';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import useMe from '../common/react-hooks/use-me';
import useGlobalStore from '../layouts/dashboard/global-store';
import useFeatureFlagPolling from './hooks/use-feature-flag-pollilng';
import useStatsigClient, {
  StatsigUserDetails,
} from './hooks/use-statsig-client';

interface AsyncStatsigProviderProps {
  children: React.ReactNode;
}
const AsyncStatsigProvider = ({ children }: AsyncStatsigProviderProps) => {
  const [setStatsigLoading] = useGlobalStore(
    (state) => [state.setStatsigLoading],
    shallow,
  );
  const { userUuid, email, companyUuid } = useMe();
  const userDetails: StatsigUserDetails = {
    userUuid,
    userEmail: email,
    companyUuid,
  };
  const client = useStatsigClient(userDetails);
  useFeatureFlagPolling(client, userDetails);

  useEffect(() => {
    if (isNil(client)) {
      return;
    }
    const initializeStatsig = async () => {
      setStatsigLoading(true);
      try {
        await client.initializeAsync();
      } catch (err) {
        console.error('Error initializing statsig', err);
      } finally {
        setStatsigLoading(false);
      }
    };
    initializeStatsig();
  }, [client, setStatsigLoading]);

  if (isNil(client)) {
    return children;
  }
  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};

export default AsyncStatsigProvider;
