/* eslint-disable no-param-reassign */
import { isNil, unzip } from 'lodash';
import { exhaustive } from 'shared/switch';
import { MakeCreateInputFieldsRequired } from 'shared/types';
import { Scalar, percent } from 'shared/units/scalar';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import apolloClient from '../../../../../apollo-client';
import {
  CreateContactToServicesMappingInput,
  CreateTariffGroupInput,
  RateType,
  TariffGroupDocument,
  TariffGroupQuery,
  TariffGroupQueryVariables,
  TariffGroupScope,
  TariffGroupType,
  TariffType,
  TariffZoneLocationType,
  TariffZonesDocument,
  TariffZonesForGroupDocument,
  TariffZonesForGroupQuery,
  TariffZonesForGroupQueryVariables,
  TariffZonesQuery,
  TariffZonesQueryVariables,
  TariffZoneType,
  UpdateTariffGroupInput,
} from '../../../../../generated/graphql';
import { initialOverageRates, MAX_MATRIX_SIZE, OverageRates } from '../types';
import {
  buildGlobalTariffOverageRateCreates,
  buildTariffCreateInputs,
  buildTariffUpsertInputs,
  buildTariffZoneGroupChanges,
  getGlobalOverageRates,
  getMileRange,
  getMinMaxAmountValues,
  getNewTariffZoneChanges,
  getRateMatrix,
  getUseGlobalOverageRates,
  getYRange,
  getZoneBasedOverageRates,
  LocationZoneData,
  TariffGroupState,
  TariffModalMode,
  getSettlementPercentageRateValues,
} from './tariff-group-controller';

interface MinMaxAmountValue {
  min?: number | undefined;
  max?: number | undefined;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type TariffGroupActions = {
  setZoneType: (zoneType: TariffZoneType) => void;
  setRateType: (rateType: RateType) => void;
  setTariffType: (tariffType: TariffType) => void;
  setRateMatrixSingleCoordinate: (
    x: number,
    y: number,
    rate: number | undefined,
  ) => void;
  deleteRowFromRateMatrix: (rowIndex: number) => void;
  addYRangeValue: () => void;
  addXRangeValue: () => void;
  setXRangeValues: (newXRangeValues: (number | undefined)[]) => void;
  setYRangeValues: (newYRangeValues: (number | undefined)[]) => void;
  setLocationZonesData: (locationZones: LocationZoneData[]) => void;
  setSelectedContactUuids: (contactUuids: string[]) => void;
  setMinMaxAmountValue: (
    index: number,
    minMaxAmount: MinMaxAmountValue,
  ) => void;
  setMinMaxAmountValues: (minMaxAmountValues: MinMaxAmountValue[]) => void;
  setSettlementPercentageRateValue: (
    index: number,
    settlementPercentageRate: number | null,
  ) => void;
  setSettlementPercentageRateValues: (
    settlementPercentageRateValues: (Scalar | null)[],
  ) => void;
  setName: (name: string) => void;
  setTariffGroupType: (tariffGroupType: TariffGroupType) => void;
  setFuelProfileUuid: (uuid: string | null | undefined) => void;
  setTerminalUuids: (uuids: string[]) => void;
  setServiceUuids: (uuids: string[]) => void;
  setVehicleTypeUuids: (uuids: string[]) => void;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setUseDeficitBilling: (useDeficitBilling: boolean) => void;
  setRateMatrix: (newMatrix: (number | undefined)[][]) => void;
  setTariffGroupScope: (scope: TariffGroupScope) => void;
  setContactToServiceMapping: (
    contactUuid: string,
    serviceUuids: string[],
  ) => void;
  setModalMode: (modalMode: TariffModalMode) => void;
  setMileOverageRateValue: (val: number | null) => void;
  setMileOverageFlatRateValue: (val: number | null) => void;
  setMileOverageApplicableAboveValue: (val: number | null) => void;
  setPieceOverageRateValue: (val: number | null) => void;
  setPieceOverageFlatRateValue: (val: number | null) => void;
  setPieceOverageApplicableAboveValue: (val: number | null) => void;
  setWeightOverageRateValue: (val: number | null) => void;
  setWeightOverageFlatRateValue: (val: number | null) => void;
  setWeightOverageApplicableAboveValue: (val: number | null) => void;
  setZoneBasedOverageRates: (zoneBasedOverageRates: OverageRates[]) => void;
  setUseGlobalOverageRates: (useGlobalOverageRates: boolean) => void;
  setUseActualWeight: (useActualWeight: boolean) => void;
  setParentTariffGroupUuid: (uuid: string) => void;
  setRateMultiplier: (multiplier: number) => void;
  removeKeyFromContactToServiceMapping: (contactUuid: string) => void;
  removeServiceFromContactToServiceMapping: (
    contactUuid: string,
    serviceUuid: string,
  ) => void;
  buildCreateTariffGroupInputFromState: () => CreateTariffGroupInput;
  resetStore: () => void;
  resetToOriginalTariffGroup: () => void;
  initStoreWithExistingTariffGroup: (tariffGroupUuid: string) => void;
  buildUpdateTariffGroupInputFromState: (
    uuid: string,
  ) => UpdateTariffGroupInput;
  selectAllContactUuids: (uuids: string[]) => void;
  setUseZoneGroups: (useZoneGroups: boolean) => void;
  setTariffZoneGroupId: (tariffZoneGroupId: string | null) => void;
};

const initialState: TariffGroupState = {
  tariffGroupUuid: undefined,
  selectedContactUuids: [],
  rateMatrix: new Array(MAX_MATRIX_SIZE)
    .fill(0)
    .map(() => new Array(MAX_MATRIX_SIZE).fill(undefined)),
  tariffType: TariffType.NoUnits,
  zoneType: TariffZoneType.Universal,
  rateType: RateType.Flat,
  xRangeValues: [],
  yRangeValues: [],
  locationZonesData: [],
  minMaxAmountValues: [],
  settlementPercentageRateValues: [],
  globalOverageRates: initialOverageRates,
  zoneBasedOverageRates: [],
  useGlobalOverageRates: true,
  name: null,
  tariffGroupType: TariffGroupType.Ordinary,
  fuelProfileUuid: null,
  existingTariffGroupBeforeChanges: null,
  tariffGroupScope: null,
  contactUuidToServiceUuidsMapping: {},
  modalMode: null,
  useActualWeight: false,
  parentTariffGroupUuid: null,
  rateMultiplier: null,
  startDate: null,
  endDate: null,
  useDeficitBilling: false,
  useZoneGroups: false,
  tariffZoneGroupId: null,
  allZonesInGroup: [],
  terminalUuids: [],
  serviceUuids: [],
  vehicleTypeUuids: [],
  isLoadingTariffGroup: false,
};

export const convertTariffGroupTypeToTariffZoneLocationType = (
  tariffGroupType: TariffGroupType,
): TariffZoneLocationType => {
  switch (tariffGroupType) {
    case TariffGroupType.LineHaul:
      return TariffZoneLocationType.LineHaulLane;
    case TariffGroupType.Ordinary:
      return TariffZoneLocationType.Zipcode;
    case TariffGroupType.Transfer:
      return TariffZoneLocationType.Terminal;
    case TariffGroupType.PointToPoint:
      return TariffZoneLocationType.Zipcode;
    default:
      return exhaustive(tariffGroupType);
  }
};

const recalculateLocationZonesAfterRemovingContact = async (
  currentState: TariffGroupState,
  contactUuid: string,
) => {
  let newZones: LocationZoneData[] | undefined;

  if (
    currentState.modalMode === TariffModalMode.CREATE &&
    Object.keys(currentState.contactUuidToServiceUuidsMapping).length === 1
  ) {
    // get the contact of the zone
    const globalZonesQuery = await apolloClient.query<
      TariffZonesQuery,
      TariffZonesQueryVariables
    >({
      query: TariffZonesDocument,
      variables: {
        tariffZoneType: TariffZoneType.Location,
        contactUuid: null,
      },
    });

    newZones =
      globalZonesQuery.data?.tariffZones
        .map((zone) => {
          return { uuid: zone.uuid, name: zone.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  } else if (
    currentState.modalMode === TariffModalMode.CREATE &&
    Object.keys(currentState.contactUuidToServiceUuidsMapping).length === 2
  ) {
    const remainingContactUuid = Object.keys(
      currentState.contactUuidToServiceUuidsMapping,
    ).find((k) => k !== contactUuid);
    const contactZonesQuery = await apolloClient.query<
      TariffZonesQuery,
      TariffZonesQueryVariables
    >({
      query: TariffZonesDocument,
      variables: {
        tariffZoneType: TariffZoneType.Location,
        contactUuid: remainingContactUuid,
      },
    });

    newZones =
      contactZonesQuery.data?.tariffZones
        .map((zone) => {
          return { uuid: zone.uuid, name: zone.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }
  return newZones;
};

const recalculateLocationZonesAfterAddingContact = async (
  currentState: TariffGroupState,
  contactUuid: string,
) => {
  let newZones: LocationZoneData[] | undefined;
  if (
    currentState.modalMode === TariffModalMode.CREATE &&
    Object.keys(currentState.contactUuidToServiceUuidsMapping).length === 0
  ) {
    // get the contact of the zone
    const contactZonesQuery = await apolloClient.query<
      TariffZonesQuery,
      TariffZonesQueryVariables
    >({
      query: TariffZonesDocument,
      variables: {
        tariffZoneType: TariffZoneType.Location,
        tariffZoneLocationType:
          currentState.tariffGroupType === TariffGroupType.Transfer
            ? TariffZoneLocationType.Terminal
            : TariffZoneLocationType.Zipcode,
        contactUuid,
      },
    });

    newZones =
      contactZonesQuery.data?.tariffZones
        .map((zone) => {
          return { uuid: zone.uuid, name: zone.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }
  // case where this contact uuid doesn't exist in the mapping yet, we need to use global zone
  else if (
    currentState.modalMode === TariffModalMode.CREATE &&
    Object.keys(currentState.contactUuidToServiceUuidsMapping).length === 1 &&
    isNil(currentState.contactUuidToServiceUuidsMapping[contactUuid])
  ) {
    const globalZonesQuery = await apolloClient.query<
      TariffZonesQuery,
      TariffZonesQueryVariables
    >({
      query: TariffZonesDocument,
      variables: {
        tariffZoneType: TariffZoneType.Location,
        tariffZoneLocationType:
          currentState.tariffGroupType === TariffGroupType.Transfer
            ? TariffZoneLocationType.Terminal
            : TariffZoneLocationType.Zipcode,
        contactUuid: null,
      },
    });

    newZones =
      globalZonesQuery.data?.tariffZones
        .map((zone) => {
          return { uuid: zone.uuid, name: zone.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }

  return newZones;
};

const getTariffZonesForGroup = async (
  tariffZoneGroupId: string,
): Promise<LocationZoneData[]> => {
  const queryRes = await apolloClient.query<
    TariffZonesForGroupQuery,
    TariffZonesForGroupQueryVariables
  >({
    query: TariffZonesForGroupDocument,
    variables: {
      tariffZoneGroupId,
    },
  });
  return queryRes.data.tariffZoneGroup.tariffZones
    .map((zone) => {
      return {
        uuid: zone.uuid,
        name: zone.name,
        isLhLaneActive: zone.lineHaulLane?.isActive,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

const useTariffGroupStore = create(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  immer<TariffGroupState & TariffGroupActions>((set, get) => ({
    ...initialState,
    setZoneType: (zoneType: TariffZoneType) =>
      set((state) => {
        state.zoneType = zoneType;
      }),
    setRateType: (rateType: RateType) =>
      set((state) => {
        state.rateType = rateType;
      }),
    setTariffType: (tariffType: TariffType) =>
      set((state) => {
        state.tariffType = tariffType;
      }),
    setRateMatrixSingleCoordinate: (
      x: number,
      y: number,
      rate: number | undefined,
    ) =>
      set((state) => {
        const column = state.rateMatrix[x];
        if (!isNil(column)) column[y] = rate;
      }),
    addYRangeValue: () =>
      set((state) => {
        const newYRangeValues = state.yRangeValues;
        if (newYRangeValues.length < MAX_MATRIX_SIZE) {
          newYRangeValues.push(undefined);
        }
      }),
    addXRangeValue: () =>
      set((state) => {
        const newXRangeValues = state.xRangeValues;
        if (newXRangeValues.length < MAX_MATRIX_SIZE) {
          newXRangeValues.push(undefined);
          state.minMaxAmountValues.push({
            min: undefined,
            max: undefined,
          });
          state.settlementPercentageRateValues.push(null);
          state.zoneBasedOverageRates.push(initialOverageRates);
        }
      }),
    deleteRowFromRateMatrix: (rowIndex: number) =>
      set((state) => {
        const newRange = state.yRangeValues;
        const newRateMatrix = state.rateMatrix;
        newRange.splice(rowIndex, 1);
        newRateMatrix.splice(rowIndex, 1);

        newRange[newRange.length - 1] = undefined;
      }),
    setXRangeValues: (newXRangeValues: (number | undefined)[]) =>
      set((state) => {
        state.xRangeValues = newXRangeValues;
      }),
    setYRangeValues: (newYRangeValues: (number | undefined)[]) =>
      set((state) => {
        state.yRangeValues = newYRangeValues;
      }),
    setRateMatrix: (newMatrix: (number | undefined)[][]) =>
      set((state) => {
        state.rateMatrix = newMatrix;
      }),
    setLocationZonesData: async (locationZones: LocationZoneData[]) => {
      const currentState = get();

      const transposedRateMatrix = unzip(currentState.rateMatrix);

      const ratesByZoneUuid: Record<string, (number | undefined)[]> = {};
      for (
        let zoneIdx = 0;
        zoneIdx < currentState.locationZonesData.length;
        zoneIdx += 1
      ) {
        const zone = currentState.locationZonesData[zoneIdx];
        if (isNil(zone)) {
          continue;
        }
        const ratesForZone = transposedRateMatrix[zoneIdx];
        if (isNil(ratesForZone)) {
          continue;
        }
        ratesByZoneUuid[zone.uuid] = ratesForZone;
      }

      const newRateMatrix: (number | undefined)[][] = new Array(MAX_MATRIX_SIZE)
        .fill(undefined)
        .map(() => new Array(MAX_MATRIX_SIZE).fill(undefined));

      for (let zoneIdx = 0; zoneIdx < locationZones.length; zoneIdx += 1) {
        const zone = locationZones[zoneIdx];
        if (isNil(zone)) {
          continue;
        }
        const ratesForZone = ratesByZoneUuid[zone.uuid];
        if (isNil(ratesForZone)) {
          continue;
        }
        for (let rateIdx = 0; rateIdx < ratesForZone.length; rateIdx += 1) {
          const row = newRateMatrix[rateIdx];
          if (isNil(row)) {
            continue;
          }
          row[zoneIdx] = ratesForZone[rateIdx];
        }
      }

      set((state) => {
        state.locationZonesData = locationZones;
        state.rateMatrix = newRateMatrix;
      });
    },
    setSelectedContactUuids: (contactUuids: string[]) =>
      set((state) => {
        state.selectedContactUuids = contactUuids;
      }),
    setModalMode: (modalMode: TariffModalMode) =>
      set((state) => {
        state.modalMode = modalMode;
      }),
    setParentTariffGroupUuid: (uuid: string) =>
      set((state) => {
        state.parentTariffGroupUuid = uuid;
      }),
    setRateMultiplier: (multiplier: number) =>
      set((state) => {
        state.rateMultiplier = multiplier;
      }),
    setMileOverageRateValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.mileOverageRate = val;
      }),
    setMileOverageFlatRateValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.mileOverageFlatRate = val;
      }),
    setMileOverageApplicableAboveValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.mileOverageApplicableAbove = val;
      }),
    setPieceOverageRateValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.pieceOverageRate = val;
      }),
    setPieceOverageFlatRateValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.pieceOverageFlatRate = val;
      }),
    setPieceOverageApplicableAboveValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.pieceOverageApplicableAbove = val;
      }),
    setWeightOverageRateValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.weightOverageRate = val;
      }),
    setWeightOverageFlatRateValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.weightOverageFlatRate = val;
      }),
    setWeightOverageApplicableAboveValue: (val: number | null) =>
      set((state) => {
        state.globalOverageRates.weightOverageApplicableAbove = val;
      }),
    setZoneBasedOverageRates: (zoneBasedOverageRates: OverageRates[]) =>
      set((state) => {
        state.zoneBasedOverageRates = zoneBasedOverageRates;
      }),
    setUseGlobalOverageRates: (useGlobalOverageRates: boolean) =>
      set((state) => {
        state.useGlobalOverageRates = useGlobalOverageRates;
      }),
    setMinMaxAmountValue: (index: number, minMaxAmount: MinMaxAmountValue) =>
      set((state) => {
        state.minMaxAmountValues[index] = minMaxAmount;
      }),
    setMinMaxAmountValues: (minMaxAmountValues: MinMaxAmountValue[]) =>
      set((state) => {
        state.minMaxAmountValues = minMaxAmountValues;
      }),
    setSettlementPercentageRateValue: (
      index: number,
      settlementPercentageRate: number | null,
    ) =>
      set((state) => {
        state.settlementPercentageRateValues[index] = !isNil(
          settlementPercentageRate,
        )
          ? percent(settlementPercentageRate)
          : null;
      }),
    setSettlementPercentageRateValues: (
      settlementPercentageRateValues: (Scalar | null)[],
    ) =>
      set((state) => {
        state.settlementPercentageRateValues = settlementPercentageRateValues;
      }),
    setName: (name: string) =>
      set((state) => {
        state.name = name;
      }),
    setTariffGroupType: (tariffGroupType: TariffGroupType) =>
      set((state) => {
        state.tariffGroupType = tariffGroupType;
      }),
    setFuelProfileUuid: (uuid: string | null | undefined) =>
      set((state) => {
        state.fuelProfileUuid = uuid;
      }),
    setTerminalUuids: (uuids: string[]) =>
      set((state) => {
        state.terminalUuids = uuids;
      }),
    setServiceUuids: (uuids: string[]) =>
      set((state) => {
        state.serviceUuids = uuids;
      }),
    setVehicleTypeUuids: (uuids: string[]) =>
      set((state) => {
        state.vehicleTypeUuids = uuids;
      }),
    setStartDate: (date: Date | null) =>
      set((state) => {
        state.startDate = date;
      }),
    setEndDate: (date: Date | null) =>
      set((state) => {
        state.endDate = date;
      }),
    setUseDeficitBilling: (useDeficitBilling: boolean) =>
      set((state) => {
        state.useDeficitBilling = useDeficitBilling;
      }),
    setTariffGroupScope: (scope: TariffGroupScope) =>
      set((state) => {
        state.tariffGroupScope = scope;
      }),
    setContactToServiceMapping: async (
      contactUuid: string,
      serviceUuids: string[],
    ) => {
      const currState = get();
      // then we would need to change the zone to be the contact specific one

      const newZones = await recalculateLocationZonesAfterAddingContact(
        currState,
        contactUuid,
      );
      set((state) => {
        state.contactUuidToServiceUuidsMapping[contactUuid] = serviceUuids;
        if (!state.useZoneGroups && !isNil(newZones))
          state.locationZonesData = newZones;
      });
    },
    setUseActualWeight: (useActualWeight: boolean) =>
      set((state) => {
        state.useActualWeight = useActualWeight;
      }),
    removeKeyFromContactToServiceMapping: async (contactUuid: string) => {
      const currState = get();
      // then we would need to change the zone to be the contact specific one
      const newZones = await recalculateLocationZonesAfterRemovingContact(
        currState,
        contactUuid,
      );

      set((state) => {
        delete state.contactUuidToServiceUuidsMapping[contactUuid];
        if (!state.useZoneGroups && !isNil(newZones)) {
          state.locationZonesData = newZones;
        }
      });
    },
    removeServiceFromContactToServiceMapping: (
      contactUuid: string,
      serviceUuid: string,
    ) =>
      set((state) => {
        const currMapping = state.contactUuidToServiceUuidsMapping;
        currMapping[contactUuid] =
          currMapping[contactUuid]?.filter((uuid) => uuid !== serviceUuid) ??
          [];
      }),
    buildCreateTariffGroupInputFromState: () => {
      const state = get();
      const {
        selectedContactUuids,
        tariffType,
        zoneType,
        rateType,
        name,
        tariffGroupType,
        fuelProfileUuid,
        terminalUuids,
        tariffGroupScope,
        contactUuidToServiceUuidsMapping,
        useActualWeight,
        useDeficitBilling,
        parentTariffGroupUuid,
        startDate,
        endDate,
        useZoneGroups,
        tariffZoneGroupId,
        useGlobalOverageRates,
        serviceUuids,
        vehicleTypeUuids,
      } = state;

      // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
      const [tariffZoneCreates, _, __, zoneUuids] =
        getNewTariffZoneChanges(state);

      const tariffCreateInputs = buildTariffCreateInputs(state, zoneUuids);

      const tariffOverageRateCreates = useGlobalOverageRates
        ? buildGlobalTariffOverageRateCreates(state)
        : undefined;

      const mappings: CreateContactToServicesMappingInput[] = [];

      Object.entries(contactUuidToServiceUuidsMapping).forEach(
        ([contactUuid, serviceUuidsForContact]) => {
          const mapping: CreateContactToServicesMappingInput = {
            contactUuid,
            serviceUuids: serviceUuidsForContact,
          };
          mappings.push(mapping);
        },
      );

      const createTariffGroupInput: MakeCreateInputFieldsRequired<CreateTariffGroupInput> =
        {
          contactUuids: selectedContactUuids,
          tariffCreateInputs: tariffCreateInputs ?? [],
          tariffZoneCreateInputs: tariffZoneCreates,
          tariffZoneUpdateInputs: [],
          tariffType,
          zoneType,
          rateType,
          name,
          fuelProfileUuid,
          terminalUuids,
          tariffGroupScope,
          contactToServicesMappings: mappings,
          tariffOverageRateCreates,
          useActualWeightInCalculation: useActualWeight,
          useDeficitBilling,
          parentTariffGroupUuid,
          tariffGroupType,
          rateMultiplier: undefined,
          startDate,
          endDate,
          tariffZoneGroupId: useZoneGroups ? tariffZoneGroupId : null,
          serviceUuids,
          vehicleTypeUuids,
        };
      return createTariffGroupInput;
    },
    resetStore: () => {
      set(initialState);
    },
    resetToOriginalTariffGroup: () => {
      set((state) => {
        const currState = get();
        if (isNil(currState.existingTariffGroupBeforeChanges)) {
          state.resetStore();
        } else {
          const { tariffs, name, tariffGroupScope, contactToServicesMappings } =
            currState.existingTariffGroupBeforeChanges;
          const rateMatrix = getRateMatrix(tariffs);

          const xRangeValues = getMileRange(tariffs);
          const yRangeValues = getYRange(tariffs);
          const minMaxAmountValues = getMinMaxAmountValues(tariffs);
          const settlementPercentageRateValues =
            getSettlementPercentageRateValues(tariffs);
          const [
            mileOverageRate,
            mileOverageFlatRate,
            mileOverageApplicableAbove,
            pieceOverageRate,
            pieceOverageFlatRate,
            pieceOverageApplicableAbove,
            weightOverageRate,
            weightOverageFlatRate,
            weightOverageApplicableAbove,
          ] = getGlobalOverageRates(tariffs);

          const zoneBasedOverageRates = getZoneBasedOverageRates(tariffs);
          const useGlobalOverageRates = getUseGlobalOverageRates(
            zoneBasedOverageRates,
          );

          const first = tariffs[0];
          if (!isNil(first)) {
            state.tariffType = first.tariffType;
            state.zoneType = first.tariffZone.type;
            state.rateType = first.rateType;
          }
          state.rateMatrix = rateMatrix;
          state.xRangeValues = xRangeValues;
          state.yRangeValues = yRangeValues;
          state.minMaxAmountValues = minMaxAmountValues;
          state.settlementPercentageRateValues = settlementPercentageRateValues;
          state.name = name ?? null;
          state.tariffGroupScope = tariffGroupScope ?? null;
          state.globalOverageRates = {
            mileOverageRate: mileOverageRate ?? null,
            mileOverageFlatRate: mileOverageFlatRate ?? null,
            mileOverageApplicableAbove: mileOverageApplicableAbove ?? null,
            pieceOverageRate: pieceOverageRate ?? null,
            pieceOverageFlatRate: pieceOverageFlatRate ?? null,
            pieceOverageApplicableAbove: pieceOverageApplicableAbove ?? null,
            weightOverageRate: weightOverageRate ?? null,
            weightOverageFlatRate: weightOverageFlatRate ?? null,
            weightOverageApplicableAbove: weightOverageApplicableAbove ?? null,
          };
          state.zoneBasedOverageRates = zoneBasedOverageRates;
          state.useGlobalOverageRates = useGlobalOverageRates;

          const mapping: Record<string, string[]> = {};
          contactToServicesMappings.forEach((m) => {
            const contactUuid = m.contact.uuid;
            mapping[contactUuid] = m.services.map((s) => s.uuid);
          });
          state.contactUuidToServiceUuidsMapping = mapping;
          state.selectedContactUuids = Object.keys(mapping);
          // state.selectedServiceUuids = services.map((s) => s.uuid);
        }
      });
    },
    initStoreWithExistingTariffGroup: async (tariffGroupUuid: string) => {
      const { useZoneGroups } = get();
      get().resetStore();
      get().isLoadingTariffGroup = true;
      const res = await apolloClient.query<
        TariffGroupQuery,
        TariffGroupQueryVariables
      >({
        query: TariffGroupDocument,
        variables: { tariffGroupUuid },
      });

      const firstTariff = res.data.tariffGroup.tariffs[0];

      const tariffZoneGroupId = res.data.tariffGroup.tariffZoneGroup?.id;
      let zones: LocationZoneData[] = [];
      if (useZoneGroups === true && !isNil(tariffZoneGroupId)) {
        zones = await getTariffZonesForGroup(tariffZoneGroupId);
        set((state) => {
          state.allZonesInGroup = zones;
          state.tariffZoneGroupId = tariffZoneGroupId ?? null;
        });
      } else {
        // get the contact of the zone
        const zonesQuery = await apolloClient.query<
          TariffZonesQuery,
          TariffZonesQueryVariables
        >({
          query: TariffZonesDocument,
          variables: {
            tariffZoneType: TariffZoneType.Location,
            tariffZoneLocationType:
              convertTariffGroupTypeToTariffZoneLocationType(
                res.data.tariffGroup.tariffGroupType,
              ),
            contactUuid: firstTariff?.tariffZone.billingPartyContactUuid,
          },
        });
        zones =
          zonesQuery.data?.tariffZones
            .map((zone) => {
              return {
                uuid: zone.uuid,
                name: zone.name,
                isLhLaneActive: zone.lineHaulLane?.isActive,
              };
            })
            .sort((a, b) => {
              const nameComparison = a.name.localeCompare(b.name);
              if (nameComparison !== 0) return nameComparison;
              // eslint-disable-next-line no-nested-ternary
              return a.isLhLaneActive === b.isLhLaneActive
                ? 0
                : a.isLhLaneActive === true
                  ? 1
                  : -1;
            }) ?? [];
      }

      set((state) => {
        const {
          tariffs,
          name,
          tariffGroupType,
          tariffGroupScope,
          contactToServicesMappings,
          useActualWeightInCalculation,
          useDeficitBilling,
          rateMultiplier,
          fuelProfile,
          terminals,
          services,
          vehicleTypes,
          startDate,
          endDate,
        } = res.data.tariffGroup;

        const rateMatrix = getRateMatrix(tariffs);
        const xRangeValues = getMileRange(tariffs);
        const yRangeValues = getYRange(tariffs);
        const minMaxAmountValues = getMinMaxAmountValues(tariffs);
        const settlementPercentageRateValues =
          getSettlementPercentageRateValues(tariffs);

        const tariffZoneUuids = tariffs.map((t) => t.tariffZone.uuid);

        const [
          mileOverageRate,
          mileOverageFlatRate,
          mileOverageApplicableAbove,
          pieceOverageRate,
          pieceOverageFlatRate,
          pieceOverageApplicableAbove,
          weightOverageRate,
          weightOverageFlatRate,
          weightOverageApplicableAbove,
        ] = getGlobalOverageRates(tariffs);

        const zoneBasedOverageRates = getZoneBasedOverageRates(tariffs);
        const useGlobalOverageRates = getUseGlobalOverageRates(
          zoneBasedOverageRates,
        );

        const first = tariffs[0];
        if (!isNil(first)) {
          state.tariffType = first.tariffType;
          state.zoneType = first.tariffZone.type;
          state.rateType = first.rateType;
        }
        state.rateMatrix = rateMatrix;
        state.xRangeValues = xRangeValues;
        state.yRangeValues = yRangeValues;
        state.minMaxAmountValues = minMaxAmountValues;
        state.settlementPercentageRateValues = settlementPercentageRateValues;
        state.name = name ?? null;
        state.tariffGroupType = tariffGroupType;
        state.fuelProfileUuid = fuelProfile?.uuid ?? null;
        state.terminalUuids = terminals.map((terminal) => terminal.uuid);
        state.serviceUuids = services.map((service) => service.uuid);
        state.vehicleTypeUuids = vehicleTypes.map(
          (vehicleType) => vehicleType.uuid,
        );
        state.locationZonesData = zones.filter((zone) =>
          tariffZoneUuids.includes(zone.uuid),
        );

        state.existingTariffGroupBeforeChanges = res.data.tariffGroup;
        state.tariffGroupScope = tariffGroupScope ?? null;
        state.useActualWeight = useActualWeightInCalculation;
        state.useDeficitBilling = useDeficitBilling ?? false;
        state.rateMultiplier = rateMultiplier ?? null;
        state.modalMode = TariffModalMode.EDIT;

        state.globalOverageRates = {
          mileOverageRate: mileOverageRate ?? null,
          mileOverageFlatRate: mileOverageFlatRate ?? null,
          mileOverageApplicableAbove: mileOverageApplicableAbove ?? null,
          pieceOverageRate: pieceOverageRate ?? null,
          pieceOverageFlatRate: pieceOverageFlatRate ?? null,
          pieceOverageApplicableAbove: pieceOverageApplicableAbove ?? null,
          weightOverageRate: weightOverageRate ?? null,
          weightOverageFlatRate: weightOverageFlatRate ?? null,
          weightOverageApplicableAbove: weightOverageApplicableAbove ?? null,
        };
        state.zoneBasedOverageRates = zoneBasedOverageRates;
        state.useGlobalOverageRates = useGlobalOverageRates;

        const mapping: Record<string, string[]> = {};
        contactToServicesMappings.forEach((m) => {
          const contactUuid = m.contact.uuid;
          mapping[contactUuid] = m.services.map((s) => s.uuid);
        });
        state.contactUuidToServiceUuidsMapping = mapping;
        state.selectedContactUuids = Object.keys(mapping);
        state.startDate = startDate;
        state.endDate = endDate;
        state.isLoadingTariffGroup = false;
      });
    },
    buildUpdateTariffGroupInputFromState: (uuid: string) => {
      const state = get();
      const {
        selectedContactUuids,
        tariffType,
        zoneType,
        rateType,
        name,
        contactUuidToServiceUuidsMapping,
        useActualWeight,
        useDeficitBilling,
        tariffGroupType,
        tariffGroupScope,
        fuelProfileUuid,
        terminalUuids,
        startDate,
        endDate,
        useGlobalOverageRates,
        serviceUuids,
        vehicleTypeUuids,
      } = state;

      const [
        tariffZoneCreates,
        tariffZoneUpdates,
        tariffZoneDeletes,
        zoneUuids,
      ] = buildTariffZoneGroupChanges(state);

      const tariffUpsertInputs = buildTariffUpsertInputs(state, zoneUuids);

      const tariffOverageRateCreates = useGlobalOverageRates
        ? buildGlobalTariffOverageRateCreates(state)
        : undefined;

      const mappings: CreateContactToServicesMappingInput[] = [];

      Object.entries(contactUuidToServiceUuidsMapping).forEach(
        ([contactUuid, serviceUuidsForContact]) => {
          const mapping: CreateContactToServicesMappingInput = {
            contactUuid,
            serviceUuids: serviceUuidsForContact,
          };
          mappings.push(mapping);
        },
      );
      const updateTariffGroupInput: UpdateTariffGroupInput = {
        contactUuids: selectedContactUuids,
        tariffUpsertInputs: tariffUpsertInputs ?? [],
        tariffZoneCreateInputs: tariffZoneCreates,
        tariffZoneUpdateInputs: tariffZoneUpdates,
        tariffZoneDeletes,
        tariffType,
        zoneType,
        rateType,
        tariffGroupType,
        uuid,
        name,
        fuelProfileUuid,
        terminalUuids,
        // don't update mappings for a default tariff
        contactToServicesMappings:
          tariffGroupScope !== TariffGroupScope.Default ? mappings : undefined,
        startDate,
        endDate,
        tariffOverageRateCreates,
        useActualWeightInCalculation: useActualWeight,
        useDeficitBilling,
        serviceUuids,
        vehicleTypeUuids,
      };

      return updateTariffGroupInput;
    },
    selectAllContactUuids: (uuids: string[]) =>
      set((state) => {
        state.selectedContactUuids = uuids;
      }),
    setUseZoneGroups: (useZoneGroups: boolean) =>
      set((state) => {
        state.useZoneGroups = useZoneGroups;
      }),
    setTariffZoneGroupId: async (tariffZoneGroupId: string | null) => {
      let newZones: LocationZoneData[] = [];
      if (!isNil(tariffZoneGroupId)) {
        newZones = await getTariffZonesForGroup(tariffZoneGroupId);
      }
      set((state) => {
        state.tariffZoneGroupId = tariffZoneGroupId;
        state.locationZonesData = [];
        state.allZonesInGroup = newZones;
      });
    },
  })),
);

export default useTariffGroupStore;
