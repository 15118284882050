import { Check } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { OrderFilterDatePicker } from '../../orders/components/enums/order-filter-date-picker';
import { NO_LIMIT } from '../../orders/components/enums/order-filters';

dayjs.extend(utc);
dayjs.extend(timezone);

type DateRangeFilterProps = {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  handleStartDateChange: (date: Date | null) => void;
  handleEndDateChange: (date: Date | null) => void;
  allowNoLimit: boolean;
};

const DateRangeFilter = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  allowNoLimit,
}: DateRangeFilterProps) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        mt: '2px',
      }}
    >
      {/* Hidden checkmark to align with the above box */}
      <Check
        sx={{
          visibility: 'hidden',
          fontSize: '14px',
          ml: 0,
          mr: '6px',
        }}
      />
      <OrderFilterDatePicker
        handleChange={handleStartDateChange}
        name={!isNil(startDate) ? 'Start' : NO_LIMIT}
        value={startDate ?? null}
        setStart
        allowNoLimit={allowNoLimit}
      />
      <Typography sx={{ fontSize: '14px', mx: '5px' }}>to</Typography>
      <OrderFilterDatePicker
        handleChange={handleEndDateChange}
        name={!isNil(endDate) ? 'End' : NO_LIMIT}
        value={endDate ?? null}
        setEnd
        allowNoLimit={allowNoLimit}
      />
    </Box>
  );
};

export default DateRangeFilter;
