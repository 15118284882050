import { ColDef } from 'ag-grid-community';
import React from 'react';
import {
  FilterOperator,
  FormattedOrderFragment,
} from '../../../../../generated/graphql';
import {
  FilterModel,
  OrderFilterField,
} from '../../../../orders/components/enums/order-filters';
import SelectedFilterButtonForToolbar from '../../../../orders/components/selected-filter-button-for-toolbar';
import { DefaultFilterTabsConfigs } from '../../types';

const FilterRow = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  DefaultFilterTabsType extends any,
>({
  filterModel,
  defaultFilterTabsConfigs,
  handleDeleteFilter,
  handleClickSelectedFilter,
  currentTab,
  columnDefs,
}: {
  filterModel: FilterModel | undefined;
  defaultFilterTabsConfigs: DefaultFilterTabsConfigs<DefaultFilterTabsType>;
  currentTab: DefaultFilterTabsType | string;
  handleDeleteFilter: (key: OrderFilterField) => void;
  handleClickSelectedFilter: (key: OrderFilterField) => void;
  columnDefs: ColDef<FormattedOrderFragment>[];
}) => {
  const andFilters = Object.entries(filterModel ?? {}).filter(
    ([_, filter]) => filter?.filterOperator !== FilterOperator.Or,
  );
  const orFilters = Object.entries(filterModel ?? {}).filter(
    ([_, filter]) => filter?.filterOperator === FilterOperator.Or,
  );

  return (
    <>
      {andFilters.map(([key, _], idx) => {
        let prependText;
        if (idx > 0) {
          prependText = 'And';
        }
        return (
          <SelectedFilterButtonForToolbar
            key={key}
            prependText={prependText}
            currentDefaultTabFilterModel={
              defaultFilterTabsConfigs.tabs.find(
                (tab) => tab.value === currentTab,
              )?.filterModel
            }
            filterModel={filterModel}
            filterName={columnDefs.find((col) => col.field === key)?.headerName}
            keyName={key}
            handleDelete={() => handleDeleteFilter(key as OrderFilterField)}
            handleSelect={() =>
              handleClickSelectedFilter(key as OrderFilterField)
            }
          />
        );
      })}
      {orFilters.map(([key, _], idx) => {
        let prependText;
        if (idx > 0 || andFilters.length > 0) {
          prependText = 'Or';
        }
        return (
          <SelectedFilterButtonForToolbar
            key={key}
            prependText={prependText}
            currentDefaultTabFilterModel={
              defaultFilterTabsConfigs.tabs.find(
                (tab) => tab.value === currentTab,
              )?.filterModel
            }
            filterModel={filterModel}
            keyName={key}
            handleDelete={() => handleDeleteFilter(key as OrderFilterField)}
            handleSelect={() =>
              handleClickSelectedFilter(key as OrderFilterField)
            }
          />
        );
      })}
    </>
  );
};

export default React.memo(FilterRow, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
