import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { BaseStorageUnitFormValues } from '../types';

export type EditStorageUnitFormValues = BaseStorageUnitFormValues;
const useEditStorageUnitForm = () => {
  const schema = joi.object({
    name: joi
      .string()
      .required()
      .messages({ 'any.required': 'Name is required' }),
    location: joi.string(),
  });
  return useForm<EditStorageUnitFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useEditStorageUnitForm;
