import {
  Box,
  Button,
  Fade,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  RecoveryTerminalFragment,
  useRecoveryTerminalsQuery,
} from '../../../generated/graphql';
import CreateOrEdit from '../enums/create-or-edit';
import RecoveryTerminalsModal from './recovery-terminals-modal';

const RecoveryTerminalsTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyRecoveryTerminals } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecoveryTerminals,
  );

  const [open, setOpen] = useState(false);
  const [hoveredRecoveryTerminalUuid, setHoveredRecoveryTerminalUuid] =
    useState<string>();
  const [selectedRecoveryTerminal, setSelectedRecoveryTerminal] = useState<
    RecoveryTerminalFragment | undefined
  >(undefined);
  const [mode, setMode] = useState<CreateOrEdit>(CreateOrEdit.Create);
  const { data: recoveryTerminals } = useRecoveryTerminalsQuery();

  return (
    <>
      <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Terminal</TableCell>
                <TableCell>IATA Code</TableCell>
                <TableCell>Address</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      setMode(CreateOrEdit.Create);
                      setOpen(true);
                    }}
                    variant="contained"
                    disabled={!canWriteCompanyRecoveryTerminals}
                  >
                    Add Recovery Terminal
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recoveryTerminals?.airportInfos?.map(
                (recoveryTerminal: RecoveryTerminalFragment) => {
                  return (
                    <TableRow
                      onMouseEnter={() => {
                        setHoveredRecoveryTerminalUuid(recoveryTerminal.uuid);
                      }}
                      onMouseLeave={() => {
                        setHoveredRecoveryTerminalUuid(undefined);
                      }}
                      key={recoveryTerminal.uuid}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>
                        <Box>
                          <Typography variant="body1">
                            {recoveryTerminal.terminal}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="body1">
                            {recoveryTerminal.iataCode}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="body1">
                            {transformAddressToFullAddressString(
                              recoveryTerminal.address,
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Fade
                          in={
                            hoveredRecoveryTerminalUuid ===
                            recoveryTerminal.uuid
                          }
                        >
                          <Button
                            onClick={() => {
                              setSelectedRecoveryTerminal(recoveryTerminal);
                              setMode(CreateOrEdit.Edit);
                              setOpen(true);
                            }}
                            variant="contained"
                            disabled={!canWriteCompanyRecoveryTerminals}
                          >
                            Edit
                          </Button>
                        </Fade>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <RecoveryTerminalsModal
        selectedRecoveryTerminal={selectedRecoveryTerminal}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedRecoveryTerminal(undefined);
        }}
        createOrEdit={mode}
      />
    </>
  );
};

export default RecoveryTerminalsTable;
