// eslint-disable-next-line no-restricted-imports
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { isNil, range } from 'lodash';
import { ReferenceNumberFormatCheckEntity } from '../../../../generated/graphql';

interface StartsWithCheckConditionsProps {
  check: ReferenceNumberFormatCheckEntity;
}

const StartsWithCheckConditions = ({
  check,
}: StartsWithCheckConditionsProps) => {
  return (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      <Grid item xs={12}>
        <Typography>
          <strong>Starts With:</strong>
        </Typography>
      </Grid>
      {!isNil(check.operator) && (
        <>
          {range(check.startsWithOperands.length - 1).map((idx) => {
            const currOperand = check.startsWithOperands[idx];
            const nextOperand = check.startsWithOperands[idx + 1];
            return (
              <>
                <Grid item xs={1}>
                  <TextField
                    value={currOperand}
                    disabled
                    size="small"
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical">
                    <strong>{check.operator}</strong>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    value={nextOperand}
                    disabled
                    size="small"
                    style={{ width: '100%' }}
                  />
                </Grid>
              </>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default StartsWithCheckConditions;
