import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import _, { isNil } from 'lodash';
import { convertInputBillingMethodToBillingMethod } from 'shared/billing';
import {
  CustomChargeFormField,
  FreightChargeFormField,
  StandardShipmentValues,
} from 'shared/types';
import {
  CustomChargeArrayUpdateInput,
  CustomChargeBillingMethod,
  FreightBillingMethod,
  FreightChargeUpsertInput,
  FuelBillingMethod,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import { FuelChargeFormField } from '../../orders/redux/drayage/order-form-types';
import { InvoiceFormField } from './invoice-form-types';

type InvoiceStoreState = InvoiceFormField;

const invoiceAdapter = createEntityAdapter<InvoiceStoreState>({
  selectId: (invoice) => invoice.uuid,
});

export const invoiceValuesSlice = createSlice({
  name: 'invoices',
  initialState: invoiceAdapter.getInitialState(),
  reducers: {
    addInvoice: invoiceAdapter.addOne,
    updateInvoice: invoiceAdapter.updateOne,
    deleteInvoice: invoiceAdapter.removeOne,
    updateInvoices: invoiceAdapter.updateMany,
    loadedInvoices: invoiceAdapter.setAll,
    upsertInvoices: invoiceAdapter.upsertMany,
    upsertInvoice: invoiceAdapter.upsertOne,
  },
});

const invoiceValuesSelector = (state: RootState) => state.invoiceFormValues;

export const selectInvoicesByIds = createSelector(
  invoiceValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<InvoiceFormField>, entityIds: EntityId[]) => {
    const entityResults: InvoiceFormField[] = [];
    entityIds.forEach((id) => {
      const match = invoiceAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  selectById: selectInvoiceById,
  selectIds: selectInvoiceIds,
  selectAll: selectAllInvoices,
  // Pass in a selector that returns the posts slice of state
} = invoiceAdapter.getSelectors((state: RootState) => state.invoiceFormValues);

export const { loadedInvoices, updateInvoice, upsertInvoices, upsertInvoice } =
  invoiceValuesSlice.actions;

export const selectBillingPartyUuidForInvoice = createSelector(
  selectInvoiceById,
  (values: InvoiceFormField | undefined) => {
    return values?.billToContactId;
  },
);

export const formatFreightCharges = (
  shipment: StandardShipmentValues,
  freightCharge: FreightChargeFormField | undefined,
  fuelCharge: FuelChargeFormField | undefined,
): FreightChargeUpsertInput | undefined => {
  if (freightCharge !== undefined) {
    const billingMethod = convertInputBillingMethodToBillingMethod(
      freightCharge?.billingMethod,
    );
    return {
      quantity: freightCharge.quantity ?? 0,
      billingMethod: billingMethod ?? FreightBillingMethod.FlatRate,
      description: freightCharge.description ?? '',
      rate: freightCharge.rate ?? 0,
      uuid: freightCharge.uuid,
      fuelChargeUpsertInput:
        fuelCharge !== undefined &&
        !isNil(fuelCharge.surchargeRate) &&
        !_.isNaN(fuelCharge.surchargeRate) &&
        fuelCharge.uuid.length > 0
          ? {
              type: fuelCharge.type ?? FuelBillingMethod.FlatRate,
              description: fuelCharge.description ?? '',
              surchargeRate:
                parseFloat(String(fuelCharge.surchargeRate ?? 0)) ?? 0,
              uuid: fuelCharge.uuid,
            }
          : undefined,
    };
  }

  return undefined;
};
export const formatCustomCharges = (customCharges: CustomChargeFormField[]) => {
  const customChargeUpdateInputs: CustomChargeArrayUpdateInput[] = [];
  customCharges.forEach((customCharge) => {
    const isUpdate =
      customCharge.isLocal !== undefined && customCharge.isLocal === false;
    if (
      isUpdate &&
      customCharge.quantity !== undefined &&
      !_.isNaN(customCharge.quantity)
    ) {
      customChargeUpdateInputs.push({
        customChargeUpdateInput: {
          billingMethod:
            customCharge.billingMethod ?? CustomChargeBillingMethod.Accessorial,
          rate: customCharge.rate ?? 0,
          description: customCharge.description ?? '',
          quantity: customCharge.quantity,
          name: customCharge.name ?? '',
          uuid: customCharge.uuid,
          accessorialTemplateUuid: customCharge.accessorialId,
          zoneBasedAccessorialMatrixItemUuid:
            customCharge.zoneBasedAccessorialMatrixItemUuid,
        },
      });
    }
    if (
      !isUpdate &&
      customCharge.quantity !== undefined &&
      customCharge.rate !== undefined &&
      !_.isNaN(customCharge.quantity) &&
      !_.isNaN(customCharge.rate)
    ) {
      customChargeUpdateInputs.push({
        customChargeCreateInput: {
          billingMethod:
            customCharge.billingMethod ?? CustomChargeBillingMethod.Accessorial,
          rate: customCharge.rate ?? 0,
          description: customCharge.description ?? '',
          quantity: customCharge.quantity,
          name: customCharge.name ?? '',
          accessorialTemplateUuid: customCharge.accessorialId,
          zoneBasedAccessorialMatrixItemUuid:
            customCharge.zoneBasedAccessorialMatrixItemUuid,
        },
      });
    }
  });
  return customChargeUpdateInputs;
};

// Can add this when we have required parameters for custom charges besides just rate and quantity.
// type ValidateCustomChargeArgs = {
//   field: keyof CustomChargeFormField;
//   value: string | number | boolean | undefined;
// };

// const validateCustomChargeField = (args: ValidateCustomChargeArgs) => {
//   const { field, value } = args;
//   if (field === 'uuid') {
//     return { valid: true, explanation: '' };
//   }
//   return { valid: true, explanation: '' };
// };

export default invoiceValuesSlice.reducer;
