import { isNil } from 'lodash';
import { getPermissionsFlags } from 'shared/roles';
import {
  COMPANY_PERMISSION_RESOURCES,
  SETTINGS_PERMISSION_RESOURCES,
} from '../../domains/permissions/constants';
import {
  AccessLevel,
  PermissionResource,
  UsersRoleFragment,
} from '../../generated/graphql';

export type UserPermissionWithAccessLevel = {
  permissionResource: PermissionResource;
  accessLevel: AccessLevel | null;
};

export function getPermissionAccessLevel(
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  permissionResource: PermissionResource,
): AccessLevel | null {
  return (
    userPermissionWithAccessLevels.find(
      (userPermissionWithAccessLevel) =>
        userPermissionWithAccessLevel.permissionResource === permissionResource,
    )?.accessLevel ?? null
  );
}

export type PermissionFlags = { canRead: boolean; canWrite: boolean };

export type PermissionResourceWithFlags = PermissionFlags & {
  permissionResource: PermissionResource;
};

export function findCompanyPagePermissionsWithAccessLevel(
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  accessLevel: AccessLevel | null,
): PermissionResourceWithFlags[] {
  if (isNil(accessLevel)) {
    return [];
  }

  const companyPermissionResourcesWithAccessLevels: PermissionResourceWithFlags[] =
    COMPANY_PERMISSION_RESOURCES.map((permissionResource) => {
      const { canRead, canWrite } = getPermissionsFlags(
        userPermissionWithAccessLevels,
        permissionResource,
      );
      return {
        canRead,
        canWrite,
        permissionResource,
      };
    });

  return companyPermissionResourcesWithAccessLevels.filter(
    (companyPermissionResourcesWithAccessLevel) => {
      const { canRead, canWrite } = companyPermissionResourcesWithAccessLevel;
      return accessLevel === AccessLevel.Read ? canRead : canWrite;
    },
  );
}

export function findSettingsPagePermissionsWithAccessLevel(
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  accessLevel: AccessLevel | null,
): PermissionResourceWithFlags[] {
  if (isNil(accessLevel)) {
    return [];
  }

  const settingsPermissionResourcesWithAccessLevels: PermissionResourceWithFlags[] =
    SETTINGS_PERMISSION_RESOURCES.map((permissionResource) => {
      const { canRead, canWrite } = getPermissionsFlags(
        userPermissionWithAccessLevels,
        permissionResource,
      );
      return {
        canRead,
        canWrite,
        permissionResource,
      };
    });

  return settingsPermissionResourcesWithAccessLevels.filter(
    (settingsPermissionResourcesWithAccessLevel) => {
      const { canRead, canWrite } = settingsPermissionResourcesWithAccessLevel;
      return accessLevel === AccessLevel.Read ? canRead : canWrite;
    },
  );
}

export function formatUserRoles(userRoles: UsersRoleFragment[]): string {
  return userRoles.map((role) => role.name).join(', ');
}
