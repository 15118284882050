import { shallow } from 'zustand/shallow';
import BillingPartyContactModal from '../../../domains/orders/components/standard/billing-party-contact-modal';
import UploadCsvOrdersDialog from '../../../domains/orders/components/standard/components/csv-orders/upload-csv-orders-dialog';
import UploadModal from '../../../domains/orders/components/upload-documents-for-order-ingestion';
import useGlobalStore from '../../../layouts/dashboard/global-store';

/**
 * A container component that encapsulates modals for various operations that are shared
 * among multiple pages/components. It is initialized in the DashboardLayout component and will be
 * available across said pages/components.
 */
const SharedModalContainer = () => {
  const [
    showBilingPartyDialog,
    setShowBilingPartyDialog,
    showUploadPdfsDialog,
    setShowUploadPdfsDialog,
    showUploadCsvsDialog,
    setShowUploadCsvsDialog,
    setShowAcceptedCsvOrdersSuccessMessage,
    setShowRejectedCsvOrdersSuccessMessage,
    setShouldRefreshOrdersTableData,
  ] = useGlobalStore(
    (state) => [
      state.showBilingPartyDialog,
      state.setShowBilingPartyDialog,
      state.showUploadPdfsDialog,
      state.setShowUploadPdfsDialog,
      state.showUploadCsvsDialog,
      state.setShowUploadCsvsDialog,
      state.setShowAcceptedCsvOrdersSuccessMessage,
      state.setShowRejectedCsvOrdersSuccessMessage,
      state.setShouldRefreshOrdersTableData,
    ],
    shallow,
  );

  return (
    <>
      <BillingPartyContactModal
        setIsOpen={setShowBilingPartyDialog}
        open={showBilingPartyDialog}
      />
      <UploadModal
        open={showUploadPdfsDialog}
        setOpen={setShowUploadPdfsDialog}
      />
      <UploadCsvOrdersDialog
        open={showUploadCsvsDialog}
        setOpen={setShowUploadCsvsDialog}
        setShouldRefreshGrid={setShouldRefreshOrdersTableData}
        setShowAcceptedCsvOrdersSuccessMessage={
          setShowAcceptedCsvOrdersSuccessMessage
        }
        setShowRejectedCsvOrdersSuccessMessage={
          setShowRejectedCsvOrdersSuccessMessage
        }
      />
    </>
  );
};

export default SharedModalContainer;
