import { Card, CardContent, Menu, Stack, styled } from '@mui/material';
import * as React from 'react';
import InvoiceTransmissionsList from '../transmissions/invoice-transmissions-list';

type EmailLogMenuProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  anchorEl: React.RefObject<HTMLAnchorElement>;
};

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  &:last-child {
    padding-bottom: 5px;
  }
`);

const EmailLogMenu = ({ open, setOpen, anchorEl }: EmailLogMenuProps) => {
  return (
    <Menu
      id="file-downloads-menu"
      anchorEl={anchorEl.current}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card sx={{ border: 'none', boxShadow: 'none', width: '900px' }}>
        <CardContentSpecialPadding>
          <Stack direction="column">
            <InvoiceTransmissionsList />
          </Stack>
        </CardContentSpecialPadding>
      </Card>
    </Menu>
  );
};

export default EmailLogMenu;
