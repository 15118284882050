import {
  Checkbox,
  Link,
  TableCell,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { safeSubtract } from 'shared/math';
import { convertNumberStringToFloat } from '../../../../common/utils/utils';
import {
  GroupOrdersForPaymentsBy,
  OrdersForPaymentFragment,
  PaymentType,
  Segment,
} from '../../../../generated/graphql';
import { getPrefillAmount } from './utils';

export type OrderToCharge = {
  [uuid: string]: {
    amount: number;
    invoiceUuid: string | undefined;
  };
};

const PaymentApplicationOrderRow = ({
  segment,
  paymentType,
  setOrdersToCharge,
  order,
  orderToCharge,
  totalAmount,
  appliedAmount,
  unappliedAmount,
  previouslyAppliedAmount,
  invoiceTotal,
  invoiceBalance,
  invoiceName,
  groupOrdersBy,
}: {
  segment: Segment | undefined;
  paymentType: PaymentType;
  setOrdersToCharge: Dispatch<SetStateAction<OrderToCharge>>;
  order: OrdersForPaymentFragment;
  orderToCharge:
    | { amount: number; invoiceUuid: string | undefined }
    | undefined;
  totalAmount: number;
  appliedAmount: number | undefined;
  unappliedAmount: number | undefined;
  previouslyAppliedAmount?: number | null;
  invoiceTotal: string | undefined;
  invoiceBalance: string;
  invoiceName: string;
  groupOrdersBy: GroupOrdersForPaymentsBy;
}) => {
  const theme = useTheme();
  const [appliedAmountInput, setAppliedAmountInput] = useState('');

  useEffect(() => {
    const amount = orderToCharge?.amount;
    if (isNil(amount) || amount === 0) {
      setAppliedAmountInput('');
      return;
    }
    setAppliedAmountInput(String(amount));
  }, [orderToCharge?.amount]);

  const lineBalance = safeSubtract(totalAmount, appliedAmount ?? 0);
  const onModifyAmount = (amount: string) => {
    setAppliedAmountInput(amount);
    const parsedFloatWithNoPrecisionValidation = Math.abs(
      convertNumberStringToFloat(amount) ?? 0,
    );
    const parsedAmount = convertNumberStringToFloat(
      parsedFloatWithNoPrecisionValidation?.toFixed(2),
    );
    setOrdersToCharge((prevState) => {
      return {
        ...prevState,
        [order.uuid]: {
          amount: Math.max(parsedAmount ?? 0, 0),
          invoiceUuid: order.invoice?.uuid,
        },
      };
    });
  };

  const onCheck = () => {
    if (isNil(orderToCharge)) {
      if (!isEmpty(appliedAmountInput)) {
        const parsedFloatWithNoPrecisionValidation = convertNumberStringToFloat(
          appliedAmountInput === '' ? '0' : appliedAmountInput,
        );
        const amount = convertNumberStringToFloat(
          parsedFloatWithNoPrecisionValidation?.toFixed(2),
        );
        if (isNil(amount)) return;
        setOrdersToCharge((prevState) => {
          return {
            ...prevState,
            [order.uuid]: { amount, invoiceUuid: order.invoice?.uuid },
          };
        });
      } else {
        const amount = getPrefillAmount({
          unappliedAmount: unappliedAmount ?? 0,
          lineBalance,
          paymentType,
          previouslyAppliedAmount,
        });
        setOrdersToCharge((prevState) => {
          return {
            ...prevState,
            [order.uuid]: { amount, invoiceUuid: order.invoice?.uuid },
          };
        });
        setAppliedAmountInput(amount.toString());
      }
    } else {
      setOrdersToCharge((prevState) => {
        const copyOfState = { ...prevState };
        delete copyOfState[order.uuid];
        return copyOfState;
      });
    }
  };

  return (
    <TableRow
      sx={{
        backgroundColor: !isNil(orderToCharge)
          ? theme.palette.primary.light
          : undefined,
      }}
    >
      <TableCell>
        <Checkbox checked={!isNil(orderToCharge)} onChange={onCheck} />
      </TableCell>
      <TableCell>
        {order.standardOrderFields.shipperBillOfLadingNumber ?? '-'}
      </TableCell>
      <TableCell>
        <Link
          sx={{
            cursor: 'pointer',
          }}
          href={`/orders/?orderUuid=${order.uuid}`}
          target="_blank"
          underline="hover"
        >
          {order.name}
        </Link>
      </TableCell>
      <TableCell>{order.secondaryRefNumber ?? '-'}</TableCell>
      {segment === Segment.Cartage && (
        <TableCell>
          {order.standardOrderFields.masterAirwayBillOfLadingNumber ?? '-'}
        </TableCell>
      )}
      {groupOrdersBy === GroupOrdersForPaymentsBy.Order && (
        <TableCell>{invoiceName}</TableCell>
      )}
      {groupOrdersBy === GroupOrdersForPaymentsBy.Order && (
        <TableCell align="right">{invoiceTotal ?? '-'}</TableCell>
      )}
      {groupOrdersBy === GroupOrdersForPaymentsBy.Order && (
        <TableCell align="right">{invoiceBalance}</TableCell>
      )}
      <TableCell align="right">${totalAmount.toFixed(2)}</TableCell>
      <TableCell align="right">${lineBalance.toFixed(2)}</TableCell>
      <TableCell align="right">
        {!isNil(orderToCharge) && !isNil(appliedAmountInput) && (
          <TextField
            type="number"
            value={appliedAmountInput}
            onChange={(e) => onModifyAmount(e.target.value)}
            size="small"
            sx={{ width: 100 }}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
            }}
            onBlur={() => {
              const parsedFloatWithNoPrecisionValidation = Math.abs(
                convertNumberStringToFloat(appliedAmountInput) ?? 0,
              );
              setAppliedAmountInput(
                parsedFloatWithNoPrecisionValidation?.toFixed(2) ?? '',
              );
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default PaymentApplicationOrderRow;
