import {
  Card,
  Stack,
  Table,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil, lowerCase } from 'lodash';
import { safeMultiply } from 'shared/math';
import ErrorBanner from '../../../../../common/components/error-banner';
import {
  PreviewProratedConsolidationV2Output,
  ProratedOrderV2Fragment,
  ProratedShipmentV2Fragment,
} from '../../../../../generated/graphql';
import {
  HawbLink,
  TableCellCustom,
  TableHeadCustom,
  TableHeaderTypography,
  TableTypography,
} from './common';

export enum ConsolidationMethod {
  PRORATE_WEIGHTED = 'PRORATE WEIGHTED',
  PRORATE_ALL = 'PRORATE ALL',
}

const BreakdownOfChargesAfterProrate = ({
  chargeType,
  shipmentInfo,
  order,
}: {
  chargeType: string;
  shipmentInfo: ProratedShipmentV2Fragment | null;
  order: ProratedOrderV2Fragment;
}) => {
  const theme = useTheme();

  return (
    <Table>
      <TableHeadCustom backgroundColor={theme.palette.grey[300]}>
        <TableCellCustom cellPadding={1} sx={{ width: '30%' }}>
          <TableHeaderTypography sidePadding={10}>
            {chargeType}
          </TableHeaderTypography>
        </TableCellCustom>
        <TableCellCustom cellPadding={0} sx={{ width: '30%' }}>
          <TableHeaderTypography />
        </TableCellCustom>
        <TableCellCustom cellPadding={0} sx={{ width: '30%' }}>
          <TableHeaderTypography />
        </TableCellCustom>
        <TableCellCustom
          cellPadding={0}
          sx={{ width: '10%', textAlign: 'right' }}
        >
          <TableHeaderTypography />
        </TableCellCustom>
      </TableHeadCustom>
      {isNil(shipmentInfo) ? (
        <TableCellCustom sx={{ width: '100%' }}>
          <TableTypography isGrey align="center">
            Order does not have {lowerCase(chargeType)} shipment
          </TableTypography>
        </TableCellCustom>
      ) : (
        <>
          <TableRow>
            <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
              <TableTypography>Freight charge</TableTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
              {!isNil(shipmentInfo.initialFreightChargeTotal) ? (
                <TableTypography>
                  {`$${shipmentInfo.initialFreightChargeTotal?.toFixed(2)}`}
                </TableTypography>
              ) : (
                <TableTypography isGrey>n/a</TableTypography>
              )}
            </TableCellCustom>
            <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
              <TableTypography>
                {safeMultiply(order.proportionOfTotalWeight, 100)}%
              </TableTypography>
            </TableCellCustom>
            <TableCellCustom
              cellPadding={3}
              sx={{ width: '10%', textAlign: 'right' }}
            >
              {!isNil(shipmentInfo.proratedFreightChargeTotal) ? (
                <TableTypography>
                  {`$${shipmentInfo.proratedFreightChargeTotal?.toFixed(2)}`}
                </TableTypography>
              ) : (
                <TableTypography isGrey>n/a</TableTypography>
              )}
            </TableCellCustom>
          </TableRow>
          <TableRow>
            <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
              <TableTypography>Fuel charge</TableTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
              {!isNil(shipmentInfo.initialFuelChargeTotal) ? (
                <TableTypography>
                  {`$${shipmentInfo.initialFuelChargeTotal?.toFixed(2)}`}
                </TableTypography>
              ) : (
                <TableTypography isGrey>n/a</TableTypography>
              )}
            </TableCellCustom>
            <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
              <TableTypography>
                {safeMultiply(order.proportionOfTotalWeight, 100)}%
              </TableTypography>
            </TableCellCustom>
            <TableCellCustom
              cellPadding={3}
              sx={{ width: '10%', textAlign: 'right' }}
            >
              {!isNil(shipmentInfo.proratedFuelChargeTotal) ? (
                <TableTypography>
                  {`$${shipmentInfo.proratedFuelChargeTotal?.toFixed(2)}`}
                </TableTypography>
              ) : (
                <TableTypography isGrey>n/a</TableTypography>
              )}
            </TableCellCustom>
          </TableRow>
          {shipmentInfo.customCharges.map((customCharge) => {
            const { initialTotal } = customCharge;
            // const tooltipText =
            //   customCharge..length > 1
            //     ? 'This charge appears in multiple places and has been combined and split by weight across all the consolidated orders'
            //     : `This charge appears ${customCharge.initialOrderUuidsPresentIn[0] === orderUuid ? 'only in this order' : 'in another order'} and is split by weight across all the consolidated orders`;

            return (
              <TableRow key={null}>
                <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
                  <TableTypography>{customCharge.name}</TableTypography>
                </TableCellCustom>
                <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
                  <TableTypography isGrey={isNil(initialTotal)}>
                    {!isNil(initialTotal)
                      ? `$${initialTotal.toFixed(2)}`
                      : 'n/a'}
                  </TableTypography>
                </TableCellCustom>
                <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
                  {safeMultiply(order.proportionOfTotalWeight, 100)}%
                  {/* <Stack direction="row" alignItems="center" gap={1}>
                <TableTypography>
                  {customCharge.initialOrderUuidsPresentIn.length > 1
                    ? 'Combined and split'
                    : 'Split'}
                </TableTypography>
                <Tooltip title={tooltipText}>
                  <InfoIcon
                    fontSize="small"
                    sx={{ color: theme.palette.grey[500] }}
                  />
                </Tooltip>
              </Stack> */}
                </TableCellCustom>
                <TableCellCustom
                  cellPadding={3}
                  sx={{ width: '10%', textAlign: 'right' }}
                >
                  <TableTypography>
                    ${customCharge.proratedTotal.toFixed(2)}
                  </TableTypography>
                </TableCellCustom>
              </TableRow>
            );
          })}
        </>
      )}
    </Table>
  );
};

const OtherCharges = ({
  lineHaulTotal,
  orderChargeTotal,
}: {
  lineHaulTotal: number | undefined;
  orderChargeTotal: number | undefined;
}) => {
  const theme = useTheme();
  if (isNil(lineHaulTotal) && isNil(orderChargeTotal)) return null;
  return (
    <Table>
      <TableHeadCustom backgroundColor={theme.palette.grey[300]}>
        <TableCellCustom cellPadding={1} sx={{ width: '30%' }}>
          <TableHeaderTypography sidePadding={10}>OTHER</TableHeaderTypography>
        </TableCellCustom>
        <TableCellCustom cellPadding={0} sx={{ width: '30%' }}>
          <TableHeaderTypography />
        </TableCellCustom>
        <TableCellCustom cellPadding={0} sx={{ width: '30%' }}>
          <TableHeaderTypography />
        </TableCellCustom>
        <TableCellCustom cellPadding={0} sx={{ width: '10%' }}>
          <TableHeaderTypography />
        </TableCellCustom>
      </TableHeadCustom>
      {!isNil(lineHaulTotal) && (
        <TableRow>
          <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
            <TableTypography>Line haul charges</TableTypography>
          </TableCellCustom>
          <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
            <TableTypography>${lineHaulTotal}</TableTypography>
          </TableCellCustom>
          <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
            <TableTypography isGrey>n/a</TableTypography>
          </TableCellCustom>
          <TableCellCustom
            cellPadding={3}
            sx={{ width: '10%', textAlign: 'right' }}
          >
            <TableTypography>${lineHaulTotal}</TableTypography>
          </TableCellCustom>
        </TableRow>
      )}
      {!isNil(orderChargeTotal) && (
        <TableRow>
          <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
            <TableTypography>Order charges</TableTypography>
          </TableCellCustom>
          <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
            <TableTypography>${orderChargeTotal}</TableTypography>
          </TableCellCustom>
          <TableCellCustom cellPadding={3} sx={{ width: '30%' }}>
            <TableTypography isGrey>n/a</TableTypography>
          </TableCellCustom>
          <TableCellCustom
            cellPadding={3}
            sx={{ width: '10%', textAlign: 'right' }}
          >
            <TableTypography>${orderChargeTotal}</TableTypography>
          </TableCellCustom>
        </TableRow>
      )}
    </Table>
  );
};

const ProratedOrderDetails = ({
  consolidationOutput,
  setOpenedOrderUuid,
}: {
  consolidationOutput: PreviewProratedConsolidationV2Output | undefined;
  setOpenedOrderUuid: (uuid: string) => void;
}) => {
  const theme = useTheme();

  if (isNil(consolidationOutput?.__typename)) {
    return null;
  }
  if (consolidationOutput.__typename === 'QueryErrorOutput') {
    return <ErrorBanner errorMessage={consolidationOutput.message} />;
  }
  if (
    consolidationOutput.__typename ===
    'PreviewProratedConsolidationV2SuccessOutput'
  ) {
    const { proratedOrders, totalAfterProrating } = consolidationOutput;

    return (
      <Card variant="outlined" sx={{ height: '70vh', overflow: 'scroll' }}>
        <Stack gap={1}>
          <Stack p={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" sx={{ fontSize: '18px' }}>
                Pro-rated total for all orders
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: '18px', textAlign: 'right' }}
              >
                ${totalAfterProrating.toFixed(2)}
              </Typography>
            </Stack>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '14px', color: theme.palette.grey[500] }}
            >
              Verify the weight-adjusted charges for these orders. Pro-rated
              charges will be set as flat rates on consolidation and cannot be
              changed.
            </Typography>
          </Stack>
          <Table>
            <TableHeadCustom addBorderBottom>
              <TableCellCustom sx={{ width: '30%' }}>
                <TableHeaderTypography sidePadding={4}>
                  Charge type
                </TableHeaderTypography>
              </TableCellCustom>
              <TableCellCustom sx={{ width: '30%' }}>
                <TableHeaderTypography>Original charge</TableHeaderTypography>
              </TableCellCustom>
              <TableCellCustom sx={{ width: '30%' }}>
                <TableHeaderTypography>
                  Proportion of total weight
                </TableHeaderTypography>
              </TableCellCustom>
              <TableCellCustom sx={{ width: '10%', textAlign: 'right' }}>
                <TableHeaderTypography>Total</TableHeaderTypography>
              </TableCellCustom>
            </TableHeadCustom>
          </Table>
          {proratedOrders?.map((proratedOrder) => {
            return (
              <Stack key={null} p={1} gap={1} borderBottom="solid 0.5px grey">
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" gap={1}>
                    <HawbLink
                      hawb={proratedOrder.hawb ?? ''}
                      onClick={() => setOpenedOrderUuid(proratedOrder.uuid)}
                      fontSize={16}
                    />
                    <Typography sx={{ color: theme.palette.grey[500] }}>
                      {proratedOrder.name}
                    </Typography>
                  </Stack>
                  <Typography variant="h6" fontSize="16px">
                    ${proratedOrder.totalAfterProrating}
                  </Typography>
                </Stack>
                <BreakdownOfChargesAfterProrate
                  chargeType="INBOUND"
                  shipmentInfo={proratedOrder.inboundShipment ?? null}
                  order={proratedOrder}
                />
                <BreakdownOfChargesAfterProrate
                  chargeType="OUTBOUND"
                  shipmentInfo={proratedOrder.outboundShipment ?? null}
                  order={proratedOrder}
                />
                <OtherCharges
                  lineHaulTotal={
                    proratedOrder.lineHaulInitialTotal ?? undefined
                  }
                  orderChargeTotal={
                    proratedOrder.orderChargesInitialTotal ?? undefined
                  }
                />
              </Stack>
            );
          })}
        </Stack>
      </Card>
    );
  }

  return null;
};

export default ProratedOrderDetails;
