import FilterListIcon from '@mui/icons-material/FilterList';
import { isEmpty } from 'lodash';
import { OrderTableFilterModel } from '../../types';
import IconButtonWithPopover from '../IconButtonWithPopover';
import { EditOrderTableFilters } from './edit-order-table-filters';
import { useFilterConstructionTypes } from './use-filter-construction-types';

interface FilterButtonProps {
  filterModelV2: OrderTableFilterModel;
  setFilterModelV2: (filterModel: OrderTableFilterModel) => void;
  hasChanges: boolean;
}

const FilterButton = ({
  filterModelV2,
  setFilterModelV2,
  hasChanges,
}: FilterButtonProps) => {
  const {
    internalFilters,
    setInternalFilters,
    resetInternalFilters,
    onApplyFilter,
  } = useFilterConstructionTypes({ filterModelV2, setFilterModelV2 });

  const onClose = () => {
    resetInternalFilters();
  };

  return (
    <IconButtonWithPopover
      icon={<FilterListIcon />}
      shouldHighlight={!isEmpty(filterModelV2)}
      showBadge={hasChanges}
      onClose={onClose}
      render={(handleIconPopoverClose) => (
        <EditOrderTableFilters
          tempFilter={internalFilters}
          setTempFilter={setInternalFilters}
          onClose={handleIconPopoverClose}
          onSave={onApplyFilter}
        />
      )}
    />
  );
};

export default FilterButton;
