import { v4 } from 'uuid';
import * as yup from 'yup';
import { ShipmentStatus } from '../../../../../generated/graphql';
import { customChargeSchema } from './custom-charge-schema';
import { freightChargeSchema } from './freight-charge-schema';

export const orderChargesShipmentSchema = yup
  .object({
    uuid: yup
      .string()
      .required()
      .default(() => v4()),
    freightCharge: freightChargeSchema.optional().nullable().default(undefined),
    customCharges: yup.array().of(customChargeSchema),
    miles: yup.number().optional().nullable(),
    isLocal: yup.boolean().required().nullable().optional(),
    shipmentStatus: yup
      .mixed<ShipmentStatus>()
      .oneOf(Object.values(ShipmentStatus))
      .nullable()
      .optional(),
    totalCharge: yup.number().optional().nullable(),
    invoiceUuid: yup.string().nullable().optional(),
    invoiceName: yup.string().nullable().optional(),
    invoiceDate: yup.date().nullable().optional(),
    invoicePostedDate: yup.date().nullable().optional(),
  })
  .required();
