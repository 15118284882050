import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  DriverSettlementBillFragment,
  useDriverSettlementBillsLazyQuery,
} from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import useStyles from './general-styles';

export type Option = {
  value: string;
  driver: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startDate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  endDate: any;
  label: string;
};

interface SettlementsFilterButtonProps {
  filterDefaultTitle?: string;
  prefix?: string;
  driverUuid: string;
  selectedOption: Option | undefined;
  handleChange: (option: Option | undefined | null) => void;
  addToOpenSettlements?: boolean;
}

const getOptionFromBill = (bill: DriverSettlementBillFragment) => {
  return {
    label: !isNil(bill.name)
      ? bill.name
      : dayjs(bill.createdAt).format('MM/DD/YY'),
    driver: `${bill.driver.firstName ?? ''} ${bill.driver.lastName ?? ''}`,
    startDate: bill.billStartDate,
    endDate: bill.billEndDate,
    value: bill.uuid,
  };
};

const SettlementsFilterButton = ({
  filterDefaultTitle = 'All',
  prefix = '',
  driverUuid,
  selectedOption,
  handleChange,
  addToOpenSettlements,
}: SettlementsFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [searchText, setSearchText] = useState<string>();
  const [debouncedSearchText] = useDebounce(searchText, 200);
  const [driverSettlementBills, setDriverSettlementBills] =
    useState<DriverSettlementBillFragment[]>();
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;
  const [getDriverSettlementBills] = useDriverSettlementBillsLazyQuery();

  useEffect(() => {
    const fetch = async () => {
      const res = await getDriverSettlementBills({
        variables: {
          driverUuid,
          isFinalized: false,
          limit: 10,
          searchText,
        },
      });
      setDriverSettlementBills(
        res.data?.driverSettlementBills.edges.map(({ node }) => node),
      );
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUuid, debouncedSearchText]);

  useEffect(() => {
    if (addToOpenSettlements === true) {
      const recentBill = driverSettlementBills?.[0];
      if (!isNil(recentBill)) {
        handleChange(getOptionFromBill(recentBill));
      }
    } else if (addToOpenSettlements === false) {
      handleChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToOpenSettlements, driverSettlementBills]);

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
          e.stopPropagation();
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>Settlement Bill:</Typography>
          <Typography sx={styles.filterValue}>
            {!isNil(selectedOption?.label)
              ? `${prefix}${selectedOption?.label} (${dayjs(
                  selectedOption?.startDate,
                ).format('MM/DD')} - 
                              ${dayjs(selectedOption?.endDate).format(
                                'MM/DD/YY',
                              )})`
              : filterDefaultTitle}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="settlement-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            onClick={(e) => {
              handleChange(undefined);
              e.stopPropagation();
            }}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    selectedOption === undefined ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>{filterDefaultTitle}</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: !isAllSelected ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={selectedOption}
                options={
                  driverSettlementBills?.map((bill) =>
                    getOptionFromBill(bill),
                  ) ?? []
                }
                matchSortOptions={{ keys: ['label'] }}
                componentsProps={{
                  popper: { style: { width: '300px' } },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    size="small"
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props}>
                      <Grid
                        container
                        sx={{ width: '300px' }}
                        alignItems="center"
                      >
                        <Grid item xs={7}>
                          <Typography color="text.primary">
                            {option.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            color="text.secondary"
                            variant="caption"
                            sx={{ float: 'right' }}
                          >
                            {option.driver}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {!isNil(option.startDate) &&
                            !isNil(option.endDate) && (
                              <Typography
                                color="text.secondary"
                                variant="caption"
                              >
                                {dayjs(option.startDate).format('MM/DD')} -{' '}
                                {dayjs(option.endDate).format('MM/DD/YY')}
                              </Typography>
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }}
                onChange={(event, option) => {
                  handleChange(option);
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SettlementsFilterButton;
