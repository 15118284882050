import { buildStringDifferenceBlockComponent } from '../audit-log-utils';

type StringDifferenceBlockProps = {
  newValue: string | null | undefined;
  oldValue: string | null | undefined;
  isOldValueBeingDisplayed: boolean;
  wasEntireEntityDeleted: boolean;
  wasEntireEntityAdded: boolean;
};
const StringDifferenceBlock = ({
  oldValue,
  newValue,
  isOldValueBeingDisplayed,
  wasEntireEntityDeleted,
  wasEntireEntityAdded,
}: StringDifferenceBlockProps) => {
  return buildStringDifferenceBlockComponent(
    oldValue,
    newValue,
    isOldValueBeingDisplayed,
    wasEntireEntityDeleted,
    wasEntireEntityAdded,
    14,
  );
};

export default StringDifferenceBlock;
