import { Clear } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputAdornment,
  InputProps,
  Stack,
  TextField,
} from '@mui/material';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  FilterChangedEvent,
  GridReadyEvent,
  IRowNode,
  IServerSideGetRowsParams,
  PaginationChangedEvent,
  SelectionChangedEvent,
  SideBarDef,
  SortChangedEvent,
  SortModelItem,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { debounce, isEmpty, isNil, uniq, uniqBy } from 'lodash';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import { filterNotNil } from 'shared/array';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import DateDropdownPicker, {
  DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../common/components/date-dropdown-picker';
import TerminalFilterButton from '../../../common/components/terminal-filter-button';
import { Option } from '../../../common/filters/types';
import {
  DISPATCH_SERVICE_DATE_OPTION_KEY,
  LH_PAGE_SIZE_LOCAL_STORAGE_KEY,
} from '../../../common/local-storage/keys';
import useMe from '../../../common/react-hooks/use-me';
import {
  useLineHaulOrdersWithUpcomingSegmentAndInboundStopStatusLazyQuery,
  SortDirection,
  OrderLineHaulSegmentSortFields,
  LinehaulDispatchTableField,
  useUpdateUserMutation,
  useSegmentedLineHaulOrdersV2LazyQuery,
  TableFieldsDocument,
} from '../../../generated/graphql';
import ConfigureLinehaulDispatchTableColumns from '../../line-haul/components/ag-grid/configure-line-haul-dispatch-columns';
import useLineHaulDispatchActions from '../../line-haul/hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../../line-haul/store/line-haul-dispatch-store';
import { SegmentedLineHaulOrder } from '../../line-haul/types';
import { canAddOrderToManifest } from '../../line-haul/utils';
import PageSizeSelector, {
  MAX_PAGE_SIZE,
  PageSizes,
} from '../PageSizeSelector';
import GridActions from '../orders/GridActions';

const INITIAL_PAGE_SIZE = 25;

const getSortAndFilterVariables = ({
  sortModel = [],
}: {
  sortModel?: SortModelItem[];
}) => {
  let sorts;
  if (!isNil(sortModel)) {
    sorts = filterNotNil(
      sortModel.map((sort) =>
        Object.values(OrderLineHaulSegmentSortFields).includes(
          sort.colId as OrderLineHaulSegmentSortFields,
        )
          ? {
              sortBy: sort.colId as OrderLineHaulSegmentSortFields,
              sortDirection: sort.sort.toUpperCase() as SortDirection,
            }
          : null,
      ),
    );
  }

  return { sorts };
};

interface State {
  currentCursor: string | null | undefined;
  debouncedSearchText: string;
  startTerminalOption: Option | undefined;
  endTerminalOption: Option | undefined;
  hasPageLoadedBeforeForPageChange: boolean;
}

type TableContext = {
  serviceDateOption: DateOption;
};

interface LineHaulOrdersTableProps {
  tableHeaders?: LinehaulDispatchTableField[];
  columnDefinitions: ColDef<SegmentedLineHaulOrder>[];
  gridRef: RefObject<AgGridReact<SegmentedLineHaulOrder>>;
  selectedUuids: string[];
  setSelectedUuids: Dispatch<SetStateAction<string[]>>;
  isHeaderCheckboxSelected: boolean;
  setIsHeaderCheckboxSelected: Dispatch<SetStateAction<boolean>>;
}

const LineHaulDispatchTableAgGrid = ({
  tableHeaders,
  columnDefinitions,
  gridRef,
  selectedUuids,
  setSelectedUuids,
  isHeaderCheckboxSelected,
  setIsHeaderCheckboxSelected,
}: LineHaulOrdersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const gridRef = useRef<AgGridReact<LineHaulOrder>>(null);
  const toolPanelVisibleRef = useRef(false);
  const [getSegmentedLineHaulOrdersV2] =
    useSegmentedLineHaulOrdersV2LazyQuery();
  const [getLineHaulOrdersWithUpcomingSegmentAndInboundStopStatus] =
    useLineHaulOrdersWithUpcomingSegmentAndInboundStopStatusLazyQuery();
  const [, setState, stateRef] = useStateRef<State>({
    currentCursor: null,
    debouncedSearchText: '',
    startTerminalOption: undefined,
    endTerminalOption: undefined,
    hasPageLoadedBeforeForPageChange: false,
  });
  const [serviceDateOption, setServiceDateOption] =
    // The same key is used on the dispatch Routes page and here for the user's convenience as they switch across pages.
    useLocalStorageState<DateOption>(DISPATCH_SERVICE_DATE_OPTION_KEY, {
      defaultValue: initialDateOption,
    });

  const routePageNumber = (() => {
    const routePage = searchParams.get('page');
    if (typeof routePage === 'string') {
      return parseInt(routePage, 10);
    }
    return 1;
  })();

  const onPaginationChangedHandler = (
    params: PaginationChangedEvent<SegmentedLineHaulOrder>,
  ) => {
    if (
      stateRef.current.hasPageLoadedBeforeForPageChange &&
      params.api.paginationGetCurrentPage() !== routePageNumber - 1
    ) {
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set(
          'page',
          (params.api.paginationGetCurrentPage() + 1).toString(),
        );
        return newParams;
      });
    }

    if (stateRef.current.hasPageLoadedBeforeForPageChange) return;
    if (routePageNumber === 1) {
      stateRef.current.hasPageLoadedBeforeForPageChange = true;
    } else if (
      params.api.getDisplayedRowCount() > 1 &&
      params.api.paginationGetCurrentPage() !== routePageNumber - 1
    ) {
      stateRef.current.hasPageLoadedBeforeForPageChange = true;
      params.api.paginationGoToPage(routePageNumber - 1);
    }
  };

  const [
    shouldRefreshGrid,
    setShouldRefreshGrid,
    openedManifest,
    currentManifestTab,
    setSnackbarSuccessMessage,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.shouldRefreshGrid,
      state.setShouldRefreshGrid,
      state.openedManifest,
      state.currentManifestTab,
      state.setSnackbarSuccessMessage,
    ],
    shallow,
  );
  const { addMultipleOrdersToOpenedManifest } = useLineHaulDispatchActions();
  const [columnWidths, setColumnWidths] = useLocalStorageState<
    { width: number | undefined; colId: string }[]
  >('line_haul_dispatch_column_widths', {
    defaultValue: [],
  });
  const { userUuid } = useMe();
  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [TableFieldsDocument],
  });
  const [searchText, setSearchText] = useState('');
  const [showConfigureHeaders, setShowConfigureHeaders] = useState(false);
  const [pageSize, setPageSize] = useLocalStorageState<PageSizes>(
    LH_PAGE_SIZE_LOCAL_STORAGE_KEY,
    {
      defaultValue: INITIAL_PAGE_SIZE,
    },
  );

  const fetchOrdersWithNextSegmentAndInboundStopStatus = async ({
    rowUuids,
    orderUuids,
  }: {
    rowUuids: string[];
    orderUuids: string[];
  }) => {
    const res = await getLineHaulOrdersWithUpcomingSegmentAndInboundStopStatus({
      variables: {
        uuids: orderUuids,
      },
    });

    const updatedRowsWithUpcomingSegment: (SegmentedLineHaulOrder | null)[] =
      rowUuids.map((rowUuid) => {
        const existingSegmentedOrderData =
          gridRef.current?.api.getRowNode(rowUuid)?.data;

        const orderWithUpcomingSegment = res.data?.ordersByUuids?.find(
          (o) => o.uuid === existingSegmentedOrderData?.order.uuid,
        );

        if (
          !isNil(orderWithUpcomingSegment) &&
          !isNil(existingSegmentedOrderData)
        ) {
          const returnedValue: SegmentedLineHaulOrder = {
            ...existingSegmentedOrderData,
            upcomingLineHaulSegment:
              orderWithUpcomingSegment.upcomingLineHaulSegment ?? undefined,
            hasNoneOrCompletedInboundStop:
              orderWithUpcomingSegment.hasNoneOrCompletedInboundStop,
            lastCompletedLineHaulSegment:
              orderWithUpcomingSegment.lastCompletedLineHaulSegment ??
              undefined,
          };
          return returnedValue;
        }
        return null;
      });

    if (!isNil(updatedRowsWithUpcomingSegment)) {
      gridRef.current?.api.applyServerSideTransaction({
        update: updatedRowsWithUpcomingSegment,
      });
      gridRef.current?.api.redrawRows();
    }
  };

  const deselectAll = () => {
    gridRef.current?.api.deselectAll();
    setSelectedUuids([]);
  };

  // call this if we want to refresh the grid (filters change, etc.)
  const refreshGrid = ({
    shouldDeselect = false,
  }: {
    shouldDeselect?: boolean;
  }) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
        totalCount: undefined,
      };
    });
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.paginationGoToFirstPage();
      gridRef.current?.api.refreshServerSide({ purge: true });
      if (shouldDeselect) {
        deselectAll();
        gridRef.current?.api.deselectAll();
      }
    }
  };

  const handleSortChanged = (_event: SortChangedEvent) => {
    refreshGrid({});
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
  };

  const createServerSideDatasource = () => {
    return {
      async getRows(
        params: IServerSideGetRowsParams<SegmentedLineHaulOrder, TableContext>,
      ) {
        const serviceDateFilters = {
          startFilterValue: params.context.serviceDateOption.startDate,
          endFilterValue: params.context.serviceDateOption.endDate,
        };

        const { sorts } = getSortAndFilterVariables({
          // filterModel: params.request.filterModel,
          sortModel: params.request.sortModel,
        });

        const { data } = await getSegmentedLineHaulOrdersV2({
          variables: {
            findSegmentedLineHaulOrdersInputV2: {
              includeCount: true,
              pageSize: MAX_PAGE_SIZE,
              page: Math.floor((params.request.startRow ?? 0) / MAX_PAGE_SIZE),
              searchText: stateRef.current.debouncedSearchText?.trim(),
              startTerminalUuid: stateRef.current.startTerminalOption?.value,
              endTerminalUuid: stateRef.current.endTerminalOption?.value,
              sorts,
              serviceDateFilters,
            },
          },
        });

        const segmentedOrders = data?.segmentedLineHaulOrdersV2.edges ?? [];
        params.success({
          rowData: segmentedOrders,
          rowCount: data?.segmentedLineHaulOrdersV2.totalCount ?? undefined,
        });
        fetchOrdersWithNextSegmentAndInboundStopStatus({
          rowUuids: segmentedOrders.map((s) => s.uuid),
          orderUuids: segmentedOrders.map((s) => s.order.uuid),
        });
      },
    };
  };

  useEffect(() => {
    if (shouldRefreshGrid) {
      refreshGrid({
        shouldDeselect: true,
      });
      setShouldRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGrid]);

  useEffect(() => {
    refreshGrid({
      shouldDeselect: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDateOption]);

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        startTerminalOption: !isNil(openedManifest)
          ? {
              label: openedManifest.startTerminal.code,
              value: openedManifest.startTerminal.uuid,
            }
          : undefined,
        endTerminalOption: !isNil(openedManifest)
          ? {
              label: openedManifest.endTerminal.code,
              value: openedManifest.endTerminal.uuid,
            }
          : undefined,
      };
    });
    refreshGrid({
      shouldDeselect: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedManifest?.uuid]);

  const updateColumnWidthsOnGridChange = () => {
    // Columns widths can't be updated until AG Grid has finished rendering the columns
    // So this code can't be placed in the useEffect above
    if (!isNil(gridRef.current?.columnApi)) {
      gridRef.current?.columnApi.setColumnWidths(
        columnWidths.map((columnWidth) => ({
          newWidth: columnWidth.width ?? 0,
          key: columnWidth.colId,
        })),
      );
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.closeToolPanel();
  };

  const closeFilterToolPanel = () => {
    const filterToolPanel =
      gridRef.current?.api.getToolPanelInstance('filters');
    filterToolPanel?.collapseFilters();
    // reset filters to all before closing.
    filterToolPanel?.refresh();
    filterToolPanel?.setFilterLayout(columnDefinitions);
    gridRef.current?.api.closeToolPanel();
  };

  const defaultColDef: ColDef<SegmentedLineHaulOrder> = useMemo(() => {
    return {
      flex: 1,
      wrapHeaderText: true,
      wrapText: true,
      resizable: true,
      suppressMenu: true,
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: true,
          },
        },
      ],
      defaultToolPanel: 'filters',
      position: 'right',
    };
  }, []);

  // This closes the toolpanel if we click outside the filter toolpanel since AG Grid doesn't have an API for this
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as Element).closest(
        '.ag-filter-toolpanel',
      );

      const isDateRangePickerClicked = (event.target as Element).closest(
        '.rmdp-wrapper',
      );

      if (
        gridApi &&
        !isToolPanelClicked &&
        !isDateRangePickerClicked &&
        toolPanelVisibleRef.current
      ) {
        closeFilterToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef]);

  const handleSearch = () => {
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
    refreshGrid({});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = debounce((value) => {
    setState((prevState) => {
      return {
        ...prevState,
        debouncedSearchText: value,
        currentCursor: null,
      };
    });
    refreshGrid({
      shouldDeselect: true,
    });
  }, 1000);

  const bulkAddOrders = async () => {
    const res = await addMultipleOrdersToOpenedManifest({
      orderUuids: selectedUuids,
      openedManifestUuid: openedManifest?.uuid,
    });
    if (!isNil(res)) {
      setSnackbarSuccessMessage(
        `Order(s) added to ${openedManifest?.referenceNumber}`,
      );
      gridRef.current?.api.applyServerSideTransaction({
        remove: gridRef.current.api.getSelectedNodes().map((node) => node.data),
      });
    }
    deselectAll();
  };

  const handleStartTerminalChange = (option: Option | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        startTerminalOption: option ?? undefined,
      };
    });
    refreshGrid({ shouldDeselect: true });
  };
  const handleEndTerminalChange = (option: Option | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        endTerminalOption: option ?? undefined,
      };
    });
    refreshGrid({ shouldDeselect: true });
  };

  const handleRowSelected = (
    selectedNode?: IRowNode<SegmentedLineHaulOrder>,
  ) => {
    // when selecting or unselecting nodes, need to select/unselect
    // all duplicates created by the reselection that was done above
    gridRef.current?.api.getSelectedNodes().forEach((node) => {
      if (node.data?.order.uuid === selectedNode?.data?.order.uuid) {
        if (selectedNode?.isSelected() === false) {
          node.setSelected(false);
        } else {
          node.setSelected(true);
        }
      }
    });

    const selectedRows = gridRef.current?.api.getSelectedRows();
    setSelectedUuids(uniqBy(selectedRows, 'uuid').map((row) => row.order.uuid));
  };

  const selectAllOrdersOnPage = () => {
    const orderUuids = filterNotNil(
      gridRef.current?.api.getRenderedNodes().map((node) => {
        node.setSelected(true);
        handleRowSelected(node);
        return node.data?.order.uuid;
      }) ?? [],
    );
    const allSelectedUuids = uniq([...selectedUuids, ...orderUuids]);
    setSelectedUuids(allSelectedUuids);
  };

  const handleSelectionChanged = (
    event: SelectionChangedEvent<SegmentedLineHaulOrder>,
  ) => {
    if (event?.source === 'uiSelectAll') {
      if (isHeaderCheckboxSelected) {
        deselectAll();
      } else {
        selectAllOrdersOnPage();
      }
      setIsHeaderCheckboxSelected((prev) => !prev);
    }
  };

  const handleFilterChanged = (_event: FilterChangedEvent) => {
    refreshGrid({ shouldDeselect: true });
  };

  const handleColumnResized = async (e: ColumnResizedEvent) => {
    const columnState = gridRef.current?.columnApi.getColumnState();

    if (e.finished && e.source === 'uiColumnResized' && !isNil(columnState)) {
      setColumnWidths(
        columnState.map((col) => ({ colId: col.colId, width: col.width })),
      );
    }
  };

  const handleColumnMoved = async (e: ColumnMovedEvent) => {
    if (isNil(userUuid)) {
      return;
    }
    const state = gridRef.current?.columnApi.getColumnState();
    if (e.finished && e.source === 'uiColumnMoved' && !isNil(state)) {
      const headers =
        state.filter((col) => col.hide !== true).map((col) => col.colId) ?? [];
      const updatedLinehaulDispatchFields = filterNotNil(
        headers.map((header) => {
          const field = Object.values(LinehaulDispatchTableField).find(
            (f) => f === header,
          );
          return field;
        }),
      );

      updateUser({
        variables: {
          updateUserInput: {
            uuid: userUuid,
            linehaulDispatchTableFields: updatedLinehaulDispatchFields,
          },
        },
      });
    }
  };

  const TextFieldInputProps: Partial<InputProps> = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <IconButton
        disabled={isEmpty(searchText)}
        onClick={() => {
          setSearchText('');
          debouncedSearch('');
        }}
      >
        <Clear />
      </IconButton>
    ),
  };

  const tableContext: TableContext = {
    serviceDateOption,
  };

  return (
    <Grid
      item
      xs={12}
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <GridActions>
        <PageSizeSelector
          initialPageSize={pageSize}
          onPageSizeChange={(size) => {
            setPageSize(size);
          }}
        />
      </GridActions>
      <Stack direction="row" gap={2} p={2} sx={{ flexWrap: 'wrap' }}>
        {showConfigureHeaders && !isNil(userUuid) && !isNil(tableHeaders) && (
          <ConfigureLinehaulDispatchTableColumns
            open={showConfigureHeaders}
            setOpen={setShowConfigureHeaders}
            userUuid={userUuid}
            tableFields={tableHeaders}
          />
        )}
        <TextField
          sx={{ flex: 1, flexBasis: '180px' }}
          className="dispatch-search-input"
          variant="standard"
          size="medium"
          placeholder="Search orders..."
          autoComplete="off"
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              handleSearch();
              debouncedSearch.cancel();
            }
          }}
          InputProps={TextFieldInputProps}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            debouncedSearch(e.target.value);
          }}
        />
        <TerminalFilterButton
          prefixText="Origin"
          selectedOption={stateRef.current.startTerminalOption}
          handleChange={handleStartTerminalChange}
          includeInactiveTerminals={false}
          isSmall
        />
        <TerminalFilterButton
          prefixText="Destination"
          selectedOption={stateRef.current.endTerminalOption}
          handleChange={handleEndTerminalChange}
          includeInactiveTerminals={false}
          isSmall
        />
        <Box>
          <DateDropdownPicker
            filterTitle="Service Date"
            dateOption={serviceDateOption}
            setDateOption={setServiceDateOption}
            showFilters={[
              DatePickerFilterType.AllSelect,
              DatePickerFilterType.PastDayInput,
              DatePickerFilterType.DayPaginate,
              DatePickerFilterType.Range,
            ]}
            small
            allowNoLimit
          />
        </Box>
        <Box>
          <Button
            onClick={() => {
              setShowConfigureHeaders(true);
            }}
            variant="outlined"
            style={{
              borderRadius: '4px',
            }}
          >
            Edit columns
          </Button>
        </Box>
        {canAddOrderToManifest(openedManifest, currentManifestTab) && (
          <Box>
            <Button
              variant="contained"
              disabled={selectedUuids.length <= 0}
              sx={{ padding: '5.5px 8.25px', borderRadius: '8px' }}
              onClick={() => {
                bulkAddOrders();
              }}
            >
              Add Orders ({selectedUuids.length})
            </Button>
          </Box>
        )}
      </Stack>
      {!stateRef.current.hasPageLoadedBeforeForPageChange && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        className="ag-theme-material ag-non-compact"
        style={{
          flex: 1,
          width: '100%',
          visibility: stateRef.current.hasPageLoadedBeforeForPageChange
            ? 'visible'
            : 'hidden',
        }}
      >
        <AgGridReact<SegmentedLineHaulOrder>
          context={tableContext}
          blockLoadDebounceMillis={500}
          onColumnMoved={handleColumnMoved}
          columnDefs={columnDefinitions}
          className="line-haul-ag-grid"
          defaultColDef={defaultColDef}
          rowModelType="serverSide"
          onGridReady={onGridReady}
          pagination
          sideBar={sideBar}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onRowSelected={(e) => {
            handleRowSelected(e.node);
          }}
          onSelectionChanged={handleSelectionChanged}
          onFilterChanged={handleFilterChanged}
          ref={gridRef}
          suppressDragLeaveHidesColumns
          onSortChanged={handleSortChanged}
          onColumnResized={handleColumnResized}
          onColumnEverythingChanged={updateColumnWidthsOnGridChange}
          getRowId={(params) => params.data?.uuid}
          paginationPageSize={pageSize}
          onPaginationChanged={onPaginationChangedHandler}
        />
      </div>
    </Grid>
  );
};

export default React.memo(LineHaulDispatchTableAgGrid);
