// Since there's no apparent order or structure to this enum, can we all just put new flags at the end?
export enum FeatureFlag {
  FF_ACCESSORIAL_QUICK_ADD = 'accessorial_quick_add',
  FF_NEW_CSV_INVOICE_BUTTON_ENABLED = 'csv_new_invoice_button_enabled',
  FF_SHOW_FREIGHT_SNAP_TABLE = 'show_freight_snap_table',
  FF_PACKAGE_SPECS = 'package_specs',
  FF_COURIER_V1 = 'courier_v1',
  FF_ORDER_FORM_TABS = 'order_form_tabs',
  FF_RECOVERY_TRANSFER_ADDRESS_ONLY = 'recovery_transfer_address_only',

  FF_SCHEDULING = 'scheduling',
  FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION = 'require_transfer_address_on_completion',
  FF_SHOW_COLLECT_ON_DELIVERY = 'show_collect_on_delivery',
  FF_SHOW_CONTACT_ID = 'show_contact_id',
  FF_SHOW_TRANSFER_ADDRESS = 'show_transfer_address',

  FF_USE_NEW_SHIPMENT_CREATE_INPUTS = 'use_new_shipment_create_inputs',
  FF_NEW_DISPATCH_PAGE = 'new_dispatch_page',
  FF_DISPATCH_TRACK_PARITY = 'dispatch_track_parity',
  FF_DISPATCH_VIEWS = 'dispatch_views',
  FF_DISPATCH_VIRTUALIZED_ROUTES = 'dispatch_virtualized_routes',

  FF_DISALLOW_ZERO_DOLLAR_CHARGES = 'disallow_zero_dollar_charges',

  FF_DISABLE_NUMBERS_QUERIES = 'disable_numbers_queries', // Disables numberOfOrders query on billing review and dispatch.
  FF_ENABLE_NUMBERS_ON_ORDER_PAGE_QUERIES = 'enable_numbers_on_order_page_queries', // Enables numberOfOrders query on orders table.
  FF_DISABLE_ORDERS_PAGE_COUNT = 'disable_orders_page_count', // Enables showing the page count on orders and billing review grid
  FF_SHOW_RERATED_TOTAL_ON_ORDERS_TABLE = 'show_rerated_total_on_orders_table',
  FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS = 'inbound_outbound_paperwork_columns',
  FF_HIDE_PAPERWORK_COMPLETE_COLUMN = 'hide_paperwork_complete_column',

  FF_SURELOGIX_AGING_REPORT_UPDATES = 'surelogix_aging_report_updates',
  FF_SURELOGIX_ORIGIN_TERMINAL = 'surelogix_origin_terminal',

  FF_USE_NEW_REPORT_DATE_RANGE_MENU = 'use_new_report_date_range_menu',
  FF_SHOW_PRORATE_CONSOLIDATION_FLOW = 'show_prorate_consolidation_flow',
  FF_CONTACT_NAME_FIELDS_COMBINED = 'contact_name_fields_combined',
  FF_DISPATCH_STOPS_RECURRING_RUN_HEADERS = 'dispatch_stops_recurring_run_headers',
  FF_CUSTOMIZABLE_ROUTE_COLUMNS = 'customizable_route_columns',
  FF_INVOICES_OPEN_CLOSED_FILTER = 'invoices_open_closed_filter',
  FF_USE_STATIONS = 'use_stations',

  FF_ZONE_GROUPS_ENABLED = 'zone_groups_enabled',
  FF_USE_BULK_FINALIZE_WITH_RE_RATING = 'use_bulk_finalize_with_re_rating',

  FF_USE_CHARGEABLE_WEIGHT = 'use_chargeable_weight',
  FF_NEW_ITEMIZED_CHARGES_TOOLTIP = 'new_itemized_charges_tooltip',
  FF_APPEND_PIECE_ID_STRING_TO_LOT_LABEL = 'append_piece_id_string_to_lot_label',

  FF_SUPPORT_SELECTED_EMAILS_AND_NOTE = 'support_selected_emails_and_note',
  FF_TARIFF_CHAINS_ENABLED = 'tariff_chains_enabled',

  FF_USE_BATCH_SEND_REPORT = 'use_batch_send_report',
  FF_USE_BATCH_SEND_DUNNING_REPORT = 'use_batch_send_dunning_report',
  FF_USE_SERVICE_DATE_FOR_BILLING_SUMMARY = 'use_service_date_for_billing_summary',
  FF_ENABLE_MILEAGE_BASED_SPECIALS = 'enable_mileage_based_specials',
  FF_GENERATE_ROUTES = 'generate_routes',

  FF_NO_RECOVERY_TRANSFER = 'no_recovery_transfer',
  FF_DEMO_CUSTOMER_PORTAL = 'demo_customer_portal',
  FF_DEMO_CUSTOMER_PORTAL_LOGISTICS_SECTION = 'demo_customer_portal_logistics_section',
  FF_DEMO_CUSTOMER_PORTAL_FULFILLMENT_TYPE = 'demo_customer_portal_fulfillment_type',
  FF_ENABLE_GENERAL_LEDGER_CONFIGURATION_PAGE = 'enable_general_ledger_configuration_page',
  FF_DEMO_LOAD_MANAGEMENT = 'demo_load_management',
  FF_DEMO_DRIVER_TARIFFS = 'demo_driver_tariffs',
  FF_DEMO_STERLING_TARIFFS = 'demo_sterling_tariffs',

  FF_ENABLE_NOTIFICATIONS = 'enable_notifications',
  FF_ENABLE_RECENT_FAILED_SEND_JOBS_QUERY = 'enable_recent_failed_send_jobs_query',
  FF_ENABLE_INVOICE_SEND_SEND_MENU_ICON_COUNT = 'enable_invoice_send_send_menu_icon_count',
  FF_ENABLE_INVOICE_SEND_JOBS_BATCH_LIST_POLLING = 'enable_invoice_send_jobs_batch_list_polling',

  FF_ENABLE_SPLIT_POD_PICKUP_CONFIGURATION = 'enable_split_pod_pickup_configuration',
  FF_ENABLE_SPLIT_POD_DELIVERY_CONFIGURATION = 'enable_split_pod_delivery_configuration',

  FF_DISPATCH_TABLE_PAGINATION = 'dispatch_table_pagination',
  FF_PIECE_STATUSES_ON_ORDER_FORM = 'piece_statuses_on_order_form',

  // Table functions
  /** @deprecated - use useNewTableFunctionsFeatureFlag instead */
  FF_ENABLE_NEW_TABLE_FUNCTIONS = 'enable_new_table_functions',
  FF_DEMO_STERLING_LINE_HAUL = 'demo_sterling_line_haul',
  FF_NEW_DASHBOARD_UI = 'new_dashboard_ui',
  FF_NEW_FONT = 'new_font',

  FF_USE_DISPATCH_CACHE = 'use_dispatch_cache',

  // Feature flag that ensures that only the current tab has the counts displayed on the stops table.
  FF_STOPS_TABLE_COUNTS_FOR_CURRENT_TAB = 'stops_table_counts_for_current_tab',

  /* Show customer contact (aka caller) fields on order entry */
  FF_SHOW_CUSTOMER_CONTACT = 'show_customer_contact',
  FF_ORDER_EVENT_NOTIFICATIONS = 'order_event_notifications',
}
