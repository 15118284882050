import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import dayjs, { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';
import { isEmpty, isNil } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import GeneralDatePicker from '../../../../../common/components/date-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import { FeatureFlag } from '../../../../../common/feature-flags';
import { Option } from '../../../../../common/filters/types';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import {
  isNilOrEmptyString,
  validateEmail,
} from '../../../../../common/utils/utils';
import {
  AccountingReportType,
  AccountingReportTypeV2,
  EmailAccountingReportStatus,
  InvoiceAgingReportQuery,
  ReportFiltersInput,
  useInvoiceAgingReportLazyQuery,
  useReportsEmailSenderIsVerifiedQuery,
  useShallowContactLazyQuery,
} from '../../../../../generated/graphql';
import EmailReportOptions from '../../../../reports/components/email-reports/email-report-options';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import {
  convertAgingDetailedReportDataToCSVOld,
  convertAgingReportSummaryDataToCSVOld,
  sendAccountingReportEmail,
} from '../../../utils';
import { Address } from '../../accounting-reports/aging-report/generated-detailed-aging-report-pdf';
import { AgingReportType } from '../../accounting-reports/aging-report/utils';
import GeneratedDetailedAgingReportPdfOld from '../../accounting-reports/aging-report-old/generated-detailed-aging-report-pdf-old';
import GeneratedSummaryAgingReportPdfOld from '../../accounting-reports/aging-report-old/generated-summary-aging-report-pdf-old';

interface DownloadInvoiceAgingReportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setShowSendAccountingReportsSuccessMessage: Dispatch<SetStateAction<boolean>>;
  setShowSendAccountingReportsErrorMessage: Dispatch<SetStateAction<boolean>>;
  type: AgingReportType;
}

const DownloadInvoiceAgingReportModalOld = ({
  isOpen,
  setIsOpen,
  setShowSendAccountingReportsSuccessMessage,
  setShowSendAccountingReportsErrorMessage,
  type,
}: DownloadInvoiceAgingReportModalProps) => {
  const { user, companyData, companyConfiguration } = useMe();
  const [customerOption, setCustomerOption] = useState<Option | undefined>(
    undefined,
  );
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  // const [hideCurrent, setHideCurrent] = useState(true);

  const ffUseBatchSendReport = useFeatureFlag(
    FeatureFlag.FF_USE_BATCH_SEND_REPORT,
  );

  const [getInvoiceAgingReport, { loading: agingReportDataLoading }] =
    useInvoiceAgingReportLazyQuery();
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const [csvReportData, setCSVReportData] = useState<{
    fileName: string;
    csvData: (string[] | (string | number | undefined)[])[];
  }>({ fileName: '', csvData: [] });
  const csvLink = useRef<{ link: HTMLAnchorElement }>(null);

  const [recipientEmails, setRecipientEmails] = useState<
    string | null | undefined
  >(undefined);
  const [recipientEmailError, setRecipientEmailError] = useState<string>('');
  const [emailSendStatus, setEmailSendStatus] = useState<string>('');
  const [emailSending, setEmailSending] = useState<boolean>(false);
  const [getContact] = useShallowContactLazyQuery();
  const { data: reportsEmailVerifiedData } =
    useReportsEmailSenderIsVerifiedQuery(
      isNil(user) || isNilOrEmptyString(user.uuid)
        ? { skip: true }
        : { variables: { userUuid: user.uuid } },
    );
  const senderEmail =
    reportsEmailVerifiedData?.reportsEmailSenderIsVerified === true &&
    !isNil(user?.reportsEmail)
      ? user?.reportsEmail
      : undefined;

  const getFilename = (prefix: string, suffix?: string) => {
    return `${prefix}-${dayjs(startDate).format(
      'MM/DD/YYYY',
    )}-${dayjs().format()}.${suffix ?? 'pdf'}`;
  };

  const close = useCallback(() => {
    setEmailSendStatus('');
    setRecipientEmails(undefined);
    setEmailSending(false);
    setCustomerOption(undefined);
    setStartDate(dayjs());
    setIsOpen(false);
  }, [
    setEmailSendStatus,
    setRecipientEmails,
    setEmailSending,
    setCustomerOption,
    setStartDate,
    setIsOpen,
  ]);

  const createSummaryAgingReportPdf = async (
    agingReportData: InvoiceAgingReportQuery,
  ) => {
    const fileName = getFilename(
      `aging-summary-report-${customerOption?.label ?? 'all-customers'}`,
    );
    const blob = await pdf(
      <GeneratedSummaryAgingReportPdfOld
        contactUuid={customerOption?.value}
        data={agingReportData.invoiceAgingReport}
        startDate={startDate}
      />,
    ).toBlob();
    return { blob, fileName };
  };

  const createDetailedAgingReportPdf = async ({
    agingReportData,
    companyName,
    companyAddress,
    companyPhone,
  }: {
    agingReportData: InvoiceAgingReportQuery;
    companyName?: string | null;
    companyPhone?: string | null;
    companyAddress?: Address | null;
  }) => {
    const fileName = getFilename(
      `aging-detailed-report-${customerOption?.label ?? 'all-customers'}`,
    );

    const blob = await pdf(
      <GeneratedDetailedAgingReportPdfOld
        data={agingReportData.invoiceAgingReport}
        startDate={startDate}
        contactUuid={customerOption?.value}
        companyName={companyName ?? undefined}
        companyPhone={companyPhone ?? undefined}
        companyAddress={companyAddress ?? undefined}
        useJournalNumberForInvoice={
          companyConfiguration?.useJournalNumberForInvoice ?? undefined
        }
      />,
    ).toBlob();
    return { blob, fileName };
  };

  const createAgingReportPdf = async (
    agingReportData: InvoiceAgingReportQuery,
  ) => {
    if (type === AgingReportType.Detailed) {
      return createDetailedAgingReportPdf({
        agingReportData,
        companyName: companyData?.name,
        companyPhone: companyData?.phone,
        companyAddress: companyData?.defaultAddress,
      });
    }
    return createSummaryAgingReportPdf(agingReportData);
  };

  const downloadAgingReportPdf = async (
    agingReportData: InvoiceAgingReportQuery,
  ) => {
    const { blob, fileName } = await createAgingReportPdf(agingReportData);
    saveAs(blob, fileName);
  };

  const createSummaryAgingReportCsv = async (
    agingReportData: InvoiceAgingReportQuery,
  ) => {
    const csvData = convertAgingReportSummaryDataToCSVOld(
      agingReportData.invoiceAgingReport,
    );
    setCSVReportData({
      csvData,
      fileName: getFilename('aging-summary-report', 'csv'),
    });
  };

  const createDetailedAgingReportCsv = async (
    agingReportData: InvoiceAgingReportQuery,
  ) => {
    const csvData = convertAgingDetailedReportDataToCSVOld(
      agingReportData.invoiceAgingReport ?? [],
      startDate,
      companyData?.configuration?.useJournalNumberForInvoice ?? undefined,
    );
    setCSVReportData({
      csvData,
      fileName: getFilename(
        `aging-detailed-report-${customerOption?.label ?? 'all'}`,
        'csv',
      ),
    });
  };
  const agingReportInput = useMemo(
    () => ({
      contactUuid: customerOption?.value,
      startDate,
    }),
    [customerOption, startDate],
  );

  const startDownload = async () => {
    const completeDownload = createFileDownload();
    const agingReportData = await getInvoiceAgingReport({
      variables: {
        args: agingReportInput,
      },
    });
    if (isNil(agingReportData) || isNil(agingReportData.data)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'There was an error fetching the aging report',
      });
      return;
    }
    if (isEmpty(agingReportData.data.invoiceAgingReport)) {
      completeDownload({
        alertSeverity: 'error',
        message: 'No data found for aging report',
      });
      return;
    }

    if (downloadType === DownloadType.PDF) {
      await downloadAgingReportPdf(agingReportData.data);
      close();
    } else if (
      downloadType === DownloadType.CSV &&
      type === AgingReportType.Summary
    ) {
      await createSummaryAgingReportCsv(agingReportData.data);
    } else if (
      downloadType === DownloadType.CSV &&
      type === AgingReportType.Detailed
    ) {
      await createDetailedAgingReportCsv(agingReportData.data);
    }
    completeDownload();
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-invoice-aging-report-old',
    logData: { ...agingReportInput, type, downloadType },
  });

  const buildAgingReportFilterInput = (): ReportFiltersInput => {
    return {
      invoiceDateFilter: {
        endFilterValue: startDate.toDate(),
      },
    };
  };

  useEffect(() => {
    if (!isEmpty(csvReportData)) {
      csvLink.current?.link.click();
      close();
    }
  }, [csvReportData, close]);

  useEffect(() => {
    const fetchContact = async () => {
      const contactUuid = customerOption?.value;
      if (!isNil(contactUuid)) {
        const res = await getContact({
          variables: {
            uuid: contactUuid,
          },
        });
        setRecipientEmails(res.data?.contact.billingContactEmail);
      }
    };
    fetchContact();
  }, [customerOption?.value, getContact]);

  useEffect(() => {
    if (!isNil(recipientEmails)) {
      const recipientEmailsList = recipientEmails
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email.length);
      if (recipientEmailsList.some((email) => !validateEmail(email))) {
        setRecipientEmailError('Invalid email');
      } else {
        setRecipientEmailError('');
      }
    } else {
      setRecipientEmailError('');
    }
  }, [recipientEmails]);

  const onEmailPDFButtonClick = async () => {
    setEmailSendStatus('Preparing to send...');
    setEmailSending(true);
    const agingReportData = await getInvoiceAgingReport({
      variables: {
        args: {
          contactUuid: customerOption?.value,
          startDate,
        },
      },
    });
    if (isNil(agingReportData) || isNil(agingReportData.data)) {
      return;
    }
    const { blob, fileName } = await createAgingReportPdf(agingReportData.data);
    const status = await sendAccountingReportEmail({
      blob,
      fileName,
      reportType: AccountingReportType.AgingReport,
      documentName: `${AccountingReportType.AgingReport} ${
        !isNil(customerOption) && !isNil(customerOption.value)
          ? ` (${customerOption.label})`
          : ''
      } - ${dayjs(startDate).format('MM/DD/YYYY')}`,
      senderEmail,
      recipientEmails,
      companyUuid: companyData?.uuid,
    });
    if (status === EmailAccountingReportStatus.Success) {
      setEmailSendStatus('Email sent successfully');
    } else {
      setEmailSendStatus('Error sending email');
    }
    setEmailSending(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        if (!agingReportDataLoading && downloadType === DownloadType.CSV) {
          close();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={[
          styles.modal,
          {
            height: type === AgingReportType.Detailed ? '425px' : '350px',
            width: '500px',
          },
        ]}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Invoice Aging Report ({type})
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={close}
              disabled={
                agingReportDataLoading && downloadType === DownloadType.CSV
              }
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <GeneralDatePicker
              date={startDate}
              setDate={setStartDate}
              text="Calculate aging from"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) =>
                setCustomerOption(option)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} display="flex" gap="4px" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId={`INVOICE_AGING_REPORT_${type}`}
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                onClick={startDownloadWithMeasurement}
                disabled={
                  agingReportDataLoading && downloadType === DownloadType.CSV
                }
              >
                Download
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Sender"
              value={senderEmail ?? 'No report email found'}
              size="small"
              sx={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                '& fieldset': {
                  border: 'none',
                },
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {reportsEmailVerifiedData?.reportsEmailSenderIsVerified ===
                    true ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip
                          title="Report email has been verified"
                          placement="top"
                        >
                          <CheckCircleIcon
                            sx={{ fontSize: 15 }}
                            color="success"
                          />
                        </Tooltip>
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip
                          title={`${
                            isEmpty(senderEmail)
                              ? `No report email found`
                              : 'Report email is unverified'
                          }. Email will send from company invoice email.`}
                          placement="top"
                        >
                          <CloseIcon sx={{ fontSize: 15 }} color="error" />
                        </Tooltip>
                      </Stack>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            style={{ alignItems: 'flex-start' }}
          >
            {ffUseBatchSendReport && type === AgingReportType.Detailed ? (
              <EmailReportOptions
                reportType={AccountingReportTypeV2.AgingReportDetailed}
                reportFilters={buildAgingReportFilterInput()}
                contactUuid={customerOption?.value}
                onEmailPdf={onEmailPDFButtonClick}
                isEmailSending={emailSending}
                showContainerModal={setIsOpen}
                showEmailReportSuccessMessage={
                  setShowSendAccountingReportsSuccessMessage
                }
                showEmailReportErrorMessage={
                  setShowSendAccountingReportsErrorMessage
                }
              />
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                gap="4px"
              >
                <TextField
                  label="Recipients (comma-separated)"
                  value={recipientEmails}
                  onChange={(e) => setRecipientEmails(e.target.value)}
                  size="small"
                  error={!isEmpty(recipientEmailError)}
                  helperText={recipientEmailError}
                  sx={{ flex: 1 }}
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <Button
                  variant="contained"
                  color="info"
                  onClick={onEmailPDFButtonClick}
                  disabled={
                    isNil(recipientEmails) ||
                    !isEmpty(recipientEmailError) ||
                    isEmpty(recipientEmails.trim()) ||
                    emailSending
                  }
                  sx={{ height: 'fit-content' }}
                >
                  Email PDF
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography fontStyle="italic" fontSize="16px">
              {emailSendStatus}
            </Typography>
          </Grid>
        </Grid>
        <CSVLink
          data={csvReportData.csvData}
          filename={csvReportData.fileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      </Box>
    </Modal>
  );
};

export default DownloadInvoiceAgingReportModalOld;
