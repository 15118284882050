import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Box, Button, Stack, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import useLogout from '../../../common/react-hooks/use-logout';
import Invoices from '../../invoices/components/invoices/invoices';

const useStyles = () => {
  return {
    appBar: {
      position: 'sticky',
      flexDirection: 'row',
      height: 64,
      alignItems: 'center',
      justifyContent: 'center',
    },
    appBarTypography: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: 1,
      color: '#FFFFFF',
    },
  };
};

export const CustomerInvoices: FunctionComponent = () => {
  const logout = useLogout();
  const styles = useStyles();
  const [, setShowSendAccountingReportsSuccessMessage] = useState(false);
  const [, setShowSendAccountingReportsErrorMessage] = useState(false);

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: '#F7F7F7',
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <AppBar color="primary" sx={styles.appBar}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography sx={styles.appBarTypography}>Invoices</Typography>
        </Box>
        <Button
          onClick={logout}
          startIcon={<LogoutIcon />}
          sx={{ justifySelf: 'flex-end', color: '#fff' }}
        >
          Logout
        </Button>
      </AppBar>
      <Box
        sx={{
          height: 'calc(100dvh - 64px)',
          paddingTop: '2px',
          backgroundColor: '#fff',
        }}
      >
        <Invoices
          setShowSendAccountingReportsSuccessMessage={
            setShowSendAccountingReportsSuccessMessage
          }
          setShowSendAccountingReportsErrorMessage={
            setShowSendAccountingReportsErrorMessage
          }
          showCustomerFilter={false}
        />
      </Box>
    </Stack>
  );
};
