import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { memo, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useFuelProfilesQuery } from '../../../../../generated/graphql';
import useTariffGroupStore from '../store/tariff-group-state-store';

const FuelProfileField = () => {
  const [fuelProfileUuid, setFuelProfileUuid] = useTariffGroupStore(
    (state) => [state.fuelProfileUuid, state.setFuelProfileUuid],
    shallow,
  );

  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();

  const sortedFuelProfiles = useMemo(
    () =>
      fuelProfilesData?.fuelProfiles.slice().sort((a, b) => {
        return (a.name ?? 'Unnamed').localeCompare(b.name ?? 'Unnamed');
      }),
    [fuelProfilesData],
  );

  return (
    <TextField
      label="Fuel Profile"
      id="select-fuel-profile"
      value={fuelProfileUuid ?? ''}
      onChange={(event) => {
        if (typeof event.target.value === 'string') {
          setFuelProfileUuid(event.target.value);
        }
      }}
      size="small"
      disabled={fuelProfilesLoading}
      fullWidth
      select
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: '20px' }}>
            <IconButton
              onClick={() => {
                setFuelProfileUuid(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    >
      {sortedFuelProfiles?.map((fuelProfile) => (
        <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
          {fuelProfile.name ?? 'Unnamed'}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default memo(FuelProfileField);
