import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FormProvider,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { filterNotNil } from 'shared/array';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import { GappedStackCard } from '../../../../common/components/gapped-stack-card';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { useWarningOnExit } from '../../../../common/react-hooks/warning-on-exit';
import {
  isNilOrEmptyString,
  validatePhoneNumber,
} from '../../../../common/utils/utils';
import {
  FreightBillingMethod,
  OrderDetailedStatus,
  PermissionResource,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import useBillingReviewStore from '../../../invoices/billing-review-store';
import useOrderFormStore from '../../order-form-store';
import { AutocompletePerson } from '../standard/components/autocomplete-person';
import ScannedOrderDocumentsViewer from '../standard/scanned-order-documents-viewer';
import Charges from './components/charges';
import { ContactStationsField } from './components/contact-station';
import Details from './components/details';
import DriverSettlement from './components/driver-settlement';
import ErrorDialog from './components/error-dialog';
import DocScanOrderActions from './components/header/doc-scan-order-actions';
import OrderActions from './components/header/order-actions';
import Logistics from './components/logistics/logistics';
import { OrderCustomerField } from './components/order-customer-field';
import { OrderFormCard } from './components/order-form-card';
import { OrderNameField } from './components/order-name-field';
import OrderOverLimitDialog from './components/order-over-limit-dialog';
import Documents from './components/overview/documents';
import Packages from './components/packages';
import { DemoPackages } from './components/packages/demo/demo-packages';
import RecurringOrderFrequency from './components/recurring-order-frequency/recurring-order-frequency';
import RightSidebar from './components/right-sidebar';
import Stops from './components/stops/stops';
import Tags from './components/tags';
import { OrderFormEditAccessProvider } from './contexts/order-form-edit-access-context';
import { OrderFormValues } from './forms/types';
import useOrderForm from './forms/use-order-form';
import {
  getDisabledForEditAccess,
  useGetOrderFormEditAccess,
} from './forms/use-order-form-edit-access';
import { useValidateChargesForOrder } from './forms/use-validate-charges-for-order';
import { useValidatePackages } from './forms/use-validate-packages';
import { useValidateStops } from './forms/use-validate-stops';
import useDocScan from './hooks/use-doc-scan';
import { useLoadOrderForm } from './hooks/use-load-order-form';
import { useOrderFormAccessorials } from './hooks/use-order-form-accessorials';
import { useOrderFormContact } from './hooks/use-order-form-contact';
import { useSaveOrderForm } from './hooks/use-save-order-form';
import OrderPageToolbar from './order-page-toolbar';
import { OnSubmitParams, SuccessCallbackParams } from './types';
import {
  getInboundStop,
  getOutboundStop,
  shouldShowFulfillmentWarning,
  shouldShowTariffWarning,
  showMismatchedDriverWarning,
} from './utils';

enum OrderFormTopTab {
  // Note that this ESLint error is a false positive that's fixed in a more recent version of ESLint.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Packages,
  Handling,
}

enum OrderFormBottomTab {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Charges,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  DriverSettlement,
}

export type OrderPageProps = {
  modalMode?: boolean;
  rebillMode?: boolean;
  orderUuid: string | null;
  modalOnClose?: () => void | Promise<void>;
  fromEdiReview?: boolean;
  recurringTemplate?: boolean;
  completeRebillAfterSave?: ({
    newContactUuid,
  }: {
    newContactUuid: string;
  }) => void;
  inInboundEmailReview?: boolean;
  draggableId?: string;
};

const OrderPageForm = ({
  modalMode,
  rebillMode,
  orderUuid,
  modalOnClose,
  fromEdiReview,
  recurringTemplate = false,
  completeRebillAfterSave,
  inInboundEmailReview,
  draggableId,
}: OrderPageProps) => {
  const { companyConfiguration } = useMe();
  const [isLeavingPageValid, setIsLeavingPageValid] = useState(false);
  const { setError, control, getValues, handleSubmit, clearErrors } =
    useFormContext<OrderFormValues>();
  const { isDirty } = useFormState<OrderFormValues>({ control });

  const navigate = useNavigate();
  const location = useLocation();
  const { stopsAreValidForOrder } = useValidateStops();
  const { packagesAreValid } = useValidatePackages();
  const { chargesAreValidForOrder } = useValidateChargesForOrder();
  const ffOrderFormTabs = useFeatureFlag(FeatureFlag.FF_ORDER_FORM_TABS);
  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  const ffDemoSterlingLineHaul = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_LINE_HAUL,
  );
  const [setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );
  const [
    unableToRateFreightChargeFormError,
    setUnableToRateFreightChargeFormError,
    setJustFinalizedCharges,
    setIsSavingDocScan,
    // Snackbar alert states and setters.
    showMarkStopAsArrivedSuccessMessage,
    setShowMarkStopAsArrivedSuccessMessage,
    showMarkStopAsArrivedErrorMessage,
    setShowMarkStopAsArrivedErrorMessage,
  ] = useOrderFormStore(
    (state) => [
      state.unableToRateFreightChargeFormError,
      state.setUnableToRateFreightChargeFormError,
      state.setJustFinalizedCharges,
      state.setIsSavingDocScan,
      // Snackbar alert states and setters.
      state.showMarkStopAsArrivedSuccessMessage,
      state.setShowMarkStopAsArrivedSuccessMessage,
      state.showMarkStopAsArrivedErrorMessage,
      state.setShowMarkStopAsArrivedErrorMessage,
    ],
    shallow,
  );

  const [setOpenedOrderDataLoading] = useBillingReviewStore(
    (state) => [state.setOpenedOrderDataLoading],
    shallow,
  );

  const [searchParams] = useSearchParams();

  const duplicate = searchParams.get('duplicate');
  const pageOrderUuid = searchParams.get('orderUuid');
  const isDocumentScan =
    !isNil(searchParams.get('isDocumentScan')) || Boolean(inInboundEmailReview);
  const scannedOrderResultUuid =
    useWatch({ control, name: 'scannedOrderResultUuid' }) ??
    searchParams.get('scannedOrderResultUuid');
  const enterManuallyFromDocScan =
    searchParams.get('enterManuallyFromDocScan') === 'true';

  const isEditMode =
    (!isNil(pageOrderUuid) || !isNil(orderUuid)) && isNil(duplicate);
  const orderUuidToUse = orderUuid ?? pageOrderUuid;

  const [selectedTopTab, setSelectedTopTab] = useState<OrderFormTopTab>(
    OrderFormTopTab.Packages,
  );
  const [selectedBottomTab, setSelectedBottomTab] =
    useState<OrderFormBottomTab>(OrderFormBottomTab.Charges);
  const { companyData } = useMe();
  const { userPermissions } = useUserRoles();
  const existingContactUuid = useWatch({ control, name: 'contactUuid' });

  const { canWrite: hasDeleteOrdersPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.DeleteOrders,
  );

  const { initializeForm } = useLoadOrderForm();
  const { saveOrderForm, refetchOrderStatus } = useSaveOrderForm({
    isEditMode,
    modalOnClose,
    completeRebillAfterSave,
  });
  const { contactUuid } = useOrderFormContact();
  const { accessorials } = useOrderFormAccessorials();

  const {
    scannedOrderResult,
    loading: scannedOrderResultLoading,
    approveScannedOrderResult,
    rejectScannedOrderResult,
  } = useDocScan({
    orderUuid: orderUuidToUse,
    scannedOrderResultUuid,
    inInboundEmailReview,
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const theme = useTheme();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState<
    string | undefined
  >();
  const confirm = useConfirm();

  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { getOrderFormEditAccess } = useGetOrderFormEditAccess();
  const editAccess = getOrderFormEditAccess({ detailedStatus });
  const { disabledIfInvoicePosted } = getDisabledForEditAccess({ editAccess });

  const successCallback = useCallback(
    async (args: SuccessCallbackParams): Promise<boolean> => {
      const orderValues = getValues();
      const stopValues = orderValues.stops ?? [];
      const packageValues = orderValues.packages ?? [];
      let isOrderValid = true;

      const latestOrder = await refetchOrderStatus(orderValues.uuid);

      if (!isNil(latestOrder)) {
        if (
          latestOrder.detailedStatusV2 === OrderDetailedStatus.InvoicePosted &&
          orderValues.detailedStatus !== OrderDetailedStatus.InvoicePosted
        ) {
          setCustomErrorMessage(
            'This order is now on a posted invoice. Refresh the page to edit the latest version.',
          );
          setShowErrorModal(true);
          return false;
        }
      }

      const shouldShowMismatchedDriverError = showMismatchedDriverWarning({
        fulfillmentType: orderValues.fulfillmentType,
        driverUuid: orderValues?.stops?.[0]?.driverUuid,
        otherDriverUuid: orderValues?.stops?.[1]?.driverUuid,
      });

      if (shouldShowMismatchedDriverError) {
        setError('stops.0.driverUuid', {
          message: 'Drivers must match',
        });
        isOrderValid = false;
      }
      if (
        companyConfiguration?.fulfillmentTypeEnabled === true &&
        isNil(orderValues.fulfillmentType)
      ) {
        setError('fulfillmentType', {
          message: 'Fulfillment type is required',
        });
        isOrderValid = false;
      }
      if (!validatePhoneNumber(orderValues.emergencyResponseNumber, false)) {
        setError('emergencyResponseNumber', {
          message: 'Must be valid phone number',
        });
        isOrderValid = false;
      }
      if (
        isNilOrEmptyString(orderValues.serviceUuid) &&
        companyConfiguration?.serviceLevelOptional !== true
      ) {
        setError(`serviceUuid`, {
          message: 'Service is required',
        });
        isOrderValid = false;
      }
      const stopsValid = await stopsAreValidForOrder({
        setError,
        stopValues,
        accessorials: accessorials ?? [],
        recurringTemplate,
      });
      const packagesValid = await packagesAreValid({
        setError,
        packageValues,
      });
      const chargesValid = await chargesAreValidForOrder({
        setError,
        orderFormValues: orderValues,
      });
      const isValid =
        stopsValid && isOrderValid && packagesValid && chargesValid;

      const shouldShowWarning = shouldShowFulfillmentWarning({
        orderValues,
        stopValues,
      });

      const inboundStop = getInboundStop(stopValues);
      const outboundStop = getOutboundStop(stopValues);

      if (isValid && shouldShowWarning) {
        await confirm({
          title: 'Warning: Inbound date is not the same as outbound date',
          confirmationText: `Save`,
        })
          .then(async () => {
            await saveOrderForm(args);
          })
          // Handle rejection when user cancels or closes dialog.
          .catch(() => {});
        return true;
      }
      if (isValid && shouldShowTariffWarning({ inboundStop, outboundStop })) {
        await confirm({
          title: 'Unable to calculate freight charges',
          description: (
            <>
              <Typography mb="10px">
                Could not find a valid tariff for:{' '}
              </Typography>
              {inboundStop?.freightCharge?.billingMethod ===
                FreightBillingMethod.Tariff &&
                isNil(inboundStop.freightCharge.tariffUuid) && (
                  <Typography fontWeight={600}>
                    Inbound stop ({inboundStop.stopType})
                  </Typography>
                )}
              {outboundStop?.freightCharge?.billingMethod ===
                FreightBillingMethod.Tariff &&
                isNil(outboundStop.freightCharge.tariffUuid) && (
                  <Typography fontWeight={600}>
                    Outbound stop ({outboundStop.stopType})
                  </Typography>
                )}
              <Typography mt="10px"> Save this order anyway?</Typography>
            </>
          ),
          confirmationText: `Save`,
        })
          .then(async () => {
            if (isDocumentScan) {
              await saveOrderForm({ isDocScan: true });
            } else {
              await saveOrderForm(args);
            }
          })
          // Catch block handles rejection when user cancels or closes dialog.
          // This ensures the promise chain completes and subsequent code executes.

          .catch(() => {});
        return true;
      }
      if (isValid) {
        await saveOrderForm(args);
        setShowSuccessMessage(true);
        return true;
      }
      setShowErrorModal(true);
      return false;
    },
    [
      accessorials,
      chargesAreValidForOrder,
      companyConfiguration,
      confirm,
      getValues,
      isDocumentScan,
      packagesAreValid,
      recurringTemplate,
      saveOrderForm,
      setError,
      stopsAreValidForOrder,
      refetchOrderStatus,
    ],
  );

  const onSubmit = useCallback(
    async ({
      duplicateEverything,
      duplicateNothing,
      noRedirect,
      isDocScan,
      saveAndPrint,
      refetchOrderAfterSave,
    }: OnSubmitParams) => {
      setIsLeavingPageValid(true);
      clearErrors();
      let success = false;
      await handleSubmit(
        async () => {
          success = await successCallback({
            duplicateEverything,
            duplicateNothing,
            noRedirect,
            isDocScan,
            saveAndPrint,
            refetchOrderAfterSave,
          });
        },
        async (err) => {
          // eslint-disable-next-line no-console
          console.warn(`Error creating/saving order: ${JSON.stringify(err)}`);
          setShowErrorModal(true);
          success = false;
        },
      )();
      return success;
    },
    [clearErrors, handleSubmit, successCallback],
  );

  const onApproveDocScan = async () => {
    setIsSavingDocScan(true);
    const success = await onSubmit({
      noRedirect: true,
      isDocScan: true,
    });
    if (success) {
      await approveScannedOrderResult();
    }
    setIsSavingDocScan(false);
  };

  useEffect(() => {
    if (!isNil(companyData)) {
      initializeForm({
        orderUuid: orderUuidToUse,
        recurringTemplate,
        duplicate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUuidToUse, companyData, recurringTemplate, duplicate]);

  useWarningOnExit(isDirty && !isLeavingPageValid && isEditMode);

  const [autocompletePerson, setAutocompletePerson] =
    useState<AutocompletePerson | null>(null);

  const showRightSidebar = fromEdiReview !== true && !recurringTemplate;

  const deleteEnabled =
    isEditMode &&
    modalMode !== true &&
    !isDocumentScan &&
    fromEdiReview !== true &&
    !recurringTemplate &&
    hasDeleteOrdersPermission;

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSuccessMessage(false)}
        open={showSuccessMessage}
      >
        <Alert>Order saved</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setUnableToRateFreightChargeFormError(undefined)}
        open={!isNil(unableToRateFreightChargeFormError)}
      >
        <Alert severity="error">{unableToRateFreightChargeFormError}</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowMarkStopAsArrivedSuccessMessage(false)}
        open={showMarkStopAsArrivedSuccessMessage}
      >
        <Alert>Marked stop as arrived</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowMarkStopAsArrivedErrorMessage(false)}
        open={showMarkStopAsArrivedErrorMessage}
      >
        <Alert severity="error">Error marking stop as arrived</Alert>
      </Snackbar>
      <ErrorDialog
        handleClose={() => {
          setShowErrorModal(false);
          setCustomErrorMessage(undefined);
        }}
        open={showErrorModal}
        additionalErrors={filterNotNil([customErrorMessage])}
        type="order"
      />
      {!isEditMode && (
        <OrderOverLimitDialog
          contactUuid={
            typeof contactUuid === 'string'
              ? (contactUuid ?? existingContactUuid)
              : existingContactUuid
          }
          modalMode={modalMode}
        />
      )}
      <OrderFormEditAccessProvider value={editAccess}>
        <Stack sx={{ maxHeight: '100%' }}>
          <OrderPageToolbar
            sx={
              fromEdiReview === true
                ? {
                    cursor: !isNil(draggableId) ? 'move' : undefined,
                    position: 'sticky',
                    top: '-10px',
                    zIndex: 3,
                  }
                : {
                    cursor: !isNil(draggableId) ? 'move' : undefined,
                  }
            }
            id={draggableId}
          >
            <Stack
              flexGrow={1}
              direction="row"
              gap={2}
              alignItems="center"
              flexWrap="wrap"
            >
              {modalMode === true
                ? fromEdiReview !== true && (
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        modalOnClose?.();
                        setOpenedOrderUuid(undefined);
                        setJustFinalizedCharges(false);
                      }}
                      sx={{
                        color: theme.palette.grey[500],
                        zIndex: 9999,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                : inInboundEmailReview !== true && (
                    <Button
                      variant="text"
                      onClick={() => {
                        const path = location.pathname;
                        navigate(path, { replace: true });
                        modalOnClose?.();
                        setOpenedOrderDataLoading(true);
                        setOpenedOrderUuid(undefined);
                        setJustFinalizedCharges(false);
                      }}
                    >
                      Back
                    </Button>
                  )}
              <OrderCustomerField disabled={disabledIfInvoicePosted} />
              {ffUseStations && (
                <ContactStationsField disabled={disabledIfInvoicePosted} />
              )}
              <OrderNameField
                disabled={
                  disabledIfInvoicePosted ||
                  companyConfiguration?.restrictEditingOrderName === true
                }
              />
              <Tags />
            </Stack>
            {inInboundEmailReview === true || isDocumentScan ? (
              <DocScanOrderActions
                onAccept={onApproveDocScan}
                onReject={rejectScannedOrderResult}
              />
            ) : (
              <OrderActions
                isEditMode={isEditMode}
                isDuplicate={!isEmpty(duplicate)}
                onSubmit={onSubmit}
                deleteEnabled={deleteEnabled}
                setIsLeavingPageValid={setIsLeavingPageValid}
                rebillMode={rebillMode}
                fromEdiReview={fromEdiReview}
              />
            )}
          </OrderPageToolbar>
          <Stack
            sx={{
              overflow: 'hidden',
              backgroundColor: theme.palette.grey[100],
              flexDirection: 'row',
            }}
          >
            <GappedStackCard
              style={{
                overflow: 'auto',
                flexBasis: isDocumentScan || showRightSidebar ? '67%' : '100%',
                flexShrink: 0,
                borderRight:
                  isDocumentScan || showRightSidebar
                    ? `1px solid ${theme.palette.borderColor.main}`
                    : 'none',
              }}
            >
              {recurringTemplate && <RecurringOrderFrequency />}
              <Details
                isEditMode={isEditMode}
                setAutocompletePerson={setAutocompletePerson}
              />
              <Stops
                isQuotePage={false}
                recurringTemplate={recurringTemplate}
                showDocumentComponent={isDocumentScan}
                isEditMode={isEditMode}
                autocompletePerson={autocompletePerson}
                setAutocompletePerson={setAutocompletePerson}
              />
              {ffOrderFormTabs ? (
                <>
                  {companyConfiguration?.logisticsSectionEnabled === true ? (
                    // Display Packages and Handling tabs only if we'll be showing both tabs.
                    <>
                      <Tabs
                        value={selectedTopTab}
                        onChange={(_, newTab) => {
                          setSelectedTopTab(newTab);
                        }}
                        sx={{
                          px: 2,
                          backgroundColor: 'white',
                        }}
                      >
                        <Tab
                          value={OrderFormTopTab.Packages}
                          label="Packages"
                        />
                        {companyConfiguration?.logisticsSectionEnabled ===
                          true && (
                          <Tab
                            value={OrderFormTopTab.Handling}
                            label="Handling"
                          />
                        )}
                      </Tabs>
                      {selectedTopTab === OrderFormTopTab.Packages &&
                        (ffDemoSterlingLineHaul ? (
                          <DemoPackages showTitle={false} />
                        ) : (
                          <Packages showTitle={false} />
                        ))}
                      {selectedTopTab === OrderFormTopTab.Handling && (
                        <Logistics showTitle={false} />
                      )}
                    </>
                  ) : ffDemoSterlingLineHaul ? (
                    <DemoPackages />
                  ) : (
                    <Packages />
                  )}
                  {isEditMode && !recurringTemplate ? (
                    // Display Charges and Driver Settlement tabs only if we'll be showing both tabs.
                    <>
                      <Tabs
                        value={selectedBottomTab}
                        onChange={(_, newTab) => {
                          setSelectedBottomTab(newTab);
                        }}
                        sx={{
                          px: 2,
                          backgroundColor: 'white',
                        }}
                      >
                        <Tab
                          value={OrderFormBottomTab.Charges}
                          label="Charges"
                        />
                        {companyConfiguration?.showDriverSettlementInBilling ===
                          true && (
                          <Tab
                            value={OrderFormBottomTab.DriverSettlement}
                            label="Driver Settlement"
                          />
                        )}
                      </Tabs>
                      {selectedBottomTab === OrderFormBottomTab.Charges && (
                        <Charges showTitle={false} onSubmit={onSubmit} />
                      )}
                      {selectedBottomTab ===
                        OrderFormBottomTab.DriverSettlement && (
                        <DriverSettlement showTitle={false} />
                      )}
                    </>
                  ) : (
                    <Charges onSubmit={onSubmit} />
                  )}
                </>
              ) : (
                <>
                  {companyConfiguration?.logisticsSectionEnabled === true && (
                    <Logistics />
                  )}
                  {ffDemoSterlingLineHaul ? <DemoPackages /> : <Packages />}
                  <Charges onSubmit={onSubmit} />
                  {isEditMode &&
                    !recurringTemplate &&
                    companyConfiguration?.showDriverSettlementInBilling ===
                      true && <DriverSettlement />}
                </>
              )}
              {(isDocumentScan || !showRightSidebar) && !recurringTemplate && (
                <OrderFormCard>
                  <Documents isEditMode={isEditMode} />
                </OrderFormCard>
              )}
            </GappedStackCard>
            {isDocumentScan || enterManuallyFromDocScan ? (
              <ScannedOrderDocumentsViewer
                scannedOrderResult={scannedOrderResult}
                loading={scannedOrderResultLoading}
                styling={{
                  flexGrow: 1,
                  overflowY: 'auto',
                }}
              />
            ) : (
              showRightSidebar && (
                <Box
                  sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                  }}
                >
                  <RightSidebar
                    isEditMode={isEditMode}
                    onSubmit={onSubmit}
                    orderUuid={orderUuidToUse}
                  />
                </Box>
              )
            )}
          </Stack>
        </Stack>
      </OrderFormEditAccessProvider>
    </>
  );
};

const OrderPage = (props: OrderPageProps) => {
  const { form } = useOrderForm();

  return (
    <FormProvider {...form}>
      <OrderPageForm {...props} />
    </FormProvider>
  );
};

export default OrderPage;
