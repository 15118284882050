/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  DriversForHelpersDocument,
  DriversWithRoutesDocument,
  RouteDocument,
  RouteFragment,
  RoutesDocument,
  useAssignHelperToRouteMutation,
  useDriversForHelpersLazyQuery,
  useRemoveHelperFromRouteMutation,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDispatchStore from '../../../dispatch-store';
import useFetchRoutes from '../../../hooks/use-fetch-routes';
import HelperRow from './helper-row';

const RouteHelpersModal = ({
  open,
  setOpen,
  route,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  route: RouteFragment;
}) => {
  const { companyConfiguration } = useMe();
  const planningDate = useDispatchStore((state) => state.planningDate);
  const [getDriversForHelpers, { data: driversData }] =
    useDriversForHelpersLazyQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fetchRoute } = useFetchRoutes();
  const [assignHelperToRoute] = useAssignHelperToRouteMutation({
    refetchQueries: [
      RouteDocument,
      DriversForHelpersDocument,
      DriversWithRoutesDocument,
      RoutesDocument,
    ],
  });
  const [removeHelper] = useRemoveHelperFromRouteMutation({
    refetchQueries: [
      RouteDocument,
      DriversForHelpersDocument,
      DriversWithRoutesDocument,
      RoutesDocument,
    ],
  });
  const [selectedHelperUuid, setSelectedHelperUuid] = useState<string | null>(
    null,
  );
  const driver = driversData?.drivers.find(
    (itrDriver) => itrDriver.uuid === selectedHelperUuid,
  );

  useEffect(() => {
    if (!isNil(planningDate)) {
      getDriversForHelpers({ variables: { date: planningDate.toDate() } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningDate]);

  const addHelper = async () => {
    if (!isNil(selectedHelperUuid)) {
      setIsLoading(true);
      await assignHelperToRoute({
        variables: { routeUuid: route.uuid, helperUuid: selectedHelperUuid },
      });
      setSelectedHelperUuid(null);
      await fetchRoute(route.uuid);
      setIsLoading(false);
    }
  };

  const onRemoveHelper = async (helperUuid: string) => {
    await removeHelper({
      variables: {
        routeUuid: route.uuid,
        helperUuid,
      },
    });
    await fetchRoute(route.uuid);
  };

  const onClose = () => {
    setOpen(false);
    fetchRoute(route.uuid);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1,
        }}
      >
        <DialogTitle>Helpers</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {route.helpers.length !== 0 && (
              <Typography sx={{ fontWeight: 'bold' }}>Current</Typography>
            )}
            {route.helpers.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Helper</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {route.helpers.map((helper) => (
                      <HelperRow
                        key={helper.uuid}
                        firstName={helper.firstName}
                        lastName={helper.lastName}
                        driverReferenceNumber={helper.driverReferenceNumber}
                        onRemove={() => {
                          onRemoveHelper(helper.uuid);
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Typography sx={{ fontWeight: 'bold', mt: '10px' }}>
              Add helper
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <AutocompleteFuzzy
                value={
                  !isNil(driver)
                    ? {
                        label:
                          companyConfiguration?.useDriverNumberForDispatch ===
                            true && !isEmpty(driver.driverReferenceNumber)
                            ? driver.driverReferenceNumber
                            : `${driver.firstName} ${driver.lastName}`,
                        id: driver.uuid,
                      }
                    : undefined
                }
                onChange={(e, val) => {
                  if (!isNil(val)) {
                    setSelectedHelperUuid(val.id);
                  }
                }}
                options={
                  driversData?.drivers
                    .filter((itrDriver) => isNil(itrDriver.route))
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((itrDriver) => ({
                      label:
                        companyConfiguration?.useDriverNumberForDispatch ===
                        true
                          ? (itrDriver.driverReferenceNumber ?? '')
                          : `${itrDriver.firstName} ${itrDriver.lastName}`,
                      id: itrDriver.uuid,
                    })) ?? []
                }
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '300px' }} size="small" />
                )}
              />
              <Button
                onClick={addHelper}
                variant="outlined"
                endIcon={isLoading ? <CircularProgress size={15} /> : null}
              >
                Add
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Done
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RouteHelpersModal;
