import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDeleteOrderMutation } from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';

const DeleteOrderModal = ({
  open,
  setOpen,
  setIsLeavingPageValid,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setIsLeavingPageValid: Dispatch<SetStateAction<boolean>>;
}) => {
  const { control } = useFormContext();
  const uuid = useWatch({ control, name: 'uuid' });
  const setOpenedOrderUuid = useGlobalStore(
    (state) => state.setCurrentOrderUuid,
  );
  const navigate = useNavigate();
  const [deleteOrder] = useDeleteOrderMutation();
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Delete order</DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setOpen(false);
            setIsLeavingPageValid(true);
            await deleteOrder({ variables: { uuid } });
            setOpenedOrderUuid(undefined);
            navigate('/orders');
          }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrderModal;
