import {
  Button,
  Box,
  Chip,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../../common/react-hooks/use-me';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import {
  EndOfDayDocumentsProcessingJobStatus,
  FindCompanyDocumentStatusFilter,
  PermissionResource,
  useEndOfDayDocumentProcessingJobsQuery,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import EndOfDayContext from '../end-of-day-context';
import { FetchCompanyDocumentsParams } from '../types/company-documents';
import BulkChangeDocumentType, {
  DOCUMENT_PAGE_SIZE,
} from './bulk-change-document-type';
import DeleteDocumentsConfirmationModal from './delete-documents-confirmation-modal';
import DocumentRow from './document-row';

const EodDocumentsJobProcessingStatusTagVariant: Record<
  EndOfDayDocumentsProcessingJobStatus,
  'success' | 'error' | 'warning' | 'default' | 'info' | undefined
> = {
  [EndOfDayDocumentsProcessingJobStatus.Completed]: 'success',
  [EndOfDayDocumentsProcessingJobStatus.Failed]: 'error',
  [EndOfDayDocumentsProcessingJobStatus.InProgress]: 'default',
};

interface EndOfDayPodFilesSidebarProps {
  searchText: string | undefined;
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetchCompanyDocuments: (params: FetchCompanyDocumentsParams) => Promise<void>;

  companyDocumentsLoading: boolean;
}

const EndOfDayPodFilesSidebar = ({
  searchText,
  setSearchText,
  fetchCompanyDocuments,
  companyDocumentsLoading,
}: EndOfDayPodFilesSidebarProps) => {
  const { height } = useWindowDimensions();
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const {
    documentStatusFilter,
    setDocumentStatusFilter,
    currentDate,
    companyDocuments,
    selectedDocumentUuids,
    setSelectedDocumentUuids,
  } = useContext(EndOfDayContext);
  const { companyUuid } = useMe();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [isBulkChangeDocumentOpen, setIsBulkChangeDocumentOpen] =
    useState(false);
  const theme = useTheme();
  const [showDeleteDocumentsModal, setShowDeleteDocumentsModal] =
    useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        setCtrlPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        setCtrlPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const {
    loading: endOfDayDocumentsProcessingJobsLoading,
    data: endOfDayDocumentsProcessingJobsData,
    startPolling: startPollingEodDocumentsProcessingJobs,
    stopPolling: stopPollingEodDocumentsProcessingJobs,
  } = useEndOfDayDocumentProcessingJobsQuery({
    variables: {
      dateUploadedFor: currentDate.toDate(),
      companyUuid: companyUuid ?? '',
      terminalUuid: selectedTerminalUuid,
      searchText:
        documentStatusFilter === FindCompanyDocumentStatusFilter.Processing &&
        !isEmpty(searchText)
          ? searchText
          : undefined,
    },
  });

  useEffect(() => {
    if (documentStatusFilter !== FindCompanyDocumentStatusFilter.Processing) {
      fetchCompanyDocuments({
        first: DOCUMENT_PAGE_SIZE,
      });
      fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });
      setShouldRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUuid, currentDate, documentStatusFilter, shouldRefresh]);

  useEffect(() => {
    startPollingEodDocumentsProcessingJobs(5000);
    return () => {
      stopPollingEodDocumentsProcessingJobs();
    };
  }, [
    startPollingEodDocumentsProcessingJobs,
    stopPollingEodDocumentsProcessingJobs,
  ]);

  const getProcessingJobStatusTag = (
    status: EndOfDayDocumentsProcessingJobStatus,
  ) => {
    if (isNil(status)) {
      return <Chip size="small" label="Unknown" color="secondary" />;
    }
    return (
      <Chip
        size="small"
        label={sentenceCase(
          status === EndOfDayDocumentsProcessingJobStatus.Failed
            ? 'Failed'
            : status,
        )}
        color={EodDocumentsJobProcessingStatusTagVariant[status]}
      />
    );
  };

  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setSelectedDocumentUuids([]);
    setDocumentStatusFilter(
      !isNil(newAlignment)
        ? (newAlignment as FindCompanyDocumentStatusFilter)
        : FindCompanyDocumentStatusFilter.All,
    );
  };

  const processingJobsBody =
    endOfDayDocumentsProcessingJobsData?.endOfDayDocumentProcessingJobs?.map(
      (job) => {
        return (
          <TableRow key={job.uuid}>
            <TableCell style={{ width: '20%' }}>
              {job.uploadedFileName}
            </TableCell>
            <TableCell align="center">
              {getProcessingJobStatusTag(job.status)}
            </TableCell>
          </TableRow>
        );
      },
    );

  const companyDocumentsBody = companyDocuments.map((document, idx) => {
    return (
      <DocumentRow
        key={document.uuid}
        document={document}
        idx={idx}
        ctrlPressed={ctrlPressed}
      />
    );
  });

  return (
    <Grid container spacing={1}>
      <BulkChangeDocumentType
        open={isBulkChangeDocumentOpen}
        setOpen={setIsBulkChangeDocumentOpen}
        fetchCompanyDocuments={fetchCompanyDocuments}
      />
      <DeleteDocumentsConfirmationModal
        open={showDeleteDocumentsModal}
        setOpen={setShowDeleteDocumentsModal}
        selectedDocumentUuids={selectedDocumentUuids}
        setShouldRefresh={setShouldRefresh}
        setSelectedDocumentUuids={setSelectedDocumentUuids}
      />
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ mx: 1, mt: 1 }}
        >
          <TextField
            size="small"
            label="Search Documents"
            value={searchText}
            onKeyDown={() =>
              fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE })
            }
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ToggleButtonGroup
            size="small"
            color="primary"
            exclusive
            value={documentStatusFilter}
            aria-label="document-status-filters"
            onChange={handleStatusFilterChange}
          >
            {[
              FindCompanyDocumentStatusFilter.Processing,
              FindCompanyDocumentStatusFilter.Unmatched,
              FindCompanyDocumentStatusFilter.Matched,
              FindCompanyDocumentStatusFilter.All,
            ].map((filter) => {
              return (
                <ToggleButton
                  key={filter}
                  value={filter}
                  onClick={() => setDocumentStatusFilter(filter)}
                >
                  {sentenceCase(filter)}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      <Grid
        sx={{
          mx: 1,
        }}
        item
        xs={12}
      >
        <Stack direction="row" justifyContent="space-between">
          <Box>
            {selectedDocumentUuids.length > 0 && (
              <Typography sx={{ mt: '10px' }}>
                {selectedDocumentUuids.length} selected
              </Typography>
            )}
          </Box>
          <Stack direction="row" spacing={1}>
            {documentStatusFilter ===
              FindCompanyDocumentStatusFilter.Unmatched && (
              <Button
                sx={{
                  backgroundColor: theme.palette.redColor.main,
                }}
                variant="contained"
                disabled={
                  selectedDocumentUuids.length === 0 || !canWriteEndOfDay
                }
                onClick={() => setShowDeleteDocumentsModal(true)}
              >
                {selectedDocumentUuids.length > 0
                  ? `Delete (${selectedDocumentUuids.length})`
                  : 'Delete'}
              </Button>
            )}
            <Button
              variant="contained"
              disabled={selectedDocumentUuids.length === 0 || !canWriteEndOfDay}
              onClick={() => setIsBulkChangeDocumentOpen(true)}
            >
              {selectedDocumentUuids.length > 0
                ? `Change document type (${selectedDocumentUuids.length})`
                : 'Change document type'}
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ maxHeight: height - 230, overflowY: 'scroll' }}>
        {(documentStatusFilter === FindCompanyDocumentStatusFilter.Processing &&
          endOfDayDocumentsProcessingJobsLoading) ||
        (documentStatusFilter !== FindCompanyDocumentStatusFilter.Processing &&
          companyDocumentsLoading) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Table stickyHeader size="small">
            <TableHead>
              {documentStatusFilter !==
                FindCompanyDocumentStatusFilter.Processing && (
                <TableCell padding="none" />
              )}
              <TableCell style={{ width: '20%' }}>File Name</TableCell>
              {documentStatusFilter !==
                FindCompanyDocumentStatusFilter.Matched && (
                <TableCell
                  align={
                    documentStatusFilter ===
                    FindCompanyDocumentStatusFilter.Processing
                      ? 'center'
                      : 'left'
                  }
                >
                  Status
                </TableCell>
              )}
              {documentStatusFilter !==
                FindCompanyDocumentStatusFilter.Processing && (
                <TableCell>Date Uploaded</TableCell>
              )}
              {documentStatusFilter ===
                FindCompanyDocumentStatusFilter.Matched && (
                <TableCell>Order</TableCell>
              )}
              {documentStatusFilter !==
                FindCompanyDocumentStatusFilter.Processing && (
                <TableCell>Document type</TableCell>
              )}
            </TableHead>
            <TableBody>
              {documentStatusFilter ===
              FindCompanyDocumentStatusFilter.Processing
                ? processingJobsBody
                : companyDocumentsBody}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

export default EndOfDayPodFilesSidebar;
