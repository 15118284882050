import { Box } from '@mui/material';
import { memo, useRef } from 'react';
import {
  DetailedLineHaulManifestFragment,
  TerminalForDetailedLineHaulManifestFragment,
} from '../../../generated/graphql';
import GroupedManifestCardContent from './grouped-manifest-card-content';

const GroupedManifestCard = ({
  startAndEndTerminal,
  manifests,
}: {
  startAndEndTerminal: {
    start: TerminalForDetailedLineHaulManifestFragment;
    end: TerminalForDetailedLineHaulManifestFragment;
  };
  manifests: DetailedLineHaulManifestFragment[] | undefined;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      className="route-card"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={cardRef}
      id={`${startAndEndTerminal.start.uuid}-${startAndEndTerminal.end.uuid}`}
      sx={{
        borderRadius: '8px',
        border: '1px solid #d2d4d4',
        '&:hover': {
          boxShadow: '0px 0px 0px 2px #d2d4d4',
        },
        cursor: 'pointer',
      }}
    >
      {manifests?.map((manifest) => (
        <GroupedManifestCardContent key={manifest.uuid} manifest={manifest} />
      ))}
    </Box>
  );
};

export default memo(GroupedManifestCard);
