// eslint-disable-next-line no-restricted-imports
import { Button, Grid } from '@mui/material';
import {
  NotificationFragment,
  NotificationsDocument,
  NotificationType,
  useAcknowledgeNotificationMutation,
} from '../../../generated/graphql';

const useStyles = () => ({
  notificationContainer: {
    padding: 2,
    borderBottom: '1px solid #F7F7F7',
  },
  buttonRow: {
    textAlign: 'end',
  },
});

interface NotificationRowProps {
  notification: NotificationFragment;
}

const NotificationRow = ({ notification }: NotificationRowProps) => {
  const styles = useStyles();

  const [acknowledgeNotification, { loading: acknowledgeNotificationLoading }] =
    useAcknowledgeNotificationMutation({
      refetchQueries: [NotificationsDocument],
    });

  const constructNotificationMessage = () => {
    switch (notification.type) {
      case NotificationType.DriverMarkedStopAsAttempted: {
        const stopDisplayName =
          notification?.driverMarkedAttemptedStop?.leg?.shipment?.order?.name ??
          '--';
        const driverDisplayName = `${
          notification?.driverMarkedAttemptedDriver?.firstName ?? ''
        } ${notification?.driverMarkedAttemptedDriver?.lastName ?? ''}`;
        return `Driver ${driverDisplayName} marked stop ${stopDisplayName} as attempted`;
      }
      case NotificationType.DriverAddedAccessorialToStop: {
        const stopDisplayName =
          notification?.targetStop?.leg?.shipment?.order?.name ?? '--';
        const accessorialName = notification?.targetAccessorial?.name;
        const driverDisplayName = `${
          notification?.sourceDriver?.firstName ?? ''
        } ${notification?.sourceDriver?.lastName ?? ''}`;
        return `Driver ${driverDisplayName} added accessorial ${accessorialName} to stop ${stopDisplayName}`;
      }
      default:
        return '';
    }
  };

  const handleAcknowledge = async () => {
    await acknowledgeNotification({ variables: { uuid: notification.uuid } });
  };

  return (
    <Grid container spacing={2} sx={styles.notificationContainer}>
      <Grid item xs={12}>
        {constructNotificationMessage()}
      </Grid>

      <Grid item xs={12} sx={styles.buttonRow}>
        <Button
          disabled={
            Boolean(acknowledgeNotificationLoading) ||
            Boolean(notification.acknowledged)
          }
          onClick={handleAcknowledge}
        >
          Acknowledge
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotificationRow;
