import { Stack } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import CenteredCircularProgress from '../../../../../../../../common/components/centered-circular-progress';
import ErrorBanner from '../../../../../../../../common/components/error-banner';
import { useOrderAuditLogEntriesLazyQuery } from '../../../../../../../../generated/graphql';
import OrderAuditLogCard from './order-audit-log-card';

const OrderAuditLogListV2 = ({ orderUuid }: { orderUuid: string | null }) => {
  const [
    getAuditLogEntries,
    { loading: auditLogEntriesLoading, data: auditLogEntries },
  ] = useOrderAuditLogEntriesLazyQuery();

  useEffect(() => {
    if (!isNil(orderUuid)) {
      getAuditLogEntries({
        variables: {
          getOrderAuditLogEntriesInput: {
            orderUuid,
          },
        },
      });
    }
  }, [orderUuid]);

  if (auditLogEntriesLoading) {
    return <CenteredCircularProgress />;
  }

  if (isNil(orderUuid)) {
    return (
      <ErrorBanner errorMessage="Something went wrong. Please contact support." />
    );
  }

  return (
    <Stack spacing={2}>
      {auditLogEntries?.orderAuditLogEntries?.auditLogEntries.map((entry) => {
        return (
          <OrderAuditLogCard
            auditLogEntry={entry}
            key={entry.orderEvent.uuid}
          />
        );
      })}
    </Stack>
  );
};

export default React.memo(OrderAuditLogListV2);
