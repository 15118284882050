import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useMe from '../../../../common/react-hooks/use-me';
import {
  PicklistsFragment,
  useContactLogosQuery,
} from '../../../../generated/graphql';
import PackingSlip from './packing-slip.pdf';
import { PackingSlipFormValues } from './use-packing-slip-form';

interface DownloadPackingSlipProps {
  picklist: PicklistsFragment;
  onBack: () => void;
}
const DownloadPackingSlip = ({
  picklist,
  onBack,
}: DownloadPackingSlipProps) => {
  const { companyName } = useMe();
  const { control } = useFormContext<PackingSlipFormValues>();
  const order = useWatch({ control, name: 'order' });
  const [logosLoading, setLogosLoading] = useState(
    !isNil(order?.billingPartyContact.uuid),
  );
  const [logoBuffer, setLogoBuffer] = useState<Buffer>();
  const [logoTypeBuffer, setLogoTypeBuffer] = useState<Buffer>();
  useContactLogosQuery({
    variables: { contactUuid: order?.billingPartyContact.uuid ?? '' },
    skip: isNil(order?.billingPartyContact.uuid),
    fetchPolicy: 'cache-first',
    // preload images so that PDF does not re-render when images are loaded
    onCompleted: async (data) => {
      const imageUrlsAndSetters = [
        {
          url: data.contact.logo?.preSignedGetUrl,
          handleLoad: setLogoBuffer,
        },
        {
          url: data.contact.logoType?.preSignedGetUrl,
          handleLoad: setLogoTypeBuffer,
        },
      ];
      await Promise.all(
        imageUrlsAndSetters.map(({ url, handleLoad }) => {
          return new Promise((resolve) => {
            if (isNil(url)) {
              resolve(null);
              return;
            }
            fetch(url)
              .then((res) => res.arrayBuffer())
              .then((buffer) => {
                handleLoad(Buffer.from(buffer));
                resolve(null);
              })
              .catch(resolve);
          });
        }),
      );
      setLogosLoading(false);
    },
  });

  const handleBack = () => {
    setLogosLoading(true);
    setLogoBuffer(undefined);
    setLogoTypeBuffer(undefined);
    onBack();
  };

  if (isNil(companyName) || logosLoading)
    return (
      <Stack alignItems="center" justifyContent="center" flex={1}>
        <CircularProgress />
      </Stack>
    );
  return (
    <>
      <Typography variant="h6">Download packing slip</Typography>
      <PDFViewer style={{ width: '100%', height: '100%' }} showToolbar>
        <PackingSlip
          picklist={picklist}
          order={order}
          companyName={companyName}
          logoBuffer={logoBuffer}
          logoTypeBuffer={logoTypeBuffer}
        />
      </PDFViewer>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button onClick={handleBack} variant="contained">
          Back
        </Button>
        <PDFDownloadLink
          document={
            <PackingSlip
              picklist={picklist}
              order={order}
              companyName={companyName}
              logoBuffer={logoBuffer}
              logoTypeBuffer={logoTypeBuffer}
            />
          }
          fileName={`packing-slip-${picklist.outboundReferenceNumber}${order?.name != null ? `-${order.name}` : ''}.pdf`}
        >
          <Button variant="contained">Download PDF</Button>
        </PDFDownloadLink>
      </Stack>
    </>
  );
};

export default DownloadPackingSlip;
