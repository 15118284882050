import { debounce, isNil } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import apolloClient from '../../../../apollo-client';
import {
  NumberOfStopsDocument,
  NumberOfStopsQuery,
  FilterOperator,
  NumberOfStopsQueryVariables,
} from '../../../../generated/graphql';
import { ReadOnlyRefObject } from '../../orders/types';
import {
  DEFAULT_UNASSIGNED_STOP_QUERY_VARIABLES,
  StopsTab,
} from '../constants';
import { DefaultFilterTabsConfigs, State } from '../types';

type UseStopsPanelTabFunctionsParams = {
  stateRef: ReadOnlyRefObject<State>;
  setState: Dispatch<SetStateAction<State>>;
  defaultFilterTabsConfigs: DefaultFilterTabsConfigs<StopsTab>;
  selectedTerminalUuid: string | undefined;
};
export const useStopsPanelTabFunctions = ({
  stateRef,
  setState,
  defaultFilterTabsConfigs,
  selectedTerminalUuid,
}: UseStopsPanelTabFunctionsParams) => {
  const getNumberOfStopsForTab = async (
    tab: StopsTab | string,
    tabFilters: Partial<NumberOfStopsQueryVariables>,
  ) => {
    const serviceDateFilters = {
      filterOperator: FilterOperator.And,
      startFilterValue: stateRef.current.serviceDateOption.startDate,
      endFilterValue: stateRef.current.serviceDateOption.endDate,
    };
    // using apollo client query because of this issue: https://github.com/apollographql/apollo-client/issues/9755
    // This query does not include recurring run headers because we want the number of actual, assignable stops.
    const res = await apolloClient.query<
      NumberOfStopsQuery,
      NumberOfStopsQueryVariables
    >({
      query: NumberOfStopsDocument,
      variables: {
        // Order matters - put the date filters before the tab filters
        serviceDateFilters,
        ...DEFAULT_UNASSIGNED_STOP_QUERY_VARIABLES,
        ...tabFilters,
        hideOnHold: true,
        hideStopsWithoutAddress: true,
        terminalUuid: selectedTerminalUuid,
        includeCount: true,
      },
    });
    const totalCount = res.data?.numberOfStops;
    if (tab === stateRef.current.stopsTab && !isNil(totalCount)) {
      setState((prevState) => ({
        ...prevState,
        totalCount,
      }));
    }
  };

  const getNumberOfStopsForAllTabs = debounce(() => {
    defaultFilterTabsConfigs.tabs.map((tab) =>
      getNumberOfStopsForTab(tab.value, tab.filtersToApply),
    );
  }, 400);

  const getNumberOfStopsForCurrentTab = () => {
    const currentTab = stateRef.current.stopsTab;
    const tabFilters = defaultFilterTabsConfigs.tabs.find(
      (tab) => tab.value === currentTab,
    )?.filtersToApply;
    getNumberOfStopsForTab(currentTab, tabFilters ?? {});
  };

  return {
    getNumberOfStopsForCurrentTab,
    getNumberOfStopsForAllTabs,
  };
};
