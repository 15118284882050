import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import useDispatchStore from '../../dispatch-store';
import GenerateRoutesModal from '../../generate-routes/generate-routes-modal';
import CreateMultipleRoutesModal from './modals/create-multiple-routes-modal';
import ServiceDateModal from './modals/service-date-modal';
import RouteStopAssignInput from './route-stop-assign-input';

const RouteAssignMultipleStopsButton = () => {
  const { companyConfiguration } = useMe();
  const ffGenerateRoutes = useFeatureFlag(FeatureFlag.FF_GENERATE_ROUTES);
  const [bulkActionsMenuRef, setBulkActionsMenuRef] =
    useState<HTMLElement | null>(null);
  const [assignMenuRef, setAssignMenuRef] = useState<HTMLElement | null>(null);
  const [showServiceDateModal, setShowServiceDateModal] =
    useState<boolean>(false);
  const [showGenerateRoutesModal, setShowGenerateRoutesModal] =
    useState<boolean>(false);
  const [selectedTerminalUuid] = useGlobalStore(
    (state) => [state.selectedTerminalUuid],
    shallow,
  );
  const [selectedStopUuids, setShouldRefreshGrid, setSelectedStopUuids] =
    useDispatchStore(
      (state) => [
        state.selectedStopUuids,
        state.setShouldRefreshGrid,
        state.setSelectedStopUuids,
      ],
      shallow,
    );
  const [showCreateRoutesModal, setShowCreateRoutesModal] =
    useState<boolean>(false);
  const onCloseGenerateRoutesModal = useCallback(() => {
    setShowGenerateRoutesModal(false);
    setBulkActionsMenuRef(null);
  }, []);
  const onConfirmGenerateRoutes = useCallback(() => {
    setSelectedStopUuids([]);
    setShouldRefreshGrid(true);
  }, [setSelectedStopUuids, setShouldRefreshGrid]);

  return (
    <>
      {!isEmpty(selectedStopUuids) && (
        <Button
          color="info"
          onClick={async (e) => {
            setBulkActionsMenuRef(e.currentTarget);
          }}
          endIcon={<ArrowDropDownIcon />}
          sx={{ padding: '4px' }}
        >
          Bulk actions ({selectedStopUuids.length})
        </Button>
      )}
      {showCreateRoutesModal && (
        <CreateMultipleRoutesModal
          open={showCreateRoutesModal}
          setOpen={setShowCreateRoutesModal}
          stopUuidsToAssign={selectedStopUuids}
          onCreate={() => {
            setShouldRefreshGrid(true);
            setSelectedStopUuids([]);
            setBulkActionsMenuRef(null);
          }}
        />
      )}
      {showGenerateRoutesModal && (
        <GenerateRoutesModal
          open
          onClose={onCloseGenerateRoutesModal}
          onConfirm={onConfirmGenerateRoutes}
          initialStopUuids={selectedStopUuids}
        />
      )}
      <ServiceDateModal
        open={showServiceDateModal}
        setOpen={setShowServiceDateModal}
        stopUuids={selectedStopUuids}
        onConfirm={() => {
          setBulkActionsMenuRef(null);
          setShouldRefreshGrid(true);
          setSelectedStopUuids([]);
        }}
      />
      <Menu
        anchorEl={bulkActionsMenuRef}
        open={!isNil(bulkActionsMenuRef)}
        onClose={() => {
          setBulkActionsMenuRef(null);
        }}
      >
        <MenuList dense sx={{ py: 0 }}>
          <MenuItem
            onClick={(e) => {
              setAssignMenuRef(e.currentTarget);
            }}
          >
            Assign stops
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowCreateRoutesModal(true);
            }}
          >
            Create new route
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowServiceDateModal(true);
            }}
          >
            Change service date
          </MenuItem>
          {ffGenerateRoutes && (
            <MenuItem
              onClick={() => {
                setShowGenerateRoutesModal(true);
              }}
              sx={{ pr: 0 }}
              disabled={
                companyConfiguration?.terminalsEnabled === true &&
                isNil(selectedTerminalUuid)
              }
            >
              <Stack>
                <ListItemText>Generate routes</ListItemText>
                {companyConfiguration?.terminalsEnabled === true &&
                  isNil(selectedTerminalUuid) && (
                    <Typography variant="caption">
                      (Terminal required)
                    </Typography>
                  )}
              </Stack>
            </MenuItem>
          )}
        </MenuList>
        <Menu
          open={!isNil(assignMenuRef)}
          anchorEl={assignMenuRef}
          onClose={() => {
            setAssignMenuRef(null);
          }}
        >
          <MenuItem sx={{ width: '300px' }}>
            <RouteStopAssignInput
              stopUuids={selectedStopUuids}
              onAssign={() => {
                setBulkActionsMenuRef(null);
                setAssignMenuRef(null);
              }}
            />
          </MenuItem>
        </Menu>
      </Menu>
    </>
  );
};

export default RouteAssignMultipleStopsButton;
