import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import useQuickbooksDesktopData from '../../../common/react-hooks/use-quickbooks-desktop-data';
import {
  useBulkUpdateContactQuickbooksIdsMutation,
  useShallowContactsQuery,
} from '../../../generated/graphql';
import QuickbooksDesktopMappingAutocomplete from './quickbooks-desktop-mapping-autocomplete';

const QuickbooksDesktopCustomerMappings = () => {
  const { data: contactsData, loading: contactsLoading } =
    useShallowContactsQuery({
      fetchPolicy: 'network-only',
    });
  const [contactsToUpdate, setContactsToUpdate] = useState<{
    [key: string]: string | undefined;
  }>({});
  const {
    quickbooksCustomers,
    quickbooksCustomersLoading,
    refetchQuickbooksCustomers,
  } = useQuickbooksDesktopData();

  const [bulkUpdateContactQuickbooksIds] =
    useBulkUpdateContactQuickbooksIdsMutation();

  useEffect(() => {
    if (!isNil(quickbooksCustomers?.error)) {
      refetchQuickbooksCustomers();
    }
  }, []);

  const onSave = async () => {
    await bulkUpdateContactQuickbooksIds({
      variables: {
        bulkUpdateContactQuickbooksId: {
          updateQuickbooksIdInputs: Object.keys(contactsToUpdate).map(
            (contactUuid) => ({
              uuid: contactUuid,
              quickbooksContactId: contactsToUpdate[contactUuid] ?? null,
            }),
          ),
        },
      },
    });
  };

  useEffect(() => {
    const contactState: {
      [key: string]: string | undefined;
    } = {};
    contactsData?.contacts.forEach((contact) => {
      contactState[contact.uuid] =
        contact.quickbooksDesktopContactId ?? undefined;
    });
    setContactsToUpdate(contactState);
  }, [contactsData?.contacts.length]);

  if (contactsLoading === true || quickbooksCustomersLoading === true) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">Customer mappings</Typography>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Box>
      {contactsData?.contacts
        ?.slice()
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((contact) => (
          <Stack
            key={contact.uuid}
            direction="row"
            justifyContent="space-between"
          >
            <Typography>{contact.displayName}</Typography>
            <QuickbooksDesktopMappingAutocomplete
              currentMappingId={contactsToUpdate[contact.uuid]}
              onChange={(value) => {
                setContactsToUpdate((prevState) => ({
                  ...prevState,
                  [contact.uuid]: value,
                }));
              }}
              quickbooksData={quickbooksCustomers}
            />
          </Stack>
        ))}
      <Typography variant="caption">
        Not seeing the latest data from Quickbooks Desktop? Try refreshing the
        page.
      </Typography>
    </Box>
  );
};

export default QuickbooksDesktopCustomerMappings;
