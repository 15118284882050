import { isNil } from 'lodash';
import { FieldPath, UseFormSetError } from 'react-hook-form';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import { AccessorialType } from '../../../../../generated/graphql';
import { CustomChargeValues, OrderFormValues } from './types';

// Only invalid if there are $0 charges or specials without
// a matrix item selected
const setErrorIfNotValid = ({
  customCharges,
  path,
  setError,
  disallowZeroDollarCharges,
}: {
  customCharges: CustomChargeValues[];
  path: FieldPath<OrderFormValues>;
  setError: UseFormSetError<OrderFormValues>;
  disallowZeroDollarCharges: boolean;
}) => {
  const nonAccessorialCustomCharges = customCharges.filter((charge) =>
    isNil(charge.accessorialUuid),
  );
  if (
    disallowZeroDollarCharges &&
    (customCharges.some((charge) => charge.totalCharge === 0) ||
      nonAccessorialCustomCharges.some((charge) => charge.rate === 0))
  ) {
    setError(path, {
      type: 'invalid',
      message: 'Please remove any $0 charges before saving the order.',
    });
    return true;
  }
  const specialCustomCharges = customCharges.filter(
    (charge) => charge.accessorialType === AccessorialType.Special,
  );
  if (
    specialCustomCharges.some((charge) =>
      isNil(charge.specialAccessorialMatrixItemUuid),
    )
  ) {
    setError(path, {
      type: 'invalid',
      message:
        'Please select a rate for special charges before saving the order.',
    });
    return true;
  }
  return false;
};

export const useValidateChargesForOrder = () => {
  const disallowZeroDollarCharges = useFeatureFlag(
    FeatureFlag.FF_DISALLOW_ZERO_DOLLAR_CHARGES,
  );
  const chargesAreValidForOrder = async ({
    setError,
    orderFormValues,
  }: {
    setError: UseFormSetError<OrderFormValues>;
    orderFormValues: OrderFormValues;
  }) => {
    let hasInvalidCharges = false;
    const orderCharges = orderFormValues.orderChargesShipment?.customCharges;
    if (!isNil(orderCharges)) {
      hasInvalidCharges =
        hasInvalidCharges ||
        setErrorIfNotValid({
          customCharges: orderCharges,
          path: 'orderChargesShipment.customCharges',
          setError,
          disallowZeroDollarCharges,
        });
    }
    if (!isNil(orderFormValues.stops)) {
      for (const [index, stop] of orderFormValues.stops.entries()) {
        const stopCharges = stop.customCharges;
        if (!isNil(stopCharges)) {
          hasInvalidCharges =
            hasInvalidCharges ||
            setErrorIfNotValid({
              customCharges: stopCharges,
              path: `stops.${index}.customCharges`,
              setError,
              disallowZeroDollarCharges,
            });
        }
      }
    }
    return !hasInvalidCharges;
  };
  return {
    chargesAreValidForOrder,
  };
};
