import { Box, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { safeDivide } from 'shared/math';

const InvoiceSendJobProgress = ({
  invoiceSendJobBatchProgress,
}: {
  invoiceSendJobBatchProgress: {
    successCount: number;
    failCount: number;
    totalCount: number;
  };
}) => {
  return (
    <Stack spacing={0}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box sx={{ width: '270px' }}>
          <LinearProgress
            color="info"
            variant="determinate"
            value={
              safeDivide(
                invoiceSendJobBatchProgress.successCount +
                  invoiceSendJobBatchProgress.failCount,
                invoiceSendJobBatchProgress.totalCount,
              ) * 100
            }
          />
        </Box>
        <Typography
          sx={{ width: '80px' }}
          variant="caption"
          color="text.secondary"
        >
          {invoiceSendJobBatchProgress.successCount +
            invoiceSendJobBatchProgress.failCount}{' '}
          / {invoiceSendJobBatchProgress.totalCount}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={5}>
        <Typography color="success" variant="caption">
          {invoiceSendJobBatchProgress.successCount} succeeded
        </Typography>
        {invoiceSendJobBatchProgress.failCount > 0 && (
          <Typography color="error" variant="caption">
            {invoiceSendJobBatchProgress.failCount} failed
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default InvoiceSendJobProgress;
