import {
  FormControl,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Chip,
  InputLabel,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray } from 'lodash';
import React from 'react';
import useServices from '../../../common/react-hooks/use-services';
import { useAppDispatch } from '../../../redux/hooks';
import { updateOneContactValues } from '../redux/contact-values-slice';

const ContactServiceLevelField = ({
  disabled,
  contactUuid,
  serviceUuids,
}: {
  disabled: boolean;
  contactUuid: string;
  serviceUuids: string[] | undefined | null;
}) => {
  const dispatch = useAppDispatch();
  const { getServiceName, services } = useServices();

  const handleChange = (event: SelectChangeEvent) => {
    if (typeof event.target.value !== 'string') {
      dispatch(
        updateOneContactValues({
          id: contactUuid,
          changes: {
            serviceUuids: event.target.value as string[],
          },
        }),
      );
    }
  };

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel>Service levels</InputLabel>
      <Select
        size="small"
        multiple
        label="Service levels"
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={serviceUuids ?? []}
        onChange={handleChange}
        renderValue={(selected) => {
          if (isArray(selected)) {
            return (
              <Box>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={sentenceCase(getServiceName(value))}
                  />
                ))}
              </Box>
            );
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }}
      >
        {services?.map((service) => (
          <MenuItem key={service.uuid} value={service.uuid}>
            {sentenceCase(service.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContactServiceLevelField;
