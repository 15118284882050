import { uniqBy } from 'lodash';
import { useLineHaulSegmentsQuery } from '../../generated/graphql';

const useLineHaulSegments = () => {
  const { data: segmentsData } = useLineHaulSegmentsQuery({
    fetchPolicy: 'cache-first',
  });

  const allSegments = segmentsData?.lineHaulSegments;

  const uniqueSegments = uniqBy(allSegments, (s) => {
    return `${s.startTerminal.uuid},${s.endTerminal.uuid}`;
  });

  return { allSegments, uniqueSegments };
};

export default useLineHaulSegments;
