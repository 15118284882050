import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Alert, Box, Button, Snackbar, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import useUserRoles from '../../../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../../../generated/graphql';
import useOrderFormStore from '../../../../order-form-store';
import useIngestedEmailsStore, {
  useSelectedOrderData,
} from '../../../email-ingestion/ingested-emails-store';
import ReportDocScanIssueDialog from '../../../report-doc-scan-issue.dialog';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import PrintMenu from '../right-sidebar/print-menu';

type DocScanOrderActionsProps = {
  onAccept: () => void;
  onReject: () => void;
};

const DocScanOrderActions = ({
  onAccept,
  onReject,
}: DocScanOrderActionsProps) => {
  const [isSaving] = useOrderFormStore(
    (state) => [state.isSavingDocScan],
    shallow,
  );
  const [showReportADocScanIssueDialog, setShowReportADocScanIssueDialog] =
    useIngestedEmailsStore(
      (state) => [
        state.showReportADocScanIssueDialog,
        state.setShowReportADocScanIssueDialog,
      ],
      shallow,
    );
  const printButtonRef = useRef<HTMLButtonElement>(null);
  const [showPrintMenu, setShowPrintMenu] = useState(false);
  const selectedOrderData = useSelectedOrderData();

  const [showSnackbarThanks, setShowSnackbarThanks] = useState(false);

  const { userPermissions } = useUserRoles();
  const { hasMasterPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Master,
  );

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSnackbarThanks(false)}
        open={showSnackbarThanks}
      >
        <Alert severity="success">Thanks for sharing your feedback!</Alert>
      </Snackbar>
      <PrintMenu
        showPrintMenu={showPrintMenu}
        onClose={() => {
          setShowPrintMenu(false);
        }}
        buttonRef={printButtonRef.current}
        isEditMode
      />
      <ReportDocScanIssueDialog
        onConfirm={() => {
          setShowSnackbarThanks(true);
          setShowReportADocScanIssueDialog(false);
        }}
        onCancel={() => {
          setShowReportADocScanIssueDialog(false);
        }}
        open={showReportADocScanIssueDialog}
        scannedOrderResultUuid={selectedOrderData?.uuid ?? ''}
      />
      <Stack direction="row" spacing={1}>
        <Button
          ref={printButtonRef}
          variant="outlined"
          onClick={() => {
            setShowPrintMenu(!showPrintMenu);
          }}
          sx={{
            borderRadius: 1,
          }}
          endIcon={<ArrowDropDownIcon />}
        >
          Print
        </Button>
        <Button
          disabled={isSaving || disabledIfNoAccess}
          variant="outlined"
          sx={{
            borderRadius: 1,
            width: 130,
          }}
          color="error"
          onClick={onReject}
        >
          Reject
        </Button>
        <Button
          disabled={isSaving || disabledIfNoAccess}
          variant="contained"
          sx={{
            borderRadius: 1,
            width: 130,
            boxShadow: 'none',
          }}
          color="info"
          onClick={onAccept}
        >
          Accept
        </Button>
      </Stack>
      {hasMasterPermission && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '24px',
            right: '24px',
            display: 'flex',
            flexDirection: 'column',
            border: '2px solid #FF0000',
            borderRadius: '4px',
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <Typography variant="body2">
            Scanned order UUID: {selectedOrderData?.uuid}
          </Typography>
          <Typography variant="body2">
            Attachment UUID:{' '}
            {selectedOrderData?.scannedOrder.ingestedOutlookEmailDetails
              ?.attachmentUuid ?? 'No attachment UUID'}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default React.memo(DocScanOrderActions);
