import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { useAvailableTariffTypes } from '../../../../common/react-hooks/use-available-tariff-types';
import { useTariffChains } from '../../../../common/react-hooks/use-tariff-chains';
import {
  CompanyDefaultTariffChainFragment,
  TariffChainFragment,
  useCompanyDefaultTariffChainsQuery,
  useUpdateDefaultTariffChainsForCompanyMutation,
} from '../../../../generated/graphql';
import { styles } from '../styles';

const EditDefaultTariffChainsModal = ({
  open,
  onClose,
  canEdit,
}: {
  open: boolean;
  onClose: () => void;
  canEdit: boolean;
}) => {
  const [defaultOrdinaryTariffChain, setDefaultOrdinaryTariffChain] =
    useState<CompanyDefaultTariffChainFragment | null>(null);
  const [defaultLineHaulTariffChain, setDefaultLineHaulTariffChain] =
    useState<CompanyDefaultTariffChainFragment | null>(null);
  const [defaultTransferTariffChain, setDefaultTransferTariffChain] =
    useState<CompanyDefaultTariffChainFragment | null>(null);
  const [defaultPointToPointTariffChain, setDefaultPointToPointTariffChain] =
    useState<CompanyDefaultTariffChainFragment | null>(null);

  const { loading: defaultTariffChainsLoading } =
    useCompanyDefaultTariffChainsQuery({
      onCompleted: (data) => {
        setDefaultOrdinaryTariffChain(
          data.company.defaultOrdinaryTariffChain ?? null,
        );
        setDefaultLineHaulTariffChain(
          data.company.defaultLineHaulTariffChain ?? null,
        );
        setDefaultTransferTariffChain(
          data.company.defaultTransferTariffChain ?? null,
        );
        setDefaultPointToPointTariffChain(
          data.company.defaultPointToPointTariffChain ?? null,
        );
      },
    });

  const [updateDefaultTariffChainsForCompany] =
    useUpdateDefaultTariffChainsForCompanyMutation();

  const {
    ordinaryTariffChains,
    lineHaulTariffChains,
    transferTariffChains,
    pointToPointTariffChains,
    loading: tariffChainsLoading,
  } = useTariffChains();

  const loading = defaultTariffChainsLoading || tariffChainsLoading;

  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const dropdownComponentForType = ({
    label,
    id,
    tariffChainsOfType,
    setDefaultTariffChainOfType,
  }: {
    label: string;
    id?: string;
    tariffChainsOfType: TariffChainFragment[];
    setDefaultTariffChainOfType: (
      tariffChain: TariffChainFragment | null,
    ) => void;
  }) => (
    <TextField
      size="small"
      disabled={!canEdit || loading}
      label={label}
      value={id ?? ''}
      onChange={(e) => {
        const tariffChain = tariffChainsOfType?.find(
          (tc) => tc.id === e.target.value,
        );
        setDefaultTariffChainOfType(tariffChain ?? null);
      }}
      fullWidth
      select
    >
      {tariffChainsOfType?.map((tariffChain) => (
        <MenuItem key={tariffChain.id} value={tariffChain.id}>
          {tariffChain.name}
        </MenuItem>
      ))}
      <MenuItem value="">None</MenuItem>
    </TextField>
  );

  const tariffChainDropdowns: ReactNode[] = [];

  if (ordinaryTariffGroupAvailable) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Ordinary Tariff Chain',
        id: defaultOrdinaryTariffChain?.id,
        tariffChainsOfType: ordinaryTariffChains ?? [],
        setDefaultTariffChainOfType: setDefaultOrdinaryTariffChain,
      }),
    );
  }

  if (lineHaulTariffGroupAvailable) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Line-Haul Tariff Chain',
        id: defaultLineHaulTariffChain?.id,
        tariffChainsOfType: lineHaulTariffChains ?? [],
        setDefaultTariffChainOfType: setDefaultLineHaulTariffChain,
      }),
    );
  }

  if (transferTariffGroupAvailable) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Transfer Tariff Chain',
        id: defaultTransferTariffChain?.id,
        tariffChainsOfType: transferTariffChains ?? [],
        setDefaultTariffChainOfType: setDefaultTransferTariffChain,
      }),
    );
  }

  if (pointToPointTariffGroupAvailable) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Point-to-Point Tariff Chain',
        id: defaultPointToPointTariffChain?.id,
        tariffChainsOfType: pointToPointTariffChains ?? [],
        setDefaultTariffChainOfType: setDefaultPointToPointTariffChain,
      }),
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h5">Edit Default Tariff Chains</Typography>
        <Stack direction="column" alignItems="center" spacing={1} width="100%">
          <Typography variant="h6">Rating</Typography>
          {tariffChainDropdowns}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateDefaultTariffChainsForCompany({
                variables: {
                  updateDefaultTariffChainsForCompanyInput: {
                    defaultOrdinaryTariffChainId:
                      defaultOrdinaryTariffChain?.id,
                    defaultLineHaulTariffChainId:
                      defaultLineHaulTariffChain?.id,
                    defaultTransferTariffChainId:
                      defaultTransferTariffChain?.id,
                    defaultPointToPointTariffChainId:
                      defaultPointToPointTariffChain?.id,
                  },
                },
              });
              onClose();
            }}
            disabled={!canEdit || defaultTariffChainsLoading}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default EditDefaultTariffChainsModal;
