import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../../redux/store';

export type ShipmentSubscriberValues = Partial<{
  phone: string;
  email: string;
}> & { uuid: string };

const shipmentSubscriberValuesAdapter =
  createEntityAdapter<ShipmentSubscriberValues>({
    selectId: (shipmentSubscriber) => shipmentSubscriber.uuid,
  });

export const shipmentSubscriberValuesSlice = createSlice({
  name: 'shipmentSubscriberValues',
  initialState: shipmentSubscriberValuesAdapter.getInitialState(),
  reducers: {
    addOneShipmentSubscriberValues: shipmentSubscriberValuesAdapter.addOne,
    updateOneShipmentSubscriberValues:
      shipmentSubscriberValuesAdapter.updateOne,
    removeOneShipmentSubscriberValues:
      shipmentSubscriberValuesAdapter.removeOne,
    setAllShipmentSubscriberValues: shipmentSubscriberValuesAdapter.setAll,
    upsertOneShipmentSubscriberValues:
      shipmentSubscriberValuesAdapter.upsertOne,
  },
});

export const { selectById: selectShipmentSubscriberValuesById } =
  shipmentSubscriberValuesAdapter.getSelectors(
    (state: RootState) => state.shipmentSubscriberValues,
  );

const shipmentSubscriberValuesSelector = (state: RootState) =>
  state.shipmentSubscriberValues;

const shipmentSubscriberValuesReducer = shipmentSubscriberValuesSlice.reducer;

export const selectShipmentSubscribersByIds = createSelector(
  shipmentSubscriberValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<ShipmentSubscriberValues>, entityIds: EntityId[]) => {
    const entityResults: ShipmentSubscriberValues[] = [];
    entityIds.forEach((id) => {
      const match = shipmentSubscriberValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addOneShipmentSubscriberValues,
  removeOneShipmentSubscriberValues,
  updateOneShipmentSubscriberValues,
  setAllShipmentSubscriberValues,
  upsertOneShipmentSubscriberValues,
} = shipmentSubscriberValuesSlice.actions;

export default shipmentSubscriberValuesReducer;
