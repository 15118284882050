import { isNil } from 'lodash';
import { UseFormSetError } from 'react-hook-form';
import { OrderFormValues, PackageValues } from './types';

type BasePackageFormValues = Pick<OrderFormValues, 'packages'>;

export const useValidatePackages = () => {
  const packagesAreValid = async ({
    setError,
    packageValues,
  }: {
    setError: UseFormSetError<BasePackageFormValues>;
    packageValues: PackageValues[];
  }) => {
    const validArr = await Promise.all(
      packageValues.map(async (pkg, idx) => {
        let isValid = true;
        if (isNil(pkg.quantity)) {
          setError(`packages.${idx}.quantity`, {
            message: 'Quantity required',
          });
          isValid = false;
        }
        return isValid;
      }),
    );

    return validArr.every((valid) => valid === true);
  };

  return { packagesAreValid };
};
