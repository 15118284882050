import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { getStopMarkAsTestIds } from '../../../../utils';
import { INBOUND_STOP_IDX } from '../../../domains/orders/components/order-form/components/constants';
import {
  useCompanyConfigurationQuery,
  useCompleteStopBulkMutation,
} from '../../../generated/graphql';
import useMarkStopAsCompletedForm, {
  MarkStopAsCompletedFormValues,
} from '../../form/stops/use-mark-stop-as-completed-form';
import TimePickerComponent from '../time-picker-component';

const shouldShowPodSigneeField = (
  requirePODPhotoAndName: boolean | undefined,
  requirePODPhotoAndNameForPickups: boolean | undefined,
  allStopAreDeliveries: boolean,
  allStopArePartnerStops: boolean,
) => {
  if (allStopArePartnerStops === true) {
    return true;
  }

  return (
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true || allStopAreDeliveries === true)
  );
};

const requirePODSigneeField = (
  requirePODPhotoAndName: boolean | undefined,
  requirePODPhotoAndNameForPickups: boolean | undefined,
  allStopAreDeliveries: boolean,
  allStopArePartnerStops: boolean,
) => {
  if (allStopArePartnerStops === true) {
    return false;
  }

  return (
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true || allStopAreDeliveries === true)
  );
};

type MarkStopAsCompletedDialogProps = {
  handleClose: (refetch: boolean) => void;
  open: boolean;
  stopUuids: string[];
  routeDate?: Date;
  saveOrder?: () => Promise<boolean>;
  setShowMarkStopAsCompletedSuccessMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowMarkStopAsCompletedErrorMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  idx?: number;
  allStopsAreDeliveries: boolean;
  allStopsArePartnerStops: boolean;
};

export const MarkStopAsCompletedDialog = ({
  handleClose,
  open,
  stopUuids,
  routeDate,
  saveOrder,
  setShowMarkStopAsCompletedSuccessMessage,
  setShowMarkStopAsCompletedErrorMessage,
  idx,
  allStopsAreDeliveries,
  allStopsArePartnerStops,
}: MarkStopAsCompletedDialogProps) => {
  const theme = useTheme();

  const { data: companyConfigurationsData } = useCompanyConfigurationQuery({
    fetchPolicy: 'cache-first',
  });
  const requirePODPhotoAndName =
    companyConfigurationsData?.companyConfiguration?.requirePODPhotoAndName;
  const requirePODPhotoAndNameForPickups =
    companyConfigurationsData?.companyConfiguration
      ?.requirePODPhotoAndNameForPickups ?? requirePODPhotoAndName;

  const showPodSigneeField = shouldShowPodSigneeField(
    requirePODPhotoAndName,
    requirePODPhotoAndNameForPickups,
    allStopsAreDeliveries,
    allStopsArePartnerStops,
  );

  const requirePODSignee = requirePODSigneeField(
    requirePODPhotoAndName,
    requirePODPhotoAndNameForPickups,
    allStopsAreDeliveries,
    allStopsArePartnerStops,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useMarkStopAsCompletedForm({
    context: {
      requirePODSignee,
      defaultDate: !isNil(routeDate)
        ? dayjs(routeDate).startOf('day').toDate()
        : undefined,
    },
  });

  const [completeStopBulk, { loading: completeStopBulkLoading }] =
    useCompleteStopBulkMutation();

  const onSubmit: SubmitHandler<MarkStopAsCompletedFormValues> = async (
    data,
  ) => {
    try {
      const saveOrderSuccess = !isNil(saveOrder) ? await saveOrder() : true;
      if (saveOrderSuccess) {
        await completeStopBulk({
          variables: {
            completeStopBulkInput: {
              completeStopInputs: stopUuids.map((stopUuid) => {
                return {
                  stopUuid,
                  sentFromDriverMobileApplication: false,
                  proofOfDeliverySignee: data.podSigneeName,
                  completedAt: data.completedDate,
                };
              }),
            },
          },
        });
        setShowMarkStopAsCompletedSuccessMessage(true);
        handleClose(true);
        reset();
      } else {
        setShowMarkStopAsCompletedErrorMessage(true);
        handleClose(true);
      }
    } catch (e) {
      setShowMarkStopAsCompletedErrorMessage(true);
    }
  };

  const {
    stopMarkCompletedDialogPodSigneeNameInputTestId,
    stopMarkCompletedDialogMarkCompletedButtonTestId,
  } = getStopMarkAsTestIds({ stopIdx: idx ?? INBOUND_STOP_IDX });

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      fullWidth
      PaperProps={{
        sx: { maxHeight: 350, minHeight: 350, minWidth: 650, maxWidth: 650 },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Mark Stop as Completed
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ marginTop: '5px' }}>
          {showPodSigneeField && (
            <Controller
              name="podSigneeName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Full Signee Name"
                  inputProps={{
                    'data-testid':
                      stopMarkCompletedDialogPodSigneeNameInputTestId,
                  }}
                  onChange={onChange}
                  value={value}
                  required={requirePODSignee}
                  error={!isNil(errors.podSigneeName)}
                  helperText={errors?.podSigneeName?.message}
                  sx={{ width: '50%' }}
                />
              )}
            />
          )}
          <Controller
            name="completedDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack direction="row" spacing={2}>
                <DatePicker
                  onChange={(newDate) => {
                    onChange(newDate);
                  }}
                  renderInput={(props) => <TextField size="small" {...props} />}
                  value={value}
                />
                <TimePickerComponent
                  appointmentTime={value}
                  updateAppointmentTime={(time: Dayjs | undefined | null) => {
                    if (!isNil(time)) {
                      const newTimeString = `${dayjs(value).format(
                        'MM/DD/YYYY',
                      )} ${time.format('hh:mm a')}`;
                      const newTime = dayjs(newTimeString);
                      if (newTime.isValid()) {
                        onChange(newTime.toDate());
                      }
                    }
                  }}
                  hideClearable
                />
                {!isNil(errors.completedDate) && (
                  <FormHelperText sx={{ color: '#B00020' }}>
                    {errors.completedDate.message}
                  </FormHelperText>
                )}
                {!isNil(routeDate) && typeof value !== 'object' && (
                  <Button
                    onClick={() => {
                      onChange(dayjs(routeDate).startOf('day').toDate());
                    }}
                  >
                    Use Route Date
                  </Button>
                )}
              </Stack>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(2) }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose(false);
            reset();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          data-testid={stopMarkCompletedDialogMarkCompletedButtonTestId}
          disabled={completeStopBulkLoading}
        >
          Mark stop as completed
        </Button>
      </DialogActions>
    </Dialog>
  );
};
