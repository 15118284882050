import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BillingMethod } from '../../../../../../../common/types';
import { OrderFormValues } from '../../../forms/types';

const RateField = ({
  namePrefix,
  disabled,
}: {
  namePrefix: `stops.${number}` | `orderChargesShipment`;
  disabled?: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const settlementPercentageRate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.fuelCharge.settlementPercentageRate`,
  });
  const settlementFlatRate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.fuelCharge.settlementFlatRate`,
  });
  const rate = settlementPercentageRate ?? settlementFlatRate;
  const [rateInput, setRateInput] = useState(rate?.toString() ?? '');
  const billingMethod =
    useWatch({
      control,
      name: `${namePrefix}.freightCharge.fuelCharge.settlementBillingMethod`,
    }) ?? BillingMethod.Percentage;

  useEffect(() => {
    setRateInput(rate?.toString() ?? '');
  }, [billingMethod, rate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <TextField
        size="small"
        value={rateInput}
        onChange={(e) => {
          setRateInput(e.target.value);
          const parsedFloat = parseFloat(e.target.value);
          if (billingMethod === BillingMethod.FlatRate) {
            if (!Number.isNaN(parsedFloat)) {
              setValue(
                `${namePrefix}.freightCharge.fuelCharge.settlementFlatRate`,
                parsedFloat,
              );
            } else {
              setValue(
                `${namePrefix}.freightCharge.fuelCharge.settlementFlatRate`,
                0,
              );
            }
          }
          if (billingMethod === BillingMethod.Percentage) {
            if (!Number.isNaN(parsedFloat)) {
              setValue(
                `${namePrefix}.freightCharge.fuelCharge.settlementPercentageRate`,
                parsedFloat,
              );
            } else {
              setValue(
                `${namePrefix}.freightCharge.fuelCharge.settlementPercentageRate`,
                0,
              );
            }
          }
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export const DriverSettlementFuelChargeRateField = React.memo(RateField);
