import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ReportRow, {
  DownloadableReport,
} from '../../../../common/components/ReportRow';
import DriverCompletedStopsReportModal from './driver-completed-stops-report-modal';

const DriverSettlementReports = () => {
  const [
    showDriverCompletedStopsReportModal,
    setShowDriverCompletedStopsReportModal,
  ] = useState(false);

  const downloadableReports: DownloadableReport[] = [
    {
      label: 'Driver Completed Stops Report',
      onClick: () => setShowDriverCompletedStopsReportModal(true),
    },
  ];

  return (
    // At some point we should create common components b/w this and account-reports.tsx
    <Stack height="100%" ml={4} spacing={2} sx={{ overflowY: 'scroll' }}>
      <DriverCompletedStopsReportModal
        isOpen={showDriverCompletedStopsReportModal}
        setIsOpen={setShowDriverCompletedStopsReportModal}
      />
      <Typography variant="h6">Download:</Typography>
      <TableContainer>
        <Table sx={{ width: '50%' }} size="small" aria-label="simple table">
          <TableBody>
            <Divider />
            {downloadableReports.map((item) => (
              <>
                <ReportRow report={item} />
                <Divider />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DriverSettlementReports;
