import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  Segment,
  useOrdersByShipperBillOfLadingNumberLazyQuery,
} from '../../../../../generated/graphql';

interface FreightSnapMatchModalProps {
  open: boolean;
  setShowFreightSnapMatchModal: Dispatch<SetStateAction<boolean>>;
  initialProNum: string | undefined;
  onConfirm: (proNum: string | undefined) => void;
}

const FreightSnapMatchModal = ({
  open,
  setShowFreightSnapMatchModal,
  initialProNum,
  onConfirm,
}: FreightSnapMatchModalProps) => {
  const { segment } = useMe();
  const [proNum, setProNum] = useState<string | undefined>();
  const [debouncedProNum] = useDebounce(proNum, 200);
  const inputFieldString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  const [getOrdersByShipperBillOfLadingNumber, { data, loading }] =
    useOrdersByShipperBillOfLadingNumberLazyQuery();

  useEffect(() => {
    setProNum(initialProNum);
  }, [initialProNum, open]);

  useEffect(() => {
    getOrdersByShipperBillOfLadingNumber({
      variables: {
        shipperBillOfLadingNumber: debouncedProNum ?? '',
      },
    });
  }, [debouncedProNum]);

  const matchedOrders = data?.ordersByShipperBillOfLadingNumber;
  const hasShipments = !isEmpty(matchedOrders?.[0]?.shipments);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => {
        setProNum(undefined);
        setShowFreightSnapMatchModal(false);
      }}
    >
      <DialogTitle>Match Freight Snap</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          alignItems="center"
          spacing={1}
          sx={{ height: '60px' }}
        >
          <TextField
            size="small"
            label={`Enter ${inputFieldString}`}
            InputProps={{ style: { backgroundColor: 'white' } }}
            value={proNum}
            onChange={(e) => {
              setProNum(e.target.value);
            }}
            sx={{ width: '300px', mt: 1 }}
          />
          {!isNil(matchedOrders) && matchedOrders?.length > 1 && (
            <Typography color={{ color: 'red' }} variant="caption">
              Multiple orders found ({matchedOrders?.length})
            </Typography>
          )}
          {matchedOrders?.length === 0 && (
            <Typography color={{ color: 'red' }} variant="caption">
              No orders found
            </Typography>
          )}
          {!hasShipments && (
            <Typography color={{ color: 'red' }} variant="caption">
              Order has no shipments
            </Typography>
          )}
          {matchedOrders?.length === 1 && hasShipments && (
            <Typography sx={{ color: 'green' }} variant="caption">
              Order Found
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={loading || matchedOrders?.length !== 1 || !hasShipments}
          onClick={async () => {
            setShowFreightSnapMatchModal(false);
            onConfirm(proNum);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FreightSnapMatchModal;
