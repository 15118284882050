/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import apolloClient from '../../../apollo-client';
import {
  ErrorResponse,
  validateString,
  ValidationResponse,
} from '../../../common/form/formValidators';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  AddressCreateInput,
  AddressFragment,
  AddressUpsertInput,
  StandardOrderQueryStopFragment,
  StandardShipmentFragment,
  CoordinatesForAddressQuery,
  CoordinatesForAddressQueryVariables,
  CoordinatesForAddressDocument,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import { upsertAddressErrors } from './addresses-errors-slice';
import {
  ADDRESS_SCHEMA,
  AddressFormField,
  selectAddressById,
  upsertAddress,
} from './addresses-values-slice';

export const addressIsEmpty = (address: AddressFormField): boolean => {
  for (const [key, value] of Object.entries(address)) {
    switch (key) {
      case 'name':
      case 'line1':
      case 'city':
      case 'state':
      case 'zip':
      case 'country':
        if (!isNilOrEmptyString(value)) {
          return false;
        }
        break;
      default:
        break;
    }
  }

  return true;
};

type CreateAddressCreateInputArg = {
  addressUuid: string;
};

export const createAddressCreateInput = createAsyncThunk<
  AddressCreateInput,
  CreateAddressCreateInputArg,
  { state: RootState }
>(
  'addresses/createAddressCreateInput',
  async (arg, thunkAPI): Promise<AddressCreateInput> => {
    const addressValues = selectAddressById(
      thunkAPI.getState(),
      arg.addressUuid,
    );
    if (isNil(addressValues)) {
      console.error(
        `[createAddressCreateInput] Unable to save order due to invalid address uuid: ${arg.addressUuid}`,
      );
      throw new Error(`Invalid address uuid: ${arg.addressUuid}`);
    }

    const coordinatesRes = await apolloClient.query<
      CoordinatesForAddressQuery,
      CoordinatesForAddressQueryVariables
    >({
      query: CoordinatesForAddressDocument,
      variables: {
        line1: addressValues.line1,
        line2: addressValues.line2,
        city: addressValues.city,
        state: addressValues.state,
        zip: addressValues.zip,
        country: addressValues.country,
      },
    });

    const { latitude, longitude } = coordinatesRes.data.coordinatesForAddress;

    return {
      city: addressValues.city!,
      country: addressValues.country ?? '',
      driverInstructions: addressValues.driverInstructions,
      line1: addressValues.line1!,
      line2: addressValues.line2,
      name: addressValues.name!,
      receivingHoursEnd: addressValues.receivingHoursEnd,
      receivingHoursStart: addressValues.receivingHoursStart,
      state: addressValues.state!,
      zip: addressValues.zip!,
      longitude,
      latitude,
    };
  },
);

type CreateAddressUpsertInputArg = {
  addressUuid: string;
};

export const createAddressUpsertInput = createAsyncThunk<
  AddressUpsertInput,
  CreateAddressUpsertInputArg,
  { state: RootState }
>(
  'addresses/createAddressUpsertInput',
  async (arg, thunkAPI): Promise<AddressUpsertInput> => {
    const addressValues = selectAddressById(
      thunkAPI.getState(),
      arg.addressUuid,
    );
    if (isNil(addressValues)) {
      console.error(
        `[createAddressUpsertInput] Unable to save order due to invalid address uuid: ${arg.addressUuid}`,
      );
      throw new Error(`Invalid address uuid: ${arg.addressUuid}`);
    }

    const coordinatesRes = await apolloClient.query<
      CoordinatesForAddressQuery,
      CoordinatesForAddressQueryVariables
    >({
      query: CoordinatesForAddressDocument,
      variables: {
        line1: addressValues.line1,
        line2: addressValues.line2,
        city: addressValues.city,
        state: addressValues.state,
        zip: addressValues.zip,
        country: addressValues.country,
      },
    });

    const { latitude, longitude } = coordinatesRes.data.coordinatesForAddress;

    return {
      uuid: v4(),
      city: addressValues.city!,
      country: addressValues.country ?? '',
      driverInstructions: addressValues.driverInstructions,
      line1: addressValues.line1!,
      line2: addressValues.line2,
      name: addressValues.name!,
      receivingHoursEnd: addressValues.receivingHoursEnd,
      receivingHoursStart: addressValues.receivingHoursStart,
      state: addressValues.state!,
      zip: addressValues.zip!,
      longitude: addressValues.longitude ?? longitude,
      latitude: addressValues.latitude ?? latitude,
      preventCoordRecompute: addressValues.preventCoordRecompute,
    };
  },
);

type CreateAddressUpdateInputArg = {
  addressUuid: string;
};

export const createAddressUpdateInput = createAsyncThunk<
  AddressUpsertInput,
  CreateAddressUpdateInputArg,
  { state: RootState }
>(
  'addresses/createAddressUpdateInput',
  async (arg, thunkAPI): Promise<AddressUpsertInput> => {
    const addressValues = selectAddressById(
      thunkAPI.getState(),
      arg.addressUuid,
    );
    if (isNil(addressValues)) {
      console.error(
        `[createAddressUpdateInput] Unable to save order due to invalid address uuid: ${arg.addressUuid}`,
      );
      throw new Error(`Invalid address uuid: ${arg.addressUuid}`);
    }

    const coordinatesRes = await apolloClient.query<
      CoordinatesForAddressQuery,
      CoordinatesForAddressQueryVariables
    >({
      query: CoordinatesForAddressDocument,
      variables: {
        line1: addressValues.line1,
        line2: addressValues.line2,
        city: addressValues.city,
        state: addressValues.state,
        zip: addressValues.zip,
        country: addressValues.country,
      },
    });

    const { latitude, longitude } = coordinatesRes.data.coordinatesForAddress;

    return {
      city: addressValues.city!,
      country: addressValues.country ?? '',
      driverInstructions: addressValues.driverInstructions,
      line1: addressValues.line1!,
      line2: addressValues.line2,
      name: addressValues.name!,
      receivingHoursEnd: addressValues.receivingHoursEnd,
      receivingHoursStart: addressValues.receivingHoursStart,
      state: addressValues.state!,
      zip: addressValues.zip!,
      uuid: arg.addressUuid,
      latitude,
      longitude,
    };
  },
);

export const upsertAddressForShipment = createAsyncThunk<
  void,
  {
    shipment: StandardShipmentFragment;
  },
  { state: RootState }
>(
  'addresses/upsertAddressForShipment',
  async (arg, thunkAPI): Promise<void> => {
    if (!isNil(arg.shipment.shipperAddress)) {
      await thunkAPI.dispatch(
        upsertAddress({ ...arg.shipment.shipperAddress, isLocal: false }),
      );
    }
  },
);

type UpsertAddressValuesThunkArg = {
  address: AddressFragment;
};

/**
 * Upsert an address fragment fetched from the backend into the redux store.
 */
export const upsertAddressValuesThunk = createAsyncThunk<
  AddressFormField,
  UpsertAddressValuesThunkArg,
  { state: RootState }
>('addressValues/upsertAddressValuesThunk', async (arg, thunkAPI) => {
  const isFreeForm =
    isNil(arg.address.latitude) || isNil(arg.address.longitude);
  const addressFormField: AddressFormField = {
    createdAt: undefined,
    isLocal: false,
    updatedAt: undefined,
    isFreeForm,
    ...arg.address,
  };
  await thunkAPI.dispatch(upsertAddress(addressFormField));

  return addressFormField;
});

type GetAddressErrorsResponseArg = {
  addressId: string;
  allowEmpty: boolean;
};
export type AddressErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
};

/**
 * Thunk to validate address form fields
 */
export const getAddressErrorsResponse = createAsyncThunk<
  AddressErrorsResponse,
  GetAddressErrorsResponseArg,
  { state: RootState }
>(
  'addresses/getAddressErrorsResponse',
  async (args, thunkAPI): Promise<AddressErrorsResponse> => {
    const { addressId, allowEmpty } = args;
    const addressValues = selectAddressById(thunkAPI.getState(), addressId);
    if (isNil(addressValues)) {
      throw new Error(`Invalid address uuid: ${addressId}`);
    }
    let isValid = true;
    const errors: ErrorResponse[] = [];
    if (allowEmpty && addressIsEmpty(addressValues)) {
      await Promise.all(
        objectKeys(ADDRESS_SCHEMA).map(async (field) => {
          if (field !== 'uuid') {
            thunkAPI.dispatch(
              upsertAddressErrors({
                uuid: addressId,
                [field]: undefined,
              }),
            );
          }
        }),
      );
    } else {
      await Promise.all(
        objectKeys(ADDRESS_SCHEMA).map(async (field) => {
          let validationResponse: ValidationResponse | null | undefined;
          let userFacingFieldName = '';
          switch (field) {
            case 'name':
              userFacingFieldName = 'Name';
              validationResponse = validateString(addressValues[field], true);
              break;
            case 'line1':
              userFacingFieldName = 'Address Line 1';
              validationResponse = validateString(addressValues[field], true);
              break;
            case 'city':
              userFacingFieldName = 'City';
              validationResponse = validateString(addressValues[field], true);
              break;
            case 'state':
              userFacingFieldName = 'State';
              validationResponse = validateString(addressValues[field], true);
              break;
            case 'zip':
              userFacingFieldName = 'Zip code';
              validationResponse = validateString(addressValues[field], true);
              break;
            case 'country':
            case 'isFreeForm':
            case 'createdAt':
            case 'driverInstructions':
            case 'latitude':
            case 'line2':
            case 'longitude':
            case 'receivingHoursEnd':
            case 'receivingHoursStart':
            case 'updatedAt':
            case 'uuid':
            case 'isLocal':
            case 'preventCoordRecompute':
            case 'specialInstructions':
            case 'associatedContact':
            case 'internalNotes':
            case 'formattedAddress':
            case 'iataCode':
              break;
            default:
              exhaustive(field);
          }
          if (!isNil(validationResponse)) {
            if (validationResponse.valid) {
              thunkAPI.dispatch(
                upsertAddressErrors({
                  uuid: args.addressId,
                  [field]: undefined,
                }),
              );
            } else {
              isValid = false;
              errors.push({ field: userFacingFieldName, validationResponse });
              thunkAPI.dispatch(
                upsertAddressErrors({
                  uuid: args.addressId,
                  [field]: validationResponse.explanation,
                }),
              );
            }
          }
        }),
      );
    }

    return { isValid, errors };
  },
);

export const upsertAddressForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    addressUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertContactPersonForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, addressUuid } = arg;
    await thunkAPI.dispatch(
      upsertAddress({
        uuid: addressUuid,
        city: stop.address.city,
        country: stop.address.country,
        driverInstructions: stop.address.driverInstructions ?? undefined,
        line1: stop.address.line1,
        line2: stop.address.line2 ?? undefined,
        name: stop.address.name ?? undefined,
        receivingHoursStart: stop.address.receivingHoursStart,
        receivingHoursEnd: stop.address.receivingHoursEnd,
        state: stop.address.state,
        zip: stop.address.zip,
        isLocal: false,
        latitude: stop.address.latitude,
        longitude: stop.address.longitude,
      }),
    );
  },
);

export const upsertShipperAddressForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    shipperAddressUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertShipperAddressForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, shipperAddressUuid } = arg;
    if (!isNil(stop.shipperAddress)) {
      await thunkAPI.dispatch(
        upsertAddress({
          uuid: shipperAddressUuid,
          city: stop.shipperAddress.city,
          country: stop.shipperAddress.country,
          driverInstructions:
            stop.shipperAddress.driverInstructions ?? undefined,
          line1: stop.shipperAddress.line1,
          line2: stop.shipperAddress.line2 ?? undefined,
          name: stop.shipperAddress.name ?? undefined,
          receivingHoursStart: stop.shipperAddress.receivingHoursStart,
          receivingHoursEnd: stop.shipperAddress.receivingHoursEnd,
          state: stop.shipperAddress.state,
          zip: stop.shipperAddress.zip,
          isLocal: false,
          latitude: stop.shipperAddress.latitude,
          longitude: stop.shipperAddress.longitude,
        }),
      );
    }
  },
);

export const upsertConsigneeAddressForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    consigneeAddressUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertConsigneeAddressForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, consigneeAddressUuid } = arg;
    if (!isNil(stop.consigneeAddress)) {
      await thunkAPI.dispatch(
        upsertAddress({
          uuid: consigneeAddressUuid,
          city: stop.consigneeAddress.city,
          country: stop.consigneeAddress.country,
          driverInstructions:
            stop.consigneeAddress.driverInstructions ?? undefined,
          line1: stop.consigneeAddress.line1,
          line2: stop.consigneeAddress.line2 ?? undefined,
          name: stop.consigneeAddress.name ?? undefined,
          receivingHoursStart: stop.consigneeAddress.receivingHoursStart,
          receivingHoursEnd: stop.consigneeAddress.receivingHoursEnd,
          state: stop.consigneeAddress.state,
          zip: stop.consigneeAddress.zip,
          isLocal: false,
          latitude: stop.consigneeAddress.latitude,
          longitude: stop.consigneeAddress.longitude,
        }),
      );
    }
  },
);

export const upsertTransferAddressForStop = createAsyncThunk<
  void,
  {
    stop: StandardOrderQueryStopFragment;
    transferAddressUuid: string;
  },
  { state: RootState }
>(
  'contactPersons/upsertTransferAddressForStop',
  async (arg, thunkAPI): Promise<void> => {
    const { stop, transferAddressUuid } = arg;
    if (!isNil(stop.transferAddress)) {
      await thunkAPI.dispatch(
        upsertAddress({
          uuid: transferAddressUuid,
          city: stop.transferAddress.city,
          country: stop.transferAddress.country,
          driverInstructions:
            stop.transferAddress.driverInstructions ?? undefined,
          line1: stop.transferAddress.line1,
          line2: stop.transferAddress.line2 ?? undefined,
          name: stop.transferAddress.name ?? undefined,
          receivingHoursStart: stop.transferAddress.receivingHoursStart,
          receivingHoursEnd: stop.transferAddress.receivingHoursEnd,
          state: stop.transferAddress.state,
          zip: stop.transferAddress.zip,
          isLocal: false,
          latitude: stop.transferAddress.latitude,
          longitude: stop.transferAddress.longitude,
        }),
      );
    }
  },
);
