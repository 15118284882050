import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddressAutocompleteForm from '../../../../common/components/address-autocomplete-form';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  TerminalFragment,
  TerminalsDocument,
  useCreateAddressMutation,
  useTerminalLazyQuery,
  useUpdateAddressMutation,
  useUpdateTerminalMutation,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import { type AddressFormField } from '../../../addresses/redux/addresses-values-slice';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from '../common/archive-action-component';
import TerminalServiceAreas from './terminal-service-areas';

const styles = {
  infoFormContainer: {
    bgcolor: 'background.paper',
    // boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '20px',
    width: '40%',
    // p: 4,
  } as SxProps,
};

const TerminalInfo = ({
  uuid,
  selectedTerminal,
  reloadTerminal,
}: {
  uuid: string | null;
  selectedTerminal: TerminalFragment | undefined;
  reloadTerminal: () => void;
}) => {
  const [currentAddress, setCurrentAddress] = useState<
    AddressFormField | undefined
  >(undefined);
  const navigate = useNavigate();

  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');

  const [updateTerminal] = useUpdateTerminalMutation({
    refetchQueries: [TerminalsDocument],
  });
  const { refetchTerminals } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [terminalQuery] = useTerminalLazyQuery();

  const [createAddress] = useCreateAddressMutation();
  const [updateAddress] = useUpdateAddressMutation({
    refetchQueries: [TerminalsDocument],
  });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isNil(uuid)) {
      terminalQuery({
        variables: {
          uuid,
        },
      });
    }
  }, [uuid, terminalQuery]);

  const handleAddressChange = useCallback(
    (_isAutofillChange: boolean, newAddress?: AddressFormField) => {
      if (!isNil(newAddress)) {
        setCurrentAddress(newAddress);
      } else {
        setCurrentAddress(undefined);
      }
    },
    [],
  );

  useEffect(() => {
    if (!isNil(selectedTerminal)) {
      setCode(selectedTerminal.code);
      setName(selectedTerminal.name);
      setCurrentAddress({
        ...selectedTerminal.address,
        isLocal: true,
      });
    }
  }, [selectedTerminal]);

  const validateInputs = () => {
    if (isEmpty(code)) {
      setErrorMessage('Please enter a terminal code.');
      return false;
    }
    if (isEmpty(name)) {
      setErrorMessage('Please enter a terminal name');
      return false;
    }

    if (isNil(currentAddress)) {
      setErrorMessage('Please enter an address.');
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid || isNil(selectedTerminal)) {
      return;
    }

    try {
      let addressData;
      if (currentAddress?.uuid !== selectedTerminal.address.uuid) {
        const { data } = await createAddress({
          variables: {
            input: {
              addressCreateInput: {
                name: currentAddress?.name ?? '',
                line1: currentAddress?.line1 ?? '',
                city: currentAddress?.city ?? '',
                state: currentAddress?.state ?? '',
                zip: currentAddress?.zip ?? '',
                country: currentAddress?.country ?? '',
              },
            },
          },
        });
        addressData = data;
      } else {
        await updateAddress({
          variables: {
            input: {
              addressUpdateInput: {
                uuid: selectedTerminal.address.uuid,
                name: currentAddress?.name ?? '',
                line1: currentAddress?.line1 ?? '',
                city: currentAddress?.city ?? '',
                state: currentAddress?.state ?? '',
                zip: currentAddress?.zip ?? '',
                country: currentAddress?.country ?? '',
                preventCoordRecompute: false,
                latitude: null,
                longitude: null,
              },
            },
          },
        });
      }
      await updateTerminal({
        variables: {
          updateTerminalInput: {
            terminalUpdateInput: {
              uuid: selectedTerminal.uuid,
              code,
              name,
              addressUuid:
                addressData?.createAddress.uuid ??
                selectedTerminal.address.uuid,
            },
          },
        },
      });
      refetchTerminals();
      setShowSaveSuccessAlert(true);
    } catch (err) {
      setErrorMessage(
        'An error occured when submitting the form, please try again.',
      );
    }
  };

  const handleArchiveOrUnarchive = async ({
    shouldArchive,
  }: {
    shouldArchive: boolean;
  }) => {
    if (isNil(selectedTerminal)) {
      return;
    }
    try {
      await updateTerminal({
        variables: {
          updateTerminalInput: {
            terminalUpdateInput: {
              uuid: selectedTerminal.uuid,
              isActive: !shouldArchive,
            },
          },
        },
      });
      refetchTerminals();
      navigate(-1);
      setShowSaveSuccessAlert(true);
    } catch (err) {
      setErrorMessage(
        'An error occured when submitting the form, please try again.',
      );
    }
  };

  return (
    <Stack direction="column" alignItems="flex-end">
      <Stack p={1}>
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{ width: '20%', m: 1 }}
        >
          Save
        </Button>
      </Stack>
      <Stack direction="row" gap="30px" width="100%">
        <Stack sx={styles.infoFormContainer}>
          <Typography variant="h6"> Terminal information</Typography>
          <Stack direction="column" gap={theme.spacing(2)}>
            <TextField
              label="Terminal Code"
              helperText="The terminal code is the unique identifier for a terminal. Ex: GSO"
              size="small"
              fullWidth
              value={code.toUpperCase()}
              onChange={(e) => {
                setCode(e.target.value.toUpperCase());
                setErrorMessage('');
              }}
            />
            <TextField
              label="Terminal name"
              size="small"
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage('');
              }}
            />
            <AddressAutocompleteForm
              currentAddress={currentAddress}
              handleChange={handleAddressChange}
            />
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Box sx={{ border: 'solid 0.5px black', p: 1, borderRadius: '10px' }}>
            <ArchiveActionComponent
              entityType={ArchiveableEntity.LINE_HAUL_STATION}
              isActive={selectedTerminal?.isActive ?? true}
              handleArchive={() => {
                handleArchiveOrUnarchive({ shouldArchive: true });
              }}
              handleUnarchive={() => {
                handleArchiveOrUnarchive({ shouldArchive: false });
              }}
            />
          </Box>
          <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setShowSaveSuccessAlert(false)}
            open={showSaveSuccessAlert}
          >
            <Alert> Saved </Alert>
          </Snackbar>
        </Stack>
        <Divider orientation="vertical" sx={{ height: '70vh' }} />
        {!isNil(selectedTerminal) && (
          <TerminalServiceAreas
            serviceAreas={selectedTerminal?.serviceAreas}
            terminalUuid={selectedTerminal?.uuid}
            reloadTerminal={reloadTerminal}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default TerminalInfo;
