import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { BaseTextFormFieldProps, FormMode } from '../common';

interface SpecialAccessorialMatrixItemTextFieldProps
  extends BaseTextFormFieldProps {
  onChange: (value: number) => void;
  onBlur: (value: number) => void;
}

export const SpecialAccessorialMatrixItemTextField = ({
  mode,
  value,
  type,
  label,
  disabled,
  onChange,
  onBlur,
}: SpecialAccessorialMatrixItemTextFieldProps) => {
  const hasError = isNil(value) || Number.isNaN(value);

  switch (mode) {
    case FormMode.EDIT:
    case FormMode.CREATE:
      return (
        <TextField
          disabled={disabled}
          error={hasError}
          type={type}
          helperText={hasError ? 'Rate is required' : undefined}
          label={label}
          value={value ?? ''}
          onChange={(e) => onChange(parseFloat(e.target.value))}
          onBlur={() => onBlur(value as number)}
          onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
          size="small"
        />
      );
    default:
      return exhaustive(mode);
  }
};
