import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { AccessorialMatrixItemStoreState } from 'shared/types';
import type { RootState } from '../../../redux/store';

const accessorialsMatrixItemsAdapter =
  createEntityAdapter<AccessorialMatrixItemStoreState>({
    selectId: (matrixItem) => matrixItem.uuid,
  });

export const zoneBasedAccessorialMatrixItemsSlice = createSlice({
  name: 'accessorialMatrixItems',
  initialState: accessorialsMatrixItemsAdapter.getInitialState(),
  reducers: {
    addOneAccessorialMatrixItem: accessorialsMatrixItemsAdapter.addOne,
    updateAccessorialMatrixItem: accessorialsMatrixItemsAdapter.updateOne,
    deleteAccessorialMatrixItem: accessorialsMatrixItemsAdapter.removeOne,
    updateAccessorialMatrixItems: accessorialsMatrixItemsAdapter.updateMany,
    setAllAccessorialMatrixItems: accessorialsMatrixItemsAdapter.setAll,
    upsertManyZoneBasedAccessorialMatrixItems:
      accessorialsMatrixItemsAdapter.upsertMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectAccessorialMatrixItemsById,
  // Pass in a selector that returns the posts slice of state
} = accessorialsMatrixItemsAdapter.getSelectors(
  (state: RootState) => state.zoneBasedAccessorialMatrixItems,
);

const accessorialMatrixItemsSelector = (state: RootState) =>
  state.zoneBasedAccessorialMatrixItems;

export const selectZoneBasedAccessorialMatrixItemsByIds = createSelector(
  accessorialMatrixItemsSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (
    state: EntityState<AccessorialMatrixItemStoreState>,
    entityIds: EntityId[],
  ) => {
    const entityResults: AccessorialMatrixItemStoreState[] = [];
    entityIds.forEach((id) => {
      const match = accessorialsMatrixItemsAdapter
        .getSelectors()
        .selectById(state, id);
      if (!_.isNil(match)) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addOneAccessorialMatrixItem,
  updateAccessorialMatrixItem,
  deleteAccessorialMatrixItem,
  setAllAccessorialMatrixItems,
  upsertManyZoneBasedAccessorialMatrixItems,
} = zoneBasedAccessorialMatrixItemsSlice.actions;

export default zoneBasedAccessorialMatrixItemsSlice.reducer;
