import {
  Alert,
  Box,
  Button,
  Card,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { debounce, isEmpty, isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddressAutocompleteForm from '../../../common/components/address-autocomplete-form';
import TerminalPicker from '../../../common/components/terminal-picker';
import useMe from '../../../common/react-hooks/use-me';
import {
  useCreateWarehouseMutation,
  useDeleteWarehouseMutation,
  useUpdateWarehouseMutation,
  useWarehouseLazyQuery,
  WarehousesDocument,
} from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  AddressFormField,
  selectAddressById,
  upsertAddress,
} from '../../addresses/redux/addresses-values-slice';
import {
  createAddressCreateInput,
  createAddressUpdateInput,
} from '../../addresses/redux/addresses-values-thunks';
import useStyles from './warehouse.styles';

const THIS_FIELD_IS_REQUIRED = 'This field is required';

type WarehouseFormData = {
  name: string;
  terminalUuid?: string;
};

type WarehouseFormErrors = {
  [key in keyof WarehouseFormData]: string | null;
};

type FormRequiredTextField = {
  name: keyof WarehouseFormData;
  terminalUuid: keyof WarehouseFormData;
};

type WarehouseProps = {
  warehouseUuid: string | null;
};

const Warehouse: FunctionComponent<WarehouseProps> = ({ warehouseUuid }) => {
  const navigate = useNavigate();
  const [showSnackbarError, setShowSnackbarError] = useState<boolean>(false);
  const { companyConfiguration } = useMe();

  const styles = useStyles();
  const [addressUuid, setAddressUuid] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();

  const handleAddressChange = (
    _isAutofillChange: boolean,
    newAddress?: AddressFormField,
  ) => {
    if (!isNil(newAddress)) {
      dispatch(upsertAddress(newAddress));
      setAddressUuid(newAddress.uuid);
    } else {
      // bad address
      setAddressUuid(undefined);
    }
  };

  const addressValues = useAppSelector((state) =>
    selectAddressById(state, addressUuid ?? ''),
  );

  const [warehouseFormData, setWarehouseFormData] = useState<WarehouseFormData>(
    {
      name: '',
    },
  );

  const [warehouseFormErrors, setWarehouseFormErrors] =
    useState<WarehouseFormErrors>({
      name: null,
      terminalUuid: null,
    });

  // if hasUuid, we are in edit mode. Otherwise, we are in create mode.
  const hasUuid = !isNil(warehouseUuid);

  const [warehouseQuery] = useWarehouseLazyQuery();
  const [createWarehouseMutation] = useCreateWarehouseMutation({
    refetchQueries: [WarehousesDocument],
  });
  const [updateWarehouseMutation] = useUpdateWarehouseMutation({
    refetchQueries: [WarehousesDocument],
  });
  const [deleteWarehouseMutation] = useDeleteWarehouseMutation({
    refetchQueries: [WarehousesDocument],
  });

  // Pull initial data excluding default vehicle
  useEffect(() => {
    if (hasUuid) {
      warehouseQuery({
        variables: {
          warehouseUuid,
        },
      }).then((res) => {
        if (!isNil(res) && !isNil(res.data?.warehouse)) {
          const warehouseData = res.data?.warehouse;
          setWarehouseFormData({
            name: warehouseData?.name ?? '',
            terminalUuid: warehouseData?.terminal?.uuid ?? undefined,
          });
          setAddressUuid(warehouseData?.address.uuid ?? '');
        }
      });
    }
  }, [warehouseQuery, hasUuid, warehouseUuid]);

  const hasWarehouseFieldError = () => {
    let hasError = false;
    const newErrors = { ...warehouseFormErrors };

    // First, check all required fields
    const requiredFields: (keyof FormRequiredTextField)[] = [
      'name',
      ...(companyConfiguration?.terminalsEnabled === true
        ? ['terminalUuid' as keyof FormRequiredTextField]
        : []),
    ];
    if (!isNil(warehouseFormData)) {
      requiredFields.forEach((requiredField) => {
        if (
          isNil(warehouseFormData[requiredField]) ||
          warehouseFormData[requiredField]?.length === 0
        ) {
          newErrors[requiredField] = THIS_FIELD_IS_REQUIRED;
          hasError = true;
        } else {
          newErrors[requiredField] = null;
        }
      });
    }
    if (isEmpty(addressUuid)) {
      setShowSnackbarError(true);
    }
    setWarehouseFormErrors(newErrors);
    return isEmpty(addressUuid) || hasError;
  };

  const handleCreateWarehouse = async () => {
    if (hasWarehouseFieldError() || isNil(addressUuid)) {
      return;
    }
    const addressCreateInput = await dispatch(
      createAddressCreateInput({
        addressUuid,
      }),
    ).unwrap();

    if (
      isEmpty(addressCreateInput.name) ||
      isEmpty(addressCreateInput.line1) ||
      isNil(addressCreateInput.latitude) ||
      isNil(addressCreateInput.longitude)
    ) {
      setShowSnackbarError(true);
      return;
    }

    const response = await createWarehouseMutation({
      variables: {
        createWarehouseInput: {
          addressCreateInput,
          name: warehouseFormData.name,
          terminalUuid: warehouseFormData.terminalUuid,
        },
      },
    });
    if (!isNil(response.data?.createWarehouse.uuid)) {
      navigate('/warehouse/settings');
    } else {
      setShowSnackbarError(true);
    }
  };

  const handleDeleteWarehouse = async () => {
    if (!isNil(warehouseUuid)) {
      const response = await deleteWarehouseMutation({
        variables: {
          warehouseUuid,
        },
      });
      if (!isNil(response.data?.deleteWarehouse.uuid)) {
        navigate('/warehouse/settings');
      } else {
        setShowSnackbarError(true);
      }
    } else {
      navigate('/warehouse/settings');
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbarError(false);
  };

  const handleUpdateWarehouse = async () => {
    if (hasWarehouseFieldError() || isNil(addressUuid)) {
      return;
    }
    const addressUpdateInput = await dispatch(
      createAddressUpdateInput({
        addressUuid,
      }),
    ).unwrap();

    if (isEmpty(addressUpdateInput.name) || isEmpty(addressUpdateInput.line1)) {
      setShowSnackbarError(true);
      return;
    }

    if (!isNil(warehouseUuid)) {
      const response = await updateWarehouseMutation({
        variables: {
          updateWarehouseInput: {
            uuid: warehouseUuid,
            name: warehouseFormData.name,
            addressUpdateInput,
            terminalUuid: warehouseFormData.terminalUuid,
          },
        },
      });
      if (!isNil(response.data?.updateWarehouse.uuid)) {
        navigate('/warehouse/settings');
      } else {
        setShowSnackbarError(true);
      }
    } else {
      navigate('/warehouse/settings');
    }
  };

  return (
    <Box>
      <Box sx={styles.flexSpaceBetweenBox}>
        <Button
          onClick={() => navigate('/warehouse/settings')}
          sx={{ marginBottom: '16px' }}
          variant="contained"
        >
          Back
        </Button>
        {hasUuid ? (
          <Button
            sx={{ marginBottom: '16px' }}
            onClick={handleUpdateWarehouse}
            variant="contained"
          >
            Save
          </Button>
        ) : (
          <Button
            sx={{ marginBottom: '16px' }}
            onClick={handleCreateWarehouse}
            variant="contained"
          >
            Confirm Add Warehouse
          </Button>
        )}
      </Box>
      {hasUuid && (
        <Button
          variant="contained"
          onClick={handleDeleteWarehouse}
          color="secondary"
        >
          Delete
        </Button>
      )}
      <Box sx={styles.flexRowBox}>
        {hasUuid ? (
          <Typography sx={{ color: 'black' }} variant="h5">
            Edit warehouse
          </Typography>
        ) : (
          <Typography sx={{ color: 'black' }} variant="h5">
            Add warehouse
          </Typography>
        )}
      </Box>
      <Card sx={styles.card}>
        <Box sx={styles.flexRowBox}>
          <Typography variant="h6">General information</Typography>
        </Box>
        <Grid container>
          <Grid sx={styles.formContent} xs={12} md={5} item>
            <TextField
              label="Warehouse name"
              error={!isNil(warehouseFormErrors.name)}
              helperText={warehouseFormErrors.name}
              onChange={(event) => {
                setWarehouseFormData({
                  ...warehouseFormData,
                  name: event.target.value,
                });
              }}
              value={warehouseFormData.name}
              required
              fullWidth
              size="small"
            />
            {companyConfiguration?.terminalsEnabled === true && (
              <TerminalPicker
                terminalUuid={warehouseFormData.terminalUuid}
                setTerminalUuid={(newValue) => {
                  setWarehouseFormData({
                    ...warehouseFormData,
                    terminalUuid: newValue,
                  });
                }}
                required
                error={warehouseFormErrors.terminalUuid ?? undefined}
              />
            )}
            <AddressAutocompleteForm
              currentAddress={addressValues}
              handleChange={debounce(handleAddressChange, 200)}
            />
          </Grid>
        </Grid>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        open={showSnackbarError}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          Error creating warehouse, please enter a valid address
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Warehouse;
