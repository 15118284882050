export const darkenHexColor = (hexColor: string, factor = 0.65) => {
  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Darken the RGB components
  const darkenedR = Math.round(r * (1 - factor));
  const darkenedG = Math.round(g * (1 - factor));
  const darkenedB = Math.round(b * (1 - factor));

  // Convert the darkened RGB components back to hex
  return `#${darkenedR.toString(16).padStart(2, '0')}${darkenedG
    .toString(16)
    .padStart(2, '0')}${darkenedB.toString(16).padStart(2, '0')}`;
};

export const lightenHexColor = (hexColor: string, factor = 0.65) => {
  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Lighten the RGB components
  const lightenedR = Math.min(255, Math.round(r + (255 - r) * factor));
  const lightenedG = Math.min(255, Math.round(g + (255 - g) * factor));
  const lightenedB = Math.min(255, Math.round(b + (255 - b) * factor));

  // Convert the lightened RGB components back to hex
  return `#${lightenedR.toString(16).padStart(2, '0')}${lightenedG
    .toString(16)
    .padStart(2, '0')}${lightenedB.toString(16).padStart(2, '0')}`;
};

export const stringToColor = (
  inputString: string,
  alpha = 1,
  darken = false,
): string => {
  let hash = 0;
  for (let i = 0; i < inputString.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + inputString.charCodeAt(i);
  }

  // eslint-disable-next-line no-bitwise
  let hexColor = `#${(hash & 0xffffff).toString(16).padStart(6, '0')}`;

  // eslint-disable-next-line no-param-reassign
  alpha = Math.min(1, Math.max(0, alpha));

  const alphaHex = Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, '0');

  hexColor = `${hexColor}${alphaHex}`;

  if (darken) {
    hexColor = darkenHexColor(hexColor);
  }

  return hexColor;
};

const calculateLuminance = (hexColor: string) => {
  // Remove the # symbol if present
  const color = hexColor.replace(/^#/, '');

  // Convert hex to RGB
  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  // Calculate luminance using the relative luminance formula
  const L = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return L;
};

export const chooseForegroundColor = (
  backgroundHexColor: string,
  dark = 'black',
  light = 'white',
) => {
  const luminance = calculateLuminance(backgroundHexColor);
  return luminance > 0.65 ? dark : light;
};
