import {
  Button,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../../common/react-hooks/use-me';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import useWarehouses from '../../../common/react-hooks/use-warehouses';
import {
  AddressFragment,
  PermissionResource,
  WarehousesDocument,
  useMarkWarehouseAsDefaultMutation,
} from '../../../generated/graphql';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  editButton: {
    mx: 1.5,
  } as SxProps,
};

type WarehouseTableEntry = {
  uuid: string;
  name: string;
  terminal?: {
    uuid: string;
    code: string;
  } | null;
  address: AddressFragment;
  isDefault: boolean;
};

interface WarehouseTableRowProps {
  uuid: string;
  isDefault: boolean;
  name: string;
  terminal?: string | null;
  address: AddressFragment;
  handleMarkDefault: () => void;
  terminalsEnabled?: boolean;
}

const WarehouseTableRow = ({
  uuid,
  isDefault,
  name,
  terminal,
  address,
  handleMarkDefault,
  terminalsEnabled = false,
}: WarehouseTableRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteWarehouses } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Warehouses,
  );
  const navigate = useNavigate();

  return (
    <TableRow key={uuid} sx={{}}>
      <TableCell component="th" scope="row">
        {isDefault ? (
          <b>
            {' '}
            {`${name}`} {`${isDefault ? '(Default)' : ''}`}{' '}
          </b>
        ) : (
          `${name}`
        )}
      </TableCell>
      {terminalsEnabled && <TableCell>{terminal}</TableCell>}{' '}
      <TableCell>
        <div>{address.name}</div>
        <div>{address.line1}</div>
        {!isNil(address.line2) && <div>{address.line2}</div>}
        <div>{`${address.city}, ${address.zip}`}</div>
      </TableCell>
      <TableCell>
        <Button
          onClick={() => navigate(`/warehouse/settings/warehouses/${uuid}`)}
          sx={styles.editButton}
          variant="outlined"
          disabled={!canWriteWarehouses}
        >
          Edit
        </Button>
      </TableCell>
      <TableCell>
        {!isDefault && (
          <Button
            onClick={handleMarkDefault}
            sx={styles.editButton}
            variant="outlined"
            disabled={!canWriteWarehouses}
          >
            Make default warehouse
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const WarehousesTable = () => {
  const { companyConfiguration } = useMe();
  const { userPermissions } = useUserRoles();
  const navigate = useNavigate();
  const { warehouses } = useWarehouses();
  const [markWarehouseAsDefaultMutation] = useMarkWarehouseAsDefaultMutation({
    refetchQueries: [WarehousesDocument],
  });

  const { canWrite: canWriteWarehouses } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Warehouses,
  );

  const handleMarkRowDefault = async (uuid: string) => {
    await markWarehouseAsDefaultMutation({
      variables: {
        warehouseUuid: uuid,
      },
    });
  };

  const defaultWarehouse = warehouses?.find((w) => w.isDefault);
  const nonDefault = warehouses?.filter((w) => !w.isDefault) ?? [];

  // order such that the default one shows on top.
  let orderedWarehouses = [];
  if (!isNil(defaultWarehouse)) {
    orderedWarehouses = [defaultWarehouse, ...nonDefault];
  } else {
    orderedWarehouses = nonDefault;
  }

  return (
    <Stack direction="column" alignItems="flex-end" sx={styles.stackView}>
      <Button
        onClick={() => navigate('/warehouse/settings/warehouses/add')}
        sx={{ marginBottom: '16px' }}
        variant="contained"
        disabled={!canWriteWarehouses}
      >
        Add warehouse
      </Button>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {companyConfiguration?.terminalsEnabled === true && (
                <TableCell>Terminal</TableCell>
              )}
              <TableCell>Address</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedWarehouses?.map((row: WarehouseTableEntry) => (
              <WarehouseTableRow
                key={row.uuid}
                name={row.name}
                terminal={row.terminal?.code}
                uuid={row.uuid}
                isDefault={row.isDefault}
                address={row.address}
                handleMarkDefault={() => {
                  handleMarkRowDefault(row.uuid);
                }}
                terminalsEnabled={companyConfiguration?.terminalsEnabled}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default WarehousesTable;
