import { ApolloQueryResult } from '@apollo/client/core/types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  Exact,
  FindRoleInput,
  RoleQuery,
  useAddUserToRoleMutation,
  useUsersForRoleQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

interface AddUserRowProps {
  roleUuid: string;

  setIsEditing: Dispatch<SetStateAction<boolean>>;

  refetchRole: (
    variables?:
      | Partial<Exact<{ findRoleInput?: FindRoleInput | undefined }>>
      | undefined,
  ) => Promise<ApolloQueryResult<RoleQuery>>;

  setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
}
const AddUserRow = ({
  roleUuid,
  setIsEditing,
  refetchRole,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
}: AddUserRowProps) => {
  const { refetchUserRoles } = useUserRoles();
  const [userUuid, setUserUuid] = useState<
    { value: string | undefined; label: string | undefined } | undefined
  >();
  const { data: usersForRoleData } = useUsersForRoleQuery();

  const [addUserToRole, { loading: addUserToRoleLoading }] =
    useAddUserToRoleMutation();

  const handleAddUserToRole = async () => {
    const selectedUserUuid = userUuid?.value;
    if (isNil(selectedUserUuid)) {
      return;
    }
    try {
      const resp = await addUserToRole({
        variables: {
          addUserToRoleInput: {
            uuid: roleUuid,
            userUuid: selectedUserUuid,
          },
        },
      });
      if (resp.data?.addUserToRole.success === true) {
        await refetchRole({ findRoleInput: { uuid: roleUuid } });
        setSuccessSnackbarVisible(true);
        refetchUserRoles();
        setIsEditing(false);
      } else {
        setErrorSnackbarVisible(true);
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleCancel = () => {
    setUserUuid(undefined);
    setIsEditing(false);
  };

  return (
    <Stack direction="row" gap={1} alignItems="middle">
      <FormControl sx={{ width: '30%' }}>
        <AutocompleteFuzzy
          size="small"
          value={userUuid}
          onChange={(e, newValue) => {
            setUserUuid(newValue ?? undefined);
          }}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              size="small"
              label="User"
            />
          )}
          options={(usersForRoleData?.users ?? [])?.map((user) => {
            return {
              label: user.email,
              value: user.uuid,
            };
          })}
          sx={{ backgroundColor: 'white', width: '250px' }}
        />
      </FormControl>
      <IconButton
        onClick={handleAddUserToRole}
        color="primary"
        disabled={addUserToRoleLoading || isNilOrEmptyString(userUuid)}
      >
        <Tooltip arrow title="Add user to role">
          <CheckIcon sx={{ fontSize: '20px' }} />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={handleCancel}
        disabled={addUserToRoleLoading}
        color="primary"
        size="small"
      >
        <Tooltip arrow title="Cancel">
          <CloseIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Stack>
  );
};

export default AddUserRow;
