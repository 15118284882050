import { FreightBillingMethod } from '../../../../../../generated/graphql';

/**
 * The set of freight charge billing methods that can have discounts applied to them.
 */
export const VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS: FreightBillingMethod[] =
  [
    FreightBillingMethod.Tariff,
    FreightBillingMethod.PerMile,
    FreightBillingMethod.PerPiece,
    FreightBillingMethod.Weight,
  ];
