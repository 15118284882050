import {
  Alert,
  Box,
  Button,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil, values } from 'lodash';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Option } from '../../common/filters/types';
import {
  ContactItemsDocument,
  ItemStatus,
  ShallowItemFragment,
  ShallowItemsDocument,
  StorageOrderDocument,
  UnitOfMeasure,
  useCreateItemMutation,
} from '../../generated/graphql';
import useStyles from '../orders/components/styles';
import WarehouseSelector from '../storage-orders/components/common/warehouse-selector';
import { CreateItemFormValues } from './types';
import { createItemFormResolver } from './utils';

const styles = {
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formCard: {
    padding: 4,
  },
  backButton: {
    marginRight: 2,
  },
  modal: {
    width: 600,
    height: 'fit-content',
  },
};

interface ItemFormModalProps {
  contactUuid: string;
  open: boolean;
  onClose: () => void;
  onCreateItem?: (item: ShallowItemFragment) => void;
  warehouseOverride?: Option;
}
const ItemFormModal = ({
  contactUuid,
  open,
  onClose,
  onCreateItem,
  warehouseOverride,
}: ItemFormModalProps) => {
  const globalStyles = useStyles();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CreateItemFormValues>({
    resolver: createItemFormResolver,
    defaultValues: {
      primaryUnitOfMeasure: UnitOfMeasure.Each,
      secondaryUnitOfMeasure: UnitOfMeasure.Unit,
      warehouse: warehouseOverride,
    },
  });

  const [
    createItemMutation,
    { data: createItemData, loading: createItemMutationLoading },
  ] = useCreateItemMutation({
    refetchQueries: [
      StorageOrderDocument,
      ShallowItemsDocument,
      ContactItemsDocument,
    ],
    awaitRefetchQueries: true,
  });

  const onSubmit: SubmitHandler<CreateItemFormValues> = async (data) => {
    try {
      const createItemResponse = await createItemMutation({
        variables: {
          input: {
            contactUuid,
            warehouseUuid: data.warehouse?.value ?? warehouseOverride?.value,
            sku: data.sku,
            description: !isNil(data.description) ? data.description : null,
            status: ItemStatus.Active,
            createInventoryPrimaryUnitOfMeasureInput: {
              unitOfMeasure: data.primaryUnitOfMeasure,
            },
            createInventorySecondaryUnitOfMeasureInput: !isNil(
              data.secondaryUnitOfMeasure,
            )
              ? {
                  unitOfMeasure: data.secondaryUnitOfMeasure,
                }
              : null,
          },
        },
      });
      if (!isNil(createItemResponse.data?.createItem.error)) {
        setErrorSnackbarVisible(true);
      } else if (createItemResponse.data?.createItem.item != null) {
        setSuccessSnackbarVisible(true);
        onCreateItem?.(createItemResponse.data.createItem.item);
        onClose();
        reset();
      } else {
        setErrorSnackbarVisible(true);
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={[globalStyles.modal, styles.modal]}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={styles.centeredRow}>
                    <Typography variant="h5">
                      <strong>Create item</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {isNil(warehouseOverride) && (
                      <Controller
                        name="warehouse"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <InputLabel>Warehouse</InputLabel>
                            <WarehouseSelector
                              value={value}
                              onChange={(newWarehouse) => {
                                onChange(newWarehouse);
                              }}
                              width="100%"
                              whiteBackground
                              noLabel
                              error={!isNil(errors.warehouse?.message)}
                              cacheId="item-form"
                            />
                            {!isNil(errors?.warehouse?.message) && (
                              <Typography color="error">
                                {errors?.warehouse?.message ?? ''}
                              </Typography>
                            )}
                          </>
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="sku"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <InputLabel>SKU</InputLabel>
                          <TextField
                            autoFocus
                            size="small"
                            onChange={onChange}
                            value={value}
                            required
                            error={!isNil(errors.sku)}
                            helperText={errors?.sku?.message}
                            sx={{ width: '100%' }}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <InputLabel>Description</InputLabel>
                          <TextField
                            size="small"
                            onChange={onChange}
                            value={value}
                            required
                            error={!isNil(errors.description)}
                            helperText={errors?.description?.message}
                            sx={{ width: '100%' }}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="primaryUnitOfMeasure"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <InputLabel id="age">
                            Primary Unit of Measure
                          </InputLabel>
                          <Select
                            size="small"
                            onChange={onChange}
                            value={value}
                            sx={{ width: '100%' }}
                            error={!isNil(errors.primaryUnitOfMeasure)}
                            native
                          >
                            {values(UnitOfMeasure).map((uom) => {
                              return (
                                <option key={uom} value={uom}>
                                  {sentenceCase(uom)}
                                </option>
                              );
                            })}
                          </Select>
                          {!isNil(errors.primaryUnitOfMeasure) && (
                            <FormHelperText sx={{ color: '#D32F2F' }}>
                              {errors.primaryUnitOfMeasure.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="secondaryUnitOfMeasure"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <InputLabel id="age">
                            Secondary Unit of Measure
                          </InputLabel>
                          <Select
                            size="small"
                            onChange={onChange}
                            value={value}
                            error={!isNil(errors.secondaryUnitOfMeasure)}
                            sx={{ width: '100%' }}
                            native
                          >
                            {values(UnitOfMeasure).map((uom) => {
                              return (
                                <option key={uom} value={uom}>
                                  {sentenceCase(uom)}
                                </option>
                              );
                            })}
                          </Select>
                          {!isNil(errors.secondaryUnitOfMeasure) && (
                            <FormHelperText sx={{ color: '#D32F2F' }}>
                              {errors.secondaryUnitOfMeasure.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={onClose}
                  disabled={createItemMutationLoading}
                  sx={styles.backButton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={createItemMutationLoading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Snackbar
        autoHideDuration={5000}
        onClose={() => setSuccessSnackbarVisible(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => setSuccessSnackbarVisible(false)}
        >
          Successfully created item
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarVisible(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
      >
        <Alert severity="error" onClose={() => setErrorSnackbarVisible(false)}>
          {createItemData?.createItem.error ?? 'Error creating item'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ItemFormModal;
