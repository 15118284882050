import LogoutIcon from '@mui/icons-material/Logout';
import { Box } from '@mui/material';
import React from 'react';
import useLogout from '../../../common/react-hooks/use-logout';
import DashboardDrawerItem from './dashboard-drawer-item';

const DashboardDrawerLogoutButton = React.memo(() => {
  const logout = useLogout();

  return (
    <Box
      sx={{
        mt: 'auto',
        borderTop: '1px solid lightgray',
      }}
    >
      <DashboardDrawerItem
        name="Logout"
        icon={<LogoutIcon sx={{ height: '24px', width: '24px' }} />}
        onClick={logout}
      />
    </Box>
  );
});

export { DashboardDrawerLogoutButton };
