import { useGeneralLedgerCodesQuery } from '../../generated/graphql';

export const useGLCodes = () => {
  const { data: glCodesData, loading } = useGeneralLedgerCodesQuery({
    fetchPolicy: 'cache-first',
  });

  const glCodes = glCodesData?.generalLedgerCodes;

  return {
    glCodes,
    glCodesLoading: loading,
  };
};
