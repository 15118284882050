import { CircularProgress } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { initNewContactValues } from '../redux/contact-values-thunks';
import ContactPage from './contact-page';
import ContactPageMode from './contact-page-mode';

const AddContactPage = () => {
  const [contactUuid, setContactUuid] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isNil(contactUuid)) {
      dispatch(initNewContactValues())
        .unwrap()
        .then((contact) => {
          setContactUuid(contact.uuid);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNil(contactUuid)) {
    return <CircularProgress />;
  }

  return (
    <ContactPage initialMode={ContactPageMode.CREATE} uuid={contactUuid} />
  );
};

export default AddContactPage;
