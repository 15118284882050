import { Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import { exhaustive } from 'shared/switch';
import {
  FilterConstructionOperatorType,
  FilterConstructionFilterType,
  Option,
  SingleFilterConstructionType,
} from '../../../../../common/filters/types';
import {
  filterToInputType,
  getFilterNameLabel,
  isTextFieldOperation,
  isMultiSelectOperation,
  getFilterOperationsByType,
} from '../../../../../common/filters/utils';
import { convertValueToOption } from '../../../../../common/utils/utils';
import { DateFilterValueInput } from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { OrderFilterFieldV2 } from '../../../../orders/components/enums/order-filters';
import FilterBoolInput from '../../../filter-input-components/filter-bool-input';

import FilterDatePickerInput from '../../../filter-input-components/filter-date-picker-input';
import FilterMultiSelectInput from '../../../filter-input-components/filter-multi-select-input';
import FilterSingleSelectInput from '../../../filter-input-components/filter-single-select-input';
import FilterTextInput from '../../../filter-input-components/filter-text-input';
import OrdersFilterDatePicker from '../../orders-filter-date-picker';
import OrdersFilterDateRangePicker from './orders-filter-date-range-picker';
import useFilterOptions from './use-filter-options';

const FORMATTED_FILTER_NAME_OPTIONS: readonly Option[] = Object.freeze(
  (Object.keys(OrderFilterFieldV2) as (keyof typeof OrderFilterFieldV2)[]).map(
    (filterNameOption) => ({
      value: filterNameOption,
      label: getFilterNameLabel(filterNameOption),
    }),
  ),
);

interface FilterModelSelectProps {
  filter: SingleFilterConstructionType;
  setFilter: (filter: SingleFilterConstructionType) => void;
}

const FilterModelSelect = ({ filter, setFilter }: FilterModelSelectProps) => {
  const filterNameInput = filter.filter;
  const filterOperationInput = filter.op;
  const filterValueInput = filter.value;
  const filterOptionMappings = useFilterOptions(filter.filter);

  const filterType = useMemo(() => {
    if (!isNil(filter.filter)) {
      return filterToInputType(filter.filter);
    }
    return 'text';
  }, [filter.filter]);

  const shouldBeMultiselect = isMultiSelectOperation({
    filterTypes: filterType,
    filterConstructionOperatorType: filterOperationInput,
  });
  const shouldBeTextField = isTextFieldOperation({
    filterTypes: filterType,
    filterConstructionOperatorType: filterOperationInput,
  });

  const mapTypeToInputComponent = () => {
    if (
      filterOperationInput === 'isBlank' ||
      filterOperationInput === 'isNotBlank'
    ) {
      return null;
    }
    switch (filterType) {
      case 'date':
        if (filterOperationInput === 'eqV2') {
          return (
            <OrdersFilterDatePicker
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput as DateFilterValueInput}
              setFilter={setFilter}
            />
          );
        }
        if (filterOperationInput === 'between') {
          return (
            <OrdersFilterDateRangePicker
              filterOperationInput={filterOperationInput}
              filterNameInput={filterNameInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
            />
          );
        }
        return (
          <FilterDatePickerInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
          />
        );

      case 'select':
        if (shouldBeTextField) {
          return (
            <FilterTextInput
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
              textFieldType="text"
            />
          );
        }
        if (shouldBeMultiselect) {
          return (
            <FilterMultiSelectInput
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
              filterOptionMappings={filterOptionMappings ?? []}
            />
          );
        }
        return (
          <FilterSingleSelectInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
            filterOptionMappings={filterOptionMappings ?? []}
          />
        );
      case 'enum':
        if (shouldBeMultiselect) {
          return (
            <FilterMultiSelectInput
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
              filterOptionMappings={filterOptionMappings ?? []}
            />
          );
        }
        return (
          <FilterSingleSelectInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
            filterOptionMappings={filterOptionMappings ?? []}
          />
        );
      case 'bool':
        return (
          <FilterBoolInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
          />
        );
      case 'text':
      case 'integer':
      case 'float':
        return (
          <FilterTextInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
            textFieldType={
              filterType === 'integer' || filterType === 'float'
                ? 'number'
                : 'text'
            }
          />
        );
      default:
        return exhaustive(filterType);
    }
  };

  const filterOperationOptions: Option[] = useMemo(() => {
    return Object.entries(getFilterOperationsByType(filterType)).map(
      ([filterOperator, filterDisplayName]) => ({
        value: filterOperator,
        label: filterDisplayName,
      }),
    );
  }, [filterType]);

  return (
    <Stack direction="row" alignItems="start">
      <AutocompleteFuzzy
        sx={{
          backgroundColor: 'white',
          minWidth: '300px',
          flexGrow: 1,
        }}
        value={convertValueToOption({
          value: filterNameInput as string,
          optionsList: FORMATTED_FILTER_NAME_OPTIONS,
        })}
        options={FORMATTED_FILTER_NAME_OPTIONS}
        matchSortOptions={{ keys: ['label'] }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            size="small"
            placeholder="Select property..."
            InputProps={{
              ...params.InputProps,
              sx: { borderRadius: 0 },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(_, selected) => {
          setFilter({
            filter: (selected?.value as FilterConstructionFilterType) ?? null,
            op: null,
            value: null,
          });
        }}
      />
      {filterType !== 'bool' && (
        <AutocompleteFuzzy
          sx={{
            backgroundColor: 'white',
            minWidth: '160px',
            minHeight: '34px',
            flexGrow: 1,
          }}
          value={convertValueToOption({
            value: filterOperationInput as string,
            optionsList: filterOperationOptions,
          })}
          options={filterOperationOptions}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              size="small"
              placeholder="Operator"
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0,
                  marginLeft: '-1px',
                },
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <li {...props} style={{ padding: '4px' }}>
              {option.label}
            </li>
          )}
          onChange={(_, selected) => {
            setFilter({
              filter: filterNameInput,
              op: (selected?.value as FilterConstructionOperatorType) ?? null,
              value: null,
            });
          }}
        />
      )}
      {mapTypeToInputComponent()}
    </Stack>
  );
};

export default FilterModelSelect;
