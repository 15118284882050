import {
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import pluralize from 'pluralize';
import { Dispatch, SetStateAction, useState } from 'react';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import { FeatureFlag } from '../../../../../common/feature-flags';
import { Option } from '../../../../../common/filters/types';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import {
  AccountingReportTypeV2,
  useDunningReportPdfLazyQuery,
  useSendAccountingReportsMutation,
} from '../../../../../generated/graphql';
import PalletModal from '../../../../../pallet-ui/modal/pallet-modal-old';
import theme from '../../../../../theme';
import useInvoicesStore from '../../../invoices-store';

const DunningModal = ({
  isOpen,
  setIsOpen,
  setShowSendAccountingReportsSuccessMessage,
  setShowSendAccountingReportsErrorMessage,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setShowSendAccountingReportsSuccessMessage: Dispatch<SetStateAction<boolean>>;
  setShowSendAccountingReportsErrorMessage: Dispatch<SetStateAction<boolean>>;
}) => {
  const [setShowInvoiceSendMenu, setInvoiceMenuTabIndex, createFileDownload] =
    useInvoicesStore((state) => [
      state.setShowInvoiceSendMenu,
      state.setInvoiceMenuTabIndex,
      state.createFileDownload,
    ]);
  const ffUseBatchSendReport = useFeatureFlag(
    FeatureFlag.FF_USE_BATCH_SEND_DUNNING_REPORT,
  );
  const [getDunningReportPdf] = useDunningReportPdfLazyQuery();
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState<
    Option[] | undefined
  >(undefined);
  const [sendAccountingReports, { loading: sendAccountingReportsLoading }] =
    useSendAccountingReportsMutation();
  const confirm = useConfirm();

  // Generating a Dunning Report is only possible for one customer at a time
  // Unclear if this is a strict product requirement but for now its how the backend is implemented
  // So we only allow a user to download a dunning report when one customer is selected but batch send is available
  // for sending to multiple customers
  const firstSelectedCustomerOption = selectedCustomerOptions?.[0];
  const numSelectedCustomerOptions = selectedCustomerOptions?.length ?? 0;
  const disableDownloadButton = numSelectedCustomerOptions !== 1;

  const onBatchSend = async () => {
    try {
      const contactUuids = selectedCustomerOptions?.map(
        (option) => option.value,
      );
      const sendAccountingReportsOutput = await sendAccountingReports({
        variables: {
          sendAccountingReportsInput: {
            reportType: AccountingReportTypeV2.DunningReport,
            contactUuids: !isNil(contactUuids) ? contactUuids : [],
            reportFilters: {},
          },
        },
      });
      if (
        sendAccountingReportsOutput.data?.sendAccountingReports.__typename ===
        'SendAccountingReportsSuccessOutput'
      ) {
        setShowSendAccountingReportsSuccessMessage(true);
        setIsOpen(false);
        await confirm({
          title: '',
          description: (
            <Typography>
              Please wait while we generate and send your reports. You can check
              the progress and download reports{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setInvoiceMenuTabIndex(1);
                  setShowInvoiceSendMenu(true);
                }}
              >
                here
              </Link>
            </Typography>
          ),
          hideCancelButton: true,
          confirmationText: `Done`,
        });
      } else {
        setShowSendAccountingReportsErrorMessage(true);
        setIsOpen(false);
      }
    } catch (e) {
      setShowSendAccountingReportsErrorMessage(true);
      setIsOpen(false);
    }
  };

  const startDownload = async () => {
    if (isNil(firstSelectedCustomerOption)) {
      Sentry.captureException(
        new Error('Contact UUID is not set when trying to generate report'),
      );
      return;
    }
    const completeDownload = createFileDownload();
    try {
      const pdfRes = await getDunningReportPdf({
        variables: {
          dunningReportInput: {
            contactUuid: firstSelectedCustomerOption.value,
          },
        },
      });
      if (!isNil(pdfRes.error)) {
        throw pdfRes.error;
      }

      const presignedGetUrl = pdfRes.data?.dunningReportPdf.url;
      const fileName = pdfRes.data?.dunningReportPdf.fileName;
      const errors = pdfRes.data?.dunningReportPdf.errors;

      if (!isNil(errors) && !isEmpty(errors)) {
        completeDownload({ alertSeverity: 'error', message: errors[0] });
      } else if (!isNil(presignedGetUrl) && !isNil(fileName)) {
        const getFileRes = await fetch(presignedGetUrl, { cache: 'no-cache' });

        const blob = await getFileRes.blob();

        fileDownload(blob, fileName);
        completeDownload();
      }
    } catch (e) {
      completeDownload({
        alertSeverity: 'error',
        message:
          'An error occurred while generating the report, please try again and contact support if the issue persists',
      });
      Sentry.captureException(e);
    }
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'dunning-report',
    logData: { contactUuid: firstSelectedCustomerOption?.value },
  });

  return (
    <PalletModal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title="Download Dunning Report"
      hideCancel
      width="sm"
    >
      <Grid container gap={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="row"
          gap={1}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <CustomerFilterButton
            selectedOptionsMultiselect={selectedCustomerOptions}
            handleChangeMultiselect={(options) =>
              setSelectedCustomerOptions(options ?? undefined)
            }
          />
          <Tooltip
            title={
              disableDownloadButton
                ? 'Select exactly one customer to download a dunning report'
                : 'Click to download the report'
            }
          >
            <span>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                onClick={startDownloadWithMeasurement}
                disabled={disableDownloadButton}
              >
                Download
              </Button>
            </span>
          </Tooltip>
        </Grid>
        {ffUseBatchSendReport && (
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              style={{ alignItems: 'flex-start' }}
            >
              <Stack gap={1}>
                <Button
                  variant="contained"
                  color="info"
                  disabled={sendAccountingReportsLoading}
                  onClick={onBatchSend}
                  sx={{ width: '300px' }}
                >
                  Send report to{' '}
                  {!isNil(firstSelectedCustomerOption)
                    ? `selected ${pluralize('contact', numSelectedCustomerOptions)}`
                    : 'all contacts'}
                </Button>
                <Typography
                  variant="body2"
                  color={theme.palette.unassignedColor.main}
                >
                  {'Emails are sent to each Contact > General > Email'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
    </PalletModal>
  );
};

export default DunningModal;
