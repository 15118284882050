import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { pdfjs } from 'react-pdf';
import { WarehouseLocationsLocationFragment } from '../../../generated/graphql';
import WarehouseLocationLabelsPdf from '../components/warehouse-locations/warehouse-location-labels-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface WarehouseLocationLabelsModalProps {
  open: boolean;

  onClose: () => void;

  warehouseLocations: WarehouseLocationsLocationFragment[];
}

const WarehouseLocationLabelsModal = ({
  open,
  onClose,
  warehouseLocations,
}: WarehouseLocationLabelsModalProps) => {
  const warehouseLocationLabelsPdf = (
    <WarehouseLocationLabelsPdf warehouseLocations={warehouseLocations} />
  );
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Warehouse Location Labels
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
              {warehouseLocationLabelsPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink
                document={warehouseLocationLabelsPdf}
                fileName="warehouse-locations.pdf"
              >
                {({ loading }) =>
                  loading === true ? 'Loading...' : 'Download'
                }
              </PDFDownloadLink>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WarehouseLocationLabelsModal;
