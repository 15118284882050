import { buildBooleanDifferenceBlockComponent } from '../audit-log-utils';

type BooleanDifferenceBlockProps = {
  newValue: boolean | null | undefined;
  oldValue: boolean | null | undefined;
  isOldValueBeingDisplayed: boolean;
  wasEntireEntityDeleted: boolean;
  wasEntireEntityAdded: boolean;
};
const BooleanDifferenceBlock = ({
  oldValue,
  newValue,
  isOldValueBeingDisplayed,
  wasEntireEntityDeleted,
  wasEntireEntityAdded,
}: BooleanDifferenceBlockProps) => {
  return buildBooleanDifferenceBlockComponent(
    oldValue,
    newValue,
    isOldValueBeingDisplayed,
    wasEntireEntityDeleted,
    wasEntireEntityAdded,
    14,
  );
};

export default BooleanDifferenceBlock;
