import { isNil } from 'lodash';
import { useMemo } from 'react';
import type { PackageSpecEntity } from '../../../../../generated/graphql';

export type PartialPackageSpec = Pick<PackageSpecEntity, 'id' | 'name'>;

type PackageSpecOptions = {
  packageSpecOptions: Array<PackageSpecEntity | PartialPackageSpec>;
  selectedPackageSpecOption: PartialPackageSpec | undefined;
};

/**
 * This hook wraps a useMemo that computes the package spec options we should display for a contact and the selected
 * option, which is guaranteed to be referentially equal to one of the returned options.
 */
export const usePackageSpecOptions = ({
  allPackageSpecs,
  contactPackageSpecs,
  selectedPackageSpecId,
}: {
  // A list of all package specs, including archived ones. Pass in null while loading.
  allPackageSpecs: PackageSpecEntity[] | null;
  // The package specs available to the contact. This can be identical to allPackageSpecs.
  contactPackageSpecs: PackageSpecEntity[] | null;
  selectedPackageSpecId: string | null | undefined;
}): PackageSpecOptions =>
  useMemo<PackageSpecOptions>(() => {
    const packageSpecOptions: Array<PackageSpecEntity | PartialPackageSpec> = [
      // Duplicate the array.
      ...(contactPackageSpecs ?? []),
    ];

    let selectedPackageSpecOption: PartialPackageSpec | undefined = isNil(
      selectedPackageSpecId,
    )
      ? undefined
      : packageSpecOptions.find(({ id }) => id === selectedPackageSpecId);

    if (
      !isNil(allPackageSpecs) &&
      !isNil(selectedPackageSpecId) &&
      isNil(selectedPackageSpecOption)
    ) {
      // The selected package spec may have been archived. We still need to show it in the dropdown.
      const inactiveSelectedPackageSpec = allPackageSpecs.find(
        ({ id }) => id === selectedPackageSpecId,
      );
      if (isNil(inactiveSelectedPackageSpec)) {
        // The selected package spec may have been deleted.
        selectedPackageSpecOption = {
          id: selectedPackageSpecId,
          name: 'Unknown',
        };
        packageSpecOptions.push(selectedPackageSpecOption);
      } else {
        selectedPackageSpecOption = inactiveSelectedPackageSpec;
        packageSpecOptions.push(inactiveSelectedPackageSpec);
      }
    }

    return {
      packageSpecOptions,
      selectedPackageSpecOption,
    };
  }, [allPackageSpecs, contactPackageSpecs, selectedPackageSpecId]);
