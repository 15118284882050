// These constants aren't carrying much weight here -- they exist solely to
// make it more difficult to accidentially introduce diversion in behavior
// between different components (multiple of which depend on the inbound stop
// being at index 0 and the outbound stop being at index 1).

import { PickupOrDelivery } from '../../../../../generated/graphql';

import { StopType } from '../forms/stop-type';

export const INBOUND_STOP_IDX = 0;
export const OUTBOUND_STOP_IDX = 1;
export const MAX_LABEL_QUANTITY = 30;

export const INBOUND_STOP_TYPES = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
];

export const INBOUND_PICKUP_OR_DELIVERIES = [
  PickupOrDelivery.Pickup,
  PickupOrDelivery.Recovery,
];

export const OUTBOUND_PICKUP_OR_DELIVERIES = [
  PickupOrDelivery.Delivery,
  PickupOrDelivery.Transfer,
];

export const OUTBOUND_STOP_TYPES = Object.values(StopType).filter(
  (stopType) => !INBOUND_STOP_TYPES.includes(stopType),
);

export const PARTNER_CARRIER_STOP_TYPES = [
  StopType.PartnerCarrierDropoff,
  StopType.PartnerCarrierPickup,
];

export const REGULAR_STOP_TYPES = Object.values(StopType).filter(
  (stopType) => !PARTNER_CARRIER_STOP_TYPES.includes(stopType),
);

export const getOtherStopIdx = (stopIdx: number) => {
  return stopIdx === INBOUND_STOP_IDX ? OUTBOUND_STOP_IDX : INBOUND_STOP_IDX;
};
