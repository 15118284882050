import { CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import AddContactAccessorial from '../domains/contacts/components/add-contact-accessorial';
import Contact from '../domains/contacts/components/contact-page';
import ContactPageMode from '../domains/contacts/components/contact-page-mode';
import useContact from '../domains/contacts/hooks/use-contact';
import Accessorial from '../domains/management/components/accessorials/accessorial';

type EditContactProps = {
  contactUuid: string;
  initialMode: ContactPageMode;
};

const EditContact: FunctionComponent<EditContactProps> = ({
  contactUuid,
  initialMode,
}) => {
  const { loading } = useContact({ uuid: contactUuid });
  if (loading) {
    return <CircularProgress />;
  }
  return <Contact uuid={contactUuid} initialMode={initialMode} />;
};

export const ContactPage = () => {
  const { contactUuid } = useParams();
  const [searchParams] = useSearchParams();
  const edit = searchParams.get('edit');
  const initialMode =
    edit === 'true' ? ContactPageMode.EDIT : ContactPageMode.VIEW;
  if (isNotNilOrEmptyString(contactUuid)) {
    return <EditContact contactUuid={contactUuid} initialMode={initialMode} />;
  }
  return null;
};

export const ContactAccessorialPage = () => {
  const { accessorialUuid } = useParams();
  const [searchParams] = useSearchParams();
  const contactUuid = searchParams.get('contactUuid');
  if (isNotNilOrEmptyString(accessorialUuid)) {
    return <Accessorial uuid={accessorialUuid} contactUuid={contactUuid} />;
  }
  return null;
};

export const AddContactAccessorialPage = () => {
  const [searchParams] = useSearchParams();
  const contactUuid = searchParams.get('contactUuid');
  if (isNotNilOrEmptyString(contactUuid)) {
    return <AddContactAccessorial contactUuid={contactUuid} />;
  }
  return null;
};
