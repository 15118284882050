import { FormGroup, Slider, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  convertCardWidthToPercentage,
  convertPercentageToCardWidth,
} from '../utils';

const RouteCardWidthSlider = ({
  routeCardWidth,
  onChange,
  onBlur,
}: {
  routeCardWidth: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
}) => {
  return (
    <Stack>
      <FormGroup sx={{ width: '100%' }}>
        <Typography sx={{ fontSize: '14px' }}>Route Card Width</Typography>
        <Slider
          size="small"
          step={2}
          value={convertCardWidthToPercentage(routeCardWidth)}
          onChangeCommitted={onBlur}
          onChange={(e, newValue) => {
            if (typeof newValue === 'number') {
              onChange(convertPercentageToCardWidth(newValue));
            }
          }}
        />
      </FormGroup>
    </Stack>
  );
};

export { RouteCardWidthSlider };
