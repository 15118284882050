import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import CommentInput from '../../../common/components/comments/order-comments/comment-input';
import CommentsList from '../../../common/components/comments/order-comments/comments-list';
import { FeatureFlag } from '../../../common/feature-flags';
import { useAvailableTariffTypes } from '../../../common/react-hooks/use-available-tariff-types';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import { useTariffChains } from '../../../common/react-hooks/use-tariff-chains';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactCommentEntity,
  ContactsSimpleDocument,
  PermissionResource,
  ReferenceNumberFormatCheckEntity,
  ReferenceNumberFormatCheckType,
  TariffChainFragment,
  useBusinessDivisionsQuery,
  useCreateContactCommentMutation,
  useCreateCustomerContactMutation,
  useDeleteContactCommentMutation,
  useReferenceNumberFormatConfigsQuery,
  useUpdateContactCommentMutation,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ArchiveActionComponent, {
  ArchiveableEntity,
} from '../../management/components/common/archive-action-component';
import { OrderFormEditAccessProvider } from '../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../orders/components/order-form/forms/use-order-form-edit-access';
import {
  selectContactErrorsById,
  upsertOneContactErrors,
} from '../redux/contact-errors-slice';
import {
  ContactValues,
  selectContactValuesById,
  updateOneContactValues,
} from '../redux/contact-values-slice';
import {
  createCustomerContactCreateInput,
  createCustomerContactUpdateInput,
  validateContact,
} from '../redux/contact-values-thunks';
import ContactPackageTypesField from './contact-package-types-field';
import ContactPageMode from './contact-page-mode';
import ContactServiceLevelField from './contact-service-level-field';
import ContactVehicleTypesField from './contact-vehicle-types-field';
import DefaultAddressAutocomplete from './default-address-autocomplete';
import DefaultAddressForm from './default-address-form';
import DefaultContactForm from './default-contact-form';
import DefaultContactPersonAutocomplete from './default-contact-person-autocomplete';
import DefaultContactService from './default-contact-service';
import LengthCheckCondition from './reference-number-format-configs/length-check-condition';
import StartsWithCheckConditions from './reference-number-format-configs/starts-with-check-conditions';
import ReferenceNumberLabels from './reference-number-labels';

const styles = {
  buttonBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 2,
    mb: 2,
    px: 1,
    py: 1,
  } as SxProps,
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 2,
    py: 2,
    width: '100%',
    height: '100%',
    gap: 2,
  } as SxProps,
  flexRowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 2,
    my: 1.5,
    width: '100%',
  } as SxProps,
  topLevelBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
    height: '100%',
  } as SxProps,
  // Use flex grow / basis to center items of different sizes in a row
  unequalRowItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexBasis: 0,
  } as SxProps,
};

type DetailsPanelProps = {
  contactUuid: string;
  mode: ContactPageMode;
  handleChangeMode: (mode: ContactPageMode) => void;
};

function DetailsPanel(props: DetailsPanelProps) {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mode, handleChangeMode, contactUuid } = props;
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const contact = useAppSelector((state) =>
    selectContactValuesById(state, contactUuid),
  );
  const [contactErrorMessage, setContactErrorMessage] = useState<string | null>(
    null,
  );
  const contactErrors = useAppSelector((state) =>
    selectContactErrorsById(state, contactUuid),
  );
  const { data: referenceNumberFormatConfigsData } =
    useReferenceNumberFormatConfigsQuery({
      variables: { contactUuid },
    });

  const referenceNumberStartsWithFormatCheck = useMemo(() => {
    const referenceNumberFormatConfig =
      referenceNumberFormatConfigsData?.referenceNumberFormatConfigs?.at(0);
    if (
      isNil(referenceNumberFormatConfig) ||
      isEmpty(referenceNumberFormatConfig)
    ) {
      return [];
    }
    return referenceNumberFormatConfig?.checks?.find(
      (check) => check.type === ReferenceNumberFormatCheckType.StartsWith,
    );
  }, [referenceNumberFormatConfigsData]);

  const referenceNumberLengthFormatCheck = useMemo(() => {
    const referenceNumberFormatConfig =
      referenceNumberFormatConfigsData?.referenceNumberFormatConfigs?.at(0);
    if (
      isNil(referenceNumberFormatConfig) ||
      isEmpty(referenceNumberFormatConfig)
    ) {
      return [];
    }
    return (referenceNumberFormatConfig?.checks ?? []).find(
      (check) => check.type === ReferenceNumberFormatCheckType.Length,
    );
  }, [referenceNumberFormatConfigsData]);

  const { data: businessDivisionsData } = useBusinessDivisionsQuery();
  const businessDivisions =
    businessDivisionsData?.businessDivisions?.businessDivisions ?? [];

  const [createCustomerContactMutation] = useCreateCustomerContactMutation({
    onError: (error) => {
      setContactErrorMessage(error.message);
    },
  });
  const [updateCustomerContactMutation] = useUpdateCustomerContactMutation({
    onError: (error) => {
      setContactErrorMessage(error.message);
    },
  });

  const [createContactComment] = useCreateContactCommentMutation({
    refetchQueries: [ContactsSimpleDocument],
  });
  const [updateContactComment] = useUpdateContactCommentMutation({
    refetchQueries: [ContactsSimpleDocument],
  });
  const [deleteContactComment] = useDeleteContactCommentMutation();

  const [newComment, setNewComment] = useState('');
  const [commentList, setCommentList] = useState(
    contact?.contactComments ?? [],
  );
  const { userUuid } = useMe();

  const {
    ordinaryTariffChains,
    lineHaulTariffChains,
    transferTariffChains,
    pointToPointTariffChains,
  } = useTariffChains();

  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  if (isNil(contact)) {
    return <CircularProgress />;
  }

  const onEditContactComment = async (
    commentUuid: string,
    newOrderComment: string,
  ) => {
    const showUpdateContactCommentErrorMessage = () => {
      setErrorMessage(`Error updating order comment`);
      setShowErrorMessage(true);
    };

    if (isNil(commentUuid) || isNilOrEmptyString(commentUuid)) {
      showUpdateContactCommentErrorMessage();
      return;
    }

    try {
      await updateContactComment({
        variables: {
          updateContactCommentInput: {
            uuid: commentUuid,
            comment: newOrderComment,
          },
        },
      });
      const newCommentList = commentList.map((comment) => {
        if (comment?.uuid === commentUuid) {
          return {
            ...comment,
            comment: newOrderComment,
          };
        }

        return comment;
      });
      setCommentList(newCommentList);
      setSuccessMessage('Successfully updated contact comment');
      setShowSuccessMessage(true);
    } catch (e) {
      showUpdateContactCommentErrorMessage();
    }
  };

  const onDeleteContactComment = async (commentUuid: string) => {
    const showDeleteOrderCommentErrorMessage = () => {
      setErrorMessage(`Error deleting contact comment`);
      setShowErrorMessage(true);
    };

    if (isNil(commentUuid) || isNilOrEmptyString(commentUuid)) {
      showDeleteOrderCommentErrorMessage();
      return;
    }

    try {
      const deleteContactCommentResponse = await deleteContactComment({
        variables: {
          deleteContactCommentInput: {
            uuid: commentUuid,
          },
        },
      });
      if (
        deleteContactCommentResponse.data?.deleteContactComment.__typename ===
        'DeleteContactCommentSuccessOutput'
      ) {
        const updatedComments = commentList.filter(
          (comment) => comment?.uuid !== commentUuid,
        );
        setCommentList(updatedComments);
        setSuccessMessage('Successfully deleted contact comment');
        setShowSuccessMessage(true);
      } else {
        showDeleteOrderCommentErrorMessage();
      }
    } catch (e) {
      showDeleteOrderCommentErrorMessage();
    }
  };

  const updateValues = (
    fieldName: keyof ContactValues,
    value: ContactValues[typeof fieldName],
  ) => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: { [fieldName]: value },
      }),
    );
  };

  const clearContactError = (fieldName: keyof ContactValues) => {
    dispatch(
      upsertOneContactErrors({
        uuid: contactUuid,
        [fieldName]: undefined,
      }),
    );
  };

  /**
   * onClick handler for creating a new contact or saving changes to an existing
   */
  const handleSave = async ({ isActive = true }: { isActive?: boolean }) => {
    const isValid = await dispatch(
      validateContact({
        contactUuid,
        mode,
      }),
    ).unwrap();
    if (!isValid) {
      return;
    }
    setSaveButtonDisabled(true);
    if (mode === ContactPageMode.CREATE) {
      const customerContactCreateInput = await dispatch(
        createCustomerContactCreateInput({ contactUuid }),
      ).unwrap();
      try {
        setContactErrorMessage(null);
        const { data } = await createCustomerContactMutation({
          variables: { input: { customerContactCreateInput } },
        });
        if (!isNil(data)) {
          navigate(`/contacts/${data.createCustomerContact.uuid}`);
        }
      } finally {
        setSaveButtonDisabled(false);
      }
    } else if (mode === ContactPageMode.EDIT) {
      const customerContactUpdateInput = await dispatch(
        createCustomerContactUpdateInput({ contactUuid }),
      ).unwrap();
      try {
        setContactErrorMessage(null);
        await updateCustomerContactMutation({
          variables: {
            input: {
              customerContactUpdateInput: {
                ...customerContactUpdateInput,
                isActive,
              },
            },
          },
        });
        handleChangeMode(ContactPageMode.VIEW);
      } finally {
        setSaveButtonDisabled(false);
      }
    }
  };

  const onCreateComment = async () => {
    if (isNil(userUuid) || isNil(contact?.uuid) || isEmpty(newComment.trim())) {
      return;
    }

    const res = await createContactComment({
      variables: {
        createContactCommentInput: {
          contactUuid: contact.uuid,
          comment: newComment,
          userUuid,
        },
      },
    });

    const createdComment: ContactCommentEntity = {
      uuid: res.data?.createContactComment.uuid ?? '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      comment: newComment,
      authorName: '',
    };

    updateValues('contactComments', [
      createdComment,
      ...(contact?.contactComments ?? []),
    ]);

    setCommentList([createdComment, ...commentList]);
    setNewComment('');
  };

  const dropdownComponentForType = ({
    label,
    name,
    tariffChains,
  }: {
    label: string;
    name:
      | 'defaultOrdinaryTariffChainId'
      | 'defaultTransferTariffChainId'
      | 'defaultLineHaulTariffChainId'
      | 'defaultPointToPointTariffChainId';
    tariffChains: TariffChainFragment[];
  }) => (
    <Grid item xs={3} padding={0}>
      <TextField
        size="small"
        disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
        label={label}
        value={contact[name]}
        onChange={(e) => {
          updateValues(name, e.target.value === '' ? null : e.target.value);
        }}
        fullWidth
        select
      >
        {tariffChains?.map((tariffChain) => (
          <MenuItem key={tariffChain.id} value={tariffChain.id}>
            {tariffChain.name}
          </MenuItem>
        ))}
        <MenuItem value="">None</MenuItem>
      </TextField>
    </Grid>
  );

  const tariffChainDropdowns: ReactNode[] = [];

  if (ordinaryTariffGroupAvailable && !isNil(ordinaryTariffChains)) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Ordinary Tariff Chain',
        name: 'defaultOrdinaryTariffChainId',
        tariffChains: ordinaryTariffChains,
      }),
    );
  }

  if (lineHaulTariffGroupAvailable && !isNil(lineHaulTariffChains)) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Line-Haul Tariff Chain',
        name: 'defaultLineHaulTariffChainId',
        tariffChains: lineHaulTariffChains,
      }),
    );
  }

  if (transferTariffGroupAvailable && !isNil(transferTariffChains)) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Transfer Tariff Chain',
        name: 'defaultTransferTariffChainId',
        tariffChains: transferTariffChains,
      }),
    );
  }

  if (pointToPointTariffGroupAvailable && !isNil(pointToPointTariffChains)) {
    tariffChainDropdowns.push(
      dropdownComponentForType({
        label: 'Default Point-to-Point Tariff Chain',
        name: 'defaultPointToPointTariffChainId',
        tariffChains: pointToPointTariffChains,
      }),
    );
  }

  const buttons = [
    <Button key="back" onClick={async () => navigate('/contacts')}>
      Back
    </Button>,
    mode === ContactPageMode.EDIT && (
      <Button
        disabled={saveButtonDisabled || !canWriteContacts}
        key="save"
        onClick={() => handleSave({})}
        variant="contained"
      >
        Save changes
      </Button>
    ),
    mode === ContactPageMode.CREATE && (
      <Button
        disabled={saveButtonDisabled || !canWriteContacts}
        key="create"
        onClick={() => handleSave({})}
        variant="contained"
      >
        Create customer
      </Button>
    ),
    mode === ContactPageMode.VIEW && (
      <Button
        key="edit"
        onClick={() => handleChangeMode(ContactPageMode.EDIT)}
        variant="contained"
        disabled={!canWriteContacts}
      >
        Edit
      </Button>
    ),
  ];
  return (
    <Box sx={styles.topLevelBox}>
      <Card sx={styles.buttonBox}>{buttons}</Card>
      {!isNil(contactErrorMessage) && (
        <Alert severity="error" sx={{ my: 1 }}>
          {contactErrorMessage}
        </Alert>
      )}
      <Grid container direction="row" gap={2} height="100%">
        <Grid display="flex" flex="2" direction="column" gap={2}>
          <Card sx={styles.card}>
            <Typography variant="h6">General</Typography>
            <Box sx={styles.flexRowBox}>
              <Box sx={styles.unequalRowItem}>
                <TextField
                  disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                  error={!isNil(contactErrors?.displayName)}
                  helperText={contactErrors?.displayName}
                  label="Name"
                  onChange={(event) => {
                    if (
                      event.target.value.length > 0 &&
                      !isNil(contactErrors?.displayName)
                    ) {
                      clearContactError('displayName');
                    }
                    updateValues('displayName', event.target.value);
                  }}
                  required
                  value={contact.displayName ?? ''}
                  sx={{
                    width: '300px',
                  }}
                />
              </Box>
              <Box sx={styles.unequalRowItem}>
                <TextField
                  disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                  error={!isNil(contactErrors?.billingContactEmail)}
                  helperText={contactErrors?.billingContactEmail}
                  required
                  label="Email"
                  onChange={(event) => {
                    if (
                      event.target.value.length > 0 &&
                      !isNil(contactErrors?.billingContactEmail)
                    ) {
                      clearContactError('billingContactEmail');
                    }
                    updateValues('billingContactEmail', event.target.value);
                  }}
                  value={contact.billingContactEmail ?? ''}
                />
              </Box>
              <Box sx={styles.unequalRowItem}>
                <TextField
                  disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                  label="Account ID"
                  onChange={(event) => {
                    updateValues('contactReferenceNumber', event.target.value);
                  }}
                  value={contact.contactReferenceNumber ?? ''}
                />
              </Box>
              <Box sx={styles.unequalRowItem}>
                <TextField
                  disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                  label="Invoice/order identifier"
                  onChange={(event) => {
                    if (
                      event.target.value.length > 0 &&
                      !isNil(contactErrors?.identifier)
                    ) {
                      clearContactError('identifier');
                    }
                    updateValues('identifier', event.target.value);
                  }}
                  value={contact.identifier ?? ''}
                  inputProps={{ maxLength: 6 }}
                  error={!isNil(contactErrors?.identifier)}
                  helperText={contactErrors?.identifier}
                />
              </Box>
              <Box
                sx={{
                  ...styles.unequalRowItem,
                }}
              >
                <FormControl sx={{ width: 150 }}>
                  <InputLabel>Business Division</InputLabel>
                  <Select
                    id="business-division-select"
                    label="Business Division"
                    disabled={
                      mode === ContactPageMode.VIEW || !canWriteContacts
                    }
                    onChange={(event) => {
                      updateValues(
                        'businessDivisionUuid',
                        event.target.value === 'None'
                          ? null
                          : event.target.value,
                      );
                    }}
                    value={contact?.businessDivisionUuid ?? 'None'}
                    sx={{ width: '100%' }}
                  >
                    <MenuItem key="Business-division-None" value="None">
                      None
                    </MenuItem>
                    {businessDivisions.map((businessDivision) => {
                      return (
                        <MenuItem
                          key={businessDivision.uuid}
                          value={businessDivision.uuid}
                        >
                          {businessDivision.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid flex="1" gridColumn="span 2">
          <Card sx={styles.card}>
            <Typography variant="h6">Notes</Typography>
            <Box width="100%">
              <CommentInput
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onSubmit={onCreateComment}
              />
              <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
                <CommentsList
                  comments={commentList}
                  maxHeight="325px"
                  onEditComment={onEditContactComment}
                  onDeleteComment={onDeleteContactComment}
                />
              </OrderFormEditAccessProvider>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Card sx={styles.card}>
        <Typography variant="h6">Default location</Typography>
        {!isNil(contact.defaultAddressUuid) &&
          mode === ContactPageMode.CREATE && (
            <DefaultAddressForm
              addressUuid={contact.defaultAddressUuid}
              mode={mode}
            />
          )}
        {mode !== ContactPageMode.CREATE && (
          <DefaultAddressAutocomplete contactUuid={contactUuid} mode={mode} />
        )}
      </Card>
      <Card sx={styles.card}>
        <Typography variant="h6">Service levels</Typography>
        <Stack
          direction="row"
          spacing={2}
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <Stack spacing={1} width="50%" alignItems="center">
            <Typography>Customer service levels:</Typography>
            <ContactServiceLevelField
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              contactUuid={contact.uuid}
              serviceUuids={contact.serviceUuids}
            />
            <Typography>Default service level:</Typography>
            <DefaultContactService
              label="Default service level"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              serviceUuid={contact.defaultServiceUuid}
              handleChange={(event: SelectChangeEvent) => {
                dispatch(
                  updateOneContactValues({
                    id: contactUuid,
                    changes: {
                      defaultServiceUuid: event.target.value,
                    },
                  }),
                );
              }}
            />
          </Stack>
          <Stack spacing={1} width="50%" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
              <Typography sx={{ color: 'black', fontWeight: 'bold', mr: 1 }}>
                EDI defaults:
              </Typography>
              <Typography variant="body2" sx={{ color: 'gray' }}>
                Default service level to use for EDI orders
              </Typography>
            </Box>
            <Typography>Inbound Stop Only</Typography>
            <DefaultContactService
              label="Default inbound service level"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              serviceUuid={contact.defaultInboundEdiServiceUuid}
              handleChange={(event: SelectChangeEvent) => {
                dispatch(
                  updateOneContactValues({
                    id: contactUuid,
                    changes: {
                      defaultInboundEdiServiceUuid: event.target.value,
                    },
                  }),
                );
              }}
            />
            <Typography>Outbound Stop Only</Typography>
            <DefaultContactService
              label="Default outbound service level"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              serviceUuid={contact.defaultOutboundEdiServiceUuid}
              handleChange={(event: SelectChangeEvent) => {
                dispatch(
                  updateOneContactValues({
                    id: contactUuid,
                    changes: {
                      defaultOutboundEdiServiceUuid: event.target.value,
                    },
                  }),
                );
              }}
            />
            <Typography>Inbound and Outbound Stop</Typography>
            <DefaultContactService
              label="Default inbound/outbound service level"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              serviceUuid={contact.defaultInboundOutboundEdiServiceUuid}
              handleChange={(event: SelectChangeEvent) => {
                dispatch(
                  updateOneContactValues({
                    id: contactUuid,
                    changes: {
                      defaultInboundOutboundEdiServiceUuid: event.target.value,
                    },
                  }),
                );
              }}
            />
          </Stack>
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Typography variant="h6">Vehicle types</Typography>
        <Box sx={styles.unequalRowItem}>
          <Checkbox
            disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
            checked={contact.useCustomVehicleTypes}
            onChange={(event) => {
              updateValues('useCustomVehicleTypes', event.target.checked);
            }}
          />
          <Typography>Customize available vehicle types</Typography>
        </Box>
        <ContactVehicleTypesField
          disabled={
            mode === ContactPageMode.VIEW ||
            !canWriteContacts ||
            !contact.useCustomVehicleTypes
          }
          contactUuid={contact.uuid}
          vehicleTypeUuids={contact.vehicleTypeUuids}
        />
      </Card>
      {ffPackageSpecs && (
        <Card sx={styles.card}>
          <Typography variant="h6">Package types</Typography>
          <Box sx={styles.unequalRowItem}>
            <Checkbox
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              checked={contact.useCustomPackageSpecs}
              onChange={(event) => {
                updateValues('useCustomPackageSpecs', event.target.checked);
              }}
            />
            <Typography>Customize available package types</Typography>
          </Box>
          <ContactPackageTypesField
            disabled={
              mode === ContactPageMode.VIEW ||
              !canWriteContacts ||
              !contact.useCustomPackageSpecs
            }
            contactUuid={contact.uuid}
            packageSpecIds={contact.packageSpecIds}
          />
        </Card>
      )}
      <Card sx={styles.card}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h6">Billing</Typography>
          <Stack direction="row" spacing={6}>
            <TextField
              size="small"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              label="Default fuel surcharge"
              type="number"
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              value={contact.defaultFuelSurcharge}
              onChange={(e) => {
                const parsed = Number.parseFloat(e.target.value);
                if (!Number.isNaN(parsed)) {
                  updateValues('defaultFuelSurcharge', parsed);
                } else {
                  updateValues('defaultFuelSurcharge', null);
                }
              }}
            />
            <TextField
              size="small"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              label="Default dim factor"
              inputProps={{ pattern: '[0-9]*' }}
              value={contact.defaultDimFactor}
              onChange={(e) => {
                const parsed = Number.parseFloat(e.target.value);
                if (!Number.isNaN(parsed)) {
                  updateValues('defaultDimFactor', parsed);
                } else {
                  updateValues('defaultDimFactor', null);
                }
              }}
            />
            <Box sx={styles.unequalRowItem}>
              <Checkbox
                disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                checked={contact.defaultUseTariff ?? false}
                onChange={(event) => {
                  updateValues('defaultUseTariff', event.target.checked);
                }}
              />
              <Typography>Default to tariff</Typography>
            </Box>
            <Box sx={styles.unequalRowItem}>
              <Checkbox
                disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                checked={contact.isPrepaidOnly}
                onChange={(event) => {
                  updateValues('isPrepaidOnly', event.target.checked);
                }}
              />
              <Typography>Prepaid only</Typography>
            </Box>
          </Stack>
        </Stack>
      </Card>{' '}
      <Card sx={styles.card}>
        <Stack direction="column" alignItems="center" spacing={1} width="100%">
          <Typography variant="h6">Rating</Typography>
          <Grid container columnSpacing={6}>
            {tariffChainDropdowns}
          </Grid>
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h6">EDI</Typography>
          <Stack direction="row" spacing={6}>
            <TextField
              size="small"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              label="ISA ID"
              value={contact.isaId}
              onChange={(e) => {
                updateValues('isaId', e.target.value);
              }}
            />
            <TextField
              size="small"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              label="Application (GS) ID"
              value={contact.ediApplicationId}
              onChange={(e) => {
                updateValues('ediApplicationId', e.target.value);
              }}
            />
            <TextField
              size="small"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              label="Stedi partner ID"
              value={contact.stediPartnerId}
              onChange={(e) => {
                updateValues('stediPartnerId', e.target.value);
              }}
            />
            <TextField
              size="small"
              disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
              label="EDI contact code"
              value={contact.ediContactCode}
              onChange={(e) => {
                updateValues('ediContactCode', e.target.value);
              }}
            />
            <Box sx={styles.unequalRowItem}>
              <Checkbox
                disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
                checked={contact.showEdiReferenceNumbersOnOrderPage}
                onChange={(event) => {
                  updateValues(
                    'showEdiReferenceNumbersOnOrderPage',
                    event.target.checked,
                  );
                }}
              />
              <Typography>Show EDI reference numbers</Typography>
            </Box>
          </Stack>
        </Stack>
      </Card>
      <Card sx={styles.card}>
        <Typography variant="h6">Default contact</Typography>
        {!isNil(contact.defaultContactPersonUuid) &&
          mode === ContactPageMode.CREATE && (
            <DefaultContactForm
              contactPersonUuid={contact.defaultContactPersonUuid}
              mode={mode}
            />
          )}
        {mode !== ContactPageMode.CREATE && (
          <DefaultContactPersonAutocomplete
            contactUuid={contactUuid}
            mode={mode}
          />
        )}
      </Card>
      <Card sx={styles.card}>
        <Typography variant="h6">
          Bill of Lading Number Format Config
        </Typography>
        {!isNil(referenceNumberStartsWithFormatCheck) &&
          !isEmpty(referenceNumberStartsWithFormatCheck) && (
            <StartsWithCheckConditions
              check={
                referenceNumberStartsWithFormatCheck as ReferenceNumberFormatCheckEntity
              }
            />
          )}
        {!isNil(referenceNumberLengthFormatCheck) &&
          !isEmpty(referenceNumberLengthFormatCheck) && (
            <LengthCheckCondition
              check={
                referenceNumberLengthFormatCheck as ReferenceNumberFormatCheckEntity
              }
            />
          )}
        <Button variant="outlined" disabled>
          Add Condition
        </Button>
      </Card>
      <Card sx={styles.card}>
        <Typography variant="h6">Reference Numbers</Typography>
        <ReferenceNumberLabels
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          contactUuid={contact.uuid}
          referenceNumberLabels={contact.referenceNumberLabels}
        />
      </Card>
      {mode === ContactPageMode.EDIT && canWriteContacts && (
        <Card sx={styles.card}>
          <ArchiveActionComponent
            entityType={ArchiveableEntity.CUSTOMER}
            isActive={contact?.isActive ?? true}
            handleArchive={async () => {
              await handleSave({ isActive: false });
              navigate('/contacts');
            }}
            handleUnarchive={async () => {
              await handleSave({ isActive: true });
              navigate('/contacts');
            }}
          />
        </Card>
      )}
    </Box>
  );
}

export default DetailsPanel;
