import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect } from 'react';
import useDocuments from '../../../../common/react-hooks/use-documents';
import { DocumentType } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

const DocumentTypeSequenceEditor = ({
  documentTypes,
  setDocumentTypes,
  forceRerender,
  setForceRerender,
}: {
  documentTypes: (DocumentType | undefined)[] | undefined;
  setDocumentTypes: Dispatch<
    SetStateAction<(DocumentType | undefined)[] | undefined>
  >;
  /*
   * need the force rerender because I encountered a behavior where if you don't unrender and then re-render the mui autocomplete component
   * upon changing the value of the options, they will render as blank
   * */
  forceRerender?: boolean;
  setForceRerender?: Dispatch<SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    if (forceRerender === true && !isNil(setForceRerender)) {
      const timeout = setTimeout(() => setForceRerender(false), 10);
      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRerender]);

  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  if (loading) {
    return null;
  }

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              1
            </TableCell>
            <TableCell colSpan={2}>Cover page</TableCell>
          </TableRow>
          {documentTypes?.map((documentType, idx) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              key={documentType}
            >
              <TableCell component="th" scope="row" sx={{ width: '50px' }}>
                {idx + 2}
              </TableCell>
              <TableCell>
                {forceRerender !== true && (
                  <AutocompleteFuzzy
                    size="small"
                    sx={{
                      '& input': {
                        fontSize: '14px',
                        width: '200px',
                      },
                      width: '270px',
                    }}
                    value={
                      !isNil(documentType)
                        ? {
                            label: getDocumentTypeCopy({
                              documentType,
                            }),
                            value: documentType,
                          }
                        : undefined
                    }
                    options={
                      documentTypesForSelection?.map((type) => ({
                        label: getDocumentTypeCopy({
                          documentType: type,
                        }),
                        value: type,
                      })) ?? []
                    }
                    matchSortOptions={{ keys: ['label'] }}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        variant="standard"
                        size="small"
                      />
                    )}
                    onChange={(event, option) => {
                      setDocumentTypes((prevState) => {
                        const newState = !isNil(prevState)
                          ? [...prevState]
                          : [];
                        newState[idx] = option?.value;
                        return newState;
                      });
                      event.stopPropagation();
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    setDocumentTypes((prevState) => {
                      const newState = !isNil(prevState) ? [...prevState] : [];
                      newState.splice(idx, 1);
                      return newState;
                    });
                  }}
                >
                  <CloseIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        onClick={() => {
          if (!isNil(documentTypes)) {
            setDocumentTypes([...documentTypes, undefined]);
          } else {
            setDocumentTypes([undefined]);
          }
        }}
      >
        + Add Document Type
      </Button>
    </>
  );
};

export default DocumentTypeSequenceEditor;
