import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import StyledDatePicker from '../../../domains/storage-orders/components/common/styled-date-picker';
import { useMarkOrderAsRefusedMutation } from '../../../generated/graphql';
import {
  MarkOrderAsRefusedFormValues,
  useMarkOrderAsRefusedForm,
} from '../../form/use-mark-refused-form';
import { DatePickerInput } from '../forms/date-picker-input';

const MarkOrderAsRefusedDialog = ({
  orderUuid,
  open,
  onClose,
}: {
  orderUuid: string;
  open: boolean;
  onClose: (data?: MarkOrderAsRefusedFormValues) => void;
}) => {
  const [markOrderAsRefused] = useMarkOrderAsRefusedMutation();

  const {
    form: {
      control,
      formState: { errors },
      handleSubmit,
    },
  } = useMarkOrderAsRefusedForm();

  const onSubmit: SubmitHandler<MarkOrderAsRefusedFormValues> = async (
    data,
  ) => {
    // TODO: Ensure we add success and error messages once FTO-308 is complete: https://linear.app/trypallet/issue/FTO-308/design-system-ensure-alert-components-follow-proper-api-guidelines
    if (isEmpty(errors)) {
      const response = await markOrderAsRefused({
        variables: {
          markOrderAsRefusedInput: {
            uuid: orderUuid,
            refusedBy: data.refusedBy,
            refusedDate: data.refusedDate,
          },
        },
      });
      if (
        response?.data?.markOrderAsRefused?.__typename ===
        'MarkOrderAsRefusedSuccessOutput'
      ) {
        onClose(data);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Mark order as refused</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
          <Controller
            name="refusedBy"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                name="refusedBy"
                size="small"
                label="Refused by"
                value={value}
                required
                onChange={onChange}
                error={!isNil(errors.refusedBy)}
                helperText={errors.refusedBy?.message}
                sx={{ width: '50%' }}
              />
            )}
          />
          <Stack>
            <Controller
              name="refusedDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledDatePicker
                  title="Date Refused"
                  onChange={(newDate) => {
                    onChange(
                      (newDate as DateObject)?.isValid
                        ? (newDate as DateObject).toDate()
                        : null,
                    );
                  }}
                  value={value}
                  plugins={[
                    <TimePicker
                      key="time-picker"
                      position="right"
                      hideSeconds
                      format="HH:mm"
                    />,
                  ]}
                  containerStyle={{ width: '35%' }}
                  calendarPosition="right"
                  hideOnScroll
                  render={<DatePickerInput placeholder="Refused Date" />}
                  name="Date Refused"
                  format="MM/DD/YYYY HH:mm"
                />
              )}
            />
            {!isNil(errors.refusedDate) && (
              <FormHelperText sx={{ color: '#B00020' }}>
                {errors.refusedDate?.message}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!isEmpty(errors)}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { MarkOrderAsRefusedDialog };
