import { View, Page, Document, Text, StyleSheet } from '@react-pdf/renderer';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { safeAdd } from 'shared/math';
import {
  NetSalesSummaryReportOutput,
  MeQuery,
} from '../../../../../generated/graphql';

const CELL_MARGIN = '10px';
const PAGE_BLOCK_SIZE = '300px';
const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '24 24 36 24',
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '24px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  tableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    backgroundColor: '#f0eded',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '10px',
    marginTop: 10,
  },
  defaultCol: {
    width: '10%',
    paddingRight: TEXT_PADDING,
  },
  customerCol: {
    width: '40%',
    paddingRight: TEXT_PADDING,
  },
  pcsCol: {
    width: '7%',
    paddingRight: TEXT_PADDING,
  },
  adjustmentsCol: {
    width: '13%',
    paddingRight: TEXT_PADDING,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontSize: '10px',
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '11px',
    fontWeight: 'bold',
    borderTop: '1px solid black',
    marginTop: 10,
  },
  footer: {
    position: 'absolute',
    fontSize: '10px',
    bottom: 24,
    left: 24,
    right: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'grey',
  },
});

interface GeneratedNetSalesReportProps {
  companyData: MeQuery | undefined;
  data: NetSalesSummaryReportOutput;
  dateString: string;
  terminalCode?: string;
}

const GeneratedNetSalesReport = ({
  companyData,
  data,
  dateString,
  terminalCode,
}: GeneratedNetSalesReportProps) => {
  const totalNetSalesInCents = data.contactRows.reduce(
    (acc, curr) => safeAdd(acc, curr.netSalesInCents),
    0,
  );

  return (
    <Document title="Net Sales Summary Report">
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={styles.header1}>Net Sales Summary</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View>
            <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: CELL_MARGIN,
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: PAGE_BLOCK_SIZE,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '10px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold', width: 50 }}>Date</Text>
                  <Text>{dateString}</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '10px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold', width: 50 }}>
                    Terminal
                  </Text>
                  <Text>{terminalCode ?? 'All'}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={styles.customerCol}>
              <Text>Customer</Text>
            </View>
            <View style={styles.pcsCol}>
              <Text>Pieces</Text>
            </View>
            <View style={styles.defaultCol}>
              <Text>Weight</Text>
            </View>
            <View style={styles.defaultCol}>
              <Text>Dim weight</Text>
            </View>
            <View style={styles.defaultCol}>
              <Text>Total</Text>
            </View>
            <View style={styles.adjustmentsCol}>
              <Text>Adjustments</Text>
            </View>
            <View style={styles.defaultCol}>
              <Text>Net sales</Text>
            </View>
          </View>
          {data.contactRows.map((row) => (
            <View key={row.contactUuid} style={styles.row}>
              <View style={styles.customerCol}>
                <Text>{row.contactName}</Text>
              </View>
              <View style={styles.pcsCol}>
                <Text>{row.pieces}</Text>
              </View>
              <View style={styles.defaultCol}>
                <Text>{row.weight}</Text>
              </View>
              <View style={styles.defaultCol}>
                <Text>{row.dimWeight}</Text>
              </View>
              <View style={styles.defaultCol}>
                <Text>
                  {currency(row.openInvoiesTotalInCents, {
                    fromCents: true,
                  }).format()}
                </Text>
              </View>
              <View style={styles.adjustmentsCol}>
                <Text>
                  {currency(row.adjustmentsInCents, {
                    fromCents: true,
                  }).format()}
                </Text>
              </View>
              <View style={styles.defaultCol}>
                <Text>
                  {currency(row.netSalesInCents, { fromCents: true }).format()}
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.totalRow}>
            <View>
              <Text>Total Net Sales</Text>
            </View>
            <View>
              <Text>
                {currency(totalNetSalesInCents, { fromCents: true }).format()}
              </Text>
            </View>
          </View>
        </View>
        <View fixed style={styles.footer}>
          <Text>{dayjs().format('MM/DD/YY')}</Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedNetSalesReport;
