import { isString } from 'lodash';
import { useCallback, useState } from 'react';
import type { MutationErrorOutput } from '../../generated/graphql';

type Errors = {
  errors: string[];
  // Add an error to the list of errors. This function automatically de-duplicates errors.
  onError: (error: Error | MutationErrorOutput | string) => void;
  clearErrors: () => void;
};

/**
 * A state container for a list of error messages with some higher-level update functions.
 */
export const useErrors = (): Errors => {
  const [errors, setErrors] = useState<string[]>([]);
  const onError = useCallback<Errors['onError']>((error) => {
    setErrors((prevErrors) => {
      const message = isString(error) ? error : error.message;
      return prevErrors.includes(message)
        ? prevErrors
        : [...prevErrors, message];
    });
  }, []);
  const clearErrors = useCallback<Errors['clearErrors']>(() => {
    setErrors([]);
  }, []);

  return { errors, onError, clearErrors };
};
