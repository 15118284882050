import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { getDriverName } from '../../../common/utils/utils';
import { useDriversForSelectionQuery } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { DriverVehicleSelectionRow } from './driver-vehicle-selection-row';
import useGenerateRoutesStore from './use-generate-routes-store';

const DriverVehicleSelection = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedTerminalUuid] = useGlobalStore(
    (state) => [state.selectedTerminalUuid],
    shallow,
  );
  const [
    getDriverEquipmentConstraintByDriverUuid,
    upsertDriverEquipmentConstraint,
    allSelected,
  ] = useGenerateRoutesStore(
    (state) => [
      state.getDriverEquipmentConstraintByDriverUuid,
      state.upsertDriverEquipmentConstraint,
      state.driverEquipmentConstraints.every(
        (constraint) => constraint.active === true,
      ),
    ],
    shallow,
  );
  const { data: driversData, loading } = useDriversForSelectionQuery({
    onCompleted: (data) => {
      data.drivers.forEach((driver) => {
        if (isNil(getDriverEquipmentConstraintByDriverUuid(driver.uuid))) {
          upsertDriverEquipmentConstraint({
            active: false,
            driverUuid: driver.uuid,
            equipmentUuid: driver.defaultVehicle?.uuid,
            maxWeight: driver.defaultVehicle?.vehicleType?.maxWeight,
            maxVolume: driver.defaultVehicle?.vehicleType?.maxVolume,
          });
        }
      });
    },
  });

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    driversData?.drivers.forEach((driver) => {
      upsertDriverEquipmentConstraint({
        active: e.currentTarget.checked,
        driverUuid: driver.uuid,
      });
    });
  };

  if (loading) {
    return <CenteredCircularProgress />;
  }

  const filteredDrivers = driversData?.drivers.filter(
    (driver) =>
      isNil(driver.terminal) || driver.terminal.uuid === selectedTerminalUuid,
  );

  if (isNil(filteredDrivers) || filteredDrivers.length === 0) {
    return (
      <Typography>
        No drivers were found
        {!isNil(selectedTerminalUuid) && ' in the selected terminal'}
      </Typography>
    );
  }

  return (
    <Stack>
      <TextField
        size="small"
        sx={{ maxWidth: '250px' }}
        label="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Table size="small">
        <TableHead>
          <TableCell>
            <Checkbox checked={allSelected} onChange={onCheckAll} />
          </TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Ref #</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Vehicle</TableCell>
          <TableCell>Volume</TableCell>
          <TableCell>Weight</TableCell>
          <TableCell>Pieces</TableCell>
        </TableHead>
        <TableBody>
          {filteredDrivers.map((driver) => (
            <DriverVehicleSelectionRow
              key={driver.uuid}
              driver={driver}
              hide={
                !getDriverName(driver)
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) &&
                driver.driverReferenceNumber?.includes(searchText) !== true
              }
            />
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default React.memo(DriverVehicleSelection);
