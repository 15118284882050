import CloseIcon from '@mui/icons-material/Close';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SideBarDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isNil } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  DispatchTableField,
  StopOnRouteFragment,
} from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import {
  getDisplayColumns,
  getEtaColumn,
  getOrderHawbColumn,
} from '../../routes/route-card-stops-list-columns';

const DEFAULT_COLUMN_DEF = {
  resizable: false,
  suppressMenu: true,
  sortable: false,
  lockPinned: true,
};

// Define this up here so it's a stable reference
const DEFAULT_ROW_DATA: StopOnRouteFragment[] = [];

const SIDE_BAR_DEF: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
  ],
  position: 'left',
};

const RouteStopColumnsEditor = ({
  routeCardStopTableFields,
  onChange,
}: {
  routeCardStopTableFields: DispatchTableField[] | undefined;
  onChange: (newFields: DispatchTableField[]) => void;
}) => {
  const [setAllRouteUuidsLoadingStops, setUnrenderAllStops] = useDispatchStore(
    (state) => [state.setAllRouteUuidsLoadingStops, state.setUnrenderAllStops],
    shallow,
  );
  const [columnToolbarOpened, setColumnToolbarOpened] =
    useState<boolean>(false);
  const gridRef = useRef<AgGridReact<StopOnRouteFragment>>(null);

  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );

  // callback which AG Grid calls when a column moves.
  const handleColumnUpdate = async () => {
    const columnState = gridRef.current?.columnApi.getColumnState();
    const columnStateHeaders =
      columnState?.filter((def) => def.hide !== true).map((def) => def.colId) ??
      [];
    const updatedDispatchFields = filterNotNil(
      columnStateHeaders.map((header) => {
        return Object.values(DispatchTableField).find(
          (field) => field === header,
        );
      }),
    );
    setAllRouteUuidsLoadingStops();
    setUnrenderAllStops(true);
    await onChange(updatedDispatchFields);
    setUnrenderAllStops(false);
  };

  const finalColumns = useMemo(() => {
    if (isNil(routeCardStopTableFields)) {
      return [];
    }
    const columnDefinitions = [
      ...getDisplayColumns(false, ffInboundOutboundPaperworkColumns),
      ...[getEtaColumn(), getOrderHawbColumn(undefined)],
    ];
    const activeColumns = filterNotNil(
      routeCardStopTableFields.map((field) =>
        columnDefinitions.find((def) => def.field === field),
      ),
    );
    const nonActiveColumns = columnDefinitions
      .filter(
        (colDef) =>
          routeCardStopTableFields?.every((field) => field !== colDef.field) ===
          true,
      )
      .map((colDef) => ({ ...colDef, hide: true }))
      .sort((a, b) => a.field?.localeCompare(b.field ?? '') ?? 0);
    return [...activeColumns, ...nonActiveColumns];
  }, [ffInboundOutboundPaperworkColumns, routeCardStopTableFields]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography sx={{ fontSize: '14px' }}>
          Customize route stop columns
        </Typography>
        <Tooltip title="Columns">
          <IconButton
            color="primary"
            onClick={() => {
              if (columnToolbarOpened) {
                gridRef.current?.api.closeToolPanel();
                handleColumnUpdate();
                setColumnToolbarOpened(false);
              } else {
                gridRef.current?.api.openToolPanel('columns');
                setColumnToolbarOpened(true);
              }
            }}
          >
            {columnToolbarOpened ? (
              <CloseIcon sx={{ fontSize: '15px' }} />
            ) : (
              <ViewWeekIcon sx={{ fontSize: '15px' }} />
            )}
          </IconButton>
        </Tooltip>
      </Stack>
      {!isNil(routeCardStopTableFields) && (
        <div
          className="unassigned-stops unassigned-stops-v2 ag-theme-material ag-non-compact"
          style={{
            height: '120px',
          }}
        >
          <AgGridReact<StopOnRouteFragment>
            ref={gridRef}
            sideBar={SIDE_BAR_DEF}
            defaultColDef={DEFAULT_COLUMN_DEF}
            rowData={DEFAULT_ROW_DATA}
            rowBuffer={60}
            headerHeight={35}
            onColumnMoved={
              // If we have this enabled while the column tool panel is open,
              // this also runs for every column move in the tool panel,
              // which resets the tool state.
              !columnToolbarOpened ? handleColumnUpdate : undefined
            }
            onGridReady={() => {
              gridRef.current?.columnApi.sizeColumnsToFit(850);
            }}
            columnDefs={finalColumns}
          />
        </div>
      )}
    </>
  );
};

export { RouteStopColumnsEditor };
