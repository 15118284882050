import { Email } from '@mui/icons-material';
import {
  styled,
  Checkbox,
  Box,
  NativeSelect,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  SxProps,
  Theme,
  useTheme,
  Typography,
} from '@mui/material';

import { isNil } from 'lodash';
import { DocumentType } from '../../../generated/graphql';
import useDocuments from '../../react-hooks/use-documents';
import PDFDocumentThumbnail, { UploadDocument } from './pdf-document-thumbnail';

const ThumbnailLabel = styled('label')({
  display: 'block',
  minWidth: 0,
  width: '100%',
  height: '100%',
  userSelect: 'none',
  cursor: 'pointer',
});

const ThumbnailImage = styled('img')({
  display: 'block',
  width: 'auto',
  height: '100%',
  maxWidth: '100%',
  objectFit: 'cover',
});

const checkboxStyles: { [key: string]: SxProps<Theme> } = {
  checkbox: {
    position: 'absolute',
    padding: 0,
    zIndex: 1,
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  },
};

const useStyles = () => {
  const theme = useTheme();
  return {
    thumbEmailContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8px',
      fontSize: '12px',
      overflowY: 'auto',
      width: '100%',
      height: '100%',
      wordBreak: 'break-word',
    },
    thumbEmailIcon: {
      fontSize: '20px',
      color: theme.palette.text.secondary,
    },
    thumbInner: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      overflow: 'hidden',
      width: '100px',
      height: '100px',
    },
    img: {
      display: 'block',
      width: 'auto',
      height: '100%',
      maxWidth: '100%',
      objectFit: 'cover',
    },
    label: {
      display: 'block',
      minWidth: 0,
      width: '100%',
      height: '100%',
      userSelect: 'none',
      cursor: 'pointer',
    },
    select: {
      width: '100px',
      fontSize: '12px',
      padding: 0,
    },
    object: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  } as const;
};

interface DocumentTypeProps {
  isPdfDocument: boolean;
}

const DocumentTypeOptions = ({ isPdfDocument }: DocumentTypeProps) => {
  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  if (loading) {
    return null;
  }

  return (
    <>
      {documentTypesForSelection.map((type) => (
        <option value={type} key={type}>
          {getDocumentTypeCopy({
            documentType: type,
          })}
        </option>
      ))}
      {/* Multiple pages option only for PDF documents */}
      {isPdfDocument && (
        <option value="MULTIPLE_PAGES" key="Multiple pages">
          Multiple pages
        </option>
      )}
    </>
  );
};

interface DocumentThumbnailProps {
  document: UploadDocument;
  updateDocumentAttributes: (
    attribute: UploadDocument['attributes'],
    documentIndex: number,
    pageIndex?: number,
  ) => void;
  documentIndex: number;
  pageIndex?: number;
}

const DocumentThumbnail = ({
  document,
  updateDocumentAttributes,
  documentIndex,
  pageIndex,
}: DocumentThumbnailProps) => {
  const styles = useStyles();
  const {
    file,
    attributes: { documentType },
  } = document;

  // Update the document attributes to toggle the isSelected attribute
  const documentSelectedToggle = () => {
    updateDocumentAttributes(
      {
        ...document.attributes,
        isSelected: !document.attributes.isSelected,
      },
      documentIndex,
      pageIndex,
    );
  };

  // Update the document type attribute
  const onSelectChangeHandler: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    updateDocumentAttributes(
      {
        ...document.attributes,
        documentType: event.target.value as DocumentType,
      },
      documentIndex,
      pageIndex,
    );
  };

  const isMultiplePagesDocumentType = documentType === 'MULTIPLE_PAGES';
  const isPdfDocument = file.type === 'application/pdf';
  const isChildPdfDocument = isPdfDocument && !isNil(pageIndex);
  const isEmlDocument = file.type === 'message/rfc822';

  const checkboxId = isNil(pageIndex)
    ? `checkbox-${documentIndex}`
    : `checkbox-${documentIndex}-${pageIndex}`;

  let thumbnail: JSX.Element;
  if (isPdfDocument) {
    thumbnail = <PDFDocumentThumbnail document={document} />;
  } else if (isEmlDocument) {
    thumbnail = (
      <Box style={styles.thumbEmailContainer}>
        <Email style={styles.thumbEmailIcon} />
        <Typography fontSize={12}>{file.name}</Typography>
      </Box>
    );
  } else if (isNil(document.attributes.existingDocument)) {
    thumbnail = (
      <ThumbnailImage src={URL.createObjectURL(file)} alt={file.name} />
    );
  } else {
    thumbnail = (
      <object
        aria-label={document.file.name}
        style={styles.object}
        data={document.attributes.url}
      />
    );
  }

  return (
    <Grid item key={`${file.name}-${documentIndex}`}>
      <Checkbox
        id={checkboxId}
        onClick={documentSelectedToggle}
        checked={document.attributes.isSelected && !isMultiplePagesDocumentType}
        sx={checkboxStyles.checkbox}
        disabled={isMultiplePagesDocumentType}
      />
      <Box style={styles.thumbInner}>
        <ThumbnailLabel htmlFor={checkboxId}>{thumbnail}</ThumbnailLabel>
      </Box>
      <NativeSelect
        value={documentType}
        sx={styles.select}
        size="small"
        onChange={onSelectChangeHandler}
      >
        <DocumentTypeOptions
          isPdfDocument={isPdfDocument && !isChildPdfDocument}
        />
      </NativeSelect>
    </Grid>
  );
};

export default DocumentThumbnail;
