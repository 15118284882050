import { ListItem, ListItemButton, ListItemText, Theme } from '@mui/material';
import { isNil } from 'lodash';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { isDevelopment } from '../../../environment-variables';
import { DEV_APP_BAR_COLOR, DEV_SELECTED_NAVBAR_COLOR } from '../constants';

const getMainColor = (theme: Theme) =>
  isDevelopment() ? DEV_APP_BAR_COLOR : theme.palette.primary.main;

const getLightColor = (theme: Theme) =>
  isDevelopment() ? DEV_SELECTED_NAVBAR_COLOR : theme.palette.primary.light;

type DashboardDrawerItemProps = {
  name: string;
  icon: ReactNode;
  selected?: boolean;
  href?: string;
  onClick: () => void;
};

const DashboardDrawerItem = ({
  name,
  icon,
  selected,
  href,
  onClick,
}: DashboardDrawerItemProps) => {
  const itemContent = (
    <ListItemButton
      component="span"
      onClick={onClick}
      // ListItemButton (rather than ListItem) supplies all
      // the padding so that the hover state fills the entire list item
      sx={[
        {
          px: '20px',
          py: '12px',
          opacity: !isNil(selected) && selected && !isDevelopment() ? 1 : 0.6,
        },
        Boolean(selected) && !isDevelopment() && { color: getMainColor },
      ]}
    >
      {icon}
      <ListItemText primary={name} sx={{ ml: 2 }} />
    </ListItemButton>
  );
  return (
    <ListItem
      sx={[
        { p: 0 },
        Boolean(selected) && {
          backgroundColor: getLightColor,
          borderStyle: 'solid',
          borderWidth: 0,
          borderRightWidth: '4px',
          borderRightColor: getMainColor,
        },
      ]}
    >
      {isNil(href) ? (
        itemContent
      ) : (
        <Link to={href} style={{ width: '100%' }}>
          {itemContent}
        </Link>
      )}
    </ListItem>
  );
};

export default React.memo(DashboardDrawerItem);
