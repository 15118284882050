import { Box, FormControl, SxProps, TextField, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectContactPersonsByIds } from '../../contact-persons/redux/contact-persons-values-slice';
import {
  selectContactDefaultContactPersonUuid,
  selectContactPersonUuids,
  updateOneContactValues,
} from '../redux/contact-values-slice';
import ContactPageMode from './contact-page-mode';

const useStyles = () => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: 2,
      px: 2,
      py: 2,
      width: '100%',
    } as SxProps,
    flexRowBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: 2,
      my: 1.5,
      width: '100%',
    } as SxProps,
  };
};

type ContactPersonOption = {
  label: string;
  uuid: string;
};

type DefaultContactPersonAutocompleteProps = {
  contactUuid: string;
  mode: ContactPageMode;
};

const DefaultContactPersonAutocomplete = ({
  contactUuid,
  mode,
}: DefaultContactPersonAutocompleteProps) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] =
    useState<ContactPersonOption | null>(null);
  const defaultContactPersonUuid = useAppSelector((state) =>
    selectContactDefaultContactPersonUuid(state, contactUuid),
  );
  const contactPersonUuids =
    useAppSelector((state) => selectContactPersonUuids(state, contactUuid)) ??
    [];
  const contactPersons = useAppSelector((state) =>
    selectContactPersonsByIds(state, contactPersonUuids),
  );
  const options: ContactPersonOption[] = contactPersons.map((c) => ({
    label: `${c.firstName} ${c.lastName} ${c.phone}`,
    uuid: c.uuid,
  }));

  const update = (newUuid: string | null) => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: { defaultContactPersonUuid: newUuid },
      }),
    );
  };

  useEffect(() => {
    const current = options.find((c) => c.uuid === defaultContactPersonUuid);

    if (!isNil(current)) {
      setInputValue(current.label);
      setSelectedValue(current);
    } else {
      setInputValue('');
      setSelectedValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultContactPersonUuid, contactPersons]);

  return (
    <Box sx={styles.flexRowBox}>
      <FormControl sx={{ width: theme.spacing(50) }} size="small">
        <AutocompleteFuzzy
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          inputValue={inputValue ?? ''}
          isOptionEqualToValue={(option, value) =>
            option.uuid === value.uuid && option.label === value.label
          }
          onChange={(e, newValue) => {
            setSelectedValue(newValue);
            update(newValue?.uuid ?? null);
          }}
          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              size="small"
            />
          )}
          options={options}
          matchSortOptions={{ keys: ['label'] }}
          sx={{ backgroundColor: 'white' }}
          value={selectedValue ?? null}
        />
      </FormControl>
    </Box>
  );
};

export default DefaultContactPersonAutocomplete;
