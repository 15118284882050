import {
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import { usePaymentsByInvoiceUuidLazyQuery } from '../../../../generated/graphql';
import useInvoicesStore from '../../invoices-store';
import InvoicePaymentListRow from './invoice-payment-list-row';

const InvoicePaymentsList = ({
  invoiceUuid,
  inCustomersTab = false,
}: {
  invoiceUuid: string;
  inCustomersTab?: boolean;
}) => {
  const { height } = useWindowDimensions();
  const [getPaymentsByInvoiceUuid, { data, loading }] =
    usePaymentsByInvoiceUuidLazyQuery();
  const [shouldRefreshInvoiceList, setShouldRefreshInvoiceList] =
    useInvoicesStore((state) => [
      state.shouldRefreshInvoiceList,
      state.setShouldRefreshInvoiceList,
    ]);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  const fetchInvoicePaymentMappings = async () => {
    await getPaymentsByInvoiceUuid({
      variables: {
        uuid: invoiceUuid,
      },
    });
  };

  useEffect(() => {
    fetchInvoicePaymentMappings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceUuid]);

  useEffect(() => {
    if (shouldRefreshInvoiceList || shouldRefresh) {
      fetchInvoicePaymentMappings();
      setShouldRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshInvoiceList, shouldRefresh]);

  return (
    <TableContainer sx={{ maxHeight: height - 295, overflowY: 'scroll' }}>
      <Grid
        container
        alignItems="center"
        sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
      >
        <Grid item xs={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {loading === true && <CircularProgress size={20} />}
          </Stack>
        </Grid>
      </Grid>
      <Table stickyHeader aria-label="invoice-preview-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Reference number</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Total amount</TableCell>
            {inCustomersTab && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <CircularProgress size={20} sx={{ m: 1 }} />
          ) : (data?.paymentsByInvoiceUuid ?? []).length > 0 ? (
            data?.paymentsByInvoiceUuid.map((payment) => (
              <InvoicePaymentListRow
                payment={payment}
                refresh={() => setShouldRefreshInvoiceList(true)}
                inCustomersTab={inCustomersTab}
                key={payment.uuid}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}> No payments applied</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoicePaymentsList;
