import { Alert, Snackbar } from '@mui/material';
import { isNil } from 'lodash';
import { PropsWithChildren, useMemo, useState } from 'react';
import ErrorContext from './error-context';

/**
 * Copied from
 * https://shinesolutions.com/2021/06/30/automatically-handling-apollo-client-errors-in-your-react-ui/
 * @param children
 * @constructor
 */
const ErrorProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const values = useMemo(
    () => ({ errorMessage, setErrorMessage }),
    [errorMessage],
  );
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // When they click outside the alert
    // https://mui.com/material-ui/react-snackbar/
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage(null);
  };
  return (
    <ErrorContext.Provider value={values}>
      {children}
      {/* Without the && there's a slight flicker when the message is set to null */}
      {/* but the snackbar still shows */}
      {!isNil(errorMessage) && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={4000}
          onClose={handleClose}
          open={!isNil(errorMessage)}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
