// eslint-disable-next-line no-restricted-imports
import { Box, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { isEmpty, isNil, sortBy } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import useTerminals from '../../common/react-hooks/use-terminals';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { PermissionResource, Segment } from '../../generated/graphql';
import { SchedulingPage } from '../scheduling/components/scheduling-page';
import AccessorialsTable from './components/accessorials/accessorials-table';
import BillingTab from './components/billing-tab';
import BusinessDivisionsTable from './components/business-divisions/business-divisions-table';
import CustomFormsTable from './components/custom-driver-forms-table';
import DriversTable from './components/drivers/drivers-table';
import EquipmentTab from './components/equipment-tab';
import FuelProfilesTable from './components/fuel-profiles/fuel-profiles-tables';
import HoldReasons from './components/hold-reasons/hold-reasons';
import LineHaulLanesTable from './components/line-haul/line-haul-lanes-table';
import PackageSpecsTable from './components/package-specs/package-specs-table';
import RecoveryTerminalsTable from './components/recovery-terminals-table';
import RecurringOrderTemplateTable from './components/recurring-order-template-table';
import RecurringRunsTable from './components/recurring-runs-table';
import RouteNames from './components/route-names';
import ServicesTable from './components/service-levels';
import TariffChainsPage from './components/tariff-chains/tariff-chains-page';
import TariffsPage from './components/tariffs-page';
import TerminalsTable from './components/terminals/terminals-table';
import ThirdPartyBrokers from './components/third-party-brokers/third-party-brokers';
import UsersTable from './components/users/users-table';
import WarehouseEmployeesTable from './components/warehouse-employees/warehouse-employees-table';
import ZoneGroupsPage from './components/zones/zone-groups-page';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  pad: boolean;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, pad } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ height: '100%' }}
    >
      {value === index && (
        <Box height="100%" overflow="scroll" sx={pad ? { p: 3 } : undefined}>
          {children}
        </Box>
      )}
    </div>
  );
}

const parseTabQueryParam = (tab: string | null): number => {
  if (isNil(tab)) {
    return 0;
  }
  return parseInt(tab, 10);
};

const gridItemSx = {
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'white',
};

const ManagementView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { companyUuid, companyData, loading, companyConfiguration } = useMe();
  const { userPermissions } = useUserRoles();
  const [value, setValue] = useState(
    parseTabQueryParam(searchParams.get('tab')),
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('tab', newValue.toString());
      return newParams;
    });
    setValue(newValue);
  };

  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const ffShowLinehaulConfigurationUi = companyConfiguration?.lineHaulEnabled;
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const ffScheduling = useFeatureFlag(FeatureFlag.FF_SCHEDULING);
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );
  const ffZoneGroupsEnabled = useFeatureFlag(
    FeatureFlag.FF_ZONE_GROUPS_ENABLED,
  );
  const ffTariffChainsEnabled = useFeatureFlag(
    FeatureFlag.FF_TARIFF_CHAINS_ENABLED,
  );
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  // This nil check necessary. The optional chaining below throws an NPE in a
  // production build for some reason.
  if (
    loading ||
    isNil(companyData) ||
    isNil(companyUuid) ||
    isEmpty(companyUuid)
  ) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  const isCartage = companyData?.segment === Segment.Cartage;

  const { canRead: canReadCompanyDrivers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyDrivers,
  );

  const { canRead: canReadWarehouseEmployees } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyWarehouseEmployees,
  );

  const { canRead: canReadEquipment } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyEquipment,
  );

  const { canRead: canReadAccessorials } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyAccessorials,
  );

  const { canRead: canReadTariffs } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTariffs,
  );

  const { canRead: canReadBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const { canRead: canReadServices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyServices,
  );

  const { canRead: canReadRouteNames } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRouteNames,
  );

  const { canRead: canReadUsers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyUsers,
  );

  const { canRead: canReadRecoveryTerminals } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecoveryTerminals,
  );

  const { canRead: canReadCustomForms } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyCustomForms,
  );

  const { canRead: canReadFuelProfiles } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyFuelProfiles,
  );

  const { canRead: canReadBusinessDivisions } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBusinessDivisions,
  );

  const { canRead: canReadRecurringOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecurringOrders,
  );

  const { canRead: canReadCompanyScheduling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyScheduling,
  );

  const { canRead: canReadTerminals } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTerminals,
  );

  const { canRead: canReadLineHaul } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyLineHaul,
  );

  const { canRead: canReadThirdPartyBrokers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyThirdPartyBrokers,
  );

  const { canRead: canReadHoldReasons } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyHoldReasons,
  );

  type TabContent = {
    label: string;
    component: ReactNode;
    tabVisibleCondition?: boolean | null;
    // Defaults to true.
    panelPadding?: boolean;
  };

  let TAB_COMPONENTS: TabContent[] = [
    {
      label: 'Accessorials',
      component: <AccessorialsTable />,
      tabVisibleCondition: canReadAccessorials,
    },
    {
      label: 'Billing',
      component: <BillingTab />,
      tabVisibleCondition: canReadBilling,
    },
    {
      label: 'Services',
      component: <ServicesTable />,
      tabVisibleCondition: canReadServices,
    },
    {
      label: 'Route Names',
      component: <RouteNames />,
      tabVisibleCondition: canReadRouteNames,
    },
    {
      label: 'Recovery Terminals',
      component: <RecoveryTerminalsTable />,
      tabVisibleCondition:
        isCartage && !ffRecoveryTransferAddressOnly && canReadRecoveryTerminals,
    },
    {
      label: 'Business Divisions',
      component: <BusinessDivisionsTable />,
      tabVisibleCondition: canReadBusinessDivisions,
    },
    {
      label: 'Custom Forms',
      component: <CustomFormsTable />,
      tabVisibleCondition: canReadCustomForms,
    },
    {
      label: ffDemoLoadManagement ? 'Drivers & Agents' : 'Drivers',
      component: <DriversTable />,
      tabVisibleCondition: canReadCompanyDrivers,
    },
    {
      label: 'Equipment & Vehicles',
      component: <EquipmentTab />,
      tabVisibleCondition: canReadEquipment,
    },
    {
      label: 'Fuel Profiles',
      component: <FuelProfilesTable />,
      tabVisibleCondition: canReadFuelProfiles,
    },
    {
      label: 'Hold Reasons',
      component: <HoldReasons />,
      tabVisibleCondition: canReadHoldReasons,
    },
    {
      label: 'Line haul',
      component: <LineHaulLanesTable />,
      tabVisibleCondition:
        ffShowLinehaulConfigurationUi === true && canReadLineHaul,
    },
    {
      label: 'Recurring Orders',
      component: <RecurringOrderTemplateTable />,
      tabVisibleCondition: canReadRecurringOrders,
    },
    {
      label: 'Route Templates',
      component: <RecurringRunsTable />,
      tabVisibleCondition: canReadRecurringOrders,
    },
    {
      label: 'Tariffs',
      component: <TariffsPage />,
      tabVisibleCondition: canReadTariffs,
    },
    {
      label: 'Tariff Chains',
      component: <TariffChainsPage />,
      tabVisibleCondition: ffTariffChainsEnabled && canReadTariffs,
    },
    {
      label: 'Terminals',
      component: <TerminalsTable />,
      tabVisibleCondition: terminalsEnabled && canReadTerminals,
    },
    {
      label: 'Third Party / Brokers',
      component: <ThirdPartyBrokers />,
      tabVisibleCondition: canReadThirdPartyBrokers,
    },
    {
      label: 'Users',
      component: <UsersTable companyUuid={companyUuid} />,
      tabVisibleCondition: canReadUsers,
    },
    {
      label: 'Warehouse Employees',
      component: <WarehouseEmployeesTable />,
      tabVisibleCondition: canReadWarehouseEmployees,
    },
    {
      label: 'Package Types',
      component: <PackageSpecsTable />,
      tabVisibleCondition: ffPackageSpecs && canReadEquipment,
    },
    {
      label: 'Scheduling',
      component: <SchedulingPage />,
      tabVisibleCondition: ffScheduling && canReadCompanyScheduling,
      panelPadding: false,
    },
    {
      label: 'Zones',
      component: <ZoneGroupsPage />,
      tabVisibleCondition: ffZoneGroupsEnabled && canReadTariffs,
    },
  ];

  TAB_COMPONENTS = sortBy(
    TAB_COMPONENTS.filter(
      (tabInfo) =>
        tabInfo.tabVisibleCondition === true ||
        isNil(tabInfo.tabVisibleCondition),
    ),
    'label',
  );

  TAB_COMPONENTS = TAB_COMPONENTS.filter(
    (component) => component.tabVisibleCondition !== false,
  );

  return (
    <Grid height="100%" container>
      <Grid item xs={2} sx={gridItemSx} borderRight={1} borderColor="divider">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          sx={{ pt: 3 }}
        >
          {TAB_COMPONENTS.map((tabComponent) => (
            <Tab
              key={tabComponent.label}
              sx={{ alignItems: 'start' }}
              label={tabComponent.label}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={10} sx={gridItemSx}>
        {TAB_COMPONENTS.map((tabComponent, idx) => (
          <TabPanel
            key={tabComponent.label}
            value={value}
            index={idx}
            pad={tabComponent.panelPadding ?? true}
          >
            {tabComponent.component}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default ManagementView;
