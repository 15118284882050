// eslint-disable-next-line no-restricted-imports
import { Alert, Box, Divider, Fade, Grid, Snackbar } from '@mui/material';
import { isNil } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import useMe from '../../../common/react-hooks/use-me';
import {
  ShallowRoutesWithDocumentsQuery,
  useShallowRoutesWithDocumentsLazyQuery,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { useAppDispatch } from '../../../redux/hooks';
import EndOfDayContext from '../end-of-day-context';
import useStyles from '../end-of-day-styles';
import useTableData, { Route } from '../hooks/use-table-data';
import { initExistingRouteValues } from '../redux/route-values-thunks';
import EndOfDayDocuments from './end-of-day-documents';
import EndOfDayRouteStopsTable from './end-of-day-route-stops-table';
import EndOfDayRoutelessStopDocuments from './end-of-day-routeless-stop-documents';
import EndOfDayRoutesSidebar from './end-of-day-routes-sidebar';

const EndOfDayRoutes = () => {
  const { user } = useMe();
  const styles = useStyles();
  const {
    shouldLoadRoutes,
    setShouldLoadRoutes,
    currentDate,
    selectedRouteUuid,
    setSelectedRouteUuid,
    completedStopsNotOnRoutesSelected,
  } = useContext(EndOfDayContext);
  const [tableIsMinimized, setTableIsMinimized] = useState<boolean>(false);
  const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false);
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const [showCancelStopSuccessMessage, setShowCancelStopSuccessMessage] =
    useState(false);
  const [
    showMarkStopAsAttemptedSuccessMessage,
    setShowMarkStopAsAttemptedSuccessMessage,
  ] = useState(false);
  const [showCancelStopErrorMessage, setShowCancelStopErrorMessage] =
    useState(false);
  const [
    showMarkStopAsAttemptedErrorMessage,
    setShowMarkStopAsAttemptedErrorMessage,
  ] = useState(false);

  const [getShallowRoutes] = useShallowRoutesWithDocumentsLazyQuery();
  const [routeQueryData, setRouteQueryData] =
    useState<ShallowRoutesWithDocumentsQuery | null>(null);
  const routeData: Route[] = useTableData({ routesData: routeQueryData });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isNil(selectedRouteUuid)) {
      dispatch(
        initExistingRouteValues({ routeUuid: String(selectedRouteUuid) }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRouteUuid, shouldLoadRoutes]);

  const queryRoute = async (selectFirstRoute: boolean) => {
    if (!isNil(currentDate)) {
      const res = await getShallowRoutes({
        variables: {
          date: currentDate.toDate(),
          terminalUuid: selectedTerminalUuid,
        },
      });
      setRouteQueryData(res.data ?? null);
      if (selectFirstRoute) {
        const firstRoute = res.data?.routes[0];
        if (!isNil(firstRoute)) {
          setSelectedRouteUuid(firstRoute.uuid);
        } else {
          setSelectedRouteUuid(undefined);
        }
      }
    }
  };

  useEffect(() => {
    if (tableIsMinimized) {
      setTimeout(() => {
        setShowDocumentViewer(true);
      }, 150);
    } else {
      setShowDocumentViewer(false);
    }
  }, [tableIsMinimized]);

  useEffect(() => {
    if (!isNil(user)) {
      queryRoute(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, selectedTerminalUuid]);

  useEffect(() => {
    if (shouldLoadRoutes) {
      queryRoute(false);
      setShouldLoadRoutes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadRoutes]);

  return (
    <Box height="100%">
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showCancelStopSuccessMessage}
        onClose={() => setShowCancelStopSuccessMessage(false)}
      >
        <Alert severity="success">Cancelled stop</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showCancelStopErrorMessage}
        onClose={() => setShowCancelStopErrorMessage(false)}
      >
        <Alert severity="error">Failed to cancel stop</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showMarkStopAsAttemptedSuccessMessage}
        onClose={() => setShowMarkStopAsAttemptedSuccessMessage(false)}
      >
        <Alert severity="success">Marked stop as attempted</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showMarkStopAsAttemptedErrorMessage}
        onClose={() => setShowMarkStopAsAttemptedErrorMessage(false)}
      >
        <Alert severity="error">Failed to mark stop as attempted</Alert>
      </Snackbar>
      <Divider sx={{ zIndex: 1 }} />
      <Grid container spacing={0} height="100%">
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            ...styles.borderRightDivider,
            ...{
              borderRightColor: styles.borderColor,
              height: '100%',
              overflowY: 'auto',
            },
          }}
        >
          <EndOfDayRoutesSidebar routeData={routeData} />
        </Grid>
        <Grid item xs={12} md={9} height="100%">
          <Grid container spacing={0} height="100%">
            <Grid
              item
              xs={12}
              md={tableIsMinimized ? 5 : 12}
              sx={{
                ...styles.borderRightDivider,
                ...{
                  borderRightColor: styles.borderColor,
                  overflowY: 'auto',
                  padding: 0,
                  transition: '0.15s',
                  height: '100%',
                },
              }}
            >
              {(!isNil(selectedRouteUuid) ||
                completedStopsNotOnRoutesSelected === true) && (
                <EndOfDayRouteStopsTable
                  tableIsMinimized={tableIsMinimized}
                  setTableIsMinimized={setTableIsMinimized}
                  setShowMarkStopAsAttemptedSuccessMessage={
                    setShowMarkStopAsAttemptedSuccessMessage
                  }
                  setShowMarkStopAsAttemptedErrorMessage={
                    setShowMarkStopAsAttemptedErrorMessage
                  }
                />
              )}
            </Grid>
            {showDocumentViewer && (
              <Fade in={showDocumentViewer}>
                <Grid item xs={12} md={7}>
                  {!isNil(selectedRouteUuid) && (
                    <EndOfDayDocuments
                      setTableIsMinimized={setTableIsMinimized}
                    />
                  )}
                  {completedStopsNotOnRoutesSelected === true && (
                    <EndOfDayRoutelessStopDocuments
                      setTableIsMinimized={setTableIsMinimized}
                    />
                  )}
                </Grid>
              </Fade>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EndOfDayRoutes;
