// eslint-disable-next-line no-restricted-imports
import { Button, Card, Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useShallowContactLazyQuery } from '../../generated/graphql';
import TariffZonesTable from './components/tariff-zones-table';
import styles from './styles';

const TariffZonesPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const contactUuid = searchParams.get('contactUuid');
  const isTerminal = searchParams.get('isTerminal') === 'true';

  const [getContact, { data: contactData }] = useShallowContactLazyQuery();

  useEffect(() => {
    if (typeof contactUuid === 'string') {
      getContact({
        variables: {
          uuid: contactUuid,
        },
      });
    }
  }, [contactUuid, getContact]);

  const goBack = () => {
    navigate(-1);
  };

  let tariffZoneTypeString = 'Default';
  if (!isNil(contactData)) {
    tariffZoneTypeString = contactData.contact.displayName;
  } else if (isTerminal) {
    tariffZoneTypeString = 'Terminal';
  }

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid item xs={4}>
        <Button variant="contained" onClick={goBack}>
          Back
        </Button>
      </Grid>
      <Grid item xs={4} sx={styles.center}>
        <Typography sx={{ color: 'black' }} variant="h5">
          {tariffZoneTypeString} Tariff Zones
        </Typography>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={12}>
        <Card sx={styles.card}>
          <TariffZonesTable contactUuid={contactUuid} isTerminal={isTerminal} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TariffZonesPage;
