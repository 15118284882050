// This file configures the initialization of Sentry on the browser.
// https://docs.sentry.io/platforms/javascript/guides/react/
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { EnvironmentVariables, AppEnvironment } from './environment-variables';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (EnvironmentVariables.VITE_APP_ENV !== AppEnvironment.DEVELOPMENT) {
  Sentry.init({
    dsn:
      SENTRY_DSN !== undefined && SENTRY_DSN !== ''
        ? SENTRY_DSN
        : 'https://8bc45d6fb47b4730b1b3b19c5c2e56f0@o1416522.ingest.sentry.io/6757915',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: EnvironmentVariables.VITE_APP_ENV,
    release: import.meta.env.RENDER_GIT_COMMIT,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [
      // See https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
  });
}

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
