import InfoIcon from '@mui/icons-material/Info';
import { CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import currency from 'currency.js';
import { ContactWithBalancesFragment } from '../../../../generated/graphql';

const CustomerSummaryItem = ({
  header,
  subtitle,
  value,
}: {
  header: string | React.ReactNode;
  subtitle: string;
  value: string;
}) => {
  return (
    <Stack alignItems="flex-start">
      {typeof header === 'string' ? (
        <Typography sx={{ fontWeight: 'bold' }}>{header}</Typography>
      ) : (
        header
      )}
      <Typography variant="caption" color="text.secondary">
        {subtitle}
      </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};

const CustomerSummary = ({
  contactPaymentsDataLoading,
  contact,
  customerBalance,
}: {
  contactPaymentsDataLoading: boolean;
  contact: ContactWithBalancesFragment | null;
  customerBalance: currency;
}) => {
  const contactBalanceValue = (
    balanceValue: number | string | currency | null | undefined,
  ) =>
    contactPaymentsDataLoading ? '-' : currency(balanceValue ?? 0).format();

  if (contact == null || contactPaymentsDataLoading) {
    return (
      <Stack
        direction="row"
        alignItems="top"
        justifyContent="center"
        sx={{ mx: 2, mt: 1 }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="top"
      justifyContent="space-between"
      sx={{ mx: 2, mt: 1 }}
    >
      <CustomerSummaryItem
        header="Posted invoices"
        subtitle="Open / Closed"
        value={`${contactBalanceValue(
          contact.postedInvoicesTotalOpenBalance,
        )} / ${contactBalanceValue(contact.postedInvoicesTotalClosedBalance)}`}
      />
      <CustomerSummaryItem
        header="Payments"
        subtitle="Unapplied / Applied"
        value={`${contactBalanceValue(contact.unappliedPayments ?? 0)} / ${contactBalanceValue(contact.paymentsAppliedToContactInvoices ?? 0)}`}
      />
      <CustomerSummaryItem
        header="Credits"
        subtitle="Unapplied / Applied"
        value={`${contactBalanceValue(contact?.unappliedCredits)} / ${contactBalanceValue(contact?.creditsAppliedToContactInvoices)}`}
      />
      <CustomerSummaryItem
        header="Debits"
        subtitle="Unapplied / Applied"
        value={`${contactBalanceValue(contact.unappliedDebits)} / ${contactBalanceValue(contact.debitsAppliedToContactInvoices)}`}
      />
      <CustomerSummaryItem
        header={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Customer balance
            </Typography>
            <Tooltip title="Total posted invoice amount - credits - payments + debits">
              <InfoIcon color="info" sx={{ fontSize: '16px' }} />
            </Tooltip>
          </Stack>
        }
        subtitle="Total posted invoice amount - credits - payments + debits"
        value={contactBalanceValue(customerBalance)}
      />
    </Stack>
  );
};

export default CustomerSummary;
