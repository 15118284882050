import { Box, Modal, Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { sleep } from '../../../../../common/utils/utils';
import {
  RouteFragment,
  useGetOptimizationResultsLazyQuery,
  useRunOptimizationMutation,
} from '../../../../../generated/graphql';
import useStyles from '../../../../orders/components/styles';
import useRouteActions from '../../../hooks/use-route-actions';
import OptimizeRouteInterface from './optimize-route-interface';

type OptimizeRouteModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  route: RouteFragment;
};

const OptimizeRouteModal = ({
  open,
  setOpen,
  route,
}: OptimizeRouteModalProps) => {
  const styles = useStyles();
  const [optimizedRoute, setOptimizedRoute] = useState<RouteFragment>();
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [getOptimizedSlots] = useGetOptimizationResultsLazyQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const [optimizeRoute] = useRunOptimizationMutation();
  const { getOptimizeRouteData } = useRouteActions();

  const retryWithSoftOptimizationIfNoSolution = async () => {
    const routeOptimizationRes = await optimizeRoute({
      variables: {
        createOptimizationRunInput: {
          routeUuid: route.uuid,
          optimizationType: 'soft',
        },
      },
    });
    return routeOptimizationRes.data?.runOptimization.uuid;
  };

  // Returns whether the update was successful or not
  const updateSlotsWithOptimized = async (
    routeOptimizationUuid: string | undefined,
  ) => {
    let optimizationRes: string[] | undefined;
    if (!isNil(routeOptimizationUuid)) {
      const optimizationResData = await getOptimizedSlots({
        variables: { routeOptimizationUuid },
      });
      optimizationRes =
        optimizationResData.data?.getOptimizationResults?.slots ?? undefined;
      if (
        optimizationResData.data?.getOptimizationResults?.error ===
        'no-solution'
      ) {
        return 'no-solution';
      }
    }
    if (!isNil(optimizationRes)) {
      const filteredOptimizationRes = filterNotNil(
        optimizationRes?.map((optimizedSlotId) => {
          const correspondingSlot = route.slots.find(
            (slot) => slot.uuid === optimizedSlotId,
          );
          return correspondingSlot;
        }) ?? [],
      );
      const slotsWithoutCoordinates = route.slots.filter((slot) => {
        return (
          isNil(slot.stops[0]?.address.latitude) ||
          isNil(slot.stops[0]?.address.longitude)
        );
      });
      const newRoute = { ...route };
      newRoute.slots = [...filteredOptimizationRes, ...slotsWithoutCoordinates];
      setOptimizedRoute(newRoute);
      return 'success';
    }
    return 'failure';
  };

  const getSoftOptimizedSlots = async (softRouteOptimizationId: string) => {
    const optimizationResData = await getOptimizedSlots({
      variables: { routeOptimizationUuid: softRouteOptimizationId },
    });
    if (
      !isNil(optimizationResData) &&
      !isNil(optimizationResData.data) &&
      !isNil(optimizationResData.data.getOptimizationResults?.slots)
    ) {
      const filteredOptimizationRes = filterNotNil(
        optimizationResData.data?.getOptimizationResults?.slots?.map(
          (optimizedSlotId) => {
            const correspondingSlot = route.slots.find(
              (slot) => slot.uuid === optimizedSlotId,
            );
            return correspondingSlot;
          },
        ) ?? [],
      );
      const slotsWithoutCoordinates = route.slots.filter((slot) => {
        return (
          isNil(slot.stops[0]?.address.latitude) ||
          isNil(slot.stops[0]?.address.longitude)
        );
      });
      const newRoute = { ...route };
      newRoute.slots = [...filteredOptimizationRes, ...slotsWithoutCoordinates];
      setOptimizedRoute(newRoute);
      return 'success';
    }
    return 'failure';
  };

  const loopSlots = async (routeOptimizationId: string | undefined) => {
    try {
      let res = 'failure';
      for (let i = 0; i < 10; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        res = await updateSlotsWithOptimized(routeOptimizationId);
        if (res === 'success') {
          setShowLoadingScreen(false);
          return;
        }
        if (res === 'no-solution') {
          break;
        }
        // eslint-disable-next-line no-await-in-loop
        await sleep(i * 1000);
      }
      if (res === 'no-solution') {
        const softOptimizationUuid =
          await retryWithSoftOptimizationIfNoSolution();
        if (!isNil(softOptimizationUuid)) {
          for (let i = 0; i < 10; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            res = await getSoftOptimizedSlots(softOptimizationUuid);
            if (res === 'success') {
              setShowLoadingScreen(false);
              return;
            }
            // eslint-disable-next-line no-await-in-loop
            await sleep(i * 1000);
          }
        }
      }
      setShowLoadingScreen(false);
      setErrorMessage(
        'Route optimization failed on this route. Please contact support.',
      );
    } catch (e) {
      captureException(String(e));
      setErrorMessage(
        'Route optimization failed on this route. Please contact support.',
      );
    }
  };

  const init = async () => {
    const { optimizationUuid } = await getOptimizeRouteData(route.uuid);
    if (!isEmpty(route.slots) && !isNil(optimizationUuid)) {
      loopSlots(optimizationUuid);
    }
  };

  useEffect(() => {
    if (showLoadingScreen) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.slots, showLoadingScreen]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: '90vh', width: '90vw' }]}>
        {showLoadingScreen ? (
          <Stack
            sx={{ width: '100%', height: '100%' }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subtitle1">Optimizing route...</Typography>
          </Stack>
        ) : (
          <Stack sx={{ height: '100%' }}>
            {errorMessage.length === 0 && !isNil(optimizedRoute) && (
              <OptimizeRouteInterface
                optimizedRoute={optimizedRoute}
                setOptimizedRoute={setOptimizedRoute}
                setOpen={setOpen}
              />
            )}
            {errorMessage.length > 0 && (
              <Typography sx={{ textAlign: 'center', color: 'red' }}>
                {errorMessage}
              </Typography>
            )}
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default OptimizeRouteModal;
