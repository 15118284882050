import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import DateDropdownPicker, {
  DatePickerFilterType,
  DateOption,
  initialDateOption,
} from '../../../../common/components/date-dropdown-picker';
import DriverFilterButton from '../../../../common/components/driver-filter-button';
import { Option } from '../../../../common/filters/types';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import { useFinalizeDriverSettlementBillsMutation } from '../../../../generated/graphql';
import theme from '../../../../theme';
import useDriverSettlementStore from '../../driver-settlement-store';
import { SettlementBillStatusTab, SettlementsDownloadType } from '../../types';
import ChangeSettlementStatusModal from './change-settlement-status-modal';
import Settlement from './settlement';
import SettlementPrintSettingsModal from './settlement-print-settings-modal';
import SettlementsContextMenu from './settlements-context-menu';
import SettlementsList from './settlements-list';

const SettlementBills = ({
  shouldRefreshFromUpperComponent,
}: {
  shouldRefreshFromUpperComponent: boolean;
}) => {
  const [openedSettlementUuid, setShouldRefreshSettlementsList] =
    useDriverSettlementStore(
      (state) => [
        state.openedSettlementUuid,
        state.setShouldRefreshSettlementsList,
      ],
      shallow,
    );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dateOption, setDateOption] = useState<DateOption>(initialDateOption);
  const [driverOption, setDriverOption] = useState<Option | null | undefined>();
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextToUse, setSearchTextToUse] = useState<string>('');
  const [isFinalized, setIsFinalized] = useState<boolean | undefined>();
  const [settlementsDownloadType, setSettlementsDownloadType] =
    useState<SettlementsDownloadType>(SettlementsDownloadType.SEPARATE_FILES);
  const [showFinalizeSettlementsModal, setShowFinalizeSettlementsModal] =
    useState<boolean>(false);
  const [showDownloadSettlementsModal, setShowDownloadSettlementsModal] =
    useState<boolean>(false);
  const [numSettlementsFinalized, setNumSettlementsFinalized] =
    useState<number>(0);
  const [showNumSettlementsFinalized, setShowNumSettlementsFinalized] =
    useState(false);
  const [finalizeSettlements, { loading: finalizeSettlementsLoading }] =
    useFinalizeDriverSettlementBillsMutation();

  const finalizeAllSettlements = async () => {
    if (!isNilOrEmptyString(searchText)) {
      // ignore all filters if searching
      const res = await finalizeSettlements({
        variables: {
          searchText: searchText.trim(),
          isFinalized: false,
        },
      });
      setNumSettlementsFinalized(res.data?.finalizeDriverSettlementBills ?? 0);
    } else {
      const res = await finalizeSettlements({
        variables: {
          driverUuid: driverOption?.value,
          startDate: dateOption.startDate,
          endDate: dateOption.endDate,
          isFinalized: false,
        },
      });
      setNumSettlementsFinalized(res.data?.finalizeDriverSettlementBills ?? 0);
    }
    setShowNumSettlementsFinalized(true);
    setShouldRefreshSettlementsList(true);
  };

  useEffect(() => {
    if (shouldRefreshFromUpperComponent) {
      setShouldRefreshSettlementsList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshFromUpperComponent]);

  return (
    <Stack height="100%" display="flex">
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowNumSettlementsFinalized(false)}
        open={showNumSettlementsFinalized}
      >
        <Alert>
          Successfully finalized {numSettlementsFinalized} settlements
        </Alert>
      </Snackbar>
      <Box
        p={2}
        pt={0}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <DateDropdownPicker
            filterTitle="Pay Period"
            dateOption={dateOption}
            setDateOption={setDateOption}
            defaultFilterType={DatePickerFilterType.AllSelect}
          />
          <DriverFilterButton
            cacheId="DRIVER_SETTLEMENTS"
            prefixText="Driver"
            selectedOption={driverOption}
            handleChange={(option: Option | null | undefined) => {
              setDriverOption(option);
            }}
          />
          <TextField
            size="small"
            label="Search settlements"
            InputProps={{ style: { backgroundColor: 'white' } }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                setSearchTextToUse(searchText);
              }
            }}
            sx={{ width: '200px' }}
          />
        </Stack>
        <Stack
          direction="row"
          gap={2}
          pb={2}
          alignItems="center"
          spacing={0}
          height="100%"
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              setShowDownloadSettlementsModal(true);
            }}
            startIcon={
              finalizeSettlementsLoading === true ? (
                <CircularProgress size={20} />
              ) : null
            }
          >
            Download All
          </Button>
          <Button
            variant="contained"
            disabled={isFinalized !== false}
            onClick={() => {
              setShowFinalizeSettlementsModal(true);
            }}
            startIcon={
              finalizeSettlementsLoading === true ? (
                <CircularProgress size={20} />
              ) : null
            }
          >
            Finalize All
          </Button>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <SettlementsContextMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </Stack>
      </Box>
      <Box display="flex" flex={1} minHeight={0} flexDirection="column">
        <Box>
          <Divider sx={{ zIndex: 1 }} />
        </Box>
        <Box flex="1" minHeight={0} flexDirection="column">
          <Stack display="flex" direction="row" spacing={0} height="100%">
            <Box
              sx={{
                borderRight: 1,
                borderRightColor: theme.palette.borderColor.main,
                height: '100%',
                transition: '0.15s',
                flex: 1,
                minWidth: 0,
              }}
            >
              <SettlementsList
                searchText={searchTextToUse}
                driverUuid={driverOption?.value}
                startDate={dateOption.startDate}
                endDate={dateOption.endDate}
                onTabChange={(tab: SettlementBillStatusTab) => {
                  setIsFinalized(
                    // eslint-disable-next-line no-nested-ternary
                    tab === SettlementBillStatusTab.UNFINALIZED
                      ? false
                      : tab === SettlementBillStatusTab.FINALIZED
                        ? true
                        : undefined,
                  );
                }}
              />
            </Box>
            {!isNil(openedSettlementUuid) && (
              <Fade in={!isNil(openedSettlementUuid)}>
                <Box flex={1} minWidth={0}>
                  <Settlement settlementUuid={openedSettlementUuid} />
                </Box>
              </Fade>
            )}
          </Stack>
        </Box>
      </Box>
      <ChangeSettlementStatusModal
        isOpen={showFinalizeSettlementsModal}
        setIsOpen={setShowFinalizeSettlementsModal}
        onConfirm={() => {
          setShowFinalizeSettlementsModal(false);
          finalizeAllSettlements();
        }}
        finalizingAll
      />
      <SettlementPrintSettingsModal
        isOpen={showDownloadSettlementsModal}
        setIsOpen={setShowDownloadSettlementsModal}
        searchText={searchTextToUse}
        driverOption={driverOption}
        startDate={dateOption.startDate}
        endDate={dateOption.endDate}
        isFinalized={isFinalized}
        selectedDownloadTypeOption={settlementsDownloadType}
        handleDownloadTypeChange={(
          selectedDownloadType: SettlementsDownloadType,
        ) => {
          setSettlementsDownloadType(selectedDownloadType);
        }}
      />
    </Stack>
  );
};

export default SettlementBills;
