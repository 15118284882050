import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import QuickbooksDesktopCompanyMappings from './quickbooks-desktop-company-mappings';
import QuickbooksDesktopCustomerMappings from './quickbooks-desktop-customer-mappings';

const QuickbooksDesktopMappings = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={currentTab}
        onChange={(_, newIndex) => setCurrentTab(newIndex)}
      >
        <Tab label="General mappings" />
        <Tab label="Contact mappings" />
      </Tabs>
      <TabPanel panelValue={0} selectedValue={currentTab}>
        <QuickbooksDesktopCompanyMappings />
      </TabPanel>

      <TabPanel panelValue={1} selectedValue={currentTab}>
        <QuickbooksDesktopCustomerMappings />
      </TabPanel>
    </Box>
  );
};

export default QuickbooksDesktopMappings;
