import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  CoverSheetDocumentTypeSequenceFragment,
  CoverSheetDocumentTypeSequenceType,
  DocumentType,
  useCreateCoverSheetDocumentTypeSequenceMutation,
  useRemoveCoverSheetDocumentTypeSequenceMutation,
  useUpdateCoverSheetDocumentTypeSequenceMutation,
} from '../../../../generated/graphql';
import DocumentTypeSequenceEditor from './document-type-sequence-editor';

interface EditDocumentTypeSequenceModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSave: () => void;
  documentTypeSequence?: CoverSheetDocumentTypeSequenceFragment;
  isCreate?: boolean;
}

const EditDocumentTypeSequenceModal = ({
  open,
  setOpen,
  onSave,
  documentTypeSequence,
  isCreate = false,
}: EditDocumentTypeSequenceModalProps) => {
  const [createCoverSheetDocumentTypeSequence] =
    useCreateCoverSheetDocumentTypeSequenceMutation();
  const [updateCoverSheetDocumentTypeSequence] =
    useUpdateCoverSheetDocumentTypeSequenceMutation();
  const [removeCoverSheetDocumentTypeSequence] =
    useRemoveCoverSheetDocumentTypeSequenceMutation();
  const [name, setName] = useState<string>('');
  const [documentTypes, setDocumentTypes] = useState<
    (DocumentType | undefined)[] | undefined
  >([]);

  useEffect(() => {
    setName(documentTypeSequence?.name ?? '');
    setDocumentTypes(documentTypeSequence?.documentTypes ?? []);
  }, [documentTypeSequence, open]);

  const onClickSave = async () => {
    const filteredDocumentTypes: DocumentType[] = [];
    documentTypes?.forEach((documentType) => {
      if (!isNil(documentType)) {
        filteredDocumentTypes.push(documentType);
      }
    });

    if (isCreate) {
      await createCoverSheetDocumentTypeSequence({
        variables: {
          input: {
            name,
            type: CoverSheetDocumentTypeSequenceType.Template,
            documentTypes: filteredDocumentTypes,
          },
        },
      });
    } else if (!isNil(documentTypeSequence)) {
      await updateCoverSheetDocumentTypeSequence({
        variables: {
          input: {
            uuid: documentTypeSequence.uuid,
            name,
            documentTypes: filteredDocumentTypes,
          },
        },
      });
    }

    setOpen(false);
    onSave();
  };

  const onClickDelete = async () => {
    if (!isNil(documentTypeSequence)) {
      await removeCoverSheetDocumentTypeSequence({
        variables: {
          uuid: documentTypeSequence.uuid,
        },
      });
    }

    setOpen(false);
    onSave();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setDocumentTypes([]);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{isCreate ? 'Add' : 'Edit'} paperwork included</DialogTitle>
      <DialogContent>
        <Stack sx={{ pt: 1 }} spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              size="small"
              sx={{ width: '350px' }}
              label="Name (optional)"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {isCreate !== true && (
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={onClickDelete}
              >
                Delete
              </Button>
            )}
          </Stack>
          <DocumentTypeSequenceEditor
            documentTypes={documentTypes}
            setDocumentTypes={setDocumentTypes}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDocumentTypeSequenceModal;
