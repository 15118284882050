import { Button, Fade, TableCell, TableRow, Box, Dialog } from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { safeAdd } from 'shared/math';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { QuoteFragment, QuoteStatus } from '../../../../generated/graphql';
import ApproveQuoteModal from './approve-quote-area';

const QuoteRow = ({ quote }: { quote: QuoteFragment }) => {
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();
  const [showQuotesModal, setShowQuotesModal] = useState(false);
  return (
    <>
      {showQuotesModal && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={showQuotesModal}
          onClose={() => setShowQuotesModal(false)}
        >
          <ApproveQuoteModal
            open={showQuotesModal}
            setOpen={setShowQuotesModal}
            quoteUuid={quote.uuid}
            billingPartyContactUuid={quote.billingPartyContact.uuid}
            isFromQuoteProfile={false}
          />
        </Dialog>
      )}
      <TableRow
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <TableCell>{quote.number}</TableCell>
        <TableCell>{dayjs(quote.createdAt).format('MM/DD')}</TableCell>
        <TableCell>{quote.billingPartyContact.displayName}</TableCell>
        <TableCell>
          {quote.shipments[0]?.legs[0]?.endStop.address.name ?? 'None'}
        </TableCell>
        <TableCell>{sentenceCase(quote.status)}</TableCell>
        <TableCell>
          $
          {quote.shipments
            .reduce((prev, curr) => safeAdd(prev, curr.totalChargesAmount), 0)
            .toFixed(2)}
        </TableCell>
        <TableCell>
          <Fade in={isHovering}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Button
                onClick={() => {
                  navigate(`/order-entry/quotes/${quote.uuid}`);
                }}
                variant="contained"
              >
                Open
              </Button>
              {quote.status === QuoteStatus.Pending && (
                <Button
                  onClick={() => setShowQuotesModal(true)}
                  variant="contained"
                >
                  Approve
                </Button>
              )}
              {!isNil(quote.order) && (
                <ButtonLink
                  href={`/orders?orderUuid=${quote.order.uuid}`}
                  variant="contained"
                >
                  Open order
                </ButtonLink>
              )}
            </Box>
          </Fade>
        </TableCell>
      </TableRow>
    </>
  );
};

export default QuoteRow;
