import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  GetAppointmentsDocument,
  PermissionResource,
  useQueueAppointmentTextsMutation,
} from '../../../generated/graphql';
import { TextPreviewData } from '../types/text-preview-data';
import AppointmentsModal from './appointment-modal';
import SendTextsView from './send-texts-view';

interface TextAppointmentsButtonProps {
  textPreviewData: TextPreviewData[];
}

const TextAppointmentsButtonAndModal = ({
  textPreviewData,
}: TextAppointmentsButtonProps) => {
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showFailureSnackbar, setShowFailureSnackbar] = useState(false);

  const { userPermissions } = useUserRoles();
  const [queueAppointmentTextsMutation, { loading }] =
    useQueueAppointmentTextsMutation();
  const { canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Appointments,
  );

  const onSendMessagesHandler = async () => {
    const res = await queueAppointmentTextsMutation({
      variables: {
        queueAppointmentTextsArgs: {
          appointmentUuids: textPreviewData.map((data) => data.uuid),
        },
      },
      refetchQueries: [GetAppointmentsDocument],
    });

    // See resolver documentation for why this value is determined by whether some promises failed
    const noneFailed = res.data?.queueAppointmentTexts;
    if (noneFailed === false) {
      setShowFailureSnackbar(true);
    } else {
      setShowSuccessSnackbar(true);
    }
  };

  return (
    <>
      <AppointmentsModal
        type="text"
        isModalOpenButtonDisabled={textPreviewData.length === 0 || !canWrite}
        isSendButtonDisabled={loading || !canWrite}
        count={textPreviewData.length}
        appointmentsView={<SendTextsView textPreviewData={textPreviewData} />}
        onSendMessages={onSendMessagesHandler}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setShowSuccessSnackbar(false)}
        open={showSuccessSnackbar}
      >
        <Alert>Successfully sent texts</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setShowFailureSnackbar(false)}
        open={showFailureSnackbar}
      >
        <Alert severity="error">
          1 or more of your texts failed to send. Please ensure that each
          appointment is associated with a valid contact before re-attempting.
        </Alert>
      </Snackbar>
    </>
  );
};

export default TextAppointmentsButtonAndModal;
