import { Document } from '@react-pdf/renderer';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { PackageValues } from 'shared/types';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import { PackageFragment, WeightUnits } from '../../../../generated/graphql';
import { StopType } from '../../../orders/components/order-form/forms/stop-type';
import {
  AddressType,
  LabelType,
  getRoutingText,
  getShipperText,
} from '../../utils';
import GeneratedLotLabel from './generated-lot-label';

export interface LotLabelsDocumentProps {
  companyName: string;
  contact: string;
  orderName: string;
  mawb: string;
  hawb: string;
  shipperAddress?: AddressType;
  recoveryAddress?: AddressType;
  consigneeAddress?: AddressType;
  pcdCarrier?: string;
  pcpCarrier?: string;
  outboundFlightAirline?: string;
  packages: PackageValues[] | PackageFragment[];
  inboundStopType?: StopType;
  outboundStopType?: StopType;
  defaultWeightUnits?: WeightUnits;
  originTerminalCode?: string;
  outboundFlightOriginCode?: string;
  destinationTerminalCode?: string;
  destinationDetails?: string;
  widthDimension: number;
  heightDimension: number;
  rotate: boolean;
  terminalsEnabled?: boolean;
  totalLotLabels: number;
  appendPieceIdToLotLabel: boolean;
  inboundDate?: string;
  outboundDate?: string;
  refNumbers:
    | {
        refNumber: string;
        label?: string;
      }[]
    | undefined;
  inboundIataCode?: string;
  outboundIataCode?: string;
}
const GeneratedLotLabelsPdf = ({
  companyName,
  contact,
  orderName,
  mawb,
  hawb,
  shipperAddress,
  recoveryAddress,
  pcdCarrier,
  pcpCarrier,
  outboundFlightAirline,
  consigneeAddress,
  packages,
  inboundStopType,
  outboundStopType,
  defaultWeightUnits,
  originTerminalCode,
  outboundFlightOriginCode,
  destinationTerminalCode,
  destinationDetails,
  widthDimension,
  heightDimension,
  rotate,
  terminalsEnabled,
  totalLotLabels,
  appendPieceIdToLotLabel,
  inboundDate,
  outboundDate,
  refNumbers = [],
  inboundIataCode,
  outboundIataCode,
}: LotLabelsDocumentProps) => {
  const totalWeight = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.weight ?? 0));
  }, [packages]);

  const shipperText = getShipperText(
    LabelType.LotLabel,
    inboundStopType,
    shipperAddress,
    recoveryAddress,
    pcdCarrier,
  );

  const outboundText = getRoutingText(
    outboundStopType,
    outboundFlightAirline,
    pcpCarrier,
  );

  const originText =
    terminalsEnabled === true
      ? originTerminalCode?.toUpperCase()
      : outboundFlightOriginCode?.toUpperCase();

  let destinationText = destinationTerminalCode?.toUpperCase();

  if (!isNilOrEmptyString(destinationDetails?.trim())) {
    destinationText = destinationDetails?.toUpperCase();
  } else if (!isNilOrEmptyString(outboundIataCode?.trim())) {
    destinationText = outboundIataCode?.toUpperCase();
  }
  const lotLabels = [];
  for (
    let packageNumber = 1;
    packageNumber < totalLotLabels + 1;
    packageNumber += 1
  ) {
    lotLabels.push(
      <GeneratedLotLabel
        companyName={companyName}
        contact={contact}
        orderName={orderName}
        mawb={mawb}
        hawb={hawb}
        widthDimension={widthDimension}
        heightDimension={heightDimension}
        rotate={rotate}
        shipperText={shipperText ?? undefined}
        outboundText={outboundText}
        packageNumber={packageNumber}
        totalWeight={totalWeight}
        originText={
          isNilOrEmptyString(inboundIataCode) ? originText : inboundIataCode
        }
        destinationText={destinationText}
        totalQuantity={totalLotLabels}
        consigneeAddress={consigneeAddress}
        defaultWeightUnits={defaultWeightUnits}
        appendPieceIdToLotLabel={appendPieceIdToLotLabel}
        refNumbers={refNumbers}
        warehouseLocation={packages[packageNumber - 1]?.warehouseLocation?.name}
        inboundDate={inboundDate}
        outboundDate={outboundDate}
      />,
    );
  }

  return <Document>{lotLabels}</Document>;
};

export default GeneratedLotLabelsPdf;
