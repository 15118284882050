import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import {
  CustomDriverFormFragment,
  DocumentType,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type DocumentFormField = {
  uuid: string;
  fileName: string;
  bucket: string;
  region: string;
  key: string;
  preSignedGetUrl?: string;
  fileType: string;
  isDeleted?: boolean;
  name?: string | null;
  type: DocumentType;
  driverFormTemplate?: CustomDriverFormFragment | null | undefined;
  notes?: string | null | undefined;
};

type DocumentValuesStoreState = DocumentFormField;

const documentsValuesAdapter = createEntityAdapter<DocumentValuesStoreState>({
  selectId: (document) => document.uuid,
});

export const documentsValuesSlice = createSlice({
  name: 'documentsValues',
  initialState: documentsValuesAdapter.getInitialState(),
  reducers: {
    addDocument: documentsValuesAdapter.addOne,
    updateDocument: documentsValuesAdapter.updateOne,
    deleteDocument: documentsValuesAdapter.removeOne,
    updateDocuments: documentsValuesAdapter.updateMany,
    loadedDocuments: documentsValuesAdapter.setAll,
    upsertDocument: documentsValuesAdapter.upsertOne,
    upsertDocuments: documentsValuesAdapter.upsertMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectDocumentById,
  selectIds: selectDocumentIds,
  selectAll: selectDocuments,
  // Pass in a selector that returns the posts slice of state
} = documentsValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormDocumentsValues,
);

const documentValuesSelector = (state: RootState) =>
  state.orderFormDocumentsValues;

export const selectDocumentsByIds = createSelector(
  documentValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<DocumentFormField>, entityIds: EntityId[]) => {
    const entityResults: DocumentFormField[] = [];
    entityIds.forEach((id) => {
      const match = documentsValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addDocument,
  updateDocument,
  loadedDocuments,
  deleteDocument,
  upsertDocument,
  upsertDocuments,
} = documentsValuesSlice.actions;

export default documentsValuesSlice.reducer;
