import { Box, Typography } from '@mui/material';
import { isNil, isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { v4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import CommentInput from '../../../../../../common/components/comments/order-comments/comment-input';
import CommentsList from '../../../../../../common/components/comments/order-comments/comments-list';
import useMe from '../../../../../../common/react-hooks/use-me';
import {
  useCreateOrderCommentMutation,
  useDeleteOrderCommentMutation,
  useUpdateOrderCommentMutation,
} from '../../../../../../generated/graphql';
import useGlobalStore from '../../../../../../layouts/dashboard/global-store';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { OrderFormValues } from '../../forms/types';
import { convertCasing } from '../../forms/utils';

type CommentsProps = {
  isEditMode: boolean;
  showHeader?: boolean;
};

const Comments = ({ isEditMode, showHeader = true }: CommentsProps) => {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const { email, userUuid, useAllCaps } = useMe();

  const [textInput, setTextInput] = useState('');
  const { control, setValue } = useFormContext<OrderFormValues>();
  const [createComment] = useCreateOrderCommentMutation();
  const [updateOrderComment] = useUpdateOrderCommentMutation();
  const [deleteOrderComment] = useDeleteOrderCommentMutation();

  const uuid = useWatch({ control, name: 'uuid' });
  const existingComments = useWatch({ control, name: 'orderComments' }) ?? [];
  const newComment = useWatch({ control, name: 'newComment' }) ?? {};
  const oldNotes = useWatch({ control, name: 'notes' }) ?? [];

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  const onCreateComment = async () => {
    const comment = convertCasing(newComment.comment, useAllCaps);
    if (
      !isNil(newComment) &&
      !isNil(newComment.user) &&
      !isNil(newComment.user.uuid) &&
      !isNil(comment)
    ) {
      if (isEditMode) {
        const res = await createComment({
          variables: {
            createOrderCommentInput: {
              orderUuid: uuid,
              userUuid: newComment.user.uuid,
              comment,
            },
          },
        });
        const createdUuid = res.data?.createOrderComment.uuid;
        setValue('orderComments', [
          { ...newComment, comment, uuid: createdUuid },
          ...(existingComments ?? []),
        ]);
      } else {
        setValue('orderComments', [
          { ...newComment, comment, uuid: v4() },
          ...(existingComments ?? []),
        ]);
      }
      setValue('newComment', { ...newComment, comment: undefined });
      setTextInput('');
    }
  };

  const onEditOrderComment = async (
    commentUuid: string,
    newOrderComment: string,
  ) => {
    const showUpdateOrderCommentErrorMessage = () => {
      setErrorMessage(`Error updating order comment`);
      setShowErrorMessage(true);
    };

    if (isNilOrEmptyString(commentUuid)) {
      showUpdateOrderCommentErrorMessage();
      return;
    }

    try {
      await updateOrderComment({
        variables: {
          updateOrderCommentInput: {
            uuid: commentUuid,
            comment: newOrderComment,
          },
        },
      });
      const newComments = existingComments.map((existingComment) => {
        if (existingComment?.uuid === commentUuid) {
          return {
            ...existingComment,
            comment: newOrderComment,
          };
        }

        return existingComment;
      });
      setValue('orderComments', newComments);
      setSuccessMessage('Successfully updated order comment');
      setShowSuccessMessage(true);
    } catch (e) {
      showUpdateOrderCommentErrorMessage();
    }
  };

  const onCheckShowOnInvoice = async (commentUuid: string, value: boolean) => {
    await updateOrderComment({
      variables: {
        updateOrderCommentInput: {
          uuid: commentUuid,
          showOnInvoice: value,
        },
      },
    });
    const updatedComments = (existingComments ?? []).map((comment) => {
      if (comment?.uuid === commentUuid) {
        return {
          ...comment,
          showOnInvoice: value,
        };
      }
      return comment;
    });
    setValue('orderComments', updatedComments);
  };

  const onDeleteOrderComment = async (commentUuid: string) => {
    const showDeleteOrderCommentErrorMessage = () => {
      setErrorMessage(`Error deleting order comment`);
      setShowErrorMessage(true);
    };

    if (isNilOrEmptyString(commentUuid)) {
      showDeleteOrderCommentErrorMessage();
      return;
    }

    try {
      const deleteOrderCommentResponse = await deleteOrderComment({
        variables: {
          deleteOrderCommentInput: {
            uuid: commentUuid,
          },
        },
      });
      if (
        deleteOrderCommentResponse.data?.deleteOrderComment.__typename ===
        'DeleteOrderCommentSuccessOutput'
      ) {
        const updatedComments = (existingComments ?? []).filter(
          (comment) => comment?.uuid !== commentUuid,
        );
        setValue('orderComments', updatedComments);
        setSuccessMessage('Successfully deleted order comment');
        setShowSuccessMessage(true);
      } else {
        showDeleteOrderCommentErrorMessage();
      }
    } catch (e) {
      showDeleteOrderCommentErrorMessage();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value);

    if (isNilOrEmptyString(e.target.value)) {
      setValue('newComment', null);
    } else {
      setValue('newComment', {
        comment: e.target.value,
        user: {
          uuid: userUuid,
          email,
        },
        updatedAt: new Date(),
      });
    }
  };

  return (
    <Box>
      {showHeader && (
        <Typography variant="h6" sx={{ fontSize: '16px' }}>
          Notes
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CommentInput
          value={textInput ?? ''}
          onChange={onChange}
          onSubmit={onCreateComment}
          disabled={disabledIfNoAccess}
          inputProps={
            useAllCaps ? { style: { textTransform: 'uppercase' } } : {}
          }
        />
        <CommentsList
          comments={existingComments ?? []}
          onEditComment={onEditOrderComment}
          onCheckShowOnInvoice={onCheckShowOnInvoice}
          onDeleteComment={onDeleteOrderComment}
        />
        {!isNil(oldNotes) && !isEmpty(oldNotes) && (
          <Box
            sx={{
              margin: '3px',
              marginBottom: '6px',
              padding: '8px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                width: '30%',
              }}
            >
              <Typography sx={{ color: 'gray' }}>Older notes</Typography>
            </Box>
            <Box
              sx={{
                width: '70%',
              }}
            >
              {(typeof oldNotes === 'string' ? oldNotes : '-')
                .split('\n')
                .map((chunk: string, index: number) => {
                  return (
                    <Typography
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      style={{ wordWrap: 'break-word' }}
                    >
                      {chunk}
                    </Typography>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Comments;
