import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import {
  Dispatch,
  Fragment,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
} from 'react';
import type { StopFragment } from '../../../generated/graphql';

const TableHeader: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Typography color="text.secondary" fontWeight={500}>
    {children}
  </Typography>
);

const OrderCol: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box minWidth="80px" maxWidth="80">
    {children}
  </Box>
);

const CustomerCol: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box minWidth="170px" maxWidth="170px">
    {children}
  </Box>
);

const ConsigneeCol: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box flexGrow={1}>{children}</Box>
);

const ZipCol: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box minWidth="88px" maxWidth="88px">
    {children}
  </Box>
);

const StopTypeCol: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box minWidth="100px" maxWidth="100px">
    {children}
  </Box>
);

const ServiceCol: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Box minWidth="110px" maxWidth="110px">
    {children}
  </Box>
);

type ConfigureProposalsProps = {
  stops: StopFragment[];
  enableText: boolean;
  setEnableText: Dispatch<SetStateAction<boolean>>;
  enableCall: boolean;
  setEnableCall: Dispatch<SetStateAction<boolean>>;
};

export const ConfigureProposals: FunctionComponent<ConfigureProposalsProps> = ({
  stops,
  enableText,
  setEnableText,
  enableCall,
  setEnableCall,
}) => (
  <>
    <Stack direction="row" p={2} gap={2}>
      <Box width="270px">
        <Typography color="text.secondary" fontSize="15px" fontWeight={500}>
          Contact method
        </Typography>
        <Stack direction="row" gap={2}>
          <FormControlLabel
            label="Text"
            control={
              <Checkbox
                checked={enableText}
                onChange={() => {
                  setEnableText((prevEnableText) => !prevEnableText);
                }}
              />
            }
          />
          <FormControlLabel
            label="Call"
            control={
              <Checkbox
                checked={enableCall}
                onChange={() => {
                  setEnableCall((prevEnableCall) => !prevEnableCall);
                }}
              />
            }
          />
        </Stack>
      </Box>
      <Box>
        <Typography
          color="text.secondary"
          fontSize="15px"
          fontWeight={500}
          mb={1}
        >
          Text preview
        </Typography>
        <Typography
          fontSize="15px"
          p={2}
          sx={{
            backgroundColor: 'rgba(255, 243, 224, 0.50)',
          }}
        >
          Please select a time window for your{' '}
          <Typography component="span" color="text.secondary">
            {'{stop type}'}
          </Typography>{' '}
          appointment to{' '}
          <Typography component="span" color="text.secondary">
            {'{address name}'}
          </Typography>{' '}
          within the next two hours. After that, your appointment may be
          scheduled automatically. Select a time using this link:{' '}
          <Typography component="span" color="text.secondary">
            {'{link}'}
          </Typography>
          <br />
          Call{' '}
          <Typography component="span" color="text.secondary">
            {'{customer service phone}'}
          </Typography>{' '}
          for questions.
        </Typography>
      </Box>
    </Stack>
    <Stack direction="row" px={2} mt={2} mb={1} gap={1}>
      <OrderCol>
        <TableHeader>Order</TableHeader>
      </OrderCol>
      <CustomerCol>
        <TableHeader>Customer</TableHeader>
      </CustomerCol>
      <ConsigneeCol>
        <TableHeader>Address Name</TableHeader>
      </ConsigneeCol>
      <ZipCol>
        <TableHeader>Zip</TableHeader>
      </ZipCol>
      <StopTypeCol>
        <TableHeader>Stop Type</TableHeader>
      </StopTypeCol>
      <ServiceCol>
        <TableHeader>Service</TableHeader>
      </ServiceCol>
    </Stack>
    {stops.map(({ stop, order }) => (
      <Fragment key={stop.uuid}>
        <Divider />
        <Stack direction="row" p={2} gap={1}>
          <OrderCol>
            <Typography fontSize="15px">{order?.name}</Typography>
          </OrderCol>
          <CustomerCol>
            <Typography fontSize="15px">{order?.contactName}</Typography>
          </CustomerCol>
          <ConsigneeCol>
            <Typography fontSize="15px">{stop.address?.name}</Typography>
          </ConsigneeCol>
          <ZipCol>
            <Typography fontSize="15px">{stop.address?.zip}</Typography>
          </ZipCol>
          <StopTypeCol>
            <Typography fontSize="15px">
              {isNil(stop.stopType) ? null : sentenceCase(stop.stopType)}
            </Typography>
          </StopTypeCol>
          <ServiceCol>
            <Typography fontSize="15px">{order?.serviceName}</Typography>
          </ServiceCol>
        </Stack>
      </Fragment>
    ))}
  </>
);
