import { MenuItem, Select, Typography } from '@mui/material';
import { isEmpty, isNil, uniq } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWmsContactsQuery } from '../../../../generated/graphql';
import InventoryPage from '../../../inventory/components/inventory-page';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../../constants';
import useCustomerPortalStore from '../../use-customer-portal-store';
import CustomerPortalAppBar from '../customer-portal-app-bar';

const CustomerPortalInventory = () => {
  const navigate = useNavigate();
  const { data: wmsContacts, loading } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const companies = useMemo(() => {
    return uniq(
      wmsContacts?.wmsContacts?.map((contact) => contact.company) ?? [],
    );
  }, [wmsContacts]);
  const { company, setCompany } = useCustomerPortalStore((state) => ({
    company: state.company,
    setCompany: state.setCompany,
  }));

  useEffect(() => {
    if (!loading && isEmpty(companies)) {
      // if there are no companies with WMS inventory for this user, redirect to orders page
      setCompany(null);
      navigate(CUSTOMER_PORTAL_ORDERS_PATH);
      return;
    }
    if (isNil(company) && !isEmpty(companies)) {
      setCompany(companies[0] ?? null);
    }
  }, [loading, company, companies, setCompany, navigate]);

  return (
    <>
      <CustomerPortalAppBar>
        <Typography color="white">Inventory for</Typography>
        {companies.length === 1 ? (
          <Typography color="white">{companies[0]?.name}</Typography>
        ) : (
          <Select<string>
            renderValue={() => (
              <Typography color="white">{company?.name}</Typography>
            )}
            onChange={(e) => {
              const selectedCompany = companies.find(
                (c) => c.uuid === e.target.value,
              );
              setCompany(selectedCompany ?? null);
            }}
            value={company?.uuid ?? ''}
            sx={{ outline: 'none' }}
            size="small"
          >
            {companies.map((c) => (
              <MenuItem key={c.uuid} value={c.uuid}>
                <Typography>{c.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        )}
      </CustomerPortalAppBar>
      <InventoryPage isCustomerPortal />
    </>
  );
};

export default CustomerPortalInventory;
