import { isEmpty, isNil } from 'lodash';
import { Resolver, useForm } from 'react-hook-form';

export type RoleFormValues = {
  name: string;
};

const resolver: Resolver<RoleFormValues> = async (formValues) => {
  const errors: {
    [property in keyof RoleFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  const { name } = formValues;

  if (isNil(name) || isEmpty(name)) {
    errors.name = {
      type: 'required',
      message: 'Name is required',
    };
  }

  return {
    values: formValues,
    errors,
  };
};

const useImportAccessorialForm = () => {
  return useForm({ resolver });
};

export default useImportAccessorialForm;
