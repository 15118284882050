import { Document } from '@react-pdf/renderer';
import {
  OrderPodReportShipmentFragment,
  Segment,
} from '../../../generated/graphql';
import { PodReportComponent } from './generated-pod-report-pdf';

const GeneratedCustomerChargesReportPdf = ({
  segment,
  shipments,
}: {
  segment: Segment | undefined;
  shipments: OrderPodReportShipmentFragment[];
}) => {
  return (
    <Document title="POD Report">
      {shipments.map((shipment) => {
        return (
          <PodReportComponent
            segment={segment}
            showCharges
            shipment={shipment}
          />
        );
      })}
    </Document>
  );
};

export default GeneratedCustomerChargesReportPdf;
