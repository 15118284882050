import { TableRow, TableCell, Button } from '@mui/material';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';
import DeleteThirdPartyUserModal from './third-party-user/delete-third-party-user-modal';

const ThirdPartyUserRow = ({
  email,
  userUuid,
  contactUuid,
}: {
  email: string;
  userUuid: string;
  contactUuid: string;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>{email}</TableCell>
        <TableCell>
          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={() => setIsDeleteModalOpen(true)}
            disabled={!canWriteContacts}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <DeleteThirdPartyUserModal
        isDeleteUserModalOpen={isDeleteModalOpen}
        setIsDeleteUserModalOpen={setIsDeleteModalOpen}
        userUuid={userUuid}
        contactUuid={contactUuid}
        email={email}
      />
    </>
  );
};

export default ThirdPartyUserRow;
