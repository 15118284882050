import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import {
  FindReportSendJobBatchesFields,
  ReportSendJobBatchFragment,
  SortDirection,
  useReportSendJobBatchesLazyQuery,
} from '../../../../generated/graphql';
import ReportSendJobBatchRow from './report-send-job-batch-row';

const DEFAULT_ROWS_PER_PAGE = 10;

const ReportSendJobsBatchList = ({
  open,
  setSelectedReportSendJobBatch,
}: {
  open: boolean;
  setSelectedReportSendJobBatch: Dispatch<
    SetStateAction<ReportSendJobBatchFragment | undefined>
  >;
}) => {
  const { height } = useWindowDimensions();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [sortByCreatedAtAsc, setSortByCreatedAtAsc] = useState<boolean>(false);
  const [
    getReportSendJobBatches,
    { data: reportSendJobBatchesData, startPolling, stopPolling, loading },
  ] = useReportSendJobBatchesLazyQuery();

  const fetchReportSendJobBatches = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const res = await getReportSendJobBatches({
      variables: {
        findReportSendJobBatchesInput: {
          first,
          after,
          last,
          before,
          // TODO: Use a generalized sort construction framework
          sorts: [
            {
              sortDirection: sortByCreatedAtAsc
                ? SortDirection.Asc
                : SortDirection.Desc,
              sortBy: FindReportSendJobBatchesFields.CreatedAtDate,
            },
          ],
        },
      },
    });
    setTotalCount(
      res.data?.reportSendJobBatches.reportSendJobBatchConnection.totalCount ??
        0,
    );
  };

  const refresh = () => {
    fetchReportSendJobBatches({
      first: DEFAULT_ROWS_PER_PAGE,
    });
    setPage(0);
  };

  const prev = async (newPage: number) => {
    await fetchReportSendJobBatches({
      last: DEFAULT_ROWS_PER_PAGE,
      before:
        reportSendJobBatchesData?.reportSendJobBatches
          .reportSendJobBatchConnection.pageInfo?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchReportSendJobBatches({
      first: DEFAULT_ROWS_PER_PAGE,
      after:
        reportSendJobBatchesData?.reportSendJobBatches
          .reportSendJobBatchConnection.pageInfo?.endCursor ?? undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    if (open) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByCreatedAtAsc]);

  useEffect(() => {
    if (open) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [open, startPolling, stopPolling]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <TableContainer
      sx={{
        maxHeight: height - 305,
        overflowY: 'scroll',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
      >
        <Grid item xs={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Statements send progress
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TablePagination
            labelRowsPerPage="Show"
            rowsPerPageOptions={[]}
            component="div"
            count={totalCount}
            rowsPerPage={DEFAULT_ROWS_PER_PAGE}
            page={page}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next(newPage);
              } else {
                prev(newPage);
              }
            }}
          />
        </Grid>
      </Grid>

      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active
                direction={sortByCreatedAtAsc ? 'asc' : 'desc'}
                hideSortIcon={false}
                onClick={() => {
                  setSortByCreatedAtAsc(!sortByCreatedAtAsc);
                }}
              >
                Started at
              </TableSortLabel>
            </TableCell>
            <TableCell>Report Type</TableCell>
            <TableCell>Progress (finished / total)</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {reportSendJobBatchesData?.reportSendJobBatches.reportSendJobBatchConnection.edges.map(
            ({ node: reportSendJobBatch }) => (
              <ReportSendJobBatchRow
                key={reportSendJobBatch.id}
                reportSendJobBatch={reportSendJobBatch}
                setSelectedReportSendJobBatch={setSelectedReportSendJobBatch}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportSendJobsBatchList;
