import { exhaustive } from 'shared/switch';

export enum ReportFilterType {
  REPORT_TYPE = 'REPORT_TYPE',
  REVENUE_TYPE = 'REVENUE_TYPE',
  SERVICE_DATE_RANGE = 'SERVICE_DATE_RANGE',
  AGGREGATION_PERIOD = 'AGGREGATION_PERIOD',
  CONTACT = 'CONTACT',
  DRIVER = 'DRIVER',
  STOP_TYPE = 'STOP_TYPE',

  BUSINESS_DIVISION = 'BUSINESS_DIVISION',
  TERMINAL = 'TERMINAL',

  CONTACTS = 'CONTACTS',
  DRIVERS = 'DRIVERS',

  // Unused
  ORDER_STATUS = 'ORDER_STATUS',
}

export enum ReportFilterDateRangeType {
  ALL = 'ALL',
  LAST_NUMBER_OF_DAYS = 'LAST_NUMBER_OF_DAYS',
  LAST_NUMBER_OF_WEEKS = 'LAST_NUMBER_OF_WEEKS',
  LAST_NUMBER_OF_MONTHS = 'LAST_NUMBER_OF_MONTHS',
  RANGE = 'RANGE',
}

export const convertReportFilterTypeToTitle = (type: ReportFilterType) => {
  switch (type) {
    case ReportFilterType.REPORT_TYPE:
      return 'Report Type';
    case ReportFilterType.REVENUE_TYPE:
      return 'Revenue';
    case ReportFilterType.SERVICE_DATE_RANGE:
      return 'Service Date Range';
    case ReportFilterType.AGGREGATION_PERIOD:
      return 'Time Period';
    case ReportFilterType.STOP_TYPE:
      return 'Stop Type';
    case ReportFilterType.ORDER_STATUS:
      return 'Order Status';
    case ReportFilterType.CONTACT:
      return 'Customer';
    case ReportFilterType.CONTACTS:
      return 'Customers';
    case ReportFilterType.DRIVER:
      return 'Driver';
    case ReportFilterType.DRIVERS:
      return 'Drivers';
    case ReportFilterType.BUSINESS_DIVISION:
      return 'Business Division';
    case ReportFilterType.TERMINAL:
      return 'Terminal';
    default:
      return exhaustive(type);
  }
};

export type Option = {
  value: string | undefined;
  label: string | undefined;
};
