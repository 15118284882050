import {
  Alert,
  Box,
  Button,
  Snackbar,
  Fade,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil, sortBy } from 'lodash';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  FuelProfileFragment,
  FuelProfileType,
  PermissionResource,
  useFuelProfilesQuery,
} from '../../../../generated/graphql';
import CreateOrEdit from '../../enums/create-or-edit';
import FuelProfileModal from './fuel-profile-modal';

const FuelProfilesTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyFuelProfiles } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyFuelProfiles,
  );

  const [open, setOpen] = useState(false);
  const [hoveredFuelProfileUuid, setHoveredFuelProfileUuid] =
    useState<string>();
  const [selectedFuelProfile, setSelectedFuelProfile] = useState<
    FuelProfileFragment | undefined
  >(undefined);
  const [mode, setMode] = useState<CreateOrEdit>(CreateOrEdit.Create);
  const { data: companyFuelProfiles } = useFuelProfilesQuery();
  const [
    updateFuelProfileSuccessMessageVisible,
    setUpdateFuelProfileSuccessMessageVisible,
  ] = useState(false);
  const [
    updateFuelProfileErrorMessageVisible,
    setUpdateFuelProfileErrorMessageVisible,
  ] = useState(false);

  const formatFuelProfileType = (fuelProfileType: FuelProfileType) => {
    switch (fuelProfileType) {
      case FuelProfileType.ContactSpecific: {
        return 'Contact specific';
      }
      case FuelProfileType.Global: {
        return 'Global';
      }
      default:
        return exhaustive(fuelProfileType);
    }
  };

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={updateFuelProfileSuccessMessageVisible}
        onClose={() => setUpdateFuelProfileSuccessMessageVisible(false)}
      >
        <Alert onClose={() => setUpdateFuelProfileSuccessMessageVisible(false)}>
          Saved fuel profile
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setUpdateFuelProfileErrorMessageVisible(false)}
        open={updateFuelProfileErrorMessageVisible}
      >
        <Alert
          severity="error"
          onClose={() => setUpdateFuelProfileErrorMessageVisible(false)}
        >
          Error saving fuel profile
        </Alert>
      </Snackbar>
      <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Contacts</TableCell>
                <TableCell align="right" sx={{ width: '200px' }}>
                  <Button
                    onClick={() => {
                      setMode(CreateOrEdit.Create);
                      setOpen(true);
                    }}
                    variant="contained"
                    disabled={!canWriteCompanyFuelProfiles}
                  >
                    Add Fuel Profile
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBy(companyFuelProfiles?.fuelProfiles, 'name').map(
                (fuelProfile) => {
                  return (
                    <TableRow
                      onMouseEnter={() => {
                        setHoveredFuelProfileUuid(fuelProfile.uuid);
                      }}
                      onMouseLeave={() => {
                        setHoveredFuelProfileUuid(undefined);
                      }}
                      key={fuelProfile.uuid}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>
                        <Box>
                          <Typography variant="body1">
                            {isEmpty(fuelProfile.name)
                              ? 'None'
                              : fuelProfile.name ?? 'None'}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="body1">
                            {sentenceCase(fuelProfile.billingType)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography variant="body1">
                            {formatFuelProfileType(fuelProfile.type)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography>
                            {isNil(fuelProfile.contacts) ||
                            fuelProfile.contacts.length === 0
                              ? 'None'
                              : fuelProfile.contacts
                                  ?.map((contact) => contact.displayName)
                                  .join(', ')}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Fade in={hoveredFuelProfileUuid === fuelProfile.uuid}>
                          <Button
                            onClick={() => {
                              setSelectedFuelProfile(fuelProfile);
                              setMode(CreateOrEdit.Edit);
                              setOpen(true);
                            }}
                            variant="contained"
                            disabled={!canWriteCompanyFuelProfiles}
                          >
                            Edit
                          </Button>
                        </Fade>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {open && (
        <FuelProfileModal
          selectedFuelProfile={selectedFuelProfile}
          setSelectedFuelProfile={setSelectedFuelProfile}
          open={open}
          setOpen={setOpen}
          createOrEdit={mode}
          setUpdateFuelProfileSuccessMessageVisible={
            setUpdateFuelProfileSuccessMessageVisible
          }
          setUpdateFuelProfileErrorMessageVisible={
            setUpdateFuelProfileErrorMessageVisible
          }
        />
      )}
    </>
  );
};

export default FuelProfilesTable;
