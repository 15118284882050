// eslint-disable-next-line no-restricted-imports
import { Grid, Card, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaddingTabPanel } from '../../../common/components/tab-panel';
import useMe from '../../../common/react-hooks/use-me';
import { titleCase } from '../../daily-control-center/utils';
import AccessorialsTable from '../../management/components/accessorials/accessorials-table';
import BillingPageTabs from '../enums/billing-page-tabs';
import ContactDiscountSettings from './contact-discount-settings';
import ContactInvoicingSettings from './contact-invoices-settings';
import ContactPaymentsSettings from './contact-payments-settings';
import ContactQuickbooksFileMappings from './contact-qb-file-mappings';
// eslint-disable-next-line import/no-named-as-default
import FuelProfilesTableForContact from './fuel-profiles-for-contact';
import QuickbooksDesktopContactMapping from './quickbooks-desktop-contact';

const parseTabQueryParam = (tab: string | null): BillingPageTabs => {
  if (Object.values(BillingPageTabs).includes(tab as BillingPageTabs)) {
    return tab as BillingPageTabs;
  }
  return BillingPageTabs.INVOICES;
};

const BillingPanel = ({ contactUuid }: { contactUuid: string }) => {
  const [searchParams] = useSearchParams();
  const billingTab = searchParams.get('billingTab');
  const [currentTab, setCurrentTab] = useState(parseTabQueryParam(billingTab));
  const { quickbooksDesktopEnabled } = useMe();
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: BillingPageTabs,
  ) => {
    setCurrentTab(newValue);
  };

  type ContactBillingSettingsTab = {
    tab: BillingPageTabs;
    component: React.ReactNode;
    visibilityCondition?: boolean;
  };

  const tabs: ContactBillingSettingsTab[] = [
    {
      tab: BillingPageTabs.INVOICES,
      component: <ContactInvoicingSettings contactUuid={contactUuid} />,
    },
    {
      tab: BillingPageTabs.ACCESSORIALS,
      component: <AccessorialsTable contactUuid={contactUuid} />,
    },
    {
      tab: BillingPageTabs.QUICKBOOKS,
      component: <ContactQuickbooksFileMappings contactUuid={contactUuid} />,
    },
    {
      tab: BillingPageTabs.QUICKBOOKS_DESKTOP,
      component: <QuickbooksDesktopContactMapping contactUuid={contactUuid} />,
      visibilityCondition: quickbooksDesktopEnabled,
    },
    {
      tab: BillingPageTabs.FUEL_PROFILES,
      component: <FuelProfilesTableForContact contactUuid={contactUuid} />,
    },
    {
      tab: BillingPageTabs.PAYMENTS,
      component: <ContactPaymentsSettings contactUuid={contactUuid} />,
    },
    {
      tab: BillingPageTabs.DISCOUNTS,
      component: <ContactDiscountSettings contactUuid={contactUuid} />,
    },
  ];

  const filteredTabs = tabs.filter((tab) => tab.visibilityCondition !== false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Card sx={{ my: 5, width: '100%', height: '80vh' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentTab}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {filteredTabs.map(({ tab }) => (
              <Tab key={tab} label={titleCase(tab)} value={tab} />
            ))}
          </Tabs>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <Card sx={{ my: 5, width: '100%', height: '80vh', overflow: 'scroll' }}>
          {filteredTabs.map(({ tab, component }) => (
            <PaddingTabPanel
              key={tab}
              panelValue={tab}
              selectedValue={currentTab}
            >
              {component}
            </PaddingTabPanel>
          ))}
        </Card>
      </Grid>
    </Grid>
  );
};

export default BillingPanel;
