import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import {
  useDeleteWarehouseLocationMutation,
  WarehouseLocationsDocument,
  WarehouseLocationsLocationFragment,
} from '../../../../generated/graphql';

interface ConfirmDeleteWarehouseLocationModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  selectedWarehouseLocation: WarehouseLocationsLocationFragment | undefined;
  setSelectedWarehouseLocation: Dispatch<
    SetStateAction<WarehouseLocationsLocationFragment | undefined>
  >;
}

const ConfirmDeleteWarehouseLocationModal = ({
  open,
  setOpen,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
  selectedWarehouseLocation,
  setSelectedWarehouseLocation,
}: ConfirmDeleteWarehouseLocationModalProps) => {
  const handleClose = () => {
    setOpen(false);
    setSelectedWarehouseLocation(undefined);
  };

  const [deleteWarehouseLocation, { loading: deleteWarehouseLocationLoading }] =
    useDeleteWarehouseLocationMutation({
      refetchQueries: [WarehouseLocationsDocument],
    });
  const handleDelete = async () => {
    try {
      const uuid = selectedWarehouseLocation?.uuid;
      if (isNil(uuid)) {
        setErrorSnackbarVisible(true);
        return;
      }
      const response = await deleteWarehouseLocation({
        variables: {
          deleteWarehouseLocationInput: {
            uuid,
          },
        },
      });
      const success = response?.data?.deleteWarehouseLocation?.success;
      if (success === true) {
        setSuccessSnackbarVisible(true);
        handleClose();
      } else {
        setErrorSnackbarVisible(true);
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Delete Warehouse Location</DialogTitle>
      <DialogContent>
        <Typography>{`Are you sure you want to delete location ${
          selectedWarehouseLocation?.name ?? '-'
        }?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={deleteWarehouseLocationLoading}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            disabled={deleteWarehouseLocationLoading}
            size="small"
          >
            Delete
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteWarehouseLocationModal;
