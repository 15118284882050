import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../common/react-hooks/use-me';
import useServices from '../../common/react-hooks/use-services';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  CompanyDefaultEdiSettingsDocument,
  MeDocument,
  OrderIdentifier,
  OrderNamingScheme,
  PermissionResource,
  useCompanyDefaultEdiSettingsQuery,
  useCreateManyReferenceNumberLabelsMutation,
  useUpdateCompanyConfigurationMutation,
  useUpdateCompanyMutation,
} from '../../generated/graphql';
import OrderFormFields from './components/order-form-fields';
import ReferenceNumberLabels from './components/reference-number-labels';

const NONE_SERVICE = 'None';

const DefaultEdiServiceSelect = ({
  disabled,
  onChange,
  value,
}: {
  disabled: boolean;
  onChange: (value: string | null | undefined) => void;
  value: string | null | undefined;
}) => {
  const { getServiceName, services } = useServices();
  return (
    <FormControl sx={{ width: '30%' }}>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        renderValue={(selected) => {
          if (selected === NONE_SERVICE) {
            return <Typography>{sentenceCase(selected)}</Typography>;
          }
          return (
            <Typography>{sentenceCase(getServiceName(selected))} </Typography>
          );
        }}
        required
        size="small"
        sx={{ backgroundColor: 'white' }}
        disabled={disabled}
      >
        <MenuItem key={NONE_SERVICE} value={NONE_SERVICE}>
          {sentenceCase(NONE_SERVICE)}
        </MenuItem>
        {services?.map((service) => (
          <MenuItem key={service.uuid} value={service.uuid}>
            {sentenceCase(service.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const OrderSettings = () => {
  const { companyData, companyConfiguration, refetchMeData } = useMe();
  const { data: companyDefaultEdiSettingsData } =
    useCompanyDefaultEdiSettingsQuery();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsOrders,
  );

  const [orderNamingScheme, setOrderNamingScheme] =
    useState<OrderNamingScheme | null>(null);
  const [companyOrdinalStart, setCompanyOrdinalStart] = useState<
    number | undefined | null
  >(null);
  const [
    minDigitsInCompanyOrdinalOrderName,
    setMinDigitsInCompanyOrdinalOrderName,
  ] = useState<number | undefined | null>(null);
  const [orderIdentifierForCoverSheet, setOrderIdentifierForCoverSheet] =
    useState<OrderIdentifier | null>(null);
  const [unsavedOrderNameChangesMessage, setUnsavedOrderNameChangesMessage] =
    useState<string>('');
  const [defaultInboundEdiService, setDefaultInboundEdiService] = useState<
    string | null | undefined
  >(NONE_SERVICE);
  const [defaultOutboundEdiService, setDefaultOutboundEdiService] = useState<
    string | null | undefined
  >(NONE_SERVICE);
  const [
    defaultInboundOutboundEdiService,
    setDefaultInboundOutboundEdiService,
  ] = useState<string | null | undefined>(NONE_SERVICE);
  const [refNumberLabels, setRefNumberLabels] = useState<string[]>(() => {
    if (
      !isNil(companyData) &&
      !isNil(companyData?.referenceNumberLabels) &&
      companyData?.referenceNumberLabels.length !== 0
    ) {
      return companyData.referenceNumberLabels.map((label) => label.name);
    }
    return Array(1).fill('');
  });
  const [updateCompanyConfiguration, { loading: companyConfigurationSaving }] =
    useUpdateCompanyConfigurationMutation({
      refetchQueries: [MeDocument],
    });
  const [updateCompanyMutation, { loading: companySaving }] =
    useUpdateCompanyMutation({
      refetchQueries: [CompanyDefaultEdiSettingsDocument],
    });
  const [createManyReferenceNumberLabels] =
    useCreateManyReferenceNumberLabelsMutation();

  const orderNamingSchemaDescriptions = {
    COMPANY_ORDINAL: 'Company ordinal (e.g. 0001234)',
    CONTACT_ORDINAL: 'Contact ordinal (e.g. CONTACT-123)',
  };

  useEffect(() => {
    if (!isNil(companyDefaultEdiSettingsData)) {
      setDefaultInboundEdiService(
        companyDefaultEdiSettingsData.company.defaultInboundEdiService?.uuid,
      );
      setDefaultOutboundEdiService(
        companyDefaultEdiSettingsData.company.defaultOutboundEdiService?.uuid,
      );
      setDefaultInboundOutboundEdiService(
        companyDefaultEdiSettingsData.company.defaultInboundOutboundEdiService
          ?.uuid,
      );
    }
  }, [companyDefaultEdiSettingsData]);

  useEffect(() => {
    setOrderNamingScheme(companyConfiguration?.orderNamingScheme ?? null);
    setOrderIdentifierForCoverSheet(
      companyConfiguration?.orderIdentifierForCoverSheet ?? null,
    );
    setCompanyOrdinalStart(companyConfiguration?.companyOrdinalStart ?? null);
    setMinDigitsInCompanyOrdinalOrderName(
      companyConfiguration?.minDigitsInCompanyOrdinalOrderName,
    );
  }, [companyConfiguration]);

  useEffect(() => {
    if (
      orderNamingScheme !== companyConfiguration?.orderNamingScheme ||
      companyOrdinalStart !== companyConfiguration?.companyOrdinalStart ||
      minDigitsInCompanyOrdinalOrderName !==
        companyConfiguration?.minDigitsInCompanyOrdinalOrderName
    ) {
      setUnsavedOrderNameChangesMessage('Unsaved changes');
    } else {
      setUnsavedOrderNameChangesMessage('');
    }
  }, [
    companyConfiguration,
    orderNamingScheme,
    companyOrdinalStart,
    minDigitsInCompanyOrdinalOrderName,
  ]);

  const handleAddLabel = () => {
    setRefNumberLabels((prevLabels) => [...prevLabels, '']);
  };

  const handleRefNumberLabelChange = (index: number, value: string) => {
    setRefNumberLabels((prevNumbers) =>
      prevNumbers.map((label, idx) => (idx === index ? value : label)),
    );
  };

  const handleDeleteLabel = (index: number) => {
    setRefNumberLabels((prevNumbers) =>
      prevNumbers.filter((_, idx) => idx !== index),
    );
  };

  const updateOrderNamingSchemeConfig = async () => {
    const configUuid = companyConfiguration?.uuid;
    if (!isNil(configUuid)) {
      await Promise.all([
        updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              orderNamingScheme: !isNil(orderNamingScheme)
                ? orderNamingScheme
                : null,
              companyOrdinalStart: !isNil(companyOrdinalStart)
                ? companyOrdinalStart
                : null,
              minDigitsInCompanyOrdinalOrderName: !isNil(
                minDigitsInCompanyOrdinalOrderName,
              )
                ? minDigitsInCompanyOrdinalOrderName
                : null,
            },
          },
          refetchQueries: [MeDocument],
        }),
        createManyReferenceNumberLabels({
          variables: {
            referenceNumberLabels: refNumberLabels,
          },
        }),
      ]);
      refetchMeData();
    } else {
      // eslint-disable-next-line no-console
      console.error('No company configuration found, could not save');
    }
  };

  const updateCompanyConfig = async ({
    restrictEditingOrderName,
    logisticsSectionEnabled,
    requireDimsToFinalizeCharges,
    _orderIdentifierForCoverSheet,
  }: {
    restrictEditingOrderName?: boolean;
    logisticsSectionEnabled?: boolean;
    requireDimsToFinalizeCharges?: boolean;
    _orderIdentifierForCoverSheet?: OrderIdentifier;
  }) => {
    const configUuid = companyConfiguration?.uuid;
    if (!isNil(configUuid)) {
      await updateCompanyConfiguration({
        variables: {
          updateCompanyConfigurationInput: {
            uuid: configUuid,
            restrictEditingOrderName,
            logisticsSectionEnabled,
            requireDimsToFinalizeCharges,
            orderIdentifierForCoverSheet: _orderIdentifierForCoverSheet,
          },
        },
        refetchQueries: [MeDocument],
      });
      refetchMeData();
    } else {
      // eslint-disable-next-line no-console
      console.error('No company configuration found, could not save');
    }
  };

  const updateCompanyHandler = async ({
    defaultInboundEdiServiceUuid,
    defaultOutboundEdiServiceUuid,
    defaultInboundOutboundEdiServiceUuid,
  }: {
    defaultInboundEdiServiceUuid?: string | null;
    defaultOutboundEdiServiceUuid?: string | null;
    defaultInboundOutboundEdiServiceUuid?: string | null;
  }) => {
    await updateCompanyMutation({
      variables: {
        updateCompanyInput: {
          defaultInboundEdiServiceUuid:
            defaultInboundEdiServiceUuid === NONE_SERVICE
              ? null
              : defaultInboundEdiServiceUuid,
          defaultOutboundEdiServiceUuid:
            defaultOutboundEdiServiceUuid === NONE_SERVICE
              ? null
              : defaultOutboundEdiServiceUuid,
          defaultInboundOutboundEdiServiceUuid:
            defaultInboundOutboundEdiServiceUuid === NONE_SERVICE
              ? null
              : defaultInboundOutboundEdiServiceUuid,
        },
      },
      refetchQueries: [CompanyDefaultEdiSettingsDocument],
    });
  };

  return (
    <Box sx={{ pr: 1 }}>
      <Stack direction="column" spacing={1}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: '12px', color: 'gray', float: 'right' }}
            >
              {companyConfigurationSaving || companySaving
                ? 'Saving...'
                : 'Saved'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={5} xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Order Naming Scheme
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                value={orderNamingScheme}
                onChange={(e) => {
                  setOrderNamingScheme(e.target.value as OrderNamingScheme);
                }}
                required
                size="small"
                sx={{ backgroundColor: 'white' }}
                disabled={!canWriteSettingsOrders}
              >
                {Object.values(OrderNamingScheme).map((scheme) => (
                  <MenuItem key={scheme} id={scheme} value={scheme}>
                    {orderNamingSchemaDescriptions[scheme]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {orderNamingScheme === OrderNamingScheme.CompanyOrdinal && (
            <Grid item xs={12} md={3}>
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Starting Order Name Number
              </Typography>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  size="small"
                  InputProps={{
                    inputProps: { pattern: '[0-9]*', min: 1 },
                  }}
                  value={companyOrdinalStart}
                  onChange={(e) => {
                    const parsed = Number.parseFloat(e.target.value);
                    if (!Number.isNaN(parsed)) {
                      setCompanyOrdinalStart(parsed < 1 ? 1 : parsed);
                    } else {
                      setCompanyOrdinalStart(undefined);
                    }
                  }}
                  disabled={!canWriteSettingsOrders}
                />
              </FormControl>
            </Grid>
          )}
          {orderNamingScheme === OrderNamingScheme.CompanyOrdinal && (
            <Grid item xs={12} md={2}>
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Min order name length
              </Typography>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  size="small"
                  type="number"
                  InputProps={{
                    inputProps: { pattern: '[0-9]*', min: 1 },
                  }}
                  value={minDigitsInCompanyOrdinalOrderName}
                  onChange={(e) => {
                    const parsed = Number.parseFloat(e.target.value);
                    if (!Number.isNaN(parsed)) {
                      setMinDigitsInCompanyOrdinalOrderName(
                        parsed < 1 ? 1 : parsed,
                      );
                    } else {
                      setMinDigitsInCompanyOrdinalOrderName(undefined);
                    }
                  }}
                  disabled={!canWriteSettingsOrders}
                />
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={2}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography color="red" fontStyle="italic" mb={1}>
              {unsavedOrderNameChangesMessage}
            </Typography>
            <Button
              variant="contained"
              onClick={updateOrderNamingSchemeConfig}
              disabled={companyConfigurationSaving || !canWriteSettingsOrders}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={companyConfiguration?.logisticsSectionEnabled}
                      onChange={(e) =>
                        updateCompanyConfig({
                          logisticsSectionEnabled: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Logistics Section Enabled"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={companyConfiguration?.restrictEditingOrderName}
                      onChange={async (e) =>
                        updateCompanyConfig({
                          restrictEditingOrderName: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Make order name un-editable"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        companyConfiguration?.requireDimsToFinalizeCharges
                      }
                      onChange={async (e) =>
                        updateCompanyConfig({
                          requireDimsToFinalizeCharges: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Require package dims to finalize charges"
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Order identifier for cover sheet
            </Typography>
            <FormControl sx={{ width: '30%' }}>
              <Select
                value={orderIdentifierForCoverSheet}
                onChange={(e) => {
                  setOrderIdentifierForCoverSheet(
                    e.target.value as OrderIdentifier,
                  );
                  updateCompanyConfig({
                    _orderIdentifierForCoverSheet: e.target
                      .value as OrderIdentifier,
                  });
                }}
                required
                size="small"
                sx={{ backgroundColor: 'white' }}
                disabled={!canWriteSettingsOrders}
              >
                {Object.values(OrderIdentifier).map((identifier) => (
                  <MenuItem key={identifier} id={identifier} value={identifier}>
                    {sentenceCase(identifier)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Customizations
            </Typography>
            <Typography variant="body2" sx={{ mb: '20px', color: 'gray' }}>
              Contextualize orders by using relevant naming for reference
              numbers
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReferenceNumberLabels
              refNumberLabels={refNumberLabels}
              handleChange={handleRefNumberLabelChange}
              handleAddLabel={handleAddLabel}
              handleDeleteLabel={handleDeleteLabel}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              EDI defaults
            </Typography>
            <Typography variant="body2" sx={{ mb: '20px', color: 'gray' }}>
              Default service level to use for EDI orders
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Inbound stop only
            </Typography>
            <DefaultEdiServiceSelect
              disabled={!canWriteSettingsOrders}
              onChange={(value) => {
                setDefaultInboundEdiService(value);
                updateCompanyHandler({
                  defaultInboundEdiServiceUuid: value,
                });
              }}
              value={defaultInboundEdiService}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Outbound stop only
            </Typography>
            <DefaultEdiServiceSelect
              disabled={!canWriteSettingsOrders}
              onChange={(value) => {
                setDefaultOutboundEdiService(value);
                updateCompanyHandler({
                  defaultOutboundEdiServiceUuid: value,
                });
              }}
              value={defaultOutboundEdiService}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Inbound and outbound stop
            </Typography>
            <DefaultEdiServiceSelect
              disabled={!canWriteSettingsOrders}
              onChange={(value) => {
                setDefaultInboundOutboundEdiService(value);
                updateCompanyHandler({
                  defaultInboundOutboundEdiServiceUuid: value,
                });
              }}
              value={defaultInboundOutboundEdiService}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              Required fields
            </Typography>
            <OrderFormFields disabled={!canWriteSettingsOrders} />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default OrderSettings;
