import { INVOICE_ORDER_TABS_TEST_ID_PREFIX } from '../../../constants';
import {
  FilterOperator,
  OrderStatus,
  OrderStatusFilterType,
  OrderTableField,
} from '../../generated/graphql';
import { DefaultFilterTabsConfigs } from '../ag-grid/orders/types';
import {
  ALL_ORDER_STATUS_FILTERS,
  ALL_SHIPMENT_STATUS_FILTERS,
} from './constants';
import { InvoiceOrderTabs } from './types/types';

/**
 * The default order table fields for the orders table on the billing review page.
 */
export const BILLING_REVIEW_ORDER_TABLE_DEFAULT_HEADERS = [
  OrderTableField.BillOfLadingNumber,
  OrderTableField.OrderServiceDate,
  OrderTableField.Name,
  OrderTableField.CustomerName,
  OrderTableField.StopTypes,
  OrderTableField.OriginTerminal,
  OrderTableField.DestinationTerminal,
  OrderTableField.BillingReviewStatus,
  OrderTableField.OrderConsignee,
  OrderTableField.Notes,
  OrderTableField.HasDriverPod,
  OrderTableField.PaperworkComplete,
  OrderTableField.TotalChargesWithItemized,
];

export const BILLING_REVIEW_DEFAULT_FILTER_TAB_CONFIGS: DefaultFilterTabsConfigs<InvoiceOrderTabs> =
  Object.freeze({
    defaultTab: InvoiceOrderTabs.Unfinalized,
    baseTab: InvoiceOrderTabs.All,
    tabs: [
      {
        value: InvoiceOrderTabs.Unfinalized,
        label: 'Unfinalized',
        filtersToApply: {
          hideOriginalConsolidatedOrderIdFilter: {
            eq: true,
          },
          onInvoice: {
            filterOperator: FilterOperator.And,
            value: false,
          },
          showConsolidatedOrders: {
            filterOperator: FilterOperator.And,
            value: true,
          },
          statusFilters: [
            {
              filterType: OrderStatusFilterType.Equals,
              status: OrderStatus.Delivered,
            },
          ],
        },
      },
      {
        value: InvoiceOrderTabs.Cancelled,
        label: 'Cancelled',
        filtersToApply: {
          hideOriginalConsolidatedOrderIdFilter: {
            eq: true,
          },
          onInvoice: {
            filterOperator: FilterOperator.And,
            value: false,
          },
          showConsolidatedOrders: {
            filterOperator: FilterOperator.And,
            value: true,
          },
          statusFilters: [
            {
              filterType: OrderStatusFilterType.Equals,
              status: OrderStatus.Cancelled,
            },
          ],
        },
      },
      {
        value: InvoiceOrderTabs.BillingIssue,
        label: 'Has Issue',
        filtersToApply: {
          hideOriginalConsolidatedOrderIdFilter: {
            eq: true,
          },
          onInvoice: {
            filterOperator: FilterOperator.And,
            value: false,
          },
          showConsolidatedOrders: {
            filterOperator: FilterOperator.And,
            value: true,
          },
          statusFilters: [
            {
              filterType: OrderStatusFilterType.Equals,
              status: OrderStatus.HasIssue,
            },
          ],
        },
      },
      {
        value: InvoiceOrderTabs.Finalized,
        label: 'Finalized',
        dataTestId: `${INVOICE_ORDER_TABS_TEST_ID_PREFIX}-${InvoiceOrderTabs.Finalized}`,
        filtersToApply: {
          hideOriginalConsolidatedOrderIdFilter: {
            eq: true,
          },
          hideFromBilling: {
            value: false,
            filterOperator: FilterOperator.And,
          },
          onInvoice: {
            value: false,
            filterOperator: FilterOperator.And,
          },
          showConsolidatedOrders: {
            value: true,
            filterOperator: FilterOperator.And,
          },
          statusFilters: [
            {
              filterType: OrderStatusFilterType.Equals,
              status: OrderStatus.Finalized,
            },
          ],
        },
      },
      {
        value: InvoiceOrderTabs.Invoiced,
        label: 'Invoiced',
        dataTestId: `${INVOICE_ORDER_TABS_TEST_ID_PREFIX}-${InvoiceOrderTabs.Invoiced}`,
        filtersToApply: {
          onInvoice: {
            filterOperator: FilterOperator.And,
            value: true,
          },
          showConsolidatedOrders: {
            filterOperator: FilterOperator.And,
            value: true,
          },
        },
      },
      {
        value: InvoiceOrderTabs.All,
        label: 'All',
        filtersToApply: {
          showConsolidatedOrders: {
            filterOperator: FilterOperator.And,
            value: true,
          },
          statusFilters: ALL_ORDER_STATUS_FILTERS,
          shipmentStatusFilters: ALL_SHIPMENT_STATUS_FILTERS,
        },
      },
    ],
  });
