import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { UnitOfMeasure } from '../../../../generated/graphql';

export interface AddItemFormValues {
  item: {
    uuid: string;
    sku: string;
    description: string;
  };
  unitOfMeasure: UnitOfMeasure;
  quantity: number | '';
}

const useAddItemForm = () => {
  const schema = joi.object({
    item: joi
      .object({
        uuid: joi
          .string()
          .required()
          .messages({ 'any.required': 'Item is required' }),
        sku: joi
          .string()
          .required()
          .messages({ 'any.required': 'SKU is required' }),
        description: joi
          .string()
          .messages({ 'any.required': 'Description is required' }),
      })
      .unknown(true),
    unitOfMeasure: joi
      .string()
      .required()
      .messages({ 'any.required': 'UoM is required' }),
    quantity: joi.number().integer().required().messages({
      'any.required': 'Quantity is required',
      'number.integer': 'Quantity must be a whole number',
      'number.base': 'Quantity must be a number',
    }),
  });
  return useForm<AddItemFormValues>({
    resolver: joiResolver(schema),
    defaultValues: {
      unitOfMeasure: UnitOfMeasure.Pallet,
    },
  });
};

export default useAddItemForm;
