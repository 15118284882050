import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';

const PlannedDateHelper = () => {
  return (
    <Tooltip title="Will populate when assigned to route">
      <HelpOutlineIcon sx={{ fontSize: 15 }} />
    </Tooltip>
  );
};

export default PlannedDateHelper;
