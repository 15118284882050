import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StandardShipmentValues } from 'shared/types';
import { PartialDeep } from 'type-fest';
import { DeepStringified } from '../../../common/types';
import type { RootState } from '../../../redux/store';

export type StandardShipmentFormFieldError = DeepStringified<
  PartialDeep<Omit<StandardShipmentValues, 'uuid'>> & { uuid: string }
>;
type StandardShipmentErrorsStoreState = StandardShipmentFormFieldError;

const standardShipmentsErrorsAdapter =
  createEntityAdapter<StandardShipmentErrorsStoreState>({
    selectId: (shipment) => shipment.uuid,
  });

export const standardShipmentsErrorsSlice = createSlice({
  name: 'standardShipmentsErrors',
  initialState: standardShipmentsErrorsAdapter.getInitialState(),
  reducers: {
    addStandardShipmentErrors: standardShipmentsErrorsAdapter.addOne,
    upsertOneStandardShipmentErrors: standardShipmentsErrorsAdapter.upsertOne,
    deleteStandardShipmentErrors: standardShipmentsErrorsAdapter.removeOne,
    updateOneStandardShipmentErrors: standardShipmentsErrorsAdapter.updateOne,
    updateManyStandardShipmentErrors: standardShipmentsErrorsAdapter.updateMany,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectStandardShipmentErrorsById,
  // Pass in a selector that returns the posts slice of state
} = standardShipmentsErrorsAdapter.getSelectors(
  (state: RootState) => state.orderFormStandardShipmentsErrors,
);

export const { upsertOneStandardShipmentErrors } =
  standardShipmentsErrorsSlice.actions;

export default standardShipmentsErrorsSlice.reducer;
