import { ChevronLeft } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { MarkStopAsAttemptedDialog } from '../../../common/components/modals/mark-stop-as-attempted.dialog';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  DocumentType,
  OrderWithInboundOutboundPaperworkDocument,
  OrderWithPaperworkDocument,
  PermissionResource,
  StopStatus,
  useDeleteSlotsFromRouteMutation,
  useDocumentsByShipmentQuery,
  useMarkStopAsFailedV2Mutation,
  useOrderWithInboundOutboundPaperworkQuery,
  useUpdatePaperworkCompletedForStopsMutation,
} from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAddresses } from '../../addresses/redux/addresses-values-slice';
import StopStatusChip from '../../dispatch/routes/components/stop-status-chip';
import Paperwork from '../../orders/components/paperwork';
import { transformAppointmentStatus } from '../../orders/components/utils';
import { selectStandardShipmentValuesById } from '../../shipments/redux/standard-shipments-values-slice';
import {
  selectStopValuesById,
  updateOneStopValues,
} from '../../stops/redux/stop-values-slice';
import EndOfDayContext from '../end-of-day-context';
import { getAppointmentTimeStringEOD } from '../end-of-day-utils';
import { selectRouteSlotValuesById } from '../redux/route-slots-values-slice';
import {
  selectRouteValuesById,
  updateOneRouteValues,
} from '../redux/routes-values-slice';
import StopRowContextMenu from './context-menu';

interface StopRowProps {
  tableIsMinimized: boolean;
  setTableIsMinimized: Dispatch<SetStateAction<boolean>>;
  selectedStopUuid: string | undefined;
  setSelectedStopUuid: Dispatch<SetStateAction<string | undefined>>;
  routeUuid: string;
  slotUuid: string;
  order: number;
  setShowMarkStopAsAttemptedSuccessMessage: Dispatch<SetStateAction<boolean>>;
  setShowMarkStopAsAttemptedErrorMessage: Dispatch<SetStateAction<boolean>>;
  setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
}

const RouteStopRow = ({
  tableIsMinimized,
  setTableIsMinimized,
  selectedStopUuid,
  setSelectedStopUuid,
  routeUuid,
  slotUuid,
  order,
  setShowMarkStopAsAttemptedSuccessMessage,
  setShowMarkStopAsAttemptedErrorMessage,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: StopRowProps) => {
  const { setShouldLoadRoutes } = useContext(EndOfDayContext);

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );
  const route = useAppSelector((state) =>
    selectRouteValuesById(state, routeUuid),
  );
  const slot = useAppSelector((state) =>
    selectRouteSlotValuesById(state, slotUuid),
  );
  const [markStopAsFailed] = useMarkStopAsFailedV2Mutation();
  const [showMarkStopAsFailedDialog, setShowMarkStopAsFailedDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  // const [ref, setRef] = useState<null | HTMLAnchorElement>(null);
  // TODO: Have handling for grouped stops
  // Assume one stop per slot for now
  const stopUuid = !isNil(slot?.stopUuids) ? slot?.stopUuids[0] : undefined;
  const [removeSlot] = useDeleteSlotsFromRouteMutation({
    variables: {
      deleteSlotsFromRouteInput: {
        routeSlotUuids: [slot?.uuid ?? ''],
        routeUuid,
      },
    },
  });

  const stop = useAppSelector((state) =>
    selectStopValuesById(state, stopUuid ?? ''),
  );
  const shipment = useAppSelector((state) =>
    selectStandardShipmentValuesById(state, stop?.shipmentUuid ?? ''),
  );

  const shipmentUuid = stop?.shipmentUuid;
  const { data: documentsByShipmentData } = useDocumentsByShipmentQuery(
    isNilOrEmptyString(shipmentUuid)
      ? { skip: true }
      : { variables: { uuid: shipmentUuid } },
  );
  const stopOrderUuid = stop?.orderUuid;
  const { data: paperworkCompleteData } =
    useOrderWithInboundOutboundPaperworkQuery(
      isNilOrEmptyString(stopOrderUuid)
        ? { skip: true }
        : { variables: { uuid: stopOrderUuid } },
    );

  const hasEndOfDayDocuments = useMemo(() => {
    return documentsByShipmentData?.shipmentV2?.documents.some(
      (doc) => doc.type === DocumentType.EndOfDayDocument,
    );
  }, [documentsByShipmentData]);

  const [updateOrderPaperworkCompleted] =
    useUpdatePaperworkCompletedForStopsMutation({
      refetchQueries: [
        OrderWithPaperworkDocument,
        OrderWithInboundOutboundPaperworkDocument,
      ],
    });
  const ffHidePaperworkCompleteColumn = useFeatureFlag(
    FeatureFlag.FF_HIDE_PAPERWORK_COMPLETE_COLUMN,
  );
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );
  const paperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;
  const paperworkMarkedCompleteOverride =
    paperworkCompleteData?.order?.paperwork.paperworkMarkedCompleteOverride ??
    null;

  const addresses = useAppSelector((state) => selectAddresses(state));
  const currentAddress = addresses.find(
    (address) => address.uuid === stop?.addressUuid,
  );

  const dispatch = useAppDispatch();

  if (isNil(stop)) {
    return <CircularProgress />;
  }

  const timeString = getAppointmentTimeStringEOD(
    stop.appointmentTime,
    stop.endAppointmentTime,
  );

  const tableCellTimeString = `${timeString} ${transformAppointmentStatus(
    stop.appointmentTextStatus ?? '',
  )}`;

  return (
    <TableRow
      sx={{
        '& > *': { borderBottom: 'unset' },
        transition: '0.15s',
        cursor: 'pointer',
      }}
      hover
      selected={selectedStopUuid === stopUuid}
      onClick={() => {
        if (tableIsMinimized) {
          setSelectedStopUuid(stopUuid);
        }
      }}
    >
      {!tableIsMinimized && (
        <TableCell align="center" width="10px">
          {order}
        </TableCell>
      )}
      <TableCell align="center">{stop.billOfLadingNumber}</TableCell>
      <TableCell align="center">
        {sentenceCase(shipment?.pickupOrDelivery ?? '-')}
      </TableCell>
      {!tableIsMinimized && (
        <TableCell align="center">{currentAddress?.name ?? '-'}</TableCell>
      )}
      {!tableIsMinimized && (
        <TableCell align="center">
          {!isNil(currentAddress)
            ? transformAddressToFullAddressString({
                ...currentAddress,
                line1: currentAddress?.line1 ?? null,
                city: currentAddress?.city ?? null,
                zip: currentAddress?.zip ?? null,
              })
            : '-'}
        </TableCell>
      )}
      {!tableIsMinimized && (
        <TableCell align="center">{tableCellTimeString}</TableCell>
      )}
      <TableCell align="center">
        <StopStatusChip status={stop.status} refusedBy={stop.refusedBy} />
      </TableCell>
      {!ffHidePaperworkCompleteColumn && (
        <>
          <TableCell align="center">
            <Stack>
              <Tooltip
                arrow
                title={
                  hasEndOfDayDocuments === true
                    ? 'Documents uploaded'
                    : 'No documents uploaded'
                }
              >
                {hasEndOfDayDocuments === true ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </Tooltip>
            </Stack>
          </TableCell>
          <TableCell align="center">
            <Stack>
              <Tooltip
                arrow
                title={
                  // eslint-disable-next-line no-nested-ternary
                  paperworkMarkedCompleteOverride === true
                    ? 'Paperwork marked complete'
                    : // eslint-disable-next-line no-nested-ternary
                      paperworkMarkedCompleteOverride === false
                      ? 'Paperwork marked incomplete'
                      : paperworkComplete === true
                        ? 'Paperwork completed'
                        : 'Missing documents'
                }
              >
                {paperworkComplete ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </Tooltip>
            </Stack>
          </TableCell>
        </>
      )}
      {ffInboundOutboundPaperworkColumns && (
        <>
          <TableCell align="center">
            <Paperwork
              paperwork={paperworkCompleteData?.order?.inboundPaperwork}
            />
          </TableCell>
          <TableCell align="center">
            <Paperwork
              paperwork={paperworkCompleteData?.order?.outboundPaperwork}
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <Stack direction="row">
          {!tableIsMinimized && (
            <Tooltip arrow title="Open Document Upload">
              <IconButton
                sx={{ padding: '5px' }}
                onClick={() => {
                  if (!tableIsMinimized) {
                    setTableIsMinimized(true);
                    setSelectedStopUuid(stopUuid);
                  }
                }}
              >
                <ChevronLeft />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            sx={{ padding: '5px' }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            disabled={!canWriteEndOfDay}
          >
            <MoreVertIcon />
          </IconButton>
          <StopRowContextMenu
            setShowMarkStopAsFailedDialog={setShowMarkStopAsFailedDialog}
            unassignStop={async () => {
              if (slot?.isLocal !== true) {
                await removeSlot();
              }
              dispatch(
                updateOneRouteValues({
                  id: routeUuid,
                  changes: {
                    routeSlotUuids: route?.routeSlotUuids?.filter(
                      (routeSlotUuid) => routeSlotUuid !== slot?.uuid,
                    ),
                  },
                }),
              );
            }}
            updatePaperworkCompleted={async () => {
              try {
                if (isNil(stop.orderUuid)) {
                  return;
                }
                const res = await updateOrderPaperworkCompleted({
                  variables: {
                    updatePaperworkCompleteInput: {
                      uuid: stop.orderUuid,
                      paperworkCompleted: !paperworkComplete,
                    },
                  },
                });
                if (!isNil(res.errors)) {
                  return;
                }
                dispatch(
                  updateOneStopValues({
                    id: stop.uuid,
                    changes: {
                      paperworkMarkedComplete: !paperworkComplete,
                    },
                  }),
                );
              } catch (e) {
                // console.log(e)
              }
            }}
            stopUuid={stop.uuid}
            orderUuid={stop.orderUuid ?? ''}
            setAnchorEl={setAnchorEl}
            setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
            setCannotCompleteOrderModalMessage={
              setCannotCompleteOrderModalMessage
            }
            status={stop.status}
            isLocalSlot={slot?.isLocal ?? false}
            anchorEl={anchorEl}
          />
        </Stack>
      </TableCell>
      <MarkStopAsAttemptedDialog
        handleClose={() => {
          setShowMarkStopAsFailedDialog(false);
        }}
        handleMarkStopAsFailed={async (notes) => {
          try {
            if (!isNil(stopUuid)) {
              const res = await markStopAsFailed({
                variables: {
                  markStopAsFailedV2Input: {
                    notes,
                    uuid: stopUuid,
                  },
                },
              });
              if (!isNil(res.data?.markStopAsFailedV2?.uuid)) {
                dispatch(
                  updateOneStopValues({
                    id: stop.uuid,
                    changes: {
                      status: StopStatus.Failed,
                    },
                  }),
                );
              }
              setShowMarkStopAsAttemptedSuccessMessage(true);
            }
            setShowMarkStopAsFailedDialog(false);
            setShouldLoadRoutes(true);
          } catch (e) {
            setShowMarkStopAsAttemptedErrorMessage(true);
          }
        }}
        open={showMarkStopAsFailedDialog}
      />
    </TableRow>
  );
};

export default RouteStopRow;
