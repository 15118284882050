import { ApolloQueryResult } from '@apollo/client/core/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TableRow, TableCell, Tooltip } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  Exact,
  FindRoleInput,
  FindRoleUserFragment,
  RoleQuery,
  useRemoveUserFromRoleMutation,
} from '../../../../generated/graphql';

interface UserRowProps {
  roleUuid: string;
  user: FindRoleUserFragment;
  refetchRole: (
    variables?:
      | Partial<Exact<{ findRoleInput?: FindRoleInput | undefined }>>
      | undefined,
  ) => Promise<ApolloQueryResult<RoleQuery>>;

  setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;
}
const UserRow = ({
  roleUuid,
  user,
  refetchRole,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
}: UserRowProps) => {
  const { refetchUserRoles } = useUserRoles();
  const [removeUserFromRole, { loading: removeUserFromRoleLoading }] =
    useRemoveUserFromRoleMutation();

  const handleRemoveUserFromRole = async () => {
    try {
      const resp = await removeUserFromRole({
        variables: {
          removeUserFromRoleInput: {
            uuid: roleUuid,
            userUuid: user.uuid,
          },
        },
      });
      if (resp?.data?.removeUserFromRole?.success === true) {
        await refetchRole({ findRoleInput: { uuid: roleUuid } });
        refetchUserRoles();
        setSuccessSnackbarVisible(true);
      } else {
        setErrorSnackbarVisible(true);
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <TableRow>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <IconButton
          onClick={handleRemoveUserFromRole}
          color="secondary"
          disabled={removeUserFromRoleLoading}
        >
          <Tooltip arrow title="Remove user from role">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default UserRow;
