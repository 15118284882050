import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';
import { BaseStorageOrderFormValues } from '../types';

export type EditStorageOrderFormValues = BaseStorageOrderFormValues;

const useEditStorageOrderForm = () => {
  const schema = joi.object({
    contactUuid: joi
      .string()
      .required()
      .messages({ 'any.required': 'Contact is required' }),
    referenceNumber: joi
      .string()
      .required()
      .messages({ 'any.required': 'Reference number is required' }),
    warehouse: joi
      .object({
        label: joi.string().required(),
        value: joi.string().required(),
      })
      .required()
      .messages({
        'any.required': 'Warehouse is required',
      }),
    purchaseOrderNumber: joi.string().optional().allow(null, ''),
    carrier: joi.string().optional().allow(null, ''),
    trackingNumber: joi.string().optional().allow(null, ''),
    supplierCompanyName: joi.string().optional().allow(null, ''),
    lotNumber: joi.string().optional().allow(null, ''),
    consigneeName: joi.string().optional().allow(null, ''),
    notes: joi.string().optional().allow(null, ''),
    pickupDate: joi.date().optional().allow(null, ''),
    expectedAt: joi.date().optional().allow(null, ''),
    arrivedAt: joi.date().required().messages({
      'any.required': 'Arrived at is required',
      'date.base': 'Arrived at is required',
    }),
  });
  return useForm<EditStorageOrderFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useEditStorageOrderForm;
