import { TextField } from '@mui/material';
import type { FunctionComponent } from 'react';
import DatePicker, {
  CalendarProps,
  DatePickerProps,
} from 'react-multi-date-picker';
import theme from '../../../../theme';

type StyledDatePickerProps = Omit<CalendarProps & DatePickerProps, 'style'> & {
  error?: boolean;
  label?: string;
};

const StyledDatePicker: FunctionComponent<StyledDatePickerProps> = ({
  error,
  title,
  required,
  ...props
}) => (
  <DatePicker
    render={(value, openCalendar, handleValueChange) => {
      return (
        <TextField
          fullWidth
          value={value}
          size="small"
          label={title}
          onChange={handleValueChange}
          onClick={openCalendar}
          required={required}
          error={error}
        />
      );
    }}
    required={required}
    calendarPosition="bottom"
    hideOnScroll
    {...props}
    style={{
      // content-box only to match MUI
      boxSizing: 'content-box',
      margin: '0',
      // This isn't the same top/bottom padding as for MUI input fields because MUI does not put a border on
      // inputs but instead creates a border with a separate fieldset element.
      padding: '5px 10px',
      // This also because content-box. The 22px is the left/right padding + border widths.
      width: 'calc(100% - 22px)',
      fontSize: '15.5px',
      fontFamily: theme.typography.fontFamily,
      borderColor: error === true ? '#B00020' : 'rgba(0, 0, 0, 0.23)',
    }}
  />
);

export default StyledDatePicker;
