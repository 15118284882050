import {
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import dayjs, { Dayjs } from 'dayjs';
import { clamp, isNil } from 'lodash';
import pluralize from 'pluralize';
import { useEffect, useState, memo } from 'react';
import * as React from 'react';
import { shallow } from 'zustand/shallow';
import { DISPATCH_PAGE_CREATE_ROUTE_CONFIRM_BUTTON_TEST_ID } from '../../../../../../constants';
import TerminalPicker from '../../../../../common/components/terminal-picker';
import TimePickerComponent from '../../../../../common/components/time-picker-component';
import useMe from '../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import useDispatchStore from '../../../dispatch-store';
import useRouteActions from '../../../hooks/use-route-actions';
import { MAX_ROUTES_TO_CREATE } from '../../../types/routes';

const CreateMultipleRoutesModal = ({
  open,
  stopUuidsToAssign,
  onCreate,
}: {
  open: boolean;
  stopUuidsToAssign?: string[];
  onCreate?: () => void;
}) => {
  const { companyConfiguration } = useMe();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [selectedTerminalUuid, setSelectedTerminalUuid] = useGlobalStore(
    (state) => [state.selectedTerminalUuid, state.setSelectedTerminalUuid],
    shallow,
  );
  const planningDate = useDispatchStore((state) => state.planningDate);
  const [terminalUuid, setTerminalUuid] = useState<string | undefined>();
  const [startTime, setStartTime] = useState<string | null | undefined>();
  const [loadTime, setLoadTime] = useState<string>();
  const [unloadTime, setUnloadTime] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const { createNewRoutes } = useRouteActions();

  useEffect(() => {
    setTerminalUuid(selectedTerminalUuid);
  }, [open, selectedTerminalUuid]);

  useEffect(() => {
    if (!isNil(companyConfiguration)) {
      const defaultRouteStartTime = !isNil(
        companyConfiguration?.defaultRouteStartTime,
      )
        ? dayjs(companyConfiguration?.defaultRouteStartTime)
        : undefined;
      const today = new Date();
      const defaultStartTime = !isNil(defaultRouteStartTime)
        ? dayjs()
            .set('year', today.getFullYear())
            .set('month', today.getMonth())
            .set('date', today.getDate())
            .set('hours', defaultRouteStartTime.hour())
            .set('minute', defaultRouteStartTime.minute())
        : undefined;
      setStartTime(
        !isNil(defaultStartTime) ? defaultStartTime?.toISOString() : undefined,
      );
      setLoadTime(String(companyConfiguration.defaultRouteLoadTimeInMinutes));
      setUnloadTime(
        String(companyConfiguration.defaultRouteUnloadTimeInMinutes),
      );
    }
  }, [companyConfiguration]);

  const createRoutes = async () => {
    setLoading(true);
    if (!isNil(planningDate) && (!terminalsEnabled || !isNil(terminalUuid))) {
      const terminalUuidToSelect = terminalsEnabled ? terminalUuid : undefined;
      const loadTimeInput = parseFloat(loadTime ?? '');
      const unloadTimeInput = parseFloat(unloadTime ?? '');
      await createNewRoutes({
        planningDate,
        count,
        terminalUuid: terminalUuidToSelect,
        startTime: !isNil(startTime) ? new Date(startTime) : null,
        loadTime: !Number.isNaN(loadTimeInput) ? loadTimeInput : null,
        unloadTime: !Number.isNaN(unloadTimeInput) ? unloadTimeInput : null,
        stopUuids: stopUuidsToAssign,
      });
      setSelectedTerminalUuid(terminalUuidToSelect);
    }
    setLoading(false);
    onCreate?.();
    setCount(1);
  };

  return (
    <Stack direction="column" sx={{ p: 2, width: '350px' }} spacing={2}>
      <Typography variant="h6">{pluralize('Create Route', count)}</Typography>
      {terminalsEnabled && (
        <TerminalPicker
          terminalUuid={terminalUuid}
          setTerminalUuid={setTerminalUuid}
          includeInactiveTerminals={false}
        />
      )}
      {isNil(stopUuidsToAssign) && (
        <TextField
          label="Number of Routes"
          size="small"
          type="number"
          onChange={(e) => {
            setCount(parseFloat(e.target.value));
          }}
          onBlur={() => {
            setCount(clamp(count ?? 1, 1, MAX_ROUTES_TO_CREATE));
          }}
          value={count}
          sx={{ width: '100%' }}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: '14px' }}>Start Time</Typography>
            <TimePickerComponent
              hideClearable
              minimized
              placeholder=""
              appointmentTime={startTime}
              updateAppointmentTime={(apptTime: Dayjs | null | undefined) => {
                if (!isNil(apptTime)) {
                  setStartTime(apptTime.set('seconds', 0).toISOString());
                } else {
                  setStartTime(null);
                }
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: '14px' }}>Load Time</Typography>
            <TextField
              sx={{ width: '90px' }}
              inputProps={{
                style: { fontSize: '14px', width: '90px' },
              }}
              size="small"
              variant="standard"
              type="number"
              placeholder=""
              value={loadTime}
              onChange={(e) => {
                setLoadTime(e.target.value);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: '14px' }}>Unload Time</Typography>
            <TextField
              sx={{ width: '90px' }}
              inputProps={{
                style: { fontSize: '14px' },
              }}
              size="small"
              variant="standard"
              type="number"
              placeholder=""
              value={unloadTime}
              onChange={(e) => {
                setUnloadTime(e.target.value);
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Tooltip
          title={
            isNil(terminalUuid) && terminalsEnabled
              ? 'Please select a terminal'
              : null
          }
        >
          <span>
            <Button
              data-testid={DISPATCH_PAGE_CREATE_ROUTE_CONFIRM_BUTTON_TEST_ID}
              variant="contained"
              onClick={createRoutes}
              disabled={isNil(terminalUuid) && terminalsEnabled}
              endIcon={
                loading ? (
                  <CircularProgress size={15} sx={{ color: 'white' }} />
                ) : null
              }
            >
              Confirm
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default memo(CreateMultipleRoutesModal);
