import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  OrderBillingStatus,
  useUpdateStandardOrderMutation,
} from '../../../../../generated/graphql';
import useBillingReviewStore from '../../../../invoices/billing-review-store';
import useBillingReviewActions from '../../../../invoices/hooks/use-billing-review-actions';
import { deepCompareOrderChanges } from '../utils';
import { OrderFormValues } from './types';
import { useSaveSnapshot } from './use-save-snapshot';
import { updateOrder } from './utils';

const useSaveOrderBrm = () => {
  const { useAllCaps } = useMe();
  const [updateOrderMutation, { loading }] = useUpdateStandardOrderMutation();
  const { saveSnapshot } = useSaveSnapshot();
  const getValues = useFormContext<OrderFormValues>()?.getValues;
  const [addOrderUuidToSave, removeOrderUuidToSave, getInitialFormData] =
    useBillingReviewStore(
      (state) => [
        state.addOrderUuidToSave,
        state.removeOrderUuidToSave,
        state.getInitialFormData,
      ],
      shallow,
    );

  const { fetchOrderCacheFirst } = useBillingReviewActions();

  const saveOrder = async ({
    forceRefresh,
    newBillingStatus,
  }: {
    forceRefresh?: boolean;
    newBillingStatus?: OrderBillingStatus;
  }) => {
    if (!isNil(getValues)) {
      const orderValues = getValues();
      const initialFormData = getInitialFormData();
      const orderUuid = orderValues.uuid;
      if (isNil(orderUuid)) {
        return null;
      }
      addOrderUuidToSave(orderUuid);

      if (
        forceRefresh !== true &&
        !isNil(initialFormData) &&
        deepCompareOrderChanges(initialFormData, orderValues)
      ) {
        removeOrderUuidToSave(orderUuid);
        return null;
      }

      const res = await updateOrder({
        updateOrderMutation,
        orderValues,
        saveSnapshot,
        newBillingStatus,
        useAllCaps,
      });

      // update the billing review store.
      if (!isNil(res)) {
        await fetchOrderCacheFirst({
          orderUuid,
          bypassCache: true,
        });
      }

      removeOrderUuidToSave(orderUuid);
      return res;
    }
    return null;
  };

  return { saveOrder, loading };
};

export default useSaveOrderBrm;
