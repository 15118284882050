import { Stack, Typography } from '@mui/material';
import React from 'react';
import { AddressDifferenceFragment } from '../../../../../../../../../generated/graphql';
import { buildStringDifferenceBlockComponent } from '../audit-log-utils';

type AddressDifferenceBlockProps = {
  addressDifference: AddressDifferenceFragment;
  isOldAddress: boolean;
  stopRemoved: boolean;
  stopAdded: boolean;
};
const AddressDifferenceBlock = ({
  addressDifference,
  isOldAddress,
  stopRemoved,
  stopAdded,
}: AddressDifferenceBlockProps) => {
  if (stopRemoved && !isOldAddress) return <>None</>;
  if (stopAdded && isOldAddress) return <>None</>;
  return (
    <Stack justifyContent="center">
      <Stack justifyContent="center">
        {buildStringDifferenceBlockComponent(
          addressDifference.name?.oldValue ?? undefined,
          addressDifference.name?.newValue ?? undefined,
          isOldAddress,
          stopRemoved,
          stopAdded,
          12,
        )}
      </Stack>
      <Stack justifyContent="center">
        {buildStringDifferenceBlockComponent(
          addressDifference.line1?.oldValue ?? undefined,
          addressDifference.line1?.newValue ?? undefined,
          isOldAddress,

          stopRemoved,
          stopAdded,
          12,
        )}
      </Stack>
      <Stack direction="row">
        {buildStringDifferenceBlockComponent(
          addressDifference.city?.oldValue ?? undefined,
          addressDifference.city?.newValue ?? undefined,
          isOldAddress,
          stopRemoved,
          stopAdded,
          12,
        )}
        <Typography fontSize={12}>{',  '}</Typography>
        {buildStringDifferenceBlockComponent(
          addressDifference.state?.oldValue ?? undefined,
          addressDifference.state?.newValue ?? undefined,
          isOldAddress,
          stopRemoved,
          stopAdded,
          12,
        )}
        <Typography fontSize={12}>{',  '}</Typography>
        {buildStringDifferenceBlockComponent(
          addressDifference.zip?.oldValue ?? undefined,
          addressDifference.zip?.newValue ?? undefined,
          isOldAddress,
          stopRemoved,
          stopAdded,
          12,
        )}
      </Stack>
    </Stack>
  );
};

export default AddressDifferenceBlock;
