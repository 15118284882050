import {
  Chip,
  Fade,
  Stack,
  SxProps,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { indigo, purple } from '@mui/material/colors';
import { isNil } from 'lodash';
import { useState } from 'react';
import { exhaustive } from 'shared/switch';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { AccessorialsTableRowFragment } from '../../../../generated/graphql';

const mapAccessorialTypeToChip = (
  accessorial: AccessorialsTableRowFragment,
) => {
  if (accessorial.__typename === 'StandardAccessorialEntity') {
    return <Chip size="small" label="Standard" color="success" />;
  }
  if (accessorial.__typename === 'WeightBasedAccessorialEntity') {
    return <Chip size="small" label="Weight Based" color="info" />;
  }
  if (accessorial.__typename === 'ZoneBasedAccessorialEntity') {
    return <Chip size="small" label="Zone Based" color="warning" />;
  }
  if (accessorial.__typename === 'UnitBasedAccessorialEntity') {
    return <Chip size="small" label="Unit Based" color="error" />;
  }
  if (accessorial.__typename === 'SkidBasedAccessorialEntity') {
    return <Chip size="small" label="Skid Based" color="default" />;
  }
  if (accessorial.__typename === 'WaitTimeAccessorialEntity') {
    return (
      <Chip
        size="small"
        label="Wait Time"
        sx={{ backgroundColor: indigo['500'], color: 'white' }}
      />
    );
  }
  if (accessorial.__typename === 'SpecialAccessorialEntity') {
    return (
      <Chip
        size="small"
        label="Special"
        sx={{ backgroundColor: purple['500'], color: 'white' }}
      />
    );
  }
  return null;
};

const getInformation = (accessorial: AccessorialsTableRowFragment): string => {
  switch (accessorial.__typename) {
    case 'UnitBasedAccessorialEntity':
      return `Rate per unit: ${accessorial.ratePerUnit}`;
    case 'SkidBasedAccessorialEntity':
      return `Rate per skid: ${accessorial.ratePerSkid}`;
    case 'StandardAccessorialEntity':
      return `Rate: ${accessorial.rate}`;
    case 'WaitTimeAccessorialEntity':
      return `Rate per ${accessorial.waitTimeChargePeriod} minutes: ${accessorial.rate}`;
    case 'WeightBasedAccessorialEntity':
    case 'SpecialAccessorialEntity':
    case 'ZoneBasedAccessorialEntity':
      return '';
    default:
      return exhaustive(accessorial);
  }
};

const styles = {
  accessorialButton: {
    mx: 1.5,
  } as SxProps,
};

const AccessorialsTableRow = ({
  accessorial,
  terminalsEnabled,
  contactAccessorialsDisabled,
  globalAcessorialsDisabled,
  contactUuid,
}: {
  accessorial: AccessorialsTableRowFragment;
  terminalsEnabled: boolean;
  contactAccessorialsDisabled: boolean;
  globalAcessorialsDisabled: boolean;
  contactUuid?: string | null | undefined;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      key={accessorial.uuid}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {accessorial.name}
      </TableCell>
      <TableCell>{mapAccessorialTypeToChip(accessorial)}</TableCell>
      {terminalsEnabled && (
        <TableCell>
          {!isNil(accessorial?.terminal)
            ? `${accessorial?.terminal?.name} (${accessorial?.terminal?.code})`
            : 'All Terminals'}
        </TableCell>
      )}
      <TableCell>{getInformation(accessorial)}</TableCell>
      <TableCell>
        {!isNil(accessorial.generalLedgerCode) ? (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: '14px' }}>
              {accessorial.generalLedgerCode?.code ?? ''}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: theme.palette.grey[500] }}
            >
              {accessorial.generalLedgerCode?.description ?? ''}
            </Typography>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[500] }}>
            none
          </Typography>
        )}
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <ButtonLink
            sx={styles.accessorialButton}
            variant="contained"
            disabled={contactAccessorialsDisabled || globalAcessorialsDisabled}
            href={
              isNil(contactUuid)
                ? `/management/accessorials/${accessorial.uuid}`
                : `/contacts/accessorials/${accessorial.uuid}?contactUuid=${contactUuid}`
            }
          >
            Edit
          </ButtonLink>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default AccessorialsTableRow;
