import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

export type RouteSlotValues = Partial<{
  routeUuid: string;
  stopUuids: string[];
  placeUuids: string[];
  name: string;
  isLocal: boolean;
}> & { uuid: string };

const routeSlotValuesAdapter = createEntityAdapter<RouteSlotValues>({
  selectId: (routeSlot) => routeSlot.uuid,
});

export const routeSlotValuesSlice = createSlice({
  name: 'routeSlotValues',
  initialState: routeSlotValuesAdapter.getInitialState(),
  reducers: {
    addOneRouteSlotValues: routeSlotValuesAdapter.addOne,
    updateOneRouteSlotValues: routeSlotValuesAdapter.updateOne,
    removeOneRouteSlotValues: routeSlotValuesAdapter.removeOne,
    setAllRouteSlotValues: routeSlotValuesAdapter.setAll,
    upsertOneRouteSlotValues: routeSlotValuesAdapter.upsertOne,
  },
});

export const { selectById: selectRouteSlotValuesById } =
  routeSlotValuesAdapter.getSelectors(
    (state: RootState) => state.routeSlotValues,
  );

const routeSlotValuesReducer = routeSlotValuesSlice.reducer;

const routeSlotValuesSelector = (state: RootState) => state.routeSlotValues;

export const selectRouteSlotsByIds = createSelector(
  routeSlotValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<RouteSlotValues>, entityIds: EntityId[]) => {
    const entityResults: RouteSlotValues[] = [];
    entityIds.forEach((id) => {
      const match = routeSlotValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addOneRouteSlotValues,
  removeOneRouteSlotValues,
  updateOneRouteSlotValues,
  setAllRouteSlotValues,
  upsertOneRouteSlotValues,
} = routeSlotValuesSlice.actions;

export default routeSlotValuesReducer;
