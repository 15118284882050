import React from 'react';
import EdiInvoicesTable from './edi-invoices/edi-invoices-table';
import InvoiceEmailsTable from './emails/invoice-emails-table';

export type InvoiceTransmissionsListProps = {
  invoiceUuid?: string | undefined;
};

const InvoiceTransmissionsList = ({
  invoiceUuid,
}: InvoiceTransmissionsListProps) => {
  return (
    <>
      <InvoiceEmailsTable invoiceUuid={invoiceUuid} />
      <EdiInvoicesTable invoiceUuid={invoiceUuid} />
    </>
  );
};

export default InvoiceTransmissionsList;
