import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import { Badge, IconButton, Popover, Stack, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import useNotifications from '../../../common/react-hooks/use-notifications';
import NotificationRow from '../../../domains/notifications/components/notification-row';
import OrdersAwaitingApprovalNotification from '../../../domains/notifications/components/orders-awaiting-approval-notification';
import { NotificationType } from '../../../generated/graphql';

const NotificationsBell = () => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<HTMLElement | null>(null);
  const iconRef = useRef<HTMLButtonElement | null>(null);
  const { notifications, ordersAwaitingApprovalNotifications } =
    useNotifications();

  useEffect(() => {
    if (!isNil(iconRef)) {
      setNotificationsAnchorEl(iconRef.current);
    }
  }, []);

  // Ensure that the popover closes when there are no more notifications to ack for the day.
  useEffect(() => {
    if (isEmpty(notifications)) {
      setNotificationsOpen(false);
    }
  }, [notifications]);

  return (
    <>
      <IconButton
        ref={iconRef}
        onClick={() => {
          setNotificationsOpen(true);
        }}
      >
        <Badge
          badgeContent={notifications?.length ?? 0}
          showZero={false}
          sx={{
            '& .MuiBadge-badge': {
              color: '#FFFFFF',
              background: 'transparent',
            },
          }}
        >
          {!isEmpty(notifications) ? (
            <NotificationsSharpIcon />
          ) : (
            <NotificationsNoneSharpIcon
              sx={{ color: '#FFFFFF', fontSize: 20 }}
            />
          )}
        </Badge>
      </IconButton>
      <Popover
        id="notifications"
        open={notificationsOpen}
        anchorEl={notificationsAnchorEl}
        onClose={() => setNotificationsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Stack padding="8px" minWidth="130px">
          {!isEmpty(ordersAwaitingApprovalNotifications) && (
            <OrdersAwaitingApprovalNotification
              notifications={ordersAwaitingApprovalNotifications ?? []}
            />
          )}
          {!isEmpty(notifications) &&
            notifications
              ?.filter(
                (notification) =>
                  notification.type !==
                    NotificationType.OrderAwaitingApproval &&
                  notification.type !== NotificationType.EmailingInvoice,
              )
              .map((notification) => (
                <NotificationRow
                  key={notification.uuid}
                  notification={notification}
                />
              ))}
          {isEmpty(notifications) && (
            <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
              No Notifications
            </Typography>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default React.memo(NotificationsBell);
