import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    mainColor: {
      color: theme.palette.primary.main,
    },
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    totalText: {
      ml: '4px',
      mb: '2px',
      color: 'black',
      fontSize: '15px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
  };
};
