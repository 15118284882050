import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isNil } from 'lodash';
import { useForm } from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import * as yup from 'yup';
import useMe from '../../../../../common/react-hooks/use-me';
import { BillingMethod } from '../../../../../common/types';
import {
  DeadlineType,
  DriverType,
  FreightBillingMethod,
  FuelBillingMethod,
  FulfillmentType,
  HazmatClass,
  InboundMethod,
  OrderDetailedStatus,
  OrderFormFieldsFragment,
  OrderSegmentType,
  OrderStatus,
  OutboundMethod,
  PackageGroup,
  ShipmentStatus,
  ShipmentType,
  StandardStopType,
  StopType as _StopType,
} from '../../../../../generated/graphql';
import {
  addressSchema,
  addressSchemaWithAllOptionalFields,
} from './address-schema';
import { contactPersonSchema } from './contact-person-schema';
import { customChargeSchema } from './custom-charge-schema';
import { documentSchema } from './document-schema';
import { freightChargeSchema } from './freight-charge-schema';
import { lineHaulShipmentSchema } from './line-haul-shipment-schema';
import { orderChargesShipmentSchema } from './order-charges-shipment-schema';
import { orderCommentSchema } from './order-comment-schema';
import { getPackagesSchema } from './packages-schema';
import { recurringOrderFrequencySchema } from './recurring-order-frequency-schema';
import { StopType } from './stop-type';
import { tagSchema } from './tag-schema';

export enum StopMethod {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export const partnerCarrierStops = [
  StopType.PartnerCarrierDropoff,
  StopType.PartnerCarrierPickup,
];

export const inboundStopTypeOptions = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
  StopType.None,
];

export const outboundStopTypeOptions = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
  StopType.None,
];

export const stopTypeOptions = (method: StopMethod) => {
  return method === StopMethod.Inbound
    ? inboundStopTypeOptions
    : outboundStopTypeOptions;
};

export const convertOrderFormStopTypeToStopType = (
  stopType: StopType,
): _StopType | null => {
  switch (stopType) {
    case StopType.Pickup:
    case StopType.PartnerCarrierDropoff:
      return _StopType.Pickup;
    case StopType.Delivery:
    case StopType.PartnerCarrierPickup:
      return _StopType.Delivery;
    case StopType.Recovery:
      return _StopType.Recovery;
    case StopType.Transfer:
      return _StopType.Transfer;
    case StopType.None:
      return null;
    default:
      return exhaustive(stopType);
  }
};

// Used only for setting default stop type via service level
export const convertServiceStopTypeToOrderFormStopType = (
  stopType: _StopType,
) => {
  switch (stopType) {
    case _StopType.Pickup:
      return StopType.Pickup;
    case _StopType.PartnerCarrierDropoff:
      return StopType.PartnerCarrierDropoff;
    case _StopType.Delivery:
      return StopType.Delivery;
    case _StopType.PartnerCarrierPickup:
      return StopType.PartnerCarrierPickup;
    case _StopType.Recovery:
      return StopType.Recovery;
    case _StopType.Transfer:
      return StopType.Transfer;
    case _StopType.None:
      return StopType.None;
    default:
      return exhaustive(stopType);
  }
};

export const stopDriverMapSchema = yup.object({
  uuid: yup.string().required(),
  stopUuid: yup.string().required(),
  driverUuid: yup.string().required(),
  driverSettlementBillUuid: yup.string().optional().nullable(),
  name: yup.string().optional().nullable(),
  enableDriverSettlement: yup.boolean().optional().nullable(),
  revenuePercentageRate: yup.number().optional().nullable(),
  revenueFlatRate: yup.number().optional().nullable(),
  driverPayoutFinalized: yup.boolean().defined(),
  isAttempt: yup.boolean().defined(),
  attemptedAt: yup.date().optional().nullable(),
});

export const stopSchema = yup
  .object({
    customCharges: yup.array().of(customChargeSchema).nullable(),
    destinationInInbound: yup.boolean().optional().nullable(),
    destinationInOutbound: yup.boolean().optional().nullable(),
    deadlineTime: yup.date().optional().nullable(),
    stopType: yup.mixed<StopType>().oneOf(Object.values(StopType)).required(),
    inboundMethod: yup
      .mixed<InboundMethod>()
      .oneOf(Object.values(InboundMethod))
      .optional()
      .nullable(),
    outboundMethod: yup
      .mixed<OutboundMethod>()
      .oneOf(Object.values(OutboundMethod))
      .optional()
      .nullable(),
    destinationAirport: yup.string().optional().nullable(),
    incomingCarrier: yup.string().optional().nullable(),
    standardStopType: yup
      .mixed<StandardStopType>()
      .oneOf(Object.values(StandardStopType))
      .nullable(),
    deadlineType: yup
      .mixed<DeadlineType>()
      .oneOf(Object.values(DeadlineType))
      .nullable(),
    deadlineDate: yup.date().optional().nullable(),
    deliveryDate: yup.date().optional().nullable(),
    appointmentTime: yup.date().optional().nullable(),
    endAppointmentTime: yup.date().optional().nullable(),
    completedAt: yup.date().optional().nullable(),
    arrivedAt: yup.date().optional().nullable(),
    equipmentNames: yup.string().optional().nullable(),
    specialInstructions: yup.string().optional().nullable(),
    proofOfDeliverySignee: yup.string().optional().nullable(),
    airportInfoUuid: yup.string().optional().nullable(),
    overridePackageWeight: yup.boolean().defined(),
    shouldUseDimWeight: yup.boolean().defined(),
    address: addressSchema,
    contactPerson: contactPersonSchema,
    shipperContactPerson: contactPersonSchema,
    consigneeContactPerson: contactPersonSchema,
    hideFromBilling: yup.boolean().defined(),
    hideFromDispatch: yup.boolean().nullable().optional(),
    status: yup.string(),
    uuid: yup.string().defined(),
    legUuid: yup.string().defined(),
    miles: yup.number().optional().nullable(),
    shipmentUuid: yup.string().defined(),
    documents: yup.array().of(documentSchema).optional().nullable(),
    expectedInboundArrivalDate: yup.date().optional().nullable(),
    expectedOutboundDate: yup.date().optional().nullable(),
    outboundDeadlineDate: yup.date().optional().nullable(),
    inboundDeadlineDate: yup.date().optional().nullable(),
    outboundCarrier: yup.string().optional().nullable(),
    freightCharge: freightChargeSchema.optional().nullable(),
    standardShipmentFieldsUuid: yup.string().nullable(),
    isLocal: yup.boolean().defined(),
    driverName: yup.string().nullable().optional(),
    notes: yup.string().nullable().optional(),
    invoiceUuid: yup.string().nullable().optional(),
    invoiceName: yup.string().nullable().optional(),
    invoiceDate: yup.date().nullable().optional(),
    invoicePostedDate: yup.date().nullable().optional(),
    paperworkMarkedComplete: yup.boolean().nullable().optional(),
    isSpecial: yup.boolean().optional(),
    routeUuid: yup.string().nullable().optional(),
    routeSlotUuid: yup.string().nullable().optional(),
    routeDate: yup.date().nullable().optional(),
    createdAt: yup.date().nullable().optional(),
    shipperAddress: addressSchemaWithAllOptionalFields,
    consigneeAddress: addressSchemaWithAllOptionalFields,
    transferAddress: addressSchemaWithAllOptionalFields,
    terminalUuid: yup
      .string()
      .nullable()
      .when(['stopType', 'terminalsEnabled', 'address.zip'], {
        is: (
          stopType: StopType,
          terminalsEnabled: boolean,
          zip: string | null | undefined,
        ) => {
          if (!terminalsEnabled) {
            return false;
          }
          if (stopType === StopType.None) {
            return false;
          }
          return (
            (!isNil(zip) && !isEmpty(zip)) ||
            stopType === StopType.PartnerCarrierPickup ||
            stopType === StopType.PartnerCarrierDropoff
          );
        },
        then: (schema) => schema.required('Terminal is required.'),
      }),
    terminalsEnabled: yup.boolean().nullable(),
    serviceDate: yup.date().nullable().optional().typeError('Invalid date'),
    driverUuid: yup.string().nullable().optional(),
    removedDriver: yup.boolean().nullable().optional(),
    driverType: yup
      .mixed<DriverType>()
      .oneOf(Object.values(DriverType))
      .optional(),
    settlementTotal: yup.number().optional(),
    settlementDeductionPercentageRate: yup.number().optional(),
    settlementDeductionFlatRate: yup.number().optional(),
    settlementBillingMethod: yup
      .mixed<BillingMethod>()
      .oneOf(Object.values(BillingMethod))
      .optional(),
    settlementName: yup.string().nullable().optional(),
    stopDriverMaps: yup.array().of(stopDriverMapSchema).optional().nullable(),
    appointmentConfirmed: yup.boolean().nullable().optional(),
    appointmentRequired: yup.boolean().nullable().optional(),
    shipmentStatus: yup
      .mixed<ShipmentStatus>()
      .oneOf(Object.values(ShipmentStatus))
      .defined(),
    shipmentType: yup
      .mixed<ShipmentType>()
      .oneOf(Object.values(ShipmentType))
      .defined(),
    totalCharge: yup.number().optional().nullable(),
  })
  .when('stopType', {
    is: (stopType: StopType) =>
      stopType === StopType.Delivery ||
      stopType === StopType.Pickup ||
      stopType === StopType.Transfer ||
      stopType === StopType.Recovery,
    otherwise: (schema) => schema.nullable(),
  });

export const getOrderSchema = (orderFormFields?: OrderFormFieldsFragment) =>
  yup.object({
    updatedAt: yup.date().optional().nullable(),
    scannedOrderResultUuid: yup.string().nullable().optional(),
    useKilograms: yup.boolean().optional().nullable(),
    useCentimeters: yup.boolean().optional().nullable(),
    uuid: yup.string().required(),
    serviceUuid: yup.string().optional().nullable(),
    fieldsUuid: yup.string().defined().nullable(),
    contactUuid: yup.string().required('Contact is required.'),
    contactStationId: yup.string().optional().nullable(),
    name: yup.string().optional().nullable(),
    shipperBillOfLadingNumber: yup
      .string()
      .required('Shipper BoL is required.'),
    lockShipperBillOfLadingNumber: yup.boolean(),
    masterAirwayBillOfLadingNumber: yup.string().optional().nullable(),
    secondaryReferenceNumber: yup.string().optional().nullable(),
    lockSecondaryReferenceNumber: yup.boolean(),
    tertiaryReferenceNumber: yup.string().optional().nullable(),
    quaternaryReferenceNumber: yup.string().optional().nullable(),
    quinaryReferenceNumber: yup.string().optional().nullable(),
    dimFactor: yup.number().optional().nullable(),
    units: yup.string().optional().nullable(),
    onHand: yup.boolean().optional().nullable(),
    receivedAtOriginDate: yup.date().optional().nullable(),
    receivedDate: yup.date().optional().nullable(),
    pickedDate: yup.date().optional().nullable(),
    pieceCount: yup.number().optional().nullable(),
    piecesPicked: yup.number().optional().nullable(),
    loadedDate: yup.date().optional().nullable(),
    personName: yup.string().optional().nullable(),
    personPhoneNumber: yup.string().optional().nullable(),
    personEmail: yup.string().optional().nullable(),
    paymentMethod: yup.string().optional().nullable(),
    codCheckAmountInDollars: yup.number().optional().nullable(),
    codCheckNumber: yup.string().optional().nullable(),
    isCollectOnDelivery: yup.boolean().optional().nullable(),
    defaultFuelBillingMethod: yup
      .mixed<FuelBillingMethod>()
      .oneOf(Object.values(FuelBillingMethod)),
    defaultFuelSurcharge: yup.number(),
    defaultFreightBillingMethod: yup
      .mixed<FreightBillingMethod>()
      .oneOf(Object.values(FreightBillingMethod)),
    refusedBy: yup.string().optional().nullable(),
    refusedDate: yup.date().optional().nullable(),
    holdReasonUuid: yup.string().optional().nullable(),
    holdReasonName: yup.string().optional().nullable(),
    warehouseUuid: yup.string().optional().nullable(),
    thirdPartyBrokerUuid: yup.string().optional().nullable(),
    tags: yup.array().of(tagSchema).optional().nullable(),
    notes: yup.string().optional().nullable(),
    documents: yup.array().of(documentSchema).defined(),
    stops: yup.array().of(stopSchema).optional().nullable(),
    packages: getPackagesSchema(orderFormFields),
    isCrossDock: yup.boolean().optional().nullable(),
    recurringOrderFrequency: recurringOrderFrequencySchema.nullable(),
    refNumbers: yup
      .array()
      .of(yup.string().defined().strict(true))
      .optional()
      .nullable(),
    orderSegmentType: yup
      .mixed<OrderSegmentType>()
      .oneOf(Object.values(OrderSegmentType))
      .nullable(),
    fulfillmentType: yup
      .mixed<FulfillmentType>()
      .oneOf(Object.values(FulfillmentType))
      .optional()
      .nullable(),
    vehicleTypeUuid: yup.string().optional().nullable(),
    isCourier: yup.boolean().nullable().optional(),
    orderComments: yup.array().of(orderCommentSchema).optional().nullable(),
    newComment: orderCommentSchema.optional().nullable(),
    detailedStatus: yup
      .mixed<OrderDetailedStatus>()
      .oneOf(Object.values(OrderDetailedStatus))
      .nullable()
      .optional(),
    deletedAutoAppliedAccessorials: yup
      .array()
      .of(yup.string().defined())
      .required(),
    osd: yup.boolean().nullable().optional(),
    status: yup
      .mixed<OrderStatus>()
      .oneOf(Object.values(OrderStatus))
      .nullable()
      .optional(),
    orderChargesShipment: orderChargesShipmentSchema.nullable(),
    isUsingLineHaul: yup.boolean().defined(),
    lineHaulShipment: lineHaulShipmentSchema.nullable(),
    lineHaulLaneUuid: yup
      .string()
      .optional()
      .nullable()
      .when(['isUsingLineHaul'], {
        is: (isUsingLineHaul: boolean) => isUsingLineHaul,
        then: (schema) => schema.required('Line haul lane is required.'),
      }),
    chargeableWeightCubicMeters: yup
      .number()
      // Allows the empty string in case the user types in a number and deletes it.
      .transform((curr, orig) => (isEmpty(orig) ? null : curr))
      .min(0)
      .optional()
      .nullable()
      .typeError('Chargeable weight must be a number'),
    totalSkids: yup.number().optional().nullable(),
    isReweighed: yup.boolean().optional().nullable(),
    driverQualificationIds: yup
      .array()
      .of(yup.string().defined().strict(true))
      .optional()
      .nullable(),
    inBond: yup.boolean().optional().nullable(),
    hazmat: yup.boolean().optional().nullable(),
    itTeNumber: yup.string().optional().nullable(),
    externalNotes: yup.string().optional().nullable(),
    unNumber: yup.string().optional().nullable(),
    hazmatDescription: yup.string().optional().nullable(),
    emergencyResponseNumber: yup.string().optional().nullable(),
    requiresPlacard: yup.boolean().optional().nullable(),
    hazmatClass: yup
      .mixed<HazmatClass>()
      .oneOf(Object.values(HazmatClass))
      .optional()
      .nullable(),
    packageGroup: yup
      .mixed<PackageGroup>()
      .oneOf(Object.values(PackageGroup))
      .optional()
      .nullable(),
    totalCharge: yup.number().optional().nullable(),
    previousTotalAmountCents: yup.number().optional().nullable(),
  });

const useOrderForm = () => {
  const { companyData } = useMe();
  const form = useForm({
    resolver: yupResolver(getOrderSchema(companyData?.orderFormFields)),
    mode: 'all',
  });

  return { form };
};

export default useOrderForm;
