import {
  Accordion,
  AccordionDetails,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import useServices from '../../../../common/react-hooks/use-services';
import { BatchAddShipmentsToInvoicesPreviewFragment } from '../../../../generated/graphql';
import AccordionSummary from '../accordion-summary';

const ContactPreviewCard = ({
  contactPreview,
  serviceUuid,
}: {
  contactPreview: BatchAddShipmentsToInvoicesPreviewFragment;
  serviceUuid: string | undefined;
}) => {
  const theme = useTheme();
  const { contactName } = contactPreview;
  const { getServiceName } = useServices();

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: theme.palette.borderColor.main,
      }}
    >
      <AccordionSummary>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ ml: 1 }}
            >
              <Typography>{contactName}</Typography>
              {!isNil(serviceUuid) && (
                <Typography sx={{ color: 'text.secondary' }}>
                  {getServiceName(serviceUuid)}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{ color: 'text.secondary', float: 'right' }}
              variant="caption"
            >
              Total orders:{' '}
              {contactPreview.newInvoiceAdds.reduce(
                (prev, curr) => prev + (curr.shipmentUuids.length ?? 0),
                0,
              ) +
                contactPreview.existingInvoiceAdds.reduce(
                  (prev, curr) => prev + (curr.shipmentUuids.length ?? 0),
                  0,
                )}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
          <TableContainer>
            <Table aria-label="invoice-preview-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>Type</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Number of shipments to add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactPreview.existingInvoiceAdds.map((invoice) => {
                  return (
                    <TableRow key={invoice.uuid}>
                      <TableCell sx={{ width: '20%' }}>Existing</TableCell>
                      <TableCell sx={{ width: '40%' }}>
                        {/* is invoice name or journal number */}
                        {invoice.name}
                      </TableCell>
                      <TableCell>{invoice.shipmentUuids.length}</TableCell>
                    </TableRow>
                  );
                })}
                {contactPreview.newInvoiceAdds.map((invoice) => {
                  return (
                    <TableRow key={invoice.shipmentUuids[0]}>
                      <TableCell sx={{ width: '20%' }}>New</TableCell>
                      <TableCell sx={{ width: '40%' }}>
                        {invoice?.name ?? 'New Invoice'}
                      </TableCell>
                      <TableCell>{invoice.shipmentUuids.length}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(ContactPreviewCard);
