import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import type { ParseCsvOrdersOrderFragment } from '../../../../../../generated/graphql';
import { OrderStopTable } from './order-stop-table';

type OrderRowProps = {
  order: ParseCsvOrdersOrderFragment;
  enableInboundStop: boolean;
  enableOutboundStop: boolean;
  showPackageSpec: boolean;
};

const OrderRow: FunctionComponent<OrderRowProps> = ({
  order,
  enableInboundStop,
  enableOutboundStop,
  showPackageSpec,
}) => {
  const [open, setOpen] = useState(false);

  const {
    referenceNumber,
    billingPartyContact,
    customerReferenceNumber,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    notes,
    serviceLevel,
    inboundStop,
    outboundStop,
    freightChargeRate,
    freightChargeType,
    fuelSurcharge,
    fuelChargeType,
    dimFactor,
  } = order;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Tooltip
            title={
              !isNil(customerReferenceNumber) &&
              customerReferenceNumber !== billingPartyContact.displayName
                ? `Imported reference number “${customerReferenceNumber}”`
                : undefined
            }
          >
            <span>{billingPartyContact.displayName}</span>
          </Tooltip>
        </TableCell>
        <TableCell>{shipperBillOfLadingNumber}</TableCell>
        <TableCell>{masterAirwayBillOfLadingNumber}</TableCell>
        <TableCell>{referenceNumber}</TableCell>
        <TableCell>{notes}</TableCell>
        <TableCell>{serviceLevel?.name ?? '-'}</TableCell>
        {enableInboundStop && (
          <TableCell>
            {!isNil(inboundStop) ? <OrderStopTable stop={inboundStop} /> : '-'}
          </TableCell>
        )}
        {enableOutboundStop && (
          <TableCell>
            {!isNil(outboundStop) ? (
              <OrderStopTable stop={outboundStop} />
            ) : (
              '-'
            )}
          </TableCell>
        )}
        <TableCell>{dimFactor}</TableCell>
        <TableCell>{freightChargeRate ?? '-'}</TableCell>
        <TableCell>{sentenceCase(freightChargeType ?? '-')}</TableCell>
        <TableCell>{fuelSurcharge ?? '-'}</TableCell>
        <TableCell>{sentenceCase(fuelChargeType ?? '-')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Packages
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {showPackageSpec && <TableCell>Type</TableCell>}
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>Length</TableCell>
                    <TableCell>Width</TableCell>
                    <TableCell>Height</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.packages.map((pkg) => {
                    return (
                      <TableRow key={pkg.uuid}>
                        {showPackageSpec && (
                          <TableCell>{pkg.packageSpec?.name ?? '-'}</TableCell>
                        )}
                        <TableCell>{pkg.description ?? '-'}</TableCell>
                        <TableCell>{pkg.quantity ?? '-'}</TableCell>
                        <TableCell>{pkg.weight ?? '-'}</TableCell>
                        <TableCell>{pkg.length ?? '-'}</TableCell>
                        <TableCell>{pkg.width ?? '-'}</TableCell>
                        <TableCell>{pkg.height ?? '-'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

type OrdersTableProps = {
  orders: ParseCsvOrdersOrderFragment[];
  enableInboundStop: boolean;
  enableOutboundStop: boolean;
};

export const OrdersTable: FunctionComponent<OrdersTableProps> = ({
  orders,
  enableInboundStop,
  enableOutboundStop,
}) => {
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);

  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableCell />
        <TableCell>Contact</TableCell>
        <TableCell>HAWB#</TableCell>
        <TableCell>MAWB#</TableCell>
        <TableCell>Ref#</TableCell>
        <TableCell>Notes</TableCell>
        <TableCell>Service Level</TableCell>
        {enableInboundStop && <TableCell>Inbound Stop</TableCell>}
        {enableOutboundStop && <TableCell>Outbound Stop</TableCell>}
        <TableCell>Dim Factor</TableCell>
        <TableCell>Freight Charge Rate</TableCell>
        <TableCell>Freight Charge Type</TableCell>
        <TableCell>Fuel Surcharge</TableCell>
        <TableCell>Fuel Charge Type</TableCell>
      </TableHead>
      {orders.map((order) => (
        <OrderRow
          key={order.shipperBillOfLadingNumber}
          order={order}
          enableInboundStop={enableInboundStop}
          enableOutboundStop={enableOutboundStop}
          showPackageSpec={ffPackageSpecs}
        />
      ))}
    </Table>
  );
};
