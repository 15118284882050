const useStyles = () => {
  return {
    flexRowBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      justifyItems: 'space-around',
      my: 1.5,
    },
    flexSpaceBetweenBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    card: {
      my: 3,
      padding: 2,
      width: '100%',
    },
    addressFields: {
      my: 2,
    },
    formContent: {
      justifyContent: 'center',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
  };
};

export default useStyles;
