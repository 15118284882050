import { styled } from '@mui/material/styles';
import { Link, LinkProps } from 'react-router-dom';

const StyledLink = styled(Link)<{ underline?: boolean }>(
  ({ theme, underline }) => ({
    textDecoration: underline === true ? 'underline' : 'none',
    color: theme.palette.primary.main,
  }),
);

type PalletRouterLinkProps = LinkProps & {
  underline?: boolean; // If set to true, the link text will be underlined
};

/**
 * A styled canonical router link that is the standard way to represent a link to navigate to a different page in Pallet.
 * @param props - The props for the link.
 * @returns A styled link that is used to navigate to a different route.
 */
const PalletRouterLink = ({
  underline = true,
  ...props
}: PalletRouterLinkProps) => <StyledLink {...props} underline={underline} />;

export default PalletRouterLink;
