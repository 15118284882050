import { useCallback, useMemo } from 'react';
import { useTagsQuery } from '../../generated/graphql';

const useTags = () => {
  const { data: tagsData, refetch: refetchTagsData } = useTagsQuery({
    fetchPolicy: 'cache-first',
  });

  const getTag = useCallback(
    (tagUuid: string) => {
      return tagsData?.getTags.find((tag) => tag.uuid === tagUuid);
    },
    [tagsData],
  );

  const tags = useMemo(() => {
    // Need to memo this, otherwise if tags are nil we create
    // a new `[]` reference on every render
    return tagsData?.getTags ?? [];
  }, [tagsData]);

  return {
    refetchTagsData,
    tags,
    getTag,
  };
};

export default useTags;
