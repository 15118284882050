import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import useMe from '../../../common/react-hooks/use-me';
import { useCsvOrderMappingsForEmailToolQuery } from '../../../generated/graphql';
import ConfigureCsvOrderMapping from './components/configure-csv-order-mapping';
import CsvOrderMappingsList from './components/csv-order-mappings-list';

const drawerWidth = 300;

const ConfigureEmailIngestionToolPage = () => {
  const { companyUuid } = useMe();
  const [selectedMappingUuid, setSelectedMappingUuid] = useState<string | null>(
    null,
  );
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);

  const [locallyCreatedMapping, setLocallyCreatedMapping] = useState<{
    uuid: string;
    name: string;
  } | null>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const { data: csvOrderMappings } = useCsvOrderMappingsForEmailToolQuery();

  const theme = useTheme();

  const handleCreateMapping = () => {
    setIsEditMode(false);
    const newMappingUuid = v4();
    setLocallyCreatedMapping({ uuid: newMappingUuid, name: 'New mapping' });

    setSelectedMappingUuid(newMappingUuid);
  };

  const handleSuccessfulCreate = (uuid: string) => {
    setIsEditMode(true);
    setLocallyCreatedMapping(null);
    setSelectedMappingUuid(uuid);
    setShowSuccessToast(true);
  };

  // when opened set the first mapping as selected
  useEffect(() => {
    if (isNil(selectedMappingUuid) && !isNil(csvOrderMappings)) {
      setSelectedMappingUuid(
        csvOrderMappings.csvOrderMappings[0]?.uuid ?? null,
      );
    }
  }, [selectedMappingUuid, csvOrderMappings]);

  if (isNil(companyUuid)) {
    return <CircularProgress />;
  }

  return (
    <Stack sx={{ display: 'flex', height: '100%' }} direction="row">
      <Stack
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          borderRight: `1px solid ${theme.palette.concreteGrey[30]}`,
          height: '100%',
        }}
      >
        <Toolbar sx={{ backgroundColor: 'white' }}>
          <Typography variant="h6" noWrap component="div">
            CSV Mappings
          </Typography>
        </Toolbar>
        <Divider />

        <Stack sx={{ p: 2, backgroundColor: 'white' }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search mappings..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            onClick={handleCreateMapping}
          >
            New Mapping
          </Button>
        </Stack>

        <CsvOrderMappingsList
          mappings={csvOrderMappings?.csvOrderMappings ?? []}
          locallyCreatedMapping={locallyCreatedMapping}
          selectedMappingUuid={selectedMappingUuid}
          setSelectedMappingUuid={setSelectedMappingUuid}
        />
      </Stack>
      {!isNil(selectedMappingUuid) && (
        <ConfigureCsvOrderMapping
          selectedMappingUuid={selectedMappingUuid}
          isEditMode={isEditMode}
          locallyCreatedMapping={locallyCreatedMapping}
          companyUuid={companyUuid}
          handleSuccessfulCreate={handleSuccessfulCreate}
        />
      )}
      {showSuccessToast && (
        <Snackbar
          open={showSuccessToast}
          autoHideDuration={6000}
          onClose={() => setShowSuccessToast(false)}
        >
          <Alert severity="success">Mapping created successfully</Alert>
        </Snackbar>
      )}
    </Stack>
  );
};

export default ConfigureEmailIngestionToolPage;
