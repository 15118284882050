import { shallow } from 'zustand/shallow';
import { COLORS } from '../../../common/constants';
import { lightenHexColor } from '../../../common/utils/colors';
import useDispatchStore from '../dispatch-store';
import { RouteSortType } from '../types/routes';
import { sortRoutes } from '../utils';

const useMapRouteColor = ({
  routeUuid,
  lighten = false,
}: {
  routeUuid: string;
  lighten?: boolean;
}) => {
  const [routeIndex] = useDispatchStore(
    (state) => [
      sortRoutes(state.routes.slice(), RouteSortType.NAME, true)?.findIndex(
        (r) => r.uuid === routeUuid,
      ) ?? 0,
    ],
    shallow,
  );

  if (lighten) {
    return lightenHexColor(COLORS[routeIndex % COLORS.length] ?? 'black', 0.2);
  }
  return COLORS[routeIndex % COLORS.length] ?? 'black';
};

export { useMapRouteColor };
