import { Button, Fade, Stack, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { ReportSendJobBatchFragment } from '../../../../generated/graphql';
import { titleCase } from '../../../daily-control-center/utils';
import ReportSendJobBatchProgress from './report-send-job-batch-progress';

const ReportSendJobBatchRow = ({
  reportSendJobBatch,
  setSelectedReportSendJobBatch,
}: {
  reportSendJobBatch: ReportSendJobBatchFragment;
  setSelectedReportSendJobBatch: Dispatch<
    SetStateAction<ReportSendJobBatchFragment | undefined>
  >;
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        {dayjs(reportSendJobBatch.createdAt).format('MM/DD/YY hh:mm a')}
      </TableCell>
      <TableCell>
        {titleCase(reportSendJobBatch.reportSendJobs[0]?.reportType ?? '-')}
      </TableCell>
      <TableCell align="right" sx={{ width: 400 }}>
        <ReportSendJobBatchProgress
          progress={reportSendJobBatch.reportSendJobBatchProgress}
        />
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <Stack spacing={1} direction="row" justifyContent="right">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedReportSendJobBatch(reportSendJobBatch);
              }}
            >
              View info
            </Button>
          </Stack>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default ReportSendJobBatchRow;
