import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Box,
  TableBody,
  Button,
  Typography,
  CircularProgress,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useQuickbooksHeaderFileMappingsQuery,
} from '../../../generated/graphql';
import CreateOrEditQuickbooksHeader, {
  CreateOrEditQBHeader,
} from './create-or-edit-quickbooks-header';
import QuickbooksHeaderMappingRow from './quickbooks-header-mapping-row';

const QuickbooksHeaderMappings = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsQuickbooks } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsQuickbooks,
  );

  const { data, loading } = useQuickbooksHeaderFileMappingsQuery();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {isCreateModalOpen && (
        <CreateOrEditQuickbooksHeader
          open={isCreateModalOpen}
          setOpen={setIsCreateModalOpen}
          createOrEdit={CreateOrEditQBHeader.Create}
          numberOfMappings={data?.quickbooksHeaderFileMappings.length ?? 0}
          uuid={undefined}
          headerName=""
          headerType={null}
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ fontWeight: 'bold' }}>Header mappings</Typography>
        <Button
          variant="contained"
          onClick={() => setIsCreateModalOpen(true)}
          disabled={!canWriteSettingsQuickbooks}
        >
          Create
        </Button>
      </Stack>
      {isNil(data) && loading === true && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isNil(data) && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.quickbooksHeaderFileMappings.length === 0 && (
                <Box
                  sx={{
                    p: '20px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  No Quickbooks headers yet.
                </Box>
              )}
              {data?.quickbooksHeaderFileMappings.map((header) => (
                <QuickbooksHeaderMappingRow
                  key={header.uuid}
                  uuid={header.uuid}
                  name={header.name}
                  headerType={header.headerType}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default QuickbooksHeaderMappings;
