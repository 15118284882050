import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Alert,
  Box,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import { FindCompanyDocumentStatusFilter } from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';

// const useStyles = () => ({
//   centeredRow: {
//     display: 'flex',
//     justifyContent: 'center',
//   },
// });

// interface EndOfDayPodDocumentViewerProps {
//   setShowMatchOrderSuccessMessage: React.Dispatch<
//     React.SetStateAction<boolean>
//   >;
//
//   setShowMatchOrderErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
// }

const EndOfDayPodDocumentViewer = () => {
  const { height } = useWindowDimensions();
  const { selectedDocumentUuids, companyDocuments, documentStatusFilter } =
    useContext(EndOfDayContext);

  // const [getOrder, { data: orderData }] = useOrderLazyQuery();
  // useEffect(() => {
  //   if (!isNil(selectedMatchedOrderUuid)) {
  //     getOrder({ variables: { uuid: selectedMatchedOrderUuid } });
  //   }
  // }, [
  //   selectedMatchedOrderUuid,
  //   companyDocuments,
  //   documentStatusFilter,
  //   selectedDocumentUuids,
  // ]);

  const [
    showDeleteDocumentSuccessSnackbar,
    setShowDeleteDocumentSuccessSnackbar,
  ] = useState<boolean>(false);
  const [showDeleteDocumentErrorSnackbar, setShowDeleteDocumentErrorSnackbar] =
    useState<boolean>(false);

  const [selectedDocumentUuid, setSelectedDocumentUuid] = useState<string>('');
  const document = useMemo(() => {
    return companyDocuments.find((doc) => selectedDocumentUuid === doc.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentUuid]);

  const documentIndex = useMemo(() => {
    return selectedDocumentUuids.findIndex(
      (doc) => selectedDocumentUuid === doc,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentUuid]);

  // const pageNumbers = useMemo(() => {
  //   return orderData?.order?.documents
  //     .filter(
  //       (doc) =>
  //         doc.type === DocumentType.ProofOfDeliveryScanned &&
  //         !isNil(doc.pageNumber),
  //     )
  //     .map((doc) => doc.pageNumber)
  //     .sort((a, b) => {
  //       if (isNil(a) || isNil(b)) {
  //         return 0;
  //       }
  //       return a - b;
  //     });
  // }, [
  //   orderData,
  //   companyDocuments,
  //   documentStatusFilter,
  //   selectedDocumentUuids,
  // ]);

  useEffect(() => {
    const newSelectedDocumentUuid =
      documentStatusFilter === FindCompanyDocumentStatusFilter.Matched
        ? selectedDocumentUuids?.at(0)
        : selectedDocumentUuids?.at(selectedDocumentUuids.length - 1);
    if (!isEmpty(selectedDocumentUuids) && !isNil(newSelectedDocumentUuid)) {
      setSelectedDocumentUuid(newSelectedDocumentUuid);
    } else {
      setSelectedDocumentUuid('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentUuids]);

  const goBack = () => {
    setSelectedDocumentUuid(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      selectedDocumentUuids[
        selectedDocumentUuids.indexOf(selectedDocumentUuid) - 1
      ],
    );
  };
  const goForward = () => {
    setSelectedDocumentUuid(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      selectedDocumentUuids[
        selectedDocumentUuids.indexOf(selectedDocumentUuid) + 1
      ],
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: 'black',
        padding: '10px',
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* {documentStatusFilter === FindCompanyDocumentStatusFilter.Matched && */}
        {/*  !isNil(orderData) && */}
        {/*  !isEmpty(selectedDocumentUuids) && ( */}
        {/*    <> */}
        {/*      <Grid item xs={12} sx={styles.centeredRow}> */}
        {/*        <Typography> */}
        {/*          <strong>{`Order: ${orderData?.order?.standardOrderFields?.shipperBillOfLadingNumber}`}</strong> */}
        {/*        </Typography> */}
        {/*      </Grid> */}
        {/*      <Grid item xs={12}> */}
        {/*        {!isNil(pageNumbers) && !isEmpty(pageNumbers) ? ( */}
        {/*          <Typography>{`Proof of Delivery Pages: ${pageNumbers.join( */}
        {/*            ',', */}
        {/*          )}`}</Typography> */}
        {/*        ) : ( */}
        {/*          <> */}
        {/*            <Grid item xs={12} sx={styles.centeredRow}> */}
        {/*              <Typography>Specify Proof of Delivery Pages</Typography> */}
        {/*            </Grid> */}
        {/*            <SpecifyBillOfLadingForm */}
        {/*              setShowMatchOrderSuccessMessage={ */}
        {/*                setShowMatchOrderSuccessMessage */}
        {/*              } */}
        {/*              setShowMatchOrderErrorMessage={ */}
        {/*                setShowMatchOrderErrorMessage */}
        {/*              } */}
        {/*              orderUuid={selectedMatchedOrderUuid} */}
        {/*            /> */}
        {/*          </> */}
        {/*        )} */}
        {/*      </Grid> */}
        {/*    </> */}
        {/*  )} */}
        <Grid item xs={2}>
          <IconButton
            onClick={goBack}
            disabled={documentIndex === 0 || isEmpty(selectedDocumentUuid)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontSize: '15px' }}>
              {!isNil(document) && document.fileName}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            sx={{ float: 'right' }}
            onClick={goForward}
            disabled={
              documentIndex === selectedDocumentUuids.length - 1 ||
              selectedDocumentUuids.length <= 1
            }
          >
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
      </Grid>
      {!isNil(document) && (
        <object
          width="100%"
          height={
            document.fileType === 'application/pdf'
              ? `${height - 200}px`
              : undefined
          }
          data={document.preSignedGetUrl}
        >
          {' '}
        </object>
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showDeleteDocumentSuccessSnackbar}
      >
        <Alert
          severity="success"
          onClose={() => setShowDeleteDocumentSuccessSnackbar(false)}
        >
          Successfully deleted document.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showDeleteDocumentErrorSnackbar}
      >
        <Alert
          severity="error"
          onClose={() => setShowDeleteDocumentErrorSnackbar(false)}
        >
          Error deleting document.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EndOfDayPodDocumentViewer;
