import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import SearchResultsTableRow from './search-results-table-row';

const SearchResultsTable = <T extends [string, React.ReactNode[]]>({
  focusedRowIndex,
  headers,
  columnWidths,
  data,
  selectRow,
  loading,
}: {
  focusedRowIndex: number;
  headers: React.ReactNode[];
  columnWidths: (number | undefined)[] | undefined;
  data: T[];
  selectRow: (row: string, openInNewTab?: boolean) => void;
  loading: boolean;
}) => {
  return (
    <TableContainer>
      <Table
        size="small"
        padding="normal"
        stickyHeader
        aria-label="scanned-orders-from-emails-table"
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell sx={{ width: columnWidths?.[index] }} key={index}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? Array.from(Array(10).keys()).map((i) => (
                <TableRow key={i}>
                  {headers.map((header, index) => (
                    <TableCell
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      sx={{ width: columnWidths?.[index] }}
                    >
                      <Skeleton sx={{ width: '75%' }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : data.map((row, index) => (
                <SearchResultsTableRow
                  selected={focusedRowIndex === index}
                  idx={index}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  columnWidth={columnWidths?.[index]}
                  data={row[1]}
                  selectRow={(openInNewTab) => selectRow(row[0], openInNewTab)}
                />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SearchResultsTable;
