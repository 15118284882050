import { DispatchTableColorField } from '../../../generated/graphql';

export enum SpecialField {
  HasAppointmentDateTrue,
  HasAppointmentDateFalse,
}

export const fieldOptions = [
  {
    label: 'Appointment start',
    value: DispatchTableColorField.AppointmentStartWithin,
  },
  {
    label: 'Appointment end',
    value: DispatchTableColorField.AppointmentEndWithin,
  },
  {
    label: 'Appointment has passed',
    value: DispatchTableColorField.AppointmentPassed,
  },
  {
    label: 'Has an appointment date',
    value: SpecialField.HasAppointmentDateTrue,
  },
  {
    label: 'Does not have an appointment date',
    value: SpecialField.HasAppointmentDateFalse,
  },
  {
    label: 'Deadline is today',
    value: DispatchTableColorField.DeadlineToday,
  },
  {
    label: 'Deadline is tomorrow',
    value: DispatchTableColorField.DeadlineTomorrow,
  },
  {
    label: 'Deadline has passed',
    value: DispatchTableColorField.DeadlinePassed,
  },
  {
    label: 'Special',
    value: DispatchTableColorField.IsSpecial,
  },
  {
    label: 'Service',
    value: DispatchTableColorField.Service,
  },
] as const;

export type FieldOption = (typeof fieldOptions)[number];
