import {
  Alert,
  Box,
  Button,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  AutoApplyAccessorialConfigFragment,
  AutoApplyAccessorialRuleType,
  PermissionResource,
  useAutoApplyAccessorialConfigsQuery,
} from '../../generated/graphql';
import AddAutoApplyAccessorialRuleModal from './components/add-auto-apply-accessorial-rule-modal';
import EditAutoApplyAccessorialRuleModal from './components/edit-auto-apply-accessorial-rule-modal';

const AccessorialsTab = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsAutoApplyAccessorialRules } =
    getPermissionsFlags(
      userPermissions,
      PermissionResource.SettingsAutoApplyAccessorialRules,
    );

  const [hoveredConfigUuid, setHoveredConfigUuid] = useState<string>();
  const [addRuleModalOpen, setAddRuleModalOpen] = useState(false);
  const [editRuleModalUuid, setEditRuleModalUuid] = useState<
    string | undefined
  >();

  const [showAddedConfig, setShowAddedConfig] = useState(false);
  const [showEditedConfig, setShowEditedConfig] = useState(false);
  const [failedToAddConfig, setFailedToAddConfig] = useState(false);
  const [failedToEditConfig, setFailedToEditConfig] = useState(false);

  const { data: autoApplyAccessorialConfigsData } =
    useAutoApplyAccessorialConfigsQuery();

  const targetValueForConfig = (
    config: AutoApplyAccessorialConfigFragment,
    // eslint-disable-next-line consistent-return
  ) => {
    // eslint-disable-next-line default-case
    switch (config.ruleType) {
      case AutoApplyAccessorialRuleType.Contact:
        return config?.contact?.displayName;
      case AutoApplyAccessorialRuleType.Service:
        return sentenceCase(config?.service?.name ?? '');
      case AutoApplyAccessorialRuleType.StopType:
        return sentenceCase(config?.stopType ?? '');
      case AutoApplyAccessorialRuleType.ContactAndService:
        return (
          <Stack>
            <Box> Contact: {config?.contact?.displayName}</Box>
            <Box> Service: {sentenceCase(config?.service?.name ?? '')} </Box>
          </Stack>
        );
      case AutoApplyAccessorialRuleType.ContactAndServiceAndTag:
        return (
          <Stack>
            <Box> Contact: {config?.contact?.displayName}</Box>
            <Box> Service: {sentenceCase(config?.service?.name ?? '')} </Box>
            <Box> Tag: {config?.tag?.value}</Box>
          </Stack>
        );
      case AutoApplyAccessorialRuleType.ServiceAndTag:
        return (
          <Stack>
            <Box> Service: {sentenceCase(config?.service?.name ?? '')} </Box>
            <Box> Tag: {config?.tag?.value}</Box>
          </Stack>
        );
      case AutoApplyAccessorialRuleType.StopTypeAndTerminal:
        return (
          <Stack>
            <Box> Stop Type: {sentenceCase(config?.stopType ?? '')} </Box>
            <Box> Terminal: {config?.terminal?.name ?? ''}</Box>
          </Stack>
        );
      case AutoApplyAccessorialRuleType.IsHazmat:
      case AutoApplyAccessorialRuleType.IsInBond:
      case AutoApplyAccessorialRuleType.Global:
        return '';
      case AutoApplyAccessorialRuleType.Residential:
        return '';
      default:
        exhaustive(config.ruleType);
    }
  };

  return (
    <Grid container spacing={2}>
      {addRuleModalOpen && (
        <AddAutoApplyAccessorialRuleModal
          open={addRuleModalOpen}
          onClose={() => setAddRuleModalOpen(false)}
          setShowAddedConfig={setShowAddedConfig}
          setFailedToAddConfig={setFailedToAddConfig}
        />
      )}
      {!isNil(editRuleModalUuid) && (
        <EditAutoApplyAccessorialRuleModal
          open={!isNil(editRuleModalUuid)}
          onClose={() => setEditRuleModalUuid(undefined)}
          configUuid={editRuleModalUuid}
          setShowEditedConfig={setShowEditedConfig}
          setFailedToEditConfig={setFailedToEditConfig}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowAddedConfig(false)}
        open={showAddedConfig}
      >
        <Alert>Successfully added rule.</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowEditedConfig(false)}
        open={showEditedConfig}
      >
        <Alert>Successfully edited rule.</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setFailedToAddConfig(false);
          setFailedToEditConfig(false);
        }}
        open={failedToAddConfig || failedToEditConfig}
      >
        <Alert severity="error">An error occurred.</Alert>
      </Snackbar>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell>Accessorial(s)</TableCell>
              <TableCell>Rule Type</TableCell>
              <TableCell>Target Value</TableCell>
              <TableCell>Contacts included with rule</TableCell>
              <TableCell>Contacts excluded from rule</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  onClick={() => setAddRuleModalOpen(true)}
                  disabled={!canWriteSettingsAutoApplyAccessorialRules}
                >
                  Add Rule
                </Button>
              </TableCell>
            </TableHead>
            {autoApplyAccessorialConfigsData?.autoApplyAccessorialConfigs?.map(
              (config) => {
                return (
                  <TableRow
                    key={config.uuid}
                    onMouseEnter={() => {
                      setHoveredConfigUuid(config.uuid);
                    }}
                    onMouseLeave={() => {
                      setHoveredConfigUuid(undefined);
                    }}
                  >
                    <TableCell>
                      {config.accessorials.map((acc) => acc.name).join(', ')}
                    </TableCell>
                    <TableCell>{sentenceCase(config.ruleType)}</TableCell>
                    <TableCell>{targetValueForConfig(config)}</TableCell>
                    <TableCell sx={{ maxWidth: '200px' }}>
                      {config.contactsToIncludeWithRule
                        ?.map((contact) => contact.displayName)
                        ?.join(', ')}
                    </TableCell>
                    <TableCell sx={{ maxWidth: '200px' }}>
                      {config.contactsToExcludeFromRule
                        ?.map((contact) => contact.displayName)
                        ?.join(', ')}
                    </TableCell>
                    <TableCell align="right">
                      <Fade in={hoveredConfigUuid === config.uuid}>
                        <Button
                          variant="contained"
                          onClick={() => setEditRuleModalUuid(config.uuid)}
                          disabled={!canWriteSettingsAutoApplyAccessorialRules}
                        >
                          Edit
                        </Button>
                      </Fade>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AccessorialsTab;
