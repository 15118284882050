import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  FormGroup,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import { ReportStatistic } from '../../../generated/graphql';
import useReportsStore from '../reports-store';
import {
  REVENUE_REPORT_STATISTICS_BY_STOP,
  ORDER_PACKAGE_COUNT_REPORT_STATISTICS,
  TERMINAL_HEAD_CELLS,
  TERMINAL_CSV_HEADERS,
} from '../types/report-configs';
import { TerminalReportBucketData } from '../types/report-types';
import { Order } from '../utils';
import ReportTable from './report-table';
import SkeletonChartLoader from './skeleton-chart-loader';
import TerminalReportBarChart from './terminal-report-bar-chart';

const TerminalReport = () => {
  const [dataTabsViewIndex, setDataTabsViewIndex] = useState<number>(0);
  const [chartData, setChartData] = useState<TerminalReportBucketData[]>([]);

  const [reportGroupConfiguration, setCurrentReportGroupConfiguration] =
    useReportsStore(
      (state) => [
        state.reportGroupConfigurations[state.currentReportIndex],
        state.setCurrentReportGroupConfiguration,
      ],
      shallow,
    );
  const terminalReportData = useReportsStore(
    (state) => state.terminalReportData,
  );
  const [isLoading] = useReportsStore((state) => [state.isLoading], shallow);

  useEffect(() => {
    setChartData(terminalReportData);
  }, [terminalReportData]);

  const handleReportStatisticChange = (reportStatistic: string) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      if (data.reportStatistics.includes(reportStatistic as ReportStatistic)) {
        data.reportStatistics = data.reportStatistics.filter(
          (value) => value !== reportStatistic,
        );
      } else {
        data.reportStatistics = [
          ...data.reportStatistics,
          reportStatistic as ReportStatistic,
        ];
      }
      setCurrentReportGroupConfiguration(data);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup
          disableElevation
          variant="outlined"
          color="primary"
          size="small"
        >
          <Button
            variant={dataTabsViewIndex === 0 ? 'contained' : 'outlined'}
            onClick={() => setDataTabsViewIndex(0)}
          >
            Revenue Chart
          </Button>
          <Button
            variant={dataTabsViewIndex === 1 ? 'contained' : 'outlined'}
            onClick={() => setDataTabsViewIndex(1)}
          >
            Counts Chart
          </Button>
          <Button
            variant={dataTabsViewIndex === 2 ? 'contained' : 'outlined'}
            onClick={() => setDataTabsViewIndex(2)}
          >
            Terminals Table
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={0}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Stack>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={8} alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <FormGroup row>
                        {REVENUE_REPORT_STATISTICS_BY_STOP.map(
                          (reportStatistic) => (
                            <FormControlLabel
                              key={reportStatistic.configKey}
                              checked={reportGroupConfiguration?.reportStatistics.includes(
                                reportStatistic.configKey,
                              )}
                              control={
                                <Checkbox
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: reportStatistic.color,
                                    },
                                  }}
                                  onChange={() => {
                                    handleReportStatisticChange(
                                      reportStatistic.configKey,
                                    );
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  fontSize={15}
                                  color={reportStatistic.color}
                                >
                                  {sentenceCase(reportStatistic.configKey)}
                                </Typography>
                              }
                            />
                          ),
                        )}
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={2} />
                  {!isLoading ? (
                    <TerminalReportBarChart
                      reportStatisticsConfig={REVENUE_REPORT_STATISTICS_BY_STOP}
                      height={800}
                      xLabel="USD ($)"
                      data={chartData}
                    />
                  ) : (
                    <SkeletonChartLoader height={800} />
                  )}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={1}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Stack>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={8} alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <FormGroup row>
                        {ORDER_PACKAGE_COUNT_REPORT_STATISTICS.map(
                          (reportStatistic) => (
                            <FormControlLabel
                              key={reportStatistic.configKey}
                              checked={reportGroupConfiguration?.reportStatistics.includes(
                                reportStatistic.configKey,
                              )}
                              control={
                                <Checkbox
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: reportStatistic.color,
                                    },
                                  }}
                                  onChange={() => {
                                    handleReportStatisticChange(
                                      reportStatistic.configKey,
                                    );
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  fontSize={15}
                                  color={reportStatistic.color}
                                >
                                  {sentenceCase(reportStatistic.configKey)}
                                </Typography>
                              }
                            />
                          ),
                        )}
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={2} />
                  {!isLoading ? (
                    <TerminalReportBarChart
                      reportStatisticsConfig={
                        ORDER_PACKAGE_COUNT_REPORT_STATISTICS
                      }
                      height={800}
                      xLabel="USD ($)"
                      data={chartData}
                    />
                  ) : (
                    <SkeletonChartLoader height={800} />
                  )}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={2}>
          <ReportTable
            headCells={TERMINAL_HEAD_CELLS}
            csvHeaders={TERMINAL_CSV_HEADERS}
            isLoading={isLoading}
            reportData={terminalReportData}
            reportGroupConfiguration={reportGroupConfiguration}
            defaultRowsPerPage={15}
            defaultOrder={Order.DESC}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default TerminalReport;
