import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, Stack } from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { OnSubmitParams } from '../../types';
import DeleteOrderModal from '../delete-order-modal';
import ContextMenu from '../right-sidebar/context-menu';
import PrintMenu from '../right-sidebar/print-menu';
import SaveButton from '../save-button';

const OrderActions = ({
  isEditMode,
  isDuplicate,
  onSubmit,
  deleteEnabled,
  setIsLeavingPageValid,
  rebillMode,
  fromEdiReview,
}: {
  isEditMode: boolean;
  isDuplicate: boolean;
  onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  deleteEnabled: boolean;
  setIsLeavingPageValid: Dispatch<SetStateAction<boolean>>;
  rebillMode?: boolean;
  fromEdiReview?: boolean;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [printButtonAnchorEl, setPrintButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [extrasButtonAnchorEl, setExtrasButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  return (
    <>
      {!isNil(showDeleteModal) && (
        <DeleteOrderModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          setIsLeavingPageValid={setIsLeavingPageValid}
        />
      )}
      {!isNil(printButtonAnchorEl) && (
        <PrintMenu
          showPrintMenu={!isNil(printButtonAnchorEl)}
          onClose={() => {
            setPrintButtonAnchorEl(null);
          }}
          buttonRef={printButtonAnchorEl}
          isEditMode={isEditMode}
        />
      )}
      {!isNil(extrasButtonAnchorEl) && (
        <ContextMenu
          showContextMenu={!isNil(extrasButtonAnchorEl)}
          onClose={() => {
            setExtrasButtonAnchorEl(null);
          }}
          buttonRef={extrasButtonAnchorEl}
          onSubmit={onSubmit}
          onDelete={deleteEnabled ? () => setShowDeleteModal(true) : undefined}
        />
      )}
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          onClick={(e) => {
            if (isEditMode) {
              setPrintButtonAnchorEl(e.currentTarget);
            } else {
              onSubmit({
                saveAndPrint: true,
                refetchOrderAfterSave: isDuplicate,
              });
            }
          }}
          endIcon={<ArrowDropDownIcon />}
        >
          {isEditMode ? 'Print' : 'Save + Print'}
        </Button>
        <SaveButton
          isEditMode={isEditMode}
          onSubmit={onSubmit}
          rebillMode={rebillMode}
          hideSaveButton={fromEdiReview}
        />
        <IconButton
          size="small"
          onClick={(e) => setExtrasButtonAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
      </Stack>
    </>
  );
};

export default React.memo(OrderActions);
