import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

export type DriverValues = Partial<{
  firstName: string;
  lastName: string;
}> & { uuid: string };

const driverValuesAdapter = createEntityAdapter<DriverValues>({
  selectId: (driver) => driver.uuid,
});

export const driverValuesSlice = createSlice({
  name: 'driverValues',
  initialState: driverValuesAdapter.getInitialState(),
  reducers: {
    addOneDriverValues: driverValuesAdapter.addOne,
    updateOneDriverValues: driverValuesAdapter.updateOne,
    removeOneDriverValues: driverValuesAdapter.removeOne,
    setAllDriverValues: driverValuesAdapter.setAll,
    upsertOneDriverValues: driverValuesAdapter.upsertOne,
  },
});

export const { selectById: selectDriverValuesById } =
  driverValuesAdapter.getSelectors((state: RootState) => state.driverValues);

const driversSelector = (state: RootState) => state.driverValues;

export const selectDriversByIds = createSelector(
  driversSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<DriverValues>, entityIds: EntityId[]) => {
    const entityResults: DriverValues[] = [];
    entityIds.forEach((id) => {
      const match = driverValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

const driverValuesReducer = driverValuesSlice.reducer;

export const {
  addOneDriverValues,
  removeOneDriverValues,
  updateOneDriverValues,
  setAllDriverValues,
  upsertOneDriverValues,
} = driverValuesSlice.actions;

export default driverValuesReducer;
