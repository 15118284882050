import CloseIcon from '@mui/icons-material/Close';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { capitalCase } from 'change-case';
import currency from 'currency.js';
import dayjs, { Dayjs } from 'dayjs';
import { groupBy, isNil } from 'lodash';
import pluralize from 'pluralize';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { filterNotNil, uniqueVals } from 'shared/array';
import { safeAdd, safeDivide, safeMultiply, safeSubtract } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { useDebounce } from 'use-debounce';
import useLocalStorageState from 'use-local-storage-state';
import DateDropdownPicker, {
  DateOption,
  initialDateOption,
} from '../../../../common/components/date-dropdown-picker';
import GeneralDatePicker from '../../../../common/components/date-picker';
import useMe from '../../../../common/react-hooks/use-me';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import { convertNumberStringToFloat } from '../../../../common/utils/utils';
import {
  AllInvoicesForPaymentApplicationQuery,
  OrdersForPaymentsSort,
  OrdersForPaymentsSortField,
  GroupOrdersForPaymentsBy,
  InvoiceForPaymentFragment,
  OrdersForPaymentFragment,
  PaymentRail,
  PaymentType,
  Segment,
  SortDirection,
  useCreatePaymentMutation,
  useOrderAmountsToPayForInvoiceLazyQuery,
  useOrdersForPaymentsLazyQuery,
  usePaymentByUuidLazyQuery,
  useUpdatePaymentMutation,
  useCreditTypesQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import styles from '../../styles';
import { CreateOrEdit } from '../invoices/enums';
import InvoiceFilterButton, {
  InvoiceFilterOption,
} from '../invoices/invoice-filter-button';
import PaymentApplicationOrderRow, {
  OrderToCharge,
} from './payment-application-order-row';
import {
  formatPaymentRail,
  getPaymentLessThanBalanceMessage,
  SingleColumnPaymentApplicationOrdersTableSortLabel,
} from './utils';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const DEFAULT_INVOICES_PAGE_SIZE = ROWS_PER_PAGE_OPTIONS[0];
const ROWS_PER_PAGE_STORAGE_KEY = 'CREATE_PAYMENT_MODAL_ROWS_PER_PAGE';
const noInvoicePlaceholder: InvoiceForPaymentFragment = {
  uuid: '',
  name: 'No invoice',
  date: null,
  journalNumber: 0,
};
const defaultSort: OrdersForPaymentsSort = {
  sortBy: OrdersForPaymentsSortField.ShipperBillOfLadingNumber,
  sortDirection: SortDirection.Asc,
};

interface SaveState {
  orderToCharge: OrderToCharge;
  amount: string;
  date: Dayjs;
  referenceNumber: string;
  comment: string;
  glTerminalUuid: string | null;
  creditTypeUuid: string | null;
  paymentRail: PaymentRail | null;
}

// Idea: Maintain a dictionary of order UUID to charge mappings. You can use the existing state mappings
// When someone clicks pay order, to populate the amount, maintain a mapping of order UUIDs to charge amounts and use that to fill the value
// When someone clicks pay invoice, to populate the amount, add all the orders in that invoice to the list
const PaymentApplicationModal = ({
  amount,
  date,
  setDate,
  referenceNumber,
  setReferenceNumber,
  comment,
  setComment,
  onClose,
  contactUuid,
  initialPaymentUuid,
  createOrEdit,
  setAmount,
  setPaymentsListLoading,
  paymentType,
  paymentRail,
  setPaymentRail,
  creditTypeUuid,
  glTerminalUuid,
  setGlTerminalUuid,
  setCreditTypeUuid,
  allInvoicesForPaymentApplication,
}: {
  amount: string;
  date: Dayjs;
  setDate: Dispatch<SetStateAction<Dayjs>>;
  referenceNumber: string;
  setReferenceNumber: Dispatch<SetStateAction<string>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  onClose: () => void;
  contactUuid: string;
  initialPaymentUuid?: string;
  createOrEdit: CreateOrEdit;
  setAmount: Dispatch<SetStateAction<string>>;
  setPaymentsListLoading?: Dispatch<SetStateAction<boolean>>;
  paymentType: PaymentType;
  paymentRail: PaymentRail | null;
  setPaymentRail: Dispatch<SetStateAction<PaymentRail | null>>;
  creditTypeUuid: string | null;
  glTerminalUuid: string | null;
  setGlTerminalUuid: Dispatch<SetStateAction<string | null>>;
  setCreditTypeUuid: Dispatch<SetStateAction<string | null>>;
  allInvoicesForPaymentApplication?: AllInvoicesForPaymentApplicationQuery;
}) => {
  const { height } = useWindowDimensions();
  const theme = useTheme();
  const {
    companyConfiguration,
    segment,
    loading: companyDataLoading,
  } = useMe();
  const [getOrdersForPaymentsModal, { data, loading }] =
    useOrdersForPaymentsLazyQuery();
  const { terminals, getTerminalName } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [lastSavedState, setLastSavedState] = useState<SaveState>({
    orderToCharge: {},
    amount,
    date,
    referenceNumber,
    comment,
    glTerminalUuid,
    creditTypeUuid,
    paymentRail,
  });
  const [ordersToCharge, setOrdersToCharge] = useState<OrderToCharge>({});
  const [createPayment, { loading: createLoading }] =
    useCreatePaymentMutation();
  const [updatePayment, { loading: updateLoading }] =
    useUpdatePaymentMutation();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorageState(
    ROWS_PER_PAGE_STORAGE_KEY,
    {
      defaultValue: DEFAULT_INVOICES_PAGE_SIZE,
    },
  );
  const [groupOrdersBy, setGroupOrdersBy] = useState<GroupOrdersForPaymentsBy>(
    GroupOrdersForPaymentsBy.Invoice,
  );
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState<OrdersForPaymentsSort | null>(null);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [paymentUuid, setPaymentUuid] = useState(initialPaymentUuid);
  const [invoiceFilter, setInvoiceFilter] = useState<InvoiceFilterOption>();
  const [dateOption, setDateOption] = useState<DateOption>(initialDateOption);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalCountsByInvoice, setTotalCountsByInvoice] = useState<
    Map<string, number>
  >(new Map<string, number>());
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [paymentLessThanBalanceMessage, setPaymentLessThanBalanceMessage] =
    useState('');
  const [
    paymentLessThanBalanceConfirmCallback,
    setPaymentLessThanBalanceConfirmCallback,
  ] = useState<() => void>(() => null);

  const hawbLabel = segment === Segment.Cartage ? 'HAWB' : 'Ref number';

  const [debouncedSearchText] = useDebounce(searchText, 500);
  const appliedAmount = Object.values(ordersToCharge).reduce(
    (prev, curr) => safeAdd(prev, curr.amount),
    0,
  );
  const { data: creditTypesData } = useCreditTypesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [getOrdersToCharges, { loading: ordersToChargesLoading }] =
    useOrderAmountsToPayForInvoiceLazyQuery();
  const [getPaymentByUuid, { loading: paymentByUuidLoading }] =
    usePaymentByUuidLazyQuery();
  const parsedAmount = convertNumberStringToFloat(amount);
  const unappliedAmount = safeSubtract(
    parsedAmount ?? appliedAmount,
    appliedAmount,
  );
  const paidOrdersOnInvoiceCounts = useMemo(() => {
    const counts = new Map<string, number>();
    Object.entries(ordersToCharge).forEach(([_, orderInfo]) => {
      if (!isNil(orderInfo.invoiceUuid)) {
        counts.set(
          orderInfo.invoiceUuid,
          (counts.get(orderInfo.invoiceUuid) ?? 0) + 1,
        );
      }
    });
    return counts;
  }, [ordersToCharge]);

  const orderUuids = useMemo(
    () => filterNotNil(Object.keys(ordersToCharge).map((uuid) => uuid)),
    [ordersToCharge],
  );
  const allInvoicesForPaymentApplicationOptions = useMemo(() => {
    if (isNil(allInvoicesForPaymentApplication)) return [];
    return allInvoicesForPaymentApplication.invoices.edges.map((invoice) => ({
      value: invoice.node.uuid,
      label:
        (companyConfiguration?.useJournalNumberForInvoice === true
          ? invoice.node.journalNumber?.toString()
          : invoice.node.name) ?? undefined,
    }));
  }, [
    allInvoicesForPaymentApplication,
    companyConfiguration?.useJournalNumberForInvoice,
  ]);

  const creditTypeOptions = useMemo(() => {
    if (isNil(creditTypesData)) return [];
    return creditTypesData.creditTypes.map((creditType) => ({
      value: creditType.uuid,
      label: creditType.name,
    }));
  }, [creditTypesData]);

  const { fetchInvoiceTotalsByUuids, invoiceTotals, invoiceBalances } =
    useInvoiceTotals();

  const ordersForPaymentsData = useMemo(() => {
    return (
      data?.ordersForPayments.edges.map(({ node: order }) => ({
        ...order,
        invoice: order.invoice ?? noInvoicePlaceholder,
      })) ?? []
    );
  }, [data?.ordersForPayments.edges]);

  // Grouped by invoiceUuid or orderUuid
  const groupedOrders: Record<string, OrdersForPaymentFragment[]> =
    useMemo(() => {
      switch (groupOrdersBy) {
        case GroupOrdersForPaymentsBy.Invoice:
          return groupBy(
            ordersForPaymentsData,
            (order) => order.invoice?.uuid ?? noInvoicePlaceholder.uuid,
          );
        case GroupOrdersForPaymentsBy.Order:
          return groupBy(ordersForPaymentsData, (order) => order.uuid);
        default:
          return exhaustive(groupOrdersBy);
      }
    }, [ordersForPaymentsData, groupOrdersBy]);

  useEffect(() => {
    const uniqueInvoiceUuids = uniqueVals(
      filterNotNil(
        ordersForPaymentsData.map((order) =>
          !isNilOrEmptyString(order.invoice?.uuid) ? order.invoice?.uuid : null,
        ) ?? [],
      ),
    );
    fetchInvoiceTotalsByUuids(uniqueInvoiceUuids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersForPaymentsData]);

  useEffect(() => {
    const onMount = async () => {
      if (isNil(paymentUuid) || createOrEdit === CreateOrEdit.Create) return;

      const { data: paymentData } = await getPaymentByUuid({
        variables: { uuid: paymentUuid },
      });
      const ordersToChargeFromData: OrderToCharge = {};
      paymentData?.paymentByUuid?.paymentToOrderMappings.forEach(
        (paymentToOrderMapping) => {
          ordersToChargeFromData[paymentToOrderMapping.order.uuid] = {
            amount: safeDivide(paymentToOrderMapping.amountInCents, 100),
            invoiceUuid: paymentToOrderMapping.order.invoice?.uuid,
          };
        },
      );
      setOrdersToCharge(ordersToChargeFromData);
      const newAmount = safeDivide(
        paymentData?.paymentByUuid?.amountInCents ?? 0,
        100,
      ).toFixed(2);
      const newDate = dayjs(paymentData?.paymentByUuid?.date ?? '');
      setGlTerminalUuid(paymentData?.paymentByUuid?.glTerminal?.uuid ?? null);
      setAmount(newAmount);
      setComment(paymentData?.paymentByUuid?.comment ?? '');
      setDate(newDate);
      setReferenceNumber(paymentData?.paymentByUuid?.referenceNumber ?? '');
      setPaymentRail(paymentData?.paymentByUuid?.paymentRail ?? null);
      setCreditTypeUuid(paymentData?.paymentByUuid?.creditType?.uuid ?? null);
      setLastSavedState({
        orderToCharge: ordersToChargeFromData,
        amount: newAmount,
        date: newDate,
        referenceNumber: paymentData?.paymentByUuid?.referenceNumber ?? '',
        comment: paymentData?.paymentByUuid?.comment ?? '',
        glTerminalUuid: paymentData?.paymentByUuid?.glTerminal?.uuid ?? null,
        paymentRail: paymentData?.paymentByUuid?.paymentRail ?? null,
        creditTypeUuid: paymentData?.paymentByUuid?.creditType?.uuid ?? null,
      });
    };
    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrEdit, paymentUuid, terminals.length]);

  const onValidateOrderPayment = () => {
    if (isNil(parsedAmount)) {
      return false;
    }
    if (appliedAmount > parsedAmount) {
      setErrorMessage(
        `The sum of the order payments ($${appliedAmount}) is greater than the payment amount ($${parsedAmount})`,
      );
      return false;
    }
    return true;
  };

  const fetchOrders = async ({
    first,
    after,
    last,
    before,
    includeTotals = false,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    includeTotals?: boolean;
  }) => {
    let sorts: OrdersForPaymentsSort[] = [];
    if (!isNil(sort)) {
      sorts = [sort];
    } else if (groupOrdersBy === GroupOrdersForPaymentsBy.Order) {
      sorts = [defaultSort];
      setSort(defaultSort);
    }
    const res = await getOrdersForPaymentsModal({
      variables: {
        searchText: debouncedSearchText,
        first,
        after,
        last,
        before,
        contactUuid,
        invoiceUuid: invoiceFilter?.value,
        invoiceStartDate: dateOption.startDate,
        invoiceEndDate: dateOption.endDate,
        filterOutZeroBalance: true,
        currentPaymentUuid: paymentUuid,
        includeTotalCount: includeTotals,
        includeTotalCountsByInvoice: includeTotals,
        groupOrdersBy,
        sorts,
      },
    });
    const newTotalCount = res.data?.ordersForPayments.totalCount;
    if (!isNil(newTotalCount)) {
      setTotalCount(newTotalCount);
    }
    if (!isNil(res.data?.ordersForPayments.groupedCountsByInvoice)) {
      const newCounts = new Map<string, number>();
      res.data?.ordersForPayments.groupedCountsByInvoice.forEach((group) => {
        newCounts.set(group.groupUuid, group.count);
      });
      setTotalCountsByInvoice(newCounts);
    }
  };

  const prev = async () => {
    await fetchOrders({
      last: rowsPerPage,
      before: data?.ordersForPayments.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchOrders({
      first: rowsPerPage,
      after: data?.ordersForPayments.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = (newRowsPerPage?: number) => {
    fetchOrders({ first: newRowsPerPage ?? rowsPerPage, includeTotals: true });
  };

  const [refreshCurrentPage, setRefreshCurrentPage] = useState(() => refresh);

  const handleRowsPerPageChange = (val: number) => {
    setRowsPerPage(val);
    setPage(0);
    refresh(val);
  };

  const onPayInvoice = async (invoiceUuid: string) => {
    const { data: ordersToChargesData } = await getOrdersToCharges({
      variables: {
        invoiceUuids: [invoiceUuid],
        amountInCents: safeMultiply(unappliedAmount ?? 0, 100),
      },
    });
    const ordersToApplyTransformed =
      ordersToChargesData?.orderAmountsToPayForInvoice
        .map((orderToCharge) => {
          const existingOrderToCharge =
            lastSavedState.orderToCharge[orderToCharge.orderUuid];
          return {
            ...orderToCharge,
            existingAmount: existingOrderToCharge?.amount ?? 0,
          };
        })
        .filter(
          (orderToCharge) =>
            orderToCharge.amountInCents > 0 ||
            orderToCharge.orderTotalInCents <= 0 ||
            orderToCharge.currentBalanceInCents > 0 || // This is irrelevant to the apply() function -- it's here to make the total orders count accurate to include orders that this payment could have been applied to
            orderToCharge.existingAmount > 0,
        );

    const apply = () => {
      setOrdersToCharge((prevState) => {
        const prevStateCopy = { ...prevState };
        ordersToApplyTransformed?.forEach((orderToCharge) => {
          // If payment is already applied, add remaining balance
          const newAmount = safeAdd(
            orderToCharge.existingAmount,
            safeDivide(orderToCharge.amountInCents, 100),
          );
          if (newAmount > 0 || orderToCharge.existingAmount > 0) {
            prevStateCopy[orderToCharge.orderUuid] = {
              amount: safeAdd(
                orderToCharge.existingAmount,
                safeDivide(orderToCharge.amountInCents, 100),
              ),
              invoiceUuid: orderToCharge.invoiceUuid ?? undefined,
            };
          }
        });
        return prevStateCopy;
      });
    };

    const totalOrders = ordersToApplyTransformed?.length ?? 0;
    const appliedOrders =
      ordersToApplyTransformed?.filter(
        (orderToCharge) =>
          orderToCharge.amountInCents > 0 || orderToCharge.existingAmount > 0,
      ).length ?? 0;
    if (totalOrders - appliedOrders > 0) {
      setPaymentLessThanBalanceConfirmCallback(() => apply);
      setPaymentLessThanBalanceMessage(
        getPaymentLessThanBalanceMessage({
          paymentType,
          totalOrders,
          appliedOrders,
        }),
      );
    } else {
      apply();
    }
  };

  const onUnpayInvoice = async (invoiceUuid: string) => {
    const paidOrdersOnInvoice = Object.entries(ordersToCharge)
      .map(([orderUuid, orderInfo]) => ({
        ...orderInfo,
        orderUuid,
      }))
      .filter((o) => o.invoiceUuid === invoiceUuid);
    setOrdersToCharge((prevState) => {
      const copyOfState = { ...prevState };
      paidOrdersOnInvoice.forEach((order) => {
        delete copyOfState[order.orderUuid];
      });
      return copyOfState;
    });
  };

  const onSavePay = async () => {
    const validationRes = onValidateOrderPayment();
    if (!validationRes) {
      return;
    }
    setErrorMessage('');
    if (!isNil(parsedAmount)) {
      const mappingsToCreate = Object.entries(ordersToCharge).map((entry) => ({
        ...entry[1],
        uuid: entry[0],
      }));
      if (!isNil(paymentUuid)) {
        const res = await updatePayment({
          variables: {
            updatePaymentInput: {
              uuid: paymentUuid,
              referenceNumber,
              comment,
              date: date.toDate(),
              amountInCents: safeMultiply(parsedAmount, 100),
              glTerminalUuid,
              creditTypeUuid,
              paymentRail:
                paymentType === PaymentType.Payment ? paymentRail : null,
              updatePaymentToOrderMappingInput: mappingsToCreate.map(
                (orderMapping) => ({
                  amountInCents: safeMultiply(orderMapping.amount, 100),
                  orderUuid: orderMapping.uuid,
                }),
              ),
            },
          },
        });
        if (isNil(res.data?.updatePayment.uuid)) {
          setErrorMessage(
            `There was an error updating the ${paymentType.toLowerCase()}`,
          );
          return;
        }
        // I don't actually think its possible to get here from reading <ContactPaymentListRow /> and <CreatePaymentModal />
        // But I'm leaving this here for now just in case
      } else if (createOrEdit === CreateOrEdit.Create) {
        const res = await createPayment({
          variables: {
            createPaymentInput: {
              contactUuid,
              referenceNumber,
              comment,
              date: date.toDate(),
              amountInCents: safeMultiply(parsedAmount, 100),
              createPaymentToOrderMappingInputs: mappingsToCreate.map(
                (orderMapping) => ({
                  amountInCents: safeMultiply(orderMapping.amount, 100),
                  orderUuid: orderMapping.uuid,
                }),
              ),
              paymentType,
              paymentRail:
                paymentType === PaymentType.Payment ? paymentRail : null,
              creditTypeUuid,
              glTerminalUuid,
            },
          },
        });
        if (isNil(res.data?.createPayment.uuid)) {
          setErrorMessage(
            `There was an error updating the ${paymentType.toLowerCase()}`,
          );
          return;
        }
        setPaymentUuid(res.data?.createPayment.uuid);
      }
      refreshCurrentPage();
      if (!isNil(setPaymentsListLoading)) {
        setPaymentsListLoading(true);
      }
      setSuccessMessage('Payment saved');
      setLastSavedState({
        orderToCharge: ordersToCharge,
        amount,
        date,
        referenceNumber,
        comment,
        glTerminalUuid,
        paymentRail,
        creditTypeUuid,
      });
    }
  };

  useEffect(() => {
    refresh();
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, invoiceFilter, dateOption, sort]);

  const hasEditedSinceLastSave = useMemo(() => {
    if (lastSavedState.glTerminalUuid !== glTerminalUuid) return true;
    if (lastSavedState.creditTypeUuid !== creditTypeUuid) return true;
    if (lastSavedState.amount !== amount) return true;
    if (lastSavedState.date.toString() !== date.toString()) return true;
    if (lastSavedState.referenceNumber !== referenceNumber) return true;
    if (lastSavedState.comment !== comment) return true;
    if (lastSavedState.paymentRail !== paymentRail) return true;
    if (
      JSON.stringify(Object.entries(ordersToCharge).sort()) !==
      JSON.stringify(Object.entries(lastSavedState.orderToCharge).sort())
    )
      return true;
    // if we are creating a payment and have not saved it yet, show the save button
    return createOrEdit === CreateOrEdit.Create && isNil(paymentUuid);
  }, [
    lastSavedState,
    amount,
    date,
    referenceNumber,
    comment,
    ordersToCharge,
    createOrEdit,
    paymentUuid,
    glTerminalUuid,
    creditTypeUuid,
    paymentRail,
  ]);

  const handleClose = async () => {
    if (hasEditedSinceLastSave) {
      setShowUnsavedChangesDialog(true);
      return;
    }
    // update cached invoices
    await fetchInvoiceTotalsByUuids(
      filterNotNil(
        Object.values(ordersToCharge).map((order) => order.invoiceUuid),
      ),
    );
    setGroupOrdersBy(GroupOrdersForPaymentsBy.Invoice);
    setSort(null);
    onClose();
  };

  return (
    <>
      <Modal open onClose={handleClose}>
        <Box
          sx={[
            styles.modal,
            {
              width: '90vw',
              height: 'fit-content',
              p: 0,
            },
          ]}
        >
          <Box sx={[{ p: 3 }]}>
            <Snackbar
              autoHideDuration={null}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={() => setErrorMessage('')}
              open={errorMessage.length > 0}
            >
              <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
            <Snackbar
              autoHideDuration={10000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={() => setSuccessMessage('')}
              open={successMessage.length > 0}
            >
              <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              sx={{ px: 2 }}
            >
              <Typography variant="h5">
                Apply {paymentType.toLowerCase()} to orders
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                textAlign: 'center',
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      size="small"
                      label={`${capitalCase(paymentType)} amount ($)`}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      onBlur={() => {
                        const parsedFloat = convertNumberStringToFloat(amount);
                        if (!isNil(parsedFloat)) {
                          setAmount(Math.abs(parsedFloat).toFixed(2));
                        }
                      }}
                      type="number"
                      autoFocus={createOrEdit === CreateOrEdit.Edit}
                      required
                    />
                    <GeneralDatePicker
                      date={date}
                      setDate={setDate}
                      text="Date"
                    />
                    {paymentType === PaymentType.Payment && (
                      <Stack direction="row" alignItems="center" gap={2}>
                        <Typography>Method:</Typography>
                        <RadioGroup
                          value={paymentRail}
                          onChange={(e) => {
                            setPaymentRail(e.target.value as PaymentRail);
                          }}
                        >
                          <Stack direction="row">
                            {Object.values(PaymentRail).map((method) => (
                              <FormControlLabel
                                key={method}
                                checked={paymentRail === method}
                                value={method}
                                control={<Radio />}
                                label={formatPaymentRail(method)}
                              />
                            ))}
                          </Stack>
                        </RadioGroup>
                      </Stack>
                    )}
                    <TextField
                      size="small"
                      label="Reference number"
                      value={referenceNumber}
                      onChange={(e) => setReferenceNumber(e.target.value)}
                    />
                    <TextField
                      size="small"
                      label="Comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    {(paymentType === PaymentType.Credit ||
                      paymentType === PaymentType.Debit) && (
                      <>
                        <AutocompleteFuzzy
                          sx={{ width: '150px' }}
                          onChange={(_, newOption) => {
                            setGlTerminalUuid(newOption?.value ?? null);
                          }}
                          defaultValue={
                            !isNil(glTerminalUuid)
                              ? {
                                  value: glTerminalUuid,
                                  label: getTerminalName(glTerminalUuid),
                                }
                              : null
                          }
                          value={
                            !isNil(glTerminalUuid)
                              ? {
                                  value: glTerminalUuid,
                                  label: getTerminalName(glTerminalUuid),
                                }
                              : null
                          }
                          options={terminals.map((terminal) => ({
                            value: terminal.uuid,
                            label: terminal.name,
                          }))}
                          matchSortOptions={{ keys: ['label'] }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="GL Terminal"
                              size="small"
                            />
                          )}
                        />
                        <AutocompleteFuzzy
                          sx={{ width: '150px' }}
                          onChange={(_, newOption) => {
                            setCreditTypeUuid(newOption?.value ?? null);
                          }}
                          defaultValue={
                            !isNil(creditTypeUuid)
                              ? {
                                  value: creditTypeUuid,
                                  label:
                                    creditTypeOptions.find(
                                      (creditType) =>
                                        creditType.value === creditTypeUuid,
                                    )?.label ?? '',
                                }
                              : null
                          }
                          value={
                            !isNil(creditTypeUuid)
                              ? {
                                  value: creditTypeUuid,
                                  label:
                                    creditTypeOptions.find(
                                      (creditType) =>
                                        creditType.value === creditTypeUuid,
                                    )?.label ?? '',
                                }
                              : null
                          }
                          options={creditTypeOptions}
                          matchSortOptions={{ keys: ['label'] }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Credit type"
                              size="small"
                            />
                          )}
                        />
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Stack
                justifyContent="space-between"
                direction="row"
                alignItems="center"
              >
                <Stack direction="row" alignItems="center">
                  <TextField
                    label="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ pr: 2 }}
                  />
                  <InvoiceFilterButton
                    options={allInvoicesForPaymentApplicationOptions}
                    selectedOption={invoiceFilter}
                    setSelectedOption={setInvoiceFilter}
                  />
                  <Box whiteSpace="nowrap" pl={2}>
                    <DateDropdownPicker
                      filterTitle="Invoice date"
                      dateOption={dateOption}
                      setDateOption={setDateOption}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography variant="subtitle2" fontWeight={400} pr={1}>
                    View by
                  </Typography>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    exclusive
                    value={groupOrdersBy}
                    aria-label="group-orders-for-payment-application-by"
                    onChange={(e, value) => {
                      if (value === GroupOrdersForPaymentsBy.Order) {
                        setSort(defaultSort);
                      } else {
                        setSort(null);
                      }
                      if (!isNil(value)) {
                        setGroupOrdersBy(value);
                      }
                    }}
                  >
                    <ToggleButton
                      value={GroupOrdersForPaymentsBy.Invoice}
                      disabled={
                        groupOrdersBy === GroupOrdersForPaymentsBy.Invoice
                      }
                    >
                      <FilterNoneIcon
                        fontSize="small"
                        sx={{ marginRight: 1 }}
                      />
                      Invoice
                    </ToggleButton>
                    <ToggleButton
                      value={GroupOrdersForPaymentsBy.Order}
                      disabled={
                        groupOrdersBy === GroupOrdersForPaymentsBy.Order
                      }
                    >
                      <FormatListBulletedIcon
                        fontSize="small"
                        sx={{ marginRight: 1 }}
                      />
                      Order
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <TablePagination
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    labelRowsPerPage="Show"
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage: number) => {
                      refreshCurrentPage();
                      if (newPage > page) {
                        setRefreshCurrentPage(() => next);
                        next();
                      } else {
                        setRefreshCurrentPage(() => prev);
                        prev();
                      }
                      setPage(newPage);
                    }}
                    backIconButtonProps={{
                      disabled: loading === true || page === 0,
                    }}
                    nextIconButtonProps={{
                      disabled:
                        loading === true ||
                        totalCount === 0 ||
                        page + 1 === Math.ceil(totalCount / rowsPerPage),
                    }}
                    onRowsPerPageChange={(e) => {
                      handleRowsPerPageChange(+e.target.value);
                    }}
                  />
                  {(createLoading === true ||
                    ordersToChargesLoading === true ||
                    paymentByUuidLoading === true ||
                    loading === true) && <CircularProgress size={15} />}
                </Stack>
              </Stack>
              <TableContainer
                sx={{ overflowY: 'scroll', height: height - 400 }}
              >
                <Table
                  stickyHeader
                  aria-label="payment-modal-invoice-preview-table"
                  size="small"
                  sx={{
                    '& .MuiTableCell-sizeSmall': {
                      padding: '6px 7px',
                    },
                  }}
                >
                  <TableHead sx={{ backgroundColor: 'white  !important' }}>
                    <TableRow sx={{ backgroundColor: 'white  !important' }}>
                      <TableCell />
                      <TableCell>
                        {groupOrdersBy === GroupOrdersForPaymentsBy.Order
                          ? SingleColumnPaymentApplicationOrdersTableSortLabel({
                              label: hawbLabel,
                              sortBy:
                                OrdersForPaymentsSortField.ShipperBillOfLadingNumber,
                              currentSort: sort,
                              setSort,
                            })
                          : hawbLabel}
                      </TableCell>
                      <TableCell>Order name</TableCell>
                      <TableCell>
                        {segment === Segment.Cartage
                          ? 'Ref number'
                          : 'Secondary ref number'}
                      </TableCell>
                      {segment === Segment.Cartage && (
                        <TableCell>MAWB</TableCell>
                      )}
                      {groupOrdersBy === GroupOrdersForPaymentsBy.Order && (
                        <TableCell>Inv name</TableCell>
                      )}
                      {groupOrdersBy === GroupOrdersForPaymentsBy.Order && (
                        <TableCell align="right">Inv total</TableCell>
                      )}
                      {groupOrdersBy === GroupOrdersForPaymentsBy.Order && (
                        <TableCell align="right">Inv balance</TableCell>
                      )}
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right">Balance</TableCell>
                      <TableCell sx={{ width: 160 }} align="right">
                        Amount to apply
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* if in edit mode, wait for payment data to load */}
                    {(createOrEdit !== CreateOrEdit.Edit ||
                      paymentByUuidLoading !== true ||
                      companyDataLoading) &&
                      Object.entries(groupedOrders).map(
                        ([groupUuid, orders]) => {
                          const invoice = orders[0]?.invoice;
                          const invoiceUuid =
                            groupOrdersBy === GroupOrdersForPaymentsBy.Invoice
                              ? groupUuid
                              : orders[0]?.invoice?.uuid;
                          if (
                            !isNil(invoice) &&
                            !isNilOrEmptyString(invoiceUuid)
                          ) {
                            const invoiceOrderCount = !isNil(invoiceUuid)
                              ? totalCountsByInvoice.get(invoiceUuid)
                              : 0;
                            const paidOrdersOnInvoiceCount = !isNil(invoiceUuid)
                              ? paidOrdersOnInvoiceCounts.get(invoiceUuid)
                              : 0;

                            const invoiceTotal = !isNil(invoiceUuid)
                              ? invoiceTotals[invoiceUuid]
                              : '-';
                            const invoiceBalance = !isNil(invoiceUuid)
                              ? currency(invoiceBalances[invoiceUuid] ?? 0, {
                                  fromCents: true,
                                }).format()
                              : '-';

                            const invoiceName =
                              companyConfiguration?.useJournalNumberForInvoice ===
                              true
                                ? invoice?.journalNumber
                                : invoice?.name;

                            return (
                              <>
                                {groupOrdersBy ===
                                  GroupOrdersForPaymentsBy.Invoice && (
                                  <TableRow
                                    sx={{
                                      '& .MuiTableCell-root': {
                                        borderBottomWidth: '2px',
                                      },
                                      backgroundColor: theme.palette.grey[200],
                                    }}
                                  >
                                    <TableCell>
                                      <Checkbox
                                        checked={
                                          isNil(invoiceOrderCount)
                                            ? false
                                            : paidOrdersOnInvoiceCount ===
                                              invoiceOrderCount
                                        }
                                        onChange={(e) => {
                                          if (!isNil(invoiceUuid)) {
                                            if (e.target.checked) {
                                              onPayInvoice(invoiceUuid);
                                            } else {
                                              onUnpayInvoice(invoiceUuid);
                                            }
                                          }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell colSpan={4}>
                                      <Stack
                                        alignItems="center"
                                        gap={1}
                                        direction="row"
                                        color={theme.palette.text.secondary}
                                      >
                                        <Link
                                          sx={{
                                            cursor: 'pointer',
                                            maxWidth: '100px',
                                          }}
                                          href={`/accounting/?invoiceUuid=${invoice?.uuid}&invoiceName=${invoice?.name}`}
                                          target="_blank"
                                          underline="hover"
                                        >
                                          <Stack
                                            alignItems="center"
                                            direction="row"
                                          >
                                            {invoiceName}
                                          </Stack>
                                        </Link>
                                        {' • '}
                                        {isNil(invoiceOrderCount) ? (
                                          <Box sx={{ width: '20px' }}>
                                            <LinearProgress color="info" />
                                          </Box>
                                        ) : (
                                          `${paidOrdersOnInvoiceCount ?? 0} of ${invoiceOrderCount} selected`
                                        )}
                                        {' • '}
                                        {!isNil(invoice?.date)
                                          ? dayjs(invoice?.date).format(
                                              'MM/DD/YY',
                                            )
                                          : '-'}
                                      </Stack>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: theme.palette.text.secondary,
                                      }}
                                      align="right"
                                    >
                                      {invoiceTotal ?? '-'}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: theme.palette.text.secondary,
                                      }}
                                      align="right"
                                    >
                                      {invoiceBalance ?? '-'}
                                    </TableCell>
                                    <TableCell />
                                  </TableRow>
                                )}
                                {orders.map((order) => {
                                  return (
                                    <PaymentApplicationOrderRow
                                      key={order.uuid}
                                      segment={segment}
                                      paymentType={paymentType}
                                      setOrdersToCharge={setOrdersToCharge}
                                      order={order}
                                      orderToCharge={ordersToCharge[order.uuid]}
                                      totalAmount={order.totalCharge}
                                      appliedAmount={order.appliedAmount}
                                      unappliedAmount={unappliedAmount}
                                      previouslyAppliedAmount={
                                        lastSavedState.orderToCharge[order.uuid]
                                          ?.amount
                                      }
                                      invoiceTotal={invoiceTotal}
                                      invoiceBalance={invoiceBalance}
                                      invoiceName={invoiceName.toString()}
                                      groupOrdersBy={groupOrdersBy}
                                    />
                                  );
                                })}
                              </>
                            );
                          }
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          return <></>;
                        },
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            boxShadow="0px -1px 5px #888"
            px={3}
            py={1}
          >
            <Stack direction="row">
              <Typography fontSize={18}>
                <b>{currency(unappliedAmount).format()}</b> unapplied /{' '}
                {currency(appliedAmount).format()} applied to{' '}
                {orderUuids.length} {pluralize('order', orderUuids.length)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                disabled={!hasEditedSinceLastSave}
                onClick={() => {
                  setOrdersToCharge(lastSavedState.orderToCharge);
                  setAmount(lastSavedState.amount);
                  setDate(lastSavedState.date);
                  setReferenceNumber(lastSavedState.referenceNumber);
                  setPaymentRail(lastSavedState.paymentRail);
                  setComment(lastSavedState.comment);
                }}
                color="error"
              >
                Reset unsaved changes
              </Button>
              <Button
                variant="contained"
                onClick={onSavePay}
                disabled={
                  !hasEditedSinceLastSave ||
                  createLoading === true ||
                  updateLoading === true
                }
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Dialog
        open={showUnsavedChangesDialog}
        onClose={() => setShowUnsavedChangesDialog(false)}
      >
        <Stack direction="row-reverse">
          <IconButton onClick={() => setShowUnsavedChangesDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{ p: 4 }}>
          You have unsaved applications. Please save changes before closing.
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setShowUnsavedChangesDialog(false);
              onClose();
            }}
          >
            Discard changes
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowUnsavedChangesDialog(false)}
          >
            Return to applications
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={paymentLessThanBalanceMessage.length > 0}
        onClose={() => {
          setPaymentLessThanBalanceConfirmCallback(() => null);
          setPaymentLessThanBalanceMessage('');
        }}
      >
        <DialogContent>
          <DialogContentText>{paymentLessThanBalanceMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPaymentLessThanBalanceConfirmCallback(() => null);
              setPaymentLessThanBalanceMessage('');
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={() => {
              paymentLessThanBalanceConfirmCallback();
              setPaymentLessThanBalanceMessage('');
              setPaymentLessThanBalanceConfirmCallback(() => null);
            }}
            variant="outlined"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentApplicationModal;
