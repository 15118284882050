import { MoreHorizOutlined } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { calculateTotalFreightCharge } from 'shared/billing';
import { getPermissionsFlags } from 'shared/roles';
import { useAllowOpenInNewTab } from '../../../../../common/react-hooks/use-allow-open-in-new-tab';
import useMe from '../../../../../common/react-hooks/use-me';
import useOrderSnapshot from '../../../../../common/react-hooks/use-order-snapshot';
import useServices from '../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  isPartnerCarrierDropoff,
  isPartnerCarrierPickup,
  isPartnerCarrierStop,
} from '../../../../../common/utils/stops';
import {
  EdiAndApiOrdersForReviewDocument,
  InboundMethod,
  OutboundMethod,
  PackageType,
  PermissionResource,
  Segment,
  ShipmentType,
  StopType,
  TariffFragment,
  useContactsSimpleQuery,
  useTariffLazyQuery,
  useUpdateStandardOrderMutation,
  WeightUnits,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import theme from '../../../../../theme';
import { selectAddressById } from '../../../../addresses/redux/addresses-values-slice';
import { selectContactPersonById } from '../../../../contact-persons/redux/contact-persons-values-slice';
import { formatAppointment } from '../../../../customer-portal/utils';
import { selectFreightChargeById } from '../../../../freight-charges/redux/freight-charges-values-slice';
import { useTotalWeight } from '../../../../invoice-profile/hooks/custom-charge-hooks';
import AccordionSummary from '../../../../invoices/components/accordion-summary';
import { selectLegValuesById } from '../../../../legs/redux/leg-values-slice';
import { selectPackageById } from '../../../../packages/redux/package-values-slice';
import {
  selectStandardShipmentFirstLegUuid,
  selectStandardShipmentFreightChargeId,
  selectStandardShipmentsByIds,
  selectStandardShipmentValuesById,
  updateStandardShipmentValues,
} from '../../../../shipments/redux/standard-shipments-values-slice';
import { selectStopValuesById } from '../../../../stops/redux/stop-values-slice';
import {
  useQuantityAndKeepInSync,
  useTariffRate,
} from '../../../hooks/freight-charge-hooks';
import {
  selectPackageUuidsForOrder,
  selectStandardOrderValuesById,
  selectStandardOrderWeightUnits,
  updateOneStandardOrderValues,
} from '../../../redux/standard/standard-orders-values-slice';
import {
  createStandardOrderUpdateInput,
  initExistingStandardOrderValues,
} from '../../../redux/standard/standard-orders-values-thunks';
import OrderPage from '../../order-form/order-page';

export const getStopTypeLabel = ({
  inboundMethod,
  outboundMethod,
  stopType,
}: {
  inboundMethod: InboundMethod | null | undefined;
  outboundMethod: OutboundMethod | null | undefined;
  stopType: StopType | null | undefined;
}) => {
  if (isPartnerCarrierPickup({ stop: { outboundMethod, stopType } })) {
    return 'Carrier pickup';
  }
  if (isPartnerCarrierDropoff({ stop: { inboundMethod, stopType } })) {
    return 'Carrier dropoff';
  }
  return sentenceCase(stopType ?? '');
};

const MarkOrderAsOnHandComponent = ({
  onHand,
  orderUuid,
}: {
  onHand: boolean | undefined;
  orderUuid: string;
}) => {
  const dispatch = useAppDispatch();
  return (
    <Stack
      minWidth="80px"
      maxWidth="80px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography fontWeight={800}>On hand</Typography>
      <Checkbox
        checked={onHand}
        onChange={(e) => {
          dispatch(
            updateOneStandardOrderValues({
              id: orderUuid ?? '',
              changes: {
                onHand: e.target.checked,
                receivedDate: e.target.checked ? new Date() : undefined,
              },
            }),
          );
        }}
      />
    </Stack>
  );
};

const ServiceLevelComponent = ({
  orderUuid,
  serviceUuid,
  setOrderHasChanges,
  shipmentUuids,
}: {
  orderUuid: string;
  serviceUuid: string | undefined;
  setOrderHasChanges: (hasChanges: boolean) => void;
  shipmentUuids: string[];
}) => {
  const dispatch = useAppDispatch();
  const { services } = useServices();
  const serviceLevelOptions =
    services
      ?.map((service) => ({
        value: service.uuid,
        label: sentenceCase(service.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <Stack minWidth="250px" maxWidth="300px">
      <Typography fontWeight={800}>Service level</Typography>
      <AutocompleteFuzzy
        size="small"
        value={
          !isNil(serviceUuid)
            ? {
                value: serviceUuid,
                label:
                  serviceLevelOptions.find(
                    (service) => service.value === serviceUuid,
                  )?.label ?? '',
              }
            : null
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={serviceLevelOptions}
        matchSortOptions={{ keys: ['label'] }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} />}
        onChange={(_event, option) => {
          for (const shipmentUuid of shipmentUuids) {
            dispatch(
              updateStandardShipmentValues({
                id: shipmentUuid ?? '',
                changes: {
                  serviceUuid: option?.value ?? '',
                  // airportInfoUuid: e.target.value,
                },
              }),
            );
          }
          dispatch(
            updateOneStandardOrderValues({
              id: orderUuid ?? '',
              changes: {
                serviceUuid: option?.value ?? '',
              },
            }),
          );
          setOrderHasChanges(true);
        }}
      />
    </Stack>
  );
};

const AccordionInfoComponentSimple = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Stack maxWidth="200px">
      <Typography fontWeight={800}>{title}</Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};

export const DuplicateOrderTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    /* eslint-disable react/jsx-props-no-spreading */
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme: tooltipTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 400,
    fontSize: tooltipTheme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // paddingTop: '10px',
    // paddingBottom: '10px',
    gap: '5px',
  },
}));

const ShipmentAccordionInfo = ({
  idx,
  shipmentUuid,
  companyTimezone,
}: {
  idx: number;
  shipmentUuid: string;
  companyTimezone: string | undefined;
}) => {
  const [getTariffByUuid] = useTariffLazyQuery();

  const shipmentValues = useAppSelector((state) =>
    selectStandardShipmentValuesById(state, shipmentUuid ?? ''),
  );
  const firstLegUuid = useAppSelector((state) =>
    selectStandardShipmentFirstLegUuid(state, shipmentUuid ?? ''),
  );
  const firstLeg = useAppSelector((state) =>
    selectLegValuesById(state, firstLegUuid ?? ''),
  );
  const endStopUuid = firstLeg?.endStopUuid;
  const stopValues = useAppSelector((state) =>
    selectStopValuesById(state, endStopUuid ?? ''),
  );
  const addressValues = useAppSelector((state) =>
    selectAddressById(state, stopValues?.addressUuid ?? ''),
  );
  const stopContactPerson = useAppSelector((state) =>
    selectContactPersonById(state, stopValues?.contactPersonUuid ?? ''),
  );
  const serviceDate = stopValues?.serviceDate;
  const freightChargeId = useAppSelector((state) =>
    selectStandardShipmentFreightChargeId(state, shipmentUuid),
  );
  const freightChargeValues = useAppSelector((state) =>
    selectFreightChargeById(state, freightChargeId ?? ''),
  );
  const tariffUuid = freightChargeValues?.tariffUuid;

  const [tariff, setTariff] = useState<TariffFragment | undefined>(undefined);

  const fetchTariff = useCallback(async () => {
    if (!isNil(tariffUuid) && !isEmpty(tariffUuid)) {
      const res = await getTariffByUuid({
        variables: {
          uuid: tariffUuid,
        },
      });
      setTariff(res.data?.tariff);
    }
  }, [tariffUuid, getTariffByUuid, setTariff]);

  useEffect(() => {
    fetchTariff();
  }, [tariffUuid, fetchTariff]);

  const weight = useTotalWeight({
    shipmentUuids: !isNil(shipmentUuid) ? [shipmentUuid] : [],
    isFromQuotePage: false,
    useActualWeightInCalculation: tariff?.useActualWeightInCalculation ?? false,
  });

  const quantity = useQuantityAndKeepInSync({
    isConsolidatedOrder: false,
    shipmentUuid,
    freightBillingMethod: freightChargeValues?.billingMethod ?? null,
    tariff: tariff ?? undefined,
    freightChargeId: freightChargeId ?? '',
    isFromQuotePage: false,
    overridePackageWeight: false,
    miles: 0,
  });

  const tariffRate = useTariffRate({
    tariff,
    quantity,
    freightChargeId: freightChargeId ?? '',
    editingDisabled: false,
  });

  const totalFreightCharge = calculateTotalFreightCharge({
    billingMethod: freightChargeValues?.billingMethod,
    rate: freightChargeValues?.rate,
    quantity: freightChargeValues?.quantity,
    tariff,
    weight,
    miles: 0,
    pieceCount: 0,
  }).toFixed(2);

  const stopTypeLabel = getStopTypeLabel({
    inboundMethod: stopValues?.inboundMethod,
    outboundMethod: stopValues?.outboundMethod,
    stopType: stopValues?.stopType,
  });

  const isPcpOrPcd = isPartnerCarrierStop({
    stop: {
      stopType: stopValues?.stopType,
      outboundMethod: stopValues?.outboundMethod,
      inboundMethod: stopValues?.inboundMethod,
    },
  });

  return (
    <Stack gap="10px">
      <Typography variant="h6">
        {' '}
        Stop {idx + 1}: {stopTypeLabel}
      </Typography>
      <Stack direction="row" gap="50px" justifyContent="flex-start">
        <AccordionInfoComponentSimple
          title="Address"
          value={`${addressValues?.line1}, ${addressValues?.city}, ${addressValues?.state} ${addressValues?.zip}`}
        />
        {stopValues?.stopType === StopType.Pickup && (
          <AccordionInfoComponentSimple
            title="Outbound method"
            value={stopValues?.outboundMethod ?? 'None'}
          />
        )}
        {stopValues?.stopType === StopType.Delivery && (
          <AccordionInfoComponentSimple
            title="Inbound method"
            value={stopValues?.inboundMethod ?? 'None'}
          />
        )}
        <Stack>
          <Typography fontWeight={800}>Appointment</Typography>
          {formatAppointment({
            appointmentDate: shipmentValues?.deliveryDate,
            appointmentTime: stopValues?.appointmentTime ?? null,
            endAppointmentTime: stopValues?.endAppointmentTime ?? null,
            timeZone: companyTimezone ?? 'America/Los_Angeles',
          })}
        </Stack>
        <Stack>
          <Typography fontWeight={800}>Contact info</Typography>
          <Typography>
            {' '}
            {!isNil(stopContactPerson?.firstName)
              ? `${stopContactPerson?.firstName} `
              : ''}
            {!isNil(stopContactPerson?.lastName)
              ? stopContactPerson?.lastName
              : ''}
          </Typography>
          {!isNil(stopContactPerson?.phone) && (
            <Typography>{stopContactPerson?.phone}</Typography>
          )}
          {!isNil(stopContactPerson?.email) && (
            <Typography>{stopContactPerson?.email}</Typography>
          )}
        </Stack>
        <Stack>
          <Typography fontWeight={800}>Service date</Typography>
          <Typography>
            {!isNil(serviceDate)
              ? dayjs(serviceDate).format('MM/DD/YYYY')
              : '-'}
          </Typography>
        </Stack>
      </Stack>
      {!isPcpOrPcd && (
        <>
          <Typography fontSize="18px">Freight charges</Typography>
          <Stack direction="row" gap="50px" justifyContent="flex-start">
            <AccordionInfoComponentSimple
              title="Billing method"
              value={`${sentenceCase(
                freightChargeValues?.billingMethod ?? '-',
              )}`}
            />
            {!isNil(tariff) && (
              <AccordionInfoComponentSimple
                title="Zone"
                value={`${tariff.tariffZone.name}`}
              />
            )}

            <AccordionInfoComponentSimple
              title="Rate"
              value={
                !isNil(tariff)
                  ? `${tariffRate}`
                  : `${freightChargeValues?.rate}`
              }
            />
            <AccordionInfoComponentSimple
              title="Total"
              value={`$${totalFreightCharge}`}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

const LineHaulTerminalAccordionInfo = ({
  startTerminalUuid,
  endTerminalUuid,
}: {
  startTerminalUuid: string;
  endTerminalUuid: string;
}) => {
  const { getTerminal } = useTerminals({
    includeInactiveTerminals: false,
  });
  const startTerminal = getTerminal(startTerminalUuid);
  const startAddress = startTerminal?.address;
  const endTerminal = getTerminal(endTerminalUuid);
  const endAddress = endTerminal?.address;

  return (
    <Stack gap="10px">
      <Stack direction="column" gap="20px" justifyContent="flex-start">
        <Stack direction="row" gap="50px" justifyContent="flex-start">
          <AccordionInfoComponentSimple
            title="Start terminal"
            value={startTerminal?.name ?? ''}
          />
          <AccordionInfoComponentSimple
            title="Address"
            value={`${startAddress?.line1}, ${startAddress?.city}, ${startAddress?.state} ${startAddress?.zip}`}
          />
        </Stack>
        <Stack direction="row" gap="50px" justifyContent="flex-start">
          <AccordionInfoComponentSimple
            title="End terminal"
            value={endTerminal?.name ?? ''}
          />
          <AccordionInfoComponentSimple
            title="Address"
            value={`${endAddress?.line1}, ${endAddress?.city}, ${endAddress?.state} ${endAddress?.zip}`}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const PackageAccordionInfo = ({
  idx,
  packageUuid,
  weightUnits,
}: {
  idx: number;
  packageUuid: string;
  weightUnits: WeightUnits;
}) => {
  const packageValues = useAppSelector((state) =>
    selectPackageById(state, packageUuid),
  );
  return (
    <Stack direction="row" alignItems="center" gap="50px">
      <Typography width="400px">
        {' '}
        Package {idx + 1}: {packageValues?.description}
      </Typography>
      <Divider orientation="vertical" />
      <Stack direction="row" gap="50px" justifyContent="flex-start">
        <AccordionInfoComponentSimple
          title="Quantity"
          value={packageValues?.quantity?.toString() ?? '0'}
        />
        <AccordionInfoComponentSimple
          title="Type"
          value={sentenceCase(packageValues?.type ?? PackageType.Skid)}
        />
        <AccordionInfoComponentSimple
          title="Weight"
          value={`${packageValues?.weight} ${weightUnits}`}
        />
      </Stack>
    </Stack>
  );
};

const EdiSelectedOrderAccordion = ({
  orderUuid,
  orderHasChanges,
  setOrderHasChanges,
  updateOrderBeingEdited,
  openRejectOrderModalForUuid,
}: {
  orderUuid: string;
  orderHasChanges: boolean;
  setOrderHasChanges: (hasChanges: boolean) => void;
  updateOrderBeingEdited: (isEditing: boolean) => void;
  openRejectOrderModalForUuid: (orderUuid: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const [updateStandardOrder] = useUpdateStandardOrderMutation();
  const [showEditSuccess, setShowEditSuccess] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const orderValues = useAppSelector((state) =>
    selectStandardOrderValuesById(state, orderUuid),
  );
  const { companyTimezone, segment } = useMe();
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );

  const { data } = useContactsSimpleQuery({ fetchPolicy: 'cache-first' });
  const contacts = data?.contacts ?? [];
  const shipmentUuids = orderValues?.shipmentUuids ?? [];
  const shipmentValues = useAppSelector((state) =>
    selectStandardShipmentsByIds(state, shipmentUuids),
  );
  const regularShipmentUuids = shipmentValues
    .filter((s) => {
      return s.shipmentType === ShipmentType.Regular;
    })
    .map((s) => {
      return s.uuid;
    });
  const lineHaulLaneStartTerminalUuid =
    orderValues?.lineHaulLaneStartTerminalUuid;
  const lineHaulLaneEndTerminalUuid = orderValues?.lineHaulLaneEndTerminalUuid;
  const { saveSnapshot } = useOrderSnapshot();

  const packageUuids = useAppSelector((state) =>
    selectPackageUuidsForOrder(state, orderUuid ?? ''),
  );
  const orderWeightUnits = useAppSelector((state) =>
    selectStandardOrderWeightUnits(state, orderUuid ?? ''),
  );

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] =
    useState<null | HTMLElement>(null);

  const [isEditingOrder, setIsEditingOrder] = useState(false);

  useEffect(() => {
    updateOrderBeingEdited(isEditingOrder);
    // TODO: Look into making this exhaustive. Adding a dependency on updateOrderBeingEdited causes the component to continously re-render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingOrder]);

  const handleMoreOptionsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMoreOptionsAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleSaveChanges = async () => {
    if (orderHasChanges) {
      const standardOrderUpdateInput = await dispatch(
        createStandardOrderUpdateInput({ orderUuid }),
      ).unwrap();
      await updateStandardOrder({
        variables: {
          updateStandardOrderInput: {
            orderUpdateInput: {
              ...standardOrderUpdateInput,
            },
          },
        },
        refetchQueries: [EdiAndApiOrdersForReviewDocument],
      });

      // save a snapshot of this change
      await saveSnapshot(orderUuid);

      setIsEditingOrder(false);
      setOrderHasChanges(false);
      setShowEditSuccess(true);
    }
  };

  const handleDiscardChanges = async () => {
    if (orderHasChanges) {
      await dispatch(
        initExistingStandardOrderValues({
          orderUuid,
        }),
      );
    }
    setIsEditingOrder(false);
    setOrderHasChanges(false);
  };

  if (isNil(orderValues)) {
    return null;
  }
  const billOfLadingName = segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  return (
    <>
      <Accordion
        sx={{
          width: '100%',
          border: `1px solid ${theme.palette.primary.main}`,
          // border-bottom-color: transparent;
          borderRadius: '6px',
        }}
        defaultExpanded
        expanded={isExpanded}
        onChange={(_, expanded) => {
          setIsExpanded(expanded);
        }}
      >
        <AccordionSummary>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              justifyItems: 'flex-start',
              width: '100%',
            }}
          >
            <Stack direction="row" gap="15px" alignItems="center">
              <Typography fontWeight={700}>{orderValues?.name}</Typography>
              {!isNil(orderValues.ordersWithSameBillOfLadingNumber) &&
                orderValues.ordersWithSameBillOfLadingNumber.length > 0 && (
                  <DuplicateOrderTooltip
                    onClick={(e) => e.stopPropagation()}
                    // sx={{ backgroundColor: 'white' }}
                    placement="bottom-start"
                    title={
                      <Stack
                        sx={{
                          backgroundColor: 'white',
                          width: '100%',
                          padding: '15px',
                        }}
                      >
                        <Typography>
                          These orders have the same {billOfLadingName}:
                        </Typography>
                        {orderValues.ordersWithSameBillOfLadingNumber.map(
                          (order) => {
                            return (
                              <Button
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'left',
                                  textAlign: 'left',
                                }}
                                key={order.uuid}
                                onClick={(e) =>
                                  allowOpenInNewTab(
                                    e,
                                    `/orders/?orderUuid=${order.uuid}`,
                                  )
                                }
                              >
                                {order.name}
                              </Button>
                            );
                          },
                        )}{' '}
                      </Stack>
                    }
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        backgroundColor: '#f7b7b7',
                        padding: '5px',
                        border: 'solid #b34b4b',
                        borderWidth: '1.5px',
                        color: '#b34b4b',
                        borderRadius: '5px',
                      }}
                    >
                      <PriorityHighIcon
                        sx={{
                          fontSize: '16px',
                          color: '#b34b4b',
                        }}
                      />
                      {orderValues.ordersWithSameBillOfLadingNumber.length}{' '}
                      duplicate {billOfLadingName} found
                    </Stack>
                  </DuplicateOrderTooltip>
                )}
              {isEditingOrder && orderHasChanges && (
                <Tooltip title="This order has unsaved changes">
                  <CircleIcon
                    sx={{ fontSize: '20px', color: theme.palette.paleRed.main }}
                  />
                </Tooltip>
              )}
            </Stack>
            <Stack direction="row" gap="10px" alignItems="center">
              {orderValues.hasEdiCancellation && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <WarningIcon
                    sx={{
                      fontSize: '15px',
                      color: theme.palette.paleRed.main,
                      mr: '2px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: theme.palette.paleRed.main,
                    }}
                  >
                    This order was cancelled via EDI
                  </Typography>
                </Box>
              )}
              {!isEditingOrder && (
                <IconButton
                  aria-label="open-more-options-menu"
                  onClick={(e) => {
                    setIsEditingOrder(true);
                    setIsExpanded(true);
                    setMoreOptionsAnchorEl(null);
                    e.stopPropagation();
                  }}
                  color="primary"
                  sx={{
                    borderRadius: '10px',
                    gap: '5px',
                  }}
                  disabled={!canWriteEdiApiOrders}
                >
                  <EditIcon fontSize="small" />
                  <Typography> Edit </Typography>
                </IconButton>
              )}
              <IconButton
                aria-label="open-more-options-menu"
                onClick={(e) => {
                  openRejectOrderModalForUuid(orderUuid);
                  setMoreOptionsAnchorEl(null);
                  e.stopPropagation();
                }}
                color="error"
                sx={{
                  borderRadius: '10px',
                  gap: '5px',
                }}
                disabled={!canWriteEdiApiOrders}
              >
                <ClearIcon fontSize="small" />
                <Typography fontWeight={700}> Reject </Typography>
              </IconButton>

              {isEditingOrder && orderHasChanges && (
                <IconButton
                  aria-label="open-more-options-menu"
                  onClick={(e) => {
                    setMoreOptionsAnchorEl(null);
                    handleSaveChanges();
                    e.stopPropagation();
                  }}
                  color="primary"
                  sx={{
                    borderRadius: '10px',
                    gap: '5px',
                  }}
                  disabled={!canWriteEdiApiOrders}
                >
                  <SaveIcon fontSize="small" />
                  <Typography> Save </Typography>
                </IconButton>
              )}
              {isEditingOrder && (
                <IconButton
                  aria-label="open-more-options-menu"
                  onClick={handleMoreOptionsClick}
                  color="primary"
                  sx={{
                    borderRadius: '10px',
                  }}
                >
                  <MoreHorizOutlined />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {!isEditingOrder && (
            <Stack gap="10px">
              <Stack gap="10px">
                <Typography variant="h5"> Order details </Typography>
                <Stack direction="row" gap="50px" justifyContent="flex-start">
                  <AccordionInfoComponentSimple
                    title="Customer"
                    value={
                      contacts?.find(
                        (contact) =>
                          contact.uuid === orderValues.billingPartyContactUuid,
                      )?.displayName ?? '-'
                    }
                  />
                  <AccordionInfoComponentSimple
                    title={billOfLadingName}
                    value={orderValues.shipperBillOfLadingNumber ?? '-'}
                  />
                  <AccordionInfoComponentSimple
                    title="Reference #"
                    value={orderValues.secondaryRefNumber ?? '-'}
                  />
                  <AccordionInfoComponentSimple
                    title="Payment method"
                    value={
                      !isNil(orderValues.paymentMethod)
                        ? sentenceCase(orderValues.paymentMethod)
                        : '-'
                    }
                  />
                  <ServiceLevelComponent
                    orderUuid={orderUuid}
                    setOrderHasChanges={setOrderHasChanges}
                    serviceUuid={orderValues?.serviceUuid}
                    shipmentUuids={shipmentUuids}
                  />
                  <MarkOrderAsOnHandComponent
                    orderUuid={orderUuid}
                    onHand={orderValues?.onHand}
                  />
                </Stack>
              </Stack>
              {regularShipmentUuids.length > 0 && (
                <>
                  <Divider />
                  <Stack gap="10px">
                    <Typography variant="h5"> Stops </Typography>
                    <Stack gap="20px">
                      {regularShipmentUuids.map((uuid, idx) => (
                        <ShipmentAccordionInfo
                          idx={idx}
                          key={uuid}
                          shipmentUuid={uuid}
                          companyTimezone={companyTimezone}
                        />
                      ))}
                    </Stack>
                  </Stack>
                </>
              )}
              {!isNil(lineHaulLaneStartTerminalUuid) &&
                !isNil(lineHaulLaneEndTerminalUuid) && (
                  <>
                    <Divider />
                    <Stack gap="10px">
                      <Typography variant="h5">Line haul</Typography>
                      <Stack gap="20px">
                        <LineHaulTerminalAccordionInfo
                          startTerminalUuid={lineHaulLaneStartTerminalUuid}
                          endTerminalUuid={lineHaulLaneEndTerminalUuid}
                        />
                      </Stack>
                    </Stack>
                  </>
                )}
              {!isNil(packageUuids) && packageUuids.length > 0 && (
                <>
                  <Divider />
                  <Stack gap="10px">
                    <Typography variant="h5"> Packages </Typography>
                    {packageUuids?.map((uuid, idx) => (
                      <>
                        <PackageAccordionInfo
                          idx={idx}
                          packageUuid={uuid}
                          weightUnits={orderWeightUnits ?? WeightUnits.Pounds}
                        />
                        {idx < shipmentUuids.length - 1 && <Divider />}
                      </>
                    ))}
                  </Stack>
                </>
              )}
            </Stack>
          )}
          {isEditingOrder && (
            <OrderPage
              modalMode
              orderUuid={orderUuid}
              modalOnClose={async () => {
                // Need to show the updated data in the accordion
                await dispatch(
                  initExistingStandardOrderValues({
                    orderUuid,
                  }),
                );
                setIsEditingOrder(false);
              }}
              fromEdiReview
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Menu
        id="more-options-menu"
        anchorEl={moreOptionsAnchorEl}
        open={Boolean(moreOptionsAnchorEl)}
        onClose={() => {
          setMoreOptionsAnchorEl(null);
        }}
        sx={{ top: '10px' }}
      >
        {isEditingOrder && orderHasChanges && (
          <MenuItem
            onClick={() => {
              handleDiscardChanges();
              setMoreOptionsAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText> Discard changes </ListItemText>
          </MenuItem>
        )}
        {isEditingOrder && !orderHasChanges && (
          <MenuItem
            onClick={() => {
              handleDiscardChanges();
              setMoreOptionsAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <UndoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText> Back to summary </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowEditSuccess(false)}
        open={showEditSuccess}
      >
        <Alert severity="success"> Edits to {orderValues.name} saved </Alert>
      </Snackbar>
    </>
  );
};

export default EdiSelectedOrderAccordion;
