import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  SameDayDispatchConfigDocument,
  SameDayDispatchConfigFragment,
  useUpdateSameDayDispatchConfigMutation,
} from '../../../generated/graphql';
import SettingsColorPicker from './settings-color-picker';

const useStyles = () => {
  return {
    colorPickerRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '55%',
    },
  };
};

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-6px, 0, 0);
  }
  
  40%, 60% {
    transform: translate3d(6px, 0, 0);
  }
`;

const ShakingDialogForError = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'hasError',
})<{
  hasError: boolean;
}>(({ hasError }) => ({
  ...(hasError && {
    '& .MuiDialog-container': {
      animation: `${shakeAnimation} 0.5s linear`,
    },
  }),
}));

const DailyControlCenterSettingsModal = ({
  currentSettings,
  open,
  setOpen,
}: {
  currentSettings: SameDayDispatchConfigFragment | null | undefined;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  const [updateSameDayDispatchConfigMutation] =
    useUpdateSameDayDispatchConfigMutation({
      refetchQueries: [SameDayDispatchConfigDocument],
    });
  const [config, setConfig] = useState<SameDayDispatchConfigFragment>({
    pickupAppointmentTimeThreshold: 0,
    deliveryAppointmentTimeThreshold: 0,
    isPastDeadlineColorCode: '',
    isNextInRouteColorCode: '',
    isSpecialColorCode: '',
    pickupWithinTimeWindowColorCode: '',
    deliveryWithinTimeWindowColorCode: '',
  });
  const [hasError, setHasError] = useState(false);

  const styles = useStyles();
  const closeModalAndSave = () => {
    setHasError(false);
    if (
      config.pickupAppointmentTimeThreshold > 0 &&
      config.deliveryAppointmentTimeThreshold > 0
    ) {
      updateSameDayDispatchConfigMutation({
        variables: {
          updateSameDayDispatchConfigInput: {
            isNextInRouteColorCode: !isEmpty(config.isNextInRouteColorCode)
              ? config.isNextInRouteColorCode
              : undefined,
            isPastDeadlineColorCode: !isEmpty(config.isPastDeadlineColorCode)
              ? config.isPastDeadlineColorCode
              : undefined,
            isSpecialColorCode: !isEmpty(config.isSpecialColorCode)
              ? config.isSpecialColorCode
              : undefined,
            pickupAppointmentTimeThreshold:
              config.pickupAppointmentTimeThreshold > 0
                ? config.pickupAppointmentTimeThreshold
                : undefined,
            deliveryAppointmentTimeThreshold:
              config.deliveryAppointmentTimeThreshold > 0
                ? config.deliveryAppointmentTimeThreshold
                : undefined,
            pickupWithinTimeWindowColorCode: !isEmpty(
              config.pickupWithinTimeWindowColorCode,
            )
              ? config.pickupWithinTimeWindowColorCode
              : undefined,
            deliveryWithinTimeWindowColorCode: !isEmpty(
              config.deliveryWithinTimeWindowColorCode,
            )
              ? config.deliveryWithinTimeWindowColorCode
              : undefined,
          },
        },
      });
      setOpen(false);
    } else {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (!isNil(currentSettings)) {
      setConfig(currentSettings);
    }
  }, [currentSettings]);

  return (
    <ShakingDialogForError
      hasError={hasError}
      sx={{ overflowY: 'visible' }}
      open={open}
      onClose={closeModalAndSave}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Color coding settings</Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          minWidth: '50vw',
          minHeight: '20vh',
        }}
      >
        <Box sx={styles.colorPickerRow}>
          <Typography>Appointment window has passed: </Typography>
          <SettingsColorPicker
            currentColor={config.isPastDeadlineColorCode}
            setColor={(colorHex: string) => {
              setConfig({
                ...config,
                isPastDeadlineColorCode: colorHex,
              });
            }}
          />
        </Box>
        <Box sx={styles.colorPickerRow}>
          <Typography>Is special: </Typography>
          <SettingsColorPicker
            currentColor={config.isSpecialColorCode}
            setColor={(colorHex: string) => {
              setConfig({
                ...config,
                isSpecialColorCode: colorHex,
              });
            }}
          />
        </Box>
        <Box sx={styles.colorPickerRow}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Is within </Typography>
            <TextField
              type="number"
              size="small"
              sx={{ maxWidth: '50px' }}
              inputProps={{
                style: { textAlign: 'center', paddingLeft: 1, paddingRight: 1 },
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              error={config.pickupAppointmentTimeThreshold <= 0}
              value={
                config.pickupAppointmentTimeThreshold > 0
                  ? config.pickupAppointmentTimeThreshold.toString()
                  : ''
              }
              onChange={(e) => {
                setConfig({
                  ...config,
                  pickupAppointmentTimeThreshold: +e.target.value,
                });
              }}
            />
            <Typography> minutes of pickup appointment end </Typography>
          </Stack>
          <SettingsColorPicker
            currentColor={config.pickupWithinTimeWindowColorCode}
            setColor={(colorHex: string) => {
              setConfig({
                ...config,
                pickupWithinTimeWindowColorCode: colorHex,
              });
            }}
          />
        </Box>
        <Box sx={styles.colorPickerRow}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Is within </Typography>
            <TextField
              type="number"
              size="small"
              sx={{ maxWidth: '50px' }}
              inputProps={{
                style: { textAlign: 'center', paddingLeft: 1, paddingRight: 1 },
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              error={config.deliveryAppointmentTimeThreshold <= 0}
              value={
                config.deliveryAppointmentTimeThreshold > 0
                  ? config.deliveryAppointmentTimeThreshold.toString()
                  : ''
              }
              onChange={(e) => {
                setConfig({
                  ...config,
                  deliveryAppointmentTimeThreshold: +e.target.value,
                });
              }}
            />
            <Typography> minutes of delivery appointment end </Typography>
          </Stack>
          <SettingsColorPicker
            currentColor={config.deliveryWithinTimeWindowColorCode}
            setColor={(colorHex: string) => {
              setConfig({
                ...config,
                deliveryWithinTimeWindowColorCode: colorHex,
              });
            }}
          />
        </Box>
      </DialogContent>
    </ShakingDialogForError>
  );
};

export default React.memo(DailyControlCenterSettingsModal);
