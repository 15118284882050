import { isNil } from 'lodash';
import {
  RecoveryTerminalFragment,
  useRecoveryTerminalsQuery,
} from '../../generated/graphql';

const useRecoveryLocationInfos = () => {
  const { data: recoveryLocationInfosData } = useRecoveryTerminalsQuery({
    fetchPolicy: 'cache-first',
  });

  const recoveryLocationInfos: RecoveryTerminalFragment[] =
    recoveryLocationInfosData?.airportInfos ?? [];

  const getRecoveryLocationInfo = (
    uuid: string | null | undefined,
  ): RecoveryTerminalFragment | null | undefined => {
    if (isNil(uuid)) {
      return null;
    }
    return recoveryLocationInfos.find((location) => location.uuid === uuid);
  };

  const getRecoveryLocationIataCode = (
    uuid: string | null | undefined,
  ): string => {
    if (isNil(uuid)) {
      return '';
    }
    return (
      recoveryLocationInfos.find((location) => location.uuid === uuid)
        ?.iataCode ?? ''
    );
  };

  const getRecoveryLocationTerminal = (
    uuid: string | null | undefined,
  ): string => {
    if (isNil(uuid)) {
      return '';
    }
    return (
      recoveryLocationInfos.find((location) => location.uuid === uuid)
        ?.terminal ?? ''
    );
  };

  return {
    getRecoveryLocationInfo,
    getRecoveryLocationIataCode,
    getRecoveryLocationTerminal,
    recoveryLocationInfos,
  };
};

export default useRecoveryLocationInfos;
