import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line no-restricted-imports
import { Grid, Box, IconButton, Modal, Stack } from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import useStyles from '../../../../common/components/general-styles';
import {
  ReportSendJobBatchFragment,
  ReportSendJobStatus,
} from '../../../../generated/graphql';
import ReportSendJobBatchProgress from './report-send-job-batch-progress';
import ReportSendJobList from './report-send-job-list';

const ReportSendJobsModal = ({
  selectedReportSendJobBatch,
  setSelectedReportSendJobBatch,
  initialStatus,
}: {
  selectedReportSendJobBatch: ReportSendJobBatchFragment | undefined;
  setSelectedReportSendJobBatch: Dispatch<
    SetStateAction<ReportSendJobBatchFragment | undefined>
  >;
  initialStatus?: ReportSendJobStatus | undefined;
}) => {
  const styles = useStyles();
  const onClose = () => {
    setSelectedReportSendJobBatch(undefined);
  };

  return (
    <Modal
      open={!isNil(selectedReportSendJobBatch)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={[styles.modal, { width: '95vw', height: '95vh' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            {!isNil(selectedReportSendJobBatch) && (
              <ReportSendJobBatchProgress
                progress={selectedReportSendJobBatch.reportSendJobBatchProgress}
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="right"
            >
              <IconButton onClick={onClose} sx={{ float: 'right' }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {!isNil(selectedReportSendJobBatch) && (
              <ReportSendJobList
                selectedReportSendJobBatchId={selectedReportSendJobBatch.id}
                initialStatus={initialStatus}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ReportSendJobsModal;
