import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ErrorBanner from '../../../../../common/components/error-banner';
import GeneralLedgerCodeAutocomplete from '../../../../../common/components/general-ledger-code-autocomplete';
import {
  CreditTypeFragment,
  CreditTypesDocument,
  useCreateCreditTypeMutation,
  useUpdateCreditTypeMutation,
} from '../../../../../generated/graphql';
import SettingsColorPicker from '../../../../daily-control-center/components/settings-color-picker';
import CreateOrEdit from '../../../enums/create-or-edit';

interface AddCreditTypeFormData {
  name: string;
  generalLedgerCodeId: string | null;
  color: string;
}

const AddOrEditCreditTypeModal = ({
  open,
  setOpen,
  createOrEdit,
  creditType,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  createOrEdit: CreateOrEdit;
  creditType: CreditTypeFragment | undefined;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm<AddCreditTypeFormData>({
    defaultValues: {
      name: '',
      color: 'black',
      generalLedgerCodeId: null,
    },
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isNil(creditType) && createOrEdit === CreateOrEdit.Edit && open) {
      setValue('color', creditType?.color ?? 'black');
      setValue('name', creditType.name);
      setValue('generalLedgerCodeId', creditType.generalLedgerCode?.id ?? null);
    }
  }, [createOrEdit, creditType, open, setValue]);

  const handleClose = () => {
    resetForm();
    setOpen(false);
    setErrorMessage('');
  };

  const [createCreditType] = useCreateCreditTypeMutation({
    refetchQueries: [CreditTypesDocument],
    onError: ({ message }) => setErrorMessage(message),
    onCompleted: () => {
      handleClose();
    },
  });

  const [updateCreditType] = useUpdateCreditTypeMutation({
    refetchQueries: [CreditTypesDocument],
    onError: ({ message }) => setErrorMessage(message),
    onCompleted: () => {
      handleClose();
    },
  });

  const validateNotNilOrEmpty = (value: string | null) => {
    return (
      !isNil(value) && (value.trim() !== '' || 'This field cannot be empty')
    );
  };

  const onSubmit: SubmitHandler<AddCreditTypeFormData> = async (
    data: AddCreditTypeFormData,
  ) => {
    if (createOrEdit === CreateOrEdit.Create) {
      await createCreditType({
        variables: {
          creditTypeCreateInput: {
            name: data.name,
            color: data.color,
            generalLedgerCodeId: data.generalLedgerCodeId,
          },
        },
      });
    } else if (!isNil(creditType)) {
      await updateCreditType({
        variables: {
          creditTypeUpdateInput: {
            uuid: creditType.uuid,
            name: data.name,
            color: data.color,
            generalLedgerCodeId: data.generalLedgerCodeId,
          },
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {createOrEdit === CreateOrEdit.Create
          ? 'Add Credit Type'
          : 'Edit Credit Type'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Controller<AddCreditTypeFormData>
            name="name"
            control={control}
            rules={{
              required: 'Name is required',
              validate: validateNotNilOrEmpty,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!errors.name}
                helperText={errors.name?.message}
                required
              />
            )}
          />
          <Controller<AddCreditTypeFormData>
            name="color"
            control={control}
            rules={{
              required: 'Color is required',
              validate: validateNotNilOrEmpty,
            }}
            render={({ field }) => (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ my: 4, px: 1 }}
              >
                <Typography>Color:</Typography>
                <SettingsColorPicker
                  currentColor={field.value}
                  setColor={(color: string) => setValue('color', color)}
                  onChangeComplete={(color: string) => field.onChange(color)}
                />
              </Stack>
            )}
          />
          <Controller<AddCreditTypeFormData>
            name="generalLedgerCodeId"
            control={control}
            render={({ field }) => (
              <GeneralLedgerCodeAutocomplete
                value={field.value}
                formError={errors.generalLedgerCodeId?.message ?? null}
                setValue={(newVal: string | null) => {
                  setValue('generalLedgerCodeId', newVal);
                }}
                disabled={false}
                size="medium"
              />
            )}
          />
          {!isEmpty(errorMessage) && (
            <ErrorBanner errorMessage={errorMessage} />
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default AddOrEditCreditTypeModal;
