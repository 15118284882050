import * as yup from 'yup';
import { BillingMethod } from '../../../../../common/types';
import {
  CustomChargeBillingMethod,
  AccessorialType,
} from '../../../../../generated/graphql';

export const customChargeSchema = yup.object({
  billingMethod: yup
    .mixed<CustomChargeBillingMethod>()
    .oneOf(Object.values(CustomChargeBillingMethod))
    .required(),
  // This can represent currency or a count. When currency, this represents a
  // dollar amount
  quantity: yup.number().required().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  rate: yup.number().required().nullable(),
  // This is a percentage out of 100
  fuelSurchargePercentageRate: yup.number().required().nullable(),
  totalCharge: yup.number().required(),
  accessorialUuid: yup.string().required().nullable(),
  accessorialName: yup.string().required().nullable(),
  accessorialType: yup
    .mixed<AccessorialType>()
    .oneOf(Object.values(AccessorialType))
    .optional()
    .nullable(),
  isAutoApplied: yup.boolean().required(),
  uuid: yup.string().required(),
  specialAccessorialMatrixItemUuid: yup.string().required().nullable(),
  zoneBasedAccessorialMatrixItemUuid: yup.string().required().nullable(),
  zoneUuid: yup.string().required().nullable(),
  chargeGroupUuid: yup.string().required().nullable(),
  accessorialRangeUuid: yup.string().required().nullable(),

  // Set when charges are finalized, cleared when unfinalized. Prefer this value
  postedFuelSurchargeRate: yup.number().required().nullable(),
  isLocal: yup.boolean().defined(),
  description: yup.string().nullable(),
  authoCode: yup.string().nullable(),
  name: yup.string().nullable(),
  settlementPercentageRate: yup.number().required().nullable(),
  settlementFlatRate: yup.number().required().nullable(),
  settlementBillingMethod: yup
    .mixed<BillingMethod>()
    .oneOf(Object.values(BillingMethod))
    .required()
    .nullable(),
  deductionTotal: yup.number().required(),
  // Not persisted. Used to track whether the rate should be taken from the Accessorial object rather than the form.
  useAccessorialRate: yup.boolean().defined(),
});
