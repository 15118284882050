import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { exhaustive } from 'shared/switch';
import {
  DayOfWeekAll,
  RecurringOrderFrequencyEntity,
  RepeatFrequencyUnit,
} from '../../../generated/graphql';
import { getDayOfWeekAllAbbreviation } from './recurring-order-frequency-selection';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const getTemplateDoesNotRecur = (
  frequency: RecurringOrderFrequencyEntity | undefined,
): boolean => {
  if (!isNil(frequency)) {
    const { repeatFrequencyUnit, daysOfWeek, daysOfMonth } = frequency;
    switch (repeatFrequencyUnit) {
      case RepeatFrequencyUnit.Week:
        return daysOfWeek.length === 0;
      case RepeatFrequencyUnit.Month:
        return daysOfMonth.length === 0;
      default:
        exhaustive(repeatFrequencyUnit);
    }
  }
  return true;
};

export const getFrequencyInformation = (
  frequency: RecurringOrderFrequencyEntity | undefined,
): string => {
  if (getTemplateDoesNotRecur(frequency)) {
    return 'Does not repeat';
  }

  if (
    !isNil(frequency) &&
    (!isNil(frequency.daysOfWeek) || !isNil(frequency.daysOfMonth)) &&
    !isNil(frequency.startDate) &&
    !isNil(frequency.repeatFrequencyUnit) &&
    (!isNil(frequency.repeatInterval) || !isNil(frequency.repeatIntervalWeeks))
  ) {
    const repeatInterval =
      frequency.repeatInterval ?? frequency.repeatIntervalWeeks;
    let dateRange;
    if (!isNil(frequency.endDate)) {
      dateRange = `from ${dayjs(frequency.startDate).format(
        'MM/DD/YYYY',
      )} to ${dayjs(frequency.endDate).format('MM/DD/YYYY')}`;
    } else {
      dateRange = `starting ${dayjs(frequency.startDate).format('MM/DD/YYYY')}`;
    }

    let repeatEvery;
    let repeatOn;
    switch (frequency.repeatFrequencyUnit) {
      case RepeatFrequencyUnit.Week:
        repeatEvery = `${repeatInterval} ${pluralize('week', repeatInterval)}`;
        repeatOn = Object.keys(DayOfWeekAll)
          .filter((dow) =>
            frequency.daysOfWeek.includes(
              DayOfWeekAll[dow as keyof typeof DayOfWeekAll],
            ),
          )
          .map((dow) => getDayOfWeekAllAbbreviation(dow))
          .join('/');
        break;
      case RepeatFrequencyUnit.Month:
        repeatEvery = `${repeatInterval} ${pluralize('month', repeatInterval)}`;
        repeatOn = `days ${frequency.daysOfMonth
          .map((day) => day.toString())
          .join('/')}`;
        break;
      default:
        exhaustive(frequency.repeatFrequencyUnit);
    }

    return `Repeat every ${repeatEvery} on ${repeatOn} ${dateRange}`;
  }
  return '';
};

export const getTemplateStatus = (
  frequency: RecurringOrderFrequencyEntity | undefined,
) => {
  if (getTemplateDoesNotRecur(frequency)) {
    return null;
  }
  if (!isNil(frequency)) {
    const { startDate, endDate }: { startDate: Date; endDate?: Date } =
      frequency;
    if (
      isNil(endDate)
        ? dayjs().isSameOrAfter(startDate, 'day')
        : dayjs().isBetween(startDate, endDate, 'day', '[]')
    ) {
      return <Chip size="small" label="Active" color="success" />;
    }
    if (dayjs().isBefore(startDate, 'day')) {
      return <Chip size="small" label="Not started" color="info" />;
    }
    if (dayjs().isSameOrAfter(endDate, 'day')) {
      return <Chip size="small" label="Expired" color="error" />;
    }
  }
  return <Chip size="small" label="Unknown" />;
};
