import { calculateTotalWeight } from 'shared/weight';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  selectPackageUuidsForOrder,
  selectStandardOrderDimFactor,
} from '../../orders/redux/standard/standard-orders-values-slice';
import { selectPackagesByIds } from '../../packages/redux/package-values-slice';
import {
  selectPackageUuidsForQuote,
  selectQuoteDimFactor,
} from '../../quotes/redux/quote-values-slice';
import {
  selectOrderUuidForShipment,
  selectQuoteUuidForShipment,
} from '../../shipments/redux/standard-shipments-values-slice';

export const usePackagesForQuoteOrOrder = ({
  shipmentUuids,
  isFromQuotePage,
}: {
  shipmentUuids: string[] | undefined;
  isFromQuotePage?: boolean;
}) => {
  const orderUuid = useAppSelector((state) =>
    selectOrderUuidForShipment(state, shipmentUuids?.[0] ?? ''),
  );
  const quoteUuid = useAppSelector((state) =>
    selectQuoteUuidForShipment(state, shipmentUuids?.[0] ?? ''),
  );
  const orderPackageUuids = useAppSelector((state) =>
    selectPackageUuidsForOrder(state, orderUuid ?? ''),
  );
  const quotePackageUuids = useAppSelector((state) =>
    selectPackageUuidsForQuote(state, quoteUuid ?? ''),
  );
  return useAppSelector((state: RootState) =>
    selectPackagesByIds(
      state,
      isFromQuotePage === true
        ? quotePackageUuids ?? []
        : orderPackageUuids ?? [],
    ),
  );
};

export const useDimFactorForQuoteOrOrder = ({
  shipmentUuids,
  isFromQuotePage,
}: {
  shipmentUuids: string[] | undefined;
  isFromQuotePage?: boolean;
}) => {
  const orderUuid = useAppSelector((state) =>
    selectOrderUuidForShipment(state, shipmentUuids?.[0] ?? ''),
  );
  const quoteUuid = useAppSelector((state) =>
    selectQuoteUuidForShipment(state, shipmentUuids?.[0] ?? ''),
  );
  const quoteDimFactor = useAppSelector((state) =>
    selectQuoteDimFactor(state, quoteUuid ?? ''),
  );
  const orderDimFactor = useAppSelector((state) =>
    selectStandardOrderDimFactor(state, orderUuid ?? ''),
  );
  return isFromQuotePage === true ? quoteDimFactor : orderDimFactor;
};

export const useTotalWeight = ({
  shipmentUuids,
  isFromQuotePage,
  useActualWeightInCalculation,
}: {
  shipmentUuids: string[] | undefined;
  useActualWeightInCalculation: boolean;
  isFromQuotePage?: boolean;
}) => {
  const packages = usePackagesForQuoteOrOrder({
    shipmentUuids,
    isFromQuotePage,
  });
  const dimFactor = useDimFactorForQuoteOrOrder({
    shipmentUuids,
    isFromQuotePage,
  });
  return calculateTotalWeight(
    packages,
    dimFactor ?? 0,
    useActualWeightInCalculation,
  );
};
