import { isEmpty } from 'lodash';
import { Resolver, useForm } from 'react-hook-form';
import { WarehouseEmployeeFormValues } from './types';

export const resolver: Resolver<WarehouseEmployeeFormValues> = async (
  values,
) => {
  const errors: {
    [property in keyof WarehouseEmployeeFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  if (isEmpty(values.firstName)) {
    errors.firstName = {
      type: 'required',
      message: 'First name is required',
    };
  }

  if (isEmpty(values.lastName)) {
    errors.lastName = {
      type: 'required',
      message: 'Last name is required',
    };
  }

  if (isEmpty(values.phoneNumber)) {
    errors.phoneNumber = {
      type: 'required',
      message: 'Phone number is required',
    };
  } else if (values?.phoneNumber?.length !== 14) {
    errors.phoneNumber = {
      type: 'required',
      message: 'Phone number is invalid',
    };
  }

  if (isEmpty(values.password)) {
    errors.password = {
      type: 'required',
      message: 'Password is required',
    };
  }

  return {
    values,
    errors,
  };
};

const useCreateWarehouseEmployeeForm = () => {
  return useForm({ resolver, defaultValues: { active: true } });
};

export default useCreateWarehouseEmployeeForm;
