import {
  Box,
  Button,
  Card,
  CardContent,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { isNil } from 'lodash';
import * as React from 'react';
import { InvoiceType } from '../../../../../generated/graphql';
import { DownloadStatus, FileDownload } from '../../../types/types';
import { downloadMultipleInvoices } from '../../../utils';

const FileDownloadCard = ({ fileDownload }: { fileDownload: FileDownload }) => {
  const download = async () => {
    if (!isNil(fileDownload.singleUrl)) {
      fetch(fileDownload.singleUrl.downloadLink).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'invoices.pdf';
          alink.click();
        });
      });
    }
  };

  const downloadMultiple = () => {
    if (!isNil(fileDownload.multipleUrl)) {
      downloadMultipleInvoices(fileDownload.multipleUrl);
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 15 }}>
                {fileDownload.invoices
                  .map((invoice) => invoice.invoiceName)
                  .join(', ')}
              </Typography>
            </Grid>
          </Grid>
          {fileDownload.status === DownloadStatus.InProgress && (
            <>
              <Typography sx={{ fontSize: 13 }} color="text.secondary">
                Generating Invoice...
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress
                    color="info"
                    variant="determinate"
                    value={fileDownload.progress * 100}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">
                    {(fileDownload.progress * 100).toFixed(0)}%
                  </Typography>
                </Box>
              </Box>
            </>
          )}{' '}
          {fileDownload.status === DownloadStatus.Finished && (
            <Stack direction="row" spacing={2} justifyContent="space-evenly">
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={downloadMultiple}
              >
                Download
              </Button>
              {(fileDownload.invoiceType === InvoiceType.PdfSummarized ||
                fileDownload.invoiceType === InvoiceType.PdfItemized) && (
                <Button
                  sx={{ float: 'right' }}
                  size="small"
                  variant="contained"
                  color="info"
                  onClick={download}
                >
                  Download Combined
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default React.memo(FileDownloadCard);
