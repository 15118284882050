import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import React from 'react';

interface ClearTextFieldButtonProps {
  searchText: string;
  handleClearSearchText: () => void;
}
const ClearTextFieldButton = ({
  searchText,
  handleClearSearchText,
}: ClearTextFieldButtonProps) => (
  <IconButton
    size="small"
    onClick={() => handleClearSearchText()}
    sx={{ visibility: searchText.length > 0 ? 'visible' : 'hidden' }}
  >
    <ClearIcon />
  </IconButton>
);

export default ClearTextFieldButton;
