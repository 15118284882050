import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useHoldReasons from '../../../../common/react-hooks/use-hold-reasons';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  HoldReasonsDocument,
  PermissionResource,
  useCreateHoldReasonMutation,
  useHoldReasonsLazyQuery,
} from '../../../../generated/graphql';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from '../common/active-archived-tab-panel';
import HoldReasonRow from './hold-reason-row';

const HoldReasons = () => {
  const { refetchHoldReasons } = useHoldReasons();
  const [getHoldReasons, { data: holdReasonsData }] = useHoldReasonsLazyQuery();
  const { userPermissions } = useUserRoles();
  const [createHoldReason] = useCreateHoldReasonMutation({
    refetchQueries: [HoldReasonsDocument],
  });
  const { canWrite: canWriteHoldReasons } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyHoldReasons,
  );
  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  useEffect(() => {
    getHoldReasons({
      variables: {
        archived: isActiveOrArchived === ActiveOrArchivedTab.ARCHIVED,
      },
    });
  }, [isActiveOrArchived]);

  const addHoldReason = async () => {
    await createHoldReason({
      variables: {
        holdReasonCreateInput: {
          name: 'New hold reason',
        },
      },
    });
    refetchHoldReasons();
  };

  return (
    <Stack direction="column" alignItems="flex-start">
      <ActiveArchivedButtonGroup
        activeTab={isActiveOrArchived}
        setTab={setIsActiveOrArchived}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Name</TableCell>
              <TableCell align="right">
                <Button
                  onClick={addHoldReason}
                  sx={{ marginBottom: '16px' }}
                  variant="contained"
                  disabled={!canWriteHoldReasons}
                >
                  Add Hold Reason
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(holdReasonsData?.holdReasons, 'name').map((holdReason) => (
              <HoldReasonRow key={holdReason.uuid} holdReason={holdReason} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default HoldReasons;
