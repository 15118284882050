import { TextField } from '@mui/material';
import {
  BaseFilterInputInputPropsSx,
  BaseFilterInputProps,
} from '../orders/components/filters/filter-utils';

interface FilterTextInputProps extends BaseFilterInputProps {
  textFieldType: 'number' | 'text';
}

const FilterTextInput = ({
  filterValueInput,
  filterOperationInput,
  filterNameInput,
  setFilter,
  textFieldType,
}: FilterTextInputProps) => {
  return (
    <TextField
      type={textFieldType}
      InputProps={{
        sx: {
          ...BaseFilterInputInputPropsSx,
          width: '160px',
          height: '34px',
          marginLeft: '-2px',
        },
      }}
      size="small"
      value={filterValueInput ?? ''}
      onChange={(event) => {
        setFilter({
          filter: filterNameInput,
          op: filterOperationInput,
          value: event.target.value,
        });
      }}
      placeholder="Value"
    />
  );
};

export default FilterTextInput;
