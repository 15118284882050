import {
  DeleteOutline as DeleteOutlineIcon,
  MoreVert,
  Upload,
} from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  Divider,
  FormControl,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getEnumKeys } from 'shared/enum';
import { CsvOrderMappingTargetFieldType } from '../../../../generated/graphql';
import { CsvImportConfigurationFormData } from '../form/csv-import-configuration-form-schema';
import { populateFieldMappingsFromCsv } from '../utils';

const FieldMappingsCard = () => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<CsvImportConfigurationFormData>();
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const fieldMappings = useWatch({ control, name: 'fieldMappings' });

  // Add hidden file input reference
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Add file upload handler
  const handleFileUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'text/csv') {
      // Handle CSV file
      const result = await populateFieldMappingsFromCsv(file);
      if (!isNil(result)) {
        // todo: handle csv upload error
        setValue('fieldMappings', result);
      } else {
        // todo: handle csv upload error
      }
    } else {
      // TODO: show error for non-CSV files
    }
  };

  const handleRemoveField = (index: number) => {
    const updatedMappings = [...fieldMappings];
    updatedMappings.splice(index, 1);
    setValue('fieldMappings', updatedMappings);
  };

  const handleAddField = () => {
    const updatedMappings = [
      {
        sourceField: '',
        targetField: CsvOrderMappingTargetFieldType.TerminalName,
      },
      ...fieldMappings,
    ];
    setValue('fieldMappings', updatedMappings);
  };

  if (isNil(fieldMappings)) {
    return null;
  }

  const fieldMappingsErrors =
    errors?.fieldMappings?.root?.message ?? errors.fieldMappings?.message;

  return (
    <Card sx={{ p: 2, boxShadow: 0, border: 'solid 0.5px grey' }}>
      <Stack sx={{ gap: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Field mappings</Typography>
          <Stack direction="row" gap={1}>
            <Button onClick={handleAddField}>Add field</Button>
            <IconButton
              size="small"
              onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  setMenuAnchorEl(null);
                  handleFileUpload();
                }}
              >
                <ListItemIcon>
                  <Upload fontSize="small" />
                </ListItemIcon>
                Upload sample CSV
              </MenuItem>
            </Menu>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".csv"
              onChange={handleFileChange}
            />
          </Stack>
        </Stack>
        {!isNil(fieldMappingsErrors) && (
          <Alert severity="error">
            <Typography>{fieldMappingsErrors}</Typography>
          </Alert>
        )}
        <Divider />
        <Stack>
          {!isEmpty(fieldMappings) ? (
            <Stack
              sx={{
                display: 'flex',
              }}
              direction="row"
              width="97%"
            >
              <Typography
                sx={{
                  flex: 1,
                  color: theme.palette.text.secondary,
                }}
                variant="subtitle1"
              >
                Source field (in CSV)
              </Typography>
              <Typography
                sx={{ flex: 1, color: theme.palette.text.secondary }}
                variant="subtitle1"
              >
                Target field (in Pallet)
              </Typography>
            </Stack>
          ) : (
            <Stack sx={{ alignItems: 'center', p: 2 }}>
              <Typography
                variant="subtitle1"
                color={theme.palette.text.secondary}
              >
                No field mappings configured
              </Typography>
            </Stack>
          )}
          <Stack spacing={1}>
            {fieldMappings.map((_, index) => (
              <Stack
                key={null}
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
                direction="row"
              >
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name={`fieldMappings.${index}.sourceField`}
                    control={control}
                    render={({ field }) => (
                      <TextField size="small" sx={{ flex: 1 }} {...field} />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name={`fieldMappings.${index}.targetField`}
                    control={control}
                    render={({ field }) => (
                      <Select size="small" sx={{ flex: 1 }} {...field}>
                        {getEnumKeys(CsvOrderMappingTargetFieldType).map(
                          (option) => (
                            <MenuItem
                              key={option}
                              value={CsvOrderMappingTargetFieldType[option]}
                            >
                              {option}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    )}
                  />
                </FormControl>

                <IconButton
                  sx={{ width: '3%' }}
                  color="error"
                  onClick={() => handleRemoveField(index)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FieldMappingsCard;
