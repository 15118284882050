import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { AgingReportDataFragment } from '../../../../../generated/graphql';
import {
  calculateAgingReportTotals,
  getAgingReportColumnHeader,
} from './utils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  invoicesTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '9px',
    width: '100%',
    textAlign: 'center',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  invoicesTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '35%',
  },
  invoicesTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  customerCol: {
    textAlign: 'left',
    width: '100%',
  },
});

const AgingReportTable = ({
  agingReportData,
}: {
  agingReportData: AgingReportDataFragment[];
}) => {
  const headerBuckets = agingReportData[0]?.openInvoiceValueBuckets ?? [];
  const colStyle = {
    width: `${Math.floor(100 / (headerBuckets.length === 0 ? 1 : headerBuckets.length))}%`,
  };
  return (
    <View style={styles.invoicesTable}>
      <View style={[styles.invoicesTableRow, styles.invoicesTableHeader]}>
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.customerCol}>Customer</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          {headerBuckets.map((bucket) => (
            // eslint-disable-next-line react/jsx-key
            <Text style={colStyle}>
              {getAgingReportColumnHeader({ bucket })}
            </Text>
          ))}
        </View>
      </View>
      {agingReportData.map(
        (contact) =>
          !isNil(contact) &&
          contact.contactUuid.length > 0 && (
            <View
              style={styles.invoicesTableRow}
              wrap={false}
              key={contact.contactUuid}
            >
              <View style={styles.invoicesTableRowLeftSection}>
                <Text style={styles.customerCol}>{contact.displayName}</Text>
              </View>
              <View style={styles.invoicesTableRowRightSection}>
                {contact.openInvoiceValueBuckets.map((bucket) => (
                  // eslint-disable-next-line react/jsx-key
                  <Text style={colStyle}>
                    {bucket.openInvoiceValue.toFixed(2)}
                  </Text>
                ))}
              </View>
            </View>
          ),
      )}
      <View style={styles.invoicesTableRow} wrap={false}>
        <View style={styles.invoicesTableRowLeftSection}>
          <Text style={styles.customerCol}>Report total:</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          {headerBuckets.map((_, bucketIdx) => (
            // eslint-disable-next-line react/jsx-key
            <Text style={colStyle}>
              {calculateAgingReportTotals({
                bucketIdx,
                agingReportData,
              }).toFixed(2)}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};

const GeneratedSummaryAgingReportPdf = ({
  contactUuid,
  contactDisplayName,
  data,
  startDate,
}: {
  contactUuid?: string;
  contactDisplayName?: string;
  data: AgingReportDataFragment[] | undefined | null;
  startDate?: Dayjs | null | undefined;
}) => {
  if (isNil(data)) {
    return null;
  }
  return (
    <Document title="Account Aging Summary Report">
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={[
            styles.rowWithSpaceBetween,
            {
              marginTop: '10px',
              fontSize: '12px',
            },
          ]}
        >
          <Text style={styles.header1}>Account Aging Summary Report</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
          }}
        >
          <View style={styles.rowWithSpaceBetween}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>Customer</Text>
              <Text style={{ marginLeft: '3px' }}>
                {!isNil(contactUuid) ? `${contactDisplayName}` : 'All'}
              </Text>
            </View>
            <Text style={{ fontSize: '10px' }}>
              Aging calculated as of{' '}
              {(startDate ?? dayjs(new Date())).format('MM/DD/YYYY')}
            </Text>
          </View>
        </View>
        {!isNil(data)}
        <View style={styles.contactBlock}>
          <AgingReportTable agingReportData={data} />
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedSummaryAgingReportPdf;
