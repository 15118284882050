import { LazyQueryExecFunction } from '@apollo/client';
import {
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import {
  OrderDocument,
  useDetachDocumentFromShipmentMutation,
  OrderQuery,
  Exact,
  PermissionResource,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';
import { FetchCompanyDocumentsParams } from '../types/company-documents';
import { DOCUMENT_PAGE_SIZE } from './bulk-change-document-type';

interface EndOfDayMatchedDocumentsTableProps {
  setShowUnmatchOrderSuccessMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  setShowUnmatchOrderErrorMessage: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  fetchCompanyDocuments: (params: FetchCompanyDocumentsParams) => void;
  getOrder: LazyQueryExecFunction<
    OrderQuery,
    Exact<{
      uuid: string;
    }>
  >;
  orderData: OrderQuery | undefined;
}

const EndOfDayMatchedDocumentsTable = ({
  setShowUnmatchOrderSuccessMessage,
  setShowUnmatchOrderErrorMessage,
  fetchCompanyDocuments,
  getOrder,
  orderData,
}: EndOfDayMatchedDocumentsTableProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const { height } = useWindowDimensions();
  const {
    selectedMatchedOrderUuid,
    selectedDocumentUuids,
    setSelectedDocumentUuids,
  } = useContext(EndOfDayContext);
  const [
    detachDocumentFromShipment,
    { loading: detachDocumentFromShipmentLoading },
  ] = useDetachDocumentFromShipmentMutation({
    refetchQueries: [OrderDocument],
  });

  useEffect(() => {
    if (!isNil(selectedMatchedOrderUuid)) {
      getOrder({ variables: { uuid: selectedMatchedOrderUuid } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatchedOrderUuid]);

  const handleUnmatch = async (documentUuid: string) => {
    try {
      await detachDocumentFromShipment({ variables: { uuid: documentUuid } });
      fetchCompanyDocuments({ first: DOCUMENT_PAGE_SIZE });
      setSelectedDocumentUuids(
        selectedDocumentUuids.filter((uuid) => uuid !== documentUuid),
      );
      setShowUnmatchOrderSuccessMessage(true);
    } catch (e) {
      setShowUnmatchOrderErrorMessage(true);
    }
  };

  const sortedDocuments = useMemo(() => {
    const documents = orderData?.order?.documents ?? [];
    documents.sort((a, b) => {
      if (isNil(a.pageNumber) || isNil(b.pageNumber)) {
        return 0;
      }

      return a.pageNumber - b.pageNumber;
    });
    return documents;
  }, [orderData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper} style={{ height: height - 135 }}>
          <Table>
            <TableHead>
              <TableCell>Document</TableCell>
              <TableCell>Page</TableCell>
              <TableCell>Order Ref #</TableCell>
              <TableCell />
            </TableHead>
            {sortedDocuments?.map((document) => {
              return (
                <TableRow key={document.uuid}>
                  <TableCell>{document.fileName}</TableCell>
                  <TableCell>{document.pageNumber}</TableCell>
                  <TableCell>
                    {
                      orderData?.order?.standardOrderFields
                        ?.shipperBillOfLadingNumber
                    }
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      color="info"
                      onClick={() => handleUnmatch(document.uuid)}
                      disabled={
                        detachDocumentFromShipmentLoading ||
                        isEmpty(selectedDocumentUuids) ||
                        !canWriteEndOfDay
                      }
                    >
                      Unmatch
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default EndOfDayMatchedDocumentsTable;
