import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import { useFormContext, UseFormSetError, useWatch } from 'react-hook-form';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import { AccessorialsByBillingContactQuery } from '../../../../../generated/graphql';
import { QuoteFormValues } from '../../../../quotes/components/quote-form/forms/use-quote-form';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { addressSchema } from './address-schema';
import { StopType } from './stop-type';
import { OrderFormValues, StopValues } from './types';
import { isRegularStop, isServiceDateRequiredForStop } from './utils';

export const useValidateStops = () => {
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );

  const { control } = useFormContext<OrderFormValues>();

  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });

  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });

  const validateStop = async ({
    stop,
    idx,
    setError,
    accessorials,
    recurringTemplate,
    isQuote = false,
  }: {
    stop: StopValues;
    idx: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setError: (key: any, { message }: { message: string }) => void;
    accessorials: AccessorialsByBillingContactQuery['accessorialsByBillingContact'];
    recurringTemplate?: boolean;
    isQuote?: boolean;
  }) => {
    let isValid = true;
    const isAddressValid =
      !isNil(stop.address) && (await addressSchema.isValid(stop.address));
    if (
      !isAddressValid &&
      (stop.stopType === StopType.Delivery ||
        stop.stopType === StopType.Pickup ||
        stop.stopType === StopType.Recovery ||
        (stop.stopType === StopType.Transfer &&
          stop.destinationInOutbound === true))
    ) {
      setError(`stops.${idx}.address`, { message: 'Address is required' });
      isValid = false;
    }
    if (
      !ffRecoveryTransferAddressOnly &&
      isNil(stop.airportInfoUuid) &&
      (stop.stopType === StopType.Recovery ||
        (stop.stopType === StopType.Transfer &&
          stop.destinationInOutbound === true))
    ) {
      setError(`stops.${idx}.airportInfoUuid`, {
        message: 'Airport is required',
      });
      isValid = false;
    }

    const otherStopType =
      idx === INBOUND_STOP_IDX ? outboundStopType : inboundStopType;

    if (
      isServiceDateRequiredForStop({
        stopType: stop.stopType,
        otherStopType,
      }) &&
      isNil(stop.serviceDate) &&
      recurringTemplate !== true &&
      isQuote === false
    ) {
      const errorMessagePrefix = isRegularStop({ stopType: stop.stopType })
        ? 'Service date'
        : 'Arrival date';
      setError(`stops.${idx}.serviceDate`, {
        message: `${errorMessagePrefix} is required for ${pluralize(sentenceCase(stop.stopType))}`,
      });
      isValid = false;
    }

    stop.customCharges?.forEach((charge, i) => {
      const accessorial = accessorials.find(
        (a) => a.uuid === charge.accessorialUuid,
      );
      if (isNil(accessorial)) return;
      if (
        accessorial.isAuthoCodeRequired &&
        (isNil(charge.authoCode) || isEmpty(charge.authoCode))
      ) {
        setError(`stops.${idx}.customCharges.${i}`, {
          message: `Autho code is required for ${accessorial.name}`,
        });
        isValid = false;
      }
      if (
        accessorial.__typename === 'ZoneBasedAccessorialEntity' &&
        (isNil(charge.chargeGroupUuid) || isNil(charge.zoneUuid))
      ) {
        setError(`stops.${idx}.customCharges.${i}`, {
          message: `Zone and charge group are required for ${accessorial.name}`,
        });
        isValid = false;
      }
    });
    return isValid;
  };

  type StopsAreValidArgs<TFormValues extends Pick<OrderFormValues, 'stops'>> = {
    setError: UseFormSetError<TFormValues>;
    stopValues: StopValues[];
    accessorials: AccessorialsByBillingContactQuery['accessorialsByBillingContact'];
    recurringTemplate?: boolean;
  };

  const stopsAreValidForOrder = async ({
    setError,
    stopValues,
    accessorials,
    recurringTemplate,
  }: StopsAreValidArgs<OrderFormValues>) => {
    const validArr = await Promise.all(
      stopValues.map((stop, idx) =>
        validateStop({
          stop,
          idx,
          setError,
          accessorials,
          recurringTemplate,
        }),
      ),
    );

    return validArr.every((valid) => valid === true);
  };

  const stopsAreValidForQuote = async ({
    setError,
    stopValues,
    accessorials,
    recurringTemplate,
  }: StopsAreValidArgs<QuoteFormValues>) => {
    const validArr = await Promise.all(
      stopValues.map((stop, idx) =>
        validateStop({
          stop,
          idx,
          setError,
          accessorials,
          recurringTemplate,
          isQuote: true,
        }),
      ),
    );

    return validArr.every((valid) => valid === true);
  };

  return { stopsAreValidForOrder, stopsAreValidForQuote };
};
