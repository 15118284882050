import type { RowDragEndEvent, RowDropZoneParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import { StopFragment } from '../../../generated/graphql';
import useDispatchStore from '../../dispatch/dispatch-store';
import useAssignStopsRouteActions from '../../dispatch/hooks/use-assign-stops-route-actions';
import useFetchRoutes from '../../dispatch/hooks/use-fetch-routes';
import {
  routeCardDefaultStyle,
  routeCardHighlightedStyle,
} from '../../dispatch/types/routes';
import { StopsTableElement } from '../../dispatch/types/stops';
import {
  getStopsAndRecurringRunHeadersFromNodes,
  isStopFragment,
} from '../../dispatch/utils';

const ERR_ASSIGN_ROUTE_LOCKED =
  'Failed to assign stops. Target route is locked';

export const useDispatchUnassignedStopsRowDrag = () => {
  const { fetchRoute } = useFetchRoutes();
  const { assignStop } = useAssignStopsRouteActions();
  const [getRouteByUuid, setSelectedStopUuids, setErrorMessage] =
    useDispatchStore(
      (state) => [
        state.getRouteByUuid,
        state.setSelectedStopUuids,
        state.setErrorMessage,
      ],
      shallow,
    );

  const handleEndDragStops = async (
    stops: StopFragment[],
    routeUuid: string,
  ) => {
    const routeIsLocked = getRouteByUuid(routeUuid)?.locked === true;
    if (routeIsLocked) return null;

    const successes = await assignStop({
      routeUuid,
      stopUuids: stops.map((stop) => stop.stop.uuid),
      emitMultiplayerEvent: true,
    });

    const responses = await Promise.all(
      (successes ?? []).map(async (success, idx) => {
        return [
          {
            stop: stops[idx],
            orderName:
              stops[idx]?.order?.shipperBillOfLadingNumber ??
              stops[idx]?.order?.name,
            success: !isNil(success),
          },
        ];
      }),
    );
    setSelectedStopUuids([]);
    fetchRoute(routeUuid);
    return responses.flat();
  };

  const addRouteDropZones = ({
    gridRef,
    onFinish,
  }: {
    gridRef: React.RefObject<AgGridReact<StopsTableElement>>;
    onFinish?: (assignedStopUuids: string[]) => void;
  }) => {
    const containers: NodeListOf<HTMLElement> =
      document.querySelectorAll('.route-card');
    containers.forEach((container: HTMLElement) => {
      const elem = container;
      const dropZone: RowDropZoneParams = {
        getContainer: () => {
          return container;
        },
        onDragEnter: () => {
          Object.assign(elem.style, routeCardHighlightedStyle);
        },
        onDragLeave: () => {
          Object.assign(elem.style, routeCardDefaultStyle);
        },
        onDragStop: async (params: RowDragEndEvent<StopsTableElement>) => {
          const { stops, recurringRunHeaders } =
            getStopsAndRecurringRunHeadersFromNodes(params.nodes);
          // Fill in stops that should be included for any recurring run header being dragged.
          if (recurringRunHeaders.length > 0) {
            const nodes = gridRef.current?.api.getRenderedNodes() ?? [];
            for (const rrh of recurringRunHeaders) {
              for (const { data } of nodes) {
                if (
                  isStopFragment(data) &&
                  data.recurringRunUuid === rrh.uuid &&
                  !stops.some((s) => s.stop.uuid === data.stop.uuid)
                ) {
                  stops.push(data);
                }
              }
            }
          }
          const results = await handleEndDragStops(stops, elem.id);
          if (isNil(results)) {
            setErrorMessage(ERR_ASSIGN_ROUTE_LOCKED);
          }
          if (results?.some((res) => !res.success) === true) {
            const orders = results
              .filter((res) => !res.success)
              .map((res) => res.orderName)
              .join(', ');
            setErrorMessage(
              `Failed to assign stop(s): ${orders}. They may already have been assigned.`,
            );
            results.forEach((res) => {
              if (!res.success && !isNil(res.stop)) {
                gridRef.current?.api.applyServerSideTransaction({
                  remove: [res.stop],
                });
              }
            });
          }
          gridRef.current?.api?.deselectAll();
          onFinish?.(stops.map(({ stop }) => stop.uuid));
          Object.assign(elem.style, routeCardDefaultStyle);
        },
      };
      gridRef.current?.api?.addRowDropZone(dropZone);
    });
  };

  return {
    addRouteDropZones,
  };
};
