import { Box } from '@mui/material';
import React, { CSSProperties } from 'react';

type StopMarkerCircleIconProps = {
  color: string;
  size?: 'large' | 'medium';
  text?: string;
  style?: CSSProperties;
};

const StopMarkerCircleIcon = ({
  color,
  size,
  text,
  style,
}: StopMarkerCircleIconProps) => {
  return (
    <Box
      sx={{
        ...style,
        backgroundColor: color,
        position: 'relative',
        top: size === 'large' ? '20px' : '15px',
        width: size === 'large' ? '40px' : '30px',
        height: size === 'large' ? '40px' : '30px',
        justifyContent: 'center',
        color: 'white',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        fontSize: size === 'large' ? '20px' : '15px',
      }}
    >
      {text}
    </Box>
  );
};

export default StopMarkerCircleIcon;
