import { Stack, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { useOrderFormEditAccess } from '../../../../domains/orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderCommentValues } from '../../../../domains/orders/components/order-form/forms/types';
import { OrderFormEditAccess } from '../../../../domains/orders/components/order-form/forms/use-order-form-edit-access';
import useMe from '../../../react-hooks/use-me';
import CommentInput from './comment-input';
import CommentsMenu from './comments-menu';

type CommentsRowProps = {
  orderComment: OrderCommentValues;
  onEditComment: (commentUuid: string, newComment: string) => Promise<void>;

  onCheckShowOnInvoice?: (commentUuid: string, value: boolean) => Promise<void>;
  onDeleteComment: (commentUuid: string) => Promise<void>;
};

const COLOR_SECONDARY_HIGHLIGHT = 'gray';
type UpdatedAt = NonNullable<OrderCommentValues>['updatedAt'];

const CommentsRow = ({
  orderComment,
  onEditComment,
  onCheckShowOnInvoice,
  onDeleteComment,
}: CommentsRowProps) => {
  const { useAllCaps } = useMe();

  const { editAccess, disabledIfNoAccess } = useOrderFormEditAccess();

  const [currentComment, setCurrentComment] = useState(
    orderComment?.comment ?? '',
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const getUpdateAtDateString = (updatedAt: UpdatedAt) => {
    return !isNil(updatedAt) ? dayjs(updatedAt).format('MM/DD/YY') : '-';
  };
  const getUpdateAtTimeString = (updatedAt: UpdatedAt) => {
    return !isNil(updatedAt) ? dayjs(updatedAt).format('hh:mma') : '-';
  };

  const onCancelEdit = () => {
    setIsEditing(false);
  };

  const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentComment(e.target.value);
  };

  const showOrderCommentsMenu =
    isHovered && !isEditing && editAccess !== OrderFormEditAccess.None;

  return (
    <Stack
      key={orderComment?.uuid}
      direction="row"
      pb={1}
      display="flex"
      spacing={2}
    >
      <Stack flex={1} minWidth={0}>
        <Stack direction="row" spacing={0.5} fontSize="15px" flexWrap="wrap">
          <Typography display="inline" fontWeight="bold">
            {getUpdateAtDateString(orderComment?.updatedAt)}
          </Typography>
          <Typography display="inline" color={COLOR_SECONDARY_HIGHLIGHT}>
            {getUpdateAtTimeString(orderComment?.updatedAt)}
          </Typography>
        </Stack>
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          title={orderComment?.authorName}
          pt={1.5}
          color={COLOR_SECONDARY_HIGHLIGHT}
          variant="caption"
        >
          {orderComment?.authorName}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="start"
        flex={2}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isEditing ? (
          <CommentInput
            value={currentComment}
            onChange={onChangeComment}
            onSubmit={() => {
              if (
                isNil(orderComment) ||
                isNilOrEmptyString(orderComment.uuid)
              ) {
                return;
              }
              onEditComment(orderComment.uuid, currentComment);
              setIsEditing(false);
            }}
            disabled={disabledIfNoAccess}
            onCancel={onCancelEdit}
            isEditMode
            inputProps={
              useAllCaps ? { style: { textTransform: 'uppercase' } } : {}
            }
          />
        ) : (
          <Typography whiteSpace="pre-wrap" style={{ wordWrap: 'break-word' }}>
            {orderComment?.comment ?? '-'}
          </Typography>
        )}
        {showOrderCommentsMenu && (
          <Tooltip title="More" placement="top-end">
            <CommentsMenu
              orderComment={orderComment}
              setIsEditing={setIsEditing}
              onCheckShowOnInvoice={onCheckShowOnInvoice}
              onDeleteComment={onDeleteComment}
            />
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default CommentsRow;
