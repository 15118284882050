import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Option } from '../../../../common/filters/types';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  DriverSettlementBillForDownloadFragment,
  DriverSettlementBillsForDownloadQueryResult,
  useDriverSettlementBillsForDownloadLazyQuery,
  useMeQuery,
} from '../../../../generated/graphql';
import { SettlementsDownloadType } from '../../types';
import { downloadDriverSettlementBills } from '../../utils';

interface SettlementPrintSettingsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  settlementUuid?: string;
  searchText?: string;
  driverOption?: Option | null | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  isFinalized?: boolean | undefined;
  selectedDownloadTypeOption: SettlementsDownloadType;
  handleDownloadTypeChange?: (
    selectedDownloadType: SettlementsDownloadType,
  ) => void;
}

const SettlementPrintSettingsModal = ({
  isOpen,
  setIsOpen,
  settlementUuid,
  searchText,
  driverOption,
  startDate,
  endDate,
  isFinalized,
  selectedDownloadTypeOption,
  handleDownloadTypeChange,
}: SettlementPrintSettingsModalProps) => {
  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });

  const [displayStopTotal, setDisplayStopTotal] = useState<boolean>(false);
  const [displayOrderTotal, setDisplayOrderTotal] = useState<boolean>(false);
  const [displayDriverPayout, setDisplayDriverPayout] = useState<boolean>(true);
  const [printFormat, setPrintFormat] = useState<string>('Basic');
  const [showOtherDriversPaidForStop, setShowOtherDriversPaidForStop] =
    useState(false);

  const [getDriverSettlementBillsForDownload] =
    useDriverSettlementBillsForDownloadLazyQuery();

  const reset = () => {
    setDisplayStopTotal(false);
    setDisplayOrderTotal(false);
    setDisplayDriverPayout(true);
    setShowOtherDriversPaidForStop(false);
    setPrintFormat('Basic');
    if (!isNil(handleDownloadTypeChange)) {
      handleDownloadTypeChange(SettlementsDownloadType.SEPARATE_FILES);
    }
  };

  const downloadPDF = async (
    settlementBills: DriverSettlementBillForDownloadFragment[],
  ) => {
    await downloadDriverSettlementBills({
      settlementBills,
      companyData,
      startDate,
      endDate,
      driverOption,
      downloadType: selectedDownloadTypeOption,
      printFormat,
      displayStopTotal,
      displayOrderTotal,
      displayDriverPayout,
      showOtherDriversPaidForStop,
    });

    reset();
  };

  const startDownload = async () => {
    setIsOpen(false);
    let res: DriverSettlementBillsForDownloadQueryResult;
    if (!isNil(settlementUuid)) {
      res = await getDriverSettlementBillsForDownload({
        variables: {
          uuids: [settlementUuid],
        },
      });
    } else if (!isNilOrEmptyString(searchText)) {
      // ignore all filters if searching
      res = await getDriverSettlementBillsForDownload({
        variables: {
          searchText: searchText?.trim(),
          isFinalized,
        },
      });
    } else {
      res = await getDriverSettlementBillsForDownload({
        variables: {
          driverUuid: driverOption?.value,
          startDate,
          endDate,
          isFinalized,
        },
      });
    }
    await downloadPDF(res.data?.driverSettlementBillsForDownload ?? []);
  };

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="settlement-print-settings-dialog-title"
    >
      <DialogTitle id="settlement-print-settings-dialog-title">
        Settlement download settings
      </DialogTitle>
      <DialogContent sx={{ pt: '10px !important' }}>
        <FormControl>
          {!isNil(handleDownloadTypeChange) && (
            <>
              <InputLabel id="settlement-download-type-selection-label">
                Download as...
              </InputLabel>
              <Select
                labelId="settlement-download-type-selection-label"
                value={
                  selectedDownloadTypeOption ===
                  SettlementsDownloadType.ONE_FILE_NO_HEADERS
                    ? SettlementsDownloadType.ONE_FILE_INCLUDE_HEADERS
                    : selectedDownloadTypeOption
                }
                label="Download as..."
                onChange={(e) => {
                  handleDownloadTypeChange(
                    (e.target.value as SettlementsDownloadType) ??
                      SettlementsDownloadType.SEPARATE_FILES,
                  );
                }}
                size="small"
                sx={{
                  backgroundColor: 'white',
                  mb:
                    selectedDownloadTypeOption ===
                    SettlementsDownloadType.SEPARATE_FILES
                      ? '20px'
                      : '0px',
                }}
              >
                {[
                  SettlementsDownloadType.SEPARATE_FILES,
                  SettlementsDownloadType.ONE_FILE_INCLUDE_HEADERS,
                ].map((downloadType) => (
                  <MenuItem
                    key={downloadType}
                    id={downloadType}
                    value={downloadType}
                  >
                    {downloadType}
                  </MenuItem>
                ))}
              </Select>
              {selectedDownloadTypeOption !==
                SettlementsDownloadType.SEPARATE_FILES && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        selectedDownloadTypeOption !==
                        SettlementsDownloadType.ONE_FILE_NO_HEADERS
                      }
                      onChange={(e) => {
                        handleDownloadTypeChange(
                          e.target.checked
                            ? SettlementsDownloadType.ONE_FILE_INCLUDE_HEADERS
                            : SettlementsDownloadType.ONE_FILE_NO_HEADERS,
                        );
                      }}
                    />
                  }
                  label="Include Headers"
                  sx={{ mb: '20px' }}
                />
              )}
            </>
          )}
          <FormLabel id="settlement-print-format-radio-buttons-group-label">
            Print format
          </FormLabel>
          <RadioGroup
            onChange={(_, value) => {
              setPrintFormat(value);
              if (value !== 'Standard') {
                setDisplayOrderTotal(false);
              }
            }}
            aria-labelledby="settlement-print-format-radio-buttons-group-label"
            value={printFormat}
            name="Settlement format"
            sx={{ mb: '15px' }}
          >
            <FormControlLabel
              value="Basic"
              control={<Radio size="small" />}
              label={
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography>Basic</Typography>
                  <Tooltip
                    title={
                      <List
                        sx={{
                          listStyleType: 'disc',
                          listStylePosition: 'inside',
                        }}
                        disablePadding
                        dense
                      >
                        Includes:
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          HAWB
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Order Name
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Completed Date
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Customer Name
                        </ListItem>
                      </List>
                    }
                    placement="right"
                    arrow
                  >
                    <InfoIcon sx={{ width: '16px', mx: 1, color: 'gray' }} />
                  </Tooltip>
                </Box>
              }
            />
            <FormControlLabel
              value="Standard"
              control={<Radio size="small" />}
              label={
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography>Standard</Typography>
                  <Tooltip
                    title={
                      <List
                        sx={{
                          listStyleType: 'disc',
                          listStylePosition: 'inside',
                        }}
                        disablePadding
                        dense
                      >
                        Includes:
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          HAWB
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Order Name
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Completed Date
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Customer Name
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', padding: 0.5 }}>
                          Detailed Shipment Info
                        </ListItem>
                      </List>
                    }
                    placement="right"
                    arrow
                  >
                    <InfoIcon sx={{ width: '16px', mx: 1, color: 'gray' }} />
                  </Tooltip>
                </Box>
              }
            />
          </RadioGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={displayDriverPayout}
                onChange={(e) => {
                  setDisplayDriverPayout(e.target.checked);
                  e.stopPropagation();
                }}
              />
            }
            label="Display driver payout"
          />
          {printFormat === 'Standard' && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayStopTotal}
                    onChange={(e) => {
                      setDisplayStopTotal(e.target.checked);
                      e.stopPropagation();
                    }}
                  />
                }
                label="Display stop total"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayOrderTotal}
                    onChange={(e) => {
                      setDisplayOrderTotal(e.target.checked);
                      e.stopPropagation();
                    }}
                  />
                }
                label="Display order total"
              />
            </>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={showOtherDriversPaidForStop}
                onChange={(e) => {
                  setShowOtherDriversPaidForStop(e.target.checked);
                  e.stopPropagation();
                }}
              />
            }
            label={
              <Stack direction="row" alignItems="center">
                <Typography>Show other drivers paid for stop</Typography>
                <Tooltip title="Display the names of other drivers that are also getting paid for each stop. Does not display the amount other drivers are paid.">
                  <InfoIcon sx={{ width: '16px', mx: 1, color: 'gray' }} />
                </Tooltip>
              </Stack>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: '100px' }}
          variant="contained"
          color="info"
          onClick={startDownload}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettlementPrintSettingsModal;
