import { Text, View } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { safeDivide } from 'shared/math';
import { ReportType } from '../../../../generated/graphql';
import { ReportGroupConfiguration } from '../../types/report-types';
import {
  formatMoney,
  getOrderReportRowLabel,
  ReportBucketData,
} from '../../utils';
import { GENERATED_REVENUE_REPORT_COLS, TEXT_PADDING } from './constants';
import styles from './generated-revenue-report.styles';

export type GeneratedRevenueReportRowProps = {
  reportRow: ReportBucketData;
  reportGroupConfiguration: ReportGroupConfiguration | undefined;
};

const getRowLabels = (
  reportGroupConfiguration: ReportGroupConfiguration | undefined,
  reportRow: ReportBucketData,
): string[] => {
  const reportType = reportGroupConfiguration?.defaultReportType;
  switch (reportType) {
    case ReportType.Customer:
      return [reportRow.displayName ?? ''];
    case ReportType.Driver:
      return [
        `${reportRow.driverName ?? ''} ${
          !isNil(reportRow.driverType)
            ? `(${sentenceCase(reportRow.driverType)})`
            : ''
        }`,
      ];
    case ReportType.Order:
      return [
        getOrderReportRowLabel(
          reportGroupConfiguration?.reportAggregationPeriod,
          reportRow,
        ) ?? '',
      ];
    case ReportType.Terminal:
      return [reportRow.terminalName ?? ''];
    case ReportType.ServiceLevel:
      return [reportRow.name ?? ''];
    default:
      return [''];
  }
};

const GeneratedRevenueReportRow = ({
  reportRow,
  reportGroupConfiguration,
}: GeneratedRevenueReportRowProps) => {
  const rowLabels = getRowLabels(reportGroupConfiguration, reportRow);

  return (
    <View style={styles.invoicesTable}>
      <View style={[styles.reportRow]}>
        <View style={styles.reportRowLeftSection}>
          {rowLabels.map((rowLabel) => (
            <Text
              key={rowLabel}
              style={{
                width: `${safeDivide(100, rowLabels.length)}%`,
                paddingRight: TEXT_PADDING,
              }}
            >
              {rowLabel}
            </Text>
          ))}
        </View>
        <View style={styles.reportRowRightSection}>
          {GENERATED_REVENUE_REPORT_COLS.map((col) => {
            const revenueValue = reportRow[col as keyof ReportBucketData];
            return (
              <Text key={`${col}-${revenueValue}`} style={styles.revenueCol}>
                {formatMoney({
                  amount: !isNil(revenueValue)
                    ? parseFloat(revenueValue.toString())
                    : null,
                })}
              </Text>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default GeneratedRevenueReportRow;
