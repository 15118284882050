import { isArray, isEmpty, isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';
import {
  FilterConstructionFilterType,
  FilterConstructionType,
  FilterConstructionOperatorType,
  FilterConstructionValueType,
  SingleFilterConstructionType,
  FilterOperatorTypeV2,
} from '../../../../../common/filters/types';
import {
  isGroupFilterConstructionType,
  filterToInputType,
  filterEmptyFilterConstructionTypes,
} from '../../../../../common/filters/utils';
import { DateFilterOptionV2 } from '../../../../../generated/graphql';
import { OrderFilterFieldV2 } from '../../../../orders/components/enums/order-filters';
import { OrderTableFilterModel } from '../../types';

export interface BaseFilterInputProps {
  filterValueInput: FilterConstructionValueType;
  filterOperationInput: FilterConstructionOperatorType;
  filterNameInput: FilterConstructionFilterType;
  setFilter: ({ filter, op, value }: SingleFilterConstructionType) => void;
}

export const BaseFilterInputInputPropsSx = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  backgroundColor: 'white',
} as const;

export function filterConstructionTypeToOrderTableFilterModel(
  filterConstructionType: FilterConstructionType[],
): OrderTableFilterModel {
  return filterEmptyFilterConstructionTypes(filterConstructionType).reduce(
    (acc, singleFilterConstructionType) => {
      if (isGroupFilterConstructionType(singleFilterConstructionType)) {
        const op: 'and' | 'or' = !isEmpty(singleFilterConstructionType.and)
          ? 'and'
          : 'or';
        const filteredGroup =
          (singleFilterConstructionType[op] ?? []).map((f) =>
            filterConstructionTypeToOrderTableFilterModel([f]),
          ) ?? [];
        if (!isEmpty(filteredGroup)) {
          acc[op] = filteredGroup;
        }

        return acc;
      }

      const { filter, op, value } = singleFilterConstructionType;
      if (isNilOrEmptyString(filter) || isNilOrEmptyString(op)) {
        return acc;
      }

      if (isNil(acc[filter])) {
        acc[filter] = {};
      }
      const filterType = filterToInputType(filter);
      const filterTypeSupportsBlank =
        filterType === 'date' ||
        filterType === 'text' ||
        filterType === 'select';
      if (op === 'isBlank' && filterTypeSupportsBlank) {
        acc[filter] = { isBlank: true };
      } else if (op === 'isNotBlank' && filterTypeSupportsBlank) {
        acc[filter] = { isNotBlank: true };
      } else {
        // Ignoring, op is all possible operators and acc[filter] is one distinct type of filter not the union
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[filter][op] = value;
      }

      return acc;
    },
    {} as OrderTableFilterModel,
  );
}

export function isOrderFilterFieldV2(
  field: string,
): field is keyof typeof OrderFilterFieldV2 {
  return Object.keys(OrderFilterFieldV2).includes(field as OrderFilterFieldV2);
}

export function orderTableFilterModelToFilterConstructionType(
  filterModel: OrderTableFilterModel,
): FilterConstructionType[] {
  const filters: FilterConstructionType[] = [];
  for (const [field, currentFilter] of Object.entries(filterModel)) {
    if (!isOrderFilterFieldV2(field) && field !== 'and' && field !== 'or') {
      continue;
    }
    if (isNil(currentFilter)) {
      continue;
    }
    if (field === 'and' || field === 'or') {
      if (!isArray(currentFilter)) {
        continue;
      }
      filters.push({
        [field]: currentFilter.flatMap((f) =>
          orderTableFilterModelToFilterConstructionType(f),
        ),
      });
    } else {
      const filterType = filterToInputType(field);
      for (const op of Object.keys(currentFilter)) {
        const typedOp = op as FilterOperatorTypeV2;
        const preFilteredValue =
          currentFilter[typedOp as keyof typeof currentFilter] ?? '';
        // Temporary measure to convert deprecated eq date operators to eqV2
        if (typedOp === 'eq' && filterType === 'date') {
          const filterDateObject = {
            date: preFilteredValue,
            dateFilterOption: DateFilterOptionV2.Static,
            offsetDays: 0,
          };

          filters.push({
            filter: field as FilterConstructionFilterType,
            op: 'eqV2',
            // FilterOperatorTypeV2 is all possible operators, but not all filters
            // support all possible operators, so we need to cast
            value: filterDateObject,
          });
        } else {
          filters.push({
            filter: field as FilterConstructionFilterType,
            op: typedOp,
            // FilterOperatorTypeV2 is all possible operators, but not all filters
            // support all possible operators, so we need to cast
            value: preFilteredValue,
          });
        }
      }
    }
  }
  return filters;
}
