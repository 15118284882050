import DeleteIcon from '@mui/icons-material/Delete';
import {
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getSettlementEligibleTotal } from 'shared/billing';
import { getPermissionsFlags } from 'shared/roles';
import { isValidPartialOrCompleteNumberInput } from '../../../../../../../../utils';
import useDrivers from '../../../../../../../common/react-hooks/use-drivers';
import useUserRoles from '../../../../../../../common/react-hooks/use-user-roles';
import { BillingMethod } from '../../../../../../../common/types';
import {
  DriverFragment,
  DriverType,
  PermissionResource,
} from '../../../../../../../generated/graphql';
import { StopDriverMapValues } from '../../../forms/types';

const DriverRow = ({
  stopDriverMapUuid,
  stopIdx,
  totalAmountForDriverSettlement,
}: {
  stopDriverMapUuid: string;
  stopIdx: number;
  totalAmountForDriverSettlement: number;
}) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);
  const { control, getValues, setValue } = useFormContext();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { drivers } = useDrivers();

  const stopDriverMaps: StopDriverMapValues[] =
    useWatch({ control, name: `stops.${stopIdx}.stopDriverMaps` }) ?? [];
  const stopDriverMapIdx = stopDriverMaps.findIndex(
    (sdMap) => sdMap.uuid === stopDriverMapUuid,
  );
  const stopDriverMapKey = `stops.${stopIdx}.stopDriverMaps.${stopDriverMapIdx}`;

  const percentageRate = useWatch({
    control,
    name: `${stopDriverMapKey}.revenuePercentageRate`,
  });
  const flatRate = useWatch({
    control,
    name: `${stopDriverMapKey}.revenueFlatRate`,
  });
  const deductionAmount = useWatch({
    control,
    name: `stops.${stopIdx}.settlementTotal`,
  });
  const [rateInput, setRateInput] = useState<string>(
    isNil(flatRate) ? percentageRate?.toFixed(2) : flatRate?.toFixed(2),
  );
  const [billingMethod, setBillingMethod] = useState<BillingMethod>(
    isNil(flatRate) ? BillingMethod.Percentage : BillingMethod.FlatRate,
  );

  const isAttempt: boolean = useWatch({
    control,
    name: `${stopDriverMapKey}.isAttempt`,
  });
  const attemptedAt = useWatch({
    control,
    name: `${stopDriverMapKey}.attemptedAt`,
  });
  const enableDriverSettlement = useWatch({
    control,
    name: `${stopDriverMapKey}.enableDriverSettlement`,
  });

  const driverUuid = useWatch({
    control,
    name: `${stopDriverMapKey}.driverUuid`,
  });
  const [driver, setDriver] = useState<DriverFragment>();

  useEffect(() => {
    if (!isNil(driverUuid)) {
      setDriver(drivers.find((d) => d.uuid === driverUuid));
    }
  }, [driverUuid, drivers]);

  const updateRates = (_billingMethod: BillingMethod, parsedRate?: number) => {
    let rate = parsedRate;
    if (isNil(rate)) {
      rate =
        getValues(`${stopDriverMapKey}.revenuePercentageRate`) ??
        getValues(`${stopDriverMapKey}.revenueFlatRate`);
    }
    if (_billingMethod === BillingMethod.Percentage) {
      setValue(`${stopDriverMapKey}.revenuePercentageRate`, rate);
      setValue(`${stopDriverMapKey}.revenueFlatRate`, null);
    } else {
      setValue(`${stopDriverMapKey}.revenueFlatRate`, rate);
      setValue(`${stopDriverMapKey}.revenuePercentageRate`, null);
    }
  };

  const onDisconnectStopDriverMap = async () => {
    setValue(`${stopDriverMapKey}.enableDriverSettlement`, false);
    setValue(`${stopDriverMapKey}.driverSettlementBillUuid`, null);
  };

  const driverTotal =
    getSettlementEligibleTotal(
      billingMethod === BillingMethod.FlatRate ? flatRate : null,
      billingMethod === BillingMethod.Percentage ? percentageRate : null,
      totalAmountForDriverSettlement,
    ) - (deductionAmount ?? 0);

  const showStopDriverMap =
    ((driver?.driverType === DriverType.OwnerOperator ||
      driver?.driverType === DriverType.Contractor) &&
      enableDriverSettlement !== false) ||
    enableDriverSettlement === true;
  if (stopDriverMapIdx === -1 || !showStopDriverMap) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <TableRow
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <TableCell>
        {driver?.firstName ?? ''} {driver?.lastName ?? ''}
        <p
          style={{
            padding: 0,
            margin: 0,
            color: theme.palette.grey[500],
          }}
        >
          {isAttempt &&
            `Attempted${
              !isNil(attemptedAt)
                ? ` ${dayjs(attemptedAt).format('MM/DD/YY hh:mm a')}`
                : ''
            }`}
        </p>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          <FormControl sx={{ maxWidth: '500px' }}>
            <TextField
              size="small"
              type="number"
              value={rateInput}
              disabled={!canWriteOrders}
              onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
              onChange={(e) => {
                if (isValidPartialOrCompleteNumberInput(e.target.value)) {
                  setRateInput(e.target.value);
                  const parsedRate = parseFloat(e.target.value);
                  if (!Number.isNaN(parsedRate)) {
                    updateRates(billingMethod, parsedRate);
                  }
                }
              }}
              InputProps={
                billingMethod === BillingMethod.Percentage
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }
              }
            />
          </FormControl>
          <FormControl sx={{ minWidth: '140px' }}>
            <Select
              size="small"
              value={billingMethod}
              required
              onChange={(event) => {
                const newBillingMethod = event.target.value as BillingMethod;
                setBillingMethod(newBillingMethod);
                updateRates(newBillingMethod);
              }}
              disabled={!canWriteOrders}
            >
              <MenuItem value={BillingMethod.Percentage}>
                {sentenceCase(BillingMethod.Percentage)}
              </MenuItem>
              <MenuItem value={BillingMethod.FlatRate}>
                {sentenceCase(BillingMethod.FlatRate)}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </TableCell>
      <TableCell>
        {currency(driverTotal).format()}{' '}
        <Fade in={isHovering}>
          <IconButton onClick={onDisconnectStopDriverMap}>
            <DeleteIcon />
          </IconButton>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(DriverRow);
