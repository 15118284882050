import {
  Alert,
  Box,
  Checkbox,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  MeDocument,
  PermissionResource,
  useUpdateCompanyConfigurationMutation,
} from '../../generated/graphql';

const DispatchSettings = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsDispatch } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsDispatch,
  );

  const enableSplitPODPickupConfiguration = useFeatureFlag(
    FeatureFlag.FF_ENABLE_SPLIT_POD_PICKUP_CONFIGURATION,
  );
  const enableSplitPODDeliveryConfiguration = useFeatureFlag(
    FeatureFlag.FF_ENABLE_SPLIT_POD_DELIVERY_CONFIGURATION,
  );

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);
  const [
    updateCompanyConfiguration,
    { loading: updateCompanyConfigurationLoading },
  ] = useUpdateCompanyConfigurationMutation({
    refetchQueries: [MeDocument],
  });

  const { companyConfiguration, refetchMeData } = useMe();

  const handleUpdateDriverAckRouteChange = async ({
    requireDriverToAcknowledgeRouteChange,
  }: {
    requireDriverToAcknowledgeRouteChange: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requireDriverToAcknowledgeRouteChange,
            },
          },
          refetchQueries: [MeDocument],
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleDriverMarkFreightAsLoaded = async ({
    driversShouldMarkFreightAsLoaded,
  }: {
    driversShouldMarkFreightAsLoaded: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              driversShouldMarkFreightAsLoaded,
            },
          },
          refetchQueries: [MeDocument],
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleRequirePODPhotoAndName = async ({
    requirePODPhotoAndName,
  }: {
    requirePODPhotoAndName: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requirePODPhotoAndName,
            },
          },
          refetchQueries: [MeDocument],
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleUpdateDriverLocationDataFromMobile = async ({
    getDriverLocationDataFromMobile,
  }: {
    getDriverLocationDataFromMobile: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              getDriverLocationDataFromMobile,
            },
          },
          refetchQueries: [MeDocument],
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleRequirePODPhotoAndNameForPickups = async ({
    requirePODPhotoAndNameForPickups,
  }: {
    requirePODPhotoAndNameForPickups: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requirePODPhotoAndNameForPickups,
            },
          },
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleRequirePODPhotoForPickups = async ({
    requirePODPhotoForPickups,
  }: {
    requirePODPhotoForPickups: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requirePODPhotoForPickups,
            },
          },
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleRequireSigneeNameForPickups = async ({
    requireSigneeNameForPickups,
  }: {
    requireSigneeNameForPickups: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requireSigneeNameForPickups,
            },
          },
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleRequireSigneeNameForDeliveries = async ({
    requireSigneeNameForDeliveries,
  }: {
    requireSigneeNameForDeliveries: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requireSigneeNameForDeliveries,
            },
          },
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleRequirePODPhotoForDeliveries = async ({
    requirePODPhotoForDeliveries,
  }: {
    requirePODPhotoForDeliveries: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              requirePODPhotoForDeliveries,
            },
          },
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  const handleAutoGroupStops = async ({
    autoGroupStops,
  }: {
    autoGroupStops: boolean | undefined;
  }) => {
    try {
      const configUuid = companyConfiguration?.uuid;
      if (!isNil(configUuid)) {
        await updateCompanyConfiguration({
          variables: {
            updateCompanyConfigurationInput: {
              uuid: configUuid,
              autoGroupStops,
            },
          },
        });
        refetchMeData();
      }
    } catch (e) {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => setSuccessSnackbarVisible(false)}
        >
          Saved settings
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
      >
        <Alert severity="error" onClose={() => setErrorSnackbarVisible(false)}>
          Error saving settings
        </Alert>
      </Snackbar>
      <Stack direction="column" spacing={1}>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h6">Dispatch settings</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{ fontSize: '12px', color: 'gray', float: 'right' }}
            >
              {updateCompanyConfigurationLoading ? 'Saving...' : 'Saved'}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <>
          <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={
                  companyConfiguration?.requireDriverToAcknowledgeRouteChange ??
                  false
                }
                onChange={(e) => {
                  handleUpdateDriverAckRouteChange({
                    requireDriverToAcknowledgeRouteChange: e.target.checked,
                  });
                }}
                disabled={
                  updateCompanyConfigurationLoading || !canWriteSettingsDispatch
                }
              />
              <Typography>
                Require drivers to acknowledge route changes
              </Typography>
            </Box>
          </Grid>
          <Divider />
        </>
        <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={
                companyConfiguration?.getDriverLocationDataFromMobile ?? false
              }
              onChange={(e) => {
                handleUpdateDriverLocationDataFromMobile({
                  getDriverLocationDataFromMobile: e.target.checked,
                });
              }}
              disabled={
                updateCompanyConfigurationLoading || !canWriteSettingsDispatch
              }
            />
            <Typography>
              Allow tracking driver locations from mobile phone
            </Typography>
          </Box>
        </Grid>
        <Divider />
        <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={
                companyConfiguration?.driversShouldMarkFreightAsLoaded ?? false
              }
              onChange={(e) => {
                handleDriverMarkFreightAsLoaded({
                  driversShouldMarkFreightAsLoaded: e.target.checked,
                });
              }}
              disabled={
                updateCompanyConfigurationLoading || !canWriteSettingsDispatch
              }
            />
            <Typography>
              Require drivers to mark on-hand freight as loaded
            </Typography>
          </Box>
        </Grid>
        <Divider />
        {!enableSplitPODDeliveryConfiguration && (
          <>
            <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={
                    companyConfiguration?.requirePODPhotoAndName ?? false
                  }
                  onChange={(e) => {
                    handleRequirePODPhotoAndName({
                      requirePODPhotoAndName: e.target.checked,
                    });
                  }}
                  disabled={
                    updateCompanyConfigurationLoading ||
                    !canWriteSettingsDispatch
                  }
                />
                <Typography>
                  Require POD{' '}
                  {companyConfiguration?.enableDigitalSignatureFlow === true
                    ? '(photo or e-sig)'
                    : 'photo'}{' '}
                  and signee name to complete delivery stops
                </Typography>
              </Box>
            </Grid>
            <Divider />
          </>
        )}
        {!enableSplitPODPickupConfiguration && (
          <>
            <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={
                    companyConfiguration?.requirePODPhotoAndNameForPickups ??
                    false
                  }
                  onChange={(e) => {
                    handleRequirePODPhotoAndNameForPickups({
                      requirePODPhotoAndNameForPickups: e.target.checked,
                    });
                  }}
                  disabled={
                    updateCompanyConfigurationLoading ||
                    !canWriteSettingsDispatch
                  }
                />
                <Typography>
                  Require POD{' '}
                  {companyConfiguration?.enableDigitalSignatureFlow === true
                    ? '(photo or e-sig)'
                    : 'photo'}{' '}
                  and signee name to complete pickup stops
                </Typography>
              </Box>
            </Grid>
            <Divider />
          </>
        )}
        {enableSplitPODPickupConfiguration && (
          <>
            <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={
                    companyConfiguration?.requirePODPhotoForPickups ?? false
                  }
                  onChange={(e) => {
                    handleRequirePODPhotoForPickups({
                      requirePODPhotoForPickups: e.target.checked,
                    });
                  }}
                  disabled={
                    updateCompanyConfigurationLoading ||
                    !canWriteSettingsDispatch
                  }
                />
                <Typography>
                  Require POD{' '}
                  {companyConfiguration?.enableDigitalSignatureFlow === true
                    ? '(photo or e-sig)'
                    : 'photo'}{' '}
                  to complete pickup stops
                </Typography>
              </Box>
            </Grid>
            <Divider />
            <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={
                    companyConfiguration?.requireSigneeNameForPickups ?? false
                  }
                  onChange={(e) => {
                    handleRequireSigneeNameForPickups({
                      requireSigneeNameForPickups: e.target.checked,
                    });
                  }}
                  disabled={
                    updateCompanyConfigurationLoading ||
                    !canWriteSettingsDispatch
                  }
                />
                <Typography>
                  Require POD signee name to complete pickup stops
                </Typography>
              </Box>
            </Grid>
            <Divider />
          </>
        )}
        {enableSplitPODDeliveryConfiguration && (
          <>
            <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={
                    companyConfiguration?.requirePODPhotoForDeliveries ?? false
                  }
                  onChange={(e) => {
                    handleRequirePODPhotoForDeliveries({
                      requirePODPhotoForDeliveries: e.target.checked,
                    });
                  }}
                  disabled={
                    updateCompanyConfigurationLoading ||
                    !canWriteSettingsDispatch
                  }
                />
                <Typography>
                  Require POD{' '}
                  {companyConfiguration?.enableDigitalSignatureFlow === true
                    ? '(photo or e-sig)'
                    : 'photo'}{' '}
                  to complete delivery stops
                </Typography>
              </Box>
            </Grid>
            <Divider />
            <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={
                    companyConfiguration?.requireSigneeNameForDeliveries ??
                    false
                  }
                  onChange={(e) => {
                    handleRequireSigneeNameForDeliveries({
                      requireSigneeNameForDeliveries: e.target.checked,
                    });
                  }}
                  disabled={
                    updateCompanyConfigurationLoading ||
                    !canWriteSettingsDispatch
                  }
                />
                <Typography>
                  Require POD signee name to complete delivery stops
                </Typography>
              </Box>
            </Grid>
          </>
        )}
        <Grid item md={12} xs={12} sx={{ paddingRight: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={companyConfiguration?.autoGroupStops ?? false}
              onChange={(e) => {
                handleAutoGroupStops({
                  autoGroupStops: e.target.checked,
                });
              }}
              disabled={
                updateCompanyConfigurationLoading || !canWriteSettingsDispatch
              }
            />
            <Typography>
              Automatically group consecutive stops with the same address
            </Typography>
          </Box>
        </Grid>
      </Stack>
    </Box>
  );
};

export default DispatchSettings;
