import { Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { ORDER_TABLE_FIELD_DEFAULT_VALUE } from '../../domains/orders/components/constants';
import { getOrderTableFieldValue } from '../../domains/orders/components/utils';
import {
  FormattedOrderFragment,
  OrderTableField,
  StopType,
} from '../../generated/graphql';
import StopTypeChipNew from './stop-type-chip-new';

const StopTypesForOrdersTableChipsNew = ({
  order,
}: {
  order: FormattedOrderFragment | undefined;
}) => {
  const stopTypes = getOrderTableFieldValue({
    order,
    orderTableField: OrderTableField.StopTypes,
  });

  if (isEmpty(stopTypes) || stopTypes === ORDER_TABLE_FIELD_DEFAULT_VALUE) {
    return <Typography>{ORDER_TABLE_FIELD_DEFAULT_VALUE}</Typography>;
  }
  const stops = stopTypes
    .split(',')
    .map((stopType) => (
      <StopTypeChipNew key={stopType} stopType={stopType as StopType} />
    ));
  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="1px"
    >
      {stops}
    </Stack>
  );
};

export default StopTypesForOrdersTableChipsNew;
