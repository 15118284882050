import { Alert, Snackbar } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { SplitterPanel, Splitter } from 'primereact/splitter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import { ExcludeFromHiding } from '../../orders/components/enums/order-filters';
import LineHaulDispatchManifestsSection from '../components/line-haul-dispatch-manifests-section';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import { SegmentedLineHaulOrder } from '../types';
import COLUMNS from './demo-grid-columns';
import { DemoLineHaulDispatchTableAgGrid } from './demo-line-haul-dispatch-table-ag-grid';
import { canAddOrderToManifest } from './demo-utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DemoLineHaulView = () => {
  const { lineHaulTableFields } = useTableFields();
  const gridRef = useRef<AgGridReact<SegmentedLineHaulOrder>>(null);
  const [selectedUuids, setSelectedUuids] = useState<string[]>([]);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] =
    useState(false);

  const [
    currentManifestTab,
    openedManifest,
    snackbarSuccessMessage,
    setSnackbarSuccessMessage,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.currentManifestTab,
      state.openedManifest,
      state.snackbarSuccessMessage,
      state.setSnackbarSuccessMessage,
    ],
    shallow,
  );

  useEffect(() => {
    gridRef.current?.columnApi?.setColumnVisible(
      ExcludeFromHiding.EMPTY_SPACE,
      canAddOrderToManifest(openedManifest, currentManifestTab),
    );
  }, [currentManifestTab, openedManifest]);

  const columnsOrderedByConfig: ColDef<SegmentedLineHaulOrder>[] =
    useMemo(() => {
      let orderedColumns: ColDef<SegmentedLineHaulOrder>[];

      if (isNil(lineHaulTableFields)) {
        orderedColumns = COLUMNS;
      } else {
        orderedColumns = ['segment.startTerminal.code', ...lineHaulTableFields]
          .map((header) => COLUMNS.find((column) => column.field === header))
          .filter(
            (column) => !isNil(column),
          ) as ColDef<SegmentedLineHaulOrder>[];
      }

      // If we add filters, remember to add them here
      return [
        ...orderedColumns,
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          maxWidth: 50,
          field: ExcludeFromHiding.EMPTY_SPACE,
          headerName: '',
          pinned: 'left' as const,
        },
      ];
    }, [lineHaulTableFields]);

  return (
    <>
      <Splitter
        stateStorage="local"
        stateKey="line-haul-splitter-state"
        style={{ height: '100%', border: 'none' }}
      >
        <SplitterPanel style={{ height: '100%' }}>
          <DemoLineHaulDispatchTableAgGrid
            tableHeaders={lineHaulTableFields}
            columnDefinitions={columnsOrderedByConfig}
            selectedUuids={selectedUuids}
            setSelectedUuids={setSelectedUuids}
            isHeaderCheckboxSelected={isHeaderCheckboxSelected}
            setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
            gridRef={gridRef}
          />
        </SplitterPanel>
        <SplitterPanel>
          <LineHaulDispatchManifestsSection />
        </SplitterPanel>
      </Splitter>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => setSnackbarSuccessMessage(undefined)}
        open={!isNil(snackbarSuccessMessage)}
      >
        <Alert>{snackbarSuccessMessage}</Alert>
      </Snackbar>
    </>
  );
};
