import { Chip } from '@mui/material';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { InvoiceSendJobStatus } from '../../../../../generated/graphql';

const InvoiceJobStatusChip = ({ status }: { status: InvoiceSendJobStatus }) => {
  switch (status) {
    case InvoiceSendJobStatus.Failed:
      return <Chip size="small" label="Failed" color="error" />;
    case InvoiceSendJobStatus.InProgress:
      return <Chip size="small" label="In Progress" color="info" />;
    case InvoiceSendJobStatus.Success:
      return <Chip size="small" label="Success" color="success" />;
    default:
      exhaustive(status);
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default InvoiceJobStatusChip;
