import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Menu, Typography, Stack } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FilterConstructionType,
  FilterGroupOperator,
  GroupFilterConstructionType,
  SingleFilterConstructionType,
} from '../../../../../common/filters/types';
import {
  filterToInputType,
  filterValueInputToDisplayName,
  getFilterGroupOperator,
  getFilterNameLabel,
  getFilterOperationsByType,
  filterEmptyFilterConstructionTypes,
  isGroupFilterConstructionType,
} from '../../../../../common/filters/utils';
import { isNilOrEmptyString } from '../../../../../common/utils/utils';
import {
  TableConfigPillText,
  TableConfigPillIcon,
  TableConfigPillIconButton,
} from '../../../table-configuration-pills';
import { OrderTableFilterModel } from '../../types';
import { EditOrderTableFilters } from './edit-order-table-filters';
import { useFilterConstructionTypes } from './use-filter-construction-types';
import useFilterOptions from './use-filter-options';

interface FilterPillProps {
  filter: SingleFilterConstructionType;
  filterGroupOperator: FilterGroupOperator;
  roundLeft: boolean;
  showSeparator: boolean;
  wrap: boolean;
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const FilterPill = ({
  filter: filterConstructionType,
  filterGroupOperator,
  roundLeft,
  showSeparator,
  wrap,
  handleClick,
}: FilterPillProps) => {
  const {
    filter: filterName,
    op: filterOperation,
    value: filterValue,
  } = filterConstructionType;
  const options = useFilterOptions(filterName);
  const filterDisplayName = !isNilOrEmptyString(filterName)
    ? getFilterNameLabel(filterName)
    : null;
  const filterOperationName =
    !isNilOrEmptyString(filterOperation) && !isNilOrEmptyString(filterName)
      ? getFilterOperationsByType(filterToInputType(filterName))[
          // filterOperation is all possible operators and getFilterOperationsByType
          // returns one distinct type of filter operations (not the union)
          filterOperation as keyof ReturnType<typeof getFilterOperationsByType>
        ]
      : null;
  const formattedFilterValue =
    filterOperation !== 'isBlank' && filterOperation !== 'isNotBlank'
      ? filterValueInputToDisplayName(filterValue, filterName, options)
      : null;
  return (
    <Stack direction="row" alignItems="center">
      <TableConfigPillText
        onClick={handleClick}
        roundLeft={roundLeft}
        roundRight={!showSeparator}
        sx={{ maxWidth: wrap ? '50vw' : '25vw' }}
      >
        {filterDisplayName} {filterOperationName}{' '}
        <Typography component="span" fontWeight="bold" fontSize="inherit">
          {formattedFilterValue}
        </Typography>
      </TableConfigPillText>
      {showSeparator && (
        <TableConfigPillIcon color="secondary">
          {filterGroupOperator === FilterGroupOperator.AND ? '&' : 'or'}
        </TableConfigPillIcon>
      )}
    </Stack>
  );
};

const useFilterGroupsForPills = ({
  currentFilters,
}: {
  currentFilters: FilterConstructionType[];
}): {
  topLevelOperator: FilterGroupOperator;
  filterGroups: GroupFilterConstructionType[];
} => {
  const { topLevelOperator, filterGroups } = useMemo(() => {
    // There should be one top-level filter group; if there isn't, we'll make one
    const topLevelGroup: GroupFilterConstructionType = currentFilters.find(
      isGroupFilterConstructionType,
    ) ?? {
      and: currentFilters,
    };
    const operator = getFilterGroupOperator(topLevelGroup);

    // If there are any orphaned (non-group) filters, create a group for them
    const groups =
      topLevelGroup[operator]?.map((f) =>
        isGroupFilterConstructionType(f) ? f : { and: [f] },
      ) ?? [];

    return {
      topLevelOperator: operator,
      filterGroups: filterEmptyFilterConstructionTypes(groups).filter(
        isGroupFilterConstructionType,
      ),
    };
  }, [currentFilters]);

  return {
    topLevelOperator,
    filterGroups,
  };
};

interface FilterPillsProps {
  wrap: boolean;
  filterModelV2: OrderTableFilterModel;
  setFilterModelV2: (filterModel: OrderTableFilterModel) => void;
}

const FilterPills = ({
  wrap,
  filterModelV2,
  setFilterModelV2,
}: FilterPillsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    internalFilters,
    setInternalFilters,
    currentFilters,
    resetInternalFilters,
    onApplyFilter,
  } = useFilterConstructionTypes({ filterModelV2, setFilterModelV2 });

  const { topLevelOperator, filterGroups } = useFilterGroupsForPills({
    currentFilters,
  });

  useEffect(() => {
    if (isNil(containerRef.current) || isNil(anchorEl)) {
      return;
    }
    // Fall back to the container if we removed the filter that we
    // were previously anchoring to
    if (!containerRef.current.contains(anchorEl)) {
      setAnchorEl(containerRef.current);
    }
  }, [internalFilters, anchorEl]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    resetInternalFilters();
  };

  const isFilterGroupsEmpty = filterGroups.every(
    (group) => isEmpty(group.and) && isEmpty(group.or),
  );

  return (
    <>
      <Stack
        ref={containerRef}
        direction="row"
        alignItems="start"
        columnGap={0.5}
      >
        <TableConfigPillIcon roundLeft roundRight>
          <FilterListIcon />
        </TableConfigPillIcon>
        <Stack
          direction="row"
          flexWrap={wrap ? 'wrap' : 'nowrap'}
          rowGap={1}
          columnGap={0.5}
        >
          {filterGroups.map((filterGroup, filterGroupIndex) => {
            const operator = getFilterGroupOperator(filterGroup);
            const filters = filterGroup[operator] ?? [];
            return (
              <>
                <Stack
                  direction="row"
                  flexWrap={wrap ? 'wrap' : 'nowrap'}
                  rowGap={1}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${operator}-${filterGroupIndex}`}
                >
                  {filters
                    .filter(
                      // Eventually, we'll support more than just 1 layer of nesting
                      (f): f is SingleFilterConstructionType =>
                        !isGroupFilterConstructionType(f),
                    )
                    .map((filter, filterIndex) => (
                      <FilterPill
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${filter.filter}-${filter.op}-${filterIndex}`}
                        wrap={wrap}
                        filter={filter}
                        filterGroupOperator={operator}
                        roundLeft={filterIndex === 0}
                        showSeparator={filterIndex < filters.length - 1}
                        handleClick={handleClick}
                      />
                    ))}
                </Stack>
                {filterGroupIndex < filterGroups.length - 1 && (
                  <TableConfigPillIcon roundLeft roundRight>
                    {topLevelOperator === FilterGroupOperator.AND ? '&' : 'or'}
                  </TableConfigPillIcon>
                )}
              </>
            );
          })}
          {isFilterGroupsEmpty && (
            <FilterPill
              filter={{ filter: 'CUSTOMER_NAME', op: 'eq', value: 'Any' }}
              filterGroupOperator={FilterGroupOperator.AND}
              roundLeft
              wrap={wrap}
              showSeparator={false}
              handleClick={handleClick}
            />
          )}
          <TableConfigPillIconButton onClick={handleClick}>
            <AddIcon />
          </TableConfigPillIconButton>
        </Stack>
      </Stack>
      <Menu
        open={!isNil(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{ sx: { py: 0 } }}
      >
        <EditOrderTableFilters
          tempFilter={internalFilters}
          setTempFilter={setInternalFilters}
          onClose={handleClose}
          onSave={onApplyFilter}
        />
      </Menu>
    </>
  );
};

export default React.memo(FilterPills);
