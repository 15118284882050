import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { safeDivide } from 'shared/math';
import { shallow } from 'zustand/shallow';
import {
  FUEL_CHARGE_NEW_RATE_AUTO_APPLIED_MESSAGE_TEST_ID,
  FUEL_CHARGE_TOTAL_TEST_ID,
} from '../../../../../../../../../constants';
import { getStopFuelChargeTestIds } from '../../../../../../../../../utils';
import {
  FuelBillingMethod,
  FuelChargeFuelProfileDateRangeFragment,
  FuelProfileDateRangeFragment,
  TariffFragment,
  useFuelChargeFuelProfileDateRangeLazyQuery,
  useFuelProfilesQuery,
  useTariffLazyQuery,
} from '../../../../../../../../generated/graphql';
import useOrderFormStore from '../../../../../../order-form-store';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';
import { OrderFormFieldValues } from '../../../../types';
import { getInboundStop, getOutboundStop } from '../../../../utils';
import { buildNewFuelProfileAppliedMessage } from '../../utils';
import AuthoCodeComponent, {
  MT_IF_HAS_AUTHO_CODE_STYLES,
} from '../autho-code-component';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from '../description-component';
import FuelChargeRateInput from './fuel-charge-rate-input';

export type FuelChargeRowProps = {
  idx: number;
};

const FuelChargeRow = ({ idx }: FuelChargeRowProps) => {
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.has('orderUuid');
  const theme = useTheme();

  const [isOrderPageRating] = useOrderFormStore(
    (state) => [state.isOrderPageRating],
    shallow,
  );

  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const [getTariffByUuid] = useTariffLazyQuery();

  const detailedStatus = useWatch({ control, name: 'detailedStatus' });

  const { shouldRateOrder, chargesFinalized } = useShouldRateOrder({
    detailedStatus,
  });

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const [tariff, setTariff] = useState<TariffFragment | undefined>(undefined);
  const fuelChargeKey = `stops.${idx}.freightCharge.fuelCharge`;
  const fuelCharge = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge`,
  });
  const fuelChargeUuid = fuelCharge?.uuid;
  const billingMethod = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.billingMethod`,
  });
  const setFuelBillingMethod = (newBillingMethod: FuelBillingMethod) =>
    setValue(
      `stops.${idx}.freightCharge.fuelCharge.billingMethod`,
      newBillingMethod,
    );

  const totalCharge = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.totalCharge`,
  });

  const contactUuid = useWatch({ control, name: 'contactUuid' });

  const stopValues = useWatch({ control, name: 'stops' }) ?? [];
  const inboundStop = getInboundStop(stopValues);
  const outboundStop = getOutboundStop(stopValues);

  const stopType = useWatch({ control, name: `stops.${idx}.stopType` });
  const serviceDate = useWatch({ control, name: `stops.${idx}.serviceDate` });
  const deliveryDate = useWatch({ control, name: `stops.${idx}.deliveryDate` });
  const completedAt = useWatch({ control, name: `stops.${idx}.completedAt` });
  const tariffUuid = useWatch({
    control,
    name: `stops.${idx}.freightCharge.tariffUuid`,
  });

  const [getFuelChargeFuelProfileDateRange] =
    useFuelChargeFuelProfileDateRangeLazyQuery();

  const surchargeRateDollars = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.surchargeRate`,
  });
  const flatRateDollars = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.flatRateDollars`,
  });

  const savedSurchargeRate = useRef(surchargeRateDollars);

  const [surchargeRateInput, setSurchargeRateInput] = useState(
    surchargeRateDollars?.toString() ?? '',
  );
  const [flatRateInput, setFlatRateInput] = useState(
    flatRateDollars?.toString() ?? '',
  );

  // Set the surcharge rate upon any order change in the billing review modal
  useEffect(() => {
    if (!isNil(fuelChargeUuid)) {
      setSurchargeRateInput(surchargeRateDollars?.toString() ?? '');
      setFlatRateInput(flatRateDollars?.toString() ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuelChargeUuid]);

  const fetchTariff = async () => {
    if (!isNil(tariffUuid) && !isEmpty(tariffUuid)) {
      const res = await getTariffByUuid({
        variables: {
          uuid: tariffUuid,
        },
      });
      setTariff(res.data?.tariff);
    }
  };

  useEffect(() => {
    fetchTariff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffUuid]);

  const [fuelProfileDateRangeToUse, setFuelProfileDateRangeToUse] = useState<
    | FuelProfileDateRangeFragment
    | FuelChargeFuelProfileDateRangeFragment
    | undefined
  >();
  const { data: fuelProfileData } = useFuelProfilesQuery({
    fetchPolicy: 'cache-first',
  });
  const authoCode = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.authoCode`,
  });

  const fetchFuelProfileDateRange = async () => {
    const response = await getFuelChargeFuelProfileDateRange({
      variables: {
        fuelChargeFuelProfileDateRangeInput: {
          contactUuid,
          serviceDate,
          appointmentDate: deliveryDate,
          stopCompletedDate: completedAt,
          tariffGroupFuelProfileUuid: tariff?.tariffGroup?.fuelProfile?.uuid,
        },
      },
    });

    const fuelProfileDateRange =
      response?.data?.fuelChargeFuelProfileDateRange?.fuelProfileDateRange;

    if (!isNil(fuelProfileDateRange)) {
      setFuelProfileDateRangeToUse(fuelProfileDateRange);
    }

    return fuelProfileDateRange;
  };

  const onChangeBillingMethod = (event: SelectChangeEvent<string>) => {
    setFuelBillingMethod(event.target.value as FuelBillingMethod);
  };

  useEffect(() => {
    if (!isEmpty(fuelProfileData?.fuelProfiles)) {
      setFuelBillingMethod(FuelBillingMethod.AutoCalculate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //  If the charges are finalized and/or the order is on an unposted invoice, we do not want to reselect a fuel profile.
    const shouldSelectFuelProfile = shouldRateOrder && !chargesFinalized;
    if (
      billingMethod === FuelBillingMethod.AutoCalculate &&
      shouldSelectFuelProfile
    ) {
      fetchFuelProfileDateRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billingMethod,
    stopType,
    serviceDate,
    deliveryDate,
    completedAt,
    tariff,
    contactUuid,
    shouldRateOrder,
    chargesFinalized,
  ]);

  useEffect(() => {
    const shouldSelectFuelProfile = shouldRateOrder && !chargesFinalized;
    if (
      billingMethod === FuelBillingMethod.AutoCalculate &&
      shouldSelectFuelProfile
    ) {
      const surchargeRate = fuelProfileDateRangeToUse?.surchargeRate;
      const flatRate = fuelProfileDateRangeToUse?.flatRateUsdCents;
      setValue(
        `stops.${idx}.freightCharge.fuelCharge.billingMethod`,
        FuelBillingMethod.AutoCalculate,
      );
      const newSurchargeRate = surchargeRate ?? 0;
      setValue(
        `stops.${idx}.freightCharge.fuelCharge.surchargeRate`,
        newSurchargeRate,
      );
      setSurchargeRateInput(String(newSurchargeRate));
      setValue(
        `stops.${idx}.freightCharge.fuelCharge.flatRateDollars`,
        !isNil(flatRate) ? safeDivide(flatRate, 100) : null,
      );
      setFlatRateInput(
        !isNil(flatRate) ? String(safeDivide(flatRate, 100)) : '',
      );
    } else if (!isNil(billingMethod)) {
      setFuelBillingMethod(billingMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fuelProfileDateRangeToUse?.uuid,
    stopType,
    billingMethod,
    shouldRateOrder,
    inboundStop?.status,
    outboundStop?.status,
  ]);

  if (isNil(fuelCharge)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const { fuelChargeTypeSelectTestId, fuelChargeRateInputTestId } =
    getStopFuelChargeTestIds({
      stopIdx: idx,
    });

  return (
    <TableRow>
      <TableCell>
        <FormControl sx={MT_IF_HAS_DESCRIPTION_STYLES}>
          <InputLabel>Fuel</InputLabel>
          <Select
            data-testid={fuelChargeTypeSelectTestId}
            inputProps={{
              'aria-label': 'Fuel charge select',
            }}
            size="small"
            value={billingMethod}
            required
            sx={{ width: '200px' }}
            onChange={onChangeBillingMethod}
            label="Fuel Type"
            disabled={disabledIfFinalizedOrLater}
          >
            {Object.values(FuelBillingMethod).map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DescriptionComponent
          keyString={fuelChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
      <TableCell>
        <Stack direction="column">
          <FuelChargeRateInput
            billingMethod={billingMethod}
            surchargeRateInput={surchargeRateInput}
            setSurchargeRateInput={setSurchargeRateInput}
            flatRateInput={flatRateInput}
            setFlatRateInput={setFlatRateInput}
            disabled={disabledIfFinalizedOrLater}
            setSurchargeRateInForm={(rate: number) => {
              setValue(
                `stops.${idx}.freightCharge.fuelCharge.surchargeRate`,
                rate,
              );
            }}
            setFlatRateDollarsInForm={(rate: number) => {
              setValue(
                `stops.${idx}.freightCharge.fuelCharge.flatRateDollars`,
                rate,
              );
            }}
            fuelChargeRateInputTestId={fuelChargeRateInputTestId}
          />
          {isEditMode &&
            isOrderPageRating === false &&
            !isNil(savedSurchargeRate.current) &&
            !isNil(surchargeRateInput) &&
            savedSurchargeRate.current !== parseFloat(surchargeRateInput) && (
              <Typography
                data-test-id={FUEL_CHARGE_NEW_RATE_AUTO_APPLIED_MESSAGE_TEST_ID}
                sx={{ color: theme.palette.primary.main, fontSize: '14px' }}
              >
                {buildNewFuelProfileAppliedMessage({
                  originalSurchargeRate: !isNil(savedSurchargeRate?.current)
                    ? savedSurchargeRate.current.toString()
                    : '-',
                })}
              </Typography>
            )}
        </Stack>
      </TableCell>
      <TableCell>1</TableCell>
      <TableCell colSpan={2}>
        <Typography
          sx={MT_IF_HAS_AUTHO_CODE_STYLES}
          data-testid={FUEL_CHARGE_TOTAL_TEST_ID}
        >
          {!isNil(totalCharge) ? currency(totalCharge).format() : '-'}
        </Typography>
        <AuthoCodeComponent
          authoCode={authoCode ?? ''}
          keyString={fuelChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
    </TableRow>
  );
};

export default FuelChargeRow;
