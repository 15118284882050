import { ToggleButtonGroup, ToggleButton } from '@mui/material';

enum LengthUnit {
  Inches = 'in',
  Centimeters = 'cm',
}

// beacuse there will never be a third option, we can use a boolean instead of an enum to simplify use
interface LengthUnitSelectorProps {
  useCentimeters: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const LengthUnitSelector = ({
  useCentimeters,
  onChange,
  disabled,
}: LengthUnitSelectorProps) => {
  return (
    <ToggleButtonGroup
      sx={{ p: 0.5 }}
      color="primary"
      value={useCentimeters ? LengthUnit.Centimeters : LengthUnit.Inches}
      exclusive
      onChange={(_, val) => {
        // setValue('value', val === MeasurementUnit.Centimeters);
        onChange(val === LengthUnit.Centimeters);
      }}
      disabled={disabled}
    >
      <ToggleButton value={LengthUnit.Inches} sx={{ p: 0.5 }}>
        In
      </ToggleButton>
      <ToggleButton value={LengthUnit.Centimeters} sx={{ p: 0.5 }}>
        Cm
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LengthUnitSelector;
