import { Alert, Box, Snackbar } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import { FilterViewPage } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { OrdersTableWithFiltersAgGrid } from '../../ag-grid/orders/orders-table-with-filters-ag-grid';
import {
  useViewAllOrdersPageFilterTabsConfigs,
  VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS,
} from '../constants';
import { useOrdersTableColumns } from './order-form/hooks/use-orders-table-columns';
import { OrdersTab } from './types';

const ORDER_PAGE_SIZE = 25;

export const ViewAllOrdersPage = () => {
  const { orderTableFields, loading: orderTableFieldsLoading } =
    useTableFields();
  const { userUuid } = useMe();
  const [
    setShowBilingPartyDialog,
    setShowUploadPdfsDialog,
    setShowUploadCsvsDialog,
    shouldRefreshOrdersTableData,
    setShouldRefreshOrdersTableData,
  ] = useGlobalStore(
    (state) => [
      state.setShowBilingPartyDialog,
      state.setShowUploadPdfsDialog,
      state.setShowUploadCsvsDialog,
      state.shouldRefreshOrdersTableData,
      state.setShouldRefreshOrdersTableData,
    ],
    shallow,
  );

  const [successMessage, setSuccessMessage] = useState('');

  const orderTableHeaders = useMemo(() => {
    return !isNil(orderTableFields) && !isEmpty(orderTableFields)
      ? orderTableFields
      : VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS;
  }, [orderTableFields]);

  const newOrdersQueryColumnDefs = useOrdersTableColumns({
    setSuccessMessage,
    pageType: FilterViewPage.Orders,
  });

  const disableOrdersPageCount = useFeatureFlag(
    FeatureFlag.FF_DISABLE_ORDERS_PAGE_COUNT,
  );
  const enableNumberOfOrdersQuery = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NUMBERS_ON_ORDER_PAGE_QUERIES,
  );

  const viewAllOrdersPageFilterTabsConfigs =
    useViewAllOrdersPageFilterTabsConfigs();

  if (orderTableFieldsLoading === true) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {!isEmpty(successMessage) && (
        <Snackbar
          autoHideDuration={3000}
          onClose={() => setSuccessMessage('')}
          open={!isEmpty(successMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert>{successMessage}</Alert>
        </Snackbar>
      )}
      <Box height="100%">
        <OrdersTableWithFiltersAgGrid<OrdersTab>
          bulkActionsEnabled
          shouldShowCustomerFilterMultiselect
          shouldShowTerminalFilter
          shouldShowNoTerminalOptionMultiselect
          columnDefinitions={newOrdersQueryColumnDefs}
          showTotalCount={!disableOrdersPageCount}
          isRowsPerPageSelectionEnabled
          userUuid={userUuid}
          pageSize={ORDER_PAGE_SIZE}
          pageType={FilterViewPage.Orders}
          defaultFilterTabsConfigs={viewAllOrdersPageFilterTabsConfigs}
          openUploadPdfsModal={() => {
            setShowUploadPdfsDialog(true);
          }}
          openUploadCsvsModal={() => {
            setShowUploadCsvsDialog(true);
          }}
          openBillingPartyModal={() => {
            setShowBilingPartyDialog(true);
          }}
          shouldShowGenerateReportButtons
          shouldAllowSavedViews
          shouldRememberFilters
          shouldRefreshGrid={shouldRefreshOrdersTableData}
          setShouldRefreshGrid={setShouldRefreshOrdersTableData}
          userOrderTableFields={orderTableHeaders}
          terminalFilterCacheId="ORDERS_PAGE_TERMINAL"
          enableNumberOfOrdersQuery={enableNumberOfOrdersQuery}
          enableHeaderCheckboxSelection={false}
          shouldShowAddOrderButton
        />
      </Box>
    </Box>
  );
};
