import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { usePaperworkCompleteCopy } from '../../../../common/react-hooks/use-paperwork-complete-copy';
import { FormattedOrderFragment } from '../../../../generated/graphql';

const PaperworkCompleteCellRenderer = (
  props: ICellRendererParams<FormattedOrderFragment>,
) => {
  const { data: params } = props;
  const paperwork = params?.paperworkField?.paperwork;

  const paperworkComplete = paperwork?.paperworkComplete ?? false;
  const paperworkMarkedCompleteOverride =
    paperwork?.paperworkMarkedCompleteOverride ?? null;
  const attachedDocuments = paperwork?.attachedDocuments ?? [];
  const missingDocuments = paperwork?.missingDocuments ?? [];

  const title = usePaperworkCompleteCopy({
    paperworkComplete,
    paperworkMarkedCompleteOverride,
    attachedDocuments,
    missingDocuments,
  });

  return (
    <Tooltip title={title}>
      {paperworkComplete ? (
        <CheckIcon color="success" />
      ) : (
        <CloseIcon color="error" />
      )}
    </Tooltip>
  );
};

export { PaperworkCompleteCellRenderer };
