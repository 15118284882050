/* eslint-disable no-param-reassign */
import { ApolloQueryResult } from '@apollo/client';
import { isEmpty, isNil } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import apolloClient from '../../../../apollo-client';
import { Option } from '../../../../common/filters/types';
import {
  IngestedEmailOrderByOrderUuidQuery,
  IngestedEmailOrdersDocument,
  IngestedEmailOrdersQuery,
  IngestedEmailOrdersQueryVariables,
  useIngestedEmailOrderByOrderUuidLazyQuery,
} from '../../../../generated/graphql';
import globalStore from '../../../../layouts/dashboard/global-store';

const DEFAULT_PAGE_SIZE = 10;

interface FetchIngestedEmailOrdersParams {
  first?: number | null | undefined;
  after?: string | null | undefined;
  last?: number | null | undefined;
  before?: string | null | undefined;
}

type IngestedEmailsTableState = {
  page: number;
  currentCursorParams?: {
    first: number;
    after?: string | null | undefined;
    endCursor?: string | null | undefined;
  };
  rowsPerPage: number;
};

type InboundMessagesState = {
  selectedOrderUuid: string | undefined;
  customerOption: Option | undefined;
  originTerminalOption: Option | undefined;
  destinationTerminalOption: Option | undefined;
  searchText: string;
  ingestedEmailOrdersData: IngestedEmailOrdersQuery | undefined;
  ingestedEmailOrdersLoading: boolean;
  ingestedEmailsTableState: IngestedEmailsTableState;
  showReportADocScanIssueDialog: boolean;
};

type InboundMessagesActions = {
  setSelectedOrderUuid: (uuid: string | undefined) => void;
  setCustomerOption: (option: Option | undefined) => void;
  setOriginTerminalOption: (option: Option | undefined) => void;
  setDestinationTerminalOption: (option: Option | undefined) => void;
  setSearchText: (text: string) => void;
  //   handleSubmitSearch: () => void;
  fetchIngestedEmailOrders: (
    params: FetchIngestedEmailOrdersParams,
  ) => Promise<void>;
  updateSelectedOrderAfterReview: (
    onSetOrderUuid: (uuid: string | undefined) => void,
  ) => Promise<void>;
  nextPage: () => void;
  prevPage: () => void;
  refresh: () => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setShowReportADocScanIssueDialog: (show: boolean) => void;
};

const isOrderPresentOnCurrentPage = (
  orderUuid: string,
  ingestedEmailOrdersData: IngestedEmailOrdersQuery,
) => {
  const orderIndex =
    ingestedEmailOrdersData.scannedOrderResults.edges.findIndex(
      ({ node }) => node.order?.uuid === orderUuid,
    );
  return orderIndex !== -1;
};

const useIngestedEmailsStore = create(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  immer<InboundMessagesState & InboundMessagesActions>((set, get) => ({
    selectedOrderUuid: undefined,
    customerOption: undefined,
    originTerminalOption: undefined,
    destinationTerminalOption: undefined,
    searchText: '',
    ingestedEmailOrdersData: undefined,
    ingestedEmailOrdersLoading: false,
    ingestedEmailsTableState: {
      page: 0,
      rowsPerPage: DEFAULT_PAGE_SIZE,
    },
    showReportADocScanIssueDialog: false,
    setSelectedOrderUuid: (uuid) => {
      set((state) => {
        state.selectedOrderUuid = uuid;
        if (
          !isNil(uuid) &&
          !isNil(state.ingestedEmailOrdersData) &&
          !isOrderPresentOnCurrentPage(uuid, state.ingestedEmailOrdersData)
        ) {
          state.fetchIngestedEmailOrders({
            first: state.ingestedEmailsTableState.rowsPerPage,
          });
        }
      });
    },
    setCustomerOption: (option) => {
      set((state) => {
        state.customerOption = option;
      });
    },
    setOriginTerminalOption: (option) => {
      set((state) => {
        state.originTerminalOption = option;
      });
    },
    setDestinationTerminalOption: (option) => {
      set((state) => {
        state.destinationTerminalOption = option;
      });
    },
    setSearchText: (value) => {
      set((state) => {
        state.searchText = value;
      });
    },
    fetchIngestedEmailOrders: async ({
      first,
      after,
      last,
      before,
    }: FetchIngestedEmailOrdersParams) => {
      const {
        searchText,
        customerOption,
        originTerminalOption,
        destinationTerminalOption,
      } = get();
      set((state) => {
        state.ingestedEmailOrdersLoading = true;
      });
      const { selectedTerminalUuid } = globalStore.getState();

      try {
        let ingestedEmailOrdersResult: ApolloQueryResult<IngestedEmailOrdersQuery>;
        if (!isEmpty(searchText)) {
          // ignore all filters if searching
          ingestedEmailOrdersResult = await apolloClient.query<
            IngestedEmailOrdersQuery,
            IngestedEmailOrdersQueryVariables
          >({
            query: IngestedEmailOrdersDocument,
            variables: {
              searchText: searchText.trim(),
              first,
              after,
              last: !isNil(last) ? last + 1 : last,
              before,
              emailIntegrationTerminalUuid: selectedTerminalUuid,
              contactUuid: customerOption?.value,
              originTerminalUuid: originTerminalOption?.value,
              destinationTerminalUuid: destinationTerminalOption?.value,
            },
          });
        } else {
          ingestedEmailOrdersResult = await apolloClient.query<
            IngestedEmailOrdersQuery,
            IngestedEmailOrdersQueryVariables
          >({
            query: IngestedEmailOrdersDocument,
            variables: {
              first,
              after,
              last: !isNil(last) ? last + 1 : last,
              before,
              emailIntegrationTerminalUuid: selectedTerminalUuid,
              contactUuid: customerOption?.value,
              originTerminalUuid: originTerminalOption?.value,
              destinationTerminalUuid: destinationTerminalOption?.value,
            },
          });
        }
        let firstToSave: number;
        let afterToSave: string | null | undefined;
        if (!isNil(last)) {
          if (
            !isNil(ingestedEmailOrdersResult.data.scannedOrderResults) &&
            ingestedEmailOrdersResult.data.scannedOrderResults.edges.length ===
              last + 1
          ) {
            await get().fetchIngestedEmailOrders({
              first: last,
              after:
                ingestedEmailOrdersResult.data.scannedOrderResults.pageInfo
                  .startCursor,
            });
            return;
          }
          firstToSave = last;
          afterToSave = null;
        } else if (!isNil(first)) {
          firstToSave = first;
          afterToSave = after;
        } else {
          throw new Error('Invalid fetchIngestedEmailOrders params');
        }
        set((state) => {
          state.ingestedEmailsTableState.currentCursorParams = {
            first: firstToSave,
            after: afterToSave,
          };
          state.ingestedEmailOrdersData = ingestedEmailOrdersResult.data;
        });
      } catch (e) {
        console.error('Error fetching ingested email orders', e);
      } finally {
        set((state) => {
          state.ingestedEmailOrdersLoading = false;
        });
      }
    },
    updateSelectedOrderAfterReview: async (onSetOrderUuid) => {
      const { selectedOrderUuid } = get();
      if (isNil(selectedOrderUuid)) {
        return;
      }
      const currentIndex =
        get().ingestedEmailOrdersData?.scannedOrderResults.edges.findIndex(
          ({ node }) => node.order?.uuid === selectedOrderUuid,
        );
      if (isNil(currentIndex) || currentIndex === -1) {
        return;
      }
      const { currentCursorParams } = get().ingestedEmailsTableState;
      await get().fetchIngestedEmailOrders({
        first: currentCursorParams?.first,
        after: currentCursorParams?.after,
      });
      set((state) => {
        const orderUuid =
          state.ingestedEmailOrdersData?.scannedOrderResults.edges[currentIndex]
            ?.node.order?.uuid;
        state.setSelectedOrderUuid(orderUuid);
        onSetOrderUuid(orderUuid);
      });
    },
    nextPage: async () => {
      // if (
      //   get().ingestedEmailOrdersData?.scannedOrderResults.pageInfo
      //     .hasNextPage === false
      // ) {
      //   console.log('no next page');
      //   return;
      // }
      // if loading, refuse to fetch more to avoid issues
      if (get().ingestedEmailOrdersLoading) {
        return;
      }
      set((state) => {
        state.ingestedEmailsTableState.page += 1;
      });
      await get().fetchIngestedEmailOrders({
        first: get().ingestedEmailsTableState.rowsPerPage,
        after:
          get().ingestedEmailOrdersData?.scannedOrderResults.pageInfo.endCursor,
      });

      set((state) => {
        // if order is selected, check whether it's on the current page
        if (
          !isNil(state.selectedOrderUuid) &&
          !isNil(state.ingestedEmailOrdersData) &&
          !isOrderPresentOnCurrentPage(
            state.selectedOrderUuid,
            state.ingestedEmailOrdersData,
          )
        ) {
          state.setSelectedOrderUuid(
            state.ingestedEmailOrdersData?.scannedOrderResults?.edges[0]?.node
              .order?.uuid,
          );
        }
      });
    },
    prevPage: async () => {
      if (get().ingestedEmailsTableState.page === 0) {
        return;
      }
      // if loading, refuse to fetch more to avoid issues
      if (get().ingestedEmailOrdersLoading) {
        return;
      }
      set((state) => {
        state.ingestedEmailsTableState.page -= 1;
      });
      await get().fetchIngestedEmailOrders({
        last: get().ingestedEmailsTableState.rowsPerPage,
        before:
          get().ingestedEmailOrdersData?.scannedOrderResults.pageInfo
            .startCursor,
      });
      set((state) => {
        // if order is selected, check whether it's on the current page
        if (
          !isNil(state.selectedOrderUuid) &&
          !isNil(state.ingestedEmailOrdersData) &&
          !isOrderPresentOnCurrentPage(
            state.selectedOrderUuid,
            state.ingestedEmailOrdersData,
          )
        ) {
          state.setSelectedOrderUuid(
            state.ingestedEmailOrdersData?.scannedOrderResults?.edges[0]?.node
              .order?.uuid,
          );
        }
      });
    },
    refresh: () => {
      set((state) => {
        state.ingestedEmailsTableState.page = 0;
      });
      get().fetchIngestedEmailOrders({
        first: get().ingestedEmailsTableState.rowsPerPage,
      });
    },
    setRowsPerPage: (rowsPerPage) => {
      set((state) => {
        state.ingestedEmailsTableState.rowsPerPage = rowsPerPage;
      });
    },
    setShowReportADocScanIssueDialog: (show) => {
      set((state) => {
        state.showReportADocScanIssueDialog = show;
      });
    },
  })),
);

export const useSelectedOrderData = ():
  | IngestedEmailOrderByOrderUuidQuery['scannedOrderResultByOrderUuid']
  | undefined => {
  const [getSelectedOrerData, { data: selectedOrderData, loading }] =
    useIngestedEmailOrderByOrderUuidLazyQuery();
  const [selectedOrderUuid, ingestedEmailOrdersData] = useIngestedEmailsStore(
    (state) => [state.selectedOrderUuid, state.ingestedEmailOrdersData],
  );
  if (isNil(selectedOrderUuid) || loading === true) {
    return undefined;
  }
  if (!isNil(selectedOrderData)) {
    return selectedOrderData.scannedOrderResultByOrderUuid;
  }
  if (isNil(ingestedEmailOrdersData)) {
    return undefined;
  }

  const resultOnCurrentPage =
    ingestedEmailOrdersData.scannedOrderResults.edges.find(
      ({ node }) => node.order?.uuid === selectedOrderUuid,
    )?.node;

  if (!isNil(resultOnCurrentPage)) {
    return resultOnCurrentPage;
  }
  getSelectedOrerData({
    variables: { uuid: selectedOrderUuid },
  });
  return undefined;
};

export default useIngestedEmailsStore;
