import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import useTerminals from '../react-hooks/use-terminals';

const TerminalPicker = ({
  label = 'Terminal',
  terminalUuid,
  setTerminalUuid,
  includeInactiveTerminals,
  required,
  error,
}: {
  label?: string;
  terminalUuid: string | undefined;
  setTerminalUuid: (newTerminalUuid: string | undefined) => void;
  includeInactiveTerminals?: boolean;
  required?: boolean;
  error?: string;
}) => {
  const { terminals, getTerminalName } = useTerminals({
    includeInactiveTerminals,
  });
  return (
    <AutocompleteFuzzy
      size="small"
      sx={{ backgroundColor: 'white', width: '100%' }}
      value={{
        value: terminalUuid,
        label: getTerminalName(terminalUuid),
      }}
      options={
        terminals?.map((terminal) => ({
          label: terminal.name,
          value: terminal.uuid,
        })) ?? []
      }
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          size="small"
          required={required}
          error={!isNil(error)}
          helperText={error}
        />
      )}
      onChange={(event, option) => {
        setTerminalUuid(option?.value);
      }}
    />
  );
};

export default TerminalPicker;
