import { Button, Divider, Stack, Tab, Tabs, Box } from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import useMe from '../../../../common/react-hooks/use-me';

import {
  FilterViewPage,
  SavedFilterViewFragment,
} from '../../../../generated/graphql';
import EditSavedViewModal from '../../../saved-filter-views/components/edit-saved-view-modal';
import { EditSavedViewTabButtonAndMenu } from '../../../saved-filter-views/components/edit-saved-view-tab-button-menu';
import { DefaultFilterTabsConfigs } from '../types';

const OrderTableTabs = <DefaultFilterTabsType,>({
  pageType,
  canWriteOrders,
  savedViews,
  currentTab,
  currentSavedViewName,
  defaultFilterTabsConfigs,
  tabsNumberOfOrdersData,
  shouldAllowSavedViews,
  handleEditSavedViewName,
  handleSetIsViewShared,
  handleDeleteSavedView,
  showCreateSavedViewModal,
  onChangeTab,
}: {
  pageType: FilterViewPage;
  canWriteOrders: boolean;
  savedViews: SavedFilterViewFragment[] | undefined;
  currentTab: string | DefaultFilterTabsType;
  currentSavedViewName: string | null;
  defaultFilterTabsConfigs: DefaultFilterTabsConfigs<DefaultFilterTabsType>;
  tabsNumberOfOrdersData?:
    | {
        tab: DefaultFilterTabsType;
        numberOfOrders: number | undefined;
      }[]
    | undefined;
  shouldAllowSavedViews: boolean;
  handleEditSavedViewName: (displayName: string) => void;
  handleSetIsViewShared: (isShared: boolean) => void;
  handleDeleteSavedView: (uuid: string) => void;
  showCreateSavedViewModal: (viewIsFromScratch: boolean) => void;
  onChangeTab: ({
    newTab,
    newView,
  }: {
    newTab: string | DefaultFilterTabsType;
    newView: SavedFilterViewFragment | undefined;
  }) => void;
}) => {
  const { userUuid } = useMe();
  const [showEditSavedViewModal, setShowEditSavedViewModal] = useState(false);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        background: 'white',
        mb: '10px',
        p: 0,
      }}
    >
      <Box
        sx={{
          flex: shouldAllowSavedViews ? '1 0 90%' : '1 0 100%',
          overflowX: 'auto',
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={(_, val) => {
            const savedViewTab = savedViews?.find((view) => view.uuid === val);
            onChangeTab({ newTab: val, newView: savedViewTab });
          }}
        >
          {defaultFilterTabsConfigs.tabs.map((tabConfig) => {
            const numberOfOrders = tabsNumberOfOrdersData?.find(
              (data) => data.tab === tabConfig.value,
            )?.numberOfOrders;
            return (
              <Tab
                key={tabConfig.label}
                value={tabConfig.value}
                data-testid={tabConfig.dataTestId}
                label={`${tabConfig.label} ${!isNil(numberOfOrders) ? `(${numberOfOrders})` : ''}`}
              />
            );
          })}
          {shouldAllowSavedViews &&
            savedViews?.map((view) => (
              <Tab
                key={view.uuid}
                sx={{ maxHeight: '10px' }}
                value={view.uuid}
                label={
                  <Stack direction="row" alignItems="center">
                    {view.displayName}
                    {currentTab === view.uuid && (
                      <EditSavedViewTabButtonAndMenu
                        pageType={pageType}
                        isViewShared={view.isShared}
                        isUserViewCreator={view.user.uuid === userUuid}
                        handleClickRename={() => {
                          setShowEditSavedViewModal(true);
                        }}
                        handleSetIsViewShared={handleSetIsViewShared}
                        handleClickDelete={() => {
                          handleDeleteSavedView(view.uuid);
                        }}
                      />
                    )}
                  </Stack>
                }
              />
            ))}
        </Tabs>
      </Box>
      {/* divider is only needed when there is the new filter view button to show */}
      {shouldAllowSavedViews && (
        <Divider
          orientation="vertical"
          style={{ height: 30, alignSelf: 'center', borderColor: 'gray' }}
        />
      )}
      <Box
        sx={{
          flex: '1 0 10%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {shouldAllowSavedViews && (
          <Button
            onClick={() => showCreateSavedViewModal(true)}
            disabled={!canWriteOrders}
          >
            + New saved view
          </Button>
        )}
      </Box>
      {showEditSavedViewModal && (
        <EditSavedViewModal
          open={showEditSavedViewModal}
          setOpen={setShowEditSavedViewModal}
          currentName={currentSavedViewName ?? undefined}
          editSavedView={handleEditSavedViewName}
        />
      )}
    </Stack>
  );
};

// React.memo doesn't work with generic props types
const typedMemo: <T>(c: T) => T = React.memo;

export default typedMemo(OrderTableTabs);
