import {
  useQueryQuickbooksDesktopAccountsQuery,
  useQueryQuickbooksDesktopCustomersQuery,
  useQueryQuickbooksDesktopItemsQuery,
} from '../../generated/graphql';

const useQuickbooksDesktopData = () => {
  const {
    data: quickbooksItemsData,
    loading: quickbooksItemsLoading,
    refetch: refetchQuickbooksItems,
  } = useQueryQuickbooksDesktopItemsQuery({
    fetchPolicy: 'cache-first',
  });
  const {
    data: quickbooksAccountsData,
    loading: quickbooksAccountsLoading,
    refetch: refetchQuickbooksAccounts,
  } = useQueryQuickbooksDesktopAccountsQuery({
    fetchPolicy: 'cache-first',
  });
  const {
    data: quickbooksCustomersData,
    loading: quickbooksCustomersLoading,
    refetch: refetchQuickbooksCustomers,
  } = useQueryQuickbooksDesktopCustomersQuery({
    fetchPolicy: 'cache-first',
  });

  const quickbooksItems = quickbooksItemsData?.queryQuickbooksDesktopItems;
  const quickbooksAccounts =
    quickbooksAccountsData?.queryQuickbooksDesktopAccounts;
  const quickbooksCustomers =
    quickbooksCustomersData?.queryQuickbooksDesktopCustomers;
  return {
    quickbooksItems,
    quickbooksAccounts,
    quickbooksCustomers,
    quickbooksItemsLoading,
    quickbooksAccountsLoading,
    quickbooksCustomersLoading,
    refetchQuickbooksItems,
    refetchQuickbooksAccounts,
    refetchQuickbooksCustomers,
  };
};

export default useQuickbooksDesktopData;
