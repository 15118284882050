import {
  Button,
  Checkbox,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import {
  StorageOrderDocument,
  StorageOrderStorageUnitFragment,
  useMarkStorageUnitAsOsdMutation,
  useRemoveStorageUnitOsdMutation,
  useUpdateStorageUnitMutation,
} from '../../../../../generated/graphql';
import useOSDForm from '../../../forms/storage-units/use-osd-form';

interface OSDFormProps {
  storageUnit: StorageOrderStorageUnitFragment;
}
const OSDForm = ({ storageUnit }: OSDFormProps) => {
  const [markStorageUnitAsOsd] = useMarkStorageUnitAsOsdMutation({
    refetchQueries: [StorageOrderDocument],
  });
  const [removeStorageUnitOsd] = useRemoveStorageUnitOsdMutation({
    refetchQueries: [StorageOrderDocument],
  });
  const [updateStorageUnit, { loading: updateStorageUnitLoading }] =
    useUpdateStorageUnitMutation({ refetchQueries: [StorageOrderDocument] });
  const { reset, control, watch, setValue } = useOSDForm();
  useEffect(() => {
    reset({
      isOSD: !isNil(storageUnit.markedOsdAt),
      markedOsdAt: storageUnit.markedOsdAt,
      osdReason: storageUnit.osdReason ?? undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageUnit.uuid, storageUnit.markedOsdAt, storageUnit.osdReason]);

  const markedOsdAt = watch('markedOsdAt');
  const isOSD = watch('isOSD');
  const osdReason = watch('osdReason');

  const getLabel = () => {
    if (!isOSD) {
      return 'Mark as OSD';
    }
    return (
      <Stack direction="column">
        <Typography>Marked as OSD</Typography>
        <Typography color="gray" variant="subtitle2">
          at {dayjs(markedOsdAt).format('MM/DD/YYYY hh:mm A')}
        </Typography>
      </Stack>
    );
  };

  const handleSaveOSDReason = async () => {
    if (osdReason === storageUnit.osdReason) {
      return;
    }
    await updateStorageUnit({
      variables: {
        updateStorageUnitInput: {
          uuid: storageUnit.uuid,
          osdReason: osdReason ?? null,
        },
      },
    });
  };

  return (
    <>
      <Grid item xs={4}>
        <FormControlLabel
          label={getLabel()}
          control={
            <Checkbox
              checked={isOSD ?? false}
              onChange={async (val) => {
                setValue('isOSD', val.target.checked);
                if (!val.target.checked) {
                  setValue('markedOsdAt', undefined);
                  await removeStorageUnitOsd({
                    variables: {
                      removeStorageUnitOsdInput: {
                        uuid: storageUnit.uuid,
                      },
                    },
                  });
                } else {
                  setValue('markedOsdAt', new Date());
                  await markStorageUnitAsOsd({
                    variables: {
                      markStorageUnitAsOsdInput: {
                        uuid: storageUnit.uuid,
                      },
                    },
                  });
                }
              }}
            />
          }
        />
      </Grid>
      {isOSD && (
        <Grid xs={8} display="flex" gap={1}>
          <Controller
            name="osdReason"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                label="OSD reason"
                placeholder="Damaged, Missing, etc."
                size="small"
                sx={{ flex: 1 }}
              />
            )}
          />
          <Button
            variant="outlined"
            disabled={
              updateStorageUnitLoading || osdReason === storageUnit.osdReason
            }
            onClick={handleSaveOSDReason}
          >
            Save
          </Button>
        </Grid>
      )}
    </>
  );
};

export default OSDForm;
