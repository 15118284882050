import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';

dayjs.extend(timezone);
dayjs.extend(utc);

export const calculateWaitTime = ({
  arrivedAt,
  completedAt,
}: {
  arrivedAt: Date | null | undefined;
  completedAt: Date | null | undefined;
}) => {
  if (isNil(arrivedAt) || isNil(completedAt)) {
    return { waitTimeHours: undefined, waitTimeMinutes: undefined };
  }

  // Round to nearest minute so that the user doesn't have to think about
  // the fact that our timestamps include seconds (so otherwise the wait
  // time could be one minute off from you'd expect at first glance)
  const arrivedAtDayjs = dayjs(arrivedAt).startOf('minute');
  const completedAtDayjs = dayjs(completedAt).startOf('minute');
  if (completedAtDayjs.isBefore(arrivedAtDayjs)) {
    return { waitTimeHours: undefined, waitTimeMinutes: undefined };
  }
  const waitTime = completedAtDayjs.diff(arrivedAtDayjs, 'minutes');
  return {
    waitTimeHours: Math.floor(waitTime / 60),
    waitTimeMinutes: waitTime % 60,
  };
};

/**
 * Formats a stop attempt time to a human readable format
 */
export const formatStopAttemptTime = ({
  completedAt,
  companyTimezone,
}: {
  completedAt: Date | null | undefined;
  companyTimezone: string | null | undefined;
}) => {
  if (isNil(completedAt) || isNil(companyTimezone)) {
    return '';
  }
  return dayjs(completedAt).tz(companyTimezone).format('MM/DD/YYYY hh:mma');
};
