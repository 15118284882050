import ModeIcon from '@mui/icons-material/Mode';
import {
  FormControl,
  TextField,
  Typography,
  Box,
  IconButton,
  useTheme,
  SxProps,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { isNilOrEmptyString } from '../../../../../../../common/utils/utils';

/**
 * SxProps to add margin-top: '16px' if the element is siblings with
 * a DescriptionComponent, to keep the element vertically centered.
 */
export const MT_IF_HAS_DESCRIPTION_STYLES: SxProps = {
  '&:has(+ .charge-description-component)': {
    marginTop: '16px',
  },
};

export type DescriptionComponentProps = {
  keyString: string;
  disabled?: boolean;
};

const DescriptionComponent = ({
  keyString,
  disabled,
}: DescriptionComponentProps) => {
  const theme = useTheme();
  const { control } = useFormContext();
  const [showAddDescription, setShowAddDescription] = useState(false);
  const description = useWatch({ control, name: `${keyString}.description` });

  if (disabled === true && isNilOrEmptyString(description)) {
    return null;
  }

  return (
    <Box className="charge-description-component">
      {showAddDescription ? (
        <Controller
          name={`${keyString}.description`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
              <TextField
                onBlur={() => setShowAddDescription(false)}
                size="small"
                value={value}
                onChange={onChange}
                variant="standard"
                label="Description"
                sx={{ fontSize: '10px' }}
                disabled={disabled}
              />
            </FormControl>
          )}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            color: theme.palette.grey[500],
          }}
        >
          <Typography>
            {!isNilOrEmptyString(description) ? description : 'Add description'}
          </Typography>
          {disabled !== true && (
            <IconButton
              sx={{ p: 0 }}
              color="inherit"
              onClick={() => setShowAddDescription(true)}
              disabled={disabled}
            >
              <ModeIcon sx={{ fontSize: '16px' }} />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(DescriptionComponent);
