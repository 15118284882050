/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { v4 } from 'uuid';
import apolloClient from '../../../../apollo-client';
import { ErrorResponse } from '../../../../common/form/formValidators';
import {
  CompanyConfigurationEntity,
  ContactDocument,
  ContactQuery,
  ContactQueryVariables,
  CreateRecurringOrderFrequencyInput,
  DocumentCreateInput,
  DocumentUpsertInput,
  InboundMethod,
  MeDocument,
  MeQuery,
  MeQueryVariables,
  NumberOfConsignees,
  NumberOfShippers,
  OrderSegmentType,
  OrderSource,
  OrderStatus,
  OutboundMethod,
  PackageArrayUpdateInput,
  PackageCreateInput,
  PackageUpsertInput,
  PaymentMethod,
  PickupOrDelivery,
  QuoteDocument,
  QuoteQuery,
  QuoteQueryVariables,
  StandardOrderCreateInput,
  StandardOrderDocument,
  StandardOrderFieldsCreateInput,
  StandardOrderFragmentFragment,
  StandardOrderQuery,
  StandardOrderQueryVariables,
  StandardOrderUpdateInput,
  StandardShipmentArrayUpdateInput,
  StandardShipmentCreateInput,
  StopType,
  StopUpsertInput,
  UpdateRecurringOrderFrequencyInput,
} from '../../../../generated/graphql';
import { RootState } from '../../../../redux/store';
import { selectLegsByIds } from '../../../legs/redux/leg-values-slice';
import {
  addOnePackageValues,
  selectPackagesByIds,
  upsertOnePackageValues,
} from '../../../packages/redux/package-values-slice';
import {
  createNewPackage,
  createPackageCreateInput,
  createPackageUpsertInput,
  upsertPackagesForOrder,
} from '../../../packages/redux/package-values-thunks';
import { selectStandardShipmentsByIds } from '../../../shipments/redux/standard-shipments-values-slice';
import {
  buildNewStandardShipment,
  createStandardShipmentArrayUpdateInput,
  createStandardShipmentCreateInput,
  ShipmentErrorsResponse,
  upsertShipment,
} from '../../../shipments/redux/standard-shipments-values-thunks';
import { selectStopsByIds } from '../../../stops/redux/stop-values-slice';
import {
  createStopUpsertInput,
  initNewStop,
} from '../../../stops/redux/stop-values-thunks';
import {
  createDocumentsCreateInput,
  createDocumentsUpsertInput,
} from '../document-values-thunks';
import { upsertDocument } from '../documents-values-slice';
import {
  addStandardOrderValues,
  selectStandardOrderValuesById,
  StandardOrderValues,
  upsertOneStandardOrderValues,
} from './standard-orders-values-slice';

type InitNewStandardOrderArg = {
  billingPartyContactUuid?: string;
  configuration?: Partial<CompanyConfigurationEntity> | null | undefined;
  name?: string;
  source?: OrderSource;
  isDraftOrder?: boolean;
  createdFromOrderTemplateUuid?: string | undefined;
};

export const initNewStandardOrder = createAsyncThunk<
  StandardOrderValues,
  InitNewStandardOrderArg,
  {
    state: RootState;
  }
>(
  'standardOrders/initNewStandardOrder',
  async (arg, thunkAPI): Promise<StandardOrderValues> => {
    let defaultFuelSurcharge;
    let defaultDimFactor;
    if (!isNil(arg.billingPartyContactUuid)) {
      const contactResponse = await apolloClient.query<
        ContactQuery,
        ContactQueryVariables
      >({
        query: ContactDocument,
        variables: { uuid: arg.billingPartyContactUuid },
      });
      const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        fetchPolicy: 'cache-first',
      });
      const companyConfig = companyData.data.me?.company?.configuration;
      const companyDefaultFuelSurcharge =
        companyConfig?.defaultFuelSurcharge ?? undefined;
      const companyDefaultDimFactor =
        companyConfig?.defaultDimFactor ?? undefined;
      defaultFuelSurcharge =
        contactResponse.data.contact.__typename === 'CustomerContactEntity'
          ? contactResponse.data.contact.defaultFuelSurcharge ??
            companyDefaultFuelSurcharge
          : companyDefaultFuelSurcharge;
      defaultDimFactor =
        contactResponse.data.contact.__typename === 'CustomerContactEntity'
          ? contactResponse.data.contact.defaultDimFactor ??
            companyDefaultDimFactor
          : companyDefaultDimFactor;
    }
    const orderUuid = v4();
    const shipmentUuid = await thunkAPI
      .dispatch(
        buildNewStandardShipment({
          orderUuid,
          pickupOrDelivery: PickupOrDelivery.Delivery,
          stopType: StopType.Delivery,
          defaultFuelSurcharge,
          defaultDimFactor,
          defaultStandardStopType: arg.configuration?.defaultStandardStopType,
        }),
      )
      .unwrap();
    const defaultShipperStop = await thunkAPI
      .dispatch(
        initNewStop({
          defaultStandardStopType: arg.configuration?.defaultStandardStopType,
        }),
      )
      .unwrap();
    const defaultConsigneeStop = await thunkAPI
      .dispatch(
        initNewStop({
          defaultStandardStopType: arg.configuration?.defaultStandardStopType,
        }),
      )
      .unwrap();
    const packageUuid: string = await thunkAPI
      .dispatch(createNewPackage())
      .unwrap();
    const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
      query: MeDocument,
    });

    return thunkAPI.dispatch(
      addStandardOrderValues({
        orderSegmentType: OrderSegmentType.Cartage,
        billingPartyContactUuid: arg.billingPartyContactUuid,
        name: arg.name,
        personName: undefined,
        personPhoneNumber: undefined,
        personEmail: undefined,
        defaultShipperStopUuid: defaultShipperStop.uuid,
        defaultConsigneeStopUuid: defaultConsigneeStop.uuid,
        dimFactor: defaultDimFactor ?? 250,
        driversNeededInVehicle: undefined,
        hasEdiCancellation: false,
        lineHaulLaneEndTerminalUuid: undefined,
        lineHaulLaneStartTerminalUuid: undefined,
        lineHaulLaneUuid: undefined,
        refNumbers: [],
        fieldsUuid: v4(),
        detailedStatus: undefined,
        measurementUnits: companyData.data.me?.company.defaultMeasurementUnits,
        numberOfConsignees: NumberOfConsignees.One,
        numberOfShippers: NumberOfShippers.One,
        packageUuids: [packageUuid],
        purchaseOrderNumber: undefined,
        serviceUuid: undefined,
        shipperBillOfLadingNumber: undefined,
        shipmentUuids: [shipmentUuid],
        status: OrderStatus.Created,
        source: arg.source,
        paymentMethod: PaymentMethod.PrepaidBySeller,
        uuid: orderUuid,
        receivedDate: undefined,
        pickedDate: undefined,
        dateMarkedLoaded: undefined,
        documentUuids: [],
        scannedOrderResultUuid: undefined,
        onHand: undefined,
        pieceCount: undefined,
        piecesPicked: undefined,
        piecesLoaded: undefined,
        orderConsolidationType: undefined,
        secondaryRefNumber: undefined,
        tertiaryRefNumber: undefined,
        notes: '',
        weightUnits: companyData.data.me?.company.defaultWeightUnits,
        masterAirwayBillOfLadingNumber: undefined,
        warehouseUuid: undefined,
        tagUuids: [],
        isDraftOrder: arg.isDraftOrder,
        createdFromOrderTemplateUuid: arg.createdFromOrderTemplateUuid,
      }),
    ).payload;
  },
);

type CreateStandardOrderCreateInputArg = {
  orderUuid: string;
  forceNewName?: boolean;
};

export const createStandardOrderCreateInput = createAsyncThunk<
  StandardOrderCreateInput,
  CreateStandardOrderCreateInputArg,
  {
    state: RootState;
  }
>(
  'standardOrders/createStandardOrderCreateInput',
  async (arg, thunkAPI): Promise<StandardOrderCreateInput> => {
    const standardOrderValues = selectStandardOrderValuesById(
      thunkAPI.getState(),
      arg.orderUuid,
    );
    if (isNil(standardOrderValues)) {
      throw new Error(`Invalid order uuid: ${arg.orderUuid}`);
    }

    // NOTE(Dwayne) - The assumption is all fields have already been validated, otherwise we duplicate a lot of code
    // Is there a better way? Maybe we should put the error validation and input creation in the same function
    const standardOrderFieldsCreateInput: StandardOrderFieldsCreateInput = {
      dimFactor: standardOrderValues.dimFactor,
      driversNeededInVehicle: standardOrderValues.driversNeededInVehicle!,
      measurementUnits: standardOrderValues.measurementUnits!,
      numberOfConsignees: standardOrderValues.numberOfConsignees!,
      numberOfShippers: standardOrderValues.numberOfShippers!,
      purchaseOrderNumber: standardOrderValues.purchaseOrderNumber!,
      shipperBillOfLadingNumber: standardOrderValues.shipperBillOfLadingNumber!,
      weightUnits: standardOrderValues.weightUnits!,
      masterAirwayBillOfLadingNumber:
        standardOrderValues.masterAirwayBillOfLadingNumber,
    };
    const shipmentUuids = standardOrderValues.shipmentUuids ?? [];
    const orderSubscribers = standardOrderValues.shipmentSubscriberUuids;

    const shipmentCreateInputs: StandardShipmentCreateInput[] = filterNotNil(
      await Promise.all(
        shipmentUuids.map(async (shipmentUuid) => {
          return thunkAPI
            .dispatch(
              createStandardShipmentCreateInput({
                shipmentUuid,
                shipmentSubscriberUuids: orderSubscribers,
              }),
            )
            .unwrap();
        }),
      ),
    );

    const documentCreateInputs: DocumentCreateInput[] = await thunkAPI
      .dispatch(
        createDocumentsCreateInput({
          documentIds: standardOrderValues?.documentUuids ?? [],
        }),
      )
      .unwrap();
    const packages = selectPackagesByIds(
      thunkAPI.getState(),
      standardOrderValues?.packageUuids ?? [],
    );
    const filteredPackages = packages.filter(
      (package_) => !(package_.quantity === 0 || isNil(package_.quantity)),
    );
    const packageCreateInputs: PackageCreateInput[] = filterNotNil(
      await Promise.all(
        filteredPackages.map(async (filteredPackage) =>
          thunkAPI
            .dispatch(
              createPackageCreateInput({ packageUuid: filteredPackage.uuid }),
            )
            .unwrap(),
        ),
      ),
    );

    const { startDate, endDate, repeatIntervalWeeks, daysOfWeek } =
      standardOrderValues;

    let recurringOrderFrequencyCreateInput:
      | CreateRecurringOrderFrequencyInput
      | undefined;
    if (
      !isNil(startDate) &&
      !isNil(repeatIntervalWeeks) &&
      !isNil(daysOfWeek)
    ) {
      recurringOrderFrequencyCreateInput = {
        startDate,
        endDate,
        repeatIntervalWeeks,
        daysOfWeek,
      };
    }

    return {
      billingPartyContactUuid: standardOrderValues.billingPartyContactUuid!,
      shipmentCreateInputs,
      documentCreateInputs,
      lineHaulLaneUuid: standardOrderValues.lineHaulLaneUuid,
      packageCreateInputs,
      standardOrderFieldsCreateInput,
      receivedDate: undefined,
      pickedDate: undefined,
      name:
        !isNil(standardOrderValues.name) &&
        standardOrderValues.name.length > 0 &&
        arg.forceNewName !== true
          ? standardOrderValues.name
          : undefined,
      status: undefined,
      source: standardOrderValues.source ?? OrderSource.ManuallyEntered,
      paymentMethod: standardOrderValues.paymentMethod,
      refNumbers: standardOrderValues.refNumbers ?? [],
      notes: standardOrderValues.notes,
      secondaryRefNumber: standardOrderValues.secondaryRefNumber,
      serviceUuid: standardOrderValues.serviceUuid,
      warehouseUuid: standardOrderValues.warehouseUuid,
      createdFromOrderTemplateUuid:
        standardOrderValues.createdFromOrderTemplateUuid,
      recurringOrderFrequencyCreateInput,
      isDraftOrder: standardOrderValues.isDraftOrder,
      totalSkids: standardOrderValues.totalSkids,
    };
  },
);

type OrderHasNilServiceDateArg = {
  orderUuid: string;
};

export const orderHasNilServiceDate = createAsyncThunk<
  {
    hasNilServiceDate: boolean;
    shipperBillOfLadingNumber: string;
    uuid: string;
  },
  OrderHasNilServiceDateArg,
  {
    state: RootState;
  }
>(
  'standardOrders/orderHasNilServiceDate',
  async (
    arg,
    thunkAPI,
  ): Promise<{
    hasNilServiceDate: boolean;
    shipperBillOfLadingNumber: string;
    uuid: string;
  }> => {
    const orderValues = selectStandardOrderValuesById(
      thunkAPI.getState(),
      arg.orderUuid,
    );
    const shipmentsValues = selectStandardShipmentsByIds(
      thunkAPI.getState(),
      orderValues?.shipmentUuids ?? [],
    );
    const pickupOrDeliveryShipments = shipmentsValues.filter(
      (s) =>
        s.pickupOrDelivery === PickupOrDelivery.Delivery ||
        s.pickupOrDelivery === PickupOrDelivery.Pickup,
    );
    const legsValues = selectLegsByIds(
      thunkAPI.getState(),
      filterNotNil(pickupOrDeliveryShipments.map((s) => s.firstLegUuid)),
    );
    const stopsValues = selectStopsByIds(
      thunkAPI.getState(),
      legsValues.map((l) => l.endStopUuid),
    );
    const pickupOrDeliveryStops = stopsValues.filter(
      (s) =>
        s.inboundMethod !== InboundMethod.InboundDelivery &&
        s.outboundMethod !== OutboundMethod.LocalDelivery,
    );

    const serviceDates = pickupOrDeliveryStops.map((s) => s.serviceDate);
    const hasNilServiceDate = serviceDates.some((s) => isNil(s));

    return {
      hasNilServiceDate,
      shipperBillOfLadingNumber: orderValues?.shipperBillOfLadingNumber ?? '',
      uuid: arg.orderUuid,
    };
  },
);

type OrderHasNilServiceLevelArg = {
  orderUuid: string;
};

export const orderHasNilServiceLevel = createAsyncThunk<
  {
    hasNilServiceLevel: boolean;
    shipperBillOfLadingNumber: string;
    uuid: string;
  },
  OrderHasNilServiceLevelArg,
  {
    state: RootState;
  }
>(
  'standardOrders/orderHasNilServiceLevel',
  async (
    arg,
    thunkAPI,
  ): Promise<{
    hasNilServiceLevel: boolean;
    shipperBillOfLadingNumber: string;
    uuid: string;
  }> => {
    const orderValues = selectStandardOrderValuesById(
      thunkAPI.getState(),
      arg.orderUuid,
    );
    const hasNilServiceLevel = isNil(orderValues?.serviceUuid);

    return {
      hasNilServiceLevel,
      shipperBillOfLadingNumber: orderValues?.shipperBillOfLadingNumber ?? '',
      uuid: arg.orderUuid,
    };
  },
);

type CreateStandardOrderUpdateInputArg = {
  orderUuid: string;
};

export const createStandardOrderUpdateInput = createAsyncThunk<
  StandardOrderUpdateInput,
  CreateStandardOrderUpdateInputArg,
  {
    state: RootState;
  }
>(
  'standardOrders/createStandardOrderUpdateInput',
  async (arg, thunkAPI): Promise<StandardOrderUpdateInput> => {
    const orderValues = selectStandardOrderValuesById(
      thunkAPI.getState(),
      arg.orderUuid,
    );
    if (isNil(orderValues)) {
      throw new Error(`invalid order uuid: ${arg.orderUuid}`);
    }

    const shipmentUuids = orderValues.shipmentUuids ?? [];
    let defaultShipperStopUpsertInput: StopUpsertInput | null | undefined;
    let defaultConsigneeStopUpsertInput: StopUpsertInput | null | undefined;

    if (
      orderValues.numberOfConsignees === NumberOfConsignees.One &&
      orderValues.numberOfShippers === NumberOfShippers.Multiple &&
      !isNil(orderValues.defaultConsigneeStopUuid)
    ) {
      defaultConsigneeStopUpsertInput = await thunkAPI
        .dispatch(
          createStopUpsertInput({
            stopUuid: orderValues.defaultConsigneeStopUuid,
          }),
        )
        .unwrap();
    }
    if (
      orderValues.numberOfConsignees === NumberOfConsignees.Multiple &&
      orderValues.numberOfShippers === NumberOfShippers.One &&
      !isNil(orderValues.defaultShipperStopUuid)
    ) {
      defaultShipperStopUpsertInput = await thunkAPI
        .dispatch(
          createStopUpsertInput({
            stopUuid: orderValues.defaultShipperStopUuid,
          }),
        )
        .unwrap();
    }

    const shipmentArrayUpdateInputs: StandardShipmentArrayUpdateInput[] =
      await Promise.all(
        shipmentUuids.map((shipmentUuid) =>
          thunkAPI
            .dispatch(createStandardShipmentArrayUpdateInput({ shipmentUuid }))
            .unwrap(),
        ),
      );

    const packages = selectPackagesByIds(
      thunkAPI.getState(),
      orderValues?.packageUuids ?? [],
    );
    const filteredPackages = packages.filter(
      (package_) =>
        !(
          (package_.quantity === 0 || isNil(package_.quantity)) &&
          (isNil(package_.type) || package_.type.length === 0) &&
          (isNil(package_.skuNumber) || package_.skuNumber.length === 0)
        ),
    );

    const packageUpsertInputs: PackageUpsertInput[] = filterNotNil(
      await Promise.all(
        filteredPackages.map(async (package_) => {
          return thunkAPI
            .dispatch(createPackageUpsertInput({ packageUuid: package_.uuid }))
            .unwrap();
        }),
      ),
    );
    const packageArrayUpdateInputs: PackageArrayUpdateInput[] =
      packageUpsertInputs.map((packageUpsertInput) => ({
        packageUpsertInput,
      }));

    const documentUpsertInputs: DocumentUpsertInput[] = await thunkAPI
      .dispatch(
        createDocumentsUpsertInput({
          documentIds: orderValues?.documentUuids ?? [],
          orderUuid: orderValues.uuid,
        }),
      )
      .unwrap();

    const { startDate, endDate, repeatIntervalWeeks, daysOfWeek } = orderValues;

    let recurringOrderFrequencyUpdateInput:
      | UpdateRecurringOrderFrequencyInput
      | undefined;
    if (!isNil(orderValues.recurringOrderFrequencyUuid)) {
      recurringOrderFrequencyUpdateInput = {
        uuid: orderValues.recurringOrderFrequencyUuid,
        startDate,
        endDate,
        repeatIntervalWeeks,
        daysOfWeek,
      };
    }

    return {
      billingPartyContactUuid: orderValues.billingPartyContactUuid,
      shipmentArrayUpdateInputs,
      receivedDate: orderValues.receivedDate,
      pickedDate: orderValues.pickedDate,
      documentUpsertInputs,
      standardOrderFieldsUpdateInput: {
        defaultConsigneeStopUpsertInput,
        defaultShipperStopUpsertInput,
        dimFactor: orderValues.dimFactor,
        driversNeededInVehicle: orderValues.driversNeededInVehicle,
        measurementUnits: orderValues.measurementUnits,
        numberOfConsignees: orderValues.numberOfConsignees,
        numberOfShippers: orderValues.numberOfShippers,
        purchaseOrderNumber: orderValues.purchaseOrderNumber,
        shipperBillOfLadingNumber: orderValues.shipperBillOfLadingNumber,
        uuid: orderValues.fieldsUuid,
        weightUnits: orderValues.weightUnits,
        masterAirwayBillOfLadingNumber:
          orderValues.masterAirwayBillOfLadingNumber,
      },
      name:
        !isNil(orderValues.name) && orderValues.name.length > 0
          ? orderValues.name
          : undefined,
      personName: orderValues.personName,
      personPhoneNumber: orderValues.personPhoneNumber,
      personEmail: orderValues.personEmail,
      status: orderValues.status,
      packageArrayUpdateInputs,
      paymentMethod: orderValues.paymentMethod,
      uuid: orderValues.uuid,
      notes: orderValues.notes,
      secondaryRefNumber: orderValues.secondaryRefNumber,
      issueReason: orderValues.issueReason,
      onHand: orderValues.onHand,
      pieceCount: orderValues.pieceCount,
      piecesPicked: orderValues.piecesPicked,
      warehouseUuid: orderValues.warehouseUuid,
      tagUuids: orderValues.tagUuids,
      refNumbers: orderValues.refNumbers,
      createdFromOrderTemplateUuid: orderValues.createdFromOrderTemplateUuid,
      recurringOrderFrequencyUpdateInput,
      totalSkids: orderValues.totalSkids ?? undefined,
    };
  },
);

export const initNewOrderFromQuote = createAsyncThunk<
  string,
  {
    quoteUuid: string;
    name: string | undefined;
  },
  {
    state: RootState;
  }
>('standardOrderValues/initNewOrderFromQuote', async (arg, thunkAPI) => {
  const quoteData = await apolloClient.query<QuoteQuery, QuoteQueryVariables>({
    query: QuoteDocument,
    variables: { uuid: arg.quoteUuid },
  });
  const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
    query: MeDocument,
  });
  const contactData = await apolloClient.query<
    ContactQuery,
    ContactQueryVariables
  >({
    query: ContactDocument,
    variables: { uuid: quoteData.data.quote.billingPartyContact.uuid },
  });
  const orderUuid = v4();

  const shipmentUuids: string[] = await Promise.all(
    quoteData.data.quote.shipments.map(async (shipment) => {
      return thunkAPI
        .dispatch(
          upsertShipment({
            companyData: companyData.data,
            isDuplicate: false,
            orderUuid,
            shipment,
            billingPartyUuid: quoteData.data.quote.billingPartyContact.uuid,
          }),
        )
        .unwrap();
    }),
  );
  const packageUuids = quoteData.data.quote.packages.map((package_) => {
    thunkAPI.dispatch(upsertOnePackageValues({ ...package_ }));
    return package_.uuid;
  });
  const defaultDimFactor =
    contactData.data.contact?.__typename === 'CustomerContactEntity' &&
    !isNil(contactData.data.contact?.defaultDimFactor)
      ? contactData.data.contact?.defaultDimFactor
      : companyData.data.me?.company.configuration?.defaultDimFactor ?? 250;
  const dimFactor = quoteData.data.quote.dimFactor ?? defaultDimFactor;
  await thunkAPI.dispatch(
    addStandardOrderValues({
      orderSegmentType: OrderSegmentType.Cartage,
      billingPartyContactUuid: quoteData.data.quote.billingPartyContact.uuid,
      hasEdiCancellation: false,
      name: arg.name,
      personName: undefined,
      personPhoneNumber: undefined,
      personEmail: undefined,
      defaultShipperStopUuid: undefined,
      defaultConsigneeStopUuid: undefined,
      dimFactor,
      driversNeededInVehicle: undefined,
      detailedStatus: undefined,
      lineHaulLaneEndTerminalUuid:
        quoteData.data.quote.lineHaulLane?.destinationTerminal.uuid,
      lineHaulLaneStartTerminalUuid:
        quoteData.data.quote.lineHaulLane?.originTerminal.uuid,
      lineHaulLaneUuid: quoteData.data.quote.lineHaulLane?.uuid,
      refNumbers: [],
      fieldsUuid: v4(),
      measurementUnits:
        quoteData.data.quote.measurementUnits ??
        companyData.data.me?.company.defaultMeasurementUnits,
      numberOfConsignees: NumberOfConsignees.One,
      numberOfShippers: NumberOfShippers.One,
      packageUuids,
      purchaseOrderNumber: undefined,
      serviceUuid: quoteData.data.quote.service?.uuid,
      shipperBillOfLadingNumber: undefined,
      shipmentUuids,
      status: OrderStatus.Created,
      source: undefined,
      paymentMethod: PaymentMethod.PrepaidBySeller,
      uuid: orderUuid,
      receivedDate: undefined,
      pickedDate: undefined,
      dateMarkedLoaded: undefined,
      documentUuids: [],
      scannedOrderResultUuid: undefined,
      orderConsolidationType: undefined,
      onHand: undefined,
      pieceCount: undefined,
      piecesPicked: undefined,
      piecesLoaded: undefined,
      secondaryRefNumber: undefined,
      tertiaryRefNumber: undefined,
      notes: '',
      weightUnits:
        quoteData.data.quote.weightUnits ??
        companyData.data.me?.company.defaultWeightUnits,
      masterAirwayBillOfLadingNumber: undefined,
      warehouseUuid: undefined,
      tagUuids: [],
      totalSkids: quoteData.data.quote.totalSkids ?? undefined,
    }),
  );
  return orderUuid;
});

export const upsertStandardOrder = createAsyncThunk<
  string,
  {
    standardOrder: StandardOrderFragmentFragment;
    isDuplicate?: boolean;
    companyData: MeQuery;
    duplicateOrderId: string;
    keepIfAlreadyInRedux?: boolean;
  },
  {
    state: RootState;
  }
>('standardOrderValues/upsertStandardOrder', async (arg, thunkAPI) => {
  const { standardOrder, companyData, duplicateOrderId } = arg;

  let documentUuids: string[] = [];
  if (arg.isDuplicate !== true) {
    documentUuids = await Promise.all(
      standardOrder.documents.map((document) => {
        thunkAPI.dispatch(
          upsertDocument({
            uuid: arg.isDuplicate !== true ? document.uuid : v4(),
            fileName: document.fileName,
            key: document.key,
            bucket: document.bucket,
            region: document.region,
            fileType: document.fileType,
            isDeleted: document.isDeleted,
            name: document.name,
            type: document.type,
          }),
        );
        return document.uuid;
      }),
    );
  }

  thunkAPI.dispatch(
    upsertPackagesForOrder({
      order: standardOrder,
      isDuplicate: arg.isDuplicate ?? false,
    }),
  );

  // TODO(Dwayne) - Break this up atomically by calling other thunks
  const shipmentUuids: string[] = await Promise.all(
    standardOrder.shipments.map(async (shipment) => {
      await thunkAPI.dispatch(
        upsertShipment({
          companyData,
          isDuplicate: arg.isDuplicate ?? false,
          orderUuid: standardOrder.uuid,
          shipment,
          billingPartyUuid: standardOrder.billingPartyContact.uuid,
        }),
      );
      return shipment.uuid;
    }),
  );

  const packageUuids = standardOrder.packages.map((package_) => {
    thunkAPI.dispatch(addOnePackageValues(package_));
    return package_.uuid;
  });

  await thunkAPI.dispatch(
    upsertOneStandardOrderValues({
      orderSegmentType: standardOrder.orderSegmentType,
      refNumbers: standardOrder.refNumbers,
      uuid: arg.isDuplicate !== true ? standardOrder.uuid : duplicateOrderId,
      name: standardOrder.name,
      personName: standardOrder.personName ?? undefined,
      personPhoneNumber: standardOrder.personPhoneNumber ?? undefined,
      personEmail: standardOrder.personEmail ?? undefined,
      fieldsUuid: standardOrder.standardOrderFields.uuid,
      detailedStatus: standardOrder.detailedStatusV2 ?? undefined,
      billingPartyContactUuid: standardOrder.billingPartyContact.uuid,
      dimFactor: standardOrder.standardOrderFields.dimFactor ?? 250,
      hasEdiCancellation: standardOrder.hasEdiCancellation,
      lineHaulLaneStartTerminalUuid:
        standardOrder.lineHaulLane?.originTerminal.uuid,
      lineHaulLaneEndTerminalUuid:
        standardOrder.lineHaulLane?.destinationTerminal.uuid,
      lineHaulLaneUuid: undefined,
      packageUuids,
      purchaseOrderNumber: undefined,
      serviceUuid: standardOrder.service?.uuid,
      shipmentUuids,
      shipperBillOfLadingNumber:
        standardOrder.standardOrderFields.shipperBillOfLadingNumber ??
        undefined,
      receivedDate: standardOrder.receivedDate,
      pickedDate: standardOrder.pickedDate,
      dateMarkedLoaded: standardOrder.dateMarkedLoaded,
      documentUuids,
      issueReason: standardOrder.issueReason ?? undefined,
      notes: standardOrder.notes,
      scannedOrderResultUuid: standardOrder.scannedOrderResult?.uuid,
      secondaryRefNumber: standardOrder.secondaryRefNumber ?? undefined,
      status: standardOrder.status,
      source: standardOrder.source,
      paymentMethod: standardOrder.paymentMethod ?? undefined,
      onHand: standardOrder.onHand ?? undefined,
      pieceCount: standardOrder.pieceCount ?? undefined,
      piecesPicked: standardOrder.piecesPicked ?? undefined,
      piecesLoaded: standardOrder.piecesLoaded ?? undefined,
      orderConsolidationType: standardOrder.orderConsolidationType ?? undefined,
      tertiaryRefNumber: standardOrder.tertiaryRefNumber ?? undefined,
      weightUnits: standardOrder.standardOrderFields.weightUnits,
      measurementUnits: standardOrder.standardOrderFields.measurementUnits,
      masterAirwayBillOfLadingNumber:
        standardOrder.standardOrderFields.masterAirwayBillOfLadingNumber ??
        undefined,
      defaultConsigneeStopUuid: undefined,
      defaultShipperStopUuid: undefined,
      driversNeededInVehicle: undefined,
      numberOfConsignees: NumberOfConsignees.One,
      numberOfShippers: NumberOfShippers.One,
      warehouseUuid: standardOrder.warehouse?.uuid ?? undefined,
      quoteUuid: standardOrder.quote?.uuid,
      quoteNumber: standardOrder.quote?.number,
      tagUuids: standardOrder.tags.map((tag) => tag.uuid),
      ordersWithSameBillOfLadingNumber:
        standardOrder.ordersWithSameBillOfLadingNumberForReviewOrders ??
        undefined,
      isDraftOrder: standardOrder.isDraftOrder ?? undefined,
      startDate: standardOrder.recurringOrderFrequency?.startDate ?? undefined,
      endDate: standardOrder.recurringOrderFrequency?.endDate ?? undefined,
      repeatIntervalWeeks:
        standardOrder.recurringOrderFrequency?.repeatIntervalWeeks ?? undefined,
      daysOfWeek:
        standardOrder.recurringOrderFrequency?.daysOfWeek ?? undefined,
      recurringOrderFrequencyUuid: standardOrder.recurringOrderFrequency?.uuid,
      totalSkids: standardOrder.totalSkids ?? undefined,
    }),
  );

  return standardOrder.uuid;
});

type InitExistingStandardOrderValuesArg = {
  orderUuid: string;
  isDuplicate?: boolean;
  snapshot?: string | null;
};

export const initExistingStandardOrderValues = createAsyncThunk<
  string | null,
  InitExistingStandardOrderValuesArg,
  {
    state: RootState;
  }
>('standardOrderValues/addStandardOrderValues', async (arg, thunkAPI) => {
  const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
    query: MeDocument,
    fetchPolicy: 'cache-first',
  });
  const duplicateOrderId = v4();

  if (!isNil(arg.snapshot)) {
    await thunkAPI.dispatch(
      upsertStandardOrder({
        standardOrder: JSON.parse(arg.snapshot),
        isDuplicate: arg.isDuplicate,
        duplicateOrderId,
        companyData: companyData.data,
      }),
    );
    return arg.isDuplicate !== true ? arg.orderUuid : duplicateOrderId;
  }

  const response = await apolloClient.query<
    StandardOrderQuery,
    StandardOrderQueryVariables
  >({
    query: StandardOrderDocument,
    variables: { uuid: arg.orderUuid },
  });

  const { standardOrder } = response.data;

  if (isNil(standardOrder)) {
    return null;
  }

  await thunkAPI.dispatch(
    upsertStandardOrder({
      standardOrder,
      isDuplicate: arg.isDuplicate,
      duplicateOrderId,
      companyData: companyData.data,
    }),
  );
  return arg.isDuplicate !== true ? arg.orderUuid : duplicateOrderId;
});

export type OrderErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
  shipmentsErrors: ShipmentErrorsResponse[];
};
