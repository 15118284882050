import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { transformAddressToFullAddressString } from 'shared/copy';
import { usePaperworkCompleteCopy } from '../../../../common/react-hooks/use-paperwork-complete-copy';
import {
  InvoiceOrderFragmentFragment,
  InvoiceShipmentWithChargesFragment,
  useInvoiceShipmentWithChargesByUuidQuery,
} from '../../../../generated/graphql';
import { getShipmentTypeCopy } from '../../utils';
import InvoiceShipmentChargesTable from './invoice-shipment-charges-table';

interface InvoiceOrdersListShipmentRowProps {
  shipment: InvoiceShipmentWithChargesFragment;
  order: InvoiceOrderFragmentFragment;
}

const InvoiceOrdersListShipmentRow = ({
  shipment,
  order,
}: InvoiceOrdersListShipmentRowProps) => {
  const { data: shipmentWithCharges, loading } =
    useInvoiceShipmentWithChargesByUuidQuery({
      variables: {
        uuid: shipment.uuid,
      },
    });

  const address = shipment.legs?.at(0)?.endStop.address;
  const { paperworkComplete } = order.paperwork;
  const { paperworkMarkedCompleteOverride } = order.paperwork;

  const shipmentType = getShipmentTypeCopy(shipment);
  const completedAt =
    !isNil(shipment.legs?.at(0)?.endStop.completedAt) &&
    dayjs(shipment.legs?.at(0)?.endStop.completedAt).format(
      'MM/DD/YYYY [at] hh:mmA',
    );

  const paperworkCompleteTooltip = usePaperworkCompleteCopy({
    paperworkComplete,
    paperworkMarkedCompleteOverride: paperworkMarkedCompleteOverride ?? null,
    missingDocuments: order.missingDocuments,
    attachedDocuments: order.documents.map((doc) => doc.type),
  });

  const paperwork = (
    <Tooltip title={paperworkCompleteTooltip}>
      {paperworkComplete ? (
        <CheckIcon color="success" />
      ) : (
        <CloseIcon color="error" />
      )}
    </Tooltip>
  );

  return (
    <TableContainer sx={{ pl: 4 }}>
      <Table size="small">
        <TableHead>
          <TableCell sx={{ width: '125px' }}>Stop Type</TableCell>
          <TableCell>Completion date</TableCell>
          <TableCell>Paperwork complete</TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{shipmentType}</TableCell>
            <TableCell>{completedAt}</TableCell>
            <TableCell>{paperwork}</TableCell>
          </TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ padding: 1, paddingLeft: 2 }}
            >
              <Typography sx={{ fontWeight: 'medium', fontSize: '14px' }}>
                Address
              </Typography>
              <Typography sx={{ fontSize: '14px' }} color="text.secondary">
                {transformAddressToFullAddressString({
                  ...address,
                  line1: address?.line1 ?? null,
                  city: address?.city ?? null,
                  zip: address?.zip ?? null,
                })}
              </Typography>
            </Stack>
            {loading || isNil(shipmentWithCharges) ? (
              <CircularProgress size={20} />
            ) : (
              <InvoiceShipmentChargesTable
                shipment={shipmentWithCharges.shipment}
              />
            )}
          </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceOrdersListShipmentRow;
