import {
  PackageSpecEntity,
  PackageType,
} from '../../../../../../generated/graphql';

// Temporarily during the migration from PackageType to PackageSpecEntity, when the package type changes, find the
// package spec that corresponds to the selected package type. This will help with enabling the PACKAGE_SPECS feature
// flag. It's fine if a package spec is not found because we can backfill packageSpecId later.
export const findCorrespondingPackageSpec = <
  T extends Pick<PackageSpecEntity, 'name'>,
>(
  packageType: PackageType,
  packageSpecs: T[],
): T | undefined =>
  packageSpecs.find((spec) => spec.name.toUpperCase() === packageType);

export const findCorrespondingPackageType = (
  packageSpec: Pick<PackageSpecEntity, 'name'>,
): PackageType | undefined =>
  Object.values(PackageType).find(
    (type) => type === packageSpec.name.toUpperCase(),
  );
