import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Box, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';

type TerminalMarkerIconProps = {
  color: string;
  text?: string;
  style?: CSSProperties;
};

const TerminalMarkerIcon = ({
  color,
  text,
  style,
}: TerminalMarkerIconProps) => {
  return (
    <Box
      sx={{
        ...style,
        backgroundColor: color,
        position: 'relative',
        top: '12px',
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100px',
          left: 34,
          lineHeight: '13px',
        }}
      >
        <Typography
          sx={{ fontSize: '10px', lineHeight: 0, color: '#121212' }}
          color="test.secondary"
          variant="caption"
        >
          {text}
        </Typography>
      </Box>
      <WarehouseIcon style={{ fontSize: '15px', color: 'white' }} />
    </Box>
  );
};

export default TerminalMarkerIcon;
