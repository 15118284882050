import { Box } from '@mui/material';
import {
  ColDef,
  ColumnMovedEvent,
  GridReadyEvent,
  IRowNode,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
  SideBarDef,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { sentenceCase } from 'change-case';
import { isArray, isEmpty, isNil, keyBy, uniqBy } from 'lodash';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import { filterNotNil } from 'shared/array';
import { isNilOrEmptyString } from 'shared/string';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import apolloClient from '../../../apollo-client';
import {
  isPrefixOption,
  isSingleCustomerOption,
  PrefixOption,
} from '../../../common/components/customer-filter-button';
import {
  DateOption,
  DatePickerFilterType,
} from '../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../common/feature-flags';
import { Option } from '../../../common/filters/types';
import {
  ORDERS_CHANGED_SORT_MODEL_KEY,
  ORDERS_CHANGED_TABLE_COLUMNS_KEY,
} from '../../../common/local-storage/keys';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  FilterViewPage,
  FormattedOrderFragment,
  GetSavedFilterViewsDocument,
  OrderSort,
  OrderSortV2,
  OrderTableField,
  OrderTableFieldHeaderFragment,
  GetOrderTableFieldValuesDocument,
  GetOrderTableFieldValuesForCountDocument,
  GetOrderTableFieldValuesForCountQuery,
  GetOrderTableFieldValuesForCountQueryVariables,
  OrderTableFieldValuesFormattedOrderConnectionFragment,
  GetOrderTableFieldValuesForSearchDocument,
  GetOrderTableFieldValuesForSearchQuery,
  GetOrderTableFieldValuesForSearchQueryVariables,
  GetOrderTableFieldValuesQuery,
  GetOrderTableFieldValuesQueryVariables,
  useUpdateSavedFilterViewMutation,
  useUpdateUserMutation,
  GetOrderTableFieldValuesInput,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import {
  ExcludeFromHiding,
  FilterModel,
} from '../../orders/components/enums/order-filters';
import { terminalOnlyOrderFields } from '../../orders/components/utils';
import { VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS } from '../../orders/constants';
import PageSizeSelector, { PageSizes } from '../PageSizeSelector';
import ResetGridColumnWidthsButton from '../ResetGridColumnWidthsButton';
import useFilterStore from '../filter-store';
import PalletAgGridReact from '../pallet-ag-grid/pallet-ag-grid-react';
import { useNewTableFunctionsFeatureFlag } from '../use-new-table-functions-feature-flag';
import {
  changesBetweenTableFields,
  countChangesBetweenFilterModels,
  countChangesBetweenSortModels,
} from '../utils';
import GridActions from './GridActions';
import ConfigureOrderTableHeaders from './components/columns/configure-order-columns';
import { OrdersTableFunctionsContainer } from './components/orders-table-functions-container';
import { OrdersTableFunctionsContainerNew } from './components/orders-table-functions-container-new';
import SelectAllOrders from './components/select-all-orders';
import {
  DefaultFilterTabsConfigs,
  OrderTableFilterModel,
  OrdersTableWithFiltersState,
} from './types';
import {
  getAGGridFilterModel,
  getOrderColumnSortStates,
  getFetchOrdersVariables,
  getOrderSortV2,
  getOrderTableFields,
  getAGGridSortModel,
  getFetchOrdersFilters,
} from './utils';

const DEFAULT_COL_DEF: ColDef<FormattedOrderFragment> = {
  resizable: true,
  suppressMenu: true,
  editable: false,
};

interface OrdersTableProps<T> {
  columnDefinitions: ColDef<FormattedOrderFragment>[];
  pageSize: PageSizes;
  isRowsPerPageSelectionEnabled?: boolean;
  pageType: FilterViewPage;
  defaultFilterTabsConfigs: DefaultFilterTabsConfigs<T>;
  shouldRefresh?: boolean;
  openUploadPdfsModal?: () => void;
  openUploadCsvsModal?: () => void;
  openBillingPartyModal?: () => void;
  shouldRefreshGrid?: boolean;
  setShouldRefreshGrid?: (show: boolean) => void;
  setShouldRefresh?: (show: boolean) => void; // TODO: Eliminate this.
  topRightComponent?: ReactNode;
  handleSelectOrders?: (orders: FormattedOrderFragment[]) => void;
  handleSelectAllOrderUuids?: (orderUuids: string[]) => void;
  handleSelectContact?: (uuid: string | undefined) => void;
  handleSelectContacts?: (uuids: string[] | undefined) => void;
  handleSelectOriginTerminal?: (uuid: string | undefined) => void;
  handleSelectDestinationTerminal?: (uuid: string | undefined) => void;
  handleSelectDate?: (option: DateOption) => void;
  defaultDatePickerFilterType?: DatePickerFilterType;
  bulkActionsEnabled?: boolean;
  handleTabChange?: (tab: T) => void;
  handleFilterChange?: (filters: FilterModel | undefined) => void;
  rowSelectionEnabled?: boolean;
  shouldShowDatePicker?: boolean;
  shouldShowCustomerFilter?: boolean;
  shouldShowCustomerFilterMultiselect?: boolean;
  shouldShowTerminalFilter?: boolean;
  shouldShowOriginTerminalFilter?: boolean;
  shouldShowDestinationTerminalFilter?: boolean;
  shouldShowNoTerminalOptionMultiselect?: boolean;
  shouldAllowSavedViews?: boolean;
  shouldRememberFilters?: boolean;
  shouldShowGenerateReportButtons?: boolean;
  controlBarSpacing?: boolean;
  userOrderTableFields: OrderTableField[];
  userUuid: string | undefined;
  showTotalCount: boolean;
  useMultiSearchText?: boolean;
  handleSortChange?: (orderSorts: OrderSort[]) => void;
  showSelectAllOrders?: boolean;
  terminalFilterCacheId?: string;
  originTerminalFilterCacheId?: string;
  destinationTerminalFilterCacheId?: string;
  shouldShowAddOrderButton?: boolean;
  enableNumberOfOrdersQuery: boolean;
  enableHeaderCheckboxSelection: boolean;
}

export const OrdersTableWithFiltersAgGrid = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  DefaultFilterTabsType extends any,
>({
  columnDefinitions,
  pageSize: initialPageSize,
  isRowsPerPageSelectionEnabled = false,
  pageType,
  defaultFilterTabsConfigs,
  shouldRefresh,
  setShouldRefresh,
  openUploadPdfsModal,
  openUploadCsvsModal,
  openBillingPartyModal,
  shouldRefreshGrid,
  setShouldRefreshGrid,
  topRightComponent,
  handleSelectOrders,
  handleSelectAllOrderUuids,
  handleSelectContact,
  handleSelectContacts,
  handleSelectOriginTerminal,
  handleSelectDestinationTerminal,
  handleSelectDate,
  handleTabChange,
  handleFilterChange,
  defaultDatePickerFilterType,
  bulkActionsEnabled = false,
  rowSelectionEnabled = false,
  shouldShowDatePicker = false,
  shouldShowCustomerFilter = false,
  shouldShowCustomerFilterMultiselect = false,
  shouldShowTerminalFilter = false,
  shouldShowOriginTerminalFilter = false,
  shouldShowDestinationTerminalFilter = false,
  shouldShowNoTerminalOptionMultiselect = false,
  shouldShowGenerateReportButtons = true,
  shouldAllowSavedViews = false,
  shouldRememberFilters = false,
  controlBarSpacing = true,
  userOrderTableFields,
  userUuid,
  showTotalCount,
  useMultiSearchText,
  handleSortChange,
  showSelectAllOrders,
  terminalFilterCacheId,
  originTerminalFilterCacheId,
  destinationTerminalFilterCacheId,
  shouldShowAddOrderButton,
  enableNumberOfOrdersQuery,
  enableHeaderCheckboxSelection,
}: OrdersTableProps<DefaultFilterTabsType>) => {
  const [, setSearchParams] = useSearchParams();
  const gridRef = useRef<AgGridReact<FormattedOrderFragment>>(null);
  const ffShowReratedTotalOnOrdersTable = useFeatureFlag(
    FeatureFlag.FF_SHOW_RERATED_TOTAL_ON_ORDERS_TABLE,
  );

  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);

  const { terminalsEnabled, getTerminalCode } = useTerminals({
    includeInactiveTerminals: false,
  });

  const [
    openedOrderUuid,
    setOpenedOrderUuid,
    orderUuidToRefetch,
    setOrderUuidToRefetch,
    selectedTerminalUuid,
  ] = useGlobalStore(
    (state) => [
      state.currentOrderUuid,
      state.setCurrentOrderUuid,
      state.orderUuidToRefetch,
      state.setOrderUuidToRefetch,
      state.selectedTerminalUuid,
    ],
    shallow,
  );

  const {
    setRememberedFilters,
    rememberedSearch,
    setRememberedSearch,
    rememberedDateOptions,
    setRememberedDateOption,
    rememberedTabs,
  } = useFilterStore((state) => {
    return {
      rememberedSearch: state.search,
      rememberedDateOptions: state.dateOptions,
      rememberedTabs: state.tabs,
      setRememberedFilters: state.setFilters,
      setRememberedSearch: state.setSearch,
      setRememberedDateOption: state.setDateOption,
    };
  });

  const [
    /**
     * This is non-nil if the current view has unsaved changes to the sort model
     * When loading the page, we first check `changedSortModel` for local changes
     * that should override the sort model saved in the DB, otherwise use the DB model
     */
    changedSortModel,
    setChangedSortModel,
  ] = useLocalStorageState<OrderSortV2[] | null>(
    `${ORDERS_CHANGED_SORT_MODEL_KEY}-${pageType}`,
    {
      defaultValue: null,
    },
  );

  const [changedOrderTableFields, setChangedOrderTableFields] =
    useLocalStorageState<OrderTableField[] | null>(
      `${ORDERS_CHANGED_TABLE_COLUMNS_KEY}-${pageType}`,
      {
        defaultValue: null,
      },
    );

  const [, setState, stateRef] = useStateRef<
    OrdersTableWithFiltersState<DefaultFilterTabsType>
  >({
    searchText: shouldRememberFilters ? (rememberedSearch[pageType] ?? '') : '',
    ordersTab: defaultFilterTabsConfigs.defaultTab,
    pageSize: initialPageSize,
    currentTabIsSavedView: false,
    currentTabIsNewView: false,
    currentCursor: null,
    previousCursor: null,
    usePreviousCursor: true,
    customFilterModelJson: {},
    orderTableFilterModel: {},
    customSortModelJson: [],
    orderTableFields:
      (ffEnableNewTableFunctions
        ? changedOrderTableFields
        : userOrderTableFields) ??
      VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS,
    currentSavedViewName: null,
    currentSavedViewUuid: null,
    originTerminalOption: undefined,
    destinationTerminalOption: undefined,
    // Multi-select
    customerOptions: undefined,
    terminalOptions:
      ffEnableNewTableFunctions && !isNil(selectedTerminalUuid)
        ? [
            {
              label: getTerminalCode(selectedTerminalUuid),
              value: selectedTerminalUuid,
            },
          ]
        : undefined,
    dateOption: undefined,
    multipleSearches: [],
    totalCount: undefined,
    ordersByHawb: [],
    datasourceVersionId: 0,
  });

  const [filterModelV2, setFilterModelV2] = useState<OrderTableFilterModel>({});
  const [filters, setFilters] =
    useState<GetOrderTableFieldValuesQueryVariables>();

  // Unfortunately these cannot be derived states because AG Grid column and filter
  // states aren't plugged into React state, we only access them in event handlers.
  const [numFiltersChanged, setNumFiltersChanged] = useState(0);
  const [numSortsChanged, setNumSortsChanged] = useState(0);
  const [numColumnsChanged, setNumColumnsChanged] = useState(0);

  const [savedViewSaveSuccessAlertText, setSavedViewSaveSuccessAlertText] =
    useState<string | undefined>(undefined);
  const [savedViewSaveFailedAlert, setSavedViewSaveFailedAlert] =
    useState(false);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] =
    useState(false);
  const [columnDefs, setColumnDefs] =
    useState<ColDef<FormattedOrderFragment>[]>(columnDefinitions);
  const [tabsNumberOfOrderData, setTabsNumberOfOrderData] = useState<
    { tab: DefaultFilterTabsType; numberOfOrders: number | undefined }[]
  >([]);
  const [selectedUuids, setSelectedUuids] = useState<string[]>([]);
  const [updateUser] = useUpdateUserMutation();
  const { refetchTableFieldsData } = useTableFields();
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  /// /////////////////////////////////////////////////////////////////////////////
  // QUERIES
  /// /////////////////////////////////////////////////////////////////////////////
  const [showConfigureOrderHeaders, setShowConfigureOrderHeaders] =
    useState(false);
  const [updateSavedViewMutation] = useUpdateSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  /**
   * This ref stores the current list of table fields used by the table fetching code,
   * calculated from changedOrderTableFields and stateRef.current.orderTableFields.
   * You shouldn't need to write to this directly – instead, write to changedOrderTableFields
   * or stateRef.current.orderTableFields and let the ref compute itself.
   *
   * The reason we use a ref for the table headers and the feature flag throughout the functions below is because
   * AG grid seems to treat the closures we pass into the AgGridReact component differently than React
   * normally does. As such, we had issues with the closures such as onGridReady having staleness issues with
   * the variables being used inside it (one of which was orderTableHeaders.) Using a ref instead of a prop directly
   * overcomes that issues since the closure, stale or not, maintains the ref within its context and the underlying value
   * of the ref is something we can always control.
   */
  /**
   * This ref is only intended for use in AG Grid closures. Do not pass it to other components, instead use
   * the stateful `orderTableFields` value.
   */
  const orderTableFieldsRef = useRef<OrderTableField[]>([]);
  const { orderTableFields: stateRefOrderTableFields } = stateRef.current;
  const orderTableFields = useMemo(() => {
    const newOrderTableFields = ffEnableNewTableFunctions
      ? (changedOrderTableFields ?? stateRefOrderTableFields)
      : stateRefOrderTableFields;
    orderTableFieldsRef.current = newOrderTableFields;
    return newOrderTableFields;
  }, [
    ffEnableNewTableFunctions,
    changedOrderTableFields,
    stateRefOrderTableFields,
  ]);

  const filterModelV2Ref = useRef(filterModelV2);
  useEffect(() => {
    filterModelV2Ref.current = filterModelV2;
  }, [filterModelV2]);

  const ffEnableNewTableFunctionsRef = useRef(ffEnableNewTableFunctions);
  useEffect(() => {
    ffEnableNewTableFunctionsRef.current = ffEnableNewTableFunctions;
  }, [ffEnableNewTableFunctions]);

  const ffShowReratedTotalOnOrdersTableRef = useRef(
    ffShowReratedTotalOnOrdersTable,
  );
  useEffect(() => {
    ffShowReratedTotalOnOrdersTableRef.current =
      ffShowReratedTotalOnOrdersTable;
  }, [ffShowReratedTotalOnOrdersTable]);

  const showTotalCountRef = useRef(showTotalCount);
  useEffect(() => {
    showTotalCountRef.current = showTotalCount;
  }, [showTotalCount]);

  /**
   * Given a list of order table field headers, updates the column defs for the AG grid to include the header names
   * for each order table field.
   * @param orderTableFieldHeaders
   */
  const setColumnDefinitionHeaderNames = ({
    orderTableFieldHeaders,
  }: {
    orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
  }) => {
    const orderTableFieldHeadersMap = keyBy(
      orderTableFieldHeaders,
      'orderTableField',
    );

    setColumnDefs((prevColumnDefs) =>
      prevColumnDefs.map((columnDef) => {
        if (
          isNil(columnDef.field) ||
          columnDef.field === OrderTableField.TotalChargesWithItemized
        ) {
          return columnDef;
        }

        if (columnDef.field === ExcludeFromHiding.EMPTY_SPACE) {
          return {
            ...columnDef,
            headerCheckboxSelection: enableHeaderCheckboxSelection,
            lockPosition: 'left',
          };
        }

        const fieldHeader = orderTableFieldHeadersMap[columnDef.field];
        if (isNil(fieldHeader) || isEmpty(fieldHeader)) {
          return columnDef;
        }
        if (ffDemoLoadManagement && fieldHeader.header === 'HAWB') {
          return {
            ...columnDef,
            headerName: 'Auth',
            lockPinned: true,
          };
        }

        return {
          ...columnDef,
          headerName: fieldHeader.header,
          lockPinned: true,
        };
      }),
    );
  };

  const fetchOrdersByUuids = async ({ uuids }: { uuids: string[] }) => {
    setIsTableLoading(true);
    try {
      const resOrderTableFieldValues = await apolloClient.query<
        GetOrderTableFieldValuesQuery,
        GetOrderTableFieldValuesQueryVariables
      >({
        query: GetOrderTableFieldValuesDocument,
        variables: {
          getOrderTableFieldValuesInput: {
            first: stateRef.current.pageSize,
            uuids,
            orderTableFields: orderTableFieldsRef.current,
          },
        },
      });

      // If the retrieved rows don't meet the filter
      // critera after changes and are removed, ag-grid will invoke getRows
      // to "fill empty rows." In that case we want to fetch orders using the same
      // cursor we used on the last call to getRows
      setState((prevState) => {
        return {
          ...prevState,
          usePreviousCursor: true,
        };
      });
      const orders =
        resOrderTableFieldValues.data?.getOrderTableFieldValues?.formattedOrderConnection?.edges.map(
          (edge) => edge.node,
        );

      if (isNil(orders) || isEmpty(orders)) {
        gridRef.current?.api.applyServerSideTransaction({
          remove: uuids.map((uuid) => ({ uuid })),
        });
      } else {
        gridRef.current?.api.applyServerSideTransaction({
          update: orders,
        });
      }
      gridRef.current?.api.redrawRows();

      // Update the count on the tabs after an order has potentially changed
      defaultFilterTabsConfigs.tabs.forEach((tab) => {
        // we need to pass the filters we just used when getting the number for the current tab

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getNumberOfOrdersForTab(
          tab.value,
          tab.value === stateRef.current.ordersTab
            ? (filters as Partial<GetOrderTableFieldValuesInput>)
            : tab.filterModel,
        );
      });
    } catch (e) {
      console.error(String(e));
    }
    setIsTableLoading(false);
  };

  const updateTabNumbersOfOrderData = (
    tab: DefaultFilterTabsType,
    numberOfOrders: number | undefined,
  ) => {
    if (!enableNumberOfOrdersQuery && tab !== stateRef.current.ordersTab) {
      return;
    }

    setTabsNumberOfOrderData((prevState) => {
      const newRecord = [...prevState];
      const existingIndex = newRecord.findIndex((record) => record.tab === tab);
      if (existingIndex > -1) {
        newRecord.splice(existingIndex, 1);
      }
      newRecord.push({ tab, numberOfOrders });
      return newRecord;
    });
  };

  const fetchAdditionalValues = async ({
    fetchDriverSettlement,
    fetchTotalChargesWithItemized,
    variables,
    isSearchQuery,
  }: {
    fetchDriverSettlement: boolean;
    fetchTotalChargesWithItemized: boolean;
    variables: GetOrderTableFieldValuesQueryVariables;
    isSearchQuery: boolean;
  }) => {
    let ordersData:
      | OrderTableFieldValuesFormattedOrderConnectionFragment
      | undefined;
    let orderTableFieldHeaders: OrderTableFieldHeaderFragment[] = [];
    setIsTableLoading(true);
    try {
      const additionaOrderTableFields = [];
      if (fetchTotalChargesWithItemized) {
        additionaOrderTableFields.push(
          OrderTableField.TotalChargesWithItemized,
        );
      }
      if (fetchDriverSettlement) {
        additionaOrderTableFields.push(OrderTableField.DriverSettlement);
      }

      if (isSearchQuery) {
        const resOrderTableFieldValues = await apolloClient.query<
          GetOrderTableFieldValuesForSearchQuery,
          GetOrderTableFieldValuesForSearchQueryVariables
        >({
          query: GetOrderTableFieldValuesForSearchDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              orderTableFields: additionaOrderTableFields,
            },
          },
        });

        orderTableFieldHeaders = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.orderTableFieldHeaders;
        ordersData = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.formattedOrderConnection;
      } else {
        const resOrderTableFieldValues = await apolloClient.query<
          GetOrderTableFieldValuesQuery,
          GetOrderTableFieldValuesQueryVariables
        >({
          query: GetOrderTableFieldValuesDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              orderTableFields: additionaOrderTableFields,
            },
          },
        });

        orderTableFieldHeaders = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.orderTableFieldHeaders;
        ordersData = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.formattedOrderConnection;
      }

      setColumnDefinitionHeaderNames({ orderTableFieldHeaders });

      const updatedOrdersWithCharges: FormattedOrderFragment[] = filterNotNil(
        ordersData?.edges.map(({ node: formattedOrder }) => {
          const existingOrderData: FormattedOrderFragment | undefined =
            gridRef.current?.api.getRowNode(formattedOrder.uuid)?.data;

          const { itemizedChargesField, formattedOrderFields } = formattedOrder;
          const itemizedChargesTableField = formattedOrderFields.find(
            (field) =>
              field.orderTableField ===
              OrderTableField.TotalChargesWithItemized,
          );
          const driverSettlementTableField = formattedOrderFields.find(
            (field) =>
              field.orderTableField === OrderTableField.DriverSettlement,
          );

          if (!isNil(existingOrderData)) {
            return {
              ...existingOrderData,
              formattedOrderFields: filterNotNil([
                ...existingOrderData.formattedOrderFields,
                itemizedChargesTableField,
                driverSettlementTableField,
              ]),
              itemizedChargesField,
            };
          }
          return null;
        }),
      );
      if (!isNil(updatedOrdersWithCharges)) {
        gridRef.current?.api.applyServerSideTransaction({
          update: updatedOrdersWithCharges,
        });
        gridRef.current?.api.redrawRows();
      }
    } catch (e) {
      console.error(String(e));
    }
    setIsTableLoading(false);
  };

  const fetchOrderTableValues = async ({
    params,
    variables,
    isSearchQuery,
  }: {
    params: IServerSideGetRowsParams<FormattedOrderFragment>;
    variables: GetOrderTableFieldValuesQueryVariables;
    isSearchQuery: boolean;
  }) => {
    setIsTableLoading(true);

    let ordersData:
      | OrderTableFieldValuesFormattedOrderConnectionFragment
      | undefined;
    let orderTableFieldHeaders: OrderTableFieldHeaderFragment[] = [];
    try {
      const { datasourceVersionId, ordersTab } = stateRef.current;

      /**
       * The query pattern being followed here involves querying an initial set of fields as fast as possible.
       * We achieve this by excluding the more expensive fields listed in the array below and querying them in a second query.
       * This avoids the expensive table fields blocking page load.
       */
      const EXPENSIVE_TABLE_FIELDS: OrderTableField[] = [
        OrderTableField.TotalChargesWithItemized,
        OrderTableField.DriverSettlement,
      ];
      const initialOrderFields = orderTableFieldsRef.current.filter(
        (header) => !EXPENSIVE_TABLE_FIELDS.includes(header),
      );
      // if TotalChargesWithItemized is requested, replace it with Charges and load TotalChargesWithItemized asynchronously per cell
      if (
        orderTableFieldsRef.current.includes(
          OrderTableField.TotalChargesWithItemized,
        ) &&
        !orderTableFieldsRef.current.includes(OrderTableField.Charges)
      ) {
        initialOrderFields.push(OrderTableField.Charges);
      }

      if (isSearchQuery) {
        const resOrderTableFieldValues = apolloClient.query<
          GetOrderTableFieldValuesForSearchQuery,
          GetOrderTableFieldValuesForSearchQueryVariables
        >({
          query: GetOrderTableFieldValuesForSearchDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              totalCount: showTotalCountRef.current,
              orderTableFields: initialOrderFields,
            },
          },
        });

        let resOrderTableValuesForOrdersWithoutStops;
        if (!isEmpty(variables.getOrderTableFieldValuesInput.searchText)) {
          resOrderTableValuesForOrdersWithoutStops = apolloClient.query<
            GetOrderTableFieldValuesForSearchQuery,
            GetOrderTableFieldValuesForSearchQueryVariables
          >({
            query: GetOrderTableFieldValuesForSearchDocument,
            variables: {
              getOrderTableFieldValuesInput: {
                ...variables.getOrderTableFieldValuesInput,
                orderTableFields: orderTableFieldsRef.current,
                searchMiscInfoOnly: true,
              },
            },
          });
        }
        ordersData = (await resOrderTableFieldValues).data
          ?.getOrderTableFieldValues.formattedOrderConnection;
        if (
          (isNil(ordersData) || ordersData.edges.length === 0) &&
          !isNil(resOrderTableValuesForOrdersWithoutStops)
        ) {
          ordersData = (await resOrderTableValuesForOrdersWithoutStops).data
            ?.getOrderTableFieldValues.formattedOrderConnection;
        }

        if (datasourceVersionId !== stateRef.current.datasourceVersionId) {
          params.fail();
          return;
        }

        setState((prevState) => {
          return {
            ...prevState,
            currentCursor: ordersData?.pageInfo.endCursor,
          };
        });

        orderTableFieldHeaders = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.orderTableFieldHeaders;
      } else {
        const resOrderTableFieldValues = apolloClient.query<
          GetOrderTableFieldValuesQuery,
          GetOrderTableFieldValuesQueryVariables
        >({
          query: GetOrderTableFieldValuesDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              totalCount: showTotalCountRef.current,
              orderTableFields: initialOrderFields,
            },
          },
        });

        let resOrderTableValuesForOrdersWithoutStops;
        if (!isEmpty(variables.getOrderTableFieldValuesInput.searchText)) {
          resOrderTableValuesForOrdersWithoutStops = apolloClient.query<
            GetOrderTableFieldValuesQuery,
            GetOrderTableFieldValuesQueryVariables
          >({
            query: GetOrderTableFieldValuesDocument,
            variables: {
              getOrderTableFieldValuesInput: {
                ...variables.getOrderTableFieldValuesInput,
                orderTableFields: orderTableFieldsRef.current,
                searchMiscInfoOnly: true,
              },
            },
          });
        }
        ordersData = (await resOrderTableFieldValues).data
          ?.getOrderTableFieldValues.formattedOrderConnection;
        if (
          (isNil(ordersData) || ordersData.edges.length === 0) &&
          !isNil(resOrderTableValuesForOrdersWithoutStops)
        ) {
          ordersData = (await resOrderTableValuesForOrdersWithoutStops).data
            ?.getOrderTableFieldValues.formattedOrderConnection;
        }

        if (datasourceVersionId !== stateRef.current.datasourceVersionId) {
          params.fail();
          return;
        }

        setState((prevState) => {
          return {
            ...prevState,
            currentCursor: ordersData?.pageInfo.endCursor,
          };
        });

        orderTableFieldHeaders = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.orderTableFieldHeaders;
      }

      setColumnDefinitionHeaderNames({ orderTableFieldHeaders });

      const orders = ordersData?.edges.map((edge) => edge.node) ?? [];
      const totalOrderCount = ordersData?.totalCount ?? undefined;

      params.success({
        rowData: orders ?? [],
        rowCount: totalOrderCount,
      });
      setState((prevState) => {
        return {
          ...prevState,
          totalCount: totalOrderCount,
          currentCursor: ordersData?.pageInfo.endCursor,
          previousCursor: variables.getOrderTableFieldValuesInput.after,
          usePreviousCursor: false,
        };
      });

      if (!isNil(totalOrderCount)) {
        updateTabNumbersOfOrderData(
          ordersTab as DefaultFilterTabsType,
          totalOrderCount,
        );
      }

      // need to reselect all the nodes
      if (rowSelectionEnabled || bulkActionsEnabled) {
        const uuids = gridRef.current?.api
          .getSelectedRows()
          ?.map((rowData) => rowData.uuid);
        if (!isNil(uuids) && !isEmpty(uuids)) {
          gridRef.current?.api.forEachNode((node) => {
            const uuid = node.data?.uuid;
            if (!isNil(uuid) && uuids.includes(uuid)) {
              node.setSelected(true);
            }
          });
        }
      }

      // if FF is enabled, lazy load the itemized charges to display in list
      const shouldFetchTotalChargesWithItemized =
        orderTableFieldsRef.current.includes(
          OrderTableField.TotalChargesWithItemized,
        ) && ffShowReratedTotalOnOrdersTableRef.current;
      const shouldFetchDriverSettlement = orderTableFieldsRef.current.includes(
        OrderTableField.DriverSettlement,
      );
      if (shouldFetchTotalChargesWithItemized || shouldFetchDriverSettlement) {
        await fetchAdditionalValues({
          variables,
          fetchTotalChargesWithItemized: shouldFetchTotalChargesWithItemized,
          fetchDriverSettlement: shouldFetchDriverSettlement,
          isSearchQuery,
        });
      }
    } catch (e) {
      console.error(String(e));
    }
    setIsTableLoading(false);
  };

  const createServerSideDatasource = () => {
    return {
      getRows(params: IServerSideGetRowsParams<FormattedOrderFragment>) {
        let currentOrdersTab = stateRef.current.ordersTab;
        if (
          !defaultFilterTabsConfigs.tabs
            .map((tab) => tab.value)
            .includes(currentOrdersTab as DefaultFilterTabsType)
        ) {
          currentOrdersTab = defaultFilterTabsConfigs.baseTab;
        }
        const { sortModel, filterModel } = params.request;
        const agGridFilterModelV2 = getAGGridFilterModel(
          filterModelV2Ref.current ?? {},
        );

        const uiFilterOptions = {
          terminalOptions: stateRef.current.terminalOptions,
          customerOptions: stateRef.current.customerOptions,
          originTerminalOption: stateRef.current.originTerminalOption,
          destinationTerminalOption: stateRef.current.destinationTerminalOption,
          dateOption: stateRef.current.dateOption,
        };

        let variables = getFetchOrdersVariables({
          currentOrdersTab,
          defaultTabsConfigs: defaultFilterTabsConfigs,
          filterModel: ffEnableNewTableFunctionsRef.current
            ? agGridFilterModelV2
            : filterModel,
          sortModel,
          uiFilterOptions,
          ffEnableNewTableFunctions: ffEnableNewTableFunctionsRef.current,
        });

        if (!isNil(handleSortChange)) {
          const sorts =
            !isNil(variables.getOrderTableFieldValuesInput.sorts) &&
            !isArray(variables.getOrderTableFieldValuesInput.sorts)
              ? [variables.getOrderTableFieldValuesInput.sorts]
              : variables.getOrderTableFieldValuesInput.sorts;
          handleSortChange(sorts ?? []);
        }

        setFilters(variables);
        const searchText = stateRef.current.searchText?.trim();
        variables = {
          getOrderTableFieldValuesInput: {
            ...variables.getOrderTableFieldValuesInput,
            first: stateRef.current.pageSize,
            after: stateRef.current.usePreviousCursor
              ? stateRef.current.previousCursor
              : stateRef.current.currentCursor,
            searchText: useMultiSearchText === true ? undefined : searchText,
            multipleSearches:
              useMultiSearchText === true
                ? stateRef.current.multipleSearches
                : undefined,
            useMultiSearch: useMultiSearchText === true,
          },
        };

        fetchOrderTableValues({
          params,
          variables,
          isSearchQuery: !isNilOrEmptyString(searchText),
        });
      },
    };
  };

  const getNumberOfOrdersForTab = async (
    tab: DefaultFilterTabsType,
    tabFilterModel: DefaultFilterTabsConfigs<DefaultFilterTabsType>['tabs'][number]['filterModel'],
  ) => {
    if (!enableNumberOfOrdersQuery) {
      return;
    }
    setIsTableLoading(true);
    try {
      if (!enableNumberOfOrdersQuery && tab !== stateRef.current.ordersTab) {
        return;
      }

      const uiFilterOptions = {
        terminalOptions: stateRef.current.terminalOptions,
        customerOptions: stateRef.current.customerOptions,
        originTerminalOption: stateRef.current.originTerminalOption,
        destinationTerminalOption: stateRef.current.destinationTerminalOption,
        dateOption: stateRef.current.dateOption,
      };

      const agGridFilterModelV2 = getAGGridFilterModel(tabFilterModel ?? {});
      const filterModel = gridRef.current?.api?.getFilterModel() ?? {};
      const filtersInput = getFetchOrdersFilters({
        currentOrdersTab: tab,
        defaultTabsConfigs: defaultFilterTabsConfigs,
        filterModel: ffEnableNewTableFunctionsRef.current
          ? agGridFilterModelV2
          : filterModel,
        uiFilterOptions,
        ffEnableNewTableFunctions: ffEnableNewTableFunctionsRef.current,
      });

      // using apollo client query because of this issue: https://github.com/apollographql/apollo-client/issues/9755
      const res = await apolloClient.query<
        GetOrderTableFieldValuesForCountQuery,
        GetOrderTableFieldValuesForCountQueryVariables
      >({
        query: GetOrderTableFieldValuesForCountDocument,
        variables: {
          getOrderTableFieldValuesInput: {
            ...(ffEnableNewTableFunctionsRef.current === true
              ? { filters: filtersInput }
              : filtersInput),
            noMaximum: true,
            orderTableFields: [],
            totalCount: true,
            includeOrders: false,
          },
        },
      });

      const totalCount =
        res.data?.getOrderTableFieldValues?.formattedOrderConnection
          ?.totalCount ?? undefined;

      updateTabNumbersOfOrderData(tab, totalCount);
    } catch (e) {
      console.error(String(e));
    }
    setIsTableLoading(false);
  };

  const handleRowSelected = useCallback(() => {
    const selectedRows = uniqBy(gridRef.current?.api.getSelectedRows(), 'uuid');
    setSelectedUuids(selectedRows.map((row) => row.uuid));
    if (!isNil(handleSelectOrders)) {
      handleSelectOrders(selectedRows);
    }
  }, [handleSelectOrders]);

  const deselectAll = useCallback(() => {
    gridRef.current?.api.deselectAll();
    handleRowSelected();
  }, [handleRowSelected]);

  const selectAllOrdersOnPage = () => {
    let nodes: IRowNode<FormattedOrderFragment>[] = [];
    // getRenderedNodes won't work here: if the page size is large enough
    // that the list is virtualized, it will only select the visible rows on the page
    gridRef.current?.api.forEachNode((node) => {
      nodes.push(node);
    });
    // If the user visited another page, we need to filter out nodes from that page
    const currentPage = gridRef.current?.api.paginationGetCurrentPage();
    if (!isNil(currentPage)) {
      const startIndex = currentPage * stateRef.current.pageSize;
      const endIndex = startIndex + stateRef.current.pageSize;
      nodes = nodes.slice(startIndex, endIndex);
    }
    // Newer versions of AG Grid have `setNodesSelected` that we can use instead
    nodes.forEach((node) => {
      node.setSelected(true);
    });
    handleRowSelected();
  };

  const handleSelectionChanged = (
    event: SelectionChangedEvent<FormattedOrderFragment>,
  ) => {
    if (event.source === 'uiSelectAll') {
      if (isHeaderCheckboxSelected) {
        deselectAll();
      } else {
        selectAllOrdersOnPage();
      }
      setIsHeaderCheckboxSelected((prev) => !prev);
    }
  };

  /** Selects all orders, including on other pages */
  const handleSelectAllOrders = (uuids: string[]) => {
    setSelectedUuids(uuids);
    if (!isNil(handleSelectAllOrderUuids)) {
      handleSelectAllOrderUuids(uuids);
    }
  };

  const handlePaginationChanged = () => {
    if (isHeaderCheckboxSelected) {
      selectAllOrdersOnPage();
    }
  };

  // call this if we want to refresh the grid (filters change, etc.)
  const refreshGrid = useCallback(
    (shouldDeselectCheckboxes = true, refreshServerSide = true) => {
      if (!isNil(gridRef.current?.api)) {
        setState((prevState) => ({
          ...prevState,
          totalCount: undefined,
          datasourceVersionId: prevState.datasourceVersionId + 1,
        }));
        if (shouldDeselectCheckboxes) {
          deselectAll();
          if (isHeaderCheckboxSelected) {
            setIsHeaderCheckboxSelected(false);
          }
          if (!isNil(handleSelectOrders)) {
            handleSelectOrders([]);
          }
        }
        if (handleFilterChange) {
          handleFilterChange(gridRef.current?.api?.getFilterModel());
        }
        if (refreshServerSide) {
          gridRef.current?.api.refreshServerSide({ purge: true });
        }
        gridRef.current?.api.paginationGoToFirstPage();
        gridRef.current?.api.hideOverlay();
      }
    },
    [
      setState,
      deselectAll,
      isHeaderCheckboxSelected,
      handleSelectOrders,
      handleFilterChange,
    ],
  );

  const computeNumFiltersChanged = useCallback(
    (filterModel: OrderTableFilterModel | FilterModel | undefined) => {
      const currentFilterModel = ffEnableNewTableFunctions
        ? stateRef.current.orderTableFilterModel
        : stateRef.current.customFilterModelJson;

      const changesFromCurrentFilterModel = countChangesBetweenFilterModels(
        currentFilterModel,
        filterModel,
      );

      setNumFiltersChanged(changesFromCurrentFilterModel);
    },
    [ffEnableNewTableFunctions, stateRef],
  );

  const computeNumSortsChanged = useCallback(
    (sortModel: OrderSortV2[] | null): number => {
      if (!ffEnableNewTableFunctions) {
        return 0;
      }
      const changesFromCurrentSortModel = countChangesBetweenSortModels(
        stateRef.current.customSortModelJson,
        sortModel ?? [],
      );
      if (changesFromCurrentSortModel > 0) {
        setChangedSortModel(sortModel ?? null);
      } else {
        setChangedSortModel(null);
      }
      setNumSortsChanged(changesFromCurrentSortModel);
      return changesFromCurrentSortModel;
    },
    [stateRef, ffEnableNewTableFunctions, setChangedSortModel],
  );

  const computeNumColumnsChanged = useCallback(
    (tableFields: OrderTableField[] | null): number => {
      if (!ffEnableNewTableFunctions) {
        return 0;
      }
      const changesFromCurrentTableFields = changesBetweenTableFields(
        stateRef.current.orderTableFields,
        tableFields,
      );

      setNumColumnsChanged(changesFromCurrentTableFields);

      if (changesFromCurrentTableFields > 0) {
        setChangedOrderTableFields(tableFields);
      } else {
        setChangedOrderTableFields(null);
      }

      return changesFromCurrentTableFields;
    },
    [stateRef, ffEnableNewTableFunctions, setChangedOrderTableFields],
  );

  const applyFilterModel = useCallback(
    (orderTableFilterModel: OrderTableFilterModel | null) => {
      if (ffEnableNewTableFunctions) {
        setFilterModelV2(orderTableFilterModel ?? {});
        // We used to call this in handleFilterChanged, but since we're not storing
        // filters in AG Grid anymore, handleFilterChanged never runs
        if (shouldRememberFilters) {
          setRememberedFilters(
            JSON.stringify(orderTableFilterModel ?? {}),
            pageType,
          );
        }

        // TODO: We might be able to remove this
        refreshGrid();
      } else {
        const agGridFilterModel = !isNil(orderTableFilterModel)
          ? getAGGridFilterModel(orderTableFilterModel)
          : {};
        gridRef.current?.api?.setFilterModel(agGridFilterModel);
      }
      computeNumFiltersChanged(orderTableFilterModel ?? undefined);
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
    },
    [
      ffEnableNewTableFunctions,
      computeNumFiltersChanged,
      setState,
      shouldRememberFilters,
      refreshGrid,
      setRememberedFilters,
      pageType,
    ],
  );

  const legacyApplyFilterModel = useCallback(
    (filterModel: FilterModel | null) => {
      gridRef.current?.api?.setFilterModel(filterModel);

      computeNumFiltersChanged(filterModel ?? undefined);
    },
    [computeNumFiltersChanged],
  );

  const applySortModel = useCallback(
    (sortModel: OrderSortV2[]) => {
      gridRef.current?.columnApi?.applyColumnState({
        state: getOrderColumnSortStates(sortModel),
        defaultState: { sort: null },
      });
      computeNumSortsChanged(sortModel);
    },
    [computeNumSortsChanged],
  );

  /**
   * Restore tabs from Zustand store after switching pages (but
   * without falling back to local storage)
   */
  const handleRememberedTabs = () => {
    const rememberedTab = rememberedTabs[pageType];
    if (!isNil(rememberedTab)) {
      if (!isNil(rememberedTab.default)) {
        const parsedTab = JSON.parse(rememberedTab.default);
        setState((prevState) => ({
          ...prevState,
          currentTabIsSavedView: false,
          currentTabIsNewView: false,
          ordersTab: parsedTab.value as DefaultFilterTabsType,
          currentCursor: null,
          currentSavedViewUuid: null,
          currentSavedViewName: null,
        }));
      } else if (!isNil(rememberedTab.custom)) {
        const parsedTab = JSON.parse(rememberedTab.custom);
        setState((prevState) => ({
          ...prevState,
          currentTabIsSavedView: true,
          currentTabIsNewView: false,
          ordersTab: parsedTab.uuid,
          currentCursor: null,
          currentSavedViewUuid: parsedTab.uuid,
          currentSavedViewName: parsedTab.displayName,
        }));
      }
    }
  };

  useEffect(() => {
    setColumnDefs(columnDefinitions);
  }, [columnDefinitions]);

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.closeToolPanel();
    if (shouldRememberFilters) {
      handleRememberedTabs();
      const dateOption = rememberedDateOptions[pageType];
      if (!isNil(dateOption)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleDatePickerChange(dateOption);
      }
    }
  };

  useEffect(() => {
    if (shouldRefreshGrid === true && !isNil(setShouldRefreshGrid)) {
      defaultFilterTabsConfigs.tabs.forEach((tab) => {
        getNumberOfOrdersForTab(tab.value, tab.filterModel);
      });
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
      refreshGrid();
      setShouldRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGrid]);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: false,
            suppressFilterSearch: false,
          },
        },
      ],
      defaultToolPanel: 'filters',
      position: 'left',
    };
  }, []);

  const populateTableFromConfig = useCallback(
    async (newFields: OrderTableField[]) => {
      setColumnDefs((prevColumnDefs) => {
        // Initially hide ALL data columns
        const updatedColumnDefs = prevColumnDefs.map((columnDef) => ({
          ...columnDef,
          hide:
            columnDef.field !== ExcludeFromHiding.BUTTON &&
            columnDef.field !== ExcludeFromHiding.EMPTY_SPACE,
        }));
        const visibleColumns = filterNotNil(
          newFields.map((field) => {
            const prevIndex = prevColumnDefs.findIndex(
              (def) =>
                sentenceCase(def.field ?? '').toLowerCase() ===
                sentenceCase(field).toLowerCase(),
            );
            const updatedColumnDefForField = updatedColumnDefs[prevIndex];
            if (!isNil(updatedColumnDefForField)) {
              updatedColumnDefForField.hide = false;
            }
            return updatedColumnDefForField;
          }),
        );
        const hiddenColumns = updatedColumnDefs.filter(
          (columnDef) =>
            !visibleColumns.some(
              (tableCol) => tableCol.field === columnDef.field,
            ) && columnDef.checkboxSelection !== true,
        );
        const checkboxCol = updatedColumnDefs.find(
          (columnDef) => columnDef.checkboxSelection === true,
        );

        const newColumns = filterNotNil([
          checkboxCol,
          ...visibleColumns,
          // Move hidden columns to the end, but don't delete them from
          // columnDefs in case we need them later
          ...hiddenColumns,
        ]);

        return newColumns;
      });
    },
    [],
  );

  useEffect(() => {
    if (!terminalsEnabled && !isNil(gridRef.current?.columnApi)) {
      gridRef.current?.columnApi.setColumnsVisible(
        terminalOnlyOrderFields,
        false,
      );
    }
  }, [terminalsEnabled, gridRef.current?.columnApi]);

  useEffect(() => {
    populateTableFromConfig(orderTableFieldsRef.current);
    refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefinitions]);

  {
    // Legacy code, can be removed once ffEnableNewTableFunctions is enabled everywhere
    const { orderTableFields: currentOrderTableFields } = stateRef.current;
    useEffect(() => {
      if (ffEnableNewTableFunctions) {
        return;
      }
      populateTableFromConfig(currentOrderTableFields);
    }, [
      populateTableFromConfig,
      ffEnableNewTableFunctions,
      currentOrderTableFields,
    ]);
  }

  const setTableColumns = useCallback(
    (fields: OrderTableField[]) => {
      populateTableFromConfig(fields);
      computeNumColumnsChanged(fields);
      (setShouldRefreshGrid ?? setShouldRefresh)?.(true);
    },
    [
      populateTableFromConfig,
      computeNumColumnsChanged,
      setShouldRefresh,
      setShouldRefreshGrid,
    ],
  );

  const userFieldForTableFields =
    pageType === FilterViewPage.Orders ||
    pageType === FilterViewPage.OrdersAudit
      ? 'orderTableFields'
      : 'billingReviewOrderTableFields';

  const { currentSavedViewUuid } = stateRef.current;
  const onSaveOrderTableColumns = useCallback(
    async (fields: OrderTableField[]) => {
      // We only want to update the user's personal list of order table fields
      // if the current view is a default view.
      if (
        (!ffEnableNewTableFunctions || isNil(currentSavedViewUuid)) &&
        !isNil(userUuid)
      ) {
        await updateUser({
          variables: {
            updateUserInput: {
              uuid: userUuid,
              [userFieldForTableFields]: fields,
            },
          },
        });
        refetchTableFieldsData();
        setState((prevState) => ({
          ...prevState,
          orderTableFields: fields,
        }));
      }
      if (ffEnableNewTableFunctions) {
        if (!isNil(currentSavedViewUuid)) {
          // Default views all share the user's personal list of order table fields,
          // which is saved as soon as the order table fields are changed.
          setChangedOrderTableFields(fields);
        }
        setTableColumns(fields);
      }
      (setShouldRefreshGrid ?? setShouldRefresh)?.(true);
    },
    [
      ffEnableNewTableFunctions,
      setChangedOrderTableFields,
      setTableColumns,
      setState,
      refetchTableFieldsData,
      currentSavedViewUuid,
      userFieldForTableFields,
      userUuid,
      updateUser,
      setShouldRefreshGrid,
      setShouldRefresh,
    ],
  );

  useEffect(() => {
    if (shouldRefresh === true) {
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
      refreshGrid();
      if (setShouldRefresh) {
        setShouldRefresh(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  useEffect(() => {
    if (!isNil(handleTabChange)) {
      setIsHeaderCheckboxSelected(false);
      handleTabChange(stateRef.current.ordersTab as DefaultFilterTabsType);
    }
    refreshGrid(true, pageType !== FilterViewPage.Orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.ordersTab]);

  useEffect(() => {
    /**
     * The timeout here is to ensure that the initial order table values query is invoked prior to the
     * numberOfOrders queries. There seems to be some resource contention on the Node.js level. if the query is called in the reverse
     * order and this leads to a significantly slower initial page load.
     */
    const numberOfOrdersQueryTimeout = setTimeout(() => {
      defaultFilterTabsConfigs.tabs.forEach((tab) => {
        getNumberOfOrdersForTab(tab.value, tab.filterModel);
      });
    }, 500);

    return () => {
      clearTimeout(numberOfOrdersQueryTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultFilterTabsConfigs,
    stateRef.current.terminalOptions,
    stateRef.current.originTerminalOption,
    stateRef.current.destinationTerminalOption,
    stateRef.current.customerOptions,
    stateRef.current.dateOption,
    shouldRefresh,
  ]);

  useEffect(() => {
    if (isNil(openedOrderUuid) && !isNil(orderUuidToRefetch)) {
      fetchOrdersByUuids({ uuids: [orderUuidToRefetch] });
      setOrderUuidToRefetch(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOrderUuid, orderUuidToRefetch]);

  // callback which AG Grid calls when a column moves.
  const handleColumnMoved = async (e: ColumnMovedEvent) => {
    if (e.source === 'uiColumnMoved' && e.finished) {
      const updatedOrderFields = getOrderTableFields(gridRef.current);

      computeNumColumnsChanged(updatedOrderFields);
      setChangedOrderTableFields(updatedOrderFields);

      // We only want to update the user's personal list of order table fields
      // if the current view is a default view.
      if (
        (!ffEnableNewTableFunctions || isNil(currentSavedViewUuid)) &&
        !isNil(userUuid)
      ) {
        await updateUser({
          variables: {
            updateUserInput: {
              uuid: userUuid,
              [userFieldForTableFields]: updatedOrderFields,
            },
          },
        });
      }

      // Some side effect causes the grid to re render when columns are moved
      // so here we ensure to refetch the same set of orders that were originally fetched
      // and currently displayed
      setState((prevState) => {
        return {
          ...prevState,
          usePreviousCursor: true,
        };
      });
    }
  };

  const handleUpdateExistingSavedView = useCallback(
    async ({ isAutoSave = false }: { isAutoSave?: boolean }) => {
      const filterModel: FilterModel | undefined = ffEnableNewTableFunctions
        ? (filterModelV2Ref.current ?? {})
        : gridRef.current?.api.getFilterModel();

      const orderSortV2 = getOrderSortV2(gridRef.current);
      try {
        if (isNil(currentSavedViewUuid)) {
          throw new Error(
            '[saving saved view] trying to save to null saved view UUID',
          );
        }
        await updateSavedViewMutation({
          variables: {
            updateSavedFilterViewInput: {
              uuid: currentSavedViewUuid,
              filterModelJson: JSON.stringify(filterModel ?? {}),
              sortModelJson: ffEnableNewTableFunctions
                ? (orderSortV2 ?? [])
                : [],
              orderTableFields: ffEnableNewTableFunctions
                ? orderTableFields
                : undefined,
            },
          },
          refetchQueries: [GetSavedFilterViewsDocument],
        });
        setNumFiltersChanged(0);
        setNumSortsChanged(0);
        setNumColumnsChanged(0);
        setChangedSortModel(null);
        setChangedOrderTableFields(null);
        setState((prevState) => {
          return {
            ...prevState,
            ...(ffEnableNewTableFunctions
              ? {
                  orderTableFilterModel:
                    (filterModel as OrderTableFilterModel) ?? {},
                  orderTableFields,
                }
              : { customFilterModelJson: filterModel ?? {} }),
            customSortModelJson: orderSortV2 ?? [],
          };
        });
        if (isAutoSave) {
          setSavedViewSaveSuccessAlertText('View auto-saved');
        } else {
          setSavedViewSaveSuccessAlertText('View saved');
        }
      } catch (err) {
        console.error('Error saving filter view', err);
        setSavedViewSaveFailedAlert(true);
      }
    },
    [
      currentSavedViewUuid,
      orderTableFields,
      ffEnableNewTableFunctions,
      updateSavedViewMutation,
      setNumFiltersChanged,
      setNumSortsChanged,
      setNumColumnsChanged,
      setChangedSortModel,
      setChangedOrderTableFields,
      setState,
    ],
  );

  // callback which AG Grid calls when a filter changes.
  const handleFilterChanged = () => {
    const filterModel: FilterModel | undefined = ffEnableNewTableFunctions
      ? (filterModelV2Ref.current ?? {})
      : gridRef.current?.api.getFilterModel();

    if (shouldRememberFilters && !ffEnableNewTableFunctions) {
      setRememberedFilters(JSON.stringify(filterModel ?? {}), pageType);
    }

    if (stateRef.current.currentTabIsNewView) {
      handleUpdateExistingSavedView({ isAutoSave: true });
      stateRef.current.currentTabIsNewView = false;
    } else {
      computeNumFiltersChanged(filterModel);

      // null out cursor
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
    }
    if (!isNil(handleFilterChange)) {
      handleFilterChange(filterModel);
    }
  };

  const handleSortChanged = () => {
    refreshGrid();
    const orderSortV2 = ffEnableNewTableFunctions
      ? getOrderSortV2(gridRef.current)
      : null;
    const changesFromOriginalSortModel = computeNumSortsChanged(
      orderSortV2 ?? null,
    );
    if (changesFromOriginalSortModel > 0) {
      setChangedSortModel(orderSortV2 ?? null);
    } else {
      setChangedSortModel(null);
    }
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
  };

  /// /////////////////////////////////////////////////////////////////////////////

  const handleSearch = useCallback(
    (searchText?: string) => {
      if (shouldRememberFilters) {
        setRememberedSearch(searchText ?? '', pageType);
      }
      setState((prevState) => {
        return {
          ...prevState,
          searchText: searchText ?? '',
          currentCursor: null,
        };
      });
      if (
        stateRef.current.searchText.length > 0 &&
        !stateRef.current.multipleSearches.includes(
          stateRef.current.searchText,
        ) &&
        useMultiSearchText === true
      ) {
        setState((prevState) => {
          return {
            ...prevState,
            multipleSearches: [
              ...prevState.multipleSearches,
              stateRef.current.searchText,
            ],
          };
        });
      }
      refreshGrid(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRememberFilters, pageType],
  );

  const handleCustomerChange = (option: Option | PrefixOption | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        // eslint-disable-next-line no-nested-ternary
        customerOptions: isPrefixOption(option)
          ? option
          : !isNil(option)
            ? [option]
            : undefined,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectContact)) {
      handleSelectContact(
        isSingleCustomerOption(option) ? option.value : undefined,
      );
    }
    refreshGrid();
  };

  // Multi-select
  const handleCustomersChange = (
    options: Option[] | PrefixOption | null | undefined,
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        customerOptions: options,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectContacts)) {
      handleSelectContacts(
        !isPrefixOption(options) ? options?.map((option) => option.value) : [],
      );
    }
    refreshGrid();
  };

  const { terminalOptions: currentTerminalOptions } = stateRef.current;
  const currentTerminalOption = currentTerminalOptions?.[0];
  useEffect(() => {
    const terminalCode = getTerminalCode(selectedTerminalUuid);
    if (
      ffEnableNewTableFunctions &&
      (selectedTerminalUuid !== currentTerminalOption?.value ||
        terminalCode !== currentTerminalOption?.label)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          terminalOptions: !isNil(selectedTerminalUuid)
            ? [
                {
                  label: terminalCode,
                  value: selectedTerminalUuid,
                },
              ]
            : undefined,
          currentCursor: null,
        };
      });
      refreshGrid();
    }
  }, [
    selectedTerminalUuid,
    ffEnableNewTableFunctions,
    currentTerminalOption,
    getTerminalCode,
    setState,
    refreshGrid,
  ]);

  const handleTerminalsChange = (options: Option[] | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        terminalOptions: options,
        currentCursor: null,
      };
    });
    refreshGrid();
  };

  const handleOriginTerminalChange = (option: Option | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        originTerminalOption: option,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectOriginTerminal)) {
      handleSelectOriginTerminal(option?.value);
    }
    refreshGrid();
  };

  const handleDestinationTerminalChange = (
    option: Option | null | undefined,
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        destinationTerminalOption: option,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectDestinationTerminal)) {
      handleSelectDestinationTerminal(option?.value);
    }
    refreshGrid();
  };

  const handleDatePickerChange = (option: DateOption) => {
    setState((prevState) => {
      return {
        ...prevState,
        dateOption: option,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectDate)) {
      handleSelectDate(option);
    }
    if (shouldRememberFilters) {
      setRememberedDateOption(option, pageType);
    }
    refreshGrid();
  };

  // TODO: Replace this with the right output for the table report (right now, the output shape is acceptable because it is the same shape as the query variables for the getOrderTableFieldValues query
  const buildOrderTableReportInput =
    (): GetOrderTableFieldValuesQueryVariables => {
      const agGridFilterModelV2 = getAGGridFilterModel(
        filterModelV2Ref.current ?? {},
      );
      const filterModel = gridRef.current?.api?.getFilterModel() ?? {};
      const sortModel = !isNil(gridRef.current)
        ? getAGGridSortModel(gridRef.current)
        : undefined;

      const uiFilterOptions = {
        terminalOptions: stateRef.current.terminalOptions,
        customerOptions: stateRef.current.customerOptions,
        originTerminalOption: stateRef.current.originTerminalOption,
        destinationTerminalOption: stateRef.current.destinationTerminalOption,
        dateOption: stateRef.current.dateOption,
      };

      const variables = getFetchOrdersVariables({
        currentOrdersTab: stateRef.current.ordersTab,
        defaultTabsConfigs: defaultFilterTabsConfigs,
        filterModel: ffEnableNewTableFunctionsRef.current
          ? agGridFilterModelV2
          : filterModel,
        sortModel,
        uiFilterOptions,
        ffEnableNewTableFunctions: ffEnableNewTableFunctionsRef.current,
      });

      return variables;
    };

  const bulkActionsOverLimit =
    (isEmpty(selectedUuids) && isNil(stateRef.current.totalCount)) ||
    (!isEmpty(selectedUuids)
      ? selectedUuids.length
      : (stateRef.current.totalCount ?? 10000)) > 400;

  const onPageSizeChange = useCallback(
    (value: PageSizes) => {
      setState((prevState) => {
        return {
          ...prevState,
          pageSize: value,
          currentCursor: null,
        };
      });
      refreshGrid();
    },
    [setState, refreshGrid],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <GridActions>
        {isRowsPerPageSelectionEnabled && (
          <PageSizeSelector
            initialPageSize={stateRef.current.pageSize}
            onPageSizeChange={onPageSizeChange}
          />
        )}
        {gridRef.current?.columnApi && (
          <ResetGridColumnWidthsButton columnApi={gridRef.current?.columnApi} />
        )}
      </GridActions>
      {showConfigureOrderHeaders && (
        <ConfigureOrderTableHeaders
          open={showConfigureOrderHeaders}
          setOpen={setShowConfigureOrderHeaders}
          initialFields={
            ffEnableNewTableFunctions
              ? orderTableFields
              : getOrderTableFields(gridRef.current)
          }
          onSaveOrderTableColumns={onSaveOrderTableColumns}
        />
      )}
      {ffEnableNewTableFunctions ? (
        <OrdersTableFunctionsContainerNew<DefaultFilterTabsType>
          gridRef={gridRef}
          stateRef={stateRef}
          setState={setState}
          defaultFilterTabsConfigs={defaultFilterTabsConfigs}
          columnDefs={columnDefs}
          orderTableFields={orderTableFields}
          defaultViewOrderTableFields={userOrderTableFields}
          onSaveOrderTableColumns={onSaveOrderTableColumns}
          getOrderTableFieldValuesInput={filters?.getOrderTableFieldValuesInput}
          changedSortModel={changedSortModel}
          setChangedSortModel={setChangedSortModel}
          changedOrderTableFields={changedOrderTableFields}
          setChangedOrderTableFields={setChangedOrderTableFields}
          refreshGrid={refreshGrid}
          applyFilterModel={applyFilterModel}
          applySortModel={applySortModel}
          setTableColumns={setTableColumns}
          enableNumberOfOrdersQuery={enableNumberOfOrdersQuery}
          controlBarSpacing={controlBarSpacing}
          pageType={pageType}
          topRightComponent={topRightComponent}
          tabsNumberOfOrderData={tabsNumberOfOrderData}
          handleUpdateExistingSavedView={handleUpdateExistingSavedView}
          numFiltersChanged={numFiltersChanged}
          setNumFiltersChanged={setNumFiltersChanged}
          numSortsChanged={numSortsChanged}
          setNumSortsChanged={setNumSortsChanged}
          numColumnsChanged={numColumnsChanged}
          setNumColumnsChanged={setNumColumnsChanged}
          savedViewSaveSuccessAlertText={savedViewSaveSuccessAlertText}
          setSavedViewSaveSuccessAlertText={setSavedViewSaveSuccessAlertText}
          savedViewSaveFailedAlert={savedViewSaveFailedAlert}
          setSavedViewSaveFailedAlert={setSavedViewSaveFailedAlert}
          shouldShowDatePicker={shouldShowDatePicker}
          defaultDatePickerFilterType={defaultDatePickerFilterType}
          handleDatePickerChange={handleDatePickerChange}
          handleSearch={handleSearch}
          multipleSearches={stateRef.current.multipleSearches}
          bulkActionsEnabled={bulkActionsEnabled}
          bulkActionsOverLimit={bulkActionsOverLimit}
          selectedUuids={selectedUuids}
          deselectAll={deselectAll}
          shouldAllowSavedViews={shouldAllowSavedViews}
          shouldRememberFilters={shouldRememberFilters}
          buildOrderTableReportInput={buildOrderTableReportInput}
          shouldShowGenerateReportButtons={shouldShowGenerateReportButtons}
          filterModelV2={filterModelV2}
          setFilterModelV2={applyFilterModel}
          shouldShowAddOrderButton={shouldShowAddOrderButton}
        />
      ) : (
        <OrdersTableFunctionsContainer<DefaultFilterTabsType>
          gridRef={gridRef}
          stateRef={stateRef}
          setState={setState}
          defaultFilterTabsConfigs={defaultFilterTabsConfigs}
          orderTableFields={orderTableFields}
          columnDefs={columnDefs}
          getOrderTableFieldValuesInput={filters?.getOrderTableFieldValuesInput}
          refreshGrid={refreshGrid}
          applyFilterModel={legacyApplyFilterModel}
          isTableLoading={isTableLoading}
          enableNumberOfOrdersQuery={enableNumberOfOrdersQuery}
          controlBarSpacing={controlBarSpacing}
          pageType={pageType}
          topRightComponent={topRightComponent}
          tabsNumberOfOrderData={tabsNumberOfOrderData}
          populateTableFromConfig={() =>
            populateTableFromConfig(orderTableFields)
          }
          handleSaveFiltersToExistingFilterView={handleUpdateExistingSavedView}
          newFiltersApplied={numFiltersChanged}
          setNewFiltersApplied={setNumFiltersChanged}
          filterViewSaveSuccessAlertText={savedViewSaveSuccessAlertText}
          setFilterViewSaveSuccessAlertText={setSavedViewSaveSuccessAlertText}
          filterViewSaveFailedAlert={savedViewSaveFailedAlert}
          setFilterViewSaveFailedAlert={setSavedViewSaveFailedAlert}
          shouldShowDatePicker={shouldShowDatePicker}
          defaultDatePickerFilterType={defaultDatePickerFilterType}
          handleDatePickerChange={handleDatePickerChange}
          shouldShowCustomerFilter={shouldShowCustomerFilter}
          shouldShowCustomerFilterMultiselect={
            shouldShowCustomerFilterMultiselect
          }
          handleCustomerChange={handleCustomerChange}
          handleCustomersChange={handleCustomersChange}
          shouldShowTerminalFilter={shouldShowTerminalFilter}
          shouldShowNoTerminalOptionMultiselect={
            shouldShowNoTerminalOptionMultiselect
          }
          shouldShowOriginTerminalFilter={shouldShowOriginTerminalFilter}
          shouldShowDestinationTerminalFilter={
            shouldShowDestinationTerminalFilter
          }
          handleTerminalsChange={handleTerminalsChange}
          handleOriginTerminalChange={handleOriginTerminalChange}
          handleDestinationTerminalChange={handleDestinationTerminalChange}
          terminalFilterCacheId={terminalFilterCacheId}
          originTerminalFilterCacheId={originTerminalFilterCacheId}
          destinationTerminalFilterCacheId={destinationTerminalFilterCacheId}
          handleSearch={handleSearch}
          multipleSearches={stateRef.current.multipleSearches}
          bulkActionsEnabled={bulkActionsEnabled}
          bulkActionsOverLimit={bulkActionsOverLimit}
          selectedUuids={selectedUuids}
          deselectAll={deselectAll}
          setShowConfigureOrderHeaders={setShowConfigureOrderHeaders}
          shouldAllowSavedFilterViews={shouldAllowSavedViews}
          shouldRememberFilters={shouldRememberFilters}
          openUploadPdfsModal={openUploadPdfsModal}
          openUploadCsvsModal={openUploadCsvsModal}
          openBillingPartyModal={openBillingPartyModal}
          buildOrderTableReportInput={buildOrderTableReportInput}
          shouldShowGenerateReportButtons={shouldShowGenerateReportButtons}
        />
      )}
      {isHeaderCheckboxSelected && showSelectAllOrders === true && (
        <SelectAllOrders
          setSelectedUuids={setSelectedUuids}
          queryVariables={
            // TODO (Luke): Clean this up. This is a temporary solution to unbreak the 'Select all orders' functionality
            // that is currently entirely borked. The value being passed in here actually has a type coming from
            // FindOrdersBaseInput, whereas the orders query (orderUuids) in the component expects FindOrdersBaseArgs, which
            // is close but not identical: https://www.diffchecker.com/3StH6bTf/
            // Because all types are optional, this typechecks, but will not behave as expected if non-overlapping filters are
            // included. We need to fix this within the next few days by eliminating the duplicate args / input type on order
            // queries.
            buildOrderTableReportInput().getOrderTableFieldValuesInput
          }
          gridRef={gridRef}
          pageSize={stateRef.current.pageSize}
          setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
          handleSelectAllOrders={handleSelectAllOrders}
          count={gridRef?.current?.api?.getDisplayedRowCount()}
        />
      )}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <PalletAgGridReact<FormattedOrderFragment>
          pageType={pageType}
          suppressCellFocus
          rowHeight={25}
          headerHeight={25}
          columnDefs={columnDefs}
          onColumnMoved={handleColumnMoved}
          rowSelection={
            rowSelectionEnabled || bulkActionsEnabled ? 'multiple' : undefined
          }
          rowMultiSelectWithClick
          defaultColDef={DEFAULT_COL_DEF}
          rowModelType="serverSide"
          onGridReady={onGridReady}
          pagination
          cacheBlockSize={stateRef.current.pageSize}
          paginationPageSize={stateRef.current.pageSize}
          animateRows={false}
          sideBar={sideBar}
          getRowId={(params) => params.data.uuid}
          onFilterChanged={handleFilterChanged}
          onRowSelected={(e) => {
            if (e.source === 'uiSelectAll') {
              // Handled by selectAllOrdersOnPage, this saves some re-rendering
              return;
            }
            handleRowSelected();
          }}
          onSelectionChanged={handleSelectionChanged}
          onPaginationChanged={handlePaginationChanged}
          onSortChanged={handleSortChanged}
          onCellClicked={(e) => {
            if (
              e.column.getColId() === ExcludeFromHiding.BUTTON ||
              e.column.getColId() === OrderTableField.Dims
            ) {
              e.node.setSelected(false);
            }
          }}
          onCellDoubleClicked={(e) => {
            if (
              e.column.getColId() === OrderTableField.PrimaryServiceLevel ||
              e.column.getColId() === OrderTableField.Dims
            )
              return;
            e.node.setSelected(false);
            const uuid = e.data?.uuid;
            if (!isNil(uuid)) {
              setOpenedOrderUuid(uuid);
              setSearchParams((sp) => {
                const newParams = new URLSearchParams(sp);
                newParams.set('orderUuid', uuid);
                return newParams;
              });
            }
          }}
          ref={gridRef}
        />
      </div>
    </Box>
  );
};
