import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { shallow } from 'zustand/shallow';
import { TariffZoneType } from '../../../../../generated/graphql';
import { getValueWithinBounds } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';
import styles from '../styles';
import { AmountInputType } from '../types';

const TariffOverageRateModal = ({
  overageRateModalOpen,
  setOverageRateModalOpen,
}: {
  overageRateModalOpen: boolean;
  setOverageRateModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [zoneType, globalOverageRates] = useTariffGroupStore(
    (state) => [state.zoneType, state.globalOverageRates],
    shallow,
  );

  const [
    setMileOverageRate,
    setMileOverageFlatRate,
    setMileOverageApplicableAbove,
    setPieceOverageRate,
    setPieceOverageFlatRate,
    setPieceOverageApplicableAbove,
    setWeightOverageRate,
    setWeightOverageFlatRate,
    setWeightOverageApplicableAbove,
    setUseGlobalOverageRates,
  ] = useTariffGroupStore(
    (state) => [
      state.setMileOverageRateValue,
      state.setMileOverageFlatRateValue,
      state.setMileOverageApplicableAboveValue,
      state.setPieceOverageRateValue,
      state.setPieceOverageFlatRateValue,
      state.setPieceOverageApplicableAboveValue,
      state.setWeightOverageRateValue,
      state.setWeightOverageFlatRateValue,
      state.setWeightOverageApplicableAboveValue,
      state.setUseGlobalOverageRates,
    ],
    shallow,
  );

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const parsed = parseFloat(event.target.value);
    let rate;
    if (!Number.isNaN(parsed)) {
      rate = getValueWithinBounds(currency(parsed, { precision: 2 }).value);
    }
    if (event.target.name === AmountInputType.MileOverage) {
      setMileOverageRate(rate ?? null);
    } else if (event.target.name === AmountInputType.MileOverageFlatRate) {
      setMileOverageFlatRate(rate ?? null);
    } else if (
      event.target.name === AmountInputType.MileOverageApplicableAbove
    ) {
      setMileOverageApplicableAbove(rate ?? null);
    } else if (event.target.name === AmountInputType.PieceOverage) {
      setPieceOverageRate(rate ?? null);
    } else if (event.target.name === AmountInputType.PieceOverageFlatRate) {
      setPieceOverageFlatRate(rate ?? null);
    } else if (
      event.target.name === AmountInputType.PieceOverageApplicableAbove
    ) {
      setPieceOverageApplicableAbove(rate ?? null);
    } else if (event.target.name === AmountInputType.WeightOverage) {
      setWeightOverageRate(rate ?? null);
    } else if (event.target.name === AmountInputType.WeightOverageFlatRate) {
      setWeightOverageFlatRate(rate ?? null);
    } else if (
      event.target.name === AmountInputType.WeightOverageApplicableAbove
    ) {
      setWeightOverageApplicableAbove(rate ?? null);
    }
  };

  return (
    <Modal
      open={overageRateModalOpen}
      onClose={() => setOverageRateModalOpen(false)}
    >
      <Box sx={{ ...styles.modal, ...{ height: '40vh', width: '40vw' } }}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12} sx={styles.center}>
            <Typography variant="h5">Edit Overage Rates</Typography>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack spacing={5} direction="column">
              <Stack spacing={2} direction="row">
                <TextField
                  sx={{ minWidth: 200 }}
                  name={AmountInputType.MileOverage}
                  size="small"
                  label="Mile Overage Rate"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setMileOverageRate(parseFloat(event.target.value));
                  }}
                  value={globalOverageRates.mileOverageRate}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ minWidth: 200 }}
                  name={AmountInputType.MileOverageFlatRate}
                  size="small"
                  label="Mile Overage Flat Rate"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setMileOverageFlatRate(parseFloat(event.target.value));
                  }}
                  value={globalOverageRates.mileOverageFlatRate}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ minWidth: 300 }}
                  name={AmountInputType.MileOverageApplicableAbove}
                  size="small"
                  label="Applicable Above (Optional)"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setMileOverageApplicableAbove(
                      parseFloat(event.target.value),
                    );
                  }}
                  value={globalOverageRates.mileOverageApplicableAbove}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">miles</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  sx={{ minWidth: 200 }}
                  name={AmountInputType.PieceOverage}
                  size="small"
                  label="Pieces Overage Rate"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setPieceOverageRate(parseFloat(event.target.value));
                  }}
                  value={globalOverageRates.pieceOverageRate}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ minWidth: 200 }}
                  name={AmountInputType.PieceOverageFlatRate}
                  size="small"
                  label="Piece Overage Flat Rate"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setPieceOverageFlatRate(parseFloat(event.target.value));
                  }}
                  value={globalOverageRates.pieceOverageFlatRate}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ minWidth: 300 }}
                  name={AmountInputType.PieceOverageApplicableAbove}
                  size="small"
                  label="Applicable Above (Optional)"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setPieceOverageApplicableAbove(
                      parseFloat(event.target.value),
                    );
                  }}
                  value={globalOverageRates.pieceOverageApplicableAbove}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">pieces</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack spacing={2} direction="row">
                <TextField
                  sx={{ minWidth: 200 }}
                  name={AmountInputType.WeightOverage}
                  size="small"
                  label="Weight Overage Rate"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setWeightOverageRate(parseFloat(event.target.value));
                  }}
                  value={globalOverageRates.weightOverageRate}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ minWidth: 200 }}
                  name={AmountInputType.WeightOverageFlatRate}
                  size="small"
                  label="Weight Overage Flat Rate"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setWeightOverageFlatRate(parseFloat(event.target.value));
                  }}
                  value={globalOverageRates.weightOverageFlatRate}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ minWidth: 300 }}
                  name={AmountInputType.WeightOverageApplicableAbove}
                  size="small"
                  label="Applicable Above (Optional)"
                  onBlur={validateAndUpdateRate}
                  onChange={(event) => {
                    setWeightOverageApplicableAbove(
                      parseFloat(event.target.value),
                    );
                  }}
                  value={globalOverageRates.weightOverageApplicableAbove}
                  type="number"
                  onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">pounds</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack direction="row" spacing={2}>
              {zoneType !== TariffZoneType.Universal && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setUseGlobalOverageRates(false);
                  }}
                >
                  Set Rates Per Zone
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => setOverageRateModalOpen(false)}
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffOverageRateModal;
