import { Box, SxProps, Typography } from '@mui/material';
import { OrderDetailedStatus } from '../../../../generated/graphql';
import { getOrderDetailedStatusCopy } from '../../../orders/components/utils';

type LoadStatusDisplayProps = {
  loadStatus: OrderDetailedStatus;
};

const LoadStatusDisplay = (props: LoadStatusDisplayProps) => {
  const { loadStatus } = props;

  // const getLoadStatusColor = (status: ShipmentStatus) => {
  //   // eslint-disable-next-line default-case
  //   switch (status) {
  //     case ShipmentStatus.Created:
  //       return '#83878c';
  //     case ShipmentStatus.InProgress:
  //       return '#FFA726';
  //     case ShipmentStatus.Delivered:
  //       return '#a06ab6';
  //     case ShipmentStatus.Finalized:
  //       return '#00FF00';
  //     case ShipmentStatus.Invoiced:
  //       return '#569ce3';
  //     case ShipmentStatus.Paid:
  //       return '#4caf50';
  //   }
  //   throw new Error(`unknown status ${status}`);
  // };

  const styles = {
    loadStatusBox: {
      borderRadius: 10,
      p: 1,
      color: 'white',
      background: '#83878c',
    } as SxProps,
  };

  return (
    <Box sx={styles.loadStatusBox}>
      <Typography variant="h5">
        {getOrderDetailedStatusCopy({ detailedStatus: loadStatus })}
      </Typography>
    </Box>
  );
};

export default LoadStatusDisplay;
