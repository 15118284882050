import { isNil } from 'lodash';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import { FilterViewPage } from '../../generated/graphql';

const FILTER_VIEW_PAGES_FOR_NEW_TABLE_FUNCTIONS = [
  FilterViewPage.Orders,
  FilterViewPage.Dispatch,
];

/**
 * We only want to enable these feature flags on certain pages,
 * but we might reuse common tables code across pages. This provides
 * a unified way to check if the feature flag should be enabled.
 */
export const useNewTableFunctionsFeatureFlag = (pageType?: FilterViewPage) => {
  const enableForPageType =
    !isNil(pageType) &&
    FILTER_VIEW_PAGES_FOR_NEW_TABLE_FUNCTIONS.includes(pageType);
  const ffEnableNewTableFunctions = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_TABLE_FUNCTIONS,
  );
  return {
    ffEnableNewTableFunctions: enableForPageType && ffEnableNewTableFunctions,
  };
};
