import { TableRow, TableCell } from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { EquipmentType, EquipmentsQuery } from '../../../../generated/graphql';
import { FormattedRoute, SlotTypes } from '../utils/utils';
import VehicleSelectField from './vehicle-select-field';

const EditStopRouteRow = ({
  selectedRouteUuid,
  route,
  setSelectedRouteUuid,
  equipmentsData,
}: {
  selectedRouteUuid: string;
  route: FormattedRoute;
  setSelectedRouteUuid: Dispatch<SetStateAction<string | null>>;
  equipmentsData: EquipmentsQuery | undefined;
}) => {
  const vehicle = route.equipments?.find(
    (equipment) => equipment.type === EquipmentType.Vehicle,
  );

  return (
    <TableRow
      sx={{
        backgroundColor: selectedRouteUuid === route.id ? 'green' : undefined,
      }}
      onClick={() => setSelectedRouteUuid(route.id)}
    >
      <TableCell>{route.name}</TableCell>
      <TableCell>
        {
          route.slots.filter(
            (slot) =>
              slot.__typename === SlotTypes.StandardStop &&
              (!isNil(slot.appointmentWindow) ||
                !isNil(slot.endAppointmentTime)),
          ).length
        }
      </TableCell>
      <TableCell>{route.slots.length}</TableCell>
      <TableCell>
        <VehicleSelectField
          currentVehicleUuid={vehicle?.uuid}
          routeUuid={route.id}
          equipmentsData={equipmentsData}
        />
      </TableCell>
    </TableRow>
  );
};

export default EditStopRouteRow;
