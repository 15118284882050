import {
  Box,
  Button,
  Checkbox,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { percent } from 'shared/units/scalar';
import { shallow } from 'zustand/shallow';
import { getValueWithinBounds } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';
import styles from './styles';
import { MinMaxAmountValue, OverageRates, initialOverageRates } from './types';

enum AmountInputType {
  Minimum = 'MINIMUM',
  Maximum = 'MAXIMUM',
}

const TariffZoneGroupRangeModal = ({
  zoneRangeModalOpen,
  setZoneRangeModalOpen,
}: {
  zoneRangeModalOpen: boolean;
  setZoneRangeModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [
    minMaxAmountValues,
    settlementPercentageRateValues,
    locationZonesData,
    allZonesInGroup,
    zoneBasedOverageRates,
    setMinMaxAmountValues,
    setSettlementPercentageRateValues,
    setLocationZonesData,
    setZoneBasedOverageRates,
  ] = useTariffGroupStore(
    (state) => [
      state.minMaxAmountValues,
      state.settlementPercentageRateValues,
      state.locationZonesData,
      state.allZonesInGroup,
      state.zoneBasedOverageRates,
      state.setMinMaxAmountValues,
      state.setSettlementPercentageRateValues,
      state.setLocationZonesData,
      state.setZoneBasedOverageRates,
    ],
    shallow,
  );
  // For each zone in the zone group, is it selected?
  const [zoneIsSelected, setZoneIsSelected] = useState<boolean[]>(
    allZonesInGroup.map((zone) =>
      locationZonesData.some((locationZone) => locationZone.uuid === zone.uuid),
    ),
  );
  // The min / max amounts for all zones in the zone group, not just the selected ones
  const [allMinMaxAmountValues, setAllMinMaxAmountValues] = useState<
    MinMaxAmountValue[]
  >(() => {
    const zoneUuidToMinMaxAmount: Record<string, MinMaxAmountValue> = {};
    for (let i = 0; i < locationZonesData.length; i += 1) {
      const zoneUuid = locationZonesData[i]?.uuid;
      const minMaxAmount = minMaxAmountValues[i];
      if (isNil(zoneUuid) || isNil(minMaxAmount)) {
        continue;
      }
      zoneUuidToMinMaxAmount[zoneUuid] = minMaxAmount;
    }
    return allZonesInGroup.map((zone) => {
      const minMaxAmount = zoneUuidToMinMaxAmount[zone.uuid];
      return minMaxAmount ?? { min: undefined, max: undefined };
    });
  });

  // The settlement percentage rate for all zones in the zone group, not just the selected ones
  const [
    allSettlementPercentageRateValues,
    setAllSettlementPercentageRateValues,
  ] = useState<(number | null)[]>(() => {
    const zoneUuidToSettlementPercentageRate: Record<string, number | null> =
      {};
    for (let i = 0; i < locationZonesData.length; i += 1) {
      const zoneUuid = locationZonesData[i]?.uuid;
      if (isNil(zoneUuid)) {
        continue;
      }
      zoneUuidToSettlementPercentageRate[zoneUuid] =
        settlementPercentageRateValues[i]?.amount.toNumber() ?? null;
    }
    return allZonesInGroup.map(
      (zone) => zoneUuidToSettlementPercentageRate[zone.uuid] ?? null,
    );
  });

  const allZoneBasedOverageRates = useMemo(() => {
    const zoneUuidToZoneBasedOverageRate: Record<string, OverageRates> = {};
    for (let i = 0; i < locationZonesData.length; i += 1) {
      const zoneUuid = locationZonesData[i]?.uuid;
      const zoneBasedOverageRate = zoneBasedOverageRates[i];

      if (isNil(zoneUuid) || isNil(zoneBasedOverageRate)) {
        continue;
      }
      zoneUuidToZoneBasedOverageRate[zoneUuid] = zoneBasedOverageRate;
    }

    return allZonesInGroup.map((zone) => {
      const zoneBasedOverageRate = zoneUuidToZoneBasedOverageRate[zone.uuid];
      return zoneBasedOverageRate ?? initialOverageRates;
    });
  }, [locationZonesData, zoneBasedOverageRates, allZonesInGroup]);

  const updateMinMaxAmount = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
    rate: number | undefined,
  ) => {
    const minMaxAmount = { ...allMinMaxAmountValues[rowIndex] };
    if (!isNil(minMaxAmount)) {
      if (event.target.name === AmountInputType.Minimum) {
        minMaxAmount.min = rate;
      } else {
        minMaxAmount.max = rate;
      }
    }
    setAllMinMaxAmountValues((prevAllMinMaxAmountValues) => {
      const newAllMinMaxAmountValues = [...prevAllMinMaxAmountValues];
      newAllMinMaxAmountValues[rowIndex] = minMaxAmount;
      return newAllMinMaxAmountValues;
    });
  };

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    const rate = !Number.isNaN(parsed)
      ? getValueWithinBounds(currency(parsed, { precision: 2 }).value)
      : undefined;
    updateMinMaxAmount(event, rowIndex, rate);
  };

  const updateSettlementPercentageRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    const newSettlementRate = !Number.isNaN(parsed) ? parsed : null;
    setAllSettlementPercentageRateValues(
      (prevAllSettlementPercentageRateValues) => {
        const newAllSettlementPercentageRateValues = [
          ...prevAllSettlementPercentageRateValues,
        ];
        newAllSettlementPercentageRateValues[rowIndex] = newSettlementRate;
        return newAllSettlementPercentageRateValues;
      },
    );
  };

  // filter out removed ones and create indexes for new ones
  const onSave = () => {
    const newLocationZones = allZonesInGroup.filter(
      (_, index) => zoneIsSelected[index],
    );
    const newMinMaxAmountValues = allMinMaxAmountValues.filter(
      (_, index) => zoneIsSelected[index],
    );
    const newSettlementPercentageRateValues = allSettlementPercentageRateValues
      .filter((_, index) => zoneIsSelected[index])
      .map((rate) => (!isNil(rate) ? percent(rate) : null));
    const newZoneBasedOverageRates = allZoneBasedOverageRates.filter(
      (_, index) => zoneIsSelected[index],
    );

    setLocationZonesData(newLocationZones);
    setMinMaxAmountValues(newMinMaxAmountValues);
    setSettlementPercentageRateValues(newSettlementPercentageRateValues);
    setZoneRangeModalOpen(false);
    setZoneBasedOverageRates(newZoneBasedOverageRates);
  };

  const handleSelectZone = (
    event: ChangeEvent<HTMLInputElement>,
    rowIndex: number,
  ) => {
    setZoneIsSelected((prevZoneIsSelected) => {
      const newZoneIsSelected = [...prevZoneIsSelected];
      newZoneIsSelected[rowIndex] = event.target.checked;
      return newZoneIsSelected;
    });
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setZoneIsSelected(allZonesInGroup.map(() => isChecked));
    event.stopPropagation();
  };

  return (
    <Modal
      open={zoneRangeModalOpen}
      onClose={() => {
        setZoneRangeModalOpen(false);
      }}
    >
      <Box sx={styles.modal}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Edit Zone Ranges</Typography>
              <Button variant="contained" onClick={onSave}>
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <TableContainer sx={{ maxHeight: '55vh' }}>
                <Table stickyHeader>
                  <TableHead aria-label="tariff-table">
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={zoneIsSelected.every(Boolean)}
                          indeterminate={
                            zoneIsSelected.some(Boolean) &&
                            !zoneIsSelected.every(Boolean)
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography>Zone Range</Typography>
                      </TableCell>
                      <TableCell colSpan={3} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allZonesInGroup.map((zone, rowIndex) => (
                      <TableRow key={zone.uuid}>
                        <TableCell>
                          <Checkbox
                            checked={zoneIsSelected[rowIndex]}
                            onChange={(event) => {
                              handleSelectZone(event, rowIndex);
                              event.stopPropagation();
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {zone.name}
                          {zone.isLhLaneActive === false && (
                            <Typography sx={{ fontSize: '10px' }}>
                              (Archived)
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={AmountInputType.Minimum}
                            size="small"
                            label="Minimum Amount"
                            onBlur={(event) => {
                              validateAndUpdateRate(event, rowIndex);
                            }}
                            onChange={(event) =>
                              updateMinMaxAmount(
                                event,
                                rowIndex,
                                parseFloat(event.target.value),
                              )
                            }
                            value={allMinMaxAmountValues[rowIndex]?.min ?? null}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            disabled={zoneIsSelected[rowIndex] !== true}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={AmountInputType.Maximum}
                            size="small"
                            label="Maximum Amount"
                            onBlur={(event) => {
                              validateAndUpdateRate(event, rowIndex);
                            }}
                            onChange={(event) => {
                              updateMinMaxAmount(
                                event,
                                rowIndex,
                                parseFloat(event.target.value),
                              );
                            }}
                            value={allMinMaxAmountValues[rowIndex]?.max ?? null}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            disabled={zoneIsSelected[rowIndex] !== true}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            label="Settlement Rate"
                            onBlur={(event) => {
                              updateSettlementPercentageRate(event, rowIndex);
                            }}
                            onChange={(event) => {
                              updateSettlementPercentageRate(event, rowIndex);
                            }}
                            value={
                              allSettlementPercentageRateValues[rowIndex] ?? ''
                            }
                            disabled={zoneIsSelected[rowIndex] !== true}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffZoneGroupRangeModal;
