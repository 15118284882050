import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Stack,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import DateDropdownPicker, {
  DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../../../common/components/date-dropdown-picker';
import { SortType } from '../../../../../common/components/sort-component';
import {
  DateFilterType,
  FreightSnapTransactionStatus,
  useApiLogsLazyQuery,
} from '../../../../../generated/graphql';
import FreightSnapTransactionRow, {
  FreightSnapStatusType,
  getStatusChip,
} from './freight-snap-transaction-row';

const DEFAULT_INVOICES_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50];
const FreightSnapTransactionsTable = () => {
  const [dateOption, setDateOption] = useState<DateOption>(initialDateOption);
  const [searchText, setSearchText] = useState<string>('');
  const [status, setStatus] = useState<FreightSnapStatusType>('All');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_INVOICES_PAGE_SIZE,
  );
  const [createdAtSortState, setCreatedAtSortState] = useState<SortType>(
    SortType.Descending,
  );
  const [page, setPage] = useState<number>(0);
  const [getApiLogs, { data: apiLogsData, loading }] = useApiLogsLazyQuery();

  const handleCreatedDateSort = () => {
    if (createdAtSortState === SortType.Ascending) {
      setCreatedAtSortState(SortType.Descending);
    } else if (createdAtSortState === SortType.Descending) {
      setCreatedAtSortState(SortType.Ascending);
    }
  };

  const fetchApiLogs = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    if (!isEmpty(searchText) && !isNil(searchText)) {
      // ignore all filters if searching
      await getApiLogs({
        variables: {
          searchText: searchText.trim(),
          first,
          after,
          last,
          before,
          sortByCreatedAtDateAsc: createdAtSortState === SortType.Ascending,
          isFreightSnap: true,
          isInvoiceEmails: false,
        },
      });
    } else {
      const dateFilters = [
        {
          filterType: DateFilterType.IsAfter,
          value: dateOption.startDate ?? new Date(0),
        },
        {
          filterType: DateFilterType.IsBefore,
          value: dateOption.endDate ?? dayjs().add(1, 'year').toDate(),
        },
      ];
      await getApiLogs({
        variables: {
          first,
          after,
          last,
          before,
          createdAtDateFilters: dateFilters,
          sortByCreatedAtDateAsc: createdAtSortState === SortType.Ascending,
          isFreightSnap: true,
          isInvoiceEmails: false,
          freightSnapTransactionStatus: status === 'All' ? undefined : status,
        },
      });
    }
  };

  const prev = async () => {
    await fetchApiLogs({
      last: rowsPerPage,
      before: apiLogsData?.apiLogs.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchApiLogs({
      first: rowsPerPage,
      after: apiLogsData?.apiLogs.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchApiLogs({ first: rowsPerPage });
    setPage(0);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchText,
    createdAtSortState,
    rowsPerPage,
    dateOption.startDate,
    dateOption.endDate,
    status,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ ml: '10px' }}
            >
              <DateDropdownPicker
                dateOption={dateOption}
                setDateOption={setDateOption}
                defaultFilterType={DatePickerFilterType.DayPaginate}
              />
              <TextField
                size="small"
                label="Search number"
                InputProps={{ style: { backgroundColor: 'white' } }}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ float: 'right', mr: '10px' }}
            >
              {loading && <CircularProgress size={20} />}
              <TablePagination
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                labelRowsPerPage="Show"
                component="div"
                count={apiLogsData?.apiLogs.totalCount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage: number) => {
                  if (newPage > page) {
                    next();
                  } else {
                    prev();
                  }
                  setPage(newPage);
                }}
                backIconButtonProps={{
                  disabled: loading || page === 0,
                }}
                nextIconButtonProps={{
                  disabled:
                    loading ||
                    apiLogsData?.apiLogs.totalCount === 0 ||
                    page + 1 ===
                      Math.ceil(
                        (apiLogsData?.apiLogs.totalCount ?? 0) / rowsPerPage,
                      ),
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(+e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer sx={{ overflowY: 'scroll' }}>
          <Table stickyHeader aria-label="freight-snap-preview-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active
                    direction={
                      createdAtSortState === SortType.Descending
                        ? 'desc'
                        : 'asc'
                    }
                    hideSortIcon={false}
                    onClick={handleCreatedDateSort}
                  >
                    Received At
                  </TableSortLabel>
                </TableCell>
                <TableCell>Number Entered</TableCell>
                <TableCell>Dimensions</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell align="center">
                  <Select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value as FreightSnapStatusType);
                    }}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    size="small"
                    renderValue={(value) => {
                      return (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="subtitle2">Status</Typography>{' '}
                          {getStatusChip(value)}
                        </Stack>
                      );
                    }}
                  >
                    {[
                      'All',
                      ...Object.values(FreightSnapTransactionStatus),
                    ].map((txnStatus) => (
                      <MenuItem key={txnStatus} value={txnStatus}>
                        {getStatusChip(txnStatus as FreightSnapStatusType)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>Failure Reason</TableCell>
                <TableCell sx={{ width: '150px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {apiLogsData?.apiLogs.edges.map(({ node: apiLog }) => (
                <FreightSnapTransactionRow key={apiLog.uuid} apiLog={apiLog} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FreightSnapTransactionsTable;
