import { QueryResult } from '@apollo/client';
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import {
  isInboundShipment,
  isOutboundShipment,
} from '../../../common/utils/stops';
import {
  MeQuery,
  Segment,
  ShipmentBillOfLadingQuery,
  ShipmentBillOfLadingQueryVariables,
} from '../../../generated/graphql';
import DisplayAddress from './display-address';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const GeneratedBillOfLadingPdf = ({
  shipmentBillOfLadingData,
  companyData,
}: {
  shipmentBillOfLadingData: QueryResult<
    ShipmentBillOfLadingQuery,
    ShipmentBillOfLadingQueryVariables
  >[];
  companyData: MeQuery | undefined;
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 24,
      fontFamily: 'Roboto',
      fontSize: '11px',
      flexDirection: 'column',
    },
    titleBold: {
      fontSize: '18px',
      fontWeight: 700,
    },
  });

  const shipments = filterNotNil(
    shipmentBillOfLadingData.map(
      (billOfLadingData) => billOfLadingData.data?.shipment,
    ),
  );

  const getShipperAddress = () => {
    const inboundShipment = shipments.find((sh) => isInboundShipment(sh));

    const inboundAddress = inboundShipment?.legs[0]?.endStop?.address;

    if (!isNil(inboundAddress) && !isEmpty(inboundAddress.name)) {
      return <DisplayAddress address={inboundAddress} />;
    }

    return <Text>No shipper info provided</Text>;
  };

  const getConsigneeAddress = () => {
    const outboundShipment = shipments.find((sh) => isOutboundShipment(sh));

    const outboundAddress = outboundShipment?.legs[0]?.endStop?.address;

    if (!isNil(outboundAddress) && !isEmpty(outboundAddress.name)) {
      return <DisplayAddress address={outboundAddress} />;
    }

    return <Text>No consignee info provided</Text>;
  };

  const firstShipment = shipments[0];
  if (isNil(firstShipment)) {
    return null;
  }

  const { order } = firstShipment;
  const orderName = order?.name;
  const packages = order?.packages ?? [];
  const shipperBillOfLadingNumber =
    firstShipment.order?.standardOrderFields.shipperBillOfLadingNumber;
  const masterAirwayBillOfLadingNumber =
    firstShipment.order?.standardOrderFields.masterAirwayBillOfLadingNumber;

  return (
    <Document title="Bill of Lading">
      <Page key={firstShipment.uuid} size="LETTER" style={styles.page}>
        <View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '66%' }} />
              {/* This isn't the BOL */}
              {!isNil(orderName) ? (
                <View
                  style={{
                    flexDirection: 'row',
                    width: '33%',
                  }}
                >
                  <Text style={{ fontWeight: 700, marginRight: '5px' }}>
                    Order name:
                  </Text>
                  <Text>{orderName}</Text>
                </View>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '66%' }} />
              {!isNil(shipperBillOfLadingNumber) && (
                <View
                  style={{
                    flexDirection: 'row',
                    width: '33%',
                  }}
                >
                  <Text style={{ fontWeight: 700, marginRight: '5px' }}>
                    {companyData?.me?.company.segment === Segment.Cartage
                      ? 'HAWB'
                      : 'Reference number'}
                  </Text>
                  <Text>{shipperBillOfLadingNumber}</Text>
                </View>
              )}
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '66%' }} />
              {!isNil(masterAirwayBillOfLadingNumber) &&
                companyData?.me?.company.segment === Segment.Cartage && (
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '33%',
                    }}
                  >
                    <Text style={{ fontWeight: 700, marginRight: '5px' }}>
                      MAWB
                    </Text>
                    <Text>{masterAirwayBillOfLadingNumber}</Text>
                  </View>
                )}
            </View>
          </View>

          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: '32px',
            }}
          >
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{ fontWeight: 700 }}>Shipper:</Text>
              {getShipperAddress()}
            </View>
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={{ fontWeight: 700 }}>Consignee:</Text>
              {getConsigneeAddress()}
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '32px',
          }}
        >
          <View style={{ width: '25%' }}>
            <Text style={{ fontWeight: 700 }}>Pieces</Text>
          </View>
          <View style={{ width: '25%' }}>
            <Text style={{ fontWeight: 700 }}>Package Type</Text>
          </View>
          <View style={{ width: '25%' }}>
            <Text style={{ fontWeight: 700 }}>Description</Text>
          </View>
          <View style={{ width: '25%' }}>
            <Text style={{ fontWeight: 700 }}>Weight (lbs)</Text>
          </View>
        </View>
        {isNil(packages) ||
          (packages.length === 0 && (
            <Text style={{ marginTop: '10px' }}>No packages listed</Text>
          ))}
        {packages.map((pkg) => (
          <View
            key={pkg.uuid}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: '8px',
            }}
          >
            <View style={{ width: '25%' }}>
              <Text>{pkg.quantity}</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>{!isNil(pkg.type) ? sentenceCase(pkg.type) : ''}</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>{pkg.description}</Text>
            </View>
            <View style={{ width: '25%' }}>
              <Text>{pkg.weight ?? 'Not listed'}</Text>
            </View>
          </View>
        ))}
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '32px',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: '25%',
              marginLeft: '75%',
            }}
          >
            <Text style={{ fontWeight: 700 }}>Total Weight (lbs): </Text>
            <Text>
              {packages.reduce((acc, pkg) => acc + (pkg?.weight ?? 0), 0)}
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              width: '25%',
              marginLeft: '75%',
            }}
          >
            <Text style={{ fontWeight: 700 }}>Total Pieces: </Text>
            <Text>{packages.reduce((acc, pkg) => acc + pkg.quantity, 0)}</Text>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
            marginTop: '32px',
          }}
        >
          <View
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text style={{ fontWeight: 700 }}>
              Shipper Signature/Pick Up Date:
            </Text>
            <View
              style={{
                marginTop: '8px',
                width: '100%',
                height: '40px',
                border: '1px solid black',
              }}
            />
          </View>
          <View
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text style={{ fontWeight: 700 }}>
              Carrier Signature/Pick Up Date:
            </Text>
            <View
              style={{
                marginTop: '8px',
                width: '100%',
                height: '40px',
                border: '1px solid black',
              }}
            />
          </View>
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '32px',
          }}
        >
          <Text style={{ fontWeight: 700 }}>Terms</Text>
          <Text>
            RECEIVED, subject to individually determined rates or contracts that
            have been agreed upon in writing between the carrier and the
            shipper, if applicable, otherwise to the rates, classifications, and
            rules that have been established by the carrier and are available to
            the shipper, on request, and to all applicable state and federal
            regulations.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedBillOfLadingPdf;
