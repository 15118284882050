import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  usePackageSpecsQuery,
  PackageSpecEntity,
  PackageSpecStatus,
} from '../../../generated/graphql';
import { useAppDispatch } from '../../../redux/hooks';
import { updateOneContactValues } from '../redux/contact-values-slice';

type ContactPackageTypesFieldProps = {
  disabled: boolean;
  contactUuid: string;
  packageSpecIds: string[] | undefined | null;
};

const ContactPackageTypesField = ({
  disabled,
  contactUuid,
  packageSpecIds,
}: ContactPackageTypesFieldProps) => {
  const dispatch = useAppDispatch();
  const { data: packageSpecsData, loading } = usePackageSpecsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = ({ target }: SelectChangeEvent<string[]>) => {
    if (Array.isArray(target.value)) {
      dispatch(
        updateOneContactValues({
          id: contactUuid,
          changes: {
            packageSpecIds: target.value,
          },
        }),
      );
    }
  };

  // Start by showing only active package types in the dropdown.
  const packageTypeOptions = useMemo<PackageSpecEntity[]>(() => {
    if (isNil(packageSpecsData)) {
      return [];
    }
    const options = packageSpecsData.packageSpecs.packageSpecs.filter(
      (ps) => ps.status === PackageSpecStatus.Active,
    );
    // Fill in any necessary archived package types.
    packageSpecIds?.forEach((id) => {
      if (!options.some((ps) => ps.id === id)) {
        const packageType = packageSpecsData.packageSpecs.packageSpecs.find(
          (ps) => ps.id === id,
        );
        if (!isNil(packageType)) {
          packageTypeOptions.push(packageType);
        }
      }
    });
    return options;
  }, [packageSpecIds, packageSpecsData]);

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel>Package types</InputLabel>
      <Select<string[]>
        label="Package types"
        size="small"
        multiple
        disabled={disabled || loading}
        value={packageSpecIds ?? []}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box>
            {selected.map((value) => (
              <Chip
                key={value}
                label={
                  packageTypeOptions.find((ps) => ps.id === value)?.name ??
                  'Unknown package type'
                }
              />
            ))}
          </Box>
        )}
      >
        {packageTypeOptions.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContactPackageTypesField;
