import * as yup from 'yup';

import { BillingMethod } from '../../../../../common/types';
import { FuelBillingMethod } from '../../../../../generated/graphql';

export const fuelChargeSchema = yup.object({
  billingMethod: yup
    .mixed<FuelBillingMethod>()
    .oneOf(Object.values(FuelBillingMethod))
    .required(),
  surchargeRate: yup.number().required().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  flatRateDollars: yup.number().required().nullable(),
  totalCharge: yup.number().required(),
  uuid: yup.string().required(),
  description: yup.string().nullable(),
  authoCode: yup.string().nullable(),
  settlementPercentageRate: yup.number().required().nullable(),
  settlementFlatRate: yup.number().required().nullable(),
  settlementBillingMethod: yup
    .mixed<BillingMethod>()
    .oneOf(Object.values(BillingMethod))
    .required()
    .nullable(),
  deductionTotal: yup.number().defined(),
});
