/* eslint-disable no-param-reassign */
import {
  AddressAutofillOptions,
  AutofillRetrieveResponse,
  AutofillSuggestion,
  AutofillSuggestionResponse,
  MapboxAutofill,
  SearchSession,
} from '@mapbox/search-js-core';
import { isNil } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  DriverFragment,
  SameDayDispatchRouteFragment,
  ServiceFragment,
  ShallowTerminalFragment,
  TariffFragment,
} from '../../generated/graphql';

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibHVrZXFpbjEiLCJhIjoiY2w4eHRvYjFzMDJ4ZTN4bzR5NmpnbnQ4ZSJ9.Pr45YkvmRo6O-hVBrAa6tA';

type ContactFragment = {
  displayName: string;
  uuid: string;
  contactReferenceNumber?: string | null | undefined;
  masterAccount?:
    | {
        uuid: string;
      }
    | null
    | undefined;
};

type GlobalState = {
  contacts: ContactFragment[];
  tariffs: TariffFragment[];
  services: ServiceFragment[];
  terminals: ShallowTerminalFragment[];
  drivers: DriverFragment[];
  currentOrderUuid: string | null | undefined;
  orderUuidToRefetch: string | null | undefined;
  saveOrder: boolean | null | undefined;
  currentAppVersionId: string | null | undefined;
  currentForceRefreshId: string | null | undefined;

  // Set this to true if any redux order is being loaded
  loadingReduxOrder: boolean;
  userShouldRefresh: boolean;
  forceRefresh: boolean;
  currentRouteSlotUuid: string | undefined;
  currentRoute: SameDayDispatchRouteFragment | undefined;
  selectedTerminalUuid: string | undefined;
  mapboxAutofill: MapboxAutofill;
  mapboxSession: SearchSession<
    Partial<AddressAutofillOptions>,
    AutofillSuggestion,
    AutofillSuggestionResponse,
    AutofillRetrieveResponse
  >;
  // Shared modals/dialogs state.
  showBilingPartyDialog: boolean;
  showUploadPdfsDialog: boolean;
  showUploadCsvsDialog: boolean;
  // Shared alerts state.
  successMessage: string | null;
  showSuccessMessage: boolean;
  errorMessage: string | null;
  showErrorMessage: boolean;
  showAcceptedCsvOrdersSuccessMessage: boolean;
  showRejectedCsvOrdersSuccessMessage: boolean;
  // Shared orders table state.
  shouldRefreshOrdersTableData: boolean;
  statsigLoading: boolean;
};

type GlobalActions = {
  setContacts: (contacts: ContactFragment[]) => void;
  setTariffs: (tariffs: TariffFragment[]) => void;
  setServices: (services: ServiceFragment[]) => void;
  setTerminals: (terminals: ShallowTerminalFragment[]) => void;
  setDrivers: (drivers: DriverFragment[]) => void;
  setLoadingReduxOrder: (loading: boolean) => void;
  setCurrentOrderUuid: (uuid: string | undefined) => void;
  setOrderUuidToRefetch: (uuid: string | undefined) => void;
  setSaveOrder: (save: boolean) => void;
  setCurrentRouteSlotUuid: (uuid: string | undefined) => void;
  setCurrentRoute: (route: SameDayDispatchRouteFragment | undefined) => void;
  setSelectedTerminalUuid: (uuid: string | undefined) => void;
  // Shared modals/dialogs state setters.
  setShowBilingPartyDialog: (show: boolean) => void;
  setShowUploadPdfsDialog: (show: boolean) => void;
  setShowUploadCsvsDialog: (show: boolean) => void;
  // Shared alerts state setters.
  setSuccessMessage: (message: string) => void;
  setShowSuccessMessage: (show: boolean) => void;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  setShowAcceptedCsvOrdersSuccessMessage: (show: boolean) => void;
  setShowRejectedCsvOrdersSuccessMessage: (show: boolean) => void;
  // Shared orders table state setters.
  setShouldRefreshOrdersTableData: (show: boolean) => void;
  setStatsigLoading: (loading: boolean) => void;
};

const useGlobalStore = create(
  immer<GlobalState & GlobalActions>((set) => ({
    contacts: [],
    tariffs: [],
    services: [],
    terminals: [],
    drivers: [],
    currentOrderUuid: undefined,
    orderUuidToRefetch: undefined,
    loadingReduxOrder: false,
    saveOrder: false,
    currentAppVersionId: undefined,
    currentForceRefreshId: undefined,
    userShouldRefresh: false,
    forceRefresh: false,
    currentRouteSlotUuid: undefined,
    currentRoute: undefined,
    selectedTerminalUuid: undefined,
    mapboxAutofill: new MapboxAutofill({
      accessToken: MAPBOX_ACCESS_TOKEN,
      country: 'us',
    }),
    mapboxSession: new SearchSession(
      new MapboxAutofill({
        accessToken: MAPBOX_ACCESS_TOKEN,
        country: 'us',
      }),
    ),
    successMessage: null,
    showSuccessMessage: false,
    errorMessage: null,
    showErrorMessage: false,
    showBilingPartyDialog: false,
    showUploadPdfsDialog: false,
    showUploadCsvsDialog: false,
    showAcceptedCsvOrdersSuccessMessage: false,
    showRejectedCsvOrdersSuccessMessage: false,
    shouldRefreshOrdersTableData: false,
    statsigLoading: false,
    setCurrentOrderUuid: (uuid: string | undefined) => {
      set((state) => {
        state.currentOrderUuid = uuid;
        if (!isNil(uuid)) {
          state.orderUuidToRefetch = uuid;
        }
      });
    },
    setOrderUuidToRefetch: (uuid: string | undefined) => {
      set((state) => {
        state.orderUuidToRefetch = uuid;
      });
    },
    setSaveOrder: (save: boolean) => {
      set((state) => {
        state.saveOrder = save;
      });
    },
    setCurrentRouteSlotUuid: (uuid: string | undefined) => {
      set((state) => {
        state.currentRouteSlotUuid = uuid;
      });
    },
    setCurrentRoute: (route: SameDayDispatchRouteFragment | undefined) => {
      set((state) => {
        state.currentRoute = route;
      });
    },
    setSelectedTerminalUuid: (uuid: string | undefined) =>
      set((state) => {
        state.selectedTerminalUuid = uuid;
      }),
    setContacts: (contacts: ContactFragment[]) => {
      set((state) => {
        state.contacts = contacts;
      });
    },
    setLoadingReduxOrder: (loading: boolean) => {
      set((state) => {
        state.loadingReduxOrder = loading;
      });
    },
    setTariffs: (tariffs: TariffFragment[]) => {
      set((state) => {
        state.tariffs = tariffs;
      });
    },
    setServices: (services: ServiceFragment[]) => {
      set((state) => {
        state.services = services;
      });
    },
    setTerminals: (terminals: ShallowTerminalFragment[]) => {
      set((state) => {
        state.terminals = terminals;
      });
    },
    setDrivers: (drivers: DriverFragment[]) => {
      set((state) => {
        state.drivers = drivers;
      });
    },
    setShowBilingPartyDialog: (show: boolean) => {
      set((state) => {
        state.showBilingPartyDialog = show;
      });
    },
    setShowUploadPdfsDialog: (show: boolean) => {
      set((state) => {
        state.showUploadPdfsDialog = show;
      });
    },
    setShowUploadCsvsDialog: (show: boolean) => {
      set((state) => {
        state.showUploadCsvsDialog = show;
      });
    },
    setSuccessMessage: (message: string) => {
      set((state) => {
        state.successMessage = message;
      });
    },
    setShowSuccessMessage: (show: boolean) => {
      set((state) => {
        state.showSuccessMessage = show;
        // Ensure that the success message gets unset to be used by the next action since it's a global success alert.
        if (!show) {
          state.successMessage = null;
        }
      });
    },
    setErrorMessage: (message: string) => {
      set((state) => {
        state.errorMessage = message;
      });
    },
    setShowErrorMessage: (show: boolean) => {
      set((state) => {
        state.showErrorMessage = show;
        // Ensure that the error message gets unset to be used by the next action since it's a global error alert.
        if (!show) {
          state.errorMessage = null;
        }
      });
    },
    setShowAcceptedCsvOrdersSuccessMessage: (show: boolean) => {
      set((state) => {
        state.showAcceptedCsvOrdersSuccessMessage = show;
      });
    },
    setShowRejectedCsvOrdersSuccessMessage: (show: boolean) => {
      set((state) => {
        state.showRejectedCsvOrdersSuccessMessage = show;
      });
    },
    setShouldRefreshOrdersTableData: (show: boolean) => {
      set((state) => {
        state.shouldRefreshOrdersTableData = show;
      });
    },
    setStatsigLoading: (loading: boolean) => {
      set((state) => {
        state.statsigLoading = loading;
      });
    },
  })),
);

export default useGlobalStore;
