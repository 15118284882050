import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { DateOption } from '../../common/components/date-dropdown-picker';
import { FILTER_STORE_KEY } from '../../common/local-storage/keys';
import { FilterViewPage } from '../../generated/graphql';

type FilterState = {
  search: Record<string, string>;
  filters: Partial<Record<FilterViewPage, string>>;
  tabs: Record<string, Record<string, string>>;
  dateOptions: Record<string, DateOption | undefined>;
};

type FilterActions = {
  setSearch: (newSearch: string, pageType: FilterViewPage) => void;
  setFilters: (newFilters: string, pageType: FilterViewPage) => void;
  setDateOption: (newDateOption: DateOption, pageType: FilterViewPage) => void;
  setTabs: (
    value: string,
    pageType: FilterViewPage,
    type: 'default' | 'custom',
  ) => void;
};

const useFilterStore = create(
  persist(
    immer<FilterState & FilterActions>((set) => ({
      search: {},
      filters: {},
      tabs: {},
      dateOptions: {},
      setSearch: (newSearch, pageType) =>
        set((state) => ({
          search: { ...state.search, [pageType]: newSearch },
        })),
      setFilters: (newFilters, pageType) =>
        set((state) => ({
          filters: { ...state.filters, [pageType]: newFilters },
        })),
      setDateOption: (newDateOption, pageType) =>
        set((state) => ({
          dateOptions: { ...state.dateOptions, [pageType]: newDateOption },
        })),
      setTabs: (value, pageType, type) =>
        set((state) => ({
          tabs: { ...state.tabs, [pageType]: { [type]: value } },
        })),
    })),
    {
      name: FILTER_STORE_KEY,
      partialize: (state) => ({
        search: state.search,
        filters: state.filters,
        tabs: state.tabs,
        dateOptions: state.dateOptions,
      }),
      version: 2,
    },
  ),
);

export default useFilterStore;
