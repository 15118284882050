import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from '@hello-pangea/dnd';
import { Box, Button, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import React, { Dispatch, SetStateAction } from 'react';
import { joinWithConjunction } from 'shared/strings';
import { RouteFragment } from '../../../../../generated/graphql';
import useFetchRoutes from '../../../hooks/use-fetch-routes';
import useRouteActions from '../../../hooks/use-route-actions';
import OptimizedRouteMap from '../../../map/optimized-route-map';
import { reorderRoute } from '../../../utils';
import RouteStopsList from '../../route-stops-list';

type OptimizeRouteInterfaceProps = {
  optimizedRoute: RouteFragment;
  setOptimizedRoute: Dispatch<SetStateAction<RouteFragment | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const OptimizeRouteInterface = ({
  optimizedRoute,
  setOptimizedRoute,
  setOpen,
}: OptimizeRouteInterfaceProps) => {
  const { fetchRoute } = useFetchRoutes();
  const { updateRouteStops } = useRouteActions();

  const handleUpdateRoute = async () => {
    setOpen(false);
    await updateRouteStops(optimizedRoute);
    fetchRoute(optimizedRoute.uuid);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;
    if (isNil(destination)) return;
    if (!isNil(destination.droppableId)) {
      setOptimizedRoute(
        reorderRoute(optimizedRoute, source.index, destination.index),
      );
    }
  };

  const slotsWithoutCoordinatesIndices = optimizedRoute.slots
    .map((slot, i) => [slot, i] as const)
    .filter(
      ([slot]) =>
        isNil(slot.stops[0]?.address.latitude) ||
        isNil(slot.stops[0]?.address.longitude),
    )
    .map(([_, i]) => String(i + 1));

  return (
    <Stack sx={{ height: '100%' }}>
      <Box>
        <Typography variant="h6">Optimization Results</Typography>
        {slotsWithoutCoordinatesIndices?.length > 0 && (
          <Typography sx={{ color: 'error' }}>
            {pluralize('Stop', slotsWithoutCoordinatesIndices.length)}{' '}
            {joinWithConjunction(slotsWithoutCoordinatesIndices, 'and')}{' '}
            {slotsWithoutCoordinatesIndices.length === 1
              ? 'was excluded from the optimization because it did not have coordinates tied to it'
              : 'were excluded from the optimization because they did not have coordinates tied to them'}
          </Typography>
        )}
      </Box>
      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
        <Box sx={{ height: '76vh', width: 400, overflowY: 'scroll' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <RouteStopsList
              route={optimizedRoute}
              width={400}
              dragDropSuffixId="temp"
            />
          </DragDropContext>
        </Box>
        <Stack
          sx={{ height: '76vh', width: '80%' }}
          spacing={1}
          alignItems="flex-end"
        >
          <OptimizedRouteMap routes={[optimizedRoute]} />
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleUpdateRoute} variant="contained">
              Update Route
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OptimizeRouteInterface;
