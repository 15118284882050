import { Alert, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { ORDER_PAGE_MARK_AS_COMPLETE_BUTTON_TEST_ID } from '../../../../../../../constants';
import { MarkOrderAsReadyToInvoiceDialog } from '../../../../../../common/components/modals/mark-order-as-ready-to-invoice-dialog';
import { MarkOrderAsRefusedDialog } from '../../../../../../common/components/modals/mark-order-as-refused-dialog';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useHoldReasons from '../../../../../../common/react-hooks/use-hold-reasons';
import useTerminals from '../../../../../../common/react-hooks/use-terminals';
import { isNilOrEmptyString } from '../../../../../../common/utils/utils';
import {
  OrderDetailedStatus,
  OrderStatus,
  StopStatus,
  useMarkOrderAsCancelledMutation,
  useMarkOrderAsNotCompleteMutation,
  useMarkOrderAsNotOnHandMutation,
  useMarkOrderAsNotReceivedAtOriginMutation,
  useMarkOrderAsOnHandMutation,
  useMarkOrderAsOnHoldMutation,
  useMarkOrderAsOsdMutation,
  useMarkOrderAsCompleteForceMutation,
  useMarkOrderAsReceivedAtOriginMutation,
  useRemoveOrderHoldMutation,
  useUncancelOrderMutation,
  useUpdateStandardOrderMutation,
  useMarkOrderAsNotOsdMutation,
  useOrderWithPaperworkQuery,
  OrderWithPaperworkDocument,
  useUpdatePaperworkCompletedForStopsMutation,
} from '../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { StopType } from '../../forms/stop-type';
import { OrderFormValues } from '../../forms/types';
import {
  getCanMarkOrderAsNotComplete,
  getCanMarkReceivedAtOrigin,
  getCannotCompleteOrder,
  getPickupOrDelivery,
} from '../../forms/utils';
import { useLoadOrderForm } from '../../hooks/use-load-order-form';
import MarkOrderOnHoldModal from './modals/mark-order-on-hold-modal';

export const canBeMarkedAsOnHold = (
  status: OrderStatus | null | undefined,
): boolean => {
  switch (status) {
    case OrderStatus.Created:
    case OrderStatus.InProgress:
      return true;
    case OrderStatus.Delivered:
    case OrderStatus.Finalized:
    case OrderStatus.Cancelled:
    case OrderStatus.HasIssue:
    case OrderStatus.Invoiced:
    case OrderStatus.OnHold:
    case null:
    case undefined:
      return false;
    default:
      return exhaustive(status);
  }
};

// This means "completed", not "finalized"
export const canBeMarkedAsReadyToInvoice = (
  status: OrderStatus | null | undefined,
): boolean => {
  switch (status) {
    case OrderStatus.Created:
    case OrderStatus.InProgress:
      return true;
    case OrderStatus.Delivered:
    case OrderStatus.Finalized:
    case OrderStatus.Cancelled:
    case OrderStatus.HasIssue:
    case OrderStatus.Invoiced:
    case OrderStatus.OnHold:
    case null:
    case undefined:
      return false;
    default:
      return exhaustive(status);
  }
};

interface MarkAsMenuProps {
  isEditMode: boolean;
  buttonRef: HTMLButtonElement | null;
  setShowContextMenu: Dispatch<SetStateAction<boolean>>;
  showContextMenu: boolean;
  saveOrder: () => Promise<boolean>;

  setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
}

const MarkAsMenu = ({
  isEditMode,
  buttonRef,
  setShowContextMenu,
  showContextMenu,
  saveOrder,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: MarkAsMenuProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { holdReasons } = useHoldReasons();
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );

  const { disabledIfFinalizedOrLater: editingDisabled } =
    useOrderFormEditAccess();

  const { fetchData } = useLoadOrderForm();
  const { control, setValue } = useFormContext<OrderFormValues>();
  const ref = useRef<HTMLUListElement>(null);
  const orderUuid = useWatch({ control, name: 'uuid' });
  const pieceCount = useWatch({ control, name: 'pieceCount' });
  const status = useWatch({ control, name: 'status' });
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const osd = useWatch({ control, name: 'osd' });
  const refusedBy = useWatch({ control, name: 'refusedBy' });
  const onHand = useWatch({ control, name: 'onHand' });
  const receivedAtOriginDate = useWatch({
    control,
    name: 'receivedAtOriginDate',
  });
  const pickedDate = useWatch({ control, name: 'pickedDate' });
  const notes = useWatch({ control, name: 'notes' });
  const shipperBillOfLadingNumber = useWatch({
    control,
    name: 'shipperBillOfLadingNumber',
  });
  const stops = useWatch({ control, name: 'stops' });
  const canBeMarkedAsNotComplete = getCanMarkOrderAsNotComplete({
    orderStatus: status,
    stops,
  });
  const [markAsErrorMessage, setMarkAsErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [showMarkOrderOnHoldModal, setShowMarkOrderOnHoldModal] =
    useState(false);
  const [showMarkAsRefusedDialog, setShowMarkAsRefusedDialog] = useState(false);
  const [showMarkAsReadyToInvoiceDialog, setShowMarkAsReadyToInvoiceDialog] =
    useState(false);

  const canMarkReceivedAtOrigin = getCanMarkReceivedAtOrigin({
    stops: stops ?? [],
  });

  const [markAsOnHandMutation, { loading: markOrderAsOnHandLoading }] =
    useMarkOrderAsOnHandMutation();
  const [updateStandardOrder] = useUpdateStandardOrderMutation();
  const [markOrderAsNotOnHand, { loading: markOrderAsNotOnHandLoading }] =
    useMarkOrderAsNotOnHandMutation();
  const [
    markOrderAsReceivedAtOrigin,
    { loading: markOrderAsReceivedAtOriginLoading },
  ] = useMarkOrderAsReceivedAtOriginMutation();
  const [
    markOrderAsNotReceivedAtOrigin,
    { loading: markOrderAsNotReceivedAtOriginLoading },
  ] = useMarkOrderAsNotReceivedAtOriginMutation();
  const [markOrderAsOsd, { loading: markOrderAsOsdLoading }] =
    useMarkOrderAsOsdMutation();
  const [markOrderAsNotOsd, { loading: markOrderAsNotOsdLoading }] =
    useMarkOrderAsNotOsdMutation();
  const [
    markOrderAsReadyToInvoice,
    { loading: markOrderAsReadyToInvoiceLoading },
  ] = useMarkOrderAsCompleteForceMutation();
  const [markOrderAsNotComplete, { loading: markOrderAsNotCompleteLoading }] =
    useMarkOrderAsNotCompleteMutation();
  const [markOrderAsOnHold, { loading: markOrderAsOnHoldLoading }] =
    useMarkOrderAsOnHoldMutation();
  const [removeOrderHold, { loading: removeOrderHoldLoading }] =
    useRemoveOrderHoldMutation();
  const [markOrderAsCancelled, { loading: markOrderAsCancelledLoading }] =
    useMarkOrderAsCancelledMutation();
  const [uncancelOrder, { loading: uncancelOrderLoading }] =
    useUncancelOrderMutation({});
  const [
    updatePaperworkCompletedForStops,
    { loading: updatePaperworkCompletedForStopsLoading },
  ] = useUpdatePaperworkCompletedForStopsMutation({
    refetchQueries: [OrderWithPaperworkDocument],
  });

  const { data: paperworkCompleteData, loading: paperworkCompleteLoading } =
    useOrderWithPaperworkQuery(
      isNilOrEmptyString(orderUuid)
        ? { skip: true }
        : { variables: { uuid: orderUuid } },
    );
  const paperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;

  const isLoading =
    markOrderAsOnHandLoading ||
    markOrderAsNotOnHandLoading ||
    markOrderAsReceivedAtOriginLoading ||
    markOrderAsNotReceivedAtOriginLoading ||
    markOrderAsOsdLoading ||
    markOrderAsNotOsdLoading ||
    markOrderAsReadyToInvoiceLoading ||
    markOrderAsNotCompleteLoading ||
    markOrderAsOnHoldLoading ||
    removeOrderHoldLoading ||
    markOrderAsCancelledLoading ||
    uncancelOrderLoading ||
    updatePaperworkCompletedForStopsLoading ||
    paperworkCompleteLoading;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current !== null &&
        !ref.current.contains(event.target as Node) &&
        buttonRef?.contains(event.target as Node) === false
      ) {
        setShowContextMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markAllPiecesAsPicked = async () => {
    const newPickedDate = new Date();
    const res = await updateStandardOrder({
      variables: {
        updateStandardOrderInput: {
          orderUpdateInput: {
            uuid: orderUuid,
            piecesPicked: pieceCount,
            pickedDate: newPickedDate,
          },
        },
      },
    });
    if (!isNil(res.errors)) {
      setMarkAsErrorMessage('Failed to mark all pieces as picked');
      return;
    }
    setValue('detailedStatus', res.data?.updateStandardOrder.detailedStatusV2);
    setValue('status', res.data?.updateStandardOrder.status);
    setValue('pickedDate', newPickedDate);
    setValue('pieceCount', pieceCount);
  };

  const markAsOnHand = async () => {
    if (isEditMode) {
      const res = await markAsOnHandMutation({
        variables: {
          markAsOnHandInput: {
            uuid: orderUuid,
            pieceCount: undefined,
            sentFromMobileApplication: false,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark as on hand');
        return;
      }
      setValue('detailedStatus', res.data?.markOrderAsOnHand.detailedStatusV2);
      setValue('status', res.data?.markOrderAsOnHand.status);
      setValue('osd', res.data?.markOrderAsOnHand.osd);
      // Refetch data since we also complete the inbound stop when marking on hand
      fetchData({ uuid: orderUuid });
    } else {
      setValue('detailedStatus', OrderDetailedStatus.OnHand);
      setValue('status', OrderStatus.InProgress);
    }
    setValue('onHand', true);
    setValue('receivedDate', new Date());
  };

  const markNotOnHand = async () => {
    if (isEditMode) {
      const res = await markOrderAsNotOnHand({
        variables: {
          markAsNotOnHandInput: {
            uuid: orderUuid,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark order as not on hand');
        return;
      }
      setValue(
        'detailedStatus',
        res.data?.markOrderAsNotOnHand.detailedStatusV2,
      );
      setValue('status', res.data?.markOrderAsNotOnHand.status);
    } else {
      if (detailedStatus?.toLowerCase() === 'On hand'.toLowerCase()) {
        setValue(
          'detailedStatus',
          !isNil(receivedAtOriginDate)
            ? OrderDetailedStatus.ReceivedAtOrigin
            : OrderDetailedStatus.Creating,
        );
      }
      setValue('status', OrderStatus.Created);
    }
    setValue('onHand', false);
    setValue('receivedDate', undefined);
    setValue('pieceCount', undefined);
  };

  const markReceivedAtOrigin = async () => {
    if (isEditMode) {
      const res = await markOrderAsReceivedAtOrigin({
        variables: {
          markAsReceivedAtOriginInput: {
            uuid: orderUuid,
            sentFromMobileApplication: false,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark as received');
        return;
      }
      setValue(
        'detailedStatus',
        res.data?.markOrderAsReceivedAtOrigin.detailedStatusV2,
      );
      setValue('status', res.data?.markOrderAsReceivedAtOrigin.status);
      setValue('osd', res.data?.markOrderAsReceivedAtOrigin.osd);
      // Refetch data since we also complete the inbound stop when marking received at origin
      fetchData({ uuid: orderUuid });
    } else {
      if (onHand !== true) {
        setValue('detailedStatus', OrderDetailedStatus.ReceivedAtOrigin);
      }
      setValue('status', OrderStatus.InProgress);
    }
    setValue('receivedAtOriginDate', new Date());
  };

  const markNotReceivedAtOrigin = async () => {
    if (isEditMode) {
      const res = await markOrderAsNotReceivedAtOrigin({
        variables: {
          markAsNotReceivedAtOriginInput: {
            uuid: orderUuid,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark as not received');
        return;
      }
      setValue(
        'detailedStatus',
        res.data?.markOrderAsNotReceivedAtOrigin.detailedStatusV2,
      );
      setValue('status', res.data?.markOrderAsNotReceivedAtOrigin.status);
    } else {
      if (
        detailedStatus?.toLowerCase() === 'Received at origin'.toLowerCase()
      ) {
        setValue(
          'detailedStatus',
          onHand === true
            ? OrderDetailedStatus.OnHand
            : OrderDetailedStatus.Creating,
        );
      }
      setValue('status', OrderStatus.Created);
    }
    setValue('receivedAtOriginDate', undefined);
  };

  const onMarkOsd = async () => {
    const newNotes = !isEmpty(notes) ? notes : undefined;
    const res = await markOrderAsOsd({
      variables: {
        markOsdInput: {
          uuid: orderUuid,
          notes: newNotes,
        },
      },
    });
    if (!isNil(res.errors)) {
      setMarkAsErrorMessage('Failed to mark OSD');
      return;
    }
    setValue('detailedStatus', res.data?.markOsd.detailedStatusV2);
    setValue('status', res.data?.markOsd.status);
    setValue('notes', res?.data?.markOsd?.notes);
    setValue('osd', true);
  };

  const onMarkNotOsd = async () => {
    const res = await markOrderAsNotOsd({
      variables: {
        markAsNotOsd: {
          uuid: orderUuid,
        },
      },
    });
    if (!isNil(res.errors)) {
      setMarkAsErrorMessage('Failed to mark OSD');
      return;
    }
    setValue('detailedStatus', res.data?.markNotOsd.detailedStatusV2);
    setValue('status', res.data?.markNotOsd.status);
    setValue('osd', false);
  };

  const onMarkReadyToInvoice = async (refused?: boolean) => {
    const cannotCompleteOrderMessage = getCannotCompleteOrder({
      stops,
      terminalsEnabled,
      ffRequireTransferAddress,
    });
    if (!isNil(cannotCompleteOrderMessage)) {
      setCannotCompleteOrderModalMessage(cannotCompleteOrderMessage);
      setCannotCompleteOrderModalOpen(true);
      return;
    }
    if (refused === true) {
      setShowMarkAsRefusedDialog(true);
      return;
    }
    const everyStopCompleted =
      stops?.every((s) => !isNil(s.completedAt)) ?? false;
    if (!everyStopCompleted) {
      setShowMarkAsReadyToInvoiceDialog(true);
      return;
    }
    const saveOrderSuccess = await saveOrder();
    if (saveOrderSuccess) {
      const res = await markOrderAsReadyToInvoice({
        variables: {
          markOrderAsCompleteForceInput: {
            uuid: orderUuid,
            refuseOrderInput: null,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark order as ready to invoice');
        return;
      }
      if (!isNil(res.data)) {
        setValue(
          'detailedStatus',
          res.data.markOrderAsCompleteForce.detailedStatusV2,
        );
        setValue('status', res.data.markOrderAsCompleteForce.status);
      }
    }
  };

  const onMarkOrderAsNotComplete = async () => {
    const saveOrderSuccess = await saveOrder();
    if (saveOrderSuccess) {
      const res = await markOrderAsNotComplete({
        variables: {
          uuid: orderUuid,
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark order as not complete');
        return;
      }
      setValue(
        'detailedStatus',
        res.data?.markOrderAsNotComplete.detailedStatusV2,
      );
      setValue('status', res.data?.markOrderAsNotComplete.status);
      setValue('refusedDate', null);
      setValue('refusedBy', null);
    }
  };

  const onMarkOrderAsNotRefused = async () => {
    const res = await updateStandardOrder({
      variables: {
        updateStandardOrderInput: {
          orderUpdateInput: {
            uuid: orderUuid,
            refusedBy: null,
            refusedDate: null,
          },
        },
      },
    });
    if (!isNil(res.errors)) {
      setMarkAsErrorMessage('Failed to mark order as not refused');
      return;
    }
    setValue('refusedBy', null);
  };

  const onMarkOrderAsOnHold = async () => {
    if (!isEmpty(holdReasons)) {
      setShowMarkOrderOnHoldModal(true);
      return;
    }
    setValue('holdReasonUuid', null);
    setValue('holdReasonName', null);
    if (isEditMode) {
      const res = await markOrderAsOnHold({
        variables: {
          markAsOnHoldInput: {
            uuid: orderUuid,
            notes: undefined,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark order as on hold');
        return;
      }
      setValue('detailedStatus', res.data?.markAsOnHold.detailedStatusV2);
      setValue('status', res.data?.markAsOnHold.status);
    } else {
      setValue('detailedStatus', OrderDetailedStatus.OnHold);
      setValue('status', OrderStatus.OnHold);
    }
  };

  const onRemoveOrderHold = async () => {
    if (isEditMode) {
      const res = await removeOrderHold({
        variables: {
          removeOrderHoldInput: {
            uuid: orderUuid,
            sentFromMobileApplication: false,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to remove order hold');
        return;
      }
      setValue('detailedStatus', res.data?.removeOrderHold.detailedStatusV2);
      setValue('status', res.data?.removeOrderHold.status);
    } else {
      setValue('detailedStatus', OrderDetailedStatus.Creating);
      setValue('status', OrderStatus.Created);
    }
    setValue('holdReasonUuid', null);
    setValue('holdReasonName', null);
  };

  const onCancelOrder = async () => {
    const res = await markOrderAsCancelled({
      variables: { uuid: orderUuid },
    });
    if (!isNil(res.errors)) {
      setMarkAsErrorMessage('Failed to cancel order');
      return;
    }
    setValue('detailedStatus', OrderDetailedStatus.Cancelled);
    setValue('status', OrderStatus.Cancelled);
  };

  const onUncancelOrder = async () => {
    const res = await uncancelOrder({ variables: { uuid: orderUuid } });
    if (!isNil(res.errors)) {
      setMarkAsErrorMessage('Failed to uncancel order');
      return;
    }
    setValue('detailedStatus', res.data?.uncancelOrder.detailedStatusV2);
    setValue('status', res.data?.uncancelOrder.status);
  };

  const updatePaperworkCompletedStatusForStops = async (
    paperworkCompleted: boolean,
  ) => {
    if (!isNil(stops)) {
      const res = await updatePaperworkCompletedForStops({
        variables: {
          updatePaperworkCompleteInput: {
            uuid: orderUuid,
            paperworkCompleted,
          },
        },
      });
      if (!isNil(res.errors)) {
        setMarkAsErrorMessage('Failed to mark paperwork as complete');
        return;
      }
      stops.forEach((s, idx) =>
        setValue(`stops.${idx}.paperworkMarkedComplete`, paperworkCompleted),
      );
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => {
          setMarkAsErrorMessage(undefined);
        }}
        open={!isNil(markAsErrorMessage)}
      >
        <Alert severity="error">{markAsErrorMessage}</Alert>
      </Snackbar>
      <MarkOrderOnHoldModal
        isEditMode={isEditMode}
        open={showMarkOrderOnHoldModal}
        setOpen={setShowMarkOrderOnHoldModal}
        orderUuid={orderUuid}
      />
      <MarkOrderAsReadyToInvoiceDialog
        handleClose={() => {
          setShowMarkAsReadyToInvoiceDialog(false);
          setShowMarkAsRefusedDialog(false);
        }}
        open={showMarkAsReadyToInvoiceDialog}
        stops={filterNotNil(
          (stops ?? []).map((stop) => {
            if (stop.stopType === StopType.None) {
              return null;
            }
            return {
              uuid: stop.uuid,
              pickupOrDelivery: getPickupOrDelivery(stop.stopType),
              addressName: stop.address?.name ?? '',
              routeDate: stop.routeDate ?? undefined,
              status: stop.status as StopStatus,
              completedAt: stop.completedAt ?? undefined,
              stopType: stop.stopType,
            };
          }),
        )}
        orderUuid={orderUuid}
        saveOrder={saveOrder}
        setShowMarkedAsReadyError={() => null}
        setShowMarkedAsReadySuccess={() => {
          fetchData({ uuid: orderUuid });
        }}
        markAsRefused={showMarkAsRefusedDialog}
      />
      <MarkOrderAsRefusedDialog
        orderUuid={orderUuid}
        open={showMarkAsRefusedDialog}
        onClose={(data) => {
          setShowMarkAsRefusedDialog(false);
          if (!isNil(data)) {
            setValue('refusedBy', data.refusedBy);
            setValue('refusedDate', data.refusedDate);
          }
        }}
      />
      <Menu
        anchorEl={buttonRef}
        open={showContextMenu}
        onClose={() => setShowContextMenu(false)}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {!isNil(receivedAtOriginDate) ? (
          <MenuItem
            onClick={markNotReceivedAtOrigin}
            disabled={isLoading || editingDisabled}
          >
            Mark as not received at origin
          </MenuItem>
        ) : canMarkReceivedAtOrigin ? (
          <MenuItem
            onClick={markReceivedAtOrigin}
            disabled={isLoading || editingDisabled}
          >
            Mark as received at origin
          </MenuItem>
        ) : null}
        {onHand === true ? (
          <MenuItem
            onClick={markNotOnHand}
            disabled={isLoading || editingDisabled}
          >
            Mark as not on hand
          </MenuItem>
        ) : (
          <MenuItem
            onClick={markAsOnHand}
            disabled={isLoading || editingDisabled}
          >
            Mark as on hand
          </MenuItem>
        )}
        <MenuItem
          onClick={markAllPiecesAsPicked}
          disabled={
            isLoading ||
            !isEditMode ||
            onHand === false ||
            !isNil(pickedDate) ||
            editingDisabled
          }
        >
          Mark all pieces as picked
        </MenuItem>
        {canBeMarkedAsOnHold(status) && (
          <MenuItem
            onClick={osd === true ? onMarkNotOsd : onMarkOsd}
            disabled={isLoading || !isEditMode || editingDisabled}
          >
            {osd === true ? 'Mark as not OSD' : 'Mark OSD'}
          </MenuItem>
        )}
        {canBeMarkedAsReadyToInvoice(status) && (
          <Tooltip
            title={
              isNilOrEmptyString(shipperBillOfLadingNumber)
                ? `Must set HAWB to mark as complete`
                : ''
            }
          >
            <span>
              <MenuItem
                disabled={
                  isLoading ||
                  !isEditMode ||
                  isNilOrEmptyString(shipperBillOfLadingNumber) ||
                  editingDisabled
                }
                onClick={() => {
                  onMarkReadyToInvoice(false);
                }}
                data-testid={ORDER_PAGE_MARK_AS_COMPLETE_BUTTON_TEST_ID}
              >
                Mark as complete
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {isNil(refusedBy) ? (
          <MenuItem
            disabled={isLoading || !isEditMode || editingDisabled}
            onClick={() => {
              setShowMarkAsRefusedDialog(true);
            }}
          >
            Mark as refused
          </MenuItem>
        ) : (
          <MenuItem
            onClick={onMarkOrderAsNotRefused}
            disabled={isLoading || !isEditMode || editingDisabled}
          >
            Mark as not refused
          </MenuItem>
        )}
        {canBeMarkedAsNotComplete && (
          <MenuItem
            onClick={onMarkOrderAsNotComplete}
            disabled={isLoading || !isEditMode || editingDisabled}
          >
            Mark order as not completed
          </MenuItem>
        )}
        {canBeMarkedAsOnHold(status) && (
          <MenuItem
            onClick={onMarkOrderAsOnHold}
            disabled={isLoading || editingDisabled}
          >
            Mark as on hold
          </MenuItem>
        )}
        {status === OrderStatus.OnHold && (
          <MenuItem
            onClick={onRemoveOrderHold}
            disabled={isLoading || editingDisabled}
          >
            Remove Hold
          </MenuItem>
        )}
        {status !== OrderStatus.Cancelled ? (
          <MenuItem
            onClick={onCancelOrder}
            disabled={isLoading || !isEditMode || editingDisabled}
          >
            Mark as cancelled
          </MenuItem>
        ) : (
          <MenuItem
            onClick={onUncancelOrder}
            disabled={isLoading || !isEditMode || editingDisabled}
          >
            Uncancel Order
          </MenuItem>
        )}
        <MenuItem
          disabled={isLoading}
          onClick={() => {
            updatePaperworkCompletedStatusForStops(!paperworkComplete);
          }}
        >
          Mark all paperwork {paperworkComplete ? 'incomplete' : 'complete'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MarkAsMenu;
