/**
 * The default contact card on the contact creation page.
 *
 * This card renders a "contact person", not a "contact"
 *
 * Only appears during creation, is replaced with an autocomplete select on edit/view
 */
import {
  Box,
  Card,
  CircularProgress,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectContactPersonErrorsById,
  upsertContactPersonErrors,
} from '../../contact-persons/redux/contact-persons-errors-slice';
import {
  ContactPersonFormField,
  selectContactPersonById,
  updateContactPerson,
} from '../../contact-persons/redux/contact-persons-values-slice';
import ContactPageMode from './contact-page-mode';

const useStyles = () => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: 2,
      px: 2,
      py: 2,
      width: '100%',
    } as SxProps,
    flexRowBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: 2,
      my: 1.5,
      width: '100%',
    } as SxProps,
  };
};

type DefaultContactProps = {
  contactPersonUuid: string;
  mode: ContactPageMode;
};

const DefaultContactForm = ({
  contactPersonUuid,
  mode,
}: DefaultContactProps) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const dispatch = useAppDispatch();
  const styles = useStyles();
  const contactPerson = useAppSelector((state) =>
    selectContactPersonById(state, contactPersonUuid),
  );
  const errors = useAppSelector((state) =>
    selectContactPersonErrorsById(state, contactPersonUuid),
  );

  const updateValue = (
    fieldName: keyof ContactPersonFormField,
    value: ContactPersonFormField[typeof fieldName],
  ) => {
    dispatch(
      updateContactPerson({
        id: contactPersonUuid,
        changes: { [fieldName]: value },
      }),
    );
  };

  const clearError = (fieldName: keyof ContactPersonFormField) => {
    dispatch(
      upsertContactPersonErrors({
        uuid: contactPersonUuid,
        [fieldName]: undefined,
      }),
    );
  };

  if (contactPerson === undefined) {
    return <CircularProgress />;
  }

  return (
    <Card sx={styles.card}>
      <Typography variant="h6">Default contact</Typography>
      <Box sx={{ ...styles.flexRowBox, justifyContent: 'center' }}>
        <TextField
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          error={!isNil(errors?.firstName)}
          helperText={errors?.firstName}
          label="First name"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('firstName');
            }
            updateValue('firstName', event.target.value);
          }}
          sx={{ mr: 2 }}
          value={contactPerson.firstName ?? ''}
        />
        <TextField
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          error={!isNil(errors?.lastName)}
          helperText={errors?.lastName}
          label="Last name"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('lastName');
            }
            updateValue('lastName', event.target.value);
          }}
          sx={{ ml: 2 }}
          value={contactPerson.lastName ?? ''}
        />
      </Box>
      <Box sx={{ ...styles.flexRowBox, justifyContent: 'center' }}>
        <TextField
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          label="Phone"
          onChange={(event) => {
            updateValue('phone', event.target.value);
          }}
          sx={{ mr: 2 }}
          value={contactPerson.phone ?? ''}
        />
        <TextField
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          label="E-mail"
          onChange={(event) => {
            updateValue('email', event.target.value);
          }}
          sx={{ ml: 2 }}
          value={contactPerson.email ?? ''}
        />
      </Box>
      <Box sx={styles.flexRowBox}>
        <TextField
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          label="Notes"
          onChange={(event) => {
            updateValue('notes', event.target.value);
          }}
          multiline
          rows={6}
          value={contactPerson.notes ?? ''}
          sx={{
            mx: 30,
          }}
          fullWidth
        />
      </Box>
    </Card>
  );
};

export default DefaultContactForm;
