import { TableCell, TableRow } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateDimensionalWeight } from 'shared/weight';
import {
  convertKilogramsToPounds,
  convertPoundsToKilograms,
  convertToCentimeters,
  convertToInches,
} from '../../../../../../../common/utils/utils';
import { PackageSpecEntity } from '../../../../../../../generated/graphql';
import { OrderFormValues, PackageValues } from '../../../forms/types';
import { usePackageSpecOptions } from '../../../hooks/use-package-spec-options';

// Tries to parse value as a number and 40% of the time returns a number within 10% of the value.
const fuzz = (value: string | number | null | undefined): string => {
  if (isNil(value)) {
    return '';
  }
  const valueNum = typeof value === 'string' ? Number(value) : value;
  if (Number.isNaN(valueNum) || Math.random() > 0.4) {
    return value.toString();
  }
  return (valueNum + valueNum * (Math.random() * 0.2 - 0.1)).toFixed(2);
};

type DemoHistoryPackageRowProps = {
  idx: number;
  // A list of all package specs, including archived ones. Pass in null while loading.
  allPackageSpecs: PackageSpecEntity[] | null;
  // The package specs available to the order's contact. This can be identical to allPackageSpecs.
  contactPackageSpecs: PackageSpecEntity[] | null;
};

export const DemoHistoryPackageRow = ({
  idx,
  allPackageSpecs,
  contactPackageSpecs,
}: DemoHistoryPackageRowProps) => {
  const { control } = useFormContext();
  const useKilograms: OrderFormValues['useKilograms'] = useWatch({
    control,
    name: 'useKilograms',
  });
  const useCentimeters: OrderFormValues['useCentimeters'] = useWatch({
    control,
    name: 'useCentimeters',
  });
  const packageSpecIdKey = `packages.${idx}.packageSpecId`;
  const typeKey = `packages.${idx}.type`;
  const weightKey = `packages.${idx}.weight`;
  const quantityKey = `packages.${idx}.quantity`;
  const heightKey = `packages.${idx}.height`;
  const widthKey = `packages.${idx}.width`;
  const lengthKey = `packages.${idx}.length`;
  const descriptionKey = `packages.${idx}.description`;

  const dimFactorKey = 'dimFactor';
  const packageSpecId: PackageValues['packageSpecId'] = useWatch({
    control,
    name: packageSpecIdKey,
  });
  const quantity: PackageValues['quantity'] = useWatch({
    control,
    name: quantityKey,
  });
  const weight: PackageValues['weight'] = useWatch({
    control,
    name: weightKey,
  });
  const type: PackageValues['type'] = useWatch({ control, name: typeKey });
  const length: PackageValues['length'] = useWatch({
    control,
    name: lengthKey,
  });
  const width: PackageValues['width'] = useWatch({ control, name: widthKey });
  const height: PackageValues['height'] = useWatch({
    control,
    name: heightKey,
  });
  const description: PackageValues['description'] = useWatch({
    control,
    name: descriptionKey,
  });
  const dimFactor: OrderFormValues['dimFactor'] = useWatch({
    control,
    name: dimFactorKey,
  });

  const [weightInput, setWeightInput] = useState('');
  const [lengthInput, setLengthInput] = useState('');
  const [widthInput, setWidthInput] = useState('');
  const [heightInput, setHeightInput] = useState('');

  const [dimWeight, setDimWeight] = useState('');

  useEffect(() => {
    if (useKilograms === true) {
      const kilograms = convertPoundsToKilograms(weight);
      setWeightInput(fuzz(kilograms));
    } else if (useKilograms === false) {
      const weightPounds = convertKilogramsToPounds(weight);
      setWeightInput(fuzz(weightPounds));
    } else {
      setWeightInput(fuzz(weight));
    }

    if (useCentimeters === true) {
      const centimetersLength = convertToCentimeters(length ?? 0);
      const centimetersWidth = convertToCentimeters(width ?? 0);
      const centimetersHeight = convertToCentimeters(height ?? 0);
      setLengthInput(fuzz(centimetersLength));
      setWidthInput(fuzz(centimetersWidth));
      setHeightInput(fuzz(centimetersHeight));
    } else if (useCentimeters === false) {
      const inchesLength = convertToInches(length ?? 0);
      const inchesWidth = convertToInches(width ?? 0);
      const inchesHeight = convertToInches(height ?? 0);
      setLengthInput(fuzz(inchesLength));
      setWidthInput(fuzz(inchesWidth));
      setHeightInput(fuzz(inchesHeight));
    } else {
      setLengthInput(fuzz(length));
      setWidthInput(fuzz(width));
      setHeightInput(fuzz(height));
    }
  }, [useCentimeters, useKilograms, weight, length, width, height]);

  useEffect(() => {
    let dimensionalWeight = calculateDimensionalWeight({
      length,
      width,
      height,
      quantity,
      dimFactor: dimFactor ?? undefined,
    });
    if (useCentimeters === true) {
      dimensionalWeight = calculateDimensionalWeight({
        length: convertToInches(length),
        width: convertToInches(width),
        height: convertToInches(height),
        quantity,
        dimFactor: dimFactor ?? undefined,
      });
    }
    setDimWeight(dimensionalWeight.toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, width, height, dimFactor, quantity, weight]);

  const { selectedPackageSpecOption } = usePackageSpecOptions({
    allPackageSpecs,
    contactPackageSpecs,
    selectedPackageSpecId: packageSpecId,
  });

  return (
    <TableRow>
      <TableCell>{quantity}</TableCell>
      <TableCell>{weightInput}</TableCell>
      <TableCell>{dimWeight}</TableCell>
      <TableCell>
        {lengthInput} x {widthInput} x {heightInput}
      </TableCell>
      <TableCell>
        {isNil(selectedPackageSpecOption)
          ? sentenceCase(type ?? '')
          : selectedPackageSpecOption?.name}
      </TableCell>
      <TableCell>{description}</TableCell>
    </TableRow>
  );
};
