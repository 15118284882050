import {
  Box,
  Button,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { exhaustive } from 'shared/switch';
import {
  ScannedDocumentType,
  ScannedOrderResultQuery,
} from '../../../../generated/graphql';
import { useSelectedOrderData } from '../email-ingestion/ingested-emails-store';
import DocumentComponent from './document-component';

const ScannedOrderDocumentsViewer = ({
  loading,
  scannedOrderResult,
  styling,
}: {
  loading: boolean;
  scannedOrderResult?: ScannedOrderResultQuery['scannedOrderResult'];
  styling?: SxProps;
}) => {
  const theme = useTheme();
  const [pageIndex, setPageIndex] = useState(0);
  const emailOrderData = useSelectedOrderData();
  const [
    selectedScannedDocumentTypeToggle,
    setSelectedScannedDocumentTypeToggle,
  ] = useState<
    | ScannedDocumentType.DeliveryAlert
    | ScannedDocumentType.DeliveryReceipt
    | ScannedDocumentType.PickupAlert
  >(ScannedDocumentType.DeliveryReceipt);

  const pickupAlertScannedOrderDocument =
    scannedOrderResult?.scannedOrderResultDocuments.find(
      (d) => d.scannedDocumentType === ScannedDocumentType.PickupAlert,
    );
  const deliveryAlertScannedOrderDocument =
    scannedOrderResult?.scannedOrderResultDocuments.find(
      (d) => d.scannedDocumentType === ScannedDocumentType.DeliveryAlert,
    );
  const deliveryReceiptScannedOrderDocument =
    scannedOrderResult?.scannedOrderResultDocuments.find(
      (d) => d.scannedDocumentType === ScannedDocumentType.DeliveryReceipt,
    );

  const shouldShowScannedDocumentTypeToggle =
    (!isNil(deliveryAlertScannedOrderDocument) ||
      !isNil(pickupAlertScannedOrderDocument)) &&
    !isNil(deliveryReceiptScannedOrderDocument);

  let documentUrl = scannedOrderResult?.scannedOrder?.preSignedGetUrl;
  let documentPages =
    scannedOrderResult?.scannedOrderResultPages.map((page) => {
      return {
        page: page.page,
        url: page.preSignedGetUrl,
      };
    }) ?? [];

  if (shouldShowScannedDocumentTypeToggle) {
    switch (selectedScannedDocumentTypeToggle) {
      case ScannedDocumentType.DeliveryAlert:
        if (!isNil(deliveryAlertScannedOrderDocument)) {
          documentUrl = deliveryAlertScannedOrderDocument.preSignedGetUrl;
          documentPages =
            deliveryAlertScannedOrderDocument?.scannedOrderResultDocumentPages.map(
              (page) => ({
                page: page.page,
                url: page.preSignedGetUrl,
              }),
            );
        }
        break;
      case ScannedDocumentType.DeliveryReceipt:
        documentUrl = deliveryReceiptScannedOrderDocument?.preSignedGetUrl;
        documentPages =
          deliveryReceiptScannedOrderDocument?.scannedOrderResultDocumentPages.map(
            (page) => ({
              page: page.page,
              url: page.preSignedGetUrl,
            }),
          );
        break;
      case ScannedDocumentType.PickupAlert:
        if (!isNil(pickupAlertScannedOrderDocument)) {
          documentUrl = pickupAlertScannedOrderDocument?.preSignedGetUrl;
          documentPages =
            pickupAlertScannedOrderDocument?.scannedOrderResultDocumentPages.map(
              (page) => ({
                page: page.page,
                url: page.preSignedGetUrl,
              }),
            );
        }
        break;
      default:
        exhaustive(selectedScannedDocumentTypeToggle);
    }
  }

  const documentToggle = (
    <ToggleButtonGroup
      exclusive
      onChange={(e, value) => {
        if (!isNil(value)) {
          setSelectedScannedDocumentTypeToggle(value);
        }
      }}
      value={selectedScannedDocumentTypeToggle}
    >
      <ToggleButton
        value={ScannedDocumentType.DeliveryReceipt}
        sx={{
          borderRadius: '4px',
          padding: '4px 10px 4px 10px',
          flexShrink: 0,
        }}
      >
        Delivery receipt
      </ToggleButton>
      <ToggleButton
        value={
          !isNil(pickupAlertScannedOrderDocument)
            ? ScannedDocumentType.PickupAlert
            : ScannedDocumentType.DeliveryAlert
        }
        sx={{
          borderRadius: '4px',
          padding: '4px 10px 4px 10px',
          flexShrink: 0,
        }}
      >
        {!isNil(pickupAlertScannedOrderDocument) ? 'Pickup' : 'Delivery'} alert
      </ToggleButton>
    </ToggleButtonGroup>
  );

  return (
    <Box sx={styling}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 2,
          width: '100%',
          background: 'white',
          borderBottom: `1px solid ${theme.palette.borderColor.main}`,
          boxShadow: 'none',
          gap: '4px',
        }}
      >
        {!isNil(emailOrderData?.scannedOrder) && (
          <Stack>
            <Typography>
              {emailOrderData?.scannedOrder.ingestedOutlookEmailDetails
                ?.subject ?? 'No subject'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              From:{' '}
              {emailOrderData?.scannedOrder.ingestedOutlookEmailDetails
                ?.fromAddress ?? 'Unknown'}
            </Typography>
          </Stack>
        )}
        <>
          {documentPages.length > 1 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <Button
                onClick={() => setPageIndex((p) => p - 1)}
                disabled={pageIndex <= 0}
                variant="contained"
              >
                Prev page
              </Button>
              <Button
                onClick={() => setPageIndex((p) => p + 1)}
                disabled={pageIndex >= documentPages.length - 1}
                variant="contained"
              >
                Next page
              </Button>
            </Box>
          )}
          <TransformWrapper>
            {(props) => (
              <DocumentComponent
                zoomFns={props}
                url={documentPages[pageIndex]?.url}
                originalUrl={documentUrl}
                documentToggle={
                  shouldShowScannedDocumentTypeToggle ? documentToggle : null
                }
                loading={loading}
              />
            )}
          </TransformWrapper>
        </>
      </Box>
    </Box>
  );
};

export default ScannedOrderDocumentsViewer;
