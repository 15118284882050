import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import CSVDownloadButton from '../../../../../common/components/buttons/csv-download-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { Option } from '../../../../../common/filters/types';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import { isProduction } from '../../../../../environment-variables';
import {
  FilterOperator,
  PaymentTableField,
  PaymentType,
  SortDirection,
  useMeQuery,
  usePaymentJournalReportLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import {
  convertPaymentJournalReportDataToCSV,
  downloadPaymentsForJournalReport,
} from '../../../utils';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import { formatDateOption } from './utils';

interface DownloadPaymentJournalReportModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  paymentTypes: PaymentType[];
}

const DownloadPaymentJournalReportModal = ({
  open,
  setOpen,
  paymentTypes,
}: DownloadPaymentJournalReportModalProps) => {
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const ffShowTerminalConfigurationUi =
    companyData?.me?.company.configuration?.terminalsEnabled === true ||
    !isProduction();
  const [getPayments] = usePaymentJournalReportLazyQuery();

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const paymentsInputs = useMemo(() => {
    return {
      contactUuid: customerOption?.value,
      paymentDateFilters: {
        filterOperator: FilterOperator.And,
        startFilterValue: dateOption.startDate,
        endFilterValue: dateOption.endDate,
      },
      paymentTypes,
      terminalUuid: terminalOption?.value,
      sorts: [
        {
          sortBy: PaymentTableField.Date,
          sortDirection: SortDirection.Asc,
        },
      ],
    };
  }, [customerOption, dateOption, terminalOption, paymentTypes]);

  const fetchPayments = async () => {
    const res = await getPayments({
      variables: {
        args: paymentsInputs,
      },
    });
    return res.data?.paymentJournalReport;
  };

  const startDownload = async () => {
    const completeDownload = createFileDownload();
    setOpen(false);
    const payments = await fetchPayments();

    if (!isNil(payments)) {
      downloadPaymentsForJournalReport({
        companyData,
        startDate: dateOption.startDate,
        endDate: dateOption.endDate,
        terminalName: terminalOption?.label,
        payments,
        paymentTypes,
      });
    }
    completeDownload();
    setCustomerOption(undefined);
    setTerminalOption(undefined);
    setDateOption(accountingReportDefaultDateOption);
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-payment-journal-report',
    logData: {
      ...paymentsInputs,
      downloadType,
    },
  });

  const downloadCSV = async () => {
    const completeDownload = createFileDownload();
    const payments = await fetchPayments();

    if (isNil(payments)) return null;
    const csvData = convertPaymentJournalReportDataToCSV(
      payments,
      paymentTypes,
    );
    completeDownload();
    return csvData;
  };
  const paymentTypeStr = paymentTypes
    .map((paymentType) => capitalCase(paymentType))
    .join('/');

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={[
          styles.modal,
          {
            height: 'fit-content',
            width: '400px',
          },
        ]}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download {paymentTypeStr} Journal Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%', mb: 2 }}>
            <Stack direction="column" spacing={1}>
              <Box>
                <DateDropdownPicker
                  filterTitle={`${paymentTypeStr} date`}
                  dateOption={dateOption}
                  setDateOption={setDateOption}
                />
              </Box>
              <CustomerFilterButton
                selectedOption={customerOption}
                handleChange={(option: Option | undefined) =>
                  setCustomerOption(option)
                }
              />
              {ffShowTerminalConfigurationUi && (
                <TerminalFilterButton
                  selectedOption={terminalOption}
                  prefixText="Terminal"
                  handleChange={(option: Option | null | undefined) =>
                    setTerminalOption(option ?? undefined)
                  }
                  includeInactiveTerminals={false}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="PAYMENT_JOURNAL_REPORT_FILE_TYPE"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              {downloadType === DownloadType.PDF ? (
                <Button
                  sx={{ width: 100 }}
                  variant="contained"
                  color="info"
                  onClick={startDownloadWithMeasurement}
                >
                  Download
                </Button>
              ) : (
                <CSVDownloadButton
                  getData={downloadCSV}
                  onDownloadComplete={() => {
                    setCustomerOption(undefined);
                    setTerminalOption(undefined);
                    setDateOption(accountingReportDefaultDateOption);
                  }}
                  buttonProps={{
                    sx: { width: 100 },
                    variant: 'contained',
                    color: 'info',
                    disabled: false,
                  }}
                  filename={`${paymentTypes.map((paymentType) => paymentType.toLowerCase()).join('-')}-journal-report-${formatDateOption(dateOption)}.csv`}
                  label="Download"
                  reportType="payment-journal-report"
                  dataForTimingLog={paymentsInputs}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadPaymentJournalReportModal;
