import { Button, ButtonProps } from '@mui/material';
import { isNil } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useMeasureExecutionTime } from '../../react-hooks/use-measure-execution-time';

type CSVRow = (
  | string
  | string[]
  | number
  | number[]
  | null
  | undefined
  | boolean
  | boolean[]
)[];
interface CSVDownloadButtonProps {
  getData: () => Promise<CSVRow[] | null>;
  filename: string;
  buttonProps?: ButtonProps;
  label: ReactNode;
  onDownloadComplete?: () => void;
  reportType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForTimingLog: Record<string, any>;
}
const CSVDownloadButton = ({
  getData,
  filename,
  buttonProps,
  label,
  onDownloadComplete,
  reportType,
  dataForTimingLog,
}: CSVDownloadButtonProps) => {
  const [data, setData] = useState<CSVRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [shouldDownload, setShouldDownload] = useState(false);
  const csvLinkRef = useRef<{ link: HTMLAnchorElement }>(null);

  const handleClick = async (
    e?: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setLoading(true);
    if (!isNil(buttonProps?.onClick) && !isNil(e)) {
      await buttonProps?.onClick?.(e);
    }
    const fetchedData = await getData();
    if (isNil(fetchedData)) return;
    setData(fetchedData);
    setShouldDownload(true);
    setLoading(false);
  };

  const handleClickWithMeasurement = useMeasureExecutionTime({
    fn: handleClick,
    rumLabel: `download-${reportType}`,
    logData: {
      ...dataForTimingLog,
      downloadType: 'CSV',
    },
  });

  useEffect(() => {
    if (shouldDownload) {
      csvLinkRef.current?.link.click();
      setShouldDownload(false);
      onDownloadComplete?.();
    }
  }, [shouldDownload, setShouldDownload, onDownloadComplete]);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={handleClickWithMeasurement}
        disabled={(buttonProps?.disabled ?? false) || loading}
      >
        {label}
      </Button>
      <CSVLink
        ref={csvLinkRef}
        data={data}
        filename={filename}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default CSVDownloadButton;
