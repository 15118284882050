import ModeIcon from '@mui/icons-material/Mode';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { safeAdd } from 'shared/math';
import { calculateDimAndRegularWeight } from 'shared/weight';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import RequiredLabel from '../../../../../../../common/form/required-label';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import { useGetContactDefaultPackageSpec } from '../../../../../../../common/react-hooks/use-get-contact-default-package-spec';
import useMe from '../../../../../../../common/react-hooks/use-me';
import { convertToInches } from '../../../../../../../common/utils/utils';
import {
  PackageSpecEntity,
  PackageSpecStatus,
  usePackageSpecsQuery,
} from '../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { OrderFormValues } from '../../../forms/types';
import { getInitialPackageValues } from '../../../forms/utils';
import { useOrderFormContact } from '../../../hooks/use-order-form-contact';
import { OrderFormCard } from '../../order-form-card';
import { OrderFormCardTitle } from '../../order-form-card-title';
import DimFactor from '../dim-factor';
import PackageRow, { MeasurementUnit, WeightUnit } from '../package-row';
import { DemoHistoryPackageRow } from './demo-history-package-row';

// Format as "MM/DD/YYYY, HH:MM" with HH being 24-hour.
const dateFmt = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h24',
});

const dateMinus2Hours = new Date();
dateMinus2Hours.setHours(dateMinus2Hours.getHours() - 2);
const dateMinus2HoursFormatted = dateFmt.format(dateMinus2Hours);

const dateMinus4Days = new Date();
dateMinus4Days.setDate(dateMinus4Days.getDate() - 4);
const dateMinus4DaysFormatted = dateFmt.format(dateMinus4Days);

const dateMinus5Days = new Date();
dateMinus5Days.setDate(dateMinus5Days.getDate() - 5);
const dateMinus5DaysFormatted = dateFmt.format(dateMinus5Days);

const styles = {
  totalsTextField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Change this to the desired border color
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#000000',
      paddingVertical: 0,
    },
  },
  totalSkidsTextField: {
    width: '70px',
  },
};

export const DemoPackages = ({ showTitle = true }: { showTitle?: boolean }) => {
  const theme = useTheme();
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext<OrderFormValues>();
  const { data: packageSpecsData } = usePackageSpecsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { contact } = useOrderFormContact();
  const getContactDefaultPackageSpec = useGetContactDefaultPackageSpec();

  // Take active package specs from the customer contact if useCustomPackageSpecs is true.
  // Otherwise take all active package specs.
  const contactPackageSpecs = useMemo<PackageSpecEntity[] | null>(
    () =>
      (contact?.__typename === 'CustomerContactEntity' &&
      contact.useCustomPackageSpecs
        ? contact.packageSpecs
        : packageSpecsData?.packageSpecs.packageSpecs
      )?.filter((ps) => ps.status === PackageSpecStatus.Active) ?? null,
    [contact, packageSpecsData],
  );

  const {
    defaultDimFactor: companyDefaultDimFactor,
    useAllCaps,
    companyConfiguration,
  } = useMe();

  const ffUseChargeableWeight = useFeatureFlag(
    FeatureFlag.FF_USE_CHARGEABLE_WEIGHT,
  );

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const useKilograms = useWatch({ control, name: 'useKilograms' });
  const useCentimeters = useWatch({ control, name: 'useCentimeters' });
  const dimFactor = useWatch({ control, name: 'dimFactor' });
  const packages = useWatch({ control, name: 'packages' }) ?? [];
  const totalSkids = useWatch({ control, name: 'totalSkids' });
  const [totalSkidsInput, setTotalSkidsInput] = useState<string>('');
  const [editingTotalSkids, setEditingTotalSkids] = useState<boolean>(false);
  useEffect(() => {
    setTotalSkidsInput(String(totalSkids ?? 0));
  }, [totalSkids]);

  const deletePackage = (uuid: string) => {
    setValue(
      'packages',
      packages.filter((pkg) => pkg.uuid !== uuid),
    );
  };

  useEffect(() => {
    if (!isNil(contact)) {
      const defaultDimFactor =
        contact.__typename === 'CustomerContactEntity' &&
        !isNil(contact.defaultDimFactor)
          ? contact.defaultDimFactor
          : companyDefaultDimFactor;
      setValue('dimFactor', defaultDimFactor);
    }
  }, [companyDefaultDimFactor, contact, setValue]);

  const addPackage = async () => {
    if (!isNil(contact)) {
      const defaultPackageSpec = await getContactDefaultPackageSpec(contact);
      setValue('packages', [
        ...packages,
        getInitialPackageValues(defaultPackageSpec),
      ]);
    }
  };

  const totalQuantity = packages.reduce(
    (prev, curr) => safeAdd(prev, curr.quantity ?? 0),
    0,
  );

  const { dimWeight, regularWeight } = calculateDimAndRegularWeight(
    useCentimeters !== true
      ? (packages ?? [])
      : (packages ?? []).map((pkg) => {
          return {
            ...pkg,
            length: convertToInches(pkg.length),
            width: convertToInches(pkg.width),
            height: convertToInches(pkg.height),
          };
        }),
    dimFactor ?? undefined,
  );

  return (
    <OrderFormCard>
      <Stack
        direction="row"
        justifyContent={showTitle ? 'space-between' : 'flex-end'}
        alignItems="start"
      >
        {showTitle && <OrderFormCardTitle title="Packages" />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={
              useKilograms === true ? WeightUnit.Kilograms : WeightUnit.Pounds
            }
            exclusive
            onChange={(_, val) => {
              setValue('useKilograms', val === WeightUnit.Kilograms);
            }}
            sx={{ p: 0.5 }}
            disabled={disabledIfInvoicePosted}
          >
            <ToggleButton value={WeightUnit.Pounds} sx={{ p: 0.5 }}>
              Lbs
            </ToggleButton>
            <ToggleButton value={WeightUnit.Kilograms} sx={{ p: 0.5 }}>
              Kgs
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            sx={{ p: 0.5 }}
            color="primary"
            value={
              useCentimeters === true
                ? MeasurementUnit.Centimeters
                : MeasurementUnit.Inches
            }
            exclusive
            onChange={(_, val) => {
              setValue('useCentimeters', val === MeasurementUnit.Centimeters);
            }}
            disabled={disabledIfInvoicePosted}
          >
            <ToggleButton value={MeasurementUnit.Inches} sx={{ p: 0.5 }}>
              In
            </ToggleButton>
            <ToggleButton value={MeasurementUnit.Centimeters} sx={{ p: 0.5 }}>
              Cm
            </ToggleButton>
          </ToggleButtonGroup>
          <DimFactor dimFactor={dimFactor} />
        </Box>
      </Stack>
      <Typography
        variant="h6"
        sx={{ margin: '2px 0 4px', fontSize: '15px', fontWeight: 500 }}
      >
        Current{' '}
        <span style={{ fontWeight: 400 }}>
          (Recorded {dateMinus2HoursFormatted} in HOU)
        </span>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ '& th': { verticalAlign: 'top' } }}>
                <TableCell sx={{ width: '50px' }}>
                  <RequiredLabel>Quantity</RequiredLabel>
                </TableCell>
                <TableCell sx={{ minWidth: '100px' }}>Weight</TableCell>
                <TableCell sx={{ minWidth: '110px' }}>Dim Weight</TableCell>
                <TableCell sx={{ width: '200px' }}>Dimensions</TableCell>
                <TableCell sx={{ width: '50px' }}>Type</TableCell>
                {companyConfiguration?.packageWarehouseLocationsEnabled ===
                  true && <TableCell>Warehouse location</TableCell>}
                <TableCell sx={{ width: '100%' }}>Description</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {packages.map((package_, idx) => (
              <PackageRow
                key={package_.uuid}
                idx={idx}
                deletePackage={deletePackage}
                useAllCaps={useAllCaps}
                allPackageSpecs={
                  packageSpecsData?.packageSpecs.packageSpecs ?? null
                }
                contactPackageSpecs={contactPackageSpecs}
              />
            ))}
            {!isNil(errors.packages?.message) && (
              <TableRow>
                <TableCell colSpan={12}>
                  <Typography color="error">
                    {errors.packages?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={7}>
                <Button
                  sx={{ mr: 'auto' }}
                  onClick={addPackage}
                  disabled={disabledIfFinalizedOrLater}
                >
                  + Add package
                </Button>
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
              <TableCell colSpan={7} sx={{ py: 1 }}>
                Totals
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{`${totalQuantity} pcs`}</TableCell>
              <TableCell>
                {`${regularWeight} ${useKilograms === true ? 'kgs' : 'lbs'}`}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  {`${dimWeight.toFixed(2)} dim wt.`}
                </Stack>
              </TableCell>
              <TableCell>
                {ffUseChargeableWeight && (
                  <Controller
                    name="chargeableWeightCubicMeters"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Stack>
                        <FormControl sx={{ width: '60%' }}>
                          <TextField
                            name="chargeableWeightCubicMeters"
                            label="Chargeable Weight"
                            size="small"
                            type="number"
                            value={value ?? ''}
                            onChange={onChange}
                            error={!isNil(errors.chargeableWeightCubicMeters)}
                            disabled={disabledIfFinalizedOrLater}
                          />
                          {!isNil(errors.chargeableWeightCubicMeters) && (
                            <FormHelperText sx={{ color: '#D32F2F' }}>
                              {errors.chargeableWeightCubicMeters?.message ??
                                ''}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                    )}
                  />
                )}
              </TableCell>
              <TableCell />
              {companyConfiguration?.packageWarehouseLocationsEnabled ===
                true && <TableCell />}
              <TableCell colSpan={2} color={theme.palette.grey[500]}>
                <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 1,
                    color: theme.palette.grey[700],
                  }}
                >
                  Total skids:
                  {editingTotalSkids ? (
                    <TextField
                      size="small"
                      variant="standard"
                      value={totalSkidsInput}
                      sx={styles.totalSkidsTextField}
                      onBlur={(e) => {
                        const parsedInt = parseInt(e.target.value, 10);
                        if (!Number.isNaN(parsedInt)) {
                          setValue('totalSkids', parsedInt);
                        }
                        setEditingTotalSkids(false);
                      }}
                      onChange={(e) => {
                        setTotalSkidsInput(e.target.value);
                      }}
                      autoFocus
                      disabled={disabledIfFinalizedOrLater}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                    >
                      {totalSkids ?? 0}
                      {!disabledIfFinalizedOrLater && (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => setEditingTotalSkids(true)}
                        >
                          <ModeIcon
                            sx={{
                              color: theme.palette.grey[500],
                              fontSize: '16px',
                            }}
                          />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </FormControl>
              </TableCell>
              <TableCell />
            </TableRow>
          </Table>
        </TableContainer>
      </Box>
      <Typography
        variant="h6"
        sx={{ margin: '18px 0 4px', fontSize: '15px', fontWeight: 500 }}
      >
        Previous{' '}
        <span style={{ fontWeight: 400 }}>
          (Recorded {dateMinus4DaysFormatted} in LAX)
        </span>
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '90px' }}>Quantity</TableCell>
              <TableCell sx={{ minWidth: '100px' }}>Weight</TableCell>
              <TableCell sx={{ minWidth: '110px' }}>Dim Weight</TableCell>
              <TableCell sx={{ minWidth: '180px', width: '180px' }}>
                Dimensions
              </TableCell>
              <TableCell sx={{ width: '80px', minWidth: '80px' }}>
                Type
              </TableCell>
              <TableCell sx={{ width: '100%' }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((_pkg, idx) => (
              <DemoHistoryPackageRow
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                idx={idx}
                allPackageSpecs={
                  packageSpecsData?.packageSpecs.packageSpecs ?? null
                }
                contactPackageSpecs={contactPackageSpecs}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="h6"
        sx={{ margin: '18px 0 4px', fontSize: '15px', fontWeight: 500 }}
      >
        Booked{' '}
        <span style={{ fontWeight: 400 }}>
          (Recorded {dateMinus5DaysFormatted})
        </span>
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '90px' }}>Quantity</TableCell>
              <TableCell sx={{ minWidth: '100px' }}>Weight</TableCell>
              <TableCell sx={{ minWidth: '110px' }}>Dim Weight</TableCell>
              <TableCell sx={{ minWidth: '180px', width: '180px' }}>
                Dimensions
              </TableCell>
              <TableCell sx={{ width: '80px', minWidth: '80px' }}>
                Type
              </TableCell>
              <TableCell sx={{ width: '100%' }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((_pkg, idx) => (
              <DemoHistoryPackageRow
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                idx={idx}
                allPackageSpecs={
                  packageSpecsData?.packageSpecs.packageSpecs ?? null
                }
                contactPackageSpecs={contactPackageSpecs}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </OrderFormCard>
  );
};
