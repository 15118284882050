import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import { FunctionComponent } from 'react';
import { plainTimeToHourAndMinute } from 'shared/plain-date-time';
import { ParseCsvOrdersStopFragment } from '../../../../../../generated/graphql';

type OrderStopTableProps = {
  stop: ParseCsvOrdersStopFragment;
};

export const OrderStopTable: FunctionComponent<OrderStopTableProps> = ({
  stop,
}) => {
  const {
    pickupOrDelivery,
    addressName,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZip,
    deadlineDate,
    serviceDate,
    appointmentStartTime,
    appointmentEndTime,
    appointmentRequired,
    contactPersonFirstName,
    contactPersonLastName,
    contactPersonPhone,
    contactPersonEmail,
    specialInstructions,
    terminalName,
    terminal,
    driverName,
    driverReferenceNumber,
    driver,
  } = stop;

  const contactPersonName =
    !isEmpty(contactPersonFirstName) && !isNil(contactPersonLastName)
      ? `${contactPersonFirstName} ${contactPersonLastName}`
      : '-';

  const formattedDeadlineDate = !isNil(deadlineDate)
    ? dayjs(deadlineDate).format('MM/DD/YYYY')
    : '-';
  const formattedServiceDate = !isNil(serviceDate)
    ? dayjs(serviceDate).format('MM/DD/YYYY')
    : '-';

  const driverTooltip = driverName ?? driverReferenceNumber;

  return (
    <Table>
      <TableHead>
        <TableCell>Stop Type</TableCell>
        <TableCell>Address Name</TableCell>
        <TableCell>Address Line 1</TableCell>
        <TableCell>Address Line 2</TableCell>
        <TableCell>Address City</TableCell>
        <TableCell>Address State</TableCell>
        <TableCell>Address Zip</TableCell>
        <TableCell>Deadline Date</TableCell>
        <TableCell>Service Date</TableCell>
        <TableCell>Appointment Start</TableCell>
        <TableCell>Appointment End</TableCell>
        <TableCell>Appointment Required</TableCell>
        <TableCell>Contact Name</TableCell>
        <TableCell>Contact Phone</TableCell>
        <TableCell>Contact Email</TableCell>
        <TableCell>Special Instructions</TableCell>
        <TableCell>Terminal</TableCell>
        <TableCell>Driver</TableCell>
      </TableHead>
      <TableBody>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>{sentenceCase(pickupOrDelivery ?? '-')}</TableCell>
          <TableCell>{addressName ?? '-'}</TableCell>
          <TableCell>{addressLine1 ?? '-'}</TableCell>
          <TableCell>{addressLine2 ?? '-'}</TableCell>
          <TableCell>{addressCity ?? '-'}</TableCell>
          <TableCell>{addressState ?? '-'}</TableCell>
          <TableCell>{addressZip ?? '-'}</TableCell>
          <TableCell>{formattedDeadlineDate}</TableCell>
          <TableCell>{formattedServiceDate}</TableCell>
          <TableCell>
            {!isNil(appointmentStartTime)
              ? plainTimeToHourAndMinute(appointmentStartTime)
              : '-'}
          </TableCell>
          <TableCell>
            {!isNil(appointmentEndTime)
              ? plainTimeToHourAndMinute(appointmentEndTime)
              : '-'}
          </TableCell>
          <TableCell>{appointmentRequired ? 'Yes' : 'No'}</TableCell>
          <TableCell>{contactPersonName}</TableCell>
          <TableCell>{contactPersonPhone ?? '-'}</TableCell>
          <TableCell>{contactPersonEmail ?? '-'}</TableCell>
          <TableCell>{specialInstructions}</TableCell>
          <TableCell>
            {!isNil(terminal) ? (
              <Tooltip
                title={
                  !isNil(terminalName) && terminalName !== terminal.name
                    ? `Imported “${terminalName}”`
                    : undefined
                }
              >
                <span>{terminal.name}</span>
              </Tooltip>
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell>
            {!isNil(driver) ? (
              <Tooltip
                title={
                  !isNil(driverTooltip)
                    ? `Imported “${driverTooltip}”`
                    : undefined
                }
              >
                <span>
                  {driver.firstName} {driver.lastName}
                </span>
              </Tooltip>
            ) : (
              '-'
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
