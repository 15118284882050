import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import {
  FindStorageOrderStorageUnitsFragment,
  StorageOrderFragment,
  StorageOrderStorageUnitFragment,
} from '../../../../../generated/graphql';
import StorageUnitLabelsPdf from '../storage-unit/storage-unit-labels-pdf';

interface StorageUnitLabelsModalProps {
  open: boolean;
  onClose: () => void;

  storageOrder: StorageOrderFragment;
  storageUnits:
    | StorageOrderStorageUnitFragment[]
    | FindStorageOrderStorageUnitsFragment[];
}
const StorageUnitLabelsModal = ({
  open,
  onClose,
  storageOrder,
  storageUnits,
}: StorageUnitLabelsModalProps) => {
  const storageUnitLabelsPdf = (
    <StorageUnitLabelsPdf
      storageOrder={storageOrder}
      storageUnits={storageUnits}
    />
  );
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Container labels
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
              {storageUnitLabelsPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink
                document={storageUnitLabelsPdf}
                fileName="storage-units.pdf"
              >
                {({ loading }) =>
                  loading === true ? 'Loading...' : 'Download'
                }
              </PDFDownloadLink>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StorageUnitLabelsModal;
