import { Stack, Typography } from '@mui/material';

const ReviewIngestedEmailsPage = () => {
  return (
    <Stack>
      <Typography>Review ingested emails</Typography>
    </Stack>
  );
};

export default ReviewIngestedEmailsPage;
