import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Option } from '../../../../common/filters/types';
import { UnifiedSearchQuery } from '../../../../generated/graphql';

type GenericSearchResultState<T> = {
  data: T | null;
  lastDirection?: 'prev' | 'next' | undefined;
};

type UnifiedSearchStore = {
  customerOption: Option | undefined;
  originTerminalOption: Option | undefined;
  destinationTerminalOption: Option | undefined;
  searchText: string;
  hasError: boolean;
  ordersState: GenericSearchResultState<
    UnifiedSearchQuery['unifiedSearch']['orders']
  >;
  unacceptedEmailOrdersState: GenericSearchResultState<
    UnifiedSearchQuery['unifiedSearch']['unacceptedEmailOrders']
  >;
  unacceptedEdiAndApiOrdersState: GenericSearchResultState<
    UnifiedSearchQuery['unifiedSearch']['unacceptedEdiAndApiOrders']
  >;
  quotesState: GenericSearchResultState<
    UnifiedSearchQuery['unifiedSearch']['quotes']
  >;
  invoicesState: GenericSearchResultState<
    UnifiedSearchQuery['unifiedSearch']['invoices']
  >;
};

type UnifiedSearchActions = {
  setCustomerOption: (option: Option | undefined) => void;
  setOriginTerminalOption: (option: Option | undefined) => void;
  setDestinationTerminalOption: (option: Option | undefined) => void;
  setSearchText: (text: string) => void;
  setHasError: (hasError: boolean) => void;
  setSearchResultData: (data?: UnifiedSearchQuery | null) => void;
  reset: () => void;
};

const initialState: UnifiedSearchStore = {
  customerOption: undefined,
  originTerminalOption: undefined,
  destinationTerminalOption: undefined,
  searchText: '',
  hasError: false,
  ordersState: { data: null },
  unacceptedEmailOrdersState: { data: null },
  unacceptedEdiAndApiOrdersState: { data: null },
  quotesState: { data: null },
  invoicesState: { data: null },
};

const useUnifiedSearchStore = create(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  immer<UnifiedSearchStore & UnifiedSearchActions>((set, get) => ({
    ...initialState,
    setCustomerOption: (option) => set({ customerOption: option }),
    setOriginTerminalOption: (option) => set({ originTerminalOption: option }),
    setDestinationTerminalOption: (option) =>
      set({ destinationTerminalOption: option }),
    setSearchText: (text) => set({ searchText: text }),
    setHasError: (hasError) => set({ hasError }),
    setSearchResultData: (data) => {
      set({
        ordersState: {
          data: data?.unifiedSearch.orders ?? null,
        },
        unacceptedEmailOrdersState: {
          data: data?.unifiedSearch.unacceptedEmailOrders ?? null,
        },
        unacceptedEdiAndApiOrdersState: {
          data: data?.unifiedSearch.unacceptedEdiAndApiOrders ?? null,
        },
        quotesState: {
          data: data?.unifiedSearch.quotes ?? null,
        },
        invoicesState: {
          data: data?.unifiedSearch.invoices ?? null,
        },
      });
    },
    reset: () => set(initialState),
  })),
);

export default useUnifiedSearchStore;
