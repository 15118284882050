import {
  AppBar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  SxProps,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { transformAddressToFullAddressString } from 'shared/copy';
import { transformTimeToTimeString } from '../../../../common/utils/prettyPrintUtils';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  CustomerPortalOrderQuery,
  CustomerPortalOrderShipmentFragment,
  MeasurementUnits,
  OrderStatus,
  ShipmentStatus,
  ShipmentType,
  StopStatus,
  StopType,
  WeightUnits,
  useCustomerPortalOrderLazyQuery,
  useEtaForCustomerPortalLazyQuery,
  useMeAsThirdPartyUserQuery,
} from '../../../../generated/graphql';
import { isToday } from '../../../daily-control-center/utils';
import StandardShipmentDocumentsContainer from '../../../orders/components/standard/components/standard-order/standard-shipment-documents-container';
import {
  getPrimaryShipmentUuids,
  getBillableShipments,
} from '../../../orders/components/utils';
import { PageMode } from '../../../orders/hooks/use-page-mode';
import {
  computeNextActionForOrder,
  formatAppointment,
  formatCheckboxColumnIcon,
  getOfdOfp,
  getPlannedDate,
  formatDate,
} from '../../utils';
import PlannedDateHelper from '../common/planned-date-helper';
import CustomChargesTable from './custom-charges-table';
// eslint-disable-next-line import/no-named-as-default
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomerLoadPageField from './customer-load-page-field';
import FreightChargesTable from './freight-charges-table';
import LoadStatusDisplay from './load-status-display';

dayjs.extend(utc);
dayjs.extend(timezone);

const styles: { [key: string]: SxProps } = {
  container: {
    flex: 1,
    minHeight: 0,
    backgroundColor: '#F7F7F7',
    display: 'grid',
    height: '100%',
    gridTemplateColumns: {
      xs: '1fr',
      md: '2fr 1fr',
    },
    gridTemplateRows: {
      xs: '1fr 1fr',
      md: '1fr',
    },
  },
  orderStatusHeader: {
    fontSize: '24px',
    alignSelf: 'flex-start',
  },
  appBar: {
    position: 'relative',
    height: 75,
    display: 'flex',
    justifyContent: 'center',
  },
  appBarTypography: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#FFFFFF',
  },
  loadContainer: {
    py: 1,
    px: 5,
  },
  mapContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '250px',
    my: 2,
  },
  loadDetailsContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    my: 2,
    mx: 2,
    padding: 2,
  },
  packagesContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    my: 2,
    mx: 2,
    padding: 2,
  },
  orderStatusContainer: {
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    my: 1,
    mx: 1,
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  loadStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: 600,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionHeading: {
    marginBottom: '24px',
    textAlign: 'left',
  },
  packagesList: {
    p: 2,
    my: 2,
  },
  packageListHeadingRowContainer: {
    borderRadius: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#F7F7F7',
    p: 2,
  },
  packageDataColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

type Address = {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  zip: string;
  latitude?: number | null;
  longitude?: number | null;
};

const getStopAppointment = (
  shipment: CustomerPortalOrderShipmentFragment | null | undefined,
): {
  appointmentDate?: string;
  appointmentTime?: string;
  endAppointmentTime?: string;
} => {
  const appointmentDate = shipment?.standardShipmentFields?.deliveryDate;

  const stop = shipment?.legs?.[0]?.endStop;
  const appointmentTime = stop?.appointmentTime;
  const endAppointmentTime = stop?.endAppointmentTime;

  return { appointmentDate, appointmentTime, endAppointmentTime };
};

const formatPickupDeliveryAppointment = (
  pickupShipment: CustomerPortalOrderShipmentFragment | null | undefined,
  deliveryShipment: CustomerPortalOrderShipmentFragment | null | undefined,
  companyTimezone: string | null | undefined,
) => {
  const {
    appointmentDate: pickupAppointmentDate,
    appointmentTime: pickupAppointmentTime,
    endAppointmentTime: pickupEndAppointmentTime,
  } = getStopAppointment(pickupShipment);

  const {
    appointmentDate: deliveryAppointmentDate,
    appointmentTime: deliveryAppointmentTime,
    endAppointmentTime: deliveryEndAppointmentTime,
  } = getStopAppointment(deliveryShipment);
  const pickupFormatted = !isNil(companyTimezone)
    ? formatAppointment({
        appointmentDate: pickupAppointmentDate,
        appointmentTime: pickupAppointmentTime,
        endAppointmentTime: pickupEndAppointmentTime,
        timeZone: companyTimezone,
      })
    : null;

  const deliveryFormatted = !isNil(companyTimezone)
    ? formatAppointment({
        appointmentDate: deliveryAppointmentDate,
        appointmentTime: deliveryAppointmentTime,
        endAppointmentTime: deliveryEndAppointmentTime,
        timeZone: companyTimezone,
      })
    : null;

  if (!isNil(pickupFormatted) && !isNil(deliveryFormatted)) {
    return `Delivery: ${deliveryFormatted}\nPickup: ${pickupFormatted}`;
  }
  if (!isNil(pickupFormatted)) {
    return pickupFormatted;
  }
  if (!isNil(deliveryFormatted)) {
    return deliveryFormatted;
  }
  return '-';
};

const CustomerLoadPage = ({
  isAuthenticated,
  loadUuid,
}: {
  isAuthenticated: boolean;
  loadUuid: string;
}) => {
  const navigate = useNavigate();
  const { data: meData } = useMeAsThirdPartyUserQuery();
  const matches = useMediaQuery('(min-width:900px)');

  // get load data from API using special resolver without authentication required
  const [customerPortalOrdersLazyQuery] = useCustomerPortalOrderLazyQuery();
  const [customerPortalOrderData, setCustomerPortalOrderData] =
    useState<CustomerPortalOrderQuery | null>(null);

  const order = customerPortalOrderData?.order;
  const [getEta, { data: etaData }] = useEtaForCustomerPortalLazyQuery();
  const initializeData = async (loadId: string) => {
    const { data } = await customerPortalOrdersLazyQuery({
      variables: { uuid: loadId },
    });
    setCustomerPortalOrderData(data ?? null);
  };

  useEffect(() => {
    if (typeof loadUuid === 'string' && !isEmpty(loadUuid)) {
      initializeData(loadUuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUuid]);

  const billOfLadingNumberHeader = 'HAWB#';

  const shipments = order?.shipments;

  const { delivery, pickup } = getPrimaryShipmentUuids({
    shipments: shipments ?? [],
  });

  const deliveryShipment = !isNil(delivery)
    ? shipments?.find((sh) => sh.uuid === delivery.uuid)
    : undefined;
  const pickupShipment = !isNil(pickup)
    ? shipments?.find((sh) => sh.uuid === pickup.uuid)
    : undefined;

  const completedPickupShipment = useMemo(() => {
    return shipments?.find((sh) => {
      const stop = sh?.legs?.at(0)?.endStop;
      return (
        stop?.stopType === StopType.Pickup &&
        stop?.status === StopStatus.Completed
      );
    });
  }, [shipments]);
  const completedRecoveryShipment = useMemo(() => {
    return shipments?.find((sh) => {
      const stop = sh?.legs?.at(0)?.endStop;
      return (
        stop?.stopType === StopType.Recovery &&
        stop?.status === StopStatus.Completed
      );
    });
  }, [shipments]);

  const isPickupAndDeliveryOrder =
    !isNil(pickupShipment) && !isNil(deliveryShipment);
  const pickupStopAddress = pickupShipment?.legs?.at(0)?.endStop?.address;
  const deliveryStopAddress = deliveryShipment?.legs?.at(0)?.endStop?.address;

  const incompleteShipments = shipments?.filter(
    (sh) => sh?.legs.at(0)?.endStop.status !== StopStatus.Completed,
  );
  const incompleteShipment = incompleteShipments?.at(0);

  const firstShipment = shipments?.at(0);

  const orderStatus = order?.status;
  const isOrderComplete =
    !isNil(orderStatus) &&
    [
      OrderStatus.Delivered,
      OrderStatus.Finalized,
      OrderStatus.Invoiced,
    ].includes(orderStatus);
  const incompleteOrderShipment = incompleteShipment ?? firstShipment;
  const completedOrderShipment =
    deliveryShipment ?? pickupShipment ?? firstShipment;
  let shipment = isOrderComplete
    ? completedOrderShipment
    : incompleteOrderShipment;
  shipment = isAuthenticated ? shipment : (deliveryShipment ?? pickupShipment);

  useEffect(() => {
    const shipmentUuid = shipment?.uuid;
    if (!isNil(shipmentUuid)) {
      getEta({ variables: { uuid: shipmentUuid } });
    }
  }, [shipment?.uuid, getEta]);

  const billableShipments = getBillableShipments({
    shipments: shipments ?? [],
  });
  const shipmentsWithCharges = billableShipments?.filter(
    (s) =>
      !isNil(s.freightCharge) ||
      (!isNil(s.customCharges) && !isEmpty(s.customCharges)),
  );
  const hasBillableShipmentWithFinalizedStatus =
    shipmentsWithCharges?.some((s) => s.status === ShipmentStatus.Finalized) ??
    false;

  const stop = shipment?.legs?.at(0)?.endStop;

  const dateRecovered = !isNil(completedRecoveryShipment)
    ? formatDate(
        completedRecoveryShipment?.legs?.at(0)?.endStop?.completedAt,
        '-',
      )
    : null;
  const datePickedUp = !isNil(completedPickupShipment)
    ? formatDate(
        completedPickupShipment?.legs?.at(0)?.endStop?.completedAt,
        '-',
      )
    : null;

  // get specific data from load query result
  const stopAddress: Address | null = stop?.address ?? null;

  const latitude = stopAddress?.latitude;
  const longitude = stopAddress?.longitude;

  const deliveryAppointment = shipment?.standardShipmentFields?.deliveryDate;
  const packages = order?.packages ?? [];
  const loadStatus = order?.detailedStatusV2;
  const shipmentUuids =
    shipments !== undefined ? shipments.map((sh) => sh.uuid) : [];

  const formatWeightUnits = (weightUnits: WeightUnits | undefined) => {
    return weightUnits === WeightUnits.Kilograms ? 'kgs' : 'lbs';
  };

  const formatMeasurementUnits = (
    measurementUnits: MeasurementUnits | undefined,
  ) => {
    return measurementUnits === MeasurementUnits.Centimeters ? 'cm' : 'in';
  };

  const weightUnit = formatWeightUnits(order?.standardOrderFields?.weightUnits);
  const measurementUnit = formatMeasurementUnits(
    order?.standardOrderFields?.measurementUnits,
  );
  const routeIsToday = isToday(
    shipment?.legs[0]?.endStop.routeSlot?.route?.date,
  );
  const reversedRoute = [
    ...(shipment?.legs[0]?.endStop.routeSlot?.route?.slots ?? []),
  ].reverse();
  const lastCompleteSlot = reversedRoute.find((slot) =>
    slot.stops.every(
      (st) =>
        st.leg.shipment.status === ShipmentStatus.Delivered ||
        st.status === StopStatus.Arrived,
    ),
  );
  let formattedEta =
    !isNil(etaData) && !isNil(etaData.etaForCustomerPortal)
      ? `${transformTimeToTimeString(
          etaData.etaForCustomerPortal.arrivalTime,
        )} - ${transformTimeToTimeString(
          etaData.etaForCustomerPortal.finishTime,
        )}`
      : '-';
  if (
    !isNil(etaData) &&
    !isNil(etaData.etaForCustomerPortal) &&
    transformTimeToTimeString(etaData.etaForCustomerPortal.arrivalTime) ===
      transformTimeToTimeString(etaData.etaForCustomerPortal.finishTime)
  ) {
    formattedEta = transformTimeToTimeString(
      etaData.etaForCustomerPortal.finishTime,
    );
  }

  const stopTimeZone = stop?.localTimeZone;

  const completedAt =
    !isNil(order) && !isNil(stop) && !isNil(stop.completedAt)
      ? dayjs(stop.completedAt).tz(stopTimeZone ?? order.company.timeZone)
      : null;

  if (isNil(order)) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <AppBar color="primary" sx={styles.appBar}>
        <Toolbar
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div />
          <Typography sx={styles.appBarTypography}>Order Details</Typography>
          <Box sx={{ backgroundColor: 'white' }}>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={styles.container}>
        <Box overflow="hidden">
          <Box overflow="scroll" height="100%">
            {isAuthenticated && (
              <Card sx={styles.loadDetailsContainer}>
                <Typography sx={styles.sectionHeading} variant="h4">
                  General
                </Typography>
                <Grid container spacing={3}>
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title={billOfLadingNumberHeader}
                    value={
                      order?.standardOrderFields?.shipperBillOfLadingNumber
                    }
                    defaultValue="-"
                  />
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="MAWB#"
                    value={
                      order?.standardOrderFields?.masterAirwayBillOfLadingNumber
                    }
                    defaultValue="-"
                  />
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="Date Marked on Hand"
                    value={formatDate(order?.receivedDate, 'Not Received')}
                    defaultValue="-"
                  />
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="OSD"
                    value={formatCheckboxColumnIcon(order?.osd ?? false)}
                    defaultValue="-"
                  />
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="Is On Hold"
                    value={formatCheckboxColumnIcon(
                      order?.status === OrderStatus.OnHold,
                    )}
                    defaultValue="-"
                  />
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="OFD/OFP"
                    value={formatCheckboxColumnIcon(
                      getOfdOfp(order, order?.company.timeZone),
                    )}
                    defaultValue="-"
                  />
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="Planned Date"
                    value={getPlannedDate(order)}
                    defaultValue="-"
                    helperText={<PlannedDateHelper />}
                  />
                  {!isNil(dateRecovered) && (
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Date Recovered"
                      value={dateRecovered}
                      defaultValue="-"
                    />
                  )}
                  {!isNil(datePickedUp) && (
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Date Picked Up"
                      value={datePickedUp}
                      defaultValue="-"
                    />
                  )}
                  {!isNilOrEmptyString(order?.notes) && (
                    <CustomerLoadPageField
                      spacing={matches ? 12 : undefined}
                      title="Notes"
                      value={order?.notes}
                      defaultValue="-"
                    />
                  )}
                </Grid>
              </Card>
            )}
            <Card sx={styles.loadDetailsContainer}>
              <Typography sx={styles.sectionHeading} variant="h4">
                Stop Information
              </Typography>
              <Grid container spacing={3}>
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Stop Type"
                  value={sentenceCase(
                    shipment?.legs[0]?.endStop?.stopType ?? '',
                  )}
                  defaultValue="-"
                />
                {!isNil(stopTimeZone) && (
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title="Appointment"
                    value={formatPickupDeliveryAppointment(
                      pickupShipment,
                      deliveryShipment,
                      stopTimeZone,
                    )}
                    defaultValue="-"
                  />
                )}
                {isPickupAndDeliveryOrder ? (
                  <>
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Pickup Address"
                      value={
                        !isNil(pickupStopAddress)
                          ? transformAddressToFullAddressString(
                              pickupStopAddress,
                              true,
                            )
                          : ''
                      }
                      defaultValue="-"
                    />
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Delivery Address"
                      value={
                        !isNil(deliveryStopAddress)
                          ? transformAddressToFullAddressString(
                              deliveryStopAddress,
                              true,
                            )
                          : ''
                      }
                      defaultValue="-"
                    />
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Scheduled Delivery Date"
                      value={formatDate(
                        deliveryAppointment,
                        shipment?.legs?.at(0)?.endStop?.appointmentTime,
                      )}
                      defaultValue="-"
                    />
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Consignee Name"
                      value={shipment?.legs?.at(0)?.endStop?.address.name}
                      defaultValue="-"
                    />
                    <CustomerLoadPageField
                      spacing={matches ? 2 : undefined}
                      title="Contact Phone"
                      value={
                        shipment?.legs?.at(0)?.endStop?.contactPerson?.phone
                      }
                      defaultValue="-"
                    />
                  </>
                ) : (
                  <CustomerLoadPageField
                    spacing={matches ? 2 : undefined}
                    title={`${sentenceCase(
                      shipment?.legs[0]?.endStop?.stopType ?? '',
                    )} Address`}
                    value={
                      !isNil(stopAddress)
                        ? transformAddressToFullAddressString(stopAddress)
                        : ''
                    }
                    defaultValue="-"
                  />
                )}
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="POD Signee Name"
                  value={stop?.proofOfDeliverySignee}
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Date Completed"
                  value={
                    isNil(completedAt) ? (
                      'Not Completed'
                    ) : (
                      <Tooltip title={`Time zone ${stopTimeZone ?? 'unknown'}`}>
                        <span>{completedAt.format('M/D/YYYY h:mm a')}</span>
                      </Tooltip>
                    )
                  }
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Next Action"
                  value={computeNextActionForOrder(order)}
                  defaultValue="-"
                />
                {shipment?.status === ShipmentStatus.InProgress &&
                  routeIsToday && (
                    <>
                      <CustomerLoadPageField
                        spacing={matches ? 2 : undefined}
                        title="Place in route"
                        value={
                          !isNil(shipment?.legs[0]?.endStop.routeSlot?.ordinal)
                            ? `Stop ${
                                (shipment?.legs[0]?.endStop.routeSlot
                                  ?.ordinal ?? 0) + 1
                              }`
                            : null
                        }
                        defaultValue="-"
                      />
                      <CustomerLoadPageField
                        spacing={matches ? 2 : undefined}
                        title="ETA"
                        value={formattedEta}
                        defaultValue="-"
                      />
                      <CustomerLoadPageField
                        spacing={matches ? 2 : undefined}
                        title="Driver(s) currently at"
                        value={
                          !isNil(lastCompleteSlot)
                            ? `Stop ${lastCompleteSlot.ordinal + 1}`
                            : null
                        }
                        defaultValue="Not started yet"
                      />
                    </>
                  )}
              </Grid>
            </Card>
            <Grid item xs={12}>
              <Card sx={styles.packagesContainer}>
                <Typography sx={styles.sectionHeading} variant="h4">
                  Packages
                </Typography>
                <Grid sx={styles.packagesList}>
                  {packages.length === 0 ? (
                    <Typography>No packages in this shipment.</Typography>
                  ) : (
                    <Box sx={{ overflow: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableCell>Description</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>{`Weight (${weightUnit})`}</TableCell>
                          <TableCell>{`Length (${measurementUnit})`}</TableCell>
                          <TableCell>{`Width (${measurementUnit})`}</TableCell>
                          <TableCell>{`Height (${measurementUnit})`}</TableCell>
                        </TableHead>
                        {packages.flatMap((pkg) => {
                          return (
                            <TableRow>
                              <TableCell>{pkg.description}</TableCell>
                              <TableCell>{pkg.quantity}</TableCell>
                              <TableCell>{pkg.weight}</TableCell>
                              <TableCell>{pkg.length}</TableCell>
                              <TableCell>{pkg.width}</TableCell>
                              <TableCell>{pkg.height}</TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </Box>
                  )}
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {isAuthenticated && hasBillableShipmentWithFinalizedStatus && (
                <Card sx={styles.packagesContainer}>
                  <Typography sx={styles.sectionHeading} variant="h4">
                    Charges
                  </Typography>
                  {shipmentsWithCharges?.map((shipmentWithCharge) => {
                    return (
                      <Stack
                        direction="row"
                        alignItems="center"
                        key={shipmentWithCharge.uuid}
                      >
                        <Typography
                          fontSize="20px"
                          fontWeight={700}
                          sx={{ minWidth: '200px' }}
                        >
                          {shipmentWithCharge.shipmentType ===
                          ShipmentType.Regular
                            ? sentenceCase(
                                shipmentWithCharge.legs[0]?.endStop?.stopType ??
                                  '',
                              )
                            : sentenceCase(
                                shipmentWithCharge.shipmentType as string,
                              )}
                        </Typography>
                        <Stack
                          justifyContent="center"
                          width="100%"
                          mb={2}
                          borderLeft="solid 0.5px grey"
                        >
                          {!isNil(shipmentWithCharge) &&
                            !isNil(shipmentWithCharge?.freightCharge) && (
                              <FreightChargesTable
                                freightCharge={shipmentWithCharge.freightCharge}
                                weightUnits={
                                  order.standardOrderFields?.weightUnits
                                }
                              />
                            )}
                          {!isNil(shipmentWithCharge) &&
                            !isNil(shipmentWithCharge?.customCharges) &&
                            !isEmpty(shipmentWithCharge?.customCharges) && (
                              <CustomChargesTable
                                customCharges={shipmentWithCharge.customCharges}
                              />
                            )}

                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              pr: '100px',
                              pb: 2,
                              alignItems: 'flex-end',
                            }}
                          >
                            <Typography variant="h6">Total</Typography>
                            <Typography fontSize="18px" fontWeight={600}>
                              $
                              {shipmentWithCharge?.totalChargesAmount.toFixed(
                                2,
                              ) ?? '0.00'}
                            </Typography>
                          </Stack>
                          <Divider />
                        </Stack>
                      </Stack>
                    );
                  })}
                  <Typography variant="h5">
                    Total:
                    <Typography variant="h5" fontWeight={700}>
                      ${order?.totalCharge.toFixed(2) ?? '0.00'}
                    </Typography>
                  </Typography>
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              {Boolean(meData?.meAsThirdPartyUser?.uuid) && (
                <Card sx={styles.packagesContainer}>
                  <Typography sx={styles.sectionHeading} variant="h4">
                    Documents
                  </Typography>
                  {!isNil(shipments) && (
                    <Box sx={styles.packagesList}>
                      <StandardShipmentDocumentsContainer
                        pageMode={PageMode.EDIT}
                        documentUuids={shipments.flatMap((s) =>
                          s.documents.map((d) => d.uuid),
                        )}
                        shipmentUuids={shipmentUuids}
                        isCustomerPortal
                      />
                    </Box>
                  )}
                </Card>
              )}
            </Grid>
          </Box>
        </Box>
        <Box height="100%" display="flex" flexDirection="column">
          <Box flex={1} minHeight="0">
            {!isNil(loadStatus) && (
              <Card sx={styles.orderStatusContainer}>
                <Typography sx={styles.orderStatusHeader} variant="h4">
                  Order status
                </Typography>
                <LoadStatusDisplay loadStatus={loadStatus} />
              </Card>
            )}
            {!isNil(latitude) && !isNil(longitude) && (
              <ReactMapGL
                initialViewState={{
                  latitude,
                  longitude,
                  zoom: 15,
                }}
                style={{ width: '100%', height: '100%' }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken="pk.eyJ1IjoibHVrZXFpbjEiLCJhIjoiY2w4eHRvYjFzMDJ4ZTN4bzR5NmpnbnQ4ZSJ9.Pr45YkvmRo6O-hVBrAa6tA"
              >
                <Marker
                  longitude={longitude}
                  latitude={latitude}
                  anchor="bottom"
                />
              </ReactMapGL>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerLoadPage;
