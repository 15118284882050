import { Divider, Stack, Typography } from '@mui/material';
import currency from 'currency.js';
import React from 'react';
import { SettlementDeductionFragment } from '../../../../generated/graphql';
import SettlementDeduction from './settlement-deduction';

interface SettlementDeductionsProps {
  isFinalized: boolean;
  driverSettlementTotal: number | undefined | null;
  totalDeductions: number;
  settlementDeductions: SettlementDeductionFragment[];
}

const SettlementDeductions = ({
  isFinalized,
  driverSettlementTotal,
  totalDeductions,
  settlementDeductions,
}: SettlementDeductionsProps) => {
  return (
    <Stack sx={{ width: '100%', pr: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ fontSize: '14px' }}>Eligible Pay</Typography>
        <Typography sx={{ textAlign: 'right', fontSize: '14px' }}>
          {currency(driverSettlementTotal ?? 0).format()}
        </Typography>
      </Stack>
      {settlementDeductions.map((settlementDeduction) => (
        <SettlementDeduction
          key={settlementDeduction.uuid}
          isFinalized={isFinalized}
          driverSettlementTotal={driverSettlementTotal}
          settlementDeduction={settlementDeduction}
        />
      ))}
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
          Final Pay
        </Typography>
        <Typography
          sx={{ textAlign: 'right', fontSize: '14px', fontWeight: 'bold' }}
        >
          {currency(driverSettlementTotal ?? 0)
            .subtract(totalDeductions)
            .format()}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SettlementDeductions;
