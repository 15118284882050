import {
  FormControl,
  FormHelperText,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import { useDebouncedCallback } from 'use-debounce';
import { ORDER_PAGE_HAWB_TEXT_FIELD_TEST_ID } from '../../../../../../constants';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  OrderSource,
  PermissionResource,
  ScannedOrderResultStatus,
} from '../../../../../generated/graphql';
import { OrderFormValues } from '../forms/types';
import {
  useGetHawbDuplicates,
  DuplicateBillOfLadingNumberOrder,
} from '../hooks/use-get-hawb-duplicates';

const MINIMIZED_FONT_SIZE = 12;
const DEBOUNCE_WAIT_TIME_MS = 500;

const HawbComponent = ({
  excludeFormLabel,
  editingDisabled,
  isMinimized = false,
}: {
  excludeFormLabel?: boolean;
  editingDisabled: boolean;
  isMinimized?: boolean;
}) => {
  const { control } = useFormContext<OrderFormValues>();
  const { errors } = useFormState({ control });
  const { useAllCaps } = useMe();
  const { userPermissions } = useUserRoles();
  const { canRead: canReadEmailOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EmailOrders,
  );
  const getBillOfLadingNumberDuplicates = useGetHawbDuplicates();
  const [duplicateBolOrders, setDuplicateBolOrders] = useState<
    (DuplicateBillOfLadingNumberOrder & {
      scannedOrderResult:
        | { uuid: string; status: ScannedOrderResultStatus }
        | null
        | undefined;
    })[]
  >([]);
  const [
    duplicateBolInReviewScannedOrders,
    setDuplicateBolInReviewScannedOrders,
  ] = useState<
    (DuplicateBillOfLadingNumberOrder & {
      scannedOrderResult:
        | {
            uuid: string;
            status: ScannedOrderResultStatus;
          }
        | null
        | undefined;
    })[]
  >([]);
  const [duplicateBolInReviewEdiOrders, setDuplicateBolInReviewEdiOrders] =
    useState<
      (DuplicateBillOfLadingNumberOrder & {
        scannedOrderResult:
          | {
              uuid: string;
              status: ScannedOrderResultStatus;
            }
          | null
          | undefined;
      })[]
    >([]);
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const orderUuid = useWatch({ control, name: 'uuid' });
  const shipperBillOfLadingNumber = useWatch({
    control,
    name: 'shipperBillOfLadingNumber',
  });
  const lockShipperBillOfLadingNumber = useWatch({
    control,
    name: 'lockShipperBillOfLadingNumber',
  });
  const lockShipperBillOfLadingNumberTooltip =
    "This number is used for EDI so we've disabled editing it.";

  const validateBillOfLadingNumber = useDebouncedCallback(async () => {
    const newDuplicateOrders = await getBillOfLadingNumberDuplicates({
      shipperBillOfLadingNumber,
      orderUuid,
      includeInReviewScannedOrdersFromEmail: true,
      includeInReviewEdiOrders: true,
    });

    const isInReviewScannedOrder = (order: (typeof newDuplicateOrders)[0]) =>
      order.scannedOrderResult?.status === ScannedOrderResultStatus.InReview;
    const isInReviewEdiOrder = (order: (typeof newDuplicateOrders)[0]) =>
      order.source === OrderSource.Edi && order.isDraftOrder === true;

    setDuplicateBolOrders(
      newDuplicateOrders.filter(
        (order) => !isInReviewScannedOrder(order) && !isInReviewEdiOrder(order),
      ),
    );
    setDuplicateBolInReviewScannedOrders(
      newDuplicateOrders.filter(isInReviewScannedOrder),
    );
    setDuplicateBolInReviewEdiOrders(
      newDuplicateOrders.filter(isInReviewEdiOrder),
    );
  }, DEBOUNCE_WAIT_TIME_MS);

  useEffect(() => {
    validateBillOfLadingNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUuid, shipperBillOfLadingNumber]);

  const focusHawb = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (input?: any) => {
      if (!isNil(input) && lockShipperBillOfLadingNumber === false) {
        input.focus();
      }
    },
    [lockShipperBillOfLadingNumber],
  );

  const fieldName = ffDemoLoadManagement ? 'Auth' : 'HAWB';

  return (
    <Controller
      name="shipperBillOfLadingNumber"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <Tooltip
            placement="right"
            title={
              lockShipperBillOfLadingNumber === true && (
                <Typography fontSize={15}>
                  {lockShipperBillOfLadingNumberTooltip}
                </Typography>
              )
            }
          >
            <TextField
              inputRef={focusHawb}
              sx={{ width: '100%' }}
              label={excludeFormLabel === true ? undefined : fieldName}
              required
              inputProps={{
                'data-testid': ORDER_PAGE_HAWB_TEXT_FIELD_TEST_ID,
                style: {
                  ...(isMinimized
                    ? {
                        fontSize: MINIMIZED_FONT_SIZE,
                        height: 10,
                      }
                    : {}),
                  ...(useAllCaps
                    ? {
                        textTransform: 'uppercase',
                      }
                    : {}),
                },
              }}
              disabled={
                editingDisabled ||
                !canWriteOrders ||
                lockShipperBillOfLadingNumber === true
              }
              size="small"
              value={value ?? ''}
              onChange={onChange}
              error={!isNil(errors.shipperBillOfLadingNumber)}
            />
          </Tooltip>
          {!isNil(errors.shipperBillOfLadingNumber) &&
            !isNil(errors.shipperBillOfLadingNumber.message) && (
              <FormHelperText sx={{ color: '#D32F2F' }}>
                {fieldName} is required
              </FormHelperText>
            )}
          {!isNil(duplicateBolOrders) && duplicateBolOrders.length > 0 && (
            <FormHelperText sx={{ color: '#D32F2F' }}>
              Other orders already use this reference number:{' '}
              {duplicateBolOrders.map((order) => order.orderName).join(', ')}
            </FormHelperText>
          )}
          {canReadEmailOrders &&
            !isNil(duplicateBolInReviewScannedOrders) &&
            duplicateBolInReviewScannedOrders.length > 0 && (
              <FormHelperText sx={{ color: '#D32F2F' }}>
                Found {fieldName} in emails awaiting review:{' '}
                {duplicateBolInReviewScannedOrders.map((order, idx) => (
                  <>
                    <Link
                      href={`/orders/inbound-messages/email/?orderUuid=${order.orderUuid}`}
                      sx={{ textDecoration: 'underline', color: 'inherit' }}
                    >
                      {order.orderName}
                    </Link>
                    {idx < duplicateBolInReviewScannedOrders.length - 1
                      ? ', '
                      : ''}
                  </>
                ))}
              </FormHelperText>
            )}
          {!isNil(duplicateBolInReviewEdiOrders) &&
            duplicateBolInReviewEdiOrders.length > 0 && (
              <FormHelperText sx={{ color: '#D32F2F' }}>
                Found this {fieldName} in EDI messages awaiting review:{' '}
                {duplicateBolInReviewEdiOrders.map((order, idx) => (
                  <>
                    <Link
                      href={`/orders/inbound-messages/?orderUuid=${order.orderUuid}`}
                      sx={{ textDecoration: 'underline', color: 'inherit' }}
                    >
                      {order.orderName}
                    </Link>
                    {idx < duplicateBolInReviewEdiOrders.length - 1 ? ', ' : ''}
                  </>
                ))}
              </FormHelperText>
            )}
        </FormControl>
      )}
    />
  );
};

export default React.memo(HawbComponent);
