import { ArrowRightAlt } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React from 'react';
import { StopDifferenceFragment } from '../../../../../../../../../generated/graphql';
import AddressDifferenceBlock from './address-difference-block';
import BooleanDifferenceBlock from './boolean-difference-block';
import { TableCellCustom, TableRowCustom } from './common-table-components';
import CustomChargeDifferenceBlock from './custom-charge-difference-block';
import DifferenceRow from './difference-row';
import StringDifferenceBlock from './string-difference-block';

type StopDifferenceBlockProps = {
  stopDifferences: StopDifferenceFragment | null;
  isInbound: boolean;
};

const StopDifferenceBlock = ({
  stopDifferences,
  isInbound,
}: StopDifferenceBlockProps) => {
  const theme = useTheme();

  const stopTypeString = isInbound ? 'Inbound' : 'Outbound';

  if (isNil(stopDifferences)) return null;
  const { wasAdded, wasDeleted } = stopDifferences;
  return (
    <>
      <Box sx={{ backgroundColor: theme.palette.grey[100], padding: 1 }}>
        {stopDifferences.wasAdded && (
          <Typography>{`${stopTypeString} stop added`}</Typography>
        )}
        {stopDifferences.wasDeleted && (
          <Typography>{`${stopTypeString} stop removed`}</Typography>
        )}
        {!stopDifferences.wasDeleted && !stopDifferences.wasAdded && (
          <Typography>{`${stopTypeString} stop changed`}</Typography>
        )}
      </Box>
      <Table size="small" style={{ border: 'none' }}>
        <colgroup>
          <col width="25%" />
          <col width="36%" />
          <col width="3%" />
          <col width="36%" />
        </colgroup>

        <TableBody>
          {/* STOP TYPE */}
          {!isNil(stopDifferences.stopType) && (
            <DifferenceRow
              oldValue={
                sentenceCase(stopDifferences.stopType.oldValue ?? '') ??
                undefined
              }
              newValue={
                sentenceCase(stopDifferences.stopType.newValue ?? '') ??
                undefined
              }
              fieldName="Stop type"
              wasEntireEntityDeleted={wasDeleted}
              wasEntireEntityAdded={wasAdded}
            />
          )}
          {!isNil(stopDifferences.address) && (
            <TableRowCustom>
              <TableCellCustom style={{ border: 'none' }}>
                Address
              </TableCellCustom>
              <TableCellCustom>
                <AddressDifferenceBlock
                  addressDifference={stopDifferences.address}
                  isOldAddress
                  stopRemoved={wasDeleted}
                  stopAdded={wasAdded}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <AddressDifferenceBlock
                  addressDifference={stopDifferences.address}
                  isOldAddress={false}
                  stopRemoved={wasDeleted}
                  stopAdded={wasAdded}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.terminal) && (
            <TableRowCustom>
              <TableCellCustom>Terminal</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={stopDifferences.terminal.oldValue}
                  newValue={stopDifferences.terminal.newValue}
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={stopDifferences.terminal.oldValue}
                  newValue={stopDifferences.terminal.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.serviceDate) && (
            <TableRowCustom>
              <TableCellCustom>Service date</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={stopDifferences.serviceDate.oldValue}
                  newValue={stopDifferences.serviceDate.newValue}
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={stopDifferences.serviceDate.oldValue}
                  newValue={stopDifferences.serviceDate.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.isSpecial) && (
            <TableRowCustom>
              <TableCellCustom>Special</TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={stopDifferences.isSpecial.oldValue}
                  newValue={stopDifferences.isSpecial.newValue}
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={stopDifferences.isSpecial.oldValue}
                  newValue={stopDifferences.isSpecial.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.appointmentRequired) && (
            <TableRowCustom>
              <TableCellCustom>Appointment required</TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={stopDifferences.appointmentRequired.oldValue}
                  newValue={stopDifferences.appointmentRequired.newValue}
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={stopDifferences.appointmentRequired.oldValue}
                  newValue={stopDifferences.appointmentRequired.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.appointmentManuallyConfirmed) && (
            <TableRowCustom>
              <TableCellCustom>Appointment confirmed</TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={
                    stopDifferences.appointmentManuallyConfirmed.oldValue
                  }
                  newValue={
                    stopDifferences.appointmentManuallyConfirmed.newValue
                  }
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={
                    stopDifferences.appointmentManuallyConfirmed.oldValue
                  }
                  newValue={
                    stopDifferences.appointmentManuallyConfirmed.newValue
                  }
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.freightCharge?.totalAmountCents) && (
            <TableRowCustom>
              <TableCellCustom>Freight charge</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={currency(
                    stopDifferences.freightCharge?.totalAmountCents.oldValue ??
                      0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  newValue={currency(
                    stopDifferences.freightCharge?.totalAmountCents.newValue ??
                      0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={currency(
                    stopDifferences.freightCharge?.totalAmountCents.oldValue ??
                      0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  newValue={currency(
                    stopDifferences.freightCharge?.totalAmountCents.newValue ??
                      0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(
            stopDifferences.freightCharge?.fuelCharge?.totalAmountCents,
          ) && (
            <TableRowCustom>
              <TableCellCustom>Fuel charge</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={currency(
                    stopDifferences.freightCharge?.fuelCharge?.totalAmountCents
                      ?.oldValue ?? 0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  newValue={currency(
                    stopDifferences.freightCharge?.fuelCharge?.totalAmountCents
                      ?.newValue ?? 0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  isOldValueBeingDisplayed
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={currency(
                    stopDifferences.freightCharge?.fuelCharge?.totalAmountCents
                      ?.oldValue ?? 0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  newValue={currency(
                    stopDifferences.freightCharge?.fuelCharge?.totalAmountCents
                      ?.newValue ?? 0,
                    {
                      fromCents: true,
                    },
                  ).format()}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.customCharges) && (
            <TableRow sx={{ border: 'none' }}>
              <TableCellCustom>Other charges</TableCellCustom>
              <TableCellCustom>
                <CustomChargeDifferenceBlock
                  customChargeDifferences={stopDifferences.customCharges}
                  isOldCustomCharges
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <CustomChargeDifferenceBlock
                  customChargeDifferences={stopDifferences.customCharges}
                  isOldCustomCharges={false}
                />
              </TableCellCustom>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default StopDifferenceBlock;
