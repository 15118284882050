import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { isNil, toUpper } from 'lodash';
import { generateBarcode } from '../../../../common/utils/barcode';
import { formatWeightUnits } from '../../../../common/utils/prettyPrintUtils';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import { WeightUnits } from '../../../../generated/graphql';
import {
  AddressType,
  convertPackageNumberToPieceNameString,
} from '../../utils';

const replaceSpacesWithNonBreakingSpaces = (text: string) =>
  text.trim().replace(/ /g, '\xa0');

export interface LotLabelDocumentProps {
  companyName: string;
  contact: string;
  orderName: string;
  mawb: string;
  hawb: string;
  defaultWeightUnits: WeightUnits | undefined;
  widthDimension: number;
  heightDimension: number;
  rotate: boolean;
  shipperText: string | undefined;
  outboundText: string | undefined;
  packageNumber: number;
  totalWeight: number;
  originText: string | undefined;
  destinationText: string | undefined;
  totalQuantity: number;
  consigneeAddress: AddressType | undefined;
  appendPieceIdToLotLabel: boolean;
  inboundDate?: string;
  outboundDate?: string;
  refNumbers:
    | {
        refNumber: string;
        label?: string;
      }[]
    | undefined;
  warehouseLocation: string | undefined;
}

const GeneratedLotLabel = ({
  companyName,
  contact,
  orderName,
  mawb,
  hawb,
  defaultWeightUnits,
  widthDimension,
  heightDimension,
  rotate,
  shipperText,
  outboundText,
  packageNumber,
  totalWeight,
  originText,
  destinationText,
  totalQuantity,
  consigneeAddress,
  appendPieceIdToLotLabel,
  inboundDate,
  outboundDate,
  refNumbers = [],
  warehouseLocation,
}: LotLabelDocumentProps) => {
  const consigneeAddressNameWithNonBreakingSpaces =
    replaceSpacesWithNonBreakingSpaces(consigneeAddress?.name ?? '');
  const shipperTextWithNonBreakingSpaces = replaceSpacesWithNonBreakingSpaces(
    shipperText ?? '',
  );
  const hasRightSideOrderDetails =
    !isNilOrEmptyString(refNumbers[0]?.refNumber) ||
    !isNilOrEmptyString(refNumbers[1]?.refNumber) ||
    !isNilOrEmptyString(refNumbers[2]?.refNumber) ||
    !isNilOrEmptyString(warehouseLocation);
  const styles = StyleSheet.create({
    page: {
      padding: 4,
      fontFamily: 'Roboto',
      fontSize: '10px',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: rotate ? 'rotate(90deg)' : undefined,
    },
    headerTextBold: {
      fontSize: '15px',
      fontWeight: 'bold',
    },
    headerText: {
      fontSize: '12px',
    },
    originCodeText: {
      fontSize: '36px',
      fontWeight: 'bold',
    },
    destinationText: {
      // hacky way to resize the destination text
      fontSize:
        // eslint-disable-next-line no-nested-ternary
        (destinationText?.length ?? 0) > 20
          ? '12px'
          : (destinationText?.length ?? 0) > 10
            ? '20px'
            : '32px',
      fontWeight: 'bold',
      flexWrap: 'wrap',
      width: '100%',
    },
    metadataText: {
      fontSize: '6px',
      color: 'gray',
    },
    outerView: {
      height: `${(rotate ? widthDimension / heightDimension : 1) * 100}%`,
      width: `${(rotate ? heightDimension / widthDimension : 1) * 100}%`,
    },
    outerCell: {
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      borderWidth: 1,
      borderColor: 'black',
    },
    cell: {
      borderTopWidth: 1,
      borderColor: 'black',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    orderSummaryCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    orderSummaryCellWithBorders: {
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: 'gray',
      paddingHorizontal: 2,
      marginHorizontal: 2,
    },
    orderSummaryText: {
      textAlign: 'right',
    },
    orderDetails: {
      flex: 1,
      display: 'flex',
      paddingRight: 2,
    },
    leftDivider: {
      borderLeft: '1px solid gray',
    },
    orderDetailCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxHeight: '14px',
      overflow: 'hidden',
    },
    orderDetailLabel: {
      flexBasis: 40,
      fontWeight: 'bold',
    },
    orderDetailText: {
      flex: 1,
      minWidth: 0,
    },
    originAndDestinationCell: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      paddingLeft: 4,
      gap: 2,
      position: 'relative',
    },
    dateCell: {
      fontSize: '7px',
      color: 'gray',
      position: 'absolute',
      width: '100%',
      textAlign: 'right',
    },
  });

  const barcodeOrderNameString = appendPieceIdToLotLabel
    ? `${orderName}+${convertPackageNumberToPieceNameString(packageNumber)}`
    : orderName;

  const barcode = generateBarcode({
    data: barcodeOrderNameString,
    width: 2,
    height: 60,
    displayValue: false,
  });

  return (
    <Page
      key={packageNumber}
      size={{ width: widthDimension * 72, height: heightDimension * 72 }} // convert from pt to inc
      orientation="portrait"
      style={styles.page}
    >
      <View style={styles.outerView}>
        <Text style={styles.headerTextBold}>{companyName.toUpperCase()}</Text>
        <View style={styles.outerCell}>
          <View style={[styles.cell, styles.orderSummaryCell]}>
            <Text style={{ width: !isNil(barcode) ? '50%' : '100%' }}>
              {contact.toUpperCase()}
            </Text>
            {!isNil(barcode) && (
              <View style={{ width: '50%', height: '30px' }}>
                <Image
                  source={{
                    uri: barcode,
                    method: 'GET',
                    headers: {},
                    body: '',
                  }}
                />
              </View>
            )}
          </View>
          <View style={[styles.cell]}>
            <View style={[styles.orderSummaryCell, { width: '30%' }]}>
              <Text>PCS:</Text>
              <Text style={styles.orderSummaryText}>{totalQuantity}</Text>
            </View>
            <View
              style={[
                styles.orderSummaryCell,
                styles.orderSummaryCellWithBorders,
                { width: '35%' },
              ]}
            >
              <Text>WT:</Text>
              <Text style={styles.orderSummaryText}>
                {totalWeight} {toUpper(formatWeightUnits(defaultWeightUnits))}
              </Text>
            </View>
            <View style={[styles.orderSummaryCell, { width: '35%' }]}>
              <Text>TAG#:</Text>
              <Text style={styles.orderSummaryText}>
                {barcodeOrderNameString}
              </Text>
            </View>
          </View>
          <View style={[styles.cell]}>
            <View style={[styles.orderDetails]}>
              <View style={[styles.orderDetailCell]}>
                <Text style={styles.orderDetailLabel}>MAWB#:</Text>
                <Text style={styles.orderDetailText}>{mawb}</Text>
              </View>
              <View style={[styles.orderDetailCell]}>
                <Text style={styles.orderDetailLabel}>HAWB#</Text>
                <Text style={styles.orderDetailText}>{hawb}</Text>
              </View>
              <View style={[styles.orderDetailCell]}>
                <Text style={styles.orderDetailLabel}>SHIP:</Text>
                <Text style={styles.orderDetailText}>
                  {shipperTextWithNonBreakingSpaces}
                </Text>
              </View>
              {!isNilOrEmptyString(
                consigneeAddressNameWithNonBreakingSpaces,
              ) && (
                <View style={[styles.orderDetailCell]}>
                  <Text style={styles.orderDetailLabel}>CONS:</Text>
                  <Text style={[styles.orderDetailText]}>
                    {consigneeAddressNameWithNonBreakingSpaces}
                  </Text>
                </View>
              )}
              {!isNilOrEmptyString(outboundText) && (
                <View style={[styles.orderDetailCell]}>
                  <Text style={styles.orderDetailLabel}>OUT:</Text>
                  <Text style={[styles.orderDetailText]}>{outboundText}</Text>
                </View>
              )}
            </View>
            {hasRightSideOrderDetails && (
              <View style={[styles.orderDetails, styles.leftDivider]}>
                {!isNilOrEmptyString(refNumbers[0]?.refNumber) && (
                  <View style={[styles.orderDetailCell]}>
                    <Text style={styles.orderDetailLabel}>
                      {refNumbers[0]?.label ?? 'REF1'}:
                    </Text>
                    <Text style={styles.orderDetailText}>
                      {refNumbers[0]?.refNumber}
                    </Text>
                  </View>
                )}
                {!isNilOrEmptyString(refNumbers[1]?.refNumber) && (
                  <View style={[styles.orderDetailCell]}>
                    <Text style={styles.orderDetailLabel}>
                      {refNumbers[1]?.label ?? 'REF2'}:
                    </Text>
                    <Text style={styles.orderDetailText}>
                      {refNumbers[1]?.refNumber}
                    </Text>
                  </View>
                )}
                {!isNilOrEmptyString(refNumbers[2]?.refNumber) && (
                  <View style={[styles.orderDetailCell]}>
                    <Text style={styles.orderDetailLabel}>
                      {refNumbers[2]?.label ?? 'REF3'}:
                    </Text>
                    <Text style={styles.orderDetailText}>
                      {refNumbers[2]?.refNumber}
                    </Text>
                  </View>
                )}
                {!isNilOrEmptyString(warehouseLocation) && (
                  <View style={[styles.orderDetailCell]}>
                    <Text style={styles.orderDetailLabel}>LOC:</Text>
                    <Text style={[styles.orderDetailText]}>
                      {warehouseLocation}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>
          <View style={styles.cell}>
            <View style={[styles.originAndDestinationCell]}>
              <Text>Origin:</Text>
              <Text style={styles.originCodeText}>{originText}</Text>
              <View style={styles.dateCell}>
                <Text>{inboundDate}</Text>
              </View>
            </View>
            <View style={[styles.originAndDestinationCell, styles.leftDivider]}>
              <Text>Dest:</Text>
              <Text style={[styles.destinationText]}>{destinationText}</Text>
              <View style={styles.dateCell}>
                <Text>{outboundDate}</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.metadataText}>
          Generated at: {dayjs().format('MM/DD/YYYY hh:mm a')}
        </Text>
      </View>
    </Page>
  );
};

export default GeneratedLotLabel;
