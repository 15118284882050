import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import {
  getAppointmentTimeString,
  transformAddressToFullAddressString,
} from 'shared/copy';
import { shallow } from 'zustand/shallow';
import useDispatchTableColors from '../../../../../common/react-hooks/use-dispatch-table-colors';
import { StopOnRouteBySearchTextFragment } from '../../../../../generated/graphql';
import useDispatchStore from '../../../dispatch-store';
import { useDispatchViewSettings } from '../../../hooks/use-dispatch-view-settings';
import { getEtaOrCompletedTimeString } from '../../../utils';
import PackageInfoText from '../package-info-text';
import StopDetailsStatusIcon from '../stop-details-status-icon';
import StopStatusChip from '../stop-status-chip';

const StopOnRouteFoundCard = ({
  stop,
}: {
  stop: StopOnRouteBySearchTextFragment;
}) => {
  const { dispatchTableColors } = useDispatchTableColors();
  const { useMinimizedAppointmentTime } = useDispatchViewSettings();
  const [setOpenedOrderUuid] = useDispatchStore(
    (state) => [state.setOpenedOrderUuid],
    shallow,
  );
  const route = stop.routeSlot?.route;
  const order = stop.shipment?.order;
  const packages = stop.shipment?.order?.packages;
  const service = stop.shipment?.order?.service;

  const serviceColor = dispatchTableColors?.find(
    (dispatchTableColor) =>
      !isNil(dispatchTableColor.service) &&
      dispatchTableColor.service.uuid === service?.uuid &&
      dispatchTableColor.active === true,
  )?.color;

  const appointmentTimeString = getAppointmentTimeString({
    stop,
    deliveryDate: stop.shipment?.standardShipmentFields?.deliveryDate,
    useMinimizedAppointmentTime,
  });

  const etaOrCompletedTimeString = getEtaOrCompletedTimeString(stop);

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        borderWidth: 1,
      }}
    >
      <Stack sx={{ p: '3px', px: '8px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {route?.name}
            </Typography>
            {!isNil(route?.terminal) && (
              <Typography variant="caption">
                {' '}
                - {route?.terminal.code}
              </Typography>
            )}
            <Typography variant="caption" color="text.secondary">
              {dayjs(route?.date).format('MM/DD')}
            </Typography>
          </Stack>
          <Button
            sx={{
              fontSize: '12px',
              py: 0,
              px: 1,
            }}
            onClick={() => {
              setOpenedOrderUuid(order?.uuid);
            }}
          >
            {order?.standardOrderFields.shipperBillOfLadingNumber}
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Stack sx={{ py: '3px', px: '8px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {sentenceCase(stop.stopType ?? '')}
            </Typography>
            {!isNil(stop.stopDetails) && (
              <StopDetailsStatusIcon
                status={stop.stopDetails.stopDetailsStatus}
                message={stop.stopDetails.message}
              />
            )}
            {!isNil(service) && (
              <>
                <Stack direction="row" alignItems="center">
                  <Typography variant="caption" color="text.secondary" noWrap>
                    {service?.name}
                  </Typography>
                </Stack>
                {!isNil(serviceColor) && (
                  <Chip
                    size="small"
                    sx={{
                      height: 7,
                      width: 7,
                      backgroundColor: serviceColor,
                    }}
                    label=""
                  />
                )}
              </>
            )}
          </Stack>
          <StopStatusChip status={stop.status} refusedBy={order?.refusedBy} />
        </Stack>
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          {stop?.address.name}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {transformAddressToFullAddressString(stop.address, false)}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            {isEmpty(packages) || isNil(packages) ? (
              <Typography variant="caption">No packages</Typography>
            ) : (
              <Tooltip
                title={
                  packages.length > 1 ? (
                    <Stack>
                      {packages.map((pkg) => (
                        <PackageInfoText pkg={pkg} key={pkg.uuid} />
                      ))}
                    </Stack>
                  ) : (
                    ''
                  )
                }
              >
                <Stack direction="row" spacing={0.25}>
                  <PackageInfoText pkg={packages[0]} />
                  {packages.length > 1 && (
                    <Typography variant="caption" color="text.secondary">
                      +{packages.length - 1}
                    </Typography>
                  )}
                </Stack>
              </Tooltip>
            )}
          </Box>
          <Stack direction="row">
            <Typography variant="caption">
              {appointmentTimeString} | {etaOrCompletedTimeString}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default StopOnRouteFoundCard;
