import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
  Operation,
  split,
  UriFunction,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { withScalars } from 'apollo-link-scalars';
import sha256 from 'crypto-js/sha256';
import { GraphQLScalarType } from 'graphql';
import { buildClientSchema, IntrospectionQuery } from 'graphql/utilities';
import { isNil } from 'lodash';
import currencyScalarConfig from 'shared/graphql-scalars/currency.scalar';
import {
  durationScalarConfig,
  lengthScalarConfig,
  massScalarConfig,
  percentScalarConfig,
  ratePerMileScalarConfig,
  ratePerMinuteScalarConfig,
  ratePerPoundScalarConfig,
  ratePerQuantityScalarConfig,
  volumeScalarConfig,
} from 'shared/graphql-scalars/scalars';
import { isNotNilOrEmptyString } from 'shared/string';
import { timeoutLink } from './apollo/links/timeout/timeout-link';
import { initializeDatadogRum, OPERATION_NAME_HEADER } from './datadog';
import { EnvironmentVariables } from './environment-variables';
import apolloClientPossibleTypes from './generated/apollo-client-possible-types';
import introspectionResult from './generated/graphql.schema.json';

const cache = new InMemoryCache({
  dataIdFromObject: (responseObject) =>
    (responseObject.uuid as string) ?? defaultDataIdFromObject(responseObject),
  possibleTypes: apolloClientPossibleTypes.possibleTypes,
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      if (graphQLError.extensions?.code === 'UNAUTHENTICATED') {
        return;
      }
      console.error(
        `GraphQLError - code: ${graphQLError.extensions?.code}, message: ${graphQLError.message}, context: ${JSON.stringify(
          graphQLError.extensions?.context,
        )}`,
      );
    });
  }

  let statusCode: number | undefined;
  if (networkError && 'statusCode' in networkError) {
    statusCode = networkError.statusCode;
  }

  if (networkError) {
    console.error(`NetworkError - ${networkError.message}`, {
      statusCode,
    });
  }
});

const QUARANTINED_OPERATIONS: ReadonlySet<string> = new Set([
  'accessorialChargeDetailsReport',
  'accountsReceivableReport',
  'agingReport',
  'auditReport',
  'contactBalances',
  'contactBalancesByOrderUuid',
  'contactsWithTariffs',
  'customerReport',
  'chargeBreakdownReportPdf',
  'driverReport',
  'dunningReportPdf',
  'emailAccountingReport',
  'incomeAnalysisReport',
  'invoiceAgingReport',
  'invoiceBillingSummaryReport',
  'invoiceBillingSummaryReportV2Pdf',
  'invoiceBillingSummaryReportV2PdfUrl',
  'invoicesByUuidsForDownload',
  'netSalesSummaryReport',
  'netSalesSummaryReport',
  'openInvoiceReport',
  'orderPodReports',
  'orderReport',
  'orderTableReport',
  'orderReportByReportGroup',
  'outstandingShipments',
  'outstandingShipmentsUuids',
  'parseCsvOrders',
  'paymentsForCheckRegisterReport',
  'sameDayDispatchRoutesWithDriverCoords',
  'serviceLevelReport',
  'shallowContacts',
  'terminalReport',
  'storageOrder',
  'importStorageOrdersFromCSV',
  'orderRevenueReport',
  'driverDailyLogReportPdf',
  'shallowInvoicesV2',
]);

const DOCUMENT_GENERATION_OPERATIONS: ReadonlySet<string> = new Set([
  'sendInvoices',
  'sendAccountingReports',
  'orderTableReportV2',
]);

const apiUrl = `${EnvironmentVariables.VITE_BACKEND_URL}/graphql`;
let slowApiUrl: string | undefined;
if (isNotNilOrEmptyString(EnvironmentVariables.VITE_SLOW_BACKEND_URL)) {
  slowApiUrl = `${EnvironmentVariables.VITE_SLOW_BACKEND_URL}/graphql`;
}
let documentGenerationApiUrl: string | undefined;
if (
  isNotNilOrEmptyString(
    EnvironmentVariables.VITE_DOCUMENT_GENERATION_BACKEND_URL,
  )
) {
  documentGenerationApiUrl = `${EnvironmentVariables.VITE_DOCUMENT_GENERATION_BACKEND_URL}/graphql`;
}

const resolvers = {
  // can't use config, and instead have to duplicate code because for some reason apollo needs one of the
  // resolver configs to not be passed in as a variable
  Currency: new GraphQLScalarType(currencyScalarConfig),
  CurrencyRatePerQuantity: new GraphQLScalarType(ratePerQuantityScalarConfig),
  CurrencyRatePerPound: new GraphQLScalarType(ratePerPoundScalarConfig),
  CurrencyRatePerMile: new GraphQLScalarType(ratePerMileScalarConfig),
  CurrencyRatePerMinute: new GraphQLScalarType(ratePerMinuteScalarConfig),
  Duration: new GraphQLScalarType(durationScalarConfig),
  Length: new GraphQLScalarType(lengthScalarConfig),
  Mass: new GraphQLScalarType(massScalarConfig),
  Percent: new GraphQLScalarType(percentScalarConfig),
  Volume: new GraphQLScalarType(volumeScalarConfig),
};

const scalarsLink = withScalars({
  schema: buildClientSchema(
    introspectionResult as unknown as IntrospectionQuery,
  ),
  typesMap: resolvers,
});

const clientMetadataLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'x-git-hash': EnvironmentVariables.VITE_RENDER_GIT_COMMIT,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'x-graphql-client-name': `tms-frontend`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'x-graphql-client-version': `git-${EnvironmentVariables.VITE_RENDER_GIT_COMMIT}`,
  },
}));

/** Set the operation name in the headers of the HTTP request. Used for debugging and Datadog RUM */
const operationNameLink = setContext((operation, { headers }) => ({
  headers: {
    ...headers,
    [OPERATION_NAME_HEADER]: operation.operationName,
  },
}));

const chooseUri: UriFunction = (operation: Operation) => {
  try {
    if (
      isNotNilOrEmptyString(slowApiUrl) &&
      QUARANTINED_OPERATIONS.has(operation.operationName)
    ) {
      return slowApiUrl;
    }
    if (
      isNotNilOrEmptyString(documentGenerationApiUrl) &&
      DOCUMENT_GENERATION_OPERATIONS.has(operation.operationName)
    ) {
      return documentGenerationApiUrl;
    }
  } catch (e) {
    console.error('Error choosing uri', e);
  }
  return apiUrl;
};

const chooseUriWithOperationQueryParam: UriFunction = (
  operation: Operation,
) => {
  const defaultUri = chooseUri(operation);

  try {
    const u = new URL(defaultUri);
    const qp = u.searchParams;
    qp.set('op', operation.operationName);
    u.search = qp.toString();
    return u.toString();
  } catch {
    return defaultUri;
  }
};

const OPERATIONS_NON_BATCHED: ReadonlySet<string> = new Set([
  'route',
  'routes',
  'orders',
  'stops',
  'standardOrder',
  'tariffs',
  'accessorials',
  'accessorialsByBillingContact',
  'shallowContacts',
  'me',
  'allInvoicesForPaymentApplication',
  'updateStandardOrder',
  'contactBalances',
  'contactIsOverCreditLimit',
  'queryQuickbooksDesktopItems',
  'queryQuickbooksDesktopAccounts',
  'queryQuickbooksDesktopCustomers',
  'featureFlags',
  'stops',
  'ablyToken',
]);

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation) => {
      for (const definitionNode of _operation.query.definitions) {
        if ('operation' in definitionNode) {
          return !isNil(error) && definitionNode.operation !== 'mutation';
        }
      }
      return !isNil(error);
    },
  },
});

const persistedQueryLink = createPersistedQueryLink({
  sha256: (str) => sha256(str).toString(),
});

// MUST be called before apollo client is initialized https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#missing-data
initializeDatadogRum();

const apolloClient = new ApolloClient({
  cache,
  credentials: 'include',
  link: ApolloLink.from([
    scalarsLink,
    operationNameLink,
    clientMetadataLink,
    onErrorLink,
    timeoutLink, // must be before retryLink
    retryLink,
    persistedQueryLink,
    split(
      (operation) => OPERATIONS_NON_BATCHED.has(operation.operationName),
      from([
        new HttpLink({
          credentials: 'include',
          uri: chooseUriWithOperationQueryParam,
        }),
      ]),
      from([
        new BatchHttpLink({
          credentials: 'include',
          uri: chooseUriWithOperationQueryParam,
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'x-apollo-batch': 'true',
          },
        }),
      ]),
    ),
  ]),
  // disable apollo cache
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
