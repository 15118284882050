import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAppointmentConfirmationInfoQuery,
  useMakeAppointmentConfirmationDecisionMutation,
} from '../../../generated/graphql';
import { ConfirmRescheduleAppointment } from './confirm-reschedule-appointment';

type AppointmentDecisionViewProps = {
  stopUuid: string;
};

const AppointmentDecisionView: FunctionComponent<
  AppointmentDecisionViewProps
> = ({ stopUuid }) => {
  const styles = {
    buttonView: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '20px',
      gap: 3,
    } as SxProps,
    textView: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      m: 5,
    } as SxProps,
  };

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: appointmentInfoData, loading } =
    useAppointmentConfirmationInfoQuery({
      variables: { stopUuid },
    });
  const [
    makeAppointmentConfirmationDecision,
    { loading: loadingMakeAppointmentConfirmationDecision },
  ] = useMakeAppointmentConfirmationDecisionMutation({
    onCompleted: (data) => {
      if (
        data.makeAppointmentConfirmationDecision.__typename ===
        'AppointmentConfirmationInfoOutput'
      ) {
        const accepted =
          !data.makeAppointmentConfirmationDecision.rescheduleRequested;
        navigate(`/confirm-appt/decision-complete?accepted=${accepted}`, {
          replace: true,
        });
      } else {
        setErrorMessage(data.makeAppointmentConfirmationDecision.message);
      }
    },
  });

  // Are we showing the reschedule confirmation dialog?
  const [showRescheduleDialog, setShowRescheduleDialog] = useState(false);

  useEffect(() => {
    if (!isNil(appointmentInfoData)) {
      // Redirect to decision complete (confirmation) if appointment has already been confirmed
      if (
        appointmentInfoData.appointmentConfirmationInfo.appointmentConfirmed
      ) {
        navigate(`/confirm-appt/decision-complete?accepted=true`, {
          replace: true,
        });
        return;
      }

      // Redirect to decision complete (reschedule/rejected) if appointment has already been rejected
      if (appointmentInfoData.appointmentConfirmationInfo.rescheduleRequested) {
        navigate(`/confirm-appt/decision-complete?accepted=false`, {
          replace: true,
        });
      }
    }
  }, [appointmentInfoData, navigate]);

  const handleAppointmentAccept = () => {
    makeAppointmentConfirmationDecision({
      variables: {
        input: {
          stopUuid,
          accepted: true,
        },
      },
    });
  };

  const handleAppointmentReschedule = () => {
    makeAppointmentConfirmationDecision({
      variables: {
        input: {
          stopUuid,
          accepted: false,
        },
      },
    });
  };

  return (
    <Box pt={4} pb={2} px={3}>
      {!isNil(errorMessage) && (
        <Typography color="error" mb={2}>
          {errorMessage}
        </Typography>
      )}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isNil(appointmentInfoData) &&
        !appointmentInfoData.appointmentConfirmationInfo.rescheduleRequested &&
        (!showRescheduleDialog ? (
          <>
            <Box sx={styles.textView}>
              <Typography variant="h5">
                Confirm{' '}
                {appointmentInfoData.appointmentConfirmationInfo.stopType?.toLowerCase()}{' '}
                appointment for{' '}
                {
                  appointmentInfoData.appointmentConfirmationInfo
                    .appointmentDateTime
                }
              </Typography>
            </Box>
            <Box sx={styles.buttonView}>
              <Button
                color="success"
                variant="contained"
                onClick={handleAppointmentAccept}
              >
                Confirm
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={() => setShowRescheduleDialog(true)}
              >
                Request Reschedule
              </Button>
            </Box>
          </>
        ) : (
          <ConfirmRescheduleAppointment
            handleAppointmentReschedule={handleAppointmentReschedule}
            loading={loadingMakeAppointmentConfirmationDecision}
            pickupOrDelivery={appointmentInfoData.appointmentConfirmationInfo.stopType?.toLowerCase()}
            onBack={() => setShowRescheduleDialog(false)}
          />
        ))}
    </Box>
  );
};

export default AppointmentDecisionView;
