import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  CreatePersonInput,
  PermissionResource,
  PersonStatus,
  useArchivePersonMutation,
  useCreatePersonMutation,
  usePersonsQuery,
  useRestorePersonMutation,
  useUpdatePersonMutation,
} from '../../../generated/graphql';
import ContactPageMode from './contact-page-mode';
import PeoplePanelPerson from './people-panel-person';

type PeoplePanelProps = {
  contactUuid: string;
  mode: ContactPageMode;
  handleChangeMode: (mode: ContactPageMode) => void;
};

function PeoplePanel({
  contactUuid,
  mode,
  handleChangeMode,
}: PeoplePanelProps) {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const {
    data: personsData,
    refetch,
    loading,
  } = usePersonsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      contactUuid,
    },
  });
  const [createPerson, { loading: creating }] = useCreatePersonMutation({
    fetchPolicy: 'network-only',
  });
  const [updatePerson, { loading: updating }] = useUpdatePersonMutation({
    fetchPolicy: 'network-only',
  });
  const [archivePerson, { loading: archiving }] = useArchivePersonMutation({
    fetchPolicy: 'network-only',
  });
  const [restorePerson, { loading: restoring }] = useRestorePersonMutation({
    fetchPolicy: 'network-only',
  });

  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(
    personsData?.persons.persons[0]?.id ?? null,
  );

  const selectedPerson = useMemo(
    () =>
      isNil(selectedPersonId)
        ? null
        : personsData?.persons.persons.find(
            ({ id }) => id === selectedPersonId,
          ),
    [personsData, selectedPersonId],
  );

  useEffect(() => {
    if (!isNil(personsData) && isNil(selectedPersonId)) {
      const [firstPerson] = personsData.persons.persons;
      setSelectedPersonId(firstPerson?.id ?? null);
    }
  }, [personsData, selectedPersonId]);

  const handleSave = (person: Omit<CreatePersonInput, 'contactUuid'>) => {
    if (mode === ContactPageMode.CREATE) {
      createPerson({
        variables: {
          input: {
            ...person,
            contactUuid,
          },
        },
        onCompleted: async (data) => {
          const { data: newPersonsData } = await refetch();
          const newPerson = newPersonsData.persons.persons.find(
            ({ id }) => id === data.createPerson.person.id,
          );
          if (!isNil(newPerson)) {
            setSelectedPersonId(newPerson.id);
          }
          handleChangeMode(ContactPageMode.VIEW);
        },
      });
    } else if (!isNil(selectedPerson)) {
      updatePerson({
        variables: {
          input: {
            id: selectedPerson.id,
            contactUuid,
            ...person,
          },
        },
        onCompleted: () => {
          handleChangeMode(ContactPageMode.VIEW);
        },
      });
    }
  };

  const onToggleArchive = () => {
    if (!isNil(selectedPerson)) {
      if (selectedPerson.status === PersonStatus.Active) {
        archivePerson({
          variables: {
            id: selectedPerson.id,
          },
        });
      } else {
        restorePerson({
          variables: {
            id: selectedPerson.id,
          },
        });
      }
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 220,
            p: 2,
          }}
        >
          <Button
            variant="outlined"
            disabled={mode === ContactPageMode.CREATE || !canWriteContacts}
            onClick={() => handleChangeMode(ContactPageMode.CREATE)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <AddIcon />
            <span>Add person</span>
          </Button>
          {personsData?.persons.persons.map((person) => (
            <Button
              key={person.id}
              disabled={mode !== ContactPageMode.VIEW}
              onClick={() => setSelectedPersonId(person.id)}
              variant={
                !isNil(selectedPersonId) && person.id === selectedPersonId
                  ? 'contained'
                  : 'outlined'
              }
            >
              {person.firstName} {person.lastName}
            </Button>
          ))}
        </Card>
        <Card
          sx={{
            width: 720,
          }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading ? (
            <Box sx={{ py: 3, textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : !isNil(selectedPerson) || mode === ContactPageMode.CREATE ? (
            <PeoplePanelPerson
              key={
                mode === ContactPageMode.CREATE ? 'create' : selectedPerson?.id
              }
              contactUuid={contactUuid}
              person={mode === ContactPageMode.CREATE ? null : selectedPerson}
              mode={mode}
              handleChangeMode={handleChangeMode}
              canWrite={canWriteContacts}
              handleSave={handleSave}
              saving={creating || updating}
              onToggleArchive={onToggleArchive}
              togglingArchive={archiving || restoring}
            />
          ) : (
            <Typography p={3} textAlign="center">
              You do not have any people for this customer.
            </Typography>
          )}
        </Card>
      </Box>
    </Box>
  );
}

export default PeoplePanel;
