import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { defaultStyles, JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { isPalletAdmin } from '../../../../../../../../../utils';
import useMe from '../../../../../../../../common/react-hooks/use-me';
import {
  AuditLogEntryFragment,
  OrderEventType,
} from '../../../../../../../../generated/graphql';
import {
  formatOrderEventActor,
  renderOrderEventMessage,
} from '../../order-audit-log-utils';
import { mapOrderSourceToString } from '../storage-order-audit-log-card';
import SnapshotDifferenceDetails from './snapshot-differences/snapshot-difference-details';

dayjs.extend(utc);
dayjs.extend(timezone);

const SCAN_ORDER_EVENT_TYPES = [
  OrderEventType.OrderScanned,
  OrderEventType.PieceScanned,
];

const CardContentSpecialPadding = styled(CardContent)(`
    padding: 10px;
    padding-bottom: 15px !important;
  `);

const OrderAuditLogCard = ({
  auditLogEntry,
}: {
  auditLogEntry: AuditLogEntryFragment;
}) => {
  const { orderEvent, snapshotDifference } = auditLogEntry;
  const { email } = useMe();
  const [showJsonDialog, setShowJsonDialog] = useState<boolean>(false);

  const isInternalUser = isPalletAdmin(email ?? '');

  const formattedTime = SCAN_ORDER_EVENT_TYPES.includes(orderEvent.eventType)
    ? dayjs(orderEvent.createdAt).format('MM/DD/YY h:mm:ssa')
    : dayjs(orderEvent.createdAt).format('MM/DD/YY h:mma');

  return (
    <Card variant="outlined">
      <CardContentSpecialPadding>
        <Stack direction="column" spacing={1}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                {formattedTime}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ float: 'right' }}
                color="text.secondary"
                variant="caption"
              >
                {formatOrderEventActor(orderEvent)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="body2">
                    {orderEvent.eventType === OrderEventType.OrderSnapshot
                      ? 'Order Edited'
                      : capitalCase(orderEvent.eventType)}
                  </Typography>
                </Grid>
                <Grid item>
                  {isInternalUser && !isNil(orderEvent.snapshot) && (
                    <>
                      <Dialog
                        onClose={() => {
                          setShowJsonDialog(false);
                        }}
                        open={showJsonDialog}
                      >
                        <DialogTitle>Raw JSON Snapshot Data</DialogTitle>
                        <JsonView
                          data={JSON.parse(orderEvent.snapshot)}
                          shouldInitiallyExpand={(level, value, field) =>
                            level === 0 || field === 'shipments'
                          }
                          style={defaultStyles}
                        />
                      </Dialog>
                      <Button
                        onClick={() => {
                          setShowJsonDialog(true);
                        }}
                        size="small"
                      >
                        View JSON
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {renderOrderEventMessage(orderEvent)}
              </Typography>
            </Grid>
            {orderEvent.eventType === OrderEventType.OrderSnapshot &&
              !isNil(snapshotDifference) && (
                <Grid item xs={12} mt={2}>
                  <SnapshotDifferenceDetails
                    snapshotDifference={snapshotDifference}
                  />
                </Grid>
              )}
          </Grid>
          {orderEvent.eventType === OrderEventType.OrderCreated && (
            <Grid item xs={12}>
              <Typography variant="caption">
                {!isNil(orderEvent.order)
                  ? mapOrderSourceToString(orderEvent.order)
                  : ''}
              </Typography>
            </Grid>
          )}
        </Stack>
      </CardContentSpecialPadding>
    </Card>
  );
};

export default React.memo(OrderAuditLogCard);
