import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { sortBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useThirdPartyBrokers from '../../../../common/react-hooks/use-third-party-brokers';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  useCreateThirdPartyBrokerMutation,
  useRemoveThirdPartyBrokerMutation,
  ThirdPartyBrokersDocument,
  PermissionResource,
} from '../../../../generated/graphql';
import ThirdPartyBrokerRow from './third-party-broker-row';

const ThirdPartyBrokers = () => {
  const confirm = useConfirm();
  const { refetchThirdPartyBrokers, thirdPartyBrokers } =
    useThirdPartyBrokers();
  const { userPermissions } = useUserRoles();
  const [createThirdPartyBroker] = useCreateThirdPartyBrokerMutation({
    refetchQueries: [ThirdPartyBrokersDocument],
  });
  const [removeThirdPartyBroker] = useRemoveThirdPartyBrokerMutation({
    refetchQueries: [ThirdPartyBrokersDocument],
  });
  const { canWrite: canWriteThirdPartyBrokers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyThirdPartyBrokers,
  );

  const addThirdPartyBroker = async () => {
    await createThirdPartyBroker({
      variables: {
        thirdPartyBrokerCreateInput: {
          name: 'New Third Party / Broker',
        },
      },
    });
    refetchThirdPartyBrokers();
  };

  const handleDelete = (uuid: string) => {
    // prompt the user before overriding the terminal
    confirm({
      title: 'Delete third party / broker',
      description: (
        <Typography>
          Are you sure you want to delete the third party / broker? This will
          delete it from all existing orders.
        </Typography>
      ),
      cancellationText: `Cancel`,
      confirmationText: `Confirm`,
    }).then(async () => {
      await removeThirdPartyBroker({
        variables: {
          uuid,
        },
      });
      refetchThirdPartyBrokers();
    });
  };

  return (
    <Stack direction="column" alignItems="flex-end">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50%' }}>Name</TableCell>
              <TableCell align="right">
                <Button
                  onClick={addThirdPartyBroker}
                  sx={{ marginBottom: '16px' }}
                  variant="contained"
                  disabled={!canWriteThirdPartyBrokers}
                >
                  Add Third Party / Broker
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(thirdPartyBrokers, 'name').map((thirdPartyBroker) => (
              <ThirdPartyBrokerRow
                key={thirdPartyBroker.uuid}
                thirdPartyBroker={thirdPartyBroker}
                onDelete={() => {
                  handleDelete(thirdPartyBroker.uuid);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ThirdPartyBrokers;
