import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import React, { memo, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import useServices from '../../../../../common/react-hooks/use-services';
import useTariffGroupStore from '../store/tariff-group-state-store';

const ServiceDropdown = ({
  contactUuid,
  serviceUuidsSelected,
}: {
  contactUuid: string;
  serviceUuidsSelected: string[];
}) => {
  const { services } = useServices();
  const [setContactToServiceMapping, removeServiceFromContactToServiceMapping] =
    useTariffGroupStore(
      (state) => [
        state.setContactToServiceMapping,
        state.removeServiceFromContactToServiceMapping,
      ],
      shallow,
    );

  const serviceCheckboxChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    serviceUuid: string,
  ) => {
    if (event.target.checked) {
      setContactToServiceMapping(contactUuid, [
        ...(serviceUuidsSelected ?? []),
        serviceUuid,
      ]);
    } else {
      removeServiceFromContactToServiceMapping(contactUuid, serviceUuid);
    }
  };

  return (
    <AccordionDetails>
      {services?.map((service) => (
        <Stack direction="row" alignItems="center" pl={2} key={service.uuid}>
          <Checkbox
            size="small"
            checked={serviceUuidsSelected?.includes(service.uuid)}
            onChange={(event) => {
              serviceCheckboxChanged(event, service.uuid);
            }}
          />
          <Typography fontSize="12px">{sentenceCase(service.name)}</Typography>
        </Stack>
      ))}
    </AccordionDetails>
  );
};

const ContactAccordion = ({
  contactName,
  contactUuid,
}: {
  contactName: string;
  contactUuid: string;
}) => {
  const { services } = useServices();
  const [
    contactUuidToServiceUuidsMapping,
    setContactToServiceMapping,
    removeKeyFromContactToServiceMapping,
  ] = useTariffGroupStore(
    (state) => [
      state.contactUuidToServiceUuidsMapping[contactUuid],
      state.setContactToServiceMapping,
      state.removeKeyFromContactToServiceMapping,
    ],
    shallow,
  );

  const contactCheckboxChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();

    if (event.target.checked) {
      setContactToServiceMapping(
        contactUuid,
        services?.map((s) => s.uuid) ?? [],
      );
    } else {
      removeKeyFromContactToServiceMapping(contactUuid);
    }
  };

  const numberOfServicesSelected = useMemo(
    () =>
      contactUuidToServiceUuidsMapping?.filter(
        (serviceUuid) => services.find((s) => s.uuid === serviceUuid)?.isActive,
      ).length ?? 0,
    [contactUuidToServiceUuidsMapping, services],
  );

  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
        }}
      >
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={(contactUuidToServiceUuidsMapping?.length ?? 0) > 0}
            onChange={(event) => {
              contactCheckboxChanged(event);
            }}
            size="small"
          />
          <Stack>
            <Typography fontSize="13px">{contactName}</Typography>
            {numberOfServicesSelected > 0 && (
              <Typography color="grey" fontSize="11px">
                {numberOfServicesSelected} service levels
              </Typography>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <ServiceDropdown
        contactUuid={contactUuid}
        serviceUuidsSelected={contactUuidToServiceUuidsMapping ?? []}
      />
    </Accordion>
  );
};

export default memo(ContactAccordion);
