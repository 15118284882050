import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import {
  DetailedLineHaulManifestFragment,
  ShallowLineHaulSegmentFragment,
} from '../../../../../generated/graphql';
import ManifestOrdersContextMenu from './manifest-orders-context-menu';
import OpenedManifestOrdersTableRow from './opened-manifest-orders-table-row';

const OpenedManifestOrdersTable = ({
  manifest,
  unmanifestedSegment,
}: {
  manifest: DetailedLineHaulManifestFragment | undefined;
  unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
}) => {
  const [searchText, setSearchText] = useState<string>('');
  // const [debouncedSearchText] = useDebounce(searchText, 200);

  const filteredOrderSegments = manifest?.orderSegments.filter(
    ({ order }) =>
      order.name.includes(searchText) ||
      order.standardOrderFields.shipperBillOfLadingNumber?.includes(searchText),
  );

  const [selectedOrderUuids, setSelectedOrderUuids] = useState<string[]>([]);

  return (
    <Stack minHeight={0}>
      <Stack direction="row">
        <Box p={2} sx={{ flex: 1 }}>
          <TextField
            className="dispatch-search-input"
            variant="standard"
            size="small"
            label="Search orders"
            autoComplete="off"
            sx={{ minWidth: 60 }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Box>
        <ManifestOrdersContextMenu
          manifestUuid={manifest?.uuid}
          selectedOrders={
            manifest?.orderSegments
              .filter((o) => selectedOrderUuids.includes(o.order.uuid))
              .map((segment) => segment.order) ?? []
          }
        />
      </Stack>

      <TableContainer sx={{ overflowY: 'scroll', width: '100%' }}>
        <Table
          stickyHeader
          aria-label="freight-snap-preview-table"
          sx={{
            '& .MuiTableCell-sizeMedium': {
              padding: '10px 10px',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>MAWB</TableCell>
              <TableCell>Packages</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>
                <Stack direction="column">
                  Departure
                  <Typography color="gray" variant="subtitle2">
                    {manifest?.startTerminal.code}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="column">
                  Arrival
                  <Typography color="gray" variant="subtitle2">
                    {manifest?.endTerminal.code}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          {!isNil(manifest) && (
            <TableBody>
              {(filteredOrderSegments ?? []).map((orderSegment) => (
                <OpenedManifestOrdersTableRow
                  key={orderSegment.order.uuid}
                  orderSegment={orderSegment}
                  orderIsSelected={selectedOrderUuids.includes(
                    orderSegment.order.uuid,
                  )}
                  setOrderAsSelected={() => {
                    setSelectedOrderUuids([
                      ...selectedOrderUuids,
                      orderSegment.order.uuid,
                    ]);
                  }}
                  unselectRow={() => {
                    setSelectedOrderUuids(
                      selectedOrderUuids.filter(
                        (uuid) => uuid !== orderSegment.order.uuid,
                      ),
                    );
                  }}
                  manifest={manifest}
                />
              ))}
            </TableBody>
          )}
          {!isNil(unmanifestedSegment) && <TableBody />}
        </Table>
        {(filteredOrderSegments?.length ?? 0) === 0 && (
          <Stack alignItems="center" padding="50px">
            <Typography variant="subtitle2" textAlign="center">
              Add orders to this line haul route by selecting them from the
              table on the left.
            </Typography>
          </Stack>
        )}
      </TableContainer>
    </Stack>
  );
};

export default OpenedManifestOrdersTable;
