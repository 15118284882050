import {
  Link,
  TableCell,
  TableHead,
  Theme,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';

interface TableCellCustomProps {
  cellPadding?: number;
}
export const TableCellCustom = styled(TableCell)<TableCellCustomProps>(
  (props) => ({
    padding: !isNil(props.cellPadding) ? `${props.cellPadding}px` : '6px',
    border: 'none',
    // fontSize: '14px',
  }),
);

interface TableTypographyProps {
  bold?: boolean;
  isGrey?: boolean;
  theme?: Theme;
  sidePadding?: number;
  color?: string;
  fontSize?: string;
}

export const TableTypography = styled(Typography)<TableTypographyProps>(
  ({ bold, isGrey, theme, sidePadding, color, fontSize }) => ({
    fontWeight: bold === true ? '600' : 'normal',
    color:
      isGrey === true && isNil(color)
        ? theme.palette.grey[600]
        : (color ?? 'black'),
    paddingLeft: !isNil(sidePadding) ? `${sidePadding}px` : 'none',
    paddingRight: !isNil(sidePadding) ? `${sidePadding}px` : 'none',
    fontSize: fontSize ?? '14px',
  }),
);

interface TableHeaderTypographyProps {
  theme?: Theme;
  sidePadding?: number;
  fontSize?: string;
}

export const TableHeaderTypography = styled(
  Typography,
)<TableHeaderTypographyProps>(({ theme, sidePadding, fontSize }) => ({
  color: theme.palette.grey[600],
  fontWeight: 500,
  paddingLeft: !isNil(sidePadding) ? `${sidePadding}px` : 'none',
  paddingRight: !isNil(sidePadding) ? `${sidePadding}px` : 'none',
  fontSize: fontSize ?? '14px',
}));

interface TableHeadCustomProps {
  backgroundColor?: string;
  addBorderBottom?: boolean;
}
export const TableHeadCustom = styled(TableHead)<TableHeadCustomProps>(
  (props) => ({
    backgroundColor: props.backgroundColor ?? 'white',
    borderBottom: props.addBorderBottom === true ? 'solid 0.5px grey' : 'none',
  }),
);

export const HawbLink = ({
  hawb,
  onClick,
  fontSize,
}: {
  hawb: string;
  onClick: () => void;
  fontSize: number;
}) => {
  const theme = useTheme();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link sx={{ cursor: 'pointer' }} onClick={onClick}>
      <Typography
        fontSize={fontSize}
        fontWeight={600}
        color={theme.palette.primary.main}
        sx={{ wordBreak: 'break-all' }}
      >
        {hawb}
      </Typography>
    </Link>
  );
};
