import { AgGridReactProps } from 'ag-grid-react';
import { FilterViewPage } from '../../../generated/graphql';
import invokeAll from './invoke-all';
import useSaveColumnWidth from './use-save-column-widths';

const useOnColumnResized = (
  onColumnResized: AgGridReactProps['onColumnResized'],
  pageType: FilterViewPage,
) => {
  const { onColumResizedWidthSave } = useSaveColumnWidth({ pageType });
  return invokeAll(onColumResizedWidthSave, onColumnResized);
};

export default useOnColumnResized;
