import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Fade, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import Popper from '@mui/material/Popper';
import { isNil } from 'lodash';
import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { shallow } from 'zustand/shallow';
import { RouteFragment } from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import useRouteActions from '../../hooks/use-route-actions';
import { TableStopOnRoute } from '../route-card-stops-list-columns';
import RouteContextMenu, { OpenModalsActions } from './route-context-menu';

const RouteCardHoverMenu = ({
  anchorRef,
  route,
  setIsEditing,
  open,
  openModalsActions,
  stops,
}: {
  anchorRef: RefObject<HTMLDivElement>;
  route: RouteFragment;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  openModalsActions: OpenModalsActions;
  stops: TableStopOnRoute[];
}) => {
  const theme = useTheme();
  const [showMap, selectedViewUuid, openedRouteUuid, setOpenedRouteUuid] =
    useDispatchStore(
      (state) => [
        state.showMap,
        state.selectedViewUuid,
        state.openedRouteUuid,
        state.setOpenedRouteUuid,
      ],
      shallow,
    );
  const { showRouteOnMap } = useRouteActions();

  return (
    <Popper
      style={{ zIndex: 100 }}
      open={open}
      anchorEl={anchorRef?.current}
      transition
      placement="top-end"
      keepMounted
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={50}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              border: `1px solid ${theme.palette.borderColor.main}`,
              borderRadius: '5px',
              backgroundColor: 'white',
              p: '3px',
              mr: -1,
              mb: -3,
            }}
          >
            <Tooltip title="Edit Route Details">
              <IconButton
                sx={{ p: '5px' }}
                onClick={(e) => {
                  setIsEditing(true);
                  e.stopPropagation();
                }}
                disabled={route.locked}
              >
                <EditIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            {openedRouteUuid !== route.uuid && (
              <Tooltip title="View Route">
                <IconButton
                  sx={{ p: '5px' }}
                  onClick={(e) => {
                    setOpenedRouteUuid(route.uuid);
                    e.stopPropagation();
                  }}
                >
                  <LoginIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}
            {!showMap && isNil(selectedViewUuid) && (
              <Tooltip title="Map Route">
                <IconButton
                  sx={{ p: '5px' }}
                  onClick={(e) => {
                    showRouteOnMap(route.uuid);
                    e.stopPropagation();
                  }}
                >
                  <MapOutlinedIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}
            <RouteContextMenu
              route={route}
              stops={stops}
              openModalsActions={openModalsActions}
            />
          </Stack>
        </Fade>
      )}
    </Popper>
  );
};

export default React.memo(RouteCardHoverMenu);
