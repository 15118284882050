import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { useOrdersForInventoryLabelsQuery } from '../../../../generated/graphql';
import {
  downloadMultipleLotLabels,
  downloadMultipleOutboundLabels,
} from '../utils';

export const useBulkDownloadDocumentsForMultipleOrders = ({
  orderUuids,
}: {
  orderUuids: string[];
}) => {
  const ffAppendPieceIdToLotLabel = useFeatureFlag(
    FeatureFlag.FF_APPEND_PIECE_ID_STRING_TO_LOT_LABEL,
  );
  const { data } = useOrdersForInventoryLabelsQuery({
    variables: {
      uuids: orderUuids,
    },
    fetchPolicy: 'cache-first',
  });

  const downloadLotLabelsForMultipleOrders = async () => {
    await downloadMultipleLotLabels(
      data?.ordersByUuids,
      ffAppendPieceIdToLotLabel,
    );
  };

  const downloadOutboundLabelsForMultipleOrders = async () => {
    await downloadMultipleOutboundLabels(
      data?.ordersByUuids,
      ffAppendPieceIdToLotLabel,
    );
  };

  return {
    downloadLotLabelsForMultipleOrders,
    downloadOutboundLabelsForMultipleOrders,
  };
};
