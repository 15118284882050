import {
  Button,
  Fade,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useTerminals from '../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  RecurringRunsDocument,
  RecurringRunTemplateFragment,
  useRecurringRunsQuery,
  useUpdateRecurringRunMutation,
} from '../../../generated/graphql';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from './common/active-archived-tab-panel';
import { RecurringRunModal } from './recurring-run-modal';

const RecurringRunsTable = () => {
  const { data: recurringRunsData } = useRecurringRunsQuery();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: true,
  });

  const [updateRecurringRun] = useUpdateRecurringRunMutation({
    refetchQueries: [RecurringRunsDocument],
  });

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyRecurringOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecurringOrders,
  );

  const [hoveredRecurringRunUuid, setHoveredRecurringRunUuid] = useState<
    string | undefined
  >();
  const [selectedRunToEdit, setSelectedRunToEdit] = useState<
    RecurringRunTemplateFragment | undefined
  >();
  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  return (
    <>
      <ActiveArchivedButtonGroup
        activeTab={isActiveOrArchived}
        setTab={setIsActiveOrArchived}
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {terminalsEnabled && <TableCell>Terminal</TableCell>}
              <TableCell>Driver</TableCell>
              <TableCell>Vehicle Type</TableCell>
              <TableCell>Orders</TableCell>
              <TableCell align="right">
                {canWriteCompanyRecurringOrders && (
                  <Button
                    onClick={() => {
                      setSelectedRunToEdit(undefined);
                      setIsDialogOpen(true);
                    }}
                    variant="contained"
                    disabled={!canWriteCompanyRecurringOrders}
                  >
                    Add route template
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recurringRunsData?.recurringRuns
              .filter(
                (template) =>
                  template.isArchived ===
                  (isActiveOrArchived === ActiveOrArchivedTab.ARCHIVED),
              )
              .map((template: RecurringRunTemplateFragment) => (
                <TableRow
                  key={template.uuid}
                  sx={{ width: '100%' }}
                  onMouseEnter={() => {
                    setHoveredRecurringRunUuid(template.uuid);
                  }}
                  onMouseLeave={() => {
                    setHoveredRecurringRunUuid(undefined);
                  }}
                >
                  <TableCell>{template.name}</TableCell>
                  {terminalsEnabled && (
                    <TableCell>
                      {!isNil(template.terminal) ? (
                        template.terminal.name
                      ) : (
                        <Typography color="text.secondary">—</Typography>
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    {!isNil(template.driver) ? (
                      <>
                        {template.driver.firstName} {template.driver.lastName}
                      </>
                    ) : (
                      <Typography color="text.secondary">—</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {!isNil(template.vehicleType) ? (
                      template.vehicleType.name
                    ) : (
                      <Typography color="text.secondary">—</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {template.recurringOrderTemplates.length}
                  </TableCell>
                  <TableCell align="right">
                    <Fade in={hoveredRecurringRunUuid === template.uuid}>
                      <Stack
                        display="flex"
                        direction="row"
                        justifyContent="flex-end"
                        gap={2}
                      >
                        <Button
                          variant="contained"
                          disabled={!canWriteCompanyRecurringOrders}
                          onClick={() => {
                            setSelectedRunToEdit(template);
                            setIsDialogOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={async (e) => {
                            e.stopPropagation();
                            await updateRecurringRun({
                              variables: {
                                input: {
                                  uuid: template.uuid,
                                  isArchived: !template.isArchived,
                                },
                              },
                            });
                          }}
                        >
                          {template.isArchived ? 'Unarchive' : 'Archive'}
                        </Button>
                      </Stack>
                    </Fade>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RecurringRunModal
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setSelectedRunToEdit(undefined);
        }}
        selectedRecurringRun={selectedRunToEdit}
      />
    </>
  );
};

export default RecurringRunsTable;
