import { Checkbox, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { NO_LIMIT } from './order-filters';

type OrderFilterDatePickerProps = {
  handleChange: (date: Date | null) => void;
  name: string;
  value: Date | null;
  allowNoLimit: boolean;
  setEnd?: boolean;
  setStart?: boolean;
};

export const OrderFilterDatePicker = ({
  handleChange,
  name,
  value,
  allowNoLimit,
  setEnd,
  setStart,
}: OrderFilterDatePickerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datePickerRef = useRef<any>();

  const [noLimit, setNoLimit] = useState<boolean>(isNil(value) && allowNoLimit);
  const onNoLimitToggle = (checked: boolean) => {
    setNoLimit(checked);
    if (checked) {
      handleChange(null);
    } else {
      handleChange(new Date());
    }
  };

  return (
    <DatePicker
      ref={datePickerRef}
      onChange={(time) => {
        if (!isNil(time)) {
          if (setEnd === true) {
            handleChange(dayjs(time.toString()).toDate());
          } else if (setStart === true) {
            handleChange(dayjs(time.toString()).toDate());
          } else {
            handleChange(new Date(time.toString()));
          }
        }
      }}
      value={value}
      calendarPosition="bottom"
      fixMainPosition
      style={{
        backgroundColor: 'white',
        padding: '4px',
        width: '80px',
        height: '20px',
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.00938em',
        color: 'rgba(0, 0, 0, 0.87)',
        zIndex: 1,
      }}
      placeholder={name}
      name={name}
      format="MM/DD/YYYY"
      highlightToday={!noLimit}
      minDate={noLimit ? dayjs().add(1, 'day').toDate() : dayjs(0).toDate()}
      maxDate={noLimit ? dayjs().subtract(1, 'day').toDate() : undefined}
      editable={!noLimit}
    >
      {allowNoLimit && (
        <Stack direction="row" alignItems="center">
          <Checkbox
            size="small"
            checked={noLimit}
            onChange={(e) => {
              onNoLimitToggle(e.target.checked);
            }}
          />
          <Typography sx={{ fontSize: 14 }}>{NO_LIMIT}</Typography>
        </Stack>
      )}
    </DatePicker>
  );
};
