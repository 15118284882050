import { ChevronLeft } from '@mui/icons-material';
import { IconButton, Skeleton, TableCell, TableRow } from '@mui/material';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil, sumBy } from 'lodash';
import React, { useMemo } from 'react';
import { getSettlementEligibleTotal } from 'shared/billing';
import { safeMultiply } from 'shared/math';
import { shallow } from 'zustand/shallow';
import { SettlementDeductionFragment } from '../../../../generated/graphql';
import useDriverSettlementStore from '../../driver-settlement-store';

interface SettlementsListRowProps {
  uuid: string;
  name: string | undefined | null;
  driverName: string;
  driverSettlementTotal: number | undefined | null;
  billStartDate: Date | undefined;
  billEndDate: Date | undefined;
  settlementDate: Date | undefined;
  settlementDeductions: SettlementDeductionFragment[];
}

const SettlementsListRow = ({
  uuid,
  name,
  driverName,
  driverSettlementTotal,
  billStartDate,
  billEndDate,
  settlementDate,
  settlementDeductions,
}: SettlementsListRowProps) => {
  const [openedSettlementUuid, setOpenedSettlementUuid] =
    useDriverSettlementStore(
      (state) => [state.openedSettlementUuid, state.setOpenedSettlementUuid],
      shallow,
    );

  const payPeriodString = useMemo(() => {
    if (isNil(billStartDate) && isNil(billEndDate)) {
      return 'No Pay Period';
    }
    return `${dayjs(billStartDate).format('MM/DD')} - ${dayjs(
      billEndDate,
    ).format('MM/DD/YYYY')}`;
  }, [billStartDate, billEndDate]);

  const settlementDateString = useMemo(() => {
    if (isNil(settlementDate) && isNil(settlementDate)) {
      return 'N/A';
    }
    return `${dayjs(settlementDate).format('MM/DD')}`;
  }, [settlementDate]);

  const totalDeductions = sumBy(settlementDeductions, (settlementDeduction) =>
    getSettlementEligibleTotal(
      settlementDeduction.flatRate,
      !isNil(settlementDeduction.percentageDecimalRate)
        ? safeMultiply(settlementDeduction.percentageDecimalRate, 100)
        : undefined,
      driverSettlementTotal,
    ),
  );

  return (
    <TableRow
      hover
      selected={uuid === openedSettlementUuid}
      role="checkbox"
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        if (!isNil(openedSettlementUuid)) {
          setOpenedSettlementUuid(uuid);
        }
      }}
    >
      <TableCell>{settlementDateString}</TableCell>
      <TableCell>{payPeriodString}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{driverName}</TableCell>
      <TableCell align="right">
        {!isNil(driverSettlementTotal) ? (
          currency(driverSettlementTotal).format()
        ) : (
          <Skeleton />
        )}
      </TableCell>
      <TableCell align="right">
        {!isNil(totalDeductions) ? (
          currency(totalDeductions).format()
        ) : (
          <Skeleton />
        )}
      </TableCell>
      <TableCell align="right">
        {!isNil(driverSettlementTotal) ? (
          currency(driverSettlementTotal).subtract(totalDeductions).format()
        ) : (
          <Skeleton />
        )}
      </TableCell>
      {isNil(openedSettlementUuid) && (
        <TableCell>
          <IconButton
            sx={{ padding: '5px' }}
            onClick={(e) => {
              setOpenedSettlementUuid(uuid);
              e.stopPropagation();
            }}
          >
            <ChevronLeft />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default SettlementsListRow;
