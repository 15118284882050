import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';
import {
  DefaultPackageSpec,
  useGetContactDefaultPackageSpec,
} from '../../../../../common/react-hooks/use-get-contact-default-package-spec';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  FreightBillingMethod,
  FuelBillingMethod,
  FulfillmentType,
  InboundMethod,
  QuoteStatus,
  ShallowContactFragment,
  ShipmentStatus,
  ShipmentType,
  useQuoteLazyQuery,
  useQuoteNameLazyQuery,
} from '../../../../../generated/graphql';
import { StopType } from '../../../../orders/components/order-form/forms/stop-type';
import {
  OrderChargesShipmentValues,
  PackageValues,
  StopValues,
} from '../../../../orders/components/order-form/forms/types';
import {
  convertLineHaulShipmentPayloadToFormData,
  convertOrderChargesShipmentPayloadToFormData,
  convertPackagePayloadToFormData,
  convertStopPayloadToFormData,
  getInitialLineHaulShipmentValues,
  getInitialPackageValues,
  getInitialStopValues,
  getNewStopsArrFromSingleStop,
} from '../../../../orders/components/order-form/forms/utils';
import { useOrderFormContact } from '../../../../orders/components/order-form/hooks/use-order-form-contact';
import { QuoteFormValues } from '../forms/use-quote-form';

const useLoadQuoteForm = () => {
  const { companyConfiguration } = useMe();
  const { refetch: refetchContact } = useOrderFormContact();
  const getContactDefaultPackageSpec = useGetContactDefaultPackageSpec();
  const [getQuote, { loading }] = useQuoteLazyQuery();
  const [quoteName] = useQuoteNameLazyQuery();
  const form = useFormContext<QuoteFormValues>();

  const initializeNewQuoteForm = async ({
    contactUuid,
    contact,
    terminalsEnabled,
    defaultPackageSpec,
  }: {
    contactUuid?: string;
    contact: ShallowContactFragment | undefined;
    terminalsEnabled: boolean;
    defaultPackageSpec: DefaultPackageSpec;
  }) => {
    let name = '';
    if (!isNil(contactUuid)) {
      [name] = await Promise.all([
        (
          await quoteName({
            variables: { billingPartyContactUuid: contactUuid },
          })
        ).data?.quoteName ?? '',
      ]);
    }
    const defaultDimFactor =
      contact?.__typename === 'CustomerContactEntity' &&
      !isNil(contact?.defaultDimFactor)
        ? contact?.defaultDimFactor
        : companyConfiguration?.defaultDimFactor;
    const defaultFuelSurcharge =
      (contact?.__typename === 'CustomerContactEntity' &&
      !isNil(contact?.defaultFuelSurcharge)
        ? contact?.defaultFuelSurcharge
        : companyConfiguration?.defaultFuelSurcharge) ?? 0;
    const defaultFreightBillingMethod =
      contact?.__typename === 'CustomerContactEntity' &&
      contact.defaultUseTariff === true
        ? FreightBillingMethod.Tariff
        : FreightBillingMethod.FlatRate;
    const stopValues: StopValues[] = [
      getInitialStopValues({
        defaultFuelSurcharge,
        freightBillingMethod: defaultFreightBillingMethod,
        stopType: StopType.Recovery,
        terminalsEnabled,
        fulfillmentType: FulfillmentType.MultiTrip,
      }),
      getInitialStopValues({
        defaultFuelSurcharge,
        freightBillingMethod: defaultFreightBillingMethod,
        inboundMethod: InboundMethod.Recovery,
        stopType: StopType.Delivery,
        terminalsEnabled,
        fulfillmentType: FulfillmentType.MultiTrip,
      }),
    ];
    const lineHaulShipmentValues = getInitialLineHaulShipmentValues({
      defaultFuelSurcharge,
    });
    const orderChargesShipmentValues: OrderChargesShipmentValues = {
      uuid: v4(),
      customCharges: [],
      shipmentStatus: ShipmentStatus.Created,
      isLocal: true,
    };

    const packageValues: PackageValues[] = [
      getInitialPackageValues(defaultPackageSpec),
    ];

    const quoteValues: QuoteFormValues = {
      contactUuid: contactUuid ?? '',
      status: QuoteStatus.Pending,
      number: name,
      stops: stopValues,
      packages: packageValues,
      dimFactor: defaultDimFactor,
      defaultFuelBillingMethod: FuelBillingMethod.AutoCalculate,
      defaultFuelSurcharge,
      defaultFreightBillingMethod,
      uuid: v4(),
      orderChargesShipment: orderChargesShipmentValues,
      isUsingLineHaul: false,
      lineHaulShipment: lineHaulShipmentValues,
      lineHaulLaneUuid: null,
    };

    form.reset(quoteValues);
  };

  const initializeExistingQuoteForm = async ({
    quoteUuid,
    terminalsEnabled,
  }: {
    quoteUuid: string;
    terminalsEnabled: boolean;
  }) => {
    const result = await getQuote({ variables: { uuid: quoteUuid } });
    const { data } = result;
    const contact = data?.quote.billingPartyContact;
    const defaultFuelSurcharge =
      (contact?.__typename === 'CustomerContactEntity' &&
      !isNil(contact?.defaultFuelSurcharge)
        ? contact?.defaultFuelSurcharge
        : companyConfiguration?.defaultFuelSurcharge) ?? 0;
    const defaultFreightBillingMethod =
      contact?.__typename === 'CustomerContactEntity' &&
      contact.defaultUseTariff === true
        ? FreightBillingMethod.Tariff
        : FreightBillingMethod.FlatRate;
    if (!isNil(data)) {
      const serviceUuid =
        data.quote.service?.uuid ??
        data.quote.shipments.find(
          (shipment) => !isNil(shipment.fields?.service?.uuid),
        )?.fields?.service?.uuid;
      const stopValues = data.quote.shipments
        .filter((shipment) => shipment.shipmentType === ShipmentType.Regular)
        .map((shipment) => {
          return convertStopPayloadToFormData({
            shipment,
            terminalsEnabled,
            defaultFuelSurcharge,
            duplicateEverything: false,
            freightBillingMethod: defaultFreightBillingMethod,
            fulfillmentType: FulfillmentType.MultiTrip,
          });
        });
      let stopsArr = stopValues;
      const singleStop = stopsArr[0];
      if (stopValues.length === 1 && !isNil(singleStop)) {
        stopsArr = getNewStopsArrFromSingleStop(
          singleStop,
          FulfillmentType.MultiTrip,
        );
      }
      const useCentimeters = form.getValues('useCentimeters');
      const useKilograms = form.getValues('useKilograms');
      const packageValues = data.quote.packages.map((pkg) =>
        convertPackagePayloadToFormData({
          package: pkg,
          useCentimeters,
          useKilograms,
        }),
      );
      const lineHaulShipment = data.quote.shipments.find(
        (s) => s.shipmentType === ShipmentType.LineHaul,
      );
      const lineHaulShipmentValues = !isNil(lineHaulShipment)
        ? // for quotes we can just assume the fuel profile for line haul uses the current date
          convertLineHaulShipmentPayloadToFormData({
            shipment: lineHaulShipment,
            lineHaulCompletedDate: new Date(),
          })
        : getInitialLineHaulShipmentValues({
            defaultFuelSurcharge:
              stopValues[0]?.freightCharge?.fuelCharge.surchargeRate,
          });

      const orderChargesShipment = data.quote.shipments.find(
        (s) => s.shipmentType === ShipmentType.OrderCharges,
      );

      const orderChargesShipmentValues = !isNil(orderChargesShipment)
        ? convertOrderChargesShipmentPayloadToFormData(orderChargesShipment)
        : null;

      const quoteValues: QuoteFormValues = {
        contactUuid: data.quote.billingPartyContact.uuid,
        serviceUuid,
        status: data.quote.status,
        number: data.quote.number,
        stops: stopsArr,
        packages: packageValues,
        dimFactor: data.quote.dimFactor,
        uuid: quoteUuid,
        lineHaulLaneUuid: data.quote.lineHaulLane?.uuid,
        lineHaulShipment: lineHaulShipmentValues,
        isUsingLineHaul: !isNil(data.quote.lineHaulLane?.uuid),
        orderChargesShipment: orderChargesShipmentValues,
        totalSkids: data.quote.totalSkids,
      };

      form.reset(quoteValues);
    }
  };

  const initializeForm = async ({
    quoteUuid,
    contactUuid,
    terminalsEnabled,
  }: {
    quoteUuid: string | undefined | null;
    contactUuid: string | undefined;
    terminalsEnabled: boolean;
  }) => {
    if (isNil(quoteUuid) && !isNil(contactUuid)) {
      const [getContactResult, defaultPackageSpec] = await Promise.all([
        refetchContact({
          uuid: contactUuid,
        }),
        getContactDefaultPackageSpec(contactUuid),
      ]);
      await initializeNewQuoteForm({
        contactUuid,
        contact: getContactResult.data?.contact,
        terminalsEnabled,
        defaultPackageSpec,
      });
    } else if (!isNil(quoteUuid)) {
      await initializeExistingQuoteForm({ quoteUuid, terminalsEnabled });
    }
  };

  return {
    initializeForm,
    loading,
  };
};

export { useLoadQuoteForm };
