import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isEmpty, isNil, sumBy, truncate } from 'lodash';
import React, { useState } from 'react';
import { safeMultiply } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { formatWeightUnits } from '../../../../common/utils/prettyPrintUtils';
import {
  InvoiceOrderWithShipmentsFragment,
  PaymentType,
  PermissionResource,
  WeightUnits,
} from '../../../../generated/graphql';
import OpenOrderButton from '../../../ag-grid/open-order-button';
import useInvoicesStore from '../../invoices-store';
import InvoiceOrdersListShipmentRow from './invoice-orders-list-shipment-row';
import RebillOrderModal from './rebill/rebill-order-modal';

interface InvoiceShipmentsListRowProps {
  order: InvoiceOrderWithShipmentsFragment;
  disableInvoiceEditing: boolean;
  removeFromInvoice?: () => void;
  invoiceUuid: string;
  invoiceContactUuid: string;
  inCustomersTab?: boolean; // To be used with Customers -> Invoices. Disables actions, shows additional columns
}

const InvoiceOrderListRow = ({
  order,
  disableInvoiceEditing,
  removeFromInvoice,
  invoiceUuid,
  invoiceContactUuid,
  inCustomersTab = false,
}: InvoiceShipmentsListRowProps) => {
  const [isChecked, selectInvoiceOrderUuid, deselectInvoiceOrderUuid] =
    useInvoicesStore(
      (state) => [
        state.selectedInvoiceOrderUuids.includes(order.uuid),
        state.selectInvoiceOrderUuid,
        state.deselectInvoiceOrderUuid,
      ],
      shallow,
    );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );

  const [showRebillModal, setShowRebillModal] = useState<boolean>(false);
  const [rebillSuccessAlertInvoiceName, setRebillSuccessAlertInvoiceName] =
    useState<string | undefined>(undefined);

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const rebillOrder = () => {
    setShowRebillModal(true);
  };

  const onRowClick = disableInvoiceEditing
    ? undefined
    : () => {
        if (isChecked) {
          deselectInvoiceOrderUuid(order.uuid);
        } else {
          selectInvoiceOrderUuid(order.uuid);
        }
      };

  const notes = order?.orderComments
    ?.filter((comment) => comment.showOnInvoice)
    .map((comment) => (
      <Typography key={comment.uuid} sx={{ fontSize: '14px' }}>
        {comment.comment}
      </Typography>
    ));

  // We can't directly use the order's total charge because an order could be split between invoices.
  const invoiceShipments = order.shipments.filter(
    (shipment) =>
      shipment.shipmentCharges.totalCharge !== 0 &&
      shipment.invoice?.uuid === invoiceUuid,
  );
  const orderTotalOnInvoice = sumBy(
    invoiceShipments,
    'shipmentCharges.totalCharge',
  );

  const nonZeroShipmentsOnInvoice = order.shipments.filter((shipment) =>
    invoiceShipments.some((sh) => sh.uuid === shipment.uuid),
  );

  return (
    <>
      <TableRow
        sx={{ cursor: !isNil(onRowClick) ? 'pointer' : undefined }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onRowClick}
      >
        <TableCell>
          <Stack direction="row" alignItems="center">
            {!disableInvoiceEditing && !inCustomersTab && (
              <Checkbox size="small" checked={isChecked} />
            )}
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: '14px' }}>{order.name}</Typography>
        </TableCell>
        <TableCell>
          {order.standardOrderFields.shipperBillOfLadingNumber}
        </TableCell>
        <TableCell>
          {order.standardOrderFields.masterAirwayBillOfLadingNumber}
        </TableCell>
        <TableCell>{order.secondaryRefNumber ?? '-'}</TableCell>
        <TableCell>${orderTotalOnInvoice.toFixed(2)}</TableCell>
        {inCustomersTab && (
          <TableCell>
            {currency(order.balanceInCents, { fromCents: true }).format()}
          </TableCell>
        )}
        <TableCell
          sx={{
            minWidth: '150px',
            maxWidth: '150px',
          }}
        >
          <Stack alignItems="center" gap={1} direction="row">
            {order.balanceInCents <= 0 ? (
              <CheckCircleIcon color="success" sx={{ fontSize: '13px' }} />
            ) : (
              <CancelIcon color="error" sx={{ fontSize: '13px' }} />
            )}
            <Stack alignItems="center" direction="column">
              {order.orderPaymentMappings.map((paymentMapping) => (
                <Link
                  sx={{
                    cursor: 'pointer',
                  }}
                  href={`/accounting/?tab=2&customerUuid=${paymentMapping.payment.contact.uuid}`}
                  target="_blank"
                  underline="hover"
                  key={paymentMapping.uuid}
                >
                  <Stack alignItems="center" direction="row">
                    {`${currency(
                      safeMultiply(
                        paymentMapping.amountInCents,
                        paymentMapping.payment.paymentType === PaymentType.Debit
                          ? -1
                          : 1,
                      ),
                      {
                        fromCents: true,
                      },
                    ).format()}${
                      paymentMapping.payment.contact.uuid === invoiceContactUuid
                        ? ''
                        : ` by ${truncate(paymentMapping.payment.contact.displayName, { length: 15 })}`
                    }`}
                  </Stack>
                </Link>
              ))}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          {!isNil(order.endStop?.serviceDate)
            ? dayjs(order.endStop?.serviceDate).format('MM/DD/YY')
            : '-'}
        </TableCell>
        {inCustomersTab && (
          <TableCell>{order.pieceCountFromPackages}</TableCell>
        )}
        {inCustomersTab && (
          <TableCell>
            {order.weight}{' '}
            {formatWeightUnits(
              order?.standardOrderFields?.weightUnits ?? WeightUnits.Pounds,
            )}
          </TableCell>
        )}
        <TableCell
          sx={{
            minWidth: '200px',
            maxWidth: '200px',
          }}
        >
          {order.endStop?.address?.name ?? '-'}
        </TableCell>
        <TableCell>
          <Fade in={isHovering}>
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <OpenOrderButton
                openInCurrentPage
                params={{ data: { uuid: order.uuid } }}
                setSuccessMessage={() => null}
                buttonLabel={!disableInvoiceEditing ? 'Open' : 'View'}
                includeContextMenu={false}
              />
              {!inCustomersTab && (
                <>
                  <IconButton
                    id="more-menu-button"
                    aria-controls={menuAnchorEl ? 'more-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuAnchorEl ? 'true' : undefined}
                    onClick={(e) => {
                      e.stopPropagation();
                      setMenuAnchorEl(e.currentTarget);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="more-menu"
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={() => {
                      setMenuAnchorEl(null);
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'more-menu-button',
                    }}
                  >
                    <Tooltip
                      title={
                        order.appliedAmount > 0
                          ? 'Order already has payment/credit applied'
                          : null
                      }
                    >
                      <div>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            rebillOrder();
                          }}
                          disabled={order.appliedAmount > 0 || !canWriteOrders}
                        >
                          Rebill
                        </MenuItem>
                      </div>
                    </Tooltip>
                    {!disableInvoiceEditing && !isNil(removeFromInvoice) && (
                      <>
                        <Divider />
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFromInvoice();
                          }}
                        >
                          Remove
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </>
              )}
            </Stack>
          </Fade>
        </TableCell>
      </TableRow>
      <TableRow style={{ paddingBottom: 0, paddingTop: 0 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Stack direction="row" spacing={1} sx={{ pl: 6, pt: 1 }}>
              {!isNil(notes) && !isEmpty(notes) && (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    Notes:{' '}
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }} color="text.secondary">
                    {notes}
                  </Typography>
                </>
              )}
            </Stack>
            {nonZeroShipmentsOnInvoice.length > 0 ? (
              nonZeroShipmentsOnInvoice.map((shipment) => (
                <InvoiceOrdersListShipmentRow
                  key={shipment.uuid}
                  shipment={shipment}
                  order={order}
                />
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: '14px',
                  pl: 6,
                  pb: 1,
                }}
                color="text.secondary"
              >
                No shipments with charges on invoice
              </Typography>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <RebillOrderModal
        open={showRebillModal}
        setOpen={setShowRebillModal}
        orderUuid={order.uuid}
        invoiceUuid={invoiceUuid}
        setRebillSuccessAlertInvoiceName={setRebillSuccessAlertInvoiceName}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setRebillSuccessAlertInvoiceName(undefined)}
        open={!isNil(rebillSuccessAlertInvoiceName)}
      >
        <Alert>
          {' '}
          Successfully created new invoice {rebillSuccessAlertInvoiceName}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InvoiceOrderListRow;
