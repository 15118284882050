import { isNil } from 'lodash';
import { useEffect, useRef } from 'react';

type ThreeInputsTuple = [
  HTMLInputElement | null,
  HTMLInputElement | null,
  HTMLInputElement | null,
];

// The actual object provided by React.
type DimensionsInputRefs = {
  current: ThreeInputsTuple;
};

// A React ref of a tuple of three HTMLInputElements. This hook handles focusing and tabbing between inputs.
const useDimensionsInputRefs = (): DimensionsInputRefs => {
  const inputRefs = useRef<ThreeInputsTuple>([null, null, null]);

  const focusNextInput = (index: number) => {
    const nextInput = inputRefs.current[index + 1];
    if (!isNil(nextInput)) {
      nextInput.focus();
    } else {
      // Fall back to the first input (loop around).
      inputRefs.current[0]?.focus();
    }
  };

  const focusPrevInput = (index: number) => {
    const prevInput = inputRefs.current[index - 1];
    if (!isNil(prevInput)) {
      prevInput.focus();
    } else {
      // Fall back to the last input (loop around).
      inputRefs.current[2]?.focus();
    }
  };

  useEffect(() => {
    const createHandleKeyDownCallback =
      (index: number) => (e: KeyboardEvent) => {
        const inputRef = inputRefs.current[index];
        if (!isNil(inputRef)) {
          const { selectionStart, value } = inputRef;

          if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
            if (selectionStart === value.length) {
              // Prevent tabbing only when caret is at the end.
              e.preventDefault();
              focusNextInput(index);
            }
          } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
            if (selectionStart === 0) {
              // Prevent tabbing only when caret is at the beginning.
              e.preventDefault();
              focusPrevInput(index);
            }
          }
        }
      };

    // We need to create these callbacks this way so that we can remove the event listeners later.
    const callbacks: Array<(e: KeyboardEvent) => void> = [];

    inputRefs.current.forEach((inputRef, index) => {
      const callback = createHandleKeyDownCallback(index);
      callbacks.push(callback);
      inputRef?.addEventListener('keydown', callback);
    });

    return () => {
      inputRefs.current.forEach((inputRef, index) => {
        const callback = callbacks[index];
        if (!isNil(inputRef) && !isNil(callback)) {
          inputRef.removeEventListener('keydown', callback);
        }
      });
    };
  }, []);

  return inputRefs;
};

export default useDimensionsInputRefs;
