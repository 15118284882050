import React from 'react';
import { useRateOrderContents } from '../../../../../hooks/use-rate-order';

/**
 * We create this component just to contain the useRateOrderContents hook in order to isolate rendering.
 */
const RateOrderContainer = React.memo(() => {
  useRateOrderContents();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
});

export default RateOrderContainer;
