import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Fade, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  TariffGroupForTableFragment,
} from '../../../../../generated/graphql';
import EditTariffGroupModal from '../edit-tariff-group-modal';
import EditTariffButton from './edit-tariff-button';
import { TariffTableContactCell } from './tariff-table-contact-cell';

interface TariffTableRowProps {
  tariffGroup: TariffGroupForTableFragment;
}

const TariffTableRow = ({ tariffGroup }: TariffTableRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyTariffs } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTariffs,
  );

  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [showEditTariffGroupModal, setShowEditTariffGroupModal] =
    useState<boolean>(false);

  const startDate = !isNil(tariffGroup.startDate)
    ? dayjs(tariffGroup.startDate).format('MM/DD/YYYY')
    : '';
  const endDate = !isNil(tariffGroup.endDate)
    ? dayjs(tariffGroup.endDate).format('MM/DD/YYYY')
    : '';
  const dateString =
    !isEmpty(startDate) || !isEmpty(endDate) ? `${startDate} - ${endDate}` : '';
  return (
    <>
      <TableRow
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <TableCell sx={{ maxWidth: '300px', fontWeight: '500' }}>
          {tariffGroup.name}
        </TableCell>
        <TableCell>
          {/* eslint-disable-next-line no-nested-ternary */}
          {tariffGroup.contactToServicesMappings.length === 0 ? (
            <Stack
              direction="row"
              alignItems="center"
              gap="10px"
              sx={{
                color: 'red',
              }}
            >
              <Typography fontSize="14px">None</Typography>
              <PriorityHighIcon fontSize="small" />
            </Stack>
          ) : (
            <TariffTableContactCell tariffGroup={tariffGroup} />
          )}
        </TableCell>
        <TableCell>
          {sentenceCase(tariffGroup.tariffGroupType ?? '-')}
        </TableCell>
        <TableCell>
          {sentenceCase(tariffGroup.firstTariff?.tariffType ?? '')}
        </TableCell>
        <TableCell>
          {sentenceCase(tariffGroup.firstTariff?.tariffZone.type ?? '')}
        </TableCell>
        <TableCell>
          {sentenceCase(tariffGroup.firstTariff?.rateType ?? '')}
        </TableCell>
        <TableCell>{dateString}</TableCell>
        <TableCell align="right">
          <Fade in={isHovering}>
            <div>
              <EditTariffButton
                clickHandler={() => {
                  setShowEditTariffGroupModal(true);
                }}
                disabled={!canWriteCompanyTariffs}
              />
            </div>
          </Fade>
        </TableCell>
      </TableRow>
      <EditTariffGroupModal
        isOpen={showEditTariffGroupModal}
        setIsOpen={setShowEditTariffGroupModal}
        tariffGroupUuid={tariffGroup.uuid}
      />
    </>
  );
};

export default React.memo(TariffTableRow);
