import { isNil } from 'lodash';
import { CsvOrderMappingTargetFieldType } from '../../../generated/graphql';

export const populateFieldMappingsFromCsv = async (file: File) => {
  // Read the CSV file
  const text = await file.text();
  const [headerRow] = text.split('\n');
  const headers = headerRow
    ?.split(',')
    .map((header) => header.trim().replace(/^"(.*)"$/, '$1')); // Remove surrounding quotes

  const newFieldMappings = headers?.map((header) => ({
    sourceField: header,
    targetField: CsvOrderMappingTargetFieldType.TerminalName,
  }));

  // todo: handle csv upload error
  if (isNil(newFieldMappings)) {
    return null;
  }
  return newFieldMappings;
};
