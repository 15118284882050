import { Dialog, Box, Paper } from '@mui/material';
import React from 'react';
import Draggable from 'react-draggable';
import UnifiedSearch from './unified-search';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PaperComponent = (props: any) => {
  return (
    <Draggable handle="#drag-icon" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const UnifiedSearchDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Dialog
      PaperComponent={PaperComponent}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="drag-icon"
    >
      <Box sx={{ minHeight: '500px' }}>
        <UnifiedSearch setOpen={setOpen} />
      </Box>
    </Dialog>
  );
};

export default UnifiedSearchDialog;
