import useMe from '../../../../common/react-hooks/use-me';

interface InvoiceNameOrJournalNumberProps {
  invoice: {
    name?: string | null;
    journalNumber?: number | null;
  };
  defaultValue?: string;
  useJournalNumberForInvoice?: boolean | null;
}

// function version
export const getInvoiceNameOrJournalNumber = ({
  invoice,
  defaultValue,
  useJournalNumberForInvoice,
}: InvoiceNameOrJournalNumberProps) => {
  return useJournalNumberForInvoice === true
    ? invoice.journalNumber?.toString() ?? defaultValue
    : invoice.name ?? defaultValue;
};

const InvoiceNameOrJournalNumber = ({
  invoice,
  defaultValue,
  useJournalNumberForInvoice,
}: InvoiceNameOrJournalNumberProps) => {
  const { companyConfiguration } = useMe();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {getInvoiceNameOrJournalNumber({
        invoice,
        defaultValue,
        useJournalNumberForInvoice:
          useJournalNumberForInvoice ??
          companyConfiguration?.useJournalNumberForInvoice === true,
      })}
    </>
  );
};

export default InvoiceNameOrJournalNumber;
