import { Checkbox, FormControlLabel } from '@mui/material';
import useMe from '../../../common/react-hooks/use-me';
import {
  MeDocument,
  useUpdateCompanyConfigurationMutation,
} from '../../../generated/graphql';

const WarehouseConfigurations = () => {
  const { companyConfiguration } = useMe();
  const [updateCompanyConfiguration] = useUpdateCompanyConfigurationMutation({
    refetchQueries: [MeDocument],
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          defaultChecked={companyConfiguration?.wmsAutoMarkOnHand ?? false}
          onChange={async (e) => {
            if (companyConfiguration?.uuid == null) return;
            updateCompanyConfiguration({
              variables: {
                updateCompanyConfigurationInput: {
                  uuid: companyConfiguration?.uuid,
                  wmsAutoMarkOnHand: e.target.checked,
                },
              },
            });
          }}
        />
      }
      label="Automatically mark containers and items on-hand"
    />
  );
};

export default WarehouseConfigurations;
