import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { FieldError, FieldValues } from 'react-hook-form';
import {
  getStopTerminalSelectInputTestId,
  getStopTerminalSelectTestId,
} from '../../../../../../utils';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

export type ContactOption = {
  label: string;
  uuid: string;
};

export type TerminalAutocompleteFieldValues = FieldValues & {
  contactUuid: string;
};

type TerminalAutocompleteComponentProps = {
  terminalUuid: string | null | undefined;
  disabled: boolean;
  onChange: (
    event: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null,
  ) => void;
  error: FieldError | undefined;
  stopIdx: number;
};

function TerminalAutocompleteComponent({
  terminalUuid,
  disabled,
  onChange,
  error,
  stopIdx,
}: TerminalAutocompleteComponentProps) {
  const { terminals, getTerminal } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [terminalsToUse, setTerminalsToUse] = useState(terminals);

  const terminalOptions = useMemo(() => {
    return (
      terminalsToUse
        ?.map((terminal) => ({
          value: terminal.uuid,
          label: terminal.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [terminalsToUse]);

  useEffect(() => {
    const fetchTerminal = async () => {
      if (
        isNil(terminalUuid) ||
        terminals.length === 0 ||
        !isNil(terminals.find((t) => t.uuid === terminalUuid))
      ) {
        setTerminalsToUse(terminals);
        return;
      }

      // this is for the case where the existing uuid corresponds to an archived contact
      const currentlySelectedTerminal = getTerminal(terminalUuid);

      if (currentlySelectedTerminal?.isActive === false) {
        setTerminalsToUse([...terminals, currentlySelectedTerminal]);
      }
    };
    fetchTerminal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminals.length, terminalUuid]);

  const terminalSelectTestId = getStopTerminalSelectTestId({ stopIdx });
  const terminalSelectInputTestId = getStopTerminalSelectInputTestId({
    stopIdx,
  });

  return (
    <FormControl fullWidth>
      <AutocompleteFuzzy
        size="small"
        data-testid={terminalSelectTestId}
        value={
          !isNil(terminalUuid)
            ? {
                value: terminalUuid,
                label:
                  terminalOptions.find(
                    (terminal) => terminal.value === terminalUuid,
                  )?.label ?? '',
              }
            : null
        }
        matchSortOptions={{ keys: ['label'] }}
        options={terminalOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Terminal"
            required
            inputProps={{
              ...params.inputProps,
              'data-testid': terminalSelectInputTestId,
            }}
            error={!isNil(error)}
          />
        )}
        onChange={onChange}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
      {!isNil(error?.message) && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          {error?.message?.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default TerminalAutocompleteComponent;
