import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { getNoonOfDay } from 'shared/date';
import {
  CustomerReportArgs,
  CustomerReportBucketFragment,
  DriverReportArgs,
  DriverReportBucketFragment,
  DriverType,
  OrderReportArgs,
  OrderReportBucketFragment,
  ServiceLevelReportArgs,
  ServiceLevelReportBucketFragment,
  StationReportArgs,
  StationReportBucketFragment,
  TerminalReportArgs,
  TerminalReportBucketFragment,
} from '../../generated/graphql';
import { getDriverNameLabel } from './components/utils';
import {
  CustomerReportBucketData,
  DriverReportBucketData,
  initialOrderReportBucketData,
  OrderReportBucketData,
  ReportGroupConfiguration,
  MONTH_NAMES,
  TerminalReportBucketData,
  ServiceLevelReportBucketData,
  StationReportBucketData,
} from './types/report-types';

dayjs.extend(utc);
dayjs.extend(timezone);

const getStartAndEndDate = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): { startDate: Date | null; endDate: Date | null } => {
  const {
    lastNumberOfDays,
    lastNumberOfWeeks,
    lastNumberOfMonths,
    startDate,
    endDate,
  } = reportGroupConfigurationToUpdate;

  if (!isNil(lastNumberOfDays)) {
    return {
      startDate: dayjs()
        .subtract(lastNumberOfDays - 1, 'day')
        .startOf('day')
        .toDate(),
      endDate: getNoonOfDay(dayjs().endOf('day')),
    };
  }
  if (!isNil(lastNumberOfWeeks)) {
    return {
      startDate: dayjs()
        .subtract(lastNumberOfWeeks - 1, 'week')
        .startOf('week')
        .toDate(),
      endDate: getNoonOfDay(dayjs().endOf('week')),
    };
  }
  if (!isNil(lastNumberOfMonths)) {
    return {
      startDate: dayjs()
        .subtract(lastNumberOfMonths - 1, 'month')
        .startOf('month')
        .toDate(),
      endDate: getNoonOfDay(dayjs().endOf('month')),
    };
  }
  return {
    startDate: !isNil(startDate) ? getNoonOfDay(startDate) : null,
    endDate: !isNil(endDate) ? getNoonOfDay(endDate) : null,
  };
};

export const convertReportGroupConfigurationToOrderReportArgsType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): OrderReportArgs => {
  const { startDate, endDate } = getStartAndEndDate(
    reportGroupConfigurationToUpdate,
  );
  return {
    reportAggregationPeriod:
      reportGroupConfigurationToUpdate.reportAggregationPeriod,
    reportStatistics: reportGroupConfigurationToUpdate.reportStatistics,
    reportRevenueType: reportGroupConfigurationToUpdate.reportRevenueType,
    driverUuid: reportGroupConfigurationToUpdate.driver?.uuid ?? null,
    contactUuid: reportGroupConfigurationToUpdate.contact?.uuid ?? null,
    businessDivisionUuid:
      reportGroupConfigurationToUpdate?.businessDivision?.uuid ?? null,
    terminalUuid: reportGroupConfigurationToUpdate?.terminal?.uuid ?? null,
    stopTypes: reportGroupConfigurationToUpdate.stopTypes,
    orderStatuses: reportGroupConfigurationToUpdate.orderStatuses,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    startDate,
    endDate,
    excludeMigratedOrders: false,
  };
};

export const convertReportGroupConfigurationToDriverReportArgsType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): DriverReportArgs => {
  const { startDate, endDate } = getStartAndEndDate(
    reportGroupConfigurationToUpdate,
  );
  return {
    reportStatistics: reportGroupConfigurationToUpdate.reportStatistics,
    reportRevenueType: reportGroupConfigurationToUpdate.reportRevenueType,
    driverUuids:
      reportGroupConfigurationToUpdate?.drivers?.map((d) => d.uuid) ?? [],
    contactUuid: reportGroupConfigurationToUpdate.contact?.uuid ?? null,
    businessDivisionUuid:
      reportGroupConfigurationToUpdate?.businessDivision?.uuid ?? null,
    terminalUuid: reportGroupConfigurationToUpdate?.terminal?.uuid ?? null,
    stopTypes: reportGroupConfigurationToUpdate.stopTypes,
    orderStatuses: reportGroupConfigurationToUpdate.orderStatuses,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    startDate,
    endDate,
    excludeMigratedOrders: false,
  };
};

export const convertReportGroupConfigurationToServiceLevelReportArgsType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): ServiceLevelReportArgs => {
  const { startDate, endDate } = getStartAndEndDate(
    reportGroupConfigurationToUpdate,
  );
  return {
    reportStatistics: reportGroupConfigurationToUpdate.reportStatistics,
    reportRevenueType: reportGroupConfigurationToUpdate.reportRevenueType,
    contactUuid: reportGroupConfigurationToUpdate.contact?.uuid ?? null,
    businessDivisionUuid:
      reportGroupConfigurationToUpdate?.businessDivision?.uuid ?? null,
    terminalUuid: reportGroupConfigurationToUpdate?.terminal?.uuid ?? null,
    driverUuid: reportGroupConfigurationToUpdate?.driver?.uuid ?? null,
    stopTypes: reportGroupConfigurationToUpdate.stopTypes,
    orderStatuses: reportGroupConfigurationToUpdate.orderStatuses,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    startDate,
    endDate,
    excludeMigratedOrders: false,
  };
};

export const convertReportGroupConfigurationToStationReportArgsType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): StationReportArgs | undefined => {
  const { startDate, endDate } = getStartAndEndDate(
    reportGroupConfigurationToUpdate,
  );

  const contactUuid = reportGroupConfigurationToUpdate.contact?.uuid;

  if (isNil(contactUuid)) {
    return undefined;
  }

  return {
    reportRevenueType: reportGroupConfigurationToUpdate.reportRevenueType,
    driverUuid: reportGroupConfigurationToUpdate.driver?.uuid ?? null,
    contactUuid,
    businessDivisionUuid:
      reportGroupConfigurationToUpdate?.businessDivision?.uuid ?? null,
    terminalUuid: reportGroupConfigurationToUpdate?.terminal?.uuid ?? null,
    stopTypes: reportGroupConfigurationToUpdate.stopTypes,
    orderStatuses: reportGroupConfigurationToUpdate.orderStatuses,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    startDate,
    endDate,
    // TODO: Make excludeMigratedOrders configurable
    excludeMigratedOrders: false,
  };
};

export const convertReportGroupConfigurationToCustomerReportArgsType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): CustomerReportArgs => {
  const { startDate, endDate } = getStartAndEndDate(
    reportGroupConfigurationToUpdate,
  );
  return {
    reportStatistics: reportGroupConfigurationToUpdate.reportStatistics,
    reportRevenueType: reportGroupConfigurationToUpdate.reportRevenueType,
    contactUuids:
      reportGroupConfigurationToUpdate?.contacts?.map((c) => c.uuid) ?? [],
    driverUuid: reportGroupConfigurationToUpdate.driver?.uuid ?? null,
    businessDivisionUuid:
      reportGroupConfigurationToUpdate?.businessDivision?.uuid ?? null,
    terminalUuid: reportGroupConfigurationToUpdate?.terminal?.uuid ?? null,
    stopTypes: reportGroupConfigurationToUpdate.stopTypes,
    orderStatuses: reportGroupConfigurationToUpdate.orderStatuses,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    startDate,
    endDate,
    excludeMigratedOrders: false,
  };
};

export const convertReportGroupConfigurationToTerminalReportArgsType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): TerminalReportArgs => {
  const { startDate, endDate } = getStartAndEndDate(
    reportGroupConfigurationToUpdate,
  );
  return {
    reportStatistics: reportGroupConfigurationToUpdate.reportStatistics,
    reportRevenueType: reportGroupConfigurationToUpdate.reportRevenueType,
    driverUuid: reportGroupConfigurationToUpdate.driver?.uuid ?? null,
    businessDivisionUuid:
      reportGroupConfigurationToUpdate?.businessDivision?.uuid ?? null,
    contactUuid: reportGroupConfigurationToUpdate.contact?.uuid ?? null,
    stopTypes: reportGroupConfigurationToUpdate.stopTypes,
    orderStatuses: reportGroupConfigurationToUpdate.orderStatuses,
    lastNumberOfDays: reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
    lastNumberOfWeeks:
      reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
    lastNumberOfMonths:
      reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    startDate,
    endDate,
    excludeMigratedOrders: false,
  };
};

export const convertDataToOrderReportBucketData = (
  data: OrderReportBucketFragment[],
): OrderReportBucketData[] => {
  return data.map((bucket, index) => {
    const newBucket: OrderReportBucketData = {
      ...initialOrderReportBucketData,
      ...{ id: index },
    };
    newBucket.freightRevenue = parseFloat(
      (bucket.freightRevenue / 100).toFixed(2),
    );
    newBucket.fuelRevenue = parseFloat((bucket.fuelRevenue / 100).toFixed(2));
    newBucket.lineHaulRevenue = parseFloat(
      (bucket.lineHaulRevenue / 100).toFixed(2),
    );
    newBucket.specialRevenue = parseFloat(
      (bucket.specialRevenue / 100).toFixed(2),
    );
    newBucket.customChargeRevenue = parseFloat(
      (bucket.customChargeRevenue / 100).toFixed(2),
    );
    newBucket.surchargeRevenue = parseFloat(
      (bucket.surchargeRevenue / 100).toFixed(2),
    );
    newBucket.pickupRevenue = parseFloat(
      (bucket.pickupRevenue / 100).toFixed(2),
    );
    newBucket.deliveryRevenue = parseFloat(
      (bucket.deliveryRevenue / 100).toFixed(2),
    );
    newBucket.recoveryRevenue = parseFloat(
      (bucket.recoveryRevenue / 100).toFixed(2),
    );
    newBucket.transferRevenue = parseFloat(
      (bucket.transferRevenue / 100).toFixed(2),
    );
    newBucket.totalRevenue = parseFloat((bucket.totalRevenue / 100).toFixed(2));
    newBucket.numberOfOrders = bucket.numberOfOrders;
    newBucket.numberOfPackages = bucket.numberOfPackages;
    newBucket.totalWeight = bucket.totalWeight;

    const currentBucketDate = data[index]?.startDate;
    const previousBucketDate = data[index - 1]?.startDate;

    newBucket.startDate = currentBucketDate;
    newBucket.endDate = data[index]?.endDate;
    newBucket.startDateLabel = dayjs.utc(currentBucketDate).format('MM/DD');
    newBucket.endDateLabel = dayjs.utc(data[index]?.endDate).format('MM/DD/YY');

    // only show month and year for first bucket of that month/year
    if (
      index === 0 ||
      dayjs(currentBucketDate).tz().month() !==
        dayjs(previousBucketDate).tz().month()
    ) {
      const month = MONTH_NAMES[dayjs.utc(currentBucketDate).month()];
      if (!isNil(month)) {
        newBucket.monthLabel = month;
      }
    }

    if (
      index === 0 ||
      dayjs(currentBucketDate).tz().year() !==
        dayjs(previousBucketDate).tz().year()
    ) {
      newBucket.yearLabel = dayjs(currentBucketDate).tz().year().toString();
    }
    return newBucket;
  });
};

export const convertDataToDriverReportBucketData = (
  data: DriverReportBucketFragment[],
): DriverReportBucketData[] => {
  return data.map((bucket, index) => {
    return {
      id: index,
      driverName: getDriverNameLabel(bucket),
      driverType: bucket.driverType as DriverType,
      driverUuid: bucket.uuid,
      freightRevenue: parseFloat((bucket.freightRevenue / 100).toFixed(2)),
      fuelRevenue: parseFloat((bucket.fuelRevenue / 100).toFixed(2)),
      lineHaulRevenue: parseFloat((bucket.lineHaulRevenue / 100).toFixed(2)),
      specialRevenue: parseFloat((bucket.specialRevenue / 100).toFixed(2)),
      customChargeRevenue: parseFloat(
        (bucket.customChargeRevenue / 100).toFixed(2),
      ),
      pickupRevenue: parseFloat((bucket.pickupRevenue / 100).toFixed(2)),
      deliveryRevenue: parseFloat((bucket.deliveryRevenue / 100).toFixed(2)),
      recoveryRevenue: parseFloat((bucket.recoveryRevenue / 100).toFixed(2)),
      transferRevenue: parseFloat((bucket.transferRevenue / 100).toFixed(2)),
      totalRevenue: parseFloat((bucket.totalRevenue / 100).toFixed(2)),
      numberOfOrders: bucket.numberOfOrders,
      numberOfPackages: bucket.numberOfPackages,
      totalWeight: bucket.totalWeight,
    };
  });
};

export const convertDataToCustomerReportBucketData = (
  data: CustomerReportBucketFragment[],
): CustomerReportBucketData[] => {
  return data.map((bucket, index) => {
    return {
      id: index,
      uuid: bucket.uuid,
      displayName: bucket.displayName,
      freightRevenue: parseFloat((bucket.freightRevenue / 100).toFixed(2)),
      fuelRevenue: parseFloat((bucket.fuelRevenue / 100).toFixed(2)),
      customChargeRevenue: parseFloat(
        (bucket.customChargeRevenue / 100).toFixed(2),
      ),
      surchargeRevenue: parseFloat((bucket.surchargeRevenue / 100).toFixed(2)),
      lineHaulRevenue: parseFloat((bucket.lineHaulRevenue / 100).toFixed(2)),
      specialRevenue: parseFloat((bucket.specialRevenue / 100).toFixed(2)),
      pickupRevenue: parseFloat((bucket.pickupRevenue / 100).toFixed(2)),
      deliveryRevenue: parseFloat((bucket.deliveryRevenue / 100).toFixed(2)),
      recoveryRevenue: parseFloat((bucket.recoveryRevenue / 100).toFixed(2)),
      transferRevenue: parseFloat((bucket.transferRevenue / 100).toFixed(2)),
      totalRevenue: parseFloat((bucket.totalRevenue / 100).toFixed(2)),
      numberOfOrders: bucket.numberOfOrders,
      numberOfPackages: bucket.numberOfPackages,
      totalWeight: bucket.totalWeight,
    };
  });
};

export const convertDataToServiceLevelReportBucketData = (
  data: ServiceLevelReportBucketFragment[],
): ServiceLevelReportBucketData[] => {
  return data.map((bucket, index) => {
    return {
      id: index,
      uuid: bucket.uuid,
      name: bucket.name,
      freightRevenue: parseFloat((bucket.freightRevenue / 100).toFixed(2)),
      fuelRevenue: parseFloat((bucket.fuelRevenue / 100).toFixed(2)),
      customChargeRevenue: parseFloat(
        (bucket.customChargeRevenue / 100).toFixed(2),
      ),
      surchargeRevenue: parseFloat((bucket.surchargeRevenue / 100).toFixed(2)),
      specialRevenue: parseFloat((bucket.specialRevenue / 100).toFixed(2)),
      lineHaulRevenue: parseFloat((bucket.lineHaulRevenue / 100).toFixed(2)),
      pickupRevenue: parseFloat((bucket.pickupRevenue / 100).toFixed(2)),
      deliveryRevenue: parseFloat((bucket.deliveryRevenue / 100).toFixed(2)),
      recoveryRevenue: parseFloat((bucket.recoveryRevenue / 100).toFixed(2)),
      transferRevenue: parseFloat((bucket.transferRevenue / 100).toFixed(2)),
      totalRevenue: parseFloat((bucket.totalRevenue / 100).toFixed(2)),
      numberOfOrders: bucket.numberOfOrders,
      numberOfPackages: bucket.numberOfPackages,
      totalWeight: bucket.totalWeight,
    };
  });
};

export const convertDataToStationReportBucketData = (
  data: StationReportBucketFragment[],
): StationReportBucketData[] => {
  return data.map((bucket, index) => {
    return {
      id: index,
      stationId: bucket.stationId,
      stationName: bucket.stationName ?? '',
      freightRevenue: parseFloat((bucket.freightRevenue / 100).toFixed(2)),
      fuelRevenue: parseFloat((bucket.fuelRevenue / 100).toFixed(2)),
      lineHaulRevenue: parseFloat((bucket.lineHaulRevenue / 100).toFixed(2)),
      specialRevenue: parseFloat((bucket.specialRevenue / 100).toFixed(2)),
      customChargeRevenue: parseFloat(
        (bucket.customChargeRevenue / 100).toFixed(2),
      ),
      surchargeRevenue: parseFloat((bucket.surchargeRevenue / 100).toFixed(2)),
      pickupRevenue: parseFloat((bucket.pickupRevenue / 100).toFixed(2)),
      deliveryRevenue: parseFloat((bucket.deliveryRevenue / 100).toFixed(2)),
      recoveryRevenue: parseFloat((bucket.recoveryRevenue / 100).toFixed(2)),
      transferRevenue: parseFloat((bucket.transferRevenue / 100).toFixed(2)),
      totalRevenue: parseFloat((bucket.totalRevenue / 100).toFixed(2)),
      totalWeight: bucket.totalWeight,
      numberOfOrders: bucket.numberOfOrders,
      numberOfPackages: bucket.numberOfPackages,
    };
  });
};

export const convertDataToTerminalReportBucketData = (
  data: TerminalReportBucketFragment[],
  userTerminalUuid?: string | undefined,
): TerminalReportBucketData[] => {
  return filterNotNil(
    data.map((bucket, index) => {
      const { uuid } = bucket;
      if (!isNil(userTerminalUuid) && userTerminalUuid !== uuid) {
        return null;
      }
      return {
        id: index,
        uuid: bucket.uuid,
        terminalName: bucket.code,
        freightRevenue: parseFloat((bucket.freightRevenue / 100).toFixed(2)),
        fuelRevenue: parseFloat((bucket.fuelRevenue / 100).toFixed(2)),
        customChargeRevenue: parseFloat(
          (bucket.customChargeRevenue / 100).toFixed(2),
        ),
        surchargeRevenue: parseFloat(
          (bucket.surchargeRevenue / 100).toFixed(2),
        ),
        specialRevenue: parseFloat((bucket.specialRevenue / 100).toFixed(2)),
        lineHaulRevenue: parseFloat((bucket.lineHaulRevenue / 100).toFixed(2)),
        pickupRevenue: parseFloat((bucket.pickupRevenue / 100).toFixed(2)),
        deliveryRevenue: parseFloat((bucket.deliveryRevenue / 100).toFixed(2)),
        recoveryRevenue: parseFloat((bucket.recoveryRevenue / 100).toFixed(2)),
        transferRevenue: parseFloat((bucket.transferRevenue / 100).toFixed(2)),
        totalRevenue: parseFloat((bucket.totalRevenue / 100).toFixed(2)),
        numberOfOrders: bucket.numberOfOrders,
        numberOfPackages: bucket.numberOfPackages,
        totalWeight: bucket.totalWeight,
      };
    }),
  );
};
