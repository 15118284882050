import {
  Button,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useServicesQuery,
} from '../../../generated/graphql';
import CreateOrEdit from '../enums/create-or-edit';
import {
  ActiveArchivedButtonGroup,
  ActiveOrArchivedTab,
} from './common/active-archived-tab-panel';
import CreateOrEditService from './create-or-edit-service';
import ServiceRow from './service-row';

const styles = {
  stackView: {
    display: 'flex',
  } as SxProps,
  driverButton: {
    mx: 1.5,
  } as SxProps,
};

const ServicesTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyServices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyServices,
  );
  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  // Services are returned by the API sorted ascending by name.
  const { data: serviceData } = useServicesQuery();
  const [showCreateService, setShowCreateService] = useState(false);

  const filteredServices = serviceData?.services.filter((s) =>
    isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
      ? s.isActive
      : !s.isActive,
  );

  return (
    <Stack direction="column" sx={styles.stackView}>
      <ActiveArchivedButtonGroup
        activeTab={isActiveOrArchived}
        setTab={setIsActiveOrArchived}
      />
      <CreateOrEditService
        createOrEdit={CreateOrEdit.Create}
        selectedService={null}
        open={showCreateService}
        setOpen={setShowCreateService}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Time at each stop (minutes)</TableCell>
              <TableCell>Use in route optimization</TableCell>
              <TableCell>Default Appointment</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  onClick={() => setShowCreateService(true)}
                  disabled={!canWriteCompanyServices}
                >
                  Add Service Level
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredServices?.map((service) => (
              <ServiceRow key={service.uuid} service={service} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ServicesTable;
