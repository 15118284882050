enum BillingPageTabs {
  INVOICES = 'INVOICES',
  ACCESSORIALS = 'ACCESSORIALS',
  QUICKBOOKS = 'QUICKBOOKS',
  FUEL_PROFILES = 'FUEL_PROFILES',
  QUICKBOOKS_DESKTOP = 'QUICKBOOKS_DESKTOP',
  PAYMENTS = 'PAYMENTS',
  DISCOUNTS = 'DISCOUNTS',
}

export default BillingPageTabs;
