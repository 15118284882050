// eslint-disable-next-line no-restricted-imports
import { Alert, Grid, Snackbar, Typography } from '@mui/material';
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import useWarehouses from '../../../common/react-hooks/use-warehouses';
import {
  FilterViewPage,
  FindItemGroupsFragment,
} from '../../../generated/graphql';
import {
  BooleanFilter,
  DateSelectFilter,
  SingleSelectFilter,
} from '../../ag-grid/filter-components';
import InventoryTableWithFiltersAgGrid from '../../ag-grid/wms/inventory/inventory-table-with-filters-ag-grid';
import {
  InventoryFilterField,
  InventoryTableField,
  getInventoryTableField,
  getInventoryTableFieldCopy,
} from '../../storage-orders/utils';

const STORAGE_UNITS_PAGE_SIZE = 15;

interface InventoryViewProps {
  isCustomerPortal?: boolean;
}
const InventoryView = ({ isCustomerPortal = false }: InventoryViewProps) => {
  const [shouldRefreshGrid, setShouldRefreshGrid] = useState(false);

  const [
    storageUnitUpdatedSuccessSnackbarVisible,
    setStorageUnitUpdatedSuccessSnackbarVisible,
  ] = useState(false);
  const [
    storageUnitUpdatedErrorSnackbarVisible,
    setStorageUnitUpdatedErrorSnackbarVisible,
  ] = useState(false);
  const { warehouses } = useWarehouses();

  const filterColumns = [
    {
      field: InventoryFilterField.WAREHOUSE_LOCATION,
      hide: true,
      filter: SingleSelectFilter,
      filterParams: {
        values: [{}],
        defaultToNothingSelected: true,
        suppressSelectAll: true,
        keyCreator: (params: ValueFormatterParams<FindItemGroupsFragment>) =>
          params.value.uuid,
        valueFormatter: (
          params: ValueFormatterParams<FindItemGroupsFragment>,
        ) => sentenceCase(params.value.displayName),
      },
    },
    {
      field: InventoryFilterField.BILLED,
      hide: true,
      filter: BooleanFilter,
      filterParams: {
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: InventoryFilterField.DATE_MARKED_ON_HAND,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: InventoryFilterField.DATE_MARKED_OSD,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
    {
      field: InventoryFilterField.DATE_PICKED,
      hide: true,
      filter: DateSelectFilter,
      filterParams: {
        values: {
          startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
          endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
        },
        defaultToNothingSelected: true,
        suppressSelectAll: true,
      },
    },
  ];
  filterColumns.sort((a, b) =>
    a.field.toString().localeCompare(b.field.toString()),
  );
  const columns = [
    ...filterColumns,
    {
      field: InventoryTableField.STORAGE_UNIT_NAME,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.STORAGE_UNIT_NAME,
      ),
      resizable: true,
      minWidth: 140,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.STORAGE_UNIT_NAME,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.CUSTOMER,
      headerName: getInventoryTableFieldCopy(InventoryTableField.CUSTOMER),
      resizable: true,
      minWidth: 140,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.CUSTOMER,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.STORAGE_ORDER_REFERENCE_NUMBER,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.STORAGE_ORDER_REFERENCE_NUMBER,
      ),
      resizable: true,
      minWidth: 140,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: (params: ICellRendererParams<FindItemGroupsFragment>) => {
        const label = getInventoryTableField(
          InventoryTableField.STORAGE_ORDER_REFERENCE_NUMBER,
          params.data,
        );
        const uuid = params?.data?.storageOrder?.uuid;
        if (isNil(uuid) || isCustomerPortal) {
          return <Typography variant="body1">{label}</Typography>;
        }

        return (
          <Link to={`/warehouse/storage-orders/${uuid}`}>
            <Typography
              variant="body1"
              style={{ cursor: 'pointer', color: 'blue', fontSize: '12px' }}
            >
              {label}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: InventoryTableField.WAREHOUSE,
      headerName: getInventoryTableFieldCopy(InventoryTableField.WAREHOUSE),
      resizable: true,
      minWidth: 120,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.WAREHOUSE,
          params.data,
        );
      },
      hide: (warehouses?.length ?? 0) < 2,
    },
    {
      field: InventoryTableField.STORAGE_UNIT_UOM,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.STORAGE_UNIT_UOM,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.STORAGE_UNIT_UOM,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.SKU,
      headerName: getInventoryTableFieldCopy(InventoryTableField.SKU),
      resizable: true,
      minWidth: 100,
      maxWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(InventoryTableField.SKU, params.data);
      },
    },
    {
      field: InventoryTableField.ITEM_DESCRIPTION,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.ITEM_DESCRIPTION,
      ),
      resizable: true,
      minWidth: 250,
      maxWidth: 250,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.ITEM_DESCRIPTION,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.ITEM_QUANTITY,
      headerName: getInventoryTableFieldCopy(InventoryTableField.ITEM_QUANTITY),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.ITEM_QUANTITY,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.ITEM_UOM,
      headerName: getInventoryTableFieldCopy(InventoryTableField.ITEM_UOM),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.ITEM_UOM,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.WAREHOUSE_LOCATION,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.WAREHOUSE_LOCATION,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.WAREHOUSE_LOCATION,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.PURCHASE_ORDER_NUMBER,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.PURCHASE_ORDER_NUMBER,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.PURCHASE_ORDER_NUMBER,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.LOT_NUMBER,
      headerName: getInventoryTableFieldCopy(InventoryTableField.LOT_NUMBER),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.LOT_NUMBER,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.DIMS,
      headerName: getInventoryTableFieldCopy(InventoryTableField.DIMS),
      resizable: true,
      minWidth: 120,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(InventoryTableField.DIMS, params.data);
      },
    },
    {
      field: InventoryTableField.WEIGHT,
      headerName: getInventoryTableFieldCopy(InventoryTableField.WEIGHT),
      resizable: true,
      minWidth: 120,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(InventoryTableField.WEIGHT, params.data);
      },
    },
    {
      field: InventoryTableField.DATE_MARKED_ON_HAND,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.DATE_MARKED_ON_HAND,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.DATE_MARKED_ON_HAND,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.DATE_MARKED_OSD,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.DATE_MARKED_OSD,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.DATE_MARKED_OSD,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.OSD_REASON,
      headerName: getInventoryTableFieldCopy(InventoryTableField.OSD_REASON),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.OSD_REASON,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.DATE_PICKED,
      headerName: getInventoryTableFieldCopy(InventoryTableField.DATE_PICKED),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.DATE_PICKED,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.TOTAL_DAYS_IN_STORAGE,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.TOTAL_DAYS_IN_STORAGE,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.TOTAL_DAYS_IN_STORAGE,
          params.data,
        );
      },
    },
    {
      field: InventoryTableField.OUTBOUND_REF_NUMBER,
      headerName: getInventoryTableFieldCopy(
        InventoryTableField.OUTBOUND_REF_NUMBER,
      ),
      resizable: true,
      minWidth: 100,
      valueGetter: (params: ValueGetterParams<FindItemGroupsFragment>) => {
        return getInventoryTableField(
          InventoryTableField.OUTBOUND_REF_NUMBER,
          params.data,
        );
      },
    },
  ];

  const [columnDefs, setColumnDefs] = // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useState<ColDef<FindItemGroupsFragment>[]>(columns);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setColumnDefs(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={storageUnitUpdatedSuccessSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => setStorageUnitUpdatedSuccessSnackbarVisible(false)}
        >
          Updated container
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={storageUnitUpdatedErrorSnackbarVisible}
      >
        <Alert
          severity="error"
          onClose={() => setStorageUnitUpdatedErrorSnackbarVisible(false)}
        >
          Error updating container
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <InventoryTableWithFiltersAgGrid
          columnDefinitions={columnDefs}
          pageSize={STORAGE_UNITS_PAGE_SIZE}
          pageType={FilterViewPage.StorageUnits}
          shouldRefreshGrid={shouldRefreshGrid}
          setShouldRefreshGrid={setShouldRefreshGrid}
        />
      </Grid>
    </>
  );
};

export default InventoryView;
