import React, { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CSVDownload = (props: any) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => btnRef.current?.click(), [btnRef]);
  return (
    <CSVLink {...props}>
      <span ref={btnRef} />
    </CSVLink>
  );
};

export default CSVDownload;
