import { isNil } from 'lodash';
import { useState } from 'react';
import { filterNotNil } from 'shared/array';
import {
  ScannedOrderCreateInput,
  ScannedOrderResultsDocument,
  useCreateScannedOrdersMutation,
  useMatchSignedPoDsMutation,
  useGenerateScannedOrderPreSignedPutUrlsLazyQuery,
} from '../../../generated/graphql';

const useSubmitDocumentModal = () => {
  const [loading, setLoading] = useState(false);
  const [generatePreSignedPutUrls] =
    useGenerateScannedOrderPreSignedPutUrlsLazyQuery();
  const [matchSignedPODs] = useMatchSignedPoDsMutation({
    refetchQueries: [ScannedOrderResultsDocument],
  });
  const [createScannedOrders] = useCreateScannedOrdersMutation({
    refetchQueries: [ScannedOrderResultsDocument],
  });

  const submitDocuments = async (
    files: File[],
    onFinish: (numberFiles: number) => void,
    matchSignedPods: boolean,
  ) => {
    setLoading(true);
    try {
      const preSignedPutUrls = await generatePreSignedPutUrls({
        variables: {
          preSignedUrlRequests: files.map((file) => {
            return {
              filename: file.name,
              filetype: file.type,
            };
          }),
        },
      });
      await Promise.all(
        files.map(async (file, idx) => {
          const options = { headers: { 'Content-Type': file.type } };
          const preSignedUrl =
            preSignedPutUrls.data?.generateScannedOrderPreSignedPutUrls[idx];
          if (isNil(preSignedUrl)) {
            // eslint-disable-next-line no-console
            console.error(
              `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
            );
            return null;
          }
          return fetch(preSignedUrl.url, {
            method: 'PUT',
            body: file,
            ...options,
          });
        }),
      );
      const scannedOrderCreateInputs: ScannedOrderCreateInput[] = filterNotNil(
        files.map((file, idx) => {
          const preSignedUrl =
            preSignedPutUrls.data?.generateScannedOrderPreSignedPutUrls[idx];
          if (isNil(preSignedUrl)) {
            // eslint-disable-next-line no-console
            console.error(
              `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
            );
            return null;
          }
          return {
            bucket: preSignedUrl.bucket,
            key: preSignedUrl.key,
            filename: preSignedUrl.filename,
            filetype: preSignedUrl.filetype,
          };
        }),
      );
      if (matchSignedPods) {
        await matchSignedPODs({
          variables: {
            matchSignedPODsInput: {
              scannedOrderCreateInputs,
            },
          },
        });
      } else {
        await createScannedOrders({
          variables: {
            createScannedOrdersInput: {
              scannedOrderCreateInputs,
            },
          },
        });
      }
      onFinish(files.length);
    } finally {
      setLoading(false);
    }
  };

  return { submitDocuments, loading };
};

export default useSubmitDocumentModal;
