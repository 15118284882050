import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import {
  AppointmentFragment,
  GetAppointmentsDocument,
  useRemoveStopsFromRoutesMutation,
} from '../../../../generated/graphql';
import { formatAppointment } from '../utils/utils';

const RemoveFromRoutesConfirmationModal = ({
  open,
  setOpen,
  selectedStops,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedStops: AppointmentFragment[];
}) => {
  const [removeStopsFromRoutes] = useRemoveStopsFromRoutesMutation({
    refetchQueries: [GetAppointmentsDocument],
  });

  const onConfirm = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    await removeStopsFromRoutes({
      variables: {
        removeStopsFromRoutesInput: {
          uuids: selectedStops.map((stop) => stop.uuid),
        },
      },
    });
    setOpen(false);
  };

  const stopsOnRoutes = selectedStops.filter((stop) => !isNil(stop.routeSlot));
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <Box
        sx={{
          p: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">
          Removing {stopsOnRoutes.length} stops from routes and appointments for{' '}
          {selectedStops.length} stops
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography>Affected stops</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order number</TableCell>
                <TableCell>Route</TableCell>
                <TableCell>Appointment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedStops.map((stop) => (
                <TableRow key={stop.uuid}>
                  <TableCell>
                    {stop.leg.shipment.order?.standardOrderFields
                      .shipperBillOfLadingNumber ??
                      stop.leg.shipment.order?.name ??
                      'NA'}
                  </TableCell>
                  <TableCell>
                    {!isNil(stop.routeSlot)
                      ? (stop.routeSlot?.route?.name ?? 'No driver')
                      : 'No route'}
                  </TableCell>
                  <TableCell>{formatAppointment(stop)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RemoveFromRoutesConfirmationModal;
