import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  ButtonGroup,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Checkbox,
  Tooltip,
  Typography,
  Select,
  Chip,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import { shallow } from 'zustand/shallow';
import useFirstRender from '../../../../common/react-hooks/use-first-render';
import useInterval from '../../../../common/react-hooks/use-interval';
import useMe from '../../../../common/react-hooks/use-me';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import {
  EmailSendStatus,
  FindInvoicesSort,
  FindInvoicesSortFields,
  InvoiceStatus,
  SortDirection,
  useMeQuery,
  useShallowInvoicesV2LazyQuery,
  useInvoiceUuidsLazyQuery,
} from '../../../../generated/graphql';
import useInvoiceEmailStatuses from '../../hooks/use-invoice-email-statuses';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import useInvoicesStore from '../../invoices-store';
import { InvoiceStatusTab } from '../../types/types';
import { SingleColumnInvoiceTableSortLabel } from '../../utils';
import InvoiceListRow from './invoice-list-row';
import SelectAllInvoices from './select-all-invoices';
import EmailTransactionStatusChip from './transmissions/emails/email-transaction-status-chip';

const DEFAULT_INVOICES_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50];

const QuickbooksSyncStatusChip = ({ isSynced }: { isSynced: boolean }) => {
  if (isSynced) {
    return <Chip size="small" label="Synced" color="success" />;
  }
  return <Chip size="small" label="Not synced" color="error" />;
};

interface InvoiceListProps {
  openedInvoiceUuid: string | undefined;
  setOpenedInvoiceUuid: Dispatch<SetStateAction<string | undefined>>;
  showInvoiceDetails: boolean;
  setShowInvoiceDetails: Dispatch<SetStateAction<boolean>>;
  searchText: string | undefined;
  contactUuid: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  emailSendStatus: EmailSendStatus | 'All';
  setEmailSendStatus: Dispatch<SetStateAction<EmailSendStatus | 'All'>>;
  isSyncedToQBD: boolean | undefined;
  setIsSyncedToQBD: Dispatch<SetStateAction<boolean | undefined>>;
  setInvoicesLoading: Dispatch<SetStateAction<boolean>>;
  statusTab: InvoiceStatusTab | undefined;
  setStatusTab: Dispatch<SetStateAction<InvoiceStatusTab | undefined>>;
  isHeaderCheckboxSelected: boolean;
  setIsHeaderCheckboxSelected: Dispatch<SetStateAction<boolean>>;
}

const InvoiceList = ({
  openedInvoiceUuid,
  setOpenedInvoiceUuid,
  showInvoiceDetails,
  setShowInvoiceDetails,
  searchText,
  contactUuid,
  startDate,
  endDate,
  emailSendStatus,
  setEmailSendStatus,
  isSyncedToQBD,
  setIsSyncedToQBD,
  setInvoicesLoading,
  statusTab,
  setStatusTab,
  isHeaderCheckboxSelected,
  setIsHeaderCheckboxSelected,
}: InvoiceListProps) => {
  const { height } = useWindowDimensions();
  const { fetchInvoiceTotalsByUuids } = useInvoiceTotals();
  const { fetchInvoiceEmailSendStatusesByUuids } = useInvoiceEmailStatuses();
  const [isClosedFilter, setIsClosedFilter] = useState<boolean | undefined>();
  const [shouldRefreshInvoiceList, setShouldRefreshInvoiceList] =
    useInvoicesStore(
      (state) => [
        state.shouldRefreshInvoiceList,
        state.setShouldRefreshInvoiceList,
      ],
      shallow,
    );
  const selectedInvoiceUuids = useInvoicesStore(
    (state) => state.selectedInvoiceUuids,
  );
  const selectAllInvoiceUuids = useInvoicesStore(
    (state) => state.selectAllInvoiceUuids,
  );
  const deselectAllInvoiceUuids = useInvoicesStore(
    (state) => state.deselectAllInvoiceUuids,
  );
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_INVOICES_PAGE_SIZE,
  );
  const [sort, setSort] = useState<FindInvoicesSort>({
    sortBy: FindInvoicesSortFields.DueDate,
    sortDirection: SortDirection.Desc,
  });
  const [page, setPage] = useState<number>(0);
  const { data: companyData } = useMeQuery({ fetchPolicy: 'cache-first' });
  const [getInvoices, { data: invoicesData, loading }] =
    useShallowInvoicesV2LazyQuery();
  const [getInvoiceUuids] = useInvoiceUuidsLazyQuery();

  const { companyConfiguration } = useMe();
  const firstRender = useFirstRender();
  const isUsingQuickbooks = !isNil(
    companyData?.me?.company.quickbooksAccessToken?.uuid,
  );
  const isUsingQuickbooksDesktop = !isNil(
    companyData?.me?.company.quickbooksDesktopDeliveryId,
  );

  useEffect(() => {
    if (
      invoicesData?.invoices.edges.every(
        ({ node: invoice }) => invoice.uuid !== openedInvoiceUuid,
      ) === true
    ) {
      if (showInvoiceDetails) {
        setOpenedInvoiceUuid(invoicesData.invoices.edges[0]?.node.uuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesData?.invoices]);

  const clearSelection = () => {
    setIsHeaderCheckboxSelected(false);
    deselectAllInvoiceUuids();
  };

  const getFilterVariables = () => {
    const statuses = [];
    if (
      statusTab === InvoiceStatusTab.NOT_POSTED ||
      statusTab === InvoiceStatusTab.ALL
    ) {
      statuses.push(InvoiceStatus.NotFinalized);
    }
    if (
      statusTab === InvoiceStatusTab.POSTED ||
      statusTab === InvoiceStatusTab.ALL
    ) {
      statuses.push(InvoiceStatus.ReadyToInvoice);
      statuses.push(InvoiceStatus.Invoiced);
    }

    return {
      statuses,
      billingPartyContactUuid: contactUuid,
      invoiceStartDate: !isNil(startDate)
        ? dayjs(startDate).startOf('day').toDate()
        : null,
      invoiceEndDate: !isNil(endDate)
        ? dayjs(endDate).endOf('day').toDate()
        : null,
      searchText: searchText?.trim(),
      isClosed: isClosedFilter,
      emailSendStatus: emailSendStatus === 'All' ? undefined : emailSendStatus,
      isSyncedToQuickbooksDesktop: isSyncedToQBD,
      sorts: [sort],
    };
  };

  const fetchInvoices = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const filters = getFilterVariables();
    await getInvoices({
      variables: {
        ...filters,
        first,
        after,
        last,
        before,
      },
    });
  };

  const handleSelectAllInvoices = async () => {
    const filters = getFilterVariables();

    const response = await getInvoiceUuids({
      variables: filters,
    });

    const uuidsAndStatus = response.data?.invoices.edges.map(({ node }) => ({
      uuid: node.uuid,
      isUnfinalized: node.status === InvoiceStatus.NotFinalized,
    }));
    if (uuidsAndStatus && uuidsAndStatus.length > 0) {
      selectAllInvoiceUuids(uuidsAndStatus);
    }
  };

  const prev = async () => {
    await fetchInvoices({
      last: rowsPerPage,
      before: invoicesData?.invoices.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchInvoices({
      first: rowsPerPage,
      after: invoicesData?.invoices.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    // Clear selections when we fetch new invoices when filters are switched
    clearSelection();
    fetchInvoices({ first: rowsPerPage });
    setPage(0);
  };

  const pollInvoiceEmailSendStatus = async () => {
    const uuids =
      invoicesData?.invoices.edges.map(({ node }) => node.uuid) || [];
    fetchInvoiceEmailSendStatusesByUuids(uuids);
  };

  useInterval(() => {
    pollInvoiceEmailSendStatus();
  }, ONE_MINUTE_IN_MS * 2);

  useEffect(() => {
    const uuids = invoicesData?.invoices.edges.map(
      ({ node: invoice }) => invoice.uuid,
    );
    if (!isNil(uuids)) {
      fetchInvoiceTotalsByUuids(uuids);
      fetchInvoiceEmailSendStatusesByUuids(uuids);
    }
  }, [
    fetchInvoiceTotalsByUuids,
    fetchInvoiceEmailSendStatusesByUuids,
    invoicesData,
  ]);

  useEffect(() => {
    setInvoicesLoading(loading);
  }, [loading, setInvoicesLoading]);

  useEffect(() => {
    if (!firstRender) {
      refresh();
    } else {
      deselectAllInvoiceUuids();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    contactUuid,
    sort,
    statusTab,
    rowsPerPage,
    startDate,
    endDate,
    isClosedFilter,
    emailSendStatus,
    isSyncedToQBD,
  ]);

  useEffect(() => {
    if (shouldRefreshInvoiceList) {
      refresh();
      setShouldRefreshInvoiceList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshInvoiceList]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <ButtonGroup
                disableElevation
                variant="outlined"
                size="small"
                aria-label="document-status-filters"
              >
                <Button
                  variant={
                    statusTab === InvoiceStatusTab.NOT_POSTED
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setStatusTab(InvoiceStatusTab.NOT_POSTED)}
                >
                  Not Posted
                </Button>
                <Button
                  variant={
                    statusTab === InvoiceStatusTab.POSTED
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setStatusTab(InvoiceStatusTab.POSTED)}
                >
                  Posted
                </Button>
                <Button
                  variant={
                    statusTab === InvoiceStatusTab.ALL
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setStatusTab(InvoiceStatusTab.ALL)}
                >
                  All
                </Button>
              </ButtonGroup>
              <ButtonGroup
                disableElevation
                variant="outlined"
                size="small"
                aria-label="closed-status-filters"
              >
                <Button
                  variant={isClosedFilter === true ? 'contained' : 'outlined'}
                  onClick={() => setIsClosedFilter(true)}
                >
                  Closed
                </Button>
                <Button
                  variant={isClosedFilter === false ? 'contained' : 'outlined'}
                  onClick={() => setIsClosedFilter(false)}
                >
                  Open
                </Button>
                <Button
                  variant={
                    isClosedFilter === undefined ? 'contained' : 'outlined'
                  }
                  onClick={() => setIsClosedFilter(undefined)}
                >
                  All
                </Button>
              </ButtonGroup>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ float: 'right' }}
            >
              <TablePagination
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                labelRowsPerPage="Show"
                component="div"
                count={invoicesData?.invoices.totalCount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage: number) => {
                  if (newPage > page) {
                    next();
                  } else {
                    prev();
                  }
                  setPage(newPage);
                }}
                backIconButtonProps={{
                  disabled: loading || page === 0,
                }}
                nextIconButtonProps={{
                  disabled:
                    loading ||
                    invoicesData?.invoices.totalCount === 0 ||
                    page + 1 ===
                      Math.ceil(
                        (invoicesData?.invoices.totalCount ?? 0) / rowsPerPage,
                      ),
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(+e.target.value);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ maxHeight: height - 290, overflowY: 'scroll' }}>
          {isHeaderCheckboxSelected && (
            <SelectAllInvoices
              handleSelectAllInvoices={handleSelectAllInvoices}
              clearSelection={clearSelection}
              pageSize={rowsPerPage}
              count={selectedInvoiceUuids.length}
            />
          )}
          <TableContainer>
            <Table stickyHeader aria-label="invoice-preview-table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20px' }}>
                    <Checkbox
                      indeterminate={
                        !isHeaderCheckboxSelected &&
                        selectedInvoiceUuids.length > 0
                      }
                      checked={isHeaderCheckboxSelected}
                      onClick={(e) => {
                        if (isHeaderCheckboxSelected) {
                          deselectAllInvoiceUuids();
                        } else {
                          selectAllInvoiceUuids(
                            invoicesData?.invoices.edges.map(
                              ({ node: invoice }) => ({
                                uuid: invoice.uuid,
                                isUnfinalized:
                                  invoice.status === InvoiceStatus.NotFinalized,
                              }),
                            ),
                          );
                        }
                        setIsHeaderCheckboxSelected(!isHeaderCheckboxSelected);
                        e.stopPropagation();
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '140px' }}>
                    {SingleColumnInvoiceTableSortLabel({
                      label:
                        companyConfiguration?.useJournalNumberForInvoice ===
                        true
                          ? 'Journal #'
                          : 'Name',
                      sortBy: FindInvoicesSortFields.InvoiceNameOrJournalNumber,
                      currentSort: sort,
                      setSort,
                    })}
                  </TableCell>
                  {!showInvoiceDetails && <TableCell>Invoice status</TableCell>}
                  <TableCell>Customer</TableCell>
                  <TableCell align="center">
                    <Select
                      value={emailSendStatus}
                      onChange={(e) => {
                        setEmailSendStatus(
                          e.target.value as EmailSendStatus | 'All',
                        );
                      }}
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      }}
                      size="small"
                      renderValue={(value) => {
                        return (
                          <Stack direction="column" alignItems="center" gap={1}>
                            <Typography variant="subtitle2">
                              Send status
                            </Typography>{' '}
                            {value !== 'All' && (
                              <EmailTransactionStatusChip status={value} />
                            )}
                          </Stack>
                        );
                      }}
                    >
                      {Object.values(EmailSendStatus).map((txnStatus) => (
                        <MenuItem key={txnStatus} value={txnStatus}>
                          <EmailTransactionStatusChip status={txnStatus} />
                        </MenuItem>
                      ))}
                      <MenuItem key="All" value="All">
                        <Chip size="small" label="All" />
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>Total charges</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      Amount due
                      <Tooltip
                        arrow
                        title="Amount Due = Total Charges - Total Applied Payments"
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </Stack>
                  </TableCell>
                  {!showInvoiceDetails && isUsingQuickbooks && (
                    <TableCell>Quickbooks sync status</TableCell>
                  )}
                  {!showInvoiceDetails && isUsingQuickbooksDesktop && (
                    <TableCell>
                      <Select
                        // Empty because the value comes from isSyncedToQBD
                        value=""
                        sx={{
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                        size="small"
                        displayEmpty
                        renderValue={() => {
                          return isNil(isSyncedToQBD) ? (
                            <Typography variant="subtitle2">
                              QB Desktop sync
                            </Typography>
                          ) : (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <Typography variant="subtitle2">QBD</Typography>
                              <QuickbooksSyncStatusChip
                                isSynced={isSyncedToQBD}
                              />
                            </Stack>
                          );
                        }}
                      >
                        <MenuItem
                          key="synced"
                          value="synced"
                          onClick={() => setIsSyncedToQBD(true)}
                        >
                          <QuickbooksSyncStatusChip isSynced />
                        </MenuItem>
                        <MenuItem
                          key="not-synced"
                          value="not-synced"
                          onClick={() => setIsSyncedToQBD(false)}
                        >
                          <QuickbooksSyncStatusChip isSynced={false} />
                        </MenuItem>
                        <MenuItem
                          key="All"
                          value="All"
                          onClick={() => setIsSyncedToQBD(undefined)}
                        >
                          <Chip size="small" label="All" />
                        </MenuItem>
                      </Select>
                    </TableCell>
                  )}
                  {!showInvoiceDetails && (
                    <>
                      <TableCell>
                        {SingleColumnInvoiceTableSortLabel({
                          label: 'Created at',
                          sortBy: FindInvoicesSortFields.CreatedAt,
                          currentSort: sort,
                          setSort,
                        })}
                      </TableCell>
                      <TableCell>
                        {SingleColumnInvoiceTableSortLabel({
                          label: 'Date',
                          sortBy: FindInvoicesSortFields.Date,
                          currentSort: sort,
                          setSort,
                        })}
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    {SingleColumnInvoiceTableSortLabel({
                      label: 'Due date',
                      sortBy: FindInvoicesSortFields.DueDate,
                      currentSort: sort,
                      setSort,
                    })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoicesData?.invoices.edges.map(({ node: invoice }) => (
                  <InvoiceListRow
                    key={invoice.uuid}
                    invoice={invoice}
                    companyConfiguration={companyConfiguration}
                    openedInvoiceUuid={openedInvoiceUuid}
                    setOpenedInvoiceUuid={setOpenedInvoiceUuid}
                    showInvoiceDetails={showInvoiceDetails}
                    setShowInvoiceDetails={setShowInvoiceDetails}
                    isUsingQuickbooks={isUsingQuickbooks}
                    isUsingQuickbooksDesktop={isUsingQuickbooksDesktop}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(InvoiceList);
