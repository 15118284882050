import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

export type EquipmentValues = Partial<{
  name: string;
}> & { uuid: string };

const equipmentValuesAdapter = createEntityAdapter<EquipmentValues>({
  selectId: (equipment) => equipment.uuid,
});

export const equipmentValuesSlice = createSlice({
  name: 'equipmentValues',
  initialState: equipmentValuesAdapter.getInitialState(),
  reducers: {
    addOneEquipmentValues: equipmentValuesAdapter.addOne,
    updateOneEquipmentValues: equipmentValuesAdapter.updateOne,
    removeOneEquipmentValues: equipmentValuesAdapter.removeOne,
    setAllEquipmentValues: equipmentValuesAdapter.setAll,
    upsertOneEquipmentValues: equipmentValuesAdapter.upsertOne,
  },
});

export const { selectById: selectEquipmentValuesById } =
  equipmentValuesAdapter.getSelectors(
    (state: RootState) => state.equipmentValues,
  );

const equipmentValuesReducer = equipmentValuesSlice.reducer;

const equipmentSelector = (state: RootState) => state.equipmentValues;

export const selectEquipmentsByIds = createSelector(
  equipmentSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<EquipmentValues>, entityIds: EntityId[]) => {
    const entityResults: EquipmentValues[] = [];
    entityIds.forEach((id) => {
      const match = equipmentValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const {
  addOneEquipmentValues,
  removeOneEquipmentValues,
  updateOneEquipmentValues,
  setAllEquipmentValues,
  upsertOneEquipmentValues,
} = equipmentValuesSlice.actions;

export default equipmentValuesReducer;
