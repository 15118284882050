import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { PartialDeep } from 'type-fest';
import { DeepStringified } from '../../../../common/types';
import type { RootState } from '../../../../redux/store';
import { StandardOrderValues } from './standard-orders-values-slice';

export type StandardOrderErrors = DeepStringified<
  PartialDeep<Omit<StandardOrderValues, 'uuid'>> & { uuid: string }
>;

const standardOrdersErrorsAdapter = createEntityAdapter<StandardOrderErrors>({
  selectId: (standardOrderErrors) => standardOrderErrors.uuid,
});

export const standardOrdersErrorsSlice = createSlice({
  name: 'standardOrdersErrors',
  initialState: standardOrdersErrorsAdapter.getInitialState,
  reducers: {
    addOneStandardOrderErrors: standardOrdersErrorsAdapter.addOne,
    removeAllStandardOrderErrors: standardOrdersErrorsAdapter.removeAll,
    updateOneStandardOrderErrors: standardOrdersErrorsAdapter.updateOne,
    upsertOneStandardOrderErrors: standardOrdersErrorsAdapter.upsertOne,
  },
});

export const { selectById: selectStandardOrderErrorsById } =
  standardOrdersErrorsAdapter.getSelectors(
    (state: RootState) => state.standardOrderErrors,
  );

export const {
  addOneStandardOrderErrors,
  updateOneStandardOrderErrors,
  upsertOneStandardOrderErrors,
} = standardOrdersErrorsSlice.actions;

const standardOrderErrorsReducer = standardOrdersErrorsSlice.reducer;
export default standardOrderErrorsReducer;
