import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useForm } from 'react-hook-form';

export type ContactDiscountSettingsFormValues = {
  pickupDiscountRate: number | null;
  deliveryDiscountRate: number | null;
  transferDiscountRate: number | null;
  lineHaulDiscountRate: number | null;
};

const useContactDiscountSettingsForm = () => {
  const schema = joi.object<ContactDiscountSettingsFormValues>({
    pickupDiscountRate: joi
      .number()
      .optional()
      .max(100)
      .allow('')
      .allow(null)
      .messages({
        'number.max': 'Pickup discount rate must be less than 100',
      }),
    deliveryDiscountRate: joi
      .number()
      .optional()
      .max(100)
      .allow('')
      .allow(null)
      .messages({
        'number.max': 'Delivery discount rate must be less than 100',
      }),
    transferDiscountRate: joi
      .number()
      .optional()
      .max(100)
      .allow('')
      .allow(null)
      .messages({
        'number.max': 'Transfer discount rate must be less than 100',
      }),
    lineHaulDiscountRate: joi
      .number()
      .optional()
      .max(100)
      .allow('')
      .allow(null)
      .messages({
        'number.max': 'Line Haul discount rate must be less than 100',
      }),
  });

  return useForm<ContactDiscountSettingsFormValues>({
    resolver: joiResolver(schema),
  });
};

export default useContactDiscountSettingsForm;
