import SearchIcon from '@mui/icons-material/Search';
import { Divider, InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react';
import { shallow } from 'zustand/shallow';
import useDispatchStore from '../../dispatch-store';
import { AllRouteSelector } from '../../routes/components/all-route-selector';
import RouteSortMenuButton from '../../routes/components/route-sort-menu-button';

const RoutesMapToolbar = () => {
  const [searchText, setSearchText] = useDispatchStore(
    (state) => [state.routeSearchText, state.setRouteSearchText],
    shallow,
  );

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ p: 1 }}>
        <TextField
          className="dispatch-search-input"
          variant="standard"
          size="small"
          placeholder="Search routes"
          autoComplete="off"
          sx={{ width: '100%', p: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <RouteSortMenuButton />
      </Stack>
      <Divider />
      <AllRouteSelector isMapView />
      <Divider />
    </Stack>
  );
};

export default React.memo(RoutesMapToolbar);
