import { Box, Typography } from '@mui/material';
import { PointLike, Popup } from 'react-map-gl';

type SameDayStopPopupProps = {
  driverName: string;
  latitude: number;
  longitude: number;
};

const POPUP_OFFSETS: { [_: string]: PointLike } = {
  bottom: [0, -20],
  'bottom-left': [0, -20],
  'bottom-right': [0, -20],
};

export const SameDayDriverPopup = ({
  driverName,
  latitude,
  longitude,
}: SameDayStopPopupProps) => {
  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      offset={POPUP_OFFSETS}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ color: 'black', textDecoration: 'underline' }}>
            {driverName}
          </Typography>
        </Box>
      </Box>
    </Popup>
  );
};
