import {
  CellMouseOverEvent,
  ColDef,
  FirstDataRenderedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isNil } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import useDispatchTableColors from '../../common/react-hooks/use-dispatch-table-colors';
import { StopFragment } from '../../generated/graphql';
import { useDispatchUnassignedStopsRowDrag } from '../ag-grid/dispatch-stops/use-dispatch-unassigned-stops-row-drag';
import useDispatchStore from './dispatch-store';
import useStopColumns from './hooks/use-stop-columns';
import { otherColumns } from './utils';

const dispatchColumnsToUse = [
  'ORDER_NAME',
  'CONSIGNEE',
  'SERVICE_DATE',
  'ADDRESS',
  'ZIP',
  'APPOINTMENT',
  'DIMS',
  'PIECE_COUNT',
];

const widthMap: { [key: string]: number } = {
  'eta-completed': 30,
  ORDER_NAME: 90,
  CONSIGNEE: 70,
  SERVICE_DATE: 50,
  ADDRESS: 90,
  ZIP: 10,
  APPOINTMENT: 70,
  DIMS: 70,
  PIECE_COUNT: 75,
};

const SelectedUnassignedStopsAgGridTable = ({
  stops,
  selectedStopUuids,
  setSelectedStopUuids,
  onAssignStops,
}: {
  stops: StopFragment[];
  selectedStopUuids: string[];
  setSelectedStopUuids: (stopUuids: string[]) => void;
  onAssignStops?: (stopUuids?: string[]) => void;
}) => {
  const gridRef = useRef<AgGridReact<StopFragment>>(null);
  const { dispatchTableColors } = useDispatchTableColors();
  const { addRouteDropZones } = useDispatchUnassignedStopsRowDrag();
  const [setHoveredStopUuid, setOpenedOrderUuid] = useDispatchStore(
    (state) => [state.setHoveredStopUuid, state.setOpenedOrderUuid],
    shallow,
  );

  const columnDefinitions: ColDef[] = useStopColumns({
    dispatchTableColors,
    setOpenedOrderUuid,
  });

  const newColumns: ColDef[] = filterNotNil(
    dispatchColumnsToUse.map((col: string) => {
      const prevIndex = columnDefinitions.findIndex((def) => def.field === col);
      const updatedColumnDefForField = columnDefinitions[prevIndex];
      if (!isNil(updatedColumnDefForField)) {
        updatedColumnDefForField.width = widthMap[col];
      }
      return updatedColumnDefForField;
    }),
  );

  const selectRow = () => {
    const rows = gridRef?.current?.api.getSelectedRows();
    if (!isNil(rows)) {
      setSelectedStopUuids(rows.map((row) => row.stop.uuid));
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent<StopFragment>) => {
    event.api.forEachNode((node) => {
      if (selectedStopUuids.includes(node.data?.stop.uuid ?? '')) {
        node.setSelected(true);
      }
    });
  };

  const onCellMouseOver = useCallback(
    (e: CellMouseOverEvent<StopFragment>) => {
      setHoveredStopUuid(e.data?.stop.uuid);
    },
    [setHoveredStopUuid],
  );

  return (
    <div
      className="unassigned-stops unassigned-stops-v2 ag-theme-material ag-non-compact border-1"
      style={{
        width: '100%',
        marginTop: '0px',
        paddingTop: '0px',
        marginBottom: '0px',
        height: '100%',
      }}
    >
      <AgGridReact<StopFragment>
        defaultColDef={{
          resizable: true,
          suppressMenu: true,
          unSortIcon: false,
          wrapText: true,
        }}
        columnDefs={[
          ...otherColumns({
            dispatchTableColors: dispatchTableColors || [],
          }),
          ...newColumns,
        ]}
        rowData={stops}
        enableCellChangeFlash
        suppressCellFocus
        rowBuffer={60}
        headerHeight={38}
        rowHeight={30}
        rowSelection="multiple"
        rowMultiSelectWithClick
        animateRows
        rowDragEntireRow={!isNil(onAssignStops)}
        rowDragMultiRow={!isNil(onAssignStops)}
        onCellMouseOver={onCellMouseOver}
        onCellMouseOut={() => {
          setHoveredStopUuid(undefined);
        }}
        onRowDragEnter={() => {
          addRouteDropZones({
            // @ts-expect-error -- It's safe to pass in a table with just StopFragments without any RecurringRunHeaderEntities.
            gridRef,
            onFinish: (assignedStopUuids: string[]) => {
              onAssignStops?.(assignedStopUuids);
            },
          });
        }}
        onRowSelected={selectRow}
        ref={gridRef}
        overlayNoRowsTemplate="No selected stops"
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
};

export default React.memo(SelectedUnassignedStopsAgGridTable);
