enum AppointmentsTableTabs {
  Appointments = 'Appointments',
  Unscheduled = 'Unscheduled appointments',
  Unconfirmed = 'Unconfirmed appointments',
  RescheduleRequested = 'Reschedule requested',
  Confirmed = 'Confirmed appointments',
  WithoutContacts = 'Appointments without contacts',
  WithoutAppointments = 'Stops without appointments',
}

export default AppointmentsTableTabs;
