import { Card } from '@mui/material';
import { FunctionComponent } from 'react';
import ApproveQuoteArea from '../../view-quotes/components/approve-quote-area';

type ApproveQuotePageProps = {
  quoteUuid: string;
  billingPartyContactUuid: string;
};

const ApproveQuotePage: FunctionComponent<ApproveQuotePageProps> = ({
  quoteUuid,
  billingPartyContactUuid,
}) => {
  return (
    <Card sx={{ height: '100%', overflowY: 'auto' }}>
      <ApproveQuoteArea
        open
        setOpen={() => null}
        quoteUuid={quoteUuid}
        billingPartyContactUuid={billingPartyContactUuid}
        isFromQuoteProfile
      />
    </Card>
  );
};

export default ApproveQuotePage;
