import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  FilterConstructionType,
  SingleFilterConstructionType,
} from '../../../../../common/filters/types';
import { OrderTableFilterModel } from '../../types';
import {
  filterConstructionTypeToOrderTableFilterModel,
  orderTableFilterModelToFilterConstructionType as rawOrderTableFilterModelToFilterConstructionType,
} from './filter-utils';

const EMPTY_CUSTOMER_NAME_SINGLE_FILTER_CONSTRUCTION_TYPE: SingleFilterConstructionType =
  {
    filter: 'CUSTOMER_NAME',
    op: 'eq',
    value: null,
  };

/**
 * If the current filter model is empty, add an empty customer name filter
 * to give the user something to start with.
 */
const convertOrderTableFilterModelToFilterConstructionType = (
  filterModel: OrderTableFilterModel,
): FilterConstructionType[] => {
  const filterConstructionType =
    rawOrderTableFilterModelToFilterConstructionType(filterModel);
  return isEmpty(filterConstructionType)
    ? [{ and: [EMPTY_CUSTOMER_NAME_SINGLE_FILTER_CONSTRUCTION_TYPE] }]
    : filterConstructionType;
};

/**
 * Utilities for managing an array of FilterConstructionTypes
 * that can eventually be saved to an OrderTableFilterModel
 */
export const useFilterConstructionTypes = ({
  filterModelV2,
  setFilterModelV2,
}: {
  filterModelV2: OrderTableFilterModel;
  setFilterModelV2: (filterModel: OrderTableFilterModel) => void;
}) => {
  const [internalFilters, setInternalFilters] = useState<
    FilterConstructionType[]
  >(convertOrderTableFilterModelToFilterConstructionType(filterModelV2));

  const currentFilters = useMemo(
    () => convertOrderTableFilterModelToFilterConstructionType(filterModelV2),
    [filterModelV2],
  );

  useEffect(() => {
    setInternalFilters(currentFilters);
  }, [currentFilters]);

  const resetInternalFilters = useCallback(() => {
    setInternalFilters(
      convertOrderTableFilterModelToFilterConstructionType(filterModelV2),
    );
  }, [filterModelV2]);

  useEffect(resetInternalFilters, [resetInternalFilters]);

  const onApplyFilter = useCallback(() => {
    setFilterModelV2(
      filterConstructionTypeToOrderTableFilterModel(internalFilters),
    );
  }, [internalFilters, setFilterModelV2]);

  return {
    /** Filter state, including any unapplied changes from EditOrderTableFilters */
    internalFilters,
    /** Set the internal filter state */
    setInternalFilters,
    /** Current applied filter state, to render anywhere outside of EditOrderTableFilters */
    currentFilters,
    /** Reset the internal filter state to the current filterModelV2 */
    resetInternalFilters,
    /** Apply the internal filter state to the filterModelV2 */
    onApplyFilter,
  };
};
