import { Check } from '@mui/icons-material';
import { MenuItem, MenuList, Stack, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import useStyles from '../reports-styles';

const ReportFilterMultiSelectMenuList = ({
  selectedOptions,
  options,
  handleChange,
}: {
  selectedOptions: string[];
  options: string[];
  handleChange: (option: string) => void;
}) => {
  const styles = useStyles();

  return (
    <MenuList
      dense
      sx={{
        p: 0,
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option}
          onClick={() => handleChange(option)}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            pl: '10px',
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Check
              sx={{
                visibility: selectedOptions.includes(option)
                  ? undefined
                  : 'hidden',
                fontSize: '14px',
                ml: 0,
                mr: '6px',
              }}
            />
            <Typography sx={styles.menuText}>{sentenceCase(option)}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default ReportFilterMultiSelectMenuList;
