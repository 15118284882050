import { Box, TableCell } from '@mui/material';
import { shallow } from 'zustand/shallow';
import { TariffType } from '../../../../generated/graphql';
import RangeCell, { RangeCellValues } from '../common/range-cell';
import useTariffGroupStore from './store/tariff-group-state-store';

export enum TariffRange {
  X = 'X',
  Y = 'Y',
}

type TariffRangeCellProps = {
  rangeToModify: TariffRange;
  rangeIndex: number;
};

const TariffRangeCell = ({
  rangeToModify,
  rangeIndex,
}: TariffRangeCellProps) => {
  const [
    tariffType,
    xRangeValues,
    yRangeValues,
    setXRangeValues,
    setYRangeValues,
  ] = useTariffGroupStore(
    (state) => [
      state.tariffType,
      state.xRangeValues,
      state.yRangeValues,
      state.setXRangeValues,
      state.setYRangeValues,
    ],
    shallow,
  );

  const range = rangeToModify === TariffRange.Y ? yRangeValues : xRangeValues;

  const onChange = (newRange: RangeCellValues) => {
    if (rangeToModify === TariffRange.X) {
      setXRangeValues(newRange);
    } else if (rangeToModify === TariffRange.Y) {
      setYRangeValues(newRange);
    }
  };

  let inputLabel = '';
  if (rangeToModify === TariffRange.X) {
    inputLabel = 'Miles';
  } else if (tariffType === TariffType.PerPiece) {
    inputLabel = 'Pieces';
  } else if (tariffType === TariffType.PerHundredPounds) {
    inputLabel = 'Pounds';
  }

  return (
    <TableCell
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
        <RangeCell
          rangeValues={range}
          rangeIndex={rangeIndex}
          inputLabel={inputLabel}
          onChange={onChange}
        />
      </Box>
    </TableCell>
  );
};

export default TariffRangeCell;
