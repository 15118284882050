import { useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    filterButton: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
  };
};

export default useStyles;
