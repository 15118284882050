import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Fade,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  CreditTypeFragment,
  PermissionResource,
} from '../../../../../generated/graphql';
import SettingsColorPicker from '../../../../daily-control-center/components/settings-color-picker';

interface CreditTypeRowProps {
  creditType: CreditTypeFragment;
  onDelete: () => void;
  onClickEdit: () => void;
}

const CreditTypeRow = ({
  creditType,
  onDelete,
  onClickEdit,
}: CreditTypeRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      hover
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Typography>{creditType.name}</Typography>
      </TableCell>
      <TableCell>
        <SettingsColorPicker
          currentColor={creditType.color}
          setColor={() => {}}
          disabled
        />
      </TableCell>
      <TableCell>
        {!isNil(creditType.generalLedgerCode) ? (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: '14px' }}>
              {creditType.generalLedgerCode?.code ?? ''}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', color: theme.palette.grey[500] }}
            >
              {creditType.generalLedgerCode?.description ?? ''}
            </Typography>
          </Stack>
        ) : (
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[500] }}>
            none
          </Typography>
        )}
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <Button
            variant="contained"
            onClick={onClickEdit}
            disabled={!canWriteCompanyBilling}
          >
            Edit
          </Button>
        </Fade>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={onDelete} disabled={!canWriteCompanyBilling}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default CreditTypeRow;
