// eslint-disable-next-line no-restricted-imports
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import TimePickerComponent from '../../../common/components/time-picker-component';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import {
  CompanyConfigurationDocument,
  MeDocument,
  TableFieldsDocument,
  useUpdateCompanyConfigurationMutation,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import { RouteStopColumnsEditor } from '../dispatch-views/components/route-stop-columns-editor';

const parseNumberOrNull = (
  value: string | undefined,
  parser: (v: string) => number,
): number | null => {
  const parsed = parser(value ?? '');
  return Number.isNaN(parsed) ? null : parsed;
};

const RoutesSettingsComponent = () => {
  const { routeCardStopTableFields } = useTableFields();
  const [updateCompanyConfig] = useUpdateCompanyConfigurationMutation();
  const [updateUser] = useUpdateUserMutation();
  const ffUseNewDispatchPage = useFeatureFlag(FeatureFlag.FF_NEW_DISPATCH_PAGE);

  const [startTime, setStartTime] = useState<string | null | undefined>();
  const [loadTime, setLoadTime] = useState<string>();
  const [unloadTime, setUnloadTime] = useState<string>();
  const [defaultApptDuration, setDefaultApptDuration] = useState<string>();
  const { companyConfiguration, userUuid } = useMe({
    onCompleted: (data) => {
      const {
        defaultRouteStartTime,
        defaultRouteLoadTimeInMinutes,
        defaultRouteUnloadTimeInMinutes,
        defaultGenerateAppointmentDurationInMinutes,
      } = data.me?.company?.configuration ?? {};
      setStartTime(defaultRouteStartTime);
      setLoadTime(String(defaultRouteLoadTimeInMinutes));
      setUnloadTime(String(defaultRouteUnloadTimeInMinutes));
      setDefaultApptDuration(
        String(defaultGenerateAppointmentDurationInMinutes),
      );
    },
  });

  const updateCompanyConfiguration = () => {
    if (isNil(companyConfiguration)) {
      return;
    }

    const defaultRouteLoadTimeInMinutes = parseNumberOrNull(
      loadTime,
      parseFloat,
    );
    const defaultRouteUnloadTimeInMinutes = parseNumberOrNull(
      unloadTime,
      parseFloat,
    );
    const defaultGenerateAppointmentDurationInMinutes = parseNumberOrNull(
      defaultApptDuration ?? '',
      (v) => parseInt(v, 10),
    );
    updateCompanyConfig({
      variables: {
        updateCompanyConfigurationInput: {
          uuid: companyConfiguration.uuid,
          defaultRouteStartTime: startTime ?? null,
          defaultRouteLoadTimeInMinutes,
          defaultRouteUnloadTimeInMinutes,
          defaultGenerateAppointmentDurationInMinutes,
        },
      },
      refetchQueries: [CompanyConfigurationDocument, MeDocument],
    });
  };

  return (
    <Grid container spacing={1} sx={{ p: 1 }}>
      <Grid item xs={3}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography sx={{ fontSize: '14px' }}>Default start time</Typography>
          <TimePickerComponent
            minimizedWidth={50}
            hideClearable
            minimized
            appointmentTime={startTime}
            updateAppointmentTime={(apptTime: Dayjs | null | undefined) => {
              if (!isNil(apptTime)) {
                setStartTime(apptTime.set('seconds', 0).toISOString());
              } else {
                setStartTime(null);
              }
            }}
            onBlur={updateCompanyConfiguration}
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography sx={{ fontSize: '14px' }}>Default load time</Typography>
          <TextField
            sx={{ width: '50px' }}
            inputProps={{
              style: { fontSize: '14px' },
            }}
            size="small"
            variant="standard"
            type="number"
            placeholder="in min."
            value={loadTime}
            onChange={(e) => setLoadTime(e.target.value)}
            onBlur={updateCompanyConfiguration}
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography sx={{ fontSize: '14px' }}>Default unload time</Typography>
          <TextField
            sx={{ width: '50px' }}
            inputProps={{
              style: { fontSize: '14px' },
            }}
            size="small"
            variant="standard"
            type="number"
            placeholder="in min."
            value={unloadTime}
            onChange={(e) => setUnloadTime(e.target.value)}
            onBlur={updateCompanyConfiguration}
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography sx={{ fontSize: '14px' }}>
            Default appt. duration
          </Typography>
          <TextField
            sx={{ width: '50px' }}
            inputProps={{
              style: { fontSize: '14px' },
            }}
            size="small"
            variant="standard"
            type="number"
            placeholder="in min."
            value={defaultApptDuration}
            onChange={(e) => {
              setDefaultApptDuration(e.target.value);
            }}
            onBlur={updateCompanyConfiguration}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {ffUseNewDispatchPage && (
          <RouteStopColumnsEditor
            routeCardStopTableFields={routeCardStopTableFields}
            onChange={(newFields) => {
              if (isNil(userUuid)) {
                return;
              }
              updateUser({
                variables: {
                  updateUserInput: {
                    uuid: userUuid,
                    routeCardStopTableFields: newFields,
                  },
                },
                refetchQueries: [TableFieldsDocument],
              });
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const RoutesSettings = React.memo(RoutesSettingsComponent);
