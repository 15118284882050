import { isEmpty } from 'lodash';
import { Resolver, useForm } from 'react-hook-form';
import { BusinessDivisionFormValues } from './types';

export const resolver: Resolver<BusinessDivisionFormValues> = async (
  values,
) => {
  const errors: {
    [property in keyof BusinessDivisionFormValues]?: {
      type: string;
      message: string;
    };
  } = {};

  if (isEmpty(values.name)) {
    errors.name = {
      type: 'required',
      message: 'Name is required',
    };
  }

  return {
    values,
    errors,
  };
};

const useCreateBusinessDivisionForm = () => {
  return useForm({ resolver });
};

export default useCreateBusinessDivisionForm;
