import { MenuItem, TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { ReactNode } from 'react';
import { useAvailableTariffTypes } from '../../../../common/react-hooks/use-available-tariff-types';
import { TariffGroupType } from '../../../../generated/graphql';

export const TariffGroupTypeDropdown = ({
  tariffGroupType,
  setTariffGroupType,
  disabled,
  fullWidth = true,
}: {
  tariffGroupType: TariffGroupType;
  setTariffGroupType: (tariffGroupType: TariffGroupType) => void;
  disabled?: boolean;
  fullWidth?: boolean;
}) => {
  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const options: ReactNode[] = [];

  if (ordinaryTariffGroupAvailable) {
    options.push(<MenuItem value={TariffGroupType.Ordinary}>Default</MenuItem>);
  }
  if (transferTariffGroupAvailable) {
    options.push(
      <MenuItem value={TariffGroupType.Transfer}>
        {sentenceCase(TariffGroupType.Transfer)}
      </MenuItem>,
    );
  }
  if (lineHaulTariffGroupAvailable) {
    options.push(
      <MenuItem value={TariffGroupType.LineHaul}>
        {sentenceCase(TariffGroupType.LineHaul)}
      </MenuItem>,
    );
  }
  if (pointToPointTariffGroupAvailable) {
    options.push(
      <MenuItem value={TariffGroupType.PointToPoint}>
        {sentenceCase(TariffGroupType.PointToPoint)}
      </MenuItem>,
    );
  }

  return (
    <TextField
      label="Tariff Group Type"
      value={tariffGroupType}
      onChange={(e) => setTariffGroupType(e.target.value as TariffGroupType)}
      size="small"
      fullWidth={fullWidth}
      select
      disabled={disabled}
      sx={{ minWidth: '150px' }}
    >
      {options}
    </TextField>
  );
};
