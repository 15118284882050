import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React from 'react';
import { shallow } from 'zustand/shallow';
import useReportsStore from '../reports-store';
import DateRangeFilter from './date-range-filter';

const ReportFilterDateRangeMenuListV2 = () => {
  const [reportGroupConfiguration, setCurrentReportGroupConfiguration] =
    useReportsStore(
      (state) => [
        state.reportGroupConfigurations[state.currentReportIndex],
        state.setCurrentReportGroupConfiguration,
      ],
      shallow,
    );

  const getUpdatedDates = (startDate: Date | null, endDate: Date | null) => {
    const newStartDate: Date = startDate ?? new Date();
    let newEndDate: Date = endDate ?? new Date();
    if (newStartDate > newEndDate) {
      newEndDate = dayjs(newStartDate).add(1, 'day').toDate();
    }
    return { startDate: newStartDate, endDate: newEndDate };
  };

  const handleStartDateChange = (date: Date | null) => {
    if (!isNil(reportGroupConfiguration) && !isNil(date)) {
      const data = { ...reportGroupConfiguration };
      const { startDate, endDate } = getUpdatedDates(date, data.endDate);
      data.startDate = startDate;
      data.endDate = endDate;
      setCurrentReportGroupConfiguration(data);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!isNil(reportGroupConfiguration) && !isNil(date)) {
      const data = { ...reportGroupConfiguration };
      const { startDate, endDate } = getUpdatedDates(data.startDate, date);
      data.startDate = startDate;
      data.endDate = endDate;
      setCurrentReportGroupConfiguration(data);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 3,
      }}
    >
      <DateRangeFilter
        startDate={reportGroupConfiguration?.startDate}
        endDate={reportGroupConfiguration?.endDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        allowNoLimit={false}
      />
    </Box>
  );
};

export default ReportFilterDateRangeMenuListV2;
