// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fade, IconButton, Stack, Tooltip } from '@mui/material';
import Popper from '@mui/material/Popper';
import { RefObject, useState } from 'react';
import { LineHaulOrderFragment } from '../../../../../generated/graphql';
import theme from '../../../../../theme';
import useLineHaulDispatchActions from '../../../hooks/use-line-haul-dispatch-actions';
import PostponeOrdersModal from '../../postpone-orders-modal';

const ManifestOrdersTableRowHoverMenu = ({
  anchorRef,
  manifestUuid,
  order,
  open,
}: {
  anchorRef: RefObject<HTMLDivElement>;
  manifestUuid: string;
  order: LineHaulOrderFragment;
  open: boolean;
}) => {
  const { removeSingleOrderFromManifest } = useLineHaulDispatchActions();

  const [showPostponeOrdersModal, setShowPostponeOrdersModal] = useState(false);

  return (
    <>
      <PostponeOrdersModal
        open={showPostponeOrdersModal}
        setOpen={setShowPostponeOrdersModal}
        orderUuids={[order.uuid]}
        manifestUuid={manifestUuid}
      />
      <Popper
        style={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorRef?.current}
        transition
        placement="top-end"
        keepMounted
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={50}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                border: `1px solid ${theme.palette.borderColor.main}`,
                borderRadius: '5px',
                backgroundColor: 'white',
                mr: 0,
                mb: -10,
              }}
            >
              <Tooltip title="Remove from manifest">
                <IconButton
                  sx={{ p: '5px' }}
                  onClick={(e) => {
                    removeSingleOrderFromManifest({
                      uuid: manifestUuid,
                      orderUuid: order.uuid,
                    });
                    e.stopPropagation();
                  }}
                >
                  <DeleteIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ManifestOrdersTableRowHoverMenu;
