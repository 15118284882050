import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../../common/react-hooks/use-me';
import useQuickbooksDesktopData from '../../../common/react-hooks/use-quickbooks-desktop-data';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  MeDocument,
  PermissionResource,
  useAccessorialsQuery,
  useBulkUpdateAccessorialMappingsMutation,
  useUpdateCompanyMutation,
} from '../../../generated/graphql';
import QuickbooksDesktopMappingAutocomplete from './quickbooks-desktop-mapping-autocomplete';

const QuickbooksDesktopCompanyMappings = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsQuickbooksDesktop } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsQuickbooksDesktop,
  );
  const { data: accessorialsData, loading: accessorialsLoading } =
    useAccessorialsQuery({
      variables: { onlyShowCompanyAccessorials: true },
    });

  const {
    companyData,
    companyConfiguration,
    loading: companyLoading,
  } = useMe();
  const {
    quickbooksItems,
    quickbooksItemsLoading,
    refetchQuickbooksItems,
    quickbooksAccounts,
    quickbooksAccountsLoading,
    refetchQuickbooksAccounts,
  } = useQuickbooksDesktopData();

  const [updateCompany] = useUpdateCompanyMutation({
    refetchQueries: [MeDocument],
  });
  const [updateAccessorials] = useBulkUpdateAccessorialMappingsMutation({
    refetchQueries: [MeDocument],
  });
  const [deliveryChargeMapping, setDeliveryChargeMapping] = useState<string>();
  const [fuelChargeMapping, setFuelChargeMapping] = useState<string>();
  const [accessorialChargeMapping, setAccessorialChargeMapping] =
    useState<string>();
  const [pickupChargeMapping, setPickupChargeMapping] = useState<string>();
  const [transferChargeMapping, setTransferChargeMapping] = useState<string>();
  const [lineHaulChargeMapping, setLineHaulChargeMapping] = useState<string>();
  const [orderChargeChargeMapping, setOrderChargeChargeMapping] =
    useState<string>();
  const [accountMapping, setAccountMapping] = useState<string>();
  const [accessorialsToUpdate, setAccessorialsToUpdate] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [showSuccessfulSave, setShowSuccessfulSave] = useState(false);
  const [showUnsuccessfulSave, setShowUnsuccessfulSave] = useState(false);

  const quickbooksLoading = quickbooksItemsLoading || quickbooksAccountsLoading;

  useEffect(() => {
    if (!isNil(quickbooksItems?.error)) {
      refetchQuickbooksItems();
    }
    if (!isNil(quickbooksAccounts?.error)) {
      refetchQuickbooksAccounts();
    }
  }, []);

  useEffect(() => {
    const quickbooksDesktopDeliveryId =
      companyData?.quickbooksDesktopDeliveryId;
    const quickbooksDesktopPickupId = companyData?.quickbooksDesktopPickupId;
    const quickbooksDesktopTransferId =
      companyData?.quickbooksDesktopTransferId;
    const quickbooksDesktopLineHaulId =
      companyData?.quickbooksDesktopLineHaulId;
    const quickbooksDesktopOrderChargeId =
      companyData?.quickbooksDesktopOrderChargeId;
    const quickbooksDesktopFuelChargeId =
      companyData?.quickbooksDesktopFuelChargeId;
    const quickbooksDesktopAccessorialId =
      companyData?.quickbooksDesktopAccessorialId;
    const quickbooksDesktopAccountsReceivableId =
      companyData?.quickbooksDesktopAccountsReceivableId;
    setDeliveryChargeMapping(quickbooksDesktopDeliveryId ?? '');
    setFuelChargeMapping(quickbooksDesktopFuelChargeId ?? '');
    setAccessorialChargeMapping(quickbooksDesktopAccessorialId ?? '');
    setPickupChargeMapping(quickbooksDesktopPickupId ?? '');
    setTransferChargeMapping(quickbooksDesktopTransferId ?? '');
    setLineHaulChargeMapping(quickbooksDesktopLineHaulId ?? '');
    setOrderChargeChargeMapping(quickbooksDesktopOrderChargeId ?? '');
    setAccountMapping(quickbooksDesktopAccountsReceivableId ?? '');
  }, [
    companyData?.quickbooksDesktopDeliveryId,
    companyData?.quickbooksDesktopPickupId,
    companyData?.quickbooksDesktopTransferId,
    companyData?.quickbooksDesktopLineHaulId,
    companyData?.quickbooksDesktopOrderChargeId,
    companyData?.quickbooksDesktopFuelChargeId,
    companyData?.quickbooksDesktopAccessorialId,
    companyData?.quickbooksDesktopAccountsReceivableId,
  ]);

  useEffect(() => {
    const accessorialState: {
      [key: string]: string;
    } = {};
    accessorialsData?.accessorials.forEach((accessorial) => {
      if (!isNil(accessorial.quickbooksDesktopAccessorialId)) {
        if (accessorial.__typename !== 'SpecialAccessorialEntity') {
          accessorialState[accessorial.uuid] =
            accessorial.quickbooksDesktopAccessorialId;
        }
      }
    });
    setAccessorialsToUpdate(accessorialState);
  }, [accessorialsData?.accessorials.length]);

  const onSave = async () => {
    const updateCompanyRes = await updateCompany({
      variables: {
        updateCompanyInput: {
          quickbooksDesktopDeliveryId: deliveryChargeMapping ?? null,
          quickbooksDesktopFuelChargeId: fuelChargeMapping ?? null,
          quickbooksDesktopAccessorialId: accessorialChargeMapping ?? null,
          quickbooksDesktopPickupId: pickupChargeMapping ?? null,
          quickbooksDesktopTransferId: transferChargeMapping ?? null,
          quickbooksDesktopLineHaulId: lineHaulChargeMapping ?? null,
          quickbooksDesktopOrderChargeId: orderChargeChargeMapping ?? null,
          quickbooksDesktopAccountsReceivableId: accountMapping ?? null,
        },
      },
    });
    const updateAccessorialRes = await updateAccessorials({
      variables: {
        bulkAccessorialUpdateInput: {
          accessorialToMappingInputs: Object.keys(accessorialsToUpdate).map(
            (accessorialUuid) => ({
              uuid: accessorialUuid,
              desktopMapping: accessorialsToUpdate[accessorialUuid] ?? null,
            }),
          ),
        },
      },
    });
    if (
      !isNil(updateCompanyRes.data?.updateCompany.uuid) &&
      !isNil(updateAccessorialRes.data?.bulkUpdateAccessorialMappings.length)
    ) {
      setShowSuccessfulSave(true);
    } else {
      setShowUnsuccessfulSave(true);
    }
  };

  if (companyLoading || accessorialsLoading || quickbooksLoading === true) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isNil(quickbooksItems?.error) || !isNil(quickbooksAccounts?.error)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          textAlign: 'center',
        }}
      >
        {!isNilOrEmptyString(quickbooksItems?.error) && (
          <Typography>
            Error fetching Quickbooks items: {quickbooksItems?.error}
          </Typography>
        )}
        {!isNilOrEmptyString(quickbooksAccounts?.error) && (
          <Typography>
            Error fetching Quickbooks accounts: {quickbooksAccounts?.error}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowSuccessfulSave(false)}
        open={showSuccessfulSave}
      >
        <Alert>Successfully saved Quickbooks mappings</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowUnsuccessfulSave(false)}
        open={showUnsuccessfulSave}
      >
        <Alert severity="error">Failed to save</Alert>
      </Snackbar>

      <Box
        sx={{
          textAlign: 'right',
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          onClick={onSave}
          disabled={!canWriteSettingsQuickbooksDesktop}
        >
          Save
        </Button>
      </Box>
      <Typography variant="h6">Freight charge mappings</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography>Pickup charge</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={pickupChargeMapping}
          onChange={(value) => {
            setPickupChargeMapping(value);
          }}
          quickbooksData={quickbooksItems}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>Delivery charge</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={deliveryChargeMapping}
          onChange={(value) => {
            setDeliveryChargeMapping(value);
          }}
          quickbooksData={quickbooksItems}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>Transfer charge</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={transferChargeMapping}
          onChange={(value) => {
            setTransferChargeMapping(value);
          }}
          quickbooksData={quickbooksItems}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      {companyConfiguration?.lineHaulEnabled === true && (
        <Stack direction="row" justifyContent="space-between">
          <Typography>Line haul charge</Typography>
          <QuickbooksDesktopMappingAutocomplete
            currentMappingId={lineHaulChargeMapping}
            onChange={(value) => {
              setLineHaulChargeMapping(value);
            }}
            quickbooksData={quickbooksItems}
            disabled={!canWriteSettingsQuickbooksDesktop}
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography>Order charge</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={orderChargeChargeMapping}
          onChange={(value) => {
            setOrderChargeChargeMapping(value);
          }}
          quickbooksData={quickbooksItems}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>Fuel charge</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={fuelChargeMapping}
          onChange={(value) => {
            setFuelChargeMapping(value);
          }}
          quickbooksData={quickbooksItems}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      <Divider />
      <Typography variant="h6">Accessorial charge mappings</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography>Default accessorial</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={accessorialChargeMapping}
          onChange={(value) => {
            setAccessorialChargeMapping(value);
          }}
          quickbooksData={quickbooksItems}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      {accessorialsData?.accessorials
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((accessorial) => (
          <Stack
            key={accessorial.uuid}
            direction="row"
            justifyContent="space-between"
          >
            <Typography>{accessorial.name}</Typography>
            <QuickbooksDesktopMappingAutocomplete
              currentMappingId={accessorialsToUpdate[accessorial.uuid]}
              onChange={(value) => {
                setAccessorialsToUpdate((prevState) => ({
                  ...prevState,
                  [accessorial.uuid]: value,
                }));
              }}
              quickbooksData={quickbooksItems}
            />
          </Stack>
        ))}
      <Divider />
      <Typography variant="h6">Company account mapping</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography>AR mapping</Typography>
        <QuickbooksDesktopMappingAutocomplete
          currentMappingId={accountMapping}
          onChange={(value) => {
            setAccountMapping(value);
          }}
          quickbooksData={quickbooksAccounts}
          disabled={!canWriteSettingsQuickbooksDesktop}
        />
      </Stack>
      <Typography variant="caption">
        Not seeing the latest data from Quickbooks Desktop? Try refreshing the
        page.
      </Typography>
    </Box>
  );
};

export default QuickbooksDesktopCompanyMappings;
