import { Button } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../theme';
import { getBadgeStyle } from '../utils/get-badge-style';

const BUTTON_COLOR = theme.palette.text.secondary;
const BUTTON_BORDER_COLOR = theme.palette.grey[500];
const BOX_SHADOW = `0 0 0 1px ${BUTTON_BORDER_COLOR}`;
const BADGE_POSITION = '2px';

const OrderTableFunctionButton = styled(Button)<{ showBadge?: boolean }>(
  ({ showBadge }) => ({
    color: BUTTON_COLOR,
    borderWidth: 0,
    borderRadius: '6px',
    whiteSpace: 'nowrap',
    height: 'fit-content',
    minWidth: 'unset',
    ...(showBadge === true && getBadgeStyle('transparent', BADGE_POSITION)),
    '&:not(:disabled)': {
      boxShadow: BOX_SHADOW,
    },
    '&:hover': {
      borderWidth: 0,
      boxShadow: BOX_SHADOW,
    },
  }),
);

export default OrderTableFunctionButton;
