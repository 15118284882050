import { useHoldReasonsQuery } from '../../generated/graphql';

const useHoldReasons = () => {
  const { data: holdReasonsData, refetch: refetchHoldReasons } =
    useHoldReasonsQuery({
      fetchPolicy: 'cache-first',
      variables: {
        archived: false,
      },
    });

  const getHoldReasonName = (uuid: string | null | undefined) => {
    return holdReasonsData?.holdReasons.find(
      (holdReason) => holdReason.uuid === uuid,
    )?.name;
  };

  return {
    holdReasons: holdReasonsData?.holdReasons ?? [],
    getHoldReasonName,
    refetchHoldReasons,
  };
};

export default useHoldReasons;
