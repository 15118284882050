import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

export type RouteValues = Partial<{
  routeSlotUuids: string[];
  name: string;
  date: Date;
  driverUuids: string[];
  equipmentUuids: string[];
}> & { uuid: string };

const routeValuesAdapter = createEntityAdapter<RouteValues>({
  selectId: (route) => route.uuid,
});

export const routeValuesSlice = createSlice({
  name: 'routeValues',
  initialState: routeValuesAdapter.getInitialState(),
  reducers: {
    addOneRouteValues: routeValuesAdapter.addOne,
    updateOneRouteValues: routeValuesAdapter.updateOne,
    removeOneRouteValues: routeValuesAdapter.removeOne,
    setAllRouteValues: routeValuesAdapter.setAll,
    upsertOneRouteValues: routeValuesAdapter.upsertOne,
  },
});

export const { selectById: selectRouteValuesById } =
  routeValuesAdapter.getSelectors((state: RootState) => state.routeValues);

const routeValuesReducer = routeValuesSlice.reducer;

export const {
  addOneRouteValues,
  removeOneRouteValues,
  updateOneRouteValues,
  setAllRouteValues,
  upsertOneRouteValues,
} = routeValuesSlice.actions;

export default routeValuesReducer;
