import { isNil } from 'lodash';
import { useBusinessDivisionsQuery } from '../../generated/graphql';

const useBusinessDivisions = () => {
  const {
    data: businessDivisionsData,
    refetch: refetchBusinessDivisions,
    loading: businessDivisionsLoading,
  } = useBusinessDivisionsQuery({
    fetchPolicy: 'cache-first',
  });

  const businessDivisions =
    businessDivisionsData?.businessDivisions.businessDivisions;

  const getBusinessDivisionsName = (uuid: string | null | undefined) => {
    if (isNil(uuid)) {
      return '';
    }
    const businessDivision = businessDivisions?.find((d) => d.uuid === uuid);
    return businessDivision?.name;
  };

  return {
    getBusinessDivisionsName,
    businessDivisions,
    businessDivisionsLoading,
    refetchBusinessDivisions,
  };
};

export default useBusinessDivisions;
