import {
  Box,
  Button,
  CircularProgress,
  Fade,
  TableCell,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import { safeDivide } from 'shared/math';
import { transformDateToDateString } from '../../../../common/utils/prettyPrintUtils';
import {
  PaymentForInvoiceFragment,
  useAllInvoicesForPaymentApplicationLazyQuery,
} from '../../../../generated/graphql';
import CreatePaymentModal from '../customers/create-payment-modal';
import { CreateOrEdit } from './enums';

interface InvoicePaymentsListRowProps {
  payment: PaymentForInvoiceFragment;
  refresh?: () => void;
  inCustomersTab?: boolean;
}

const InvoicePaymentListRow = ({
  payment,
  refresh,
  inCustomersTab = false,
}: InvoicePaymentsListRowProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showEditPaymentsModal, setShowEditPaymentsModal] = useState(false);

  const masterAccountUuid = payment.contact.masterAccount?.uuid;
  const [
    fetchAllInvoicesForPaymentApplication,
    { data: allInvoicesForPaymentApplication, loading: allInvoicesDataLoading },
  ] = useAllInvoicesForPaymentApplicationLazyQuery({
    variables: {
      masterAccountUuid,
      billingPartyContactUuid: isNil(masterAccountUuid)
        ? payment.contact.uuid
        : undefined,
    },
  });

  const handleClickModifyApplication = () => {
    setShowEditPaymentsModal(true);
    fetchAllInvoicesForPaymentApplication();
  };

  const handleClosePaymentModal = () => {
    if (!isNil(refresh)) refresh();
    setShowEditPaymentsModal(false);
  };

  return (
    <>
      {showEditPaymentsModal && !isNil(allInvoicesForPaymentApplication) && (
        <CreatePaymentModal
          open={showEditPaymentsModal}
          onClose={handleClosePaymentModal}
          contactUuid={payment.contact.uuid}
          paymentUuid={payment.uuid}
          createOrEdit={CreateOrEdit.Edit}
          type={payment.paymentType}
          allInvoicesForPaymentApplication={allInvoicesForPaymentApplication}
        />
      )}
      <TableRow
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <TableCell>
          {!isEmpty(payment.referenceNumber) ? payment.referenceNumber : 'None'}
        </TableCell>
        <TableCell>
          {transformDateToDateString(new Date(payment.date).toISOString())}
        </TableCell>
        <TableCell>{sentenceCase(payment.paymentType)}</TableCell>
        <TableCell>{payment.contact.displayName}</TableCell>
        <TableCell>
          ${safeDivide(payment.amountInCents, 100).toFixed(2)}
        </TableCell>
        {inCustomersTab && (
          <TableCell>
            <Fade in={isHovering}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <Button
                  onClick={handleClickModifyApplication}
                  variant="contained"
                  startIcon={
                    allInvoicesDataLoading && (
                      <CircularProgress size={15} sx={{ color: 'gray' }} />
                    )
                  }
                >
                  Modify application
                </Button>
              </Box>
            </Fade>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default InvoicePaymentListRow;
