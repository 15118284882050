import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DriverPayType,
  useFindDefaultTariffGroupForCompanyQuery,
  useFindDefaultTransferTariffGroupForCompanyQuery,
} from '../../../../../generated/graphql';
import CreateTariffGroupModal from '../../tariffs/create-tariff-group-modal';
import EditTariffGroupModal from '../../tariffs/edit-tariff-group-modal';
import { DemoAllTariffsTable } from '../../tariffs/tariffs-table/demo/demo-all-tariffs-table';

const DRIVER_PAY_TYPES = [
  {
    driverPayTypeName: DriverPayType.PercentGrossRevenue,
    displayName: 'Percent of Gross Revenue',
  },
  {
    driverPayTypeName: DriverPayType.PerMile,
    displayName: 'Per Mile',
  },
  {
    driverPayTypeName: 'Flat Rate',
    displayName: 'Flat Rate',
  },
  {
    driverPayTypeName: 'Flat Rate Per Lane',
    displayName: 'Flat Rate Per Lane',
  },
  {
    driverPayTypeName: 'Zone Based',
    displayName: 'Zone Based',
  },
] as const;

type DriverFormData = {
  active: boolean;
  defaultVehicleUuid: string | null;
  driverPayType?: string | null;
  driverType?: string | null;
  driverReferenceNumber?: string | null;
  emailAddress?: string | null;
  firstName: string;
  hourlyRate?: number | null;
  lastName: string;
  password?: string | null;
  perMileRate?: number | null;
  percentGrossRevenueRate?: number | null;
  phoneNumber: string;
  notes: string;
  defaultFuelSettlementPercentageRate?: number | null;
  terminalUuid?: string | null;
  driverQualificationIds: string[];
};

type DriverFormErrors = {
  [key in keyof DriverFormData]?: string;
};

type DriverSettlementSectionProps = {
  driverFormData: DriverFormData;
  setDriverFormData: (driverFormData: DriverFormData) => void;
  driverFormErrors: DriverFormErrors;
};

// DEMO
export const DriverSettlementSection: FunctionComponent<
  DriverSettlementSectionProps
> = ({ driverFormData, setDriverFormData, driverFormErrors }) => {
  const navigate = useNavigate();
  const [
    showEditTransferTariffGroupModal,
    setShowEditTransferTariffGroupModal,
  ] = useState<boolean>(false);
  const [showEditTariffGroupModal, setShowEditTariffGroupModal] =
    useState<boolean>(false);
  const { data: defaultTariffForCompany, loading } =
    useFindDefaultTariffGroupForCompanyQuery();
  const { data: defaultTransferTariffForCompany } =
    useFindDefaultTransferTariffGroupForCompanyQuery();
  const [
    showCreateDefaultTariffGroupModal,
    setShowCreateDefaultTariffGroupModal,
  ] = useState<boolean>(false);
  const [
    showCreateDefaultTransferTariffGroupModal,
    setShowCreateDefaultTransferTariffGroupModal,
  ] = useState<boolean>(false);
  const [showCreateTariffGroupModal, setShowCreateTariffGroupModal] =
    useState<boolean>(false);

  return (
    <>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack direction="row" gap={2} justifyContent="flex-start">
          <FormControl
            sx={{ width: '220px' }}
            error={!isNil(driverFormErrors.driverPayType)}
          >
            <InputLabel id="select-driver-pay-type-label">Pay type</InputLabel>
            <Select
              size="small"
              labelId="select-driver-pay-type-label"
              label="Pay type"
              id="select-driver-pay-type"
              value={driverFormData.driverPayType ?? ''}
              onChange={(event) => {
                if (typeof event.target.value === 'string') {
                  setDriverFormData({
                    ...driverFormData,
                    driverPayType: event.target.value,
                  });
                }
              }}
            >
              {DRIVER_PAY_TYPES.map((driverPayType) => (
                <MenuItem
                  key={driverPayType.driverPayTypeName}
                  value={driverPayType.driverPayTypeName}
                >
                  {driverPayType.displayName}
                </MenuItem>
              ))}
            </Select>
            {!isNil(driverFormErrors.driverPayType) && (
              <FormHelperText error>
                {driverFormErrors.driverPayType}
              </FormHelperText>
            )}
          </FormControl>
          {driverFormData.driverPayType === DriverPayType.PerMile && (
            <TextField
              size="small"
              sx={{ width: '220px' }}
              label="Per Mile Rate"
              type="number"
              error={!isNil(driverFormErrors.perMileRate)}
              helperText={driverFormErrors.perMileRate}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  perMileRate: Number.isNaN(event.target.value)
                    ? 0
                    : parseFloat(event.target.value),
                });
              }}
              value={driverFormData.perMileRate}
            />
          )}
          {driverFormData.driverPayType ===
            DriverPayType.PercentGrossRevenue && (
            <TextField
              size="small"
              sx={{ width: '220px' }}
              label="Percent Of Gross Revenue to Pay"
              type="number"
              error={!isNil(driverFormErrors.percentGrossRevenueRate)}
              helperText={driverFormErrors.percentGrossRevenueRate}
              onChange={(event) => {
                setDriverFormData({
                  ...driverFormData,
                  percentGrossRevenueRate: Number.isNaN(event.target.value)
                    ? 0
                    : parseFloat(event.target.value),
                });
              }}
              value={driverFormData.percentGrossRevenueRate}
              required
            />
          )}
          <TextField
            size="small"
            sx={{ width: '220px' }}
            label="Fuel Settlement Rate"
            type="number"
            error={!isNil(driverFormErrors.defaultFuelSettlementPercentageRate)}
            helperText={driverFormErrors.defaultFuelSettlementPercentageRate}
            onChange={(event) => {
              setDriverFormData({
                ...driverFormData,
                defaultFuelSettlementPercentageRate: Number.isNaN(
                  event.target.value,
                )
                  ? null
                  : parseFloat(event.target.value),
              });
            }}
            value={driverFormData.defaultFuelSettlementPercentageRate ?? ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="flex-start">
          {isNil(defaultTariffForCompany?.findDefaultTariffGroupForCompany) &&
            !loading && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowCreateDefaultTariffGroupModal(true);
                }}
              >
                Create Default Tariff
              </Button>
            )}
          {!isNil(
            defaultTariffForCompany?.findDefaultTariffGroupForCompany,
          ) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setShowEditTariffGroupModal(true);
              }}
            >
              Edit Default Tariff
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowCreateTariffGroupModal(true);
            }}
          >
            Create New Tariff
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(`/management/tariff-zones/edit?isTerminal=true`);
            }}
          >
            Edit Zones
          </Button>
        </Stack>
      </Stack>
      <DemoAllTariffsTable />
      <CreateTariffGroupModal
        isOpen={showCreateDefaultTariffGroupModal}
        setIsOpen={setShowCreateDefaultTariffGroupModal}
        isCompanyDefaultTariff
      />
      <CreateTariffGroupModal
        isOpen={showCreateDefaultTransferTariffGroupModal}
        setIsOpen={setShowCreateDefaultTransferTariffGroupModal}
        isCompanyDefaultTariff
        isTransferTariff
      />
      <CreateTariffGroupModal
        isOpen={showCreateTariffGroupModal}
        setIsOpen={setShowCreateTariffGroupModal}
      />
      {!isNil(
        defaultTariffForCompany?.findDefaultTariffGroupForCompany?.uuid,
      ) && (
        <EditTariffGroupModal
          isOpen={showEditTariffGroupModal}
          setIsOpen={setShowEditTariffGroupModal}
          tariffGroupUuid={
            defaultTariffForCompany?.findDefaultTariffGroupForCompany?.uuid ??
            ''
          }
          isCompanyDefaultTariff
        />
      )}
      {!isNil(
        defaultTransferTariffForCompany
          ?.findDefaultTransferTariffGroupForCompany?.uuid,
      ) && (
        <EditTariffGroupModal
          isOpen={showEditTransferTariffGroupModal}
          setIsOpen={setShowEditTransferTariffGroupModal}
          tariffGroupUuid={
            defaultTransferTariffForCompany
              ?.findDefaultTransferTariffGroupForCompany?.uuid ?? ''
          }
          isCompanyDefaultTariff
          isTransferTariff
        />
      )}
    </>
  );
};
