import { useNavigate } from 'react-router-dom';
import apolloClient from '../../apollo-client';
import { useLogoutMutation } from '../../generated/graphql';
import { useAppDispatch } from '../../redux/hooks';
import { resetStateAction } from '../../redux/store';
import { LOCAL_STORAGE_KEYS_TO_PERSIST } from '../local-storage/keys';

const useLogout = () => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();

  const logoutFunc = async () => {
    // send logout request
    await logout();

    // clear redux state
    dispatch(resetStateAction());

    const cookieKey = import.meta.env.VITE_SESSION_COOKIE_KEY ?? 'appSession';

    // clear cookies
    document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    // clear localstorage except for specific keys
    const keysToDelete = Object.keys(localStorage).filter(
      (key) => !LOCAL_STORAGE_KEYS_TO_PERSIST.includes(key as never),
    );
    keysToDelete.forEach((key) => {
      localStorage.removeItem(key);
    });

    // clear apollo cache
    await apolloClient.clearStore();

    navigate('/');
    window?.location.reload();
  };
  return logoutFunc;
};

export default useLogout;
