import { CircularProgress, Stack } from '@mui/material';
import { useEffect } from 'react';
import { usePreviewProratedConsolidationV2Query } from '../../../../../generated/graphql';
import ProratedOrderDetails from './prorated-order-details';
import { ChargesToConsolidate } from './select-charges-to-consolidate';

const ConsolidatedOrderInformation = ({
  primaryOrderUuid,
  ordersToConsolidateWithUuids,
  selectedChargesToConsolidate,
  orderUuidsToRefresh,
  setOpenedOrderUuid,
  setDisableConsolidateButton,
}: {
  primaryOrderUuid: string;
  ordersToConsolidateWithUuids: string[];
  selectedChargesToConsolidate: Record<ChargesToConsolidate, boolean>;
  orderUuidsToRefresh: string[];
  setOpenedOrderUuid: (uuid: string) => void;
  setDisableConsolidateButton: (disabled: boolean) => void;
}) => {
  const {
    data: previewedConsolidationData,
    loading,
    refetch: refetchPreviewedConsolidation,
  } = usePreviewProratedConsolidationV2Query({
    variables: {
      previewProratedConsolidationV2Input: {
        primaryOrderUuid,
        ordersToConsolidateWithUuids,
        shouldProrateInbound: selectedChargesToConsolidate.INBOUND,
        shouldProrateOutbound: selectedChargesToConsolidate.OUTBOUND,
      },
    },
    onCompleted: ({ previewProratedConsolidationV2: previewResult }) => {
      if (previewResult.__typename === 'QueryErrorOutput') {
        setDisableConsolidateButton(true);
      } else {
        setDisableConsolidateButton(false);
      }
    },
  });

  useEffect(() => {
    refetchPreviewedConsolidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUuidsToRefresh.length]);

  // disable the consolidate button if the preview is loading
  useEffect(() => {
    if (loading) {
      setDisableConsolidateButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Stack gap={2}>
      {loading && <CircularProgress />}
      {!loading && (
        <ProratedOrderDetails
          consolidationOutput={
            previewedConsolidationData?.previewProratedConsolidationV2
          }
          setOpenedOrderUuid={setOpenedOrderUuid}
        />
      )}
    </Stack>
  );
};

export default ConsolidatedOrderInformation;
