import { Box, Divider, Stack } from '@mui/material';
import React from 'react';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import useTerminals from '../../../common/react-hooks/use-terminals';
import { ReportType } from '../../../generated/graphql';
import useReportsStore from '../reports-store';
import { ReportFilterType } from '../types/report-filters';
import CustomerReport from './customer-report';
import DriverReport from './driver-report';
import OrderReport from './order-report/order-report';
import ReportFilterButton from './report-filter-button';
import ServiceLevelReport from './service-level-report';
import StationReport from './station-report/station-report';
import TerminalReport from './terminal-report';

const Report = () => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: true,
  });
  const [reportGroupConfiguration] = useReportsStore((state) => [
    state.reportGroupConfigurations[state.currentReportIndex],
  ]);

  return (
    <Box>
      <Stack direction="row" padding={1} rowGap={1} flexWrap="wrap">
        <ReportFilterButton type={ReportFilterType.REPORT_TYPE} />
        {reportGroupConfiguration?.defaultReportType ===
          ReportType.Customer && (
          <ReportFilterButton type={ReportFilterType.CONTACTS} />
        )}
        {reportGroupConfiguration?.defaultReportType === ReportType.Driver && (
          <ReportFilterButton type={ReportFilterType.DRIVERS} />
        )}
        <ReportFilterButton type={ReportFilterType.REVENUE_TYPE} />
        <ReportFilterButton type={ReportFilterType.SERVICE_DATE_RANGE} />
        {reportGroupConfiguration?.defaultReportType === ReportType.Order && (
          <ReportFilterButton type={ReportFilterType.AGGREGATION_PERIOD} />
        )}
        <ReportFilterButton type={ReportFilterType.STOP_TYPE} />
        {reportGroupConfiguration?.defaultReportType !==
          ReportType.Customer && (
          <ReportFilterButton type={ReportFilterType.CONTACT} />
        )}
        {reportGroupConfiguration?.defaultReportType !== ReportType.Driver && (
          <ReportFilterButton type={ReportFilterType.DRIVER} />
        )}
        <ReportFilterButton type={ReportFilterType.BUSINESS_DIVISION} />
        {terminalsEnabled &&
          reportGroupConfiguration?.defaultReportType !==
            ReportType.Terminal && (
            <ReportFilterButton type={ReportFilterType.TERMINAL} />
          )}
      </Stack>
      <Box padding={1}>
        <Divider />
      </Box>
      <Box>
        <TabPanel
          panelValue={ReportType.Order}
          selectedValue={reportGroupConfiguration?.defaultReportType}
        >
          <OrderReport />
        </TabPanel>
        <TabPanel
          panelValue={ReportType.Customer}
          selectedValue={reportGroupConfiguration?.defaultReportType}
        >
          <CustomerReport />
        </TabPanel>
        <TabPanel
          panelValue={ReportType.Driver}
          selectedValue={reportGroupConfiguration?.defaultReportType}
        >
          <DriverReport />
        </TabPanel>
        <TabPanel
          panelValue={ReportType.ServiceLevel}
          selectedValue={reportGroupConfiguration?.defaultReportType}
        >
          <ServiceLevelReport />
        </TabPanel>
        <TabPanel
          panelValue={ReportType.Station}
          selectedValue={reportGroupConfiguration?.defaultReportType}
        >
          <StationReport />
        </TabPanel>
        {terminalsEnabled && (
          <TabPanel
            panelValue={ReportType.Terminal}
            selectedValue={reportGroupConfiguration?.defaultReportType}
          >
            <TerminalReport />
          </TabPanel>
        )}
      </Box>
    </Box>
  );
};

export default Report;
