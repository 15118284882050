import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useTags from '../../common/react-hooks/use-tags';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../generated/graphql';
import AddTagModal from './add-tag-modal';
import TagRow from './tag-row';

const TagsTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsTags } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsTags,
  );

  const { tags } = useTags();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
      <AddTagModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Style</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  onClick={() => setIsModalOpen(true)}
                  disabled={!canWriteSettingsTags}
                >
                  Add Tag
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((tag) => (
              <TagRow uuid={tag.uuid} color={tag.color} value={tag.value} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default TagsTable;
