import { isNil } from 'lodash';
import { DocViewerDocument } from '../../domains/end-of-day/types/doc-viewer-document';
import DocumentViewerModal from './document-viewer-modal';

type DocumentsViewerModalProps = {
  documents: DocViewerDocument[];
  selectedIndex: number | undefined;
  setSelectedIndex: (index: number | undefined) => void;
};

/**
 * A convenient wrapper around the {@link DocumentViewerModal} component
 * that handles the logic for navigating within a list of documents.
 */
const DocumentsViewerModal = ({
  documents,
  selectedIndex,
  setSelectedIndex,
}: DocumentsViewerModalProps) => {
  if (isNil(selectedIndex)) {
    return null;
  }

  const selectedDoc = documents[selectedIndex] ?? null;
  if (isNil(selectedDoc)) {
    return null;
  }

  const handleClickBack = () => {
    setSelectedIndex(Math.max(selectedIndex - 1, 0));
  };

  const handleClickForward = () => {
    setSelectedIndex(Math.min(selectedIndex + 1, documents.length - 1));
  };

  const handleClose = () => {
    setSelectedIndex(undefined);
  };

  const hasPrevDoc = selectedIndex > 0;
  const hasNextDoc = selectedIndex < documents.length - 1;

  return (
    <DocumentViewerModal
      showDocViewerModal={!isNil(selectedIndex)}
      onClose={handleClose}
      doc={selectedDoc}
      hasPrevDoc={hasPrevDoc}
      hasNextDoc={hasNextDoc}
      onClickBack={handleClickBack}
      onClickForward={handleClickForward}
    />
  );
};

export { DocumentsViewerModal };
