import { usePresence } from '@ably-labs/react-hooks';
import { useEffect, memo } from 'react';
import { shallow } from 'zustand/shallow';
import useMultiplayerStore from '../multiplayer-store';
import { DispatchUserLocationPayload } from '../types/dispatch';

const DispatchMultiplayerLocationComponent = ({
  companyUuid,
  name,
  email,
}: {
  companyUuid: string;
  name: string;
  email: string;
}) => {
  const [
    dispatchConnectionId,
    setUpdateDispatchUserLocation,
    setDispatchUserPresenceData,
  ] = useMultiplayerStore(
    (state) => [
      state.dispatchConnectionId,
      state.setUpdateDispatchUserLocation,
      state.setDispatchUserPresenceData,
    ],
    shallow,
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [presenceData, updateDispatchUserLocation] =
    usePresence<DispatchUserLocationPayload>(
      { channelName: `${companyUuid}:dispatch:locations` },
      {
        connectionId: dispatchConnectionId,
        name,
        email,
        ts: new Date(),
      },
    );

  useEffect(() => {
    setUpdateDispatchUserLocation(updateDispatchUserLocation);
  }, [updateDispatchUserLocation]);

  useEffect(() => {
    setDispatchUserPresenceData(presenceData);
  }, [presenceData]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default memo(DispatchMultiplayerLocationComponent);
