import {
  Draggable,
  DragDropContext,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Button,
  Typography,
  Stack,
  useTheme,
  Box,
  Alert,
  Snackbar,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { exhaustive } from 'shared/switch';
import { v4 } from 'uuid';
import { isMutationErrorOutput } from '../../../common/utils/utils';
import {
  DispatchTableColorField,
  DispatchTableColorFragment,
  DispatchTableColorUpsertInput,
  UpdateDispatchTableColorsMutation,
  useUpdateDispatchTableColorsMutation,
} from '../../../generated/graphql';
import ColorSettingRow from './color-setting-row';

type DispatchColorSettingsProps = {
  initialColors: DispatchTableColorFragment[];
  loading: boolean;
  onSaved: () => void;
  dispatchViewId?: string;
};

const DispatchColorSettings: FunctionComponent<DispatchColorSettingsProps> = ({
  initialColors,
  loading,
  onSaved,
  dispatchViewId,
}) => {
  const theme = useTheme();
  const [showColorsSavedSnackbar, setShowColorsSavedSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDispatchTableColors, { loading: saving }] =
    useUpdateDispatchTableColorsMutation({
      onCompleted: ({ updateDispatchTableColors: result }) => {
        // Refetch imperatively rather than using refetchQueries because we want to refetch only if the mutation was
        // successful so that we don't lose the user's changes if the mutation fails.
        if (
          !isMutationErrorOutput<
            UpdateDispatchTableColorsMutation['updateDispatchTableColors']
          >(result)
        ) {
          setShowColorsSavedSnackbar(true);
          onSaved();
        } else {
          setErrorMessage(result.message);
        }
      },
    });
  const [dispatchTableColors, setDispatchTableColors] =
    useState<DispatchTableColorFragment[]>(initialColors);

  const handleSave = () => {
    setErrorMessage(null);
    const payload: DispatchTableColorUpsertInput[] = [];
    for (const c of dispatchTableColors) {
      if (c.fields.length === 0) {
        continue;
      }

      let appointmentStartWithinMinutes: number | undefined;
      let appointmentEndWithinMinutes: number | undefined;
      let serviceUuid: string | undefined;
      let hasAppointmentDate: boolean | undefined;
      let isSpecial: boolean | undefined;
      for (const field of c.fields) {
        switch (field) {
          case DispatchTableColorField.AppointmentStartWithin:
            if (isNil(c.appointmentStartWithinMinutes)) {
              setErrorMessage(
                'An appointment start is required for any color rule where an appointment start condition is included',
              );
              return;
            }
            appointmentStartWithinMinutes = c.appointmentStartWithinMinutes;
            break;
          case DispatchTableColorField.AppointmentEndWithin:
            if (isNil(c.appointmentEndWithinMinutes)) {
              setErrorMessage(
                'An appointment end is required for any color rule where an appointment end condition is included',
              );
              return;
            }
            appointmentEndWithinMinutes = c.appointmentEndWithinMinutes;
            break;
          case DispatchTableColorField.Service:
            if (isNil(c.service)) {
              setErrorMessage(
                'A service is required for any color rule where a service condition is included',
              );
              return;
            }
            serviceUuid = c.service.uuid;
            break;
          case DispatchTableColorField.HasAppointmentDate:
            hasAppointmentDate = c.hasAppointmentDate ?? false;
            break;
          case DispatchTableColorField.IsSpecial:
            // For now we only support rules with isSpecial: true without supporting isSpecial: false.
            isSpecial = true;
            break;
          case DispatchTableColorField.AppointmentPassed:
          case DispatchTableColorField.DeadlinePassed:
          case DispatchTableColorField.DeadlineToday:
          case DispatchTableColorField.DeadlineTomorrow:
            break;
          default:
            exhaustive(field);
        }
      }

      payload.push({
        fields: c.fields,
        active: c.active,
        color: c.color,
        appointmentStartWithinMinutes,
        appointmentEndWithinMinutes,
        serviceUuid,
        hasAppointmentDate,
        isSpecial,
        dispatchViewId,
      });
    }
    updateDispatchTableColors({
      variables: {
        input: { dispatchTableColors: payload },
      },
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = dispatchTableColors.slice();
    const [reorderedItem] = items.splice(result.source.index, 1);
    if (!isNil(reorderedItem)) {
      items.splice(result.destination.index, 0, reorderedItem);
      setDispatchTableColors(items);
    }
  };

  return (
    <>
      <Snackbar
        open={showColorsSavedSnackbar}
        autoHideDuration={3_000}
        onClose={() => setShowColorsSavedSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setShowColorsSavedSnackbar(false)}
          severity="success"
        >
          Color settings saved
        </Alert>
      </Snackbar>
      <Box sx={{ height: '450px' }}>
        {!isNil(errorMessage) && (
          <Alert severity="error" sx={{ my: 1 }}>
            {errorMessage}
          </Alert>
        )}
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => {
            if (!saving) {
              setDispatchTableColors([
                {
                  // uuid is used only as a component key and is not sent to the server
                  uuid: v4(),
                  fields: [],
                  active: true,
                  color: 'black',
                },
                ...dispatchTableColors,
              ]);
            }
          }}
        >
          Add a color
        </Button>
        {!loading && isEmpty(dispatchTableColors) && (
          <Typography color="text.secondary">No colors yet.</Typography>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="dispatch-color-settings">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  minHeight:
                    // Each row
                    dispatchTableColors.length * 34 +
                    // Row gap
                    (dispatchTableColors.length - 1) * 17 +
                    // Top padding
                    6,
                  rowGap: '17px',
                  // Padding to not clip focus highlight and floating label.
                  pt: '6px',
                }}
              >
                {dispatchTableColors.map((dispatchTableColor, index) => (
                  <Draggable
                    key={dispatchTableColor.uuid}
                    draggableId={dispatchTableColor.uuid}
                    index={index}
                  >
                    {(prov) => (
                      <Stack
                        key={dispatchTableColor.uuid}
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        direction="row"
                        alignItems="center"
                        gap={1}
                      >
                        <Button
                          {...prov.dragHandleProps}
                          sx={{
                            p: '3px',
                            minWidth: 'max-content',
                          }}
                        >
                          <DragHandleIcon
                            sx={{ color: theme.palette.text.primary }}
                          />
                        </Button>
                        <ColorSettingRow
                          dispatchTableColor={dispatchTableColor}
                          onChange={(newData) => {
                            const updatedDispatchTableColors = [
                              ...dispatchTableColors,
                            ];
                            updatedDispatchTableColors[index] = newData;
                            setDispatchTableColors(updatedDispatchTableColors);
                          }}
                          onDelete={() => {
                            const updatedDispatchTableColors = [
                              ...dispatchTableColors,
                            ];
                            updatedDispatchTableColors.splice(index, 1);
                            setDispatchTableColors(updatedDispatchTableColors);
                          }}
                        />
                      </Stack>
                    )}
                  </Draggable>
                ))}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Stack alignItems="end" mt={2}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={saving}
          sx={{
            flexShrink: 0,
            alignSelf: 'end',
          }}
        >
          Save
        </Button>
      </Stack>
    </>
  );
};

export default DispatchColorSettings;
