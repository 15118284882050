import JsBarcode from 'jsbarcode';
import { isNilOrEmptyString } from './utils';

interface GenerateBarcodeOpts {
  data: string;

  width: number;

  height: number;

  displayValue?: boolean;

  text?: string;
}

export function generateBarcode({
  data,
  width,
  height,
  displayValue = true,
  text,
}: GenerateBarcodeOpts): string | null {
  if (isNilOrEmptyString(data)) {
    return null;
  }
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, data, {
    format: 'CODE128',
    width,
    height,
    displayValue,
    text,
  });
  return canvas.toDataURL('image/png');
}
