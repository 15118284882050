import {
  DriverType,
  OrderStatus,
  PickupOrDelivery,
  ReportAggregationPeriod,
  ReportGroupConfigurationCreateInput,
  ReportRevenueType,
  ReportStatistic,
  ReportType,
} from '../../../generated/graphql';

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export interface ReportGroupConfigurationContact {
  uuid: string;
  displayName: string;
}

export interface ReportGroupConfigurationBusinessDivision {
  uuid: string;
  name: string;
}

export interface ReportGroupConfigurationTerminal {
  uuid: string;
  code: string;
}

export interface ReportGroupConfigurationDriver {
  uuid: string;
  firstName: string;
  lastName: string;
}

export interface ReportGroupConfiguration {
  uuid: string;
  name: string;
  contact?: ReportGroupConfigurationContact | undefined;
  contacts?: ReportGroupConfigurationContact[];
  businessDivision?: ReportGroupConfigurationBusinessDivision | undefined;
  driver?: ReportGroupConfigurationDriver | undefined;
  drivers?: ReportGroupConfigurationDriver[];
  terminal?: ReportGroupConfigurationTerminal | null | undefined;
  orderStatuses: OrderStatus[];
  stopTypes: PickupOrDelivery[];
  reportAggregationPeriod: ReportAggregationPeriod;
  defaultReportType: ReportType;
  reportRevenueType: ReportRevenueType;
  reportStatistics: ReportStatistic[];
  lastNumberOfDays: number | undefined;
  lastNumberOfWeeks: number | undefined;
  lastNumberOfMonths: number | undefined;
  startDate: Date | null;
  endDate: Date | null;
}

export interface OrderReportBucketData {
  startDateLabel: string;
  endDateLabel: string;
  monthLabel: string;
  yearLabel: string;
  startDate?: Date | null;
  endDate?: Date | null;
  id: number;
  freightRevenue: number;
  fuelRevenue: number;
  specialRevenue: number;
  lineHaulRevenue: number;
  surchargeRevenue: number;
  customChargeRevenue: number;
  pickupRevenue: number;
  deliveryRevenue: number;
  recoveryRevenue: number;
  transferRevenue: number;
  totalRevenue: number;
  totalWeight: number;
  numberOfOrders: number;
  numberOfPackages: number;
}

export const initialOrderReportBucketData: OrderReportBucketData = {
  startDateLabel: '',
  endDateLabel: '',
  monthLabel: '',
  yearLabel: '',
  id: 0,
  startDate: null,
  freightRevenue: 0,
  fuelRevenue: 0,
  lineHaulRevenue: 0,
  specialRevenue: 0,
  customChargeRevenue: 0,
  surchargeRevenue: 0,
  pickupRevenue: 0,
  deliveryRevenue: 0,
  recoveryRevenue: 0,
  transferRevenue: 0,
  totalRevenue: 0,
  totalWeight: 0,
  numberOfOrders: 0,
  numberOfPackages: 0,
};

export interface DriverReportBucketData {
  id: number;
  driverName: string;
  driverUuid: string;
  driverType?: DriverType | null | undefined;
  freightRevenue: number;
  fuelRevenue: number;
  specialRevenue: number;
  lineHaulRevenue: number;
  customChargeRevenue: number;
  pickupRevenue: number;
  deliveryRevenue: number;
  recoveryRevenue: number;
  transferRevenue: number;
  totalRevenue: number;
  totalWeight: number;
  numberOfOrders: number;
  numberOfPackages: number;
}

export interface CustomerReportBucketData {
  uuid: string;
  displayName: string;
  freightRevenue: number;
  fuelRevenue: number;
  customChargeRevenue: number;
  specialRevenue: number;
  lineHaulRevenue: number;
  surchargeRevenue: number;
  pickupRevenue: number;
  deliveryRevenue: number;
  recoveryRevenue: number;
  transferRevenue: number;
  totalRevenue: number;
  totalWeight: number;
  numberOfOrders: number;
  numberOfPackages: number;
}

export interface ServiceLevelReportBucketData {
  uuid: string;
  name: string;
  freightRevenue: number;
  fuelRevenue: number;
  customChargeRevenue: number;
  specialRevenue: number;
  lineHaulRevenue: number;
  surchargeRevenue: number;
  pickupRevenue: number;
  deliveryRevenue: number;
  recoveryRevenue: number;
  transferRevenue: number;
  totalRevenue: number;
  totalWeight: number;
  numberOfOrders: number;
  numberOfPackages: number;
}

// export type StationReportQuery = StationReportQuery['stationReport'][number];

export interface StationReportBucketData {
  id: number;
  stationId: string | null | undefined;
  stationName: string;
  freightRevenue: number;
  fuelRevenue: number;
  specialRevenue: number;
  lineHaulRevenue: number;
  surchargeRevenue: number;
  customChargeRevenue: number;
  pickupRevenue: number;
  deliveryRevenue: number;
  recoveryRevenue: number;
  transferRevenue: number;
  totalRevenue: number;
  totalWeight: number;
  numberOfOrders: number;
  numberOfPackages: number;
}

export interface TerminalReportBucketData {
  uuid: string;
  terminalName: string;
  freightRevenue: number;
  fuelRevenue: number;
  customChargeRevenue: number;
  specialRevenue: number;
  lineHaulRevenue: number;
  surchargeRevenue: number;
  pickupRevenue: number;
  deliveryRevenue: number;
  recoveryRevenue: number;
  transferRevenue: number;
  totalRevenue: number;
  totalWeight: number;
  numberOfOrders: number;
  numberOfPackages: number;
}

export const initialCreateInput: ReportGroupConfigurationCreateInput = {
  name: 'Report',
  contactUuid: undefined,
  contactUuids: undefined,
  driverUuid: undefined,
  driverUuids: undefined,
  orderStatuses: [],
  stopTypes: [],
  reportAggregationPeriod: ReportAggregationPeriod.Week,
  defaultReportType: ReportType.Order,
  reportRevenueType: ReportRevenueType.Earned,
  reportStatistics: [
    ReportStatistic.TotalRevenue,
    ReportStatistic.NumberOfOrders,
  ],
  lastNumberOfDays: undefined,
  lastNumberOfWeeks: 2,
  lastNumberOfMonths: undefined,
  startDate: null,
  endDate: null,
  businessDivisionUuid: null,
  terminalUuid: null,
};
