import { Box } from '@mui/material';
import { isNil } from 'lodash';
import React, { useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import useDriverSettlementStore from '../../driver-settlement-store';
import CreateSettlementsModal from './create-settlements-modal';
import StopDriverMapsTable from './stop-driver-maps-table';

const DriverSettlementStops = ({
  shouldRefreshFromUpperComponent,
}: {
  shouldRefreshFromUpperComponent: boolean;
}) => {
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [
    selectedStopsForSettlements,
    setAllSelected,
    setSelectedStopsForSettlements,
  ] = useDriverSettlementStore(
    (state) => [
      state.selectedStopsForSettlements,
      state.setAllSelected,
      state.setSelectedStopsForSettlements,
    ],
    shallow,
  );

  useEffect(() => {
    if (shouldRefreshFromUpperComponent) {
      setShouldRefresh(true);
    }
  }, [shouldRefreshFromUpperComponent]);

  const onClose = (refresh?: boolean) => {
    setSelectedStopsForSettlements(undefined);
    if (refresh === true) {
      setShouldRefresh(true);
      setAllSelected(false);
    }
  };

  return (
    <Box height="100%">
      <StopDriverMapsTable
        shouldRefresh={shouldRefresh}
        setShouldRefresh={setShouldRefresh}
      />
      <CreateSettlementsModal
        isOpen={!isNil(selectedStopsForSettlements)}
        onClose={onClose}
      />
    </Box>
  );
};

export default DriverSettlementStops;
