import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import * as React from 'react';
import { CSSProperties } from 'react';
import useDispatchTableColors from '../../../../common/react-hooks/use-dispatch-table-colors';
import { chooseForegroundColor } from '../../../../common/utils/colors';
import {
  StopFragment,
  StopOnRouteFragment,
} from '../../../../generated/graphql';
import { getDispatchTableColorForStop } from '../../utils';
import { pinTextForStopType } from '../util';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

type PinProps = {
  stop: StopOnRouteFragment | StopFragment;
  isSelected: boolean;
  pinColor: string;
  text?: string;
};

type StopMarkerCircleIconProps = {
  color: string;
  isSelected: boolean;
  text?: string;
  style?: CSSProperties;
};

const StopMarkerCircle = ({
  color,
  isSelected,
  text,
  style,
}: StopMarkerCircleIconProps) => {
  return (
    <Box
      sx={{
        ...style,
        backgroundColor: color,
        zIndex: 10,
        position: 'absolute',
        bottom: 0,
        top: isSelected ? '3.33px' : '2.67px',
        width: isSelected ? '20px' : '17.33px',
        height: isSelected ? '20px' : '17.33px',
        marginLeft: isSelected ? '6.67px' : '4.8px',
        justifyContent: 'center',
        color: chooseForegroundColor(color),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        fontSize: isSelected ? '13.33px' : '10px',
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: 'row',
          zIndex: 100,
          fontSize: '11px',
          marginTop: '1.33px',
          marginLeft: '1.33px',
          color: chooseForegroundColor(color),
        }}
      >
        {text}
        {/* <div style={{ marginTop: '2.5px', marginLeft: '1px' }}>{icon}</div> */}
      </Typography>
    </Box>
  );
};

const CustomPin = ({ stop, isSelected, pinColor, text }: PinProps) => {
  const { dispatchTableColors } = useDispatchTableColors();

  const pinStyle = {
    cursor: 'pointer',
    fill: pinColor,
    stroke: 'none',
  };

  const preSetColor = getDispatchTableColorForStop({
    isSpecial:
      stop.__typename === 'StandardStopEntity'
        ? stop.isSpecial === true
        : stop.stop.isSpecial === true,
    serviceUuid:
      stop.__typename === 'StandardStopEntity'
        ? stop.shipment?.order?.service?.uuid
        : stop.order?.serviceUuid,
    deadlineDate:
      stop.__typename === 'StandardStopEntity'
        ? stop.shipment?.standardShipmentFields?.deadlineDate
        : stop.stop.deadlineDate,
    appointmentDate:
      stop.__typename === 'StandardStopEntity'
        ? stop.shipment?.standardShipmentFields?.deliveryDate
        : stop.stop.deliveryDate,
    appointmentStartTime:
      stop.__typename === 'StandardStopEntity'
        ? stop.appointmentTime
        : stop.stop.appointmentTime,
    appointmentEndTime:
      stop.__typename === 'StandardStopEntity'
        ? stop.endAppointmentTime
        : stop.stop.endAppointmentTime,
    dispatchTableColors: dispatchTableColors ?? [],
  });

  const color = !isNil(preSetColor) ? preSetColor : '#FFFFFF';
  const size = isSelected ? '33.33px' : '26.67px';
  const stopType =
    stop.__typename === 'StandardStopEntity'
      ? stop.stopType
      : stop.stop.stopType;

  return (
    <div
      style={{
        position: 'relative',
        width: size,
        height: size,
      }}
    >
      <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
        <path d={ICON} />
      </svg>
      <StopMarkerCircle
        color={color}
        isSelected={isSelected}
        text={!isNil(text) ? text : pinTextForStopType(stopType)}
      />
    </div>
  );
};

export default React.memo(CustomPin);
