import { useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import TrackingWidget from '../domains/tracking-widget';

export const TrackingWidgetPage = () => {
  const { companyUuid } = useParams();
  if (isNotNilOrEmptyString(companyUuid)) {
    return <TrackingWidget companyUuid={companyUuid} />;
  }
  return null;
};
