import { Box, SxProps } from '@mui/material';
import React from 'react';

export interface TabPanelProps<T> {
  children?: React.ReactNode;
  panelValue: T;
  selectedValue: T;
  sx?: SxProps;
}

/**
 * A generic tab panel
 *
 * Example taking from docs: https://mui.com/material-ui/react-tabs/
 * @param props
 * @constructor
 */
export function PaddingTabPanel<T>(props: TabPanelProps<T>) {
  const { children, panelValue, selectedValue, ...other } = props;
  return (
    <Box
      hidden={selectedValue !== panelValue}
      id={`tab-panel-${panelValue}`}
      aria-labelledby={`tab-panel-${panelValue}`}
      {...other}
      sx={{
        ...other.sx,
        scrollbarGutter: 'stable',
      }}
    >
      {panelValue === selectedValue && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
