import { TablePagination } from '@mui/material';
import useIngestedEmailsStore from '../ingested-emails-store';

const ReviewEmailOrdersTablePagination = ({
  stretchWidth,
}: {
  stretchWidth?: boolean;
}) => {
  const [
    ingestedEmailOrdersData,
    ingestedEmailOrdersLoading,
    ingestedEmailsTableState,
    nextPage,
    prevPage,
    setRowsPerPage,
  ] = useIngestedEmailsStore((state) => [
    state.ingestedEmailOrdersData,
    state.ingestedEmailOrdersLoading,
    state.ingestedEmailsTableState,
    state.nextPage,
    state.prevPage,
    state.setRowsPerPage,
  ]);
  return (
    <TablePagination
      sx={{
        ...(stretchWidth === true && {
          '.MuiTablePagination-toolbar': {
            px: 0,
            justifyContent: 'space-between',
            // ml: 2,
          },
          '.MuiTablePagination-spacer': {
            display: 'none',
          },
          width: '100%',
        }),
        my: -1,
      }}
      rowsPerPageOptions={[]}
      labelRowsPerPage="Show"
      component="div"
      count={ingestedEmailOrdersData?.numberOfScannedOrderResultsInReview ?? 0}
      rowsPerPage={ingestedEmailsTableState.rowsPerPage}
      page={ingestedEmailsTableState.page}
      onPageChange={(e, newPage: number) => {
        if (newPage > ingestedEmailsTableState.page) {
          nextPage();
        } else {
          prevPage();
        }
      }}
      backIconButtonProps={{
        disabled:
          ingestedEmailOrdersLoading === true ||
          ingestedEmailsTableState.page === 0,
      }}
      nextIconButtonProps={{
        disabled:
          ingestedEmailOrdersLoading === true ||
          ingestedEmailOrdersData?.numberOfScannedOrderResultsInReview === 0 ||
          ingestedEmailsTableState.page + 1 ===
            Math.ceil(
              (ingestedEmailOrdersData?.numberOfScannedOrderResultsInReview ??
                0) / ingestedEmailsTableState.rowsPerPage,
            ),
      }}
      onRowsPerPageChange={(e) => {
        setRowsPerPage(+e.target.value);
      }}
    />
  );
};

export default ReviewEmailOrdersTablePagination;
