import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { exhaustive } from 'shared/switch';
import { LineHaulManifestStatus } from '../../../generated/graphql';

const ManifestStatusChip = ({ status }: { status: LineHaulManifestStatus }) => {
  const chipColor = useMemo(() => {
    switch (status) {
      case LineHaulManifestStatus.Planning:
        return '#c77c3c';
      case LineHaulManifestStatus.Departed:
        return '#3ca3c2';
      case LineHaulManifestStatus.Arrived:
        return '#6f7070';
      default:
        return exhaustive(status);
    }
  }, [status]);
  return (
    <Chip
      size="small"
      sx={{
        backgroundColor: chipColor,
        color: 'white',
        fontWeight: 400,
        minWidth: 80,
      }}
      label={status}
    />
  );
};

export default ManifestStatusChip;
