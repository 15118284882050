import AddIcon from '@mui/icons-material/Add';
import { Button, TableCell, TableRow } from '@mui/material';
import currency from 'currency.js';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import { v4 } from 'uuid';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../../../../generated/graphql';
import AddDriverForDriverSettlementModal from '../../../../standard/components/driver-settlement/add-driver-for-driver-settlement-modal';
import { OrderFormValues, StopDriverMapValues } from '../../../forms/types';
import DriverRow from './driver-row';

const DriversTable = ({
  idx,
  isOrderCharge = false,
}: {
  idx: number;
  isOrderCharge?: boolean;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  const { control, setValue } = useFormContext<OrderFormValues>();
  const [showAddDriverModal, setShowAddDriverModal] = useState<boolean>(false);
  const stopUuid = useWatch({ control, name: `stops.${idx}.uuid` });
  const stopDriverMaps: StopDriverMapValues[] =
    useWatch({ control, name: `stops.${idx}.stopDriverMaps` }) ?? [];
  const customCharges = useWatch({
    control,
    name: isOrderCharge
      ? `orderChargesShipment.customCharges`
      : `stops.${idx}.customCharges`,
  });
  const freightChargeSettlement = useWatch({
    control,
    name: `stops.${idx}.freightCharge.deductionTotal`,
  });
  const fuelChargeSettlement = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.deductionTotal`,
  });
  const totalSettlement = (
    customCharges?.reduce(
      (prev, curr) => prev.add(curr.deductionTotal ?? 0),
      currency(0),
    ) ?? currency(0)
  )
    .add(isOrderCharge ? 0 : freightChargeSettlement ?? 0)
    .add(isOrderCharge ? 0 : fuelChargeSettlement ?? 0).value;

  const onAddStopDriver = async (driverUuid: string) => {
    const newStopDriverMap: StopDriverMapValues = {
      uuid: v4(),
      driverUuid,
      stopUuid,
      enableDriverSettlement: true,
      revenuePercentageRate: 0,
      revenueFlatRate: 0,
      driverPayoutFinalized: false,
      isAttempt: false,
      attemptedAt: null,
    };

    setValue(`stops.${idx}.stopDriverMaps`, [
      ...stopDriverMaps.filter(
        (stopDriverMap) =>
          stopDriverMap.driverUuid !== driverUuid ||
          stopDriverMap.stopUuid !== stopUuid,
      ),
      newStopDriverMap,
    ]);
    setShowAddDriverModal(false);
  };

  const driverLabel = ffDemoLoadManagement ? 'Driver/agent' : 'Driver';

  return (
    <>
      <AddDriverForDriverSettlementModal
        isOpen={showAddDriverModal}
        setIsOpen={setShowAddDriverModal}
        onConfirm={onAddStopDriver}
      />
      <TableRow>
        <TableCell sx={{ fontWeight: 500 }}>{driverLabel}</TableCell>
        <TableCell sx={{ fontWeight: 500 }}>Settlement Rate</TableCell>
        <TableCell sx={{ fontWeight: 500 }}>{driverLabel} Total</TableCell>
      </TableRow>
      {stopDriverMaps.map((stopDriverMap) => (
        <DriverRow
          key={stopDriverMap.uuid}
          stopDriverMapUuid={stopDriverMap.uuid}
          stopIdx={idx}
          totalAmountForDriverSettlement={totalSettlement}
        />
      ))}
      <TableRow>
        <TableCell>
          <Button
            onClick={() => setShowAddDriverModal(true)}
            disabled={!canWriteOrders}
            startIcon={<AddIcon />}
          >
            {driverLabel}
          </Button>
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </>
  );
};

export default React.memo(DriversTable);
