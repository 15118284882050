import {
  Box,
  Button,
  Dialog,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import AddressAutocompleteForm from '../../../../common/components/address-autocomplete-form';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  TerminalsDocument,
  useCreateAddressMutation,
  useCreateTerminalMutation,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import { type AddressFormField } from '../../../addresses/redux/addresses-values-slice';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 4,
  } as SxProps,
};

const TerminalsModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [currentAddress, setCurrentAddress] = useState<
    AddressFormField | undefined
  >(undefined);

  const [code, setCode] = useState('');
  const [name, setName] = useState('');

  const [createTerminal] = useCreateTerminalMutation({
    refetchQueries: [TerminalsDocument],
  });
  const { refetchTerminals } = useTerminals({
    includeInactiveTerminals: true,
  });

  const [createAddress] = useCreateAddressMutation();

  const [errorMessage, setErrorMessage] = useState('');

  const handleAddressChange = useCallback(
    (_isAutofillChange: boolean, newAddress?: AddressFormField) => {
      if (!isNil(newAddress)) {
        setCurrentAddress(newAddress);
      } else {
        setCurrentAddress(undefined);
      }
    },
    [],
  );

  const handleReset = () => {
    setOpen(false);
    setCode('');
    setName('');
    setCurrentAddress(undefined);
  };

  const validateInputs = useCallback(() => {
    if (isEmpty(code)) {
      setErrorMessage('Please enter a terminal code.');
      return false;
    }
    if (isEmpty(name)) {
      setErrorMessage('Please enter a terminal name');
      return false;
    }

    if (isNil(currentAddress)) {
      setErrorMessage('Please enter an address.');
      return false;
    }
    return true;
  }, [code, name, currentAddress]);

  const handleSave = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }

    try {
      const { data: addressData } = await createAddress({
        variables: {
          input: {
            addressCreateInput: {
              name: currentAddress?.name ?? '',
              line1: currentAddress?.line1 ?? '',
              city: currentAddress?.city ?? '',
              state: currentAddress?.state ?? '',
              zip: currentAddress?.zip ?? '',
              country: currentAddress?.country ?? '',
            },
          },
        },
      });
      await createTerminal({
        variables: {
          createTerminalInput: {
            terminalCreateInput: {
              code,
              name,
              addressUuid: addressData?.createAddress.uuid ?? '',
            },
          },
        },
      });
      refetchTerminals();
    } catch (err) {
      setErrorMessage(
        'An error occured when submitting the form, please try again.',
      );
      return;
    }
    handleReset();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <Box sx={styles.modalInnerContainer}>
        <Typography variant="h6">Create terminal</Typography>
        <Stack direction="column" gap={theme.spacing(2)}>
          <Stack direction="row" spacing={1}>
            <TextField
              label="Terminal Code"
              helperText="Unique identifier for a terminal. Ex: GSO"
              size="small"
              value={code.toUpperCase()}
              onChange={(e) => {
                setCode(e.target.value.toUpperCase());
                setErrorMessage('');
              }}
            />
            <TextField
              label="Terminal name"
              size="small"
              value={name}
              sx={{ width: '50%' }}
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage('');
              }}
            />
          </Stack>
          <AddressAutocompleteForm
            currentAddress={currentAddress}
            handleChange={handleAddressChange}
          />
        </Stack>
        <Box>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{ float: 'right' }}
          >
            Save
          </Button>
        </Box>
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      </Box>
    </Dialog>
  );
};

export default TerminalsModal;
