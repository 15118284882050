import { InfoOutlined } from '@mui/icons-material';
import { Box, Chip, Stack, styled, Tooltip } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { usePiecesGroupedByStatusQuery } from '../../../../../../generated/graphql';
import { OrderFormValues } from '../../forms/types';

const StyledChip = styled(Chip)`
  color: white;
  border-radius: 2px;
`;

const PieceStatusesTooltip = () => {
  const { control } = useFormContext<OrderFormValues>();
  const orderUuid = useWatch({ control, name: 'uuid' });
  const { data, loading, error } = usePiecesGroupedByStatusQuery({
    variables: {
      piecesGroupedByStatusInput: {
        orderUuid,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (
    loading ||
    isNil(data) ||
    isEmpty(data?.piecesGroupedByStatus.pieceGroups)
  ) {
    return null;
  }
  const getTooltipContent = () => {
    if (!isNil(error)) {
      return 'There was an error loading package statuses';
    }
    return (
      <Stack gap={1}>
        {data.piecesGroupedByStatus.pieceGroups.map((pieceGroup) => (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            gap={1}
            fontSize={14}
            minHeight={24}
            key={`${pieceGroup.terminal?.code}${pieceGroup.status}`}
          >
            <Stack alignItems="center" direction="row" gap={1}>
              <Box>{pieceGroup.pieceCount}</Box>
              <Box>{sentenceCase(pieceGroup.status)}</Box>
            </Stack>
            {!isNil(pieceGroup.terminal?.code) && (
              <StyledChip
                label={pieceGroup.terminal?.code}
                variant="outlined"
                size="small"
              />
            )}
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <Tooltip title={getTooltipContent()}>
      <InfoOutlined color="action" fontSize="small" />
    </Tooltip>
  );
};

export default PieceStatusesTooltip;
