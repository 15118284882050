import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { isEmpty, isNil } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { isTariffBillingMethod } from 'shared/billing';
import { centsToDollars } from 'shared/math';
import { exhaustive } from 'shared/switch';
import {
  FreightChargeFormField,
  InputBillingMethod,
  PackageValues,
} from 'shared/types';
import { calculateTotalVolume, calculateTotalWeight } from 'shared/weight';
import { mapToZipFile } from 'shared/zip/map-to-zip';
import createPagesForPdf from '../../../common/utils/pdf-gen';
import { transformDateTimeToDateString } from '../../../common/utils/prettyPrintUtils';
import {
  isPartnerCarrierDropoff,
  isPartnerCarrierPickup,
  isPartnerCarrierStop,
} from '../../../common/utils/stops';
import {
  getInboundOutboundShipmentsFromOrder,
  getStopType,
  isNilOrEmptyString,
} from '../../../common/utils/utils';
import {
  AppointmentTextStatus,
  CompanyConfigurationFragment,
  CustomerPortalOrderShipmentFragment,
  DocumentType,
  FormattedOrderFragment,
  FreightBillingMethod,
  StopType as GeneratedStopType,
  InboundMethod,
  OrderAction,
  OrderDetailedStatus,
  OrderForBulkEditFragment,
  OrderForCoverSheetFragment,
  OrderForDocumentAttachmentsDownloadFragment,
  OrderForInventoryLabelFragment,
  OrderPodReportShipmentFragment,
  OrderStatus,
  OrderTableField,
  OrderTableFieldHeaderFragment,
  OutboundMethod,
  Segment,
  ShipmentFragment,
  ShipmentType,
  StandardShipmentEntity,
  StopAction,
  StopStatus,
  TariffFragment,
  TariffRangeEntity,
  TariffType,
  TariffZoneType,
} from '../../../generated/graphql';
import { titleCase } from '../../daily-control-center/utils';
import GeneratedCustomerChargesReportPdf from '../../generated-documents/components/generated-customer-charges-report-pdf';
import GeneratedInventoryLabelPdf from '../../generated-documents/components/generated-inventory-label-pdf';
import GeneratedOrderCoverSheetPdf from '../../generated-documents/components/generated-order-cover-sheet-pdf';
import GeneratedPodReportPdf from '../../generated-documents/components/generated-pod-report-pdf';
import GeneratedLotLabelsPdf from '../../generated-documents/components/lot-label/generated-lot-labels-pdf';
import {
  OrderCoverSheetData,
  convertOrderCoverSheetFragmentToCoverSheetData,
} from '../../generated-documents/utils';
import { DocumentAttachments } from '../../invoice-old/components/download-documents';
import { StopValues } from '../../stops/redux/stop-values-slice';
import {
  ORDER_TABLE_FIELD_DEFAULT_VALUE,
  ORDER_TABLE_FIELD_HEADER_DEFAULT_VALUE,
} from './constants';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from './order-form/components/constants';
import { StopType } from './order-form/forms/stop-type';
import { AddressOptionalValues } from './order-form/forms/types';

/**
 * Given a formatted order and an order table field, retrieves the value for the order table field.
 * @param order
 * @param orderTableField
 */
export function getOrderTableFieldValue({
  order,
  orderTableField,
}: {
  order: FormattedOrderFragment | undefined;
  orderTableField: OrderTableField;
}): string {
  if (isNil(order) || isEmpty(order.formattedOrderFields)) {
    return ORDER_TABLE_FIELD_DEFAULT_VALUE;
  }
  const value = order.formattedOrderFields.find(
    (field) => field.orderTableField === orderTableField,
  )?.value;
  return value ?? ORDER_TABLE_FIELD_DEFAULT_VALUE;
}

/**
 * Given a formatted order and an order table field, retrieves the value for the order table field.
 * @param order
 * @param orderTableField
 */
export function getOrderTableFieldHeader({
  orderTableFieldHeaders,
  orderTableField,
}: {
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
  orderTableField: OrderTableField;
}): string {
  if (isNil(orderTableFieldHeaders) || isEmpty(orderTableFieldHeaders)) {
    return ORDER_TABLE_FIELD_HEADER_DEFAULT_VALUE;
  }
  const header = orderTableFieldHeaders.find(
    (orderTableFieldHeader) =>
      orderTableFieldHeader.orderTableField === orderTableField,
  )?.header;
  return header ?? ORDER_TABLE_FIELD_HEADER_DEFAULT_VALUE;
}

export function getOrderDetailedStatusCopy({
  detailedStatus,
  holdReasonName,
  refusedBy,
}: {
  detailedStatus: OrderDetailedStatus | null | undefined;
  holdReasonName?: string | null;
  refusedBy?: string | null;
}): string {
  if (isNil(detailedStatus)) {
    return '';
  }
  switch (detailedStatus) {
    case OrderDetailedStatus.Osd:
      return 'OSD';
    case OrderDetailedStatus.OutForTransfer:
      return 'Out for Transfer';
    case OrderDetailedStatus.OutForRecovery:
      return 'Out for Recovery';
    case OrderDetailedStatus.OutForDelivery:
      return 'Out for Delivery';
    case OrderDetailedStatus.OutForPickup:
      return 'Out for Pickup';
    case OrderDetailedStatus.ReceivedAtOrigin:
      return 'Received at Origin';
    case OrderDetailedStatus.OnHold:
      return `${titleCase(detailedStatus)}${
        !isNil(holdReasonName) ? ` - ${holdReasonName}` : ''
      }`;
    case OrderDetailedStatus.Complete:
      return `${titleCase(detailedStatus)}${
        !isNil(refusedBy) ? ` - refused` : ''
      }`;
    case OrderDetailedStatus.TransferScheduled:
    case OrderDetailedStatus.RecoveryScheduled:
    case OrderDetailedStatus.DeliveryScheduled:
    case OrderDetailedStatus.PickupScheduled:
    case OrderDetailedStatus.Created:
    case OrderDetailedStatus.Creating:
    case OrderDetailedStatus.Picked:
    case OrderDetailedStatus.OnHand:
    case OrderDetailedStatus.Received:
    case OrderDetailedStatus.InProgress:
    case OrderDetailedStatus.OnInvoice:
    case OrderDetailedStatus.InvoicePosted:
    case OrderDetailedStatus.ChargesFinalized:
    case OrderDetailedStatus.BillingIssues:
    case OrderDetailedStatus.Attempted:
    case OrderDetailedStatus.Cancelled:
      return titleCase(detailedStatus);
    default: {
      return exhaustive(detailedStatus);
    }
  }
}

export type ShipmentForDerivingStopType = {
  uuid: string;
  shipmentType: ShipmentType;
  legs: {
    endStop?: {
      stopType?: GeneratedStopType | null;
      inboundMethod?: InboundMethod | null;
      outboundMethod?: OutboundMethod | null;
    } | null;
  }[];
};

export const isPartnerCarryShipment = ({
  shipment,
  stopFromRedux,
}: {
  shipment?: ShipmentForDerivingStopType;
  stopFromRedux?: StopValues;
}) => {
  const stop = shipment?.legs?.[0]?.endStop ?? stopFromRedux;
  return !isNil(stop) ? isPartnerCarrierStop({ stop }) : false;
};

export const getBillableShipments = ({
  shipments,
}: {
  shipments?: CustomerPortalOrderShipmentFragment[];
}) => {
  return shipments?.filter(
    (shipment) =>
      !shipment.hideFromBilling &&
      (shipment.shipmentType === ShipmentType.LineHaul ||
        shipment.shipmentType === ShipmentType.OrderCharges ||
        (!isNil(shipment?.legs[0]?.endStop.stopType) &&
          [
            GeneratedStopType.Pickup,
            GeneratedStopType.Delivery,
            GeneratedStopType.Recovery,
            GeneratedStopType.Transfer,
          ].includes(shipment?.legs[0]?.endStop.stopType))),
  );
};

export const getPrimaryShipmentUuids = ({
  shipments,
}: {
  shipments: ShipmentForDerivingStopType[];
}): {
  delivery: ShipmentForDerivingStopType | undefined;
  pickup: ShipmentForDerivingStopType | undefined;
  recovery: ShipmentForDerivingStopType | undefined;
  transfer: ShipmentForDerivingStopType | undefined;
} => {
  const delivery = shipments.find((shipment) => {
    const stop = shipment?.legs[0]?.endStop;
    return (
      !isNil(stop) &&
      stop.stopType === GeneratedStopType.Delivery &&
      !isPartnerCarrierPickup({ stop }) &&
      shipment.shipmentType === ShipmentType.Regular
    );
  });
  const pickup = shipments.find((shipment) => {
    const stop = shipment?.legs[0]?.endStop;
    return (
      !isNil(stop) &&
      stop.stopType === GeneratedStopType.Pickup &&
      !isPartnerCarrierDropoff({ stop }) &&
      shipment.shipmentType === ShipmentType.Regular
    );
  });
  const recovery = shipments.find(
    (shipment) =>
      shipment?.legs[0]?.endStop?.stopType === GeneratedStopType.Recovery &&
      shipment.shipmentType === ShipmentType.Regular,
  );
  const transfer = shipments.find(
    (shipment) =>
      shipment?.legs[0]?.endStop?.stopType === GeneratedStopType.Transfer &&
      shipment.shipmentType === ShipmentType.Regular,
  );
  return { delivery, pickup, recovery, transfer };
};

const getInboundStopTypeFromShipments = (
  pickupShipment?: { uuid: string },
  recoveryShipment?: { uuid: string },
  pcdShipment?: { uuid: string },
): StopType | undefined => {
  if (!isNil(pickupShipment) && pickupShipment?.uuid !== pcdShipment?.uuid) {
    return StopType.Pickup;
  }
  if (!isNil(pickupShipment) && pickupShipment?.uuid === pcdShipment?.uuid) {
    return StopType.PartnerCarrierDropoff;
  }
  if (isNil(pickupShipment) && !isNil(recoveryShipment)) {
    return StopType.Recovery;
  }

  return undefined;
};

const getOutboundStopTypeFromShipments = (
  deliveryShipment?: { uuid: string },
  transferShipment?: { uuid: string },
  pcpShipment?: { uuid: string },
): StopType | undefined => {
  if (
    !isNil(deliveryShipment) &&
    deliveryShipment?.uuid !== pcpShipment?.uuid
  ) {
    return StopType.Delivery;
  }
  if (
    !isNil(deliveryShipment) &&
    deliveryShipment?.uuid === pcpShipment?.uuid
  ) {
    return StopType.PartnerCarrierPickup;
  }
  if (isNil(deliveryShipment) && !isNil(transferShipment)) {
    return StopType.Transfer;
  }

  return undefined;
};

const getInboundOrOutboundStopIndex = (
  shipment: Pick<
    ShipmentFragment,
    'standardShipmentFields' | 'shipmentType' | 'legs'
  >,
): number => {
  const stopType = getStopType(
    shipment?.legs[0]?.endStop.stopType,
    shipment.legs[0]?.endStop.inboundMethod,
    shipment.legs[0]?.endStop.outboundMethod,
  );

  switch (stopType) {
    case GeneratedStopType.Delivery:
    case GeneratedStopType.Transfer:
    case GeneratedStopType.PartnerCarrierPickup: {
      return OUTBOUND_STOP_IDX;
    }
    case GeneratedStopType.PartnerCarrierDropoff:
    case GeneratedStopType.Pickup:
    case GeneratedStopType.Recovery:
    case GeneratedStopType.None: {
      return INBOUND_STOP_IDX;
    }
    default:
      return exhaustive(stopType);
  }
};

export const getShipmentForStopType = (
  order: {
    shipments?: Pick<
      ShipmentFragment,
      'standardShipmentFields' | 'shipmentType' | 'legs'
    >[];
  },
  stopIndex: typeof INBOUND_STOP_IDX | typeof OUTBOUND_STOP_IDX,
): Pick<
  ShipmentFragment,
  'standardShipmentFields' | 'shipmentType' | 'legs'
> | null => {
  const shipments =
    order?.shipments?.filter(
      (shipment) => shipment.shipmentType === ShipmentType.Regular,
    ) ?? [];
  if (isEmpty(shipments)) {
    return null;
  }
  if (shipments.length === 1 && shipments[0]) {
    const inboundOrOutbound = getInboundOrOutboundStopIndex(shipments[0]);
    if (inboundOrOutbound === stopIndex) {
      return shipments[0];
    }
    return null;
  }
  if (shipments.length === 2) {
    return shipments[stopIndex] ?? null;
  }
  return null;
};

export const terminalOnlyOrderFields = [
  OrderTableField.OriginTerminal,
  OrderTableField.DestinationTerminal,
];

export const deprecatedOrderFields = [
  // Replaced by OrderServiceDate
  OrderTableField.PrimaryServiceDate,
];

export const transformAppointmentStatus = (appointmentStatus: string) => {
  switch (appointmentStatus) {
    case AppointmentTextStatus.NotSent:
      return 'Not scheduled';
    case AppointmentTextStatus.Sent:
      return 'Pending';
    case AppointmentTextStatus.Rejected:
      return 'Rejected';
    case AppointmentTextStatus.Confirmed:
      return 'Confirmed';
    default:
      return 'N/A';
  }
};

export const calculateTariffRate = ({
  tariff,
  quantity,
  miles,
}: {
  tariff: TariffFragment | undefined;
  quantity: number;
  miles?: number | null | undefined;
}) => {
  let firstTariffRateLargerIndex;
  if (
    tariff?.tariffZone?.type === TariffZoneType.Miles &&
    tariff?.tariffType === TariffType.PerCubicFoot
  ) {
    firstTariffRateLargerIndex = tariff.tariffRanges.findIndex((range) => {
      return range.lessThanOrEqualToValue >= (miles ?? 0);
    });
  } else {
    firstTariffRateLargerIndex = tariff?.tariffRanges.findIndex((range) => {
      return range.lessThanOrEqualToValue >= quantity;
    });
  }

  // If there is no tariff rate larger, then use the upper limit
  if (
    firstTariffRateLargerIndex === -1 ||
    isNil(tariff) ||
    isNil(firstTariffRateLargerIndex)
  ) {
    const lastIndex = (tariff?.tariffRanges?.length ?? 0) - 1;
    if (lastIndex !== undefined && lastIndex > 0) {
      return centsToDollars(
        tariff?.tariffRanges?.[lastIndex]?.rateUsdCents ?? 0,
      );
    }
    return 0;
  }

  // If the first tariff rate is larger than the quantity, then use the first rate
  // First tariff ranges should start at 0 so this is not a well formed tariff
  if (firstTariffRateLargerIndex === 0) {
    const tariffRange = tariff.tariffRanges[0] as TariffRangeEntity;
    return centsToDollars(tariffRange.rateUsdCents);
  }

  const tariffRange = tariff.tariffRanges[
    firstTariffRateLargerIndex
  ] as TariffRangeEntity;
  return centsToDollars(tariffRange.rateUsdCents);
};

export const calculateQuantityForOrder = (
  packages: PackageValues[],
  freightBillingMethod: InputBillingMethod | null,
  tariff: TariffFragment | undefined,
  freightChargeValues: FreightChargeFormField | undefined,
  dimFactor: number | undefined,
  overridePackageWeight: boolean | undefined,
) => {
  const totalWeight = calculateTotalWeight(
    packages,
    dimFactor ?? undefined,
    tariff?.useActualWeight ?? false,
  );
  const totalPieces = packages.reduce(
    (acc, pkg) => acc + (pkg?.quantity ?? 0),
    0,
  );
  const totalVolume = calculateTotalVolume({ packages });

  if (
    freightBillingMethod === FreightBillingMethod.Weight ||
    (isTariffBillingMethod(freightBillingMethod) === true &&
      tariff?.tariffType === TariffType.PerHundredPounds)
  ) {
    if (overridePackageWeight === true) {
      return freightChargeValues?.quantity ?? totalWeight;
    }
    return totalWeight;
  }
  if (
    tariff?.tariffType === TariffType.PerPiece ||
    freightBillingMethod === FreightBillingMethod.PerPiece
  ) {
    return totalPieces;
  }
  if (freightBillingMethod === FreightBillingMethod.PerMile) {
    return freightChargeValues?.quantity ?? 0;
  }
  if (
    isTariffBillingMethod(freightBillingMethod) &&
    tariff?.tariffType === TariffType.PerCubicFoot
  ) {
    return totalVolume;
  }
  return freightChargeValues?.quantity ?? 1;
};

const orderStatusesThatCanBeOnHold = [
  OrderStatus.Cancelled,
  OrderStatus.Created,
  OrderStatus.InProgress,
];

export const getFilteredOrdersForOrderAction = (
  orders: OrderForBulkEditFragment[],
  action: OrderAction | undefined,
  // eslint-disable-next-line consistent-return
) => {
  switch (action) {
    case OrderAction.MarkAllPiecesPicked:
      return orders;
    case OrderAction.MarkAsCancelled:
      return orders.filter((order) => order.status !== OrderStatus.Cancelled);
    case OrderAction.MarkAsOnHand:
      return orders.filter((order) => order.onHand !== true);
    case OrderAction.MarkAsOnHold:
      return orders.filter((order) =>
        orderStatusesThatCanBeOnHold.includes(order.status),
      );
    case OrderAction.RemoveHold:
      return orders.filter((order) => order.status === OrderStatus.OnHold);
    case OrderAction.Uncancel:
      return orders.filter((order) => order.status === OrderStatus.Cancelled);
    default:
      return [];
  }
};

export const getFilteredOrdersForStopAction = (
  orders: OrderForBulkEditFragment[],
  stopTypes: GeneratedStopType[] | undefined,
  action: StopAction | undefined,
  // eslint-disable-next-line consistent-return
) => {
  switch (action) {
    case StopAction.MarkAttempted:
      return orders.filter((order) =>
        order.shipments.some((shipment) => {
          const stopType = shipment?.legs[0]?.endStop.stopType;
          return !isNil(stopType) && stopTypes?.includes(stopType);
        }),
      );
    case StopAction.MarkComplete:
      return orders.filter((order) =>
        order.shipments.some((shipment) => {
          const stopType = shipment?.legs[0]?.endStop.stopType;
          return (
            shipment.legs[0]?.endStop.status !== StopStatus.Completed &&
            !isNil(stopType) &&
            stopTypes?.includes(stopType)
          );
        }),
      );
    case StopAction.MarkIncomplete:
      return orders.filter((order) =>
        order.shipments.some((shipment) => {
          const stopType = shipment?.legs[0]?.endStop.stopType;
          return !isNil(stopType) && stopTypes?.includes(stopType);
        }),
      );
    default:
      return [];
  }
};

function saveByteArray(reportName: string, byte: Uint8Array) {
  const blob = new Blob([byte], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = reportName;
  link.click();
}

export const getPodReportBlob = async (
  segment: Segment | undefined,
  shipment: OrderPodReportShipmentFragment,
  attachments: DocumentAttachments,
  showCharges: boolean,
) => {
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedPodReportPdf
      segment={segment}
      shipments={[shipment]}
      showCharges={showCharges}
    />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const { documents } = shipment;
  if (!isNil(documents) && attachments !== DocumentAttachments.NoAttachments) {
    for (let i = 0; i < documents.length; i += 1) {
      const doc = documents[i];
      if (
        !isNil(doc) &&
        (attachments === DocumentAttachments.IncludeAll ||
          doc.type === DocumentType.ProofOfDeliveryScanned)
      ) {
        const { preSignedGetUrl, fileType } = doc;
        // eslint-disable-next-line no-await-in-loop
        const buffer = await fetch(preSignedGetUrl, { cache: 'no-cache' })
          .then((res) => res.arrayBuffer())
          .catch(() => 'error');
        if (!isNil(buffer) && typeof buffer !== 'string') {
          const directlyAttachPdfPagesUsingCopy =
            fileType === 'application/pdf' &&
            [
              DocumentType.DigitalProofOfDelivery,
              DocumentType.ProofOfDelivery,
              DocumentType.ProofOfDeliveryScanned,
              DocumentType.DigitalProofOfPickup,
            ].includes(doc.type);

          // eslint-disable-next-line no-await-in-loop
          await createPagesForPdf(
            buffer,
            fileType,
            pdfDoc,
            directlyAttachPdfPagesUsingCopy,
          );
        }
      }
    }
  }

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], {
    type: 'application/pdf',
  });
};

export const getCoverSheetBlob = async (
  segment: Segment | undefined,
  companyConfiguration: CompanyConfigurationFragment | null | undefined,
  order: OrderForCoverSheetFragment,
  terminalsEnabled: boolean,
  logisticsEnabled: boolean,
) => {
  const pdfDoc = await PDFDocument.create();
  const orderCoverSheetData: OrderCoverSheetData | null =
    convertOrderCoverSheetFragmentToCoverSheetData({
      order,
      terminalsEnabled,
      logisticsEnabled,
    });

  if (!isNil(orderCoverSheetData)) {
    const blob = await pdf(
      <GeneratedOrderCoverSheetPdf
        segment={segment}
        companyConfiguration={companyConfiguration}
        order={orderCoverSheetData}
      />,
    ).toBlob();
    await createPagesForPdf(
      await blob.arrayBuffer(),
      'application/pdf',
      pdfDoc,
    );
  }
  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], {
    type: 'application/pdf',
  });
};

export const getCombinedCoverSheetBlob = async (
  segment: Segment | undefined,
  companyConfiguration: CompanyConfigurationFragment | null | undefined,
  orders: OrderForCoverSheetFragment[],
  terminalsEnabled: boolean,
  logisticsEnabled: boolean,
) => {
  const pdfDoc = await PDFDocument.create();
  for (const order of orders) {
    const orderCoverSheetData: OrderCoverSheetData | null =
      convertOrderCoverSheetFragmentToCoverSheetData({
        order,
        terminalsEnabled,
        logisticsEnabled,
      });
    if (!isNil(orderCoverSheetData)) {
      const blob = await pdf(
        <GeneratedOrderCoverSheetPdf
          segment={segment}
          companyConfiguration={companyConfiguration}
          order={orderCoverSheetData}
        />,
      ).toBlob();
      await createPagesForPdf(
        await blob.arrayBuffer(),
        'application/pdf',
        pdfDoc,
      );
    }
  }
  return pdfDoc.save();
};

const getInventoryLabelBlob = async (order: OrderForInventoryLabelFragment) => {
  const pickupShipment = order.shipments.find(
    (shipment) =>
      shipment?.legs[0]?.endStop?.stopType === GeneratedStopType.Pickup,
  );
  const recoveryShipment = order.shipments.find(
    (shipment) =>
      shipment?.legs[0]?.endStop?.stopType === GeneratedStopType.Recovery,
  );
  const pcdShipment = order.shipments.find((shipment) =>
    isPartnerCarrierDropoff({
      stop: {
        stopType: shipment?.legs[0]?.endStop.stopType,
        inboundMethod: shipment.legs[0]?.endStop.inboundMethod,
        outboundMethod: shipment.legs[0]?.endStop.outboundMethod,
      },
    }),
  );

  const deliveryShipment = order.shipments.find(
    (shipment) =>
      shipment?.legs[0]?.endStop?.stopType === GeneratedStopType.Delivery,
  );
  const transferShipment = order.shipments.find(
    (shipment) =>
      shipment?.legs[0]?.endStop?.stopType === GeneratedStopType.Transfer,
  );
  const pcpShipment = order.shipments.find((shipment) =>
    isPartnerCarrierPickup({
      stop: {
        stopType: shipment?.legs[0]?.endStop.stopType,
        inboundMethod: shipment.legs[0]?.endStop.inboundMethod,
        outboundMethod: shipment.legs[0]?.endStop.outboundMethod,
      },
    }),
  );

  const transferStop = transferShipment?.legs[0]?.endStop;

  let airportInfo;
  if (!isNil(transferShipment)) {
    airportInfo = transferShipment.airportInfo;
  }

  const inboundStopType = getInboundStopTypeFromShipments(
    pickupShipment as StandardShipmentEntity,
    recoveryShipment as StandardShipmentEntity,
    pcdShipment as StandardShipmentEntity,
  );
  const outboundStopType = getOutboundStopTypeFromShipments(
    deliveryShipment as StandardShipmentEntity,
    transferShipment as StandardShipmentEntity,
    pcpShipment as StandardShipmentEntity,
  );

  const pcdCarrier = pcdShipment?.legs[0]?.endStop?.incomingCarrier;
  const pcpCarrier = pcpShipment?.legs[0]?.endStop?.outboundCarrier;

  const outboundFlightOriginCode = airportInfo?.iataCode;
  const outboundFlightDestinationCode =
    transferStop?.destinationAirport ?? undefined;

  const blob = await pdf(
    <GeneratedInventoryLabelPdf
      companyName={order.company.name}
      contact={order.billingPartyContact.displayName}
      orderName={order.name}
      hawb={order.standardOrderFields.shipperBillOfLadingNumber ?? ''}
      mawb={order.standardOrderFields.masterAirwayBillOfLadingNumber ?? ''}
      packages={order.packages}
      inboundStopType={inboundStopType}
      outboundStopType={outboundStopType}
      recoveryAddress={
        (recoveryShipment?.legs[0]?.endStop.address as AddressOptionalValues) ??
        undefined
      }
      pcdCarrier={pcdCarrier ?? ''}
      pcpCarrier={pcpCarrier ?? ''}
      shipper={
        (pickupShipment?.legs[0]?.endStop.address as AddressOptionalValues) ??
        undefined
      }
      consignee={deliveryShipment?.legs[0]?.endStop.address.name ?? ''}
      outboundFlightAirline={airportInfo?.terminal}
      outboundFlightOriginCode={outboundFlightOriginCode}
      outboundFlightDestinationCode={outboundFlightDestinationCode}
      widthDimension={6}
      heightDimension={4}
    />,
  ).toBlob();
  return blob;
};

export const getCombinedInventoryLabelBlob = async (
  orders: OrderForInventoryLabelFragment[],
) => {
  const pdfDoc = await PDFDocument.create();
  await Promise.all(
    orders.map(async (order) => {
      const blob = await getInventoryLabelBlob(order);
      const doc = await PDFDocument.load(await blob.arrayBuffer());
      const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());
      copiedPages.forEach((copiedPage) => pdfDoc.addPage(copiedPage));
    }),
  );

  return pdfDoc.save();
};

export const getSingleInventoryLabelBlob = async (
  order: OrderForInventoryLabelFragment,
) => {
  const pdfDoc = await PDFDocument.create();
  const blob = await getInventoryLabelBlob(order);
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], {
    type: 'application/pdf',
  });
};

export const getPodReportBlobs = async (
  segment: Segment | undefined,
  shipments: OrderPodReportShipmentFragment[],
) => {
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedCustomerChargesReportPdf
      segment={segment}
      shipments={shipments}
    />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], {
    type: 'application/pdf',
  });
};

export const downloadCustomerChargesPodReport = async (
  segment: Segment | undefined,
  shipments: OrderPodReportShipmentFragment[] | undefined,
  contactName: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  if (isNil(shipments)) return;

  let dateString = '';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `-${transformDateTimeToDateString(
      startDate.toISOString(),
    )}-${transformDateTimeToDateString(endDate.toISOString())}`;
  } else if (!isNil(startDate) || !isNil(endDate)) {
    dateString = `-${transformDateTimeToDateString(
      (startDate ?? endDate)?.toISOString(),
    )}`;
  }
  const blob = await getPodReportBlobs(segment, shipments);
  saveAs(blob, `${contactName}-charges-report${dateString}.pdf`);
};

export const downloadMultiplePodReport = async (
  segment: Segment | undefined,
  shipments: OrderPodReportShipmentFragment[] | undefined,
  attachments: DocumentAttachments,
  showCharges: boolean,
) => {
  if (isNil(shipments)) return;

  const content = await mapToZipFile({
    data: shipments,
    mapper: async (shipment) => {
      const blob = await getPodReportBlob(
        segment,
        shipment,
        attachments,
        showCharges,
      );

      const displayName =
        shipment.order?.billingPartyContact.displayName ?? 'No Name';
      const fileName = `${shipment.order?.standardOrderFields.shipperBillOfLadingNumber}-${shipment?.legs[0]?.endStop.stopType ?? 'stop'}.pdf`;
      return {
        path: [displayName, fileName],
        blob,
      };
    },
  });

  saveAs(content, 'pod-reports.zip');
};

export const downloadMultipleDocumentAttachments = async (
  orders: OrderForDocumentAttachmentsDownloadFragment[],
  documentTypes: DocumentType[],
) => {
  const documents = orders.flatMap((order) => order.documents);

  const pdfDoc = await PDFDocument.create();
  for (let i = 0; i < documents.length; i += 1) {
    const doc = documents[i];
    if (!isNil(doc) && documentTypes.includes(doc.type)) {
      const { preSignedGetUrl, fileType } = doc;
      // eslint-disable-next-line no-await-in-loop
      const buffer = await fetch(preSignedGetUrl, { cache: 'no-cache' })
        .then((res) => res.arrayBuffer())
        .catch(() => 'error');
      if (!isNil(buffer) && typeof buffer !== 'string') {
        // Documents with e-signatures need to be attached directly
        // otherwise the e-signatures disappear
        const directlyAttachPdfPagesUsingCopy =
          fileType === 'application/pdf' &&
          [
            DocumentType.DeliveryReceipt,
            DocumentType.PickupReceiptForEsign,
          ].includes(doc.type);
        // eslint-disable-next-line no-await-in-loop
        await createPagesForPdf(
          buffer,
          fileType,
          pdfDoc,
          directlyAttachPdfPagesUsingCopy,
        );
      }
    }
  }

  saveByteArray('unsigned-pods.pdf', await pdfDoc.save());
};

export const downloadMultipleCoverSheets = async (
  segment: Segment | undefined,
  companyConfiguration: CompanyConfigurationFragment | null | undefined,
  orders: OrderForCoverSheetFragment[],
  terminalsEnabled: boolean,
  logisticsEnabled: boolean,
  combinedCoverSheets?: boolean,
  name?: string,
) => {
  if (combinedCoverSheets === true) {
    const blob = await getCombinedCoverSheetBlob(
      segment,
      companyConfiguration,
      orders,
      terminalsEnabled,
      logisticsEnabled,
    );
    saveByteArray(`${!isEmpty(name) ? name : 'cover-sheet'}.pdf`, blob);
    return;
  }

  const content = await mapToZipFile({
    data: orders,
    mapper: async (order) => {
      const blob = await getCoverSheetBlob(
        segment,
        companyConfiguration,
        order,
        terminalsEnabled,
        logisticsEnabled,
      );

      return {
        path: [
          order.billingPartyContact.displayName,
          `${order.standardOrderFields.shipperBillOfLadingNumber}.pdf`,
        ],
        blob,
      };
    },
  });

  saveAs(content, `${!isEmpty(name) ? name : 'cover-sheets'}.zip`);
};

export const downloadCoverSheet = async (
  segment: Segment | undefined,
  companyConfiguration: CompanyConfigurationFragment | null | undefined,
  order: OrderForCoverSheetFragment | undefined,
  terminalsEnabled: boolean,
  logisticsEnabled: boolean,
) => {
  if (isNil(order)) return;
  const blob = await getCoverSheetBlob(
    segment,
    companyConfiguration,
    order,
    terminalsEnabled,
    logisticsEnabled,
  );

  if (!isNil(blob)) {
    const fileURL = window.URL.createObjectURL(blob);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `order-cover-sheet-${order.standardOrderFields.shipperBillOfLadingNumber}.pdf`;
    alink.click();
  }
};

export const downloadMultipleOutboundLabels = async (
  orders: OrderForInventoryLabelFragment[] | undefined,
  combinedLabels: boolean,
) => {
  if (isNil(orders)) return;
  if (combinedLabels) {
    const blob = await getCombinedInventoryLabelBlob(orders);
    saveByteArray('outbound-labels.pdf', blob);
    return;
  }

  const content = await mapToZipFile({
    data: orders,
    mapper: async (order) => {
      const blob = await getSingleInventoryLabelBlob(order);
      return {
        path: [
          order.billingPartyContact.displayName,
          `${order.standardOrderFields.shipperBillOfLadingNumber}.pdf`,
        ],
        blob,
      };
    },
  });
  saveAs(content, 'inventory-labels.zip');
};

export const getReferenceNumberLabels = ({
  companyReferenceNumberLabels = [],
  contactReferenceNumberLabels = [],
}: {
  companyReferenceNumberLabels?: { name: string }[];
  contactReferenceNumberLabels?: { name: string }[];
}) => {
  // opt for company labels if the contact labels are all empty
  if (
    !isNil(contactReferenceNumberLabels) &&
    !isEmpty(contactReferenceNumberLabels) &&
    contactReferenceNumberLabels.some(
      (label) => !isNilOrEmptyString(label.name.trim()),
    )
  ) {
    return contactReferenceNumberLabels.map((label) => label.name);
  }
  return companyReferenceNumberLabels.map((label) => label.name);
};

export const formatReferenceNumbersForLotLabel = ({
  refNumbers,
  referenceNumberLabels,
}: {
  refNumbers: string[];
  referenceNumberLabels: string[];
}) => {
  return refNumbers.map((refNumber, index) => {
    const label =
      referenceNumberLabels[index]?.slice(0, 6) ?? `REF${index + 1}`;
    return { label, refNumber: refNumber.toUpperCase() };
  });
};

export const downloadMultipleLotLabels = async (
  orders: OrderForInventoryLabelFragment[] | undefined,
  ffAppendPieceIdToLotLabel: boolean,
) => {
  if (isNil(orders)) return;

  const pdfDoc = await PDFDocument.create();

  for (const order of orders) {
    if (!isNil(order)) {
      const pickupShipment = order.shipments.find(
        (sh) =>
          sh.legs[0]?.endStop != null &&
          sh.legs[0]?.endStop.stopType === GeneratedStopType.Pickup,
      );
      const deliveryShipment = order.shipments.find(
        (sh) =>
          sh.legs[0]?.endStop != null &&
          sh.legs[0]?.endStop.stopType === GeneratedStopType.Delivery,
      );
      const recoveryShipment = order.shipments.find(
        (sh) =>
          sh.legs[0]?.endStop != null &&
          sh.legs[0]?.endStop.stopType === GeneratedStopType.Recovery,
      );
      const transferShipment = order.shipments.find(
        (sh) =>
          sh.legs[0]?.endStop != null &&
          sh.legs[0]?.endStop.stopType === GeneratedStopType.Transfer,
      );
      const pcdShipment = order.shipments.find(
        (sh) =>
          sh.legs[0]?.endStop != null &&
          sh.legs[0]?.endStop.stopType ===
            GeneratedStopType.PartnerCarrierDropoff,
      );
      const pcpShipment = order.shipments.find(
        (sh) =>
          sh.legs[0]?.endStop != null &&
          sh.legs[0]?.endStop.stopType ===
            GeneratedStopType.PartnerCarrierPickup,
      );
      const consigneeAddress = order.shipments.find((sh) =>
        (
          [
            GeneratedStopType.Delivery,
            GeneratedStopType.PartnerCarrierPickup,
          ] as (GeneratedStopType | null | undefined)[]
        ).includes(sh.legs[0]?.endStop.stopType),
      );
      const { inboundShipment, outboundShipment } =
        getInboundOutboundShipmentsFromOrder(order);
      const totalLotLabels = order.packages.reduce(
        (acc, pkg) => acc + (pkg?.quantity ?? 0),
        0,
      );
      const referenceNumberLabels = getReferenceNumberLabels({
        companyReferenceNumberLabels: order.company.referenceNumberLabels,
        contactReferenceNumberLabels:
          order.billingPartyContact.referenceNumberLabels,
      });
      const formattedRefNumbers = formatReferenceNumbersForLotLabel({
        refNumbers: order.refNumbers,
        referenceNumberLabels,
      });
      const lotLabelBlob = await pdf(
        <GeneratedLotLabelsPdf
          companyName={order.company.name}
          contact={order.billingPartyContact.displayName}
          orderName={order.name}
          mawb={order.standardOrderFields.masterAirwayBillOfLadingNumber ?? '-'}
          hawb={order.standardOrderFields.shipperBillOfLadingNumber ?? '-'}
          shipperAddress={pickupShipment?.legs[0]?.endStop.address}
          recoveryAddress={recoveryShipment?.legs[0]?.endStop.address}
          pcdCarrier={
            pcdShipment?.legs[0]?.endStop.incomingCarrier ?? undefined
          }
          pcpCarrier={
            pcpShipment?.legs[0]?.endStop?.outboundCarrier ?? undefined
          }
          // outboundFlightAirline={getRecoveryLocationInfo(transferStop?.airportInfoUuid)}
          consigneeAddress={consigneeAddress}
          packages={order.packages}
          inboundStopType={getInboundStopTypeFromShipments(
            pickupShipment,
            recoveryShipment,
            pcdShipment,
          )}
          outboundStopType={getOutboundStopTypeFromShipments(
            deliveryShipment,
            transferShipment,
            pcpShipment,
          )}
          defaultWeightUnits={order.company.defaultWeightUnits}
          originTerminalCode={inboundShipment?.legs[0]?.endStop?.terminal?.code}
          outboundFlightOriginCode={transferShipment?.airportInfo?.iataCode}
          destinationTerminalCode={
            outboundShipment?.legs[0]?.endStop?.terminal?.code
          }
          destinationDetails={
            transferShipment?.legs[0]?.endStop?.destinationAirport ??
            pcpShipment?.legs[0]?.endStop?.destinationAirport ??
            undefined
          }
          widthDimension={3}
          heightDimension={4}
          rotate
          terminalsEnabled={order.company.configuration?.terminalsEnabled}
          totalLotLabels={totalLotLabels}
          appendPieceIdToLotLabel={ffAppendPieceIdToLotLabel}
          refNumbers={formattedRefNumbers}
        />,
      ).toBlob();

      const doc = await PDFDocument.load(await lotLabelBlob.arrayBuffer());
      const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());
      copiedPages.forEach((copiedPage) => pdfDoc.addPage(copiedPage));
    }
  }
  const pdfBytes = await pdfDoc.save();
  saveByteArray('lot-labels.pdf', pdfBytes);
};
