import { FormControl, TextField, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useWarehouseLocationsQuery } from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { AutocompleteOption } from '../../types';

interface WarehouseLocationSelectorProps {
  selectedWarehouseLocationUuid: string | null | undefined;
  selectedWarehouseLocationLabel?: string | null;
  onChange: (value: AutocompleteOption) => void;
  warehouseUuid?: string;
  disabled?: boolean;
  error?: string;
  inputLabel?: string; // i.e. "Location"
}

const WarehouseLocationSelector = ({
  selectedWarehouseLocationUuid,
  selectedWarehouseLocationLabel,
  onChange,
  warehouseUuid,
  disabled,
  error,
  inputLabel,
}: WarehouseLocationSelectorProps) => {
  const theme = useTheme();

  const { loading, data: warehouseLocationsData } = useWarehouseLocationsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      warehouseUuid,
    },
  });

  const warehouseLocationOptions = useMemo(
    () =>
      warehouseLocationsData?.warehouseLocations.warehouseLocations.map(
        (warehouseLocation) => ({
          value: warehouseLocation.uuid,
          label: warehouseLocation.name,
        }),
      ) ?? [],
    [warehouseLocationsData],
  );

  const selectedValue = useMemo(() => {
    if (isNil(selectedWarehouseLocationUuid)) {
      return undefined;
    }

    const label =
      selectedWarehouseLocationLabel ??
      warehouseLocationOptions.find(
        (option) => option.value === selectedWarehouseLocationUuid,
      )?.label;
    return {
      value: selectedWarehouseLocationUuid,
      label: label ?? '',
    };
  }, [
    selectedWarehouseLocationUuid,
    selectedWarehouseLocationLabel,
    warehouseLocationOptions,
  ]);

  return (
    <FormControl
      sx={{ minWidth: theme.spacing(15) }}
      required
      error={!isNil(error)}
    >
      <AutocompleteFuzzy
        disabled={disabled}
        size="small"
        value={selectedValue}
        disableClearable
        isOptionEqualToValue={(
          option1: AutocompleteOption,
          option2: AutocompleteOption,
        ) => option1?.value === option2?.value}
        options={warehouseLocationOptions}
        matchSortOptions={{ keys: ['label'] }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            data-cy="package-warehouse-location"
            {...params}
            error={!isNil(error)}
            helperText={error}
            size="small"
            label={inputLabel}
            autoComplete="off"
          />
        )}
        onChange={(_, option) => onChange(option)}
      />
    </FormControl>
  );
};

export default WarehouseLocationSelector;
