import { Box } from '@mui/material';
import { useRef } from 'react';
import MapLegendRow from './components/map-legend-row';

export type SameDayDispatchMapLegendRoute = {
  driverName: string;
  color: string;
  isSelected: boolean;
  routeUuid: string;
};

const SameDayDispatchMapLegend = ({
  routes,
}: {
  routes: SameDayDispatchMapLegendRoute[];
}) => {
  const legendRefs = useRef<Record<string, HTMLElement>>({});

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'white',
        right: 0,
        opacity: '70%',
        maxHeight: '40%',
        width: '17%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {routes.map((route) => {
        if (route.isSelected) {
          legendRefs.current[route.routeUuid]?.scrollIntoView();
        }
        return (
          <MapLegendRow
            ref={(element: HTMLElement) => {
              legendRefs.current[route.routeUuid] = element;
            }}
            preferRouteName={false}
            key={route.routeUuid}
            isSelected={route.isSelected}
            color={route.color}
            driverName={route.driverName}
            routeName={route.driverName}
          />
        );
      })}
    </Box>
  );
};

export default SameDayDispatchMapLegend;
