/* eslint-disable default-case */
/* eslint-disable no-await-in-loop */
import { sentenceCase } from 'change-case';
import ExcelJS from 'exceljs';
import { flatten, isEmpty, isNil, uniqBy } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import {
  getChargeName,
  transformAddressToFullAddressString,
} from 'shared/copy';
import createPagesForPdf from '../../common/utils/pdf-gen';
import { transformDateToDateString } from '../../common/utils/prettyPrintUtils';
import { convertDocumentToS3Url } from '../../common/utils/utils';
import {
  CompanyFragment,
  CustomChargeBillingMethod,
  CustomChargeEntity,
  DocumentFragment,
  DocumentType,
  FreightChargeEntity,
  InvoiceForDownloadFragmentFragment,
  InvoiceShipmentForDownloadFragmentFragment,
  InvoiceType,
} from '../../generated/graphql';
import { StandardOrderValues } from '../orders/redux/standard/standard-orders-values-slice';
import { DocumentAttachments } from './components/download-documents';

type InvoiceShipmentFragmentCustomCharge = Omit<
  CustomChargeEntity,
  'billingMethod'
> & {
  customChargeBillingMethod: CustomChargeBillingMethod;
};

export const SKIP_COUNT_FUEL_AND_FREIGHT_CHARGES = 2;

const shouldAttachDocument = (
  document: { type: DocumentType },
  invoiceAttachments: DocumentAttachments,
) => {
  if (invoiceAttachments === DocumentAttachments.NoAttachments) {
    return false;
  }
  if (invoiceAttachments === DocumentAttachments.IncludeAll) {
    return true;
  }
  if (invoiceAttachments === DocumentAttachments.IncludeUnsignedPoDs) {
    return document.type === DocumentType.ProofOfDeliveryScanned;
  }
  if (invoiceAttachments === DocumentAttachments.IncludeDriverPoDs) {
    return document.type === DocumentType.ProofOfDelivery;
  }
  if (invoiceAttachments === DocumentAttachments.IncludeAllPoDs) {
    return (
      document.type === DocumentType.ProofOfDelivery ||
      document.type === DocumentType.ProofOfDeliveryScanned
    );
  }
  return false;
};

export type DocumentFormatForInvoiceDownload = {
  invoiceName: string;
  invoiceUuid?: string;
  url?: string;
  fileType: string;
  blob?: Blob;
};

const convertDocumentToDocumentFormatForInvoiceDownload = (
  invoiceName: string,
  document: DocumentFragment,
) => {
  return {
    invoiceName,
    fileType: document.fileType,
    url:
      document.preSignedGetUrl ??
      convertDocumentToS3Url(document.bucket, document.key, 'us-west-1'),
  };
};

export const getAttachmentDocumentsFromInvoice = async (
  invoices: InvoiceForDownloadFragmentFragment[],
  // documentUuids: string[],
  invoiceAttachments = DocumentAttachments.IncludeAll,
) => {
  if (invoiceAttachments === DocumentAttachments.NoAttachments) {
    return [];
  }
  const res: DocumentFormatForInvoiceDownload[][] = await Promise.all(
    invoices.map(async (invoice) => {
      const allOrders = uniqBy(
        invoice.shipments.flatMap((shipment) => shipment.order),
        'uuid',
      );
      const orderDocuments = flatten(
        allOrders.map((order) => {
          if (isNil(order)) {
            return [];
          }
          if (invoiceAttachments === DocumentAttachments.IncludeAllPoDs) {
            let pods = order.documents.filter(
              (document) =>
                document.type === DocumentType.ProofOfDeliveryScanned,
            );
            if (isEmpty(pods)) {
              pods = order.documents.filter(
                (document) => document.type === DocumentType.ProofOfDelivery,
              );
            }
            return pods.map((document) =>
              convertDocumentToDocumentFormatForInvoiceDownload(
                invoice.name,
                document,
              ),
            );
          }
          return order.documents
            .filter((document) =>
              shouldAttachDocument(document, invoiceAttachments),
            )
            .map((document) =>
              convertDocumentToDocumentFormatForInvoiceDownload(
                invoice.name,
                document,
              ),
            );
        }),
      );
      return orderDocuments;
    }),
  );
  return flatten(res);
};

export const getAttachmentDocumentsFromInvoiceNew = async (
  invoices: InvoiceForDownloadFragmentFragment[],
  // documentUuids: string[],
  invoiceAttachments: DocumentType[],
) => {
  const res: DocumentFormatForInvoiceDownload[][] = await Promise.all(
    invoices.map(async (invoice) => {
      const allOrders = uniqBy(
        invoice.shipments.flatMap((shipment) => shipment.order),
        'uuid',
      );
      const orderDocuments = flatten(
        allOrders.map((order) => {
          if (isNil(order)) {
            return [];
          }
          return order.documents
            .filter((document) => invoiceAttachments.includes(document.type))
            .map((document) =>
              convertDocumentToDocumentFormatForInvoiceDownload(
                invoice.name,
                document,
              ),
            );
        }),
      );
      return orderDocuments;
    }),
  );
  return flatten(res);
};

export const getRowCharge = (
  currentRow: number,
  shipments: InvoiceShipmentForDownloadFragmentFragment[],
  rowYOffset: number,
) => {
  const targetIndex = currentRow - rowYOffset - 1;
  let currentIndex = 0;
  let res:
    | {
        name: string;
        billingMethod: string | undefined;
        total: number | undefined;
        shipperBillOfLadingNumber?: string | undefined;
        allChargeTotal?: number | undefined;
      }
    | undefined;

  shipments.forEach((shipment) => {
    const freightCharge: FreightChargeEntity | undefined =
      shipment.charges.find(
        (charge) => charge.__typename === 'FreightChargeEntity',
      ) as FreightChargeEntity;
    const fuelCharge = freightCharge?.fuelCharge;
    const customCharges: InvoiceShipmentFragmentCustomCharge[] =
      shipment.charges.filter(
        (charge) => charge.__typename === 'CustomChargeEntity',
      ) as InvoiceShipmentFragmentCustomCharge[];
    currentIndex += 1; // skip fuel and freight charge
    if (currentIndex > targetIndex && isNil(res)) {
      res = {
        name: 'Freight Charge',
        billingMethod: freightCharge?.billingMethod,
        total: shipment.shipmentCharges.totalFreightCharge,
        shipperBillOfLadingNumber:
          shipment.order?.standardOrderFields.shipperBillOfLadingNumber ?? '',
        allChargeTotal: shipment.shipmentCharges.totalCharge,
      };
    }
    currentIndex += 1;
    if (currentIndex > targetIndex && isNil(res)) {
      res = {
        name: 'Fuel Charge',
        billingMethod: fuelCharge?.type,
        total: shipment.shipmentCharges.totalFuelCharge,
      };
    }
    currentIndex += customCharges.length;
    if (currentIndex > targetIndex && isNil(res)) {
      const customCharge = customCharges[currentIndex - targetIndex - 1];
      res = {
        name: getChargeName(customCharge) ?? '',
        billingMethod: customCharge?.customChargeBillingMethod,
        total: customCharge?.total,
      };
    }
  });

  return res;
};

export const convertInvoiceDataToSpreadsheets = async (
  invoices: InvoiceForDownloadFragmentFragment[],
  companyData: CompanyFragment | undefined,
  invoiceType?: InvoiceType | undefined,
) => {
  const useJournalNumber =
    companyData?.configuration?.useJournalNumberForInvoice === true;
  const bold = { bold: true };

  const res: DocumentFormatForInvoiceDownload[] = await Promise.all(
    invoices.map(async (invoice) => {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet(`Invoice-${invoice.name}`);
      // Add bold "INVOICE" to the top left corner
      const titleCell = sheet.getCell('A1');
      titleCell.value = 'INVOICE';
      titleCell.font = { bold: true, size: 26 };
      sheet.mergeCells('A1:C3');

      // Add company address to rows B4-B6
      sheet.getCell('A4').value = companyData?.name ?? '';
      sheet.getCell('A4').font = bold;

      const address = companyData?.defaultAddress;
      sheet.getCell('A5').value = !isNil(address)
        ? transformAddressToFullAddressString(address)
        : '';

      // Add bill to address to rows B9-B12
      sheet.getCell('A7').value = 'BILL TO';
      sheet.getCell('A7').font = bold;
      sheet.getCell('A8').value = invoice.billToContact?.displayName ?? '';
      sheet.getCell('A8').font = bold;

      const billToAddress = transformAddressToFullAddressString({
        ...invoice.billToContact?.defaultAddress,
        zip: invoice.billToContact?.defaultAddress?.zip ?? null,
        city: invoice.billToContact?.defaultAddress?.city ?? null,
        line1: invoice.billToContact?.defaultAddress?.line1 ?? null,
      });
      sheet.getCell('A9').value = billToAddress;

      // Add Invoice #, Invoice date, Invoice Due, and Terms to H9-H12
      sheet.getCell('H4').value = 'Invoice #';
      sheet.getCell('H5').value = 'Invoice date';
      sheet.getCell('H6').value = 'Invoice Due';
      sheet.getCell('H7').value = 'Terms';

      sheet.getCell('I4').value = useJournalNumber
        ? String(invoice.journalNumber)
        : (invoice.name ?? '');
      sheet.getCell('I5').value = !isNil(invoice.date)
        ? transformDateToDateString(String(invoice.date ?? ''))
        : 'N/A';
      sheet.getCell('I6').value = !isNil(invoice.dueDate)
        ? transformDateToDateString(String(invoice.dueDate ?? ''))
        : 'N/A';
      sheet.getCell('I7').value = sentenceCase(
        invoice.invoiceTerms?.terms ?? '',
      );

      // Add reference #'s and amount
      const sortedShipments = invoice.shipments.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      sheet.getCell('C12').value = 'DESCRIPTION';
      sheet.getCell('C12').font = bold;
      sheet.getCell('C13').value = 'Waybill #';
      sheet.getCell('C13').font = bold;
      if (invoiceType === InvoiceType.ExcelItemized) {
        sheet.getCell('D13').value = 'Charge Type';
        sheet.getCell('D13').font = bold;
        sheet.getCell('E13').value = 'Charge Name';
        sheet.getCell('E13').font = bold;
        sheet.getCell('F13').value = 'Charge Total';
        sheet.getCell('F13').font = bold;
      }
      sheet.getCell('G13').value = 'Total Charges';
      sheet.getCell('G13').font = bold;

      sheet.getCell('H13').value = 'Notes';
      sheet.getCell('H13').font = bold;

      let currentRow = 14;
      let rowToWrite = 14;
      sortedShipments.forEach((shipment) => {
        const numberOfCustomCharges = shipment.charges.filter(
          (charge) => charge.__typename === 'CustomChargeEntity',
        ).length;
        for (let i = 0; i < 2 + numberOfCustomCharges; i += 1) {
          const data = getRowCharge(currentRow, sortedShipments, 13);
          if (i === 0) {
            sheet.getCell(`C${rowToWrite}`).value =
              data?.shipperBillOfLadingNumber;
          }
          if (invoiceType === InvoiceType.ExcelItemized) {
            sheet.getCell(`D${rowToWrite}`).value = sentenceCase(
              data?.billingMethod ?? '',
            );
            sheet.getCell(`E${rowToWrite}`).value = data?.name;
            sheet.getCell(`F${rowToWrite}`).value = data?.total?.toFixed(2);
          }
          if (i === 0) {
            sheet.getCell(`G${rowToWrite}`).value =
              data?.allChargeTotal?.toFixed(2);
            const notes = shipment.order?.orderComments
              ?.filter((comment) => comment.showOnInvoice)
              .map((comment) => comment.comment)
              .join('\n');
            sheet.getCell(`H${rowToWrite}`).value = notes;
          }
          currentRow += 1;
          if (
            invoiceType === InvoiceType.ExcelItemized ||
            (invoiceType === InvoiceType.ExcelSummarized &&
              i === 2 + numberOfCustomCharges - 1)
          ) {
            rowToWrite += 1;
          }
        }
      });

      sheet.getCell(`C${rowToWrite}`).value = 'Total (USD)';
      sheet.getCell(`C${rowToWrite}`).font = bold;
      sheet.getCell(`G${rowToWrite}`).value = `$${sortedShipments
        .reduce((acc, curr) => acc + curr.shipmentCharges.totalCharge, 0)
        .toFixed(2)}`;
      sheet.getCell(`G${rowToWrite}`).font = bold;

      rowToWrite += SKIP_COUNT_FUEL_AND_FREIGHT_CHARGES;
      sheet.getCell(`C${rowToWrite}`).value = `Please remit payment to ${
        !isNil(address) ? transformAddressToFullAddressString(address) : ''
      }`;
      sheet.getCell(`C${rowToWrite}`).font = bold;
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      return {
        invoiceName: useJournalNumber
          ? String(invoice.journalNumber)
          : invoice.name,
        blob,
        url,
        fileType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
    }),
  );

  return res;
};

export const pdfDownload = async ({
  documents,
}: {
  documents: DocumentFormatForInvoiceDownload[];
}) => {
  const pdfDoc = await PDFDocument.create();
  const promises = [];

  for (let i = 0; i < documents.length; i += 1) {
    const documentObj = documents[i];
    if (!isNil(documentObj)) {
      const { url, fileType, blob } = documentObj;
      if (!isNil(blob)) {
        promises.push(
          createPagesForPdf(await blob.arrayBuffer(), fileType, pdfDoc),
        );
      } else if (!isNil(url)) {
        promises.push(
          createPagesForPdf(
            await fetch(url).then((res) => res.arrayBuffer()),
            fileType,
            pdfDoc,
          ),
        );
      }
    }
  }

  await Promise.all(promises);
  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = URL.createObjectURL(file);
  return fileURL;
};
export const multiplePdfDownload = async ({
  documents,
}: {
  documents: DocumentFormatForInvoiceDownload[];
}) => {
  const fileUrls = [];
  for (let i = 0; i < documents.length; i += 1) {
    const document = documents[i];
    if (!isNil(document)) {
      fileUrls.push(pdfDownload({ documents: [document] }));
    }
  }
  return Promise.all(fileUrls);
};

export const combineIndividualOrderNamesToConsolidatedString = (
  orders: StandardOrderValues[],
) => {
  const commaSeparated = orders.map(
    (order, idx) =>
      `${order.shipperBillOfLadingNumber ?? order.name}${
        idx < orders.length - 1 ? ', ' : ''
      }`,
  );

  return `${commaSeparated.join('')} (consolidated)`;
};
