import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, useState } from 'react';

const CreateNewSavedViewModal = ({
  open,
  setOpen,
  createNewSavedView,
  viewIsFromScratch,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  createNewSavedView: (displayName: string, viewIsFromScratch: boolean) => void;
  viewIsFromScratch: boolean;
}) => {
  const [name, setName] = useState('');

  const closeModal = () => {
    setOpen(false);
  };

  const handleChangeName = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setName(e.target.value);
  };

  const handleClickCreate = () => {
    if (!isEmpty(name)) {
      createNewSavedView(name, viewIsFromScratch);
      setName('');
      closeModal();
    }
  };

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Create new saved view</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Name"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={name}
              onChange={handleChangeName}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClickCreate();
                  e.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ float: 'right', mb: 1, mr: 1 }}
          variant="contained"
          onClick={handleClickCreate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewSavedViewModal;
