import {
  Box,
  Button,
  CircularProgress,
  Popover,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useGetCityAndStateOptionsFromZipcodeLazyQuery } from '../../generated/graphql';
import { isZipcode, removeNonZipcodeCharacters } from '../utils/zipcodes';

type Location = {
  zipcode: string;
  city: string;
  state: string;
};

type ZipcodeAndCityMultiSelectProps = {
  onSelect: (location: Location) => void;
  onEnterManually?: (input: string) => void;
  excludeLocation?: (location: Location) => boolean;
};

export const ZipcodeAndCityMultiSelect: FunctionComponent<
  ZipcodeAndCityMultiSelectProps
> = ({ onSelect, onEnterManually, excludeLocation }) => {
  const theme = useTheme();
  const [getCityAndStateFromZipcode, { data, loading }] =
    useGetCityAndStateOptionsFromZipcodeLazyQuery({
      fetchPolicy: 'cache-first',
    });
  const [inputValue, setInputValue] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const anchorElement = useRef<HTMLDivElement>(null);
  const zipcodeInputElement = useRef<HTMLInputElement>(null);

  const [debouncedInputValue] = useDebounce(inputValue, 200);

  useEffect(() => {
    if (debouncedInputValue.length > 0) {
      setMenuOpen(true);
    }
    if (isZipcode(debouncedInputValue)) {
      getCityAndStateFromZipcode({
        variables: {
          getCityAndStateFromZipcodeInput: { zipcode: debouncedInputValue },
        },
      });
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    // Focus the popover when the down arrow key is pressed.
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        setMenuOpen(true);
      }
    };

    if (zipcodeInputElement.current) {
      zipcodeInputElement.current.addEventListener('keydown', keydownHandler);
    }

    return () => {
      if (zipcodeInputElement.current) {
        zipcodeInputElement.current.removeEventListener(
          'keydown',
          keydownHandler,
        );
      }
    };
  }, []);

  const results = data?.getCityAndStateOptionsFromZipcode;

  const locationOptions =
    !isNil(results) && results.zipcode === inputValue
      ? results.citiesAndStates.filter(
          (option) =>
            isNil(excludeLocation) ||
            !excludeLocation({
              zipcode: results.zipcode,
              city: option.city,
              state: option.state,
            }),
        )
      : [];

  return (
    <>
      <Box>
        <TextField
          ref={anchorElement}
          inputRef={zipcodeInputElement}
          placeholder="Zipcode"
          value={inputValue}
          onChange={({ target }) => {
            const value = removeNonZipcodeCharacters(target.value);
            setInputValue(value);
            if (value.length === 0) {
              setMenuOpen(false);
            }
          }}
          size="small"
          sx={{ width: '100%' }}
        />
      </Box>
      <Popover
        id="zipcode-and-city-multi-select"
        anchorEl={anchorElement.current}
        disableAutoFocus
        open={
          menuOpen &&
          (loading || locationOptions.length > 0 || !isNil(onEnterManually))
        }
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          sx: {
            width: `${anchorElement.current?.clientWidth ?? 300}px`,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {loading && <CircularProgress />}
        {!isNil(results) &&
          locationOptions.map((option) => (
            <Button
              key={`${option.city}${option.state}`}
              sx={{
                display: 'flex',
                width: '100%',
                borderRadius: 0,
                textAlign: 'left',
                justifyContent: 'start',
                gap: 2,
                pl: 2,
                color: theme.palette.text.primary,
              }}
              onClick={() => {
                onSelect({
                  zipcode: results.zipcode,
                  city: option.city,
                  state: option.state,
                });
              }}
            >
              <span style={{ fontWeight: 700 }}>{results.zipcode}</span>
              <span style={{ fontWeight: 400 }}>{option.city}</span>
            </Button>
          ))}
        {!loading && !isNil(onEnterManually) && (
          <Button
            sx={{
              display: 'flex',
              width: '100%',
              borderRadius: 0,
              textAlign: 'left',
              justifyContent: 'start',
              gap: 2,
              pl: 2,
              color: theme.palette.text.primary,
            }}
            onClick={() => {
              onEnterManually(inputValue);
            }}
          >
            Enter a zipcode and city manually
          </Button>
        )}
      </Popover>
    </>
  );
};
