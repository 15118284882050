import { Box, IconButton, Popover } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';

import { getBadgeStyle, getCutoutStyle } from '../utils/get-badge-style';

const BUTTON_HIGHLIGHT_COLOR = theme.palette.primary.light;
const BUTTON_HOVER_COLOR = '#EDEFF2';
const BADGE_POSITION = '10%';

const StyledIconButton = styled(IconButton)<{
  shouldHighlight: boolean;
  showBadge: boolean;
}>(({ shouldHighlight, showBadge }) => {
  const backgroundColor = shouldHighlight
    ? BUTTON_HIGHLIGHT_COLOR
    : 'transparent';
  return {
    borderRadius: '6px',
    ...(shouldHighlight && { color: theme.palette.primary.main }),
    ...(showBadge
      ? {
          ...getBadgeStyle(backgroundColor, BADGE_POSITION),
        }
      : { backgroundColor }),
    '&:hover': {
      color: theme.palette.text.secondary,
      ...(showBadge
        ? getCutoutStyle(BUTTON_HOVER_COLOR, BADGE_POSITION)
        : { background: `${BUTTON_HOVER_COLOR}` }),
    },
  };
});

interface IconButtonWithPopoverProps {
  icon: React.ReactNode;
  shouldHighlight: boolean;
  showBadge?: boolean;
  render: (handleClose: () => void) => React.ReactNode;
  onClose?: () => void;
}

const IconButtonWithPopover = ({
  icon,
  shouldHighlight,
  showBadge = false,
  render,
  onClose,
}: IconButtonWithPopoverProps) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    onClose?.();
    setAnchorElement(null);
  };

  return (
    <>
      <StyledIconButton
        onClick={handleClick}
        size="small"
        shouldHighlight={shouldHighlight}
        showBadge={showBadge}
      >
        {icon}
      </StyledIconButton>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box>{render(handleClose)}</Box>
      </Popover>
    </>
  );
};

export default IconButtonWithPopover;
