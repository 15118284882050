import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TableHead,
  TableRow,
  Modal,
  Typography,
  Stack,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  AlertTitle,
  Alert,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { getValueWithinBounds } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';
import styles from './styles';
import TariffRangeCell, { TariffRange } from './tariff-range-cell';

enum AmountInputType {
  Minimum = 'MINIMUM',
  Maximum = 'MAXIMUM',
}

const TariffMileRangeModal = ({
  mileRangeModalOpen,
  setMileRangeModalOpen,
}: {
  mileRangeModalOpen: boolean;
  setMileRangeModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [rangeError, setRangeError] = useState<boolean>(false);
  const [
    rateMatrix,
    minMaxAmountValues,
    settlementPercentageRateValues,
    xRangeValues,
    zoneBasedOverageRates,
    setMinMaxAmountValue,
    setMinMaxAmountValues,
    setSettlementPercentageRateValue,
    setSettlementPercentageRateValues,
    addXRangeValue,
    setXRangeValues,
    setRateMatrix,
    setZoneBasedOverageRates,
  ] = useTariffGroupStore(
    (state) => [
      state.rateMatrix,
      state.minMaxAmountValues,
      state.settlementPercentageRateValues,
      state.xRangeValues,
      state.zoneBasedOverageRates,
      state.setMinMaxAmountValue,
      state.setMinMaxAmountValues,
      state.setSettlementPercentageRateValue,
      state.setSettlementPercentageRateValues,
      state.addXRangeValue,
      state.setXRangeValues,
      state.setRateMatrix,
      state.setZoneBasedOverageRates,
    ],
    shallow,
  );

  const onClose = () => {
    const rangeValues = xRangeValues;
    if (!isNil(rangeValues)) {
      let min = 0;
      for (let i = 0; i < rangeValues.length - 1; i += 1) {
        const value = rangeValues[i];
        if (Number.isNaN(value) || isNil(value)) {
          setRangeError(true);
          return;
        }
        if (min >= value) {
          setRangeError(true);
          return;
        }
        min = value;
      }
    }
    setRangeError(false);
    setMileRangeModalOpen(false);
  };
  const addMileRange = () => {
    addXRangeValue();
  };

  const deleteRow = (rowIndex: number) => {
    const newRange = [...xRangeValues];
    const newRateMatrix = [...rateMatrix];
    const newZoneBasedOverageRates = [...zoneBasedOverageRates];
    const newMinMaxAmountValues = [...minMaxAmountValues];
    const newSettlementPercentageRateValues = [
      ...settlementPercentageRateValues,
    ];

    newRange.splice(rowIndex, 1);
    newRateMatrix.splice(rowIndex, 1);
    newZoneBasedOverageRates.splice(rowIndex, 1);
    newMinMaxAmountValues.splice(rowIndex, 1);
    newSettlementPercentageRateValues.splice(rowIndex, 1);

    setXRangeValues(newRange);
    setRateMatrix(newRateMatrix);
    setZoneBasedOverageRates(newZoneBasedOverageRates);
    setMinMaxAmountValues(newMinMaxAmountValues);
    setSettlementPercentageRateValues(newSettlementPercentageRateValues);
  };

  const updateMinMaxAmount = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
    rate: number | undefined,
  ) => {
    const minMaxAmount = { ...minMaxAmountValues[rowIndex] };
    if (!isNil(minMaxAmount)) {
      if (event.target.name === AmountInputType.Minimum) {
        minMaxAmount.min = rate;
      } else {
        minMaxAmount.max = rate;
      }
    }
    setMinMaxAmountValue(rowIndex, minMaxAmount);
  };

  const validateAndUpdateRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    const rate = !Number.isNaN(parsed)
      ? getValueWithinBounds(currency(parsed, { precision: 2 }).value)
      : undefined;
    updateMinMaxAmount(event, rowIndex, rate);
  };

  const updateSettlementPercentageRate = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number,
  ) => {
    const parsed = parseFloat(event.target.value);
    const newSettlementRate = !Number.isNaN(parsed) ? parsed : null;
    setSettlementPercentageRateValue(rowIndex, newSettlementRate);
  };

  return (
    <Modal open={mileRangeModalOpen} onClose={onClose}>
      <Box sx={styles.modal}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Edit Mile Ranges</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setMileRangeModalOpen(false);
                }}
              >
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack spacing={2} sx={{ width: '100%' }}>
              {rangeError && (
                <Alert severity="error">
                  <AlertTitle>Range Error</AlertTitle>
                  Please ensure that you have a valid range.
                </Alert>
              )}
              <TableContainer sx={{ maxHeight: '55vh' }}>
                <Table stickyHeader>
                  <TableHead aria-label="tariff-table">
                    <TableRow>
                      <TableCell align="center">
                        <Typography>Mile Range</Typography>
                      </TableCell>
                      <TableCell colSpan={4} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {xRangeValues.map((value, rowIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableRow key={rowIndex}>
                        <TariffRangeCell
                          rangeToModify={TariffRange.X}
                          rangeIndex={rowIndex}
                        />
                        <TableCell>
                          <TextField
                            name={AmountInputType.Minimum}
                            size="small"
                            label="Minimum Amount"
                            onBlur={(event) => {
                              validateAndUpdateRate(event, rowIndex);
                            }}
                            onChange={(event) =>
                              updateMinMaxAmount(
                                event,
                                rowIndex,
                                parseFloat(event.target.value),
                              )
                            }
                            value={minMaxAmountValues[rowIndex]?.min ?? ''}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={AmountInputType.Maximum}
                            size="small"
                            label="Maximum Amount"
                            onBlur={(event) => {
                              validateAndUpdateRate(event, rowIndex);
                            }}
                            onChange={(event) => {
                              updateMinMaxAmount(
                                event,
                                rowIndex,
                                parseFloat(event.target.value),
                              );
                            }}
                            value={minMaxAmountValues[rowIndex]?.max ?? ''}
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            label="Settlement Rate"
                            onBlur={(event) => {
                              updateSettlementPercentageRate(event, rowIndex);
                            }}
                            onChange={(event) => {
                              updateSettlementPercentageRate(event, rowIndex);
                            }}
                            value={
                              settlementPercentageRateValues[
                                rowIndex
                              ]?.amount.toNumber() ?? ''
                            }
                            type="number"
                            onWheel={(e) =>
                              (e.target as HTMLTextAreaElement).blur()
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="error"
                            onClick={() => deleteRow(rowIndex)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button onClick={addMileRange}>Add Range</Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffMileRangeModal;
