import { Box, Button, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { EnvironmentVariables } from '../../environment-variables';
import {
  StopType,
  useShipmentsByPhoneOrReferenceNumberLazyQuery,
} from '../../generated/graphql';

type TrackingWidgetProps = {
  companyUuid: string;
};

const TrackingWidget: FunctionComponent<TrackingWidgetProps> = ({
  companyUuid,
}) => {
  const [searchText, setSearchText] = useState('');
  const [getShipmentsByPhoneOrRefNumber] =
    useShipmentsByPhoneOrReferenceNumberLazyQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const onClickSearch = async () => {
    const shipmentsData = await getShipmentsByPhoneOrRefNumber({
      variables: { searchText, companyUuid },
    });
    const pickupOrDeliveryShipment =
      shipmentsData.data?.shipmentsByPhoneOrReferenceNumber.find(
        (shipment) =>
          shipment.legs[0]?.endStop.stopType === StopType.Delivery ||
          shipment.legs[0]?.endStop.stopType === StopType.Pickup,
      );
    if (
      !isNil(pickupOrDeliveryShipment) &&
      !isNil(pickupOrDeliveryShipment.order)
    ) {
      window.open(
        `${EnvironmentVariables.VITE_FRONTEND_URL}/consignee/shipment-profile/${pickupOrDeliveryShipment.order.uuid}`,
        '_blank',
      );
      return;
    }
    setErrorMessage('No shipments associated with this search.');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        textAlign: 'center',
        width: '300px',
        height: '100px',
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        p: '10px',
      }}
    >
      <Typography variant="h6">Track your delivery</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Search"
          value={searchText}
          onChange={(e) => {
            if (errorMessage.length > 0) {
              setErrorMessage('');
            }
            setSearchText(e.target.value);
          }}
          size="small"
        />
        <Button onClick={onClickSearch} variant="contained">
          Search
        </Button>
      </Box>
      {errorMessage.length > 0 && (
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      )}
    </Box>
  );
};

export default TrackingWidget;
