import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import useDispatchTableColors from '../../../common/react-hooks/use-dispatch-table-colors';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import useDispatchStore from '../dispatch-store';
import { useDispatchViews } from '../dispatch-views/use-dispatch-views';

const useDispatchViewSettings = () => {
  const [
    selectedViewUuid,
    useMinimizedAppointmentTime,
    numberOfStops,
    showCompleteStops,
    routeCardWidth,
  ] = useDispatchStore(
    (state) => [
      state.selectedViewUuid,
      state.useMinimizedAppointmentTime,
      state.numberOfStops,
      state.showCompleteStops,
      state.routeCardWidth,
    ],
    shallow,
  );
  const { routeCardStopTableColumnWidths, routeCardStopTableFields } =
    useTableFields();
  const { dispatchTableColors: globalDispatchTableColors } =
    useDispatchTableColors();
  const { dispatchViews } = useDispatchViews();

  const selectedView = dispatchViews?.find(
    (view) => view.id === selectedViewUuid,
  );

  if (!isNil(selectedView)) {
    return selectedView;
  }

  return {
    dispatchTableColors: globalDispatchTableColors,
    useMinimizedAppointmentTime,
    numberOfStops,
    showCompleteStops,
    routeCardStopTableColumnWidths,
    routeCardWidth,
    routeCardStopTableFields,
  };
};

export { useDispatchViewSettings };
