import { useEffect } from 'react';
import { useContactQuery } from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import type { RootState } from '../../../redux/store';
import { selectContactValuesById } from '../redux/contact-values-slice';
import { upsertOneContactValuesThunk } from '../redux/contact-values-thunks';

const useContact = ({ uuid }: { uuid: string }) => {
  const dispatch = useAppDispatch();
  const { data, called, loading, error, refetch } = useContactQuery({
    variables: { uuid },
  });

  useEffect(() => {
    if (data !== undefined) {
      dispatch(upsertOneContactValuesThunk({ data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const contactValues = useAppSelector((state: RootState) =>
    selectContactValuesById(state, uuid),
  );

  return {
    data: contactValues,
    called,
    loading,
    error,
    refetch,
  };
};

export default useContact;
