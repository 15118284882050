import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { TariffZoneType } from '../../../../../generated/graphql';
import { getValueWithinBounds } from '../store/tariff-group-controller';
import useTariffGroupStore from '../store/tariff-group-state-store';
import styles from '../styles';
import { AmountInputType } from '../types';
import TariffMileRangeOverageRateModal from './tariff-mile-range-overage-rates-modal';
import TariffZoneOverageRow from './tariff-zone-overage-row';

const TariffZoneOverageRateModal = ({
  overageRateModalOpen,
  setOverageRateModalOpen,
}: {
  overageRateModalOpen: boolean;
  setOverageRateModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [zoneType, zoneBasedOverageRates, locationZonesData] =
    useTariffGroupStore(
      (state) => [
        state.zoneType,
        state.zoneBasedOverageRates,
        state.locationZonesData,
      ],
      shallow,
    );

  const [setZoneBasedOverageRates, setUseGlobalOverageRates] =
    useTariffGroupStore(
      (state) => [
        state.setZoneBasedOverageRates,
        state.setUseGlobalOverageRates,
      ],
      shallow,
    );

  const [statefulZoneBasedOverageRates, setStatefulZoneBasedOverageRates] =
    useState(zoneBasedOverageRates);

  useEffect(() => {
    setStatefulZoneBasedOverageRates(zoneBasedOverageRates);
  }, [zoneBasedOverageRates]);

  const updateMileOverageRate = useCallback(
    (rowIndex: number, rate: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex ? { ...item, mileOverageRate: rate } : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updateMileOverageFlatRate = useCallback(
    (rowIndex: number, rate: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex ? { ...item, mileOverageFlatRate: rate } : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updateMileOverageApplicableAbove = useCallback(
    (rowIndex: number, applicableAboveAmount: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex
            ? { ...item, mileOverageApplicableAbove: applicableAboveAmount }
            : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updatePieceOverageRate = useCallback(
    (rowIndex: number, rate: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex ? { ...item, pieceOverageRate: rate } : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updatePieceOverageFlatRate = useCallback(
    (rowIndex: number, rate: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex ? { ...item, pieceOverageFlatRate: rate } : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updatePieceOverageApplicableAbove = useCallback(
    (rowIndex: number, applicableAboveAmount: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex
            ? {
                ...item,
                pieceOverageApplicableAbove: applicableAboveAmount,
              }
            : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updateWeightOverageRate = useCallback(
    (rowIndex: number, rate: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex ? { ...item, weightOverageRate: rate } : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updateWeightOverageFlatRate = useCallback(
    (rowIndex: number, rate: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex ? { ...item, weightOverageFlatRate: rate } : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const updateWeightOverageApplicableAbove = useCallback(
    (rowIndex: number, applicableAboveAmount: number | null) => {
      setStatefulZoneBasedOverageRates((prev) => {
        const newState = prev.map((item, index) =>
          index === rowIndex
            ? { ...item, weightOverageApplicableAbove: applicableAboveAmount }
            : item,
        );
        return newState;
      });
    },
    [setStatefulZoneBasedOverageRates],
  );

  const validateAndUpdateRate = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      rowIndex: number,
    ) => {
      const parsed = parseFloat(event.target.value);
      let rate = null;
      if (!Number.isNaN(parsed)) {
        rate = getValueWithinBounds(currency(parsed, { precision: 2 }).value);
      }
      if (event.target.name === AmountInputType.MileOverage) {
        updateMileOverageRate(rowIndex, rate);
      } else if (event.target.name === AmountInputType.MileOverageFlatRate) {
        updateMileOverageFlatRate(rowIndex, rate);
      } else if (
        event.target.name === AmountInputType.MileOverageApplicableAbove
      ) {
        updateMileOverageApplicableAbove(rowIndex, rate);
      } else if (event.target.name === AmountInputType.PieceOverage) {
        updatePieceOverageRate(rowIndex, rate);
      } else if (event.target.name === AmountInputType.PieceOverageFlatRate) {
        updatePieceOverageFlatRate(rowIndex, rate);
      } else if (
        event.target.name === AmountInputType.PieceOverageApplicableAbove
      ) {
        updatePieceOverageApplicableAbove(rowIndex, rate);
      } else if (event.target.name === AmountInputType.WeightOverage) {
        updateWeightOverageRate(rowIndex, rate);
      } else if (event.target.name === AmountInputType.WeightOverageFlatRate) {
        updateWeightOverageFlatRate(rowIndex, rate);
      } else if (
        event.target.name === AmountInputType.WeightOverageApplicableAbove
      ) {
        updateWeightOverageApplicableAbove(rowIndex, rate);
      }
    },
    [
      updateMileOverageRate,
      updateMileOverageFlatRate,
      updateMileOverageApplicableAbove,
      updatePieceOverageRate,
      updatePieceOverageFlatRate,
      updatePieceOverageApplicableAbove,
      updateWeightOverageRate,
      updateWeightOverageFlatRate,
      updateWeightOverageApplicableAbove,
    ],
  );

  const onSave = () => {
    setZoneBasedOverageRates(statefulZoneBasedOverageRates);
    setOverageRateModalOpen(false);
  };

  if (zoneType === TariffZoneType.Miles) {
    return (
      <TariffMileRangeOverageRateModal
        overageRateModalOpen={overageRateModalOpen}
        setOverageRateModalOpen={setOverageRateModalOpen}
      />
    );
  }

  return (
    <Modal
      open={overageRateModalOpen}
      onClose={() => setOverageRateModalOpen(false)}
    >
      <Box sx={{ ...styles.modal, width: '90vw' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Edit Overages</Typography>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => setUseGlobalOverageRates(true)}
                >
                  Set Global Overage Rates
                </Button>
                <Button variant="contained" onClick={onSave}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={styles.center}>
            <Stack
              spacing={2}
              sx={{ width: '100%', maxHeight: '55vh', overflowY: 'auto' }}
            >
              {locationZonesData.map((zone, rowIndex) => (
                <>
                  <TariffZoneOverageRow
                    zone={zone}
                    rowIndex={rowIndex}
                    mileOverageRate={
                      statefulZoneBasedOverageRates[rowIndex]?.mileOverageRate
                    }
                    mileOverageFlatRate={
                      statefulZoneBasedOverageRates[rowIndex]
                        ?.mileOverageFlatRate
                    }
                    mileOverageApplicableAbove={
                      statefulZoneBasedOverageRates[rowIndex]
                        ?.mileOverageApplicableAbove
                    }
                    weightOverageRate={
                      statefulZoneBasedOverageRates[rowIndex]?.weightOverageRate
                    }
                    weightOverageFlatRate={
                      statefulZoneBasedOverageRates[rowIndex]
                        ?.weightOverageFlatRate
                    }
                    weightOverageApplicableAbove={
                      statefulZoneBasedOverageRates[rowIndex]
                        ?.weightOverageApplicableAbove
                    }
                    updateMileOverageRate={updateMileOverageRate}
                    updateMileOverageFlatRate={updateMileOverageFlatRate}
                    updateMileOverageApplicableAbove={
                      updateMileOverageApplicableAbove
                    }
                    pieceOverageRate={
                      statefulZoneBasedOverageRates[rowIndex]?.pieceOverageRate
                    }
                    pieceOverageFlatRate={
                      statefulZoneBasedOverageRates[rowIndex]
                        ?.pieceOverageFlatRate
                    }
                    pieceOverageApplicableAbove={
                      statefulZoneBasedOverageRates[rowIndex]
                        ?.pieceOverageApplicableAbove
                    }
                    updatePieceOverageRate={updatePieceOverageRate}
                    updatePieceOverageFlatRate={updatePieceOverageFlatRate}
                    updatePieceOverageApplicableAbove={
                      updatePieceOverageApplicableAbove
                    }
                    updateWeightOverageRate={updateWeightOverageRate}
                    updateWeightOverageFlatRate={updateWeightOverageFlatRate}
                    updateWeightOverageApplicableAbove={
                      updateWeightOverageApplicableAbove
                    }
                    validateAndUpdateRate={validateAndUpdateRate}
                  />
                  <Divider />
                </>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TariffZoneOverageRateModal;
