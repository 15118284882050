import {
  Checkbox,
  FormControl,
  FormControlLabel,
  // eslint-disable-next-line
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ButtonLink from '../../../common/components/buttons/button-link';
import {
  AccessorialType,
  AllAccessorialFieldsFragment,
  useAccessorialsLazyQuery,
} from '../../../generated/graphql';
import {
  FormMode,
  getAccessorialTypeFromTypename,
  getBackUrl,
} from '../../management/components/accessorials/common';
import QuantityBasedAccessorialForm from '../../management/components/accessorials/quantity-based-accessorial-form';
import WeightBasedAccessorialForm from '../../management/components/accessorials/range-based-accessorial-form';
import SpecialAccessorialForm from '../../management/components/accessorials/special-accessorial-form';
import StandardAccessorialForm from '../../management/components/accessorials/standard-accessorial-form';
import WaitTimeAccessorialForm from '../../management/components/accessorials/wait-time-accessorial-form';
import ZoneBasedAccessorialForm from '../../management/components/accessorials/zone-based-accessorial-form';

const muiStyles = {
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
  },
};

type AddContactAccessorialProps = {
  contactUuid: string;
};

const AddContactAccessorial: FunctionComponent<AddContactAccessorialProps> = ({
  contactUuid,
}) => {
  const mode = FormMode.CREATE;

  const [accessorialType, setAccessorialType] = useState<AccessorialType>(
    AccessorialType.Standard,
  );

  const [accessorialTemplatesMap, setAccessorialTemplatesMap] =
    useState<Map<string, AllAccessorialFieldsFragment>>();
  const [accessorialTemplate, setAccessorialTemplate] =
    useState<AllAccessorialFieldsFragment>();
  const [isAuthoCodeRequired, setIsAuthoCodeRequired] = useState(false);

  const [accessorialsQuery] = useAccessorialsLazyQuery();
  useEffect(() => {
    accessorialsQuery().then((response) => {
      if (response.data?.accessorials !== undefined) {
        const accessorialTemplateMap: Map<
          string,
          AllAccessorialFieldsFragment
        > = new Map(
          response.data?.accessorials
            .filter((accessorial) => accessorial.contact === null)
            .map((item) => {
              return [item.uuid, item];
            }),
        );
        setAccessorialTemplatesMap(accessorialTemplateMap);
      }
    });
  }, [accessorialsQuery]);

  return (
    <Grid container spacing={2} overflow="scroll" height="100%">
      <Grid item xs={12} justifyContent="center">
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={4}>
            <ButtonLink
              href={getBackUrl(contactUuid)}
              variant="outlined"
              color="primary"
            >
              Back to setup
            </ButtonLink>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{ color: 'black', textAlign: 'center' }}
              variant="h6"
            >{`${mode === FormMode.CREATE ? 'Add' : 'Edit'}${
              !isNil(contactUuid) ? ' Contact' : ''
            } Accessorial`}</Typography>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={muiStyles.centeredRow}
        direction="row"
        alignItems="center"
        gap={2}
      >
        {mode === FormMode.CREATE &&
          !isNil(contactUuid) &&
          !isNil(accessorialTemplatesMap) &&
          accessorialTemplatesMap.size > 0 && (
            <FormControl>
              <InputLabel>Select a template accessorial</InputLabel>
              <Select
                style={{ width: 300 }}
                value={accessorialTemplate?.uuid ?? ''}
                onChange={(e) => {
                  const template = accessorialTemplatesMap.get(e.target.value);
                  setAccessorialTemplate(template);
                  if (!isNil(template?.__typename)) {
                    setAccessorialType(
                      getAccessorialTypeFromTypename(template?.__typename),
                    );
                  } else {
                    setAccessorialType(AccessorialType.Standard);
                  }
                }}
                label="Select a template accessorial"
              >
                {Array.from(accessorialTemplatesMap.values())
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((accessorial) => {
                    return (
                      <MenuItem key={accessorial.uuid} value={accessorial.uuid}>
                        {accessorial.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
        {mode === FormMode.CREATE && (
          <Grid item>
            <Select
              value={accessorialType}
              onChange={(e) =>
                setAccessorialType(e.target.value as AccessorialType)
              }
            >
              {Object.values(AccessorialType).map((at) => {
                return (
                  <MenuItem key={at} value={at}>
                    {at}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        )}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAuthoCodeRequired}
                onChange={() => {
                  setIsAuthoCodeRequired(!isAuthoCodeRequired);
                }}
              />
            }
            label="Autho required"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {accessorialType === AccessorialType.Standard && (
          <StandardAccessorialForm
            mode={mode}
            uuid={undefined}
            contactUuid={contactUuid}
            templateUuid={accessorialTemplate?.uuid}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {(accessorialType === AccessorialType.Unit ||
          accessorialType === AccessorialType.Skid) && (
          <QuantityBasedAccessorialForm
            mode={mode}
            accessorialType={accessorialType}
            uuid={undefined}
            contactUuid={contactUuid}
            templateUuid={accessorialTemplate?.uuid}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.Weight && (
          <WeightBasedAccessorialForm
            mode={mode}
            accessorialType={accessorialType}
            uuid={undefined}
            contactUuid={contactUuid}
            templateUuid={accessorialTemplate?.uuid}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.ZoneBased && (
          <ZoneBasedAccessorialForm
            mode={mode}
            uuid={undefined}
            contactUuid={contactUuid}
            templateUuid={accessorialTemplate?.uuid}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.WaitTime && (
          <WaitTimeAccessorialForm
            mode={mode}
            uuid={undefined}
            contactUuid={contactUuid}
            templateUuid={accessorialTemplate?.uuid}
            isAuthoCodeRequired={isAuthoCodeRequired}
          />
        )}
        {accessorialType === AccessorialType.Special && (
          <SpecialAccessorialForm
            mode={mode}
            uuid={undefined}
            contactUuid={contactUuid}
            templateUuid={accessorialTemplate?.uuid}
            isAuthoCodeRequired={isAuthoCodeRequired}
            isDefaultGlobalSpecial={false}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AddContactAccessorial;
