import { Box, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const AccessorialMapping = ({
  uuid,
  name,
  setAccessorialsToUpdate,
  mapping,
  disabled,
}: {
  uuid: string;
  name: string;
  setAccessorialsToUpdate: Dispatch<
    SetStateAction<{
      [key: string]: string;
    }>
  >;
  mapping: string;
  disabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Typography>{name}</Typography>
      <TextField
        value={mapping}
        size="small"
        onChange={(e) =>
          setAccessorialsToUpdate((prevState) => ({
            ...prevState,
            [uuid]: e.target.value,
          }))
        }
        disabled={disabled}
      />
    </Box>
  );
};

export default AccessorialMapping;
