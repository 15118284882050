import { QueryResult } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  SxProps,
  Modal,
  Box,
  IconButton,
  Button,
  MenuItem,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ShipmentBillOfLadingQuery,
  ShipmentBillOfLadingQueryVariables,
  useMeQuery,
  useShipmentBillOfLadingLazyQuery,
} from '../../../../../../../generated/graphql';
import GeneratedBillOfLadingPdf from '../../../../../../generated-documents/components/generated-bill-of-lading-pdf';
import { StopValues } from '../../../forms/types';

const PrintBol = () => {
  const { getValues } = useFormContext();
  const stopValuesArr: StopValues[] | undefined = getValues('stops');
  const shipmentUuids = stopValuesArr?.map((stop) => stop.shipmentUuid) ?? [];
  const [isPrintShipmentModalOpen, setIsPrintShipmentModalOpen] =
    useState(false);
  const styles = {
    shipmentBillOfLadingModal: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60vw',
      bgcolor: 'background.paper',
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    } as SxProps,
  };

  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const [displayShipmentBillOfLadingData, setDisplayShipmentBillOfLadingData] =
    useState<
      QueryResult<
        ShipmentBillOfLadingQuery,
        ShipmentBillOfLadingQueryVariables
      >[]
    >([]);

  const [shipmentBillofLadingQuery] = useShipmentBillOfLadingLazyQuery();

  const handleShipmentBillOfLadingModalOpen = async () => {
    const bolDataArr: QueryResult<
      ShipmentBillOfLadingQuery,
      ShipmentBillOfLadingQueryVariables
    >[] = [];
    for (const shipmentUuid of shipmentUuids) {
      // eslint-disable-next-line no-await-in-loop
      const bolData = await shipmentBillofLadingQuery({
        variables: {
          shipmentUuid,
        },
      });
      bolDataArr.push(bolData);
    }
    setDisplayShipmentBillOfLadingData(bolDataArr);
    setIsPrintShipmentModalOpen(true);
  };

  const handleShipmentBillOfLadingModalClose = () => {
    setIsPrintShipmentModalOpen(false);
  };

  if (isNil(displayShipmentBillOfLadingData)) {
    return <CircularProgress />;
  }
  return (
    <>
      <MenuItem onClick={handleShipmentBillOfLadingModalOpen}>
        Bill of lading
      </MenuItem>
      <Modal
        open={isPrintShipmentModalOpen}
        onClose={handleShipmentBillOfLadingModalClose}
        aria-labelledby="modal-modal-title"
      >
        <Box>
          {displayShipmentBillOfLadingData.length > 0 && (
            <Box sx={styles.shipmentBillOfLadingModal}>
              <IconButton
                onClick={handleShipmentBillOfLadingModalClose}
                sx={{ padding: 0, float: 'right', mb: '10px', ml: 'auto' }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
                <GeneratedBillOfLadingPdf
                  shipmentBillOfLadingData={displayShipmentBillOfLadingData}
                  companyData={companyData}
                />
              </PDFViewer>

              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 'auto', mt: '10px' }}
              >
                <PDFDownloadLink
                  document={
                    <GeneratedBillOfLadingPdf
                      shipmentBillOfLadingData={displayShipmentBillOfLadingData}
                      companyData={companyData}
                    />
                  }
                  fileName="bill-of-lading.pdf"
                >
                  {({ loading }) =>
                    loading === true ? 'Loading...' : 'Download'
                  }
                </PDFDownloadLink>
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default PrintBol;
