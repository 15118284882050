import CloseIcon from '@mui/icons-material/Close';
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { shallow } from 'zustand/shallow';
import { TariffType, TariffZoneType } from '../../../../generated/graphql';
import useTariffGroupStore from './store/tariff-group-state-store';
import TariffRangeCell, { TariffRange } from './tariff-range-cell';
import TariffRateCell from './tariff-rate-cell';

const TariffGroupGridContent = () => {
  const [
    tariffType,
    zoneType,
    locationZonesData,
    xRangeValues,
    yRangeValues,
    deleteRowFromRateMatrix,
  ] = useTariffGroupStore(
    (state) => [
      state.tariffType,
      state.zoneType,
      state.locationZonesData,
      state.xRangeValues,
      state.yRangeValues,
      state.deleteRowFromRateMatrix,
    ],
    shallow,
  );

  return (
    <TableBody>
      {[TariffType.NoUnits, TariffType.PerCubicFoot].includes(tariffType) ? (
        <TableRow>
          {zoneType === TariffZoneType.Location
            ? locationZonesData.map((locationZone, colIndex) => (
                <TariffRateCell
                  key={locationZone.uuid}
                  rowIndex={0}
                  colIndex={colIndex}
                />
              ))
            : xRangeValues.map((_, colIndex) => (
                // eslint-disable-next-line react/jsx-key
                <TariffRateCell rowIndex={0} colIndex={colIndex} />
              ))}
        </TableRow>
      ) : (
        yRangeValues.map((_, rowIndex) => (
          // eslint-disable-next-line react/jsx-key
          <TableRow>
            <TariffRangeCell
              rangeToModify={TariffRange.Y}
              rangeIndex={rowIndex}
            />
            {zoneType === TariffZoneType.Universal && (
              <TariffRateCell rowIndex={rowIndex} colIndex={0} />
            )}
            {zoneType === TariffZoneType.Location &&
              locationZonesData.map((locationZone, colIndex) => (
                <TariffRateCell
                  key={locationZone.uuid}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                />
              ))}
            {zoneType === TariffZoneType.Miles &&
              xRangeValues.map((__, colIndex) => (
                // eslint-disable-next-line react/jsx-key
                <TariffRateCell rowIndex={rowIndex} colIndex={colIndex} />
              ))}
            <TableCell>
              <IconButton
                color="error"
                onClick={() => deleteRowFromRateMatrix(rowIndex)}
              >
                <CloseIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      )}
    </TableBody>
  );
};

export default TariffGroupGridContent;
