import {
  Accordion,
  AccordionDetails,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import DocumentTypeSelector from '../../../../../common/components/document-type-selector';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  ContactForInvoiceFragment,
  DocumentType,
  InvoiceTransmissionMethod,
  InvoiceType,
  ShallowInvoiceWithoutShipmentsFragment,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useInvoicesStore from '../../../invoices-store';
import AccordionSummary from '../../accordion-summary';

const PostAndSendInvoicePreviewCard = ({
  invoice,
  contact,
  documentTypesForSelection,
}: {
  invoice: ShallowInvoiceWithoutShipmentsFragment;
  contact: ContactForInvoiceFragment | null | undefined;
  documentTypesForSelection: DocumentType[];
}) => {
  const { companyConfiguration } = useMe();
  const theme = useTheme();
  const options = useInvoicesStore((state) =>
    state.invoicesToSendOptions.find(
      (option) => option.invoiceUuid === invoice.uuid,
    ),
  );
  const [
    setInvoiceEmailSubject,
    setInvoiceNote,
    setInvoiceSelectedEmails,
    setInvoiceTransmissionMethod,
    setInvoiceDownloadType,
    setInvoiceAttachments,
  ] = useInvoicesStore(
    (state) => [
      state.setInvoiceEmailSubject,
      state.setInvoiceNote,
      state.setInvoiceSelectedEmails,
      state.setInvoiceTransmissionMethod,
      state.setInvoiceDownloadType,
      state.setInvoiceAttachments,
    ],
    shallow,
  );
  const ffSupportSelectedEmailsAndNote = useFeatureFlag(
    FeatureFlag.FF_SUPPORT_SELECTED_EMAILS_AND_NOTE,
  );

  useEffect(() => {
    if (contact?.__typename === 'CustomerContactEntity') {
      setInvoiceTransmissionMethod(
        invoice.uuid,
        contact.defaultInvoiceTransmissionMethod,
      );
      setInvoiceAttachments(invoice.uuid, documentTypesForSelection);
      setInvoiceDownloadType(invoice.uuid, contact.defaultInvoiceType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, documentTypesForSelection]);

  const invoiceHasOne210Mapping: boolean =
    invoice.billToContact?.ediPartnerships?.some(
      (partnership) =>
        !isNil(partnership.stedi210MappingId) &&
        partnership.stedi210MappingId !== '',
    ) ?? false;

  const showEmailAndNoteOptions =
    ffSupportSelectedEmailsAndNote &&
    (options?.invoiceTransmissionMethod === InvoiceTransmissionMethod.Email ||
      options?.invoiceTransmissionMethod ===
        InvoiceTransmissionMethod.EmailAndDownload);

  const noEmailOptions = useMemo(() => {
    return (
      isNil(options?.invoiceEmailOptions) ||
      isEmpty(options?.invoiceEmailOptions)
    );
  }, [options?.invoiceEmailOptions]);

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{ border: '1px solid', borderColor: theme.palette.borderColor.main }}
    >
      <AccordionSummary>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ marginLeft: '10px' }}>
              <Typography>
                {companyConfiguration?.useJournalNumberForInvoice === true
                  ? invoice.journalNumber
                  : invoice.name}
              </Typography>
              <Typography>{invoice.billToContact.displayName}</Typography>
              <Typography color="text.secondary">•</Typography>
              <Typography color="text.secondary">
                {dayjs(invoice.date).format('MM/DD/YYYY')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="transmission-method-label">
                Transmission Method
              </InputLabel>
              <Select
                size="small"
                fullWidth
                labelId="transmission-method-label"
                id="transmission-method-select"
                value={options?.invoiceTransmissionMethod ?? ''}
                label="Transmission method"
                onChange={(e) => {
                  setInvoiceTransmissionMethod(
                    invoice.uuid,
                    e.target.value as InvoiceTransmissionMethod,
                  );
                }}
              >
                <MenuItem value={InvoiceTransmissionMethod.None}>
                  {sentenceCase(InvoiceTransmissionMethod.None)}
                </MenuItem>
                <MenuItem value={InvoiceTransmissionMethod.Download}>
                  {sentenceCase(InvoiceTransmissionMethod.Download)}
                </MenuItem>
                <MenuItem value={InvoiceTransmissionMethod.Email}>
                  {sentenceCase(InvoiceTransmissionMethod.Email)}
                </MenuItem>
                <MenuItem value={InvoiceTransmissionMethod.EmailAndDownload}>
                  {sentenceCase(InvoiceTransmissionMethod.EmailAndDownload)}
                </MenuItem>
                {invoiceHasOne210Mapping && (
                  <MenuItem value={InvoiceTransmissionMethod.Edi}>
                    {sentenceCase(InvoiceTransmissionMethod.Edi)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {options?.invoiceTransmissionMethod !==
              InvoiceTransmissionMethod.Edi && (
              <Stack spacing={2} sx={{ marginTop: 2 }}>
                <FormControl>
                  <InputLabel id="invoice-type-label">Invoice type</InputLabel>
                  <Select
                    labelId="invoice-type-label"
                    onChange={(e) => {
                      setInvoiceDownloadType(
                        invoice.uuid,
                        e.target.value as InvoiceType,
                      );
                    }}
                    label="Invoice Type"
                    value={options?.invoiceDownloadType ?? ''}
                    required
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                  >
                    {Object.values(InvoiceType).map((type) => (
                      <MenuItem key={type} id={type} value={type}>
                        {sentenceCase(type)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <DocumentTypeSelector
                    label="Invoice attachments"
                    value={options?.invoiceAttachments ?? []}
                    onChange={(newInvoiceAttachments) =>
                      setInvoiceAttachments(invoice.uuid, newInvoiceAttachments)
                    }
                  />
                </FormControl>
              </Stack>
            )}
          </Grid>
          {showEmailAndNoteOptions && (
            <Grid item xs={6}>
              <Stack spacing={2}>
                <AutocompleteFuzzy
                  multiple
                  value={options?.invoiceSelectedEmails ?? []}
                  options={['All', ...(options?.invoiceEmailOptions ?? [])]}
                  disabled={noEmailOptions}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={
                        noEmailOptions
                          ? 'No emails found for this contact'
                          : 'Emails'
                      }
                      autoComplete="off"
                    />
                  )}
                  onChange={(_, newValue) => {
                    if (newValue.includes('All')) {
                      setInvoiceSelectedEmails(
                        invoice.uuid,
                        options?.invoiceEmailOptions ?? [],
                      );
                    } else {
                      setInvoiceSelectedEmails(invoice.uuid, newValue);
                    }
                  }}
                />
                <TextField
                  label="Subject"
                  size="small"
                  fullWidth
                  value={options.invoiceEmailSubject ?? ''}
                  onChange={(e) => {
                    setInvoiceEmailSubject(invoice.uuid, e.target.value);
                  }}
                />
                <TextField
                  multiline
                  placeholder="Add a note to be included in the email"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setInvoiceNote(invoice.uuid, e.target.value as string);
                  }}
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(PostAndSendInvoicePreviewCard);
