import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  AppointmentFragment,
  PermissionResource,
} from '../../../../generated/graphql';
import AppointmentContextMenu from './context-menu';
import RemoveFromRoutesConfirmationModal from './remove-from-routes-confirmation';

const MoreActionsButton = ({
  selectedStops,
  isContextMenuOpen,
  setIsContextMenuOpen,
}: {
  selectedStops: AppointmentFragment[];
  isContextMenuOpen: boolean;
  setIsContextMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteAppointments } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Appointments,
  );

  const ref = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const [
    showRemoveFromRoutesConfirmation,
    setShowRemoveFromRoutesConfirmation,
  ] = useState(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <RemoveFromRoutesConfirmationModal
        open={showRemoveFromRoutesConfirmation}
        setOpen={setShowRemoveFromRoutesConfirmation}
        selectedStops={selectedStops}
      />
      <IconButton
        onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
        ref={ref}
        sx={{ p: 0 }}
        disabled={!canWriteAppointments}
      >
        <MoreVert />
      </IconButton>
      {isContextMenuOpen && (
        <AppointmentContextMenu
          setShowContextMenu={setIsContextMenuOpen}
          buttonRef={ref}
          menuStyling={{
            position: 'absolute',
            border: '1px solid',
            right: '15px',
            borderColor: theme.palette.borderColor.main,
            backgroundColor: 'white',
            zIndex: 1,
            borderRadius: '5px',
          }}
          setShowRemoveFromRoutesConfirmation={
            setShowRemoveFromRoutesConfirmation
          }
          selectedStops={selectedStops}
        />
      )}
    </Box>
  );
};

export default MoreActionsButton;
