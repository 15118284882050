import { Box, Card, CardContent, Typography } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ReportStatistic } from '../../../generated/graphql';
import useReportsStore from '../reports-store';
import {
  DriverReportBucketData,
  ReportGroupConfiguration,
} from '../types/report-types';
import { getReportLimitMessage, REPORT_Y_AXIS_LIMIT } from './utils';

export type ReportStatisticConfig = {
  key: string;
  name: string;
  color: string;
  configKey: ReportStatistic;
};

type ReportBarChartProps = {
  reportStatisticsConfig: ReportStatisticConfig[];
  height: number | undefined;
  xLabel?: string | null;
  data: DriverReportBucketData[];
};

declare type CustomTooltipProps<
  Value extends ValueType,
  Name extends NameType,
> = TooltipProps<Value, Name> & {
  reportGroupConfiguration: ReportGroupConfiguration | undefined;
  reportStatisticsConfig: ReportStatisticConfig[];
};

const CustomTooltip = ({
  active,
  payload,
  reportGroupConfiguration,
  reportStatisticsConfig,
}: CustomTooltipProps<ValueType, NameType>) => {
  if (Boolean(active) && !isNil(payload) && !isNil(payload[0])) {
    const bucket = payload[0]?.payload;
    return (
      <Card>
        <CardContent>
          <Typography>{bucket?.driverName}</Typography>
          {reportStatisticsConfig.map((statistic) => {
            if (
              !isNil(reportGroupConfiguration) &&
              reportGroupConfiguration.reportStatistics.includes(
                statistic.configKey,
              )
            ) {
              return (
                <Typography color={statistic.color} key={statistic.key}>
                  {statistic.name}:{' '}
                  {statistic.configKey !== ReportStatistic.NumberOfOrders &&
                  statistic.configKey !== ReportStatistic.NumberOfPackages
                    ? currency(bucket[statistic.key], {
                        separator: ',',
                      }).format()
                    : bucket[statistic.key]}
                </Typography>
              );
            }
            return <Box key={statistic.key} />;
          })}
        </CardContent>
      </Card>
    );
  }

  return null;
};

const DriverReportBarChart = ({
  reportStatisticsConfig,
  height,
  xLabel,
  data,
}: ReportBarChartProps) => {
  const reportGroupConfiguration = useReportsStore(
    (state) => state.reportGroupConfigurations[state.currentReportIndex],
  );
  if (data.length > REPORT_Y_AXIS_LIMIT) {
    return (
      <Typography>
        {getReportLimitMessage({
          reportType: 'drivers',
        })}
      </Typography>
    );
  }

  return (
    <ResponsiveContainer width="99.9%" height={height}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 5,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          content={
            <CustomTooltip
              reportGroupConfiguration={reportGroupConfiguration}
              reportStatisticsConfig={reportStatisticsConfig}
            />
          }
        />
        <YAxis
          type="category"
          dataKey="driverName"
          yAxisId={0}
          interval={0}
          width={200}
        />
        <XAxis
          type="number"
          label={{
            value: xLabel,
            textAnchor: 'middle',
            dy: 15,
          }}
          height={50}
        />
        {reportStatisticsConfig.map((statistic) => {
          if (
            !isNil(reportGroupConfiguration) &&
            reportGroupConfiguration.reportStatistics.includes(
              statistic.configKey,
            )
          ) {
            return (
              <Bar
                key={statistic.key}
                dataKey={statistic.key}
                name={statistic.name}
                fill={statistic.color}
              />
            );
          }
          return <Box key={statistic.key} />;
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default DriverReportBarChart;
