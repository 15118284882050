import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ShallowTerminalFragment } from '../../../../generated/graphql';

const DestinationOriginSelectors = ({
  originTerminalUuid,
  setOriginTerminalUuid,
  destinationTerminalUuid,
  setDestinationTerminalUuid,
  intermediateTerminalUuids,
  terminals,
  setErrorMessage,
}: {
  originTerminalUuid: string | undefined;
  setOriginTerminalUuid: Dispatch<SetStateAction<string | undefined>>;
  destinationTerminalUuid: string | undefined;
  setDestinationTerminalUuid: Dispatch<SetStateAction<string | undefined>>;
  intermediateTerminalUuids: string[] | undefined;
  terminals: ShallowTerminalFragment[] | undefined;
  setErrorMessage: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <Stack
      direction="row"
      gap="30px"
      alignItems="center"
      justifyContent="center"
    >
      <Stack gap="10px">
        <Typography> Origin terminal </Typography>
        <Select
          // placeholder="Origin terminal"
          onChange={(e) => {
            setOriginTerminalUuid(e.target.value);
            setErrorMessage('');
          }}
          size="small"
          sx={{ minWidth: '300px' }}
          value={originTerminalUuid}
        >
          {terminals
            ?.filter(
              (t) =>
                t.uuid !== destinationTerminalUuid &&
                !(intermediateTerminalUuids ?? []).includes(t.uuid),
            )
            .map((terminal: ShallowTerminalFragment) => {
              return <MenuItem value={terminal.uuid}>{terminal.name}</MenuItem>;
            })}
        </Select>
      </Stack>
      <Stack gap="10px">
        <Typography> Destination terminal </Typography>
        <Select
          placeholder="Destination terminal"
          onChange={(e) => {
            setDestinationTerminalUuid(e.target.value);
            setErrorMessage('');
          }}
          size="small"
          sx={{ minWidth: '300px' }}
          value={destinationTerminalUuid}
        >
          {terminals
            ?.filter(
              (t) =>
                t.uuid !== originTerminalUuid &&
                !(intermediateTerminalUuids ?? []).includes(t.uuid),
            )
            .map((terminal: ShallowTerminalFragment) => {
              return <MenuItem value={terminal.uuid}>{terminal.name}</MenuItem>;
            })}
        </Select>
      </Stack>
    </Stack>
  );
};

export default DestinationOriginSelectors;
