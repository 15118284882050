import { intersection, isNil, uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { v4 } from 'uuid';
import {
  AutoApplyAccessorialConfigFragment,
  AutoApplyAccessorialRuleType,
  CustomChargeBillingMethod,
  ShipmentStatus,
  StandardStopType,
  useAutoApplyAccessorialConfigsQuery,
} from '../../../generated/graphql';
import {
  getOtherStopIdx,
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../../orders/components/order-form/components/constants';
import { StopType } from '../../orders/components/order-form/forms/stop-type';
import { CustomChargeValues } from '../../orders/components/order-form/forms/types';
import { getPickupOrDelivery } from '../../orders/components/order-form/forms/utils';
import { useOrderFormAccessorials } from '../../orders/components/order-form/hooks/use-order-form-accessorials';
import { OrderFormFieldValues } from '../../orders/components/order-form/types';
import useOrderFormStore from '../../orders/order-form-store';

/**
 * Hook which can be used to auto-apply accessorials
 *
 * Be sure to only call it when loading is false, otherwise it won't do anything
 */
export const useAutoApplyAccessorials = ({
  contactUuid,
}: {
  contactUuid: string;
}) => {
  const { getValues, setValue } = useFormContext<OrderFormFieldValues>();
  const setIsOrderPageRating = useOrderFormStore(
    (state) => state.setIsOrderPageRating,
  );
  const {
    data: autoApplyAccessorialConfigsData,
    loading: autoApplyAccessorialConfigsLoading,
  } = useAutoApplyAccessorialConfigsQuery();
  const { accessorials, loading: loadingAccessorials } =
    useOrderFormAccessorials();

  useEffect(() => {
    setIsOrderPageRating(
      loadingAccessorials || autoApplyAccessorialConfigsLoading,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAccessorials, autoApplyAccessorialConfigsLoading]);

  const stopTypeIsBillable = (stopType: StopType): boolean => {
    switch (stopType) {
      case StopType.Pickup:
      case StopType.Delivery:
      case StopType.Recovery:
      case StopType.Transfer:
        return true;
      case undefined:
      case StopType.PartnerCarrierDropoff:
      case StopType.PartnerCarrierPickup:
      case StopType.None:
        return false;
      default:
        return exhaustive(stopType);
    }
  };

  // If either stop is billable and not partner carrier, then we can't have order charges
  const shouldClearOrderCharges = (
    inboundStopType: StopType,
    outboundStopType: StopType,
  ): boolean => {
    const isBillableInbound = stopTypeIsBillable(inboundStopType);
    const isBillableOutbound = stopTypeIsBillable(outboundStopType);
    const isNotPartnerCarrierInbound =
      inboundStopType !== StopType.PartnerCarrierDropoff;
    const isNotPartnerCarrierOutbound =
      outboundStopType !== StopType.PartnerCarrierPickup;

    return (
      (isBillableInbound || isBillableOutbound) &&
      isNotPartnerCarrierInbound &&
      isNotPartnerCarrierOutbound
    );
  };

  // This was breaking localdev (null is passed in here for some reason, we don't know why)
  // TODO: Investigate why its being passed in, fix, and remove this case
  const statusIsFinalized = (status: ShipmentStatus | null): boolean => {
    switch (status) {
      case ShipmentStatus.Created:
      case ShipmentStatus.InProgress:
      case ShipmentStatus.OutForDelivery:
      case ShipmentStatus.Delivered:
      case ShipmentStatus.HasIssue:
      case null:
        return false;
      case undefined:
      case ShipmentStatus.Finalized:
      case ShipmentStatus.Invoiced:
      case ShipmentStatus.Paid:
        return true;
      default:
        return exhaustive(status);
    }
  };

  /**
   * Return true/false if the rule applies to the given stop information
   *
   * This check only includes "global" checks that could apply to both stops
   */
  const accessorialRuleApplies = ({
    addressType,
    autoApplyAccessorialConfig,
    serviceUuid,
    terminalUuid,
    tagUuids,
    isInBond,
    isHazmat,
  }: {
    addressType: StandardStopType | null | undefined;
    autoApplyAccessorialConfig: AutoApplyAccessorialConfigFragment;
    serviceUuid: string | null | undefined;
    terminalUuid: string | null | undefined;
    tagUuids: string[];
    isInBond: boolean | null | undefined;
    isHazmat: boolean | null | undefined;
  }) => {
    const isGlobal =
      autoApplyAccessorialConfig.ruleType ===
      AutoApplyAccessorialRuleType.Global;
    const matchesContact =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.Contact &&
      autoApplyAccessorialConfig.contact?.uuid === contactUuid;
    const matchesService =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.Service &&
      autoApplyAccessorialConfig.service?.uuid === serviceUuid;
    const matchesContactAndService =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.ContactAndService &&
      autoApplyAccessorialConfig.contact?.uuid === contactUuid &&
      autoApplyAccessorialConfig.service?.uuid === serviceUuid;
    const matchesContactAndServiceAndTag =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.ContactAndServiceAndTag &&
      autoApplyAccessorialConfig.contact?.uuid === contactUuid &&
      autoApplyAccessorialConfig.service?.uuid === serviceUuid &&
      tagUuids.includes(autoApplyAccessorialConfig.tag?.uuid ?? '');
    const matchesServiceAndTag =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.ServiceAndTag &&
      autoApplyAccessorialConfig.service?.uuid === serviceUuid &&
      tagUuids.includes(autoApplyAccessorialConfig.tag?.uuid ?? '');
    const matchesResidential =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.Residential &&
      addressType === StandardStopType.Residential;
    const matchesTerminal =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.StopTypeAndTerminal &&
      autoApplyAccessorialConfig.terminal?.uuid === terminalUuid;
    const matchesIsInBond =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.IsInBond && isInBond === true;
    const matchesIsHazmat =
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.IsHazmat && isHazmat === true;
    return (
      isGlobal ||
      matchesContact ||
      matchesService ||
      matchesContactAndService ||
      matchesContactAndServiceAndTag ||
      matchesServiceAndTag ||
      matchesResidential ||
      matchesTerminal ||
      matchesIsInBond ||
      matchesIsHazmat
    );
  };

  /**
   * Determine if the config should apply to the given stop based on the
   * config's criteria
   *
   * In addition to matching the scope of the rule, this also checks if the
   * accessorial config applies to both stops, and determines which stop it
   * should apply to.
   *
   * Any config type that's not specific to a stop type should only apply to
   * one of the stops. The hierarchy goes:
   * - Delivery
   * - Pickup
   * - Transfer
   * - Recovery
   *
   * @param autoApplyAccessorialConfig
   * @param contactUuid
   * @param serviceUuid
   * @param stopIdx - Null for order charges
   */
  const shouldApply = ({
    autoApplyAccessorialConfig,
    serviceUuid,
    stopIdx,
  }: {
    autoApplyAccessorialConfig: AutoApplyAccessorialConfigFragment;
    serviceUuid: string | null | undefined;
    stopIdx: number | null;
  }) => {
    const addressType = !isNil(stopIdx)
      ? getValues(`stops.${stopIdx}.standardStopType`)
      : null;
    const stopType = !isNil(stopIdx)
      ? getValues(`stops.${stopIdx}.stopType`)
      : null;
    const terminalUuid = !isNil(stopIdx)
      ? getValues(`stops.${stopIdx}.terminalUuid`)
      : null;
    const tagUuids = (getValues(`tags`) ?? []).map((t) => t.uuid);
    const isHazmat = getValues(`hazmat`);
    const isInBond = getValues(`inBond`);

    // If it matches this specific stop type, always use
    if (
      !isNil(stopType) &&
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.StopType &&
      stopType !== StopType.PartnerCarrierDropoff &&
      stopType !== StopType.PartnerCarrierPickup &&
      stopType !== StopType.None &&
      autoApplyAccessorialConfig.stopType === getPickupOrDelivery(stopType)
    ) {
      return true;
    }

    // If its StopTypeAndTerminal and the the stopType doesn't match
    if (
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.StopTypeAndTerminal &&
      (isNil(stopType) ||
        stopType === StopType.None ||
        autoApplyAccessorialConfig.stopType !== getPickupOrDelivery(stopType))
    ) {
      return false;
    }

    if (
      !accessorialRuleApplies({
        addressType,
        autoApplyAccessorialConfig,
        serviceUuid,
        terminalUuid,
        tagUuids,
        isHazmat,
        isInBond,
      })
    ) {
      return false;
    }

    // If this was called for the order charge shipment and there are no other
    // stops, then apply it
    if (isNil(stopIdx)) {
      const inboundStopType = getValues(`stops.${INBOUND_STOP_IDX}.stopType`);
      const outboundStopType = getValues(`stops.${OUTBOUND_STOP_IDX}.stopType`);

      return (
        !stopTypeIsBillable(inboundStopType) &&
        !stopTypeIsBillable(outboundStopType)
      );
    }

    // Need to check if it matches the other stop, if it does then we have to
    // pick which stop to apply to
    const otherStopIdx = getOtherStopIdx(stopIdx);
    const otherAddressType = getValues(
      `stops.${otherStopIdx}.standardStopType`,
    );
    const otherStopType = getValues(`stops.${otherStopIdx}.stopType`);
    if (
      autoApplyAccessorialConfig.ruleType ===
        AutoApplyAccessorialRuleType.StopTypeAndTerminal || // StopTypeAndTerminal can only apply to the current stop
      isNil(otherAddressType) ||
      !accessorialRuleApplies({
        addressType: otherAddressType,
        autoApplyAccessorialConfig,
        serviceUuid,
        terminalUuid: null,
        tagUuids,
        isHazmat,
        isInBond,
      })
    ) {
      // Only applies to this stop, so apply it
      return true;
    }

    // Pick the stop with higher priority - delivery > pickup > transfer >
    // recovery
    if (stopType === StopType.Delivery) {
      return true;
    }
    if (otherStopType === StopType.Delivery) {
      return false;
    }
    if (stopType === StopType.Pickup) {
      return true;
    }
    if (otherStopType === StopType.Pickup) {
      return false;
    }
    if (stopType === StopType.Recovery) {
      return true;
    }
    if (otherStopType === StopType.Recovery) {
      return false;
    }
    if (stopType === StopType.Transfer) {
      return true;
    }
    if (otherStopType === StopType.Transfer) {
      return false;
    }

    return false;
  };

  /**
   * Apply the auto-apply accessorial configs and return the new set of custom
   * charges
   *
   * This will remove auto-applied custom charges that no longer match and add
   * new custom charges that do match. This won't affect any manually applied
   * custom charges. Manually applied custom charges that match an accessorial
   * that would have been auto-applied will block it from being auto-applied
   * again.
   *
   * @param stopIdx Index of the stop, pass in null to represent the order
   * charges shipment
   */
  const autoApplyAccessorials = ({ stopIdx }: { stopIdx: number | null }) => {
    let customCharges: CustomChargeValues[] = [];
    const deletedAutoAppliedAccessorials =
      getValues('deletedAutoAppliedAccessorials') ?? [];
    if (isNil(stopIdx)) {
      const orderChargeShipmentStatus = getValues(
        'orderChargesShipment.shipmentStatus',
      );
      if (
        !isNil(orderChargeShipmentStatus) &&
        statusIsFinalized(orderChargeShipmentStatus)
      ) {
        return;
      }
      const inboundStopType = getValues(`stops.${INBOUND_STOP_IDX}.stopType`);
      const outboundStopType = getValues(`stops.${OUTBOUND_STOP_IDX}.stopType`);

      customCharges = getValues('orderChargesShipment.customCharges') ?? [];
      if (shouldClearOrderCharges(inboundStopType, outboundStopType)) {
        setValue(
          'orderChargesShipment.customCharges',
          customCharges.filter((c) => !c.isAutoApplied),
        );
        return;
      }
    } else {
      const shipmentStatus = getValues(`stops.${stopIdx}.shipmentStatus`);
      if (!isNil(shipmentStatus) && statusIsFinalized(shipmentStatus)) {
        return;
      }
      customCharges = getValues(`stops.${stopIdx}.customCharges`) ?? [];
    }
    const serviceUuid = getValues(`serviceUuid`);
    // This does nothing if the data isn't loaded. Only call this function is
    // loading is false
    const autoApplyAccessorialConfigs =
      autoApplyAccessorialConfigsData?.autoApplyAccessorialConfigs;
    if (isNil(autoApplyAccessorialConfigs)) {
      // eslint-disable-next-line no-console
      console.warn(
        'Accessorial config still loading, could not auto apply accessorials',
      );
      return;
    }
    if (isNil(accessorials)) {
      // eslint-disable-next-line no-console
      console.warn(
        'Accessorials for billing contact still loading, could not auto apply accessorials',
      );
      return;
    }

    const autoAppliedCustomCharges = customCharges.filter((c) => {
      return c.isAutoApplied;
    });

    // Remove applied custom charges that don't meet the criteria.
    const accessorialsToRemove = autoAppliedCustomCharges.filter(
      (customCharge) => {
        const { accessorialUuid } = customCharge;
        if (isNil(accessorialUuid)) {
          return false;
        }
        // If global accessorial already applied but contact-specific accessorial should be auto-applied instead
        const contactSpecificAccessorial = accessorials?.find(
          (acc) =>
            !isNil(acc.contact) &&
            acc.matchingGlobalAccessorial?.uuid === accessorialUuid,
        );
        if (!isNil(contactSpecificAccessorial)) {
          return true;
        }

        // if there are no longer any configs that cover the applied custom charge, then keep it
        if (
          autoApplyAccessorialConfigs.every((config) =>
            config.accessorials.every((acc) => acc.uuid !== accessorialUuid),
          )
        ) {
          return false;
        }

        for (const config of autoApplyAccessorialConfigs) {
          if (config.accessorials.some((acc) => acc.uuid === accessorialUuid)) {
            if (
              shouldApply({
                autoApplyAccessorialConfig: config,
                serviceUuid,
                stopIdx,
              })
            ) {
              return false;
            }
          }
        }
        return true;
      },
    );

    customCharges = customCharges.filter(
      (c) =>
        !accessorialsToRemove.some(
          (customCharge) => customCharge.uuid === c.uuid,
        ),
    );
    const existingAccessorialUuids: string[] = filterNotNil(
      customCharges.map((c) => c.accessorialUuid) ?? [],
    );

    // Apply custom charges for configs that meet the criteria.
    const accessorialsToAutoApply = uniqBy(
      autoApplyAccessorialConfigs.flatMap((autoApplyAccessorialConfig) => {
        const shouldApplyAccessorials = shouldApply({
          autoApplyAccessorialConfig,
          serviceUuid,
          stopIdx,
        });
        if (!shouldApplyAccessorials) {
          return [];
        }
        const contactsToExcludeFromRule =
          autoApplyAccessorialConfig.contactsToExcludeFromRule ?? [];
        if (
          contactsToExcludeFromRule.map((c) => c.uuid).includes(contactUuid)
        ) {
          return [];
        }
        const contactsToIncludeWithRule =
          autoApplyAccessorialConfig.contactsToIncludeWithRule ?? [];
        if (
          contactsToIncludeWithRule.length > 0 &&
          !contactsToIncludeWithRule.map((c) => c.uuid).includes(contactUuid)
        ) {
          return [];
        }
        return autoApplyAccessorialConfig.accessorials.filter((accessorial) => {
          const accessorialAlreadyExists = existingAccessorialUuids.includes(
            accessorial.uuid,
          );
          const parentAccessorialAlreadyExists = !isNil(
            accessorial.matchingGlobalAccessorial,
          )
            ? existingAccessorialUuids.includes(
                accessorial.matchingGlobalAccessorial.uuid,
              )
            : false;
          const childAccessorialAlreadyExists = (
            accessorial.matchingCustomerAccessorials ?? []
          ).some((a) => {
            return existingAccessorialUuids.includes(a.uuid);
          });
          // The UUID of the child accessorial is what gets stored as deleted -
          // not sure if that's the right behavior but this code is a reaction to
          // that
          const configChildrenAccessorials =
            accessorial.matchingCustomerAccessorials?.map((a) => a.uuid) ?? [];
          const accessorialWasDeleted =
            deletedAutoAppliedAccessorials.includes(accessorial.uuid) ||
            intersection(
              deletedAutoAppliedAccessorials,
              configChildrenAccessorials,
            ).length > 0;
          return (
            !accessorialAlreadyExists &&
            !parentAccessorialAlreadyExists &&
            !childAccessorialAlreadyExists &&
            !accessorialWasDeleted
          );
        });
      }),
      (accessorial) => accessorial.uuid,
    );

    // accessorialsToAutoApply could contain a global accessorial which has null contact,
    // but we would want to apply the contact-specific one if it exists.
    // e.g if we autoApply a 'Special' but this contact has a contact-specific 'Special' we'd want
    // to apply the latter
    const accessorialsToAutoApplyWithContactSpecificSubstitutions =
      accessorialsToAutoApply.map((accessorial) => {
        // this accessorial is global
        if (isNil(accessorial.contact)) {
          const contactSpecificAccessorials = accessorials.filter(
            (acc) => !isNil(acc.contact),
          );
          const contactSpecificAccessorial = contactSpecificAccessorials.find(
            (acc) => acc.matchingGlobalAccessorial?.uuid === accessorial.uuid,
          );

          if (!isNil(contactSpecificAccessorial))
            return contactSpecificAccessorial;
        }
        return accessorial;
      });

    const accessorialsToAdd: CustomChargeValues[] =
      accessorialsToAutoApplyWithContactSpecificSubstitutions.map(
        (accessorial) => {
          const customChargeUuid = v4();
          return {
            uuid: customChargeUuid,
            rate: null,
            quantity: 1,
            isAutoApplied: true,
            isLocal: true,
            billingMethod: CustomChargeBillingMethod.Accessorial,
            name: accessorial.name,
            accessorialUuid: accessorial.uuid,
            zoneUuid:
              accessorial.__typename === 'ZoneBasedAccessorialEntity'
                ? (accessorial?.zones?.at(0)?.uuid ?? null)
                : null,
            chargeGroupUuid:
              accessorial.__typename === 'ZoneBasedAccessorialEntity' ||
              accessorial.__typename === 'SpecialAccessorialEntity'
                ? (accessorial?.chargeGroups?.at(0)?.uuid ?? null)
                : null,
            deductionTotal: 0,
            fuelSurchargePercentageRate: null,
            totalCharge: 0,
            accessorialName: accessorial.name,
            specialAccessorialMatrixItemUuid: null,
            zoneBasedAccessorialMatrixItemUuid: null,
            accessorialRangeUuid: null,
            postedFuelSurchargeRate: null,
            description: '',
            authoCode: null,
            settlementPercentageRate: null,
            settlementFlatRate: null,
            settlementBillingMethod: null,
            useAccessorialRate: true,
          };
        },
      ) ?? [];
    if (isNil(stopIdx)) {
      setValue('orderChargesShipment.customCharges', [
        ...customCharges,
        ...accessorialsToAdd,
      ]);
    } else {
      setValue(`stops.${stopIdx}.customCharges`, [
        ...customCharges,
        ...accessorialsToAdd,
      ]);
    }
  };

  return {
    autoApplyAccessorials,
    loading: autoApplyAccessorialConfigsLoading || loadingAccessorials,
  };
};
