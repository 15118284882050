import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import useDrivers from '../../../../common/react-hooks/use-drivers';
import { useCreateDriverSettlementBillMutation } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDriverSettlementStore from '../../driver-settlement-store';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  padding: 2,
};

const CreateEmptyInvoiceModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [createSettlement] = useCreateDriverSettlementBillMutation();
  const [driverUuid, setDriverUuid] = useState<string>();
  const { drivers, getDriverName } = useDrivers();
  const currentDriver = drivers?.find((driver) => driver.uuid === driverUuid);
  const setShouldRefreshSettlementsList = useDriverSettlementStore(
    (state) => state.setShouldRefreshSettlementsList,
  );

  const onClickCreateSettlement = async () => {
    if (!isNil(driverUuid)) {
      await createSettlement({
        variables: {
          createDriverSettlementBillInput: {
            driverSettlementBillCreateInput: {
              driverUuid,
              settlementDate: new Date(),
            },
          },
        },
      });
      setIsOpen(false);
      setShouldRefreshSettlementsList(true);
    }
  };

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6" sx={{ mb: '20px' }}>
          Create an Empty Settlement
        </Typography>
        <Typography sx={{ mb: '10px' }}>Select a driver</Typography>
        <AutocompleteFuzzy
          size="small"
          sx={{ width: '300px' }}
          value={
            !isNil(drivers) && !isNil(currentDriver)
              ? {
                  value: currentDriver.uuid,
                  label: getDriverName(currentDriver.uuid),
                }
              : { value: '', label: '' }
          }
          options={(drivers ?? []).map((driver) => ({
            value: driver.uuid,
            label: getDriverName(driver.uuid),
          }))}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              size="small"
            />
          )}
          onChange={(event, option) => {
            setDriverUuid(option?.value);
          }}
        />
        <DialogActions>
          <Button
            variant="contained"
            sx={{ mt: '20px' }}
            onClick={onClickCreateSettlement}
          >
            Create Settlement
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateEmptyInvoiceModal;
