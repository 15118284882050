import { Alert } from '@mui/material';
import type { CSSProperties, FunctionComponent } from 'react';
import theme from '../../theme';

const style: CSSProperties = {
  whiteSpace: 'pre-line',
  // This margin allows the component to be used without worrying about making sure there's a reasonable space
  // between the alert and the content that follows when the alert is shown.
  marginBottom: theme.spacing(0.5),
};

type ErrorsAlertProps = {
  errors: string[];
  onClear?: () => void;
};

/**
 * An error alert that displays all the provided errors.
 */
export const ErrorsAlert: FunctionComponent<ErrorsAlertProps> = ({
  errors,
  onClear,
}) => {
  if (errors.length === 0) {
    return null;
  }
  return (
    <Alert severity="error" style={style} onClose={onClear}>
      {errors.join('\n')}
    </Alert>
  );
};
