import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useContactsForInvoicesLazyQuery } from '../../../../generated/graphql';
import CustomerListRow from './customer-list-row';

interface CustomerListProps {
  openedCustomerUuid: string | undefined;
}

const DEFAULT_CONTACTS_PAGE_SIZE = 100;

const CustomerList = ({ openedCustomerUuid }: CustomerListProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 200);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_CONTACTS_PAGE_SIZE,
  );
  const [page, setPage] = useState<number>(0);
  const [getContacts, { data: contactsData, loading }] =
    useContactsForInvoicesLazyQuery();

  const fetchContacts = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    await getContacts({
      variables: {
        first,
        after,
        last,
        before,
        searchText,
        sortByContactName: true,
      },
    });
  };

  const prev = async () => {
    await fetchContacts({
      last: rowsPerPage,
      before: contactsData?.paginatedContacts.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchContacts({
      first: rowsPerPage,
      after: contactsData?.paginatedContacts.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchContacts({ first: rowsPerPage });
    setPage(0);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  return (
    <Stack height="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2 }}
      >
        <TextField
          sx={{ width: '170px' }}
          size="small"
          label="Search Customers"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Stack direction="row" alignItems="center">
          <TablePagination
            rowsPerPageOptions={[]}
            labelRowsPerPage="Show"
            component="div"
            count={contactsData?.paginatedContacts.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next();
              } else {
                prev();
              }
              setPage(newPage);
            }}
            backIconButtonProps={{
              disabled: loading === true || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                loading === true ||
                contactsData?.paginatedContacts.totalCount === 0 ||
                page + 1 ===
                  Math.ceil(
                    (contactsData?.paginatedContacts.totalCount ?? 0) /
                      rowsPerPage,
                  ),
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(+e.target.value);
            }}
          />
        </Stack>
        {loading === true && <CircularProgress size={20} />}
      </Stack>
      <TableContainer>
        <Table stickyHeader aria-label="customer-preview-table" size="small">
          <colgroup>
            <col style={{ width: '40%' }} />
            <col style={{ width: '60%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Account ID</TableCell>
              <TableCell>Customer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactsData?.paginatedContacts.edges.map(({ node: contact }) => {
              const hasCreditHold =
                contact.__typename === 'CustomerContactEntity'
                  ? contact.enableCreditHold === true
                  : false;

              return (
                <CustomerListRow
                  openedCustomerUuid={openedCustomerUuid}
                  uuid={contact.uuid}
                  contactName={contact.displayName}
                  contactIdentifier={contact.contactReferenceNumber}
                  hasCreditHold={hasCreditHold}
                  key={contact.uuid}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default React.memo(CustomerList);
