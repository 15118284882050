import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { ErrorResponse } from '../../../../common/form/formValidators';
import { QuoteErrorsResponse } from '../../redux/quote-value-thunks';

const buildListFromErrorResponse = (
  errorResponses: ErrorResponse[],
  errorHeader?: string,
) => {
  return (
    <List sx={{ ml: 1 }}>
      {!isNil(errorHeader) && (
        <Typography fontWeight="bold" sx={{ mr: 0.5 }}>
          {errorHeader}
        </Typography>
      )}
      {errorResponses.map((error) => (
        <ListItem key={error.field}>
          <Typography fontWeight="bold" sx={{ mr: 0.5 }}>
            {`${error.field}: `}
          </Typography>
          <Typography>{error.validationResponse.explanation}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

const QuoteErrorsResponseDialog = ({
  errors,
  handleClose,
  open,
}: {
  errors: QuoteErrorsResponse;
  handleClose: () => void;
  open: boolean;
}) => {
  const errorsList = (
    <List>
      {buildListFromErrorResponse(errors.errors)}
      {errors.shipmentsErrors.some((e) => !e.isValid) ? (
        <List>
          {errors.shipmentsErrors.map((shipmentErrorsResponse, shipmentIdx) => {
            if (shipmentErrorsResponse.isValid) {
              return null;
            }
            const hasStopError =
              shipmentErrorsResponse.legsErrors[0]?.endStopErrorsResponse
                ?.isValid === false;
            const stopErrorList = hasStopError ? (
              <>
                <Typography fontWeight="bold">
                  {/* A shipment is synonymous with a stop right now */}
                  Stop {shipmentIdx + 1}
                </Typography>
                {shipmentErrorsResponse.legsErrors[0]?.endStopErrorsResponse
                  ?.isValid === false ? (
                  <List>
                    {buildListFromErrorResponse(
                      shipmentErrorsResponse.legsErrors[0]
                        ?.endStopErrorsResponse?.errors,
                    )}
                    {shipmentErrorsResponse.legsErrors[0]?.endStopErrorsResponse
                      ?.addressErrorsResponse?.isValid === false &&
                      buildListFromErrorResponse(
                        shipmentErrorsResponse.legsErrors[0]
                          ?.endStopErrorsResponse?.addressErrorsResponse
                          ?.errors,
                        'Location',
                      )}
                  </List>
                ) : null}
              </>
            ) : null;
            const shipmentErrorList =
              shipmentErrorsResponse.errors.length > 0 ? (
                <List>
                  {buildListFromErrorResponse(shipmentErrorsResponse.errors)}
                </List>
              ) : null;
            const packageErrorsList = shipmentErrorsResponse.packagesErrors.map(
              (packageErrors, packageIdx) => {
                if (packageErrors.isValid) {
                  return null;
                }
                return (
                  <List>
                    <Typography fontWeight="bold">
                      Package {packageIdx + 1}
                    </Typography>
                    {buildListFromErrorResponse(packageErrors.errors)}
                  </List>
                );
              },
            );
            const freightChargeErrorsList = shipmentErrorsResponse
              .freightChargeErrors.isValid ? null : (
              <List>
                <Typography fontWeight="bold">Freight charge</Typography>
                {buildListFromErrorResponse(
                  shipmentErrorsResponse.freightChargeErrors.errors,
                )}
              </List>
            );

            const airportInfoError = shipmentErrorsResponse?.errors?.find(
              (error) => error.field === 'Airport Info',
            );
            const airportInfoErrorsList = !isEmpty(airportInfoError) ? (
              <List>
                <Typography fontWeight="bold">
                  {buildListFromErrorResponse([airportInfoError])}
                </Typography>
              </List>
            ) : null;
            return (
              <>
                {stopErrorList}
                {packageErrorsList}
                {freightChargeErrorsList}
                {shipmentErrorList}
                {airportInfoErrorsList}
              </>
            );
          })}
        </List>
      ) : null}
    </List>
  );
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ErrorIcon color="error" sx={{ mr: 1 }} />
          Errors in saving this quote
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {errorsList}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuoteErrorsResponseDialog;
