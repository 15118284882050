import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Modal,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { CsvError, parse } from 'csv-parse/browser/esm';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  TariffZoneLocationType,
  TariffZoneType,
  TariffZonesForGroupDocument,
  useCreateTariffLocationMutation,
  useCreateTariffZoneMutation,
  useDeleteTariffLocationMutation,
  useDeleteTariffZoneMutation,
  useFindTariffZoneByUuidLazyQuery,
  useUpdateTariffZoneMutation,
} from '../../../generated/graphql';
import styles from '../styles';

const TariffZoneModal = ({
  tariffZoneUuid,
  open,
  setOpen,
  contactUuid,
  tariffZoneGroupId,
}: {
  tariffZoneUuid?: string | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  contactUuid?: string | null;
  tariffZoneGroupId?: string | null;
}) => {
  const [
    findTariffZoneByUuid,
    { data: tariffZoneData, refetch: refetchTariffZone },
  ] = useFindTariffZoneByUuidLazyQuery();
  const [createTariffZone] = useCreateTariffZoneMutation({
    refetchQueries: [
      {
        query: TariffZonesForGroupDocument,
        variables: {
          tariffZoneGroupId,
        },
      },
    ],
  });
  const [updateTariffZone] = useUpdateTariffZoneMutation();
  const [
    deleteTariffZone,
    { error: deleteTariffError, reset: clearDeleteTariffError },
  ] = useDeleteTariffZoneMutation();
  const [createTariffLocation] = useCreateTariffLocationMutation();
  const [deleteTariffLocation, { loading: deleteTariffLocationLoading }] =
    useDeleteTariffLocationMutation();

  const [hoveredRow, setHoveredRow] = useState<number>();
  const [textInputValue, setTextInputValue] = useState<string>('');
  const [textInputError, setTextInputError] = useState<boolean>(false);
  const [csvUploadError, setCsvUploadError] = useState<boolean>(false);
  const [locations, setLocations] = useState<
    { city: string; zipcode: string; uuid?: string }[]
  >([]);
  const [showAddTariffLocation, setShowAddTariffLocation] =
    useState<boolean>(false);
  const [city, setCity] = useState<string>('');
  const [cityError, setCityError] = useState<boolean>(false);
  const [zip, setZip] = useState<string>('');
  const [zipError, setZipError] = useState<boolean>(false);

  const resetState = () => {
    setLocations([]);
    setShowAddTariffLocation(false);
    setTextInputValue('');
    setTextInputError(false);
    setCity('');
    setCityError(false);
    setZip('');
    setZipError(false);
    clearDeleteTariffError();
  };

  useEffect(() => {
    resetState();
    if (open && !isNil(tariffZoneUuid)) {
      findTariffZoneByUuid({
        variables: { uuid: tariffZoneUuid },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const locationData = tariffZoneData?.findTariffZoneByUuidOrThrow.locations;
    if (!isNil(locationData)) {
      setLocations(
        locationData.map((location) => ({
          city: location.city ?? '',
          zipcode: location.zipcode ?? '',
          uuid: location.uuid,
        })),
      );
    }
    setTextInputValue(tariffZoneData?.findTariffZoneByUuidOrThrow.name ?? '');
  }, [tariffZoneData]);

  const handleDelete = async () => {
    if (!isNil(tariffZoneUuid)) {
      const { errors } = await deleteTariffZone({
        variables: { uuid: tariffZoneUuid },
      });
      if (isEmpty(errors)) {
        setOpen(false);
      }
    }
  };

  const handleSave = async () => {
    if (textInputValue === '') {
      setTextInputError(true);
    } else {
      if (!isNil(tariffZoneUuid)) {
        await updateTariffZone({
          variables: {
            tariffZoneUpdateInput: {
              locations,
              uuid: tariffZoneUuid,
              name: textInputValue,
            },
            contactUuid,
          },
        });
      } else {
        await createTariffZone({
          variables: {
            tariffZoneCreateInput: {
              locations,
              name: textInputValue,
              type: TariffZoneType.Location,
              tariffZoneLocationType: TariffZoneLocationType.Zipcode,
              tariffZoneGroupId,
            },
            contactUuid,
          },
        });
      }
      setOpen(false);
      resetState();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const csv = reader.result?.toString(); // Get the CSV data as a string
        if (csv !== undefined) {
          parse(
            csv,
            {
              delimiter: ',',
              columns: ['city', 'zipcode'],
              fromLine: 2,
            },
            (
              error: CsvError | undefined,
              result: { zipcode: string; city: string }[],
            ) => {
              if (!isNil(error)) {
                setCsvUploadError(true);
              } else {
                setCsvUploadError(false);
                setLocations(result);
              }
            },
          );
        }
      };
      reader.readAsText(file);
    }
  };

  const handleAddTariffLocation = async () => {
    if (isEmpty(city)) {
      setCityError(true);
    }
    if (isEmpty(zip)) {
      setZipError(true);
    }
    if (isEmpty(city) || isEmpty(zip)) {
      return;
    }
    if (isNil(tariffZoneUuid)) {
      setLocations((currLocations) => [
        ...currLocations,
        { city, zipcode: zip },
      ]);
    } else {
      await createTariffLocation({
        variables: {
          createTariffLocationInput: {
            tariffZoneUuid,
            tariffLocationCreateInput: {
              city,
              zipcode: zip,
            },
          },
        },
      });
      await refetchTariffZone();
    }
    setCity('');
    setZip('');
  };

  const handleDeleteTariffLocation = async ({ uuid }: { uuid: string }) => {
    await deleteTariffLocation({
      variables: { deleteTariffLocationInput: { uuid } },
    });
    await refetchTariffZone();
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modal}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={4}>
            {!isNil(tariffZoneUuid) && (
              <Button variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            )}
          </Grid>
          <Grid item xs={4} sx={styles.center}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isNil(tariffZoneUuid) ? 'Add' : 'Edit'} Tariff Zone
            </Typography>
          </Grid>
          <Grid item xs={4} justifyContent="flex-end">
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              value={textInputValue}
              onChange={(event) => {
                setTextInputValue(event.target.value);
              }}
              error={textInputError}
              id="outlined-basic"
              size="small"
              label="Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} justifyContent="flex-end">
            <Button
              component="label"
              onClick={() => setShowAddTariffLocation(true)}
              variant="outlined"
              sx={{ ml: 1, float: 'right' }}
            >
              Add location
            </Button>
            <Button
              component="label"
              variant="outlined"
              sx={{ float: 'right' }}
            >
              Upload from CSV
              <input
                type="file"
                accept=".csv"
                hidden
                onChange={handleFileUpload}
                multiple
              />
            </Button>
          </Grid>
          {showAddTariffLocation && (
            <Grid item xs={12} justifyContent="flex-end">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <TextField
                  required
                  error={cityError}
                  value={city}
                  onChange={(event) => {
                    setCity(event.target.value);
                    setCityError(false);
                  }}
                  label="City"
                  size="small"
                />
                <TextField
                  required
                  type="number"
                  error={zipError}
                  value={zip}
                  onChange={(event) => {
                    setZip(event.target.value);
                    setZipError(false);
                  }}
                  label="Zip"
                  size="small"
                  sx={{ ml: 1 }}
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button onClick={handleAddTariffLocation} variant="contained">
                  Save
                </Button>
                <Button
                  onClick={() => setShowAddTariffLocation(false)}
                  sx={{ ml: 1 }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sx={{ height: '100%' }}>
            {csvUploadError && (
              <Alert severity="error">
                <AlertTitle>CSV Parse Error</AlertTitle>
                Please check the csv format (city,zipcode).
              </Alert>
            )}
            <TableContainer sx={{ height: '75%' }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>City</TableCell>
                    <TableCell>Zipcode</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locations.map((location, idx) => (
                    <TableRow
                      key={location.uuid ?? idx}
                      onMouseEnter={() => setHoveredRow(idx)}
                      onMouseLeave={() => setHoveredRow(undefined)}
                    >
                      <TableCell>{location.city}</TableCell>
                      <TableCell>{location.zipcode}</TableCell>
                      <TableCell
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          height: '100%',
                        }}
                      >
                        <Fade in={hoveredRow === idx && !isNil(location.uuid)}>
                          <Button
                            disabled={deleteTariffLocationLoading}
                            onClick={async () => {
                              if (!isNil(location.uuid)) {
                                await handleDeleteTariffLocation({
                                  uuid: location.uuid,
                                });
                              }
                            }}
                          >
                            Remove
                          </Button>
                        </Fade>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {deleteTariffError && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={3000}
            open={!isNil(deleteTariffError)}
            onClose={clearDeleteTariffError}
          >
            <Alert severity="error">Error deleting tariff zone</Alert>
          </Snackbar>
        )}
      </Box>
    </Modal>
  );
};

export default TariffZoneModal;
