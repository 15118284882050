import { OrderFilterFieldV2 } from '../../domains/orders/components/enums/order-filters';
import {
  DateFilterValueInput,
  BooleanFilterV2,
  DateFilterV2,
  FloatFilterV2,
  IntegerFilterV2,
  StringFilterV2,
  UuidFilterV2,
  DateRangeFilterInput,
} from '../../generated/graphql';

export type Option = {
  value: string;
  label: string;
};

export enum FilterGroupOperator {
  AND = 'and',
  OR = 'or',
}

export type FilterOperatorTypeV2 =
  | keyof typeof SELECT_OPERATIONS
  | keyof typeof STRING_OPERATIONS
  | keyof typeof FLOAT_OPERATIONS
  | keyof typeof INTEGER_OPERATIONS
  | keyof typeof DATE_OPERATIONS;

export type FilterConstructionOperatorType = FilterOperatorTypeV2 | null;

export type FilterConstructionFilterType =
  | keyof typeof OrderFilterFieldV2
  | null;

export type FilterConstructionValueType =
  | string
  | string[]
  | number
  | Date
  | boolean
  | DateFilterValueInput
  | DateRangeFilterInput
  | null;

export type SingleFilterConstructionType = {
  filter: FilterConstructionFilterType;
  op: FilterConstructionOperatorType;
  value: FilterConstructionValueType;
};

export type GroupFilterConstructionType = {
  and?: FilterConstructionType[];
  or?: FilterConstructionType[];
};

export type FilterConstructionType =
  | SingleFilterConstructionType
  | GroupFilterConstructionType;

/**
 * Frontend types used to render filter input values (select represents both single and multiselect)
 */
export type FilterTypes =
  | 'select'
  | 'enum'
  | 'date'
  | 'text'
  | 'bool'
  | 'integer'
  | 'float';

export const STRING_OPERATIONS: Record<
  Exclude<
    keyof StringFilterV2,
    | 'eqIgnoreCase'
    | 'neqIgnoreCase'
    | 'containsIgnoreCase'
    | 'startsWithIgnoreCase'
    | 'endsWithIgnoreCase'
    | 'in'
    | 'nin'
  >,
  string
> = {
  eq: 'is',
  neq: 'is not',
  contains: 'contains',
  startsWith: 'starts with',
  endsWith: 'ends with',
  isBlank: 'is blank',
  isNotBlank: 'is not blank',
};

export const SELECT_OPERATIONS: Record<keyof UuidFilterV2, string> = {
  eq: 'is',
  neq: 'is not',
  in: 'is one of',
  nin: 'is not one of',
  isBlank: 'is blank',
  isNotBlank: 'is not blank',
};

export const ENUM_OPERATIONS: Record<
  Exclude<
    keyof StringFilterV2,
    | 'eqIgnoreCase'
    | 'neqIgnoreCase'
    | 'containsIgnoreCase'
    | 'startsWithIgnoreCase'
    | 'endsWithIgnoreCase'
    | 'contains'
    | 'startsWith'
    | 'endsWith'
    | 'isBlank'
    | 'isNotBlank'
  >,
  string
> = {
  eq: 'is',
  neq: 'is not',
  in: 'is one of',
  nin: 'is not one of',
};

export const INTEGER_OPERATIONS: Record<
  Exclude<keyof IntegerFilterV2, 'in' | 'nin'>,
  string
> = {
  eq: 'is',
  neq: 'is not',
  lt: 'less than',
  lte: 'less than or equal to',
  gt: 'greater than',
  gte: 'greater than or equal to',
};

export const FLOAT_OPERATIONS: Record<
  Exclude<keyof FloatFilterV2, 'in' | 'nin'>,
  string
> = {
  eq: 'is',
  neq: 'is not',
  lt: 'less than',
  lte: 'less than or equal to',
  gt: 'greater than',
  gte: 'greater than or equal to',
};

export const DATE_OPERATIONS: Record<
  Exclude<keyof DateFilterV2, 'in' | 'nin' | 'eq'>,
  string
> = {
  lt: 'is before',
  lte: 'is on or before',
  gt: 'is after',
  gte: 'is on or after',
  isBlank: 'is blank',
  isNotBlank: 'is not blank',
  eqV2: 'is',
  neq: 'is not on',
  between: 'is between',
};

export const BOOL_OPERATIONS: Record<keyof BooleanFilterV2, string> = {
  eq: 'is',
  neq: 'is not',
};

/**
 * We use enum values as the display names for filters.
 * We can't just change the enum values to rename filters since legacy saved views
 * still save filters keyed by the display names / enum values.
 * This will be solved once we backfill (FTO-114).
 */
export const FILTER_NAME_LABEL_OVERRIDES: Partial<
  Record<NonNullable<FilterConstructionFilterType>, string>
> = {
  BUSINESS_DIVISION: 'Business Division',
  INBOUND_CONTACT_NAME: 'Inbound Customer Name',
  OUTBOUND_CONTACT_NAME: 'Outbound Customer Name',
};

export const EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE: SingleFilterConstructionType =
  {
    filter: null,
    op: 'eq',
    value: null,
  };

export const EMPTY_GROUP_FILTER_CONSTRUCTION_TYPE: GroupFilterConstructionType =
  { and: [EMPTY_SINGLE_FILTER_CONSTRUCTION_TYPE] };
