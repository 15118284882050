import { useParams, useSearchParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import EmailProcessingStatus from '../domains/orders/components/email-ingestion/processing-status/email-processing-status-page';
import OrderPage from '../domains/orders/components/order-form/order-page';
import { ViewAllOrdersPage } from '../domains/orders/components/view-all-orders-page';
import Quote from '../domains/quotes/components/quote-form/quote-page';
import ApproveQuote from '../domains/quotes/quote-profile/components/approve-quote-page';

export const OrdersPage = () => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get('orderUuid');
  if (isNotNilOrEmptyString(orderUuid)) {
    return <OrderPage orderUuid={orderUuid} />;
  }
  return <ViewAllOrdersPage />;
};

export const EmailProcessingStatusPage = () => {
  const { emailUuid } = useParams();
  if (isNotNilOrEmptyString(emailUuid)) {
    return <EmailProcessingStatus emailUuid={emailUuid} />;
  }
  return null;
};

export const ApproveQuotePage = () => {
  const { quoteUuid } = useParams();
  const [searchParams] = useSearchParams();
  const billingPartyContactUuid = searchParams.get('billingPartyContactUuid');
  if (
    isNotNilOrEmptyString(quoteUuid) &&
    isNotNilOrEmptyString(billingPartyContactUuid)
  ) {
    return (
      <ApproveQuote
        quoteUuid={quoteUuid}
        billingPartyContactUuid={billingPartyContactUuid}
      />
    );
  }
  return null;
};

export const QuotePage = () => {
  const { quoteUuid } = useParams();
  if (isNotNilOrEmptyString(quoteUuid)) {
    return <Quote quoteUuid={quoteUuid} />;
  }
  return null;
};

export const AddQuotePage = () => <Quote quoteUuid={null} />;
