import { Alert, Box, Button, Snackbar, Stack } from '@mui/material';
import { isEmpty, isNil, noop, union } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import { INVOICE_ORDER_TABS_TEST_ID_PREFIX } from '../../../../../constants';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import {
  DateOption,
  DatePickerFilterType,
} from '../../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import { useTableFields } from '../../../../common/react-hooks/use-table-fields';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  FilterViewPage,
  FormattedOrderFragment,
  OrderTableField,
  PermissionResource,
  useFinalizeOrdersMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import { OrdersTableWithFiltersAgGrid } from '../../../ag-grid/orders/orders-table-with-filters-ag-grid';
import { useOrdersTableColumns } from '../../../orders/components/order-form/hooks/use-orders-table-columns';
import {
  BILLING_REVIEW_DEFAULT_FILTER_TAB_CONFIGS,
  BILLING_REVIEW_ORDER_TABLE_DEFAULT_HEADERS,
} from '../../billing-review-constants';
import useBillingReviewStore from '../../billing-review-store';
import { InvoiceOrderTabs } from '../../types/types';
import { getReviewModalLabel, isInvoiceOrderTabReviewType } from '../../utils';
import PostFinalizedModalV2, {
  SelectedOrders,
} from './post-finalized-modal-v2';
import ReviewOutstandingOrdersModal from './review-outstanding-orders-modal';

const ORDER_PAGE_SIZE = 25;

const BillingReview = () => {
  const [shouldRefreshOrdersTableData, setShouldRefreshOrdersTableData] =
    useGlobalStore(
      (state) => [
        state.shouldRefreshOrdersTableData,
        state.setShouldRefreshOrdersTableData,
      ],
      shallow,
    );

  const disableNumberOfOrdersQuery = useFeatureFlag(
    FeatureFlag.FF_DISABLE_NUMBERS_QUERIES,
  );
  const ffUseBulkFinalizeWithReRating = useFeatureFlag(
    FeatureFlag.FF_USE_BULK_FINALIZE_WITH_RE_RATING,
  );

  const disableOrdersPageCount = useFeatureFlag(
    FeatureFlag.FF_DISABLE_ORDERS_PAGE_COUNT,
  );

  const confirm = useConfirm();

  const {
    billingReviewOrderTableFields,
    loading: billingReviewOrderTableFieldsLoading,
  } = useTableFields();
  const { userUuid } = useMe();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteBillingReview } = getPermissionsFlags(
    userPermissions,
    PermissionResource.BillingReview,
  );
  const { canWrite: canFinalizeCharges } = getPermissionsFlags(
    userPermissions,
    PermissionResource.FinalizeChargesOrders,
  );
  const { canWrite: canSendFinalizedToBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SendFinalizedToBilling,
  );

  const orderTableHeadersWithCharges = useMemo(() => {
    return !isEmpty(billingReviewOrderTableFields)
      ? union(billingReviewOrderTableFields, [
          OrderTableField.TotalChargesWithItemized,
        ])
      : BILLING_REVIEW_ORDER_TABLE_DEFAULT_HEADERS;
  }, [billingReviewOrderTableFields]);

  const columnDefinitions = useOrdersTableColumns({
    setSuccessMessage: noop,
    pageType: FilterViewPage.Invoices,
    isEditable: true,
  });

  const [setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );

  const [contactUuids, setContactUuids] = useState<string[] | undefined>();
  const [serviceUuid, setServiceUuid] = useState<string | undefined>();
  const [dateOption, setDateOption] = useState<DateOption | undefined>();
  const [currentTab, setCurrentTab] = useState<InvoiceOrderTabs>(
    InvoiceOrderTabs.Unfinalized,
  );
  const [showSendFinalizedModal, setShowSendFinalizedModal] =
    useState<boolean>(false);
  const [selectedOrderUuids, setSelectedOrderUuids] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<SelectedOrders | null>(
    null,
  );
  const [numOrdersFinalized, setNumOrdersFinalized] = useState<number>(0);
  const [numFailedFinalizeOrders, setNumFailedFinalizeOrders] =
    useState<number>(0);
  const [
    showFinalizeOrdersSuccessMessage,
    setShowFinalizeOrdersSuccessMessage,
  ] = useState<boolean>(false);
  const [showFinalizeOrdersErrorMessage, setShowFinalizeOrdersErrorMessage] =
    useState<boolean>(false);

  const [
    outstandingOrderReviewModalIsOpen,
    setOutstandingOrderReviewModalIsOpen,
  ] = useState(false);

  const [finalizeOrders, { loading: finalizeOrdersLoading }] =
    useFinalizeOrdersMutation();

  const [setSelectedOriginTerminalUuid, setSelectedDestinationTerminalUuid] =
    useBillingReviewStore(
      (state) => [
        state.setSelectedOriginTerminalUuid,
        state.setSelectedDestinationTerminalUuid,
      ],
      shallow,
    );

  const handleSelectAllOrders = (uuids: string[]) => {
    setSelectedOrderUuids(uuids);
    setSelectedOrders({ retrievalMethod: 'queryAll' });
  };

  const handleFinalizeOrders = async () => {
    confirm({
      title: `Are you sure you want to finalize ${selectedOrderUuids.length} orders?`,
      description: '',
      cancellationText: `Cancel`,
      confirmationText: `Confirm`,
    }).then(async () => {
      const res = await finalizeOrders({
        variables: {
          finalizeOrdersInput: {
            uuids: selectedOrderUuids,
          },
        },
      });

      const finalizeOrdersResponse = res?.data?.finalizeOrders;

      if (
        finalizeOrdersResponse?.__typename === 'FinalizeOrdersSuccessOutput'
      ) {
        setNumOrdersFinalized(
          finalizeOrdersResponse.finalizedOrderUuids.length,
        );
        setNumFailedFinalizeOrders(
          finalizeOrdersResponse.failedOrderUuids.length,
        );
        setShowFinalizeOrdersSuccessMessage(true);
        setShouldRefreshOrdersTableData(true);
      } else {
        setShowFinalizeOrdersErrorMessage(true);
      }
    });
  };

  const handleSelectOrder = (orders: FormattedOrderFragment[]) => {
    setSelectedOrderUuids(orders.map((order) => order.uuid));
    // The new orders query relies on the selectedOrderUuids alone so these two pieces of state can be set to a default value.
    setSelectedOrders({ retrievalMethod: 'selection', orders: [] });
  };

  if (billingReviewOrderTableFieldsLoading === true) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box height="100%">
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFinalizeOrdersSuccessMessage}
        onClose={() => {
          setShowFinalizeOrdersSuccessMessage(false);
          setNumOrdersFinalized(0);
        }}
      >
        <Alert severity="success">
          Successfully finalized {numOrdersFinalized} /{' '}
          {numOrdersFinalized + numFailedFinalizeOrders} orders
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFinalizeOrdersErrorMessage}
        onClose={() => {
          setShowFinalizeOrdersErrorMessage(false);
          setNumOrdersFinalized(0);
        }}
      >
        <Alert severity="error">Error finalizing orders</Alert>
      </Snackbar>
      <OrdersTableWithFiltersAgGrid<InvoiceOrderTabs>
        rowSelectionEnabled
        shouldAllowSavedViews={false}
        shouldShowGenerateReportButtons
        shouldShowOriginTerminalFilter
        shouldShowDestinationTerminalFilter
        shouldShowDatePicker
        shouldShowCustomerFilterMultiselect
        shouldRememberFilters
        defaultDatePickerFilterType={DatePickerFilterType.AllSelect}
        columnDefinitions={columnDefinitions}
        showTotalCount={!disableOrdersPageCount}
        userOrderTableFields={orderTableHeadersWithCharges}
        shouldRefresh={shouldRefreshOrdersTableData}
        setShouldRefresh={setShouldRefreshOrdersTableData}
        handleSelectOrders={handleSelectOrder}
        handleSelectAllOrderUuids={handleSelectAllOrders}
        handleSelectContacts={(uuids: string[] | undefined) => {
          setContactUuids(uuids);
        }}
        handleSelectDate={(option: DateOption) => {
          setDateOption(option);
        }}
        handleSelectOriginTerminal={(uuid: string | undefined) => {
          setSelectedOriginTerminalUuid(uuid);
        }}
        handleSelectDestinationTerminal={(uuid: string | undefined) => {
          setSelectedDestinationTerminalUuid(uuid);
        }}
        handleTabChange={(tab: InvoiceOrderTabs) => {
          setCurrentTab(tab);
        }}
        handleFilterChange={(filters) => {
          if (!isNil(filters) && !isNil(filters['Service Level'])) {
            setServiceUuid(filters['Service Level']?.value);
          }
        }}
        pageSize={ORDER_PAGE_SIZE}
        isRowsPerPageSelectionEnabled
        showSelectAllOrders
        pageType={FilterViewPage.Invoices}
        userUuid={userUuid}
        topRightComponent={
          <Stack direction="row" spacing={2}>
            {[InvoiceOrderTabs.Unfinalized].includes(currentTab) &&
              ffUseBulkFinalizeWithReRating === true && (
                <Button
                  variant="contained"
                  onClick={handleFinalizeOrders}
                  disabled={
                    !canSendFinalizedToBilling ||
                    selectedOrderUuids.length === 0 ||
                    finalizeOrdersLoading
                  }
                >
                  {selectedOrderUuids.length === 0
                    ? 'Finalize orders'
                    : `Finalize ${selectedOrderUuids.length} ${pluralize(
                        'order',
                        selectedOrderUuids.length,
                      )}`}
                </Button>
              )}
            {[
              InvoiceOrderTabs.Cancelled,
              InvoiceOrderTabs.Finalized,
              InvoiceOrderTabs.FinalizedNoCharge,
              InvoiceOrderTabs.BillingIssue,
            ].includes(currentTab) && (
              <Button
                variant="contained"
                onClick={() => setShowSendFinalizedModal(true)}
                disabled={selectedOrderUuids.length === 0}
              >
                {selectedOrderUuids.length > 0
                  ? `Send Orders (${selectedOrderUuids.length})`
                  : 'Send Orders'}
              </Button>
            )}
            {isInvoiceOrderTabReviewType(currentTab) && (
              <Button
                data-test-id={`${INVOICE_ORDER_TABS_TEST_ID_PREFIX}-${currentTab}`}
                variant="contained"
                onClick={() => {
                  setOutstandingOrderReviewModalIsOpen(true);
                  setOpenedOrderUuid(undefined);
                }}
                disabled={!canWriteBillingReview || !canFinalizeCharges}
              >
                {selectedOrderUuids.length === 0
                  ? getReviewModalLabel(currentTab)
                  : `Review Orders (${selectedOrderUuids.length})`}
              </Button>
            )}
          </Stack>
        }
        defaultFilterTabsConfigs={BILLING_REVIEW_DEFAULT_FILTER_TAB_CONFIGS}
        originTerminalFilterCacheId="BILLING_REVIEW_ORIGIN_TERMINAL"
        destinationTerminalFilterCacheId="BILLING_REVIEW_DESTINATION_TERMINAL"
        enableNumberOfOrdersQuery={!disableNumberOfOrdersQuery}
        enableHeaderCheckboxSelection
      />
      {showSendFinalizedModal && !isNil(selectedOrders) && (
        <PostFinalizedModalV2
          isOpen={showSendFinalizedModal}
          setIsOpen={setShowSendFinalizedModal}
          selectedOrderUuids={selectedOrderUuids}
          contactUuids={contactUuids}
          serviceUuid={serviceUuid}
          startDate={dateOption?.startDate}
          endDate={dateOption?.endDate}
          onClose={() => setShouldRefreshOrdersTableData(true)}
        />
      )}
      <ReviewOutstandingOrdersModal
        initialOrderUuids={selectedOrderUuids}
        onClose={() => {
          setShouldRefreshOrdersTableData(true);
          setOutstandingOrderReviewModalIsOpen(false);
        }}
        isOpen={outstandingOrderReviewModalIsOpen}
      />
    </Box>
  );
};

export default BillingReview;
