import { Check, Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CircularProgress,
  FormHelperText,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  StorageOrderDocument,
  StorageUnitItemFragment,
  useUnassignInventoryItemsMutation,
  useUpsertItemGroupMutation,
} from '../../../../../generated/graphql';
import useEditItemGroupForm, {
  EditItemGroupFormValues,
} from '../../../forms/storage-units/use-edit-item-group-form';

interface AssignedItemRowProps {
  item: StorageUnitItemFragment;

  storageOrderUuid: string;

  storageUnitUuid: string;

  setUpdateStorageUnitSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;

  setUpdateStorageUnitErrorMessageVisible: Dispatch<SetStateAction<boolean>>;
}
const AssignedItemRow = ({
  item,
  storageOrderUuid,
  storageUnitUuid,
  setUpdateStorageUnitSuccessMessageVisible,
  setUpdateStorageUnitErrorMessageVisible,
}: AssignedItemRowProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useEditItemGroupForm();
  useEffect(() => {
    reset({ quantity: item.assignedQuantity });
  }, []);

  const [upsertItemGroup, { loading: upsertItemGroupLoading }] =
    useUpsertItemGroupMutation({
      refetchQueries: [StorageOrderDocument],
      awaitRefetchQueries: true,
    });
  const [unassignInventoryItems, { loading: unassignInventoryItemsLoading }] =
    useUnassignInventoryItemsMutation({
      refetchQueries: [StorageOrderDocument],
      awaitRefetchQueries: true,
    });

  const handleUnassignItem = async () => {
    try {
      const response = await unassignInventoryItems({
        variables: {
          unassignInventoryItemsInput: {
            uuid: storageUnitUuid,
            itemUuid: item.item.uuid,
          },
        },
      });
      if (response?.data?.unassignInventoryItems?.success === true) {
        setUpdateStorageUnitSuccessMessageVisible(true);
      } else {
        setUpdateStorageUnitErrorMessageVisible(true);
      }
    } catch (e) {
      setUpdateStorageUnitErrorMessageVisible(true);
    }
  };

  const onEdit = async (values: EditItemGroupFormValues) => {
    await upsertItemGroup({
      variables: {
        upsertItemGroupInput: {
          storageOrderUuid,
          storageUnitUuid,
          itemUuid: item.item.uuid,
          quantity: values.quantity,
        },
      },
    });
    setIsEditMode(false);
  };

  const getEditButton = () => {
    if (upsertItemGroupLoading) {
      return (
        <IconButton>
          <CircularProgress size={20} />
        </IconButton>
      );
    }
    if (isEditMode) {
      return (
        <IconButton
          onClick={handleSubmit(onEdit)}
          color="primary"
          disabled={unassignInventoryItemsLoading}
        >
          <Check fontSize="small" />
        </IconButton>
      );
    }
    return (
      <Tooltip arrow title="Edit quantity">
        <IconButton
          onClick={() => setIsEditMode(true)}
          color="primary"
          disabled={unassignInventoryItemsLoading}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <TableRow>
      <TableCell>{item.item.sku}</TableCell>
      <TableCell>{item.item.description}</TableCell>
      <TableCell>{sentenceCase(item.item.primaryUOM.unitOfMeasure)}</TableCell>
      <TableCell sx={{ width: 100 }}>
        {!isEditMode ? (
          item.assignedQuantity
        ) : (
          <Controller
            name="quantity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  name="quantity"
                  type="number"
                  size="small"
                  value={value}
                  required
                  onChange={onChange}
                  disabled={upsertItemGroupLoading}
                  error={!isNil(errors.quantity)}
                />
                {!isNil(errors.quantity) && (
                  <FormHelperText sx={{ color: '#D32F2F' }}>
                    {errors.quantity?.message?.toString() ?? ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        )}
      </TableCell>
      <TableCell>
        {getEditButton()}
        <Tooltip arrow title="Unassign items">
          <IconButton
            onClick={handleUnassignItem}
            color="secondary"
            disabled={unassignInventoryItemsLoading}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default AssignedItemRow;
