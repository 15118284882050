import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  FormGroup,
  // eslint-disable-next-line
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getNoonOfDay } from 'shared/date';
import { shallow } from 'zustand/shallow';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import { ReportStatistic } from '../../../../generated/graphql';
import { getOrderReportDataTotals } from '../../report-group-configuration-converter';
import useReportsStore from '../../reports-store';
import {
  REVENUE_REPORT_STATISTICS,
  ORDER_PACKAGE_COUNT_REPORT_STATISTICS,
  ORDER_HEAD_CELLS,
  ORDER_CSV_HEADERS,
} from '../../types/report-configs';
import { OrderReportBucketData } from '../../types/report-types';
import ReportTable from '../report-table';
import SkeletonChartLoader from '../skeleton-chart-loader';
import OrderReportBarChart from './order-report-bar-chart';
import OrderReportRevenue from './order-report-revenue';

const OrderReport = () => {
  const [dataTabsViewIndex, setDataTabsViewIndex] = useState<number>(0);
  const [shouldResetRevenueChartZoom, setShouldResetRevenueChartZoom] =
    useState<boolean>(false);
  const [shouldResetCountChartZoom, setShouldResetCountChartZoom] =
    useState<boolean>(false);
  const [revenueChartData, setRevenueChartData] = useState<
    OrderReportBucketData[]
  >([]);
  const [countChartData, setCountChartData] = useState<OrderReportBucketData[]>(
    [],
  );
  const [reportGroupConfiguration, setCurrentReportGroupConfiguration] =
    useReportsStore(
      (state) => [
        state.reportGroupConfigurations[state.currentReportIndex],
        state.setCurrentReportGroupConfiguration,
      ],
      shallow,
    );
  const orderReportData = useReportsStore((state) => state.orderReportData);
  const [isLoading] = useReportsStore((state) => [state.isLoading], shallow);

  const revenueChartTotals = getOrderReportDataTotals(revenueChartData);
  const countChartTotals = getOrderReportDataTotals(countChartData);

  useEffect(() => {
    setRevenueChartData(orderReportData);
    setCountChartData(orderReportData);
  }, [orderReportData]);

  const handleReportStatisticChange = (reportStatistic: string) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      if (data.reportStatistics.includes(reportStatistic as ReportStatistic)) {
        data.reportStatistics = data.reportStatistics.filter(
          (value) => value !== reportStatistic,
        );
      } else {
        data.reportStatistics = [
          ...data.reportStatistics,
          reportStatistic as ReportStatistic,
        ];
      }
      setCurrentReportGroupConfiguration(data);
    }
  };

  const getTotalDateRangeString = (data: OrderReportBucketData[]) => {
    const buildDateRangeString = (startDate: Date, endDate: Date) => {
      return `${dayjs(getNoonOfDay(startDate)).format('MM/DD/YYYY')} - ${dayjs(getNoonOfDay(endDate)).format('MM/DD/YYYY')}`;
    };
    const startDate = reportGroupConfiguration?.startDate;
    const endDate = reportGroupConfiguration?.endDate;
    if (!isNil(data) && !isNil(startDate) && !isNil(endDate)) {
      return buildDateRangeString(startDate, endDate);
    }
    return '';
  };

  return (
    <Stack>
      <Box>
        <ButtonGroup
          disableElevation
          variant="outlined"
          color="primary"
          size="small"
        >
          <Button
            variant={dataTabsViewIndex === 0 ? 'contained' : 'outlined'}
            onClick={() => setDataTabsViewIndex(0)}
          >
            Revenue Chart
          </Button>
          <Button
            variant={dataTabsViewIndex === 1 ? 'contained' : 'outlined'}
            onClick={() => setDataTabsViewIndex(1)}
          >
            Counts Chart
          </Button>
          <Button
            variant={dataTabsViewIndex === 3 ? 'contained' : 'outlined'}
            onClick={() => setDataTabsViewIndex(3)}
          >
            Orders Table
          </Button>
        </ButtonGroup>
      </Box>
      <Box>
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={0}>
          <Grid container spacing={2} sx={{ color: 'black' }}>
            <Grid item md={2} xs={12}>
              <Grid container spacing={3}>
                {!isNil(revenueChartData) && (
                  <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6">{`Totals for ${getTotalDateRangeString(
                        revenueChartData,
                      )}`}</Typography>
                      <OrderReportRevenue
                        amount={revenueChartTotals.totalRevenue}
                        label="Total Revenue"
                        isReportLoading={isLoading}
                      />
                      <OrderReportRevenue
                        amount={revenueChartTotals.freightRevenue}
                        label="Freight Revenue"
                        isReportLoading={isLoading}
                      />
                      <OrderReportRevenue
                        amount={revenueChartTotals.customChargeRevenue}
                        label="Custom Charge Revenue"
                        isReportLoading={isLoading}
                      />
                      <OrderReportRevenue
                        amount={revenueChartTotals.fuelRevenue}
                        label="Fuel Revenue"
                        isReportLoading={isLoading}
                      />
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
              <Stack>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={8} alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <FormGroup row>
                        {REVENUE_REPORT_STATISTICS.map((reportStatistic) => (
                          <FormControlLabel
                            key={reportStatistic.configKey}
                            checked={reportGroupConfiguration?.reportStatistics.includes(
                              reportStatistic.configKey,
                            )}
                            control={
                              <Checkbox
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    color: reportStatistic.color,
                                  },
                                }}
                                onChange={() => {
                                  handleReportStatisticChange(
                                    reportStatistic.configKey,
                                  );
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                fontSize={15}
                                color={reportStatistic.color}
                              >
                                {sentenceCase(reportStatistic.configKey)}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      sx={{ float: 'right' }}
                      onClick={() => setShouldResetRevenueChartZoom(true)}
                      variant="outlined"
                    >
                      Reset Zoom
                    </Button>
                  </Grid>
                </Grid>
                {!isLoading ? (
                  <OrderReportBarChart
                    reportStatisticsConfig={REVENUE_REPORT_STATISTICS}
                    height={800}
                    yLabel="USD ($)"
                    resetZoom={shouldResetRevenueChartZoom}
                    setResetZoom={setShouldResetRevenueChartZoom}
                    data={revenueChartData}
                    setData={setRevenueChartData}
                  />
                ) : (
                  <SkeletonChartLoader height={800} />
                )}
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={1}>
          <Grid container spacing={2} sx={{ color: 'black' }}>
            <Grid item md={2} xs={12}>
              <Grid container spacing={3}>
                {!isNil(countChartData) && (
                  <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6">{`Totals for ${getTotalDateRangeString(countChartData)}`}</Typography>
                      <Typography>
                        {`Number of Orders: ${countChartTotals.numberOfOrders.toLocaleString()}`}
                      </Typography>
                      <Typography>
                        {`Number of Packages: ${countChartTotals.numberOfPackages.toLocaleString()}`}
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
              <Stack>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={8} alignItems="center">
                    <Box display="flex" justifyContent="center">
                      <FormGroup row>
                        {ORDER_PACKAGE_COUNT_REPORT_STATISTICS.map(
                          (reportStatistic) => (
                            <FormControlLabel
                              key={reportStatistic.configKey}
                              checked={reportGroupConfiguration?.reportStatistics.includes(
                                reportStatistic.configKey,
                              )}
                              control={
                                <Checkbox
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: reportStatistic.color,
                                    },
                                  }}
                                  onChange={() => {
                                    handleReportStatisticChange(
                                      reportStatistic.configKey,
                                    );
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  fontSize={15}
                                  color={reportStatistic.color}
                                >
                                  {sentenceCase(reportStatistic.configKey)}
                                </Typography>
                              }
                            />
                          ),
                        )}
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      sx={{ float: 'right' }}
                      onClick={() => setShouldResetCountChartZoom(true)}
                      variant="outlined"
                    >
                      Reset Zoom
                    </Button>
                  </Grid>
                </Grid>
                {!isLoading ? (
                  <OrderReportBarChart
                    reportStatisticsConfig={
                      ORDER_PACKAGE_COUNT_REPORT_STATISTICS
                    }
                    height={800}
                    yLabel="USD ($)"
                    resetZoom={shouldResetCountChartZoom}
                    setResetZoom={setShouldResetCountChartZoom}
                    data={countChartData}
                    setData={setCountChartData}
                  />
                ) : (
                  <SkeletonChartLoader height={800} />
                )}
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={2} />
        <TabPanel selectedValue={dataTabsViewIndex} panelValue={3}>
          <ReportTable
            headCells={ORDER_HEAD_CELLS}
            csvHeaders={ORDER_CSV_HEADERS}
            isLoading={isLoading}
            reportData={orderReportData ?? []}
            reportGroupConfiguration={reportGroupConfiguration}
          />
        </TabPanel>
      </Box>
    </Stack>
  );
};

export default OrderReport;
