import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import Order from '../domains/orders/components/order-form/order-page';

export const OrderPage = () => {
  const { orderUuid } = useParams();
  if (isNotNilOrEmptyString(orderUuid)) {
    return <Order orderUuid={orderUuid} />;
  }
  return null;
};

export const AddOrderPage = () => <Order orderUuid={null} />;

export const ApproveQuotePage = () => {
  const navigate = useNavigate();
  const { quoteUuid } = useParams();
  const [searchParams] = useSearchParams();
  const billingPartyContactUuid = searchParams.get('billingPartyContactUuid');
  useEffect(() => {
    if (
      isNotNilOrEmptyString(quoteUuid) &&
      isNotNilOrEmptyString(billingPartyContactUuid)
    ) {
      navigate(
        `/orders/quotes/${quoteUuid}/approve?billingPartyContactUuid=${billingPartyContactUuid}`,
      );
    }
  }, [quoteUuid, billingPartyContactUuid, navigate]);
  return null;
};
