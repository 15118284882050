import { TableCell, TableRow } from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import React, { useState } from 'react';

type SearchResultsTableRowProps<T> = {
  selected: boolean;
  idx: number;
  data: T;
  columnWidth: number | undefined;
  selectRow: (openInNewTab?: boolean) => void;
};

const SearchResultsTableRow = <T extends React.ReactNode[]>({
  selected,
  idx,
  data,
  columnWidth,
  selectRow,
}: SearchResultsTableRowProps<T>) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useHotkeys(['Enter', 'Meta+Enter'], (e) => {
    if (selected) {
      selectRow(e.metaKey);
    }
  });

  return (
    <TableRow
      id={`result-row-${idx}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={(e) => {
        selectRow(e.metaKey);
      }}
      sx={{
        backgroundColor: isHovering || selected ? '#f1f1f1' : undefined,
        width: columnWidth,
        cursor: 'pointer',
      }}
    >
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableCell key={index}>{item}</TableCell>
      ))}
    </TableRow>
  );
};

export default React.memo(SearchResultsTableRow);
