import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { isNil } from 'lodash';
import { WrappedText } from 'shared/pdfs/common';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  OrderForPackingSlipFragment,
  PicklistsFragment,
} from '../../../../generated/graphql';
import DisplayAddress from '../../../generated-documents/components/display-address';
import { formatAppointmentTime, formatDate } from './utils';

const FILL_LINE = '________________';

const styles = StyleSheet.create({
  page: {
    padding: 24,
    paddingBottom: 48,
    fontSize: 12,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
  },
  section: {
    marginBottom: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  headerBranding: {
    width: '60%',
    fontSize: 24,
    marginBottom: 16,
  },
  headerSubtitle: {
    width: '30%',
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'right',
  },
  logoType: {
    height: '48px',
    marginBottom: 16,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  label: {
    width: '100px',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  underline: {
    textDecoration: 'underline',
  },
  warehouseCertification: {
    margin: 'auto',
    marginTop: 30,
    width: '60%',
    textAlign: 'center',
  },
  halfWidth: {
    width: '50%',
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  signatureSubtitle: {
    marginTop: 30,
    fontSize: 10,
    fontStyle: 'italic',
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
  },
  signature: {
    marginTop: 4,
    fontSize: 10,
    flexDirection: 'row',
    textAlign: 'center',
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: 'black',
    marginTop: 30,
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 12,
    right: 24,
    textAlign: 'right',
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  footer: {
    width: '100%',
    left: 24,
    bottom: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
  },
  footerLogo: {
    height: '24px',
  },
  shippedVia: {
    fontFamily: 'Helvetica-Bold',
    maxWidth: '60%',
  },
  supplierCompany: {
    width: '12.5%',
  },
  sku: {
    width: '20%',
  },
  description: {
    width: '20%',
  },
  qty: {
    width: '7%',
  },
  unit: {
    width: '13%',
  },
  poNumber: {
    width: '15%',
  },
  lotNumber: {
    width: '12.5%',
  },
  exception: {
    width: '30%',
  },
  checkIfApplicable: {
    width: '20%',
  },
  exceptionDescription: {
    width: '50%',
  },
});

interface PackingSlipProps {
  order?: OrderForPackingSlipFragment;
  picklist: PicklistsFragment;
  companyName: string;
  logoBuffer?: Buffer;
  logoTypeBuffer?: Buffer;
}
const PackingSlip = ({
  order,
  picklist,
  companyName,
  logoBuffer,
  logoTypeBuffer,
}: PackingSlipProps) => {
  const contactName = order?.billingPartyContact.displayName ?? companyName;
  const getTopLeftContent = () => {
    if (!isNil(logoTypeBuffer)) {
      return <Image style={styles.logoType} src={logoTypeBuffer} />;
    }
    return <Text style={styles.headerBranding}>{contactName}</Text>;
  };
  const header = (
    <View style={styles.section}>
      <View style={styles.spaceBetween}>
        {getTopLeftContent()}
        <Text style={styles.headerSubtitle}>PACKING SLIP</Text>
      </View>
      <View style={styles.spaceBetween}>
        <View style={styles.halfWidth}>
          <View style={styles.row}>
            <Text style={styles.label}>Order #:</Text>
            <Text style={styles.bold}>{order?.name ?? FILL_LINE}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Order created:</Text>
            <Text style={styles.bold}>
              {formatDate(order?.createdAt) ?? FILL_LINE}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>HAWB:</Text>
            <Text style={styles.bold}>
              {!isNilOrEmptyString(
                order?.standardOrderFields.shipperBillOfLadingNumber,
              )
                ? order?.standardOrderFields.shipperBillOfLadingNumber
                : FILL_LINE}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>MAWB:</Text>
            <Text style={styles.bold}>
              {!isNilOrEmptyString(
                order?.standardOrderFields.masterAirwayBillOfLadingNumber,
              )
                ? order?.standardOrderFields.masterAirwayBillOfLadingNumber
                : FILL_LINE}
            </Text>
          </View>
        </View>
        <View style={styles.halfWidth}>
          <View style={styles.row}>
            <Text style={styles.label}>Delivery date:</Text>
            <Text style={styles.bold}>
              {formatDate(
                order?.outboundShipment?.standardShipmentFields?.deliveryDate,
              ) ??
                formatDate(
                  order?.outboundShipment?.legs[0]?.endStop?.serviceDate,
                ) ??
                FILL_LINE}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Delivery time:</Text>
            <Text style={styles.bold}>
              {formatAppointmentTime(
                order?.outboundShipment?.legs[0]?.endStop?.appointmentTime,
                order?.outboundShipment?.legs[0]?.endStop?.endAppointmentTime,
              ) ?? FILL_LINE}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Shipped via:</Text>
            <Text style={styles.shippedVia}>{companyName}</Text>
          </View>
        </View>
      </View>
    </View>
  );
  const footer = (
    <>
      {!isNil(logoBuffer) && (
        <View style={styles.footer} fixed>
          <Image style={styles.footerLogo} src={logoBuffer} />
        </View>
      )}
      <Text
        style={styles.pageNumbers}
        render={({ pageNumber }) => `${pageNumber}`}
        fixed
      />
    </>
  );
  return (
    <Document title="Packing slip">
      <Page size="A4" style={styles.page}>
        {header}
        {footer}
        <View style={[styles.spaceBetween, styles.section]}>
          <View style={styles.halfWidth}>
            <Text style={styles.section}>Ship to:</Text>
            <View style={styles.bold}>
              {order?.outboundShipment?.legs[0]?.endStop?.address != null ? (
                <DisplayAddress
                  address={order?.outboundShipment?.legs[0]?.endStop?.address}
                  fontSize="12px"
                  gap={8}
                />
              ) : (
                <>
                  <View style={styles.row}>
                    <Text>{FILL_LINE}</Text>
                    <Text>{FILL_LINE}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>{FILL_LINE}</Text>
                    <Text>{FILL_LINE}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>{FILL_LINE}</Text>
                    <Text>{FILL_LINE}</Text>
                  </View>
                </>
              )}
            </View>
          </View>
          <View style={styles.halfWidth}>
            <Text style={styles.section}>Bill to:</Text>
            <View style={styles.bold}>
              {order?.billingPartyContact.defaultAddress != null ? (
                <DisplayAddress
                  address={order?.billingPartyContact.defaultAddress}
                  fontSize="12px"
                  gap={8}
                />
              ) : (
                <>
                  <View style={styles.row}>
                    <Text style={styles.bold}>{contactName}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>{FILL_LINE}</Text>
                    <Text>{FILL_LINE}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>{FILL_LINE}</Text>
                    <Text>{FILL_LINE}</Text>
                  </View>
                </>
              )}
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, styles.supplierCompany]}>
              <Text style={[styles.tableCellHeader]}>Supplier company</Text>
            </View>
            <View style={[styles.tableColHeader, styles.sku]}>
              <Text style={styles.tableCellHeader}>SKU</Text>
            </View>
            <View style={[styles.tableColHeader, styles.description]}>
              <Text style={styles.tableCellHeader}>Description</Text>
            </View>
            <View style={[styles.tableColHeader, styles.qty]}>
              <Text style={styles.tableCellHeader}>Qty</Text>
            </View>
            <View style={[styles.tableColHeader, styles.unit]}>
              <Text style={styles.tableCellHeader}>Unit</Text>
            </View>
            <View style={[styles.tableColHeader, styles.poNumber]}>
              <Text style={styles.tableCellHeader}>PO number</Text>
            </View>
            <View style={[styles.tableColHeader, styles.lotNumber]}>
              <Text style={styles.tableCellHeader}>Lot number</Text>
            </View>
          </View>

          {picklist.picklistItemGroups.map((pig) => (
            <View style={styles.tableRow} key={pig.id}>
              <View style={[styles.tableCol, styles.supplierCompany]}>
                <WrappedText style={styles.tableCell}>
                  {pig.itemGroup.storageUnit?.storageOrder?.supplierCompanyName}
                </WrappedText>
              </View>
              <View style={[styles.tableCol, styles.sku]}>
                <WrappedText style={styles.tableCell}>
                  {pig.itemGroup.item.sku}
                </WrappedText>
              </View>
              <View style={[styles.tableCol, styles.description]}>
                <WrappedText style={styles.tableCell}>
                  {pig.itemGroup.item.description}
                </WrappedText>
              </View>
              <View style={[styles.tableCol, styles.qty]}>
                <WrappedText style={styles.tableCell}>
                  {pig.pickedQuantity}
                </WrappedText>
              </View>
              <View style={[styles.tableCol, styles.unit]}>
                <WrappedText style={styles.tableCell}>
                  {pig.itemGroup.item.primaryUOM.unitOfMeasure}
                </WrappedText>
              </View>
              <View style={[styles.tableCol, styles.poNumber]}>
                <WrappedText style={styles.tableCell}>
                  {pig.itemGroup.storageUnit?.storageOrder?.purchaseOrderNumber}
                </WrappedText>
              </View>
              <View style={[styles.tableCol, styles.lotNumber]}>
                <WrappedText style={styles.tableCell}>
                  {pig.itemGroup.storageUnit?.storageOrder?.lotNumber}
                </WrappedText>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.warehouseCertification} wrap={false}>
          <View style={styles.section}>
            <Text style={styles.textAlignCenter}>
              The material shown on this document has been checked by{' '}
              {contactName} personnel. {contactName} certifies that this
              document accurately reflects the material contained in the
              delivery above.
            </Text>
          </View>
          <View style={styles.section}>
            <View style={styles.line} />
            <Text style={styles.signature}>WAREHOUSE SIGNATURE / PRINT</Text>
          </View>
          <View style={styles.section}>
            <View style={styles.line} />
            <Text style={styles.signature}>DATE</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        {header}
        {footer}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, styles.exception]}>
              <Text style={styles.tableCellHeader}>EXCEPTION</Text>
            </View>
            <View style={[styles.tableColHeader, styles.checkIfApplicable]}>
              <Text style={styles.tableCellHeader}>CHECK IF APPLICABLE</Text>
            </View>
            <View style={[styles.tableColHeader, styles.exceptionDescription]}>
              <Text style={styles.tableCellHeader}>DESCRIPTION</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.exception]}>
              <Text style={styles.tableCell}>Shortages/Discrepancies</Text>
            </View>
            <View style={[styles.tableCol, styles.checkIfApplicable]} />
            <View style={[styles.tableCol, styles.exceptionDescription]}>
              <Text style={styles.tableCell}>
                Please confirm the quantity listed on the packing slip and the
                quantity received. All discrepancies shall be reported to{' '}
                {contactName} within 72 hours.
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.exception]}>
              <Text style={styles.tableCell}>Visible damage</Text>
            </View>
            <View style={[styles.tableCol, styles.checkIfApplicable]} />
            <View style={[styles.tableCol, styles.exceptionDescription]}>
              <Text style={styles.tableCell}>
                Please inspect the freight for damage and record any obvious
                damage on the delivery record. Be as specific as possible in
                describing the damage.
              </Text>
              <Text style={[styles.tableCell, styles.bold]}>
                When there is visible damage, be sure to identify exactly what
                is damaged on the CARRIER delivery receipt.
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.exception]}>
              <Text style={styles.tableCell}>Concealed damage</Text>
            </View>
            <View style={[styles.tableCol, styles.checkIfApplicable]} />
            <View style={[styles.tableCol, styles.exceptionDescription]}>
              <Text style={styles.tableCell}>
                When damage is discovered after delivery, it should be reported
                to {contactName} within 72hrs.
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.exception]}>
              <Text style={styles.tableCell}>Considerable loss or damage</Text>
            </View>
            <View style={[styles.tableCol, styles.checkIfApplicable]} />
            <View style={[styles.tableCol, styles.exceptionDescription]}>
              <Text style={styles.tableCell}>
                In the event of considerable loss or damage, the complete
                shipment should be refused.
              </Text>
              <Text style={[styles.tableCell, styles.bold, styles.underline]}>
                THIS SHOULD BE REPORTED TO {contactName} IMMEDIATELY.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.signatureSubtitle}>
          <Text>
            By signing this document, the consignee agrees that this material is
            accounted for and ready for delivery.
          </Text>
          <Text>
            It is understood that {contactName} assumes zero responsibility for
            lost and/or damaged material unless there is an exception as noted
            in the exceptions grid.
          </Text>
        </View>

        <View style={styles.section}>
          <View style={styles.line} />
          <Text style={styles.signature}>Consignee signature</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.line} />
          <Text style={styles.signature}>Consignee printed name</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.line} />
          <Text style={styles.signature}>Date received</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PackingSlip;
