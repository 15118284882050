import { Typography } from '@mui/material';
import pluralize from 'pluralize';
import { TariffGroupForTableFragment } from '../../../../../generated/graphql';

interface TariffTableContactCellProps {
  tariffGroup: TariffGroupForTableFragment;
}

export const TariffTableContactCell = ({
  tariffGroup,
}: TariffTableContactCellProps) => {
  const contacts = tariffGroup?.contactToServicesMappings.map(
    (mapping) => mapping.contact,
  );

  const totalContacts = tariffGroup.totalContactsToServicesMappings;
  const numberOfFetchedContacts = contacts.length;
  const numberOfAdditionalContacts = totalContacts - numberOfFetchedContacts;

  return (
    <>
      {contacts?.map((c, idx) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Typography fontSize="14px" key={c.displayName + idx}>
            {c.displayName}
          </Typography>
        );
      })}
      {numberOfAdditionalContacts > 0 && (
        <Typography
          sx={{
            textDecorationStyle: 'dotted',
            color: 'text.secondary',
          }}
        >
          +{numberOfAdditionalContacts} more{' '}
          {pluralize('contact', numberOfAdditionalContacts)}
        </Typography>
      )}
    </>
  );
};
