import { TableSortLabel } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { capitalCase, sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs, { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';
import { groupBy, isEmpty, isNil, uniq, values } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { Dispatch, SetStateAction } from 'react';
import { filterNotNil } from 'shared/array';
import { safeDivide, safeMultiply, safeSubtract } from 'shared/math';
import GeneratedInvoiceRegisterReport from 'shared/pdfs/generated-invoice-register-report';
import { filenamify, isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { MimeType } from 'shared/types';
import { mapToZipFile } from 'shared/zip/map-to-zip';
import { v4 } from 'uuid';
import apolloClient from '../../apollo-client';
import { AccessorialOption } from '../../common/components/accessorial-filter-button';
import { BusinessDivisionOption } from '../../common/components/business-division-filter-button';
import { DownloadType } from '../../common/components/download-type-selection';
import { Option } from '../../common/filters/types';
import createPagesForPdf from '../../common/utils/pdf-gen';
import {
  AccessorialChargeDetailsDataFragment,
  AccountingReportType,
  AccountsReceivableDataFragment,
  AgingReportDataFragment,
  BatchAddShipmentsToInvoicesResultInvoiceFragment,
  CompanyFragment,
  CreateAccountingReportDocumentDocument,
  CreateAccountingReportDocumentMutation,
  CreateAccountingReportDocumentMutationVariables,
  CreateInvoiceDocumentsDocument,
  CreateInvoiceDocumentsMutation,
  CreateInvoiceDocumentsMutationVariables,
  CustomerReportBucketFragment,
  DocumentType,
  EmailAccountingReportDocument,
  EmailAccountingReportMutation,
  EmailAccountingReportMutationVariables,
  EmailAccountingReportStatus,
  EmailMultipleInvoicesDocument,
  EmailMultipleInvoicesMutation,
  EmailMultipleInvoicesMutationVariables,
  FindInvoicesSort,
  FindInvoicesSortFields,
  FindOrdersOnInvoiceSort,
  FindOrdersOnInvoiceSortFields,
  GenerateAccountingReportPreSignedPutUrlDocument,
  GenerateAccountingReportPreSignedPutUrlMutation,
  GenerateAccountingReportPreSignedPutUrlMutationVariables,
  GenerateInvoicePreSignedPutUrlsDocument,
  GenerateInvoicePreSignedPutUrlsMutation,
  GenerateInvoicePreSignedPutUrlsMutationVariables,
  InvoiceAgingReportDataFragment,
  InvoiceBillingSummaryGroupByType,
  InvoiceBillingSummaryReportV2Fragment,
  InvoiceForOpenInvoiceReportFragment,
  InvoiceForOrderSummaryReportFragment,
  InvoiceForRegisterReportFragment,
  InvoiceStatus,
  InvoiceStatusFilter,
  InvoiceType,
  MeQuery,
  NetSalesSummaryReportOutputRow,
  OrderForPaymentJournalReportFragment,
  PaymentForJournalReportFragment,
  PaymentForPaymentApplicationReportFragment,
  PaymentType,
  ReportAggregationPeriod,
  ReportRevenueType,
  ReportType,
  RevenueReportOrderDataFragment,
  ShipmentTypeForCharge,
  ShipmentType,
  SortDirection,
  StopType,
} from '../../generated/graphql';
import { DocumentAttachmentsNew } from '../invoice-old/components/download-documents';
import GeneratedRevenueReport from '../reports/components/download/generated-revenue-report';
import { convertDataToCustomerReportBucketData } from '../reports/reports-converter';
import { ReportGroupConfiguration } from '../reports/types/report-types';
import {
  calculateInvoiceOpenBalanceForBucket,
  getAgingReportColumnHeader,
} from './components/accounting-reports/aging-report/utils';
import { ReportDateFilterType } from './components/invoices/download/components/report-date-filter-type-button';
import GeneratedAccessorialDetailsReport from './components/invoices/download/generated-accessorial-details-report';
import GeneratedAccountsReceivableReport, {
  GeneratedAccountsReceivableReportProps,
} from './components/invoices/download/generated-accounts-receivable-report';
import GeneratedInvoiceOrderSummaryReport from './components/invoices/download/generated-invoice-order-summary-report';
import GeneratedOpenInvoicesReport from './components/invoices/download/generated-open-invoices-report';
import GeneratedPaymentApplicationReport, {
  GeneratedPaymentApplicationReportProps,
} from './components/invoices/download/generated-payment-application-report';
import GeneratedPaymentJournalReport from './components/invoices/download/generated-payment-journal-report';
import { formatDateOption } from './components/invoices/download/utils';
import {
  InvoiceDownload,
  InvoiceOrderReviewType,
  InvoiceOrderTabs,
  InvoiceStatusTab,
  InvoiceToSendOption,
} from './types/types';

export const groupInvoicesByContactUuid = (
  invoices: BatchAddShipmentsToInvoicesResultInvoiceFragment[],
) => groupBy(invoices, (invoice) => invoice.billToContact.uuid);

export const convertInvoiceDownloadDocumentsToInvoiceAttachmentsNew = ({
  documents,
  documentTypesForSelection,
}: {
  documents: DocumentType[];
  documentTypesForSelection: DocumentType[];
}) => {
  if (
    !isEmpty(documentTypesForSelection) &&
    documentTypesForSelection.every((docType) => documents.includes(docType))
  ) {
    return DocumentAttachmentsNew.IncludeAll;
  }
  if (
    documentTypesForSelection
      .filter((type) => type !== DocumentType.ShipmentPhoto)
      .every((docType) => documents.includes(docType))
  ) {
    return DocumentAttachmentsNew.IncludeAllExceptShipmentPhotos;
  }
  if (
    [
      DocumentType.DigitalProofOfDelivery,
      DocumentType.ProofOfDelivery,
      DocumentType.DigitalProofOfPickup,
    ].every((type) => documents.includes(type))
  ) {
    return DocumentAttachmentsNew.IncludeDigitalPodsAndDriverPod;
  }
  if (
    [
      DocumentType.DigitalProofOfDelivery,
      DocumentType.DigitalProofOfPickup,
    ].every((type) => documents.includes(type))
  ) {
    return DocumentAttachmentsNew.IncludeDigitalPods;
  }
  return DocumentAttachmentsNew.NoAttachments;
};

export const convertInvoiceAttachmentsToInvoiceDownloadDocuments = (
  documentAttachments: DocumentAttachmentsNew,
  documentTypesForSelection: DocumentType[],
) => {
  switch (documentAttachments) {
    case DocumentAttachmentsNew.NoAttachments:
      return [];
    case DocumentAttachmentsNew.IncludeDigitalPods:
      return [
        DocumentType.DigitalProofOfDelivery,
        DocumentType.DigitalProofOfPickup,
      ];
    case DocumentAttachmentsNew.IncludeDigitalPodsAndDriverPod:
      return [
        DocumentType.DigitalProofOfDelivery,
        DocumentType.DigitalProofOfPickup,
        DocumentType.ProofOfDelivery,
      ];
    case DocumentAttachmentsNew.IncludeAllExceptShipmentPhotos:
      return documentTypesForSelection.filter(
        (type) => type !== DocumentType.ShipmentPhoto,
      );
    case DocumentAttachmentsNew.IncludeAll:
      return documentTypesForSelection;
    default:
      return exhaustive(documentAttachments);
  }
};

export const uploadDocumentToAws = async (
  url: string,
  file: File,
  mimeType: MimeType,
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const options = { headers: { 'Content-Type': mimeType } };
  const awsRes = await fetch(url, {
    method: 'PUT',
    body: file,
    ...options,
  });
  return awsRes.status;
};

const getInvoiceDocumentUuids = async ({
  data,
}: {
  data: { invoice: InvoiceToSendOption; url: string }[];
}) => {
  const generateInvoicePreSignedPutUrlInputs = data.map(({ invoice }) => {
    const uuid = v4();
    const mimeType = [
      InvoiceType.ExcelSummarized,
      InvoiceType.ExcelItemized,
    ].includes(invoice.invoiceDownloadType)
      ? MimeType.XLSX
      : MimeType.PDF;
    const uniqueFileName = `invoice-${
      invoice?.invoiceName
    }_${uuid}_${mimeType.slice(0, 14)}`;

    return {
      fileName: uniqueFileName,
      fileType: mimeType,
      invoiceUuid: invoice.invoiceUuid,
    };
  });
  const res = await apolloClient.mutate<
    GenerateInvoicePreSignedPutUrlsMutation,
    GenerateInvoicePreSignedPutUrlsMutationVariables
  >({
    mutation: GenerateInvoicePreSignedPutUrlsDocument,
    variables: {
      generateInvoicePreSignedPutUrlsInput: {
        generateInvoicePreSignedPutUrlInputs,
      },
    },
  });

  const results = res.data?.generateInvoicePreSignedPutUrls;

  if (isNil(results)) {
    // eslint-disable-next-line no-alert
    alert('Failed to upload invoices');
    console.error(
      `[getInvoiceDocumentUuids] failed to generate pre-signed put urls`,
    );
    return null;
  }

  for (const { invoiceUuid, url: preSignedUrl } of results) {
    const invoiceData = data.find(
      ({ invoice }) => invoice.invoiceUuid === invoiceUuid,
    );
    const input = generateInvoicePreSignedPutUrlInputs.find(
      (d) => d.invoiceUuid === invoiceUuid,
    );

    if (!isNil(invoiceData) && !isNil(input)) {
      const blobRes = await fetch(invoiceData.url);
      const resStatus = await uploadDocumentToAws(
        preSignedUrl,
        new File([await blobRes.blob()], input?.fileName),
        input?.fileType,
      );

      if (resStatus !== 200) {
        console.error(
          `[getInvoiceDocumentUuids] aws upload failed with status code ${resStatus}`,
        );
      }
    }
  }

  const createInvoiceDocumentInputs = generateInvoicePreSignedPutUrlInputs.map(
    (input) => {
      const invoiceData = data.find(
        ({ invoice }) => invoice.invoiceUuid === input.invoiceUuid,
      );
      return {
        documentType: DocumentType.Other,
        fileType: input.fileType,
        fileName: input.fileName,
        invoiceUuid: input.invoiceUuid,
        name: invoiceData?.invoice?.invoiceName,
        thumbnail: null,
        pageNumber: null,
      };
    },
  );

  const resDocuments = await apolloClient.mutate<
    CreateInvoiceDocumentsMutation,
    CreateInvoiceDocumentsMutationVariables
  >({
    mutation: CreateInvoiceDocumentsDocument,
    variables: {
      createInvoiceDocumentsInput: {
        createInvoiceDocumentInputs,
      },
    },
  });

  return resDocuments.data?.createInvoiceDocuments;
};

export const emailInvoices = async (
  data: { invoice: InvoiceToSendOption; url: string }[],
) => {
  if (isEmpty(data)) return;

  const invoiceDocumentResults = await getInvoiceDocumentUuids({
    data,
  });

  if (isNil(invoiceDocumentResults)) {
    // eslint-disable-next-line no-alert
    alert('Failed to upload invoices');
    console.error(
      '[emailInvoices] invoice create documentUuids response is empty',
    );
    return;
  }

  const emailInvoiceInputs = invoiceDocumentResults.flatMap((result) =>
    !isNil(result.invoice)
      ? [
          {
            invoiceUuid: result.invoice.uuid,
            contactUuid: result.invoice.billToContact.uuid,
            documentUuid: result.uuid,
          },
        ]
      : [],
  );

  apolloClient.mutate<
    EmailMultipleInvoicesMutation,
    EmailMultipleInvoicesMutationVariables
  >({
    mutation: EmailMultipleInvoicesDocument,
    variables: {
      emailMultipleInvoicesInput: {
        emailInvoiceInputs,
      },
    },
  });
};

export const downloadMultipleInvoices = async (urls: InvoiceDownload[]) => {
  const content = await mapToZipFile({
    data: urls,
    mapper: async (url) => {
      const res = await fetch(url.downloadLink);
      const blob = await res.blob();

      const extension =
        url.invoiceType === InvoiceType.ExcelItemized ||
        url.invoiceType === InvoiceType.ExcelSummarized
          ? 'xlsx'
          : 'pdf';
      return {
        path: [`${url.firstDocumentInvoiceName ?? ''}-attachment.${extension}`],
        blob,
      };
    },
  });

  saveAs(content, 'invoices.zip');
};

export const getCombinedInvoiceUrl = async (fileUrls: InvoiceDownload[]) => {
  const pdfDoc = await PDFDocument.create();
  await Promise.all(
    fileUrls
      .filter(
        (fileUrl) =>
          fileUrl.invoiceType === InvoiceType.PdfSummarized ||
          fileUrl.invoiceType === InvoiceType.PdfItemized,
      )
      .map(async (fileUrl) => {
        const blob = await fetch(fileUrl.downloadLink);
        await createPagesForPdf(
          await blob.arrayBuffer(),
          'application/pdf',
          pdfDoc,
        );
      }),
  );
  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  return window.URL.createObjectURL(file);
};

export const downloadInvoiceOrdersSummaryReport = async ({
  companyData,
  invoice,
}: {
  companyData: MeQuery | undefined;
  invoice: InvoiceForOrderSummaryReportFragment;
}) => {
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedInvoiceOrderSummaryReport
      companyData={companyData}
      invoice={invoice}
    />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = window.URL.createObjectURL(file);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = `invoice-summary-report-${
    companyData?.me?.company.configuration?.useJournalNumberForInvoice === true
      ? (invoice.journalNumber ?? invoice.name)
      : invoice.name
  }.pdf`;
  alink.click();
};

const INVOICE_BILLING_SUMMARY_CSV_HEADERS = [
  '',
  'Customer',
  'Account ID',
  'Posted',
  'Invoice date',
  'Invoice No',
  'HAWB',
  'Order name',
  'Ref Nos',
  'Service level',
  'Inbound name',
  'Outbound name',
  'Terminal',
  'Stop type',
  'Service date',
  'Pieces',
  'Weight',
  'Total',
];

// we show pickup/recovery (inbound) first, then line haul/order charge, then
// delivery/transfer (outbound) last when displaying stop type text
function getStopTypeOrderPriority(type: ShipmentTypeForCharge) {
  switch (type) {
    case ShipmentTypeForCharge.Pickup:
    case ShipmentTypeForCharge.Recovery:
      return 1;
    case ShipmentTypeForCharge.LineHaul:
    case ShipmentTypeForCharge.OrderCharge:
      return 2;
    case ShipmentTypeForCharge.Delivery:
    case ShipmentTypeForCharge.Transfer:
      return 3;
    default:
      return exhaustive(type);
  }
}

export const convertInvoiceBillingSummaryV2DataToCSV = (
  billingSummaryData: InvoiceBillingSummaryReportV2Fragment,
) => {
  const rows: (string | number | undefined)[][] = [];

  billingSummaryData.groups.forEach((group) => {
    group.subgroups.forEach((subgroup) => {
      subgroup.lineItems.forEach((lineItem) => {
        const hasLineHaul = lineItem.shipmentsIncludedInLineItemRevenue.find(
          (s) => s.shipmentTypeForCharge === ShipmentTypeForCharge.LineHaul,
        );

        let terminalColumnText = '';

        // if one of the shipments whose revenue is counted in this line
        // is a line haul, then show LH: ATL-CLT
        if (hasLineHaul && !isNil(lineItem.orderLineHaulLane)) {
          terminalColumnText = `LH: ${lineItem.orderLineHaulLane.originTerminalCode}-${lineItem.orderLineHaulLane.destinationTerminalCode}`;
        } else {
          // show all the terminals here, if grouped by terminal it would usually be one
          // if grouped by contact, it could be multiple.
          terminalColumnText = uniq(
            lineItem.shipmentsIncludedInLineItemRevenue
              .filter((s) => !isNil(s.terminalCode))
              .map((s) => s.terminalCode),
          ).join(', ');
        }

        let stopTypeText = '';

        if (hasLineHaul) {
          const shipmentsWithoutLineHaul =
            lineItem.shipmentsIncludedInLineItemRevenue.filter(
              (s) => s.shipmentTypeForCharge !== ShipmentTypeForCharge.LineHaul,
            );

          stopTypeText = shipmentsWithoutLineHaul
            .sort(
              (a, b) =>
                getStopTypeOrderPriority(a.shipmentTypeForCharge) -
                getStopTypeOrderPriority(b.shipmentTypeForCharge),
            )
            .map((s) =>
              s.shipmentTypeForCharge === ShipmentTypeForCharge.OrderCharge
                ? 'ORDER'
                : s.shipmentTypeForCharge,
            )
            .join(', ');
        } else {
          terminalColumnText = uniq(
            lineItem.shipmentsIncludedInLineItemRevenue
              .filter((s) => !isNil(s.terminalCode))
              .map((s) => s.terminalCode),
          ).join(', ');
          stopTypeText = lineItem.shipmentsIncludedInLineItemRevenue
            .sort(
              (a, b) =>
                getStopTypeOrderPriority(a.shipmentTypeForCharge) -
                getStopTypeOrderPriority(b.shipmentTypeForCharge),
            )
            .map((s) =>
              s.shipmentTypeForCharge === ShipmentTypeForCharge.OrderCharge
                ? 'ORDER'
                : s.shipmentTypeForCharge,
            )
            .join(', ');
        }

        rows.push([
          '',
          lineItem.contactDisplayName,
          lineItem.contactReferenceNumber ?? '',
          lineItem.invoiceStatus,
          dayjs(lineItem.invoiceDate).format('MM/DD/YY'),
          lineItem.invoiceName,
          lineItem.shipperBillOfLadingNumber ?? '',
          lineItem.orderName ?? '',
          lineItem.refNumbers?.join(', '),
          sentenceCase(lineItem.serviceLevel ?? ''),
          lineItem.inboundAddressName ?? '-',
          lineItem.outboundAddressName ?? '-',
          terminalColumnText,
          stopTypeText,
          !isNil(lineItem.serviceDate)
            ? dayjs(lineItem.serviceDate).format('MM/DD/YY')
            : '-',
          lineItem.pieces,
          lineItem.weight,
          lineItem.totalCharge.toFixed(2),
        ]);
      });
    });
  });

  return [INVOICE_BILLING_SUMMARY_CSV_HEADERS, ...rows];
};

export const downloadInvoiceBillingSummaryV2Csv = async ({
  startDate,
  endDate,
  billingSummaryData,
  terminalOption,
  businessDivisionOption,
  customerOption,
  groupOrdersBy,
}: {
  startDate: Date | undefined;
  endDate: Date | undefined;
  billingSummaryData: InvoiceBillingSummaryReportV2Fragment;
  terminalOption?: Option | null | undefined;
  businessDivisionOption?: BusinessDivisionOption | null | undefined;
  customerOption?: Option | null | undefined;
  groupOrdersBy: InvoiceBillingSummaryGroupByType;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }

  const fileName = `invoice-billing-summary${dateString}${
    !isNil(customerOption) ? `-${customerOption.label}` : ''
  }${!isNil(terminalOption) ? `-${terminalOption.label}` : ''}${
    !isNil(businessDivisionOption) ? `-${businessDivisionOption.label}` : ''
  }-by-${sentenceCase(groupOrdersBy.toString())}`;

  return {
    fileName,
    csvData: convertInvoiceBillingSummaryV2DataToCSV(billingSummaryData),
  };
};

const INVOICE_REGISTER_REPORT_CSV_HEADERS = [
  'Invoice No',
  'Status',
  'Invoice Date',
  'Customer',
  'Total',
  'Balance',
];

export const convertInvoiceRegisterReportDataToCSV = (
  invoices: InvoiceForRegisterReportFragment[],
  companyData: MeQuery | undefined,
) => {
  const rows: (string | number | undefined)[][] = [];
  const useJournalNum =
    companyData?.me?.company.configuration?.useJournalNumberForInvoice === true;

  invoices.forEach((invoice) => {
    rows.push([
      useJournalNum
        ? (invoice.journalNumber?.toString() ?? '')
        : (invoice.name ?? ''),
      invoice.status === InvoiceStatus.NotFinalized ? 'Not Posted' : 'Posted',
      dayjs(invoice.date).format('MM/DD/YY'),
      invoice.billToContact.displayName,
      currency(invoice.invoiceTotal).value,
      currency(invoice.balanceInCents, {
        fromCents: true,
      }).value,
    ]);
  });
  return [INVOICE_REGISTER_REPORT_CSV_HEADERS, ...rows];
};

export const downloadInvoiceRegisterReport = async ({
  companyData,
  contactName,
  startDate,
  endDate,
  status,
  invoices,
  downloadType,
}: {
  companyData: MeQuery | undefined;
  contactName: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  status: InvoiceStatusFilter;
  invoices: InvoiceForRegisterReportFragment[];
  downloadType: DownloadType;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }
  const fileName = `invoice-register-report${
    !isNil(contactName) ? `-${contactName}` : ''
  }${status !== InvoiceStatusFilter.All ? `-${status}` : ''}-${dateString}`;

  const transformedInvoices = invoices
    .sort((a, b) =>
      a.billToContact.displayName.localeCompare(b.billToContact.displayName),
    )
    .map((invoice) => ({
      ...invoice,
      invoiceBalance: currency(invoice.balanceInCents, {
        fromCents: true,
      }).format(),
    }));

  const totalInvoiceTotal = transformedInvoices.reduce(
    (prev, curr) => prev.add(curr.invoiceTotal),
    currency(0),
  ).value;
  const totalInvoiceBalance = transformedInvoices.reduce(
    (prev, curr) => prev.add(curr.invoiceBalance),
    currency(0),
  ).value;

  const company = companyData?.me?.company;
  if (isNil(company)) {
    console.error(
      '[downloadInvoiceRegisterReport] Attempted to download invoice register report without company data',
    );
    return undefined;
  }

  switch (downloadType) {
    case DownloadType.PDF: {
      const blob = await pdf(
        <GeneratedInvoiceRegisterReport
          company={company}
          displayStatus={sentenceCase(status)}
          dateString={dateString}
          invoices={transformedInvoices}
          isFirstChunk
          isLastChunk
          totalInvoiceTotal={totalInvoiceTotal}
          totalInvoiceBalance={totalInvoiceBalance}
        />,
      ).toBlob();
      saveAs(blob, `${fileName}.pdf`);
      break;
    }
    case DownloadType.CSV: {
      return {
        fileName,
        csvData: convertInvoiceRegisterReportDataToCSV(
          transformedInvoices,
          companyData,
        ),
      };
    }
    default:
      exhaustive(downloadType);
  }
  return undefined;
};

export interface OpenInvoiceReportTransformedRow {
  customer: string;
  accountID: string;
  posted: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceName: string;
  journalNumber: string;
  serviceDate: string;
  shipperBillOfLadingNumber: string;
  masterAirwayBillOfLadingNumber: string;
  orderName: string;
  refNumbers: string;
  originTerminalCode?: string;
  destinationTerminalCode?: string;
  pieces: string;
  weight: string;
  total: string;
  balance: string;
}

export const OPEN_INVOICES_REPORT_CSV_HEADERS: OpenInvoiceReportTransformedRow =
  {
    customer: 'Customer',
    accountID: 'Account ID',
    posted: 'Posted',
    invoiceDate: 'Invoice date',
    invoiceDueDate: 'Invoice due date',
    invoiceName: 'Invoice name',
    journalNumber: 'Journal no',
    serviceDate: 'Service date',
    shipperBillOfLadingNumber: 'HAWB',
    masterAirwayBillOfLadingNumber: 'MAWB',
    orderName: 'Order name',
    refNumbers: 'Ref nos',
    pieces: 'Pieces',
    weight: 'Weight',
    total: 'Total',
    balance: 'Balance',
  };
export const OPEN_INVOICES_REPORT_CSV_HEADERS_WITH_TERMINALS: OpenInvoiceReportTransformedRow =
  {
    ...OPEN_INVOICES_REPORT_CSV_HEADERS,
    originTerminalCode: 'Orig',
    destinationTerminalCode: 'Dest',
  };

export const convertOpenInvoiceReportDataToCSV = (
  groupedInvoices: Record<string, InvoiceForOpenInvoiceReportFragment[]>,
  terminalsEnabled: boolean,
): (string | undefined)[][] => {
  const rows: OpenInvoiceReportTransformedRow[] = [];
  Object.entries(groupedInvoices)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .forEach(([__, groupInvoices]) =>
      groupInvoices.map((invoice) =>
        invoice.orders.forEach((order) => {
          let orderRow: OpenInvoiceReportTransformedRow = {
            customer: invoice.billingPartyContactDisplayName ?? '',
            accountID: invoice.billingPartyContactReferenceNumber ?? '',
            posted:
              invoice.status === InvoiceStatus.NotFinalized ? 'No' : 'Yes',
            invoiceDate: dayjs(invoice.date).format('MM/DD/YY'),
            invoiceDueDate: !isNil(invoice.dueDate)
              ? dayjs(invoice.dueDate).format('MM/DD/YY')
              : '',
            invoiceName: invoice.name,
            journalNumber: invoice.journalNumber ?? '',
            serviceDate: !isNil(order?.serviceDate)
              ? dayjs(order?.serviceDate).format('MM/DD/YY')
              : '',
            shipperBillOfLadingNumber: order?.shipperBillOfLadingNumber ?? '',
            masterAirwayBillOfLadingNumber:
              order?.masterAirwayBillOfLadingNumber ?? '',
            orderName: order?.orderName ?? '',
            refNumbers: order?.refNumbers?.join('; ') ?? '',
            pieces: order.pieces.toString(),
            weight: order.weight.toString(),
            total: safeDivide(order.orderTotalCents, 100).toFixed(2),
            balance: safeDivide(order.orderBalanceCents, 100).toFixed(2),
          };
          if (terminalsEnabled) {
            orderRow = {
              ...orderRow,
              originTerminalCode: order.originTerminalCode ?? '',
              destinationTerminalCode: order.destinationTerminalCode ?? '',
            };
          }
          rows.push(orderRow);
        }),
      ),
    );

  const csvData = [
    terminalsEnabled
      ? OPEN_INVOICES_REPORT_CSV_HEADERS_WITH_TERMINALS
      : OPEN_INVOICES_REPORT_CSV_HEADERS,
    ...rows,
  ];
  return csvData.map((row) => values(row));
};

export const downloadOpenInvoicesReport = async ({
  companyData,
  startDate,
  endDate,
  status,
  invoices,
  businessDivisionOption,
  customerOption,
  downloadType,
}: {
  companyData: MeQuery | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  status: InvoiceStatusTab;
  invoices: InvoiceForOpenInvoiceReportFragment[];
  businessDivisionOption?: BusinessDivisionOption | null | undefined;
  customerOption?: Option | null | undefined;
  downloadType: DownloadType;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD/YY')} - ${dayjs(
      endDate,
    ).format('MM/DD/YY')}`;
  }

  const groupedInvoices: Record<string, InvoiceForOpenInvoiceReportFragment[]> =
    groupBy(invoices, 'billingPartyContactDisplayName');

  const fileName = filenamify(
    `open-invoices-report${dateString}${
      !isNil(customerOption) ? `-${customerOption.label}` : ''
    }${
      !isNil(businessDivisionOption) ? `-${businessDivisionOption.label}` : ''
    }`,
  );

  const terminalsEnabled =
    companyData?.me?.company.configuration?.terminalsEnabled === true;

  switch (downloadType) {
    case DownloadType.PDF: {
      const blob = await pdf(
        <GeneratedOpenInvoicesReport
          companyData={companyData}
          status={status}
          dateString={dateString}
          groupedInvoices={groupedInvoices}
          businessDivisionOption={businessDivisionOption}
          customerOption={customerOption}
        />,
      ).toBlob();
      saveAs(blob, `${fileName}.pdf`);
      break;
    }
    case DownloadType.CSV: {
      return {
        fileName: `${fileName}.csv`,
        csvData: convertOpenInvoiceReportDataToCSV(
          groupedInvoices,
          terminalsEnabled,
        ),
      };
    }
    default:
      exhaustive(downloadType);
  }
  return undefined;
};

export const downloadPaymentsForJournalReport = async ({
  companyData,
  startDate,
  endDate,
  terminalName,
  payments,
  paymentTypes,
}: {
  companyData: MeQuery | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  terminalName: string | undefined;
  payments: PaymentForJournalReportFragment[];
  paymentTypes: PaymentType[];
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }

  const paymentsByContact = groupBy(payments, 'contactUuid');

  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedPaymentJournalReport
      companyData={companyData}
      dateString={dateString}
      terminalName={terminalName}
      paymentsByContact={paymentsByContact}
      paymentTypes={paymentTypes}
    />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = window.URL.createObjectURL(file);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = `${paymentTypes.map((paymentType) => paymentType.toLowerCase()).join('-')}-journal-report-${dateString}.pdf`;
  alink.click();
};

export const downloadPaymentApplicationReport = async (
  props: GeneratedPaymentApplicationReportProps,
) => {
  const { dateOption } = props;
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedPaymentApplicationReport {...props} />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = window.URL.createObjectURL(file);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = `payment-application-report-${formatDateOption(dateOption)}.pdf`;
  alink.click();
};

export const downloadAccountsReceivableReport = async (
  props: GeneratedAccountsReceivableReportProps,
) => {
  const { dateOption } = props;
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(
    <GeneratedAccountsReceivableReport {...props} />,
  ).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  const pdfBytes = await pdfDoc.save();
  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = window.URL.createObjectURL(file);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = `accounts-receivable-report-${formatDateOption(dateOption)}.pdf`;
  alink.click();
};

export const downloadAccessorialDetailsReport = async ({
  companyData,
  startDate,
  endDate,
  status,
  accessorialDetails,
  terminalOption,
  accessorialOption,
  terminalsEnabled,
}: {
  companyData: MeQuery | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  status: InvoiceStatusTab;
  accessorialDetails: AccessorialChargeDetailsDataFragment[];
  terminalOption?: Option | null | undefined;
  accessorialOption?: AccessorialOption | null | undefined;
  terminalsEnabled: boolean;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }
  const blob = await pdf(
    <GeneratedAccessorialDetailsReport
      companyData={companyData}
      status={status}
      dateString={dateString}
      terminalOption={terminalOption}
      accessorialOption={accessorialOption}
      accessorialDetails={accessorialDetails}
      terminalsEnabled={terminalsEnabled}
    />,
  ).toBlob();
  const fileName = `accessorial-details-report-${
    accessorialOption?.label ?? 'ALL'
  }${dateString}${
    !isNil(terminalOption) ? `-${terminalOption.label}` : ''
  }-${status}-invoices.pdf`;
  saveAs(blob, fileName);
};

export const downloadIncomeAnalysisReport = async ({
  companyData,
  startDate,
  endDate,
  reportDateFilterType,
  status,
  incomeAnalysisReportData,
  terminalOption,
  terminalsEnabled,
}: {
  companyData: CompanyFragment | undefined;
  startDate: Date | null;
  endDate: Date | null;
  reportDateFilterType: ReportDateFilterType;
  status: InvoiceStatusTab;
  incomeAnalysisReportData: CustomerReportBucketFragment[];
  terminalOption?: Option | null | undefined;
  terminalsEnabled: boolean;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }

  const reportGroupConfiguration: ReportGroupConfiguration = {
    uuid: '',
    name: '',
    orderStatuses: [],
    stopTypes: [],
    reportAggregationPeriod: ReportAggregationPeriod.Week,
    defaultReportType: ReportType.Customer,
    reportRevenueType: ReportRevenueType.Earned,
    reportStatistics: [],
    lastNumberOfDays: undefined,
    lastNumberOfWeeks: undefined,
    lastNumberOfMonths: undefined,
    startDate,
    endDate,
  };
  const reportData = convertDataToCustomerReportBucketData(
    incomeAnalysisReportData,
  );

  const blob = await pdf(
    <GeneratedRevenueReport
      companyData={companyData}
      reportData={reportData}
      reportGroupConfiguration={reportGroupConfiguration}
      terminalsEnabled={terminalsEnabled}
      isIncomeAnalysisReport
      isRevenueReport={false}
      invoiceStatus={status}
      reportDateFilterType={reportDateFilterType}
    />,
  ).toBlob();
  const fileName = `income-analysis-report-${dateString}${
    !isNil(terminalOption) ? `-${terminalOption.label}` : ''
  }-${status}-invoices.pdf`;
  saveAs(blob, fileName);
};

export const getPaymentTypeColorForChip = (
  paymentType: PaymentType | string,
) => {
  switch (paymentType) {
    case PaymentType.Credit:
      return 'info';
    case PaymentType.Debit:
      return 'warning';
    case PaymentType.Payment:
      return 'success';
    default:
      return 'default';
  }
};

export interface AccountsReceivableReportTransformedRow {
  customer: string;
  accountID: string;
  totalInvoices: string;
  totalPayments: string;
  unappliedPayments: string;
  totalCredits: string;
  unappliedCredits: string;
  totalDebits: string;
  unappliedDebits: string;
  balance: string;
}

export const ACCOUNTS_RECEIVABLE_CSV_HEADERS: AccountsReceivableReportTransformedRow =
  {
    customer: 'Customer',
    accountID: 'Account ID',
    totalInvoices: 'Total Invoices',
    totalPayments: 'Total Payments',
    unappliedPayments: 'Unapplied Payments',
    totalCredits: 'Total Credits',
    unappliedCredits: 'Unapplied Credits',
    totalDebits: 'Total Debits',
    unappliedDebits: 'Unapplied Debits',
    balance: 'Balance',
  };

export const convertAccountsReceivableDataToCSV = (
  accounts: AccountsReceivableDataFragment[],
) => {
  const rows: AccountsReceivableReportTransformedRow[] = [];
  accounts.forEach((account) => {
    rows.push({
      customer: account.displayName,
      accountID: account.contactReferenceNumber ?? '',
      totalInvoices: account.totalInvoices.toFixed(2),
      totalPayments: account.totalPayments.toFixed(2),
      unappliedPayments: account.unappliedPayments.toFixed(2),
      totalCredits: account.totalCredits.toFixed(2),
      unappliedCredits: account.unappliedCredits.toFixed(2),
      totalDebits: safeMultiply(account.totalDebits, -1).toFixed(2),
      unappliedDebits: safeMultiply(account.unappliedDebits, -1).toFixed(2),
      balance: account.balance.toFixed(2),
    });
  });

  const csvData = [ACCOUNTS_RECEIVABLE_CSV_HEADERS, ...rows];
  return csvData.map((row) => values(row));
};

const UNBILLED_REVENUE_CSV_HEADERS = [
  'Customer',
  'Account ID',
  'HAWB',
  'MAWB',
  'Order name',
  'Controlling terminal',
  'Status',
  'Inbound address',
  'Outbound address',
  'Service date',
  'Pcs',
  'Weight',
  'Total',
  'POD document uploaded',
  'Inbound stop type',
  'Outbound stop type',
];

export const convertUnbilledRevenueDataToCSV = (
  unbilledOrders: RevenueReportOrderDataFragment[],
) => {
  const rows: (string | number | undefined)[][] = [];
  unbilledOrders.forEach((order) => {
    const serviceDates = order.serviceDates
      .map((serviceDate) => dayjs(serviceDate).format('MM/DD/YY'))
      .join(', ');
    const pieces = order.packageQuantity;
    const weight = order.packageWeight;

    rows.push([
      order.contactName ?? '',
      order.contactReferenceNumber ?? '',
      order.shipperBillOfLadingNumber ?? '',
      order.masterAirwayBillOfLadingNumber ?? '',
      order.name ?? '',
      order.controllingTerminalCode ?? '',
      sentenceCase(order.detailedStatusV2 ?? '-'),
      order.inboundAddressString ?? '',
      order.outboundAddressString ?? '',
      serviceDates,
      pieces,
      Math.round(weight),
      order.totalCharge.toFixed(2),
      order?.podUploaded === true ? 'YES' : 'NO',
      order.inboundStopType ?? '',
      order.outboundStopType ?? '',
    ]);
  });
  return [UNBILLED_REVENUE_CSV_HEADERS, ...rows];
};

export const getAccountingReportDocumentUuid = async ({
  url,
  fileName,
  name,
  companyUuid,
}: {
  url: string | undefined;
  fileName: string;
  name: string;
  companyUuid: string;
}) => {
  if (isNil(url)) {
    throw Error(
      `nil generated url for accounting report for order: ${companyUuid}`,
    );
  }
  const uuid = v4();
  let uniqueFileName;
  if (fileName.endsWith('.pdf')) {
    uniqueFileName = `${fileName.substring(0, fileName.lastIndexOf('.'))}_${uuid}.pdf`;
  } else {
    uniqueFileName = `${fileName}_${uuid}`;
  }

  const urlResult = await apolloClient.mutate<
    GenerateAccountingReportPreSignedPutUrlMutation,
    GenerateAccountingReportPreSignedPutUrlMutationVariables
  >({
    mutation: GenerateAccountingReportPreSignedPutUrlDocument,
    variables: {
      generateAccountingReportPreSignedPutUrlInput: {
        fileName: uniqueFileName,
        fileType: MimeType.PDF,
        companyUuid,
      },
    },
  });
  const preSignedUrl = urlResult.data?.generateAccountingReportPreSignedPutUrl;
  if (!isNil(preSignedUrl)) {
    const blobRes = await fetch(url);
    const resStatus = await uploadDocumentToAws(
      preSignedUrl,
      new File([await blobRes.blob()], uniqueFileName),
      MimeType.PDF,
    );
    if (resStatus === 200) {
      const resDocument = await apolloClient.mutate<
        CreateAccountingReportDocumentMutation,
        CreateAccountingReportDocumentMutationVariables
      >({
        mutation: CreateAccountingReportDocumentDocument,
        variables: {
          createAccountingReportDocumentInput: {
            documentType: DocumentType.AccountingReport,
            fileType: MimeType.PDF,
            fileName: uniqueFileName,
            name,
            companyUuid,
            thumbnail: null,
          },
        },
      });

      return resDocument.data?.createAccountingReportDocument.document.uuid;
    }
  }
  return null;
};

export const sendAccountingReportEmail = async ({
  blob,
  fileName,
  companyUuid,
  reportType,
  documentName,
  senderEmail,
  recipientEmails,
}: {
  blob: Blob;
  fileName: string;
  companyUuid: string | undefined;
  reportType: AccountingReportType;
  documentName: string;
  senderEmail: string | undefined | null;
  recipientEmails: string | undefined | null;
}) => {
  const fileURL = window.URL.createObjectURL(blob);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = fileName;

  if (!isNil(alink) && !isNil(companyUuid)) {
    const accountingReportDocumentUuid = await getAccountingReportDocumentUuid({
      url: alink.href,
      fileName,
      name: documentName,
      companyUuid,
    });
    const recipientEmailsList = recipientEmails
      ?.split(',')
      .map((email) => email.trim());

    if (
      !isNil(accountingReportDocumentUuid) &&
      !isNil(recipientEmailsList) &&
      !isNil(recipientEmailsList[0])
    ) {
      const res = await apolloClient.mutate<
        EmailAccountingReportMutation,
        EmailAccountingReportMutationVariables
      >({
        mutation: EmailAccountingReportDocument,
        variables: {
          emailAccountingReportInput: {
            accountingReportDocumentUuid,
            senderEmail,
            recipientEmail: recipientEmailsList[0],
            reportType,
            ccEmails: recipientEmailsList.slice(1),
          },
        },
      });
      return res.data?.emailAccountingReport.status;
    }
  }
  return EmailAccountingReportStatus.Other;
};

const PAYMENT_JOURNAL_REPORT_CSV_HEADERS = [
  'Customer',
  'Date',
  'Reference number',
  'Comment',
  'Total',
  'Invoice name',
  'Journal number',
  'Invoice date',
  'Order #',
  'HAWB',
  'Type',
  'Applied amount',
];

export const convertPaymentJournalReportDataToCSV = (
  payments: PaymentForJournalReportFragment[],
  paymentTypes: PaymentType[],
) => {
  const paymentsTransformed = payments.map((payment) => {
    const multiplyFactor = payment.paymentType === PaymentType.Debit ? -1 : 1;
    const unappliedPaymentToOrderMapping: OrderForPaymentJournalReportFragment | null =
      payment.unappliedAmountDollars !== 0
        ? {
            appliedAmountDollars: safeMultiply(
              payment.unappliedAmountDollars,
              multiplyFactor,
            ),
            orderUuid: '',
            orderName: `UNAPPLIED ${payment.paymentType}`,
            shipperBillOfLadingNumber: '',
            invoiceName: `UNAPPLIED ${payment.paymentType}`,
            invoiceJournalNumber: 0,
            invoiceDate: null,
          }
        : null;
    return {
      ...payment,
      amount: safeMultiply(payment.totalAmount, multiplyFactor),
      orders: filterNotNil([
        ...payment.orders.map((order) => ({
          ...order,
          appliedAmountDollars: safeMultiply(
            order.appliedAmountDollars,
            multiplyFactor,
          ),
        })),
        unappliedPaymentToOrderMapping,
      ]),
    };
  });
  const rows: (string | number | null | undefined)[][] = paymentsTransformed
    .sort((a, b) => {
      // Sort first by contact name and then by date.
      const nameDiff = a.contactDisplayName.localeCompare(b.contactDisplayName);
      if (nameDiff !== 0) {
        return nameDiff;
      }
      return dayjs(a.paymentDate).diff(b.paymentDate);
    })
    .flatMap((payment) =>
      filterNotNil(
        payment.orders.map((order) => {
          let res = [
            payment.contactDisplayName,
            dayjs(payment.paymentDate).format('MM/DD/YY'),
            payment.referenceNumber,
            payment.comment?.replace(/\r\n|\n|\r/gm, ' '),
            currency(payment.totalAmount).format(),
            order.invoiceName,
            order.invoiceJournalNumber,
            dayjs(order.invoiceDate).format('MM/DD/YY'),
            order.orderName,
            order.shipperBillOfLadingNumber ?? '',
            payment.paymentType,
            currency(order.appliedAmountDollars).format(),
          ];
          if (
            paymentTypes.includes(PaymentType.Credit) ||
            paymentTypes.includes(PaymentType.Debit)
          ) {
            res = [
              payment.creditTypeName ?? '-',
              payment.glTerminalName ?? '-',
              ...res,
            ];
          }
          return res;
        }),
      ),
    );
  let headers = PAYMENT_JOURNAL_REPORT_CSV_HEADERS;
  const paymentTypeStr = paymentTypes
    .map((paymentType) => capitalCase(paymentType))
    .join('/');
  if (
    paymentTypes.includes(PaymentType.Credit) ||
    paymentTypes.includes(PaymentType.Debit)
  ) {
    headers = [`${paymentTypeStr} Type`, 'GL Terminal', ...headers];
  }
  rows.unshift(headers);
  return rows;
};

const PAYMENT_APPLICATION_REPORT_CSV_HEADERS = [
  'Customer',
  'Account ID',
  'Master account',
  'Payment date',
  'Payment ref #',
  'Payment type',
  'Comment',
  'Payment total',
  'Payment total applied',
  'Payment total unapplied',
  'Invoice name',
  'Journal number',
  'Invoice date',
  'Invoice balance',
  'Order name',
  'HAWB',
  'MAWB',
  'Ref #s',
  'Pieces',
  'Weight',
  'Inbound name',
  'Outbound name',
  'Order total',
  'Order balance',
  'Applied amount',
];

export const convertPaymentApplicationReportDataToCSV = (
  payments: PaymentForPaymentApplicationReportFragment[],
) => {
  const paymentsTransformed: PaymentForPaymentApplicationReportFragment[] =
    payments.map((payment) =>
      payment.paymentType === PaymentType.Debit
        ? {
            ...payment,
            totalAmount: safeMultiply(payment.totalAmount, -1),
            unappliedAmount: safeMultiply(payment.unappliedAmount, -1),
            orders: payment.orders.map((order) => ({
              ...order,
              appliedAmount: safeMultiply(order.appliedAmount, -1),
            })),
          }
        : payment,
    );
  const rows: (string | number | undefined)[][] = paymentsTransformed.flatMap(
    (payment) => {
      const {
        referenceNumber,
        paymentDate,
        paymentType,
        comment,
        totalAmount,
        unappliedAmount,
        contactDisplayName,
        contactReferenceNumber,
        masterAccountName,
      } = payment;
      return filterNotNil(
        payment.orders.map((order) => {
          const {
            orderName,
            shipperBillOfLadingNumber,
            masterAirwayBillOfLadingNumber,
            refNumbers,
            inboundAddressName,
            outboundAddressName,
            pieces,
            weight,
            orderTotal,
            orderBalance,
            appliedAmount,
            invoiceUuid,
            invoiceName,
            invoiceJournalNumber,
            invoiceDate,
            invoiceBalance,
          } = order;
          if (isNilOrEmptyString(invoiceUuid)) return null;
          return [
            contactDisplayName,
            contactReferenceNumber ?? '-',
            masterAccountName ?? '-',
            dayjs(paymentDate).format('MM/DD/YY'),
            referenceNumber,
            sentenceCase(paymentType),
            comment?.replace(/\r\n|\n|\r/gm, ' ') ?? '-',
            currency(totalAmount).format(),
            currency(safeSubtract(totalAmount, unappliedAmount)).format(),
            currency(unappliedAmount).format(),
            invoiceName ?? '-',
            invoiceJournalNumber ?? '-',
            !isNil(invoiceDate) ? dayjs(invoiceDate).format('MM/DD/YY') : '-',
            !isNil(invoiceBalance) ? currency(invoiceBalance).format() : '-',
            orderName,
            shipperBillOfLadingNumber ?? '-',
            masterAirwayBillOfLadingNumber ?? '-',
            refNumbers?.join(' ') ?? '-',
            pieces,
            weight,
            inboundAddressName ?? '-',
            outboundAddressName ?? '-',
            currency(orderTotal).format(),
            currency(orderBalance).format(),
            currency(appliedAmount).format(),
          ];
        }),
      );
    },
  );
  rows.unshift(PAYMENT_APPLICATION_REPORT_CSV_HEADERS);
  return rows;
};

const AGING_REPORT_SUMMARY_CSV_HEADERS_OLD = [
  'Customer',
  'Balance',
  'Current',
  '31 to 38',
  '39 to 45',
  '46 to 60',
  '61 to 75',
  'Over 75',
];

export const convertAgingReportSummaryDataToCSVOld = (
  agingReportData: InvoiceAgingReportDataFragment[],
) => {
  const rows: (string | number | undefined)[][] = agingReportData
    .sort((a, b) => (a?.displayName ?? '').localeCompare(b?.displayName ?? ''))
    .map((contact) => [
      contact.displayName ?? '',
      contact.contactOpenInvoiceValue?.toFixed(2),
      contact.contactOpenInvoiceValue0to30d?.toFixed(2),
      contact.contactOpenInvoiceValue31to38d?.toFixed(2),
      contact.contactOpenInvoiceValue39to45d?.toFixed(2),
      contact.contactOpenInvoiceValue46to60d?.toFixed(2),
      contact.contactOpenInvoiceValue61to75d?.toFixed(2),
      contact.contactOpenInvoiceValueOver75d?.toFixed(2),
    ]);
  return [AGING_REPORT_SUMMARY_CSV_HEADERS_OLD, ...rows];
};

// Be careful about removing any entries in this array because we splice out the invoice name if useJournalNumberForInvoice is true.
const AGING_REPORT_DETAILED_CSV_HEADERS_OLD = [
  'Customer',
  'Date',
  'Invoice name',
  'Journal/Check number',
  'Age',
  'Balance',
  'Current',
  '31 to 38',
  '39 to 45',
  '46 to 60',
  '61 to 75',
  'Over 75',
];

export const convertAgingDetailedReportDataToCSVOld = (
  agingReportData: InvoiceAgingReportDataFragment[],
  startDate: Dayjs | null | undefined,
  useJournalNumberForInvoice: boolean | undefined,
) => {
  const startDays = !isNil(startDate) ? dayjs().diff(startDate, 'day') : 0;
  const rows = agingReportData
    .sort((a, b) => (a?.displayName ?? '').localeCompare(b?.displayName ?? ''))
    .flatMap((contact) =>
      (contact?.invoices ?? [])
        .sort((a, b) => {
          if (dayjs(a.date).isBefore(b.date)) return 1;
          if (dayjs(a.date).isAfter(b.date)) return -1;
          return a.name.localeCompare(b.name);
        })
        .filter((invoice) => invoice.totalOpenAmount !== 0)
        .map((invoice) => {
          // Be careful about removing any entries in this array because we splice out the invoice name if useJournalNumberForInvoice is true.
          const arr = [
            contact.displayName ?? '',
            invoice.date,
            invoice.name,
            invoice.journalNumber ?? '-',
            invoice.age,
            invoice.totalOpenAmount.toFixed(2),
            invoice.age <= 30 + startDays ? invoice.totalOpenAmount : '0.00',
            invoice.age > 30 + startDays && invoice.age <= 38 + startDays
              ? invoice.totalOpenAmount
              : '0.00',
            invoice.age > 38 + startDays && invoice.age <= 45 + startDays
              ? invoice.totalOpenAmount
              : '0.00',
            invoice.age > 45 + startDays && invoice.age <= 60 + startDays
              ? invoice.totalOpenAmount
              : '0.00',
            invoice.age > 60 + startDays && invoice.age <= 75 + startDays
              ? invoice.totalOpenAmount
              : '0.00',
            invoice.age > 75 + startDays ? invoice.totalOpenAmount : '0.00',
          ];
          if (useJournalNumberForInvoice === true) {
            arr.splice(2, 1);
          }
          return arr;
        }),
    );
  if (useJournalNumberForInvoice === true) {
    AGING_REPORT_DETAILED_CSV_HEADERS_OLD.splice(2, 1);
  }
  rows.unshift(AGING_REPORT_DETAILED_CSV_HEADERS_OLD);
  return rows;
};

export const convertAgingReportSummaryDataToCSV = (
  agingReportData: AgingReportDataFragment[],
) => {
  const headerBuckets = agingReportData[0]?.openInvoiceValueBuckets ?? [];
  const csvHeaders = [
    'Customer',
    ...headerBuckets.map((bucket) => getAgingReportColumnHeader({ bucket })),
  ];

  const rows: (string | number | undefined)[][] = agingReportData
    .sort((a, b) => (a?.displayName ?? '').localeCompare(b?.displayName ?? ''))
    .filter((contact) => contact.contactUuid.length > 0)
    .map((contact) => [
      contact.displayName ?? '',
      ...contact.openInvoiceValueBuckets.map((bucket) =>
        bucket.openInvoiceValue.toFixed(2),
      ),
    ]);
  return [csvHeaders, ...rows];
};

export const convertAgingDetailedReportDataToCSV = (
  agingReportData: AgingReportDataFragment[],
  startDate: Dayjs | null | undefined,
  useJournalNumberForInvoice: boolean | undefined,
) => {
  const headerBuckets = agingReportData[0]?.openInvoiceValueBuckets ?? [];
  // Be careful about removing any entries in this array because we splice out the invoice name if useJournalNumberForInvoice is true.
  const csvHeaders = [
    'Customer',
    'Date',
    'Invoice name',
    'Journal/Check number',
    'Age',
    ...headerBuckets.map((bucket) => getAgingReportColumnHeader({ bucket })),
  ];

  const startDays = !isNil(startDate) ? dayjs().diff(startDate, 'day') : 0;
  const rows = agingReportData
    .sort((a, b) => (a?.displayName ?? '').localeCompare(b?.displayName ?? ''))
    .filter((contact) => contact.contactUuid.length > 0)
    .flatMap((contact) =>
      contact.invoices
        .sort((a, b) => {
          if (dayjs(a.date).isBefore(b.date)) return 1;
          if (dayjs(a.date).isAfter(b.date)) return -1;
          return a.name.localeCompare(b.name);
        })
        .map((invoice) => {
          // Be careful about removing any entries in this array because we splice out the invoice name if useJournalNumberForInvoice is true.
          const arr = [
            contact.displayName ?? '',
            dayjs(invoice.date).format('MM/DD/YY'),
            invoice.name,
            invoice.journalNumber ?? '-',
            invoice.age,
            ...contact.openInvoiceValueBuckets.map((bucket) =>
              calculateInvoiceOpenBalanceForBucket({
                bucket,
                invoice,
                startDays,
              }).toFixed(2),
            ),
          ];
          if (useJournalNumberForInvoice === true) {
            arr.splice(2, 1);
          }
          return arr;
        }),
    );
  if (useJournalNumberForInvoice === true) {
    csvHeaders.splice(2, 1);
  }
  rows.unshift(csvHeaders);
  return rows;
};

const NET_SALES_SUMMARY_REPORT_CSV_HEADERS = [
  'Customer',
  'Pieces',
  'Weight',
  'Dim weight',
  'Total',
  'Adjustments',
  'Net sales',
];

export const convertNetSalesSummaryReportDataToCSV = (
  data: NetSalesSummaryReportOutputRow[],
) => {
  const rows = data.map((row) => [
    row.contactName,
    row.pieces,
    row.weight,
    row.dimWeight,
    currency(row.openInvoiesTotalInCents, { fromCents: true }).value,
    currency(row.adjustmentsInCents, { fromCents: true }).value,
    currency(row.netSalesInCents, { fromCents: true }).value,
  ]);
  rows.unshift(NET_SALES_SUMMARY_REPORT_CSV_HEADERS);
  return rows;
};

// Use this if exactly 1 sort (not 0 or multiple) should be active
export const SingleColumnInvoiceTableSortLabel = ({
  label,
  sortBy,
  currentSort,
  setSort,
  onClick,
}: {
  label: string;
  sortBy: FindInvoicesSortFields; // This column's sort fields
  currentSort: FindInvoicesSort; // Currently set sort
  setSort: Dispatch<SetStateAction<FindInvoicesSort>>;
  onClick?: () => void;
}) => {
  const sortActive = currentSort.sortBy === sortBy;

  const handleChangeSort = () => {
    if (currentSort.sortBy === sortBy) {
      // toggle direction
      setSort({
        sortBy,
        sortDirection:
          currentSort.sortDirection === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc,
      });
    } else {
      // set to default for field
      switch (sortBy) {
        case FindInvoicesSortFields.DueDate:
        case FindInvoicesSortFields.CreatedAt:
        case FindInvoicesSortFields.Date:
          setSort({
            sortBy,
            sortDirection: SortDirection.Desc,
          });
          break;
        case FindInvoicesSortFields.InvoiceNameOrJournalNumber:
          setSort({
            sortBy,
            sortDirection: SortDirection.Asc,
          });
          break;
        default:
          exhaustive(sortBy);
      }
    }
  };

  return (
    <TableSortLabel
      active={sortActive}
      direction={
        sortActive
          ? (currentSort.sortDirection.toLowerCase() as 'asc' | 'desc')
          : undefined
      }
      hideSortIcon={false}
      onClick={() => {
        handleChangeSort();
        if (!isNil(onClick)) {
          onClick();
        }
      }}
    >
      {label}
    </TableSortLabel>
  );
};

// Use this if exactly 1 sort (not 0 or multiple) should be active
export const SingleColumnInvoiceOrdersTableSortLabel = ({
  label,
  sortBy,
  currentSort,
  setSort,
  onClick,
}: {
  label: string;
  sortBy: FindOrdersOnInvoiceSortFields; // This column's sort fields
  currentSort: FindOrdersOnInvoiceSort; // Currently set sort
  setSort: Dispatch<SetStateAction<FindOrdersOnInvoiceSort>>;
  onClick?: () => void;
}) => {
  const sortActive = currentSort.sortBy === sortBy;

  const handleChangeSort = () => {
    if (currentSort.sortBy === sortBy) {
      // toggle direction
      setSort({
        sortBy,
        sortDirection:
          currentSort.sortDirection === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc,
      });
    } else {
      // set to default for field
      switch (sortBy) {
        case FindOrdersOnInvoiceSortFields.ServiceDate:
          setSort({
            sortBy,
            sortDirection: SortDirection.Desc,
          });
          break;
        case FindOrdersOnInvoiceSortFields.ShipperBillOfLadingNumber:
        case FindOrdersOnInvoiceSortFields.MasterAirwayBillOfLadingNumber:
        case FindOrdersOnInvoiceSortFields.Consignee:
          setSort({
            sortBy,
            sortDirection: SortDirection.Asc,
          });
          break;
        default:
          exhaustive(sortBy);
      }
    }
  };

  return (
    <TableSortLabel
      active={sortActive}
      direction={
        sortActive
          ? (currentSort.sortDirection.toLowerCase() as 'asc' | 'desc')
          : undefined
      }
      hideSortIcon={false}
      onClick={() => {
        handleChangeSort();
        if (!isNil(onClick)) {
          onClick();
        }
      }}
    >
      {label}
    </TableSortLabel>
  );
};

export const getReviewModalLabel = (tab: InvoiceOrderTabs) => {
  const reviewModalLabel = 'Review';
  switch (tab) {
    case InvoiceOrderTabs.Unfinalized:
      return 'Review Unfinalized';
    case InvoiceOrderTabs.Cancelled:
      return 'Review Cancelled';
    case InvoiceOrderTabs.Finalized:
      return 'Review Finalized';
    case InvoiceOrderTabs.FinalizedNoCharge:
      return 'Review Finalized - No Charge';
    case InvoiceOrderTabs.BillingIssue:
      return 'Review Has Issue';
    default:
      return reviewModalLabel;
  }
};

export const isInvoiceOrderTabReviewType = (
  tab: InvoiceOrderTabs,
): tab is InvoiceOrderReviewType =>
  tab === InvoiceOrderTabs.Unfinalized ||
  tab === InvoiceOrderTabs.Finalized ||
  tab === InvoiceOrderTabs.FinalizedNoCharge ||
  tab === InvoiceOrderTabs.BillingIssue;

const CONSOLIDATABLE_STOP_TYPES: StopType[] = [
  StopType.Pickup,
  StopType.Delivery,
];
export const isConsolidatableStopType = (
  stopType: StopType | null | undefined,
) => !isNil(stopType) && CONSOLIDATABLE_STOP_TYPES.includes(stopType);

export const getShipmentTypeCopy = (shipment: {
  shipmentType: string;
  legs: { endStop?: { stopType?: StopType | null } }[];
}) => {
  if (shipment.shipmentType === ShipmentType.Regular) {
    return sentenceCase(shipment.legs[0]?.endStop?.stopType ?? 'Shipment');
  }
  return sentenceCase(shipment.shipmentType);
};
