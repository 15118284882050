import { SortModelItem } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { invert, isEmpty, isNil, mapValues, sortBy } from 'lodash';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import {
  isMultiselectCustomerOption,
  isPrefixOption,
  PrefixOption,
} from '../../../common/components/customer-filter-button';
import {
  DateOption,
  DatePickerFilterType,
} from '../../../common/components/date-dropdown-picker';
import { Option } from '../../../common/types';
import {
  DateFilterV2,
  FilterOperator,
  FindOrdersFiltersInput,
  FormattedOrderFragment,
  GetOrderTableFieldValuesQueryVariables,
  NullsOrder,
  OrderSortDirection,
  OrderSortV2,
  OrderSource,
  OrderStatus,
  OrderStatusFilterType,
  OrderTableField,
  ShipmentStatus,
  ShipmentStatusFilterType,
  SortOrder,
  StandardStopType,
  StopType,
  StringFilter,
  StringFilterType,
  StringFilterV2,
  StringsFilter,
} from '../../../generated/graphql';
import {
  booleanFilterOptionToBooleanFilter,
  convertDateFilterOptionToDateFilters,
  convertDateFilterOptionToDateFilterV2,
  convertStatusFilterOptionToQueryFilters,
  DateFilterOption,
  FilterModel,
  MultiSelectFilterValue,
  OrderFilterField,
  OrderFilterFieldV2,
  OrderStatusFilterOption,
} from '../../orders/components/enums/order-filters';
import { ColumnSortState, getSortOrder } from '../utils';
import {
  DefaultFilterTabsConfigs,
  OrdersTableWithFiltersState,
  OrderTableFilterModel,
} from './types';

function buildOrderSortV2({
  fieldName,
  direction,
  nulls,
}: {
  fieldName: OrderTableField;
  direction: SortOrder;
  nulls: NullsOrder;
}): OrderSortV2 | undefined {
  const baseSortInput = { direction, nulls };
  switch (fieldName) {
    case OrderTableField.Name:
      return {
        fieldName: OrderTableField.Name,
        nameSortInput: baseSortInput,
      };
    case OrderTableField.TotalSkids:
      return {
        fieldName: OrderTableField.TotalSkids,
        totalSkidsSortInput: baseSortInput,
      };
    case OrderTableField.BillOfLadingNumber:
      return {
        fieldName: OrderTableField.BillOfLadingNumber,
        shipperBillOfLadingNumberSortInput: baseSortInput,
      };
    case OrderTableField.CompletedAt:
      return {
        fieldName: OrderTableField.CompletedAt,
        completedAtSortInput: baseSortInput,
      };
    case OrderTableField.PrimaryServiceDate:
    case OrderTableField.OrderServiceDate:
      return {
        fieldName: OrderTableField.OrderServiceDate,
        orderServiceDateSortInput: baseSortInput,
      };
    case OrderTableField.InboundServiceDate:
      return {
        fieldName: OrderTableField.InboundServiceDate,
        inboundServiceDateSortInput: baseSortInput,
      };
    case OrderTableField.OutboundServiceDate:
      return {
        fieldName: OrderTableField.OutboundServiceDate,
        outboundServiceDateSortInput: baseSortInput,
      };
    case OrderTableField.Mawb:
      return {
        fieldName: OrderTableField.Mawb,
        mawbSortInput: baseSortInput,
      };
    case OrderTableField.CustomerName:
      return {
        fieldName: OrderTableField.CustomerName,
        customerNameSortInput: baseSortInput,
      };
    case OrderTableField.SecondaryRefNumber:
      return {
        fieldName: OrderTableField.SecondaryRefNumber,
        secondaryRefNumberSortInput: baseSortInput,
      };
    case OrderTableField.InboundAddress:
      return {
        fieldName: OrderTableField.InboundAddress,
        inboundAddressSortInput: baseSortInput,
      };
    case OrderTableField.OutboundAddress:
      return {
        fieldName: OrderTableField.OutboundAddress,
        outboundAddressSortInput: baseSortInput,
      };
    case OrderTableField.OriginTerminal:
      return {
        fieldName: OrderTableField.OriginTerminal,
        originTerminalSortInput: baseSortInput,
      };
    case OrderTableField.DestinationTerminal:
      return {
        fieldName: OrderTableField.DestinationTerminal,
        destinationTerminalSortInput: baseSortInput,
      };
    case OrderTableField.InboundCityName:
      return {
        fieldName: OrderTableField.InboundCityName,
        inboundCityNameSortInput: baseSortInput,
      };
    case OrderTableField.OutboundCityName:
      return {
        fieldName: OrderTableField.OutboundCityName,
        outboundCityNameSortInput: baseSortInput,
      };
    case OrderTableField.InboundDriverName:
      return {
        fieldName: OrderTableField.InboundDriverName,
        inboundDriverNameSortInput: baseSortInput,
      };
    case OrderTableField.OutboundDriverName:
      return {
        fieldName: OrderTableField.OutboundDriverName,
        outboundDriverNameSortInput: baseSortInput,
      };
    case OrderTableField.Source:
      return {
        fieldName: OrderTableField.Source,
        orderSourceSortInput: baseSortInput,
      };
    case OrderTableField.InboundCompletedDate:
      return {
        fieldName: OrderTableField.InboundCompletedDate,
        inboundCompletedDateSortInput: baseSortInput,
      };
    case OrderTableField.OutboundCompletedDate:
      return {
        fieldName: OrderTableField.OutboundCompletedDate,
        outboundCompletedDateSortInput: baseSortInput,
      };
    case OrderTableField.InBond:
      return {
        fieldName: OrderTableField.InBond,
        inBondSortInput: baseSortInput,
      };
    case OrderTableField.OutboundMethod:
      return {
        fieldName: OrderTableField.OutboundMethod,
        outboundMethodSortInput: baseSortInput,
      };
    case OrderTableField.InboundRouteName:
      return {
        fieldName: OrderTableField.InboundRouteName,
        inboundRouteNameSortInput: baseSortInput,
      };
    case OrderTableField.OutboundRouteName:
      return {
        fieldName: OrderTableField.OutboundRouteName,
        outboundRouteNameSortInput: baseSortInput,
      };
    case OrderTableField.PieceCount:
      return {
        fieldName: OrderTableField.PieceCount,
        pieceCountSortInput: baseSortInput,
      };
    case OrderTableField.Weight:
      return {
        fieldName: OrderTableField.Weight,
        totalPackageWeightSortInput: baseSortInput,
      };
    case OrderTableField.DimWeight:
      return {
        fieldName: OrderTableField.DimWeight,
        dimWeightSortInput: baseSortInput,
      };
    case OrderTableField.ReceivedAt:
      return {
        fieldName: OrderTableField.ReceivedAt,
        dateMarkedOnHandSortInput: baseSortInput,
      };
    case OrderTableField.DestinationDetails:
      return {
        fieldName: OrderTableField.DestinationDetails,
        destinationDetailsSortInput: baseSortInput,
      };
    case OrderTableField.IsReweighed:
      return {
        fieldName: OrderTableField.IsReweighed,
        isReweighedSortInput: baseSortInput,
      };
    case OrderTableField.InboundZipcode:
      return {
        fieldName: OrderTableField.InboundZipcode,
        inboundZipcodeSortInput: baseSortInput,
      };
    case OrderTableField.OutboundZipcode:
      return {
        fieldName: OrderTableField.OutboundZipcode,
        outboundZipcodeSortInput: baseSortInput,
      };
    case OrderTableField.InboundAddressName:
      return {
        fieldName: OrderTableField.InboundAddressName,
        inboundAddressNameSortInput: baseSortInput,
      };
    case OrderTableField.OutboundAddressName:
      return {
        fieldName: OrderTableField.OutboundAddressName,
        outboundAddressNameSortInput: baseSortInput,
      };
    case OrderTableField.InboundRouteDate:
      return {
        fieldName: OrderTableField.InboundRouteDate,
        inboundRouteDateSortInput: baseSortInput,
      };
    case OrderTableField.OutboundRouteDate:
      return {
        fieldName: OrderTableField.OutboundRouteDate,
        outboundRouteDateSortInput: baseSortInput,
      };
    case OrderTableField.OrderConsignee:
      return {
        fieldName: OrderTableField.OrderConsignee,
        orderConsigneeSortInput: baseSortInput,
      };
    // These fields aren't sortable
    case OrderTableField.DriverName:
    case OrderTableField.DriverSettlement:
    case OrderTableField.OrderType:
    case OrderTableField.Status:
    case OrderTableField.BillingReviewStatus:
    case OrderTableField.ShipmentType:
    case OrderTableField.NextAddress:
    case OrderTableField.PrimaryAddress:
    case OrderTableField.CityName:
    case OrderTableField.NextConsignee:
    case OrderTableField.PrimaryConsignee:
    case OrderTableField.Zipcode:
    case OrderTableField.ContactInformation:
    case OrderTableField.BusinessDivision:
    case OrderTableField.NextDeadline:
    case OrderTableField.RoutingLocation:
    case OrderTableField.Tags:
    case OrderTableField.Charges:
    case OrderTableField.RouteName:
    case OrderTableField.PodName:
    case OrderTableField.PrimaryDeadline:
    case OrderTableField.Special:
    case OrderTableField.PrimaryServiceLevel:
    case OrderTableField.PrimaryAppointment:
    case OrderTableField.NextAppointment:
    case OrderTableField.InboundMethod:
    case OrderTableField.ItTeNumber:
    case OrderTableField.Notes:
    case OrderTableField.HasDriverPod:
    case OrderTableField.OnHold:
    case OrderTableField.Attempted:
    case OrderTableField.PaperworkComplete:
    case OrderTableField.InboundPaperwork:
    case OrderTableField.OutboundPaperwork:
    case OrderTableField.Photos:
    case OrderTableField.PrimaryAddressType:
    case OrderTableField.Dims:
    case OrderTableField.ContactName:
    case OrderTableField.PrimaryContactName:
    case OrderTableField.TotalChargesWithItemized:
    case OrderTableField.ExternalNotes:
    case OrderTableField.StopTypes:
    case OrderTableField.InboundStopType:
    case OrderTableField.OutboundStopType:
    case OrderTableField.IsLinehaul:
    case OrderTableField.InboundRouting:
    case OrderTableField.OutboundRouting:
    case OrderTableField.UnNumber:
    case OrderTableField.InboundAppointment:
    case OrderTableField.OutboundAppointment:
    case OrderTableField.InboundAppointmentConfirmed:
    case OrderTableField.OutboundAppointmentConfirmed:
    case OrderTableField.InboundAddressType:
    case OrderTableField.OutboundAddressType:
    case OrderTableField.InboundContactName:
    case OrderTableField.OutboundContactName:
    case OrderTableField.HoldReason:
    case OrderTableField.OsdReason:
    case OrderTableField.InboundCharges:
    case OrderTableField.OutboundCharges:
    case OrderTableField.LineHaulCharges:
    case OrderTableField.AdditionalCharges:
      return undefined;
    default:
      return exhaustive(fieldName);
  }
}

/**
 * Build customer, terminal, and date filters from the selected options
 * shown in the UI, for use as part of FetchOrdersVariables.
 *
 * Don't call this function directly, @see getFetchOrdersFilters
 */
export const getFetchOrdersFiltersFromOptions = ({
  terminalOptions,
  customerOptions,
  originTerminalOption,
  destinationTerminalOption,
  dateOption,
  ffEnableNewTableFunctions,
}: {
  terminalOptions: Option[] | null | undefined;
  customerOptions: Option[] | PrefixOption | null | undefined;
  originTerminalOption: Option | null | undefined;
  destinationTerminalOption: Option | null | undefined;
  dateOption: DateOption | null | undefined;
  ffEnableNewTableFunctions: boolean;
}): FindOrdersFiltersInput => {
  let billingPartyContactUuidsFilter: StringsFilter | undefined;
  let billingPartyContactDisplayNameFilter: StringFilter | undefined;
  let customerNameFilter: StringFilterV2 | undefined;
  if (isMultiselectCustomerOption(customerOptions)) {
    billingPartyContactUuidsFilter = !isNil(customerOptions)
      ? {
          fieldLevelFilterOperator: FilterOperator.Or,
          values: customerOptions.map((option) => option.value),
        }
      : undefined;
  } else if (
    isPrefixOption(customerOptions) &&
    customerOptions.prefix.trim() !== ''
  ) {
    if (ffEnableNewTableFunctions) {
      customerNameFilter = {
        startsWithIgnoreCase: customerOptions.prefix,
      };
    } else {
      billingPartyContactDisplayNameFilter = {
        filterType: StringFilterType.StartsWith,
        filterOperator: FilterOperator.And,
        value: customerOptions.prefix,
      };
    }
  }

  const terminalUuidsFilter: StringsFilter | undefined = !isNil(terminalOptions)
    ? {
        fieldLevelFilterOperator: FilterOperator.Or,
        values: terminalOptions.map((option) => option.value),
      }
    : undefined;

  let originTerminalUuidFilter: StringFilter | undefined;
  let originTerminalUuidFilterV2: StringFilterV2 | undefined;
  const originTerminalFilterValue =
    originTerminalOption?.value ??
    (isNil(originTerminalOption) ? originTerminalOption : undefined);
  if (!isNil(originTerminalFilterValue)) {
    if (ffEnableNewTableFunctions) {
      originTerminalUuidFilterV2 = {
        eq: originTerminalFilterValue,
      };
    } else {
      originTerminalUuidFilter = {
        filterType: StringFilterType.Equals,
        filterOperator: FilterOperator.And,
        value: originTerminalFilterValue,
      };
    }
  }

  let destinationTerminalUuidFilter: StringFilter | undefined;
  let destinationTerminalUuidFilterV2: StringFilterV2 | undefined;
  const destinationTerminalFilterValue =
    destinationTerminalOption?.value ??
    (isNil(destinationTerminalOption) ? destinationTerminalOption : undefined);
  if (!isNil(destinationTerminalFilterValue)) {
    if (ffEnableNewTableFunctions) {
      destinationTerminalUuidFilterV2 = {
        eq: destinationTerminalFilterValue,
      };
    } else {
      destinationTerminalUuidFilter = {
        filterType: StringFilterType.Equals,
        filterOperator: FilterOperator.And,
        value: destinationTerminalFilterValue,
      };
    }
  }

  const serviceDateFilter: DateFilterV2 | undefined =
    !isNil(dateOption) &&
    dateOption.filterType !== DatePickerFilterType.AllSelect
      ? {
          gte: dateOption.startDate,
          lte: dateOption.endDate,
        }
      : undefined;

  return {
    ...(billingPartyContactUuidsFilter && { billingPartyContactUuidsFilter }),
    ...(billingPartyContactDisplayNameFilter && {
      billingPartyContactDisplayNameFilter,
    }),
    ...(customerNameFilter && { customerNameFilter }),
    ...(terminalUuidsFilter && { terminalUuidsFilter }),
    ...(originTerminalUuidFilter && { originTerminalUuidFilter }),
    ...(originTerminalUuidFilterV2 && { originTerminalUuidFilterV2 }),
    ...(destinationTerminalUuidFilter && { destinationTerminalUuidFilter }),
    ...(destinationTerminalUuidFilterV2 && { destinationTerminalUuidFilterV2 }),
    ...(serviceDateFilter && { serviceDateFilter }),
  };
};

const addFilterFromFilterModelToFiltersInput = (
  currentResult: FindOrdersFiltersInput,
  filterField: OrderFilterField,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterModelValue: any,
): FindOrdersFiltersInput => {
  switch (filterField) {
    case OrderFilterField.ACTIVE_TERMINAL: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        activeTerminalUuidFilter: {
          filterOperator: filterModelValue.filterOperator,
          filterType: StringFilterType.Equals,
          value: filterModelValue.value,
        },
      };
    }
    case OrderFilterField.ADDRESS_TYPE: {
      return {
        ...currentResult,
        standardStopType:
          isEmpty(filterModelValue.value as StandardStopType) ||
          !Object.values(StandardStopType).includes(filterModelValue.value)
            ? undefined
            : (filterModelValue.value as StandardStopType),
      };
    }
    case OrderFilterField.APPOINTMENT_REQUIRED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        appointmentRequired: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.APPOINTMENT_SCHEDULED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        hasAppointmentScheduled: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.ASSIGNED_TO_A_ROUTE: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        hasRoute: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.BUSINESS_DIVISION: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        businessDivisionUuidFilter: {
          filterType: StringFilterType.Equals,
          filterOperator: filterModelValue.filterOperator,
          value,
        },
      };
    }
    case OrderFilterField.CHARGES_FINALIZED: {
      return {
        ...currentResult,
        shipmentStatusFilters: [
          {
            filterType: booleanFilterOptionToBooleanFilter(
              filterModelValue.value,
            )
              ? ShipmentStatusFilterType.Equals
              : ShipmentStatusFilterType.NotEquals,
            status: ShipmentStatus.Finalized,
          },
        ],
      };
    }
    case OrderFilterField.CITY: {
      return {
        ...currentResult,
        addressCityFilter: {
          filterType: StringFilterType.Contains,
          filterOperator: filterModelValue.filterOperator,
          value: filterModelValue.value as string,
        },
      };
    }
    case OrderFilterField.HAS_CONTACT_INFORMATION: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        hasContactInformation: {
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
          filterOperator: filterModelValue.filterOperator,
        },
      };
    }
    case OrderFilterField.HAWB: {
      return { ...currentResult, hawbFilter: filterModelValue };
    }
    case OrderFilterField.MAWB: {
      return { ...currentResult, mawbFilter: filterModelValue };
    }
    case OrderFilterField.DRIVER_NAME: {
      return { ...currentResult, driverNameFilter: filterModelValue };
    }
    case OrderFilterField.INBOUND_DRIVER_NAME: {
      return { ...currentResult, inboundDriverNameFilter: filterModelValue };
    }
    case OrderFilterField.OUTBOUND_DRIVER_NAME: {
      return { ...currentResult, outboundDriverNameFilter: filterModelValue };
    }
    case OrderFilterField.INBOUND_CITY: {
      return { ...currentResult, inboundCityFilter: filterModelValue };
    }
    case OrderFilterField.TOTAL_WEIGHT: {
      return { ...currentResult, totalWeightFilter: filterModelValue };
    }
    case OrderFilterField.TOTAL_PIECES: {
      return { ...currentResult, totalPiecesFilter: filterModelValue };
    }
    case OrderFilterField.UN_NUMBER: {
      return { ...currentResult, unNumberFilter: filterModelValue };
    }
    case OrderFilterField.IT_NUMBER: {
      return { ...currentResult, itNumberFilter: filterModelValue };
    }
    case OrderFilterField.ORDER_NAME: {
      return { ...currentResult, orderNameFilterV2: filterModelValue };
    }
    // TODO(ashwin): change API to allow multiple contact UUIDs
    case OrderFilterField.CUSTOMER: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        billingPartyContactUuidFilter: {
          filterType: StringFilterType.Equals,
          filterOperator: filterModelValue.filterOperator,
          value,
        },
      };
    }
    case OrderFilterField.TERMINAL: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        terminalUuidFilter: {
          filterType: StringFilterType.Equals,
          filterOperator: filterModelValue.filterOperator,
          value,
        },
      };
    }
    case OrderFilterField.DRIVER: {
      return {
        ...currentResult,
        driverUuidsFilter: !isNil(filterModelValue)
          ? {
              fieldLevelFilterOperator: filterModelValue.filterOperator,
              values: filterModelValue.values.map(
                (filterValue: MultiSelectFilterValue) =>
                  filterValue.actualValue,
              ),
            }
          : null,
      };
    }
    case OrderFilterField.INBOUND_DRIVER:
      return {
        ...currentResult,
        inboundDriverUuidsFilter: !isNil(filterModelValue)
          ? {
              fieldLevelFilterOperator:
                filterModelValue.fieldLevelFilterOperator,
              values: filterModelValue.values.map(
                (filterValue: MultiSelectFilterValue) =>
                  filterValue.actualValue,
              ),
            }
          : null,
      };
    case OrderFilterField.OUTBOUND_DRIVER:
      return {
        ...currentResult,
        outboundDriverUuidsFilter: !isNil(filterModelValue)
          ? {
              fieldLevelFilterOperator:
                filterModelValue.fieldLevelFilterOperator,
              values: filterModelValue.values.map(
                (filterValue: MultiSelectFilterValue) =>
                  filterValue.actualValue,
              ),
            }
          : null,
      };
    case OrderFilterField.ON_HAND: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        onHand: {
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
          filterOperator: filterModelValue.filterOperator,
        },
      };
    }
    case OrderFilterField.ON_HOLD: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        statusFilters: [
          {
            filterType: booleanFilterOptionToBooleanFilter(value)
              ? OrderStatusFilterType.Equals
              : OrderStatusFilterType.NotEquals,
            status: OrderStatus.OnHold,
          },
        ],
      };
    }
    case OrderFilterField.ON_INVOICE: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        onInvoice: {
          value: booleanFilterOptionToBooleanFilter(value),
          filterOperator: filterModelValue.filterOperator,
        },
      };
    }
    case OrderFilterField.ORDER_SOURCE: {
      return {
        ...currentResult,
        orderSourceFilter:
          isEmpty(filterModelValue.value as OrderSource) ||
          !Object.values(OrderSource).includes(filterModelValue.value)
            ? undefined
            : {
                // TODO: Implement remaining operators once filter construction functions are written.
                eq: filterModelValue.value as OrderSource,
              },
      };
    }
    case OrderFilterField.ORDER_STATUS: {
      return {
        ...currentResult,
        ...convertStatusFilterOptionToQueryFilters(
          // `value` if it's a legacy filter, `eq` if it's a new filter
          (filterModelValue.value ??
            filterModelValue.eq) as OrderStatusFilterOption,
        ),
      };
    }
    case OrderFilterField.PAPERWORK_COMPLETED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        paperworkComplete: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(value),
        },
      };
    }
    case OrderFilterField.IS_FINALIZED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isFinalized: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.IS_CANCELLED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isCancelled: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.IS_REFUSED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isRefused: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.IS_OSD: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isOsd: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.IS_PICKED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isPicked: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.IS_SPECIAL: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isSpecial: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.INBOUND_NOT_ARRIVED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        inboundNotArrived: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.REQUIRES_ROUTING: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        requiresRouting: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.REQUIRES_RECOVERY: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        requiresRecovery: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.INBOUND_SERVICE_DATE: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        // TODO: Use the new filter construction primitives once they are introduced as part of https://linear.app/trypallet/issue/PLT-1403/implement-filters-for-all-data-types-frontend
        inboundServiceDateFilter: convertDateFilterOptionToDateFilterV2({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.SERVICE_DATE: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        // TODO: Use the new filter construction primitives once they are introduced as part of https://linear.app/trypallet/issue/PLT-1403/implement-filters-for-all-data-types-frontend
        serviceDateFilter: convertDateFilterOptionToDateFilterV2({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.SERVICE_LEVEL: {
      return {
        ...currentResult,
        serviceUuids: isEmpty(filterModelValue.value)
          ? []
          : [filterModelValue.value],
      };
    }
    case OrderFilterField.LINE_HAUL_LANE: {
      return {
        ...currentResult,
        lineHaulLaneUuids: isEmpty(filterModelValue.value)
          ? []
          : [filterModelValue.value],
      };
    }
    case OrderFilterField.TRANSFER_PENDING: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        transferPending: {
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
          filterOperator: filterModelValue.filterOperator,
        },
      };
    }
    case OrderFilterField.ORDER_TYPE: {
      return {
        ...currentResult,
        stopTypesFilter: {
          fieldLevelFilterOperator: filterModelValue.filterOperator,
          stopTypes: filterModelValue.values.map(
            (filterValue: MultiSelectFilterValue) => filterValue.actualValue,
          ),
        },
      };
    }
    case OrderFilterField.INBOUND_METHOD: {
      return {
        ...currentResult,
        inboundMethodFilterV2: {
          stopType: filterModelValue.value as StopType,
          filterOperator: filterModelValue.filterOperator,
        },
        // To be deprecated
        inboundMethodFilter: filterModelValue.value as StopType,
      };
    }
    case OrderFilterField.OUTBOUND_METHOD: {
      return {
        ...currentResult,
        outboundMethodFilterV2: {
          stopType: filterModelValue.value as StopType,
          filterOperator: filterModelValue.filterOperator,
        },
        // To be deprecated
        outboundMethodFilter: filterModelValue.value as StopType,
      };
    }
    case OrderFilterField.ZIP_CODE: {
      return {
        ...currentResult,
        ...currentResult,
        addressZipFilter: {
          filterType: StringFilterType.Contains,
          filterOperator: filterModelValue.filterOperator,
          value: filterModelValue.value as string,
        },
      };
    }
    case OrderFilterField.DATE_CREATED: {
      return {
        ...currentResult,
        createdAtDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.DATE_ATTEMPTED: {
      return {
        ...currentResult,
        attemptedAtDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.DATE_COMPLETED: {
      return {
        ...currentResult,
        completionDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.DATE_RECEIVED: {
      return {
        ...currentResult,
        receivedDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.DATE_SCHEDULED: {
      return {
        ...currentResult,
        scheduledAtDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.DEADLINE_DATE: {
      return {
        ...currentResult,
        deadlineDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.EXPECTED_INBOUND_ARRIVAL_DATE: {
      return {
        ...currentResult,
        expectedInboundArrivalDateFilters: convertDateFilterOptionToDateFilters(
          {
            dateFilterOption: filterModelValue.value
              .fixedDateOption as DateFilterOption,
            startDate: filterModelValue.startDate as Date,
            endDate: filterModelValue.endDate as Date,
          },
        ),
      };
    }
    case OrderFilterField.INVOICE_DATE: {
      return {
        ...currentResult,
        invoiceDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.IS_COLLECT_ON_DELIVERY: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isCollectOnDelivery: booleanFilterOptionToBooleanFilter(value),
      };
    }
    case OrderFilterField.IS_HAZMAT: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isHazmat: {
          filterOperator: filterModelValue.filterOperator,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case OrderFilterField.IS_IN_BOND: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        isInBond: {
          value: booleanFilterOptionToBooleanFilter(value),
          filterOperator: filterModelValue.filterOperator,
        },
      };
    }
    case OrderFilterField.PRIMARY_APPOINTMENT_DATE: {
      return {
        ...currentResult,
        primaryAppointmentDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    }
    case OrderFilterField.TAG: {
      return {
        ...currentResult,
        tagUuidsFilter: !isNil(filterModelValue)
          ? {
              fieldLevelFilterOperator: filterModelValue.filterOperator,
              values: filterModelValue.values.map(
                (filterValue: MultiSelectFilterValue) =>
                  filterValue.actualValue,
              ),
            }
          : null,
      };
    }
    case OrderFilterField.OUTBOUND_CITY: {
      return {
        ...currentResult,
        endStopFilter: {
          filterType: StringFilterType.Contains,
          filterOperator: filterModelValue.filterOperator,
          value: filterModelValue.value as string,
        },
      };
    }
    case OrderFilterField.SECONDARY_REFERENCE_NUMBER: {
      return {
        ...currentResult,
        secondaryReferenceNumberFilter: filterModelValue,
      };
    }
    case OrderFilterField.OUTBOUND_TERMINAL: {
      return { ...currentResult, outboundTerminalFilter: filterModelValue };
    }
    case OrderFilterField.CUSTOMER_NAME: {
      return { ...currentResult, customerNameFilter: filterModelValue };
    }
    case OrderFilterField.PROOF_OF_DELIVERY_NAME: {
      return { ...currentResult, proofOfDeliveryNameFilter: filterModelValue };
    }
    case OrderFilterField.COMPLETED_AT: {
      return { ...currentResult, completedAtDateFilter: filterModelValue };
    }
    case OrderFilterField.ORDER_SERVICE_DATE: {
      return { ...currentResult, orderServiceDateFilter: filterModelValue };
    }
    case OrderFilterField.INBOUND_COMPLETED_DATE: {
      return { ...currentResult, inboundCompletedDateFilter: filterModelValue };
    }
    case OrderFilterField.OUTBOUND_COMPLETED_DATE: {
      return {
        ...currentResult,
        outboundCompletedDateFilter: filterModelValue,
      };
    }
    case OrderFilterField.DATE_MARKED_ON_HAND: {
      return { ...currentResult, markedOnHandDateFilter: filterModelValue };
    }
    case OrderFilterField.INBOUND_ADDRESS: {
      return { ...currentResult, inboundAddressFilter: filterModelValue };
    }
    case OrderFilterField.INBOUND_TERMINAL: {
      return { ...currentResult, inboundTerminalFilter: filterModelValue };
    }
    case OrderFilterField.OUTBOUND_ADDRESS: {
      return { ...currentResult, outboundAddressFilter: filterModelValue };
    }
    case OrderFilterField.TAGS:
    case OrderFilterField.ROUTING_LOCATION:
    case OrderFilterField.INBOUND_ROUTE_NAME:
    case OrderFilterField.OUTBOUND_ROUTE_NAME:
    case OrderFilterField.OUTBOUND_SERVICE_DATE:
    case OrderFilterField.INBOUND_APPOINTMENT_REQUIRED:
    case OrderFilterField.OUTBOUND_APPOINTMENT_REQUIRED:
    case OrderFilterField.DESTINATION_DETAILS:
    case OrderFilterField.HOLD_REASON:
    case OrderFilterField.INBOUND_APPOINTMENT_CONFIRMED:
    case OrderFilterField.OUTBOUND_APPOINTMENT_CONFIRMED:
    case OrderFilterField.INBOUND_STOP_TYPE:
    case OrderFilterField.OUTBOUND_STOP_TYPE:
    case OrderFilterField.INBOUND_COMPLETED:
    case OrderFilterField.OUTBOUND_COMPLETED:
    case OrderFilterField.INBOUND_ZIPCODE:
    case OrderFilterField.OUTBOUND_ZIPCODE:
    case OrderFilterField.DIM_WEIGHT:
    case OrderFilterField.NOTES:
    case OrderFilterField.EXTERNAL_NOTES:
    case OrderFilterField.TOTAL_SKIDS:
    case OrderFilterField.OSD_REASON:
    case OrderFilterField.TOTAL_CHARGES:
    case OrderFilterField.INBOUND_CONTACT_NAME:
    case OrderFilterField.OUTBOUND_CONTACT_NAME:
    case OrderFilterField.IS_LINE_HAUL:
    case OrderFilterField.IS_REWEIGHED:
    case OrderFilterField.INBOUND_APPOINTMENT_DATE:
    case OrderFilterField.OUTBOUND_APPOINTMENT_DATE:
    case OrderFilterField.RECEIVED_AT_ORIGIN:
    case OrderFilterField.INBOUND_ADDRESS_TYPE:
    case OrderFilterField.OUTBOUND_ADDRESS_TYPE:
    case OrderFilterField.CAN_DISPATCH:
    case OrderFilterField.INBOUND_ROUTING:
    case OrderFilterField.OUTBOUND_ROUTING:
    case OrderFilterField.PROOF_OF_DELIVERY_SIGNEE:
    case OrderFilterField.INBOUND_ROUTE_DATE:
    case OrderFilterField.OUTBOUND_ROUTE_DATE:
      return currentResult;
    default:
      return exhaustive(filterField);
  }
};

const addFilterFromFilterModelToFiltersInputV2 = (
  currentResult: FindOrdersFiltersInput,
  filterField: OrderFilterFieldV2,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterModelValue: any,
): FindOrdersFiltersInput => {
  switch (filterField) {
    case OrderFilterFieldV2.ORDER_NAME: {
      return {
        ...currentResult,
        orderNameFilterV2: filterModelValue,
      };
    }
    case OrderFilterFieldV2.HAWB: {
      return {
        ...currentResult,
        hawbFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.MAWB: {
      return {
        ...currentResult,
        mawbFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_ADDRESS: {
      return {
        ...currentResult,
        inboundAddressFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_DRIVER_NAME: {
      return {
        ...currentResult,
        inboundDriverNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_TERMINAL: {
      return {
        ...currentResult,
        inboundTerminalFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ORDER_SERVICE_DATE: {
      return {
        ...currentResult,
        orderServiceDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_ADDRESS: {
      return {
        ...currentResult,
        outboundAddressFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_DRIVER_NAME: {
      return {
        ...currentResult,
        outboundDriverNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_TERMINAL: {
      return {
        ...currentResult,
        outboundTerminalFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_SERVICE_DATE: {
      return {
        ...currentResult,
        inboundServiceDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_SERVICE_DATE: {
      return {
        ...currentResult,
        outboundServiceDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_ROUTE_NAME: {
      return {
        ...currentResult,
        inboundRouteNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_ROUTE_NAME: {
      return {
        ...currentResult,
        outboundRouteNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_APPOINTMENT_REQUIRED: {
      return {
        ...currentResult,
        inboundAppointmentRequiredFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_REQUIRED: {
      return {
        ...currentResult,
        outboundAppointmentRequiredFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.DESTINATION_DETAILS: {
      return {
        ...currentResult,
        outboundDestinationAirportFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.HOLD_REASON: {
      return {
        ...currentResult,
        holdReasonFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OSD_REASON: {
      return {
        ...currentResult,
        osdReasonFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_SPECIAL: {
      return {
        ...currentResult,
        isSpecialFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.DATE_RECEIVED: {
      return {
        ...currentResult,
        markedOnHandDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_APPOINTMENT_CONFIRMED: {
      return {
        ...currentResult,
        inboundAppointmentConfirmedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_CONFIRMED: {
      return {
        ...currentResult,
        outboundAppointmentConfirmedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ON_HAND: {
      return {
        ...currentResult,
        onHandFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ON_HOLD: {
      return {
        ...currentResult,
        onHoldFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_STOP_TYPE: {
      return {
        ...currentResult,
        inboundStopTypeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_STOP_TYPE: {
      return {
        ...currentResult,
        outboundStopTypeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ROUTING_LOCATION: {
      return {
        ...currentResult,
        routingLocationFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.SERVICE_LEVEL: {
      return {
        ...currentResult,
        serviceLevelFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ACTIVE_TERMINAL: {
      return {
        ...currentResult,
        activeTerminalFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_COMPLETED_DATE: {
      return {
        ...currentResult,
        inboundCompletedDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_COMPLETED_DATE: {
      return {
        ...currentResult,
        outboundCompletedDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_COMPLETED: {
      return {
        ...currentResult,
        inboundCompletedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_COMPLETED: {
      return {
        ...currentResult,
        outboundCompletedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.TAGS: {
      return {
        ...currentResult,
        tagsFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_REFUSED: {
      return {
        ...currentResult,
        isRefusedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_REWEIGHED: {
      return {
        ...currentResult,
        isReweighedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_CANCELLED: {
      return {
        ...currentResult,
        isCancelledFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ORDER_SOURCE: {
      return {
        ...currentResult,
        orderSourceFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_ZIPCODE: {
      return {
        ...currentResult,
        inboundZipcodeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_ZIPCODE: {
      return {
        ...currentResult,
        outboundZipcodeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.CUSTOMER_NAME: {
      return {
        ...currentResult,
        customerNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.TOTAL_WEIGHT: {
      return {
        ...currentResult,
        totalWeightFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.TOTAL_PIECES: {
      return {
        ...currentResult,
        totalPiecesFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.DIM_WEIGHT: {
      return {
        ...currentResult,
        dimWeightFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.EXTERNAL_NOTES: {
      return {
        ...currentResult,
        externalNotesFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.SECONDARY_REFERENCE_NUMBER: {
      return {
        ...currentResult,
        secondaryReferenceNumberFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.TOTAL_SKIDS: {
      return {
        ...currentResult,
        totalSkidsFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.UN_NUMBER: {
      return {
        ...currentResult,
        unNumberFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.TOTAL_CHARGES: {
      return {
        ...currentResult,
        totalAmountDollarsFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_CONTACT_NAME: {
      return {
        ...currentResult,
        inboundContactNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_CONTACT_NAME: {
      return {
        ...currentResult,
        outboundContactNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_LINE_HAUL: {
      return {
        ...currentResult,
        isLineHaulFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_IN_BOND: {
      return {
        ...currentResult,
        isInBondFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_APPOINTMENT_DATE: {
      return {
        ...currentResult,
        inboundAppointmentDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_DATE: {
      return {
        ...currentResult,
        outboundAppointmentDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_ADDRESS_TYPE: {
      return {
        ...currentResult,
        inboundAddressTypeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_ADDRESS_TYPE: {
      return {
        ...currentResult,
        outboundAddressTypeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.RECEIVED_AT_ORIGIN: {
      return {
        ...currentResult,
        receivedAtOriginFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ASSIGNED_TO_A_ROUTE: {
      return {
        ...currentResult,
        hasRouteFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_FINALIZED: {
      return {
        ...currentResult,
        isFinalizedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ON_INVOICE: {
      return {
        ...currentResult,
        onInvoiceFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_PICKED: {
      return {
        ...currentResult,
        isPickedFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.REQUIRES_RECOVERY: {
      return {
        ...currentResult,
        requiresRecoveryFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.PAPERWORK_COMPLETED: {
      return {
        ...currentResult,
        paperworkCompleteFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.COMPLETED_AT: {
      return {
        ...currentResult,
        completedAtDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.DATE_CREATED: {
      return {
        ...currentResult,
        createdDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INVOICE_DATE: {
      return {
        ...currentResult,
        invoiceDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.DEADLINE_DATE: {
      return {
        ...currentResult,
        deadlineDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.DATE_ATTEMPTED: {
      return {
        ...currentResult,
        attemptedDateFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.CAN_DISPATCH: {
      return {
        ...currentResult,
        canDispatchFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_ROUTING: {
      return {
        ...currentResult,
        inboundRoutingFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_ROUTING: {
      return {
        ...currentResult,
        outboundRoutingFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.PROOF_OF_DELIVERY_SIGNEE: {
      return {
        ...currentResult,
        proofOfDeliverySigneeFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.BUSINESS_DIVISION: {
      return {
        ...currentResult,
        businessDivisionNameFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.IS_HAZMAT: {
      return {
        ...currentResult,
        isHazmatFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.ORDER_STATUS: {
      return {
        ...currentResult,
        orderStatusFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.TRANSFER_PENDING: {
      return {
        ...currentResult,
        transferPendingFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.REQUIRES_ROUTING: {
      return {
        ...currentResult,
        requiresRoutingFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.INBOUND_CITY: {
      return {
        ...currentResult,
        inboundCityFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.OUTBOUND_CITY: {
      return {
        ...currentResult,
        outboundCityFilter: filterModelValue,
      };
    }
    case OrderFilterFieldV2.HAS_CONTACT_INFORMATION:
      return {
        ...currentResult,
        hasContactInformationFilter: filterModelValue,
      };
    case OrderFilterFieldV2.INBOUND_NOT_ARRIVED:
      return {
        ...currentResult,
        inboundNotArrivedFilter: filterModelValue,
      };
    case OrderFilterFieldV2.APPOINTMENT_REQUIRED:
      return {
        ...currentResult,
        appointmentRequiredFilter: filterModelValue,
      };
    case OrderFilterFieldV2.APPOINTMENT_SCHEDULED:
      return {
        ...currentResult,
        hasAppointmentScheduledFilter: filterModelValue,
      };
    case OrderFilterFieldV2.INBOUND_ROUTE_DATE:
      return {
        ...currentResult,
        inboundRouteDateFilter: filterModelValue,
      };
    case OrderFilterFieldV2.OUTBOUND_ROUTE_DATE:
      return {
        ...currentResult,
        outboundRouteDateFilter: filterModelValue,
      };
    case OrderFilterFieldV2.LINE_HAUL_LANE: {
      return {
        ...currentResult,
        lineHaulLaneFilter: filterModelValue,
      };
    }
    default:
      try {
        // TODO(Elliot): this should actually be exhaustive without the try/catch,
        // just not confident that the types are correct yet
        return exhaustive(filterField);
      } catch (e) {
        console.error(
          `Switch on OrderFilterFieldV2 not exhaustive: ${filterField}`,
        );
        return currentResult;
      }
  }
};

type GetFetchOrdersVariables<DefaultOrderTabsType> = {
  currentOrdersTab: DefaultOrderTabsType;
  defaultTabsConfigs: DefaultFilterTabsConfigs<DefaultOrderTabsType>;
  filterModel: FilterModel;
  sortModel?: SortModelItem[];
  uiFilterOptions: Pick<
    OrdersTableWithFiltersState<DefaultOrderTabsType>,
    | 'terminalOptions'
    | 'customerOptions'
    | 'originTerminalOption'
    | 'destinationTerminalOption'
    | 'dateOption'
  >;
  ffEnableNewTableFunctions: boolean | undefined;
};

export const getFetchOrdersFilters = <DefaultOrderTabsType>({
  currentOrdersTab,
  defaultTabsConfigs,
  filterModel,
  uiFilterOptions,
  ffEnableNewTableFunctions,
}: Pick<
  GetFetchOrdersVariables<DefaultOrderTabsType>,
  | 'currentOrdersTab'
  | 'defaultTabsConfigs'
  | 'filterModel'
  | 'uiFilterOptions'
  | 'ffEnableNewTableFunctions'
>): FindOrdersFiltersInput => {
  const filtersFromPresetTabs =
    defaultTabsConfigs.tabs.find((tab) => tab.value === currentOrdersTab)
      ?.filtersToApply ?? {};

  let filtersFromOptions = getFetchOrdersFiltersFromOptions({
    ...uiFilterOptions,
    ffEnableNewTableFunctions: ffEnableNewTableFunctions ?? false,
  });

  // TODO(Elliot): clean up the very loose types here
  const getFiltersFromFilterModel = (model: FilterModel): FilterModel => {
    if (isNil(model) || typeof model !== 'object') {
      return model;
    }

    let tempFilterModel: FilterModel = {};

    Object.entries(model).forEach(([key, value]) => {
      if (
        ffEnableNewTableFunctions === true &&
        (key === 'and' || key === 'or')
      ) {
        if (!Array.isArray(value)) {
          return;
        }
        tempFilterModel[key] = (value as FilterModel[]).map(
          getFiltersFromFilterModel,
        );
      } else if (ffEnableNewTableFunctions === true) {
        tempFilterModel = addFilterFromFilterModelToFiltersInputV2(
          tempFilterModel,
          // Cast is OK because OrderFilterFieldV2 is a subset of OrderFilterField
          key as OrderFilterFieldV2,
          value,
        );
      } else {
        tempFilterModel = addFilterFromFilterModelToFiltersInput(
          tempFilterModel,
          key as OrderFilterField,
          value,
        );
      }
    });

    return tempFilterModel;
  };

  let filtersFromFilterModel = getFiltersFromFilterModel(filterModel);

  // If filter groups are enabled, we ignore keys other than 'and' or 'or',
  // so filtersFromOptions won't be used unless we add it to a filter group
  if (
    ffEnableNewTableFunctions === true &&
    ('and' in filtersFromFilterModel || 'or' in filtersFromFilterModel)
  ) {
    filtersFromFilterModel = {
      and: [filtersFromFilterModel, filtersFromOptions],
    };
    filtersFromOptions = {};
  }

  const findOrdersFiltersInput: FindOrdersFiltersInput = {
    ...(ffEnableNewTableFunctions !== true && filtersFromPresetTabs),
    ...filtersFromOptions,
    ...filtersFromFilterModel,
  };

  return findOrdersFiltersInput;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
export const getFetchOrdersVariables = <DefaultOrderTabsType extends any>({
  currentOrdersTab,
  defaultTabsConfigs,
  filterModel,
  uiFilterOptions,
  sortModel = [],
  ffEnableNewTableFunctions,
}: GetFetchOrdersVariables<DefaultOrderTabsType>): GetOrderTableFieldValuesQueryVariables => {
  const fetchOrdersFilters = getFetchOrdersFilters({
    currentOrdersTab,
    defaultTabsConfigs,
    filterModel,
    uiFilterOptions,
    ffEnableNewTableFunctions,
  });

  const sortsV2 = filterNotNil(
    sortModel?.map((sort) => {
      const fieldName = sort.colId as OrderTableField;
      return Object.values(OrderTableField).includes(fieldName)
        ? buildOrderSortV2({
            fieldName,
            direction: sort.sort as SortOrder,
            // TODO: figure out what to do with nulls.
            nulls: NullsOrder.Last,
          })
        : null;
    }) ?? [],
  );

  const sorts = filterNotNil(
    sortModel?.map((sort) =>
      Object.values(OrderTableField).includes(sort.colId as OrderTableField)
        ? {
            sortBy: sort.colId as OrderTableField,
            sortDirection: sort.sort as OrderSortDirection,
          }
        : null,
    ) ?? [],
  );

  const result: GetOrderTableFieldValuesQueryVariables = {
    getOrderTableFieldValuesInput: {
      // Default value because this field is required, it'll be overridden later
      orderTableFields: [],
      ...(ffEnableNewTableFunctions === true
        ? { filters: fetchOrdersFilters }
        : fetchOrdersFilters),
      ...(ffEnableNewTableFunctions === true ? { sortsV2 } : { sorts }),
    },
  };

  return result;
};

const ORDER_FILTER_FIELD_ID_TO_DISPLAY_NAME = mapValues(
  OrderFilterField,
) as Record<OrderFilterField, string>;

const ORDER_FILTER_FIELD_DISPLAY_NAME_TO_ID = invert(
  OrderFilterField,
) as Record<string, OrderFilterField>;

/** If a filter model uses display name keys, it's outdated */
export const isLegacyFilterModel = (filterModel: FilterModel): boolean => {
  return Object.keys(filterModel).some(
    (key) => key in ORDER_FILTER_FIELD_DISPLAY_NAME_TO_ID,
  );
};

/** Migrate legacy display name keys to column ID keys */
export const migrateLegacyFilterModelKeys = (
  filterModel: FilterModel,
): OrderTableFilterModel => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const orderTableFilterModel: Record<keyof typeof OrderFilterField, any> =
    Object.fromEntries(
      Object.entries(filterModel ?? {}).map(([displayName, value]) => [
        ORDER_FILTER_FIELD_DISPLAY_NAME_TO_ID[displayName],
        value,
      ]),
    );
  return orderTableFilterModel;
};

export const isLegacyFilterModelValue = (
  value: FilterModel[keyof FilterModel],
): boolean => {
  return 'filterOperator' in value || 'filterType' in value;
};

/**
 * Convert an AG Grid filter model to an OrderTableFilterModel to save
 * in local storage or the DB
 *
 * @see OrderTableFilterModel
 */
export const getOrderTableFilterModel = (
  gridRef: AgGridReact<FormattedOrderFragment> | null,
): OrderTableFilterModel => {
  return migrateLegacyFilterModelKeys(gridRef?.api.getFilterModel() ?? {});
};

/**
 * Convert an OrderTableFilterModel to an AG Grid filter model
 * that can be directly applied to an AG Grid instance
 *
 * @see OrderTableFilterModel
 */
export const getAGGridFilterModel = (
  orderTableFilterModel: OrderTableFilterModel,
): FilterModel => {
  return Object.fromEntries(
    Object.entries(orderTableFilterModel).map(([key, value]) => {
      if (key === 'and' || key === 'or') {
        if (!Array.isArray(value)) {
          throw new Error('Expected array for and/or filter group');
        }
        return [
          key,
          (value as OrderTableFilterModel[]).map((fm) =>
            getAGGridFilterModel(fm),
          ),
        ];
      }
      return [
        ORDER_FILTER_FIELD_ID_TO_DISPLAY_NAME[key as OrderFilterField],
        value,
      ];
    }),
  );
};

export const getOrderTableFields = (
  gridRef: AgGridReact<FormattedOrderFragment> | null,
): OrderTableField[] => {
  const columnState = gridRef?.columnApi?.getColumnState();
  const columnDisplayNames =
    columnState?.filter((def) => def.hide !== true).map((def) => def.colId) ??
    [];
  return filterNotNil(
    columnDisplayNames.map((header) => {
      const orderTableField = Object.values(OrderTableField).find(
        (field) => field === header,
      );
      return orderTableField;
    }),
  );
};

/**
 * Given the current AG grid state ref, constructs and returns the sort model.
 * @param gridRef
 */
export function getAGGridSortModel(
  gridRef: AgGridReact | null,
): SortModelItem[] | undefined {
  if (isNil(gridRef)) {
    return undefined;
  }
  return gridRef?.columnApi
    ?.getColumnState()
    ?.filter((s): s is ColumnSortState<OrderTableField> => !isNil(s.sort))
    ?.map((s) => ({
      colId: s.colId,
      sort: getSortOrder(s.sort),
      sortIndex: s.sortIndex,
    }));
}

/** Convert AG Grid column sort state into OrderSortV2[] */
export const getOrderSortV2 = (
  gridRef: AgGridReact | null,
): OrderSortV2[] | undefined => {
  if (isNil(gridRef)) {
    return undefined;
  }

  const sortState = getAGGridSortModel(gridRef);

  const sortModelBySortIndex = sortBy(sortState, 'sortIndex', 'asc');

  const sortModel = sortModelBySortIndex.map(({ colId, sort }) =>
    buildOrderSortV2({
      fieldName: colId as OrderTableField,
      direction: sort as SortOrder,
      // TODO: support null ordering
      nulls: NullsOrder.Last,
    }),
  );

  return filterNotNil(sortModel);
};

/**
 * Convert OrderSortV2[] into AG Grid column sort state
 *
 * TODO: this should also handle null ordering
 */
export const getOrderColumnSortStates = (
  orderSortV2: OrderSortV2[],
): ColumnSortState<OrderTableField>[] => {
  const sortModel = orderSortV2.map(({ fieldName, ...sortInput }, i) => {
    switch (fieldName) {
      case OrderTableField.Name:
        if (isNil(sortInput.nameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.Name,
          sort: sortInput.nameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.TotalSkids:
        if (isNil(sortInput.totalSkidsSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.TotalSkids,
          sort: sortInput.totalSkidsSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundZipcode:
        if (isNil(sortInput.inboundZipcodeSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundZipcode,
          sort: sortInput.inboundZipcodeSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundZipcode:
        if (isNil(sortInput.outboundZipcodeSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundZipcode,
          sort: sortInput.outboundZipcodeSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.BillOfLadingNumber:
        if (isNil(sortInput.shipperBillOfLadingNumberSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.BillOfLadingNumber,
          sort: sortInput.shipperBillOfLadingNumberSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.CompletedAt:
        if (isNil(sortInput.completedAtSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.CompletedAt,
          sort: sortInput.completedAtSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OrderServiceDate:
        if (isNil(sortInput.orderServiceDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OrderServiceDate,
          sort: sortInput.orderServiceDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.PrimaryServiceDate:
        if (isNil(sortInput.orderServiceDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.PrimaryServiceDate,
          sort: sortInput.orderServiceDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundServiceDate:
        if (isNil(sortInput.inboundServiceDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundServiceDate,
          sort: sortInput.inboundServiceDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundServiceDate:
        if (isNil(sortInput.outboundServiceDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundServiceDate,
          sort: sortInput.outboundServiceDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.Mawb:
        if (isNil(sortInput.mawbSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.Mawb,
          sort: sortInput.mawbSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.CustomerName:
        if (isNil(sortInput.customerNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.CustomerName,
          sort: sortInput.customerNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.SecondaryRefNumber:
        if (isNil(sortInput.secondaryRefNumberSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.SecondaryRefNumber,
          sort: sortInput.secondaryRefNumberSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundAddress:
        if (isNil(sortInput.inboundAddressSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundAddress,
          sort: sortInput.inboundAddressSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundAddress:
        if (isNil(sortInput.outboundAddressSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundAddress,
          sort: sortInput.outboundAddressSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OriginTerminal:
        if (isNil(sortInput.originTerminalSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OriginTerminal,
          sort: sortInput.originTerminalSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.DestinationTerminal:
        if (isNil(sortInput.destinationTerminalSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.DestinationTerminal,
          sort: sortInput.destinationTerminalSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundCityName:
        if (isNil(sortInput.inboundCityNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundCityName,
          sort: sortInput.inboundCityNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundCityName:
        if (isNil(sortInput.outboundCityNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundCityName,
          sort: sortInput.outboundCityNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundDriverName:
        if (isNil(sortInput.inboundDriverNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundDriverName,
          sort: sortInput.inboundDriverNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundDriverName:
        if (isNil(sortInput.outboundDriverNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundDriverName,
          sort: sortInput.outboundDriverNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.Source:
        if (isNil(sortInput.orderSourceSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.Source,
          sort: sortInput.orderSourceSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundCompletedDate:
        if (isNil(sortInput.inboundCompletedDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundCompletedDate,
          sort: sortInput.inboundCompletedDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundCompletedDate:
        if (isNil(sortInput.outboundCompletedDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundCompletedDate,
          sort: sortInput.outboundCompletedDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InBond:
        if (isNil(sortInput.inBondSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InBond,
          sort: sortInput.inBondSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundMethod:
        if (isNil(sortInput.outboundMethodSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundMethod,
          sort: sortInput.outboundMethodSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundRouteName:
        if (isNil(sortInput.inboundRouteNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundRouteName,
          sort: sortInput.inboundRouteNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundRouteName:
        if (isNil(sortInput.outboundRouteNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundRouteName,
          sort: sortInput.outboundRouteNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.PieceCount:
        if (isNil(sortInput.pieceCountSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.PieceCount,
          sort: sortInput.pieceCountSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.Weight:
        if (isNil(sortInput.totalPackageWeightSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.Weight,
          sort: sortInput.totalPackageWeightSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.ReceivedAt:
        if (isNil(sortInput.dateMarkedOnHandSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.ReceivedAt,
          sort: sortInput.dateMarkedOnHandSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.DestinationDetails:
        if (isNil(sortInput.destinationDetailsSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.DestinationDetails,
          sort: sortInput.destinationDetailsSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.IsReweighed:
        if (isNil(sortInput.isReweighedSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.IsReweighed,
          sort: sortInput.isReweighedSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.DimWeight:
        if (isNil(sortInput.dimWeightSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.DimWeight,
          sort: sortInput.dimWeightSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundAddressName:
        if (isNil(sortInput.inboundAddressNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundAddressName,
          sort: sortInput.inboundAddressNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundAddressName:
        if (isNil(sortInput.outboundAddressNameSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundAddressName,
          sort: sortInput.outboundAddressNameSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.InboundRouteDate:
        if (isNil(sortInput.inboundRouteDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.InboundRouteDate,
          sort: sortInput.inboundRouteDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OutboundRouteDate:
        if (isNil(sortInput.outboundRouteDateSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OutboundRouteDate,
          sort: sortInput.outboundRouteDateSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.OrderConsignee:
        if (isNil(sortInput.orderConsigneeSortInput)) {
          return undefined;
        }
        return {
          colId: OrderTableField.OrderConsignee,
          sort: sortInput.orderConsigneeSortInput.direction,
          sortIndex: i,
        };
      case OrderTableField.DriverName:
      case OrderTableField.DriverSettlement:
      case OrderTableField.OrderType:
      case OrderTableField.Status:
      case OrderTableField.BillingReviewStatus:
      case OrderTableField.ShipmentType:
      case OrderTableField.NextAddress:
      case OrderTableField.PrimaryAddress:
      case OrderTableField.CityName:
      case OrderTableField.NextConsignee:
      case OrderTableField.PrimaryConsignee:
      case OrderTableField.Zipcode:
      case OrderTableField.ContactInformation:
      case OrderTableField.BusinessDivision:
      case OrderTableField.NextDeadline:
      case OrderTableField.RoutingLocation:
      case OrderTableField.Tags:
      case OrderTableField.Charges:
      case OrderTableField.RouteName:
      case OrderTableField.PodName:
      case OrderTableField.PrimaryDeadline:
      case OrderTableField.Special:
      case OrderTableField.PrimaryServiceLevel:
      case OrderTableField.PrimaryAppointment:
      case OrderTableField.NextAppointment:
      case OrderTableField.InboundMethod:
      case OrderTableField.ItTeNumber:
      case OrderTableField.Notes:
      case OrderTableField.HasDriverPod:
      case OrderTableField.OnHold:
      case OrderTableField.Attempted:
      case OrderTableField.PaperworkComplete:
      case OrderTableField.InboundPaperwork:
      case OrderTableField.OutboundPaperwork:
      case OrderTableField.Photos:
      case OrderTableField.PrimaryAddressType:
      case OrderTableField.Dims:
      case OrderTableField.ContactName:
      case OrderTableField.PrimaryContactName:
      case OrderTableField.TotalChargesWithItemized:
      case OrderTableField.ExternalNotes:
      case OrderTableField.StopTypes:
      case OrderTableField.InboundStopType:
      case OrderTableField.OutboundStopType:
      case OrderTableField.IsLinehaul:
      case OrderTableField.InboundRouting:
      case OrderTableField.OutboundRouting:
      case OrderTableField.UnNumber:
      case OrderTableField.InboundAppointment:
      case OrderTableField.OutboundAppointment:
      case OrderTableField.InboundAppointmentConfirmed:
      case OrderTableField.OutboundAppointmentConfirmed:
      case OrderTableField.InboundAddressType:
      case OrderTableField.OutboundAddressType:
      case OrderTableField.InboundContactName:
      case OrderTableField.OutboundContactName:
      case OrderTableField.HoldReason:
      case OrderTableField.OsdReason:
      case OrderTableField.InboundCharges:
      case OrderTableField.OutboundCharges:
      case OrderTableField.LineHaulCharges:
      case OrderTableField.AdditionalCharges:
        return undefined;
      default:
        return exhaustive(fieldName);
    }
  });
  return filterNotNil(sortModel);
};
