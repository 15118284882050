import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { isNil, sumBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { getSettlementEligibleTotal } from 'shared/billing';
import { safeMultiply } from 'shared/math';
import {
  DriverSettlementBillByUuidDocument,
  DriverSettlementBillsDocument,
  useCreateSettlementDeductionMutation,
  useDriverSettlementBillByUuidQuery,
  useUpdateDriverSettlementBillMutation,
} from '../../../../generated/graphql';
import useDriverSettlementStore from '../../driver-settlement-store';
import { SettlementsDownloadType } from '../../types';
import CreateAdjustmentSettlementModal from '../driver-settlement-stops/create-adjustment-settlement-modal';
import StopDriverMapsTable from '../driver-settlement-stops/stop-driver-maps-table';
import ChangeSettlementStatusModal from './change-settlement-status-modal';
import SettlementDeductions from './settlement-deductions';
import SettlementPrintSettingsModal from './settlement-print-settings-modal';

type UpdateInput = {
  newName?: string;
  newSettlementDate?: Dayjs | null;
  finalize?: boolean;
};

const Settlement = ({ settlementUuid }: { settlementUuid: string }) => {
  const [
    showCreateAdjustmentSettlementModal,
    setShowCreateAdjustmentSettlementModal,
  ] = useState<boolean>(false);
  const [setShouldRefreshSettlementsList, setOpenedSettlementUuid] =
    useDriverSettlementStore((state) => [
      state.setShouldRefreshSettlementsList,
      state.setOpenedSettlementUuid,
    ]);
  const [showFinalizeSettlementModal, setShowFinalizeSettlementModal] =
    useState<boolean>(false);
  const [showUnfinalizeSettlementModal, setShowUnfinalizeSettlementModal] =
    useState<boolean>(false);
  const [
    showSettlementPrintSettingsModal,
    setShowSettlementPrintSettingsModal,
  ] = useState<boolean>(false);
  const [shouldRefreshStopDriverMaps, setShouldRefreshStopDriverMaps] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>();
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>();
  const [settlementDate, setSettlementDate] = useState<
    Dayjs | null | undefined
  >();
  const { data: settlementData, loading } = useDriverSettlementBillByUuidQuery({
    variables: {
      uuid: settlementUuid,
    },
  });
  const [updateDriverSettlementBill] = useUpdateDriverSettlementBillMutation();
  const [createSettlementDeduction] = useCreateSettlementDeductionMutation();

  const isFinalized = !isNil(
    settlementData?.driverSettlementBill.finalizedDate,
  );

  useEffect(() => {
    const settlementBill = settlementData?.driverSettlementBill;
    setName(settlementBill?.name ?? undefined);
    setStartDate(
      !isNil(settlementBill?.billStartDate)
        ? dayjs(settlementBill?.billStartDate)
        : null,
    );
    setEndDate(
      !isNil(settlementBill?.billEndDate)
        ? dayjs(settlementBill?.billEndDate)
        : undefined,
    );
    setSettlementDate(
      !isNil(settlementBill?.settlementDate)
        ? dayjs(settlementBill?.settlementDate)
        : undefined,
    );
  }, [settlementData]);

  const update = async ({
    newName,
    newSettlementDate,
    finalize,
  }: UpdateInput) => {
    await updateDriverSettlementBill({
      variables: {
        updateDriverSettlementBillInput: {
          driverSettlementBillUpdateInput: {
            uuid: settlementUuid,
            name: newName,
            settlementDate: !isNil(newSettlementDate)
              ? newSettlementDate.toDate()
              : undefined,
            finalizedDate:
              // eslint-disable-next-line no-nested-ternary
              finalize === true
                ? new Date()
                : finalize === false
                  ? null
                  : undefined,
          },
        },
      },
      refetchQueries: [
        DriverSettlementBillsDocument,
        DriverSettlementBillByUuidDocument,
      ],
    });
  };

  const finalizeSettlement = async () => {
    await update({ finalize: true });
    setShouldRefreshSettlementsList(true);
  };

  const unfinalizeSettlement = async () => {
    await update({ finalize: false });
    setShouldRefreshSettlementsList(true);
  };

  const createDeduction = async () => {
    await createSettlementDeduction({
      variables: {
        settlementDeductionCreateInput: {
          driverSettlementBillUuid: settlementUuid,
          name: 'New Deduction',
        },
      },
      refetchQueries: [
        DriverSettlementBillsDocument,
        DriverSettlementBillByUuidDocument,
      ],
    });
  };

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 500);
    }
  }, [isSaving]);

  const totalDeductions = useMemo(() => {
    return sumBy(
      settlementData?.driverSettlementBill.settlementDeductions,
      (settlementDeduction) =>
        getSettlementEligibleTotal(
          settlementDeduction.flatRate,
          !isNil(settlementDeduction.percentageDecimalRate)
            ? safeMultiply(settlementDeduction.percentageDecimalRate, 100)
            : undefined,
          settlementData?.driverSettlementBill.totalDriverPayoutPreDeduction,
        ),
    );
  }, [settlementData]);

  return (
    <Stack height="100%" display="flex" flexDirection="column">
      <CreateAdjustmentSettlementModal
        open={showCreateAdjustmentSettlementModal}
        setOpen={setShowCreateAdjustmentSettlementModal}
        settlementUuid={settlementUuid}
        onSuccess={async () => {
          setShouldRefreshStopDriverMaps(true);
          setShowCreateAdjustmentSettlementModal(false);
        }}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ p: 1, paddingBottom: '10px' }}>
          <Grid container alignItems="center">
            <Grid item md={8} xs={12}>
              <Stack direction="row" spacing={1} alignItems="center">
                <FormControl>
                  <TextField
                    label="Name"
                    value={name ?? ''}
                    size="small"
                    disabled={isFinalized}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onBlur={(e) => {
                      setIsSaving(true);
                      update({ newName: e.target.value });
                    }}
                    InputProps={{
                      endAdornment: !isFinalized && (
                        <Typography sx={{ fontSize: '12px', color: 'gray' }}>
                          {isSaving ? 'Saving...' : 'Saved'}
                        </Typography>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled={isFinalized}
                      label="Settlement date"
                      value={settlementDate}
                      renderInput={(params) => (
                        <TextField
                          aria-label="date-picker"
                          sx={{ width: 150 }}
                          size="small"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                      onChange={(newValue) => {
                        setSettlementDate(newValue);
                        update({ newSettlementDate: newValue });
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl>
                  <TextField
                    label="Pay period"
                    value={`${startDate?.format('MM/DD/YYYY') ?? 'N/A'} - ${
                      endDate?.format('MM/DD/YYYY') ?? 'N/A'
                    }`}
                    size="small"
                    sx={{
                      width: '300px',
                      ml: 1,
                      '& fieldset': { border: 'none' },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ float: 'right' }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  disabled={loading}
                  onClick={() => {
                    setShowSettlementPrintSettingsModal(true);
                  }}
                >
                  Download
                </Button>
                {!loading &&
                  (!isFinalized ? (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setShowFinalizeSettlementModal(true);
                      }}
                    >
                      Finalize
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setShowUnfinalizeSettlementModal(true);
                      }}
                    >
                      Unfinalize
                    </Button>
                  ))}
                <Tooltip title="Close Settlement View">
                  <IconButton
                    onClick={() => {
                      setOpenedSettlementUuid(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box flex="1" minHeight="0">
        <StopDriverMapsTable
          settlementUuid={settlementUuid}
          settlementIsFinalized={isFinalized}
          shouldRefresh={shouldRefreshStopDriverMaps}
          setShouldRefresh={setShouldRefreshStopDriverMaps}
          rightComponent={
            <Stack
              direction="row-reverse"
              sx={{ width: '100%' }}
              alignItems="center"
              spacing={1}
            >
              <Box>
                <SettlementDeductions
                  isFinalized={isFinalized}
                  driverSettlementTotal={
                    settlementData?.driverSettlementBill
                      .totalDriverPayoutPreDeduction
                  }
                  totalDeductions={totalDeductions}
                  settlementDeductions={
                    settlementData?.driverSettlementBill.settlementDeductions ??
                    []
                  }
                />
              </Box>
              <Stack>
                <Button
                  size="small"
                  onClick={() => {
                    setShowCreateAdjustmentSettlementModal(true);
                  }}
                  disabled={isFinalized}
                >
                  Create Adjustment
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    createDeduction();
                  }}
                  disabled={isFinalized}
                >
                  Create Deduction
                </Button>
              </Stack>
            </Stack>
          }
        />
      </Box>
      <ChangeSettlementStatusModal
        isOpen={showFinalizeSettlementModal}
        setIsOpen={setShowFinalizeSettlementModal}
        onConfirm={() => {
          setShowFinalizeSettlementModal(false);
          finalizeSettlement();
          setShouldRefreshSettlementsList(true);
        }}
      />
      <ChangeSettlementStatusModal
        isOpen={showUnfinalizeSettlementModal}
        setIsOpen={setShowUnfinalizeSettlementModal}
        onConfirm={() => {
          setShowUnfinalizeSettlementModal(false);
          unfinalizeSettlement();
          setShouldRefreshSettlementsList(true);
        }}
        unfinalizing
      />
      <SettlementPrintSettingsModal
        isOpen={showSettlementPrintSettingsModal}
        setIsOpen={setShowSettlementPrintSettingsModal}
        settlementUuid={settlementUuid}
        selectedDownloadTypeOption={SettlementsDownloadType.SEPARATE_FILES}
      />
    </Stack>
  );
};

export default React.memo(Settlement);
