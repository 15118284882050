import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { ChangeEvent } from 'react';
import { shallow } from 'zustand/shallow';
import {
  DetailedLineHaulManifestFragment,
  ShallowLineHaulSegmentFragment,
} from '../../../generated/graphql';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import ManifestStatusChip from './manifest-status-chip';

const ManifestCardHeader = ({
  manifest,
  unmanifestedSegment,
  color = 'black',
}: {
  manifest: DetailedLineHaulManifestFragment | undefined;
  unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
  color?: string;
}) => {
  // const [name, setName] = useState<string>('');

  const [
    setOpenedManifest,
    setOpenedUnmanifestedSegment,
    selectManifestUuid,
    deselectManifestUuid,
    selectUnmanifestedSegmentUuid,
    deselectUnmanifestedSegmentUuid,
    isSelected,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.setOpenedManifest,
      state.setOpenedUnmanifestedSegment,
      state.selectManifestUuid,
      state.deselectManifestUuid,
      state.selectUnmanifestedSegmentUuid,
      state.deselectUnmanifestedSegmentUuid,
      state.selectedManifestUuids.includes(manifest?.uuid ?? '') ||
        state.selectedUnmanifestedSegmentUuids.includes(
          unmanifestedSegment?.uuid ?? '',
        ),
    ],
    shallow,
  );

  const [selectingManifests] = useLineHaulDispatchStore(
    (state) => [state.selectingManifests],
    shallow,
  );

  const handleOpenManifest = () => {
    if (!isNil(manifest)) {
      setOpenedManifest(manifest);
    } else if (!isNil(unmanifestedSegment)) {
      setOpenedUnmanifestedSegment(unmanifestedSegment);
    }
  };

  const handleCheckboxSelect = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.target.checked) {
      if (!isNil(manifest)) selectManifestUuid(manifest.uuid);
      else if (!isNil(unmanifestedSegment))
        selectUnmanifestedSegmentUuid(unmanifestedSegment.uuid);
    } else if (!isNil(manifest)) deselectManifestUuid(manifest.uuid);
    else if (!isNil(unmanifestedSegment))
      deselectUnmanifestedSegmentUuid(unmanifestedSegment.uuid);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Stack direction="row" gap={1.5} alignItems="center">
        {selectingManifests && (
          <Checkbox
            sx={{ p: 0 }}
            style={{
              color: isSelected ? 'white' : undefined,
            }}
            size="small"
            checked={isSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={handleCheckboxSelect}
          />
        )}
        <Typography
          variant="subtitle2"
          noWrap
          sx={{ color, fontWeight: 600, fontSize: 16 }}
          gap={2}
        >
          {manifest?.startTerminal.code} - {manifest?.endTerminal.code}
        </Typography>
        <Typography variant="caption" sx={{ opacity: 0.6, p: 0 }}>
          {manifest?.referenceNumber}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        {!isNil(manifest) && <ManifestStatusChip status={manifest.status} />}
        <IconButton size="small" onClick={handleOpenManifest}>
          <ArrowForwardIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ManifestCardHeader;
