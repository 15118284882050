import { styled, TableCell, TableRow } from '@mui/material';

export const TableCellCustom = styled(TableCell)(`
    fontSize: 12px;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
    width: 100;
    max-width: 100;
    text-overflow: ellipsis;
`);

export const TableRowCustom = styled(TableRow)(`
    border: 0
`);
