import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useShallowContactQuery } from '../../../../../generated/graphql';
import { OrderFormFieldValues } from '../types';

const useOrderFormContact = () => {
  const [searchParams] = useSearchParams();
  const { control } = useFormContext<OrderFormFieldValues>();
  const contactUuidFromUrl = searchParams.get('contactUuid');
  const contactUuidFromForm = useWatch({ control, name: 'contactUuid' });
  const contactUuid = useMemo(() => {
    if (!isNil(contactUuidFromForm) && !isEmpty(contactUuidFromForm)) {
      return contactUuidFromForm;
    }
    if (!isNil(contactUuidFromUrl)) {
      return contactUuidFromUrl;
    }
    return undefined;
  }, [contactUuidFromUrl, contactUuidFromForm]);

  const {
    data: contactData,
    refetch,
    loading,
  } = useShallowContactQuery(
    !isNil(contactUuid)
      ? {
          variables: {
            uuid: contactUuid,
          },
          fetchPolicy: 'cache-first',
        }
      : { skip: true },
  );

  return {
    contactUuid,
    contact: contactData?.contact,
    refetch,
    loading,
  };
};

export { useOrderFormContact };
