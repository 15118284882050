import { Box, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  FormattedOrderFragment,
  OrderUuidsQueryVariables,
  useOrderUuidsLazyQuery,
} from '../../../../generated/graphql';
import { Order } from '../../../orders/types';

const SelectAllOrders = ({
  setSelectedUuids,
  queryVariables,
  gridRef,
  pageSize,
  setIsHeaderCheckboxSelected,
  handleSelectAllOrders,
  count,
}: {
  setSelectedUuids: Dispatch<SetStateAction<string[]>>;
  queryVariables: OrderUuidsQueryVariables | undefined;
  gridRef: React.RefObject<
    AgGridReact<Order> | AgGridReact<FormattedOrderFragment>
  >;
  pageSize: number;
  setIsHeaderCheckboxSelected: (prevState: boolean) => void;
  handleSelectAllOrders?: (uuids: string[]) => void;
  count: number | undefined;
}) => {
  const [allOrdersSelected, setAllOrdersSelected] = useState(false);
  const [orderUuidsQuery] = useOrderUuidsLazyQuery();
  const [orderCount, setOrderCount] = useState<number | null>(null);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E6F6FD',
        pt: '20px',
        pb: '20px',
      }}
    >
      <Typography sx={{ color: '#104C67' }}>
        {!allOrdersSelected
          ? `All ${Math.min(
              pageSize,
              count ?? 0,
            )} orders on this page are selected.`
          : `All ${orderCount} orders are selected.`}
      </Typography>
      <Typography
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
          ml: '5px',
          color: '#0D8ED4',
        }}
        onClick={async () => {
          if (!isNil(queryVariables)) {
            if (allOrdersSelected) {
              gridRef.current?.api.deselectAll();
              setSelectedUuids([]);
              setOrderCount(null);
              setIsHeaderCheckboxSelected(false);
              if (!isNil(handleSelectAllOrders)) {
                handleSelectAllOrders([]);
              }
            } else {
              // We can't just use the nodes from the gridRef because we want to
              // select orders on all pages, not just the current page
              const orderData = await orderUuidsQuery({
                variables: { ...queryVariables, noMaximum: true },
              });
              if (!isNil(orderData.data)) {
                const selectedOrderUuids = orderData.data.orders.edges.map(
                  ({ node: order }) => {
                    const node = gridRef.current?.api.getRowNode(order.uuid);
                    node?.setSelected(true);
                    return order.uuid;
                  },
                );
                setSelectedUuids(selectedOrderUuids);
                setOrderCount(orderData.data.orders.edges?.length ?? null);
                if (!isNil(handleSelectAllOrders)) {
                  handleSelectAllOrders(selectedOrderUuids);
                }
              }
            }
          }
          setAllOrdersSelected(!allOrdersSelected);
        }}
      >
        {!allOrdersSelected ? `Select all orders` : `Clear selection`}
      </Typography>
    </Box>
  );
};

export default SelectAllOrders;
