import * as yup from 'yup';

import { userSchema } from './user-schema';

export const orderCommentSchema = yup
  .object({
    uuid: yup.string().optional().nullable(),
    authorName: yup.string(),
    user: userSchema,
    comment: yup.string().optional().nullable(),
    updatedAt: yup.date().optional().nullable(),
    showOnInvoice: yup.boolean().optional().nullable(),
  })
  .optional()
  .nullable();
