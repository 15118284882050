import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
} from '@reduxjs/toolkit';
import { FreightChargeFormField } from 'shared/types';
import type { RootState } from '../../../redux/store';

const freightChargesValuesAdapter = createEntityAdapter<FreightChargeFormField>(
  {
    selectId: (freightCharge) => freightCharge.uuid,
  },
);

export const freightChargesValuesSlice = createSlice({
  name: 'freightChargesValues',
  initialState: freightChargesValuesAdapter.getInitialState(),
  reducers: {
    addFreightCharge: freightChargesValuesAdapter.addOne,
    updateFreightCharge: freightChargesValuesAdapter.updateOne,
    deleteFreightCharge: freightChargesValuesAdapter.removeOne,
    updateFreightCharges: freightChargesValuesAdapter.updateMany,
    loadedFreightCharges: freightChargesValuesAdapter.setAll,
    upsertFreightCharge: freightChargesValuesAdapter.upsertOne,
  },
});

export const {
  selectById: selectFreightChargeById,
  selectIds: selectFreightChargeIds,
  selectAll: selectFreightCharges,
} = freightChargesValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormFreightChargesValues,
);

const freightChargeValuesSelector = (state: RootState) =>
  state.orderFormFreightChargesValues;

export const selectFreightChargesByIds = createSelector(
  freightChargeValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state, entityIds) => {
    const entityResults: FreightChargeFormField[] = [];
    entityIds.forEach((id) => {
      const match = freightChargesValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    });
    return entityResults;
  },
);

export const selectFreightChargeQuantity = createSelector(
  selectFreightChargeById,
  (values: FreightChargeFormField | undefined) => {
    return values?.quantity;
  },
);

export const selectFreightChargeTariffUuid = createSelector(
  selectFreightChargeById,
  (values: FreightChargeFormField | undefined) => {
    return values?.tariffUuid;
  },
);

export const selectFreightChargeFuelChargeId = createSelector(
  selectFreightChargeById,
  (values: FreightChargeFormField | undefined) => {
    return values?.fuelChargeId;
  },
);

type FreightChargeSchema = {
  [k in keyof Required<FreightChargeFormField>]: boolean;
};

export const freightChargeFieldIsRequired: FreightChargeSchema = {
  quantity: false,
  billingMethod: true,
  description: false,
  fuelChargeId: false,
  ignoreQuantityRegression: false,
  rate: true,
  uuid: true,
  shipmentUuid: false,
  tariffUuid: false,
  settlementFlatRate: false,
  settlementPercentageRate: false,
  authoCode: false,
};

export const {
  addFreightCharge,
  updateFreightCharge,
  loadedFreightCharges,
  upsertFreightCharge,
} = freightChargesValuesSlice.actions;

const freightChargeValuesReducer = freightChargesValuesSlice.reducer;

export default freightChargeValuesReducer;
