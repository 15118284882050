import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Badge } from '@mui/material';
import { subHours } from 'date-fns';
import React, { memo, useEffect } from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import {
  DateFilterType,
  FilterOperator,
  InvoiceSendJobBatchesQueryVariables,
  useInvoiceSendJobBatchesQuery,
} from '../../../../../generated/graphql';

const recentInvoiceSendJobBatchesVariables: InvoiceSendJobBatchesQueryVariables =
  {
    first: 1,
    inProgress: true,
    createdAtDateFilters: [
      {
        filterType: DateFilterType.IsAfter,
        filterOperator: FilterOperator.And,
        value: subHours(new Date(), 1),
      },
    ],
  };

const InvoiceSendMenuIconWithCount = () => {
  const ffEnableInvoiceSendJobsBatchListCount = useFeatureFlag(
    FeatureFlag.FF_ENABLE_INVOICE_SEND_SEND_MENU_ICON_COUNT,
  );
  const {
    data: recentInvoiceSendJobBatches,
    startPolling,
    stopPolling,
  } = useInvoiceSendJobBatchesQuery({
    variables: recentInvoiceSendJobBatchesVariables,
  });

  useEffect(() => {
    if (ffEnableInvoiceSendJobsBatchListCount) {
      startPolling(ONE_MINUTE_IN_MS);
    }

    return () => {
      stopPolling();
    };
  }, [ffEnableInvoiceSendJobsBatchListCount, startPolling, stopPolling]);

  return (
    <Badge
      badgeContent={
        recentInvoiceSendJobBatches?.invoiceSendJobBatches.totalCount ?? 0
      }
      color="info"
      showZero={false}
    >
      <AttachEmailIcon />
    </Badge>
  );
};

export default memo(InvoiceSendMenuIconWithCount);
