import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  styled,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { isNil, sumBy } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { transformAddressToFullAddressString } from 'shared/copy';
import { shallow } from 'zustand/shallow';
import { formatWeightUnits } from '../../../../common/utils/prettyPrintUtils';
import {
  ConsolidatableOrderSummaryFragment,
  OutstandingOrderFragmentFragment,
  PackageType,
  StandardOrderFragmentFragment,
  useCreateConsolidatedOrderFromOrderMutation,
  useMarkConsolidatableOrdersAsBilledIndividuallyMutation,
  WeightUnits,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewActions from '../../hooks/use-billing-review-actions';
import useBillingReviewNavigationActions from '../../hooks/use-billing-review-navigation-actions';
import { isConsolidatableStopType } from '../../utils';

const useStyles = () => {
  return {
    boxFlexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxFlexCol: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    packageItem: {
      borderLeft: '2px solid lightgray',
      paddingLeft: 1,
      paddingRight: 1,
      textAlign: 'center',
    },
    step: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.MuiStepIcon-text': {
        display: 'none',
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '.Mui-completed': {
        fontWeight: 'normal',
      },
    },
  };
};

const CardHeaderNoPadding = styled(CardHeader)(`
  padding-bottom: 0;
`);

const ConsolidateOutstandingOrdersModal = ({
  handleClose,
  setErrorMessage,
}: {
  handleClose: () => void;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
}) => {
  const styles = useStyles();

  const setOpenedOrderUuid = useGlobalStore(
    (state) => state.setCurrentOrderUuid,
  );
  const [
    openedOutstandingOrderUuid,
    outstandingOrdersInPage,
    removeSearchedOrdersByUuids,
  ] = useBillingReviewStore(
    (state) => [
      state.openedOutstandingOrderUuid,
      state.outstandingOrdersInPage,
      state.removeSearchedOrdersByUuids,
    ],
    shallow,
  );
  const {
    fetchOrderCacheFirst,
    fetchConsolidatableOrdersSummaries,
    refetchOrdersAndOverwriteCache,
  } = useBillingReviewActions();

  const { refetchPage } = useBillingReviewNavigationActions();

  const [currentOutstandingOrder, setCurrentOutstandingOrder] = useState<
    StandardOrderFragmentFragment | OutstandingOrderFragmentFragment | null
  >(null);
  const [consolidatableOrders, setConsolidatableOrders] = useState<
    ConsolidatableOrderSummaryFragment[]
  >([]);
  const [markBillIndividually] =
    useMarkConsolidatableOrdersAsBilledIndividuallyMutation();
  const [createConsolidatedOrder, { loading: createConsolidatedOrderLoading }] =
    useCreateConsolidatedOrderFromOrderMutation();

  const fetchData = async () => {
    setCurrentOutstandingOrder(
      await fetchOrderCacheFirst({ orderUuid: openedOutstandingOrderUuid }),
    );
    setConsolidatableOrders(
      filterNotNil((await fetchConsolidatableOrdersSummaries()) ?? []),
    );
  };
  useEffect(() => {
    if (!isNil(openedOutstandingOrderUuid)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOutstandingOrderUuid]);

  const getPackageType = (
    packages: { type?: PackageType | null | undefined }[],
  ): PackageType | string => {
    return !isNil(packages[0]?.type) &&
      packages.every((pkg) => pkg.type === packages[0]?.type)
      ? (packages[0]?.type ?? 'PCS')
      : 'PCS';
  };

  const consolidate = async () => {
    if (!isNil(currentOutstandingOrder)) {
      const result = await createConsolidatedOrder({
        variables: {
          createConsolidatedOrderFromOrderInput: {
            orderUuid: currentOutstandingOrder.uuid,
          },
        },
      });
      const consolOrderUuid =
        result.data?.createConsolidatedOrderFromOrder.uuid;
      if (!isNil(consolOrderUuid)) {
        await refetchPage(
          [...outstandingOrdersInPage.map((o) => o.uuid), consolOrderUuid],
          consolOrderUuid,
        );
        const consolidatableOrderUuids =
          currentOutstandingOrder.consolidatableOrders.orders.map(
            (consolidatableOrder) => consolidatableOrder.uuid,
          );

        removeSearchedOrdersByUuids([
          ...consolidatableOrderUuids,
          currentOutstandingOrder.uuid,
        ]);
      } else {
        setErrorMessage(
          `Error consolidating order ${currentOutstandingOrder?.standardOrderFields.shipperBillOfLadingNumber}`,
        );
      }
    } else {
      setErrorMessage('Error consolidating order');
    }
    handleClose();
  };

  return (
    <Card
      variant="outlined"
      style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}
    >
      <CardHeaderNoPadding
        title={
          <Box sx={styles.boxFlexCol}>
            <Typography
              variant="h6"
              sx={{ fontSize: '20px' }}
              textAlign="center"
            >
              Order{' '}
              {currentOutstandingOrder?.standardOrderFields
                .shipperBillOfLadingNumber ?? ''}{' '}
              was delivered to the same address on the same route as{' '}
              {currentOutstandingOrder?.consolidatableOrders.orders.length}{' '}
              other orders.
            </Typography>
            <Typography sx={{ fontSize: '17px' }} textAlign="center">
              Would you like to consolidate the weights of these orders or bill
              them individually?
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Grid container justifyContent="center" spacing={2}>
            {consolidatableOrders.map((order) => {
              const shipmentToShow = order.shipments.find((s) =>
                isConsolidatableStopType(s.legs[0]?.endStop?.stopType),
              );
              const shipmentAddress = shipmentToShow?.legs[0]?.endStop?.address;
              return (
                <Grid item xs={4} key={order.uuid}>
                  <Card
                    variant="outlined"
                    style={{
                      height: '100%',
                      maxHeight: '100%',
                      boxShadow: '3px 3px 2px lightgray',
                    }}
                  >
                    <CardHeaderNoPadding
                      title={
                        <Box
                          sx={{
                            ...styles.boxFlexRow,
                            borderBottom: '2px solid lightgray',
                          }}
                        >
                          <Box
                            sx={{
                              ...styles.boxFlexCol,
                              alignItems: 'flex-start',
                            }}
                          >
                            <Typography
                              fontWeight="bold"
                              fontSize="17px"
                              lineHeight={1}
                            >
                              {
                                order.standardOrderFields
                                  .shipperBillOfLadingNumber
                              }
                            </Typography>
                            <Typography color="gray" fontSize="14px">
                              {order.name}
                            </Typography>
                          </Box>
                          <Button
                            onClick={async () => {
                              setOpenedOrderUuid(order.uuid);
                            }}
                            endIcon={<OpenInNewIcon />}
                          >
                            Open order
                          </Button>
                        </Box>
                      }
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography fontWeight="bold">Address</Typography>
                          </Grid>
                          {!isNil(shipmentAddress) ? (
                            <Grid item xs={12}>
                              <Typography>{shipmentAddress?.name}</Typography>
                              <Typography>
                                {transformAddressToFullAddressString(
                                  shipmentAddress,
                                )}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Typography>-</Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Divider />
                        <Grid container>
                          <Grid item xs={7}>
                            <Typography fontWeight="bold">Packages</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {sumBy(order.packages, (pkg) => pkg.quantity)}{' '}
                              {getPackageType(order.packages)}
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography fontWeight="bold">Weight</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {' '}
                              {`${order.weight} ${formatWeightUnits(
                                currentOutstandingOrder?.standardOrderFields
                                  ?.weightUnits ?? WeightUnits.Pounds,
                              )}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography fontWeight="bold">
                              Dim weight
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {' '}
                              {`${order.dimWeight} ${formatWeightUnits(
                                currentOutstandingOrder?.standardOrderFields
                                  ?.weightUnits ?? WeightUnits.Pounds,
                              )}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        <Grid container>
                          <Grid item xs={7}>
                            <Typography fontWeight="bold">
                              Freight charge
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {currency(order.freightChargeRevenue).format()}
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography fontWeight="bold">
                              Fuel charge
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {currency(order.fuelChargeRevenue).format()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider sx={{ mb: '5px' }} />
                          </Grid>
                          <Grid item xs={7}>
                            <Typography fontWeight="bold">Total</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {currency(order.totalCharge).format()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{ ...styles.boxFlexRow, justifyContent: 'center', gap: '20px' }}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={async () => {
                if (!isNil(openedOutstandingOrderUuid)) {
                  await markBillIndividually({
                    variables: {
                      markConsolidatableOrdersAsBilledIndividuallyInput: {
                        orderUuid: openedOutstandingOrderUuid,
                      },
                    },
                  });
                  await refetchOrdersAndOverwriteCache([
                    openedOutstandingOrderUuid,
                  ]);
                } else {
                  setErrorMessage(
                    'Error marking orders as billed individually',
                  );
                }
                handleClose();
              }}
            >
              Bill individually
            </Button>
            <Button
              disabled={createConsolidatedOrderLoading}
              startIcon={
                Boolean(createConsolidatedOrderLoading) && (
                  <CircularProgress size={20} />
                )
              }
              variant="contained"
              color="primary"
              size="large"
              onClick={consolidate}
            >
              Consolidate
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ConsolidateOutstandingOrdersModal;
