import { configureAbly } from '@ably-labs/react-hooks';
import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import * as Fullstory from '@fullstory/browser';
import { jam } from '@jam.dev/sdk';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import { Types } from 'ably/promises';
import { LicenseManager } from 'ag-grid-enterprise';
import { isNil, maxBy } from 'lodash';
import { ConfirmProvider } from 'material-ui-confirm';
import primeReactOptions, { PrimeReactProvider } from 'primereact/api';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import ErrorProvider from './apollo/providers/errors/error-provider';
import apolloClient from './apollo-client';
import { ErrorBoundary } from './common/components/ErrorBoundary';
import { FeatureFlag } from './common/feature-flags';
import useFeatureFlag from './common/react-hooks/use-feature-flag';
import useInterval from './common/react-hooks/use-interval';
import useMe from './common/react-hooks/use-me';
import { setupDatadogForUser } from './datadog';
import {
  EnvironmentVariables,
  isDevelopment,
  isProduction,
  isRelease,
  isStaging,
} from './environment-variables';
import {
  AblyTokenDocument,
  AblyTokenQuery,
  AblyTokenQueryVariables,
  useMeAsThirdPartyUserQuery,
  UserRolesQuery,
  useUserRolesQuery,
} from './generated/graphql';
import { createStore } from './redux/store';
import { routes } from './routes';
import { sentryCreateBrowserRouter } from './sentry';
import AsyncStatsigProvider from './statsig/async-statsig-provider';
import { getTheme, inputBorderColor } from './theme';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-043191 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Andrew-test-project )_is_granted_a_( Single Application )_Developer_License_for_the_application_( andrew-test-project )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( andrew-test-project )_need_to_be_licensed___( andrew-test-project )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 2 June 2024 )____[v2]_MTcxNzI4MjgwMDAwMA==0447f5aa3140343b07a6a91e6b89dd19',
);
LicenseInfo.setLicenseKey(
  EnvironmentVariables.VITE_MUI_X_PRO_LICENSE_KEY ?? '',
);

const MonitorInitializers = () => {
  const me = useMe();
  const { data: meAsThirdPartyUserData, loading: meAsThirdPartyUserLoading } =
    useMeAsThirdPartyUserQuery({ fetchPolicy: 'cache-first' });

  const loading = me.loading || meAsThirdPartyUserLoading;
  const userUuid =
    me.userUuid ?? meAsThirdPartyUserData?.meAsThirdPartyUser?.uuid;
  const email = me.email ?? meAsThirdPartyUserData?.meAsThirdPartyUser?.email;
  const { companyUuid } = me;
  const isThirdPartyUser = !isNil(meAsThirdPartyUserData?.meAsThirdPartyUser);
  // Initialize Datadog
  useEffect(() => {
    if (isProduction() && typeof window !== 'undefined' && !loading) {
      setupDatadogForUser({
        userUuid,
        email,
        companyUuid,
        isThirdPartyUser,
      });
    }
  }, [companyUuid, email, isThirdPartyUser, loading, userUuid]);

  // Initialize Jam Metadata https://jam.dev/docs/product-features/dev-tools/jam.metadata
  useEffect(() => {
    if (typeof window !== 'undefined' && !loading) {
      jam.metadata(() => {
        return {
          userUuid,
          email,
          companyUuid,
          isThirdPartyUser,
        };
      });
    }
  }, [companyUuid, email, isThirdPartyUser, loading, userUuid]);

  // Initialize Fullstory
  useEffect(() => {
    if (isProduction() && typeof window !== 'undefined') {
      Fullstory.init({ orgId: 'o-1S03XZ-na1' });
    }
  }, []);

  return null;
};

function reportMemoryUsage() {
  if (!isNil(performance) && 'memory' in performance) {
    const memoryUsage = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      jsHeapSizeLimit: performance.memory?.jsHeapSizeLimit,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      totalJSHeapSize: performance.memory?.totalJSHeapSize,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      usedJSHeapSize: performance.memory?.usedJSHeapSize,
    };

    // Send custom action with memory usage data
    datadogRum.addAction('memory_usage', memoryUsage);
  }
}

// The version of PrimeReact that we're on doesn't support partially overriding the Provider options.
// This is the way to keep all the defaults but set a pt property, which is undefined by default.
primeReactOptions.pt = {
  calendar: {
    input: {
      root: {
        style: {
          height: '32px',
          borderColor: inputBorderColor,
        },
      },
    },
  },
};

const store = createStore();

configureAbly({
  async authCallback(
    data: Types.TokenParams,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    callback: (
      error: Types.ErrorInfo | string | null,
      tokenRequestOrDetails:
        | Types.TokenDetails
        | Types.TokenRequest
        | string
        | null,
    ) => void,
  ) {
    const res = await apolloClient.query<
      AblyTokenQuery,
      AblyTokenQueryVariables
    >({
      query: AblyTokenDocument,
    });
    const token = res.data?.ablyToken.token;
    if (!isNil(token)) {
      callback(null, token);
    } else {
      callback(res.error?.message ?? 'error', null);
    }
  },
});

// Use "ADMIN" role as a proxy for the user being an owner
function getAppcuesRole(roles: UserRolesQuery['userRoles']) {
  return (
    maxBy(roles, (role) => {
      if (role.name.toUpperCase() === 'SUPER ADMIN') {
        return 2;
      }
      if (role.name.toUpperCase() === 'ADMIN') {
        return 1;
      }
      return 0;
    })?.name.toUpperCase() ?? 'USER'
  );
}

// Separate component so we can `useMe` from within ApolloProvider
function Appcues() {
  const { userUuid, email, createdAt, companyName, companyUuid } = useMe();
  // the userUuid is undefined when a third party user is logged in
  // user roles only exist for normal users so we skip the query for third party users
  const { data: userRoles, loading: userRolesLoading } = useUserRolesQuery({
    skip: isNil(userUuid),
  });
  const maxRole = userRolesLoading
    ? undefined
    : getAppcuesRole(userRoles?.userRoles ?? []);

  useEffect(() => {
    if (!isNil(userUuid) && !isNil(window.Appcues)) {
      window.Appcues.identify(userUuid, {
        email,
        createdAt,
        role: maxRole,
        companyName,
        companyUuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUuid]);

  useEffect(() => {
    window.AppcuesSettings = { enableURLDetection: true };
  }, []);

  if (!isProduction()) {
    return null;
  }

  return (
    <Helmet>
      <script src="//fast.appcues.com/210572.js" type="text/javascript" />
    </Helmet>
  );
}

const router = sentryCreateBrowserRouter(routes);

export const App = () => {
  const ffNewFont = useFeatureFlag(FeatureFlag.FF_NEW_FONT);

  useInterval(
    () => {
      reportMemoryUsage();
    },
    parseInt(
      EnvironmentVariables.RUM_REPORT_MEMORY_POLL_SECONDS ?? '30000',
      10,
    ),
  );
  const theme = getTheme(ffNewFont);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Helmet>
          {isDevelopment() && <link rel="icon" href="/favicon-dev.ico" />}
          {isStaging() && <link rel="icon" href="/favicon-staging.ico" />}
          {isProduction() && <link rel="icon" href="/favicon.ico" />}
          {isRelease() && <link rel="icon" href="/favicon-release.ico" />}
        </Helmet>
        <Provider store={store}>
          <ErrorProvider>
            <PrimeReactProvider>
              <ApolloProvider client={apolloClient}>
                <ThemeProvider theme={theme}>
                  <ConfirmProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Appcues />
                        <MonitorInitializers />
                        <AsyncStatsigProvider>
                          <RouterProvider router={router} />
                        </AsyncStatsigProvider>
                      </LocalizationProvider>
                    </LocalizationProvider>
                  </ConfirmProvider>
                </ThemeProvider>
              </ApolloProvider>
            </PrimeReactProvider>
          </ErrorProvider>
        </Provider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
