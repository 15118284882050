import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import useMe from '../common/react-hooks/use-me';
import EditItemForm from '../domains/items/edit-item-form';
import Warehouse from '../domains/management/components/warehouse';
import EditStorageOrderForm from '../domains/storage-orders/components/edit-storage-order/edit-storage-order-form';

export const WarehouseRedirectPage = () => {
  const navigate = useNavigate();
  const { companyConfiguration } = useMe();

  const wmsEnabled = companyConfiguration?.wmsEnabled;
  const wmsStorageOrdersEnabled = companyConfiguration?.wmsStorageOrdersEnabled;
  const wmsShortTermStorageEnabled =
    companyConfiguration?.wmsShortTermStorageEnabled;
  useEffect(() => {
    if (wmsEnabled !== true) {
      navigate('/', { replace: true });
    }
    if (wmsStorageOrdersEnabled === true) {
      navigate('/warehouse/storage-orders', { replace: true });
    } else if (wmsShortTermStorageEnabled === true) {
      navigate('/warehouse/reports', { replace: true });
    } else {
      navigate('/warehouse/settings', { replace: true });
    }
  }, [
    wmsEnabled,
    wmsStorageOrdersEnabled,
    wmsShortTermStorageEnabled,
    navigate,
  ]);

  return null;
};

export const ItemPage = () => {
  const { itemUuid } = useParams();
  if (isNotNilOrEmptyString(itemUuid)) {
    return <EditItemForm itemUuid={itemUuid} />;
  }
  return null;
};

export const WarehousePage = () => {
  const { warehouseUuid } = useParams();
  if (isNotNilOrEmptyString(warehouseUuid)) {
    return <Warehouse warehouseUuid={warehouseUuid} />;
  }
  return null;
};

export const AddWarehousePage = () => <Warehouse warehouseUuid={null} />;

export const StorageOrderPage = () => {
  const { orderUuid } = useParams();
  if (isNotNilOrEmptyString(orderUuid)) {
    return <EditStorageOrderForm uuid={orderUuid} />;
  }
  return null;
};
