import * as yup from 'yup';
import {
  DayOfWeekAll,
  RepeatFrequencyUnit,
} from '../../../../../generated/graphql';

export const recurringOrderFrequencySchema = yup.object({
  uuid: yup.string().required(),
  startDate: yup.date().required('Start date is required'),
  endDate: yup.date().optional().nullable(),
  repeatInterval: yup.number().required('Weekly repeat interval is required'),
  repeatFrequencyUnit: yup
    .mixed<RepeatFrequencyUnit>()
    .oneOf(Object.values(RepeatFrequencyUnit))
    .required('Repeat frequency type is required'),
  daysOfMonth: yup.array().of(yup.number().required()).required(),
  daysOfWeek: yup
    .array()
    .of(yup.mixed<DayOfWeekAll>().oneOf(Object.values(DayOfWeekAll)).required())
    .required(),
});
