import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import { getNoonOfDay } from 'shared/date';
import { useArriveAtStopsMutation } from '../../../generated/graphql';
import useMarkStopAsArrivedForm, {
  MarkStopAsArrivedFormValues,
} from '../../form/stops/use-mark-stop-as-arrived-form';
import TimePickerComponent from '../time-picker-component';

export type MarkStopAsArrivedDialogProps = {
  handleClose: (refetch: boolean) => void;
  open: boolean;
  stopUuids: string[];
  routeDate?: Date;
  saveOrder?: () => Promise<boolean>;
  setShowMarkStopAsArrivedSuccessMessage: (show: boolean) => void;
  setShowMarkStopAsArrivedErrorMessage: (show: boolean) => void;
};

const MarkStopAsArrivedDialog = ({
  handleClose,
  open,
  stopUuids,
  routeDate,
  saveOrder,
  setShowMarkStopAsArrivedSuccessMessage,
  setShowMarkStopAsArrivedErrorMessage,
}: MarkStopAsArrivedDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useMarkStopAsArrivedForm({
    context: {
      defaultDate: !isNil(routeDate) ? getNoonOfDay(routeDate) : undefined,
    },
  });

  const [arriveAtStops, { loading: arriveAtStopsLoading }] =
    useArriveAtStopsMutation();

  const onSubmit: SubmitHandler<MarkStopAsArrivedFormValues> = async (data) => {
    try {
      const saveOrderSuccess = !isNil(saveOrder) ? await saveOrder() : true;
      if (saveOrderSuccess) {
        const arriveAtStopsResponse = await arriveAtStops({
          variables: {
            arriveAtStopsInput: {
              arriveAtStopInputs: stopUuids.map((stopUuid) => {
                return {
                  stopUuid,
                  arrivalTime: data.arrivedDate,
                };
              }),
            },
          },
        });

        if (
          arriveAtStopsResponse.data?.arriveAtStops.__typename ===
          'ArriveAtStopsSuccessOutput'
        ) {
          setShowMarkStopAsArrivedSuccessMessage(true);
          handleClose(true);
          reset();
        } else {
          setShowMarkStopAsArrivedErrorMessage(true);
          handleClose(true);
        }
      } else {
        setShowMarkStopAsArrivedErrorMessage(true);
        handleClose(true);
      }
    } catch (e) {
      setShowMarkStopAsArrivedErrorMessage(true);
    }
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      fullWidth
      PaperProps={{
        sx: { maxHeight: 350, minHeight: 350, minWidth: 650, maxWidth: 650 },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Mark Stop as Arrived
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ marginTop: '5px' }}>
          <Controller
            name="arrivedDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack direction="row" spacing={2}>
                <DatePicker
                  onChange={(newDate) => {
                    onChange(newDate);
                  }}
                  renderInput={(props) => <TextField size="small" {...props} />}
                  value={value}
                />
                <TimePickerComponent
                  appointmentTime={value}
                  updateAppointmentTime={(time: Dayjs | undefined | null) => {
                    if (!isNil(time)) {
                      const newTimeString = `${dayjs(value).format(
                        'MM/DD/YYYY',
                      )} ${time.format('hh:mm a')}`;
                      const newTime = dayjs(newTimeString);
                      if (newTime.isValid()) {
                        onChange(newTime.toDate());
                      }
                    }
                  }}
                  hideClearable
                />
                {!isNil(errors.arrivedDate) && (
                  <FormHelperText sx={{ color: '#B00020' }}>
                    {errors.arrivedDate.message}
                  </FormHelperText>
                )}
                {!isNil(routeDate) && typeof value !== 'object' && (
                  <Button
                    onClick={() => {
                      onChange(dayjs(routeDate).startOf('day').toDate());
                    }}
                  >
                    Use Route Date
                  </Button>
                )}
              </Stack>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(2) }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose(false);
            reset();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={arriveAtStopsLoading}
        >
          Mark stop as arrived
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkStopAsArrivedDialog;
