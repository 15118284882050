import {
  alpha,
  Button,
  Menu,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isEmpty, isNil } from 'lodash';
import React, { useState } from 'react';
import {
  FilterConstructionOperatorType,
  FilterConstructionFilterType,
  SingleFilterConstructionType,
} from '../../../common/filters/types';
import {
  filterValueInputToDisplayName,
  getFilterDateObject,
  mapDateFilterOptionToRelativeDateOption,
} from '../../../common/filters/utils';
import {
  DateFilterOptionV2,
  DateFilterValueInput,
} from '../../../generated/graphql';
import { BaseFilterInputInputPropsSx } from './components/filters/filter-utils';
import { relativeFilterDateObjects } from './constants';

export interface OrdersFilterDatePickerProps {
  filterValueInput: DateFilterValueInput;
  filterOperationInput: FilterConstructionOperatorType;
  filterNameInput: FilterConstructionFilterType;
  setFilter: ({ filter, op, value }: SingleFilterConstructionType) => void;
}

const OrdersFilterDatePicker = ({
  filterValueInput,
  filterOperationInput,
  filterNameInput,
  setFilter,
}: OrdersFilterDatePickerProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isStaticDate =
    filterValueInput?.dateFilterOption === DateFilterOptionV2.Static;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function updateFilterValue(
    filterValue: DateFilterOptionV2 | null | undefined,
    offsetDays: number,
  ) {
    if (isNil(filterValue)) {
      return;
    }
    const filterDateObject = getFilterDateObject(
      filterValue as Exclude<DateFilterOptionV2, DateFilterOptionV2.Static>,
      offsetDays,
    );

    setFilter({
      filter: filterNameInput,
      op: filterOperationInput,
      value: filterDateObject,
    });
    handleClose();
  }
  const displayName = filterValueInputToDisplayName(
    filterValueInput,
    filterNameInput,
  );

  return (
    <Stack>
      <Button
        onClick={handleClick}
        sx={{
          ...BaseFilterInputInputPropsSx,
          backgroundColor: 'white',
          height: '34px',
          border: '1px solid #ccc',
          fontWeight: 'normal',
          minWidth: '160px',
          justifyContent: 'flex-start',
          paddingLeft: '10px',
          marginLeft: '-2px',
          color: isEmpty(displayName) ? theme.palette.text.secondary : 'black',
        }}
      >
        {isEmpty(displayName) ? 'Select a value...' : displayName}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          sx={{
            backgroundColor: isStaticDate
              ? alpha(theme.palette.primary.main, 0.08)
              : 'inherit',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.15),
            },
          }}
        >
          <DatePicker
            onChange={(newDate) => {
              const filterDateObject: DateFilterValueInput = {
                date: newDate,
                dateFilterOption: DateFilterOptionV2.Static,
                offsetDays: 0,
              };
              setFilter({
                filter: filterNameInput,
                op: filterOperationInput,
                value: filterDateObject,
              });
            }}
            renderInput={(props) => (
              <TextField
                sx={{ width: 160 }}
                size="small"
                placeholder="Value"
                {...props}
              />
            )}
            value={isStaticDate ? filterValueInput.date : null}
          />
        </MenuItem>
        {Object.values(relativeFilterDateObjects).map((option) => (
          <MenuItem
            key={`${option.dateFilterOption}-${option.offsetDays}`}
            onClick={() => {
              updateFilterValue(option.dateFilterOption, option.offsetDays);
            }}
            sx={{
              backgroundColor:
                !isStaticDate &&
                filterValueInput?.dateFilterOption ===
                  option.dateFilterOption &&
                filterValueInput?.offsetDays === option.offsetDays
                  ? alpha(theme.palette.primary.main, 0.08)
                  : 'inherit',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.15),
              },
            }}
          >
            {mapDateFilterOptionToRelativeDateOption(
              option.dateFilterOption,
              option.offsetDays,
            )}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default OrdersFilterDatePicker;
