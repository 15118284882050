import { Clear } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import useMe from '../../../../../common/react-hooks/use-me';
import { isNilOrEmptyString } from '../../../../../common/utils/utils';
import { getReferenceNumberLabels } from '../../utils';
import { useOrderFormEditAccess } from '../contexts/order-form-edit-access-context';
import { OrderFormValues } from '../forms/types';
import { useOrderFormContact } from '../hooks/use-order-form-contact';

const MINIMIZED_FONT_SIZE = 12;

const ReferenceNumbers = ({
  excludeFormLabel,
  isMinimized = false,
}: {
  excludeFormLabel?: boolean;
  isMinimized?: boolean;
}) => {
  const { setValue, control } = useFormContext<OrderFormValues>();
  const refNumbersWatch = useWatch({ control, name: 'refNumbers' });
  const refNumbers = useMemo(() => refNumbersWatch ?? [], [refNumbersWatch]);
  const secondaryReferenceNumber = useWatch({
    control,
    name: 'secondaryReferenceNumber',
  });
  const tertiaryReferenceNumber = useWatch({
    control,
    name: 'tertiaryReferenceNumber',
  });
  const quaternaryReferenceNumber = useWatch({
    control,
    name: 'quaternaryReferenceNumber',
  });
  const quinaryReferenceNumber = useWatch({
    control,
    name: 'quinaryReferenceNumber',
  });
  const lockSecondaryRefNumber = useWatch({
    control,
    name: 'lockSecondaryReferenceNumber',
  });
  const { useAllCaps, companyData } = useMe();
  const [autoFocusLastField, setAutoFocusLastField] = useState<boolean>(false);

  const { contact } = useOrderFormContact();

  const { disabledIfInvoicePosted } = useOrderFormEditAccess();

  const labels = useMemo(
    () =>
      getReferenceNumberLabels({
        companyReferenceNumberLabels: companyData?.referenceNumberLabels,
        contactReferenceNumberLabels: contact?.referenceNumberLabels,
      }),
    [contact, companyData],
  );

  useEffect(() => {
    // backfill refNumbers in case there are more labels than actual reference numbers
    if (refNumbers.length < labels.length) {
      const diff = labels.length - refNumbers.length;
      const filledRefNumbers = [...refNumbers, ...new Array(diff).fill('')];
      setValue('refNumbers', filledRefNumbers);
    }
  }, [labels, refNumbers, setValue]);

  const showEdiReferenceNumbers = contact?.showEdiReferenceNumbersOnOrderPage;

  const lockedEdiRefNumberTooltip =
    'This reference number is reserved for EDI connection. Add another reference number to track this order.';

  const inputProps: InputBaseComponentProps = {
    style: {
      ...(isMinimized ? { fontSize: MINIMIZED_FONT_SIZE, height: 10 } : {}),
      ...(useAllCaps ? { textTransform: 'uppercase' } : {}),
    },
  };
  const textFieldSx = { flexBasis: '240px' };

  const refNumComponents = [
    // eslint-disable-next-line react/jsx-key
    <FormControl sx={textFieldSx}>
      <Tooltip
        title={
          lockSecondaryRefNumber === true ? (
            <Typography fontSize={15}>{lockedEdiRefNumberTooltip}</Typography>
          ) : (
            ''
          )
        }
      >
        <TextField
          label={
            excludeFormLabel === true
              ? undefined
              : (labels[0] ?? 'Reference Number')
          }
          size="small"
          value={secondaryReferenceNumber ?? ''}
          inputProps={inputProps}
          onChange={(e) => {
            const input = e.target.value;
            const newRefNumbers = filterNotNil([
              input,
              ...(refNumbers?.slice(1) ?? []),
            ]);
            setValue('refNumbers', [input, ...newRefNumbers.slice(1)]);
            setValue('secondaryReferenceNumber', input);
          }}
          disabled={disabledIfInvoicePosted || lockSecondaryRefNumber === true}
        />
      </Tooltip>
    </FormControl>,
  ];

  let refNumOrdinal = refNumComponents.length + 1;
  const ediRefNumbers = [
    tertiaryReferenceNumber,
    quaternaryReferenceNumber,
    quinaryReferenceNumber,
  ].map((refNumber) => {
    if (!isNilOrEmptyString(refNumber) && showEdiReferenceNumbers === true) {
      const element = (
        <FormControl sx={textFieldSx}>
          <Tooltip
            title={
              <Typography fontSize={15}>{lockedEdiRefNumberTooltip}</Typography>
            }
          >
            <TextField
              label={`Reference Number ${refNumOrdinal}`}
              size="small"
              value={refNumber ?? ''}
              inputProps={inputProps}
              disabled
            />
          </Tooltip>
        </FormControl>
      );
      // Only increment if we're actually rendering a TextField
      refNumOrdinal += 1;
      return element;
    }
    return null;
  });

  refNumComponents.push(
    ...ediRefNumbers.filter((comp): comp is React.ReactElement => !isNil(comp)),
  );

  refNumComponents.push(
    ...(refNumbers ?? []).slice(1).map((refNumber, idx) => {
      const element = (
        <FormControl
          // eslint-disable-next-line react/no-array-index-key
          key={refNumOrdinal}
          sx={textFieldSx}
        >
          <TextField
            size="small"
            label={
              !isNilOrEmptyString(labels[idx + 1]?.trim())
                ? labels[idx + 1]
                : `Reference number ${refNumOrdinal}`
            }
            value={refNumber ?? ''}
            onChange={(e) => {
              const input = e.target.value;
              const firstRefNumbers = refNumbers.slice(0, idx + 1);
              const lastRefNumbers = refNumbers.slice(idx + 2);
              setValue('refNumbers', [
                ...firstRefNumbers,
                input,
                ...lastRefNumbers,
              ]);
            }}
            disabled={disabledIfInvoicePosted}
            InputProps={{
              inputProps: {
                ...{
                  autoFocus:
                    autoFocusLastField && idx === refNumbers.length - 2,
                  onFocus: () => {
                    setAutoFocusLastField(false);
                  },
                },
                ...inputProps,
              },
              endAdornment: isNil(labels[idx + 1]) &&
                !disabledIfInvoicePosted && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setValue(
                          'refNumbers',
                          refNumbers?.filter(
                            (itrRef, itrIdx) => itrIdx !== idx + 1,
                          ),
                        );
                      }}
                      disabled={disabledIfInvoicePosted}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
            }}
          />
        </FormControl>
      );
      refNumOrdinal += 1;
      return element;
    }),
  );

  return (
    <>
      {refNumComponents}
      <Button
        onClick={(evt) => {
          (evt.target as HTMLButtonElement).blur();
          setValue('refNumbers', [...(refNumbers ?? []), '']);
          setAutoFocusLastField(true);
        }}
        disabled={disabledIfInvoicePosted}
        startIcon={<AddIcon />}
      >
        Ref number
      </Button>
    </>
  );
};

export default React.memo(ReferenceNumbers);
