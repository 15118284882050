import { FormGroup, Slider, Stack, Typography } from '@mui/material';
import React from 'react';

const StopsToDisplaySlider = ({
  numberOfStops,
  onChange,
  onBlur,
}: {
  numberOfStops: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
}) => {
  const marks = [
    ...Array.from({ length: 26 }, (_, i) => ({
      value: i,
      label: i % 5 === 0 ? i : undefined,
    })),
    ...[{ value: 50, label: 50 }],
  ];
  return (
    <Stack>
      <FormGroup sx={{ width: '100%' }}>
        <Typography variant="body2">Stops To Display</Typography>
        <Slider
          size="small"
          max={50}
          step={null}
          valueLabelDisplay="auto"
          marks={marks}
          value={numberOfStops}
          onChangeCommitted={onBlur}
          onChange={(e, newValue) => {
            if (typeof newValue === 'number') {
              onChange(newValue);
            }
          }}
        />
      </FormGroup>
    </Stack>
  );
};

export { StopsToDisplaySlider };
