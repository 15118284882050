import { Button, Fade, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { memo, useState } from 'react';
import useMe from '../../../../../../common/react-hooks/use-me';
import { ApiLogFragment } from '../../../../../../generated/graphql';
import EmailTransactionStatusChip from './email-transaction-status-chip';
import InvoiceEmailTransactionLogStatuses from './invoice-email-transaction-log-statuses';

const InvoiceEmailRow = ({
  invoiceUuid,
  apiLog,
}: {
  invoiceUuid: string | undefined;
  apiLog: ApiLogFragment;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { companyConfiguration } = useMe();
  const { emailTransaction } = apiLog;
  const documentUrl = emailTransaction?.document.preSignedGetUrl;

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <TableCell>
        {dayjs(apiLog.createdAt).format('MM/DD/YY hh:mm a')}{' '}
      </TableCell>
      {isNil(invoiceUuid) && (
        <TableCell>{emailTransaction?.contact.displayName}</TableCell>
      )}
      {isNil(invoiceUuid) && (
        <TableCell>{emailTransaction?.invoice?.name}</TableCell>
      )}
      {isNil(invoiceUuid) &&
        companyConfiguration?.useJournalNumberForInvoice === true && (
          <TableCell>{emailTransaction?.invoice?.journalNumber}</TableCell>
        )}
      <TableCell align="center">
        {!isNil(emailTransaction) && (
          <EmailTransactionStatusChip
            status={emailTransaction.emailTransactionStatus}
          />
        )}
      </TableCell>
      <TableCell>
        <InvoiceEmailTransactionLogStatuses
          emailTransactionLogs={emailTransaction?.emailTransactionLogs ?? []}
        />
      </TableCell>
      <TableCell>
        {!isNil(documentUrl) && (
          <Fade in={isHovered} timeout={100}>
            <Button variant="contained" size="small">
              <a href={documentUrl} target="_blank" rel="noreferrer">
                View
              </a>
            </Button>
          </Fade>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(InvoiceEmailRow);
