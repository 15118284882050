import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import { AgGridReact } from 'ag-grid-react';
import React, { RefObject } from 'react';
import {
  FormattedOrderFragment,
  OrderTableField,
} from '../../../../../generated/graphql';
import IconButtonWithPopover from '../IconButtonWithPopover';
import SortPopover from './sort-popover';

interface SortButtonProps {
  gridRef: RefObject<AgGridReact<FormattedOrderFragment>>;
  orderTableFields: OrderTableField[];
  hasSorts: boolean;
  hasChanges: boolean;
}

const SortButton = ({
  gridRef,
  orderTableFields,
  hasSorts,
  hasChanges,
}: SortButtonProps) => {
  return (
    <IconButtonWithPopover
      icon={<SwapVertRoundedIcon />}
      shouldHighlight={hasSorts}
      showBadge={hasChanges}
      render={(handleIconPopoverClose) => (
        <SortPopover
          onClose={handleIconPopoverClose}
          orderTableFields={orderTableFields}
          gridRef={gridRef}
        />
      )}
    />
  );
};

export default SortButton;
