import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, TableCell, TableRow } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { LinehaulDispatchTableField } from '../../../../generated/graphql';
import { getLineHaulOrdersTableFieldCopy } from '../../utils';

const AvailableFieldRow = ({
  field,
  setShowCurrentlyShowingFields,
  setShowAvailableFieldsToShow,
}: {
  field: LinehaulDispatchTableField;
  setShowCurrentlyShowingFields: Dispatch<
    SetStateAction<LinehaulDispatchTableField[]>
  >;
  setShowAvailableFieldsToShow: Dispatch<
    SetStateAction<LinehaulDispatchTableField[]>
  >;
}) => {
  return (
    <TableRow>
      <TableCell>{getLineHaulOrdersTableFieldCopy(field)}</TableCell>
      <TableCell>
        <Button
          onClick={() => {
            setShowCurrentlyShowingFields((prevState) => [...prevState, field]);
            setShowAvailableFieldsToShow((prevState) =>
              prevState.filter((itrField) => itrField !== field),
            );
          }}
        >
          <ArrowForwardIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AvailableFieldRow;
