import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import CenteredCircularProgress from '../../../../../../common/components/centered-circular-progress';
import useWindowDimensions from '../../../../../../common/react-hooks/use-window-dimensions';
import {
  PageInfo,
  useInvoiceEdiTransactionsLazyQuery,
} from '../../../../../../generated/graphql';
import EdiInvoiceRow from './edi-invoice-row';

const DEFAULT_EDI_TRANSACTION_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export type EdiInvoicesTableProps = {
  invoiceUuid?: string | undefined;
};
const EdiInvoicesTable = ({ invoiceUuid }: EdiInvoicesTableProps) => {
  const { height } = useWindowDimensions();
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DEFAULT_EDI_TRANSACTION_PAGE_SIZE,
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>();

  const [
    getInvoiceEdiTransactions,
    {
      data: invoiceEdiTransactionsData,
      loading: invoiceEdiTransactionsLoading,
    },
  ] = useInvoiceEdiTransactionsLazyQuery();

  const fetchInvoiceEmailTransactions = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    if (isNilOrEmptyString(invoiceUuid)) {
      return;
    }

    const res = await getInvoiceEdiTransactions({
      variables: {
        findInvoiceEdiTransactionsInput: {
          first,
          after,
          last,
          before,
          includeCount: true,
          invoiceUuid,
        },
      },
    });
    setPageInfo(
      res.data?.invoiceEdiTransactions?.ediTransactionConnection.pageInfo,
    );
    setTotalCount(
      res.data?.invoiceEdiTransactions?.ediTransactionConnection?.totalCount ??
        0,
    );
  };

  const refresh = () => {
    fetchInvoiceEmailTransactions({
      first: rowsPerPage,
    });
    setPage(0);
  };

  const prev = async (newPage: number) => {
    await fetchInvoiceEmailTransactions({
      last: rowsPerPage,
      before: pageInfo?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchInvoiceEmailTransactions({
      first: rowsPerPage,
      after: pageInfo?.endCursor ?? undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceUuid, rowsPerPage]);

  if (invoiceEdiTransactionsLoading === true) {
    return <CenteredCircularProgress />;
  }

  if (
    isEmpty(
      invoiceEdiTransactionsData?.invoiceEdiTransactions
        ?.ediTransactionConnection?.edges,
    )
  ) {
    return null;
  }

  return (
    <TableContainer
      sx={{
        maxHeight: height - 305,
        overflowY: 'scroll',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
      >
        <Grid item xs={12}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 'bold' }}>EDI Invoices</Typography>
            <TablePagination
              labelRowsPerPage="Show"
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage: number) => {
                if (newPage > page) {
                  next(newPage);
                } else {
                  prev(newPage);
                }
              }}
              backIconButtonProps={{
                disabled: invoiceEdiTransactionsLoading || page === 0,
              }}
              nextIconButtonProps={{
                disabled:
                  invoiceEdiTransactionsLoading ||
                  page + 1 === Math.ceil(totalCount / rowsPerPage),
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value);
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Table stickyHeader aria-label="invoice-preview-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Sent At</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Order Name</TableCell>
            <TableCell>Shipper Bill of Lading # </TableCell>
            <TableCell>Reference #</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceEdiTransactionsData?.invoiceEdiTransactions?.ediTransactionConnection.edges.map(
            ({ node: ediTransaction }) => (
              <EdiInvoiceRow
                key={ediTransaction.createdAt}
                ediTransaction={ediTransaction}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EdiInvoicesTable;
