import dayjs, { Dayjs } from 'dayjs';
import React, { createContext, Dispatch, SetStateAction } from 'react';
import {
  DocumentFragment,
  DocumentType,
  FindCompanyDocumentStatusFilter,
  ScannedOrderResultWithOrdersFragment,
} from '../../generated/graphql';

export type DocumentUuidToDocumentType = { [uuid: string]: DocumentType };

interface EndOfDayContextProps {
  documentStatusFilter: FindCompanyDocumentStatusFilter;
  setDocumentStatusFilter: Dispatch<
    React.SetStateAction<FindCompanyDocumentStatusFilter>
  >;

  scannedPodResults: ScannedOrderResultWithOrdersFragment[];
  setScannedPodResults: Dispatch<
    React.SetStateAction<ScannedOrderResultWithOrdersFragment[]>
  >;
  companyDocuments: DocumentFragment[];
  setCompanyDocuments: Dispatch<React.SetStateAction<DocumentFragment[]>>;
  shouldLoadDocuments: boolean;
  setShouldLoadDocuments: Dispatch<React.SetStateAction<boolean>>;
  selectedDocumentUuids: string[];
  setSelectedDocumentUuids: Dispatch<React.SetStateAction<string[]>>;
  documentUuidToDocumentType: DocumentUuidToDocumentType;
  setDocumentUuidToDocumentType: Dispatch<
    SetStateAction<DocumentUuidToDocumentType>
  >;
  shouldLoadRoutes: boolean;
  setShouldLoadRoutes: Dispatch<React.SetStateAction<boolean>>;

  selectedOrderToMatchUuid: string | undefined;
  setSelectedOrderToMatchUuid: Dispatch<
    React.SetStateAction<string | undefined>
  >;

  selectedMatchedOrderUuid: string | undefined;
  setSelectedMatchedOrderUuid: Dispatch<
    React.SetStateAction<string | undefined>
  >;
  selectedRouteUuid: string | undefined;
  setSelectedRouteUuid: Dispatch<React.SetStateAction<string | undefined>>;

  completedStopsNotOnRoutesSelected: boolean;

  setCompletedStopsNotOnRoutesSelected: Dispatch<React.SetStateAction<boolean>>;
  selectedStopUuid: string | undefined;
  setSelectedStopUuid: Dispatch<React.SetStateAction<string | undefined>>;
  currentDate: Dayjs;
  setCurrentDate: Dispatch<React.SetStateAction<Dayjs>>;
  isReloading: boolean;
  setIsReloading: Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
}

export default createContext<EndOfDayContextProps>({
  documentStatusFilter: FindCompanyDocumentStatusFilter.All,
  setDocumentStatusFilter: () => {},
  scannedPodResults: [],
  setScannedPodResults: () => {},
  companyDocuments: [],
  setCompanyDocuments: () => {},
  shouldLoadDocuments: false,
  setShouldLoadDocuments: () => {},
  selectedDocumentUuids: [],
  setSelectedDocumentUuids: () => {},
  documentUuidToDocumentType: {},
  setDocumentUuidToDocumentType: () => {},
  shouldLoadRoutes: false,
  setShouldLoadRoutes: () => {},
  selectedOrderToMatchUuid: undefined,
  setSelectedOrderToMatchUuid: () => {},
  selectedMatchedOrderUuid: undefined,
  setSelectedMatchedOrderUuid: () => {},
  selectedRouteUuid: undefined,
  setSelectedRouteUuid: () => {},
  completedStopsNotOnRoutesSelected: false,
  setCompletedStopsNotOnRoutesSelected: () => {},
  selectedStopUuid: undefined,
  setSelectedStopUuid: () => {},
  currentDate: dayjs(),
  setCurrentDate: () => {},
  isReloading: false,
  setIsReloading: () => {},
  isLoading: false,
  setIsLoading: () => {},
});
