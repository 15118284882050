import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import QRCode from '../../../../../common/components/pdf/qr-code';
import {
  FindStorageOrderStorageUnitsFragment,
  StorageOrderFragment,
  StorageOrderStorageUnitFragment,
} from '../../../../../generated/graphql';

interface StorageUnitLabelsPdfProps {
  storageOrder: StorageOrderFragment;
  storageUnits:
    | StorageOrderStorageUnitFragment[]
    | FindStorageOrderStorageUnitsFragment[];
}
const StorageUnitLabelsPdf = ({
  storageOrder,
  storageUnits,
}: StorageUnitLabelsPdfProps) => {
  const STORAGE_UNIT_NAME_THRESHOLD = 12;

  const styles = StyleSheet.create({
    page: {
      padding: 4,
      fontFamily: 'Roboto',
      fontSize: '10px',
      flexDirection: 'row',
    },
    titleBold: {
      fontSize: '48px',
      fontWeight: 'bold',
    },
    smallTitleBold: {
      fontSize: '36px',
      fontWeight: 'bold',
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subHeaderTextSize: {
      fontSize: '10px',
    },
    subHeaderText: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    outerCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '3px',
    },
    innerRow: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    detailsColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      padding: 16,
    },
  });

  return (
    <Document>
      {storageUnits.map((storageUnit) => {
        return (
          <Page
            key={storageUnit.uuid}
            size="A6"
            orientation="landscape"
            style={styles.page}
          >
            <View style={styles.outerCell}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={
                    storageUnit.name.length > STORAGE_UNIT_NAME_THRESHOLD
                      ? styles.smallTitleBold
                      : styles.titleBold
                  }
                >
                  {storageUnit.name}
                </Text>
              </View>
              <View style={styles.innerRow}>
                <View style={styles.detailsColumn}>
                  <View>
                    <Text
                      style={styles.headerText}
                    >{`Order ref#: ${storageOrder.referenceNumber}`}</Text>
                  </View>
                  <View>
                    <Text style={styles.headerText}>{`UoM: ${sentenceCase(
                      storageUnit.unitOfMeasure,
                    )}`}</Text>
                  </View>
                  {!isNil(storageOrder.arrivedAt) && (
                    <View>
                      <Text style={styles.headerText}>
                        Arrived on:{' '}
                        {dayjs(storageOrder.arrivedAt).format('MM/DD/YY')}
                      </Text>
                    </View>
                  )}
                  {!isEmpty(storageUnit.items) && (
                    <View style={{ marginVertical: 8 }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View>
                          <Text style={styles.subHeaderText}>SKU</Text>
                        </View>
                        <View>
                          <Text style={styles.subHeaderText}>Quantity</Text>
                        </View>
                      </View>
                      {storageUnit.items.map((item) => (
                        <View
                          key={item.item.uuid}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <View>
                            <Text style={styles.subHeaderTextSize}>
                              {item.item.sku}
                            </Text>
                          </View>
                          <View>
                            <Text style={styles.subHeaderTextSize}>
                              {item.assignedQuantity}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  )}
                  <View>
                    <Text>
                      Printed at: {dayjs().format('MM/DD/YY HH:MM a')}
                    </Text>
                  </View>
                </View>
                <QRCode value={storageUnit.name} width="40%" />
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default StorageUnitLabelsPdf;
