import { isNil } from 'lodash';
import { transformDateToDateString } from '../../../../common/utils/prettyPrintUtils';
import { StopForUnifiedSearchFragment } from '../../../../generated/graphql';

export const getRouteString = ({
  startStop,
  endStop,
}: {
  startStop: StopForUnifiedSearchFragment | null | undefined;
  endStop: StopForUnifiedSearchFragment | null | undefined;
}) => {
  const outboundRoute = endStop?.routeSlot?.route;
  const inboundRoute = startStop?.routeSlot?.route;
  let routeString = '';
  if (isNil(outboundRoute) && isNil(inboundRoute)) {
    routeString = '-';
  } else if (isNil(startStop?.completedAt)) {
    const route = inboundRoute ?? outboundRoute;
    routeString = `${route?.name ?? ''} on ${transformDateToDateString(route?.date)}`;
  } else {
    const route = outboundRoute ?? inboundRoute;
    routeString = `${route?.name ?? ''} on ${transformDateToDateString(route?.date)}`;
  }
  return routeString;
};
