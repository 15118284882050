import {
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import { Option } from '../../../../../common/filters/types';
import useWarehouses from '../../../../../common/react-hooks/use-warehouses';
import useWindowDimensions from '../../../../../common/react-hooks/use-window-dimensions';
import {
  FilterOperator,
  FindItemGroupsFragment,
  ItemGroupQuantityFilterType,
  ItemGroupsQueryVariables,
  StringFilterType,
  useItemGroupsLazyQuery,
  useWmsContactsQuery,
} from '../../../../../generated/graphql';
import useCustomerPortalStore from '../../../../customer-portal/use-customer-portal-store';
import { PicklistFormValues } from '../../../forms/storage-units/use-picklist-form';
import WarehouseSelector from '../warehouse-selector';

const DEFAULT_ROWS_PER_PAGE = 10;

const PickListItemGroupsTable = () => {
  const { height } = useWindowDimensions();
  const [getItemGroups, { data: itemGroupsData }] = useItemGroupsLazyQuery();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 500);
  const [page, setPage] = React.useState(0);
  const [searching, setSearching] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<Option>();
  const [selectedWarehouse, setSelectedWarehouse] = useState<Option | null>(
    null,
  );

  const customerPortalCompany = useCustomerPortalStore(
    ({ company }) => company,
  );
  const { data: wmsContacts } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      companyUuid: customerPortalCompany?.uuid,
    },
  });
  const { warehouses } = useWarehouses();

  const { watch, setValue, getValues } = useFormContext<PicklistFormValues>();
  const pickedItemGroups = watch('pickedItemGroups');

  const itemGroupsRemovedFromPicklist = watch('itemGroupsRemovedFromPicklist');
  const itemGroups = useMemo(() => {
    return (
      itemGroupsData?.itemGroups?.edges.map(({ node }) => {
        const itemGroupRemovedFromPicklist = itemGroupsRemovedFromPicklist.find(
          ({ uuid }) => uuid === node.uuid,
        );
        return {
          ...node,
          quantity:
            node.quantity + (itemGroupRemovedFromPicklist?.quantity ?? 0),
        };
      }) ?? []
    );
  }, [itemGroupsData, itemGroupsRemovedFromPicklist]);

  const fetchItemGroups = async ({
    first = DEFAULT_ROWS_PER_PAGE,
    after,
    last,
    before,
    companyUuid = customerPortalCompany?.uuid,
    contactUuid = selectedContact?.value,
    warehouseUuid = selectedWarehouse?.value,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    companyUuid?: string | null | undefined;
    contactUuid?: string | null | undefined;
    warehouseUuid?: string | null | undefined;
  } = {}) => {
    setSearching(true);
    const variables: ItemGroupsQueryVariables = {
      findItemGroupsInput: {
        first,
        after,
        last,
        before,
        searchText,
        quantityFilter: {
          type: ItemGroupQuantityFilterType.NotZero,
          overrideUUIDs: getValues('itemGroupsRemovedFromPicklist').map(
            ({ uuid }) => uuid,
          ),
        },
        companyUuidFilter: !isNil(companyUuid)
          ? {
              filterType: StringFilterType.Equals,
              filterOperator: FilterOperator.And,
              value: companyUuid,
            }
          : undefined,
        contactUuidFilter: !isNil(contactUuid)
          ? {
              filterType: StringFilterType.Equals,
              filterOperator: FilterOperator.And,
              value: contactUuid,
            }
          : undefined,
        warehouseUuidFilter: !isNil(warehouseUuid)
          ? {
              filterType: StringFilterType.Equals,
              filterOperator: FilterOperator.And,
              value: warehouseUuid,
            }
          : undefined,
      },
    };
    await getItemGroups({
      variables,
    });
    setSearching(false);
  };

  useEffect(() => {
    fetchItemGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, selectedContact]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (newPage > page) {
      await fetchItemGroups({
        first: DEFAULT_ROWS_PER_PAGE,
        after: itemGroupsData?.itemGroups?.pageInfo.endCursor,
      });
    } else {
      await fetchItemGroups({
        last: DEFAULT_ROWS_PER_PAGE,
        before: itemGroupsData?.itemGroups?.pageInfo.startCursor,
      });
    }
    setPage(newPage);
  };

  const handleAddItemGroupToPicklist = (itemGroup: FindItemGroupsFragment) => {
    setValue('pickedItemGroups', [...pickedItemGroups, itemGroup]);
    const newItemGroupsRemovedFromPicklist =
      itemGroupsRemovedFromPicklist.filter(
        ({ uuid }) => uuid !== itemGroup.uuid,
      );
    setValue('itemGroupsRemovedFromPicklist', newItemGroupsRemovedFromPicklist);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          size="small"
          label="Search containers"
          InputProps={{ style: { backgroundColor: 'white' } }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              fetchItemGroups();
            }
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          sx={{ flex: 1 }}
        />
        {searching && <CircularProgress size={20} />}
        <WarehouseSelector
          value={selectedWarehouse}
          onChange={(option) => {
            setSelectedWarehouse(option);
            fetchItemGroups({ warehouseUuid: option?.value ?? null });
          }}
          width={175}
          cacheId="edit-pick-tickets"
        />
        {(wmsContacts?.wmsContacts.length ?? 0) > 1 && (
          <CustomerFilterButton
            cacheId="create-pick-list"
            contactsOverride={wmsContacts?.wmsContacts ?? []}
            isSmall
            selectedOption={selectedContact}
            handleChange={(option) => {
              setSelectedContact(option);
              fetchItemGroups({ contactUuid: option?.value });
            }}
          />
        )}
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
          component="div"
          count={itemGroupsData?.itemGroups.totalCount ?? 0}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
          page={page}
          onPageChange={handleChangePage}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          style={{ height: height - 350, overflow: 'auto' }}
        >
          <Table>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
              }}
            >
              <TableRow>
                <TableCell>Item SKU</TableCell>
                <TableCell>Item description</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Container name</TableCell>
                <TableCell>Receipt reference</TableCell>
                <TableCell>PO number</TableCell>
                <TableCell>Lot number</TableCell>
                {(warehouses?.length ?? 0) > 1 && (
                  <TableCell>Warehouse</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {itemGroups.map((itemGroup) => {
                const isAddedToPicklist =
                  pickedItemGroups.find(
                    (pickedItemGroup) =>
                      pickedItemGroup.uuid === itemGroup.uuid,
                  ) != null;
                return (
                  <Tooltip
                    key={itemGroup.uuid}
                    arrow
                    title={isAddedToPicklist ? '' : 'Add to picklist'}
                    followCursor
                  >
                    <TableRow
                      hover={!isAddedToPicklist}
                      onClick={() => {
                        if (!isAddedToPicklist)
                          handleAddItemGroupToPicklist(itemGroup);
                      }}
                      sx={{
                        cursor: !isAddedToPicklist ? 'pointer' : 'not-allowed',
                      }}
                    >
                      <TableCell>{itemGroup.item.sku}</TableCell>
                      <TableCell>{itemGroup.item.description}</TableCell>
                      <TableCell>
                        {itemGroup.storageUnit?.warehouseLocation?.name}
                      </TableCell>
                      <TableCell>{itemGroup.quantity}</TableCell>
                      <TableCell>
                        {itemGroup.storageOrder.contact.displayName}
                      </TableCell>
                      <TableCell>
                        {itemGroup.storageUnit?.name ?? '-'}
                      </TableCell>
                      <TableCell>
                        {itemGroup.storageOrder.referenceNumber}
                      </TableCell>
                      <TableCell>
                        {itemGroup.storageOrder.purchaseOrderNumber ?? '-'}
                      </TableCell>
                      <TableCell>
                        {itemGroup.storageOrder.lotNumber ?? '-'}
                      </TableCell>
                      {(warehouses?.length ?? 0) > 1 && (
                        <TableCell>
                          {itemGroup.storageOrder.warehouse.name ?? '-'}
                        </TableCell>
                      )}
                    </TableRow>
                  </Tooltip>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PickListItemGroupsTable;
