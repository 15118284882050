import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { Dictionary, groupBy, isEmpty, isNil, size } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';
import { StopBySearchTextFragment } from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import StopStatusChip from '../../../dispatch/routes/components/stop-status-chip';

const SettlementStatusChip = ({ finalized }: { finalized: boolean }) => {
  let color;
  let backgroundColor;
  let fontWeight;
  let label = 'Unknown';
  if (finalized) {
    label = 'FINALIZED';
    color = green['900'];
    backgroundColor = '#BBF7D0';
  } else {
    label = 'UNFINALIZED';
    backgroundColor = grey['50'];
    fontWeight = 400;
  }
  return (
    <Chip
      size="small"
      variant="outlined"
      sx={{
        height: 16,
        fontSize: '12px',
        fontWeight: fontWeight ?? '500',
        borderColor: color,
        color,
        backgroundColor,
      }}
      label={label}
    />
  );
};

const StopsBySearchTextDialog = ({
  matchingStops,
  onClose,
}: {
  matchingStops: StopBySearchTextFragment[];
  onClose: () => void;
}) => {
  const [setOpenedOrderUuid] = useGlobalStore((state) => [
    state.setCurrentOrderUuid,
  ]);

  const orders: Dictionary<StopBySearchTextFragment[]> = groupBy(
    matchingStops,
    'shipment.order.uuid',
  );
  const numOrders = size(orders);
  return (
    <Dialog
      open={!isEmpty(matchingStops)}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Found {numOrders}
        {numOrders >= 10 ? '+' : ''} other matching{' '}
        {pluralize('order', numOrders)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={1}>
            {Object.entries(orders).map(([orderUuid, stops]) => {
              const order = stops.at(0)?.shipment?.order;
              return (
                <Card
                  variant="outlined"
                  sx={{
                    width: '100%',
                    borderWidth: 2,
                  }}
                  key={orderUuid}
                >
                  <Grid container p={1}>
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={2}>
                          <Typography fontWeight="bold">Name</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>{order?.name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography fontWeight="bold">HAWB</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>
                            {
                              order?.standardOrderFields
                                .shipperBillOfLadingNumber
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography fontWeight="bold">Ref Nos.</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>
                            {order?.refNumbers.join(', ')}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography fontWeight="bold">Status</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>{order?.detailedStatus}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenedOrderUuid(order?.uuid);
                        }}
                      >
                        Open
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        {stops.map((stop) => {
                          return (
                            <Card
                              key={stop.uuid}
                              variant="outlined"
                              sx={{
                                width: '100%',
                                borderWidth: 1,
                              }}
                            >
                              <Stack sx={{ py: '3px', px: '8px' }}>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                      {sentenceCase(
                                        stop.shipment?.standardShipmentFields
                                          ?.pickupOrDelivery ?? '',
                                      )}
                                    </Typography>
                                    <Typography>
                                      {stop.isAttempt === true
                                        ? ' (Attempt)'
                                        : ''}
                                    </Typography>
                                  </Stack>
                                  <StopStatusChip
                                    status={stop.status}
                                    refusedBy={order?.refusedBy}
                                  />
                                </Stack>
                                <Stack>
                                  {stop.stopDriverMaps.length > 0 ? (
                                    stop.stopDriverMaps.map((stopDriverMap) => {
                                      return (
                                        <Card
                                          key={stopDriverMap.uuid}
                                          variant="outlined"
                                          sx={{
                                            width: '100%',
                                            borderWidth: 0,
                                            borderTopWidth: 1,
                                            borderRadius: 0,
                                            p: '2px',
                                          }}
                                        >
                                          <Grid container>
                                            <Grid
                                              item
                                              xs={12}
                                              display="flex"
                                              flexDirection="row"
                                              justifyContent="space-between"
                                            >
                                              <Typography
                                                variant="caption"
                                                sx={{ fontWeight: 'bold' }}
                                              >
                                                {!isNil(stopDriverMap.driver)
                                                  ? `${stopDriverMap.driver.firstName} ${stopDriverMap.driver.lastName}`
                                                  : '-'}
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                color="gray"
                                              >
                                                {isNil(
                                                  stopDriverMap.driverSettlementBill,
                                                )
                                                  ? 'No settlement'
                                                  : ''}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              display="flex"
                                              flexDirection="row"
                                              justifyContent="space-between"
                                            >
                                              <Typography variant="caption">
                                                {!isNil(
                                                  stopDriverMap.driverSettlementBill,
                                                )
                                                  ? `Settlement: ${
                                                      stopDriverMap
                                                        .driverSettlementBill
                                                        .name
                                                    } (${
                                                      !isNil(
                                                        stopDriverMap
                                                          .driverSettlementBill
                                                          .settlementDate,
                                                      )
                                                        ? dayjs(
                                                            stopDriverMap
                                                              .driverSettlementBill
                                                              .settlementDate,
                                                          ).format('MM/DD/YY')
                                                        : 'No date'
                                                    })`
                                                  : ''}
                                              </Typography>
                                              {!isNil(
                                                stopDriverMap.driverSettlementBill,
                                              ) && (
                                                <SettlementStatusChip
                                                  finalized={
                                                    !isNil(
                                                      stopDriverMap
                                                        .driverSettlementBill
                                                        ?.finalizedDate,
                                                    )
                                                  }
                                                />
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Card>
                                      );
                                    })
                                  ) : (
                                    <Typography variant="caption" color="gray">
                                      No drivers
                                    </Typography>
                                  )}
                                </Stack>
                              </Stack>
                            </Card>
                          );
                        })}
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              );
            })}
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default StopsBySearchTextDialog;
