import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getNoonOfDay } from 'shared/date';
import {
  BaseFilterInputInputPropsSx,
  BaseFilterInputProps,
} from '../orders/components/filters/filter-utils';

const {
  backgroundColor: baseFilterInputInputPropsBackgroundColor,
  ...restBaseFilterInputInputPropsSx
} = BaseFilterInputInputPropsSx;

const FilterDatePickerInput = ({
  filterValueInput,
  filterOperationInput,
  filterNameInput,
  setFilter,
}: BaseFilterInputProps) => {
  return (
    <DatePicker
      onChange={(newDate) => {
        setFilter({
          filter: filterNameInput,
          op: filterOperationInput,
          value: getNoonOfDay(newDate as Date),
        });
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            width: 160,
            '& fieldset': {
              // If we use the solid background color in the fieldset
              // it obscures the text/icon in the input
              ...restBaseFilterInputInputPropsSx,
            },
            backgroundColor: baseFilterInputInputPropsBackgroundColor,
            marginLeft: '-2px',
          }}
          size="small"
          placeholder="Value"
          {...params}
        />
      )}
      value={filterValueInput}
    />
  );
};

export default FilterDatePickerInput;
