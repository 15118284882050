import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { StandardOrderValues } from '../../../redux/standard/standard-orders-values-slice';

interface MergeEdiOrdersModalProps {
  open: boolean;
  onClose: () => void;
  orders: StandardOrderValues[];
  handleMerge: (orderToMerge: StandardOrderValues) => void;
  handleCreateDuplicate: (orderToDuplicate: StandardOrderValues) => void;
  // handleDiscardChanges?: () => void;
}

const MergeEdiOrdersModal: React.FC<MergeEdiOrdersModalProps> = ({
  open,
  onClose,
  orders,
  handleMerge,
  handleCreateDuplicate,
}: // handleDiscardChanges,
MergeEdiOrdersModalProps) => {
  //   const dispatch = useAppDispatch();

  if (orders.length === 0) {
    onClose();
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          textAlign: 'left',
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {' '}
        Accepting orders with duplicates {'   '}
        {/* <WarningAmberIcon sx={{ ml: '10px' }} fontSize="medium" /> */}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <Typography fontStyle="italic">
              The following orders share a HAWB with an order(s) already in the
              system. You can merge (existing orders with the same HAWB will get
              updated with the information in this order) or create a new
              duplicate order.
            </Typography>
          </Grid>
          {orders.map((order) => (
            <Grid
              key={order.uuid}
              item
              xs={12}
              spacing={5}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pl="20px"
                pr="20px"
              >
                <Typography fontWeight={700}>{order.name}</Typography>
                <Stack
                  direction="row"
                  gap="10px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    onClick={() => {
                      handleMerge(order);
                    }}
                    variant="contained"
                  >
                    Merge
                  </Button>
                  <Button
                    onClick={() => {
                      handleCreateDuplicate(order);
                    }}
                    variant="outlined"
                  >
                    Create duplicate
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeEdiOrdersModal;
