import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useAccessorialsQuery } from '../../generated/graphql';

import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import useStyles from './general-styles';

const CACHE_PREFIX = 'ACCESSORIAL_FILTER';

export type AccessorialOption = {
  value: string | undefined;
  label: string | undefined;
};

interface AccessorialFilterButtonProps {
  cacheId?: string;
  selectedOption: AccessorialOption | undefined;
  prefixText: string;
  handleChange: (option: AccessorialOption | undefined) => void;
  allowSelectAll?: boolean;
}

const AccessorialFilterButton = ({
  cacheId,
  prefixText,
  selectedOption,
  handleChange,
  allowSelectAll = true,
}: AccessorialFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;
  const { data: globalAccessorials } = useAccessorialsQuery({
    variables: {
      onlyShowCompanyAccessorials: true,
    },
  });

  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedLabel = localStorage.getItem(
        `${CACHE_PREFIX}_LABEL_${cacheId}`,
      );
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedLabel) && !isNil(cachedValue)) {
        handleChange({
          label: cachedLabel,
          value: cachedValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (option: AccessorialOption | null | undefined) => {
    if (!isNil(cacheId)) {
      if (!isNil(option) && !isNil(option.label) && !isNil(option.value)) {
        localStorage.setItem(`${CACHE_PREFIX}_LABEL_${cacheId}`, option.label);
        localStorage.setItem(`${CACHE_PREFIX}_VALUE_${cacheId}`, option.value);
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_LABEL_${cacheId}`);
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      }
    }
    handleChange(option ?? undefined);
  };

  const options =
    globalAccessorials?.accessorials
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((accessorial) => ({
        label: accessorial.name,
        value: accessorial.uuid,
      })) ?? [];

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{prefixText}:</Typography>
          <Typography sx={styles.filterValue}>
            {selectedOption?.label ?? (allowSelectAll ? 'All' : 'Select one')}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="customer-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          {allowSelectAll && (
            <MenuItem
              key="all"
              onClick={() => onChange(undefined)}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  sx={{
                    visibility:
                      selectedOption === undefined ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>All</Typography>
              </Stack>
            </MenuItem>
          )}
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: !isAllSelected ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                sx={{ backgroundColor: 'white', width: '250px' }}
                value={selectedOption}
                options={options}
                matchSortOptions={{ keys: ['label'] }}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    size="small"
                  />
                )}
                onChange={(_, option) => {
                  onChange(option);
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default AccessorialFilterButton;
