import * as yup from 'yup';
import { freightChargeSchema } from './freight-charge-schema';

export const lineHaulShipmentSchema = yup
  .object({
    uuid: yup.string().required(),
    freightCharge: freightChargeSchema.optional().nullable(),
    isLocal: yup.boolean().required(),
    // TODO: This is poorly named. It should be called lineHaulCompletedDate
    dateForFuelCharge: yup.date().nullable(),
    totalCharge: yup.number().optional().nullable(),
    invoiceUuid: yup.string().nullable().optional(),
    invoiceName: yup.string().nullable().optional(),
    invoiceDate: yup.date().nullable().optional(),
    invoicePostedDate: yup.date().nullable().optional(),
  })
  .required();
