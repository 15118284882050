import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import {
  OrderDocumentType,
  useShallowContactLazyQuery,
} from '../../../../../../../generated/graphql';
import GeneratedQuoteAirWaybillPdf from '../../../../../../generated-documents/components/generated-quote-air-waybill-pdf';
import {
  convertOrderFormValuesToAirWaybillData,
  OrderAirWaybillData,
} from '../../../../../../generated-documents/utils';
import EmailOrderDocuments from './email-order-documents-component';

const PrintQuoteAirWaybillModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { companyData } = useMe();
  const { getServiceName } = useServices();
  const { getTerminalCode, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [getContact, { data: contactData }] = useShallowContactLazyQuery();
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();

  useEffect(() => {
    if (!isNil(order.contactUuid)) {
      getContact({
        variables: {
          uuid: order.contactUuid,
        },
      });
    }
  }, [order.contactUuid, getContact]);

  const orderForAirWaybill: OrderAirWaybillData = useMemo(
    () =>
      convertOrderFormValuesToAirWaybillData({
        order,
        getTerminalCode,
        getServiceName,
        contact: contactData?.contact,
        companyName: companyData?.name,
        companyAddress: companyData?.defaultAddress,
        companyPhone: companyData?.phone,
        companyInvoiceEmail: companyData?.invoiceEmail,
        terminalsEnabled,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, contactData, companyData, terminalsEnabled],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const [generatedPdf, setGeneratedPdf] = useState<JSX.Element>(<></>);
  useEffect(() => {
    if (isOpen) {
      setGeneratedPdf(
        <GeneratedQuoteAirWaybillPdf order={orderForAirWaybill} />,
      );
    }
  }, [orderForAirWaybill, isOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        Air Waybill (Half)
      </DialogTitle>
      <DialogContent sx={{ pt: '10px !important' }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PDFViewer style={{ width: '100%', height: '65vh' }} showToolbar>
              {generatedPdf}
            </PDFViewer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained">
              <PDFDownloadLink
                document={generatedPdf}
                fileName={`quote-air-waybill-${orderForAirWaybill.quoteNumber}.pdf`}
              >
                {({ loading }) => (loading ? 'Loading...' : 'Download')}
              </PDFDownloadLink>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <EmailOrderDocuments
              documentType={OrderDocumentType.AirWaybill}
              isQuote
              generatedPdf={generatedPdf}
              isOpen={isOpen}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PrintQuoteAirWaybillModal;
