import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  FreightBillingMethod,
  FuelBillingMethod,
  OrderFormFieldsFragment,
  QuoteStatus,
} from '../../../../../generated/graphql';
import { lineHaulShipmentSchema } from '../../../../orders/components/order-form/forms/line-haul-shipment-schema';
import { orderChargesShipmentSchema } from '../../../../orders/components/order-form/forms/order-charges-shipment-schema';
import { getPackagesSchema } from '../../../../orders/components/order-form/forms/packages-schema';
import { stopSchema } from '../../../../orders/components/order-form/forms/use-order-form';

export const getQuoteSchema = (orderFormFields?: OrderFormFieldsFragment) =>
  yup.object({
    uuid: yup.string().required(),
    contactUuid: yup.string().required('Contact is required.'),
    serviceUuid: yup.string().optional().nullable(),
    number: yup.string().optional(),
    status: yup
      .mixed<QuoteStatus>()
      .oneOf(Object.values(QuoteStatus))
      .nullable()
      .optional(),
    dimFactor: yup.number().optional().nullable(),
    stops: yup.array().of(stopSchema).optional().nullable(),
    packages: getPackagesSchema(orderFormFields),
    orderChargesShipment: orderChargesShipmentSchema.nullable(),
    isUsingLineHaul: yup.boolean().required(),
    lineHaulShipment: lineHaulShipmentSchema.nullable(),
    lineHaulLaneUuid: yup.string().optional().nullable(),
    totalSkids: yup.number().optional().nullable(),
    defaultFuelBillingMethod: yup
      .mixed<FuelBillingMethod>()
      .oneOf(Object.values(FuelBillingMethod)),
    defaultFuelSurcharge: yup.number(),
    defaultFreightBillingMethod: yup
      .mixed<FreightBillingMethod>()
      .oneOf(Object.values(FreightBillingMethod)),
    useKilograms: yup.boolean().optional().nullable(),
    useCentimeters: yup.boolean().optional().nullable(),
  });

export type QuoteFormValues = yup.InferType<ReturnType<typeof getQuoteSchema>>;

const useQuoteForm = () => {
  const { companyData } = useMe();

  const form = useForm({
    resolver: yupResolver(getQuoteSchema(companyData?.orderFormFields)),
  });

  return {
    form,
  };
};

export default useQuoteForm;
