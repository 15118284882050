import { Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  DetailedLineHaulManifestFragment,
  ShallowLineHaulSegmentFragment,
  useEquipmentsQuery,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';

type VehicleOption = {
  value: string | undefined;
  label: string;
};

const ManifestVehicleField = ({
  manifest,
  unmanifestedSegment,
}: {
  manifest: DetailedLineHaulManifestFragment | undefined;
  unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
}) => {
  const { data: equipmentsData } = useEquipmentsQuery();

  const [selectedVehicleOption, setSelectedVehicleOption] =
    useState<VehicleOption>();
  const [showInput, setShowInput] = useState<boolean>(false);

  const [planningDate] = useLineHaulDispatchStore((state) => [
    state.planningDate,
  ]);

  const { createNewManifestFromUnmanifestedSegment, updateVehicleOnManifest } =
    useLineHaulDispatchActions();

  useEffect(() => {
    const vehicle = manifest?.equipment;

    if (!isNil(vehicle)) {
      setSelectedVehicleOption({
        label: vehicle.name,
        value: vehicle.uuid,
      });
      setShowInput(true);
    } else if (!isNil(manifest)) {
      setSelectedVehicleOption({
        label: '',
        value: undefined,
      });
      setShowInput(true);
    }
  }, [manifest]);

  const updateVehicle = async (newOption: VehicleOption | null) => {
    // create a new manifest from this segment
    if (isNil(newOption)) {
      return;
    }
    if (!isNil(manifest) && !isNil(newOption.value)) {
      await updateVehicleOnManifest({
        equipmentUuid: newOption.value,
        uuid: manifest.uuid,
      });
    } else if (!isNil(unmanifestedSegment) && !isNil(newOption.value)) {
      await createNewManifestFromUnmanifestedSegment({
        input: {
          equipmentUuid: newOption.value,
          unmanifestedSegmentUuid: unmanifestedSegment.uuid,
          departDate: planningDate,
        },
        unmanifestedSegmentUuid: unmanifestedSegment.uuid,
      });
    }
  };

  return (
    <Stack width="100%" direction="row" alignItems="center" gap={2}>
      <Typography variant="subtitle2">Vehicle</Typography>
      {(showInput || !isNil(unmanifestedSegment)) && (
        <AutocompleteFuzzy
          size="small"
          sx={{
            '& input': {
              fontSize: '14px',
            },
            flex: '1',
          }}
          value={selectedVehicleOption}
          options={
            equipmentsData?.equipments
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((equipment) => ({
                label: equipment.name,
                value: equipment.uuid,
              })) ?? []
          }
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              onClick={(e) => {
                e.stopPropagation();
              }}
              variant="standard"
              size="small"
            />
          )}
          onChange={(event, option) => {
            setSelectedVehicleOption(option ?? undefined);
            updateVehicle(option);
            event.stopPropagation();
          }}
        />
      )}
    </Stack>
  );
};

export default ManifestVehicleField;
