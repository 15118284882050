import * as yup from 'yup';

export const contactPersonSchema = yup
  .object({
    email: yup.string().optional().nullable(),
    firstName: yup.string().optional().nullable(),
    lastName: yup.string().optional().nullable(),
    notes: yup.string().optional().nullable(),
    phone: yup.string().optional().nullable(),
    uuid: yup.string().defined(),
  })
  .optional()
  .nullable()
  .default(null);
