import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useRouteNameQuery } from '../../../generated/graphql';
import RouteNameLocationRow from './route-name-location-row';

const RouteNameLocationsModal = ({
  open,
  setOpen,
  name,
  uuid,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<string | null>>;
  name: string;
  uuid: string;
}) => {
  const { data: routeNameData } = useRouteNameQuery({ variables: { uuid } });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(null)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <DialogTitle>{name} locations</DialogTitle>
        <Box>
          <TableContainer sx={{ minWidth: '100px' }}>
            <TableHead>
              <TableRow>
                <TableCell>City</TableCell>
                <TableCell>Zipcode</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {routeNameData?.routeName?.routeNameLocations?.map((location) => (
                <RouteNameLocationRow
                  city={location.city}
                  zipcode={location.zipcode}
                  uuid={location.uuid}
                />
              ))}
            </TableBody>
            {routeNameData?.routeName?.routeNameLocations?.length === 0 && (
              <Box sx={{ textAlign: 'center', mt: '10px', width: '100%' }}>
                No locations
              </Box>
            )}
          </TableContainer>
        </Box>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(null)}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RouteNameLocationsModal;
