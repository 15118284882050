import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import {
  Button,
  // eslint-disable-next-line
  Grid,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import useContacts from '../../../../common/react-hooks/use-contacts';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  AllInvoicesForPaymentApplicationQuery,
  PermissionResource,
  useAllInvoicesForPaymentApplicationLazyQuery,
} from '../../../../generated/graphql';
import useContactWithBalances from '../../../contacts/hooks/use-contact-with-balances';
import { CreateOrEdit } from '../invoices/enums';
import ContactInvoiceList from './contact-invoice-list';
import ContactPaymentsList from './contact-payments-list';
import CreatePaymentModal from './create-payment-modal';
import CustomerNotesModal from './customer-notes-modal';
import CustomerSummary from './customer-summary';

interface CustomerProfileProps {
  openedCustomerUuid: string;
}

const CustomerProfile = ({ openedCustomerUuid }: CustomerProfileProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCustomers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Customers,
  );
  const { getContact } = useContacts();

  const [paymentsListLoading, setPaymentsListLoading] = useState(false);
  const [showCreatePaymentsModal, setShowCreatePaymentModal] = useState(false);
  const [showCreateCreditDebitModal, setShowCreateCreditDebitModal] =
    useState(false);
  const [showCustomerNotesModal, setShowCustomerNotesModal] = useState(false);
  const {
    contact,
    customerBalance,
    creditHoldEnabled,
    contactPaymentsDataLoading,
    loadContactWithBalances,
  } = useContactWithBalances();
  const [paymentsRefetchKey, setPaymentsRefetchKey] = useState<number>(0);
  const refetchPayments = () => setPaymentsRefetchKey((prev) => prev + 1);
  const [
    allInvoicesForPaymentApplication,
    setAllInvoicesForPaymentApplication,
  ] = useState<AllInvoicesForPaymentApplicationQuery | undefined>();

  const [getAllInvoices] = useAllInvoicesForPaymentApplicationLazyQuery();

  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    loadContactWithBalances(openedCustomerUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsListLoading, openedCustomerUuid]);

  const fetchAllInvoices = async () => {
    // Get all invoices of master account contacts -- if no master account, pass in billing party contact instead
    const masterAccountUuid =
      getContact(openedCustomerUuid)?.masterAccount?.uuid;
    let billingPartyContactUuid;
    if (isNil(masterAccountUuid)) {
      billingPartyContactUuid = openedCustomerUuid;
    }
    const res = await getAllInvoices({
      variables: {
        masterAccountUuid,
        billingPartyContactUuid,
      },
    });
    setAllInvoicesForPaymentApplication(res.data);
  };

  useEffect(() => {
    fetchAllInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedCustomerUuid]);

  const handleClosePaymentModal = () => {
    refetchPayments();
    setShowCreatePaymentModal(false);
    setShowCreateCreditDebitModal(false);
  };

  return (
    <Stack flex="3">
      {showCreatePaymentsModal && (
        <CreatePaymentModal
          open={showCreatePaymentsModal}
          onClose={handleClosePaymentModal}
          contactUuid={openedCustomerUuid}
          createOrEdit={CreateOrEdit.Create}
          setPaymentsListLoading={setPaymentsListLoading}
          allInvoicesForPaymentApplication={allInvoicesForPaymentApplication}
        />
      )}
      {showCreateCreditDebitModal && (
        <CreatePaymentModal
          open={showCreateCreditDebitModal}
          onClose={handleClosePaymentModal}
          contactUuid={openedCustomerUuid}
          isCreditDebit
          createOrEdit={CreateOrEdit.Create}
          setPaymentsListLoading={setPaymentsListLoading}
          allInvoicesForPaymentApplication={allInvoicesForPaymentApplication}
        />
      )}
      {showCustomerNotesModal && (
        <CustomerNotesModal
          open={showCustomerNotesModal}
          onClose={() => setShowCustomerNotesModal(false)}
          contactUuid={openedCustomerUuid}
        />
      )}
      <Grid container>
        <Grid item xs={2.3} spacing={0.5}>
          <Stack sx={{ ml: 1, mt: 1 }}>
            <Stack alignItems="center" direction="row" spacing={0.5}>
              {!isNil(contact) && (
                <Typography sx={{ fontWeight: 'bold' }}>
                  {contact.displayName}
                </Typography>
              )}
              {creditHoldEnabled && (
                <Tooltip title="On Credit Hold">
                  <CreditCardOffIcon color="error" sx={{ fontSize: '16px' }} />
                </Tooltip>
              )}
            </Stack>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
                cursor: 'pointer',
              }}
              onClick={() => setShowCustomerNotesModal(true)}
            >
              View notes
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={9.7}>
          <CustomerSummary
            contactPaymentsDataLoading={contactPaymentsDataLoading}
            contact={contact ?? null}
            customerBalance={customerBalance}
          />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mx: 1, mt: 1 }}
      >
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={tabIndex}
          onChange={(e, newIndex) => {
            setTabIndex(newIndex);
          }}
        >
          <Tab label="Payments/Credits/Debits" />
          <Tab label="Invoices" />
        </Tabs>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={() => setShowCreateCreditDebitModal(true)}
            disabled={!canWriteCustomers}
          >
            Create credit/debit
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowCreatePaymentModal(true)}
            disabled={!canWriteCustomers}
          >
            Create payment
          </Button>
        </Stack>
      </Stack>
      <TabPanel selectedValue={tabIndex} panelValue={0}>
        <ContactPaymentsList
          key={openedCustomerUuid}
          contactUuid={openedCustomerUuid}
          paymentsListLoading={paymentsListLoading}
          setPaymentsListLoading={setPaymentsListLoading}
          refetchKey={paymentsRefetchKey}
        />
      </TabPanel>
      <TabPanel selectedValue={tabIndex} panelValue={1}>
        <ContactInvoiceList contactUuid={openedCustomerUuid} />
      </TabPanel>
    </Stack>
  );
};

export default CustomerProfile;
