/**
 * The default address card on the contact creation page
 *
 * Only appears during creation, is replaced with an autocomplete select on edit/view
 */
import { Box, CircularProgress, SxProps, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectAddressErrorsById,
  upsertAddressErrors,
} from '../../addresses/redux/addresses-errors-slice';
import {
  AddressFormField,
  selectAddressById,
  updateAddress,
} from '../../addresses/redux/addresses-values-slice';
import ContactPageMode from './contact-page-mode';

const useStyles = () => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: 2,
      px: 2,
      py: 2,
      width: '100%',
    } as SxProps,
    flexRowBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: 2,
      my: 1.5,
      width: '100%',
    } as SxProps,
  };
};

type DefaultAddressProps = {
  addressUuid: string;
  mode: ContactPageMode;
};

/**
 * Render the default location form when creating a new contact
 */
const DefaultAddressForm = ({ addressUuid, mode }: DefaultAddressProps) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const address = useAppSelector((state) =>
    selectAddressById(state, addressUuid),
  );
  const errors = useAppSelector((state) =>
    selectAddressErrorsById(state, addressUuid),
  );

  const updateValue = (
    fieldName: keyof AddressFormField,
    value: AddressFormField[typeof fieldName],
  ) => {
    dispatch(
      updateAddress({
        id: addressUuid,
        changes: { [fieldName]: value },
      }),
    );
  };

  const clearError = (fieldName: keyof AddressFormField) => {
    dispatch(
      upsertAddressErrors({
        uuid: addressUuid,
        [fieldName]: undefined,
      }),
    );
  };

  if (address === undefined) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={styles.flexRowBox}>
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          error={!isNil(errors?.name)}
          helperText={errors?.name}
          label="Address name"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('name');
            }
            updateValue('name', event.target.value);
          }}
          value={address.name ?? ''}
        />
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          error={!isNil(errors?.line1)}
          helperText={errors?.line1}
          label="Address line 1"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('line1');
            }
            updateValue('line1', event.target.value);
          }}
          value={address.line1 ?? ''}
        />
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          label="Address line 2"
          onChange={(event) => updateValue('line2', event.target.value)}
          value={address.line2 ?? ''}
        />
      </Box>
      <Box sx={styles.flexRowBox}>
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          error={!isNil(errors?.city)}
          helperText={errors?.city}
          label="City"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('city');
            }
            updateValue('city', event.target.value);
          }}
          value={address.city ?? ''}
        />
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          error={!isNil(errors?.state)}
          helperText={errors?.state}
          label="State"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('state');
            }
            updateValue('state', event.target.value);
          }}
          value={address.state ?? ''}
        />
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          error={!isNil(errors?.zip)}
          helperText={errors?.zip}
          label="Zip code"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('zip');
            }
            updateValue('zip', event.target.value);
          }}
          value={address.zip ?? ''}
        />
      </Box>
      <Box sx={styles.flexRowBox}>
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          error={!isNil(errors?.country)}
          helperText={errors?.country}
          label="Country"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              clearError('country');
            }
            updateValue('country', event.target.value);
          }}
          value={address.country ?? ''}
        />
      </Box>
      <Box sx={{ ...styles.flexRowBox, justifyContent: 'center' }}>
        <TextField
          disabled
          // error={}
          // helperText={}
          label="Hours start"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              // setReceivingHoursStartError(false);
            }
            // setReceivingHoursStart(event.target.value as Date);
          }}
          type="time"
          value={address.receivingHoursStart ?? ''}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mr: 2 }}
        />

        <TextField
          disabled
          // error={}
          // helperText={}
          label="Hours end"
          onChange={(event) => {
            if (event.target.value.length > 0) {
              // setReceivingHoursEndError(false);
            }
            // setReceivingHoursEnd(event.target.value as Date);
          }}
          type="time"
          value={address.receivingHoursEnd ?? ''}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ ml: 2 }}
        />
      </Box>
      <Box sx={styles.flexRowBox}>
        <TextField
          disabled={mode === ContactPageMode.VIEW}
          label="Driver instructions"
          onChange={(event) => {
            updateValue('driverInstructions', event.target.value);
          }}
          multiline
          rows={6}
          value={address.driverInstructions ?? ''}
          sx={{
            mx: 30,
          }}
          fullWidth
        />
      </Box>
    </>
  );
};

export default DefaultAddressForm;
