import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';
import { capitalCase, sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil, truncate } from 'lodash';
import { Segment } from '../../../generated/graphql';
import { INBOUND_STOP_IDX } from '../../orders/components/order-form/components/constants';
import { OrderDeliveryReceiptData } from '../utils';
import DisplayAddress from './display-address';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Roboto',
    fontSize: '11px',
    flexDirection: 'column',
    gap: '10px',
  },
  titleBold: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '12px',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  subHeaderTextSize: {
    fontSize: '10px',
  },
  subHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  metadataText: {
    fontSize: '8px',
    color: 'gray',
  },
  refNumText: {
    fontWeight: 'bold',
  },
  labelText: {
    fontWeight: 'bold',
    width: '20%',
  },
  infoText: {
    width: '80%',
  },
  smallLabelText: {
    fontWeight: 'bold',
    width: '50%',
  },
  smallInfoText: {
    width: '50%',
  },
  formLabelText: {
    fontWeight: 'bold',
    width: '25%',
    textAlign: 'right',
  },
  formLine: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    width: '75%',
  },
  rowWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rowFlexStart: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rowSpaceBetween: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  colSpaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  outerCell: {
    borderWidth: 2,
    borderColor: 'black',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    borderWidth: 1,
    borderColor: 'black',
    padding: '5px',
    width: '100%',
  },
});

const GeneratedOrderDeliveryReceiptPdf = ({
  segment,
  order,
  terminalsEnabled,
}: {
  segment: Segment | undefined;
  order: OrderDeliveryReceiptData;
  terminalsEnabled: boolean;
}) => {
  const serviceDate =
    order.inboundShipment?.serviceDate ?? order.outboundShipment?.serviceDate;

  const deliveryReceipt = () => {
    return (
      <View wrap={false} style={styles.outerCell}>
        <View style={[styles.cell]}>
          <View style={styles.rowSpaceBetween}>
            <Text style={styles.metadataText}>
              {dayjs().format('MM/DD/YYYY hh:mm a')}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={{ ...{ fontWeight: 700 }, ...styles.titleBold }}>
                {order.contactDisplayName}
              </Text>
              <Text style={styles.subtitle}>{order.companyName}</Text>
            </View>
            <Text style={{ fontWeight: 700 }}>Delivery Receipt</Text>
          </View>
        </View>
        <View style={[styles.cell]}>
          <View style={styles.rowSpaceBetween}>
            <Text>
              <Text style={styles.refNumText}>Service date: </Text>
              {!isNil(serviceDate)
                ? dayjs(serviceDate).format('MM/DD/YY')
                : '-'}
            </Text>
            <Text>
              <Text style={styles.refNumText}>
                {`${segment === Segment.Cartage ? 'HAWB #' : 'Pro #'}: `}
              </Text>
              {order.shipperBillOfLadingNumber}
            </Text>
            <Text>
              <Text style={styles.refNumText}>MAWB: </Text>
              {order.masterAirwayBillOfLadingNumber}
            </Text>
            <Text>
              <Text style={styles.refNumText}>Order: </Text>
              {order.orderName}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          {[order.inboundShipment, order.outboundShipment].map((stop, idx) => (
            // eslint-disable-next-line react/jsx-key
            <View
              style={[styles.cell, { borderBottomWidth: 0, marginBottom: 0 }]}
            >
              <View style={styles.rowSpaceBetween}>
                <Text
                  style={[styles.headerText, { textDecoration: 'underline' }]}
                >
                  {capitalCase(stop?.stopType ?? 'Stop')}
                </Text>
                {terminalsEnabled && (
                  <Text>
                    <Text style={styles.refNumText}>
                      {idx === INBOUND_STOP_IDX ? 'Orig: ' : 'Dest: '}
                    </Text>
                    {stop?.terminalCode}
                  </Text>
                )}
              </View>
              <View style={styles.row}>
                <Text style={styles.labelText}>Address: </Text>
                <View style={styles.infoText}>
                  <DisplayAddress address={stop?.address} />
                </View>
              </View>
              <View style={styles.row}>
                <Text style={styles.labelText}>Phone: </Text>
                <Text style={{ width: '30%' }}>{stop?.contactPhone}</Text>
                <Text style={styles.labelText}>Contact: </Text>
                <Text style={{ width: '30%' }}>{stop?.contactPersonName}</Text>
              </View>
              <View style={styles.row}>
                <Text>
                  <Text style={styles.refNumText}>Special instructions: </Text>
                  {truncate(stop?.instructions ?? '', {
                    length: 200,
                  })}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.row}>
          {[order.inboundShipment, order.outboundShipment].map((stop) => (
            // eslint-disable-next-line react/jsx-key
            <View
              style={[
                styles.cell,
                { borderTopWidth: 0, marginTop: 0, paddingTop: 5 },
              ]}
            >
              <View style={styles.rowSpaceBetween}>
                <Text>
                  <Text style={styles.refNumText}>Appt: </Text>
                  {stop?.appointmentDateString}
                </Text>
                <Text>
                  <Text style={styles.refNumText}>Deadline: </Text>
                  {!isNil(stop?.deadlineDate)
                    ? dayjs(stop?.deadlineDate).format('MM/DD')
                    : ''}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View style={[styles.cell]}>
          <View style={styles.rowFlexStart}>
            <View style={[styles.col, { width: '20%' }]}>
              <View style={styles.row}>
                <Text style={styles.smallLabelText}>Pieces:</Text>
                <Text style={styles.smallInfoText}>{order.totalPieces}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.smallLabelText}>Weight:</Text>
                <Text style={styles.smallInfoText}>{order.totalWeight} lb</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.smallLabelText}>Dim wt:</Text>
                <Text style={styles.smallInfoText}>{order.totalDimWeight}</Text>
              </View>
            </View>
            <View style={[styles.col, { width: '50%' }]}>
              <View style={styles.row}>
                <Text style={styles.labelText}>Service:</Text>
                <Text style={styles.infoText}>
                  {sentenceCase(order.serviceName ?? '')}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.cell]}>
          <View style={styles.row}>
            <View style={[styles.col, { width: '55%', gap: '5px' }]}>
              <View style={styles.row}>
                <Text style={styles.formLabelText}>Printed name: </Text>
                <View style={styles.formLine} />
              </View>
              <View style={styles.row}>
                <Text style={styles.formLabelText}>Received by: </Text>
                <View style={styles.formLine} />
              </View>
            </View>
            <View style={[styles.col, { width: '45%', gap: '5px' }]}>
              <View style={styles.row}>
                <Text style={[styles.formLabelText, { width: '20%' }]}>
                  Driver:{' '}
                </Text>
                <View style={[styles.formLine, { width: '80%' }]} />
              </View>
              <View style={styles.row}>
                <View style={[styles.row, { width: '60%' }]}>
                  <Text style={[styles.formLabelText, { width: '35%' }]}>
                    Date:{' '}
                  </Text>
                  <View style={[styles.formLine, { width: '65%' }]} />
                </View>
                <View style={[styles.row, { width: '40%' }]}>
                  <Text style={[styles.formLabelText, { width: '35%' }]}>
                    Time:{' '}
                  </Text>
                  <View style={[styles.formLine, { width: '65%' }]} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Document
      title={`Order Delivery Receipt - ${order?.shipperBillOfLadingNumber}`}
    >
      <Page size="LETTER" style={styles.page}>
        {deliveryReceipt()}
        {deliveryReceipt()}
      </Page>
    </Document>
  );
};
export default GeneratedOrderDeliveryReceiptPdf;
