// eslint-disable-next-line no-restricted-imports
import { Box, Grid, SxProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { isNilOrEmptyString } from '../../../../common/utils/utils';

interface CustomerLoadPageFieldProps {
  spacing?: number;
  title: string;
  value?: ReactNode | string | null;
  defaultValue: string;

  helperText?: ReactNode;
}

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  bold: {
    fontWeight: 600,
  } as SxProps,
  gridItem: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
  } as SxProps,
};

const CustomerLoadPageField = ({
  spacing,
  title,
  value,
  defaultValue,
  helperText,
}: CustomerLoadPageFieldProps) => {
  return (
    <Grid item xs={spacing}>
      <Box sx={styles.gridItem}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.bold}>{title}</Typography>
          {helperText}
        </Box>
        <Typography>
          {!isNilOrEmptyString(value) ? value : defaultValue}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CustomerLoadPageField;
