import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { shallow } from 'zustand/shallow';
import { OrderForDocumentAttachmentsDownloadFragment } from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import useRouteActions from '../../hooks/use-route-actions';
import { convertRoutesToOverviewCsv } from '../../utils';
import ConfirmUnsignedPodsDownloadModal from './modals/confirm-unsigned-pods-download-modal';
import DeleteRouteModal from './modals/delete-routes-modal';
import PrintManifestModal from './modals/print-manifest-modal';
import TextDriversModal from './modals/text-drivers-modal';

const RoutesContextMenu = ({
  alwaysSelecting,
}: {
  alwaysSelecting?: boolean;
}) => {
  const [
    planningDate,
    selectingRoutes,
    setSelectingRoutes,
    deselectAllRouteUuids,
    setSelectedMapRouteUuids,
    showMap,
    setShowMap,
    selectedViewUuid,
    selectedRoutes,
    deleteRoute,
  ] = useDispatchStore(
    (state) => [
      state.planningDate,
      state.selectingRoutes || alwaysSelecting === true,
      state.setSelectingRoutes,
      state.deselectAllRoutes,
      state.setSelectedMapRouteUuids,
      state.showMap,
      state.setShowMap,
      state.selectedViewUuid,
      state.routes.filter((r) => state.selectedRouteUuids.includes(r.uuid)),
      state.deleteRoute,
    ],
    shallow,
  );
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);
  const [showTextDriversModal, setShowTextDriversModal] =
    useState<boolean>(false);
  const [showPrintManifestModal, setShowPrintManifestModal] =
    useState<boolean>(false);
  const [showDeleteRouteModal, setShowDeleteRouteModal] =
    useState<boolean>(false);
  const [
    showConfirmUnsignedPodsDownloadModal,
    setShowConfirmUnsignedPodsDownloadModal,
  ] = useState<boolean>(false);
  const [ordersWithoutUnsignedPods, setOrdersWithoutUnsignedPods] = useState<
    OrderForDocumentAttachmentsDownloadFragment[]
  >([]);
  const {
    downloadCoverSheetsForRoutes,
    downloadUnsignedPodDocumentsForRoutes,
  } = useRouteActions();

  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };

  const printUnsignedPods = async () => {
    const ordersMissingDocument = await downloadUnsignedPodDocumentsForRoutes(
      selectedRoutes,
      false,
    );

    if (!isEmpty(ordersMissingDocument)) {
      setOrdersWithoutUnsignedPods(ordersMissingDocument);
      setShowConfirmUnsignedPodsDownloadModal(true);
    }
    closeDropdownMenu();
  };

  const selectedRouteUuids = useMemo(
    () => selectedRoutes.map((r) => r.uuid),
    [selectedRoutes],
  );

  const handleDeleteRoute = useCallback(() => {
    selectedRouteUuids.forEach((uuid) => {
      deleteRoute(uuid);
    });
    deselectAllRouteUuids();
    closeDropdownMenu();
  }, [deleteRoute, deselectAllRouteUuids, selectedRouteUuids]);

  return (
    <>
      {showTextDriversModal && (
        <TextDriversModal
          open={showTextDriversModal}
          setOpen={setShowTextDriversModal}
          routes={selectedRoutes}
        />
      )}
      {showPrintManifestModal && (
        <PrintManifestModal
          open={showPrintManifestModal}
          setOpen={setShowPrintManifestModal}
          routes={selectedRoutes}
        />
      )}
      {showDeleteRouteModal && (
        <DeleteRouteModal
          open={showDeleteRouteModal}
          setOpen={setShowDeleteRouteModal}
          routeUuids={selectedRouteUuids}
          onDeleteRoute={handleDeleteRoute}
        />
      )}
      {showConfirmUnsignedPodsDownloadModal && (
        <ConfirmUnsignedPodsDownloadModal
          open={showConfirmUnsignedPodsDownloadModal}
          setOpen={setShowConfirmUnsignedPodsDownloadModal}
          routes={selectedRoutes}
          ordersWithoutUnsignedPods={ordersWithoutUnsignedPods}
        />
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <Button
          color="info"
          onClick={(e) => {
            if (selectingRoutes) {
              setDropdownMenuRef(e.currentTarget);
            } else {
              setSelectingRoutes(true);
            }
          }}
          endIcon={selectingRoutes ? <ArrowDropDownIcon /> : null}
        >
          {selectingRoutes
            ? `Manage ${
                isEmpty(selectedRoutes) ? '' : `(${selectedRoutes.length})`
              }`
            : 'Select'}
        </Button>
        {selectingRoutes && alwaysSelecting !== true && (
          <Button
            sx={{ borderRadius: 1 }}
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectingRoutes(false);
              deselectAllRouteUuids();
            }}
            size="small"
          >
            Done
          </Button>
        )}
      </Stack>
      {dropdownMenuOpen && (
        <Menu
          anchorEl={dropdownMenuRef}
          open={dropdownMenuOpen}
          onClose={closeDropdownMenu}
        >
          <MenuList dense sx={{ py: 0 }}>
            {isNil(selectedViewUuid) && !showMap && (
              <MenuItem
                onClick={() => {
                  closeDropdownMenu();
                  setSelectedMapRouteUuids(selectedRouteUuids);
                  setShowMap(true);
                  setSelectingRoutes(false);
                }}
              >
                Map routes
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                closeDropdownMenu();
                setShowTextDriversModal(true);
              }}
            >
              Text drivers
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeDropdownMenu();
                setShowPrintManifestModal(true);
              }}
            >
              Print manifests
            </MenuItem>
            <MenuItem
              onClick={async () => {
                closeDropdownMenu();
                downloadCoverSheetsForRoutes(selectedRoutes);
              }}
            >
              Print cover sheets
            </MenuItem>
            <MenuItem onClick={printUnsignedPods}>Print unsigned PODs</MenuItem>
            <CSVLink
              data={convertRoutesToOverviewCsv(selectedRoutes)}
              filename={`routes-${planningDate?.format('MM/DD/YYYY')}.csv`}
            >
              <MenuItem>Print routes overview</MenuItem>
            </CSVLink>
            <Divider />
            <MenuItem
              onClick={() => {
                closeDropdownMenu();
                setShowDeleteRouteModal(true);
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete routes</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export default memo(RoutesContextMenu);
