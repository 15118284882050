import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import ReportRow, {
  DownloadableReport,
} from '../../../../common/components/ReportRow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { PaymentType, PermissionResource } from '../../../../generated/graphql';
import CreateEmptyInvoiceModal from '../../../driver-settlement/components/driver-settlement-bills/create-empty-settlement-modal';
import DownloadAverageDaysToPayReportModal from '../customers/download-average-days-to-pay-report-modal';
import DownloadCheckRegisterReportModal from '../customers/download-check-register-report-modal';
import DownloadAccessorialDetailsReportModal from '../invoices/download/download-accessorial-details-report-modal';
import DownloadAccountsReceivableReportModal from '../invoices/download/download-accounts-receivable-report-modal';
import DownloadChargeBreakdownReportModal from '../invoices/download/download-charge-breakdown-report';
import DownloadCustomerChargesReportModal from '../invoices/download/download-customer-charges-report';
import DownloadDriverDailyLogModal from '../invoices/download/download-driver-daily-log-modal';
import DownloadGLCodeReportModal from '../invoices/download/download-gl-code-report-modal';
import DownloadIncomeAnalysisReportModal from '../invoices/download/download-income-analysis-report-modal';
import DownloadInvoiceAgingReportModal from '../invoices/download/download-invoice-aging-report-modal';
import DownloadInvoiceAgingReportModalOld from '../invoices/download/download-invoice-aging-report-modal-old';
import DownloadInvoiceBillingSummaryModal from '../invoices/download/download-invoice-billing-summary-modal';
import DownloadInvoiceRegisterReportModal from '../invoices/download/download-invoice-register-report-modal';
import DownloadNetSalesReportModal from '../invoices/download/download-net-sales-report-modal';
import DownloadOnTimePerformanceReportModal from '../invoices/download/download-on-time-performance-report-modal';
import DownloadOpenInvoicesReportModal from '../invoices/download/download-open-invoices-report-modal';
import DownloadPaymentApplicationReportModal from '../invoices/download/download-payment-application-report-modal';
import DownloadPaymentJournalReportModal from '../invoices/download/download-payment-journal-report-modal';
import DownloadUnappliedPaymentsReportModal from '../invoices/download/download-unapplied-payments-report-modal';
import DownloadUnbilledAndUninvoicedOrdersReportModal from '../invoices/download/download-unbilled-and-uninvoiced-orders-report-modal';
import DunningModal from '../invoices/download/dunning-modal';
import { ReportType } from '../invoices/download/generated-unbilled-revenue-report';
import { AgingReportType } from './aging-report/utils';

export type AccountingReportsProps = {
  setShowSendAccountingReportsSuccessMessage: Dispatch<SetStateAction<boolean>>;
  setShowSendAccountingReportsErrorMessage: Dispatch<SetStateAction<boolean>>;
};

const AccountingReports = ({
  setShowSendAccountingReportsSuccessMessage,
  setShowSendAccountingReportsErrorMessage,
}: AccountingReportsProps) => {
  const { userPermissions } = useUserRoles();

  const [showCreateEmptyInvoiceModal, setShowCreateEmptyInvoiceModal] =
    useState<boolean>(false);
  const [
    showDownloadInvoiceRegisterReportModal,
    setShowDownloadInvoiceRegisterReportModal,
  ] = useState<boolean>(false);
  const [
    showDownloadAccountsReceivableReportModal,
    setShowDownloadAccountsReceivableReportModal,
  ] = useState<boolean>(false);
  const [
    showDownloadInvoiceBillingSummaryModal,
    setShowDownloadInvoiceBillingSummaryModal,
  ] = useState<boolean>(false);
  const [
    showDownloadOpenInvoicesReportModal,
    setShowDownloadOpenInvoicesReportModal,
  ] = useState<boolean>(false);
  const [currAgingReportType, setCurrAgingReportType] =
    useState<AgingReportType>(AgingReportType.Detailed);
  const [showDownloadAgingModalOld, setShowDownloadAgingModalOld] =
    useState(false);
  const [showDownloadAgingModal, setShowDownloadAgingModal] = useState(false);
  const [showDownloadDriverDailyLogModal, setShowDownloadDriverDailyLogModal] =
    useState(false);
  const [showDunningModal, setShowDunningModal] = useState(false);
  const [showGLCodeReportModal, setShowGLCodeReportModal] = useState(false);
  const [showPaymentJournalModal, setShowPaymentJournalModal] = useState(false);
  const [showCreditJournalModal, setShowCreditJournalModal] = useState(false);
  const [showPaymentApplicationModal, setShowPaymentApplicationModal] =
    useState(false);
  const [
    showDownloadCustomerChargesReportModal,
    setShowDownloadCustomerChargesReportModal,
  ] = useState(false);
  const [
    showDownloadChargeBreakdownReportModal,
    setShowDownloadChargeBreakdownReportModal,
  ] = useState(false);
  const [showUnbilledRevenueModal, setShowUnbilledRevenueModal] =
    useState(false);
  const [showCompletedUninvoicedModal, setShowCompletedUninvoicedModal] =
    useState(false);
  const [showAccessorialDetailsModal, setShowAccessorialDetailsModal] =
    useState(false);
  const [
    showDownloadCheckRegisterReportModal,
    setShowDownloadCheckRegisterReportModal,
  ] = useState<boolean>(false);
  const [
    showDownloadAverageDaysToPayReportModal,
    setShowDownloadAverageDaysToPayReportModal,
  ] = useState<boolean>(false);
  const [showIncomeAnalysisModal, setShowIncomeAnalysisModal] = useState(false);
  const [showDownloadNetSalesReportModal, setShowDownloadNetSalesReportModal] =
    useState(false);
  const [
    showDownloadOnTimePerformanceReportModal,
    setShowDownloadOnTimePerformanceReportModal,
  ] = useState(false);
  const [
    showDownloadUnappliedPaymentsReportModal,
    setShowDownloadUnappliedPaymentsReportModal,
  ] = useState<boolean>(false);

  const { canRead: canReadAccountingReports } = getPermissionsFlags(
    userPermissions,
    PermissionResource.AccountingReports,
  );

  const ffUpdatedAgingReport = useFeatureFlag(
    FeatureFlag.FF_SURELOGIX_AGING_REPORT_UPDATES,
  );
  const ffGLCodeReport = useFeatureFlag(
    FeatureFlag.FF_ENABLE_GENERAL_LEDGER_CONFIGURATION_PAGE,
  );

  const shouldNotShowTerminalAccountingReports = !canReadAccountingReports;

  const downloadableReports: DownloadableReport[] = [
    {
      label: 'Accessorial Details Report',
      onClick: () => {
        setShowAccessorialDetailsModal(true);
      },
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Accounts Receivable Report',
      onClick: () => setShowDownloadAccountsReceivableReportModal(true),
      hidden: false,
    },
    {
      label: 'Aging Report (Detailed)',
      onClick: () => {
        if (ffUpdatedAgingReport) {
          setShowDownloadAgingModal(true);
        } else {
          setShowDownloadAgingModalOld(true);
        }
        setCurrAgingReportType(AgingReportType.Detailed);
      },
      hidden: false,
    },
    {
      label: 'Aging Report (Summary)',
      onClick: () => {
        if (ffUpdatedAgingReport) {
          setShowDownloadAgingModal(true);
        } else {
          setShowDownloadAgingModalOld(true);
        }
        setCurrAgingReportType(AgingReportType.Summary);
      },
      hidden: false,
    },
    {
      label: 'Average Days to Pay Report',
      onClick: () => setShowDownloadAverageDaysToPayReportModal(true),
      hidden: false,
    },
    {
      label: 'Check Register Report',
      onClick: () => setShowDownloadCheckRegisterReportModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Credit Journal Report',
      onClick: () => setShowCreditJournalModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Charge Breakdown Report',
      onClick: () => setShowDownloadChargeBreakdownReportModal(true),
      hidden: false,
    },
    {
      label: 'Driver Daily Log',
      onClick: () => setShowDownloadDriverDailyLogModal(true),
      hidden: false,
    },
    {
      label: 'Dunning Report',
      onClick: () => setShowDunningModal(true),
      hidden: false,
    },
    {
      label: 'GL Code Report',
      onClick: () => setShowGLCodeReportModal(true),
      hidden: shouldNotShowTerminalAccountingReports || !ffGLCodeReport,
    },
    {
      label: 'Income Analysis Report',
      onClick: () => setShowIncomeAnalysisModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Invoice Billing Summary',
      onClick: () => setShowDownloadInvoiceBillingSummaryModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Invoice Register Report',
      onClick: () => setShowDownloadInvoiceRegisterReportModal(true),
      hidden: false,
    },
    {
      label: 'Net Sales Summary Report',
      onClick: () => setShowDownloadNetSalesReportModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Open Invoices Report',
      onClick: () => setShowDownloadOpenInvoicesReportModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Payment Journal Report',
      onClick: () => setShowPaymentJournalModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Payment Application Report',
      onClick: () => setShowPaymentApplicationModal(true),
      hidden: false,
    },
    {
      label: 'Unapplied Payments Report',
      onClick: () => setShowDownloadUnappliedPaymentsReportModal(true),
      hidden: false,
    },
    {
      label: 'Unbilled Revenue Report',
      onClick: () => setShowUnbilledRevenueModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'Uninvoiced Orders Report',
      onClick: () => setShowCompletedUninvoicedModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
    {
      label: 'On-Time Performance Report',
      onClick: () => setShowDownloadOnTimePerformanceReportModal(true),
      hidden: shouldNotShowTerminalAccountingReports,
    },
  ];

  return (
    <Stack spacing={2} sx={{ height: '100%', overflowY: 'scroll', ml: 4 }}>
      <CreateEmptyInvoiceModal
        isOpen={showCreateEmptyInvoiceModal}
        setIsOpen={setShowCreateEmptyInvoiceModal}
      />
      <DownloadAccountsReceivableReportModal
        isOpen={showDownloadAccountsReceivableReportModal}
        setIsOpen={setShowDownloadAccountsReceivableReportModal}
      />
      <DownloadInvoiceRegisterReportModal
        isOpen={showDownloadInvoiceRegisterReportModal}
        setIsOpen={setShowDownloadInvoiceRegisterReportModal}
      />
      <DownloadInvoiceBillingSummaryModal
        isOpen={showDownloadInvoiceBillingSummaryModal}
        setIsOpen={setShowDownloadInvoiceBillingSummaryModal}
      />
      <DownloadOpenInvoicesReportModal
        isOpen={showDownloadOpenInvoicesReportModal}
        setIsOpen={setShowDownloadOpenInvoicesReportModal}
      />
      <DownloadCustomerChargesReportModal
        isOpen={showDownloadCustomerChargesReportModal}
        setIsOpen={setShowDownloadCustomerChargesReportModal}
      />
      <DownloadChargeBreakdownReportModal
        isOpen={showDownloadChargeBreakdownReportModal}
        setIsOpen={setShowDownloadChargeBreakdownReportModal}
      />
      <DownloadDriverDailyLogModal
        isOpen={showDownloadDriverDailyLogModal}
        setIsOpen={setShowDownloadDriverDailyLogModal}
      />
      <DunningModal
        isOpen={showDunningModal}
        setIsOpen={setShowDunningModal}
        setShowSendAccountingReportsSuccessMessage={
          setShowSendAccountingReportsSuccessMessage
        }
        setShowSendAccountingReportsErrorMessage={
          setShowSendAccountingReportsErrorMessage
        }
      />
      <DownloadPaymentJournalReportModal
        open={showPaymentJournalModal}
        setOpen={setShowPaymentJournalModal}
        paymentTypes={[PaymentType.Payment]}
      />
      <DownloadPaymentJournalReportModal
        open={showCreditJournalModal}
        setOpen={setShowCreditJournalModal}
        paymentTypes={[PaymentType.Credit, PaymentType.Debit]}
      />
      <DownloadPaymentApplicationReportModal
        open={showPaymentApplicationModal}
        setOpen={setShowPaymentApplicationModal}
      />
      <DownloadUnbilledAndUninvoicedOrdersReportModal
        open={showUnbilledRevenueModal}
        setOpen={setShowUnbilledRevenueModal}
        reportType={ReportType.UnbilledRevenue}
      />
      <DownloadUnbilledAndUninvoicedOrdersReportModal
        open={showCompletedUninvoicedModal}
        setOpen={setShowCompletedUninvoicedModal}
        reportType={ReportType.UninvoicedOrders}
      />
      <DownloadInvoiceAgingReportModal
        isOpen={showDownloadAgingModal}
        setIsOpen={setShowDownloadAgingModal}
        setShowSendAccountingReportsSuccessMessage={
          setShowSendAccountingReportsSuccessMessage
        }
        setShowSendAccountingReportsErrorMessage={
          setShowSendAccountingReportsErrorMessage
        }
        type={currAgingReportType}
      />
      <DownloadInvoiceAgingReportModalOld
        isOpen={showDownloadAgingModalOld}
        setIsOpen={setShowDownloadAgingModalOld}
        setShowSendAccountingReportsSuccessMessage={
          setShowSendAccountingReportsSuccessMessage
        }
        setShowSendAccountingReportsErrorMessage={
          setShowSendAccountingReportsErrorMessage
        }
        type={currAgingReportType}
      />
      <DownloadAccessorialDetailsReportModal
        isOpen={showAccessorialDetailsModal}
        setIsOpen={setShowAccessorialDetailsModal}
      />
      <DownloadCheckRegisterReportModal
        isOpen={showDownloadCheckRegisterReportModal}
        setIsOpen={setShowDownloadCheckRegisterReportModal}
      />
      <DownloadAverageDaysToPayReportModal
        isOpen={showDownloadAverageDaysToPayReportModal}
        setIsOpen={setShowDownloadAverageDaysToPayReportModal}
      />
      <DownloadIncomeAnalysisReportModal
        isOpen={showIncomeAnalysisModal}
        setIsOpen={setShowIncomeAnalysisModal}
      />
      <DownloadNetSalesReportModal
        isOpen={showDownloadNetSalesReportModal}
        setIsOpen={setShowDownloadNetSalesReportModal}
      />
      <DownloadUnappliedPaymentsReportModal
        open={showDownloadUnappliedPaymentsReportModal}
        setOpen={setShowDownloadUnappliedPaymentsReportModal}
      />
      <DownloadGLCodeReportModal
        isOpen={showGLCodeReportModal}
        setIsOpen={setShowGLCodeReportModal}
      />
      <DownloadOnTimePerformanceReportModal
        isOpen={showDownloadOnTimePerformanceReportModal}
        setIsOpen={setShowDownloadOnTimePerformanceReportModal}
      />
      <Typography variant="h6"> Download: </Typography>
      <TableContainer>
        <Table sx={{ width: '50%' }} size="small" aria-label="simple table">
          <TableBody>
            <Divider />
            {downloadableReports.map((item) => (
              <>
                <ReportRow report={item} />
                <Divider />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default AccountingReports;
