import CloseIcon from '@mui/icons-material/Close';
import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Box,
  Typography,
  IconButton,
  Modal,
  Button,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import useStyles from '../../../../../common/components/general-styles';
import {
  InvoiceSendJobBatchFragment,
  InvoiceSendJobStatus,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import InvoiceSendJobList from './invoice-send-job-list';
import InvoiceSendJobProgress from './invoice-send-job-progress';

const InvoiceSendJobsModal = ({
  selectedInvoiceSendJobBatch,
  setSelectedInvoiceSendJobBatch,
  initialStatus,
}: {
  selectedInvoiceSendJobBatch: InvoiceSendJobBatchFragment | undefined;
  setSelectedInvoiceSendJobBatch: Dispatch<
    SetStateAction<InvoiceSendJobBatchFragment | undefined>
  >;
  initialStatus?: InvoiceSendJobStatus;
}) => {
  const downloadInvoiceBatchDocument = useInvoicesStore(
    (state) => state.downloadInvoiceBatchDocument,
  );
  const styles = useStyles();
  const onClose = () => {
    setSelectedInvoiceSendJobBatch(undefined);
  };

  const invoicesBackupDocument =
    selectedInvoiceSendJobBatch?.invoiceBatchDocuments.invoicesBackupDocument;
  const invoicesCombinedDocument =
    selectedInvoiceSendJobBatch?.invoiceBatchDocuments.invoicesCombinedDocument;
  const invoicesZipDocument =
    selectedInvoiceSendJobBatch?.invoiceBatchDocuments.invoicesZipDocument;

  return (
    <Modal
      open={!isNil(selectedInvoiceSendJobBatch)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose}
    >
      <Box sx={[styles.modal, { width: '95vw', height: '95vh' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="caption">
              {sentenceCase(selectedInvoiceSendJobBatch?.type ?? '')} started at{' '}
              {dayjs(selectedInvoiceSendJobBatch?.createdAt).format(
                'MM/DD/YY hh:mm a',
              )}
            </Typography>
            {!isNil(selectedInvoiceSendJobBatch) && (
              <InvoiceSendJobProgress
                invoiceSendJobBatchProgress={
                  selectedInvoiceSendJobBatch.invoiceSendJobBatchProgress
                }
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="right"
            >
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  if (!isNil(invoicesZipDocument)) {
                    downloadInvoiceBatchDocument(invoicesZipDocument);
                  }
                }}
                disabled={isNil(invoicesZipDocument)}
              >
                Download invoices
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  if (!isNil(invoicesCombinedDocument)) {
                    downloadInvoiceBatchDocument(invoicesCombinedDocument);
                  }
                }}
                disabled={isNil(invoicesCombinedDocument)}
              >
                Download combined pdf
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  if (!isNil(invoicesBackupDocument)) {
                    downloadInvoiceBatchDocument(invoicesBackupDocument);
                  }
                }}
                disabled={isNil(invoicesBackupDocument)}
              >
                Download backups
              </Button>
              <IconButton onClick={onClose} sx={{ float: 'right' }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {!isNil(selectedInvoiceSendJobBatch) && (
              <InvoiceSendJobList
                selectedInvoiceSendJobBatchUuid={
                  selectedInvoiceSendJobBatch.uuid
                }
                initialStatus={initialStatus}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default InvoiceSendJobsModal;
