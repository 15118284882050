import { shallow } from 'zustand/shallow';
import { useCreateInvoiceMutation } from '../../../../generated/graphql';
import useInvoicesStore from '../../invoices-store';

const useInvoices = () => {
  const [setShouldRefreshInvoiceList] = useInvoicesStore(
    (state) => [state.setShouldRefreshInvoiceList],
    shallow,
  );
  const [createInvoiceMutation] = useCreateInvoiceMutation();

  const createInvoice = async ({
    contactUuid,
    shipmentUuids,
    invoiceDate,
  }: {
    contactUuid: string;
    shipmentUuids?: string[];
    invoiceDate?: Date;
  }) => {
    const res = await createInvoiceMutation({
      variables: {
        createInvoiceInput: {
          invoiceCreateInput: {
            billToContactUuid: contactUuid,
            associatedShipmentUuids: shipmentUuids ?? [],
            customChargeCreateInputs: [],
            invoiceDate,
          },
        },
      },
    });
    setShouldRefreshInvoiceList(true);
    return res;
  };

  return { createInvoice };
};

export default useInvoices;
