import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Alert,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Snackbar,
  Stack,
} from '@mui/material';
import { isNil } from 'lodash';
import { memo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { DetailedLineHaulManifestFragment } from '../../../generated/graphql';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import DeleteTruckloadModal from './delete-truckload-modal';
import PrintLineHaulManifestsModal from './print-manifests/print-line-haul-manifests-modal';

const ManifestsContextMenu = ({
  manifests,
}: {
  manifests: DetailedLineHaulManifestFragment[];
}) => {
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const [actionFailedAlert, setActionFailedAlert] = useState<
    string | undefined
  >(undefined);
  const [actionSucceededAlert, setActionSucceededAlert] = useState<
    string | undefined
  >(undefined);
  const dropdownMenuOpen = Boolean(dropdownMenuRef);

  const [showPrintManifestsModal, setShowPrintManifestsModal] =
    useState<boolean>(false);
  const [showDeleteTruckloadModal, setShowDeleteTruckloadModal] =
    useState(false);
  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };

  const { stopSelectingManifests, departManifests } =
    useLineHaulDispatchActions();

  const [selectedManifestUuids] = useLineHaulDispatchStore(
    (state) => [
      state.selectedManifestUuids,
      manifests.filter((m) => state.selectedManifestUuids.includes(m.uuid)),
    ],
    shallow,
  );

  const [selectingManifests, setSelectingManifests] = useLineHaulDispatchStore(
    (state) => [state.selectingManifests, state.setSelectingManifests],
    shallow,
  );

  const markManifestsAsDeparted = async () => {
    if (selectingManifests) {
      await departManifests({ uuids: selectedManifestUuids });
      setActionSucceededAlert(
        `${selectedManifestUuids.length} manifests were departed successfully`,
      );
    } else {
      await departManifests({ uuids: selectedManifestUuids });

      setActionSucceededAlert(
        `${manifests.length} manifests were departed successfully`,
      );
    }
  };

  return (
    <>
      <PrintLineHaulManifestsModal
        open={showPrintManifestsModal}
        setOpen={setShowPrintManifestsModal}
        manifestUuids={
          selectingManifests
            ? selectedManifestUuids
            : manifests.map((m) => m.uuid)
        }
      />
      <DeleteTruckloadModal
        open={showDeleteTruckloadModal}
        setOpen={setShowDeleteTruckloadModal}
        manifestUuids={selectedManifestUuids}
      />
      <Stack direction="row" alignItems="center">
        <Button
          color="info"
          onClick={(e) => {
            setDropdownMenuRef(e.currentTarget);
          }}
          // sx={{ minWidth: 0 }}
          endIcon={<ArrowDropDownIcon />}
        >
          {selectingManifests
            ? `Manage (${selectedManifestUuids.length})`
            : 'Manage'}
        </Button>
        {selectingManifests && (
          <Button
            variant="outlined"
            onClick={() => {
              stopSelectingManifests();
            }}
          >
            Done
          </Button>
        )}
      </Stack>
      <Menu
        anchorEl={dropdownMenuRef}
        open={dropdownMenuOpen}
        onClose={closeDropdownMenu}
      >
        <MenuList dense sx={{ py: 0 }}>
          <MenuItem
            onClick={() => {
              closeDropdownMenu();
              setShowPrintManifestsModal(true);
            }}
          >
            Print{' '}
            {selectingManifests ? `${selectedManifestUuids.length}` : 'all'}{' '}
            manifests
          </MenuItem>
          <MenuItem
            onClick={async () => {
              closeDropdownMenu();
              markManifestsAsDeparted();
            }}
          >
            Mark{' '}
            {selectingManifests ? `${selectedManifestUuids.length}` : 'all'} as
            departed
          </MenuItem>
          {!selectingManifests && (
            <MenuItem
              onClick={() => {
                closeDropdownMenu();
                setSelectingManifests(true);
              }}
            >
              Select manifests
            </MenuItem>
          )}
          {selectingManifests && selectedManifestUuids.length > 0 && (
            <MenuItem
              onClick={() => {
                closeDropdownMenu();
                setShowDeleteTruckloadModal(true);
              }}
            >
              Delete manifests
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <Stack position="relative">
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!isNil(actionFailedAlert)}
          sx={{ maxWidth: '400px' }}
        >
          <Alert
            onClose={() => setActionFailedAlert(undefined)}
            severity="error"
          >
            {actionFailedAlert}
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!isNil(actionSucceededAlert)}
          sx={{
            width: '400px',
          }}
        >
          <Alert onClose={() => setActionSucceededAlert(undefined)}>
            {actionSucceededAlert}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default memo(ManifestsContextMenu);
