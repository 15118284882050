import isEqual from 'lodash/isEqual';
import { useRef } from 'react';

/**
 * Hook to memoize a value so that it maintains its identity across renders
 * as long as it is equal (as determined by a deep comparison) to the previous value.
 * Returns the new value if it has changed, or the previous value if not.
 * Uses lodash's isEqual for deep comparison.
 *
 * @param value - The value to memoize.
 * @returns The current or most recent value.
 */
const useStableIdentity = <T>(value: T): T => {
  const valueRef = useRef<T>(value);

  // Synchronous comparison and update
  if (!isEqual(valueRef.current, value)) {
    valueRef.current = value;
  }

  return valueRef.current;
};

export default useStableIdentity;
