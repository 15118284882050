import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { RecurringOrderTemplateFragment } from '../../../generated/graphql';
import {
  getFrequencyInformation,
  getTemplateStatus,
} from './recurring-order-template-utils';

interface ConfirmCreateOrdersFromTemplatesProps {
  open: boolean;
  onCreateOrders: () => Promise<void>;
  onClose: () => void;
  templates: RecurringOrderTemplateFragment[];
}

const ConfirmCreateOrdersFromTemplates: React.FC<
  ConfirmCreateOrdersFromTemplatesProps
> = ({
  open,
  onCreateOrders,
  onClose,
  templates,
}: ConfirmCreateOrdersFromTemplatesProps) => {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Manually create orders</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to manually create orders from these recurring
          templates?
        </DialogContentText>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>HAWB</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Frequency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates
                ?.reverse() // Display most recent first
                .map((template: RecurringOrderTemplateFragment) => (
                  <TableRow
                    key={template.uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {template.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {template.standardOrderFields.shipperBillOfLadingNumber}
                    </TableCell>
                    <TableCell>
                      {template?.billingPartyContact?.displayName ?? 'N/A'}
                    </TableCell>
                    <TableCell>
                      {getTemplateStatus(
                        template?.recurringOrderFrequency ?? undefined,
                      )}
                    </TableCell>
                    <TableCell>
                      {getFrequencyInformation(
                        template?.recurringOrderFrequency ?? undefined,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onCreateOrders}>
          Create orders
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCreateOrdersFromTemplates;
