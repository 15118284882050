import { HighlightOff } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import useDocuments from '../../../common/react-hooks/use-documents';
import useStyles from '../end-of-day-styles';
import { DocViewerDocument } from '../types/doc-viewer-document';
import { DocumentPreview } from './document-preview';

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
`);

type DocumentPreviewCardProps = {
  doc: DocViewerDocument;
  onDeleteDocument?: (uuid: string) => void;
  handleOpenModal: (doc: DocViewerDocument) => void;
  canModifyDocuments?: boolean;
  cardHeight: string;
  colSize: number;
  hoveredDoc: string | undefined;
  setHoveredDoc: Dispatch<SetStateAction<string | undefined>>;
};

const DocumentPreviewCard = ({
  doc,
  onDeleteDocument,
  handleOpenModal,
  canModifyDocuments,
  cardHeight,
  colSize,
  hoveredDoc,
  setHoveredDoc,
}: DocumentPreviewCardProps) => {
  const cardStyles = useStyles();
  const { getDocumentTypeCopy, loading } = useDocuments();

  if (loading) {
    return null;
  }

  return (
    <Grid item xs={colSize}>
      <Card
        variant="outlined"
        sx={[cardStyles.cardHover, { height: cardHeight, overflowY: 'auto' }]}
        onClick={() => handleOpenModal(doc)}
        onMouseEnter={() => setHoveredDoc(doc.uuid)}
        onMouseLeave={() => setHoveredDoc(undefined)}
      >
        <CardContentSpecialPadding>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: 'gray',
                  marginBottom: '5px',
                }}
              >
                {getDocumentTypeCopy({ documentType: doc.docType })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {!isNil(onDeleteDocument) && canModifyDocuments === true && (
                <Fade in={doc.uuid === hoveredDoc} timeout={0}>
                  <IconButton
                    sx={{
                      float: 'right',
                      padding: '2px',
                      transform: 'translate(5px, -5px)',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteDocument(doc.uuid);
                    }}
                  >
                    <HighlightOff />
                  </IconButton>
                </Fade>
              )}
            </Grid>
          </Grid>
          <DocumentPreview doc={doc} />
        </CardContentSpecialPadding>
      </Card>
    </Grid>
  );
};

export default DocumentPreviewCard;
