import { Box, Button, Stack, Typography } from '@mui/material';

export enum ArchiveableEntity {
  CUSTOMER = 'customer',
  DRIVER = 'driver',
  EQUIPMENT = 'equipment',
  HOLD_REASON = 'hold reason',
  SERVICE = 'service level',
  USER = 'user',
  LINE_HAUL_STATION = 'line haul terminal',
  LINE_HAUL_LANE = 'line haul lane',
}

const ArchiveActionComponent = ({
  entityType,
  isActive,
  handleArchive,
  handleUnarchive,
}: {
  entityType: ArchiveableEntity;
  isActive: boolean;
  handleArchive: () => void;
  handleUnarchive: () => void;
}) => {
  if (!isActive) {
    return (
      <Stack gap={2}>
        <Typography variant="h6">Un-archive this {entityType}</Typography>
        <Typography>This {entityType} is archived.</Typography>
        <Box>
          <Button onClick={handleUnarchive} variant="contained">
            Un-archive
          </Button>
        </Box>
      </Stack>
    );
  }
  return (
    <Stack gap={2}>
      <Typography variant="h6">Archive this {entityType}</Typography>
      <Typography>
        This {entityType} will still show up in reports, but will no longer be
        seen in views like dispatch, orders, etc. You can always un-archive by
        coming back to this page.
      </Typography>
      <Box>
        <Button onClick={handleArchive} variant="contained" color="error">
          Archive
        </Button>
      </Box>
    </Stack>
  );
};

export default ArchiveActionComponent;
