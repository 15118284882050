import { Scalar } from 'shared/units/scalar';
import * as yup from 'yup';

import { BillingMethod } from '../../../../../common/types';
import { FreightBillingMethod } from '../../../../../generated/graphql';
import { fuelChargeSchema } from './fuel-charge-schema';

export const freightChargeSchema = yup.object({
  billingMethod: yup
    .mixed<FreightBillingMethod>()
    .oneOf(Object.values(FreightBillingMethod))
    .required(),
  // This can represent currency or a count. When currency, this represents a
  // dollar amount
  quantity: yup.number().required().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  rate: yup.number().required().nullable(),
  discountRate: yup.mixed<Scalar>().nullable(),
  totalCharge: yup.number().required(),
  tariffUuid: yup.string().required().nullable(),
  fuelCharge: fuelChargeSchema,
  uuid: yup.string().required(),
  description: yup.string().nullable(),
  authoCode: yup.string().nullable(),
  settlementPercentageRate: yup.number().required().nullable(),
  settlementFlatRate: yup.number().required().nullable(),
  settlementBillingMethod: yup
    .mixed<BillingMethod>()
    .oneOf(Object.values(BillingMethod))
    .required()
    .nullable(),
  deductionTotal: yup.number().defined(),
  errorMessage: yup.string().defined().nullable(),
});
