import { Stack } from '@mui/material';
import fileDownload from 'js-file-download';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import DateDropdownPicker, {
  DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { Option } from '../../../../../common/filters/types';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import {
  GlCodeReportPdfInput,
  useGlCodeReportPdfLazyQuery,
} from '../../../../../generated/graphql';
import PalletModal from '../../../../../pallet-ui/modal/pallet-modal-old';
import useInvoicesStore from '../../../invoices-store';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';

interface DownloadGLCodeReportModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DownloadGLCodeReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadGLCodeReportModalProps) => {
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const { terminalsEnabled } = useTerminals({});

  const [getGLCodeReportPdf, { loading: reportLoading }] =
    useGlCodeReportPdfLazyQuery();

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const resetFilters = () => {
    setDateOption(accountingReportDefaultDateOption);
  };

  const glCodeReportPdfInput: GlCodeReportPdfInput = useMemo(() => {
    return {
      startDate: dateOption.startDate,
      endDate: dateOption.endDate,
      terminalUuid: terminalOption?.value,
    };
  }, [dateOption, terminalOption]);

  const startDownload = async () => {
    const completeDownload = createFileDownload();
    const pdfRes = await getGLCodeReportPdf({
      variables: {
        glCodeReportPdfInput,
      },
    });

    const presignedGetUrl = pdfRes.data?.glCodeReportPdf.url;
    const fileName = pdfRes.data?.glCodeReportPdf.fileName;

    if (!isNil(presignedGetUrl) && !isNil(fileName)) {
      const getFileRes = await fetch(presignedGetUrl, { cache: 'no-cache' });

      const blob = await getFileRes.blob();

      fileDownload(blob, fileName);
    }

    resetFilters();
    completeDownload();
  };

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-gl-code-report',
    logData: {
      glCodeReportPdfInput,
    },
  });

  return (
    <PalletModal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title="Download GL Code Report"
      actions={{
        bottomRight: [
          {
            onClick: startDownloadWithMeasurement,
            children: 'Download PDF',
            disabled: reportLoading,
          },
        ],
      }}
    >
      <Stack width="50%" gap={1}>
        <DateDropdownPicker
          filterTitle="Invoice Date"
          dateOption={dateOption}
          setDateOption={setDateOption}
        />
        {terminalsEnabled && (
          <TerminalFilterButton
            prefixText="Terminal"
            selectedOption={terminalOption}
            handleChange={(option: Option | null | undefined) => {
              setTerminalOption(option ?? undefined);
            }}
            displayCode
            includeInactiveTerminals
          />
        )}
      </Stack>
    </PalletModal>
  );
};

export default DownloadGLCodeReportModal;
