import { Chip, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { Size } from '../types';
import { chooseForegroundColor } from '../utils/colors';

const ColoredChip = ({
  label,
  size = Size.sm,
  color,
  dot = false,
}: {
  label: string;
  size?: Size;
  color?: string | null | undefined;
  dot?: boolean;
}) => {
  const muiSize = size === Size.xs ? Size.sm : size;
  if (dot) {
    return (
      <Tooltip title={label}>
        <Chip
          size={muiSize}
          label=""
          sx={{
            height: 7,
            width: 7,
            backgroundColor: color,
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Chip
      size={muiSize}
      label={label}
      sx={{
        height: size === Size.xs ? 14 : undefined,
        fontSize: size === Size.xs ? 10 : undefined,
        backgroundColor: color,
        color: !isNil(color) ? chooseForegroundColor(color) : undefined,
      }}
    />
  );
};

export { ColoredChip };
