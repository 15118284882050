import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import { useDebounce } from 'use-debounce';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import TerminalFilterButton from '../../../../common/components/terminal-filter-button';
import useMe from '../../../../common/react-hooks/use-me';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  UnifiedSearchQuery,
  useUnifiedSearchLazyQuery,
} from '../../../../generated/graphql';
import SearchResultsTable from './search-result-tables/search-results-table';
import useUnifiedSearchStore from './unified-search-store';
import { getRouteString } from './utils';

export enum UnifiedSearchTab {
  ORDERS = 'ORDERS',
  EDI = 'EDI',
  EMAILS = 'EMAILS',
  QUOTES = 'QUOTES',
  INVOICES = 'INVOICES',
}

const countForTab = (tab: UnifiedSearchTab, data: UnifiedSearchQuery) => {
  const { unifiedSearch } = data;
  switch (tab) {
    case UnifiedSearchTab.ORDERS:
      return unifiedSearch.orders.totalCount ?? 0;
    case UnifiedSearchTab.EMAILS:
      return unifiedSearch.unacceptedEmailOrders.totalCount ?? 0;
    case UnifiedSearchTab.EDI:
      return unifiedSearch.unacceptedEdiAndApiOrders.totalCount ?? 0;
    case UnifiedSearchTab.QUOTES:
      return unifiedSearch.quotes.totalCount ?? 0;
    case UnifiedSearchTab.INVOICES:
      return unifiedSearch?.invoices?.totalCount ?? 0;
    default:
      return exhaustive(tab);
  }
};

const UnifiedSearch = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const abortController = useRef<AbortController | null>(null);
  const searchInputRef = useRef<HTMLInputElement>();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [focusedRowIndex, setFocusedRowIndex] = useState<number>(-1);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const { userPermissions } = useUserRoles();

  const { companyConfiguration } = useMe();
  const { canRead: canReadOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { canRead: canReadEmailOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EmailOrders,
  );
  const { canRead: canReadEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );
  const { canRead: canReadQuotes } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Quote,
  );
  const { canRead: canReadInvoices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Invoices,
  );

  useHotkeys(['Control+k', 'Meta+k'], async (e) => {
    e.preventDefault();
    searchInputRef.current?.focus();
  });

  useEffect(() => {
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 10);
  }, []);

  const [
    customerOption,
    setCustomerOption,
    originTerminalOption,
    setOriginTerminalOption,
    destinationTerminalOption,
    setDestinationTerminalOption,
    searchText,
    setSearchText,
    hasError,
    setHasError,
    orders,
    unacceptedEmailOrders,
    unacceptedEdiAndApiOrders,
    quotes,
    invoices,
    setSearchResultData,
  ] = useUnifiedSearchStore(
    (state) => [
      state.customerOption,
      state.setCustomerOption,
      state.originTerminalOption,
      state.setOriginTerminalOption,
      state.destinationTerminalOption,
      state.setDestinationTerminalOption,
      state.searchText,
      state.setSearchText,
      state.hasError,
      state.setHasError,
      state.ordersState.data,
      state.unacceptedEmailOrdersState.data,
      state.unacceptedEdiAndApiOrdersState.data,
      state.quotesState.data,
      state.invoicesState.data,
      state.setSearchResultData,
    ],
    shallow,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedSearchText] = useDebounce(searchText.trim(), 300);

  const [currentTab, setCurrentTab] = useLocalStorageState<UnifiedSearchTab>(
    'unified_search_tab',
    {
      defaultValue: UnifiedSearchTab.ORDERS,
    },
  );

  const [searchUnified] = useUnifiedSearchLazyQuery();

  const jumpToNonZeroTab = (data: UnifiedSearchQuery) => {
    if (countForTab(currentTab, data) > 0) {
      return;
    }
    for (const tab of Object.values(UnifiedSearchTab)) {
      if (countForTab(tab, data) > 0) {
        setCurrentTab(tab);
        return;
      }
    }
  };

  const handleSearch = async ({
    input,
    contactUuid,
    originTerminalUuid,
    destinationTerminalUuid,
  }: {
    input: string;
    contactUuid: string | undefined;
    originTerminalUuid: string | undefined;
    destinationTerminalUuid: string | undefined;
  }) => {
    if (input.length > 0) {
      setLoading(true);
      const newAbortController = new AbortController();
      abortController.current = newAbortController;
      const res = await searchUnified({
        variables: {
          unifiedSearchInput: {
            searchText: input,
            contactUuid,
            originTerminalUuid,
            destinationTerminalUuid,
            ordersConnectionArgs: {
              first: 10,
            },
            unacceptedEmailOrdersConnectionArgs: {
              first: 10,
            },
            unacceptedEdiAndApiOrdersConnectionArgs: {
              first: 10,
            },
            quotesConnectionArgs: {
              first: 10,
            },
            invoicesConnectionArgs: {
              first: 10,
            },
          },
        },
        context: {
          fetchOptions: {
            signal: newAbortController.signal,
          },
        },
      });
      if (!isNil(res.error) || isNil(res.data)) {
        setHasError(true);
        setSearchResultData(null);
      } else {
        setHasError(false);
        jumpToNonZeroTab(res.data);
        setSearchResultData(res.data);
      }
    } else {
      setHasError(false);
      setSearchResultData(null);
    }
    setFocusedRowIndex(-1);
    setLoading(false);
  };

  useEffect(() => {
    handleSearch({
      input: debouncedSearchText,
      contactUuid: customerOption?.value,
      originTerminalUuid: originTerminalOption?.value,
      destinationTerminalUuid: destinationTerminalOption?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchText,
    customerOption,
    originTerminalOption,
    destinationTerminalOption,
  ]);

  useEffect(() => {
    if (hasError) {
      setErrorSnackbarOpen(true);
    }
  }, [hasError]);

  const tableData = () => {
    switch (currentTab) {
      case UnifiedSearchTab.ORDERS:
        return {
          header: ['Order #', 'HAWB', 'Source', 'Customer', 'Next Route'],
          columnWidths: [120, 150, 150, 200, undefined],
          data: orders?.edges.map((order): [string, ReactNode[]] => {
            return [
              order.node.uuid,
              [
                order.node.name,
                order.node.standardOrderFields.shipperBillOfLadingNumber ?? '',
                sentenceCase(order.node.source),
                order.node.billingPartyContact.displayName,
                getRouteString({
                  startStop: order.node.startStop,
                  endStop: order.node.endStop,
                }),
              ],
            ];
          }),
        };
      case UnifiedSearchTab.EMAILS:
        return {
          header: ['Order #', 'HAWB', 'Customer', 'Email'],
          columnWidths: [120, 150, 200, 300],
          data: unacceptedEmailOrders?.edges.map(
            (unacceptedEmailOrder): [string, ReactNode[]] => [
              unacceptedEmailOrder.node.order?.uuid ?? '',
              [
                unacceptedEmailOrder.node.order?.name ?? '',
                unacceptedEmailOrder.node.order?.standardOrderFields
                  .shipperBillOfLadingNumber ?? '',
                unacceptedEmailOrder.node.order?.billingPartyContact
                  .displayName ?? '',
                <Stack key={unacceptedEmailOrder.node.uuid}>
                  <Typography color="text.secondary" variant="caption">
                    {
                      unacceptedEmailOrder.node.scannedOrder
                        .ingestedOutlookEmailDetails?.fromAddress
                    }
                  </Typography>
                  <Typography variant="caption">
                    {
                      unacceptedEmailOrder.node.scannedOrder
                        ?.ingestedOutlookEmailDetails?.subject
                    }
                  </Typography>
                </Stack>,
              ],
            ],
          ),
        };
      case UnifiedSearchTab.EDI:
        return {
          header: ['Order #', 'HAWB', 'MAWB', 'Customer'],
          columnWidths: [120, 150, 150, undefined],
          data: unacceptedEdiAndApiOrders?.edges.map(
            (unacceptedEdiAndApiOrder): [string, ReactNode[]] => [
              unacceptedEdiAndApiOrder.node.uuid,
              [
                unacceptedEdiAndApiOrder.node.name ?? '',
                unacceptedEdiAndApiOrder.node.standardOrderFields
                  .shipperBillOfLadingNumber ?? '',
                unacceptedEdiAndApiOrder.node.standardOrderFields
                  .masterAirwayBillOfLadingNumber ?? '',
                unacceptedEdiAndApiOrder.node.billingPartyContact.displayName ??
                  '',
              ],
            ],
          ),
        };
      case UnifiedSearchTab.QUOTES:
        return {
          header: ['Quote #', 'Customer'],
          columnWidths: [200, undefined],
          data: quotes?.edges.map((quote): [string, ReactNode[]] => [
            quote.node.uuid,
            [quote.node.number, quote.node.billingPartyContact.displayName],
          ]),
        };
      case UnifiedSearchTab.INVOICES:
        return {
          header: ['Invoice #', 'Date', 'Customer', 'Total', 'Balance'],
          columnWidths: [120, 150, 150, 200, undefined],
          data: invoices?.edges.map((invoice): [string, ReactNode[]] => [
            invoice.node.uuid,
            [
              companyConfiguration?.useJournalNumberForInvoice === true
                ? invoice.node.journalNumber
                : invoice.node.name,
              dayjs(invoice.node.date).format('MM/DD/YYYY'),
              invoice.node.billToContact.displayName,
              invoice.node.invoiceTotal,
              currency(invoice.node.balanceInDollars ?? 0).format(),
            ],
          ]),
        };
      default:
        return exhaustive(currentTab);
    }
  };

  const selectRow = (uuid: string, openInNewTab?: boolean) => {
    let url = '';
    switch (currentTab) {
      case UnifiedSearchTab.ORDERS:
        url = `/orders/?orderUuid=${uuid}`;
        break;
      case UnifiedSearchTab.EMAILS:
        url = `/orders/inbound-messages/email/?orderUuid=${uuid}`;
        break;
      case UnifiedSearchTab.EDI:
        url = `/orders/inbound-messages/?orderUuid=${uuid}`;
        break;
      case UnifiedSearchTab.QUOTES:
        url = `/order-entry/quotes/${uuid}`;
        break;
      case UnifiedSearchTab.INVOICES: {
        const selectedInvoice = invoices?.edges.filter((invoice) => {
          return invoice.node.uuid === uuid;
        })[0]?.node;
        const invoiceName =
          companyConfiguration?.useJournalNumberForInvoice === true
            ? selectedInvoice?.journalNumber
            : selectedInvoice?.name;
        url = `/accounting/?invoiceUuid=${uuid}&invoiceName=${invoiceName}`;
        break;
      }
      default:
        exhaustive(currentTab);
    }
    if (openInNewTab === true) {
      window.open(url, '_blank');
    } else {
      setOpen(false);
      navigate(url);
    }
  };

  const onArrow = (e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
    let length = 0;
    switch (currentTab) {
      case UnifiedSearchTab.ORDERS:
        length = orders?.edges.length ?? 0;
        break;
      case UnifiedSearchTab.EMAILS:
        length = unacceptedEmailOrders?.edges.length ?? 0;
        break;
      case UnifiedSearchTab.EDI:
        length = unacceptedEdiAndApiOrders?.edges.length ?? 0;
        break;
      case UnifiedSearchTab.QUOTES:
        length = quotes?.edges.length ?? 0;
        break;
      case UnifiedSearchTab.INVOICES:
        length = invoices?.edges.length ?? 0;
        break;
      default:
        exhaustive(currentTab);
    }
    const newIndex =
      e.code === 'ArrowDown'
        ? Math.max(0, (focusedRowIndex + 1) % length)
        : Math.max(0, (focusedRowIndex - 1) % length);
    setFocusedRowIndex(newIndex);
    e.preventDefault();
  };

  useHotkeys(['ArrowDown', 'ArrowUp'], onArrow);

  return (
    <Stack
      sx={{
        pb: 1,
      }}
    >
      <Stack>
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          message="Error while searching! Please try again and contact support if the issue persists."
          onClose={() => setErrorSnackbarOpen(false)}
        />
        <Stack
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.borderColor.main,
            position: 'relative',
            p: 1,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip title="Drag">
                <IconButton
                  sx={{ p: 0, mt: '-2px' }}
                  id="drag-icon"
                  size="small"
                >
                  <DragIndicatorIcon />
                </IconButton>
              </Tooltip>
              <Box sx={{ width: '20px', mr: 1 }}>
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <SearchIcon sx={{ color: theme.palette.grey.A400 }} />
                )}
              </Box>
            </Stack>
            <TextField
              inputRef={searchInputRef}
              variant="standard"
              size="small"
              placeholder="Search by order numbers, customer, address, email..."
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                  onArrow(e);
                } else if (e.key === 'Enter') {
                  document
                    ?.getElementById(`result-row-${focusedRowIndex}`)
                    ?.click();
                }
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              sx={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <ClearTextFieldButton
                        searchText={searchText}
                        handleClearSearchText={() => {
                          setSearchText('');
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{ border: 1, borderRadius: 1, px: 0.5 }}
                      >
                        esc
                      </Typography>
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          aria-label="email orders page tabs"
          sx={{ px: 1 }}
        >
          {canReadOrders && (
            <Tab
              value={UnifiedSearchTab.ORDERS}
              label={`Orders (${orders?.totalCount ?? '-'})`}
              onClick={() => {
                setCurrentTab(UnifiedSearchTab.ORDERS);
              }}
            />
          )}
          {canReadEdiApiOrders && (
            <Tab
              value={UnifiedSearchTab.EDI}
              label={`EDI (${unacceptedEdiAndApiOrders?.totalCount ?? '-'})`}
              onClick={() => {
                setCurrentTab(UnifiedSearchTab.EDI);
              }}
            />
          )}
          {canReadEmailOrders && (
            <Tab
              value={UnifiedSearchTab.EMAILS}
              label={`Emails (${unacceptedEmailOrders?.totalCount ?? '-'})`}
              onClick={() => {
                setCurrentTab(UnifiedSearchTab.EMAILS);
              }}
            />
          )}
          {canReadQuotes && (
            <Tab
              value={UnifiedSearchTab.QUOTES}
              label={`Quotes (${quotes?.totalCount ?? '-'})`}
              onClick={() => {
                setCurrentTab(UnifiedSearchTab.QUOTES);
              }}
            />
          )}
          {canReadInvoices && (
            <Tab
              value={UnifiedSearchTab.INVOICES}
              label={`Invoices (${invoices?.totalCount ?? '-'})`}
              onClick={() => {
                setCurrentTab(UnifiedSearchTab.INVOICES);
              }}
            />
          )}
        </Tabs>
        <Divider />
        <Stack direction="row" spacing={1} sx={{ p: 1, px: 1 }}>
          <CustomerFilterButton
            selectedOption={customerOption}
            handleChange={(option) => setCustomerOption(option ?? undefined)}
            isSmall
          />
          {terminalsEnabled && (
            <TerminalFilterButton
              selectedOption={originTerminalOption}
              handleChange={(option) => {
                setOriginTerminalOption(option ?? undefined);
              }}
              prefixText="Orig"
              displayCode
              includeInactiveTerminals={false}
              isSmall
            />
          )}
          {terminalsEnabled && (
            <TerminalFilterButton
              selectedOption={destinationTerminalOption}
              handleChange={(option) => {
                setDestinationTerminalOption(option ?? undefined);
              }}
              prefixText="Dest"
              displayCode
              includeInactiveTerminals={false}
              isSmall
            />
          )}
        </Stack>
        <SearchResultsTable
          focusedRowIndex={focusedRowIndex}
          headers={tableData().header}
          columnWidths={tableData().columnWidths}
          data={tableData().data ?? []}
          selectRow={selectRow}
          loading={loading}
        />
      </Stack>
      {hasError && (
        <Typography
          color="text.secondary"
          fontStyle="italic"
          sx={{ alignSelf: 'center' }}
        >
          Error while searching! Please try again and contact support if the
          issue persists.
        </Typography>
      )}
    </Stack>
  );
};

export default React.memo(UnifiedSearch);
