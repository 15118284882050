import {
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil, values } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import WarehouseLocationSelector from '../../../../../common/components/forms/warehouse-location-selector';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  ContactItemsItemFragment,
  FindStorageOrderFragment,
  StorageOrderDocument,
  StorageOrderStorageUnitFragment,
  UnitOfMeasure,
  useUpdateStorageUnitMutation,
} from '../../../../../generated/graphql';
import ItemFormModal from '../../../../items/item-form-modal';
import AddItemForm from '../inventory-items/add-item-form';
import AssignInventoryItemsRow from '../inventory-items/assign-inventory-items-row';
import AssignedItemRow from '../inventory-items/assigned-item-row';
import OSDForm from './osd-form';
import StorageUnitSpecs from './storage-unit-specs';

const useStyles = () => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  centeredRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface StorageUnitDetailsProps {
  storageOrder: FindStorageOrderFragment;
  storageUnit: StorageOrderStorageUnitFragment;
  items: ContactItemsItemFragment[];
  setUpdateStorageUnitSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;
  setUpdateStorageUnitErrorMessageVisible: Dispatch<SetStateAction<boolean>>;
}
const StorageUnitDetails = ({
  storageOrder,
  storageUnit,
  items,
  setUpdateStorageUnitSuccessMessageVisible,
  setUpdateStorageUnitErrorMessageVisible,
}: StorageUnitDetailsProps) => {
  const { companyTimezone } = useMe();
  const styles = useStyles();
  const [warehouseLocation, setWarehouseLocation] = useState<string>('');
  const [unitOfMeasure, setUnitOfMeasure] = useState<UnitOfMeasure>(
    UnitOfMeasure.Pallet,
  );

  const [isAssigningInventoryItems, setIsAssigningInventoryItems] =
    useState(false);
  const [selectedItem, setSelectedItem] =
    useState<ContactItemsItemFragment | null>(null);
  const [showItemFormModal, setShowItemFormModal] = useState(false);

  const [updateStorageUnit] = useUpdateStorageUnitMutation({
    refetchQueries: [StorageOrderDocument],
  });

  useEffect(() => {
    const location = storageUnit?.warehouseLocation;
    setWarehouseLocation(!isNil(location) ? location.uuid : '');
  }, [storageUnit]);

  useEffect(() => {
    const uom = storageUnit?.unitOfMeasure;
    setUnitOfMeasure(!isNil(uom) ? uom : UnitOfMeasure.Pallet);
  }, [storageUnit]);

  const handleWarehouseLocationChange = async (
    warehouseLocationUuid: string,
  ) => {
    setWarehouseLocation(warehouseLocationUuid);
    try {
      await updateStorageUnit({
        variables: {
          updateStorageUnitInput: {
            uuid: storageUnit.uuid,
            warehouseLocationUuid,
          },
        },
      });
      setUpdateStorageUnitSuccessMessageVisible(true);
    } catch (err) {
      setUpdateStorageUnitErrorMessageVisible(true);
    }
  };

  const handleUnitOfMeasureChange = async (
    e: SelectChangeEvent<UnitOfMeasure>,
  ) => {
    const newUnitOfMeasure = e.target.value as UnitOfMeasure;
    setUnitOfMeasure(newUnitOfMeasure);
    try {
      await updateStorageUnit({
        variables: {
          updateStorageUnitInput: {
            uuid: storageUnit.uuid,
            unitOfMeasure: newUnitOfMeasure,
          },
        },
      });
      setUpdateStorageUnitSuccessMessageVisible(true);
    } catch (err) {
      setUpdateStorageUnitErrorMessageVisible(true);
    }
  };

  const timeZone = companyTimezone ?? 'America/Los_Angeles';
  const markedOnHandDate = !isNil(storageUnit?.markedOnHandAt)
    ? dayjs(storageUnit?.markedOnHandAt).tz(timeZone).format('MM/DD/YYYY')
    : null;
  const inventoryLastVerifiedDate = !isNil(storageUnit?.inventoryLastVerifiedAt)
    ? dayjs(storageUnit?.inventoryLastVerifiedAt)
        .tz(timeZone)
        .format('MM/DD/YYYY')
    : null;
  const pickedDate = !isNil(storageUnit?.pickedAt)
    ? dayjs(storageUnit?.pickedAt).tz(timeZone).format('MM/DD/YYYY')
    : null;
  const outboundReferenceNumber = storageUnit?.outboundReferenceNumber ?? null;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={styles.rowContainer}>
          {!isNil(markedOnHandDate) && (
            <Grid item xs={3}>
              <Typography style={{ fontStyle: 'bold' }}>
                Marked on hand:
              </Typography>
              <Typography>{markedOnHandDate}</Typography>
            </Grid>
          )}
          {!isNil(inventoryLastVerifiedDate) && (
            <Grid item xs={3}>
              <Typography style={{ fontStyle: 'bold' }}>
                Inventory Verified:
              </Typography>
              <Typography>{inventoryLastVerifiedDate}</Typography>
            </Grid>
          )}
          {!isNil(pickedDate) && (
            <Grid item xs={3}>
              <Typography style={{ fontStyle: 'bold' }}>Picked:</Typography>
              <Typography>{pickedDate}</Typography>
            </Grid>
          )}
          {!isNil(outboundReferenceNumber) && (
            <Grid item xs={3}>
              <Typography style={{ fontStyle: 'bold' }}>
                Outbound ref #:
              </Typography>
              <Typography>{outboundReferenceNumber}</Typography>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid item xs={4}>
          <FormControl>
            <Typography>Location</Typography>
            <WarehouseLocationSelector
              warehouseUuid={storageOrder.warehouse.uuid}
              selectedWarehouseLocationUuid={warehouseLocation}
              onChange={(option) => handleWarehouseLocationChange(option.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl>
            <Typography>Unit of measure</Typography>
            <Select
              size="small"
              value={unitOfMeasure}
              onChange={handleUnitOfMeasureChange}
              required
            >
              {values(UnitOfMeasure).map((uom) => {
                return (
                  <MenuItem key={uom} value={uom}>
                    {sentenceCase(uom)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ ...styles.rowContainer, gap: 1 }}>
          <StorageUnitSpecs storageUnit={storageUnit} />
        </Grid>
        <Grid item xs={12} sx={{ ...styles.rowContainer, gap: 1 }}>
          <OSDForm storageUnit={storageUnit} />
        </Grid>
        <Grid item xs={12} sx={{ ...styles.rowContainer, gap: 1 }}>
          <AddItemForm
            items={items}
            storageOrder={storageOrder}
            storageUnit={storageUnit}
          />
        </Grid>
        {(!isEmpty(storageUnit.items) || isAssigningInventoryItems) && (
          <Grid item xs={12} sx={styles.centeredRow}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableCell>SKU</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Unit of Measure</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell />
                </TableHead>
                {isAssigningInventoryItems && !isNil(selectedItem) && (
                  <AssignInventoryItemsRow
                    storageOrderUuid={storageOrder.uuid}
                    storageUnitUuid={storageUnit.uuid}
                    item={selectedItem}
                    onComplete={() => {
                      setSelectedItem(null);
                      setIsAssigningInventoryItems(false);
                    }}
                    setUpdateStorageUnitSuccessMessageVisible={
                      setUpdateStorageUnitSuccessMessageVisible
                    }
                    setUpdateStorageUnitErrorMessageVisible={
                      setUpdateStorageUnitErrorMessageVisible
                    }
                  />
                )}
                {storageUnit.items?.map((itemWithAssignedCount) => {
                  return (
                    <AssignedItemRow
                      key={itemWithAssignedCount.item.uuid}
                      item={itemWithAssignedCount}
                      storageOrderUuid={storageOrder.uuid}
                      storageUnitUuid={storageUnit.uuid}
                      setUpdateStorageUnitSuccessMessageVisible={
                        setUpdateStorageUnitSuccessMessageVisible
                      }
                      setUpdateStorageUnitErrorMessageVisible={
                        setUpdateStorageUnitErrorMessageVisible
                      }
                    />
                  );
                })}
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <ItemFormModal
        contactUuid={storageOrder.contact.uuid}
        open={showItemFormModal}
        onClose={() => setShowItemFormModal(false)}
        onCreateItem={({ uuid }) => {
          const item = items.find((i) => i.uuid === uuid);
          if (isNil(item)) return;
          setSelectedItem(item);
          setIsAssigningInventoryItems(true);
        }}
        warehouseOverride={{
          label: storageOrder.warehouse.name,
          value: storageOrder.warehouse.uuid,
        }}
      />
    </>
  );
};

export default StorageUnitDetails;
