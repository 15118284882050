import WarningIcon from '@mui/icons-material/Warning';
import { Stack, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';

const RouteCardWarningText = ({ text }: { text: string }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <WarningIcon sx={{ fontSize: '10px', color: red['800'] }} />
      <Typography
        variant="caption"
        sx={{ fontWeight: 'bold' }}
        color={red['800']}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export { RouteCardWarningText };
