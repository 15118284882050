// eslint-disable-next-line no-restricted-imports
import { Autocomplete, AutocompleteProps } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { matchSorter, MatchSorterOptions } from 'match-sorter';

// This is a separate, nested type so that we can require
// filterStickyOption if there's a stickyOption provided
type StickyOptionProps<T> = {
  stickyOption: T;
  /**
   * Use `true` when the sticky option is a meaningful, selectable value
   * rather than an action like "Create new option"
   */
  filterStickyOption: boolean;
  stickyOptionPosition?: 'first' | 'last';
};

export type AutocompleteFuzzyProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
  matchSortOptions?: MatchSorterOptions<T>;
  stickyOptionProps?: StickyOptionProps<T>;
};

const AutocompleteFuzzy = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: AutocompleteFuzzyProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { matchSortOptions, stickyOptionProps, ...rest } = props;
  const { stickyOption, filterStickyOption, stickyOptionPosition } =
    stickyOptionProps ?? {};

  const defaultMatchSortOptions: MatchSorterOptions<T> = {
    threshold: matchSorter.rankings.CONTAINS,
  };
  const sortOptions = { ...defaultMatchSortOptions, ...matchSortOptions };

  const stickyOptionMatches = (inputValue: string) => {
    if (isNil(stickyOption)) {
      return false;
    }
    const result = matchSorter([stickyOption], inputValue, sortOptions);
    return !isEmpty(result);
  };

  /**
   * If we have a sticky option, we will add it to the top of the sorted list.
   * This is useful when we want to add a "Create new item" option to the top of the list.
   */
  const filterOptions = (options: T[], params: { inputValue: string }) => {
    const sortedResult = matchSorter(options, params.inputValue, sortOptions);
    if (isNil(stickyOption)) {
      return sortedResult;
    }
    const includeStickyOption =
      filterStickyOption !== true || stickyOptionMatches(params.inputValue);
    if (!includeStickyOption) {
      return sortedResult;
    }
    if (stickyOptionPosition === 'last') {
      return [...sortedResult, stickyOption];
    }
    return [stickyOption, ...sortedResult];
  };

  return <Autocomplete filterOptions={filterOptions} {...rest} />;
};

export default AutocompleteFuzzy;
