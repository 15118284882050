import { useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import { ChooseAppointmentView } from '../domains/reservation-system/components/choose-appointment-view';

// This page is for a contact person of a stop to choose an appointment using a link they were sent.
// The page is meant to be unauthenticated.
const ChooseAppointmentPage = () => {
  const { stopUuid } = useParams();
  if (isNotNilOrEmptyString(stopUuid)) {
    return <ChooseAppointmentView stopUuid={stopUuid} />;
  }
  return null;
};

export default ChooseAppointmentPage;
