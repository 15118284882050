import { Button, Fade, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import {
  RouteNameDocument,
  useDeleteRouteNameLocationMutation,
} from '../../../generated/graphql';

const RouteNameLocationRow = ({
  city,
  zipcode,
  uuid,
}: {
  city: string;
  zipcode: string;
  uuid: string;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [deleteRouteNameLocation] = useDeleteRouteNameLocationMutation({
    refetchQueries: [RouteNameDocument],
  });

  return (
    <TableRow
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <TableCell>{city}</TableCell>
      <TableCell>{zipcode}</TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <Button
            variant="contained"
            onClick={() => deleteRouteNameLocation({ variables: { uuid } })}
          >
            Delete
          </Button>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default RouteNameLocationRow;
