import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';

export type PlaceValues = Partial<{
  addressUuid: string;
  appointmentTime: string;
}> & { uuid: string };

const placeValuesAdapter = createEntityAdapter<PlaceValues>({
  selectId: (place) => place.uuid,
});

export const placeValuesSlice = createSlice({
  name: 'placeValues',
  initialState: placeValuesAdapter.getInitialState(),
  reducers: {
    addOnePlaceValues: placeValuesAdapter.addOne,
    updateOnePlaceValues: placeValuesAdapter.updateOne,
    removeOnePlaceValues: placeValuesAdapter.removeOne,
    setAllPlaceValues: placeValuesAdapter.setAll,
    upsertOnePlaceValues: placeValuesAdapter.upsertOne,
  },
});

export const { selectById: selectPlaceValuesById } =
  placeValuesAdapter.getSelectors((state: RootState) => state.placeValues);

const placeValuesReducer = placeValuesSlice.reducer;

export const {
  addOnePlaceValues,
  removeOnePlaceValues,
  updateOnePlaceValues,
  setAllPlaceValues,
  upsertOnePlaceValues,
} = placeValuesSlice.actions;

export default placeValuesReducer;
