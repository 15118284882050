import {
  ArrowLeft,
  ArrowRight,
  Grading,
  ExpandMore,
  Check,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Stack,
  Typography,
  Menu,
  MenuList,
  MenuItem,
  ButtonGroup,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import useStyles from '../../../common/components/general-styles';
import useInterval from '../../../common/react-hooks/use-interval';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  ScannedOrderFailureReason,
  ScannedOrderResultSourceFilter,
  ScannedOrderResultStatus,
  ScannedOrderResultType,
  useFirstScannedOrderResultInReviewQuery,
  useScannedOrderResultsLazyQuery,
} from '../../../generated/graphql';
import ScannedOrderRow from '../../scanned-orders/components/scanned-order-row';
import BillingPartyContactModal from './standard/billing-party-contact-modal';
import UploadModal from './upload-documents-for-order-ingestion';

const ORDER_PAGE_SIZE = 15;

const SCANNED_ORDER_HEADERS = [
  // '', // Checkboxes
  'Date created',
  'File name',
  'Reference #',
  'Customer',
  'Scan status',
  '', // Buttons
];

enum ScannedOrdersTab {
  IN_REVIEW = 'IN_REVIEW',
  DUPLICATES = 'DUPLICATES',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

type PaginationArgs = {
  first?: number | null | undefined;
  after?: string | null | undefined;
  last?: number | null | undefined;
  before?: string | null | undefined;
};

const getScannedOrderResultStatusesForTab = (
  tab: ScannedOrdersTab,
  // eslint-disable-next-line consistent-return
): ScannedOrderResultStatus[] => {
  // eslint-disable-next-line default-case
  switch (tab) {
    case ScannedOrdersTab.IN_REVIEW:
      return [
        ScannedOrderResultStatus.InProgress,
        ScannedOrderResultStatus.InReview,
      ];
    case ScannedOrdersTab.FAILED:
      return [ScannedOrderResultStatus.Failed];
    case ScannedOrdersTab.REJECTED:
      return [ScannedOrderResultStatus.Rejected];
    case ScannedOrdersTab.APPROVED:
      return [
        ScannedOrderResultStatus.Approved,
        ScannedOrderResultStatus.ManuallyEntered,
      ];
    case ScannedOrdersTab.DUPLICATES:
      return [ScannedOrderResultStatus.Duplicate];
  }
};

/* NOTE: Styles and structure here are largely copied from the CustomerFilterButton component.
 * If we generalize our styles / components, we should consider combining this and the many other components
 * that are similar.
 */
const ScannedOrderSourceFilterButton = ({
  sourceFilter,
  setSourceFilter,
}: {
  sourceFilter: ScannedOrderResultSourceFilter;
  setSourceFilter: (sourceFilter: ScannedOrderResultSourceFilter) => void;
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>Source:</Typography>
          <Typography sx={styles.filterValue}>
            {sentenceCase(sourceFilter)}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="customer-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          {[
            ScannedOrderResultSourceFilter.All,
            ScannedOrderResultSourceFilter.UploadedPdf,
            ScannedOrderResultSourceFilter.EmailIntegration,
          ].map((option) => (
            <MenuItem
              key={option}
              onClick={() => setSourceFilter(option)}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  sx={{
                    visibility: sourceFilter === option ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography sx={styles.menuText}>
                    {sentenceCase(option)}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default function ViewAllScannedOrdersPage() {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteScannedOrders, hasMasterPermission } =
    getPermissionsFlags(userPermissions, PermissionResource.ScannedOrders);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(ScannedOrdersTab.IN_REVIEW);
  const [counts, setCounts] = useState<{
    inProgress: number;
    inReview: number;
    failed: number;
    rejected: number;
    approved: number;
    duplicates: number;
  }>();
  const [getScannedOrderResults, { data: scannedOrderResultsData }] =
    useScannedOrderResultsLazyQuery();
  const {
    data: firstScannedOrderResultInReviewData,
    refetch: refetchFirstScannedOrderResultInReview,
  } = useFirstScannedOrderResultInReviewQuery();
  const [searchText, setSearchText] = useState('');
  // It's non-trivial to calculate the cursor in the opposite direction, so we
  // track it based on previous button navigation instead
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [showBillingPartyModal, setShowBillingPartyModal] = useState(false);
  const [showUploadPdfsModal, setShowUploadPdfsModal] = useState(false);
  const [lastPaginationArgs, setLastPaginationArgs] = useState<PaginationArgs>({
    first: ORDER_PAGE_SIZE,
  });
  const [searching, setSearching] = useState<boolean>(false);
  const [sourceFilter, setSourceFilter] =
    useState<ScannedOrderResultSourceFilter>(
      ScannedOrderResultSourceFilter.UploadedPdf,
    );

  const initialTab = searchParams.get('tab');
  useEffect(() => {
    if (initialTab === 'APPROVED') {
      setTab(ScannedOrdersTab.APPROVED);
    } else if (initialTab === 'IN_REVIEW') {
      setTab(ScannedOrdersTab.IN_REVIEW);
    }
    if (initialTab === 'FAILED') {
      setTab(ScannedOrdersTab.FAILED);
    }
    if (initialTab === 'REJECTED') {
      setTab(ScannedOrdersTab.REJECTED);
    }
    if (initialTab === 'DUPLICATES') {
      setTab(ScannedOrdersTab.DUPLICATES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [failureReasonFilter, setFailureReasonFilter] = useState<
    ScannedOrderFailureReason | undefined
  >();

  /**
   * Graphql pagination args
   * https://relay.dev/graphql/connections.htm
   */
  const fetchOrders = async ({
    first,
    last,
    before,
    after,
  }: PaginationArgs) => {
    const midnightToday = getCurrentTimeDefaultTimezone()
      .startOf('day')
      .toDate();
    await getScannedOrderResults({
      variables: {
        first,
        last,
        before,
        after,
        approvedAtAfter:
          tab === ScannedOrdersTab.APPROVED ? midnightToday : undefined,
        // Every tab except for the IN_REVIEW tab resets at midnight
        createdAtAfter:
          tab !== ScannedOrdersTab.IN_REVIEW &&
          tab !== ScannedOrdersTab.APPROVED &&
          tab !== ScannedOrdersTab.REJECTED &&
          searchText === ''
            ? midnightToday
            : undefined,
        rejectedAtAfter:
          tab === ScannedOrdersTab.REJECTED ? midnightToday : undefined,
        searchText,
        type: ScannedOrderResultType.Order,
        statuses: getScannedOrderResultStatusesForTab(tab),
        sourceFilter,
        failureReasonFilter,
      },
    });
    await refetchFirstScannedOrderResultInReview();
  };

  useEffect(() => {
    // Storing these in a state variable reduces the flickering on load
    if (!isNil(scannedOrderResultsData)) {
      setCounts({
        duplicates:
          scannedOrderResultsData.numberOfScannedOrderResultDuplicates,
        inProgress:
          scannedOrderResultsData.numberOfScannedOrderResultsInProgress,
        inReview: scannedOrderResultsData.numberOfScannedOrderResultsInReview,
        failed: scannedOrderResultsData.numberOfScannedOrderResultsFailed,
        rejected: scannedOrderResultsData.numberOfScannedOrderResultsRejected,
        approved: scannedOrderResultsData.numberOfScannedOrderResultsApproved,
      });
    }
  }, [scannedOrderResultsData]);

  useEffect(() => {
    fetchOrders({
      first: ORDER_PAGE_SIZE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, sourceFilter, failureReasonFilter]);

  // Poll every 10 seconds
  useInterval(() => fetchOrders(lastPaginationArgs), 10000);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <BillingPartyContactModal
        setIsOpen={setShowBillingPartyModal}
        open={showBillingPartyModal}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          m: '10px',
          pt: '5px',
        }}
      >
        <Stack direction="row" spacing={2}>
          {hasMasterPermission && (
            <ScannedOrderSourceFilterButton
              sourceFilter={sourceFilter}
              setSourceFilter={setSourceFilter}
            />
          )}

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            <TextField
              size="small"
              label="Search Orders"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{ width: '400px' }}
            />
            <Button
              variant="contained"
              disabled={searching}
              onClick={async () => {
                setSearching(true);
                await fetchOrders({ first: ORDER_PAGE_SIZE });
                setSearching(false);
              }}
            >
              Search
            </Button>
          </Box>
        </Stack>
        <Box
          sx={{
            alignItems: 'top',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Button
            onClick={() => setShowUploadPdfsModal(true)}
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
            color="secondary"
            disabled={!canWriteScannedOrders}
          >
            Create from PDFs
          </Button>
          <Button
            onClick={() => setShowBillingPartyModal(true)}
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
            data-cy="add-order-button"
            disabled={!canWriteScannedOrders}
          >
            Add order manually
          </Button>
        </Box>
      </Box>

      {hasMasterPermission && (
        <Box>
          <ButtonGroup
            disableElevation
            variant="outlined"
            size="small"
            aria-label="document-status-filters"
          >
            <Button
              variant={
                failureReasonFilter ===
                ScannedOrderFailureReason.LowDocumentQuality
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                setFailureReasonFilter(
                  ScannedOrderFailureReason.LowDocumentQuality,
                )
              }
            >
              Low document quality
            </Button>
            <Button
              variant={
                failureReasonFilter ===
                ScannedOrderFailureReason.NoHawbExtracted
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                setFailureReasonFilter(
                  ScannedOrderFailureReason.NoHawbExtracted,
                )
              }
            >
              No HAWB extracted
            </Button>
            <Button
              variant={
                failureReasonFilter === ScannedOrderFailureReason.Unknown
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                setFailureReasonFilter(ScannedOrderFailureReason.Unknown)
              }
            >
              Unknown
            </Button>
            <Button
              variant={
                failureReasonFilter === undefined ? 'contained' : 'outlined'
              }
              onClick={() => setFailureReasonFilter(undefined)}
            >
              All
            </Button>
          </ButtonGroup>
        </Box>
      )}

      <Box
        sx={{
          marginTop: 'auto',
          border: '1px solid',
          borderColor: 'divider',
          mb: 1,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={tab}
          onChange={(_, val) => setTab(val as ScannedOrdersTab)}
        >
          <Tab
            value={ScannedOrdersTab.IN_REVIEW}
            label={
              isNil(counts)
                ? `${sentenceCase(ScannedOrdersTab.IN_REVIEW)}`
                : `${sentenceCase(ScannedOrdersTab.IN_REVIEW)} (${
                    counts.inReview + counts.inProgress
                  })`
            }
          />
          <Tab
            value={ScannedOrdersTab.DUPLICATES}
            label={
              isNil(counts)
                ? `${sentenceCase(ScannedOrdersTab.DUPLICATES)}`
                : `${sentenceCase(ScannedOrdersTab.DUPLICATES)} (${
                    counts.duplicates
                  })`
            }
          />
          <Tab
            value={ScannedOrdersTab.FAILED}
            label={
              isNil(counts)
                ? `${sentenceCase(ScannedOrdersTab.FAILED)}`
                : `${sentenceCase(ScannedOrdersTab.FAILED)} (${counts.failed})`
            }
          />
          <Tab
            value={ScannedOrdersTab.REJECTED}
            label={
              isNil(counts)
                ? `${sentenceCase(ScannedOrdersTab.REJECTED)}`
                : `${sentenceCase(ScannedOrdersTab.REJECTED)} (${
                    counts.rejected
                  })`
            }
          />
          <Tab
            value={ScannedOrdersTab.APPROVED}
            label={
              isNil(counts)
                ? `${sentenceCase(ScannedOrdersTab.APPROVED)}`
                : `${sentenceCase(ScannedOrdersTab.APPROVED)} (${
                    counts.approved
                  })`
            }
          />
        </Tabs>
        <Box>
          <Button
            onClick={async () => {
              const orderUuid =
                firstScannedOrderResultInReviewData
                  ?.firstScannedOrderResultInReview?.order?.uuid;
              if (!isNil(orderUuid)) {
                navigate(`/orders?orderUuid=${orderUuid}&isDocumentScan=true`);
              }
            }}
            disabled={
              isNil(
                firstScannedOrderResultInReviewData
                  ?.firstScannedOrderResultInReview?.order?.uuid,
              ) || !canWriteScannedOrders
            }
            variant="contained"
            sx={{ mr: 2 }}
            startIcon={<Grading />}
          >
            {isNil(scannedOrderResultsData)
              ? 'Start review'
              : `Start review (${scannedOrderResultsData.numberOfScannedOrderResultsInReview})`}
          </Button>
        </Box>
      </Box>
      <Paper sx={{ flex: 1, minHeight: 0, overflowY: 'auto' }}>
        <TableContainer>
          <Table stickyHeader aria-label="orders table">
            <TableHead>
              <TableRow>
                {SCANNED_ORDER_HEADERS.map((header) => (
                  <TableCell sx={{ backgroundColor: 'white' }} key={header}>
                    <Box>{header}</Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {scannedOrderResultsData?.scannedOrderResults.edges.map(
                ({ node: scannedOrderResult }) => {
                  return (
                    <ScannedOrderRow
                      key={scannedOrderResult.uuid}
                      scannedOrderResultUuid={scannedOrderResult.uuid}
                      orderUuid={scannedOrderResult.order?.uuid}
                      date={scannedOrderResult.createdAt}
                      duplicateIsDocScan={
                        !isNil(
                          scannedOrderResult.duplicateScannedOrderResultOrder,
                        )
                      }
                      duplicateOrderSource={
                        scannedOrderResult.duplicateOrder?.source ??
                        scannedOrderResult.duplicateScannedOrderResultOrder
                          ?.source
                      }
                      duplicateOrderUuid={
                        scannedOrderResult.duplicateOrder?.uuid ??
                        scannedOrderResult.duplicateScannedOrderResultOrder
                          ?.uuid
                      }
                      failureReason={scannedOrderResult.failureReason}
                      filename={scannedOrderResult.filename}
                      pageEnd={scannedOrderResult.pageEnd}
                      pageStart={scannedOrderResult.pageStart}
                      status={scannedOrderResult.status}
                      billingPartyContact={
                        scannedOrderResult.order?.billingPartyContact
                          ?.displayName
                      }
                      shipperBillOfLadingNumber={
                        scannedOrderResult.order?.standardOrderFields
                          ?.shipperBillOfLadingNumber
                      }
                    />
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          mt: '15px',
        }}
      >
        <Button
          onClick={async () => {
            await fetchOrders({
              last: ORDER_PAGE_SIZE,
              before:
                scannedOrderResultsData?.scannedOrderResults.pageInfo
                  .startCursor,
            });
            setLastPaginationArgs({
              last: ORDER_PAGE_SIZE,
              before:
                scannedOrderResultsData?.scannedOrderResults.pageInfo
                  .startCursor,
            });
            setCanGoPrevious(false);
            setCanGoNext(true);
          }}
          disabled={
            scannedOrderResultsData?.scannedOrderResults.pageInfo
              .hasPreviousPage !== true && !canGoPrevious
          }
          variant="contained"
          sx={{ mr: 2 }}
          startIcon={<ArrowLeft />}
        >
          Previous page
        </Button>
        <Button
          onClick={async () => {
            await fetchOrders({
              first: ORDER_PAGE_SIZE,
              after:
                scannedOrderResultsData?.scannedOrderResults.pageInfo.endCursor,
            });
            setLastPaginationArgs({
              first: ORDER_PAGE_SIZE,
              after:
                scannedOrderResultsData?.scannedOrderResults.pageInfo.endCursor,
            });
            setCanGoPrevious(true);
            setCanGoNext(false);
          }}
          disabled={
            scannedOrderResultsData?.scannedOrderResults.pageInfo
              .hasNextPage !== true && !canGoNext
          }
          variant="contained"
          endIcon={<ArrowRight />}
        >
          Next page
        </Button>
      </Box>
      <UploadModal
        open={showUploadPdfsModal}
        setOpen={setShowUploadPdfsModal}
      />
    </Box>
  );
}
