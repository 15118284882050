import { useMeAsThirdPartyUserQuery } from '../../generated/graphql';

const useMeAsThirdPartyUser = () => {
  const { data: meData, loading: meDataLoading } = useMeAsThirdPartyUserQuery({
    fetchPolicy: 'cache-first',
  });

  const thirdPartyUser = meData?.meAsThirdPartyUser;
  const email = thirdPartyUser?.email;

  return {
    loading: meDataLoading,
    thirdPartyUser,
    email,
  };
};

export default useMeAsThirdPartyUser;
