import { Button, ButtonProps } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import React from 'react';

const ConfirmationButton = (
  props: ButtonProps & {
    onConfirm: () => void;
    onCancel?: () => void;
    children: React.ReactNode;
    confirmationTitle?: React.ReactNode;
    confirmationDescription?: React.ReactNode;
    cancellationText?: string;
    confirmationText?: string;
    hideCancelButton?: boolean;
  },
) => {
  const confirm = useConfirm();
  const {
    children,
    confirmationTitle,
    confirmationDescription,
    cancellationText,
    confirmationText,
    hideCancelButton,
    onConfirm,
    onCancel,
  } = props;
  return (
    <Button
      {...props}
      onClick={async () => {
        await confirm({
          title: confirmationTitle,
          description: confirmationDescription,
          cancellationText: cancellationText ?? `Cancel`,
          confirmationText: confirmationText ?? `Confirm`,
          hideCancelButton,
        })
          .then(onConfirm)
          .catch(onCancel);
      }}
    >
      {children}
    </Button>
  );
};

export { ConfirmationButton };
