import * as yup from 'yup';

export const addressSchema = yup
  .object({
    uuid: yup.string().defined(),
    city: yup.string().required(),
    country: yup.string().optional(),
    line1: yup.string().required(),
    line2: yup.string().optional().nullable(),
    name: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    longitude: yup.number().optional().nullable(),
    latitude: yup.number().optional().nullable(),
    preventCoordRecompute: yup.boolean().optional().nullable(),
    specialInstructions: yup.string().optional().nullable(),
    internalNotes: yup.string().optional().nullable(),
    iataCode: yup.string().optional().nullable(),
  })
  .optional()
  .nullable();

export const addressSchemaWithAllOptionalFields = yup
  .object({
    uuid: yup.string().defined(),
    city: yup.string().optional(),
    country: yup.string().optional(),
    line1: yup.string().optional(),
    line2: yup.string().optional().nullable(),
    name: yup.string().optional(),
    state: yup.string().optional(),
    zip: yup.string().optional(),
    longitude: yup.number().optional().nullable(),
    latitude: yup.number().optional().nullable(),
    preventCoordRecompute: yup.boolean().optional().nullable(),
    specialInstructions: yup.string().optional().nullable(),
    internalNotes: yup.string().optional().nullable(),
    iataCode: yup.string().optional().nullable(),
  })
  .optional()
  .nullable()
  .default(null);
