import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ORDER_PAGE_TERMINAL_SELECTOR_BUTTON } from '../../../constants';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { Option } from '../filters/types';
import useTerminals from '../react-hooks/use-terminals';
import useStyles from './general-styles';
import MultiselectFilterButton, {
  getMultiselectFilterLabel,
} from './multiselect-filter-button';

const CACHE_PREFIX = 'TERMINAL_FILTER';

interface TerminalFilterButtonProps {
  // Single select
  selectedOption?: Option | null | undefined;
  handleChange?: (option: Option | null | undefined) => void;
  // Multi-select
  selectedOptionsMultiselect?: Option[] | null | undefined;
  handleChangeMultiselect?: (options: Option[] | null | undefined) => void;

  prefixText: string;
  cacheId?: string;
  displayCode?: boolean;
  displayNoneOptionMultiselect?: boolean; // Only supported for multiselect right now
  includeInactiveTerminals: boolean;
  isSmall?: boolean;
  disableButton?: boolean;
}

const TerminalFilterButton = ({
  includeInactiveTerminals,
  cacheId,
  prefixText,
  selectedOption,
  handleChange,
  selectedOptionsMultiselect,
  handleChangeMultiselect,
  displayCode,
  displayNoneOptionMultiselect = false,
  isSmall = false,
  disableButton = false,
}: TerminalFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const { terminals } = useTerminals({ includeInactiveTerminals });

  useEffect(() => {
    if (!isNil(cacheId) && !isNil(handleChange)) {
      const cachedLabel = localStorage.getItem(
        `${CACHE_PREFIX}_LABEL_${cacheId}`,
      );
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedLabel) && !isNil(cachedValue)) {
        handleChange({
          label: cachedLabel,
          value: cachedValue,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const terminalOptions = useMemo(
    () =>
      terminals
        .map((terminal) => ({
          label: displayCode === true ? terminal.code : terminal.name,
          value: terminal.uuid,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label)),
    [terminals, displayCode],
  );

  if (isNil(handleChange) && isNil(handleChangeMultiselect)) {
    return <Box />;
  }

  const filterLabel = getMultiselectFilterLabel(
    // eslint-disable-next-line no-nested-ternary
    !isNil(selectedOptionsMultiselect)
      ? selectedOptionsMultiselect
      : !isNil(selectedOption)
        ? [selectedOption]
        : null,
    displayNoneOptionMultiselect,
  );

  if (!isNil(handleChangeMultiselect)) {
    return (
      <MultiselectFilterButton
        selectedOptions={selectedOptionsMultiselect}
        options={terminalOptions}
        handleChange={handleChangeMultiselect}
        prefixText={prefixText}
        displayNoneOption={displayNoneOptionMultiselect}
        isSmall={isSmall}
        filterLabel={filterLabel}
        cachePrefix={CACHE_PREFIX}
        cacheId={cacheId}
        disableButton={disableButton}
      />
    );
  }

  /// /////////////////////////////////////////////////////////////////////////////
  // SINGLE SELECT
  /// /////////////////////////////////////////////////////////////////////////////
  const onChange = (option: Option | null | undefined) => {
    if (!isNil(handleChange)) {
      if (!isNil(cacheId)) {
        if (!isNil(option) && !isNil(option.label) && !isNil(option.value)) {
          localStorage.setItem(
            `${CACHE_PREFIX}_LABEL_${cacheId}`,
            option.label,
          );
          localStorage.setItem(
            `${CACHE_PREFIX}_VALUE_${cacheId}`,
            option.value,
          );
        } else {
          localStorage.removeItem(`${CACHE_PREFIX}_LABEL_${cacheId}`);
          localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
        }
      }
      handleChange(option);
    }
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box>
      <Button
        data-testid={ORDER_PAGE_TERMINAL_SELECTOR_BUTTON}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        variant="outlined"
        sx={[isSmall ? styles.filterButtonSmall : styles.filterButton]}
        disabled={disableButton}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{prefixText}:</Typography>
          <Typography sx={styles.filterValue}>{filterLabel}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="customer-menu"
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            onClick={() => {
              onChange(undefined);
              handleClose();
            }}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: isNil(selectedOption) ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            onClick={(e) => e.preventDefault()}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: !isNil(selectedOption) ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={selectedOption}
                options={terminalOptions}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    onClick={(e) => e.preventDefault()}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.value}>
                    {option.label}
                    {terminals.find(
                      (terminal) => terminal.uuid === option.value,
                    )?.isActive === false && ` (inactive)`}
                  </li>
                )}
                onClick={(e) => e.preventDefault()}
                onChange={(_, option) => {
                  onChange(option);
                }}
                onClose={handleClose}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default TerminalFilterButton;
