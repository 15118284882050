import {
  AppBar,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { CSSProperties } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../common/feature-flags';
import { SIDEBAR_OPEN_KEY } from '../../common/local-storage/keys';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import useTerminals from '../../common/react-hooks/use-terminals';
import { chooseForegroundColor } from '../../common/utils/colors';
import { useNewTableFunctionsFeatureFlag } from '../../domains/ag-grid/use-new-table-functions-feature-flag';
import { isDevelopment } from '../../environment-variables';
import { FilterViewPage } from '../../generated/graphql';
import LeftPanelOpen from '../../icons/left-panel-open.svg?react';
import theme from '../../theme';
import NavbarSearch from './components/navbar-search';
import NavbarTerminalSelector from './components/navbar-terminal-selector';
import NotificationsBell from './components/notifications-bell';
import { DEV_APP_BAR_COLOR } from './constants';
import useGlobalStore from './global-store';
import { DashboardDrawerItem, DashboardPage } from './types';

const APP_BAR_HEIGHT_OLD_PX = 65;
const APP_BAR_HEIGHT_NEW_PX = 45;

const getAppBarColor = (ffNewDashboardUI: boolean) => {
  if (isDevelopment()) return DEV_APP_BAR_COLOR;
  return ffNewDashboardUI ? '#ffffff' : '';
};

const getSidebarIconColor = () => {
  return isDevelopment() ? 'white' : 'black';
};

const DASHBOARD_STYLES: { [key: string]: CSSProperties } = {
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  appBarTypography: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '29px',
    marginLeft: 0,
    color: '#FFFFFF',
  },
};

interface AppBarProps {
  page: DashboardPage;
  selectedDrawerItem?: DashboardDrawerItem;
}
export const AppBarOld = ({ page, selectedDrawerItem }: AppBarProps) => {
  const { pathname } = useLocation();
  const ffNewDashboardUi = useFeatureFlag(FeatureFlag.FF_NEW_DASHBOARD_UI);
  const appbarColor = getAppBarColor(ffNewDashboardUi);
  const { companyConfiguration } = useMe();
  const notificationsEnabled =
    companyConfiguration?.notificationsEnabled === true;
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
    page === DashboardPage.ORDERS ? FilterViewPage.Orders : undefined,
  );

  const shouldShowTerminalSelector =
    terminalsEnabled &&
    (page === DashboardPage.TRANSIT ||
      (page === DashboardPage.ORDERS &&
        pathname === '/orders/inbound-messages/email') ||
      (ffEnableNewTableFunctions &&
        page === DashboardPage.ORDERS &&
        pathname === '/orders'));

  const [setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );

  return (
    <AppBar
      elevation={0}
      color="primary"
      sx={{
        backgroundColor: appbarColor,
        ...DASHBOARD_STYLES.appBar,
        ...{ zIndex: (t) => t.zIndex.drawer + 1 },
        height: APP_BAR_HEIGHT_OLD_PX,
      }}
    >
      <Toolbar style={{ width: '100%', display: 'flex' }}>
        <Typography sx={DASHBOARD_STYLES.appBarTypography}>
          {selectedDrawerItem?.name ?? ''}
        </Typography>
        <Stack
          ml={10}
          display="flex"
          flexDirection="row"
          gap={10}
          alignItems="center"
          flexShrink={0}
        >
          {selectedDrawerItem?.tabs.map((tab) => {
            const { url } = tab;
            return (
              <Link style={{ flexShrink: 0 }} to={url} key={url}>
                <Typography
                  sx={{
                    ...DASHBOARD_STYLES.appBarTypography,
                    fontSize: '16px',
                    cursor: 'pointer',
                    textDecoration: pathname === url ? 'underline' : undefined,
                  }}
                  onClick={() => {
                    setOpenedOrderUuid(undefined);
                  }}
                >
                  {tab.name}
                </Typography>
              </Link>
            );
          })}
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          marginLeft="auto"
          alignItems="center"
          display="flex"
        >
          {shouldShowTerminalSelector && <NavbarTerminalSelector />}
          {notificationsEnabled && <NotificationsBell />}
          {(shouldShowTerminalSelector || notificationsEnabled) && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ backgroundColor: 'white' }}
            />
          )}
          <NavbarSearch />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export const AppBarNew = ({ page, selectedDrawerItem }: AppBarProps) => {
  const { pathname } = useLocation();
  const ffNewDashboardUi = useFeatureFlag(FeatureFlag.FF_NEW_DASHBOARD_UI);
  const appbarColor = getAppBarColor(ffNewDashboardUi);
  const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
    page === DashboardPage.ORDERS ? FilterViewPage.Orders : undefined,
  );
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(SIDEBAR_OPEN_KEY, {
    defaultValue: true,
  });
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const shouldShowTerminalSelector =
    terminalsEnabled &&
    (page === DashboardPage.TRANSIT ||
      (page === DashboardPage.ORDERS &&
        pathname === '/orders/inbound-messages/email') ||
      (ffEnableNewTableFunctions &&
        page === DashboardPage.ORDERS &&
        pathname === '/orders'));

  return (
    <AppBar
      component="nav"
      position="fixed"
      elevation={0}
      sx={{
        ...DASHBOARD_STYLES.appBar,
        backgroundColor: appbarColor,
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        height: APP_BAR_HEIGHT_NEW_PX,
      }}
    >
      <Toolbar sx={{ width: '100%' }}>
        {!sidebarOpen && (
          <Tooltip title="Open sidebar">
            <IconButton
              size="small"
              onClick={() => {
                setSidebarOpen(true);
              }}
              sx={{
                marginRight: 1,
              }}
            >
              <LeftPanelOpen fill={getSidebarIconColor()} />
            </IconButton>
          </Tooltip>
        )}
        <Typography
          component="div"
          variant="h6"
          sx={{
            color: chooseForegroundColor(appbarColor),
          }}
        >
          {selectedDrawerItem?.tabs.find((tab) => pathname === tab.url)?.name}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          marginLeft="auto"
          alignItems="center"
        >
          {shouldShowTerminalSelector && (
            <NavbarTerminalSelector
              color={chooseForegroundColor(appbarColor)}
            />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
