import { Stack } from '@mui/material';
import React from 'react';
import DriverVehicleSelection from './driver-vehicle-selection';
import GeneralConstraintSettings from './general-constraint-settings';

const GenerateRoutesConstraints = () => {
  return (
    <Stack spacing={1}>
      <GeneralConstraintSettings />
      <DriverVehicleSelection />
    </Stack>
  );
};

export default React.memo(GenerateRoutesConstraints);
