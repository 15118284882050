import FileOpenIcon from '@mui/icons-material/FileOpen';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import {
  EmailTransactionStatus,
  ReportSendJobFragment,
  ReportSendJobError,
  ReportSendJobStatus,
} from '../../../../generated/graphql';
import EmailTransactionStatusChip from '../../../invoices/components/invoices/transmissions/emails/email-transaction-status-chip';
import InvoiceEmailTransactionLogStatuses from '../../../invoices/components/invoices/transmissions/emails/invoice-email-transaction-log-statuses';
import ReportSendJobStatusChip from './report-send-job-status-chip';

const DerivedReportSendJobStatusChip = ({
  reportSendJob,
}: {
  reportSendJob: ReportSendJobFragment;
}) => {
  if (!isNil(reportSendJob.reportSendJobError)) {
    return <ReportSendJobStatusChip status={ReportSendJobStatus.Failed} />;
  }
  if (!isNil(reportSendJob.emailTransaction)) {
    return (
      <EmailTransactionStatusChip
        status={reportSendJob.emailTransaction?.emailTransactionStatus}
      />
    );
  }
  return <ReportSendJobStatusChip status={ReportSendJobStatus.InProgress} />;
};

const ReportSendJobListRow = ({
  reportSendJob,
}: {
  reportSendJob: ReportSendJobFragment;
}) => {
  const errorString = useMemo(() => {
    if (!isNil(reportSendJob.reportSendJobError)) {
      return sentenceCase(reportSendJob.reportSendJobError);
    }
    if (
      reportSendJob.emailTransaction?.emailTransactionStatus ===
      EmailTransactionStatus.Failed
    ) {
      return reportSendJob.emailTransaction.emailTransactionLogs
        .map((log) => log.reason)
        .join('\n');
    }
    return '-';
  }, [reportSendJob]);

  return (
    <TableRow>
      <TableCell>{reportSendJob.contact.displayName}</TableCell>
      <TableCell align="center">
        <DerivedReportSendJobStatusChip reportSendJob={reportSendJob} />
      </TableCell>
      <TableCell>
        <InvoiceEmailTransactionLogStatuses
          emailTransactionLogs={
            reportSendJob.emailTransaction?.emailTransactionLogs ?? []
          }
        />
      </TableCell>
      <TableCell>{errorString}</TableCell>
      <TableCell align="right">
        {reportSendJob.reportSendJobError ===
        ReportSendJobError.DocumentGenerationFailed ? (
          <Tooltip title="Failed to generate">
            <IconButton disableFocusRipple>
              <WarningIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          !isNil(reportSendJob.document?.preSignedGetUrl) && (
            <Tooltip title="View report">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a
                href={reportSendJob.document?.preSignedGetUrl}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton color="info">
                  <FileOpenIcon />
                </IconButton>
              </a>
            </Tooltip>
          )
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ReportSendJobListRow);
