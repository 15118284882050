import { Button, styled } from '@mui/material';

const SecondaryButton = styled(Button)`
  background-color: #ffffff;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(70, 79, 96, 0.16);
  border: none;
  border-radius: 6px;
  color: #5d6578;
  .MuiButton-startIcon {
    color: #9ca3b4;
  }
  :hover {
    border: none;
    background-color: #f6f6f6;
  }
`;

export default SecondaryButton;
