import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AccordionSummary as MuiAccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import type { FunctionComponent } from 'react';
import { isNotNilOrEmptyString } from 'shared/string';

type AccordionSummaryProps = {
  heading: string;
  subheading?: string;
};

/**
 * A wrapper for MUI's AccordionSummary, to help maintain consistent styling.
 */
export const AccordionSummary: FunctionComponent<AccordionSummaryProps> = ({
  heading,
  subheading,
}) => (
  // The Box below is needed because MuiAccordionSummary is a flexbox row container, but the two Typography elements need to be stacked.
  <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
    <Box>
      <Typography fontWeight={500} lineHeight={1.3}>
        {heading}
      </Typography>
      {isNotNilOrEmptyString(subheading) && (
        <Typography variant="caption" color="text.secondary">
          {subheading}
        </Typography>
      )}
    </Box>
  </MuiAccordionSummary>
);
