import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import useStyles from './general-styles';

export enum OrderBillingStatus {
  CHARGES_FINALIZED = 'Charges finalized',
  ON_INVOICE = 'On any invoice',
  ON_POSTED_INVOICE = 'On posted invoice',
}

interface OrderBillingStatusFilterButtonProps {
  selectedOption: OrderBillingStatus | undefined;
  handleChange: (status: OrderBillingStatus | undefined) => void;
}

const OrderBillingStatusFilterButton = ({
  selectedOption,
  handleChange,
}: OrderBillingStatusFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  const onChange = (status: OrderBillingStatus | null | undefined) => {
    handleChange(status ?? undefined);
  };

  return (
    <Box>
      <Button
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>Billing Status:</Typography>
          <Typography sx={styles.filterValue}>
            {selectedOption ?? 'All'}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="order-billing-status-simple-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            onClick={() => onChange(undefined)}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    selectedOption === undefined ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          {Object.values(OrderBillingStatus).map((status) => (
            <MenuItem
              key={status}
              onClick={() => onChange(status)}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible',
                pl: '10px',
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Check
                  sx={{
                    visibility:
                      selectedOption === status ? undefined : 'hidden',
                    fontSize: '14px',
                    ml: 0,
                    mr: '6px',
                  }}
                />
                <Typography sx={styles.menuText}>{status}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default OrderBillingStatusFilterButton;
