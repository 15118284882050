import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';
import { validateEmail } from '../../../../common/utils/utils';
import {
  ThirdPartyUsersByContactDocument,
  useAddThirdPartyUserToContactMutation,
} from '../../../../generated/graphql';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
};

const AddThirdPartyUserModal = ({
  isAddUserModalOpen,
  setIsAddUserModalOpen,
  contactUuid,
}: {
  isAddUserModalOpen: boolean;
  setIsAddUserModalOpen: Dispatch<SetStateAction<boolean>>;
  contactUuid: string;
}) => {
  const [email, setEmail] = useState('');
  const [addThirdPartyUserToContact] = useAddThirdPartyUserToContactMutation({
    refetchQueries: [ThirdPartyUsersByContactDocument],
  });
  const [errorMessage, setErrorMessage] = useState('');

  const addUser = async () => {
    if (email.length === 0) {
      setErrorMessage('Email is required.');
    } else if (validateEmail(email) === false) {
      setErrorMessage('Not a valid email.');
    } else {
      const res = await addThirdPartyUserToContact({
        variables: {
          addThirdPartyUserToContactInput: { email, contactUuid },
        },
      });

      setErrorMessage(
        (res?.errors ?? []).map((r) => r.message).join(', ') ?? '',
      );
      if (!isNil(res.data) && isNil(res.errors)) {
        setIsAddUserModalOpen(false);
      }
    }
  };

  return (
    <Dialog
      open={isAddUserModalOpen}
      maxWidth="md"
      fullWidth
      onClose={() => setIsAddUserModalOpen(false)}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h5">Add third party user</Typography>
        <Box sx={{ m: '20px' }}>
          <Typography>Email</Typography>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            size="small"
          />
        </Box>
        <Button variant="contained" onClick={addUser}>
          Save
        </Button>
        <Typography sx={{ color: 'red', mt: '10px' }}>
          {errorMessage}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default AddThirdPartyUserModal;
