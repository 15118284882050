import { Box, Stack, Tab, Tabs } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import useIngestedEmailsStore from '../ingested-emails-store';
import EmailProcessingHistoryTable, {
  EmailProcessingHistoryTablePagination,
} from '../processing-status/email-processing-history-table';
import ReviewEmailOrdersTable from './review-email-orders-table';
import ReviewEmailOrdersTablePagination from './table-pagination';

enum ReviewEmailOrdersTabs {
  REVIEW_ORDERS,
  EMAIL_PROCESSING_HISTORY,
}

const ReviewEmailOrders = () => {
  const [selecedOrderUuid] = useIngestedEmailsStore((state) => [
    state.selectedOrderUuid,
  ]);

  const hasOrderSelected = !isNil(selecedOrderUuid);

  const [currentTab, setCurrentTab] = useState<ReviewEmailOrdersTabs>(
    ReviewEmailOrdersTabs.REVIEW_ORDERS,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        padding: '10px',
        height: '80vh',
        width: '100%',
        overflowX: 'hidden',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          flex: '0 0 auto' /* This makes the left element not grow or shrink and have an "auto" initial size */,
          width: hasOrderSelected ? '300px' : '1500px',
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: hasOrderSelected ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={currentTab}
              onChange={(_, newValue) => setCurrentTab(newValue)}
              aria-label="email orders page tabs"
              sx={{
                marginTop: '-10px',
              }}
            >
              <Tab
                value={ReviewEmailOrdersTabs.REVIEW_ORDERS}
                label="In-review orders from email"
              />
              <Tab
                value={ReviewEmailOrdersTabs.EMAIL_PROCESSING_HISTORY}
                label="Email & attachment processing history"
              />
            </Tabs>
            {currentTab === ReviewEmailOrdersTabs.REVIEW_ORDERS ? (
              <ReviewEmailOrdersTablePagination />
            ) : (
              <EmailProcessingHistoryTablePagination />
            )}
          </Box>
          {currentTab === ReviewEmailOrdersTabs.REVIEW_ORDERS ? (
            <ReviewEmailOrdersTable />
          ) : (
            <EmailProcessingHistoryTable />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default ReviewEmailOrders;
