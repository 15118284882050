import { sentenceCase } from 'change-case';
import { PermissionResource } from '../../generated/graphql';

const PERMISSION_LABELS: Partial<Record<PermissionResource, string>> = {
  [PermissionResource.FinalizeChargesOrders]: 'Finalize order charges',
  [PermissionResource.FinalizeChargesOrdersPaperworkIncomplete]:
    'Finalize order charges without completing paperwork',
  [PermissionResource.EdiApiOrders]: 'EDI API orders',
  [PermissionResource.SendFinalizedToBilling]:
    'Send finalized orders to billing',
  [PermissionResource.CompanyDrivers]: 'Drivers',
  [PermissionResource.CompanyWarehouseEmployees]: 'Warehouse employees',
  [PermissionResource.CompanyEquipment]: 'Equipment',
  [PermissionResource.CompanyAccessorials]: 'Accessorials',
  [PermissionResource.CompanyTariffs]: 'Tariffs',
  [PermissionResource.CompanyBilling]: 'Billing',
  [PermissionResource.CompanyScheduling]: 'Scheduling',
  [PermissionResource.CompanyServices]: 'Services',
  [PermissionResource.CompanyRouteNames]: 'Route names',
  [PermissionResource.CompanyUsers]: 'Users',
  [PermissionResource.CompanyRecoveryTerminals]: 'Recovery terminals',
  [PermissionResource.CompanyThirdPartyBrokers]: 'Third-party brokers',
  [PermissionResource.CompanyCustomForms]: 'Custom forms',
  [PermissionResource.CompanyFuelProfiles]: 'Fuel profiles',
  [PermissionResource.CompanyBusinessDivisions]: 'Business divisions',
  [PermissionResource.CompanyRecurringOrders]: 'Recurring orders',
  [PermissionResource.CompanyTerminals]: 'Terminals',
  [PermissionResource.CompanyHoldReasons]: 'Hold reasons',
  [PermissionResource.CompanyLineHaul]: 'Line haul',
  [PermissionResource.SettingsAutoApplyAccessorialRules]:
    'Auto-apply accessorial rules',
  [PermissionResource.SettingsTags]: 'Tags',
  [PermissionResource.SettingsSamsara]: 'Samsara',
  [PermissionResource.SettingsQuickbooks]: 'QuickBooks',
  [PermissionResource.SettingsDispatch]: 'Dispatch',
  [PermissionResource.SettingsRoles]: 'Roles',
  [PermissionResource.SettingsOrders]: 'Orders',
  [PermissionResource.SettingsQuickbooksDesktop]: 'QuickBooks Desktop',
  [PermissionResource.SettingsQuickbooksOnline]: 'QuickBooks Online',
  [PermissionResource.SettingsGeneral]: 'General',
};

export const getPermissionLabel = (resource: PermissionResource) => {
  return PERMISSION_LABELS[resource] ?? sentenceCase(resource);
};
