import { Chip } from '@mui/material';
import { green, lightBlue, red, orange, grey } from '@mui/material/colors';
import { isNil } from 'lodash';
import React from 'react';
import { exhaustive } from 'shared/switch';
import { StopStatus } from '../../../../generated/graphql';

const StopStatusChip = ({
  status,
  refusedBy,
}: {
  status: StopStatus;
  refusedBy: string | null | undefined;
}) => {
  let color;
  let backgroundColor;
  let fontWeight;
  let label = 'Unknown';

  switch (status) {
    case StopStatus.NotArrived:
      label = 'PENDING';
      backgroundColor = grey['50'];
      fontWeight = 400;
      break;
    case StopStatus.Arrived:
      color = lightBlue['900'];
      backgroundColor = lightBlue['50'];
      label = 'ARRIVED';
      break;
    case StopStatus.Completed:
      color = green['900'];
      backgroundColor = '#BBF7D0';
      label = 'DONE';
      break;
    case StopStatus.Cancelled:
      color = red['900'];
      backgroundColor = red['50'];
      label = 'CANCELLED';
      break;
    case StopStatus.Failed:
      color = orange['900'];
      backgroundColor = orange['50'];
      label = 'ATTEMPTED';
      break;
    default:
      return exhaustive(status);
  }

  if (!isNil(refusedBy)) {
    color = red['900'];
    backgroundColor = red['100'];
    label = 'REFUSED';
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      sx={{
        height: 16,
        fontSize: '12px',
        fontWeight: fontWeight ?? '500',
        borderColor: color,
        color,
        backgroundColor,
      }}
      label={label}
    />
  );
};

export default StopStatusChip;
