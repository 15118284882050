import {
  Box,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useCompletedStopsNotOnRoutesQuery } from '../../../generated/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import CannotCompleteOrderModal from '../../orders/components/order-form/components/overview/modals/cannot-complete-order-modal';
import EndOfDayContext from '../end-of-day-context';
import { selectRouteValuesById } from '../redux/routes-values-slice';
import RouteStopRow from './route-stop-row';
import RoutelessStopRow from './routeless-stop-row';

type EndOfDayRouteStopsTableProps = {
  tableIsMinimized: boolean;
  setTableIsMinimized: Dispatch<SetStateAction<boolean>>;
  setShowMarkStopAsAttemptedSuccessMessage: Dispatch<SetStateAction<boolean>>;
  setShowMarkStopAsAttemptedErrorMessage: Dispatch<SetStateAction<boolean>>;
};

const EndOfDayRouteStopsTable = ({
  tableIsMinimized,
  setTableIsMinimized,
  setShowMarkStopAsAttemptedSuccessMessage,
  setShowMarkStopAsAttemptedErrorMessage,
}: EndOfDayRouteStopsTableProps) => {
  const {
    selectedRouteUuid,
    selectedStopUuid,
    setSelectedStopUuid,
    completedStopsNotOnRoutesSelected,
    currentDate,
  } = useContext(EndOfDayContext);

  const { data: completedStopsNotOnRoutesData } =
    useCompletedStopsNotOnRoutesQuery({
      variables: { date: currentDate.toDate() },
    });

  const route = useAppSelector((state: RootState) =>
    selectRouteValuesById(state, String(selectedRouteUuid)),
  );
  const [cannotCompleteOrderModalOpen, setCannotCompleteOrderModalOpen] =
    useState(false);
  const [cannotCompleteOrderModalMessage, setCannotCompleteOrderModalMessage] =
    useState<string | undefined>();
  const [showRouteCompleteNotification, setShowRouteCompleteNotification] =
    useState(false);
  const ffHidePaperworkCompleteColumn = useFeatureFlag(
    FeatureFlag.FF_HIDE_PAPERWORK_COMPLETE_COLUMN,
  );
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: 'black',
        height: '100%',
      }}
    >
      <TableContainer component={Paper}>
        <Table
          aria-label="collapsible table"
          stickyHeader
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableHead>
            <TableRow>
              {!tableIsMinimized &&
                completedStopsNotOnRoutesSelected === false && (
                  <TableCell align="left" size="small">
                    Stop
                  </TableCell>
                )}
              <TableCell align="left">HAWB</TableCell>
              <TableCell align="left">Stop Type</TableCell>
              {!tableIsMinimized && (
                <TableCell align="left">Address Name</TableCell>
              )}
              {!tableIsMinimized && <TableCell align="left">Address</TableCell>}
              {!tableIsMinimized && (
                <TableCell align="left">Appointment Time</TableCell>
              )}
              {completedStopsNotOnRoutesSelected === false && (
                <TableCell align="left">Status</TableCell>
              )}
              {!ffHidePaperworkCompleteColumn && (
                <>
                  <TableCell align="left">Documents</TableCell>
                  <TableCell align="left">Order Paperwork Completed</TableCell>
                </>
              )}
              {ffInboundOutboundPaperworkColumns && (
                <>
                  <TableCell align="left">Inbound paperwork</TableCell>
                  <TableCell align="left">Outbound paperwork</TableCell>
                </>
              )}
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isNil(selectedRouteUuid) &&
              completedStopsNotOnRoutesSelected === false &&
              route?.routeSlotUuids?.map((slotUuid, idx) => (
                <RouteStopRow
                  tableIsMinimized={tableIsMinimized}
                  setTableIsMinimized={setTableIsMinimized}
                  selectedStopUuid={selectedStopUuid}
                  setSelectedStopUuid={setSelectedStopUuid}
                  routeUuid={selectedRouteUuid}
                  slotUuid={slotUuid}
                  key={slotUuid}
                  order={idx + 1}
                  setShowMarkStopAsAttemptedSuccessMessage={
                    setShowMarkStopAsAttemptedSuccessMessage
                  }
                  setShowMarkStopAsAttemptedErrorMessage={
                    setShowMarkStopAsAttemptedErrorMessage
                  }
                  setCannotCompleteOrderModalOpen={
                    setCannotCompleteOrderModalOpen
                  }
                  setCannotCompleteOrderModalMessage={
                    setCannotCompleteOrderModalMessage
                  }
                />
              ))}
            {completedStopsNotOnRoutesSelected === true &&
              completedStopsNotOnRoutesData?.completedStopsNotOnRoutes?.map(
                (stop, idx) => (
                  <RoutelessStopRow
                    stop={stop}
                    tableIsMinimized={tableIsMinimized}
                    setTableIsMinimized={setTableIsMinimized}
                    selectedStopUuid={selectedStopUuid}
                    setSelectedStopUuid={setSelectedStopUuid}
                    key={stop.uuid}
                    order={idx + 1}
                    setShowMarkStopAsAttemptedSuccessMessage={
                      setShowMarkStopAsAttemptedSuccessMessage
                    }
                    setShowMarkStopAsAttemptedErrorMessage={
                      setShowMarkStopAsAttemptedErrorMessage
                    }
                    setCannotCompleteOrderModalOpen={
                      setCannotCompleteOrderModalOpen
                    }
                    setCannotCompleteOrderModalMessage={
                      setCannotCompleteOrderModalMessage
                    }
                  />
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <CannotCompleteOrderModal
        open={cannotCompleteOrderModalOpen}
        setOpen={setCannotCompleteOrderModalOpen}
        message={cannotCompleteOrderModalMessage}
        setMessage={setCannotCompleteOrderModalMessage}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showRouteCompleteNotification}
        onClose={() => setShowRouteCompleteNotification(false)}
        message="Route marked as complete"
      />
    </Box>
  );
};

export default EndOfDayRouteStopsTable;
