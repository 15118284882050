import { ColDef } from 'ag-grid-community';
import { DateOption } from '../../../common/components/date-dropdown-picker';
import {
  DispatchTableField,
  FindStopsFiltersInput,
  Scalars,
  StopOnRouteBySearchTextFragment,
} from '../../../generated/graphql';
import {
  DateFilterOption,
  FilterModel,
} from '../../orders/components/enums/order-filters';
import { StopFilterField, StopsTab } from './constants';

export type DefaultStopsFilterTab<T> = {
  value: T;
  label: string;
  filtersToApply: Partial<FindStopsFiltersInput>;
  filterModel?: FilterModel;
  sortModel?: DispatchSortV2[];
};

/**
 * Represents the shape of the state object for the stops table.
 */
export type State = {
  searchText: string;
  serviceDateOption: DateOption;
  terminalUuid: string | undefined;
  stopsTab: StopsTab | string;
  currentCursor: string | null | undefined;
  /** @deprecated @see orderTableFilterModel */
  customFilterModelJson: FilterModel;
  /**
   * The filter model saved in the DB. We compare against this to calculate
   * how many filters have changed.
   */
  dispatchTableFilterModel: DispatchTableFilterModel;
  /**
   * The sort model saved in the DB. We compare against this to calculate
   * how many sorts have changed.
   */
  customSortModelJson: DispatchSortV2[];
  /**
   * The list of dispatch table fields saved in the DB. We compare against this to
   * calculate how many table columns have changed.
   */
  dispatchTableFields: DispatchTableField[];
  currentFilterViewUuid: string | null;
  currentFilterViewName: string | null;
  columnDefs: ColDef[];
  numberNewStops: number;
  topScrollPosition: number;
  // This field is displayed but is not used for pagination. It will not include recurring run headers.
  totalCount: number | undefined;
  stopsOnRoutes: StopOnRouteBySearchTextFragment[];
  datasourceVersionId: number;
  firstRender: boolean;
};

// the type here will be an enum.
export type DefaultFilterTabsConfigs<T> = {
  defaultTab: T;
  tabs: DefaultStopsFilterTab<T>[];
};

/**
 * Type for storing filter values as JSON in the DB or local storage, using
 * column IDs instead of display names to make renaming columns easier,
 * since DispatchFilterField values are also used as display names.
 *
 * @see getDispatchTableFilterModel to convert to a FilterModel that can be
 * passed to AG Grid.
 */
export type DispatchTableFilterModel = Partial<
  Record<keyof typeof StopFilterField, Scalars['JSON']['input']>
>;

// TODO: placeholder until we have a GraphQL type for this
/* eslint-disable @typescript-eslint/no-explicit-any */
export type DispatchSortV2 = {
  fieldName: DispatchTableField;
  stopTypeSortInput?: any;
  inboundMethodSortInput?: any;
  outboundMethodSortInput?: any;
  mawbSortInput?: any;
  citySortInput?: any;
  zipCodeSortInput?: any;
  dueDateSortInput?: any;
  serviceDateSortInput?: any;
  addressSortInput?: any;
  addressTypeSortInput?: any;
  stateSortInput?: any;
  appointmentSortInput?: any;
  consigneeSortInput?: any;
  appointmentScheduledSortInput?: any;
  appointmentRequiredSortInput?: any;
  customerSortInput?: any;
  serviceLevelSortInput?: any;
  vehicleTypeNameSortInput?: any;
  businessDivisionSortInput?: any;
  specialInstructionsSortInput?: any;
  routeNameSortInput?: any;
  terminalSortInput?: any;
  inboundNameSortInput?: any;
  outboundNameSortInput?: any;
  inboundZipSortInput?: any;
  outboundZipSortInput?: any;
  inboundCitySortInput?: any;
  outboundCitySortInput?: any;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export enum DispatchFilterType {
  FIXED = 'FIXED',
  RELATIVE = 'RELATIVE',
}

export enum DispatchRelativeDateFilterOption {
  PLANNING_DATE = 'Planning Date',
}

export type DispatchDateFilterOption =
  | {
      filterType: DispatchFilterType.FIXED;
      fixedDateOption: DateFilterOption;
      relativeDateOption?: undefined;
    }
  | {
      filterType: DispatchFilterType.RELATIVE;
      fixedDateOption?: undefined;
      relativeDateOption: DispatchRelativeDateFilterOption;
    };
