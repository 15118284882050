import React from 'react';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../global-store';
import { DashboardDrawerItem as DashboardDrawerItemType } from '../types';
import DashboardDrawerItem from './dashboard-drawer-item';

type DashboardDrawerNavItemProps = {
  drawerItem: DashboardDrawerItemType;
  selected: boolean;
};

const DashboardDrawerNavItem = ({
  drawerItem,
  selected,
}: DashboardDrawerNavItemProps) => {
  const [setCurrentOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );

  return (
    <DashboardDrawerItem
      key={drawerItem.name}
      name={drawerItem.name}
      icon={drawerItem.icon}
      selected={selected}
      href={drawerItem.navbarUrl ?? drawerItem.url}
      onClick={() => {
        setCurrentOrderUuid(undefined);
      }}
    />
  );
};

export default React.memo(DashboardDrawerNavItem);
