import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { OrderForConsolidationFlowFragment } from '../../../../../generated/graphql';

export enum ChargesToConsolidate {
  INBOUND_CHARGES = 'INBOUND',
  OUTBOUND_CHARGES = 'OUTBOUND',
}

const SelectChargesToConsolidate = ({
  selectedChargesToConsolidate,
  setSelectedChargesToConsolidate,
  lineHaulEnabled,
  primaryOrder,
}: {
  selectedChargesToConsolidate: Record<ChargesToConsolidate, boolean> | null;
  setSelectedChargesToConsolidate: Dispatch<
    SetStateAction<Record<ChargesToConsolidate, boolean> | null>
  >;
  lineHaulEnabled: boolean;
  primaryOrder: OrderForConsolidationFlowFragment;
}) => {
  const { inboundShipmentValid, outboundShipmentValid } = useMemo(() => {
    return {
      inboundShipmentValid: !isNil(primaryOrder.inboundShipment?.freightCharge),
      outboundShipmentValid: !isNil(
        primaryOrder.outboundShipment?.freightCharge,
      ),
    };
  }, [primaryOrder.inboundShipment, primaryOrder.outboundShipment]);

  useEffect(() => {
    if (isNil(selectedChargesToConsolidate)) {
      setSelectedChargesToConsolidate({
        INBOUND: inboundShipmentValid,
        OUTBOUND: outboundShipmentValid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChargesToConsolidate]);

  useEffect(() => {
    if (isNil(selectedChargesToConsolidate)) {
      setSelectedChargesToConsolidate({
        INBOUND: inboundShipmentValid,
        OUTBOUND: outboundShipmentValid,
      });
    } else {
      setSelectedChargesToConsolidate({
        ...selectedChargesToConsolidate,
        INBOUND: inboundShipmentValid,
        OUTBOUND: outboundShipmentValid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundShipmentValid, outboundShipmentValid]);

  if (isNil(selectedChargesToConsolidate)) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ padding: 1, gap: 0, overflow: 'visible' }}>
      <Typography variant="h6" sx={{ fontSize: '18px' }}>
        Pro-rate weight-based charges for:
      </Typography>

      <FormGroup>
        <Stack mt={1} gap={1} direction="row">
          <FormControlLabel
            control={
              <Tooltip
                title="Primary order requires an inbound shipment to pro-rate by inbound"
                disableHoverListener={inboundShipmentValid}
              >
                <span>
                  <Checkbox
                    checked={
                      selectedChargesToConsolidate.INBOUND &&
                      inboundShipmentValid
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedChargesToConsolidate({
                          ...selectedChargesToConsolidate,
                          INBOUND: true,
                        });
                      } else {
                        setSelectedChargesToConsolidate({
                          ...selectedChargesToConsolidate,
                          INBOUND: false,
                        });
                      }
                    }}
                    disabled={!inboundShipmentValid}
                    sx={{
                      cursor: inboundShipmentValid ? 'pointer' : 'auto',
                    }}
                  />
                </span>
              </Tooltip>
            }
            label="Inbound charges"
            sx={{
              color: inboundShipmentValid ? 'inherit' : 'text.disabled',
              cursor: inboundShipmentValid ? 'pointer' : 'default',
              '& .MuiFormControlLabel-label': {
                cursor: inboundShipmentValid ? 'pointer' : 'default',
              },
            }}
          />
          <FormControlLabel
            control={
              <span>
                <Tooltip
                  title="Primary order requires an outbound shipment to pro-rate by outbound"
                  disableHoverListener={outboundShipmentValid}
                >
                  <Checkbox
                    checked={
                      selectedChargesToConsolidate?.OUTBOUND &&
                      outboundShipmentValid
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedChargesToConsolidate({
                          ...selectedChargesToConsolidate,
                          OUTBOUND: true,
                        });
                      } else {
                        setSelectedChargesToConsolidate({
                          ...selectedChargesToConsolidate,
                          OUTBOUND: false,
                        });
                      }
                    }}
                    disabled={!outboundShipmentValid}
                    sx={{
                      cursor: outboundShipmentValid ? 'pointer' : 'not-allowed',
                    }}
                  />
                </Tooltip>
              </span>
            }
            label="Outbound charges"
            sx={{
              color: outboundShipmentValid ? 'inherit' : 'text.disabled',
              cursor: outboundShipmentValid ? 'pointer' : 'default',
              '& .MuiFormControlLabel-label': {
                cursor: outboundShipmentValid ? 'pointer' : 'default',
              },
            }}
          />
          {lineHaulEnabled && (
            <FormControlLabel
              disabled
              control={<Checkbox />}
              label="Line haul"
            />
          )}
        </Stack>
      </FormGroup>
    </Card>
  );
};

export default SelectChargesToConsolidate;
