import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { shallow } from 'zustand/shallow';
import useReport from '../hooks/use-report';
import useReportsStore from '../reports-store';

const ReportEditModal = ({
  reportEditModalOpen,
  setReportEditModalOpen,
}: {
  reportEditModalOpen: boolean;
  setReportEditModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { updateReportGroupConfiguration } = useReport();
  const [
    currentReportIndex,
    reportGroupConfiguration,
    setCurrentReportGroupConfiguration,
  ] = useReportsStore(
    (state) => [
      state.currentReportIndex,
      state.reportGroupConfigurations[state.currentReportIndex],
      state.setCurrentReportGroupConfiguration,
    ],
    shallow,
  );

  const handleChangeName = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (!isNil(reportGroupConfiguration)) {
      const data = { ...reportGroupConfiguration };
      data.name = e.target.value;
      setCurrentReportGroupConfiguration(data);
    }
  };

  return (
    <Dialog
      open={reportEditModalOpen}
      onClose={() => setReportEditModalOpen(false)}
      maxWidth="xs"
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Edit Report View</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Name"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={reportGroupConfiguration?.name ?? ''}
              onChange={handleChangeName}
              onBlur={() => updateReportGroupConfiguration(currentReportIndex)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} sx={{ pr: 1, pt: 1 }}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              onClick={() => setReportEditModalOpen(false)}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReportEditModal;
