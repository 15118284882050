import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { getChargeName } from 'shared/copy';
import { InputBillingMethod } from 'shared/types';
import {
  FreightChargeEntity,
  FuelBillingMethod,
  FuelChargeEntity,
  InvoiceShipmentWithChargesFragment,
  StopType,
  TariffZoneType,
} from '../../../../generated/graphql';
import { useQuantityAndKeepInSync } from '../../../orders/hooks/freight-charge-hooks';
import { InvoiceShipmentFragmentCustomCharge } from '../../types/types';

const CustomChargeRow = ({
  customCharge,
}: {
  customCharge: InvoiceShipmentFragmentCustomCharge;
}) => {
  const fuelSurchargeRate = customCharge.postedFuelSurchargeRate;
  return (
    <TableRow>
      <TableCell>{getChargeName(customCharge)}</TableCell>
      <TableCell>
        {sentenceCase(customCharge?.customChargeBillingMethod)}
      </TableCell>
      <TableCell>{customCharge?.rate}</TableCell>
      <TableCell>{customCharge?.quantity}</TableCell>
      <TableCell>
        {!isNil(fuelSurchargeRate) && (fuelSurchargeRate ?? 0) > 0
          ? `${fuelSurchargeRate}%`
          : '-'}
      </TableCell>
      <TableCell>{`$${customCharge?.total?.toFixed(2)}`}</TableCell>
    </TableRow>
  );
};

const InvoiceShipmentChargesTable = ({
  shipment,
}: {
  shipment: InvoiceShipmentWithChargesFragment;
}) => {
  const { charges, shipmentCharges } = shipment;

  const freightCharge: FreightChargeEntity | null | undefined = charges.find(
    (charge) => charge.__typename === 'FreightChargeEntity',
  ) as FreightChargeEntity;

  const quantity = useQuantityAndKeepInSync({
    isConsolidatedOrder: false,
    shipmentUuid: shipment.uuid,
    freightBillingMethod:
      (freightCharge?.billingMethod as InputBillingMethod) ?? null,
    tariff: undefined, // TODO: ask Luke about this
    freightChargeId: freightCharge?.uuid ?? '',
    isFromQuotePage: false,
    overridePackageWeight: shipment.overridePackageWeight,
    miles: undefined,
  });
  const freightChargeRow =
    !isNil(charges) &&
    !isEmpty(freightCharge) &&
    shipment.legs[0]?.endStop.stopType !== StopType.Recovery ? (
      <TableRow>
        <TableCell>Freight Charge</TableCell>
        <TableCell>
          {sentenceCase(freightCharge?.billingMethod)}{' '}
          {freightCharge?.tariff?.tariffZone?.type === TariffZoneType.Location
            ? `- ${freightCharge.tariff?.tariffZone?.name}`
            : ''}
        </TableCell>
        <TableCell>{freightCharge?.rate}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>{`$${shipmentCharges.totalFreightCharge}`}</TableCell>
      </TableRow>
    ) : null;

  const fuelCharge: FuelChargeEntity | null | undefined =
    freightCharge?.fuelCharge;

  const fuelChargeRow =
    !isNil(fuelCharge) &&
    shipment.legs[0]?.endStop.stopType !== StopType.Recovery ? (
      <TableRow>
        <TableCell>Fuel Charge</TableCell>
        <TableCell>{sentenceCase(fuelCharge?.type)}</TableCell>
        <TableCell>
          {fuelCharge?.type === FuelBillingMethod.FlatRate
            ? fuelCharge?.flatRate
            : fuelCharge?.surchargeRate}
        </TableCell>
        <TableCell>{1}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>{`$${shipmentCharges.totalFuelCharge}`}</TableCell>
      </TableRow>
    ) : null;

  const customCharges: InvoiceShipmentFragmentCustomCharge[] = (charges.filter(
    (charge) => charge.__typename === 'CustomChargeEntity',
  ) ?? []) as InvoiceShipmentFragmentCustomCharge[];

  const customChargeRows =
    !isNil(customCharges) && !isEmpty(customCharges)
      ? customCharges.map((customCharge) => {
          return (
            <CustomChargeRow
              key={customCharge.uuid}
              customCharge={customCharge}
            />
          );
        })
      : null;

  const totalChargesRow = (
    <TableRow sx={{ '& td': { border: 0 } }}>
      <TableCell>
        <strong>Stop total</strong>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>{`$${shipmentCharges.totalCharge}`}</TableCell>
    </TableRow>
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell>Charge Type</TableCell>
          <TableCell>Billing Item</TableCell>
          <TableCell>Rate</TableCell>
          <TableCell>Quantity</TableCell>
          <TableCell>Fuel surcharge %</TableCell>
          <TableCell>Total</TableCell>
        </TableHead>
        <TableBody>
          {freightChargeRow}
          {fuelChargeRow}
          {customChargeRows}
          {totalChargesRow}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceShipmentChargesTable;
