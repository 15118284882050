import { ToggleButtonGroup, ToggleButton } from '@mui/material';

enum WeightUnit {
  Kilograms = 'kg',
  Pounds = 'lb',
}

// beacuse there will never be a third option, we can use a boolean instead of an enum to simplify use
interface WeightUnitSelectorProps {
  useKilograms: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const WeightUnitSelector = ({
  useKilograms,
  onChange,
  disabled,
}: WeightUnitSelectorProps) => {
  return (
    <ToggleButtonGroup
      color="primary"
      value={useKilograms === true ? WeightUnit.Kilograms : WeightUnit.Pounds}
      exclusive
      onChange={(_, val) => {
        onChange(val === WeightUnit.Kilograms);
      }}
      sx={{ p: 0.5 }}
      disabled={disabled}
    >
      <ToggleButton value={WeightUnit.Pounds} sx={{ p: 0.5 }}>
        Lbs
      </ToggleButton>
      <ToggleButton value={WeightUnit.Kilograms} sx={{ p: 0.5 }}>
        Kgs
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default WeightUnitSelector;
