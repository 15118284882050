import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  useVehicleTypesMinimalQuery,
  VehicleTypesMinimalQuery,
  VehicleTypeStatus,
} from '../../../generated/graphql';
import { useAppDispatch } from '../../../redux/hooks';
import { updateOneContactValues } from '../redux/contact-values-slice';

type MinimalVehicleType = VehicleTypesMinimalQuery['vehicleTypes'][number];

type ContactVehicleTypesFieldProps = {
  disabled: boolean;
  contactUuid: string;
  vehicleTypeUuids: string[] | undefined | null;
};

const ContactVehicleTypesField = ({
  disabled,
  contactUuid,
  vehicleTypeUuids,
}: ContactVehicleTypesFieldProps) => {
  const dispatch = useAppDispatch();
  const { data: vehicleTypesData, loading } = useVehicleTypesMinimalQuery({
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = ({ target }: SelectChangeEvent<string[]>) => {
    if (Array.isArray(target.value)) {
      dispatch(
        updateOneContactValues({
          id: contactUuid,
          changes: {
            vehicleTypeUuids: target.value,
          },
        }),
      );
    }
  };

  // Start by showing only active vehicle types in the dropdown.
  const vehicleTypeOptions = useMemo<MinimalVehicleType[]>(() => {
    if (isNil(vehicleTypesData)) {
      return [];
    }
    const options = vehicleTypesData.vehicleTypes.filter(
      (vt) => vt.status === VehicleTypeStatus.Active,
    );
    // Fill in any necessary archived vehicle types.
    vehicleTypeUuids?.forEach((uuid) => {
      if (!options.some((vt) => vt.uuid === uuid)) {
        const vehicleType = vehicleTypesData.vehicleTypes.find(
          (vt) => vt.uuid === uuid,
        );
        if (!isNil(vehicleType)) {
          vehicleTypeOptions.push(vehicleType);
        }
      }
    });
    return options;
  }, [vehicleTypeUuids, vehicleTypesData]);

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel>Vehicle types</InputLabel>
      <Select<string[]>
        label="Vehicle types"
        size="small"
        multiple
        disabled={disabled || loading}
        value={vehicleTypeUuids ?? []}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box>
            {selected.map((value) => (
              <Chip
                key={value}
                label={
                  vehicleTypeOptions.find((vt) => vt.uuid === value)?.name ??
                  'Unknown vehicle type'
                }
              />
            ))}
          </Box>
        )}
      >
        {vehicleTypeOptions.map(({ uuid, name }) => (
          <MenuItem key={uuid} value={uuid}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContactVehicleTypesField;
