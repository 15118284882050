import useLineHaulLanes from './use-line-haul-lanes';
import useMe from './use-me';

export const useAvailableTariffTypes = () => {
  const { companyConfiguration } = useMe();
  const { lineHaulEnabled } = useLineHaulLanes({
    includeInactiveTerminals: false,
  });

  return {
    ordinaryTariffGroupAvailable: true,
    lineHaulTariffGroupAvailable: lineHaulEnabled,
    transferTariffGroupAvailable: true,
    pointToPointTariffGroupAvailable:
      companyConfiguration?.pointToPointTariffsEnabled === true,
  };
};
