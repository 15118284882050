import { Email } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import React, { CSSProperties } from 'react';
import { DocViewerDocument } from '../types/doc-viewer-document';

const useEmailStyles = () => {
  const theme = useTheme();
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '12px',
      width: '100%',
      height: '100%',
      wordBreak: 'break-word',
    } satisfies CSSProperties,
    icon: {
      fontSize: '20px',
      color: theme.palette.text.secondary,
    },
  };
};

type DocumentPreviewProps = {
  doc: DocViewerDocument;
  pdfScale?: number;
};

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  doc,
  pdfScale = 0.5,
}) => {
  const emailStyles = useEmailStyles();
  if (doc.fileType === 'application/pdf') {
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div style={{ width: '100%' }}>
          <Viewer fileUrl={doc.preSignedGetUrl} defaultScale={pdfScale} />
        </div>
      </Worker>
    );
  }
  if (doc.fileType === 'message/rfc822') {
    return (
      <Box style={emailStyles.container}>
        <Email style={emailStyles.icon} />
        <Typography fontSize={12}>{doc.fileName}</Typography>
      </Box>
    );
  }
  return (
    <object
      data={doc.preSignedGetUrl}
      width="100%"
      style={{ objectFit: 'cover' }}
    >
      {' '}
    </object>
  );
};
