import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import {
  StorageOrderDocument,
  useDeleteStorageUnitMutation,
} from '../../../../../generated/graphql';

interface ConfirmDeleteStorageUnitModalProps {
  open: boolean;

  storageUnitUuid: string | undefined;

  onSelectStorageUnit: (newStorageUnitUuid: string | null) => void;

  setStorageUnitToDeleteUuid: Dispatch<SetStateAction<string | undefined>>;

  handleClose: () => void;

  setDeleteStorageUnitSuccessMessageVisible: Dispatch<SetStateAction<boolean>>;

  setDeleteStorageUnitErrorMessageVisible: Dispatch<SetStateAction<boolean>>;
}

const ConfirmDeleteStorageUnitModal = ({
  open,
  storageUnitUuid,
  onSelectStorageUnit,
  setStorageUnitToDeleteUuid,
  handleClose,
  setDeleteStorageUnitSuccessMessageVisible,
  setDeleteStorageUnitErrorMessageVisible,
}: ConfirmDeleteStorageUnitModalProps) => {
  const [deleteStorageUnit, { loading: deleteStorageUnitLoading }] =
    useDeleteStorageUnitMutation({ refetchQueries: [StorageOrderDocument] });

  const onClose = () => {
    setStorageUnitToDeleteUuid(undefined);
    onSelectStorageUnit(null);
    handleClose();
  };

  const handleDelete = async () => {
    if (!isNil(storageUnitUuid)) {
      try {
        await deleteStorageUnit({
          variables: {
            deleteStorageUnitInput: {
              uuid: storageUnitUuid,
            },
          },
        });
        setDeleteStorageUnitSuccessMessageVisible(true);
        onClose();
      } catch (e) {
        setDeleteStorageUnitErrorMessageVisible(true);
        onClose();
      }
    } else {
      setDeleteStorageUnitErrorMessageVisible(true);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Container</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this container? This will also
          unassign all items currently in the container.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={deleteStorageUnitLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDelete}
          disabled={deleteStorageUnitLoading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteStorageUnitModal;
