import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import { WrappedText } from 'shared/pdfs/common';
import { PicklistsFragment } from '../../../generated/graphql';

const ITEM_COLUMN_WIDTH = '25%';
const LOCATION_COLUMN_WIDTH = '10%';
const QUANTITY_COLUMN_WIDTH = '5%';
const CONTACT_COLUMN_WIDTH = '10%';
const STORAGE_UNIT_NAME_COLUMN_WIDTH = '12.5%';
const STORAGE_ORDER_REF_COLUMN_WIDTH = '12.5%';
const PO_NUMBER_COLUMN_WIDTH = '12.5%';
const LOT_NUMBER_COLUMN_WIDTH = '12.5%';
const COLUMN_PADDING = '2px';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 8,
    fontFamily: 'Roboto',
    fontSize: '10px',
    flexDirection: 'column',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  titleBold: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  subHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    backgroundColor: '#BEBEBE',
    color: '#181818',
    marginBottom: '4px',
  },
  pageNumberContainer: {
    fontSize: '8px',
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
});

interface PicklistPdfProps {
  picklist: PicklistsFragment;
}
const PicklistPdf = ({ picklist }: PicklistPdfProps) => {
  return (
    <Document title="Picklist">
      <Page orientation="landscape" size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <View>
            <Text style={styles.titleBold}>Pick ticket</Text>
          </View>
          <View style={styles.subHeaderRow}>
            <Text>
              <Text style={{ fontWeight: 'bold' }}>Outbound ref #:</Text>{' '}
              {picklist.outboundReferenceNumber}
            </Text>
          </View>
          <View style={styles.tableHeader}>
            <Text style={{ width: ITEM_COLUMN_WIDTH, padding: COLUMN_PADDING }}>
              Item SKU / Description
            </Text>
            <Text
              style={{ width: LOCATION_COLUMN_WIDTH, padding: COLUMN_PADDING }}
            >
              Location
            </Text>
            <Text
              style={{ width: QUANTITY_COLUMN_WIDTH, padding: COLUMN_PADDING }}
            >
              Qty
            </Text>
            <Text
              style={{ width: CONTACT_COLUMN_WIDTH, padding: COLUMN_PADDING }}
            >
              Customer
            </Text>
            <Text
              style={{
                width: STORAGE_UNIT_NAME_COLUMN_WIDTH,
                padding: COLUMN_PADDING,
              }}
            >
              Container name
            </Text>
            <Text
              style={{
                width: STORAGE_ORDER_REF_COLUMN_WIDTH,
                padding: COLUMN_PADDING,
              }}
            >
              Receipt reference
            </Text>
            <Text
              style={{ width: PO_NUMBER_COLUMN_WIDTH, padding: COLUMN_PADDING }}
            >
              PO number
            </Text>
            <Text
              style={{
                width: LOT_NUMBER_COLUMN_WIDTH,
                padding: COLUMN_PADDING,
              }}
            >
              Lot number
            </Text>
          </View>
          {picklist.picklistItemGroups.map((picklistItemGroup) => (
            <View
              key={picklistItemGroup.itemGroup.uuid}
              style={{
                flexDirection: 'row',
                marginBottom: '4px',
              }}
            >
              <View
                style={{ width: ITEM_COLUMN_WIDTH, padding: COLUMN_PADDING }}
              >
                <WrappedText style={{ width: '100%' }}>
                  {picklistItemGroup.itemGroup.item.sku}
                </WrappedText>
                <WrappedText
                  style={{
                    color: 'gray',
                  }}
                >
                  {picklistItemGroup.itemGroup.item.description}
                </WrappedText>
              </View>
              <WrappedText
                style={{
                  width: LOCATION_COLUMN_WIDTH,
                  padding: COLUMN_PADDING,
                }}
              >
                {picklistItemGroup.itemGroup.storageUnit?.warehouseLocation
                  ?.name ?? '-'}
              </WrappedText>
              <WrappedText
                style={{
                  width: QUANTITY_COLUMN_WIDTH,
                  padding: COLUMN_PADDING,
                }}
              >
                {picklistItemGroup.pickedQuantity}
              </WrappedText>
              <WrappedText
                style={{ width: CONTACT_COLUMN_WIDTH, padding: COLUMN_PADDING }}
              >
                {picklistItemGroup.itemGroup.storageUnit?.storageOrder?.contact
                  .displayName ?? '-'}
              </WrappedText>
              <WrappedText
                style={{
                  width: STORAGE_UNIT_NAME_COLUMN_WIDTH,
                  padding: COLUMN_PADDING,
                }}
              >
                {picklistItemGroup.itemGroup.storageUnit?.name ?? '-'}
              </WrappedText>
              <WrappedText
                style={{
                  width: STORAGE_ORDER_REF_COLUMN_WIDTH,
                  padding: COLUMN_PADDING,
                }}
              >
                {sentenceCase(
                  picklistItemGroup.itemGroup.storageUnit?.storageOrder
                    ?.referenceNumber ?? '-',
                )}
              </WrappedText>
              <WrappedText
                style={{
                  width: PO_NUMBER_COLUMN_WIDTH,
                  padding: COLUMN_PADDING,
                }}
              >
                {picklistItemGroup.itemGroup.storageUnit?.storageOrder
                  ?.purchaseOrderNumber ?? '-'}
              </WrappedText>
              <WrappedText
                style={{
                  width: LOT_NUMBER_COLUMN_WIDTH,
                  padding: COLUMN_PADDING,
                }}
              >
                {picklistItemGroup.itemGroup.storageUnit?.storageOrder
                  ?.lotNumber ?? '-'}
              </WrappedText>
            </View>
          ))}
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
          style={styles.pageNumberContainer}
        />
      </Page>
    </Document>
  );
};

export default PicklistPdf;
