// eslint-disable-next-line no-restricted-imports
import { Alert, Button, Grid, Pagination, Snackbar } from '@mui/material';
import { isNil } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import DocumentUploadModal from '../../../common/components/document-upload-modal';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import useWindowDimensions from '../../../common/react-hooks/use-window-dimensions';
import {
  DocumentType,
  PermissionResource,
  RouteDocument,
  useCreateShipmentDocumentMutation,
  useGenerateShipmentPreSignedPutUrlMutation,
} from '../../../generated/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { selectStandardShipmentValuesById } from '../../shipments/redux/standard-shipments-values-slice';
import EndOfDayContext from '../end-of-day-context';
import { DocViewerDocument } from '../types/doc-viewer-document';

type EndOfDayDocumentViewerProps = {
  shipmentUuid: string | undefined;
  docType: DocumentType;
  docs: DocViewerDocument[];
  hasUpload?: boolean;
};

const EndOfDayDocumentViewer = ({
  shipmentUuid,
  docType,
  docs,
  hasUpload = true,
}: EndOfDayDocumentViewerProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const { height } = useWindowDimensions();
  const { setShouldLoadRoutes, selectedStopUuid } = useContext(EndOfDayContext);
  const shipment = useAppSelector((state) =>
    selectStandardShipmentValuesById(state, shipmentUuid ?? ''),
  );
  const [showUploadErrorSnackbar, setShowUploadErrorSnackbar] =
    useState<boolean>(false);
  const [showUnmatchErrorSnackbar, setShowUnmatchErrorSnackbar] =
    useState<boolean>(false);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [isDocumentUploadModalOpen, setIsDocumentUploadModalOpen] =
    useState(false);
  const [addShipmentDocument] = useCreateShipmentDocumentMutation({
    refetchQueries: [RouteDocument],
  });
  const [generateShipmentPreSignedPutUrl] =
    useGenerateShipmentPreSignedPutUrlMutation();
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setFileIndex(value - 1);
  };

  useEffect(() => {
    setFileIndex(0);
  }, [selectedStopUuid]);

  const updateShipmentWithDocument = async (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => {
    if (!isNil(shipment)) {
      const res = await addShipmentDocument({
        variables: {
          createShipmentDocumentInput: {
            documentType,
            fileName,
            fileType,
            name,
            shipmentUuid: shipment.uuid,
            isNewlyCreatedShipment: false,
          },
        },
      });
      const createShipmentDocument = res.data?.createShipmentDocument;
      if (isNil(res.errors) && !isNil(createShipmentDocument)) {
        setShouldLoadRoutes(true);
        setFileIndex(0);
      } else {
        setShowUploadErrorSnackbar(true);
      }
    }
  };

  const getAwsUrl = async (fileName: string, fileType: string) => {
    const res = await generateShipmentPreSignedPutUrl({
      variables: {
        generateShipmentPreSignedPutUrlInput: {
          fileName,
          fileType,
          shipmentUuid: shipmentUuid ?? '',
        },
      },
    });
    return res.data?.generateShipmentPreSignedPutUrl;
  };

  const sortedDocuments = useMemo(() => {
    const sortedDocs = docs;
    docs.sort((a, b) => {
      if (isNil(a.pageNumber) || isNil(b.pageNumber)) {
        return 0;
      }

      return a.pageNumber - b.pageNumber;
    });

    return sortedDocs;
  }, [docs]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        {hasUpload && (
          <Button
            variant="contained"
            size="small"
            onClick={() => setIsDocumentUploadModalOpen(true)}
            disabled={!canWriteEndOfDay}
          >
            Upload
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        <Pagination
          sx={{ float: 'right' }}
          count={docs.length}
          size="small"
          page={fileIndex + 1}
          onChange={handlePageChange}
        />
      </Grid>
      <Grid item xs={12}>
        {sortedDocuments.map((doc, index) => (
          <TabPanel key={doc.uuid} panelValue={index} selectedValue={fileIndex}>
            <object
              width="100%"
              height={
                doc.fileType === 'application/pdf'
                  ? `${height - 410}px`
                  : undefined
              }
              data={doc.preSignedGetUrl}
            >
              {' '}
            </object>
          </TabPanel>
        ))}
      </Grid>
      <DocumentUploadModal
        isOpen={isDocumentUploadModalOpen}
        onClose={() => setIsDocumentUploadModalOpen(false)}
        documentUploadType={docType}
        handleNewDocument={updateShipmentWithDocument}
        getAwsUrl={getAwsUrl}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showUploadErrorSnackbar}
        onClose={() => setShowUploadErrorSnackbar(false)}
        autoHideDuration={3000}
      >
        <Alert
          severity="error"
          onClose={() => setShowUploadErrorSnackbar(false)}
        >
          Error uploading document - please try again.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showUnmatchErrorSnackbar}
        onClose={() => setShowUnmatchErrorSnackbar(false)}
        autoHideDuration={3000}
      >
        <Alert
          severity="error"
          onClose={() => setShowUnmatchErrorSnackbar(false)}
        >
          Error unmatching document - please try again.
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default EndOfDayDocumentViewer;
