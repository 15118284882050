import { Box, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { safeDivide } from 'shared/math';
import { ReportSendJobBatchProgressFragment } from '../../../../generated/graphql';

const ReportSendJobBatchProgress = ({
  progress,
}: {
  progress: ReportSendJobBatchProgressFragment;
}) => {
  return (
    <Stack spacing={0}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box sx={{ width: '270px' }}>
          <LinearProgress
            color="info"
            variant="determinate"
            value={
              safeDivide(
                progress.successCount + progress.failCount,
                progress.totalCount,
              ) * 100
            }
          />
        </Box>
        <Typography
          sx={{ width: '80px' }}
          variant="caption"
          color="text.secondary"
        >
          {progress.successCount + progress.failCount} / {progress.totalCount}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={5}>
        <Typography color="success" variant="caption">
          {progress.successCount} succeeded
        </Typography>
        {progress.failCount > 0 && (
          <Typography color="error" variant="caption">
            {progress.failCount} failed
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ReportSendJobBatchProgress;
