import { isNil } from 'lodash';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useDriversLazyQuery } from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';

const useDrivers = () => {
  const [getDrivers] = useDriversLazyQuery({
    variables: {
      active: true,
    },
  });
  const [drivers, setDrivers] = useGlobalStore(
    (state) => [state.drivers, state.setDrivers],
    shallow,
  );

  const getDriverName = useCallback(
    (uuid: string | null | undefined) => {
      if (isNil(uuid)) {
        return '';
      }
      const driver = drivers?.find((d) => d.uuid === uuid);
      return `${driver?.firstName ?? ''} ${driver?.lastName ?? ''}`;
    },
    [drivers],
  );

  const loadDrivers = async () => {
    const res = await getDrivers({
      variables: {
        active: true,
      },
    });

    setDrivers(res.data?.drivers ?? []);
  };

  return { getDriverName, drivers, loadDrivers };
};

export default useDrivers;
