import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  FormGroup,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './general-styles';

const CACHE_PREFIX = 'BOOLEAN_AND_INPUT_FILTER';

export const initialBooleanAndInputOption = {
  value: undefined,
  isSelected: false,
};

export type BooleanAndInputOption = {
  value: number | undefined;
  isSelected: boolean;
};

interface BooleanAndInputFilterButtonProps {
  cacheId?: string;
  option: BooleanAndInputOption;
  handleChange: (newOption: BooleanAndInputOption) => void;
  filterTitle?: string;
  isNotSelectedOptionTitle?: string;
  isNotSelectedValueTitle?: string;
  isSelectedOptionLabel?: string;
  isSelectedValueEmptyTitle?: string;
  isSelectedValuePrefixTitle?: string;
  isSelectedValueSuffixTitle?: string;
}

const BooleanAndInputFilterButton = ({
  cacheId,
  option,
  handleChange,
  filterTitle,
  isNotSelectedOptionTitle,
  isNotSelectedValueTitle,
  isSelectedOptionLabel,
  isSelectedValueEmptyTitle,
  isSelectedValuePrefixTitle,
  isSelectedValueSuffixTitle,
}: BooleanAndInputFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      const cachedIsSelected = localStorage.getItem(
        `${CACHE_PREFIX}_IS_SELECTED_${cacheId}`,
      );
      if (!isNil(cachedIsSelected) || !isNil(cachedValue)) {
        handleChange({
          value:
            !isNil(cachedValue) && !Number.isNaN(cachedValue)
              ? Number.parseFloat(cachedValue)
              : undefined,
          isSelected: cachedIsSelected === 'true',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (newOption: BooleanAndInputOption) => {
    if (!isNil(cacheId)) {
      if (!isNil(newOption.isSelected)) {
        localStorage.setItem(
          `${CACHE_PREFIX}_IS_SELECTED_${cacheId}`,
          newOption.isSelected.toString(),
        );
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_IS_SELECTED_${cacheId}`);
      }
      if (!isNil(newOption.value)) {
        localStorage.setItem(
          `${CACHE_PREFIX}_VALUE_${cacheId}`,
          newOption.value.toString(),
        );
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
      }
    }
    handleChange(newOption);
  };

  const valueTitle = useMemo(() => {
    if (!isNil(option.value)) {
      return `${
        !isNil(isSelectedValuePrefixTitle) ? isSelectedValuePrefixTitle : ''
      }${option.value}${
        !isNil(isSelectedValueSuffixTitle) ? isSelectedValueSuffixTitle : ''
      }`;
    }
    if (!option.isSelected && !isNil(isNotSelectedValueTitle)) {
      return isNotSelectedValueTitle;
    }
    if (option.isSelected && !isNil(isSelectedValueEmptyTitle)) {
      return isSelectedValueEmptyTitle;
    }
    return '';
  }, [
    option,
    isNotSelectedValueTitle,
    isSelectedValueEmptyTitle,
    isSelectedValuePrefixTitle,
    isSelectedValueSuffixTitle,
  ]);

  return (
    <Box>
      <Button
        data-testid="boolean-and-input-filter-button"
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
          data-testid="boolean-and-input-filter-button-title"
        >
          <Typography sx={styles.filterTitle}>{filterTitle ?? ''}</Typography>
          <Typography sx={styles.filterValue}>{valueTitle}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="boolean-and-input-filter-menu"
        data-testid="boolean-and-input-filter-menu"
        open={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            data-testid="boolean-and-input-filter-all-option-button"
            key="all"
            onClick={() =>
              onChange({
                value: undefined,
                isSelected: false,
              })
            }
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                data-testid="boolean-and-input-filter-all-option-checkmark"
                sx={{
                  visibility: !option.isSelected ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>
                {!isNil(isNotSelectedOptionTitle)
                  ? isNotSelectedOptionTitle
                  : 'All'}
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            data-testid="boolean-and-input-filter-input-option-button"
            key="input"
            onClick={() =>
              onChange({
                value: option.value,
                isSelected: true,
              })
            }
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                data-testid="boolean-and-input-filter-input-option-checkmark"
                sx={{
                  visibility: option.isSelected ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <FormGroup>
                <TextField
                  size="small"
                  label={isSelectedOptionLabel ?? ''}
                  inputProps={{
                    pattern: '[0-9]*',
                    'data-testid': 'boolean-and-input-filter-input-textfield',
                  }}
                  sx={{ maxWidth: '250px' }}
                  value={option.value ?? ''}
                  onChange={(e) => {
                    const parsed = Number.parseFloat(e.target.value);
                    if (!Number.isNaN(parsed)) {
                      onChange({
                        value: parsed,
                        isSelected: true,
                      });
                    } else {
                      onChange({
                        value: undefined,
                        isSelected: true,
                      });
                    }
                  }}
                />
              </FormGroup>
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default BooleanAndInputFilterButton;
