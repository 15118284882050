import { sentenceCase } from 'change-case';
import dayjs, { ManipulateType } from 'dayjs';
import { isNil, omit } from 'lodash';
import { exhaustive } from 'shared/switch';
import {
  ReportGroupConfigurationFragment,
  ReportGroupConfigurationsQuery,
  ReportGroupConfigurationUpdateInput,
} from '../../generated/graphql';
import { getDriverNameLabel } from './components/utils';
import { ReportFilterType } from './types/report-filters';
import {
  OrderReportBucketData,
  ReportGroupConfiguration,
} from './types/report-types';

export const convertReportGroupConfigurationToUpdateInputType = (
  reportGroupConfigurationToUpdate: ReportGroupConfiguration,
): ReportGroupConfigurationUpdateInput => {
  return {
    ...omit(reportGroupConfigurationToUpdate, [
      '__typename',
      'contact',
      'contacts',
      'driver',
      'drivers',
      'businessDivision',
      'terminal',
    ]),
    ...{
      uuid: reportGroupConfigurationToUpdate.uuid,
      contactUuid: reportGroupConfigurationToUpdate.contact?.uuid ?? null,
      contactUuids:
        reportGroupConfigurationToUpdate.contacts?.map(
          (contact) => contact.uuid,
        ) ?? [],
      businessDivisionUuid:
        reportGroupConfigurationToUpdate.businessDivision?.uuid ?? null,
      driverUuid: reportGroupConfigurationToUpdate.driver?.uuid ?? null,
      driverUuids:
        reportGroupConfigurationToUpdate.drivers?.map(
          (driver) => driver.uuid,
        ) ?? [],
      terminalUuid: reportGroupConfigurationToUpdate.terminal?.uuid ?? null,
      lastNumberOfDays:
        reportGroupConfigurationToUpdate.lastNumberOfDays ?? null,
      lastNumberOfWeeks:
        reportGroupConfigurationToUpdate.lastNumberOfWeeks ?? null,
      lastNumberOfMonths:
        reportGroupConfigurationToUpdate.lastNumberOfMonths ?? null,
    },
  };
};

export const convertCreateDataToReportGroupConfiguration = (
  data: ReportGroupConfigurationFragment,
): ReportGroupConfiguration => {
  return {
    ...data,
    ...{
      contact: !isNil(data.contact)
        ? {
            uuid: data.contact?.uuid,
            displayName: data.contact?.displayName,
          }
        : undefined,
      contacts: !isNil(data.contacts)
        ? data.contacts.map((contact) => ({
            uuid: contact.uuid,
            displayName: contact.displayName,
          }))
        : undefined,
      driver: !isNil(data.driver)
        ? {
            uuid: data.driver?.uuid,
            firstName: data.driver?.firstName,
            lastName: data.driver?.lastName,
          }
        : undefined,
      drivers: !isNil(data.drivers)
        ? data.drivers.map((driver) => ({
            uuid: driver.uuid,
            firstName: driver.firstName,
            lastName: driver.lastName,
          }))
        : undefined,
      lastNumberOfDays: data.lastNumberOfDays ?? undefined,
      lastNumberOfWeeks: data.lastNumberOfWeeks ?? undefined,
      lastNumberOfMonths: data.lastNumberOfMonths ?? undefined,
      startDate: data.startDate,
      endDate: data.endDate,
    },
  };
};

export const convertDataToReportGroupConfigurations = (
  data: ReportGroupConfigurationsQuery,
): ReportGroupConfiguration[] => {
  return data.reportGroupConfigurations
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((config) => convertCreateDataToReportGroupConfiguration(config));
};

const getLastUnitsDateRangeString = (unit: ManipulateType, value: number) => {
  const startDate = dayjs()
    .tz()
    .subtract(value - 1, unit)
    .startOf(unit);
  const endDate = dayjs().tz().endOf(unit);
  return `${startDate.format('MM/DD')} - ${endDate.format('MM/DD/YY')}`;
};

export const getDateRangeLabel = (
  reportGroupConfiguration: ReportGroupConfiguration | undefined,
) => {
  let filterValue = '';
  if (!isNil(reportGroupConfiguration?.lastNumberOfDays)) {
    filterValue = getLastUnitsDateRangeString(
      'day',
      reportGroupConfiguration?.lastNumberOfDays ?? 0,
    );
  } else if (!isNil(reportGroupConfiguration?.lastNumberOfWeeks)) {
    filterValue = getLastUnitsDateRangeString(
      'week',
      reportGroupConfiguration?.lastNumberOfWeeks ?? 0,
    );
  } else if (!isNil(reportGroupConfiguration?.lastNumberOfMonths)) {
    filterValue = getLastUnitsDateRangeString(
      'month',
      reportGroupConfiguration?.lastNumberOfMonths ?? 0,
    );
  } else if (!isNil(reportGroupConfiguration?.startDate)) {
    filterValue = `${dayjs(reportGroupConfiguration?.startDate).format(
      'MM/DD',
    )} - ${dayjs(reportGroupConfiguration?.endDate).format('MM/DD/YY')}`;
  } else {
    filterValue = 'All';
  }
  return filterValue;
};

export const getFilterLabelValue = (
  type: ReportFilterType,
  reportGroupConfiguration: ReportGroupConfiguration | undefined,
) => {
  let filterValue = '';
  const stopTypes = reportGroupConfiguration?.stopTypes;
  const orderStatuses = reportGroupConfiguration?.orderStatuses;
  const contact = reportGroupConfiguration?.contact;
  const driver = reportGroupConfiguration?.driver;
  const businessDivision = reportGroupConfiguration?.businessDivision;
  const terminal = reportGroupConfiguration?.terminal;
  const contacts = reportGroupConfiguration?.contacts;
  const drivers = reportGroupConfiguration?.drivers;
  switch (type) {
    case ReportFilterType.SERVICE_DATE_RANGE:
      filterValue = getDateRangeLabel(reportGroupConfiguration);
      break;
    case ReportFilterType.REPORT_TYPE:
      filterValue = sentenceCase(
        reportGroupConfiguration?.defaultReportType ?? '',
      );
      break;
    case ReportFilterType.REVENUE_TYPE:
      filterValue = sentenceCase(
        reportGroupConfiguration?.reportRevenueType ?? '',
      );
      break;
    case ReportFilterType.AGGREGATION_PERIOD:
      filterValue = sentenceCase(
        reportGroupConfiguration?.reportAggregationPeriod ?? '',
      );
      break;
    case ReportFilterType.STOP_TYPE:
      if (isNil(stopTypes) || stopTypes.length === 0) {
        filterValue = `All`;
      } else {
        filterValue = stopTypes
          .map((pickupOrDelivery) => sentenceCase(pickupOrDelivery))
          .join(', ');
      }
      break;
    case ReportFilterType.ORDER_STATUS:
      if (isNil(orderStatuses) || orderStatuses.length === 0) {
        filterValue = `All`;
      } else {
        filterValue = orderStatuses
          .map((orderStatus) => sentenceCase(orderStatus))
          .join(', ');
      }
      break;
    case ReportFilterType.CONTACT:
      filterValue = contact?.displayName ?? 'All';
      break;
    case ReportFilterType.CONTACTS:
      if (isNil(contacts) || contacts.length === 0) {
        filterValue = 'All';
      } else {
        filterValue = `${contacts.length} selected`;
      }
      break;
    case ReportFilterType.DRIVER:
      if (!isNil(driver)) {
        filterValue = getDriverNameLabel(driver);
      } else {
        filterValue = 'All';
      }
      break;
    case ReportFilterType.DRIVERS:
      if (isNil(drivers) || drivers.length === 0) {
        filterValue = 'All';
      } else {
        filterValue = `${drivers.length} selected`;
      }
      break;
    case ReportFilterType.BUSINESS_DIVISION:
      filterValue = !isNil(businessDivision) ? businessDivision.name : 'All';
      break;
    case ReportFilterType.TERMINAL:
      filterValue = !isNil(terminal) ? terminal.code : 'All';
      break;
    default:
      exhaustive(type);
  }

  return filterValue;
};

export const getOrderReportDataTotals = (
  orderReportData: OrderReportBucketData[] | undefined,
): {
  totalRevenue: number;
  freightRevenue: number;
  fuelRevenue: number;
  customChargeRevenue: number;
  numberOfPackages: number;
  totalWeight: number;
  numberOfOrders: number;
} => {
  const data = isNil(orderReportData) ? [] : orderReportData;
  return data.reduce(
    (accumulator, currentValue) => {
      return {
        totalRevenue: accumulator.totalRevenue + currentValue.totalRevenue,
        freightRevenue:
          accumulator.freightRevenue + currentValue.freightRevenue,
        fuelRevenue: accumulator.fuelRevenue + currentValue.fuelRevenue,
        customChargeRevenue:
          accumulator.customChargeRevenue + currentValue.customChargeRevenue,
        numberOfPackages:
          accumulator.numberOfPackages + currentValue.numberOfPackages,
        totalWeight: accumulator.totalWeight + currentValue.totalWeight,
        numberOfOrders:
          accumulator.numberOfOrders + currentValue.numberOfOrders,
      };
    },
    {
      totalRevenue: 0,
      freightRevenue: 0,
      fuelRevenue: 0,
      customChargeRevenue: 0,
      numberOfPackages: 0,
      totalWeight: 0,
      numberOfOrders: 0,
    },
  );
};
