import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';

const CACHE_PREFIX = 'DOWNLOAD_TYPE';

export enum DownloadType {
  PDF = 'PDF',
  CSV = 'CSV',
}

interface DownloadTypeSelectionProps {
  cacheId?: string;
  selectedOption?: DownloadType;
  labelText: string;
  handleChange: (selectedDownloadType: DownloadType) => void;
  options?: DownloadType[];
}

const DownloadTypeSelection = ({
  cacheId,
  labelText,
  selectedOption = DownloadType.PDF,
  handleChange,
  options,
}: DownloadTypeSelectionProps) => {
  useEffect(() => {
    if (!isNil(cacheId)) {
      const cachedType = localStorage.getItem(`${CACHE_PREFIX}_${cacheId}`);
      if (!isNil(cachedType)) {
        handleChange(cachedType as DownloadType);
      }
    }
  }, []);

  const onChange = (downloadType: DownloadType) => {
    if (!isNil(cacheId)) {
      if (!isNil(downloadType)) {
        localStorage.setItem(
          `${CACHE_PREFIX}_${cacheId}`,
          downloadType.toString(),
        );
      } else {
        localStorage.removeItem(`${CACHE_PREFIX}_${cacheId}`);
      }
    }
    handleChange(downloadType);
  };

  return (
    <Box>
      <FormControl sx={{ display: 'flex' }}>
        <InputLabel id="download-type-selection-label">{labelText}</InputLabel>
        <Select
          labelId="download-type-selection-label"
          value={selectedOption}
          label={labelText}
          onChange={(e) => {
            onChange(e.target.value as DownloadType);
          }}
          size="small"
          sx={{ backgroundColor: 'white' }}
        >
          {(options ?? Object.values(DownloadType)).map((groupingType) => (
            <MenuItem key={groupingType} id={groupingType} value={groupingType}>
              {groupingType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DownloadTypeSelection;
