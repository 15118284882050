import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray, isNil, values } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import useContacts from '../../../common/react-hooks/use-contacts';
import useServices from '../../../common/react-hooks/use-services';
import useTags from '../../../common/react-hooks/use-tags';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  AutoApplyAccessorialConfigsDocument,
  AutoApplyAccessorialRuleType,
  PickupOrDelivery,
  useAccessorialNamesByBillingContactLazyQuery,
  useAccessorialNamesQuery,
  useCreateAutoApplyAccessorialConfigMutation,
} from '../../../generated/graphql';
import { CONTACT_BASED_AUTO_APPLY_ACCESSORIAL_RULES } from '../contants';
import useAddAutoApplyAccessorialRuleForm, {
  AddAutoApplyAccessorialRuleFormValues,
} from '../hooks/use-add-auto-apply-accessorial-rule-form';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AddAutoApplyAccessorialRuleModalProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;

  setShowAddedConfig: Dispatch<SetStateAction<boolean>>;

  setFailedToAddConfig: Dispatch<SetStateAction<boolean>>;
}

const getRuleTypeLabel = (rt: AutoApplyAccessorialRuleType) => {
  switch (rt) {
    case AutoApplyAccessorialRuleType.ContactAndService:
      return 'Contact + Service';
    case AutoApplyAccessorialRuleType.ContactAndServiceAndTag:
      return 'Contact + Service + Tag';
    case AutoApplyAccessorialRuleType.ServiceAndTag:
      return 'Service + Tag';
    case AutoApplyAccessorialRuleType.StopTypeAndTerminal:
      return 'Stop Type + Terminal';
    default:
      return sentenceCase(rt);
  }
};

const AddAutoApplyAccessorialRuleModal: React.FC<
  AddAutoApplyAccessorialRuleModalProps
> = ({
  open,
  onClose,
  setShowAddedConfig,
  setFailedToAddConfig,
}: AddAutoApplyAccessorialRuleModalProps) => {
  const [accessorialsToUse, setAccessorialsToUse] = useState<
    { name: string; uuid: string }[]
  >([]);
  const { contacts, getContactName } = useContacts();
  const { services } = useServices();
  const { tags } = useTags();
  const { terminals } = useTerminals({ includeInactiveTerminals: false }); // should we include non active
  const [getAccessorialsForContact] =
    useAccessorialNamesByBillingContactLazyQuery();

  const { data: globalAccessorials } = useAccessorialNamesQuery({
    variables: {
      onlyShowCompanyAccessorials: true,
    },
  });

  const [
    createAutoApplyAccessorialConfig,
    { loading: createAutoApplyAccessorialConfigLoading },
  ] = useCreateAutoApplyAccessorialConfigMutation({
    refetchQueries: [AutoApplyAccessorialConfigsDocument],
  });

  const {
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useAddAutoApplyAccessorialRuleForm();

  const ruleTypeValue = watch('ruleType');
  const contactValue = watch('contactUuid');

  const showContactPicker =
    ruleTypeValue === AutoApplyAccessorialRuleType.Contact ||
    ruleTypeValue === AutoApplyAccessorialRuleType.ContactAndService ||
    ruleTypeValue === AutoApplyAccessorialRuleType.ContactAndServiceAndTag;
  const showServicePicker =
    ruleTypeValue === AutoApplyAccessorialRuleType.Service ||
    ruleTypeValue === AutoApplyAccessorialRuleType.ContactAndService ||
    ruleTypeValue === AutoApplyAccessorialRuleType.ContactAndServiceAndTag ||
    ruleTypeValue === AutoApplyAccessorialRuleType.ServiceAndTag;
  const showTagPicker =
    ruleTypeValue === AutoApplyAccessorialRuleType.ContactAndServiceAndTag ||
    ruleTypeValue === AutoApplyAccessorialRuleType.ServiceAndTag;
  const showStopTypePicker =
    ruleTypeValue === AutoApplyAccessorialRuleType.StopType ||
    ruleTypeValue === AutoApplyAccessorialRuleType.StopTypeAndTerminal;
  const showTerminalPicker =
    ruleTypeValue === AutoApplyAccessorialRuleType.StopTypeAndTerminal;

  const [accessorialPickerOpen, setAccessorialPickerOpen] = useState(false);

  const setContactAccessorials = useCallback(
    async (contactUuid: string) => {
      if (ruleTypeValue === AutoApplyAccessorialRuleType.Contact) {
        const contactAccessorials = await getAccessorialsForContact({
          variables: {
            billingPartyContactUuid: contactUuid,
          },
        });
        const contactAccessorialsData =
          contactAccessorials.data?.accessorialsByBillingContact;
        if (!isNil(contactAccessorialsData)) {
          setAccessorialsToUse(contactAccessorialsData);
        }
      }
    },
    [getAccessorialsForContact, ruleTypeValue, setAccessorialsToUse],
  );

  useEffect(() => {
    // if the contact values changed, get the contact accessorials, otherwise use the globals.
    if (ruleTypeValue !== AutoApplyAccessorialRuleType.Contact) {
      setAccessorialsToUse(globalAccessorials?.accessorials ?? []);
    } else if (!isNil(contactValue)) {
      setContactAccessorials(contactValue);
    }
  }, [
    contactValue,
    globalAccessorials,
    ruleTypeValue,
    setAccessorialsToUse,
    setContactAccessorials,
  ]);

  const onSubmit: SubmitHandler<AddAutoApplyAccessorialRuleFormValues> = async (
    formValues,
  ) => {
    const {
      accessorialUuids,
      ruleType,
      serviceUuid,
      stopType,
      contactUuid,
      terminalUuid,
      tagUuid,
      contactsToIncludeUuids,
      contactsToExcludeUuids,
    } = formValues;
    if (accessorialUuids.length === 0) {
      setFailedToAddConfig(true);
      return;
    }
    try {
      await createAutoApplyAccessorialConfig({
        variables: {
          createAutoApplyAccessorialConfigInput: {
            accessorialUuid: accessorialUuids[0] ?? '',
            accessorialUuids,
            ruleType,
            serviceUuid:
              ruleType === AutoApplyAccessorialRuleType.Service ||
              ruleType === AutoApplyAccessorialRuleType.ContactAndService ||
              ruleType ===
                AutoApplyAccessorialRuleType.ContactAndServiceAndTag ||
              ruleType === AutoApplyAccessorialRuleType.ServiceAndTag
                ? serviceUuid
                : undefined,
            stopType:
              ruleType === AutoApplyAccessorialRuleType.StopType ||
              ruleType === AutoApplyAccessorialRuleType.StopTypeAndTerminal
                ? stopType
                : undefined,
            contactUuid:
              ruleType === AutoApplyAccessorialRuleType.Contact ||
              ruleType === AutoApplyAccessorialRuleType.ContactAndService ||
              ruleType === AutoApplyAccessorialRuleType.ContactAndServiceAndTag
                ? contactUuid
                : undefined,
            terminalUuid:
              ruleType === AutoApplyAccessorialRuleType.StopTypeAndTerminal
                ? terminalUuid
                : undefined,
            tagUuid:
              ruleType ===
                AutoApplyAccessorialRuleType.ContactAndServiceAndTag ||
              ruleType === AutoApplyAccessorialRuleType.ServiceAndTag
                ? tagUuid
                : undefined,
            contactsToIncludeUuids,
            contactsToExcludeUuids,
          },
        },
      });
      setShowAddedConfig(true);
      onClose(false);
    } catch (e) {
      setFailedToAddConfig(true);
      onClose(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Add Auto Apply Accessorial Rule
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="accessorialUuids"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel id="accessorials">Accessorial(s)</InputLabel>
                  <Select
                    labelId="accessorials"
                    size="small"
                    onChange={(e) => {
                      onChange(e);
                      setAccessorialPickerOpen(false);
                    }}
                    onOpen={() => setAccessorialPickerOpen(true)}
                    onClose={() => setAccessorialPickerOpen(false)}
                    open={accessorialPickerOpen}
                    value={value}
                    multiple
                    multiline
                    sx={{ width: '100%' }}
                    error={!isNil(errors.accessorialUuids)}
                    MenuProps={MenuProps}
                  >
                    {accessorialsToUse?.map((accessorial) => {
                      return (
                        <MenuItem
                          key={accessorial.uuid}
                          value={accessorial.uuid}
                        >
                          {sentenceCase(accessorial.name)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!isNil(errors.accessorialUuids) && (
                    <FormHelperText sx={{ color: '#D32F2F' }}>
                      {errors.accessorialUuids.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="ruleType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel id="age">Rule Type</InputLabel>
                  <Select
                    size="small"
                    onChange={onChange}
                    value={value}
                    sx={{ width: '100%' }}
                    error={!isNil(errors.ruleType)}
                    MenuProps={MenuProps}
                  >
                    {values(AutoApplyAccessorialRuleType).map((rt) => {
                      return (
                        <MenuItem key={rt} value={rt}>
                          {getRuleTypeLabel(rt)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!isNil(errors.ruleType) && (
                    <FormHelperText sx={{ color: '#D32F2F' }}>
                      {errors.ruleType.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          {/* eslint-disable-next-line no-nested-ternary */}
          <Grid item xs={12}>
            {showContactPicker && (
              <Controller
                name="contactUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Contact</InputLabel>
                    <Select
                      displayEmpty
                      size="small"
                      onChange={onChange}
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.contactUuid)}
                      placeholder="Select contact"
                      MenuProps={MenuProps}
                    >
                      {contacts.map((contact) => {
                        return (
                          <MenuItem key={contact.uuid} value={contact.uuid}>
                            {contact.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            )}
            {showServicePicker && (
              <Controller
                name="serviceUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Service</InputLabel>
                    <Select
                      size="small"
                      onChange={onChange}
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.serviceUuid)}
                      placeholder="Select service"
                      MenuProps={MenuProps}
                    >
                      {services.map((service) => {
                        return (
                          <MenuItem key={service.uuid} value={service.uuid}>
                            {sentenceCase(service.name)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.serviceUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.serviceUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            )}
            {showTagPicker && (
              <Controller
                name="tagUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Tag</InputLabel>
                    <Select
                      size="small"
                      onChange={onChange}
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.tagUuid)}
                      placeholder="Select tag"
                      MenuProps={MenuProps}
                    >
                      {tags.map((tag) => {
                        return (
                          <MenuItem key={tag.uuid} value={tag.uuid}>
                            {tag.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.tagUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.tagUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            )}
            {showStopTypePicker && (
              <Controller
                name="stopType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Stop Type</InputLabel>
                    <Select
                      displayEmpty
                      size="small"
                      onChange={onChange}
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.stopType)}
                      MenuProps={MenuProps}
                    >
                      {values(PickupOrDelivery).map((stopType) => {
                        return (
                          <MenuItem key={stopType} value={stopType}>
                            {sentenceCase(stopType)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.stopType) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.stopType.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            )}
            {showTerminalPicker && (
              <Controller
                name="terminalUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Terminal</InputLabel>
                    <Select
                      size="small"
                      displayEmpty
                      onChange={onChange}
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.terminalUuid)}
                      placeholder="Select terminal"
                      MenuProps={MenuProps}
                    >
                      {terminals.map((terminal) => {
                        return (
                          <MenuItem key={terminal.uuid} value={terminal.uuid}>
                            {terminal.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.terminalUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.terminalUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            )}
          </Grid>
          {!CONTACT_BASED_AUTO_APPLY_ACCESSORIAL_RULES.includes(
            ruleTypeValue,
          ) && (
            <Grid item xs={12}>
              <Controller
                name="contactsToIncludeUuids"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">
                      Contacts included with this rule
                    </InputLabel>
                    <Select
                      size="small"
                      multiple
                      label="Contacts to include"
                      MenuProps={MenuProps}
                      value={value ?? []}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      renderValue={(selected) => {
                        if (isArray(selected)) {
                          return (
                            <Box>
                              {selected.map((contactUuid, idx) => {
                                if (idx < 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={getContactName(contactUuid)}
                                    />
                                  );
                                }
                                if (idx === 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={`+ ${selected.length - idx}`}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </Box>
                          );
                        }
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        return <></>;
                      }}
                    >
                      {contacts?.map((contact) => (
                        <MenuItem key={contact.uuid} value={contact.uuid}>
                          {contact.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
              <Controller
                name="contactsToExcludeUuids"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">
                      Contacts to exclude from this rule
                    </InputLabel>
                    <Select
                      size="small"
                      multiple
                      label="Contacts to exclude"
                      MenuProps={MenuProps}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      value={value ?? []}
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      renderValue={(selected) => {
                        if (isArray(selected)) {
                          return (
                            <Box>
                              {selected.map((contactUuid, idx) => {
                                if (idx < 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={getContactName(contactUuid)}
                                    />
                                  );
                                }
                                if (idx === 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={`+ ${selected.length - idx}`}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </Box>
                          );
                        }
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        return <></>;
                      }}
                    >
                      {contacts?.map((contact) => (
                        <MenuItem key={contact.uuid} value={contact.uuid}>
                          {contact.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)}>
          Close
        </Button>
        <Button
          variant="contained"
          disabled={createAutoApplyAccessorialConfigLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAutoApplyAccessorialRuleModal;
