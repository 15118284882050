import { isNil } from 'lodash';
import { useOrdersByShipperBillOfLadingNumberLazyQuery } from '../../../../../generated/graphql';

const ACCEPTABLE_DUPLICATE_VALUES = ['', 'NONE', 'EMPTY'];

export type DuplicateBillOfLadingNumberOrder = {
  orderName: string;
  orderUuid: string;
  createdAt: Date;
};

const useGetHawbDuplicates = () => {
  const [ordersByShipperBillOfLadingNumberQuery] =
    useOrdersByShipperBillOfLadingNumberLazyQuery();
  return async ({
    shipperBillOfLadingNumber,
    orderUuid,
    includeInReviewScannedOrdersFromEmail,
    includeInReviewEdiOrders,
  }: {
    shipperBillOfLadingNumber: string | undefined;
    orderUuid: string;
    includeInReviewScannedOrdersFromEmail?: boolean;
    includeInReviewEdiOrders?: boolean;
  }) => {
    if (
      isNil(shipperBillOfLadingNumber) ||
      ACCEPTABLE_DUPLICATE_VALUES.includes(shipperBillOfLadingNumber)
    ) {
      return [];
    }
    const result = await ordersByShipperBillOfLadingNumberQuery({
      variables: {
        shipperBillOfLadingNumber,
        includeInReviewScannedOrdersFromEmail,
        includeInReviewEdiOrders,
      },
    });
    return (result.data?.ordersByShipperBillOfLadingNumber ?? [])
      .filter((order) => order.uuid !== orderUuid)
      .map((order) => {
        return {
          createdAt: order.createdAt,
          orderName: order.name,
          orderUuid: order.uuid,
          scannedOrderResult: order.scannedOrderResult,
          isDraftOrder: order.isDraftOrder,
          source: order.source,
        };
      });
  };
};

export { useGetHawbDuplicates };
