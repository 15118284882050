import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Modal,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  StopDriverMapSummaryFragment,
  useCreateDriverSettlementBillMutation,
  useUpdateDriverSettlementBillMutation,
} from '../../../../generated/graphql';
import styles from '../../../invoices/styles';
import useDriverSettlementStore from '../../driver-settlement-store';
import {
  getOldestAndNewestDates,
  groupStopDriverMapsByDriverUuid,
} from '../../utils';
import SettlementPreviewCard from './settlement-preview-card';

const CreateSettlementsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (refresh?: boolean) => void;
}) => {
  const [selectedStopDriverMaps] = useDriverSettlementStore(
    (state) => [state.selectedStopsForSettlements],
    shallow,
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [addToOpenSettlements, setAddToOpenSettlements] =
    useState<boolean>(true);
  const [stopDriverMapsByDriverUuid, setStopDriverMapsByDriverUuid] = useState<
    Record<string, StopDriverMapSummaryFragment[]> | undefined
  >();
  const [driverUuidToSettlementUuidMap, setDriverUuidToSettlementUuidMap] =
    useState<Record<string, string>>({});
  const [createDriverSettlementBill] = useCreateDriverSettlementBillMutation();
  const [updateDriverSettlementBill] = useUpdateDriverSettlementBillMutation();

  const onCloseModal = (refresh?: boolean) => {
    setAddToOpenSettlements(true);
    // We need to reset the driverUuidToSettlementUuidMap to ensure that
    // the modal is in a clean state when it is opened again.
    setDriverUuidToSettlementUuidMap({});
    onClose(refresh);
  };

  const updateDriverUuidToSettlementUuidMap = useCallback(
    (driverUuid: string, settlementUuid: string | undefined) => {
      if (isNil(settlementUuid)) {
        setDriverUuidToSettlementUuidMap((prev) => {
          const next = { ...prev };
          delete next[driverUuid];
          return next;
        });
      } else {
        setDriverUuidToSettlementUuidMap((prev) => ({
          ...prev,
          [driverUuid]: settlementUuid,
        }));
      }
    },
    [],
  );

  useEffect(() => {
    setStopDriverMapsByDriverUuid(
      isNil(selectedStopDriverMaps)
        ? selectedStopDriverMaps
        : groupStopDriverMapsByDriverUuid(selectedStopDriverMaps),
    );
  }, [selectedStopDriverMaps]);

  const createSettlements = async () => {
    setIsCreating(true);
    await Promise.all(
      Object.entries(stopDriverMapsByDriverUuid ?? {}).map(
        ([driverUuid, stopDriverMaps]) => {
          const stopDriverMapUuidAdds = stopDriverMaps.map(
            (stopDriverMap) => stopDriverMap.uuid,
          );
          const { oldestDate, newestDate } =
            getOldestAndNewestDates(stopDriverMaps);

          const settlementUuid = driverUuidToSettlementUuidMap[driverUuid];

          if (!isNil(settlementUuid)) {
            return updateDriverSettlementBill({
              variables: {
                updateDriverSettlementBillInput: {
                  driverSettlementBillUpdateInput: {
                    uuid: settlementUuid,
                    driverUuid,
                    stopDriverMapUuidAdds,
                  },
                },
              },
            });
          }
          return createDriverSettlementBill({
            variables: {
              createDriverSettlementBillInput: {
                driverSettlementBillCreateInput: {
                  driverUuid,
                  stopDriverMapUuidAdds,
                  billStartDate: oldestDate,
                  billEndDate: newestDate,
                  settlementDate: new Date(),
                },
              },
            },
          });
        },
      ),
    );

    setIsCreating(false);
    onCloseModal(true);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onCloseModal()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={[styles.modal, { width: '90vw' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <IconButton onClick={() => onCloseModal()}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h6">Review Settlements</Typography>
            <Typography variant="caption" color="text.secondary">
              Adding {selectedStopDriverMaps?.length} stops
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              startIcon={isCreating ? <CircularProgress size={15} /> : null}
              disabled={isCreating}
              variant="contained"
              sx={{ float: 'right' }}
              onClick={createSettlements}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={addToOpenSettlements}
                    onChange={(e) => setAddToOpenSettlements(e.target.checked)}
                  />
                }
                label="Add All to Open Settlements"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sx={{ height: '70vh', overflowY: 'scroll' }}>
            {Object.entries(stopDriverMapsByDriverUuid ?? {}).map(
              ([driverUuid, stopDriverMaps]) => (
                <SettlementPreviewCard
                  key={driverUuid}
                  driverUuid={driverUuid}
                  stopDriverMaps={stopDriverMaps}
                  addToOpenSettlements={addToOpenSettlements}
                  updateDriverUuidToSettlementUuidMap={
                    updateDriverUuidToSettlementUuidMap
                  }
                />
              ),
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CreateSettlementsModal;
