import { isNil } from 'lodash';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useContactsSimpleLazyQuery } from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';

const useContacts = () => {
  const [getContacts] = useContactsSimpleLazyQuery();
  const [contacts, setContacts] = useGlobalStore(
    (state) => [state.contacts, state.setContacts],
    shallow,
  );

  const getContactName = useCallback(
    (uuid: string | null | undefined) => {
      if (isNil(uuid)) {
        return '';
      }
      return (
        contacts.find((contact) => contact.uuid === uuid)?.displayName ?? ''
      );
    },
    [contacts],
  );

  const getContact = useCallback(
    (uuid: string | null | undefined) => {
      if (isNil(uuid)) {
        return null;
      }
      return contacts.find((contact) => contact.uuid === uuid) ?? null;
    },
    [contacts],
  );

  const loadContacts = async () => {
    const res = await getContacts({
      variables: {
        excludeNonActive: true,
      },
    });
    setContacts(res.data?.contacts ?? []);
  };

  return {
    loadContacts,
    getContactName,
    getContact,
    contacts,
  };
};

export default useContacts;
