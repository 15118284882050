import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  SxProps,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  ContactDocument,
  PermissionResource,
  useRemoveAddressMutation,
  useUpsertAddressMutation,
} from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectAddressErrorsById,
  upsertAddressErrors,
} from '../../addresses/redux/addresses-errors-slice';
import {
  AddressFormField,
  selectAddressById,
  selectAddressesByIds,
  updateAddress,
} from '../../addresses/redux/addresses-values-slice';
import {
  createAddressUpsertInput,
  getAddressErrorsResponse,
} from '../../addresses/redux/addresses-values-thunks';
import { selectContactAddressUuids } from '../redux/contact-values-slice';
import {
  addNewAddressToContact,
  removeAddressFromContact,
} from '../redux/contact-values-thunks';
import ContactPageMode from './contact-page-mode';

const styles = {
  buttonBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 2,
    py: 1,
    px: 1,
    mb: 2,
  } as SxProps,
};

type AddressBookPanelProps = {
  contactUuid: string;
  mode: ContactPageMode;
  handleChangeMode: (mode: ContactPageMode) => void;
};

function AddressBookPanel(props: AddressBookPanelProps) {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { contactUuid, handleChangeMode, mode } = props;
  const addressUuids =
    useAppSelector((state) => selectContactAddressUuids(state, contactUuid)) ??
    [];
  const [currentAddressUuid, setCurrentAddressUuid] = useState<string | null>(
    addressUuids[0] ?? null,
  );

  const [originalAddressData, setOriginalAddressData] =
    useState<AddressFormField>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [upsertAddressMutation, { loading }] = useUpsertAddressMutation({
    refetchQueries: [ContactDocument],
  });
  const [removeAddress, { loading: removeAddressLoading }] =
    useRemoveAddressMutation({
      refetchQueries: [ContactDocument],
    });

  const addresses = useAppSelector((state) =>
    selectAddressesByIds(state, addressUuids),
  );
  const address = useAppSelector((state) =>
    selectAddressById(state, currentAddressUuid ?? ''),
  );
  const errors = useAppSelector((state) =>
    selectAddressErrorsById(state, currentAddressUuid ?? ''),
  );

  useEffect(() => {
    setOriginalAddressData(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddressUuid]);

  const updateValue = (
    field: keyof AddressFormField,
    value: AddressFormField[typeof field],
  ) => {
    if (!isNil(currentAddressUuid)) {
      dispatch(
        updateAddress({
          id: currentAddressUuid,
          changes: { [field]: value },
        }),
      );
    }
  };

  const clearError = (field: keyof AddressFormField) => {
    if (!isNil(currentAddressUuid)) {
      dispatch(
        upsertAddressErrors({
          uuid: currentAddressUuid,
          [field]: undefined,
        }),
      );
    }
  };

  const handleSave = async () => {
    if (currentAddressUuid !== null) {
      const addressErrorsResponse = await dispatch(
        getAddressErrorsResponse({
          addressId: currentAddressUuid,
          allowEmpty: false,
        }),
      ).unwrap();
      if (!addressErrorsResponse.isValid) {
        return;
      }
      const addressUpsertInput = await dispatch(
        createAddressUpsertInput({ addressUuid: currentAddressUuid }),
      ).unwrap();
      await upsertAddressMutation({
        variables: {
          upsertAddressInput: {
            addressUpsertInput,
            contactUuid,
          },
        },
      });
      handleChangeMode(ContactPageMode.VIEW);
    }
  };

  const handleDiscardChanges = () => {
    if (mode === ContactPageMode.CREATE) {
      if (!isNil(currentAddressUuid)) {
        dispatch(
          removeAddressFromContact({
            contactUuid,
            addressUuid: currentAddressUuid,
          }),
        );
      }
      setCurrentAddressUuid(addressUuids[0] ?? null);
    } else if (mode === ContactPageMode.EDIT) {
      if (!isNil(currentAddressUuid) && !isNil(originalAddressData)) {
        dispatch(
          updateAddress({
            id: currentAddressUuid,
            changes: originalAddressData,
          }),
        );
      }
    }
    handleChangeMode(ContactPageMode.VIEW);
  };

  const buttons = [
    <Button key="back" onClick={async () => navigate('/contacts')}>
      Back
    </Button>,
    ((currentAddressUuid !== null && mode === ContactPageMode.EDIT) ||
      mode === ContactPageMode.CREATE) && (
      <Button
        key="discard"
        onClick={handleDiscardChanges}
        variant="outlined"
        disabled={!canWriteContacts}
      >
        Discard changes
      </Button>
    ),
    currentAddressUuid !== null && mode === ContactPageMode.EDIT && (
      <Button
        disabled={loading || !canWriteContacts}
        key="save"
        onClick={handleSave}
        variant="contained"
      >
        Save changes
      </Button>
    ),
    mode === ContactPageMode.CREATE && (
      <Button
        disabled={loading || !canWriteContacts}
        key="create"
        onClick={handleSave}
        variant="contained"
      >
        Save new address
      </Button>
    ),
    mode === ContactPageMode.VIEW && currentAddressUuid !== null && (
      <Button
        key="edit"
        onClick={() => handleChangeMode(ContactPageMode.EDIT)}
        variant="contained"
        disabled={!canWriteContacts}
      >
        Edit
      </Button>
    ),
    mode === ContactPageMode.VIEW && currentAddressUuid !== null && (
      <Button
        key="delete"
        onClick={() => setShowDeleteDialog(true)}
        variant="contained"
        disabled={!canWriteContacts}
      >
        Delete
      </Button>
    ),
    (mode === ContactPageMode.VIEW || currentAddressUuid === null) && (
      <Button
        key="create-new"
        onClick={async () => {
          const newAddress = await dispatch(
            addNewAddressToContact({ contactUuid }),
          ).unwrap();
          setCurrentAddressUuid(newAddress.uuid);
          handleChangeMode(ContactPageMode.CREATE);
        }}
        variant="contained"
        disabled={!canWriteContacts}
      >
        Add new address
      </Button>
    ),
  ];

  const deleteDialog = (
    <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
      <DialogContent>
        <DialogContentText>Delete address {address?.name}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={removeAddressLoading}
          onClick={() => setShowDeleteDialog(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={removeAddressLoading || !canWriteContacts}
          onClick={async () => {
            if (!isNil(currentAddressUuid)) {
              await removeAddress({
                variables: {
                  uuid: currentAddressUuid,
                },
              });
              setCurrentAddressUuid(null);
            }
            setShowDeleteDialog(false);
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  const disableInputs =
    mode === ContactPageMode.VIEW || currentAddressUuid === null;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Card sx={styles.buttonBox}>{buttons}</Card>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: 200,
            px: 2,
            py: 2,
          }}
        >
          {addresses
            .filter(
              (a) =>
                // Hide address being drafted in create mode from showing in the sidebar
                mode !== ContactPageMode.CREATE ||
                a.uuid !== currentAddressUuid,
            )
            .map((a) => (
              <Button
                disabled={mode !== ContactPageMode.VIEW}
                key={a.uuid}
                onClick={() => {
                  setCurrentAddressUuid(a.uuid);
                }}
                variant={
                  a.uuid === currentAddressUuid ? 'contained' : 'outlined'
                }
              >
                {a.name}
              </Button>
            ))}
        </Card>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: 2,
            py: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <TextField
              disabled={disableInputs || !canWriteContacts}
              error={!isNil(errors?.name)}
              helperText={errors?.name}
              label="Address name"
              onBlur={(event) => {
                if (event.target.value.length > 0 && !isNil(errors?.name)) {
                  clearError('name');
                }
              }}
              onChange={(event) => updateValue('name', event.target.value)}
              required
              value={address?.name ?? ''}
            />
            <TextField
              disabled={disableInputs || !canWriteContacts}
              error={!isNil(errors?.line1)}
              helperText={errors?.line1}
              label="Address line 1"
              onBlur={(event) => {
                if (event.target.value.length > 0 && !isNil(errors?.line1)) {
                  clearError('line1');
                }
              }}
              onChange={(event) => updateValue('line1', event.target.value)}
              required
              value={address?.line1 ?? ''}
            />
            <TextField
              disabled={disableInputs || !canWriteContacts}
              label="Address line 2"
              onChange={(event) => updateValue('line2', event.target.value)}
              value={address?.line2 ?? ''}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <TextField
              disabled={disableInputs || !canWriteContacts}
              error={!isNil(errors?.city)}
              helperText={errors?.city}
              label="City"
              onBlur={(event) => {
                if (event.target.value.length > 0 && !isNil(errors?.city)) {
                  clearError('city');
                }
              }}
              onChange={(event) => updateValue('city', event.target.value)}
              required
              value={address?.city ?? ''}
            />
            <TextField
              disabled={disableInputs || !canWriteContacts}
              error={!isNil(errors?.state)}
              helperText={errors?.state}
              label="State"
              onBlur={(event) => {
                if (event.target.value.length > 0 && !isNil(errors?.state)) {
                  clearError('state');
                }
              }}
              onChange={(event) => updateValue('state', event.target.value)}
              required
              value={address?.state ?? ''}
            />
            <TextField
              disabled={disableInputs || !canWriteContacts}
              error={!isNil(errors?.zip)}
              helperText={errors?.zip}
              label="Zip code"
              onBlur={(event) => {
                if (event.target.value.length > 0 && !isNil(errors?.zip)) {
                  clearError('zip');
                }
              }}
              onChange={(event) => updateValue('zip', event.target.value)}
              required
              value={address?.zip ?? ''}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <TextField
              disabled={disableInputs || !canWriteContacts}
              error={!isNil(errors?.country)}
              helperText={errors?.country}
              label="Country"
              onBlur={(event) => {
                if (event.target.value.length > 0 && !isNil(errors?.country)) {
                  clearError('country');
                }
              }}
              onChange={(event) => updateValue('country', event.target.value)}
              value={address?.country ?? ''}
            />
            {/* <TextField disabled label="Receiving hours start" /> */}
            {/* <TextField disabled label="Receiving hours end" /> */}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TextField
              disabled={disableInputs || !canWriteContacts}
              label="Driver special instructions"
              fullWidth
              multiline
              onChange={(event) =>
                updateValue('driverInstructions', event.target.value)
              }
              rows={6}
              value={address?.driverInstructions ?? ''}
            />
          </Box>
        </Card>
      </Box>
      {deleteDialog}
    </Box>
  );
}

export default AddressBookPanel;
