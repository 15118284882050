import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import GeneralDatePicker from '../../../../common/components/date-picker';
import useStyles from '../../../../common/components/general-styles';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import { convertNumberStringToFloat } from '../../../../common/utils/utils';
import {
  AllInvoicesForPaymentApplicationQuery,
  PaymentRail,
  PaymentType,
  useCreditTypesQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { CreateOrEdit } from '../invoices/enums';
import PaymentApplicationModal from './payment-application-modal';
import { formatPaymentRail } from './utils';

const EnterDetailsSection = ({
  amount,
  setAmount,
  date,
  setDate,
  referenceNumber,
  setReferenceNumber,
  comment,
  setComment,
  setOnApplyPaymentsStep,
  paymentType,
  setPaymentType,
  paymentRail,
  setPaymentRail,
  creditTypeUuid,
  setCreditTypeUuid,
  isCreditDebit = false,
  glTerminalUuid,
  setGlTerminalUuid,
}: {
  amount: string;
  setAmount: Dispatch<SetStateAction<string>>;
  date: Dayjs;
  setDate: Dispatch<SetStateAction<Dayjs>>;
  referenceNumber: string;
  setReferenceNumber: Dispatch<SetStateAction<string>>;
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  setOnApplyPaymentsStep: Dispatch<SetStateAction<boolean>>;
  paymentType: PaymentType;
  setPaymentType: Dispatch<SetStateAction<PaymentType>>;
  paymentRail: PaymentRail | null;
  setPaymentRail: Dispatch<SetStateAction<PaymentRail | null>>;
  creditTypeUuid: string | null;
  setCreditTypeUuid: Dispatch<SetStateAction<string | null>>;
  isCreditDebit?: boolean;
  glTerminalUuid: string | null;
  setGlTerminalUuid: Dispatch<SetStateAction<string | null>>;
}) => {
  const { data: creditTypesData } = useCreditTypesQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const { terminals, getTerminalName } = useTerminals({
    includeInactiveTerminals: false,
  });

  const onClickNext = () => {
    const parsedAmount = convertNumberStringToFloat(amount);
    if (isNil(parsedAmount)) {
      setErrorMessage('Invalid amount entered');
      return;
    }
    if (paymentType === PaymentType.Payment && isNil(paymentRail)) {
      setErrorMessage('Payment method required');
      return;
    }
    setOnApplyPaymentsStep(true);
  };

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="h6">
        Create {isCreditDebit === true ? 'Credit/Debit' : 'Payment'} (1/2)
      </Typography>
      {isCreditDebit === true && (
        <Stack
          direction="row"
          gap={2}
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="credit-debit-transaction-label">
              Transaction
            </InputLabel>
            <Select
              size="small"
              labelId="credit-debit-transaction-label"
              label="Transaction"
              value={paymentType}
              onChange={(e) => {
                setPaymentType(e.target.value as PaymentType);
              }}
            >
              <MenuItem key={PaymentType.Credit} value={PaymentType.Credit}>
                {capitalCase(PaymentType.Credit)}
              </MenuItem>
              <MenuItem key={PaymentType.Debit} value={PaymentType.Debit}>
                {capitalCase(PaymentType.Debit)}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="credit-debit-credit-type-label">
              Credit type
            </InputLabel>
            <Select
              size="small"
              labelId="credit-debit-credit-type-label"
              label="Credit type"
              value={creditTypeUuid}
              sx={{ width: '150px' }}
            >
              {creditTypesData?.creditTypes.map((creditType) => (
                <MenuItem
                  key={creditType.uuid}
                  value={creditType.uuid}
                  onClick={() => {
                    setCreditTypeUuid(creditType.uuid);
                  }}
                >
                  {creditType.name}
                </MenuItem>
              ))}
              <MenuItem
                value=""
                onClick={() => {
                  setCreditTypeUuid(null);
                }}
              >
                None
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      )}
      <TextField
        size="small"
        label="Amount ($)"
        value={amount}
        onBlur={() => {
          const parsedFloat = convertNumberStringToFloat(amount);
          if (!isNil(parsedFloat)) {
            setAmount(Math.abs(parsedFloat).toFixed(2));
          }
        }}
        onChange={(e) => setAmount(e.target.value)}
        required
      />
      {paymentType === PaymentType.Payment && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>Method: *</Typography>
          <RadioGroup
            value={paymentRail}
            onChange={(e) => {
              setPaymentRail(e.target.value as PaymentRail);
            }}
          >
            <Stack direction="row">
              {Object.values(PaymentRail).map((method) => (
                <FormControlLabel
                  key={method}
                  checked={paymentRail === method}
                  value={method}
                  control={<Radio />}
                  label={formatPaymentRail(method)}
                />
              ))}
            </Stack>
          </RadioGroup>
        </Stack>
      )}
      <TextField
        size="small"
        label="Reference Number"
        value={referenceNumber}
        onChange={(e) => setReferenceNumber(e.target.value)}
      />
      <TextField
        size="small"
        label="Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Stack spacing={1}>
        <Typography>Date</Typography>
        <GeneralDatePicker date={date} setDate={setDate} text="" width={340} />
      </Stack>
      {isCreditDebit === true && (
        <Stack spacing={1}>
          <Typography>GL Terminal</Typography>
          <AutocompleteFuzzy
            onChange={(_, newOption) => {
              setGlTerminalUuid(newOption?.value ?? null);
            }}
            defaultValue={
              !isNil(glTerminalUuid)
                ? {
                    value: glTerminalUuid,
                    label: getTerminalName(glTerminalUuid),
                  }
                : null
            }
            value={
              !isNil(glTerminalUuid)
                ? {
                    value: glTerminalUuid,
                    label: getTerminalName(glTerminalUuid),
                  }
                : null
            }
            options={terminals.map((terminal) => ({
              value: terminal.uuid,
              label: terminal.name,
            }))}
            matchSortOptions={{ keys: ['label'] }}
            renderInput={(params) => (
              <TextField {...params} label="GL Terminal" size="small" />
            )}
          />
        </Stack>
      )}
      <Stack>
        <Button
          variant="contained"
          onClick={onClickNext}
          sx={{ float: 'right' }}
        >
          Next
        </Button>
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      </Stack>
    </Stack>
  );
};

const CreatePaymentModal = ({
  open,
  onClose,
  contactUuid,
  paymentUuid,
  createOrEdit,
  isCreditDebit = false,
  type,
  setPaymentsListLoading,
  allInvoicesForPaymentApplication,
}: {
  open: boolean;
  onClose: () => void;
  contactUuid: string;
  paymentUuid?: string;
  createOrEdit: CreateOrEdit;
  isCreditDebit?: boolean;
  type?: PaymentType;
  setPaymentsListLoading?: Dispatch<SetStateAction<boolean>>;
  allInvoicesForPaymentApplication?: AllInvoicesForPaymentApplicationQuery;
}) => {
  const styles = useStyles();

  const [paymentType, setPaymentType] = useState<PaymentType>(
    isCreditDebit === true ? PaymentType.Credit : PaymentType.Payment,
  );
  const [paymentRail, setPaymentRail] = useState<PaymentRail | null>(
    PaymentRail.Ach,
  );
  const [creditTypeUuid, setCreditTypeUuid] = useState<string | null>(null);
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(dayjs());
  const [referenceNumber, setReferenceNumber] = useState('');
  const [comment, setComment] = useState('');
  const [onApplyPaymentsStep, setOnApplyPaymentsStep] = useState(false);
  const [glTerminalUuid, setGlTerminalUuid] = useState<string | null>(null);

  useEffect(() => {
    if (!isNil(type)) {
      setPaymentType(type);
    }
  }, [type]);

  useEffect(() => {
    if (!isNil(paymentUuid) && createOrEdit === CreateOrEdit.Edit) {
      setOnApplyPaymentsStep(true);
    }
  }, [paymentUuid, createOrEdit]);

  if (!onApplyPaymentsStep) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={[
            styles.modal,
            {
              width: 'fit-content',
              height: 'fit-content',
            },
          ]}
        >
          <Stack direction="row-reverse">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <EnterDetailsSection
            amount={amount}
            setAmount={setAmount}
            date={date}
            setDate={setDate}
            referenceNumber={referenceNumber}
            setReferenceNumber={setReferenceNumber}
            comment={comment}
            setComment={setComment}
            setOnApplyPaymentsStep={setOnApplyPaymentsStep}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            paymentRail={paymentRail}
            setPaymentRail={setPaymentRail}
            creditTypeUuid={creditTypeUuid}
            setCreditTypeUuid={setCreditTypeUuid}
            isCreditDebit={isCreditDebit}
            glTerminalUuid={glTerminalUuid}
            setGlTerminalUuid={setGlTerminalUuid}
          />
        </Box>
      </Modal>
    );
  }

  return (
    <PaymentApplicationModal
      amount={amount}
      date={date}
      setDate={setDate}
      referenceNumber={referenceNumber}
      comment={comment}
      setComment={setComment}
      onClose={onClose}
      contactUuid={contactUuid}
      setReferenceNumber={setReferenceNumber}
      initialPaymentUuid={paymentUuid}
      createOrEdit={createOrEdit}
      setAmount={setAmount}
      setPaymentsListLoading={setPaymentsListLoading}
      paymentType={paymentType}
      paymentRail={paymentRail}
      setPaymentRail={setPaymentRail}
      creditTypeUuid={creditTypeUuid}
      glTerminalUuid={glTerminalUuid}
      setGlTerminalUuid={setGlTerminalUuid}
      setCreditTypeUuid={setCreditTypeUuid}
      allInvoicesForPaymentApplication={allInvoicesForPaymentApplication}
    />
  );
};

export default CreatePaymentModal;
