import { Box } from '@mui/material';
import { isNil } from 'lodash';
import React, { CSSProperties } from 'react';

type CircleIconProps = {
  color: string;
  diameterPx?: string;
  strokeColor: string;
  text?: string;
  style?: CSSProperties;
};

const CircleIcon = ({
  color,
  diameterPx = '20px',
  strokeColor,
  text,
  style,
}: CircleIconProps) => {
  return (
    <Box
      sx={{
        ...style,
        border: '1.5px solid',
        borderColor: strokeColor,
        backgroundColor: color,
        width: diameterPx,
        height: diameterPx,
        justifyContent: 'center',
        color: 'white',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        zIndex: text !== '' && !isNil(text) ? 1 : 0,
      }}
    >
      {text}
    </Box>
  );
};

export default CircleIcon;
