import { Box, CardContent, Stack, styled, useTheme } from '@mui/material';
import { memo, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { chooseForegroundColor } from '../../../common/utils/colors';
import {
  DetailedLineHaulManifestFragment,
  LineHaulManifestStatus,
} from '../../../generated/graphql';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import ManifestCardHeader from './manifest-card-header';
import ManifestLoadSummary from './manifest-load-summary';

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
   &:last-child {
    padding-bottom: 10px;
  };

`);

const GroupedManifestCardContent = ({
  manifest,
}: {
  manifest: DetailedLineHaulManifestFragment;
}) => {
  const theme = useTheme();

  const [isSelected, setOpenedManifest] = useLineHaulDispatchStore(
    (state) => [
      state.selectedManifestUuids.includes(manifest?.uuid ?? ''),
      state.setOpenedManifest,
    ],
    shallow,
  );

  const cardHeaderBackgroundColor = useMemo(() => {
    if (isSelected) {
      return theme.palette.primary.main;
    }
    switch (manifest.status) {
      case LineHaulManifestStatus.Planning:
        return '#f7e5d5';
      case LineHaulManifestStatus.Departed:
        return '#dff0f5';
      case LineHaulManifestStatus.Arrived:
        return '#d2d4d4';
      default:
        return theme.palette.background.default;
    }
  }, [
    isSelected,
    manifest.status,
    theme.palette.background.default,
    theme.palette.primary.main,
  ]);

  const cardHeaderForegroundColor = chooseForegroundColor(
    cardHeaderBackgroundColor,
  );

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        setOpenedManifest(manifest);
      }}
    >
      <CardContent
        sx={{
          backgroundColor: cardHeaderBackgroundColor,
          p: 1,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ManifestCardHeader
            manifest={manifest}
            unmanifestedSegment={undefined}
            color={cardHeaderForegroundColor}
          />
        </Stack>
      </CardContent>
      <CardContentSpecialPadding>
        <Stack>
          <ManifestLoadSummary manifest={manifest} />
        </Stack>
      </CardContentSpecialPadding>
    </Box>
  );
};

export default memo(GroupedManifestCardContent);
