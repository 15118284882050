import { isNil } from 'lodash';
import {
  InboundMethod,
  OutboundMethod,
  StopOnRouteFragment,
  StopType,
} from '../../generated/graphql';

type StopInfo = {
  stopType?: StopType | null;
  inboundMethod?: InboundMethod | null;
  outboundMethod?: OutboundMethod | null;
};

const OUTBOUND_SHIPMENTS_PICKUP_OR_DELIVERY = [
  StopType.Delivery,
  StopType.Transfer,
];
const INBOUND_SHIPMENTS_PICKUP_OR_DELIVERY = [
  StopType.Pickup,
  StopType.Recovery,
];

export const isOutboundShipment = (shipment: {
  legs: { endStop: { stopType?: StopType | null } }[];
}): boolean => {
  const stopType = shipment?.legs[0]?.endStop.stopType;
  return (
    !isNil(stopType) && OUTBOUND_SHIPMENTS_PICKUP_OR_DELIVERY.includes(stopType)
  );
};

export const isInboundShipment = (shipment: {
  legs: { endStop: { stopType?: StopType | null } }[];
}): boolean => {
  const stopType = shipment?.legs[0]?.endStop.stopType;
  return (
    !isNil(stopType) && INBOUND_SHIPMENTS_PICKUP_OR_DELIVERY.includes(stopType)
  );
};

export const isPartnerCarrierPickup = ({ stop }: { stop: StopInfo }) => {
  return (
    stop?.stopType === StopType.Delivery &&
    stop?.outboundMethod === OutboundMethod.LocalDelivery
  );
};

export const isPartnerCarrierDropoff = ({ stop }: { stop: StopInfo }) => {
  return (
    stop?.stopType === StopType.Pickup &&
    stop?.inboundMethod === InboundMethod.InboundDelivery
  );
};

export const isPartnerCarrierStop = ({ stop }: { stop: StopInfo }) => {
  return isPartnerCarrierPickup({ stop }) || isPartnerCarrierDropoff({ stop });
};

export const areAllStopsDeliveries = (selectedStops: StopOnRouteFragment[]) => {
  return selectedStops?.every((stop) => stop.stopType === StopType.Delivery);
};

export const areAllStopsPartnerCarrierStops = (
  selectedStops: StopOnRouteFragment[],
) => {
  return selectedStops?.every((stop) =>
    isPartnerCarrierStop({
      stop,
    }),
  );
};
