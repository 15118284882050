type TemplatedFunction<T, R> = (...args: T[]) => R;

/**
 * invokeAll is a function that takes in a list of functions and
 * returns a function that will call each function in the list with the
 * provided arguments.
 *
 * This is useful for composing prop functions together in a single function.
 * We allow undefined functions in the list to allow for optional functions
 * to be passed in.
 * @param args list of functions to call
 * @returns function that will call each function in the list with the provided arguments
 */
const invokeAll =
  <T, R>(...args: (TemplatedFunction<T, R> | undefined)[]) =>
  (vars: T) => {
    return args.forEach((arg) => {
      if (arg !== undefined) {
        arg(vars);
      }
    });
  };

export default invokeAll;
