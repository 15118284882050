import {
  Card,
  CardContent,
  Checkbox,
  Stack,
  styled,
  Table,
  TableBody,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isEmpty, isNil, sumBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import { getTodayTimeISOString } from 'shared/date';
import { shallow } from 'zustand/shallow';
import { chooseForegroundColor } from '../../../common/utils/colors';
import { getDriverName } from '../../../common/utils/utils';
import { GenerateRoutesPreviewRouteFragment } from '../../../generated/graphql';
import GenerateRouteResultStopRow from './generate-route-result-stop-row';
import useGenerateRoutesStore from './use-generate-routes-store';

dayjs.extend(isBetween);

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 0px;
   &:last-child {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`);

const GenerateRouteResultCardComponent = ({
  route,
  headerColor,
}: {
  route: GenerateRoutesPreviewRouteFragment;
  headerColor: string;
}) => {
  const [isSelected, setSelectedDriverUuid] = useGenerateRoutesStore(
    (state) => [
      state.selectedDriverUuid === route.driver.uuid,
      state.setSelectedDriverUuid,
    ],
    shallow,
  );

  const stopIndicesOutsideOfAppointmentWindow = useMemo(() => {
    return filterNotNil(
      route.stops.map((stop, idx) => {
        const etaTime = dayjs(stop.eta);
        if (
          (!isNil(stop.stop.endAppointmentTime) &&
            etaTime.isAfter(
              getTodayTimeISOString(stop.stop.endAppointmentTime),
              'minutes',
            )) ||
          (!isNil(stop.stop.appointmentTime) &&
            etaTime.isBefore(
              getTodayTimeISOString(stop.stop.appointmentTime),
              'minutes',
            ))
        ) {
          return idx + 1;
        }
        return null;
      }),
    );
  }, [route.stops]);

  return (
    <Card variant="outlined">
      <CardContentSpecialPadding>
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              backgroundColor: headerColor,
              p: 0.5,
              pl: 1,
              color: chooseForegroundColor(headerColor),
              cursor: 'pointer',
            }}
            onClick={() => {
              if (isSelected === true) {
                setSelectedDriverUuid(undefined);
              } else {
                setSelectedDriverUuid(route.driver.uuid);
              }
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Checkbox
                sx={{ p: 0 }}
                style={{
                  color: chooseForegroundColor(headerColor),
                }}
                size="small"
                checked={isSelected}
              />
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold' }}
                gutterBottom
              >
                {!isNil(route.driver)
                  ? `${getDriverName(route.driver)}${!isNil(route.driver.driverReferenceNumber) ? ` - ${route.driver.driverReferenceNumber}` : ''}`
                  : 'New Route'}
              </Typography>
            </Stack>
            <Typography variant="caption">
              {route.stops.length} stops |{' '}
              {sumBy(
                route.stops,
                (stop) => stop.stop.shipment?.order?.weight ?? 0,
              )}{' '}
              lbs
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 0.5 }}
          >
            <Typography variant="caption" color="text.secondary">
              {route.equipment?.name}
              {!isNil(route.equipment?.vehicleType)
                ? ` - ${route.equipment?.vehicleType.name}`
                : ''}
            </Typography>
            {!isEmpty(stopIndicesOutsideOfAppointmentWindow) && (
              <Typography variant="caption" color="error">
                {pluralize(
                  'Stop',
                  stopIndicesOutsideOfAppointmentWindow.length,
                )}{' '}
                {stopIndicesOutsideOfAppointmentWindow.join(', ')}{' '}
                {pluralize('are', stopIndicesOutsideOfAppointmentWindow.length)}{' '}
                outside of appt. window
              </Typography>
            )}
          </Stack>
          <Stack sx={{ maxHeight: '110px', overflowY: 'scroll' }}>
            <Table size="small">
              <TableBody>
                {route.stops.map((s, index) => (
                  <GenerateRouteResultStopRow
                    key={s.stop.uuid}
                    index={index}
                    stopData={s}
                  />
                ))}
              </TableBody>
            </Table>
          </Stack>
        </Stack>
      </CardContentSpecialPadding>
    </Card>
  );
};

export const GenerateRouteResultCard = React.memo(
  GenerateRouteResultCardComponent,
);
