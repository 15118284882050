import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMe from '../../../../../common/react-hooks/use-me';
import { useOutstandingInvoicesForCustomerLazyQuery } from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import useContactIsOverCreditLimit from '../../../../contacts/hooks/use-contact-is-over-credit-limit';
import useContactWithBalances from '../../../../contacts/hooks/use-contact-with-balances';

const OrderOverLimitDialog = ({
  contactUuid,
  modalMode,
  isQuote = false,
}: {
  contactUuid: string | undefined | null;
  modalMode?: boolean;
  isQuote?: boolean;
}) => {
  const { companyConfiguration } = useMe();
  const useJournalNumberForInvoice =
    companyConfiguration?.useJournalNumberForInvoice === true;
  const navigate = useNavigate();
  const setOpenedOrderUuid = useGlobalStore(
    (state) => state.setCurrentOrderUuid,
  );
  const { contact, creditHoldEnabled, loadContactWithBalances } =
    useContactWithBalances();
  const { isOverCreditLimit } = useContactIsOverCreditLimit({ contactUuid });
  const [getOutstandingInvoicesForCustomer, { data: outstandingInvoicesData }] =
    useOutstandingInvoicesForCustomerLazyQuery();

  useEffect(() => {
    if (!isNil(contactUuid)) {
      loadContactWithBalances(contactUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUuid]);

  useEffect(() => {
    if (creditHoldEnabled && !isNil(contactUuid)) {
      getOutstandingInvoicesForCustomer({
        variables: {
          contactUuid,
        },
      });
    }
  }, [creditHoldEnabled, contactUuid, getOutstandingInvoicesForCustomer]);

  if (!creditHoldEnabled && isOverCreditLimit !== true) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Dialog
      open
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack>
          {isOverCreditLimit === true
            ? 'Customer over credit limit'
            : 'Customer on credit hold'}
          <Typography color="text.secondary">{contact?.displayName}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {isEmpty(outstandingInvoicesData?.outstandingInvoicesForCustomer) ||
        isNil(outstandingInvoicesData?.outstandingInvoicesForCustomer) ||
        isOverCreditLimit === true ? (
          <Typography>
            {isQuote ? 'Quote' : 'Order'} creation is blocked
          </Typography>
        ) : (
          <Typography>
            {isQuote ? 'Quote' : 'Order'} creation is blocked - found{' '}
            {outstandingInvoicesData?.outstandingInvoicesForCustomer.length}{' '}
            invoices outstanding:
            <br />
            {outstandingInvoicesData?.outstandingInvoicesForCustomer
              .map((invoice) =>
                useJournalNumberForInvoice
                  ? String(invoice.journalNumber)
                  : invoice.name,
              )
              .sort((a, b) =>
                useJournalNumberForInvoice
                  ? Number(a) - Number(b)
                  : a.localeCompare(b),
              )
              .join(', ')}
          </Typography>
        )}
        <Typography />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpenedOrderUuid(undefined);
            if (modalMode !== true) {
              navigate(-1);
            }
          }}
        >
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(OrderOverLimitDialog);
