import { useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import ConsigneeShipmentsView from '../domains/customer-portal/components/consignee-shipments-view';
import ConsigneeLoadPage from '../domains/customer-portal/components/customer-portal-order/consignee-load-page';

export const ConsigneeShipmentsPage = () => {
  const { companyUuid } = useParams();
  if (isNotNilOrEmptyString(companyUuid)) {
    return <ConsigneeShipmentsView companyUuid={companyUuid} />;
  }
  return null;
};

export const ShipmentProfileLoadPage = () => {
  const { loadUuid } = useParams();
  if (isNotNilOrEmptyString(loadUuid)) {
    return <ConsigneeLoadPage loadUuid={loadUuid} />;
  }
  return null;
};
