import { Box } from '@mui/material';
import { OrderCommentValues } from '../../../../domains/orders/components/order-form/forms/types';
import CommentsRow from './comments-row';

type CommentsListProps = {
  comments: OrderCommentValues[];
  onEditComment: (commentUuid: string, newComment: string) => Promise<void>;
  onCheckShowOnInvoice?: (commentUuid: string, value: boolean) => Promise<void>;
  onDeleteComment: (commentUuid: string) => Promise<void>;
  maxHeight?: string;
};

const CommentsList = ({
  comments,
  onEditComment,
  onCheckShowOnInvoice,
  onDeleteComment,
  maxHeight = '100%',
}: CommentsListProps) => {
  return (
    <Box maxHeight={maxHeight} width="100%" sx={{ overflowY: 'auto' }}>
      {comments.map((comment) => (
        <CommentsRow
          key={comment!.uuid!}
          orderComment={comment}
          onEditComment={onEditComment}
          onCheckShowOnInvoice={onCheckShowOnInvoice}
          onDeleteComment={onDeleteComment}
        />
      ))}
    </Box>
  );
};

export default CommentsList;
