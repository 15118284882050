import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, Stack, SxProps, Typography } from '@mui/material';

import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import {
  FindTariffGroupsWithContactAndServicesCombinationOutput,
  TariffGroupsDocument,
  useDeleteTariffGroupMutation,
  useDuplicateTariffGroupMutation,
  useFindTariffGroupsWithContactAndServiceCombinationLazyQuery,
  useUpdateTariffGroupMutation,
} from '../../../../generated/graphql';
import WarningDuplicateContactServicesModal from '../warning-duplicate-contact-service-modal';
import useTariffGroupStore from './store/tariff-group-state-store';
import useTariffPageStore from './store/tariff-page-store';
import TariffModalContent from './tariff-modal-content/tariff-modal-content';

const styles = {
  modalInnerContainer: {
    bgcolor: 'background.paper',
    boxShadow: 24,
    color: 'black',
    gap: '20px',
    p: 3,
    width: '100%',
  } as SxProps,
  selectDropdownContainer: {
    alignItems: 'center',
    width: '50%',
    gap: '10px',
  },
};

const EditTariffGroupModal = ({
  isOpen,
  setIsOpen,
  tariffGroupUuid,
  isCompanyDefaultTariff,
  isTransferTariff,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  tariffGroupUuid: string;
  isCompanyDefaultTariff?: boolean;
  isTransferTariff?: boolean;
}) => {
  const zoneGroupsEnabled = useFeatureFlag(FeatureFlag.FF_ZONE_GROUPS_ENABLED);
  const [isUpdateTariffGroupLoading, setIsUpdateTariffGroupLoading] =
    useState(false);

  const [setErrorMessage] = useTariffPageStore(
    (state) => [state.setErrorMessage],
    shallow,
  );

  const [warningModalContent, setWarningModalContent] = useState<
    FindTariffGroupsWithContactAndServicesCombinationOutput[] | undefined
  >(undefined);

  const [
    isLoadingTariffGroup,
    initStoreWithExistingTariffGroup,
    buildUpdateTariffGroupInputFromState,
    resetStore,
    resetToOriginalTariffGroup,
    setUseZoneGroups,
  ] = useTariffGroupStore(
    (state) => [
      state.isLoadingTariffGroup,
      state.initStoreWithExistingTariffGroup,
      state.buildUpdateTariffGroupInputFromState,
      state.resetStore,
      state.resetToOriginalTariffGroup,
      state.setUseZoneGroups,
    ],
    shallow,
  );

  const handleClose = () => {
    resetStore();
    setIsOpen(false);
  };

  const [updateTariffGroup] = useUpdateTariffGroupMutation({
    onError: ({ message }) =>
      setErrorMessage(`Failed to update tariff: ${message}`),
    onCompleted: () => handleClose(),
  });
  const [deleteTariffGroup, { loading: deleteTariffGroupLoading }] =
    useDeleteTariffGroupMutation({
      onError: ({ message }) =>
        setErrorMessage(`Failed to delete tariff: ${message}`),
      onCompleted: () => handleClose(),
    });
  const [duplicateTariffGroup, { loading: duplicateTariffGroupLoading }] =
    useDuplicateTariffGroupMutation({
      onError: ({ message }) =>
        setErrorMessage(`Failed to duplicate tariff: ${message}`),
      onCompleted: () => handleClose(),
    });
  const [findMatchingTariffGroups] =
    useFindTariffGroupsWithContactAndServiceCombinationLazyQuery();

  const handleApply = async () => {
    setIsUpdateTariffGroupLoading(true);

    // check that this contact service mapping doesn't exist already in another tariff group.
    const updateTariffGroupInput =
      buildUpdateTariffGroupInputFromState(tariffGroupUuid);

    const existingTariffGroupsWithTheseCombinations =
      await findMatchingTariffGroups({
        variables: {
          mappingsList:
            updateTariffGroupInput.contactToServicesMappings?.map((m) => {
              return {
                contactUuid: m.contactUuid,
                serviceUuids: m.serviceUuids,
              };
            }) ?? [],
        },
      });

    const data =
      existingTariffGroupsWithTheseCombinations.data
        ?.findTariffGroupsWithContactAndServiceCombination;

    const cleanedUpContent = data
      ?.map((content) => {
        return {
          ...content,
          matchingTariffGroups: content.matchingTariffGroups.filter(
            (group) => group.tariffGroupUuid !== tariffGroupUuid,
          ),
        };
      })
      .filter((content) => content.matchingTariffGroups.length > 0);

    // TODO(Dwayne): Disabled temporarily, seems to false positive sometimes
    if ((cleanedUpContent?.length ?? 0) > 100000) {
      setWarningModalContent(cleanedUpContent);
    } else {
      await updateTariffGroup({
        variables: {
          updateTariffGroupInput,
        },
        refetchQueries: [TariffGroupsDocument],
      });
    }
    setIsUpdateTariffGroupLoading(false);
  };

  const handleDuplicate = async () => {
    await duplicateTariffGroup({
      variables: {
        duplicateTariffGroupInput: {
          uuid: tariffGroupUuid,
        },
      },
      refetchQueries: [TariffGroupsDocument],
    });
  };

  const handleDelete = async () => {
    await deleteTariffGroup({
      variables: {
        uuid: tariffGroupUuid,
      },
      refetchQueries: [TariffGroupsDocument],
    });
  };

  const handleReset = async () => {
    resetToOriginalTariffGroup();
  };

  useEffect(() => {
    if (isOpen) {
      initStoreWithExistingTariffGroup(tariffGroupUuid);
    }
    setUseZoneGroups(zoneGroupsEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const isLoading =
    isLoadingTariffGroup ||
    isUpdateTariffGroupLoading ||
    deleteTariffGroupLoading ||
    duplicateTariffGroupLoading;

  return (
    <Dialog fullWidth maxWidth={false} open={isOpen} onClose={handleClose}>
      <Stack
        direction="column"
        justifyContent="center"
        sx={styles.modalInnerContainer}
      >
        <Stack direction="row" justifyContent="space-between" gap="10px">
          <Typography variant="h6">
            {' '}
            {!isNil(isCompanyDefaultTariff) && isCompanyDefaultTariff
              ? 'Edit default tariff'
              : 'Edit tariff'}{' '}
          </Typography>
          <Stack direction="row" gap="10px">
            <Button
              sx={{ pl: 4, pr: 4 }}
              disabled={duplicateTariffGroupLoading}
              variant="contained"
              color="info"
              onClick={handleDuplicate}
              startIcon={<ContentCopyIcon fontSize="small" />}
            >
              Duplicate
            </Button>
            {(isNil(isCompanyDefaultTariff) ||
              !isCompanyDefaultTariff ||
              isTransferTariff === true) && (
              <Button
                sx={{ pl: 4, pr: 4 }}
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                startIcon={<DeleteIcon fontSize="small" />}
              >
                Delete
              </Button>
            )}
            <Button
              sx={{ pl: 4, pr: 4 }}
              variant="contained"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
        <TariffModalContent isCompanyDefaultTariff={isCompanyDefaultTariff} />
        <Stack
          direction="row"
          width="50%"
          alignSelf="flex-end"
          justifyContent="flex-end"
          gap="10px"
        >
          <Button
            sx={{ pl: 4, pr: 4 }}
            variant="outlined"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            sx={{ pl: 4, pr: 4 }}
            variant="contained"
            onClick={handleApply}
            disabled={isLoading}
          >
            Apply
          </Button>
        </Stack>
      </Stack>

      <WarningDuplicateContactServicesModal
        warningModalContent={warningModalContent}
        closeWarningModal={() => {
          setWarningModalContent(undefined);
        }}
      />
    </Dialog>
  );
};

export default EditTariffGroupModal;
