import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMarkStopAsIncompleteMutation } from '../../../../../../../generated/graphql';

interface ConfirmMarkIncompleteModalProps {
  open: boolean;
  onClose: () => Promise<void>;
  saveOrder?: () => Promise<boolean>;
  stopUuid: string;
}

const ConfirmMarkIncompleteModal: React.FC<ConfirmMarkIncompleteModalProps> = ({
  open,
  onClose,
  saveOrder,
  stopUuid,
}: ConfirmMarkIncompleteModalProps) => {
  const [markStopAsIncomplete, { loading }] = useMarkStopAsIncompleteMutation();
  const handleClose = () => onClose();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Mark stop as incomplete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to mark this stop as incomplete? This action
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={loading === true ? <CircularProgress /> : null}
          variant="contained"
          disabled={loading}
          onClick={async () => {
            if (!isNil(saveOrder)) {
              await saveOrder();
            }
            await markStopAsIncomplete({
              variables: { uuid: stopUuid },
            });
            await handleClose();
          }}
        >
          Mark incomplete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMarkIncompleteModal;
