// eslint-disable-next-line no-restricted-imports
import { Box, Grid, Typography } from '@mui/material';
import QuickbooksChargeMappings from './components/quickbooks-charge-mappings';
import QuickbooksHeaderMappings from './components/quickbooks-header-mappings';

const QuickbooksFileMapping = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        p: 1,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h6">Quickbooks</Typography>
      </Grid>
      <QuickbooksHeaderMappings />
      <QuickbooksChargeMappings />
    </Box>
  );
};

export default QuickbooksFileMapping;
