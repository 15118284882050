import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import useMe from '../../../../../../../common/react-hooks/use-me';
import { validateEmail } from '../../../../../../../common/utils/utils';
import {
  EmailOrderDocumentStatus,
  OrderDocumentType,
  useReportsEmailSenderIsVerifiedLazyQuery,
  useShallowContactLazyQuery,
} from '../../../../../../../generated/graphql';
import {
  getFileNamePrefixFromOrderDocumentType,
  sendOrderDocumentEmail,
} from '../../../../../../generated-documents/utils';

const EmailOrderDocuments = ({
  isOpen,
  generatedPdf,
  generatedBlob,
  isQuote,
  documentType,
}: {
  isOpen: boolean;
  generatedPdf: JSX.Element;
  generatedBlob?: Blob | undefined;
  isQuote: boolean;
  documentType: OrderDocumentType;
}) => {
  const { companyData } = useMe();
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();
  const [getContact] = useShallowContactLazyQuery();

  const [senderEmail, setSenderEmail] = useState<string | undefined>();
  const [recipientEmails, setRecipientEmails] = useState<
    string | null | undefined
  >(undefined);
  const [recipientEmailError, setRecipientEmailError] = useState<string>('');
  const [emailSendStatus, setEmailSendStatus] = useState<string>('');
  const [emailSending, setEmailSending] = useState<boolean>(false);
  const { user } = useMe();
  const [getReportsEmailSenderIsVerified, { data: reportsEmailVerifiedData }] =
    useReportsEmailSenderIsVerifiedLazyQuery();

  useEffect(() => {
    if (isOpen && !isNil(order.contactUuid)) {
      const fetchRecipientEmail = async () => {
        const res = await getContact({
          variables: {
            uuid: order.contactUuid,
          },
        });
        setRecipientEmails(res.data?.contact.billingContactEmail);
      };
      fetchRecipientEmail();
    }
  }, [order.contactUuid, isOpen, getContact]);

  useEffect(() => {
    if (isOpen && !isNil(user?.reportsEmail)) {
      setSenderEmail(user?.reportsEmail);
    }
  }, [user?.reportsEmail, isOpen]);

  const checkReportsEmailSenderIsVerified = () => {
    if (!isNil(user?.uuid)) {
      getReportsEmailSenderIsVerified({
        variables: {
          userUuid: user?.uuid ?? '',
        },
      });
    }
  };

  useEffect(() => {
    if (!isNil(user?.uuid)) {
      checkReportsEmailSenderIsVerified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uuid]);

  useEffect(() => {
    if (!isNil(recipientEmails)) {
      const recipientEmailsList = recipientEmails
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email.length);
      if (recipientEmailsList.some((email) => !validateEmail(email))) {
        setRecipientEmailError('Invalid email');
      } else {
        setRecipientEmailError('');
      }
    } else {
      setRecipientEmailError('');
    }
  }, [recipientEmails]);

  const clear = () => {
    setEmailSendStatus('');
    setRecipientEmails(undefined);
    setEmailSending(false);
  };

  useEffect(() => {
    if (!isOpen) {
      clear();
    }
  }, [isOpen]);

  return (
    <Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="row"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <FormControl>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap={1}
          >
            <TextField
              label="Sender"
              value={senderEmail ?? 'No report email found'}
              size="small"
              sx={{ width: '300px', '& fieldset': { border: 'none' } }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {reportsEmailVerifiedData?.reportsEmailSenderIsVerified ===
                    true ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip
                          title="Report email has been verified"
                          placement="top"
                        >
                          <CheckCircleIcon
                            sx={{ fontSize: 15 }}
                            color="success"
                          />
                        </Tooltip>
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Tooltip
                          title={`${
                            isEmpty(senderEmail)
                              ? `No report email found`
                              : 'Report email is unverified'
                          }. Email will send from company invoice email.`}
                          placement="top"
                        >
                          <CloseIcon sx={{ fontSize: 15 }} color="error" />
                        </Tooltip>
                      </Stack>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Recipients (comma-separated)"
              value={recipientEmails}
              onChange={(e) => setRecipientEmails(e.target.value)}
              size="small"
              error={!isEmpty(recipientEmailError)}
              helperText={recipientEmailError}
              sx={{ width: '370px' }}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Box>
        </FormControl>
        <Box sx={{ float: 'right' }}>
          <Button
            variant="contained"
            color="info"
            sx={{ ml: 'auto', mr: 'auto', mt: '10px', mb: '10px' }}
            onClick={async () => {
              setEmailSendStatus('Preparing to send...');
              setEmailSending(true);
              const blob = generatedBlob ?? (await pdf(generatedPdf).toBlob());
              const orderIdentifier = isQuote ? order.number : order.name;
              const status = await sendOrderDocumentEmail({
                blob,
                fileName: `${getFileNamePrefixFromOrderDocumentType(
                  documentType,
                  isQuote,
                )}-${orderIdentifier}.pdf`,
                documentType,
                documentName: `${documentType} ${orderIdentifier}`,
                isQuote,
                orderIdentifier,
                recipientEmails,
                senderEmail:
                  reportsEmailVerifiedData?.reportsEmailSenderIsVerified ===
                    true && !isEmpty(senderEmail)
                    ? senderEmail
                    : undefined,
                companyUuid: companyData?.uuid,
              });
              if (status === EmailOrderDocumentStatus.Success) {
                setEmailSendStatus('Email sent successfully');
              } else {
                setEmailSendStatus('Error sending email');
              }
              setEmailSending(false);
            }}
            disabled={
              isNil(recipientEmails) ||
              !isEmpty(recipientEmailError) ||
              emailSending
            }
          >
            Email
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography fontStyle="italic" fontSize="16px">
          {emailSendStatus}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmailOrderDocuments;
