import { Dayjs } from 'dayjs';
import {
  DeadlineType,
  OrderAction,
  StopType,
  StopAction,
} from '../../../../generated/graphql';

export enum StopTypeGroup {
  PickupRecovery = 'Pickup / Recovery',
  DeliveryTransfer = 'Delivery / Transfer',
}

export enum DownloadAction {
  DownloadPodReport = 'Download POD report',
  DownloadPodReportWithUnsignedPods = 'Download POD report with unsigned PODs',
  DownloadCoverSheet = 'Download cover sheet',
  DownloadOutboundLabels = 'Download outbound labels',
  DownloadLotLabels = 'Download lot labels',
}

export interface BulkActionData {
  warehouseUuid: string | undefined;
  mawb: string | undefined;
  secondaryReferenceNumber: string | undefined;
  stopTypeGroup: StopTypeGroup | undefined;
  stopTypes: StopType[] | undefined;
  deadlineType: DeadlineType | undefined;
  deadlineDate: Dayjs | undefined;
  orderAction: OrderAction | undefined;
  stopAction: StopAction | undefined;
  downloadActions: DownloadAction[];
  serviceDate: Dayjs | undefined;
}
