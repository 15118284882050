import {
  Alert,
  Button,
  CircularProgress,
  Fade,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Snackbar,
  Chip,
} from '@mui/material';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource, useRolesQuery } from '../../../generated/graphql';
import CreateOrEdit from '../../management/enums/create-or-edit';
import RolesModal from './roles-modal';

const RolesTable = () => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<CreateOrEdit>(CreateOrEdit.Create);
  const [hoveredRoleUuid, setHoveredRoleUuid] = useState<string>();
  const [selectedRoleUuid, setSelectedRoleUuid] = useState<string>();

  const [successSnackbarVisible, setSuccessSnackbarVisible] = useState(false);
  const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsRoles, hasMasterPermission } =
    getPermissionsFlags(userPermissions, PermissionResource.SettingsRoles);

  const { loading: rolesLoading, data: rolesData } = useRolesQuery({
    variables: {
      findRolesInput: { includeInternalRoles: hasMasterPermission },
    },
  });

  if (rolesLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={successSnackbarVisible}
      >
        <Alert
          severity="success"
          onClose={() => setSuccessSnackbarVisible(false)}
        >
          Successfully saved role
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={errorSnackbarVisible}
      >
        <Alert severity="error" onClose={() => setErrorSnackbarVisible(false)}>
          Error saving role
        </Alert>
      </Snackbar>
      <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      setMode(CreateOrEdit.Create);
                      setOpen(true);
                    }}
                    variant="contained"
                    disabled={!canWriteSettingsRoles}
                  >
                    Add Role
                  </Button>
                </TableCell>
              </TableRow>
              {rolesData?.roles?.roles?.map((role) => {
                return (
                  <TableRow
                    key={role.uuid}
                    onMouseEnter={() => {
                      setHoveredRoleUuid(role.uuid);
                    }}
                    onMouseLeave={() => {
                      setHoveredRoleUuid(undefined);
                    }}
                  >
                    <TableCell>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {role.name}
                        {role?.isInternalRole === true && (
                          <Chip
                            size="small"
                            variant="outlined"
                            label="Internal"
                            color="error"
                          />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <Fade in={hoveredRoleUuid === role.uuid}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSelectedRoleUuid(role.uuid);
                            setMode(CreateOrEdit.Edit);
                            setOpen(true);
                          }}
                          disabled={!canWriteSettingsRoles}
                        >
                          Edit
                        </Button>
                      </Fade>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>
      </Stack>
      <RolesModal
        selectedRoleUuid={selectedRoleUuid}
        open={open}
        setOpen={setOpen}
        createOrEdit={mode}
        setCreateOrEdit={setMode}
        setSelectedRoleUuid={setSelectedRoleUuid}
        setSuccessSnackbarVisible={setSuccessSnackbarVisible}
        setErrorSnackbarVisible={setErrorSnackbarVisible}
      />
    </>
  );
};

export default RolesTable;
