import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty, isNil, times, constant, flatten } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GeneralLedgerCodeAutocomplete from '../../../../common/components/general-ledger-code-autocomplete';
import { validateString } from '../../../../common/form/formValidators';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  useAccessorialLazyQuery,
  useCreateAccessorialMutation,
  useFuelProfilesQuery,
  useUpdateAccessorialMutation,
  ZoneBasedAccessorialChargeGroupArrayUpdateInput,
  ZoneBasedAccessorialChargeGroupCreateInput,
  ZoneBasedAccessorialCreateInput,
  ZoneBasedAccessorialMatrixItemArrayUpdateInput,
  ZoneBasedAccessorialMatrixItemCreateInput,
  ZoneBasedAccessorialZoneArrayUpdateInput,
} from '../../../../generated/graphql';
import { muiStyles } from '../accessorial.styles';
import {
  ALL_TERMINALS,
  FormMode,
  getBackUrl,
  NO_FUEL_PROFILE,
  ZoneBasedAccessorialChargeGroup,
  ZoneBasedAccessorialChargeGroupTextField,
  ZoneBasedAccessorialFormErrors,
  ZoneBasedAccessorialFormValues,
  ZoneBasedAccessorialMatrixItem,
  ZoneBasedAccessorialMatrixItemTextField,
  ZoneBasedAccessorialZone,
  ZoneBasedAccessorialZoneTextField,
} from './common';

const useFormValues = (
  uuid: string | undefined,
): [
  ZoneBasedAccessorialFormValues,
  React.Dispatch<React.SetStateAction<ZoneBasedAccessorialFormValues>>,
] => {
  const [formValues, setFormValues] = useState<ZoneBasedAccessorialFormValues>({
    name: '',
    zones: [],
    chargeGroups: [],
    matrixItems: [],
    percentForSettlement: 100,
    terminalUuid: ALL_TERMINALS,
    fuelProfileUuid: NO_FUEL_PROFILE,
    code: null,
    ediCode: null,
    invoiceDisplayName: null,
    generalLedgerCodeId: null,
  });

  const [accessorialQuery] = useAccessorialLazyQuery();

  useEffect(() => {
    if (uuid !== undefined) {
      accessorialQuery({ variables: { uuid: uuid ?? '' } }).then((response) => {
        const accessorial = response.data?.accessorial;
        if (!isNil(accessorial)) {
          const isZoneBased =
            accessorial.__typename === 'ZoneBasedAccessorialEntity';
          const {
            name,
            code,
            ediCode,
            percentForSettlement,
            invoiceDisplayName,
            generalLedgerCode,
          } = accessorial;
          let zones: {
            uuid: string;
            name: string;
          }[] = [];
          let chargeGroups: {
            uuid: string;
            name: string;
          }[] = [];
          let matrixItems: {
            zoneUuid: string;
            chargeGroupUuid: string;
            uuid: string;
            rate: number;
          }[] = [];

          if (isZoneBased) {
            zones = accessorial.zones;
            chargeGroups = accessorial.chargeGroups;
            matrixItems = accessorial.matrixItems;
          }

          const terminalUuid = isZoneBased ? accessorial?.terminal?.uuid : null;
          const fuelProfileUuid = isZoneBased
            ? accessorial?.fuelProfile?.uuid
            : null;
          const newFormValues: ZoneBasedAccessorialFormValues = {
            name,
            zones: zones.map((zone) => ({
              ...zone,
              toBeCreated: null,
              isUpdated: null,
            })),
            chargeGroups: chargeGroups.map((chargeGroup) => ({
              ...chargeGroup,
              toBeCreated: null,
              isUpdated: null,
            })),
            matrixItems: [],
            percentForSettlement: percentForSettlement ?? null,
            terminalUuid: !isEmpty(terminalUuid) ? terminalUuid : ALL_TERMINALS,
            code: code ?? null,
            ediCode: ediCode ?? null,
            fuelProfileUuid: !isEmpty(fuelProfileUuid)
              ? fuelProfileUuid
              : NO_FUEL_PROFILE,
            invoiceDisplayName: invoiceDisplayName ?? null,
            generalLedgerCodeId: generalLedgerCode?.id ?? null,
          };
          const matrixFormItems: (ZoneBasedAccessorialMatrixItem | null)[][] =
            [];

          zones.forEach((zone, i) => {
            const currRowItems: (ZoneBasedAccessorialMatrixItem | null)[] = [];
            chargeGroups.forEach((chargeGroup, j) => {
              if (zones[i] && chargeGroups[j]) {
                const zoneRate = matrixItems.find(
                  (item) =>
                    item.zoneUuid === zones[i]?.uuid &&
                    item.chargeGroupUuid === chargeGroups[j]?.uuid,
                );
                currRowItems.push(
                  zoneRate
                    ? {
                        uuid: zoneRate.uuid,
                        rate: zoneRate.rate,
                        toBeCreated: null,
                        isUpdated: null,
                      }
                    : null,
                );
              }
            });
            matrixFormItems.push(currRowItems);
          });
          setFormValues({ ...newFormValues, matrixItems: matrixFormItems });
        }
      });
    }
  }, [accessorialQuery, uuid]);

  return [formValues, setFormValues];
};

const makeCreateInput = (
  formValues: ZoneBasedAccessorialFormValues,
  contactUuid: string | undefined,
  isAuthoCodeRequired: boolean,
  templateUuid?: string | undefined,
) => {
  const zones: ZoneBasedAccessorialCreateInput[] = formValues.zones.map(
    (zone: ZoneBasedAccessorialZone) =>
      ({ name: zone.name }) as ZoneBasedAccessorialCreateInput,
  );
  const chargeGroups: ZoneBasedAccessorialChargeGroupCreateInput[] =
    formValues.chargeGroups.map(
      (chargeGroup: ZoneBasedAccessorialChargeGroup) =>
        ({
          name: chargeGroup.name,
        }) as ZoneBasedAccessorialChargeGroupCreateInput,
    );
  const matrixItems: ZoneBasedAccessorialMatrixItemCreateInput[] = [];
  formValues.matrixItems.forEach((matrixItemRow, i) => {
    matrixItemRow.forEach((matrixItem, j) => {
      if (
        matrixItem !== null &&
        matrixItem.rate !== null &&
        formValues.zones.length >= i
      ) {
        matrixItems.push({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          zoneName: formValues.zones[i].name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          chargeGroupName: formValues.chargeGroups[j].name,
          rate: matrixItem.rate,
        });
      }
    });
  });
  return {
    variables: {
      input: {
        accessorialCreateInput: {
          zoneBasedAccessorialCreateInput: {
            name: formValues.name,
            zones,
            zoneBasedChargeGroups: chargeGroups,
            zoneBasedMatrixItems: matrixItems,
          },
          matchingGlobalAccessorial: templateUuid,
          contactUuid,
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid !== ALL_TERMINALS
              ? formValues.terminalUuid
              : null,
          code: formValues.code,
          ediCode: formValues.ediCode,
          fuelProfileUuid:
            formValues.fuelProfileUuid !== NO_FUEL_PROFILE
              ? formValues.fuelProfileUuid
              : null,
          isAuthoCodeRequired,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
          invoiceDisplayName: formValues.invoiceDisplayName,
        },
      },
    },
  };
};

const makeUpdateInput = (
  formValues: ZoneBasedAccessorialFormValues,
  uuid: string,
  isAuthoCodeRequired: boolean,
) => {
  // TODO: Handle error if UUID is undefined (this shouldn't happen.)
  if (uuid === undefined) {
    throw new Error('Zone-based accessorial UUID is undefined');
  }

  const zoneArrayUpdates: ZoneBasedAccessorialZoneArrayUpdateInput[] | null =
    [];
  formValues.zones.forEach((zone) => {
    if (zone.toBeCreated === true) {
      zoneArrayUpdates.push({
        zoneBasedAccessorialZoneCreateInput: {
          name: zone.name,
        },
      });
    } else if (zone.uuid !== null) {
      zoneArrayUpdates.push({
        zoneBasedAccessorialZoneUpdateInput: {
          name: zone.name,
          uuid: zone.uuid,
        },
      });
    }
  });

  const chargeGroupUpdates:
    | ZoneBasedAccessorialChargeGroupArrayUpdateInput[]
    | null = [];
  formValues.chargeGroups.forEach((chargeGroup) => {
    if (chargeGroup.toBeCreated === true) {
      chargeGroupUpdates.push({
        zoneBasedAccessorialChargeGroupCreateInput: {
          name: chargeGroup.name,
        },
      });
    } else if (chargeGroup.uuid !== null) {
      chargeGroupUpdates.push({
        zoneBasedAccessorialChargeGroupUpdateInput: {
          name: chargeGroup.name,
          uuid: chargeGroup.uuid,
        },
      });
    }
  });

  const matrixItemsArrayUpdateInput:
    | ZoneBasedAccessorialMatrixItemArrayUpdateInput[]
    | null
    | undefined = [];

  formValues.matrixItems.forEach((matrixItemRow, zoneIdx) => {
    matrixItemRow.forEach((matrixItem, chargeGroupIdx) => {
      if (matrixItem?.toBeCreated === true) {
        matrixItemsArrayUpdateInput.push({
          matrixItemCreateInput: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            zoneName: formValues.zones[zoneIdx].name,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            chargeGroupName: formValues.chargeGroups[chargeGroupIdx].name,
            rate: matrixItem.rate,
          },
        });
      } else if (matrixItem && matrixItem.uuid !== null) {
        matrixItemsArrayUpdateInput.push({
          matrixItemUpdateInput: {
            uuid: matrixItem.uuid,
            rate: matrixItem.rate,
          },
        });
      }
    });
  });

  return {
    variables: {
      input: {
        accessorialUpdateInput: {
          zoneBasedAccessorialUpdateInput: {
            uuid,
            name: formValues.name,
            zoneArrayUpdates,
            chargeGroupUpdates,
            matrixItemsArrayUpdateInput,
          },
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid !== ALL_TERMINALS
              ? formValues.terminalUuid
              : null,
          code: formValues.code,
          ediCode: formValues.ediCode,
          fuelProfileUuid:
            formValues.fuelProfileUuid !== NO_FUEL_PROFILE
              ? formValues.fuelProfileUuid
              : null,
          isAuthoCodeRequired,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
          invoiceDisplayName: formValues.invoiceDisplayName,
        },
      },
    },
  };
};

interface ZoneBasedAccessorialFormProps {
  mode: FormMode;
  uuid: string | undefined;
  contactUuid: string | undefined;
  templateUuid?: string | undefined;
  isAuthoCodeRequired: boolean;
}

const ZoneBasedAccessorialForm = ({
  mode,
  uuid,
  contactUuid,
  templateUuid,
  isAuthoCodeRequired,
}: ZoneBasedAccessorialFormProps) => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<ZoneBasedAccessorialFormErrors>(
    {},
  );
  const [formValues, setFormValues] = useFormValues(
    templateUuid !== undefined ? templateUuid : uuid,
  );

  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();
  const { terminalsEnabled, terminalsLoading, terminals } = useTerminals({
    includeInactiveTerminals: false,
  });

  const [createAccessorialMutation] = useCreateAccessorialMutation({});
  const [updateAccessorialMutation] = useUpdateAccessorialMutation({});
  // const [removeZoneBasedAccessorialMutation] =
  //   useRemoveZoneBasedAccessorialMutation({});

  const onAddZone = () => {
    setFormValues({
      ...formValues,
      zones: [
        ...formValues.zones,
        {
          name: '',
          uuid: null,
          toBeCreated: true,
          isUpdated: null,
        },
      ],
      matrixItems: [
        ...formValues.matrixItems,
        times(formValues.chargeGroups.length, constant(null)),
      ],
    });
  };

  const onRemoveZone = (name: string, idx: number) => {
    setFormValues({
      ...formValues,
      zones: formValues.zones.filter((zone, i) => i !== idx),
      matrixItems: formValues.matrixItems.filter((val, i) => i !== idx),
    });
  };

  const onAddChargeGroup = () => {
    setFormValues({
      ...formValues,
      chargeGroups: [
        ...formValues.chargeGroups,
        {
          name: '',
          uuid: null,
          toBeCreated: true,
          isUpdated: null,
        },
      ],
      matrixItems: formValues.matrixItems.map((matrixItem) => [
        ...matrixItem,
        null,
      ]),
    });
  };

  const onRemoveChargeGroup = (name: string, idx: number) => {
    setFormValues({
      ...formValues,
      chargeGroups: formValues.chargeGroups.filter(
        (chargeGroup, chargeGroupIdx) => chargeGroupIdx !== idx,
      ),
      matrixItems: formValues.matrixItems.map((matrixItem) =>
        matrixItem.filter((item, matrixItemIdx) => matrixItemIdx !== idx),
      ),
    });
  };

  const onZoneChange = (name: string, zoneIdx: number) => {
    const newZones = formValues.zones ?? [];
    const zone = newZones[zoneIdx];
    if (!isNil(zone)) {
      zone.name = name;
      if (zone.uuid !== null) {
        zone.isUpdated = true;
      }
      setFormValues({ ...formValues, zones: newZones });
    }
  };

  const onChargeGroupChange = (name: string, chargeGroupIdx: number) => {
    const newChargeGroups = formValues.chargeGroups ?? [];
    const chargeGroup = newChargeGroups[chargeGroupIdx];
    if (!isNil(chargeGroup)) {
      chargeGroup.name = name;
      if (chargeGroup.uuid !== null) {
        chargeGroup.isUpdated = true;
      }
      setFormValues({ ...formValues, chargeGroups: newChargeGroups });
    }
  };
  const onMatrixItemChange = (
    rate: number,
    zoneIdx: number,
    chargeGroupIdx: number,
  ) => {
    const newMatrixItems = formValues.matrixItems ?? [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newMatrixItems[zoneIdx][chargeGroupIdx] = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...newMatrixItems[zoneIdx][chargeGroupIdx],
      rate,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isNil(newMatrixItems[zoneIdx][chargeGroupIdx]?.uuid)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newMatrixItems[zoneIdx][chargeGroupIdx].isUpdated = true;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (newMatrixItems[zoneIdx][chargeGroupIdx] !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newMatrixItems[zoneIdx][chargeGroupIdx].toBeCreated = true;
    }
    setFormValues({ ...formValues, matrixItems: newMatrixItems });
  };

  // const deleteOnClick = async () => {
  //   if (uuid !== undefined) {
  //     await removeZoneBasedAccessorialMutation({
  //       variables: { input: uuid as string },
  //     });
  //     push(getBackUrl(contactUuid));
  //   }
  // };

  const validateZonesAndSetErrors = (
    zone: ZoneBasedAccessorialZone,
    idx: number,
  ) => {
    const zoneErrors = formErrors.zones ?? [];
    if (zone.name.length === 0) {
      zoneErrors[idx] = 'Zone cannot be empty';
    } else {
      zoneErrors[idx] = '';
    }
    const newFormErrors = { ...formErrors, zones: zoneErrors };
    setFormErrors(newFormErrors);
    return newFormErrors;
  };

  const validateChargeGroupsAndSetErrors = (
    chargeGroup: ZoneBasedAccessorialChargeGroup,
    idx: number,
  ) => {
    const chargeGroupErrors = formErrors.zones ?? [];
    if (chargeGroup.name.length === 0) {
      chargeGroupErrors[idx] = 'Rate group cannot be empty';
    } else {
      chargeGroupErrors[idx] = '';
    }
    const newFormErrors = { ...formErrors, chargeGroups: chargeGroupErrors };
    setFormErrors(newFormErrors);
    return newFormErrors;
  };

  const validateMatrixItemsAndSetErrors = (
    item: ZoneBasedAccessorialMatrixItem | null,
    i: number,
    j: number,
  ) => {
    if (item !== null && item.rate !== null && item.rate < 0) {
      const matrixItemErrors = formErrors.matrixItems ?? [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      matrixItemErrors[i][j] = 'Rate cannot be negative';
      const newFormErrors = { ...formErrors, matrixItems: matrixItemErrors };
      setFormErrors(newFormErrors);
      return newFormErrors;
    }
    return formErrors;
  };

  const validateAllFields = (values: ZoneBasedAccessorialFormValues) => {
    const { zones, chargeGroups, matrixItems } = values;
    let newFormErrors = formErrors;
    zones.forEach((zone, idx) => {
      newFormErrors = validateZonesAndSetErrors(zone, idx);
    });
    chargeGroups.forEach((chargeGroup, idx) => {
      newFormErrors = validateChargeGroupsAndSetErrors(chargeGroup, idx);
    });
    matrixItems.forEach((matrixItemRow, zoneIdx) => {
      matrixItemRow.forEach((matrixItem, chargeGroupIdx) => {
        newFormErrors = validateMatrixItemsAndSetErrors(
          matrixItem,
          zoneIdx,
          chargeGroupIdx,
        );
      });
    });
    return newFormErrors;
  };

  const onSave = async () => {
    const { zones, chargeGroups, matrixItems } = validateAllFields(formValues);
    const hasInvalidField =
      (zones ?? []).filter((zoneError) => zoneError.length > 0).length > 0 ||
      (chargeGroups ?? []).filter(
        (chargeGroupError) => chargeGroupError.length > 0,
      ).length > 0 ||
      flatten(matrixItems ?? []).filter(
        (matrixItemError) => matrixItemError.length > 0,
      ).length > 0;
    if (!hasInvalidField) {
      if (mode === FormMode.CREATE) {
        const input = makeCreateInput(
          formValues,
          contactUuid,
          isAuthoCodeRequired,
          templateUuid,
        );
        // TODO: handle the errors from this don't just assume it passes
        await createAccessorialMutation(input);
        navigate(getBackUrl(contactUuid));
      } else if (mode === FormMode.EDIT) {
        // This should never happen
        if (uuid === null) {
          throw new Error('uuid is not defined but in edit mode');
        }
        const input = makeUpdateInput(
          formValues,
          uuid as string,
          isAuthoCodeRequired,
        );

        // TODO: handle the errors from this don't just assume it passes
        await updateAccessorialMutation(input);
        navigate(getBackUrl(contactUuid));
      } else {
        setFormErrors({});
      }
    }
  };

  const zonesController = (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6" sx={{ color: 'black' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            Zones
            <Tooltip
              title="Pallet offers automatic selection of zones for an order's accessorial charge based on the tariff zone of the order. Please make sure the zone names on the accessorial match the tariff zone names in order to use this feature."
              arrow
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Typography>
      </Grid>
      {formValues.zones.map((zone, idx) => {
        const error = formErrors?.zones?.[idx];
        return (
          <Grid key={zone.uuid} item xs={12} sx={muiStyles.centeredRow}>
            <ZoneBasedAccessorialZoneTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={zone.name}
              error={!isNil(error) && error.length > 0 ? error : undefined}
              type="text"
              onBlur={() => validateZonesAndSetErrors(zone, idx)}
              onChange={(e) => onZoneChange(e, idx)}
              name="name"
              label="Name"
            />
            {isNil(contactUuid) && (
              <CloseIcon
                onClick={() => onRemoveZone(zone.name, idx)}
                sx={{ marginTop: '10px', marginLeft: '4px' }}
              />
            )}
          </Grid>
        );
      })}
      {isNil(contactUuid) && (
        <Grid item xs={12} sx={muiStyles.centeredRow}>
          <Button variant="outlined" color="primary" onClick={onAddZone}>
            Add Zone
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const chargeGroupController: JSX.Element = (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6" sx={{ color: 'black' }}>
          Rate Groups
        </Typography>
      </Grid>
      {formValues.chargeGroups.map((chargeGroup, idx) => {
        const error = formErrors?.chargeGroups?.[idx];
        return (
          <Grid key={chargeGroup.uuid} item xs={12} sx={muiStyles.centeredRow}>
            <ZoneBasedAccessorialChargeGroupTextField
              mode={mode}
              disabled={!isNil(contactUuid)}
              value={chargeGroup.name}
              error={!isNil(error) && error.length > 0 ? error : undefined}
              type="text"
              onBlur={() => validateChargeGroupsAndSetErrors(chargeGroup, idx)}
              onChange={(e) => onChargeGroupChange(e, idx)}
              name="name"
              label="Name"
            />
            {isNil(contactUuid) && (
              <CloseIcon
                onClick={() => onRemoveChargeGroup(chargeGroup.name, idx)}
                sx={{ marginTop: '10px', marginLeft: '4px' }}
              />
            )}
          </Grid>
        );
      })}
      {isNil(contactUuid) && (
        <Grid item xs={12} sx={muiStyles.centeredRow}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onAddChargeGroup}
            disabled={
              !isEmpty(
                formValues.chargeGroups.filter(
                  (chargeGroup) => chargeGroup.name === '',
                ),
              )
            }
          >
            Add Rate Group
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const accessorialMatrixController: JSX.Element = (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6" sx={{ color: 'black', mt: 6 }}>
          Accessorial Matrix
        </Typography>
      </Grid>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <TableContainer component={Paper}>
          <Table aria-label="accessorials-matrix">
            <TableHead>
              <TableRow>
                <TableCell key="zone-header">
                  <strong>ZONE</strong>
                </TableCell>
                {formValues.chargeGroups.map(
                  (chargeGroup: ZoneBasedAccessorialChargeGroup) => (
                    <TableCell key={chargeGroup.uuid}>
                      {chargeGroup.name}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {formValues.zones.map(
                (zone: ZoneBasedAccessorialZone, i: number) => (
                  <TableRow key={zone.uuid}>
                    <TableCell>{zone.name}</TableCell>
                    {(formValues.matrixItems[i] ?? []).map((matrixItem, j) => {
                      const formError =
                        formErrors.matrixItems !== undefined
                          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            formErrors.matrixItems[i][j]
                          : undefined;
                      return (
                        <TableCell key={matrixItem?.uuid}>
                          <ZoneBasedAccessorialMatrixItemTextField
                            mode={mode}
                            value={matrixItem?.rate ?? 0.0}
                            error={formError}
                            type="number"
                            onBlur={() =>
                              validateMatrixItemsAndSetErrors(matrixItem, i, j)
                            }
                            onChange={(e) => onMatrixItemChange(e, i, j)}
                            name={`${i}+${j}`}
                            label="Rate"
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={muiStyles.pageContainer}>
      <Box sx={muiStyles.buttonRow}>
        {(mode === FormMode.CREATE || mode === FormMode.EDIT) && (
          <Button onClick={onSave} variant="contained">
            Save
          </Button>
        )}
      </Box>
      <Box sx={muiStyles.pageContent}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.name}
              error={!isNil(formErrors.name)}
              type="text"
              onBlur={() => validateString(formValues.name, true)}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
              name="name"
              label="Name"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.percentForSettlement}
              error={!isNil(formErrors.percentForSettlement)}
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(e.target.value)) {
                  setFormValues({
                    ...formValues,
                    percentForSettlement: parseFloat(e.target.value),
                  });
                } else {
                  setFormValues({ ...formValues, percentForSettlement: null });
                }
              }}
              name="percentForSettlement"
              label="Settlement Rate"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
              fullWidth
            />
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.terminalUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.terminalUuid) &&
                    !isEmpty(formValues.terminalUuid)
                  }
                  id="select-terminal-label"
                >
                  Terminal
                </InputLabel>
                <Select
                  labelId="select-terminal-label"
                  id="select-terminal"
                  label="Terminal"
                  value={formValues.terminalUuid}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        terminalUuid: event.target.value,
                      });
                    }
                  }}
                  disabled={terminalsLoading}
                  size="small"
                  fullWidth
                >
                  <MenuItem key={ALL_TERMINALS} value={ALL_TERMINALS}>
                    {ALL_TERMINALS}
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.terminalUuid) && (
                  <FormHelperText error>
                    {formErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <FormControl
              sx={{ width: '100%' }}
              error={!isNil(formErrors.fuelProfileUuid)}
            >
              <InputLabel
                shrink={
                  !isNil(formValues.fuelProfileUuid) &&
                  !isEmpty(formValues.fuelProfileUuid)
                }
                id="select-fuel-profile-label"
              >
                Fuel Profile
              </InputLabel>
              <Select
                labelId="select-fuel-profile-label"
                id="select-fuel-profile"
                label="Fuel Profile"
                value={formValues.fuelProfileUuid}
                onChange={(event) => {
                  if (typeof event.target.value === 'string') {
                    setFormValues({
                      ...formValues,
                      fuelProfileUuid: event.target.value,
                    });
                  }
                }}
                disabled={fuelProfilesLoading}
                size="small"
                fullWidth
              >
                <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                  {NO_FUEL_PROFILE}
                </MenuItem>
                {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                  <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                    {fuelProfile.name}
                  </MenuItem>
                ))}
              </Select>
              {!isNil(formErrors.fuelProfileUuid) && (
                <FormHelperText error>
                  {formErrors.fuelProfileUuid}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.code}
              error={!isNil(formErrors.code)}
              type="text"
              onChange={(e) =>
                setFormValues({ ...formValues, code: e.target.value })
              }
              name="code"
              label="Accessorial Code"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.ediCode}
              error={!isNil(formErrors.ediCode)}
              type="text"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  ediCode: e.target.value,
                })
              }
              name="ediCode"
              label="EDI Code"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.invoiceDisplayName}
              error={!isNil(formErrors.invoiceDisplayName)}
              type="text"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  invoiceDisplayName: e.target.value,
                })
              }
              name="invoiceDisplayName"
              label="Display on invoices as"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <GeneralLedgerCodeAutocomplete
              value={formValues.generalLedgerCodeId}
              formError={formErrors.generalLedgerCodeId?.join(', ') ?? null}
              setValue={(newValue: string | null) => {
                setFormValues({
                  ...formValues,
                  generalLedgerCodeId: newValue,
                });
              }}
              disabled={!isNil(contactUuid)}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: 6 }}>
          <Grid item xs={6}>
            {zonesController}
          </Grid>
          <Grid item xs={6}>
            {chargeGroupController}
          </Grid>
          <Grid item xs={12}>
            {accessorialMatrixController}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ZoneBasedAccessorialForm;
