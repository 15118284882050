import { SxProps, useTheme } from '@mui/material';

const useStyles = () => {
  const theme = useTheme();
  return {
    borderRightDivider: {
      borderRight: 1,
    } as SxProps,
    borderColor: theme.palette.borderColor.main,
    unassignedColor: theme.palette.unassignedColor.main,
    routeCardContainer: {
      display: 'flex',
      height: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardHover: {
      '&:hover': {
        background: 'rgba(37, 48, 82, 0.08)',
        transition: '0.15s',
        cursor: 'pointer',
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        fontSize: 9,
        height: 15,
        minWidth: 15,
      },
    } as SxProps,
  };
};

export default useStyles;
