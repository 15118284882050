import { StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { transformAddressToFullAddressStringWithLineBreaks } from 'shared/copy';
import { PageWithPageNumbers } from 'shared/pdfs/common';
import { InvoiceAgingReportDataFragment } from '../../../../../generated/graphql';
import { Address } from '../aging-report/generated-detailed-aging-report-pdf';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '18px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    marginTop: 4,
  },
  contactBlockHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    padding: 4,
  },
  invoicesTable: {
    width: '100%',
  },
  invoicesTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '9px',
    width: '100%',
    textAlign: 'center',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  invoicesTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '35%',
  },
  invoicesTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    justifyContent: 'space-between',
    textAlign: 'center',
  },

  // So Declarative and unDRY 👌
  dateCol: {
    textAlign: 'left',
    width: '30%',
  },
  invoiceNumberCol: {
    width: '30%',
  },
  journalNumberCol: {
    width: '30%',
  },
  ageCol: {
    paddingLeft: 5,
    width: '10%',
  },
  balanceCol: {
    width: '14%',
  },
  currentCol: {
    width: '14%',
  },
  col31to38: {
    width: '14%',
  },
  col39to45: {
    width: '14%',
  },
  col46to60: {
    width: '14%',
  },
  col61to75: {
    width: '14%',
  },
  colOver75: {
    width: '14%',
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 15,
    left: 10,
    right: 10,
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalBox: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
  },
  totalBoxInner: {
    border: '1px solid black',
    width: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalBoxLabel: {
    fontSize: 12,
    padding: 5,
    fontWeight: 'bold',
    borderRight: '1px solid black',
    backgroundColor: '#f0eded',
  },
  totalBoxAmount: {
    fontSize: 12,
    padding: 5,
    textAlign: 'right',
  },
});

const AgingReportTableHeader = ({
  useJournalNumberForInvoice,
}: {
  useJournalNumberForInvoice?: boolean | null;
}) => {
  return (
    <View style={[styles.invoicesTableRow, styles.invoicesTableHeader]} fixed>
      <View style={styles.invoicesTableRowLeftSection}>
        <Text style={styles.dateCol}>Date</Text>
        {useJournalNumberForInvoice !== true && (
          <Text style={styles.invoiceNumberCol}>Inv #</Text>
        )}
        <Text style={styles.journalNumberCol}>Journal/Chk #</Text>
        <Text style={styles.ageCol}>Age</Text>
      </View>
      <View style={styles.invoicesTableRowRightSection}>
        <Text style={styles.balanceCol}> Balance </Text>
        <Text style={styles.currentCol}> Current </Text>
        <Text style={styles.col31to38}> 31-38 </Text>
        <Text style={styles.col39to45}> 39-45 </Text>
        <Text style={styles.col46to60}> 46-60 </Text>
        <Text style={styles.col61to75}> 61-75 </Text>
        <Text style={styles.colOver75}> Over 75 </Text>
      </View>
    </View>
  );
};

const AgingReportTable = ({
  contact,
  startDate,
  useJournalNumberForInvoice,
}: {
  contact: InvoiceAgingReportDataFragment;
  startDate: Dayjs;
  useJournalNumberForInvoice?: boolean | null;
}) => {
  const startDays = dayjs().diff(startDate, 'day');
  return (
    <View style={styles.invoicesTable}>
      {contact.invoices
        ?.sort((a, b) => {
          if (dayjs(a.date).isBefore(b.date)) return 1;
          if (dayjs(a.date).isAfter(b.date)) return -1;
          return a.name.localeCompare(b.name);
        })
        .filter((invoice) => invoice.totalOpenAmount !== 0)
        .map((invoice) => (
          // eslint-disable-next-line react/jsx-key
          <View style={styles.invoicesTableRow} wrap={false}>
            <View style={styles.invoicesTableRowLeftSection}>
              <Text style={styles.dateCol}>
                {dayjs(invoice.date).format('MM/DD/YYYY')}
              </Text>
              {useJournalNumberForInvoice !== true && (
                <Text style={styles.invoiceNumberCol}>{invoice.name}</Text>
              )}
              <Text style={styles.journalNumberCol}>
                {invoice.journalNumber ?? '-'}
              </Text>
              <Text style={styles.ageCol}>{invoice.age}</Text>
            </View>
            <View style={styles.invoicesTableRowRightSection}>
              <Text style={styles.balanceCol}>{invoice.totalOpenAmount}</Text>
              <Text style={styles.currentCol}>
                {invoice.age <= 30 + startDays
                  ? invoice.totalOpenAmount
                  : '0.00'}
              </Text>
              <Text style={styles.col31to38}>
                {invoice.age > 30 + startDays && invoice.age <= 38 + startDays
                  ? invoice.totalOpenAmount
                  : '0.00'}
              </Text>
              <Text style={styles.col39to45}>
                {invoice.age > 38 + startDays && invoice.age <= 45 + startDays
                  ? invoice.totalOpenAmount
                  : '0.00'}
              </Text>
              <Text style={styles.col46to60}>
                {invoice.age > 45 + startDays && invoice.age <= 60 + startDays
                  ? invoice.totalOpenAmount
                  : '0.00'}
              </Text>
              <Text style={styles.col61to75}>
                {invoice.age > 60 + startDays && invoice.age <= 75 + startDays
                  ? invoice.totalOpenAmount
                  : '0.00'}
              </Text>
              <Text style={styles.colOver75}>
                {invoice.age > 75 + startDays
                  ? invoice.totalOpenAmount
                  : '0.00'}
              </Text>
            </View>
          </View>
        ))}
      <View style={styles.invoicesTableRow}>
        <View
          style={[
            styles.invoicesTableRowLeftSection,
            { justifyContent: 'flex-end' },
          ]}
        >
          <Text style={{ fontWeight: 'bold' }}>
            {contact.displayName} totals:
          </Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.balanceCol}>
            {contact.contactOpenInvoiceValue}
          </Text>
          <Text style={styles.currentCol}>
            {contact.contactOpenInvoiceValue0to30d}
          </Text>
          <Text style={styles.col31to38}>
            {contact.contactOpenInvoiceValue31to38d}
          </Text>
          <Text style={styles.col39to45}>
            {contact.contactOpenInvoiceValue39to45d}
          </Text>
          <Text style={styles.col46to60}>
            {contact.contactOpenInvoiceValue46to60d}
          </Text>
          <Text style={styles.col61to75}>
            {contact.contactOpenInvoiceValue61to75d}
          </Text>
          <Text style={styles.colOver75}>
            {contact.contactOpenInvoiceValueOver75d}
          </Text>
        </View>
      </View>
    </View>
  );
};

const GeneratedDetailedAgingReportPdfOld = ({
  contactUuid,
  data,
  companyName,
  companyPhone,
  companyAddress,
  startDate,
  useJournalNumberForInvoice,
}: {
  contactUuid?: string;
  data: InvoiceAgingReportDataFragment[] | undefined;
  companyName: string | undefined;
  companyPhone: string | undefined;
  companyAddress: Address | undefined;
  startDate: Dayjs;
  useJournalNumberForInvoice: boolean | undefined;
}) => {
  const { displayName: contactDisplayName, contactAddress } = data?.[0] ?? {};
  const isSingleContactReport =
    !isNil(contactUuid) && !isNil(contactDisplayName) && !isNil(contactAddress);

  const totalBalance = data
    ?.reduce((prev, curr) => prev + (curr?.contactOpenInvoiceValue ?? 0), 0)
    .toFixed(2);

  return (
    <PageWithPageNumbers
      documentTitle="Account Aging Report"
      orientation="portrait"
      optionalText={`Aging calculated as of ${startDate.format('MM/DD/YYYY')}`}
    >
      <View
        style={[
          styles.rowWithSpaceBetween,
          {
            marginTop: '10px',
            fontSize: '12px',
          },
        ]}
      >
        <Text style={styles.header1}>Account Aging Report</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: '10px',
          fontSize: '12px',
        }}
      >
        {isSingleContactReport ? (
          <View>
            <View>
              <Text style={styles.header2}>{companyName}</Text>
              {!isNil(companyAddress) && (
                <Text>
                  {transformAddressToFullAddressStringWithLineBreaks({
                    address: companyAddress,
                  })}
                </Text>
              )}
              <Text>Phone: {companyPhone}</Text>
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={{ fontWeight: 'bold' }}>{contactDisplayName}</Text>
              <Text>{contactAddress.name}</Text>
              <Text>
                {transformAddressToFullAddressStringWithLineBreaks({
                  address: contactAddress,
                })}
              </Text>
            </View>
          </View>
        ) : (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '10px',
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>Customer:</Text>
            <Text style={{ marginLeft: '3px' }}>All</Text>
          </View>
        )}
        <Text>Aging calculated as of {startDate.format('MM/DD/YYYY')}</Text>
      </View>
      {data?.map((contact) => {
        return !isNil(contact) &&
          (contact.invoices?.length ?? -1) > 0 &&
          contact.contactOpenInvoiceValue !== 0 ? (
          <View style={styles.contactBlock}>
            <View style={styles.contactBlockHeader}>
              <Text style={{ fontWeight: 'bold' }}>{contact?.displayName}</Text>
            </View>
            <AgingReportTableHeader
              useJournalNumberForInvoice={useJournalNumberForInvoice}
            />
            <AgingReportTable
              contact={contact}
              startDate={startDate}
              useJournalNumberForInvoice={useJournalNumberForInvoice}
            />
          </View>
        ) : null;
      })}
      <View style={styles.invoicesTableRow} wrap={false}>
        <View
          style={[
            styles.invoicesTableRowLeftSection,
            { justifyContent: 'flex-end' },
          ]}
        >
          <Text style={{ fontWeight: 'bold' }}>Report totals:</Text>
        </View>
        <View style={styles.invoicesTableRowRightSection}>
          <Text style={styles.balanceCol}>{totalBalance}</Text>
          <Text style={styles.currentCol}>
            {data
              ?.reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue0to30d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col31to38}>
            {data
              ?.reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue31to38d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col39to45}>
            {data
              ?.reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue39to45d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col46to60}>
            {data
              ?.reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue46to60d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.col61to75}>
            {data
              ?.reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValue61to75d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
          <Text style={styles.colOver75}>
            {data
              ?.reduce(
                (prev, curr) =>
                  prev + (curr?.contactOpenInvoiceValueOver75d ?? 0),
                0,
              )
              .toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={styles.totalBox}>
        <View style={styles.totalBoxInner}>
          <Text style={styles.totalBoxLabel}>AMOUNT DUE:</Text>
          <Text style={styles.totalBoxAmount}>{totalBalance}</Text>
        </View>
      </View>
    </PageWithPageNumbers>
  );
};

export default GeneratedDetailedAgingReportPdfOld;
