import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type MarkOrderAsRefusedFormValues = {
  refusedBy: string;
  refusedDate: Date;
};

export const useMarkOrderAsRefusedForm = () => {
  const schema = yup.object({
    refusedBy: yup.string().required('Refused by is required.'),
    refusedDate: yup.date().required('Refused date is required'),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  return {
    form,
  };
};
