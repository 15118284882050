import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, ButtonProps, IconButton, Stack, styled } from '@mui/material';
import { isNil } from 'lodash';
import React, { forwardRef, useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  OrderTableField,
  OrderTableFieldHeaderFragment,
} from '../../../../../generated/graphql';
import { getOrderTableFieldHeader } from '../../../../orders/components/utils';

const OrderTableColumnPill = styled(
  forwardRef<
    HTMLButtonElement,
    Omit<ButtonProps, 'variant' | 'size' | 'startIcon'>
  >(({ ...props }, ref) => (
    <Button
      variant="outlined"
      size="small"
      startIcon={<DragIndicatorIcon />}
      ref={ref}
      {...props}
    />
  )),
)(({ theme }) => ({
  fontSize: '14px',
  padding: theme.spacing(0, 0.5),
  color: theme.palette.text.secondary,
  borderColor: theme.palette.grey[500],
  '&:hover': {
    borderColor: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  },
}));

const ORDER_TABLE_FIELD_PILL_TYPE = 'order-table-field-pill';

type DraggablePillProps = {
  field: OrderTableField;
  index: number;
  moveField: (dragIndex: number, hoverIndex: number) => void;
  handleRemove: (field: OrderTableField) => void;
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
};

const DraggablePill = ({
  field,
  index,
  moveField,
  handleRemove,
  orderTableFieldHeaders,
}: DraggablePillProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: ORDER_TABLE_FIELD_PILL_TYPE,
    item: () => ({ field, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop<
    { index: number },
    void,
    { handlerId: string | null }
  >({
    accept: ORDER_TABLE_FIELD_PILL_TYPE,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId() as string | null,
    }),
    hover: (item: { index: number }, monitor) => {
      if (isNil(item)) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (isNil(hoverBoundingRect)) return;

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (isNil(clientOffset)) return;

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // When dragging upwards, only move when the cursor is above 50%
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveField(dragIndex, hoverIndex);

      // This param reassign is encouraged by react-dnd
      // for performance reasons.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <OrderTableColumnPill
      ref={ref}
      data-handler-id={handlerId}
      sx={{ opacity: isDragging ? 0.5 : 1 }}
      endIcon={
        <IconButton
          size="small"
          sx={{ p: 0.5 }}
          onClick={() => handleRemove(field)}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      }
    >
      {getOrderTableFieldHeader({
        orderTableFieldHeaders,
        orderTableField: field,
      })}
    </OrderTableColumnPill>
  );
};

type ConfigureOrderColumnsNewPillsProps = {
  fields: OrderTableField[];
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
  setFields: React.Dispatch<React.SetStateAction<OrderTableField[]>>;
};

const ConfigureOrderColumnsNewPills = ({
  fields,
  orderTableFieldHeaders,
  setFields,
}: ConfigureOrderColumnsNewPillsProps) => {
  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setFields((prevFields) => {
        const newFields = [...prevFields];
        const [reorderedItem] = newFields.splice(dragIndex, 1);
        if (isNil(reorderedItem)) {
          return prevFields;
        }
        newFields.splice(hoverIndex, 0, reorderedItem);
        return newFields;
      });
    },
    [setFields],
  );

  const handleRemove = useCallback(
    (field: OrderTableField) => {
      setFields((prevFields) => prevFields.filter((f) => f !== field));
    },
    [setFields],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {fields.map((field, index) => (
          <DraggablePill
            key={field}
            field={field}
            index={index}
            moveField={moveField}
            handleRemove={handleRemove}
            orderTableFieldHeaders={orderTableFieldHeaders}
          />
        ))}
      </Stack>
    </DndProvider>
  );
};

export { ConfigureOrderColumnsNewPills };
