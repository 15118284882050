import { Button, Stack, Table, TableContainer } from '@mui/material';
import { shallow } from 'zustand/shallow';
import { TariffType } from '../../../../generated/graphql';
import useTariffGroupStore from './store/tariff-group-state-store';
import TariffGroupGridContent from './tariff-group-grid-content';
import TariffGroupGridHeader from './tariff-group-grid-header';

const TariffGroupGridEditor = () => {
  const [tariffType] = useTariffGroupStore(
    (state) => [state.tariffType],
    shallow,
  );
  const [addYRangeValue] = useTariffGroupStore(
    (state) => [state.addYRangeValue],
    shallow,
  );

  const addRange = () => {
    addYRangeValue();
  };

  return (
    <Stack spacing={2}>
      <TableContainer sx={{ maxHeight: '50vh', overflowX: 'auto' }}>
        <Table stickyHeader>
          <TariffGroupGridHeader />
          <TariffGroupGridContent />
        </Table>
      </TableContainer>
      {tariffType !== TariffType.NoUnits && (
        <Button onClick={addRange}>Add Range</Button>
      )}
    </Stack>
  );
};

export default TariffGroupGridEditor;
