import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import useMe from '../react-hooks/use-me';
import useMultiplayerStore from './multiplayer-store';
import { DispatchMultiplayerAction } from './types/dispatch';

const useMultiplayer = () => {
  const { email } = useMe();
  const [
    updateDispatchUserLocation,
    getDispatchMyLocation,
    dispatchConnectionId,
  ] = useMultiplayerStore(
    (state) => [
      state.updateDispatchUserLocation,
      state.getDispatchMyLocation,
      state.dispatchConnectionId,
    ],
    shallow,
  );

  const sendDispatchUserLocationEvent = async ({
    action,
    routeUuid,
    stopUuid,
  }: {
    action: DispatchMultiplayerAction;
    routeUuid?: string;
    stopUuid?: string;
  }) => {
    const currentLocation = getDispatchMyLocation();
    if (
      !isNil(email) &&
      !isNil(updateDispatchUserLocation) &&
      (currentLocation?.data.stopUuid !== stopUuid ||
        currentLocation?.data.routeUuid !== routeUuid)
    ) {
      updateDispatchUserLocation({
        connectionId: dispatchConnectionId,
        name: email,
        email,
        action,
        stopUuid,
        routeUuid,
        ts: new Date(),
      });
    }
  };

  const sendDispatchUserLeaveLocationEvent = () => {
    const currentLocation = getDispatchMyLocation();
    if (
      !isNil(email) &&
      !isNil(updateDispatchUserLocation) &&
      (currentLocation?.data.action === DispatchMultiplayerAction.EDITING ||
        currentLocation?.data.action === DispatchMultiplayerAction.REORDERING)
    ) {
      updateDispatchUserLocation({
        connectionId: dispatchConnectionId,
        name: email,
        email,
        action: DispatchMultiplayerAction.LEAVE,
        ts: new Date(),
      });
    }
  };

  return {
    sendDispatchUserLocationEvent,
    sendDispatchUserLeaveLocationEvent,
  };
};

export default useMultiplayer;
