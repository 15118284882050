import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import AddressAutocompleteForm, {
  FormAddressWithAssociatedContact,
} from '../../../common/components/address-autocomplete-form';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import {
  CreatePersonInput,
  PersonsQuery,
  PersonStatus,
  useContactStationsQuery,
  useServicesQuery,
  useVehicleTypesMinimalQuery,
  VehicleTypeStatus,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import type { AddressFormField } from '../../addresses/redux/addresses-values-slice';
import ContactPageMode from './contact-page-mode';

type Person = PersonsQuery['persons']['persons'][number];

const createNewEmptyAddress = (): FormAddressWithAssociatedContact => ({
  uuid: v4(),
  name: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  isLocal: false,
});

type PersonFormData = {
  firstName: string;
  lastName: string;
  phone: string | null;
  email: string | null;
  defaultPickupAddress: FormAddressWithAssociatedContact | null;
  defaultDeliveryAddress: FormAddressWithAssociatedContact | null;
  defaultServiceUuid: string | null;
  defaultVehicleTypeUuid: string | null;
  contactStationIds: { id: string; name: string }[];
};

type PersonFormErrors = {
  [key in keyof PersonFormData]?: string;
};

type PeoplePanelPersonProps = {
  contactUuid: string;
  person?: Person | null;
  mode: ContactPageMode;
  handleChangeMode: (mode: ContactPageMode) => void;
  canWrite: boolean;
  handleSave: (person: Omit<CreatePersonInput, 'contactUuid'>) => void;
  saving: boolean;
  // If an onToggleArchive function is provided, an archive/un-archive button will be shown.
  onToggleArchive?: () => void;
  togglingArchive?: boolean;
};

const PeoplePanelPerson: FunctionComponent<PeoplePanelPersonProps> = ({
  person,
  contactUuid,
  mode,
  handleChangeMode,
  canWrite,
  handleSave,
  saving,
  onToggleArchive,
  togglingArchive = false,
}) => {
  const ffCourierV1 = useFeatureFlag(FeatureFlag.FF_COURIER_V1);
  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  const theme = useTheme();
  const { data: servicesData, loading: loadingServices } = useServicesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: contactStationsData } = useContactStationsQuery({
    variables: {
      contactUuid,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: vehicleTypesData, loading: loadingVehicleTypes } =
    useVehicleTypesMinimalQuery({
      fetchPolicy: 'cache-and-network',
    });
  const [personFormData, setPersonFormData] = useState<PersonFormData>({
    firstName: '',
    lastName: '',
    phone: null,
    email: null,
    defaultPickupAddress: null,
    defaultDeliveryAddress: null,
    defaultServiceUuid: null,
    defaultVehicleTypeUuid: null,
    contactStationIds: [],
  });
  const [errors, setErrors] = useState<Partial<PersonFormErrors>>({});

  useEffect(() => {
    setPersonFormData({
      firstName: person?.firstName ?? '',
      lastName: person?.lastName ?? '',
      phone: person?.phone ?? null,
      email: person?.email ?? null,
      defaultPickupAddress:
        isNil(person) || isNil(person.defaultPickupAddress)
          ? null
          : {
              ...person.defaultPickupAddress,
              isLocal: false,
            },
      defaultDeliveryAddress:
        isNil(person) || isNil(person.defaultDeliveryAddress)
          ? null
          : {
              ...person.defaultDeliveryAddress,
              isLocal: false,
            },
      defaultServiceUuid: person?.defaultService?.uuid ?? null,
      defaultVehicleTypeUuid: person?.defaultVehicleType?.uuid ?? null,
      contactStationIds:
        person?.defaultForContactStations?.map((cs) => ({
          id: cs.id,
          name: cs.name,
        })) ?? [],
    });
  }, [person]);

  const onSave = () => {
    const newErrors: Partial<PersonFormErrors> = {};
    if (personFormData.firstName.trim().length === 0) {
      newErrors.firstName = 'First name is required';
    }
    if (personFormData.lastName.trim().length === 0) {
      newErrors.lastName = 'Last name is required';
    }
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      return;
    }
    handleSave({
      firstName: personFormData.firstName,
      lastName: personFormData.lastName,
      phone: personFormData.phone,
      email: personFormData.email,
      defaultPickupAddress: isNil(personFormData.defaultPickupAddress)
        ? null
        : {
            uuid: personFormData.defaultPickupAddress.uuid,
            name: personFormData.defaultPickupAddress.name ?? '',
            line1: personFormData.defaultPickupAddress.line1 ?? '',
            line2: personFormData.defaultPickupAddress.line2,
            city: personFormData.defaultPickupAddress.city ?? '',
            state: personFormData.defaultPickupAddress.state ?? '',
            zip: personFormData.defaultPickupAddress.zip ?? '',
            country: personFormData.defaultPickupAddress.country ?? '',
            specialInstructions:
              personFormData.defaultPickupAddress.specialInstructions,
          },
      defaultDeliveryAddress: isNil(personFormData.defaultDeliveryAddress)
        ? null
        : {
            uuid: personFormData.defaultDeliveryAddress.uuid,
            name: personFormData.defaultDeliveryAddress.name ?? '',
            line1: personFormData.defaultDeliveryAddress.line1 ?? '',
            line2: personFormData.defaultDeliveryAddress.line2,
            city: personFormData.defaultDeliveryAddress.city ?? '',
            state: personFormData.defaultDeliveryAddress.state ?? '',
            zip: personFormData.defaultDeliveryAddress.zip ?? '',
            country: personFormData.defaultDeliveryAddress.country ?? '',
            specialInstructions:
              personFormData.defaultDeliveryAddress.specialInstructions,
          },
      defaultServiceUuid: personFormData.defaultServiceUuid,
      defaultVehicleTypeUuid: personFormData.defaultVehicleTypeUuid,
      contactStationIds: personFormData.contactStationIds.map((cs) => cs.id),
    });
  };

  const clearError = (fieldName: keyof PersonFormErrors) =>
    setErrors(({ [fieldName]: _ignored, ...restErrors }) => restErrors);

  const activeVehicleTypes = vehicleTypesData?.vehicleTypes.filter(
    (vehicleType) => vehicleType.status === VehicleTypeStatus.Active,
  );

  const activeContactStationOptions = useMemo(
    () =>
      contactStationsData?.contactStations
        .filter((cs) => cs.isArchived === false)
        .map((cs) => ({
          id: cs.id,
          name: cs.name,
        })) ?? [],
    [contactStationsData],
  );

  if (loadingServices || loadingVehicleTypes) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stack direction="column" gap={2} p={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          gap={1}
        >
          <Stack direction="column" gap={2}>
            {!isNil(person) && person.status !== PersonStatus.Active && (
              <Typography
                style={{
                  width: 'max-content',
                  padding: '3px 12px',
                  borderRadius: '8px',
                  backgroundColor: theme.palette.redColor.main,
                  color: '#fff',
                  fontSize: '15px',
                  fontWeight: 500,
                }}
              >
                {person.status}
              </Typography>
            )}
            <Stack direction="row" gap={2}>
              <TextField
                label="First name"
                disabled={mode === ContactPageMode.VIEW || !canWrite}
                error={'firstName' in errors}
                helperText={errors.firstName}
                size="small"
                required
                value={personFormData.firstName}
                onChange={({ target }) => {
                  setPersonFormData((prevData) => ({
                    ...prevData,
                    firstName: target.value,
                  }));
                  if (target.value.length > 0 && 'firstName' in errors) {
                    clearError('firstName');
                  }
                }}
              />
              <TextField
                label="Last name"
                disabled={mode === ContactPageMode.VIEW || !canWrite}
                error={'lastName' in errors}
                helperText={errors.lastName}
                size="small"
                required
                value={personFormData.lastName}
                onChange={({ target }) => {
                  setPersonFormData((prevData) => ({
                    ...prevData,
                    lastName: target.value,
                  }));
                  if (target.value.length > 0 && 'lastName' in errors) {
                    clearError('lastName');
                  }
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={1}>
            {mode === ContactPageMode.CREATE ||
            mode === ContactPageMode.EDIT ? (
              <>
                <Button
                  variant="text"
                  onClick={() => handleChangeMode(ContactPageMode.VIEW)}
                  disabled={saving || togglingArchive}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={onSave}
                  disabled={saving || togglingArchive}
                >
                  Save
                </Button>
              </>
            ) : (
              canWrite && (
                <Button
                  variant="contained"
                  onClick={() => handleChangeMode(ContactPageMode.EDIT)}
                  disabled={togglingArchive}
                >
                  Edit
                </Button>
              )
            )}
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <TextField
            label="Phone"
            error={'phone' in errors}
            helperText={errors.phone}
            disabled={mode === ContactPageMode.VIEW || !canWrite}
            size="small"
            value={personFormData.phone ?? ''}
            onChange={({ target }) => {
              setPersonFormData((prevData) => ({
                ...prevData,
                phone: target.value,
              }));
              if ('phone' in errors) {
                clearError('phone');
              }
            }}
          />
          <TextField
            label="Email"
            error={'email' in errors}
            helperText={errors.email}
            disabled={mode === ContactPageMode.VIEW || !canWrite}
            size="small"
            value={personFormData.email ?? ''}
            onChange={({ target }) => {
              setPersonFormData((prevData) => ({
                ...prevData,
                email: target.value,
              }));
              if ('email' in errors) {
                clearError('email');
              }
            }}
          />
        </Stack>
      </Stack>
      {ffCourierV1 && (
        <>
          <Divider />
          <Stack direction="column" gap={2} p={2}>
            <Box>
              <Typography fontWeight={700} fontSize="17px">
                Order defaults
              </Typography>
              <Typography variant="caption">
                Speed up manual order entry by setting defaults for orders that
                this person places.
              </Typography>
            </Box>
            <Typography fontWeight={500}>Default pickup address</Typography>
            <Stack direction="row" gap={2}>
              <AddressAutocompleteForm
                disabled={mode === ContactPageMode.VIEW || !canWrite}
                currentAddress={personFormData.defaultPickupAddress}
                handleChange={(
                  _isAutofillChange: boolean,
                  newAddress?: AddressFormField,
                ) => {
                  setPersonFormData((prevData) => ({
                    ...prevData,
                    defaultPickupAddress: newAddress ?? null,
                  }));
                }}
                newStyling
              />
              <TextField
                label="Special instructions"
                disabled={mode === ContactPageMode.VIEW || !canWrite}
                size="small"
                rows={6}
                sx={{
                  width: '300px',
                }}
                multiline
                value={
                  personFormData.defaultPickupAddress?.specialInstructions ?? ''
                }
                onChange={({ target }) => {
                  setPersonFormData((prevData) => ({
                    ...prevData,
                    defaultPickupAddress: isNil(prevData.defaultPickupAddress)
                      ? {
                          ...createNewEmptyAddress(),
                          specialInstructions: target.value,
                        }
                      : {
                          ...prevData.defaultPickupAddress,
                          specialInstructions: target.value,
                        },
                  }));
                }}
              />
            </Stack>
            <Typography fontWeight={500}>Default delivery address</Typography>
            <Stack direction="row" gap={2}>
              <AddressAutocompleteForm
                disabled={mode === ContactPageMode.VIEW || !canWrite}
                currentAddress={personFormData.defaultDeliveryAddress}
                handleChange={(_isAutofillChange, newAddress) => {
                  setPersonFormData((prevData) => ({
                    ...prevData,
                    defaultDeliveryAddress: newAddress ?? null,
                  }));
                }}
                newStyling
              />
              <TextField
                label="Special instructions"
                disabled={mode === ContactPageMode.VIEW || !canWrite}
                size="small"
                rows={6}
                sx={{
                  width: '300px',
                }}
                multiline
                value={
                  personFormData.defaultDeliveryAddress?.specialInstructions ??
                  ''
                }
                onChange={({ target }) => {
                  setPersonFormData((prevData) => ({
                    ...prevData,
                    defaultDeliveryAddress: isNil(
                      prevData.defaultDeliveryAddress,
                    )
                      ? {
                          ...createNewEmptyAddress(),
                          specialInstructions: target.value,
                        }
                      : {
                          ...prevData.defaultDeliveryAddress,
                          specialInstructions: target.value,
                        },
                  }));
                }}
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <FormControl>
                <InputLabel id="default-service-select-label">
                  Default service
                </InputLabel>
                <Select<string>
                  labelId="default-service-select-label"
                  label="Default service"
                  disabled={mode === ContactPageMode.VIEW || !canWrite}
                  size="small"
                  sx={{ width: '230px' }}
                  value={personFormData.defaultServiceUuid ?? ''}
                  onChange={({ target }) => {
                    setPersonFormData((prevData) => ({
                      ...prevData,
                      defaultServiceUuid: target.value,
                    }));
                  }}
                >
                  {servicesData?.services.map((service) => (
                    <MenuItem key={service.uuid} value={service.uuid}>
                      {sentenceCase(service.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="default-vehicle-type-select-label">
                  Default vehicle type
                </InputLabel>
                <Select<string>
                  labelId="default-vehicle-type-select-label"
                  label="Default vehicle type"
                  disabled={mode === ContactPageMode.VIEW || !canWrite}
                  size="small"
                  sx={{ width: '230px' }}
                  value={personFormData.defaultVehicleTypeUuid ?? ''}
                  onChange={({ target }) => {
                    setPersonFormData((prevData) => ({
                      ...prevData,
                      defaultVehicleTypeUuid: target.value,
                    }));
                  }}
                >
                  {activeVehicleTypes?.map((vehicleType) => (
                    <MenuItem key={vehicleType.uuid} value={vehicleType.uuid}>
                      {vehicleType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </>
      )}
      {!isNil(person) && (
        <>
          {ffUseStations && (
            <>
              <Divider />
              <Box p={2}>
                <Typography fontWeight={700} fontSize="17px">
                  Contact Station
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                  mb={2}
                >
                  Associate this person with a station to make them the default
                  contact for all orders placed at that station.
                </Typography>
                {contactStationsData?.contactStations && (
                  <AutocompleteFuzzy
                    multiple
                    matchSortOptions={{ keys: ['name'] }}
                    disabled={mode === ContactPageMode.VIEW || !canWrite}
                    value={personFormData.contactStationIds}
                    options={activeContactStationOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Station"
                        autoComplete="off"
                      />
                    )}
                    onChange={(event, newValue) => {
                      setPersonFormData((prevData) => ({
                        ...prevData,
                        contactStationIds: newValue,
                      }));
                    }}
                  />
                )}
              </Box>
            </>
          )}
          <Divider />
          <Box p={2}>
            <Typography display="block" fontWeight={700} fontSize="17px">
              {person.status === PersonStatus.Archived
                ? 'Un-archive '
                : 'Archive '}
              this person
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
              mb={2}
            >
              {person.status === PersonStatus.Archived
                ? 'This person is archived and is not available for new orders.'
                : 'Archiving this person will make them unavailable for new orders.'}
            </Typography>
            <Button
              variant="outlined"
              color={
                person.status === PersonStatus.Active ? 'error' : undefined
              }
              onClick={onToggleArchive}
              disabled={togglingArchive}
            >
              {person.status === PersonStatus.Archived
                ? 'Un-archive'
                : 'Archive'}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default PeoplePanelPerson;
