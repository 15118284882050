import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { capitalCase, sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import React, { ReactNode } from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import {
  ConsigneePortalOrderFragment,
  CustomerPortalOrderForTableFragment,
  CustomerPortalOrderQuery,
  CustomerPortalOrdersFragment,
  CustomerPortalOrderShipmentFragment,
  CustomerPortalOrdersQueryVariables,
  CustomerPortalShipmentForTableFragment,
  FilterOperator,
  OutboundMethod,
  PickupOrDelivery,
  Segment,
  ShipmentType,
  StopStatus,
  StopType,
} from '../../generated/graphql';
import { stopIsComplete } from '../daily-control-center/utils';
import {
  booleanFilterOptionToBooleanFilter,
  convertDateFilterOptionToDateFilters,
  DateFilterOption,
  FilterModel,
} from '../orders/components/enums/order-filters';
import {
  getOrderDetailedStatusCopy,
  isPartnerCarryShipment,
} from '../orders/components/utils';

dayjs.extend(utc);
dayjs.extend(timezone);

type CustomerPortalOrder = NonNullable<CustomerPortalOrderQuery['order']>;

// For demos.
export const isThirdPartyAgent = (
  user: { email: string } | null | undefined,
): boolean => user?.email.includes('+agent@') ?? false;

export enum CustomerPortalOrderFilterField {
  ORDER_TYPE = 'Order Type',
  APPOINTMENT_DATE = 'Appointment Date',
  CREATED_DATE = 'Created Date',
  DATE_MARKED_ON_HAND = 'Date Marked on Hand',
  DATE_PLANNED = 'Date Planned',
  DISPATCHED = 'All Dispatched',

  OFD_OFP = 'Is OFD/OFP',
  COMPLETED_DATE = 'Completed Date',
  COMPANY = 'Company name',
  CONTACT = 'Contact name',
}

export const getPrimaryShipments = ({
  shipments,
}: {
  shipments: CustomerPortalShipmentForTableFragment[];
}): {
  delivery: CustomerPortalShipmentForTableFragment | undefined;
  pickup: CustomerPortalShipmentForTableFragment | undefined;
  recovery: CustomerPortalShipmentForTableFragment | undefined;
  transfer: CustomerPortalShipmentForTableFragment | undefined;
} => {
  const delivery = shipments.find(
    (shipment) =>
      shipment.legs[0]?.endStop?.stopType === StopType.Delivery &&
      !isPartnerCarryShipment({ shipment }) &&
      shipment.shipmentType !== ShipmentType.LineHaul,
  );
  const pickup = shipments.find(
    (shipment) =>
      shipment.legs[0]?.endStop?.stopType === StopType.Pickup &&
      !isPartnerCarryShipment({ shipment }),
  );
  const recovery = shipments.find(
    (shipment) => shipment.legs[0]?.endStop?.stopType === StopType.Recovery,
  );
  const transfer = shipments.find(
    (shipment) => shipment.legs[0]?.endStop?.stopType === StopType.Transfer,
  );
  return { delivery, pickup, recovery, transfer };
};

export const formatDate = (
  dateString: string | null,
  altMessage: string,
): string => {
  return !isNil(dateString) && dayjs(dateString).isValid()
    ? dayjs(dateString).format('MM/DD/YYYY')
    : altMessage;
};

export const formatDateWithTime = (
  dateString: string | null,
  altMessage: string,
): string => {
  return !isNil(dateString)
    ? dayjs(dateString).format('MM/DD/YYYY h:mm A')
    : altMessage;
};

export const getIncompleteShipments = (
  order: CustomerPortalOrderForTableFragment,
): CustomerPortalShipmentForTableFragment[] => {
  return order?.shipments?.filter(
    (shipment) => shipment?.legs.at(0)?.endStop.status !== StopStatus.Completed,
  );
};

export const getFirstShipment = (
  order: CustomerPortalOrderForTableFragment,
  incompleteShipments: CustomerPortalShipmentForTableFragment[],
): CustomerPortalShipmentForTableFragment | undefined => {
  const incompleteShipment = incompleteShipments?.at(0);
  const firstShipment = order?.shipments?.at(0);
  const shipment = incompleteShipment ?? firstShipment;

  return shipment;
};

export const getPickupShipment = (
  order: CustomerPortalOrderForTableFragment,
): CustomerPortalShipmentForTableFragment | undefined => {
  return order?.shipments?.find(
    (shipment) => shipment?.legs[0]?.endStop?.stopType === StopType.Pickup,
  );
};

export const getDeliveryShipment = (
  order: CustomerPortalOrderForTableFragment,
): CustomerPortalShipmentForTableFragment | undefined => {
  return order?.shipments?.find(
    (shipment) => shipment?.legs[0]?.endStop?.stopType === StopType.Delivery,
  );
};

export const getShipmentsWithRouteDate = (
  order: CustomerPortalOrdersFragment | CustomerPortalOrder,
  excludeCompletedShipments?: boolean,
): CustomerPortalOrderShipmentFragment[] => {
  let shipments = order?.shipments.filter(
    (shipment) => !isNil(shipment?.legs.at(0)?.endStop?.routeSlot?.route?.date),
  );

  if (excludeCompletedShipments === true) {
    shipments = shipments?.filter((sh) => {
      const status = sh?.legs?.at(0)?.endStop?.status;
      return !isNil(status) && !stopIsComplete(status);
    });
  }

  return shipments;
};

// TODO: refactor to avoid code duplication
export const getShipmentsWithRouteDateForTable = (
  order: CustomerPortalOrderForTableFragment,
  excludeCompletedShipments?: boolean,
): CustomerPortalShipmentForTableFragment[] => {
  let shipments = order?.shipments.filter(
    (shipment) => !isNil(shipment?.legs.at(0)?.endStop?.routeSlot?.route?.date),
  );

  if (excludeCompletedShipments === true) {
    shipments = shipments?.filter((sh) => {
      const status = sh?.legs?.at(0)?.endStop?.status;
      return !isNil(status) && !stopIsComplete(status);
    });
  }

  return shipments;
};

export const getStopAppointment = (
  shipment: CustomerPortalShipmentForTableFragment | null | undefined,
): {
  appointmentDate?: string;
  appointmentTime?: string;
  endAppointmentTime?: string;
} => {
  const appointmentDate = shipment?.standardShipmentFields?.deliveryDate;

  const stop = shipment?.legs?.[0]?.endStop;
  const appointmentTime = stop?.appointmentTime;
  const endAppointmentTime = stop?.endAppointmentTime;

  return { appointmentDate, appointmentTime, endAppointmentTime };
};

export const formatAppointment = ({
  appointmentDate,
  appointmentTime,
  endAppointmentTime,
  timeZone,
}: {
  appointmentDate: string | null | undefined;
  appointmentTime: string | null | undefined;
  endAppointmentTime: string | null | undefined;
  timeZone: string;
}): string | null => {
  const appointmentStartTime = !isNil(appointmentTime)
    ? dayjs(appointmentTime).tz(timeZone)
    : null;
  const appointmentEndTime = !isNil(endAppointmentTime)
    ? dayjs(endAppointmentTime).tz(timeZone)
    : null;

  const deliveryDate = !isNil(appointmentDate)
    ? dayjs(appointmentDate).tz(timeZone)
    : null;

  if (isNil(deliveryDate)) {
    return null;
  }
  const deliveryDateText = !isNil(deliveryDate)
    ? deliveryDate.format('MM/DD/YYYY')
    : '';
  const appointmentStartTimeText = !isNil(appointmentStartTime)
    ? appointmentStartTime.format('hh:mm a')
    : '';
  const appointmentEndTimeText = !isNil(appointmentEndTime)
    ? appointmentEndTime.format('hh:mm a')
    : '';

  if (
    !isNilOrEmptyString(deliveryDateText) &&
    !isNilOrEmptyString(appointmentStartTimeText) &&
    !isNilOrEmptyString(appointmentEndTimeText)
  ) {
    return `${deliveryDateText}, ${appointmentStartTimeText} to ${appointmentEndTimeText}`;
  }

  if (
    !isNilOrEmptyString(deliveryDateText) &&
    !isNilOrEmptyString(appointmentStartTimeText)
  ) {
    return `${deliveryDateText}, ${appointmentStartTimeText}`;
  }

  if (
    !isNilOrEmptyString(deliveryDateText) &&
    !isNilOrEmptyString(appointmentEndTimeText)
  ) {
    return `${deliveryDateText}, ${appointmentEndTimeText}`;
  }

  if (!isNilOrEmptyString(deliveryDateText)) {
    return deliveryDateText;
  }

  return null;
};

export const getAppointment = ({
  order,
  companyTimezone,
}: {
  order: CustomerPortalOrderForTableFragment;
  companyTimezone?: string;
}): string => {
  const pickupShipment = getPickupShipment(order);
  const deliveryShipment = getDeliveryShipment(order);

  const {
    appointmentDate: pickupAppointmentDate,
    appointmentTime: pickupAppointmentTime,
    endAppointmentTime: pickupEndAppointmentTime,
  } = getStopAppointment(pickupShipment);

  const {
    appointmentDate: deliveryAppointmentDate,
    appointmentTime: deliveryAppointmentTime,
    endAppointmentTime: deliveryEndAppointmentTime,
  } = getStopAppointment(deliveryShipment);

  const pickupFormatted = !isNil(companyTimezone)
    ? formatAppointment({
        appointmentDate: pickupAppointmentDate,
        appointmentTime: pickupAppointmentTime,
        endAppointmentTime: pickupEndAppointmentTime,
        timeZone: companyTimezone,
      })
    : null;

  const deliveryFormatted = !isNil(companyTimezone)
    ? formatAppointment({
        appointmentDate: deliveryAppointmentDate,
        appointmentTime: deliveryAppointmentTime,
        endAppointmentTime: deliveryEndAppointmentTime,
        timeZone: companyTimezone,
      })
    : null;

  if (!isNil(pickupFormatted) && !isNil(deliveryFormatted)) {
    return `Delivery: ${deliveryFormatted}\nPickup: ${pickupFormatted}`;
  }
  if (!isNil(pickupFormatted)) {
    return pickupFormatted;
  }
  if (!isNil(deliveryFormatted)) {
    return deliveryFormatted;
  }
  return '-';
};

export const getDeadlineDate = (
  order: CustomerPortalOrderForTableFragment,
): string => {
  const incompleteShipments = getIncompleteShipments(order);
  const shipment = getFirstShipment(order, incompleteShipments);

  const deadlineDate = shipment?.standardShipmentFields?.deadlineDate;
  return formatDate(deadlineDate, 'No deadline');
};

export const getAddress = (
  order: CustomerPortalOrderForTableFragment,
): string => {
  const { shipments } = order;
  const { delivery, pickup } = getPrimaryShipments({ shipments });
  const shipment = delivery ?? pickup;

  const address = shipment?.consigneeAddress;
  return !isNil(address) ? transformAddressToFullAddressString(address) : 'N/A';
};

export const getConsigneeName = (
  order: CustomerPortalOrderForTableFragment,
): string => {
  const shipments: CustomerPortalShipmentForTableFragment[] =
    order.shipments ?? [];
  const { delivery, pickup } = getPrimaryShipments({ shipments });
  const shipment = delivery ?? pickup;

  return shipment?.legs?.at(0)?.endStop?.address?.name ?? 'N/A';
};

export const getPlannedDate = (
  order: CustomerPortalOrdersFragment | CustomerPortalOrder,
  excludeCompletedShipments?: boolean,
  isOfdOfp?: boolean,
): string => {
  const shipments = getShipmentsWithRouteDate(order, excludeCompletedShipments);
  const deliveryShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Delivery,
  );
  const pickupShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Pickup,
  );
  const recoveryShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Recovery,
  );
  const transferShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Transfer,
  );

  const pickupShipmentWithOrWithoutRouteDate = order?.shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Pickup,
  );
  const isPickupWithoutRouteDateAndTransferWithRouteDate =
    !isNil(pickupShipmentWithOrWithoutRouteDate) && !isNil(transferShipment);

  const plannedDateDeliveryCondition =
    isOfdOfp === true
      ? !isNil(
          deliveryShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date,
        ) && isNil(recoveryShipment)
      : !isNil(deliveryShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date);
  // eslint-disable-next-line no-nested-ternary
  let plannedDate = plannedDateDeliveryCondition
    ? deliveryShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date
    : pickupShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date;

  if (isPickupWithoutRouteDateAndTransferWithRouteDate) {
    const pickupRouteDate =
      pickupShipmentWithOrWithoutRouteDate?.legs?.at(0)?.endStop?.routeSlot
        ?.route?.date;
    const transferRouteDate =
      transferShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date;
    // Use the transfer route date as the planned date if both are not null.
    if (
      (!isNil(pickupRouteDate) && !isNil(transferRouteDate)) ||
      !isNil(transferRouteDate)
    ) {
      plannedDate = transferRouteDate;
    } else {
      plannedDate = pickupRouteDate;
    }
  }

  return formatDate(plannedDate ?? null, 'Not Planned');
};

// TODO: refactor to avoid code duplication
export const getPlannedDateForTable = (
  order: CustomerPortalOrderForTableFragment,
  excludeCompletedShipments?: boolean,
  isOfdOfp?: boolean,
): string => {
  const shipments = getShipmentsWithRouteDateForTable(
    order,
    excludeCompletedShipments,
  );
  const deliveryShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Delivery,
  );
  const pickupShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Pickup,
  );
  const recoveryShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Recovery,
  );
  const transferShipment = shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Transfer,
  );

  const pickupShipmentWithOrWithoutRouteDate = order?.shipments?.find(
    (sh) => sh.legs[0]?.endStop?.stopType === StopType.Pickup,
  );
  const isPickupWithoutRouteDateAndTransferWithRouteDate =
    !isNil(pickupShipmentWithOrWithoutRouteDate) && !isNil(transferShipment);

  const plannedDateDeliveryCondition =
    isOfdOfp === true
      ? !isNil(
          deliveryShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date,
        ) && isNil(recoveryShipment)
      : !isNil(deliveryShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date);
  // eslint-disable-next-line no-nested-ternary
  let plannedDate = plannedDateDeliveryCondition
    ? deliveryShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date
    : pickupShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date;

  if (isPickupWithoutRouteDateAndTransferWithRouteDate) {
    const pickupRouteDate =
      pickupShipmentWithOrWithoutRouteDate?.legs?.at(0)?.endStop?.routeSlot
        ?.route?.date;
    const transferRouteDate =
      transferShipment?.legs?.at(0)?.endStop?.routeSlot?.route?.date;
    // Use the transfer route date as the planned date if both are not null.
    if (
      (!isNil(pickupRouteDate) && !isNil(transferRouteDate)) ||
      !isNil(transferRouteDate)
    ) {
      plannedDate = transferRouteDate;
    } else {
      plannedDate = pickupRouteDate;
    }
  }

  return formatDate(plannedDate ?? null, 'Not Planned');
};

export const getOfdOfp = (
  order: CustomerPortalOrdersFragment | CustomerPortalOrder,
  companyTimezone?: string,
): boolean => {
  const plannedDate = getPlannedDate(order, true, true);
  return !isNil(plannedDate) && !isNil(companyTimezone)
    ? dayjs(plannedDate)
        .tz(companyTimezone)
        .isSame(dayjs(new Date()).tz(companyTimezone), 'day')
    : false;
};

// TODO: refactor to avoid code duplication
export const getOfdOfpForTable = (
  order: CustomerPortalOrderForTableFragment,
  companyTimezone?: string,
): boolean => {
  const plannedDate = getPlannedDateForTable(order, true, true);
  return !isNil(plannedDate) && !isNil(companyTimezone)
    ? dayjs(plannedDate)
        .tz(companyTimezone)
        .isSame(dayjs(new Date()).tz(companyTimezone), 'day')
    : false;
};

export enum CustomerPortalOrderTableField {
  BILL_OF_LADING_NUMBER = 'BILL_OF_LADING_NUMBER',
  COMPANY = 'COMPANY',
  CONTACT = 'CONTACT',
  STATUS = 'STATUS',
  NEXT_ACTION = 'NEXT_ACTION',
  APPOINTMENT = 'APPOINTMENT',
  DEADLINE_DATE = 'DEADLINE_DATE',
  CONSIGNEE_NAME = 'CONSIGNEE_NAME',
  ADDRESS = 'ADDRESS',
  DATE_MARKED_ON_HAND = 'DATE_MARKED_ON_HAND',
  DATE_PLANNED = 'DATE_PLANNED',
  OSD = 'OSD',
  ON_HOLD = 'ON_HOLD',
  OFD_OFP = 'OFD_OFP',
}

export const computeNextActionForConsigneePortalOrder = (
  order: ConsigneePortalOrderFragment,
): string => {
  const incompleteShipments = order?.shipments?.filter(
    (shipment) => shipment?.status !== StopStatus.Completed,
  );
  const incompleteShipment = incompleteShipments?.[0];

  if (!isNil(incompleteShipment)) {
    return sentenceCase(incompleteShipment?.stopType ?? '-');
  }

  const pickupAirportTransferShipment = order?.shipments.filter(
    (sh) =>
      sh?.stopType === StopType.Pickup &&
      sh?.outboundMethod === OutboundMethod.AirportTransfer,
  )?.[0];
  const incompleteTransferShipments = incompleteShipments?.filter(
    (sh) => sh?.stopType === StopType.Transfer,
  );
  const hasAirportTransferPickupShipment =
    !isEmpty(pickupAirportTransferShipment) &&
    isEmpty(incompleteTransferShipments);

  if (hasAirportTransferPickupShipment) {
    return 'Transfer';
  }

  return 'Order Complete';
};

export const computeNextActionForOrder = (
  order: CustomerPortalOrdersFragment | CustomerPortalOrder,
): string => {
  const incompleteShipments = order?.shipments?.filter(
    (shipment) => shipment?.legs.at(0)?.endStop.status !== StopStatus.Completed,
  );
  const incompleteShipment = incompleteShipments?.at(0);

  if (!isNil(incompleteShipment)) {
    return sentenceCase(incompleteShipment?.legs[0]?.endStop.stopType ?? '-');
  }

  const pickupAirportTransferShipment = order?.shipments
    .filter(
      (sh) =>
        sh?.legs[0]?.endStop?.stopType === StopType.Pickup &&
        sh?.legs.at(0)?.endStop?.outboundMethod ===
          OutboundMethod.AirportTransfer,
    )
    ?.at(0);
  const incompleteTransferShipments = incompleteShipments?.filter(
    (sh) => sh?.legs[0]?.endStop?.stopType === StopType.Transfer,
  );
  const hasAirportTransferPickupShipment =
    !isEmpty(pickupAirportTransferShipment) &&
    isEmpty(incompleteTransferShipments);

  if (hasAirportTransferPickupShipment) {
    return 'Transfer';
  }

  return 'Order Complete';
};

// TODO: refactor to avoid code duplication
export const computeNextActionForOrderForTable = (
  order: CustomerPortalOrderForTableFragment,
): string => {
  const incompleteShipments = order?.shipments?.filter(
    (shipment) => shipment?.legs.at(0)?.endStop.status !== StopStatus.Completed,
  );
  const incompleteShipment = incompleteShipments?.at(0);

  if (!isNil(incompleteShipment)) {
    return sentenceCase(incompleteShipment?.legs[0]?.endStop.stopType ?? '-');
  }

  const pickupAirportTransferShipment = order?.shipments
    .filter(
      (sh) =>
        sh?.legs[0]?.endStop?.stopType === StopType.Pickup &&
        sh?.legs.at(0)?.endStop?.outboundMethod ===
          OutboundMethod.AirportTransfer,
    )
    ?.at(0);
  const incompleteTransferShipments = incompleteShipments?.filter(
    (sh) => sh?.legs[0]?.endStop?.stopType === StopType.Transfer,
  );
  const hasAirportTransferPickupShipment =
    !isEmpty(pickupAirportTransferShipment) &&
    isEmpty(incompleteTransferShipments);

  if (hasAirportTransferPickupShipment) {
    return 'Transfer';
  }

  return 'Order Complete';
};

export const formatCheckboxColumnIcon = (
  checked: boolean | null,
): ReactNode => {
  if (checked === true) {
    return <CheckIcon fontSize="small" color="success" />;
  }
  return <CloseIcon fontSize="small" color="error" />;
};

export const getCustomerPortalOrdersTableFieldCopy = (
  field: CustomerPortalOrderTableField,
  segment?: Segment,
): string => {
  switch (field) {
    case CustomerPortalOrderTableField.BILL_OF_LADING_NUMBER:
      return segment === Segment.Cartage ? 'HAWB#' : 'PRO#';
    case CustomerPortalOrderTableField.STATUS:
      return 'Status';
    case CustomerPortalOrderTableField.NEXT_ACTION:
      return 'Next Action';
    case CustomerPortalOrderTableField.APPOINTMENT:
      return 'Appointment';
    case CustomerPortalOrderTableField.DEADLINE_DATE:
      return 'Deadline Date';
    case CustomerPortalOrderTableField.OSD:
      return 'OS&D';
    case CustomerPortalOrderTableField.ADDRESS:
      return 'Primary Address';
    case CustomerPortalOrderTableField.OFD_OFP:
      return 'OFD/OFP';
    case CustomerPortalOrderTableField.CONSIGNEE_NAME:
    case CustomerPortalOrderTableField.DATE_PLANNED:
    case CustomerPortalOrderTableField.ON_HOLD:
    case CustomerPortalOrderTableField.DATE_MARKED_ON_HAND:
    case CustomerPortalOrderTableField.COMPANY:
    case CustomerPortalOrderTableField.CONTACT:
      return capitalCase(field.toString());
    default:
      return exhaustive(field);
  }
};

export const getCustomerPortalOrdersTableField = (
  field: CustomerPortalOrderTableField,
  order: CustomerPortalOrderForTableFragment | undefined,
  companyTimezone?: string,
): ReactNode => {
  if (isNil(order)) {
    return '-';
  }
  switch (field) {
    case CustomerPortalOrderTableField.BILL_OF_LADING_NUMBER: {
      return order?.standardOrderFields.shipperBillOfLadingNumber ?? '-';
    }
    case CustomerPortalOrderTableField.STATUS: {
      return getOrderDetailedStatusCopy({
        detailedStatus: order?.detailedStatusV2,
      });
    }
    case CustomerPortalOrderTableField.NEXT_ACTION:
      return computeNextActionForOrderForTable(order);
    case CustomerPortalOrderTableField.APPOINTMENT:
      return getAppointment({
        order,
        companyTimezone,
      });
    case CustomerPortalOrderTableField.DEADLINE_DATE:
      return getDeadlineDate(order);
    case CustomerPortalOrderTableField.OSD:
      return formatCheckboxColumnIcon(order?.osd === true);
    case CustomerPortalOrderTableField.OFD_OFP:
      return formatCheckboxColumnIcon(
        getOfdOfpForTable(order, companyTimezone),
      );
    case CustomerPortalOrderTableField.ADDRESS:
      return getAddress(order);
    case CustomerPortalOrderTableField.CONSIGNEE_NAME:
      return getConsigneeName(order);
    case CustomerPortalOrderTableField.DATE_PLANNED:
      return getPlannedDateForTable(order);
    case CustomerPortalOrderTableField.ON_HOLD:
      return formatCheckboxColumnIcon(order.onHold);
    case CustomerPortalOrderTableField.DATE_MARKED_ON_HAND:
      return formatDate(order.receivedDate, 'Not received');
    case CustomerPortalOrderTableField.COMPANY:
      return order?.company.name ?? '-';
    case CustomerPortalOrderTableField.CONTACT:
      return order?.contact.displayName ?? '-';
    default:
      return '-';
  }
};

const addFilterFromFilterModelToResult = (
  currentResult: CustomerPortalOrdersQueryVariables,
  filterField: CustomerPortalOrderFilterField,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterModelValue: any,
): CustomerPortalOrdersQueryVariables => {
  switch (filterField) {
    case CustomerPortalOrderFilterField.ORDER_TYPE:
      return {
        ...currentResult,
        stopTypesFilter: {
          fieldLevelFilterOperator: FilterOperator.And,
          stopTypes: [filterModelValue.value as PickupOrDelivery],
        },
      };
    case CustomerPortalOrderFilterField.APPOINTMENT_DATE:
      return {
        ...currentResult,
        appointmentDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    case CustomerPortalOrderFilterField.CREATED_DATE:
      return {
        ...currentResult,
        createdAtDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    case CustomerPortalOrderFilterField.DATE_MARKED_ON_HAND:
      return {
        ...currentResult,
        receivedDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    case CustomerPortalOrderFilterField.DATE_PLANNED:
      return {
        ...currentResult,
        plannedDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    case CustomerPortalOrderFilterField.DISPATCHED: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        dispatched: {
          filterOperator: FilterOperator.And,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case CustomerPortalOrderFilterField.OFD_OFP: {
      const { value } = filterModelValue;
      if (isNil(value)) {
        return {
          ...currentResult,
        };
      }
      return {
        ...currentResult,
        ofdOfp: {
          filterOperator: FilterOperator.And,
          value: booleanFilterOptionToBooleanFilter(filterModelValue.value),
        },
      };
    }
    case CustomerPortalOrderFilterField.COMPLETED_DATE:
      return {
        ...currentResult,
        completionDateFilters: convertDateFilterOptionToDateFilters({
          dateFilterOption: filterModelValue.value
            .fixedDateOption as DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
        }),
      };
    default:
      return { ...currentResult };
  }
};

export const getFetchCustomerPortalOrdersQueryVariables = ({
  companyFilter,
  contactFilter,
  filterModel,
}: {
  companyFilter?: string;
  contactFilter?: string;
  filterModel: FilterModel;
}): CustomerPortalOrdersQueryVariables => {
  let result: CustomerPortalOrdersQueryVariables = {
    companyFilter: isEmpty(companyFilter) ? undefined : companyFilter,
    contactFilter: isEmpty(contactFilter) ? undefined : contactFilter,
  };

  Object.keys(filterModel).forEach((key) => {
    const value = filterModel[key];
    result = addFilterFromFilterModelToResult(
      result,
      key as CustomerPortalOrderFilterField,
      value,
    );
  });
  return result;
};
