import { Menu, MenuItem } from '@mui/material';
import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { OrderFormValues } from '../../forms/types';
import { OnSubmitParams } from '../../types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../constants';

const ContextMenu = ({
  showContextMenu,
  buttonRef,
  onClose,
  onSubmit,
  onDelete,
}: {
  showContextMenu: boolean;
  buttonRef: HTMLButtonElement | null;
  onClose: () => void;
  onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  onDelete?: () => void | undefined;
}) => {
  const { getValues } = useFormContext<OrderFormValues>();
  const orderIsOnPostedInvoice =
    !isNil(getValues(`stops.${INBOUND_STOP_IDX}.invoicePostedDate`)) ||
    !isNil(getValues(`stops.${OUTBOUND_STOP_IDX}.invoicePostedDate`));

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  return (
    <Menu anchorEl={buttonRef} open={showContextMenu} onClose={onClose}>
      <MenuItem
        onClick={async () => {
          await onSubmit({
            duplicateEverything: true,
          });
        }}
        disabled={disabledIfNoAccess}
      >
        Save and duplicate entire order
      </MenuItem>
      <MenuItem
        onClick={async () => {
          await onSubmit({ duplicateNothing: true });
        }}
        disabled={disabledIfNoAccess}
      >
        Save and duplicate Customer
      </MenuItem>
      {!isNil(onDelete) && !orderIsOnPostedInvoice && (
        <MenuItem onClick={onDelete}>Delete order</MenuItem>
      )}
    </Menu>
  );
};

export default ContextMenu;
