import {
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  CircularProgress,
  Chip,
  Select,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { useDebounce } from 'use-debounce';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  InvoiceSendJobStatus,
  PermissionResource,
  useInvoiceSendJobsLazyQuery,
} from '../../../../../generated/graphql';
import InvoiceJobStatusChip from './invoice-job-status-chip';
import InvoiceSendJobListRow from './invoice-send-job-list-row';

const DEFAULT_ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE_OPTIONS = [10, 25, 100];

const InvoiceSendJobList = ({
  selectedInvoiceSendJobBatchUuid,
  initialStatus,
}: {
  selectedInvoiceSendJobBatchUuid: string;
  initialStatus?: InvoiceSendJobStatus;
}) => {
  const [status, setStatus] = useState<InvoiceSendJobStatus | 'All'>(
    initialStatus ?? 'All',
  );
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [
    getInvoiceSendJobs,
    { data: invoiceSendJobsData, startPolling, stopPolling, loading },
  ] = useInvoiceSendJobsLazyQuery();

  const fetchInvoiceSendJobs = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const res = await getInvoiceSendJobs({
      variables: {
        first,
        after,
        last,
        before,
        searchText,
        invoiceSendJobBatchUuid: selectedInvoiceSendJobBatchUuid,
        invoiceSendJobStatus: status === 'All' ? undefined : status,
      },
    });
    setTotalCount(res.data?.invoiceSendJobs.totalCount ?? 0);
  };

  const refresh = () => {
    fetchInvoiceSendJobs({
      first: rowsPerPage,
    });
    setPage(0);
  };

  const prev = async (newPage: number) => {
    await fetchInvoiceSendJobs({
      last: rowsPerPage,
      before:
        invoiceSendJobsData?.invoiceSendJobs.pageInfo?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchInvoiceSendJobs({
      first: rowsPerPage,
      after:
        invoiceSendJobsData?.invoiceSendJobs.pageInfo?.endCursor ?? undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, rowsPerPage, status]);

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const { userPermissions } = useUserRoles();
  const { hasMasterPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsRoles,
  );

  return (
    <TableContainer sx={{ height: '80vh' }}>
      <Grid container alignItems="center" sx={{ paddingBottom: 1 }}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              label="Search Invoices"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              sx={{ maxWidth: '400px' }}
            />
            {loading === true && <CircularProgress size={20} />}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TablePagination
            labelRowsPerPage="Show"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage: number) => {
              if (newPage > page) {
                next(newPage);
              } else {
                prev(newPage);
              }
            }}
            backIconButtonProps={{
              disabled: loading === true || page === 0,
            }}
            nextIconButtonProps={{
              disabled:
                loading === true ||
                page + 1 === Math.ceil(totalCount / rowsPerPage),
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(+e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Invoice</TableCell>
            <TableCell>Document attachments</TableCell>
            <TableCell>Transmission method</TableCell>
            <TableCell align="center">
              <Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value as InvoiceSendJobStatus | 'All');
                }}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                size="small"
                renderValue={(value) => {
                  return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle2">Status</Typography>{' '}
                      {value === 'All' ? (
                        <Chip size="small" label="All" />
                      ) : (
                        <InvoiceJobStatusChip status={value} />
                      )}
                    </Stack>
                  );
                }}
              >
                {Object.values(InvoiceSendJobStatus).map((txnStatus) => (
                  <MenuItem key={txnStatus} value={txnStatus}>
                    <InvoiceJobStatusChip status={txnStatus} />
                  </MenuItem>
                ))}
                <MenuItem key="All" value="All">
                  <Chip size="small" label="All" />
                </MenuItem>
              </Select>
            </TableCell>
            <TableCell>Emails</TableCell>
            <TableCell>Error</TableCell>
            <TableCell align="right">Document</TableCell>
            {hasMasterPermission && <TableCell>Invoice Charges</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceSendJobsData?.invoiceSendJobs.edges.map(
            ({ node: invoiceSendJob }) => (
              <InvoiceSendJobListRow
                key={invoiceSendJob.uuid}
                invoiceSendJob={invoiceSendJob}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceSendJobList;
