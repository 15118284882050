import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useHoldReasons from '../../../../../../../common/react-hooks/use-hold-reasons';
import {
  OrderDetailedStatus,
  OrderStatus,
  useMarkOrderAsOnHoldMutation,
} from '../../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { OrderFormValues } from '../../../forms/types';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  p: 2,
};

const MarkOrderOnHoldModal = ({
  isEditMode,
  open,
  setOpen,
  orderUuid,
}: {
  isEditMode: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  orderUuid: string;
}) => {
  const setValue = useFormContext<OrderFormValues>()?.setValue;
  const { getHoldReasonName, holdReasons } = useHoldReasons();
  const [holdReasonUuid, setHoldReasonUuid] = useState<string | undefined>(
    undefined,
  );
  const [markOrderAsOnHold] = useMarkOrderAsOnHoldMutation();

  const markAsOnHold = async () => {
    if (isEditMode) {
      const res = await markOrderAsOnHold({
        variables: {
          markAsOnHoldInput: {
            uuid: orderUuid,
            notes: undefined,
            holdReasonUuid,
          },
        },
      });
      if (!isNil(setValue)) {
        setValue('status', res.data?.markAsOnHold.status);
        setValue('detailedStatus', res.data?.markAsOnHold.detailedStatusV2);
      }
    } else if (!isNil(setValue)) {
      setValue('detailedStatus', OrderDetailedStatus.OnHold);
      setValue('status', OrderStatus.OnHold);
    }
    if (!isNil(setValue)) {
      setValue('holdReasonUuid', holdReasonUuid);
      setValue('holdReasonName', getHoldReasonName(holdReasonUuid));
    }
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)}>
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6" sx={{ mb: '20px' }}>
          Mark as on hold
        </Typography>
        <Typography color="text.secondary">Hold Reason (optional)</Typography>
        <AutocompleteFuzzy
          size="small"
          sx={{ width: '100%' }}
          value={
            !isEmpty(holdReasons) && !isNil(holdReasonUuid)
              ? {
                  value: holdReasonUuid,
                  label: getHoldReasonName(holdReasonUuid),
                }
              : { value: '', label: '' }
          }
          options={holdReasons.map((h) => ({
            value: h.uuid,
            label: h.name,
          }))}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              onClick={(e) => {
                e.stopPropagation();
              }}
              size="small"
            />
          )}
          onChange={(event, option) => {
            setHoldReasonUuid(option?.value);
          }}
        />
        <DialogActions>
          <Button variant="contained" sx={{ mt: 1 }} onClick={markAsOnHold}>
            Mark as on hold
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default React.memo(MarkOrderOnHoldModal);
