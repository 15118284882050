import { Layer, Source } from 'react-map-gl';

const Line = ({
  id,
  coordinates,
  color,
}: {
  id: string;
  coordinates: number[][];
  color: string;
}) => {
  return (
    <Source
      type="geojson"
      data={{
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates,
        },
      }}
    >
      <Layer
        id={id}
        type="line"
        paint={{
          'line-color': color, // Line color
          'line-width': 3, // Line width
        }}
      />
    </Source>
  );
};

export default Line;
