import { Check } from '@mui/icons-material';
import {
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useStyles from '../reports-styles';
import { Option } from '../types/report-filters';

const ReportFilterAutocompleteMenuList = ({
  allowAll = true,
  selectedOption,
  options,
  handleChange,
}: {
  allowAll?: boolean;
  selectedOption: Option | undefined;
  options: Option[];
  handleChange: (option: Option | undefined) => void;
}) => {
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;

  return (
    <MenuList
      dense
      sx={{
        p: 0,
      }}
    >
      {allowAll && (
        <MenuItem
          key="all"
          onClick={() => handleChange(undefined)}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            pl: '10px',
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Check
              sx={{
                visibility: isAllSelected ? undefined : 'hidden',
                fontSize: '14px',
                ml: 0,
                mr: '6px',
              }}
            />
            <Typography sx={styles.menuText}>All</Typography>
          </Stack>
        </MenuItem>
      )}
      <MenuItem
        key="custom"
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'visible',
          pl: '10px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Check
            sx={{
              visibility: !isAllSelected ? undefined : 'hidden',
              fontSize: '14px',
              ml: 0,
              mr: '6px',
            }}
          />
          <AutocompleteFuzzy
            size="small"
            sx={{ backgroundColor: 'white', width: '200px' }}
            value={selectedOption}
            options={options.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            matchSortOptions={{ keys: ['label'] }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                size="small"
              />
            )}
            onChange={(event, option) => {
              if (!isNil(option)) {
                handleChange(option);
              }
            }}
          />
        </Stack>
      </MenuItem>
    </MenuList>
  );
};

export default ReportFilterAutocompleteMenuList;
