import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import {
  AccessorialsTableDocument,
  AccessorialsTableQueryVariables,
  useAccessorialsTableQuery,
  useUpdateQuickAddAccessorialsMutation,
} from '../../../../generated/graphql';

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '460px',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
  },
};

const queryVariables: AccessorialsTableQueryVariables = {
  includeArchived: true,
};

type QuickAddAccessorialsModalProps = {
  open: boolean;
  onClose: () => void;
};

export const QuickAddAccessorialsModal: FunctionComponent<
  QuickAddAccessorialsModalProps
> = ({ open, onClose }) => {
  const theme = useTheme();
  // The parent component already fetches this data. We have this query here so that we can have direct access to the
  // data and refetch it as necessary.
  const { data: accessorialsData, loading } = useAccessorialsTableQuery({
    fetchPolicy: 'cache-first',
    variables: queryVariables,
  });
  const [update, { loading: saving }] = useUpdateQuickAddAccessorialsMutation({
    fetchPolicy: 'network-only',
    refetchQueries: [
      {
        query: AccessorialsTableDocument,
        variables: queryVariables,
      },
    ],
  });
  const [quickAddAccessorialUuids, setQuickAddAccessorialUuids] = useState<
    Array<string | null>
  >([]);
  useEffect(() => {
    setQuickAddAccessorialUuids(
      accessorialsData?.accessorials
        .filter(({ enableQuickAdd }) => enableQuickAdd)
        .map(({ uuid }) => uuid) ?? [],
    );
  }, [accessorialsData]);

  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false);

  const onSave = () => {
    update({
      variables: {
        input: {
          quickAddAccessorialUuids: filterNotNil(quickAddAccessorialUuids),
        },
      },
      onCompleted: () => {
        onClose();
        setShowSavedSnackbar(true);
      },
    });
  };

  return (
    <>
      <Snackbar
        autoHideDuration={2_000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSavedSnackbar}
        onClose={() => setShowSavedSnackbar(false)}
      >
        <Alert severity="success">Quick add accessorials saved</Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={styles.modal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <Typography variant="h4" fontSize="20px" lineHeight={1}>
              Quick add accessorials
            </Typography>
            <IconButton
              onClick={onClose}
              disabled={saving}
              style={{ width: '30px', height: '30px' }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box p={2} maxHeight="80vh" overflow="auto">
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
              mb={2}
            >
              These accessorials will appear under the charges on the order
              entry screen. You can add these accessorial charges with one
              click.
            </Typography>
            {loading ? (
              <Box display="flex" justifyContent="center" py={3}>
                <CircularProgress />
              </Box>
            ) : (
              <Stack gap={2}>
                {quickAddAccessorialUuids.map((accessorialUuid) => (
                  <Stack
                    key={accessorialUuid ?? 'new'}
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Select
                      size="small"
                      style={{ flexGrow: 1 }}
                      placeholder="Select an accessorial"
                      value={accessorialUuid ?? ''}
                      onChange={({ target }) => {
                        setQuickAddAccessorialUuids((prev) => {
                          if (prev.includes(target.value)) {
                            return prev;
                          }
                          return prev.map((uuid) =>
                            uuid === accessorialUuid ? target.value : uuid,
                          );
                        });
                      }}
                    >
                      {accessorialsData?.accessorials
                        .filter(
                          ({ uuid }) =>
                            uuid === accessorialUuid ||
                            !quickAddAccessorialUuids.includes(uuid),
                        )
                        .map(({ uuid, name }) => (
                          <MenuItem key={uuid} value={uuid}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                    <IconButton
                      style={{ width: '30px', height: '30px' }}
                      onClick={() => {
                        setQuickAddAccessorialUuids((prev) =>
                          prev.filter((uuid) => uuid !== accessorialUuid),
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
                <Button
                  variant="text"
                  sx={{
                    minWidth: 'max-content',
                    width: 'max-content',
                    padding: '1px',
                    color: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  color="inherit"
                  disabled={saving || quickAddAccessorialUuids.includes(null)}
                  onClick={() => {
                    setQuickAddAccessorialUuids((prev) => [...prev, null]);
                  }}
                >
                  Add
                </Button>
              </Stack>
            )}
          </Box>
          <Divider />
          <Stack direction="row" justifyContent="flex-end" p={2} gap={2}>
            <Button
              variant="text"
              color="inherit"
              disabled={saving}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={loading || saving}
              onClick={onSave}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
