import * as yup from 'yup';

import { PackageType } from '../../../../../generated/graphql';

export const packageSchema = yup.object({
  description: yup.string().optional().nullable(),
  height: yup.number().optional().nullable(),
  length: yup.number().optional().nullable(),
  width: yup.number().optional().nullable(),
  weight: yup.number().optional().nullable(),
  quantity: yup.number().required('Quantity is required'),
  type: yup
    .mixed<PackageType>()
    .oneOf(Object.values(PackageType))
    .optional()
    .nullable(),
  uuid: yup.string().required(),
  packageSpecId: yup.string().optional().nullable(),
  warehouseLocationName: yup.string().optional().nullable(),
  warehouseLocationUuid: yup.string().optional().nullable(),
});
