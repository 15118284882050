import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil, sum, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { pdfjs } from 'react-pdf';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useContacts from '../../../../../../../common/react-hooks/use-contacts';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useRecoveryLocationInfos from '../../../../../../../common/react-hooks/use-recovery-location-infos';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import {
  RecoveryTerminalFragment as RecoveryLocationFragment,
  WeightUnits,
} from '../../../../../../../generated/graphql';
import GeneratedContainerLabelPdf from '../../../../../../generated-documents/components/generated-container-label-pdf';
import GeneratedInventoryLabelPdf from '../../../../../../generated-documents/components/generated-inventory-label-pdf';
import GeneratedLotLabelsPdf from '../../../../../../generated-documents/components/lot-label/generated-lot-labels-pdf';
import {
  LabelDimensions,
  LabelSize,
  LabelType,
  getLabelDimensionFromLabelSize,
  getValidLabelSizesForLabelType,
} from '../../../../../../generated-documents/utils';
import {
  formatReferenceNumbersForLotLabel,
  getReferenceNumberLabels,
} from '../../../../utils';
import { StopType } from '../../../forms/stop-type';
import {
  AddressOptionalValues,
  AddressValues,
  OrderFormValues,
  PackageValues,
} from '../../../forms/types';
import { useOrderFormContact } from '../../../hooks/use-order-form-contact';
import {
  INBOUND_STOP_IDX,
  MAX_LABEL_QUANTITY,
  OUTBOUND_STOP_IDX,
} from '../../constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface LabelDocumentProps {
  terminalsEnabled: boolean;
  ffSurelogixOverrides: boolean;
  labelType: LabelType;
  orderName: string | null | undefined;
  companyName: string | undefined;
  contactName: string | undefined;
  shipperBillOfLadingNumber: string | undefined;
  masterAirwayBillOfLadingNumber: string | undefined;
  packages: PackageValues[] | null | undefined;
  defaultWeightUnits: WeightUnits | undefined;
  pickupAddress: AddressValues | AddressOptionalValues | null | undefined;
  recoveryAddress: AddressValues | AddressOptionalValues | null | undefined;
  pcdCarrier: string | null | undefined;
  pcpCarrier: string | null | undefined;
  consigneeAddress: AddressValues | AddressOptionalValues | null | undefined;
  originTerminalCode: string | undefined;
  destinationTerminalCode: string | undefined;
  destinationDetails: string | null | undefined;
  outboundFlightOriginCode: string | undefined;
  outboundFlightDestinationCode: string | undefined;
  recoveryLocationInfoForLabel: RecoveryLocationFragment | undefined;
  allDeadlineDates: string[];
  labelDimensions: LabelDimensions | undefined;
  isForwardAirComplete: boolean;
  totalLotLabels: number;
  inboundStopType: StopType | undefined;
  outboundStopType: StopType | undefined;
  appendPieceIdToLotLabel: boolean;
  refNumbers:
    | {
        refNumber: string;
        label?: string;
      }[]
    | undefined;
  inboundDate: string;
  outboundDate: string;
  inboundIataCode: string | undefined;
  outboundIataCode: string | undefined;
  ffRecoveryTransferAddressOnly: boolean;
}

const LabelDocument = ({
  terminalsEnabled,
  ffSurelogixOverrides,
  labelType,
  orderName,
  companyName,
  contactName,
  shipperBillOfLadingNumber,
  masterAirwayBillOfLadingNumber,
  packages,
  defaultWeightUnits,
  pickupAddress,
  recoveryAddress,
  pcdCarrier,
  pcpCarrier,
  consigneeAddress,
  originTerminalCode,
  destinationTerminalCode,
  destinationDetails,
  outboundFlightOriginCode,
  outboundFlightDestinationCode,
  recoveryLocationInfoForLabel,
  allDeadlineDates,
  labelDimensions,
  isForwardAirComplete,
  totalLotLabels,
  inboundStopType,
  outboundStopType,
  appendPieceIdToLotLabel,
  refNumbers,
  inboundDate,
  outboundDate,
  inboundIataCode,
  outboundIataCode,
  ffRecoveryTransferAddressOnly,
}: LabelDocumentProps) => {
  if (isNil(labelDimensions)) return null;
  switch (labelType) {
    case LabelType.OutboundLabel:
      return (
        <GeneratedInventoryLabelPdf
          companyName={companyName ?? ''}
          contact={contactName ?? ''}
          orderName={orderName ?? ''}
          hawb={shipperBillOfLadingNumber ?? ''}
          mawb={masterAirwayBillOfLadingNumber ?? ''}
          packages={filterNotNil(packages ?? []).map((package_) => ({
            ...package_,
            uuid: package_.uuid,
            description: package_.description ?? '',
            quantity: package_.quantity ?? undefined,
          }))}
          inboundStopType={inboundStopType}
          outboundStopType={outboundStopType}
          recoveryAddress={recoveryAddress ?? undefined}
          pcdCarrier={pcdCarrier ?? ''}
          pcpCarrier={pcpCarrier ?? ''}
          shipper={pickupAddress ?? undefined}
          consignee={consigneeAddress?.name ?? ''}
          outboundFlightAirline={recoveryLocationInfoForLabel?.terminal}
          outboundFlightOriginCode={
            ffRecoveryTransferAddressOnly || ffSurelogixOverrides
              ? originTerminalCode
              : outboundFlightOriginCode
          }
          outboundFlightDestinationCode={outboundFlightDestinationCode}
          destinationDetails={destinationDetails}
          destinationTerminalCode={destinationTerminalCode}
          isForwardAirComplete={isForwardAirComplete}
          widthDimension={labelDimensions.width}
          heightDimension={labelDimensions.height}
          inboundIataCode={inboundIataCode ?? undefined}
          outboundIataCode={outboundIataCode ?? undefined}
          ffRecoveryTransferAddressOnly={ffRecoveryTransferAddressOnly}
        />
      );
    case LabelType.ContainerLabel:
      return (
        <GeneratedContainerLabelPdf
          companyName={companyName ?? ''}
          contact={contactName ?? ''}
          tagNumber={orderName ?? ''}
          hawb={shipperBillOfLadingNumber ?? ''}
          mawb={masterAirwayBillOfLadingNumber ?? ''}
          packages={filterNotNil(packages ?? []).map((package_) => ({
            ...package_,
            uuid: package_.uuid,
            description: package_.description ?? '',
            quantity: package_.quantity ?? undefined,
          }))}
          inboundStopType={inboundStopType}
          outboundStopType={outboundStopType}
          recoveryAddress={recoveryAddress}
          pcdCarrier={pcdCarrier ?? undefined}
          pcpCarrier={pcpCarrier ?? undefined}
          defaultWeightUnits={defaultWeightUnits}
          shipperAddress={pickupAddress ?? undefined}
          consigneeAddress={consigneeAddress ?? undefined}
          outboundFlightAirline={recoveryLocationInfoForLabel?.terminal}
          outboundFlightOriginCode={
            ffSurelogixOverrides ? originTerminalCode : outboundFlightOriginCode
          }
          outboundFlightDestinationCode={outboundFlightDestinationCode}
          dates={allDeadlineDates.join(', ')}
          widthDimension={labelDimensions.width}
          heightDimension={labelDimensions.height}
          rotate={false}
        />
      );
    case LabelType.LotLabel:
      return (
        <GeneratedLotLabelsPdf
          companyName={companyName ?? ''}
          contact={contactName ?? ''}
          orderName={orderName ?? ''}
          hawb={shipperBillOfLadingNumber ?? ''}
          mawb={masterAirwayBillOfLadingNumber ?? ''}
          packages={filterNotNil(packages ?? []).map((package_) => ({
            ...package_,
            uuid: package_.uuid,
            description: package_.description ?? '',
            quantity: package_.quantity ?? undefined,
            warehouseLocation:
              !isNil(package_.warehouseLocationUuid) &&
              !isNil(package_.warehouseLocationName)
                ? {
                    uuid: package_.warehouseLocationUuid,
                    name: package_.warehouseLocationName,
                  }
                : undefined,
          }))}
          inboundStopType={inboundStopType}
          outboundStopType={outboundStopType}
          defaultWeightUnits={defaultWeightUnits}
          shipperAddress={pickupAddress ?? undefined}
          recoveryAddress={recoveryAddress ?? undefined}
          pcdCarrier={pcdCarrier ?? undefined}
          pcpCarrier={pcpCarrier ?? undefined}
          outboundFlightAirline={recoveryLocationInfoForLabel?.terminal}
          consigneeAddress={consigneeAddress ?? undefined}
          originTerminalCode={originTerminalCode}
          outboundFlightOriginCode={outboundFlightOriginCode}
          destinationTerminalCode={destinationTerminalCode}
          destinationDetails={destinationDetails ?? undefined}
          widthDimension={labelDimensions.width}
          heightDimension={labelDimensions.height}
          rotate
          terminalsEnabled={terminalsEnabled}
          totalLotLabels={totalLotLabels}
          appendPieceIdToLotLabel={appendPieceIdToLotLabel}
          refNumbers={refNumbers}
          inboundDate={inboundDate}
          outboundDate={outboundDate}
          inboundIataCode={inboundIataCode}
          outboundIataCode={outboundIataCode}
        />
      );
    default:
      return exhaustive(labelType);
  }
};

interface ViewInventoryLabelsProps {
  open: boolean;
  onClose: () => void;
  labelType: LabelType;
}

const DownloadInventoryLabelsModal = ({
  open,
  onClose,
  labelType,
}: ViewInventoryLabelsProps) => {
  const { getValues } = useFormContext();
  const { defaultWeightUnits, companyName, companyData } = useMe();
  const { contacts } = useContacts();
  const { terminalsEnabled, getTerminalCode } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getRecoveryLocationInfo } = useRecoveryLocationInfos();
  const ffShowTransferAddress = useFeatureFlag(
    FeatureFlag.FF_SHOW_TRANSFER_ADDRESS,
  );
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );
  const ffSurelogixOverrides = useFeatureFlag(
    FeatureFlag.FF_SURELOGIX_ORIGIN_TERMINAL,
  );
  const ffAppendPieceIdToLotLabel = useFeatureFlag(
    FeatureFlag.FF_APPEND_PIECE_ID_STRING_TO_LOT_LABEL,
  );
  const billingPartyContactUuid: OrderFormValues['contactUuid'] =
    getValues('contactUuid');
  const { contact } = useOrderFormContact();
  const name: OrderFormValues['name'] = getValues('name');
  const stops: OrderFormValues['stops'] = getValues('stops');
  const refNumbers: OrderFormValues['refNumbers'] = getValues('refNumbers');
  const formattedRefNumbers = useMemo(() => {
    const referenceNumberLabels = getReferenceNumberLabels({
      companyReferenceNumberLabels: companyData?.referenceNumberLabels,
      contactReferenceNumberLabels: contact?.referenceNumberLabels,
    });
    return formatReferenceNumbersForLotLabel({
      refNumbers: refNumbers ?? [],
      referenceNumberLabels,
    });
  }, [
    companyData?.referenceNumberLabels,
    contact?.referenceNumberLabels,
    refNumbers,
  ]);
  const currentContact = useMemo(() => {
    return contacts.find((c) => c.uuid === billingPartyContactUuid);
  }, [billingPartyContactUuid, contacts]);

  const shipperBillOfLadingNumber: OrderFormValues['shipperBillOfLadingNumber'] =
    getValues('shipperBillOfLadingNumber');
  const masterAirwayBillOfLadingNumber: OrderFormValues['masterAirwayBillOfLadingNumber'] =
    getValues('masterAirwayBillOfLadingNumber');
  const packages: OrderFormValues['packages'] = getValues('packages');

  const [recoveryLocationInfoForLabel, setRecoveryLocationInfoForLabel] =
    useState<RecoveryLocationFragment | null | undefined>();

  const transferStop = stops?.find((sh) => sh.stopType === StopType.Transfer);
  const recoveryStop = stops?.find((sh) => sh.stopType === StopType.Recovery);
  const pcdStop = stops?.find(
    (sh) => sh.stopType === StopType.PartnerCarrierDropoff,
  );
  const pcpStop = stops?.find(
    (sh) => sh.stopType === StopType.PartnerCarrierPickup,
  );

  const inboundStopType = stops?.at(INBOUND_STOP_IDX)?.stopType;
  const outboundStopType = stops?.at(OUTBOUND_STOP_IDX)?.stopType;

  const pickupAddress = useMemo(() => {
    return stops?.find((sh) => sh.stopType === StopType.Pickup)?.address;
  }, [stops]);
  const recoveryAddress = recoveryStop?.address;
  const pcdCarrier = pcdStop?.incomingCarrier;
  const pcpCarrier = pcpStop?.outboundCarrier;
  const consigneeAddress = useMemo(() => {
    const addr = stops?.find(
      (sh) =>
        sh.stopType === StopType.Delivery ||
        sh.stopType === StopType.PartnerCarrierPickup,
    )?.address;
    if (!isNil(addr)) {
      return addr;
    }
    if (!ffRecoveryTransferAddressOnly && ffShowTransferAddress) {
      return stops?.find((sh) => sh.stopType === StopType.Transfer)
        ?.transferAddress;
    }
    return null;
  }, [ffRecoveryTransferAddressOnly, ffShowTransferAddress, stops]);

  const inboundIataCode = stops?.at(INBOUND_STOP_IDX)?.address?.iataCode;
  const outboundIataCode = stops?.at(OUTBOUND_STOP_IDX)?.address?.iataCode;

  const outboundFlightOriginCode = getRecoveryLocationInfo(
    transferStop?.airportInfoUuid,
  )?.iataCode;
  const outboundFlightDestinationCode =
    transferStop?.destinationAirport ?? undefined;

  const originTerminalCode = terminalsEnabled
    ? getTerminalCode(stops?.at(INBOUND_STOP_IDX)?.terminalUuid)
    : undefined;

  const destinationTerminalCode = terminalsEnabled
    ? getTerminalCode(stops?.at(OUTBOUND_STOP_IDX)?.terminalUuid)
    : undefined;

  const destinationDetails =
    stops?.at(OUTBOUND_STOP_IDX)?.stopType === StopType.Transfer ||
    stops?.at(OUTBOUND_STOP_IDX)?.stopType === StopType.PartnerCarrierPickup
      ? stops?.at(OUTBOUND_STOP_IDX)?.destinationAirport
      : undefined;

  const inboundDate = isNil(stops?.at(INBOUND_STOP_IDX)?.deadlineDate)
    ? ''
    : dayjs(stops?.at(INBOUND_STOP_IDX)?.deadlineDate).format('MM/DD/YY');
  const outboundDate = isNil(stops?.at(OUTBOUND_STOP_IDX)?.deadlineDate)
    ? ''
    : dayjs(stops?.at(OUTBOUND_STOP_IDX)?.deadlineDate).format('MM/DD/YY');

  const allDeadlineDates = useMemo(() => {
    return filterNotNil(
      uniq(
        stops?.map((s) =>
          !isNil(s.deadlineDate)
            ? dayjs(s.deadlineDate).format('MM/DD/YY')
            : undefined,
        ),
      ),
    );
  }, [stops]);

  useEffect(() => {
    if (!isNil(transferStop) && !isEmpty(transferStop?.airportInfoUuid)) {
      setRecoveryLocationInfoForLabel(
        getRecoveryLocationInfo(transferStop?.airportInfoUuid),
      );
    }
  }, [stops, recoveryStop, transferStop, getRecoveryLocationInfo]);

  const [selectedLabelSize, setSelectedLabelSize] = useState<LabelSize>();
  const [isForwardAirComplete, setIsForwardAirComplete] =
    useState<boolean>(false);

  const initialNumberOfLotLabelsToDisplay = sum(
    packages?.map((pkg) => pkg.quantity ?? 1),
  );

  const [totalLotLabels, setTotalLotLabels] = useState<number>(
    initialNumberOfLotLabelsToDisplay,
  );

  useEffect(() => {
    const validLabelSizes = getValidLabelSizesForLabelType(labelType);
    if (!isNil(validLabelSizes[0])) setSelectedLabelSize(validLabelSizes[0]);
  }, [labelType]);

  const labelDimensions = useMemo(() => {
    if (isNil(selectedLabelSize)) return null;
    return getLabelDimensionFromLabelSize(selectedLabelSize);
  }, [selectedLabelSize]);

  const [error, setError] = useState(false);

  const handleLotLabelQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const valueAsNumber = Number(event.target.value);

    if (valueAsNumber > MAX_LABEL_QUANTITY) {
      setTotalLotLabels(MAX_LABEL_QUANTITY); // Set to max value if above the required range
      setError(true);
    } else {
      setTotalLotLabels(valueAsNumber);
      setError(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {labelType}
      </DialogTitle>
      {!isNil(selectedLabelSize) && (
        <DialogContent sx={{ pt: '10px !important' }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel id="label-type-selection-label">
                      Type
                    </InputLabel>
                    <Select
                      label="Type"
                      size="small"
                      style={{ minWidth: 100, backgroundColor: 'white' }}
                      value={selectedLabelSize}
                      onChange={(e) => {
                        const labelSize = e.target.value as LabelSize;
                        setSelectedLabelSize(labelSize);
                      }}
                    >
                      {getValidLabelSizesForLabelType(labelType).map((size) => {
                        return (
                          <MenuItem key={size} value={size}>
                            {size}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </FormControl>

                {labelType === LabelType.LotLabel && (
                  <FormControl>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        label="Number"
                        type="text"
                        size="small"
                        value={totalLotLabels}
                        onChange={handleLotLabelQuantityChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        error={error}
                        helperText={
                          error
                            ? `Value can't exceed ${MAX_LABEL_QUANTITY}`
                            : ''
                        }
                      />
                    </Box>
                  </FormControl>
                )}
                {labelType === LabelType.OutboundLabel && (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isForwardAirComplete}
                          onChange={(e) =>
                            setIsForwardAirComplete(e.target.checked)
                          }
                        />
                      }
                      label="Forward Air Complete"
                    />
                  </FormControl>
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <PDFViewer style={{ width: '100%', height: '75vh' }} showToolbar>
                <LabelDocument
                  companyName={companyName ?? ''}
                  contactName={currentContact?.displayName}
                  orderName={name}
                  shipperBillOfLadingNumber={shipperBillOfLadingNumber}
                  masterAirwayBillOfLadingNumber={
                    masterAirwayBillOfLadingNumber ?? undefined
                  }
                  packages={packages}
                  pickupAddress={pickupAddress}
                  recoveryAddress={recoveryAddress}
                  pcdCarrier={pcdCarrier}
                  pcpCarrier={pcpCarrier}
                  consigneeAddress={consigneeAddress}
                  recoveryLocationInfoForLabel={
                    recoveryLocationInfoForLabel ?? undefined
                  }
                  outboundFlightOriginCode={outboundFlightOriginCode}
                  outboundFlightDestinationCode={outboundFlightDestinationCode}
                  labelDimensions={labelDimensions ?? undefined}
                  isForwardAirComplete={isForwardAirComplete}
                  terminalsEnabled={terminalsEnabled}
                  labelType={labelType}
                  defaultWeightUnits={defaultWeightUnits}
                  originTerminalCode={originTerminalCode}
                  destinationTerminalCode={destinationTerminalCode}
                  destinationDetails={destinationDetails}
                  allDeadlineDates={allDeadlineDates}
                  totalLotLabels={totalLotLabels}
                  ffSurelogixOverrides={ffSurelogixOverrides}
                  inboundStopType={inboundStopType}
                  outboundStopType={outboundStopType}
                  appendPieceIdToLotLabel={ffAppendPieceIdToLotLabel}
                  refNumbers={formattedRefNumbers}
                  inboundDate={inboundDate}
                  outboundDate={outboundDate}
                  inboundIataCode={inboundIataCode ?? undefined}
                  outboundIataCode={outboundIataCode ?? undefined}
                  ffRecoveryTransferAddressOnly={ffRecoveryTransferAddressOnly}
                />
              </PDFViewer>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button variant="contained">
                <PDFDownloadLink
                  document={
                    <LabelDocument
                      companyName={companyName ?? ''}
                      contactName={currentContact?.displayName}
                      orderName={name}
                      shipperBillOfLadingNumber={shipperBillOfLadingNumber}
                      masterAirwayBillOfLadingNumber={
                        masterAirwayBillOfLadingNumber ?? undefined
                      }
                      packages={packages}
                      pickupAddress={pickupAddress}
                      recoveryAddress={recoveryAddress}
                      pcdCarrier={pcdCarrier}
                      pcpCarrier={pcpCarrier}
                      consigneeAddress={consigneeAddress}
                      recoveryLocationInfoForLabel={
                        recoveryLocationInfoForLabel ?? undefined
                      }
                      outboundFlightOriginCode={outboundFlightOriginCode}
                      outboundFlightDestinationCode={
                        outboundFlightDestinationCode
                      }
                      labelDimensions={labelDimensions ?? undefined}
                      isForwardAirComplete={isForwardAirComplete}
                      terminalsEnabled={terminalsEnabled}
                      labelType={labelType}
                      defaultWeightUnits={defaultWeightUnits}
                      originTerminalCode={originTerminalCode}
                      destinationTerminalCode={destinationTerminalCode}
                      destinationDetails={destinationDetails}
                      allDeadlineDates={allDeadlineDates}
                      totalLotLabels={totalLotLabels}
                      ffSurelogixOverrides={ffSurelogixOverrides}
                      inboundStopType={inboundStopType}
                      outboundStopType={outboundStopType}
                      appendPieceIdToLotLabel={ffAppendPieceIdToLotLabel}
                      refNumbers={formattedRefNumbers}
                      inboundDate={inboundDate}
                      outboundDate={outboundDate}
                      inboundIataCode={inboundIataCode ?? undefined}
                      outboundIataCode={outboundIataCode ?? undefined}
                      ffRecoveryTransferAddressOnly={
                        ffRecoveryTransferAddressOnly
                      }
                    />
                  }
                  fileName={`${sentenceCase(
                    labelType.toString(),
                  )}-${shipperBillOfLadingNumber}.pdf`}
                >
                  {({ loading }) => (loading ? 'Loading...' : 'Download')}
                </PDFDownloadLink>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DownloadInventoryLabelsModal;
