export const base64ToUint8Array = (base64data: string): Uint8Array => {
  const binaryString = window.atob(base64data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};
